import { Paper, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import React, { Component } from 'react';
import styled from 'styled-components';
import { Space } from './Layout';

let Container = styled.div`
  padding: .5em 1em;

  display: flex;
  flex-align: center;
`

let StyledPaper = styled(Paper).attrs(() => ({
  classes: {
    root: 'root',
  },
}))`
  background-color: ${({theme}) => theme.palette.success.main}
`

/**
 * @deprecated: Use the toaster helpers instead (which are built on react-hot-toast)
 */
let Alert = ({children, color = 'success', onClose}) => {
  return (
    <StyledPaper elevation={3} classes={color} style={{opacity: ".9"}}>
      <Container>
        {children}
        <Space inline />
        <IconButton style={{padding: '0px', opacity: ".7"}} onClick={onClose} size="large">
          <Close />
        </IconButton>
      </Container>
    </StyledPaper>
  );
}
export default Alert 

