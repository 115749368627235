import React from 'react'
import styled from 'styled-components'

let CalendarContainer = styled.div`
  flex: 1;
  background-color: cornflowerblue;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: repeat(5, 1fr);
  grid-auto-rows: 1fr;
`
CalendarContainer.displayName = 'CalendarContainer'

let CalendarDay = styled.div`
  border: solid 1px palevioletred;
`
CalendarDay.displayName = 'CalendarDay'

class AttendanceCalendar extends React.Component {
  render() {
    return (
      <CalendarContainer>
        {[...Array(31).keys()].map(i => (
          <CalendarDay>day {i}</CalendarDay>
        ))}
      </CalendarContainer>
    )
  }
}

export default AttendanceCalendar
