import React, { useState, useEffect } from 'react'
import gql from 'graphql-tag'
import { Column } from 'react-virtualized'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import moment from 'moment'
import queryString from 'query-string'

import { DatePicker } from '@mui/lab'
import Button from '@mui/material/Button'
import Input from '@mui/material/Input'
import InputAdornment from '@mui/material/InputAdornment'
import SelectMenu from '../../components/ToolbarDropDownMenu'
import MenuItem from '@mui/material/MenuItem'

import CalendarIcon from '@mui/icons-material/CalendarToday'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TableHead,
  TableCell,
  Table,
  TableBody,
  TableRow,
  FormControlLabel,
  Checkbox,
  Typography,
  TextField,
} from '@mui/material'

import TableList from '../../components/Table/TableList'
import { Body1, Subhead } from '../../components/Typography'
import AvatarBorder from '../../components/AvatarBorder'
import { usePagination } from '../../components/PaginationContainer'
import { Toolbar } from '../../AppHandler'
import ToolbarGroup from '../../components/ToolbarGroup'
import ToolbarMegaTitle from '../../components/ToolbarMegaTitle'
import ModifyQueryParams from '../../components/ModifyQueryParams'
import { Space } from '../../components/Layout'
import { formatMoneyStandard } from '../../utils/moneyFormatter'
import { ArrowForward } from '@mui/icons-material'
import { fetchAPI } from '../../schema/utils'
import { useQuery, useMutation } from 'react-query'
import { prgql } from '../../utils/graphql'
import LoadingButton from '../../components/LoadingButton'

function DateInput({ onClick, value }) {
  return (
    <Input
      onClick={onClick}
      value={value}
      startAdornment={
        <InputAdornment position="start">
          <CalendarIcon />
        </InputAdornment>
      }
    />
  )
}

let usersQuery = gql`
  query CommissionUsers {
    allUsers(filters: {commission: true, status: active}) {
      edges {
        edge {
          id
          first_name
          last_name
        }
      }
    }
  }
`

let tipReportMutation = gql`
  mutation tipReport($user_ids: [ID]!, $from: String!, $to: String!) {
    sendTipsReports(input: {user_ids: $user_ids, from: $from, to: $to}) {
      message
    }
  }
`

const ConfirmSendReport = ({
  open,
  onCancel,
  onConfirm,
  selectedRows,
  loading,
}) => (
  <Dialog open={!!open} maxWidth={'sm'} fullWidth>
    <DialogTitle>Confirm Send Tips Reports</DialogTitle>
    <DialogContent>
      <Typography style={{ marginBottom: '1em' }}>
        You are about to send a tip report for{' '}
        {selectedRows.map(r => r.name).join(', ')}.
      </Typography>
      <Typography style={{ marginBottom: '1em' }}>
        Please make sure they all have an email address, else the report will
        not send for them.
      </Typography>
      <Typography style={{ marginBottom: '1em' }}>Proceed?</Typography>
    </DialogContent>
    <DialogActions>
      <Button onClick={onCancel}>Cancel</Button>
      <LoadingButton
        variant="contained"
        color="primary"
        onClick={onConfirm}
        loading={!!loading}
        id={'send-tips-reports'}
      >
        Send
      </LoadingButton>
    </DialogActions>
  </Dialog>
)

function ScreenToolbar({
  yearMonth,
  reportable,
  includeOpen,
  onChange = () => { },
  onSendReport,
  userId,
}) {
  return (
    <Toolbar>
      <ToolbarGroup first>
        <ToolbarMegaTitle>Tips</ToolbarMegaTitle>
        <Space inline size={2} />
        <DatePicker
          views={['year', 'month']}
          label="As of"
          value={moment.utc(yearMonth + '-01').format('YYYY-MM-DD')}
          inputFormat="MMMM YYYY"
          onChange={d => onChange({ yearMonth: d.format('YYYY-MM') })}
          renderInput={props => (
            <TextField {...props} margin="dense" size="small" />
          )}
          autoOk
        />
        <Space inline size={2} />
        <FormControlLabel
          control={
            <Checkbox
              checked={reportable === 'true'}
              onChange={e =>
                onChange({ reportable: e.target.checked ? 'true' : 'false' })
              }
            />
          }
          label={'Only Show Reportable Tips'}
        />
      </ToolbarGroup>
      <ToolbarGroup>
        <Button onClick={onSendReport}>Send Tips Reports</Button>
      </ToolbarGroup>
    </Toolbar>
  )
}

let getData = async url => {
  let data = await fetchAPI({
    url,
  })

  return JSON.parse(await data.text())
}

function StatisticsTips({ location, match }) {
  let [confirmReport, setConfirmReport] = useState(false)
  let [queryChanges, setQueryChanges] = useState({})
  let {
    yearMonth = moment
      .utc()
      .subtract(1, 'month')
      .format('YYYY-MM'),
    reportable = 'false',
  } = queryString.parse(location.search)

  let from = yearMonth + '-01'
  let to = moment
    .utc(yearMonth + '-01')
    .endOf('month')
    .format('YYYY-MM-DD')

  let { data, isLoading, error } = useQuery(['tips', to, from], () =>
    getData(`/statistics/tips?from=${from}&to=${to}`),
  )

  let { mutateAsync: send, isLoading: sendLoading } = useMutation(variables =>
    prgql({ query: tipReportMutation, variables }),
  )

  let sendReport = async rows => {
    let user_ids = rows.map(r => r.user_id)

    await send({ user_ids, from, to })
    setConfirmReport(false)
  }

  if (isLoading) {
    return (
      <>
        <ScreenToolbar
          to={to}
          from={from}
          onChange={changes => setQueryChanges(c => ({ ...c, ...changes }))}
        />
        <p>Loading...</p>
      </>
    )
  }

  if (error) {
    return (
      <>
        <ScreenToolbar
          to={to}
          from={from}
          onChange={changes => setQueryChanges(c => ({ ...c, ...changes }))}
        />
        <p>An error occurred: {error.message}</p>
      </>
    )
  }

  const reducedData = data.reduce((acc, item) => {
    if (!acc[item.user_id]) {
      acc[item.user_id] = {
        user_id: item.user_id,
        name: item.name,
        total: 0,
        totalCash: 0,
        totalPurchasingCard: 0,
        email: '',
      }
    }
    acc[item.user_id].totalCash += item.type_id === 1 ? item.amount : 0
    acc[item.user_id].totalPurchasingCard +=
      item.type_id === 2 ? item.amount : 0
    acc[item.user_id].total += item.amount
    acc[item.user_id].email = item.email
    return acc
  }, {})

  let dataArray = Object.values(reducedData)

  if (reportable === 'true') {
    dataArray = dataArray.filter(v => v.total >= 20)
  }

  return (
    <React.Fragment>
      <ScreenToolbar
        yearMonth={yearMonth}
        reportable={reportable}
        onChange={changes => setQueryChanges(c => ({ ...c, ...changes }))}
        onSendReport={() => setConfirmReport(true)}
      />
      <ModifyQueryParams query={queryChanges} />
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>User ID</TableCell>
            <TableCell>Total Cash</TableCell>
            <TableCell>Total Purchasing Card</TableCell>
            <TableCell>Total</TableCell>
            <TableCell>Email</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {dataArray.map(item => (
            <TableRow key={item.user_id}>
              <TableCell>{item.name}</TableCell>
              <TableCell>{formatMoneyStandard(item.totalCash)}</TableCell>
              <TableCell>
                {formatMoneyStandard(item.totalPurchasingCard)}
              </TableCell>
              <TableCell>{formatMoneyStandard(item.total)}</TableCell>
              <TableCell>{item.email}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <ConfirmSendReport
        open={confirmReport}
        onCancel={() => setConfirmReport(false)}
        onConfirm={() => sendReport(dataArray)}
        loading={sendLoading}
        selectedRows={dataArray}
      />
    </React.Fragment>
  )
}

export default StatisticsTips
