import React from 'react'
import styled from 'styled-components'
import {Link} from 'react-router-dom'
import gql from 'graphql-tag'
import {useQuery, useMutation} from '@apollo/react-hooks'
import moment from 'moment'
import queryString from 'query-string'
import {Formik, Field} from 'formik'
import * as Yup from 'yup'

import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  Checkbox,
  TableBody,
  Typography,
  Input,
  InputAdornment,
  Button,
  Grid,
  MenuItem,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField as MuiTextField,
} from '@mui/material'
import {
  CalendarToday as CalendarIcon,
  ArrowRightAlt,
  Event as RepeatOneIcon,
  Loop as RepeatIcon,
  ArrowForward,
} from '@mui/icons-material'
import {DatePicker as MuiDatePicker} from '@mui/lab'

import {Toolbar} from '../../AppHandler'
import ToolbarGroup from '../../components/ToolbarGroup'
import {Space} from '../../components/Layout'
import {RouteSelectStatic as RouteSelect} from '../../components/RouteSelect'
import RouteSelectDynamic from '../../components/RouteSelect'
import ModifyQueryParams from '../../components/ModifyQueryParams'
import {TextField, AutoCompleteField, DatePicker} from '../../components/forms'
import LoadingButton from '../../components/LoadingButton'
import {Recurrence} from '../Customer/CustomerSchedules'

function DateInput({onClick, value}) {
  return (
    <Input
      onClick={onClick}
      value={value}
      startAdornment={
        <InputAdornment position="start">
          <CalendarIcon />
        </InputAdornment>
      }
    />
  )
}

let scheduleSchema = Yup.object().shape({
  route_id: Yup.string(),
})

const DAYS_OF_WEEK = {
  1: 'Sun',
  2: 'Mon',
  3: 'Tue',
  4: 'Wed',
  5: 'Thu',
  6: 'Fri',
  7: 'Sat',
}

const ORDINALS = {
  1: '1st',
  2: '2nd',
  3: '3rd',
  4: '4th',
  5: 'Last',
}

function _getFormulaText(schedule) {
  if (schedule.freq_type === 1)
    return `1 time on ${moment.utc(schedule.start_at).format('M/D/YY')}`

  let text = ''
  switch (schedule.freq_type) {
    case 0:
      text = `(4 Wk Cycle) ${DAYS_OF_WEEK[schedule.day]}, Wk ${schedule.week}`
      break
    case 2:
      text = `Every ${schedule.freq_interval} days`
      break
    case 3:
      text = `Every ${schedule.freq_recurrence_factor} weeks on ${
        DAYS_OF_WEEK[schedule.freq_interval]
      }`
      break
    case 4:
      text = `Every ${schedule.freq_recurrence_factor} months on day ${schedule.freq_interval}`
      break
    case 5:
      text = `Every ${schedule.freq_recurrence_factor} months on the ${
        ORDINALS[schedule.freq_relative_interval]
      } ${DAYS_OF_WEEK[schedule.freq_interval]}`
      break
    default:
      text = 'Every so often'
      break
  }

  if (!schedule.start_at) return text

  if (schedule.end_at)
    return text + ` ending on ${moment.utc(schedule.end_at).format('M/D/YY')}`

  return text + ` starting on ${moment.utc(schedule.start_at).format('M/D/YY')}`
}

function EditScheduleDetails({
  schedule,
  defaultAssignedAt = moment().add(1, 'd'),
  children,
  ...props
}) {
  return (
    <Formik
      initialValues={schedule || {start_at: null}}
      validationSchema={scheduleSchema}
      {...props}
      children={({
        isSubmitting,
        isValid,
        values,
        setFieldValue,
        submitForm,
      }) => {
        let form = (
          <>
            <Grid container spacing={2}>
              <Grid item sm={12} spacing={2} container>
                <Grid item>
                  <Chip
                    label="Recurring"
                    icon={<RepeatIcon />}
                    onClick={() => {
                      setFieldValue('start_at', null)
                      setFieldValue('week', null)
                      setFieldValue('day', null)
                      setFieldValue('freq_type', 2)
                    }}
                    color={
                      values.freq_type >= 2 || values.freq_type === 0
                        ? 'primary'
                        : undefined
                    }
                  />
                </Grid>
                <Grid item>
                  <Chip
                    label="One Time"
                    icon={<RepeatOneIcon />}
                    onClick={() => {
                      setFieldValue(
                        'start_at',
                        moment()
                          .add(1, 'd')
                          .format('YYYY-MM-DD'),
                      )
                      setFieldValue('week', null)
                      setFieldValue('day', null)
                      setFieldValue('freq_type', 1)
                    }}
                    color={values.freq_type === 1 ? 'primary' : undefined}
                  />
                </Grid>
              </Grid>

              <Grid item sm={2}>
                <AutoCompleteField
                  as={RouteSelectDynamic}
                  name="route_id"
                  selectedId={values.route_id}
                  fullWidth
                />
              </Grid>

              <Recurrence values={values} />

              {values.freq_type >= 2 && (
                <>
                  <Grid item sm={2}>
                    <DatePicker
                      name="start_at"
                      inputVariant="filled"
                      label="Start"
                      format="dddd, MMMM D, YYYY"
                      fullWidth
                    />
                  </Grid>
                  <Grid item style={{display: 'flex', alignItems: 'center'}}>
                    <ArrowForward />
                  </Grid>
                  <Grid item sm={2}>
                    <DatePicker
                      name="end_at"
                      inputVariant="filled"
                      label="End"
                      format="dddd, MMMM D, YYYY"
                      fullWidth
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </>
        )
        if (typeof children === 'function') {
          return children({submitForm, isSubmitting, form, isValid})
        } else {
          return form
        }
      }}
    />
  )
}

let schedulesQuery = gql`
  query routeRunSchedulesMove($date: String, $route: Int) {
    allRouteRuns(filters: {from: $date, to: $date, route: $route}) {
      edges {
        edge {
          id
          run_at
          schedules {
            id
            # service
            charge
            man_minutes
            route_id
            # assigned_at
            start_at
            end_at
            freq_type
            freq_interval
            freq_relative_interval
            freq_recurrence_factor
            day
            week
            customer {
              id
              name
              city {
                id
                name
              }
            }
            services {
              id
              saleitem {
                id
                name
                description
              }
              description
              notes
            }
          }
        }
      }
    }
  }
`
let moveSchedulesMut = gql`
  mutation moveSchedules($input: [UpdateManySchedulesInput]) {
    updateManySchedules(input: $input) {
      schedules {
        id
      }
    }
  }
`

function Screen({location}) {
  let {
    route = '11',
    date = moment.utc().format('YYYY-MM-DD'),
  } = queryString.parse(location.search)

  let [queryChanges, setQueryChanges] = React.useState({})
  let [openDialog, setOpenDialog] = React.useState(false)

  let {data, loading, error, refetch} = useQuery(schedulesQuery, {
    variables: {
      date,
      route: parseInt(route, 10),
    },
  })
  let [moveSchedules] = useMutation(moveSchedulesMut)

  let schedules =
    (data &&
      data.allRouteRuns &&
      data.allRouteRuns.edges[0] &&
      data.allRouteRuns.edges[0].edge.schedules) ||
    []

  let [selected, setSelected] = React.useState({})
  let checked = Object.values(selected).filter(s => s)
  let allChecked = checked.length === schedules.length
  let someChecked = !!checked.length

  // Reset selected when page changes
  React.useEffect(() => {
    setSelected({})
  }, [route, date])

  function handleCheck(id) {
    setSelected(s => ({...s, [id]: !s[id]}))
  }
  function handleCheckAll() {
    if (allChecked) {
      setSelected({})
    } else {
      setSelected(schedules.reduce((acc, {id}) => ({...acc, [id]: true}), {}))
    }
  }

  function handleSave(s) {
    console.log('saving schedules', s)
    return moveSchedules({
      variables: {
        input: Object.keys(selected).map(id => ({
          id,
          ...s,
        })),
      },
    }).then(() => {
      setOpenDialog(false)
      setSelected({})
      refetch()
    })
  }

  if (loading) {
    return <div>Loading...</div>
  }

  if (error) {
    return <div>Error...</div>
  }

  return (
    <>
      <EditScheduleDetails
        onSubmit={handleSave}
        defaultAssignedAt={moment(date)}
      >
        {({form, submitForm, isSubmitting, isValid}) => {
          return (
            <Dialog open={openDialog} maxWidth="lg" fullWidth>
              <DialogTitle>Move {checked.length || 0} Schedules</DialogTitle>
              <DialogContent>{form}</DialogContent>
              <DialogActions>
                <Button
                  onClick={() => setOpenDialog(false)}
                  disabled={isSubmitting}
                >
                  Cancel
                </Button>
                <LoadingButton
                  variant="contained"
                  color="primary"
                  onClick={submitForm}
                  loading={isSubmitting}
                  disabled={!isValid}
                >
                  Save
                </LoadingButton>
              </DialogActions>
            </Dialog>
          )
        }}
      </EditScheduleDetails>
      <Toolbar>
        <ModifyQueryParams query={{...queryChanges}} />
        <ToolbarGroup first>
          <Typography variant="h6">Move Schedules</Typography>
          <Space inline size={2} />
          <Space inline />
        </ToolbarGroup>
        <ToolbarGroup last>
          <MuiDatePicker
            variant="filled"
            label="As of"
            value={moment.utc(date)}
            inputFormat="ddd. MMMM D, YYYY"
            onChange={d =>
              setQueryChanges(c => ({...c, date: d.format('YYYY-MM-DD')}))
            }
            renderInput={props => (
              <MuiTextField
                {...props}
                variant="outlined"
                margin="dense"
                size="small"
              />
            )}
            autoOk
          />
          <Space inline />
          <RouteSelect
            value={route}
            onChange={e =>
              setQueryChanges(c => ({...c, route: e.target.value}))
            }
          />
          <Space inline size={3} />
          <Button variant="contained" onClick={() => setOpenDialog(true)}>
            Move
            <ArrowRightAlt />
          </Button>
          <Space inline size={3} />
          <Typography variant="body1">
            {checked.length || 0} schedules selected
          </Typography>
        </ToolbarGroup>
      </Toolbar>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell padding="checkbox">
              <Checkbox
                color="secondary"
                checked={allChecked}
                indeterminate={someChecked && !allChecked}
                onChange={handleCheckAll}
              />
            </TableCell>
            <TableCell>Name</TableCell>
            <TableCell>City</TableCell>
            <TableCell>Charge</TableCell>
            <TableCell>Time</TableCell>
            <TableCell>Service</TableCell>
            <TableCell>Frequency</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {schedules.map(s => {
            let isRecurring = !s.assigned_at
            let scheduleText = isRecurring
              ? `every ${s.day}, Week ${s.week}`
              : moment.utc(s.assigned_at).format('M/DD/YYYY')
            return (
              <TableRow key={s.id}>
                <TableCell padding="checkbox">
                  <Checkbox
                    color="secondary"
                    checked={!!selected[s.id]}
                    onChange={() => handleCheck(s.id)}
                  />
                </TableCell>
                <TableCell>
                  <Link to={`/customers/${s.customer.id}`}>
                    {s.customer.name}
                  </Link>
                </TableCell>
                <TableCell>{s.customer.city.name}</TableCell>
                <TableCell>{s.charge}</TableCell>
                <TableCell>{s.man_minutes}</TableCell>
                <TableCell>
                  {s.services
                    .map(s => s.saleitem.name + ' ' + s.description)
                    .join(', ')}
                </TableCell>
                <TableCell>{_getFormulaText(s)}</TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </>
  )
}

export default Screen
