import PropTypes from 'prop-types';
import React from 'react';

class EntityColumn extends React.Component {
  static propTypes = {
    relationship: PropTypes.string,
    attribute: PropTypes.string,
    cellRenderer: PropTypes.func,
    dataKey: PropTypes.string,
    findEntity: PropTypes.bool,
  };

  static defaultProps = {
    cellRenderer: ({entityData}) => <span>{entityData}</span>,
    findEntity: true,
  };
}
export default EntityColumn

