import urlJoin from 'url-join'

import { resolve, fetchEndpoint, resolveCachedEntity, getIncludes } from './utils'

export const relationship = {
  types: `
    directive @relationship(nestedInclude: Boolean, includeName: String) on FIELD_DEFINITION
  `,
  source: () => { },
  schema: ({ definition, args }) => {
    if (args.nestedInclude !== false && args.nestedInclude !== null) {
      definition.relationship = true
      definition.relationshipIncludeName = args.includeName
    }
    definition.resolve = async (root, query, context, info) => {
      let includeName = args.includeName || info.fieldName
      if (includeName in root) {
        return resolveCachedEntity(root[includeName], root._included)
      } else {
        let parentUrl = root._endpointUrl
        let includes = getIncludes({ info })
          // append each include with relationship name
          .split(',')
          .map(
            include =>
              // empty string means no nested includes. Just use includeName
              include || includeName,
          )
          .join(',')
        let data = await context.entityLoader.load({
          url: parentUrl,
          id: root.id,
          relationship: includeName,
          includes,
        })
        return data
      }
    }
  },
}

let mapObjOrArr = (v, fn) => (Array.isArray(v) ? v.map(fn) : fn(v, 0))
let unwrapType = type => (type.kind === 'ListType' ? type.type : type)
let capitalizeString = string =>
  string.charAt(0).toUpperCase() + string.slice(1)
let parseUrlTemplate = url => {
  let reg = new RegExp('{([a-zA-Z0-9]+):([a-zA-Z!]+)}', 'g')

  let parsed = []
  let result
  while ((result = reg.exec(url)) !== null) {
    let [fullMatch, varName, varType] = result
    parsed.push({
      varName,
      varType,
      start: result.index,
      end: result.index + fullMatch.length,
    })
  }

  return parsed.length ? parsed : null
}
let getUrlFieldVars = parsedTemplate => {
  return parsedTemplate
    .map(({ varName, varType }) => `${varName}:${varType}`)
    .join(', ')
}
let urlFromTemplate = ({ url, vars }) => {
  let parsedUrl = parseUrlTemplate(url)
  if (!parsedUrl) {
    return url
  }
  return parsedUrl
    .sort((a, b) => b.start - a.start)
    .reduce(
      (newUrl, { varName, start, end }) =>
        newUrl.slice(0, start) + vars[varName] + newUrl.slice(end),
      url,
    )
}

export const model = {
  types: `
    directive @model(url: String, queryName: String, onlyQueryFields: Boolean, filters: String) repeatable on OBJECT
    directive @updateable on FIELD_DEFINITION
    directive @notUpdateable on FIELD_DEFINITION
    directive @redirect on FIELD_DEFINITION

    type PageInfo {
      count: Int
      total: Int
      current: Int
      next: Int
      prev: Int
    }

    type Error {
      code: String
      title: String
      detail: String
    }
  `,
  sourceType: ({ astDefinition, name, args, addTypes, addResolvers }) => {
    let parsedUrl = parseUrlTemplate(args.url)
    let urlFieldVars = parsedUrl ? getUrlFieldVars(parsedUrl) : ''
    let fieldName = name.charAt(0).toLowerCase() + name.slice(1)
    let queryFieldName = args.queryName || fieldName
    let connectionTypeName = `Query${capitalizeString(
      queryFieldName,
    )}Connection`
    let edgeTypeName = `${queryFieldName}ConnectionEdge`
    let queryManyFieldName = `${queryFieldName}ByIds`
    let updateFieldName = `update${name}`
    let updateManyFieldName = `updateMany${name}`
    let createFieldName = `create${name}`
    let deleteFieldName = `delete${name}`
    let updateInputName = `Update${name}Input`
    let updateManyInputName = `UpdateMany${name}Input`
    let createInputName = `Create${name}Input`
    let deleteInputName = `Delete${name}Input`
    let updatePayloadName = `Update${name}Payload`
    let updateManyPayloadName = `UpdateMany${name}Payload`
    let createPayloadName = `Create${name}Payload`
    let deletePayloadName = `Delete${name}Payload`

    let inputFields = astDefinition.fields
      .filter(f => f.directives.find(({ name }) => name.value === 'updateable'))
      .map(({ name, type }) => `${name.value}: ${type.name.value}`)
      .join('\n')

    let orderFields = astDefinition.fields
      .filter(
        f =>
          f.directives.filter(({ name }) => name.value === 'relationship')
            .length === 0,
      )
      .reduce((acc, { name, type }) => {
        acc.push(name.value + '_ASC', name.value + '_DESC')
        return acc
      }, [])

    let relationshipFields = astDefinition.fields.filter(f =>
      f.directives.find(({ name }) => name.value === 'relationship'),
    )
    let nestedMutRelationshipFields = relationshipFields.filter(
      ({ directives }) => !directives.find(d => d.name.value === 'notUpdateable'),
    )
    let relationshipInputFields = nestedMutRelationshipFields
      .map(
        ({ name: relFieldName, type }) =>
          `${relFieldName.value}: ${capitalizeString(
            relFieldName.value,
          )}On${name}`,
      )
      .join('\n')
    let relationshipInputTypes = nestedMutRelationshipFields
      .map(({ name: relFieldName, type }) => {
        let relType = unwrapType(type).name.value
        let tName = `${capitalizeString(relFieldName.value)}On${name}`
        let createTName = `Create${relType}Input`
        let updateTName = `Update${relType}Input`
        return `
          input ${tName} {
            api_type: String
            create: [${createTName}]
            set: [${updateTName}]
            update: [${updateTName}]
            remove: [${updateTName}]
            destroy: [${updateTName}]
          }
        `
      })
      .join('\n')

    let createUpdateTypes = ''
    let createUpdateMuts = ''
    if (inputFields) {
      createUpdateTypes = `      
        input ${updateInputName} {
          id: String!
          ${inputFields}
          ${relationshipInputFields}
        }
        type ${updatePayloadName} {
          ${fieldName}: ${name}
          query: Query
          errors: [Error]
        }
        input ${updateManyInputName} {
          id: String!
          ${inputFields}
          ${relationshipInputFields}
        }
        type ${updateManyPayloadName} {
          ${fieldName}: [${name}]
          query: Query
          errors: [Error]
        }
        ${relationshipInputTypes}


        input ${createInputName} {
          id: String
          ${inputFields}
          ${relationshipInputFields}
        }
        type ${createPayloadName} {
          ${fieldName}: ${name}
          query: Query
          errors: [Error]
        }
      `
      createUpdateMuts = `
        ${updateFieldName}(input: ${updateInputName}): ${updatePayloadName}
        ${updateManyFieldName}(input: [${updateManyInputName}]): ${updateManyPayloadName}
        ${createFieldName}(input: ${createInputName}): ${createPayloadName}
      `
    }

    let queryTypes = `
      extend type Query {
        all${capitalizeString(queryFieldName)}(
          ${urlFieldVars}
          limit: Int, 
          cursor: String,
          orderBy: ${name}OrderFields,
          ${args.filters ? `filters: ${args.filters},` : ''}
        ): ${connectionTypeName}
        ${queryFieldName}(${urlFieldVars} id: String!): ${name} @redirect
        ${queryManyFieldName}(ids: [ID]!, limit: Int, cursor: String): ${connectionTypeName}
      }

      type ${edgeTypeName} {
        edge: ${name}
        cursor: Int
      }
      type ${connectionTypeName} {
        edges: [${edgeTypeName}]
        pageInfo: PageInfo
      }
    `

    addTypes(
      args.onlyQueryFields
        ? queryTypes
        : `
      ${queryTypes}

      enum ${name}OrderFields {
        ${orderFields}
      }

      extend type Mutation {
        ${createUpdateMuts}
        ${deleteFieldName}(input: ${deleteInputName}): ${deletePayloadName}
      }
      ${createUpdateTypes}

      input ${deleteInputName} {
        id: String!
      }
      type ${deletePayloadName} {
        message: String
        query: Query
      }
    `,
    )

    let formatNestedMutations = (muts, type) =>
      Object.entries(muts).reduce((muts, [k, v]) => {
        let mappings = {
          create: 'added_data',
          set: 'data',
          update: 'updated_data',
          remove: 'removed_data',
          destroy: 'deleted_data',
        }
        let newKey = mappings[k] || k
        return {
          ...muts,
          [newKey]: mapObjOrArr(v, ({ id, ...otherAttributes }) => {
            let [
              relationships,
              attributes,
            ] = separateRelationshipsFromAttributes(otherAttributes)
            return ({ type, attributes, relationships, id })
          }),
        }
      }, {})

    // takes a gql formatted entity and splits normal attributes
    // and relationships into [rels, attrs]
    let separateRelationshipsFromAttributes = attrs => {
      let relFieldNames = relationshipFields.map(({ name }) => name.value)

      let isRelationshipLike = v => {
        if (v === null) return false
        return ['create', 'set', 'update', 'remove', 'destroy'].some((k) => Object.keys(v).includes(k))
      }

      return Object.entries(attrs).reduce(
        ([rels, attrs], [k, v]) => {
          if (relFieldNames.includes(k) || isRelationshipLike(v)) {

            let {
              name: { value: relType },
            } = unwrapType(
              relationshipFields.find(({ name }) => name.value === k) || { name: { value: v.api_type || 'UNKNOWN_TYPE' } },
            )
            let { api_type, ...val } = v
            return [{ ...rels, [k]: formatNestedMutations(val, relType) }, attrs]
          } else {
            return [rels, { ...attrs, [k]: v }]
          }
        },
        [{}, {}],
      )
    }

    addResolvers({
      Query: {
        ['all' + capitalizeString(queryFieldName)]: (
          root,
          { limit, cursor, filters, orderBy, ...vars },
          context,
          info,
        ) => {
          let order = orderBy
            ? orderBy.replace('_DESC', ',desc').replace('_ASC', ',asc')
            : undefined
          let query = Object.assign({ limit, cursor, order }, filters)
          for (let [k, v] of Object.entries(query)) {
            if (v === null) delete query[k]
            if (Array.isArray(v)) query[k] = v.join(',')
          }
          let url = urlFromTemplate({ url: args.url, vars })
          return resolve({ url: url, query, info })
        },
        [queryFieldName]: (root, { id, ...vars }, context, info) => {
          let url = urlFromTemplate({ url: args.url, vars })
          return resolve({ url: urlJoin(url, id), info })
        },
        [queryManyFieldName]: (
          root,
          { limit, cursor, ids, ...vars },
          context,
          info,
        ) => {
          let url = urlFromTemplate({ url: args.url, vars })
          return resolve({
            url: urlJoin(url, 'batch'),
            query: { limit: limit || ids.length, cursor, ids: ids.join(',') },
            info,
          })
        },
      },
      Mutation: {
        ...(inputFields
          ? {
            [updateFieldName]: (root, { input: { id, ...otherAttrs } }) => {
              let [
                relationships,
                attributes,
              ] = separateRelationshipsFromAttributes(otherAttrs)
              return fetchEndpoint({
                url: urlJoin(args.url, id),
                options: {
                  method: 'PUT',
                  body: JSON.stringify({ data: { attributes, relationships } }),
                },
              }).then(data => ({ [fieldName]: data, query: {}, errors: data.errors }))
            },
            [updateManyFieldName]: (root, { input }) => {
              let formattedPayload = input.map(entity => {
                let { id, ...otherAttrs } = entity
                let [
                  relationships,
                  attributes,
                ] = separateRelationshipsFromAttributes(otherAttrs)
                return {
                  id,
                  attributes,
                  relationships,
                }
              })
              return fetchEndpoint({
                url: urlJoin(args.url, 'batch'),
                options: {
                  method: 'PUT',
                  body: JSON.stringify({ data: formattedPayload }),
                },
                isConnection: false,
              }).then(data => {
                return {
                  [fieldName]: data,
                  query: {},
                  errors: data.errors
                }
              })
            },
            [createFieldName]: (root, { input: attributes }) => {
              let [
                relationships,
                attrs,
              ] = separateRelationshipsFromAttributes(attributes)
              return fetchEndpoint({
                url: args.url,
                options: {
                  method: 'POST',
                  body: JSON.stringify({ data: { attributes: attrs, relationships } }),
                },
              }).then(data => ({ [fieldName]: data, errors: data.errors, query: {} }))
            }
          }
          : {}),
        [deleteFieldName]: (root, { input: { id } }) =>
          fetchEndpoint({
            url: urlJoin(args.url, id),
            options: { method: 'DELETE' },
          }).then(data => ({ ...data, query: {} })),
      },
    })
  },
  schemaType: args => { },
}

export const restoreable = {
  types: `
    directive @restoreable(url: String) repeatable on OBJECT
  `,
  sourceType: ({ astDefinition, name, args, addTypes, addResolvers }) => {
    let restoreFieldName = `restore${name}`
    let restoreInputName = `Restore${name}Input`

    addTypes(
      `
      extend type Mutation {
        ${restoreFieldName}(input: ${restoreInputName}): ${name}
      }

      input ${restoreInputName} {
        id: String!
      }
    `,
    )

    addResolvers({
      Mutation: {
        [restoreFieldName]: (root, { input: { id } }) =>
          fetchEndpoint({
            url: urlJoin(args.url, id, 'restore'),
            options: { method: 'POST' },
          }).then(data => ({ ...data, query: {} })),
      },
    })
  },
  schemaType: args => { },
}
