import React, {useEffect, useState} from 'react'
import {Toolbar} from '../../AppHandler'
import ToolbarGroup from '../../components/ToolbarGroup'
import ToolbarMegaTitle from '../../components/ToolbarMegaTitle'
import styled from 'styled-components'
import {DatePicker} from '@mui/lab'
import SelectMenu from '../../components/ToolbarDropDownMenu'
import {
  Typography,
  LinearProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  MenuItem,
  CircularProgress,
  TextField,
  InputLabel,
} from '@mui/material'
import {Space} from '../../components/Layout'
import {fetchAPI} from '../../schema/utils'
import moment from 'moment'
import {useQuery, useQueryClient} from 'react-query'
import _ from 'lodash'
import {ArrowForward} from '@mui/icons-material'
import queryString from 'query-string'
import ModifyQueryParams from '../../components/ModifyQueryParams'
import {UserSelectDynamic} from '../../components/UsersSelect'

let SummaryWrapper = styled.div`
  page-break-after: always;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
`

let TableWrapper = styled.div`
  margin-top: 2em;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

let TypeSelect = ({value = 'all', ...props}) => (
  <SelectMenu value={value} label="Type" {...props}>
    <MenuItem value="all">All Types</MenuItem>
    <MenuItem value="AR">AR</MenuItem>
    <MenuItem value="Complaint">Complaint</MenuItem>
    <MenuItem value="Complement">Complement</MenuItem>
    <MenuItem value="Delinquent">Delinquent</MenuItem>
    <MenuItem value="Other">Other</MenuItem>
    <MenuItem value="Sales">Sales</MenuItem>
    <MenuItem value="Service">Service</MenuItem>
  </SelectMenu>
)

let getData = async ({
  from,
  to,
  type,
  from_id,
  user_id,
  cc_id,
  technician_id,
}) => {
  let data = await fetchAPI({
    url: '/reports/feedbacks_by_type',
    query: {
      from: from.format('YYYY-MM-DD'),
      to: to.format('YYYY-MM-DD'),
      type: type,
      technician_id,
      from_id,
      user_id,
      cc_id,
    },
  })

  return JSON.parse(await data.text())
}

let munge = ({from, to, type, from_id, user_id, cc_id, technician_id}) => {
  from = !from ? moment().subtract(1, 'week') : moment(from)
  to = !to ? moment() : moment(to)
  type = ![
    'AR',
    'Complaint',
    'Complement',
    'Delinquent',
    'Other',
    'Sales',
    'Service',
  ].includes(type)
    ? ''
    : type
  return {from, to, type, from_id, user_id, cc_id, technician_id}
}

const FeedbackReport = () => {
  let [queryChanges, setQueryChanges] = useState(
    queryString.parse(location.search),
  )

  let {
    from,
    to,
    type,
    from_id,
    user_id,
    cc_id,
    technician_id,
  } = React.useMemo(() => munge(queryString.parse(location.search)), [
    location.search,
  ])

  const key = [
    'feedbacks',
    from.format(),
    to.format(),
    type,
    from_id,
    user_id,
    cc_id,
    technician_id,
  ]
  const {isLoading, error, data} = useQuery(
    key,
    () => getData({from, to, type, from_id, user_id, cc_id, technician_id}),
    {onError: err => console.log('error ', err)},
  )

  return (
    <>
      <ModifyQueryParams query={queryChanges} />
      <Toolbar>
        <ToolbarMegaTitle>Feedbacks by Type </ToolbarMegaTitle>
        <Space inline size={2} />
        <ToolbarGroup last>
          <DatePicker
            value={from}
            onChange={d =>
              setQueryChanges({...queryChanges, from: d.format('YYYY-MM-DD')})
            }
            renderInput={props => <TextField {...props} size="small" />}
          />
          <Space inline size={2} />
          <ArrowForward />
          <Space inline size={2} />
          <DatePicker
            value={to}
            onChange={d =>
              setQueryChanges({...queryChanges, to: d.format('YYYY-MM-DD')})
            }
            renderInput={props => <TextField {...props} size="small" />}
          />
          <Space inline size={2} />
          <TypeSelect
            value={!type ? 'all' : type}
            onChange={e =>
              setQueryChanges({...queryChanges, type: e.target.value})
            }
          />
        </ToolbarGroup>
      </Toolbar>
      <Toolbar>
        <ToolbarGroup first>
          <UserSelectDynamic
            selectedId={from_id}
            onChange={(e, u) =>
              setQueryChanges({
                ...queryChanges,
                from_id: u?.id || '',
              })
            }
            sx={{width: 200}}
            inputProps={{
              variant: 'outlined',
              size: 'small',
              margin: 'dense',
            }}
            label={'From'}
          />
          <Space inline size={2} />
          <UserSelectDynamic
            selectedId={user_id}
            onChange={(e, u) =>
              setQueryChanges({
                ...queryChanges,
                user_id: u?.id || '',
              })
            }
            sx={{width: 200}}
            inputProps={{
              variant: 'outlined',
              size: 'small',
              margin: 'dense',
            }}
            label={'User'}
          />
          <Space inline size={2} />
          <UserSelectDynamic
            selectedId={cc_id}
            onChange={(e, u) =>
              setQueryChanges({
                ...queryChanges,
                cc_id: u?.id || '',
              })
            }
            sx={{width: 200}}
            inputProps={{
              variant: 'outlined',
              size: 'small',
              margin: 'dense',
            }}
            label={'CC'}
          />
          <Space inline size={2} />
          <UserSelectDynamic
            selectedId={technician_id}
            onChange={(e, u) =>
              setQueryChanges({
                ...queryChanges,
                technician_id: u?.id || '',
              })
            }
            sx={{width: 200}}
            inputProps={{
              variant: 'outlined',
              size: 'small',
              margin: 'dense',
            }}
            label={'Technician'}
          />
        </ToolbarGroup>
      </Toolbar>

      {isLoading && <LinearProgress />}

      {error && <p>Error: {error.message}</p>}

      {data && (
        <SummaryWrapper>
          {data.map(({type: dataType, data}) => (
            <TableWrapper key={dataType}>
              <Typography variant="h6">{dataType}</Typography>
              <Table key={dataType} size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Created At</TableCell>
                    <TableCell>Customer</TableCell>
                    <TableCell>From</TableCell>
                    <TableCell>User</TableCell>
                    <TableCell>CC</TableCell>
                    <TableCell>Technician</TableCell>
                    <TableCell>Feedback</TableCell>
                    <TableCell>Resolution</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map(feedback => (
                    <TableRow key={feedback.id}>
                      <TableCell>
                        {moment(feedback.created_at).format('MM/DD/YY')}
                      </TableCell>
                      <TableCell>
                        {feedback.customer ? feedback.customer.name : <></>}
                      </TableCell>
                      <TableCell>
                        {feedback.from && feedback.from.first_name}{' '}
                        {feedback.from && feedback.from.last_name}
                      </TableCell>
                      <TableCell>
                        {feedback.user && feedback.user.first_name}{' '}
                        {feedback.user && feedback.user.last_name}
                      </TableCell>
                      <TableCell>
                        {feedback.cc ? (
                          <>
                            {feedback.cc.first_name} {feedback.cc.last_name}
                          </>
                        ) : (
                          <></>
                        )}
                      </TableCell>
                      <TableCell>
                        {feedback.technician && feedback.technician.first_name}{' '}
                        {feedback.technician && feedback.technician.last_name}
                      </TableCell>
                      <TableCell>{feedback.feedback}</TableCell>
                      <TableCell>
                        {feedback.closed_at && (
                          <>({moment(feedback.closed_at).format('MM/DD/YY')})</>
                        )}
                        &nbsp;&nbsp;&nbsp;
                        {feedback.resolution}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableWrapper>
          ))}
        </SummaryWrapper>
      )}
    </>
  )
}

export default FeedbackReport
