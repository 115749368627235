import React, {useState} from 'react'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'

import Button from '@mui/material/Button'
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  MenuItem,
  Select,
  TextField as MuiTextField,
  Switch,
  Tooltip,
} from '@mui/material'

import {FieldArray, Formik} from 'formik'
import LoadingButton from '../../components/LoadingButton'
import {useMutation, useQuery} from 'react-query'
import {gql, prgql} from '../../utils/graphql'
import {
  Add,
  ArrowDownward,
  Cancel,
  Check,
  ChevronRight,
  Close,
  Delete,
  Edit,
  ExpandMore,
  Label,
  Create,
} from '@mui/icons-material'
import {
  AutoCompleteField,
  Checkbox,
  DatePicker,
  PhoneNumber,
  Radio,
  StaticSelect,
  TextField,
} from '../../components/forms'
import {UserSelectDynamic} from '../../components/UsersSelect'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import {CitySelect} from '../../components/CitySelect'
import AccountSelect from '../../components/AccountSelect'
import {SalesstageSelect, SourceSelect} from '../../components/apiSelects'
import {
  COMMERCIAL,
  LARGE_COMMERCIAL,
  RESIDENTIAL,
} from '../../constants/CustomerTypes'
import {BILL, COD, PURCHASING_CARD} from '../../constants/TermCodes'
import {
  TaxitemSelectForm,
  TaxitemSelectUnified,
} from '../../components/TaxitemsSelect'
import {DocumentNode, validate} from 'graphql'
import theme from '../../styles/theme'
import moment from 'moment'
import {useCurrentUser} from '../../security/auth'
import {StopReasonSelectDynamic} from '../../components/StopReasonSelect'
import {fetchAPI} from '../../schema/utils'
import * as Yup from 'yup'
import STATES from '../../constants/States'
import colors from '../../styles/colors'
import styled from 'styled-components'

import {useAuth} from '../../security/auth'
import {
  mungeFormikRelationshipAssociate,
  mungeFormikRelationshipUpdate,
} from '../../utils'

const AccordionTitle = ({title}) => (
  <AccordionSummary expandIcon={<ExpandMore />}>
    <Typography variant="h6">{title}</Typography>
  </AccordionSummary>
)

const customerEditMutation: DocumentNode = gql`
  mutation UpdateCustomer($input: UpdateCustomersInput) {
    customer: updateCustomers(input: $input) {
      customers {
        id
        name
      }
    }
  }
`
const customerCreateMutation: DocumentNode = gql`
  mutation CreateCustomer($input: CreateCustomersInput) {
    customer: createCustomers(input: $input) {
      customers {
        id
        name
      }
    }
  }
`
const EMAIL_REGEX =
  '(?:[a-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:.[a-z0-9!#$%&\'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])).){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)])'

const StyledTooltip = styled(Tooltip)``

const StyledDialogTitle = styled(DialogTitle)`
  background-color: ${theme.palette.info.medium};
`
const StyledDialogContent = styled(DialogContent)`
  background-color: ${theme.palette.info.medium};
`
const StyledDialogActions = styled(DialogActions)`
  background-color: ${theme.palette.info.medium};
`

const EditDetails = function({
  open,
  customerId,
  onSubmit,
  onCancel,
  isLoading,
  isError,
  data,
  defaultValues = {},
}: {
  open: boolean,
  customerId?: string,
  onSubmit: Function,
  onCancel: Function,
  isLoading?: boolean,
  isError: boolean,
  data: any,
  defaultValues: object,
}) {
  const {currentUser} = useCurrentUser()

  const {status: canPauseStopCustomer} = useAuth('pause stop customer')

  const {mutateAsync: update} = useMutation((variables: {[key: string]: any}) =>
    prgql({
      query: customerEditMutation,
      variables: {input: {id: customerId, ...variables}},
    }),
  )

  const {mutateAsync: create} = useMutation((variables: {[key: string]: any}) =>
    prgql({
      query: customerCreateMutation,
      variables: {input: {...variables}},
    }),
  )

  // to assist with tabbing through photos and emails
  const [autofocusPhone, setAutofocusPhone] = useState(null)
  const [autofocusEmail, setAutofocusEmail] = useState(null)
  const [editingEmailNotes, setEditingEmailNotes] = useState(null)

  let c = data ? data.customers : {}
  if (!customerId) {
    c = {
      start_at: null,
      user_id: currentUser?.id,
      emails: [],
      phones: [],
      terms_id: '1',
      taxitem_id: '',
      customertype_id: '',
    }
  }
  const default_email = (c?.emails || []).filter(e => e.default_email).pop()
  const formikValues = {
    region: '',
    po_needed: false,
    key_access: false,
    invoice_needs_po_number: false,
    needs_service_confirmation: false,
    rain_sensitive: false,
    salesstages: [],
    ...defaultValues,
    ...c,
    default_email: default_email?.id,
  }

  const _mungeFormikUpdate = (values: {
    [k: string]: any,
  }): {[k: string]: any} => {
    const fields = [
      'name',
      'customertype_id',
      'attention',
      'batch_output',
      'start_at',
      'stop_at',
      'days_open',
      'time_open',
      'lat',
      'lng',
      'street1',
      'contract_start_at',
      'contract_stop_at',
      'services_paused',
      'po_needed',
      'key_access',
      'batch_output_attach_statement',
      'batch_output_attach_invoices',
      'batch_output_attach_workorders',
      'email_recorded_invoices',
      'email_recorded_invoices_attach_invoices',
      'email_recorded_invoices_attach_workorders',
      'attach_confirmation_photos_to_invoice',
      'statements_email_id',
      'recorded_invoices_email_id',
      'city_id',
      'region',
      'postcode',
      'notes',
      'scheduling_notes',
      'estimate_notes',
      'user_id',
      'sales_person_id',
      'contact',
      'days_open',
      'time_open',
      'terms_id',
      'taxable',
      'taxitem_id',
      'source_id',
      'stopreason_id',
      'account_id',
      'invoice_needs_po_number',
      'needs_service_confirmation',
      'rain_sensitive',
      'move_obstruction_for_service',
    ]

    const formValues = {}

    for (const prop of fields) {
      let val = values[prop]
      if (prop === 'region') {
        val = val.toUpperCase()
      }
      formValues[prop] = val
    }

    return formValues
  }

  const fixPhoneNumber = phone => {
    const number = phone.number?.replace('+1', '').replace(/\s+/g, '')
    return {...phone, number}
  }

  const yupRequiredWhenTrue = (
    schema: any,
    requiredProp: string,
    message: string,
  ) =>
    schema.when(requiredProp, ([other]: any, schema: any) =>
      other ? schema.required(message) : schema.nullable(),
    )

  const leadSchema = Yup.object().shape({
    // Lead
    name: Yup.string().required('Name required'),
    customertype_id: Yup.string().required('Customer type required'),
    source_id: Yup.string().required('Source required'),
    user_id: Yup.string().required('Employee contact required'),
    phones: Yup.array()
      .of(
        Yup.object().shape({
          label: Yup.string().required('Label required'),
          number: Yup.string().required('Number required'),
          phonetype: Yup.number()
            .required('Select a phone type')
            .oneOf([1, 2, 3]),
        }),
      )
      .when('emails', (emails: any, schema: any) =>
        !emails || emails.length === 0
          ? schema.min(1, 'Must have a phone or an email')
          : schema.min(0),
      ),
    emails: Yup.array().of(
      Yup.object().shape(
        {
          email: Yup.string()
            // Yup busted?
            // .matches(EMAIL_REGEX, "Doesn't look like an email")
            .required('Email required'),
          label: Yup.string().required('Email label required'),
        },
        'Not valid',
      ),
    ),

    // Client
    street1: yupRequiredWhenTrue(Yup.string(), 'start_at', 'Street required'),
    city_id: yupRequiredWhenTrue(Yup.string(), 'start_at', 'City required'),
    region: yupRequiredWhenTrue(Yup.string(), 'start_at', 'State required'),
    postcode: yupRequiredWhenTrue(
      Yup.string().min(5),
      'start_at',
      'Postcode required',
    ),
    terms_id: Yup.string().required('Terms Required'),
    taxitem_id: Yup.string().required('Tax Authority required'),
    taxable: yupRequiredWhenTrue(Yup.boolean(), 'start_at', 'Taxable required'),
  })

  return (
    <Dialog open={!!open} maxWidth="lg" fullWidth>
      {isLoading || isError ? (
        <>
          <DialogTitle>Edit Customer Details</DialogTitle>
          <DialogContent>
            {isLoading && (
              <Grid container spacing={2}>
                <CircularProgress />
              </Grid>
            )}
            {isError && 'Something broke, please refresh'}
          </DialogContent>
          <DialogActions>
            <Button onClick={onCancel}>Cancel</Button>
          </DialogActions>
        </>
      ) : (
        <>
          <Formik
            initialValues={formikValues}
            validationSchema={leadSchema}
            initialErrors={{name: 'Please fill out the form'}}
            onSubmit={async (values, {setSubmitting}) => {
              setSubmitting(true)
              const detailFields = _mungeFormikUpdate(values)
              const phoneProps = mungeFormikRelationshipUpdate({
                relname: 'phones',
                reldata: values.phones?.map(fixPhoneNumber),
                origData: c,
              })
              const emailProps = mungeFormikRelationshipUpdate({
                relname: 'emails',
                reldata: values.emails.map(e => ({
                  ...e,
                  default_email: values.default_email === e.id,
                })),
                origData: c,
              })
              const salesstagesProps = mungeFormikRelationshipAssociate({
                relname: 'salesstages',
                reldata: values.salesstages,
                origData: c,
              })

              let res
              if (customerId) {
                res = await update({
                  ...detailFields,
                  ...phoneProps,
                  ...emailProps,
                  ...salesstagesProps,
                })
              } else {
                res = await create({
                  ...detailFields,
                  ...phoneProps,
                  ...emailProps,
                  ...salesstagesProps,
                })
              }

              onSubmit(res.customer.customers.id)
            }}
          >
            {({
              isSubmitting,
              submitForm,
              isValid,
              values,
              setFieldValue,
              validateForm,
              errors,
            }) => {
              const accountExemptButCustomerTaxable =
                values.account_taxable !== undefined &&
                values.account_taxable === false &&
                values.taxable === true

              return (
                <>
                  <StyledDialogTitle>Edit Customer Details</StyledDialogTitle>
                  <StyledDialogContent>
                    <Accordion defaultExpanded>
                      <AccordionTitle title={'Details'} />
                      <AccordionDetails>
                        <Grid container spacing={2}>
                          <Grid
                            item
                            container
                            md={6}
                            spacing={1}
                            alignContent="flex-start"
                          >
                            <Grid item md={12}>
                              <AutoCompleteField
                                name="account_id"
                                as={AccountSelect}
                                selectedId={values?.account_id}
                                fullWidth
                                onSelectChange={i => {
                                  setFieldValue('account_taxable', i?.taxable)
                                }}
                                inputProps={{
                                  variant: 'filled',
                                  size: 'small',
                                }}
                              />
                            </Grid>
                            <Grid item md={12}>
                              <TextField
                                label="Name"
                                name="name"
                                variant="filled"
                                fullWidth
                                size="small"
                              />
                            </Grid>
                            <Grid item md={12}>
                              <TextField
                                label="Street"
                                name="street1"
                                variant="filled"
                                size="small"
                                fullWidth
                              />
                            </Grid>
                            <Grid
                              item
                              lg={6}
                              container
                              alignItems={'center'}
                              direction={'row'}
                            >
                              <AutoCompleteField
                                as={CitySelect}
                                name="city_id"
                                selectedId={values.city_id}
                                inputProps={{
                                  variant: 'filled',
                                  size: 'small',
                                }}
                              />
                              <IconButton
                                href="/old/database/items.php?item=City"
                                size="small"
                              >
                                <Create />
                              </IconButton>
                            </Grid>
                            <Grid item md={3}>
                              <StaticSelect
                                name={'region'}
                                label="State"
                                variant="filled"
                                size="small"
                                fullWidth
                                style={{marginTop: '0px'}}
                              >
                                {STATES.map(({id, name}) => (
                                  <MenuItem value={id}>{id}</MenuItem>
                                ))}
                              </StaticSelect>
                            </Grid>
                            <Grid item md={3}>
                              <TextField
                                label="Postcode"
                                name="postcode"
                                variant="filled"
                                size="small"
                                fullWidth
                                style={{marginTop: '0px'}}
                              />
                            </Grid>
                            <Grid item lg={12}>
                              <AutoCompleteField
                                as={SalesstageSelect}
                                name="salesstages.0.id"
                                selectedId={
                                  values.salesstages
                                    ? values.salesstages[0]?.id
                                    : ''
                                }
                                inputProps={{
                                  variant: 'filled',
                                  size: 'small',
                                }}
                              />
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            container
                            md={6}
                            spacing={1}
                            alignContent="flex-start"
                          >
                            <Grid item md={12}>
                              <StaticSelect
                                name={'customertype_id'}
                                label="Customer Type"
                                variant="filled"
                                size="small"
                                fullWidth
                                value={values.customertype_id}
                                onChange={e => {
                                  setFieldValue(
                                    'customertype_id',
                                    e.target.value,
                                  )
                                  if (e.target.value === RESIDENTIAL) {
                                    setFieldValue('terms_id', COD)
                                  }
                                }}
                              >
                                <MenuItem value={COMMERCIAL}>
                                  Commercial
                                </MenuItem>
                                <MenuItem value={RESIDENTIAL}>
                                  Residential
                                </MenuItem>
                                <MenuItem value={LARGE_COMMERCIAL}>
                                  Large Commercial
                                </MenuItem>
                              </StaticSelect>
                            </Grid>
                            <Grid item md={6}>
                              <AutoCompleteField
                                name={'source_id'}
                                as={SourceSelect}
                                selectedId={values.source_id}
                                fullWidth={true}
                                inputProps={{
                                  variant: 'filled',
                                  size: 'small',
                                }}
                              />
                            </Grid>
                            <Grid item md={6}>
                              <AutoCompleteField
                                name={'user_id'}
                                label="Employee Contact"
                                as={UserSelectDynamic}
                                selectedId={values.user_id}
                                fullWidth
                                inputProps={{
                                  variant: 'filled',
                                  size: 'small',
                                }}
                              />
                            </Grid>
                            <Grid item md={12}>
                              <AutoCompleteField
                                as={UserSelectDynamic}
                                name={'sales_person_id'}
                                label="Sales Person"
                                variant="filled"
                                selectedId={values.sales_person_id}
                                fullWidth
                                inputProps={{
                                  variant: 'filled',
                                  size: 'small',
                                }}
                              />
                            </Grid>
                            <Grid item md={12}>
                              <TextField
                                label="Estimate notes"
                                name="estimate_notes"
                                variant="filled"
                                fullWidth
                                size="small"
                                multiline
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion defaultExpanded id="section-contact">
                      <AccordionTitle title={'Contact Info'} />
                      <AccordionDetails>
                        <Grid container spacing={1}>
                          <Grid item md={12} direction="row" container>
                            <FieldArray name={'phones'}>
                              {({remove, push}) => (
                                <Grid
                                  item
                                  md={6}
                                  container
                                  spacing={1}
                                  direction="row"
                                  alignItems="center"
                                  alignContent="flex-start"
                                >
                                  <Grid item md={12}>
                                    <Typography variant="subtitle2">
                                      Phones
                                    </Typography>
                                  </Grid>
                                  {values.phones.map((p, i) => (
                                    <Grid
                                      item
                                      md={12}
                                      container
                                      spacing={1}
                                      direction="row"
                                      alignItems="center"
                                      alignContent="flex-start"
                                    >
                                      <Grid item md={3}>
                                        <TextField
                                          name={`phones.${i}.label`}
                                          label="Contact"
                                          variant="filled"
                                          size="small"
                                        />
                                      </Grid>
                                      <Grid item md={5}>
                                        <PhoneNumber
                                          name={`phones.${i}.number`}
                                          label="Number"
                                          variant="filled"
                                          size="small"
                                          fullWidth
                                          autoFocus={autofocusPhone === i}
                                        />
                                      </Grid>
                                      <Grid item md={2}>
                                        <TextField
                                          name={`phones.${i}.phonetype`}
                                          label="type"
                                          variant="filled"
                                          size="small"
                                          select
                                          fullWidth
                                        >
                                          <MenuItem value={1}>Voice</MenuItem>
                                          <MenuItem value={2}>Text</MenuItem>
                                          <MenuItem value={3}>Fax</MenuItem>
                                        </TextField>
                                      </Grid>
                                      <Grid item md={1}>
                                        <IconButton
                                          onClick={() => remove(i)}
                                          size="small"
                                        >
                                          <Delete
                                            style={{
                                              color: theme.palette.danger.main,
                                            }}
                                          />
                                        </IconButton>
                                      </Grid>
                                    </Grid>
                                  ))}
                                  <Grid item md={1}>
                                    <Button
                                      id="addNewPhone"
                                      variant="contained"
                                      color="secondary"
                                      onClick={() => {
                                        setAutofocusPhone(values.phones.length) // length === index of the item we're going to create. Paradoxical, I know
                                        push({
                                          id:
                                            'new' + (values.phones.length + 1),
                                          label: 'main',
                                          number: null,
                                          phonetype: 1,
                                        })
                                      }}
                                      size="small"
                                    >
                                      <Add />
                                    </Button>
                                  </Grid>
                                </Grid>
                              )}
                            </FieldArray>
                            <FieldArray name={'emails'}>
                              {({remove, push}) => (
                                <Grid
                                  item
                                  md={6}
                                  container
                                  spacing={1}
                                  direction="row"
                                  alignItems="center"
                                  alignContent="flex-start"
                                >
                                  <Grid item md={12}>
                                    <Typography variant="subtitle2">
                                      Emails
                                    </Typography>
                                  </Grid>
                                  {values.emails.map((p, i) => (
                                    <Grid
                                      item
                                      md={12}
                                      container
                                      spacing={1}
                                      direction="row"
                                      alignItems="center"
                                      alignContent="flex-start"
                                    >
                                      <Grid
                                        item
                                        md={4}
                                        style={{
                                          display: 'flex',
                                          flexDirection: 'row',
                                          alignItems: 'center',
                                        }}
                                      >
                                        <Tooltip title="Edit Notes" arrow>
                                          <IconButton
                                            size="small"
                                            style={{width: '25px'}}
                                            onClick={() =>
                                              setEditingEmailNotes(
                                                editingEmailNotes === null ||
                                                  editingEmailNotes !== i
                                                  ? i
                                                  : null,
                                              )
                                            }
                                          >
                                            {editingEmailNotes === i ? (
                                              <ExpandMore />
                                            ) : (
                                              <ChevronRight />
                                            )}
                                          </IconButton>
                                        </Tooltip>
                                        <TextField
                                          name={`emails.${i}.label`}
                                          label="Contact"
                                          variant="filled"
                                          size="small"
                                        />
                                      </Grid>
                                      <Grid item md={7}>
                                        <TextField
                                          name={`emails.${i}.email`}
                                          label="Email"
                                          variant="filled"
                                          size="small"
                                          fullWidth
                                          autoFocus={autofocusEmail === i}
                                        />
                                      </Grid>
                                      <Grid item md={1}>
                                        <IconButton
                                          onClick={() => remove(i)}
                                          size="small"
                                        >
                                          <Delete
                                            style={{
                                              color: theme.palette.danger.main,
                                            }}
                                          />
                                        </IconButton>
                                      </Grid>
                                      {editingEmailNotes === i && (
                                        <Grid item md={12}>
                                          <TextField
                                            name={`emails.${i}.notes`}
                                            label="Notes"
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                          />
                                        </Grid>
                                      )}
                                    </Grid>
                                  ))}
                                  <Grid item md={1}>
                                    <Button
                                      id="addNewEmail"
                                      variant="contained"
                                      color="secondary"
                                      onClick={() => {
                                        setAutofocusEmail(values.emails.length) // length === index of the item we're going to create. Paradoxical, I know
                                        push({
                                          id:
                                            'new' + (values.emails.length + 1),
                                          label: 'Main',
                                          email: '',
                                        })
                                      }}
                                      size="small"
                                    >
                                      <Add />
                                    </Button>
                                  </Grid>
                                </Grid>
                              )}
                            </FieldArray>
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion id="section-billing" defaultExpanded>
                      <AccordionTitle title={'Billing'} />
                      <AccordionDetails>
                        <Grid container spacing={2}>
                          <Grid item container md={6} spacing={1}>
                            <Grid item md={3}>
                              <StaticSelect
                                name="terms_id"
                                label="Terms"
                                variant="filled"
                                size="small"
                                fullWidth
                                value={values.terms_id}
                              >
                                <MenuItem value={COD}>COD</MenuItem>
                                <MenuItem value={BILL}>Bill</MenuItem>
                                <MenuItem value={PURCHASING_CARD}>
                                  Purchasing Card
                                </MenuItem>
                              </StaticSelect>
                            </Grid>

                            <Grid item md={9}>
                              {/* <TaxitemSelectForm */}
                              {/*   label="Taxitem" */}
                              {/*   name="taxitem_id" */}
                              {/*   selectedId={values.taxitem_id} */}
                              {/*   variant="filled" */}
                              {/*   fullWidth */}
                              {/*   clearable={false} */}
                              {/* /> */}
                              {/* <Grid item md={2}> */}
                              {/*   <FormControlLabel */}
                              {/*     control={<Checkbox name={'taxable'} />} */}
                              {/*     label="Taxable" */}
                              {/*   /> */}
                              {/* </Grid> */}
                              <TaxitemSelectUnified
                                label="Taxitem"
                                name="taxitem_unified"
                                taxitem_id={values.taxitem_id}
                                taxable={values.taxable}
                                onChange={e => {
                                  const [
                                    taxitem_id,
                                    taxable,
                                  ] = e.target.value.split('-')
                                  setFieldValue('taxitem_id', taxitem_id)
                                  setFieldValue('taxable', taxable !== 'exempt')
                                  setTimeout(() => validateForm(), 1)
                                }}
                                variant="filled"
                                size="small"
                                fullWidth
                              />
                            </Grid>
                          </Grid>
                          <Grid item container md={6}>
                            <Grid item md={12}>
                              <TextField
                                label="Billing Notes"
                                name="notes"
                                variant="filled"
                                size="small"
                                fullWidth
                                multiline
                              />
                            </Grid>
                          </Grid>
                          {/* attach_confirmation_photos_to_invoice */}
                          <Grid item container md={6}>
                            <Grid item md={6}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    name={
                                      'attach_confirmation_photos_to_invoice'
                                    }
                                    color={'secondary'}
                                  />
                                }
                                label="Attach WO Confirmation Photos To Invoice"
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion id="section-scheduling">
                      <AccordionTitle title={'Scheduling'} />
                      <AccordionDetails>
                        <Grid container spacing={2}>
                          <Grid item container md={6} spacing={1}>
                            <Grid item md={6}>
                              <TextField
                                label="Days Open"
                                name="days_open"
                                variant="filled"
                                size="small"
                                fullWidth
                              />
                            </Grid>
                            <Grid item md={6}>
                              <TextField
                                label="Time Open"
                                name="time_open"
                                variant="filled"
                                size="small"
                                fullWidth
                              />
                            </Grid>
                            <Grid
                              item
                              md={5}
                              container
                              direction="row"
                              alignItems="center"
                              style={{marginRight: '0.5em'}}
                            >
                              <Grid item md={11}>
                                <DatePicker
                                  name={'start_at'}
                                  label={'Start At'}
                                  inputVariant="filled"
                                  size="small"
                                  format="dddd, MMMM D, YYYY"
                                  fullWidth
                                />
                              </Grid>
                              {/* Hello, and welcome to the "I GIVE UP ON CSS" channel */}
                              <Grid item md={1} style={{marginLeft: '-0.75em'}}>
                                <IconButton
                                  onClick={() =>
                                    setFieldValue('start_at', null)
                                  }
                                  size="large"
                                >
                                  <Close />
                                </IconButton>
                              </Grid>
                            </Grid>
                            <Grid
                              item
                              md={5}
                              container
                              direction="row"
                              alignItems="center"
                            >
                              <Grid item md={11}>
                                <DatePicker
                                  name={'stop_at'}
                                  label={'Stop At'}
                                  inputVariant="filled"
                                  size="small"
                                  format="dddd, MMMM D, YYYY"
                                  fullWidth
                                  disabled={
                                    canPauseStopCustomer !== 'authorized'
                                  }
                                />
                              </Grid>
                              {/* Hello, and welcome to the "I GIVE UP ON CSS" channel */}
                              <Grid item md={1} style={{marginLeft: '-0.75em'}}>
                                <IconButton
                                  onClick={() => setFieldValue('stop_at', null)}
                                  size="large"
                                  disabled={
                                    canPauseStopCustomer !== 'authorized'
                                  }
                                >
                                  <Close />
                                </IconButton>
                              </Grid>
                            </Grid>
                            <Grid item md={8}>
                              {!!values.stop_at && (
                                <AutoCompleteField
                                  as={StopReasonSelectDynamic}
                                  name={'stopreason_id'}
                                  label="Stop Reason"
                                  selectedId={values.stopreason_id}
                                  fullWidth
                                  inputVariant="filled"
                                  inputMargin="dense"
                                  disabled={
                                    canPauseStopCustomer !== 'authorized'
                                  }
                                />
                              )}
                            </Grid>
                          </Grid>
                          <Grid item container md={6}>
                            <Grid item md={12}>
                              <TextField
                                label="Scheduling Notes"
                                name="scheduling_notes"
                                variant="filled"
                                size="small"
                                fullWidth
                                multiline
                              />
                            </Grid>

                            <Grid item md={6}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    name={'po_needed'}
                                    color={'secondary'}
                                  />
                                }
                                label="Paper Receipt / PO"
                              />
                            </Grid>

                            <Grid item md={6}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    name={'key_access'}
                                    color={'secondary'}
                                  />
                                }
                                label="Need Key Access"
                              />
                            </Grid>

                            <Grid item md={6}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    name={'invoice_needs_po_number'}
                                    color={'secondary'}
                                  />
                                }
                                label="Invoice needs PO Number"
                              />
                            </Grid>

                            <Grid item md={6}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    name={'needs_service_confirmation'}
                                    color={'secondary'}
                                  />
                                }
                                label="Needs confirmation before service"
                              />
                            </Grid>

                            <Grid item md={6}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    name={'rain_sensitive'}
                                    color={'secondary'}
                                  />
                                }
                                label="Rain sensitive"
                              />
                            </Grid>

                            <Grid item md={6}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    name={'move_obstruction_for_service'}
                                    color={'secondary'}
                                  />
                                }
                                label="Must move obstructions for service (shopping carts, etc)"
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion id="section-deprecated">
                      <AccordionTitle title={'Deprecated'} />
                      <AccordionDetails>
                        <Grid item md={12}>
                          <TextField
                            label="Contact"
                            name="contact"
                            variant="filled"
                            size="small"
                            style={{minWidth: '420px'}}
                          />
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                    <Grid container spacing={2}></Grid>
                  </StyledDialogContent>
                  <StyledDialogActions>
                    {accountExemptButCustomerTaxable && (
                      <Typography
                        style={{
                          background: theme.palette.alert.main,
                          padding: '.25em',
                          borderRadius: '10px',
                        }}
                      >
                        Warning: it looks like this customer is taxable but the
                        account is not. This is permitted, but unusual.
                      </Typography>
                    )}
                    <Button onClick={onCancel}>Cancel</Button>
                    <StyledTooltip
                      title={
                        <Typography>
                          {isValid && <Check />}
                          {Object.entries(errors)
                            .map(([key, val]) => {
                              if (Array.isArray(val)) {
                                const obj: any = [...val].pop()
                                if (!obj) {
                                  return
                                }
                                return `${Object.entries(obj)
                                  .map(([key, val]) => val)
                                  .join(',')}`
                              }

                              return `${val}`
                            })
                            .join(', ')}
                        </Typography>
                      }
                      arrow
                    >
                      <span id={'saveCustomer'}>
                        <LoadingButton
                          variant="contained"
                          color="primary"
                          onClick={submitForm}
                          loading={isSubmitting}
                          disabled={!isValid || isLoading}
                        >
                          Save
                        </LoadingButton>
                      </span>
                    </StyledTooltip>
                  </StyledDialogActions>
                </>
              )
            }}
          </Formik>
        </>
      )}
    </Dialog>
  )
}

export default EditDetails
