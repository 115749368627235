import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

class BethanyLogo extends React.Component {
  render() {
    return (
      <SvgIcon {...this.props} viewBox="0 0 312 315">
        <g
          id="Page-1"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g id="bethany-associates-logo-2-black" fill="#1D1C1B">
            <path
              d="M208.355,105.748 L104.377,105.748 L104.377,0.331 L0.148,0.331 L0.148,54.622 C19.721,59.933 197.508,113.707 199.197,314.619 L208.355,314.619 C265.544,314.619 311.904,272.522 311.904,220.595 L311.904,199.774 C311.904,147.845 265.544,105.748 208.355,105.748"
              fill="white"
            />
            <path
              d="M0.148,130.054 L0.148,216.589 L0.518,216.589 L0.518,220.595 C0.518,272.522 46.879,314.619 104.067,314.619 L127.694,314.619 C128.372,296.4 127.083,185.457 0.148,130.054"
              fill="white"
            />
          </g>
        </g>
      </SvgIcon>
    )
  }
}
export default BethanyLogo
