import React from 'react'
import styled from 'styled-components'
import {Link} from 'react-router-dom'

import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'

let MahTabs = props => (
  <Tabs indicatorColor="secondary" textColor="inherit" {...props} />
)

export let NavTabs = styled(MahTabs).attrs(() => ({
  classes: {indicator: 'indicator', flexContainer: 'container'},
}))`
  & .container {
    position: relative;
    z-index: 2;
  }

  & .indicator {
    height: 36px;
    bottom: ${(48 - 36) / 2}px;
    border-radius: 24px;
    z-index: 1;
  }
`

export let TabLink = styled(props => <Tab component={Link} {...props} />)`
  text-align: center;
  color: white;

  &:visited {
    color: white;
  }
`

export let TabSimple = styled(props => <Tab {...props} />)`
  text-align: center;
  color: white;

  &:visited {
    color: white;
  }
`
