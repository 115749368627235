import React, { useState } from 'react'
import url from 'url'
import { Switch } from 'react-router-dom'
import gql from 'graphql-tag'
import { useQuery, useMutation } from 'react-query'
import styled from 'styled-components'

import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'

import NavigationArrowBack from '@mui/icons-material/ArrowBack'
import EditIcon from '@mui/icons-material/Edit'

import CustomerDetail, { customerQuery } from './CustomerDetail'
import { Toolbar } from '../../AppHandler'
import { ToolbarIconMenu, DeleteMenuItem } from '../../components/Toolbar'
import ToolbarGroup from '../../components/ToolbarGroup'
import ToolbarMegaTitle from '../../components/ToolbarMegaTitle'
import theme from '../../styles/theme'
import { Route } from '../../pr-router'
import { NavigateBack, RedirectBack } from '../../components/Navigator'
import DeleteDialog from '../../components/DeleteDialog'
import { NavTabs, TabLink } from '../../components/NavTabs'
import CustomerEmail from './CustomerEmail'
import CustomerEstimates from './CustomerEstimates'
import CustomerWorkorders from './CustomerWorkorders'
import ActivityLog from './ActivityLog'
import CustomerTransactions from './CustomerTransactions'
import EditDetails from './EditDetails'
import { useQueryClient } from 'react-query'
import { prgql } from '../../utils/graphql'
import { ListItemIcon, MenuItem } from '@mui/material'
import { Pause, PlayArrow } from '@mui/icons-material'
import { useAuth } from '../../security/auth'
import Sales from './Sales'
import { Danger } from './Danger'
import Estimate, { NewEstimate } from './Estimate'

const sheet = {
  customerDetail: { minWidth: '920px', margin: '20px 120px' },
  toolbarStyle: {},
  toolbarGroup: {},
  toolbarTitle: { color: theme.palette.textInv1Color },
  toolbarIcon: { fill: theme.palette.textInv1Color },
  toolbarTab: {},
  detailsWrapper: { position: 'fixed', top: 0, right: 0, bottom: 0, left: 0 },
}

function CustomerTabs({ baseUrl, selectedTab, customerId }) {
  return (
    <NavTabs
      value={selectedTab || 'main'}
      variant="scrollable"
      scrollButtons
      allowScrollButtonsMobile
    >
      <TabLink to={baseUrl} label="Info" value="main" />
      <TabLink
        component="a"
        href={`/old/database/feedback.php?JobID=${customerId}`}
        label="Feedbacks"
        value="feedbacks"
      />
      <TabLink
        to={baseUrl + '/estimates'}
        label="Estimates"
        value="estimates"
      />
      <TabLink
        to={baseUrl + '/sales'}
        label="Sales"
        value="sales"
      />
      <TabLink
        component="a"
        href={`/old/database/documents.php?JobID=${customerId}`}
        label="Documents"
        value="documents"
      />
      <TabLink
        component="a"
        href={`/old/database/ar.php?JobID=${customerId}`}
        label="AR"
        value="ar"
      />
      <TabLink
        to={baseUrl + '/workorders/manage'}
        label="Workorders"
        value="workorders"
      />
      <TabLink
        to={`${baseUrl}/transactions`}
        label="Transactions"
        value="transactions"
      />
      <TabLink
        component="a"
        href={`/old/database/evaluations.php?JobID=${customerId}`}
        label="Evaluations"
        value="evaluations"
      />
      <TabLink to={baseUrl + '/activity'} label="activity" value="activity" />
      <TabLink to={baseUrl + '/danger'} label="danger" value="danger" />
    </NavTabs>
  )
}

let PAUSE_CUSTOMER_SERVICES = gql`
  mutation Pause($id: String!, $paused: Boolean!) {
    updateCustomers(input: {id: $id, services_paused: $paused}) {
      customers {
        id
        services_paused
      }
    }
  }
`

let deleteQuery = gql`
  mutation deleteCustomer($id: String!) {
    deleteCustomers(input: {id: $id}) {
      message
    }
  }
`
function DeleteCustomer({ id, showDelete, onCancel, onDelete }) {
  let { mutateAsync: deleteMut } = useMutation(() =>
    prgql({ query: deleteQuery, variables: { id } }),
  )
  return (
    <DeleteDialog
      title="Delete Customer?"
      message="Are you sure you want to delete this customer?"
      isOpen={showDelete}
      onCancel={onCancel}
      onConfirm={() => {
        deleteMut().then(onDelete)
      }}
    />
  )
}

let CustomerToolbarQuery = gql`
  query CustomerToolbarQuery($id: String!) {
    customers(id: $id) {
      id
      name
      customertype {
        id
        type
      }
    }
  }
`

let CustomerTypeTag = styled(Typography)`
  background-color: ${({ theme }) => theme.grey600};
  padding: 4px;
  border-radius: 4px;
  margin-left: 8px;
  opacity: 0.9;
`

function ScreenToolbar({ match }) {
  let [showDelete, setShowDelete] = useState(false)
  let [showEdit, setShowEdit] = useState(false)
  let [goBack, setGoBack] = useState(false)
  // let {data, isLoading: loading, isError: error} = useQuery(
  //   ['customer',  'toolbar'],
  //   () =>
  //     prgql({query: CustomerToolbarQuery, variables: {id: match.params.id}}),
  // )

  let { data, isLoading: loading, isError: error } = useQuery(
    ['customer', match.params.id],
    async () =>
      await prgql({ query: customerQuery, variables: { id: customerId } }),
  )

  let { mutateAsync: pauseServices, isLoading: pauseLoading } = useMutation(
    async () =>
      await prgql({
        query: PAUSE_CUSTOMER_SERVICES,
        variables: { id: customerId, paused: !data?.customers?.services_paused },
      }).then(() => queryClient.invalidateQueries('customer')),
  )

  let queryClient = useQueryClient()

  let customerId = match.params.id

  let { status: canPauseCustomer } = useAuth('pause stop customer')

  return (
    <>
      <Toolbar style={sheet.toolbarStyle}>
        <ToolbarGroup first={true}>
          <Route
            path={`${match.path}/(feedbacks|estimates|documents|ar|transactions)?`}
            exact
            render={() => (
              <NavigateBack
                defaultBack={url.resolve(match.url, '.')}
                marker="CustomerBack"
              >
                <IconButton size="large">
                  <NavigationArrowBack />
                </IconButton>
              </NavigateBack>
            )}
          />
        </ToolbarGroup>
        <ToolbarGroup>
          <ToolbarMegaTitle>
            {loading && !data ? (
              'Loading'
            ) : error ? (
              'Oops. Something went wrong.'
            ) : !data.customers ? (
              'Customer not found'
            ) : (
              <>
                {data.customers.name}
                {data.customers.customertype && (
                  <CustomerTypeTag display="inline">
                    {data.customers.customertype.type}
                  </CustomerTypeTag>
                )}
              </>
            )}
          </ToolbarMegaTitle>
        </ToolbarGroup>
        <ToolbarGroup last={true}>
          {!loading && !error && (
            <IconButton
              // href={`/old/database/editcustomer.php?JobID=${customerId}`}
              onClick={() => setShowEdit(true)}
              size="large"
            >
              <EditIcon />
            </IconButton>
          )}
          <ToolbarIconMenu>
            {!!data && canPauseCustomer === 'authorized' && (
              <MenuItem onClick={() => pauseServices()} disabled={pauseLoading}>
                {data.customers.services_paused ? (
                  <>
                    <ListItemIcon>
                      <PlayArrow />
                    </ListItemIcon>{' '}
                    Resume Services
                  </>
                ) : (
                  <>
                    <ListItemIcon>
                      <Pause />
                    </ListItemIcon>{' '}
                    Pause Services{' '}
                  </>
                )}
              </MenuItem>
            )}
            {/* <DeleteMenuItem */}
            {/*   onClick={() => { */}
            {/*     setShowDelete(true) */}
            {/*   }} */}
            {/* /> */}
          </ToolbarIconMenu>
          <DeleteCustomer
            id={customerId}
            showDelete={showDelete}
            onCancel={() => {
              setShowDelete(false)
            }}
            onDelete={setGoBack}
          />
          {goBack && (
            <RedirectBack
              defaultBack={url.resolve(match.url, '.')}
              marker="CustomerBack"
            />
          )}
        </ToolbarGroup>
      </Toolbar>
      <Toolbar>
        <Route
          path={`${match.path}/:tab?`}
          children={({ match: tabsMatch }) => (
            <CustomerTabs
              baseUrl={match.url}
              selectedTab={tabsMatch ? tabsMatch.params.tab : ''}
              customerId={customerId}
            />
          )}
        />
      </Toolbar>
      <EditDetails
        customerId={customerId}
        open={showEdit}
        onSubmit={() => {
          setShowEdit(false)
          queryClient.refetchQueries(['customer'])
        }}
        onCancel={() => setShowEdit(false)}
        data={data}
      />
    </>
  )
}

// const CustomerRoute = ({
//   match,
//   location,
//   customer,
//   updateCustomer,
//   savePhone,
//   createPhone,
//   saveCustomer,
//   deleteCustomer,
// }) => (
export default function CustomerRoute({ match, location }) {
  return (
    <>
      <ScreenToolbar match={match} location={location} />
      <Switch>
        <Route path={match.path} exact component={CustomerDetail} />
        {/* <Route */}
        {/*   path={`${match.path}/feedbacks`} */}
        {/*   exact */}
        {/*   component={CustomerFeedbacks} */}
        {/* /> */}
        {/* <Route */}
        {/*   path={`${match.path}/feedbacks/:feedbackId(\\d+)`} */}
        {/*   component={Feedback} */}
        {/* /> */}
        {/* <Route */}
        {/*   path={`${match.path}/feedbacks/add`} */}
        {/*   render={matchProps => <Feedback isNew={true} {...matchProps} />} */}
        {/* /> */}
        <Route
          path={`${match.path}/estimates`}
          exact
          component={CustomerEstimates}
        />
        <Route
          path={`${match.path}/estimates/:estimateId(\\d+)`}
          component={Estimate}
        />
        <Route path={`${match.path}/estimates/new`} component={NewEstimate} />
        {/* <Route */}
        {/*   path={`${match.path}/documents`} */}
        {/*   render={matchProps => ( */}
        {/*     <CustomerDocuments {...matchProps} customerId={match.params.id} /> */}
        {/*   )} */}
        {/* /> */}
        <Route path={`${match.path}/ar`} component={() => <div>AR</div>} />
        <Route
          path={`${match.path}/transactions/email`}
          component={CustomerEmail}
        />
        <Route
          path={`${match.path}/transactions`}
          component={CustomerTransactions}
        />
        {/* <Route */}
        {/*   path={`${match.path}/photos`} */}
        {/*   render={matchProps => ( */}
        {/*     <CustomerPhotos {...matchProps} customerId={match.params.id} /> */}
        {/*   )} */}
        {/* /> */}
        <Route
          path={`${match.path}/activity`}
          render={matchProps => (
            <ActivityLog {...matchProps} resourceId={match.params.id} />
          )}
        />
        <Route
          path={`${match.path}/workorders`}
          component={CustomerWorkorders}
        />
        <Route
          path={`${match.path}/sales`}
          component={Sales}
        />
        <Route
          path={`${match.path}/danger`}
          component={Danger}
        />
      </Switch>
    </>
  )
}
