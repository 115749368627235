import React from "react";
import { SvgIcon } from "@mui/material";

const Cone = ({ ...props }) => (
  <SvgIcon {...props} viewBox="0 0 978 952">
    <path d="m520.29 1.967c-1.615 4.413-14.747 7.465-31.096 7.465-16.88 0-30.314-4.884-31.217-8.822l-76.571 214.59h-0.018c0.037 17 48.295 30.526 107.54 30.526 59.236 0 107.54-13.084 107.54-29.72v-5e-3l-76.168-214.03z" />
    <path d="m897.21 691.27l-148.07-45.49 34.283 96.421h-3e-3c-0.099 46-131.98 82.708-294.66 82.708s-294.56-36.708-294.66-82.708h0.055l34.278-96.406-147.77 45.491c-87.209 26.845-108.2 73.362-39.651 104.09l282.49 126.62c89.181 39.971 241.99 40.025 331.12 0.096l282.62-126.61c68.645-30.751 47.467-77.333-40.027-104.21z" />
    <g fill="#fff">
      <path d="m596.45 216.19c-0.355 16.547-48.509 29.536-107.53 29.536-58.484 0-106.26-13.184-107.5-29.877l-131.75 370.29c-6e-3 -0.015-0.015-0.028-0.021-0.042l-55.505 156.1h-0.055c0.101 46 131.98 82.708 294.66 82.708s294.56-36.708 294.66-82.708h0.043l-187.01-526.01z" />
      <path d="m458 0.666l2e-3 -0.056-0.011 0.03c2e-3 9e-3 6e-3 0.018 9e-3 0.026z" />
    </g>
    <path d="m656 381.46c-4.879 11.841-21.986 22.058-50.218 29.847-31.25 8.622-72.838 13.37-117.1 13.37-44.28 0-85.87-4.857-117.11-13.678-27.324-7.715-44.232-17.743-49.711-29.335l-69.411 194.53h-0.043c0.08 35 106.41 66.121 236.53 66.121s236.53-29.668 236.53-66.207v-0.012l-69.461-194.64z" />
  </SvgIcon>
);

export default Cone;
