import React, {useState} from 'react'
import gql from 'graphql-tag'
import {Column} from 'react-virtualized'
import {Link} from 'react-router-dom'
import styled from 'styled-components'
import moment from 'moment'
import queryString from 'query-string'

import {DatePicker} from '@mui/lab'
import Button from '@mui/material/Button'
import Input from '@mui/material/Input'
import InputAdornment from '@mui/material/InputAdornment'
import TextField from '@mui/material/TextField'

import CalendarIcon from '@mui/icons-material/CalendarToday'

import TableList from '../../components/Table/TableList'
import {Body1, Subhead} from '../../components/Typography'
import AvatarBorder from '../../components/AvatarBorder'
import PaginationContainer from '../../components/PaginationContainer'
import {Toolbar} from '../../AppHandler'
import ToolbarGroup from '../../components/ToolbarGroup'
import ToolbarMegaTitle from '../../components/ToolbarMegaTitle'
import ModifyQueryParams from '../../components/ModifyQueryParams'
import {Space} from '../../components/Layout'

function DateInput({onClick, value, ...props}) {
  return (
    <Input
      onClick={onClick}
      value={value}
      startAdornment={
        <InputAdornment position="start">
          <CalendarIcon />
        </InputAdornment>
      }
      {...props}
    />
  )
}

function ScreenToolbar({to, onChange = () => {}}) {
  return (
    <Toolbar>
      <ToolbarGroup first>
        <ToolbarMegaTitle>Unpaid Attendances by User</ToolbarMegaTitle>
        <Space inline size={2} />
        <DatePicker
          label="As of"
          onChange={d => onChange({to: d.format('YYYY-MM-DD')})}
          inputFormat="MMMM D, YYYY"
          value={moment.utc(to)}
          renderInput={props => (
            <TextField
              {...props}
              variant="outlined"
              margin="dense"
              size="small"
            />
          )}
          autoOk
        />
      </ToolbarGroup>
      <ToolbarGroup></ToolbarGroup>
      <ToolbarGroup last>
        <Button>Do something</Button>
      </ToolbarGroup>
    </Toolbar>
  )
}

let DescWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0 16px;
  & > * {
    margin-right: 8px;
  }
`

let getInitials = ({first, last}) =>
  [...first.slice(0, 1), ...last.slice(0, 1)].join('').toUpperCase()

let DescCol = ({cellData, rowData}) => (
  <Link to={`/employees/${rowData.id}/attendance?attendanceType=pto`}>
    <DescWrapper>
      <AvatarBorder>
        {getInitials({first: cellData, last: rowData.last_name})}
      </AvatarBorder>
      <Subhead display="block">
        {cellData} {rowData.last_name}
      </Subhead>
    </DescWrapper>
  </Link>
)

function UnpaidAttendanceList({items = [], onInfiniteLoad, pageInfo}) {
  function getRowCount() {
    if (!pageInfo) {
      return 9999
    }
    return pageInfo.total
  }

  return (
    <TableList
      data={items}
      infinite
      loadMoreRows={onInfiniteLoad}
      rowCount={getRowCount()}
    >
      <Column
        dataKey="first_name"
        headerRenderer={({label}) => (
          <div style={{textAlign: 'center'}}>{label}</div>
        )}
        cellRenderer={data => <DescCol {...data} />}
        label="Name"
        flexGrow={1}
        width={0}
      />
      <Column
        dataKey="unpaid_pto_hours"
        headerRenderer={({label}) => (
          <div style={{textAlign: 'right'}}>{label}</div>
        )}
        cellRenderer={({cellData, rowData = {}}) => (
          <Body1 display="block" style={{textAlign: 'right'}}>
            {cellData}
          </Body1>
        )}
        label="Unpaid PTO Hours"
        flexGrow={1}
        width={1}
        maxWidth={250}
      />
      <Column
        label=""
        dataKey="id"
        disableSort={true}
        width={200}
        cellRenderer={({id}) => <Button onClick={() => {}}>Mark Paid</Button>}
      />
    </TableList>
  )
}
UnpaidAttendanceList.fragments = {
  unpaidAttendanceByUserListDetail: gql`
    fragment UnpaidAttendanceByUserListDetail on UnpaidAttendanceByUsers {
      id
      first_name
      last_name
      unpaid_pto_hours
    }
  `,
}

let unpaidQuery = gql`
  query UnapidAttendanceByUsers(
    $cursor: String
    $limit: Int = 50
    $to: String
  ) {
    unpaidAttendanceByUser: allUnpaidAttendanceByUsers(
      cursor: $cursor
      limit: $limit
      filters: {to: $to}
    ) {
      # query UnapidAttendanceByUsers {
      #   unpaidAttendanceByUser: allUnpaidAttendanceByUsers(
      #     cursor: "-1"
      #     limit: 30
      #   ) {
      edges {
        edge {
          ...UnpaidAttendanceByUserListDetail
        }
        cursor
      }
      pageInfo {
        next
        current
        prev
        count
        total
      }
    }
  }
  ${UnpaidAttendanceList.fragments.unpaidAttendanceByUserListDetail}
`

function UnpaidAttendance({location, match}) {
  let [queryChanges, setQueryChanges] = useState({})
  let {to = moment.utc().format('YYYY-MM-DD')} = queryString.parse(
    location.search,
  )

  return (
    <React.Fragment>
      <ScreenToolbar to={to} onChange={setQueryChanges} />
      <ModifyQueryParams query={queryChanges} />
      <PaginationContainer
        query={unpaidQuery}
        variables={{
          to,
          cursor: '-1',
        }}
        children={({unpaidAttendanceByUser, loadMore}) => (
          <UnpaidAttendanceList
            items={unpaidAttendanceByUser && unpaidAttendanceByUser.edges}
            pageInfo={unpaidAttendanceByUser && unpaidAttendanceByUser.pageInfo}
            onInfiniteLoad={loadMore}
          />
        )}
      />
    </React.Fragment>
  )
}

export default UnpaidAttendance
