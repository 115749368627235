// @flow
import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import {withRouter, Link, Redirect} from 'react-router-dom'

// Navigation provider listens to history
// - stores stack in local storage for access on app boot-up
//    - actually needs to be sessions storage
//    - diff navigators for diff windows
// - pushes, pops, replaces the stack based history actions
// - locations have keys for uniqueness
// - no need to make links pass state through location.state to track
//    can just check navigation stack length
// - jump to location based on key, pop everything in between off stack
//    - still pushing onto actual history stack
//    - (never pop, only user pops through browser)
// type ProviderProps = {
//   location: Location,
//   history: RouterHistory,
//   children?: React$Element<*>,
// }
// type LocationMarkers = {[key: string]: string}
// type LocationEntry = {
//   location: Location,
//   markers: LocationMarkers,
// }
// type NavigationStack = {
//   entries: Array<LocationEntry>,
//   index: number,
//   markers: LocationMarkers,
// }
const entryIsValid = (entry) => {
  if (
    typeof entry === 'object' &&
    typeof entry.location === 'object' &&
    typeof entry.markers === 'object'
  ) {
    return true
  } else {
    return false
  }
}
const stackIsValid = (stack) => {
  if (
    typeof stack === 'object' &&
    Array.isArray(stack.entries) &&
    typeof stack.markers === 'object'
  ) {
    let allValid = true
    stack.entries.forEach(entry => {
      if (!entryIsValid(entry)) allValid = false
    })
    return allValid
  } else {
    return false
  }
}
class NavigationProvider extends React.Component {
  constructor(...args) {
    super(...args)
    let scrollHandler = (scrollPos, key = '') => {
      this.replaceLocation({
        ...this.props.location,
        state: {
          ['scrollIndex' + key]: scrollPos,
        },
      })
    }
    this.onScroll = _.debounce(scrollHandler.bind(this), 100)
  }

  stack = {
    entries: [],
    index: -1,
    markers: {},
  }
  static childContextTypes = {
    markLocation: PropTypes.func,
    getMarker: PropTypes.func,
    deleteMarker: PropTypes.func,
    getLocation: PropTypes.func,
    getLocationByKey: PropTypes.func,
    updateScroll: PropTypes.func,
  }
  getChildContext() {
    return {
      markLocation: this.markLocation,
      getMarker: this.getMarker,
      deleteMarker: this.deleteMarker,
      getLocation: this.getLocation,
      getLocationByKey: this.getLocationByKey,
      updateScroll: this.onScroll,
    }
  }

  componentWillMount() {
    let {location} = this.props
    window.addEventListener('mousewheel', e => {
      // Registering a noop because of Chrome's stupid blocking of xhr
      // on mouse wheel event.
      // Suggested deltaY fix doesn't seem to work
      // Really just need to register a listener that Chrome thinks
      // MIGHT call e.preventDefault() by not using "passive" option
      // https://stackoverflow.com/questions/47524205/random-high-content-download-time-in-chrome/47684257#47684257
    })

    // hydrate the stack
    let hydratedStack = sessionStorage.getItem('navigatorStack')
    let parsed = hydratedStack != null && JSON.parse(hydratedStack)
    if (parsed && parsed.entries.length && stackIsValid(parsed)) {
      this.stack = parsed
    } else {
      this.pushLocation(location)
    }
  }

  componentDidMount() {
    // window.addEventListener('scroll', this.onWindowScroll)
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location !== this.props.location) {
      this.updateStack(nextProps.location, nextProps.history)
    }
  }

  componentWillUnmount() {
    // window.removeEventListener('scroll', this.onWindowScroll)
  }

  onWindowScroll = () => this.onScroll(window.scrollY)
  updateStack = (location, {action}) => {
    if (action === 'PUSH') this.pushLocation(location)
    if (action === 'POP') this.shiftLocation(location)
    if (action === 'REPLACE') this.replaceLocation(location)
  }
  pushLocation = (location) => {
    let removed = this.stack.entries.splice(
      this.stack.index + 1,
      this.stack.entries.length,
      {
        location,
        markers: {...this.stack.markers},
      },
    )
    this.stack.index = this.stack.entries.length - 1
    // clear any markers for removed locations
    removed.forEach(({location: {key}}) => {
      Object.keys(this.stack.markers).forEach(marker => {
        if (this.stack.markers[marker] === key) this.deleteMarker(marker)
      })
    })
    this.storeStack()
  }
  shiftLocation = (location) => {
    this.stack.index = location.key ? this.findLocationIndex(location.key) : -1
    if (this.stack.index === -1) this.pushLocation(location)
    this.stack.markers = this.stack.entries[this.stack.index].markers

    this.storeStack()
  }
  replaceLocation = (location) => {
    this.stack.entries.splice(this.stack.index, 1, {
      location,
      markers: {
        ...this.stack.entries[this.stack.index].markers,
      },
    })
    this.storeStack()
  }
  markLocation = (marker, location) => {
    this.stack.markers[marker] = location.key || ''
    this.storeStack()
  }
  getMarker = (marker) => {
    let markerKey = this.stack.markers[marker]
    return this.getLocationByKey(markerKey)
  }
  deleteMarker = (marker) => {
    delete this.stack.markers[marker]
    this.storeStack()
  }
  getLocation = (relativeIndex) => {
    let entry = this.stack.entries[this.stack.index + relativeIndex]
    return entry && entry.location
  }
  getLocationByKey = (key) => {
    let index = key ? this.findLocationIndex(key) : -1
    if (index > -1) {
      return this.stack.entries[index].location
    } else {
      return undefined
    }
  }
  storeStack = () => {
    sessionStorage.setItem('navigatorStack', JSON.stringify(this.stack))
  }
  findLocationIndex = (key) =>
    this.stack.entries.findIndex(entry => entry.location.key === key)
  render() {
    return this.props.children
  }
}
NavigationProvider = withRouter(NavigationProvider)
export {NavigationProvider}

class NavigateBack extends React.Component {
  // props: {
  //   location: Location,
  //   history: History,
  //   defaultBack: string | LocationShape,
  //   marker: string,
  //   replace: boolean,
  //   onGoBack: () => void,
  //   children?: React$Element<*>,
  // }
  static contextTypes = {
    markLocation: PropTypes.func,
    getMarker: PropTypes.func,
    deleteMarker: PropTypes.func,
    getLocation: PropTypes.func,
  }
  static defaultProps = {
    onGoBack: () => {},
  }
  componentWillMount() {
    let {marker} = this.props
    let prevLocation = this.context.getLocation(-1)
    if (!this.context.getMarker(marker) && prevLocation) {
      this.context.markLocation(marker, prevLocation)
    }
  }

  onGoBack = () => {
    this.context.deleteMarker(this.props.marker)
    this.props.onGoBack()
  }
  render() {
    let {defaultBack, marker, replace, children} = this.props
    let backLocation = this.context.getMarker(marker)
    return (
      <Link
        replace={replace}
        to={backLocation || defaultBack}
        onClick={this.onGoBack}
        children={children}
      />
    )
  }
}
NavigateBack = withRouter(NavigateBack)
export {NavigateBack}

class RedirectBack extends React.Component {
  // props: {
  //   location: Location,
  //   history: History,
  //   defaultBack: string | LocationShape,
  //   marker: string,
  //   push: boolean,
  // }
  static contextTypes = {
    markLocation: PropTypes.func,
    getMarker: PropTypes.func,
    deleteMarker: PropTypes.func,
    getLocation: PropTypes.func,
  }
  componentWillMount() {
    let {marker} = this.props
    let prevLocation = this.context.getLocation(-1)
    if (!this.context.getMarker(marker) && prevLocation) {
      this.context.markLocation(marker, prevLocation)
    }
  }
  componentWillUnmount() {
    this.onGoBack()
  }

  onGoBack = () => {
    this.context.deleteMarker(this.props.marker)
  }
  render() {
    let {defaultBack, marker, push} = this.props
    let backLocation = this.context.getMarker(marker)
    return <Redirect push={push} to={backLocation || defaultBack} />
  }
}
RedirectBack = withRouter(RedirectBack)
export {RedirectBack}

// type RestoreScrollProps = {
//   location: Location,
//   children: ({
//     scrollPos: number,
//     updateScroll: (scrollPos: number, key?: string) => void,
//   }) => React$Element<*>,
//   key?: string,
//   windowScroll?: boolean,
// }
class RestoreScroll extends React.Component {
  static contextTypes = {
    updateScroll: PropTypes.func,
    getLocationByKey: PropTypes.func,
  }
  componentWillMount() {
    let {location, key = ''} = this.props
    let {key: locationKey} = location
    let savedLoc = this.context.getLocationByKey(locationKey)
    let state = savedLoc && savedLoc.state
    this.scrollPos = state && state['scrollIndex' + key]
  }

  componentDidMount() {
    let {windowScroll} = this.props.location.state || {}
    if (windowScroll) {
      window.scrollTo(0, this.scrollPos)
    }
  }

  render() {
    let {children} = this.props
    return children
      ? children({
          scrollPos: this.scrollPos,
          updateScroll: this.context.updateScroll,
        })
      : null
  }
}
RestoreScroll = withRouter(RestoreScroll)
export {RestoreScroll}
