import React, {useEffect, useState} from 'react'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'

import Button from '@mui/material/Button'
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'

import theme from '../styles/theme'
import {Formik} from 'formik'
import {Checkbox, TextField, Radio} from '../components/forms'
import LoadingButton from '../components/LoadingButton'
import Delete from '@mui/icons-material/Delete'
import Add from '@mui/icons-material/Add'
import {split} from 'lodash'
import * as Yup from 'yup'

let _mungFormikCreate = values => {
  let creates = Object.values(
    Object.entries(values).reduce((carry, [key, value]) => {
      let [id, field] =
        key === 'default-email' ? value.split('-') : key.split('-')
      if (
        id.indexOf('new') === -1 ||
        (field === 'email' && field === 'label' && value === '')
      ) {
        return carry
      }

      if (!carry[id]) {
        carry[id] = {}
      }

      if (field !== 'delete' && field !== 'default_email') {
        carry[id][field] = field === 'email' ? value.trim() : value
        carry[id]['default_email'] = carry[id]['default_email'] || false
      }

      if (field !== 'delete' && field === 'default_email') {
        carry[id]['default_email'] = value === id + '-default_email'
      }

      return carry
    }, {}),
  )

  creates = creates.filter(v => v.email !== '')

  return creates
}

let _mungFormikUpdate = values => {
  return Object.values(
    Object.entries(values).reduce((carry, [key, value]) => {
      let [id, field] =
        key === 'default-email' ? value.split('-') : key.split('-')
      if (field === 'delete' || isNaN(Number(id))) {
        return carry
      }

      if (!carry[id]) {
        carry[id] = {}
      }

      if (field !== 'delete' && field !== 'default_email') {
        carry[id].id = id
        carry[id][field] = field === 'email' ? value.trim() : value
        carry[id]['default_email'] = carry[id]['default_email'] || false
      }

      if (field !== 'delete' && field === 'default_email') {
        carry[id].id = id
        carry[id]['default_email'] = true
      }

      return carry
    }, {}),
  )
}

let _mungFormikDelete = values => {
  return Object.values(
    Object.entries(values).reduce((carry, [key, value]) => {
      let [id, field] = key.split('-')
      if (field !== 'delete') {
        return carry
      }

      if (!carry[id]) {
        carry[id] = {}
      }

      carry[id].id = id
      carry[id].type = 'emails'
      return carry
    }, {}),
  )
}

let EditEmails = function({emails, setEditEmails, onSubmit}) {
  let [newId, setNewId] = useState(1)

  let formikValues =
    emails &&
    emails.reduce((carry, email) => {
      if (!carry['default-email']) {
        carry['default-email'] = ''
      }
      carry['default-email'] = email.default_email
        ? email.id + '-default_email'
        : carry['default-email']
      carry[email.id + '-email'] = email.email
      carry[email.id + '-label'] = email.label
      carry[email.id + '-notes'] = email.notes
      return carry
    }, {})

  let _removeEmail = (id, formValues, setFormValues) => {
    let newEmails = emails.filter(email => email.id !== id)
    setEditEmails(newEmails)
    let set = {...formValues}
    if (id.indexOf('new') === -1) {
      set[id + '-delete'] = true
    }
    delete set[id + '-default_email']
    delete set[id + '-email']
    delete set[id + '-label']
    delete set[id + '-notes']

    if (set['default-email'] === id + '-default_email') {
      let someOtherKey = Object.keys(set)
        .filter(v => 'default-email')
        .filter(v => {
          let [key, field] = v.split('-')
          return field !== 'delete'
        })
        .map(v => v.split('-')[0])
        .filter((v, i, s) => s.indexOf(v) === i)
        .pop()

      set['default-email'] = someOtherKey + '-default_email'
    }

    setFormValues(set)
  }

  let _addEmail = (newid, formValues, setFormValues) => {
    let e = [...emails]
    e.push({
      id: 'new' + newid,
      email: '',
      label: '',
    })
    setEditEmails(e)
    let set = {...formValues}
    set['new' + newid + '-email'] = ''
    set['new' + newid + '-label'] = ''
    setFormValues(set)
    return {
      email: 'new' + newid + '-email',
      label: 'new' + newid + '-label',
      notes: 'new' + newid + '-notes',
    }
  }

  let _checkValid = values => {
    let valid = true
    Object.entries(values).forEach(([key, value]) => {
      let [id, field] =
        key === 'default-email' ? value.split('-') : key.split('-')
      if ((field === 'email' || field === 'label') && value === '') {
        valid = false
      }
    })

    return valid
  }

  // Yes, this is every bit as insane as you think it is. But I am NOT remaking this component!
  let _createFormikSchema = values => {
    let genSchema = {}
    for (let key in values) {
      if (key.includes('notes')) {
        continue
      }
      genSchema[key] = Yup.string()
        .min(1)
        .required('Required')
    }
    return genSchema
  }
  let _setTouched = values => {
    let genSchema = {}
    for (let key in values) {
      if (key.includes('notes')) {
        continue
      }
      genSchema[key] = true
    }
    return genSchema
  }
  let _setErrors = values => {
    let genSchema = {}
    for (let key in values) {
      if (key.includes('notes')) {
        continue
      }
      genSchema[key] = values[key] === ''
    }
    return genSchema
  }

  let schema = Yup.object().shape(_createFormikSchema(formikValues))

  return (
    <Dialog open={!!emails} maxWidth="xl">
      <Formik
        initialValues={formikValues}
        validationSchema={schema}
        initialTouched={_setTouched(formikValues)}
        initialErrors={_setErrors(formikValues)}
        onSubmit={(values, {setSubmitting}) => {
          setSubmitting(true)
          onSubmit(
            _mungFormikUpdate(values),
            _mungFormikDelete(values),
            _mungFormikCreate(values),
          )
        }}
      >
        {({
          isSubmitting,
          submitForm,
          values,
          setValues,
          validateForm,
          setTouched,
        }) => {
          schema = Yup.object().shape(_createFormikSchema(values))

          return <>
            <DialogTitle>Edit Emails</DialogTitle>
            <DialogContent>
              {emails &&
                emails
                  .filter(email => !email.delete)
                  .map(email => (
                    <Grid
                      container
                      spacing={1}
                      key={email.id}
                      direction="column"
                      alignItems="flex-end"
                      style={{marginBottom: '1em'}}
                    >
                      <Grid item md={12}>
                        <Typography variant="subtitle">Default</Typography>
                        {/* <Checkbox name={email.id + '-default_email'} /> */}
                        <Radio
                          color="secondary"
                          value={email.id + '-default_email'}
                          name="default-email"
                        />
                        <TextField
                          variant="filled"
                          label="Label"
                          style={{width: '200px', marginRight: '2em'}}
                          name={email.id + '-label'}
                        />
                        <TextField
                          variant="filled"
                          label="Email"
                          style={{width: '500px'}}
                          name={email.id + '-email'}
                        />
                        <IconButton
                          onClick={() =>
                            _removeEmail(email.id, values, setValues)
                          }
                          size="large">
                          <Delete
                            style={{color: theme.palette.danger.main}}
                          />
                        </IconButton>
                      </Grid>
                      <Grid item md={7} style={{marginRight: '50px'}}>
                        <TextField
                          variant="outlined"
                          label="Notes"
                          size="small"
                          name={email.id + '-notes'}
                          style={{width: '500px'}}
                        />
                      </Grid>
                    </Grid>
                  ))}
              <Grid container spacing={6}>
                <Grid item>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      let {email, label} = _addEmail(newId, values, setValues)
                      setNewId(newId + 1)
                      setTimeout(() => {
                        setTouched(
                          _setTouched({
                            ...values,
                            [email]: true,
                            [label]: true,
                          }),
                        )
                        validateForm()
                      }, 1)
                    }}
                    id="addNewEmail"
                  >
                    <Add />
                  </Button>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setEditEmails(null)}>Cancel</Button>
              <LoadingButton
                variant="contained"
                color="primary"
                onClick={submitForm}
                loading={isSubmitting}
                disabled={!_checkValid(values)}
              >
                Save
              </LoadingButton>
            </DialogActions>
          </>;
        }}
      </Formik>
    </Dialog>
  );
}

export default EditEmails
