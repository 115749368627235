import React, {useState} from 'react'
import styled from 'styled-components'
import gql from 'graphql-tag'
import {useMutation} from '@apollo/react-hooks'
import moment from 'moment'

import ImageListItem from '@mui/material/ImageListItem'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import Divider from '@mui/material/Divider'
import MuiLink from '@mui/material/Link'
import Grid from '@mui/material/Grid'

import Description from '@mui/icons-material/Description'
import Home from '@mui/icons-material/Home'
import Store from '@mui/icons-material/Store'
import Phone from '@mui/icons-material/Phone'
import Email from '@mui/icons-material/Email'
import History from '@mui/icons-material/History'
import Money from '@mui/icons-material/Money'
import Receipt from '@mui/icons-material/Receipt'
import Chat from '@mui/icons-material/Chat'
import Person from '@mui/icons-material/Person'
import Edit from '@mui/icons-material/Edit'
import DateRange from '@mui/icons-material/DateRange'
import MenuItem from '@mui/material/MenuItem'
import {Box, Button, Chip, Fab, Tooltip} from '@mui/material'

import ToolbarGroup from '../../components/ToolbarGroup'
import SelectMenu from '../../components/ToolbarDropDownMenu'

import {Body1, Caption} from '../../components/Typography'
import theme from '../../styles/theme'
import {formatMoneyStandard} from '../../utils/moneyFormatter'
import {Space} from '../../components/Layout'
import Stat from '../../components/Stat'
import {TableCell, TableHead, useMediaQuery} from '@mui/material'
import {
  DetailToolbar,
  ToolbarTitle,
  ToolbarRight,
  DividerlessDetailToolbar,
} from '../../components/Toolbar'
import {TableBody, TableRow, Table} from '@mui/material'
import CustomerAvatar from '../../components/CustomerAvatar'
import {Link} from 'react-router-dom'
import {
  Add,
  FilterList,
  Gavel,
  Handshake,
  InsertDriveFile,
  NewReleases,
  PersonAdd,
} from '@mui/icons-material'
import EditEmails from '../../components/EditEmails'
import {fetchAPI} from '../../schema/utils'
import colors from '../../styles/colors'
import EditPhones from '../../components/EditPhones'
import {formatPhone} from '../../utils'
import {DocumentDialog} from '../Customer/CustomerDetail'
import EditCommunications from '../../components/EditCommunications'
import {prgql} from '../../utils/graphql'
import {useQuery, useQueryClient} from 'react-query'
import EditDetailsCustomer from '../Customer/EditDetails'
import {fragments} from '../Contact/Contact'
import PillBox from '../../components/PillBox'
import {EditDetails as EditContacts} from '../Contact/EditDetails'
import DetailHeader from '../../components/DetailHeader'

let Content = styled(Grid)`
  padding: 25px 50px;
`
let InfoRow = styled.div`
  display: flex;
  align-items: top;
  margin-bottom: 16px;
`
let InfoColumn = styled.div`
  flex: 1 1 auto;
`
let RowIcon = styled.div`
  color: ${({theme}) => theme.muiTheme.palette.action.active};
  margin-right: 16px;
`
let ClickRowIcon = styled.div`
  color: ${({theme}) => theme.palette.secondary.main};
  margin-right: 16px;
  &:hover {
    color: ${({theme}) => theme.palette.secondary.light};
  }
`
let LargeIcon = styled.div`
  color: ${({theme}) => theme.muiTheme.palette.action.disabled};
  width: 66%;
  height: 66%;
`
let PhotoWrapper = styled(ImageListItem)`
  width: 300px;
  height: 300px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
`
let PhotoFallback = styled.div`
  width: 300px;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({theme}) => theme.grey300};
`
let DetailsWrapper = styled(Paper)`
  padding: 16px;
`
let DescriptionIcon = styled(RowIcon).attrs(() => ({as: Description}))``
let HomeIcon = styled(RowIcon).attrs(() => ({as: Home}))``
let HomeIconLarge = styled(LargeIcon).attrs(() => ({as: Home}))``
let StoreIcon = styled(RowIcon).attrs(() => ({as: Store}))``
let StoreIconLarge = styled(LargeIcon).attrs(() => ({as: Store}))``
let PhoneIcon = styled(RowIcon).attrs(() => ({as: Phone}))``
let EmailIcon = styled(RowIcon).attrs(() => ({as: Email}))``
let HistoryIcon = styled(RowIcon).attrs(() => ({as: History}))``
let MoneyIcon = styled(RowIcon).attrs(() => ({as: Money}))``
let ReceiptIcon = styled(RowIcon).attrs(() => ({as: Receipt}))``
let ChatIcon = styled(RowIcon).attrs(() => ({as: Chat}))``
let PersonIcon = styled(RowIcon).attrs(() => ({as: Person}))``
let DateRangeIcon = styled(RowIcon).attrs(() => ({as: DateRange}))``
let ContractIcon = styled(RowIcon).attrs(() => ({as: Gavel}))``
let EditIcon = styled(ClickRowIcon).attrs(() => ({as: Edit}))``
let InvoiceNeedsPOIcon = styled(RowIcon).attrs(() => ({as: NewReleases}))``
let AddIcon = styled(ClickRowIcon).attrs(() => ({as: Add}))``
const sheet = {
  container: {},
  photoColumn: {flex: 'none', width: '33%', marginRight: '15px'},
  rowIcon: {color: theme.iconGrey, marginRight: '5px'},
  photoIcon: {
    color: 'white',
    backgroundColor: theme.iconGrey,
    padding: '9px',
    borderRadius: '100%',
    fontSize: '179px',
  },
  image: {width: '100%'},
  imageCell: {maxWidth: '33.33333333%', flex: '1 1 99px', paddingRight: '4px'},
  thumbRow: {display: 'flex', flexWrap: 'wrap', marginRight: '-4px'},
  imageSecondary: {maxWidth: '100%', cursor: 'pointer'},
  label: {fontWeight: 'bold'},
  cellContainer: {display: 'flex', alignItems: 'center', padding: '5px 20px'},
  photoWrapper: {marginRight: '20px'},
}

let StatusSelect = ({value = 'all', ...props}) => (
  <SelectMenu value={value} label="Status" {...props}>
    <MenuItem value="all">All Statuses</MenuItem>
    <MenuItem value="active">Active</MenuItem>
    <MenuItem value="inactive">Inactive</MenuItem>
  </SelectMenu>
)

function InlineLabel({label, children}) {
  return (
    <div style={{paddingRight: '20px'}}>
      <Caption style={{width: '45px', marginRight: '8px'}}>{label}</Caption>
      {children}
    </div>
  )
}

function ScheduleDateText({date, route}) {
  return (
    <Link
      to={`/schedules/daily?date=${moment
        .utc(date)
        .format('YYYY-MM-DD')}&routes=${route}`}
    >
      {moment.utc(date).format('dddd, M/D/YYYY')}
    </Link>
  )
}

let CustomersWrapper = styled(Paper)`
  padding: 0 16px 16px 16px;
`
let CustomersContent = styled.div`
  display: flex;
  justify-content: center;
`
function getCustomerAge({start_at, stop_at}) {
  if (!start_at) return 'none'
  return moment(start_at).from(stop_at || moment(), true)
}

export let customerQuery = gql`
  query AccountQuery($id: String!) {
    customers: accounts(id: $id) {
      id
      name
      attention
      customertype_id
      customertype {
        id
        type
      }
      active
      batch_output
      start_at
      stop_at
      street1
      contract_start_at
      contract_stop_at
      street2
      parent_id

      batch_output_attach_statement
      batch_output_attach_invoices
      batch_output_attach_workorders
      batch_output_statement_as_excel
      email_recorded_invoices
      email_recorded_invoices_attach_invoices
      email_recorded_invoices_attach_workorders

      statements_email_id
      recorded_invoices_email_id

      invoice_needs_po_number

      city_id
      city {
        id
        name
      }
      region
      postcode
      notes
      user_id
      user {
        id
        first_name
        last_name
      }
      sales_person_id
      sales_person {
        id
        first_name
        last_name
      }
      contact
      phone
      other_phone
      fax
      phones {
        id
        number
        label
        phonetype
      }
      emails {
        id
        email
        label
        default_email
        cc_statements
        cc_recorded_invoices
        notes
      }
      terms {
        id
        terms
      }
      taxable
      taxitem_id
      taxitem {
        id
        name
      }
      source_id
      source {
        id
        source
      }
      # stopreason {
      #   id
      #   reason
      # }
      balances {
        id
        balance
      }
      sales: salesstat {
        id
        sales
      }
      customers {
        id
        name
        region
        balances {
          id
          balance
        }
        customertype {
          id
          type
        }
        start_at
        stop_at
        street1
        city {
          id
          name
        }
        next_routerun {
          id
          run_at
          route_id
        }
        last_routerun {
          id
          run_at
          route_id
        }
      }
      documents {
        id
        name
        important
        description
        account_id
      }
      parent_id
      parent_account {
        id
        name
      }
      child_accounts {
        id
        name
        balances {
          id
          balance
        }
        street1
        region
        city {
          id
          name
        }
      }
      contacts {
        ...ContactDetail
      }
      salesstages {
        id
        name
      }
    }
  }
  ${fragments.contact}
`

function getContractDateDescription({contract_start_at, contract_stop_at}) {
  if (!contract_start_at) contract_start_at = undefined
  if (!contract_stop_at) contract_stop_at = undefined
  let isBetween = moment
    .utc()
    .isBetween(moment.utc(contract_start_at), moment.utc(contract_stop_at))
  if (isBetween)
    return `Contract started ${moment
      .utc(contract_start_at)
      .format(dateFmt)} (${moment
      .utc(contract_stop_at)
      .diff(moment.utc(contract_start_at), 'days')} days left) `
  if (contract_start_at && moment.utc().isBefore(contract_start_at))
    return `Contract starts ${moment
      .utc(contract_start_at)
      .format(dateFmt)} (in ${moment
      .utc(contract_start_at)
      .diff(moment.utc(), 'days')} days) `
  if (contract_stop_at && moment.utc().isAfter(contract_stop_at))
    return `Contract ended ${moment.utc(contract_stop_at).format(dateFmt)}`
  return null
}

let dateFmt = 'MMM. D, YYYY'

let renderDetails = c => {
  return (
    <>
      {c.user ? (
        <InfoRow>
          <ChatIcon />
          <Typography variant="body1">
            Relates to {c.user.first_name} {c.user.last_name}
            {c.sales_person &&
              `; Sold by ${c.sales_person.first_name} ${c.sales_person.last_name}`}
          </Typography>
        </InfoRow>
      ) : null}

      <InfoRow>
        <MoneyIcon />
        <Typography variant="body1">
          {formatMoneyStandard(c.sales ? c.sales.sales : 0)} in sales over{' '}
          {getCustomerAge(c)}
        </Typography>
      </InfoRow>

      {getContractDateDescription({
        contract_start_at: c.contract_start_at,
        contract_stop_at: c.contract_stop_at,
      }) ? (
        <InfoRow>
          <ContractIcon />
          <Typography variant="body1">
            {getContractDateDescription({
              contract_start_at: c.contract_start_at,
              contract_stop_at: c.contract_stop_at,
            })}
          </Typography>
        </InfoRow>
      ) : null}

      <InfoRow>
        <ReceiptIcon />
        <InfoColumn>
          <InlineLabel label="Tax">
            <Typography variant="body1" display="inline">
              {c.taxable ? 'Taxable' : 'Exempt'} (
              {c.taxitem ? c.taxitem.name : 'No tax authority set'})
            </Typography>
          </InlineLabel>

          <InlineLabel label="Statement batch">
            <Typography variant="body1" display="inline">
              {c.batch_output ? c.batch_output : 'None'}
            </Typography>
          </InlineLabel>
        </InfoColumn>
      </InfoRow>

      <InfoRow>
        {c.invoice_needs_po_number ? (
          <>
            <InvoiceNeedsPOIcon style={{color: 'red', fontWeight: 'bold'}} />
            <InfoColumn>
              <Typography
                variant="body1"
                display="inline"
                style={{color: 'red', fontWeight: 'bold'}}
              >
                Invoice needs PO Number
              </Typography>
            </InfoColumn>
          </>
        ) : (
          <></>
        )}
      </InfoRow>
    </>
  )
}

let renderComms = c => {
  return (
    <Grid container>
      <Grid item md={6}>
        <InfoRow>
          <ReceiptIcon />
          <InfoColumn>
            <InlineLabel label="Statement batch">
              <Typography variant="body1" display="inline">
                {c.batch_output ? c.batch_output : 'None'}
              </Typography>
            </InlineLabel>
            <InlineLabel label="Attach Statement">
              <Typography variant="body1" display="inline">
                {c.batch_output_attach_statement ? 'YES' : '----'}
              </Typography>
            </InlineLabel>
            <InlineLabel label="Attach Invoices">
              <Typography variant="body1" display="inline">
                {c.batch_output_attach_invoices ? 'YES' : '----'}
              </Typography>
            </InlineLabel>
            <InlineLabel label="Attach Work Orders">
              <Typography variant="body1" display="inline">
                {c.batch_output_attach_workorders ? 'YES' : '----'}
              </Typography>
            </InlineLabel>
            <InlineLabel label="Format">
              <Typography variant="body1" display="inline">
                {c.batch_output_statement_as_excel ? 'Excel' : 'PDF'}
              </Typography>
            </InlineLabel>
            <InlineLabel label="Email">
              <Typography variant="body1" display="inline">
                {c.statements_email_id &&
                c.emails.filter(e => e.id === c.statements_email_id).length
                  ? c.emails.filter(e => e.id === c.statements_email_id)[0]
                      .email
                  : 'None'}
              </Typography>
            </InlineLabel>
            <InlineLabel label="CC">
              <Typography variant="body1" display="inline">
                {c.emails
                  .filter(e => e.cc_statements)
                  .map(e => e.email)
                  .join(', ')}
              </Typography>
            </InlineLabel>
          </InfoColumn>
        </InfoRow>
      </Grid>
      <Grid item md={6}>
        <InfoRow>
          <ReceiptIcon />
          <InfoColumn>
            <InlineLabel label="Recorded Invoices">
              <Typography variant="body1" display="inline">
                {c.email_recorded_invoices ? 'Email' : 'None'}
              </Typography>
            </InlineLabel>
            <InlineLabel label="Attach Invoices">
              <Typography variant="body1" display="inline">
                {c.email_recorded_invoices_attach_invoices}
              </Typography>
            </InlineLabel>
            <InlineLabel label="Attach Work Orders">
              <Typography variant="body1" display="inline">
                {c.email_recorded_invoices_attach_workorders ? 'YES' : '----'}
              </Typography>
            </InlineLabel>
            <InlineLabel label="Email">
              <Typography variant="body1" display="inline">
                {c.recorded_invoices_email_id &&
                c.emails.filter(e => e.id === c.recorded_invoices_email_id)
                  .length
                  ? c.emails.filter(
                      e => e.id === c.recorded_invoices_email_id,
                    )[0].email
                  : 'None'}
              </Typography>
            </InlineLabel>
            <InlineLabel label="CC">
              <Typography variant="body1" display="inline">
                {c.emails
                  .filter(e => e.cc_recorded_invoices)
                  .map(e => e.email)
                  .join(', ')}
              </Typography>
            </InlineLabel>
          </InfoColumn>
        </InfoRow>
      </Grid>
    </Grid>
  )
}

let updateEmailMutation = gql`
  mutation emails(
    $id: String!
    $emails: [UpdateEmailsInput]!
    $createEmails: [CreateEmailsInput]!
  ) {
    updateAccounts(
      input: {id: $id, emails: {update: $emails, create: $createEmails}}
    ) {
      accounts {
        name
        emails {
          id
          email
          default_email
        }
      }
    }
  }
`

let updatePhoneMutation = gql`
  mutation phones(
    $id: String!
    $phones: [UpdatePhonesInput]!
    $createPhones: [CreatePhonesInput]!
  ) {
    updateAccounts(
      input: {id: $id, phones: {update: $phones, create: $createPhones}}
    ) {
      accounts {
        name
        phones {
          id
          number
        }
      }
    }
  }
`

let updateCommsMutation = gql`
  mutation comms(
    $id: String!
    $batch_output: String!
    $batch_output_attach_statement: Boolean!
    $batch_output_attach_invoices: Boolean!
    $batch_output_attach_workorders: Boolean!
    $batch_output_statement_as_excel: Boolean!
    $email_recorded_invoices: Boolean!
    $email_recorded_invoices_attach_invoices: String!
    $email_recorded_invoices_attach_workorders: Boolean!
    $statements_email_id: ID
    $recorded_invoices_email_id: ID
    $emails: [UpdateEmailsInput]
  ) {
    updateAccounts(
      input: {
        id: $id
        batch_output: $batch_output
        batch_output_attach_statement: $batch_output_attach_statement
        batch_output_attach_invoices: $batch_output_attach_invoices
        batch_output_attach_workorders: $batch_output_attach_workorders
        batch_output_statement_as_excel: $batch_output_statement_as_excel
        email_recorded_invoices: $email_recorded_invoices
        email_recorded_invoices_attach_invoices: $email_recorded_invoices_attach_invoices
        email_recorded_invoices_attach_workorders: $email_recorded_invoices_attach_workorders
        statements_email_id: $statements_email_id
        recorded_invoices_email_id: $recorded_invoices_email_id
        emails: {update: $emails}
      }
    ) {
      accounts {
        id
        batch_output
        batch_output_attach_statement
        batch_output_attach_invoices
        batch_output_attach_workorders
        batch_output_statement_as_excel
        email_recorded_invoices
        email_recorded_invoices_attach_invoices
        email_recorded_invoices_attach_workorders
        statements_email_id
        recorded_invoices_email_id
        emails {
          id
          cc_statements
          cc_recorded_invoices
        }
      }
    }
  }
`

let setDefaultEmailsMutation = gql`
  mutation defaults(
    $id: String!
    $statements_email_id: ID!
    $recorded_invoices_email_id: ID!
  ) {
    updateAccounts(
      input: {
        id: $id
        statements_email_id: $statements_email_id
        recorded_invoices_email_id: $recorded_invoices_email_id
      }
    ) {
      accounts {
        id
        statements_email_id
        recorded_invoices_email_id
      }
    }
  }
`

function DetailsPanel({customer: c, active, refetch}) {
  let [editEmails, setEditEmails] = useState(false)
  let [editPhones, setEditPhones] = useState(false)
  let [updateEmails] = useMutation(updateEmailMutation)
  let [updatePhones] = useMutation(updatePhoneMutation)
  let [updateComms] = useMutation(updateCommsMutation)
  let [setDefaultEmails] = useMutation(setDefaultEmailsMutation)
  let [showContactsEdit, setShowContactsEdit] = useState(false)
  let [showEmailEdit, setShowEmailEdit] = useState(false)
  let [showPhoneEdit, setShowPhoneEdit] = useState(false)
  let [showDocument, setShowDocument] = useState(false)
  let [showComms, setShowComms] = useState(false)
  let [showCommsEdit, setShowCommsEdit] = useState(false)
  let [showContacts, setShowContacts] = useState(false)
  let [showContactData, setShowContactData] = useState(false)
  let [contactEditMode, setContactEditMode] = useState('view')
  let [selectedDocument, setSelectedDocument] = useState({})

  function _documentPreview(doc) {
    let response = fetchAPI({
      url: `/documents/${doc.id}.pdf`,
      options: {method: 'get'},
    })
    response.then(async res => {
      let blob = await res.blob()
      let data_url = URL.createObjectURL(blob)
      document.querySelector('#documentPreview').src = data_url
    })
  }

  return (
    <DetailsWrapper>
      {c.parent_account ? (
        <DetailHeader>
          <Typography variant="body2" color="textSecondary" display="inline">
            Parent Account:
          </Typography>
          <Space size={1} inline />
          <Link
            variant="h6"
            display="inline"
            to={`/accounts/${c.parent_account.id}`}
          >
            <Typography variant="h6" display="inline">
              {c.parent_account.name}
            </Typography>
          </Link>
        </DetailHeader>
      ) : null}
      {!c.active ? (
        <DetailHeader warning={!c.active}>
          <Typography variant="body1" color="inherit">
            Stopped
            {/* on {moment(c.stop_at).format(dateFmt)}
            {c.stopreason ? '; ' + c.stopreason.reason : null} */}
          </Typography>
        </DetailHeader>
      ) : null}
      <div style={{display: 'flex'}}>
        <div style={{marginRight: '20px', flex: 1}}>
          <InfoRow>
            {c.customertype.id === '2' ? <HomeIcon /> : <StoreIcon />}
            <InfoColumn>
              <a
                href={`https://maps.google.com/maps?q=${c.street1}+${c.city &&
                  c.city.name}+${c.region}+${c.postcode}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Typography variant="subtitle2">{c.street1}</Typography>
                {c.street2 && (
                  <Typography variant="subtitle2">{c.street2}</Typography>
                )}
                <Typography variant="body2">
                  {c.city && c.city.name}, {c.region} {c.postcode}{' '}
                </Typography>
                {c.attention && (
                  <Typography variant="body2">Attn: {c.attention}</Typography>
                )}
              </a>
            </InfoColumn>
          </InfoRow>
          <InfoRow
            onMouseEnter={() => setShowPhoneEdit(true)}
            onMouseLeave={() => setShowPhoneEdit(false)}
          >
            {showPhoneEdit ? (
              <EditIcon onClick={() => setEditPhones(c.phones)} />
            ) : (
              <PhoneIcon />
            )}
            <InfoColumn>
              {c.phones.map(p => (
                <InlineLabel label={p.label}>
                  <Typography variant="body2" display="inline">
                    <a href={'tel:' + p.number.replace(/\D/g, '')}>
                      {formatPhone(p.number)}
                    </a>
                  </Typography>
                </InlineLabel>
              ))}
            </InfoColumn>
          </InfoRow>
          <InfoRow
            onMouseEnter={() => setShowEmailEdit(true)}
            onMouseLeave={() => setShowEmailEdit(false)}
          >
            {showEmailEdit ? (
              <EditIcon onClick={() => setEditEmails(c.emails)} />
            ) : (
              <EmailIcon />
            )}
            <InfoColumn as="ul" style={{listStyle: 'none'}}>
              {(c.emails || []).map(email => (
                <li
                  key={email.email}
                  style={{display: 'flex', justifyContent: 'left'}}
                >
                  <Typography
                    variant="body1"
                    style={{
                      fontWeight: email.default_email ? 'bold' : undefined,
                    }}
                  >
                    {email.label ? email.label + ': ' : ''}
                  </Typography>
                  <a href={'mailto:' + email.email} style={{marginLeft: '1em'}}>
                    <Typography variant="body1">{email.email}</Typography>
                  </a>
                </li>
              ))}
            </InfoColumn>
          </InfoRow>
          <InfoRow>
            <PersonIcon />
            <InfoColumn>
              <Typography>{c.contact}</Typography>
            </InfoColumn>
          </InfoRow>
          {c.days_open || c.time_open ? (
            <InfoRow>
              <DateRangeIcon />
              <InfoColumn>
                <Typography>
                  Open{c.days_open ? ' ' + c.days_open : null}
                  {c.time_open ? ` @ ${c.time_open}` : null}
                </Typography>
              </InfoColumn>
            </InfoRow>
          ) : null}
        </div>
        <div style={{flex: 2}}>{renderDetails(c)}</div>
      </div>
      {c.salesstages?.length ? (
        <InfoRow>
          <Handshake color="action" />
          <InfoColumn>
            {c.salesstages.map(s => (
              <Chip
                key={s.id}
                style={{marginLeft: '.5em'}}
                label={
                  <Box display={'flex'} alignItems={'center'}>
                    <Space inline />
                    {s.name}
                  </Box>
                }
              />
            ))}
          </InfoColumn>
        </InfoRow>
      ) : (
        <></>
      )}
      <InfoRow>
        <DescriptionIcon />
        <Typography>{c.notes}</Typography>
      </InfoRow>
      <Space size={2} />
      <Divider />
      <Space size={2} />
      <Stat.Label
        onMouseEnter={() => setShowContactsEdit(true)}
        onMouseLeave={() => setShowContactsEdit(false)}
        style={{display: 'flex', alignItems: 'center'}}
        id={'Contacts'}
      >
        Contacts
        {showContactsEdit ? (
          <AddIcon
            style={{height: '1em'}}
            onClick={() => {
              setShowContactData({accounts: [{id: c.id}]})
              setShowContacts(true)
              setContactEditMode('edit')
            }}
            id={'editContacts'}
          />
        ) : (
          <></>
        )}
      </Stat.Label>
      <Box gap={1} display={'flex'} flexWrap={'wrap'}>
        {c.contacts?.map(con => (
          <PillBox
            key={con.id}
            direction="column"
            hover
            onClick={() => {
              setShowContactData(con)
              setShowContacts(true)
            }}
          >
            <Typography variant="body2" style={{fontStyle: 'italic'}}>
              {con.first_name} {con.last_name}
            </Typography>
            {con.emails.map(e => (
              <Typography variant="body2" key={e.id}>
                {e.email}
              </Typography>
            ))}
            {con.phones.map(e => (
              <Typography variant="body2" key={e.id}>
                {formatPhone(e.number)}
              </Typography>
            ))}
          </PillBox>
        ))}
      </Box>
      <Space size={2} />
      <Divider />
      <Space size={2} />
      <Stat.Label
        onMouseEnter={() => setShowCommsEdit(true)}
        onMouseLeave={() => setShowCommsEdit(false)}
        style={{display: 'flex', alignItems: 'center'}}
        id={'Communications'}
      >
        Communications
        {showCommsEdit ? (
          <EditIcon
            style={{height: '1em'}}
            onClick={() => setShowComms(true)}
            id={'editCommunications'}
          />
        ) : (
          <></>
        )}
      </Stat.Label>
      {renderComms(c)}
      <Divider />
      <Space size={2} />
      <div
        style={{
          display: 'flex',
          flexFlow: 'row wrap',
          justifyContent: 'space-around',
          marginBottom: '8px',
        }}
      >
        {c.balances ? (
          <>
            <Stat>
              <Stat.Label>Balance</Stat.Label>
              <Stat.Number>
                {formatMoneyStandard(c.balances.balance)}
              </Stat.Number>
            </Stat>
            <Space size={2} inline />
          </>
        ) : null}
        <Stat></Stat>
        <Stat></Stat>
      </div>
      {c.documents && !![...c.documents].filter(d => d.important).length && (
        <>
          <Divider />
          <Space size={2} />
          <div
            style={{
              display: 'flex',
              flexFlow: 'row wrap',
              // justifyContent: 'space-around',
              marginBottom: '8px',
            }}
          >
            <Space size={2} inline />
            {[...c.documents]
              .filter(d => d.important)
              .map(d => (
                <>
                  <Typography
                    component="a"
                    variant={'subtitle1'}
                    onClick={() => {
                      setSelectedDocument(d)
                      setShowDocument(true)
                      _documentPreview(d)
                    }}
                    style={{
                      cursor: 'pointer',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <InsertDriveFile />
                    {d.description}
                  </Typography>
                  <Space size={2} inline />
                </>
              ))}
          </div>
        </>
      )}
      <EditEmails
        emails={editEmails}
        setEditEmails={setEditEmails}
        onSubmit={(e, d, n) => {
          let body = JSON.stringify({
            data: d,
          })
          updateEmails({
            variables: {id: c.id, emails: e, deleteEmails: d, createEmails: n},
          }).then(async res => {
            setEditEmails(null)
            if (!(c.emails && c.emails.length)) {
              let emails =
                res.data &&
                res.data.updateAccounts &&
                res.data.updateAccounts.accounts
                  ? res.data.updateAccounts.accounts.emails
                  : []
              let defaultEmail = emails.filter(e => e.default_email).pop()
              if (!defaultEmail) defaultEmail = emails.pop()
              if (defaultEmail) {
                await setDefaultEmails({
                  variables: {
                    id: c.id,
                    statements_email_id: defaultEmail.id,
                    recorded_invoices_email_id: defaultEmail.id,
                  },
                })
              }
            }
            refetch()
          })
          fetchAPI({
            url: '/emails/batch',
            options: {
              method: 'DELETE',
              body,
            },
          })
        }}
      />
      <EditPhones
        phones={editPhones}
        setEditPhones={setEditPhones}
        onSubmit={(e, d, n) => {
          let body = JSON.stringify({
            data: d,
          })
          updatePhones({
            variables: {id: c.id, phones: e, deletePhones: d, createPhones: n},
          }).then(() => {
            setEditPhones(null)
            refetch()
          })
          fetchAPI({
            url: '/phones/batch',
            options: {
              method: 'DELETE',
              body,
            },
          })
        }}
      />
      <DocumentDialog
        isOpen={showDocument}
        document={selectedDocument}
        onClose={() => setShowDocument(false)}
        account
      />
      <EditCommunications
        customer={c}
        open={showComms}
        onSubmit={async values => {
          await updateComms({variables: values})
          setShowComms(false)
          refetch()
        }}
        onCancel={() => setShowComms(false)}
      />
      <EditContacts
        key={showContactData?.id}
        open={showContacts}
        contactId={showContactData?.id}
        data={showContactData}
        mode={contactEditMode}
        onSubmit={() => {
          setContactEditMode('view')
          setShowContacts(false)
          refetch()
        }}
        onCancel={() => {
          setContactEditMode('view')
          if (!(contactEditMode === 'edit' && showContactData.id)) {
            setShowContacts(false)
          }
        }}
        onViewEdit={() => setContactEditMode('edit')}
      />
    </DetailsWrapper>
  )
}

let DenseCell = styled(TableCell)`
  padding: 0.5em;
`
function AccountDetail({match, location}) {
  let {id} = match.params

  let {data, isLoading: loading, isError: error, refetch} = useQuery(
    ['account', id],
    () => prgql({query: customerQuery, variables: {id}}),
  )
  // let mediaMatches = useMediaQuery('(min-width:1680px)')

  let queryClient = useQueryClient()

  let [searchStatus, setSearchStatus] = useState('all')
  let [editCustomer, setEditCustomer] = useState(false)

  if ((loading && !data) || error || !data.customers) return null

  let _customerFilter = function(c) {
    switch (searchStatus) {
      case 'all':
        return true
      case 'active':
        return c.stop_at === null || moment(c.stop_at).isAfter(moment())
      case 'inactive':
        return moment(c.stop_at).isBefore(moment())
    }
  }

  const c = data.customers
  let active = !c.stop_at || moment.utc(c.stop_at).isAfter(moment())
  let accountCustomers = [...c.customers]

  // you need:
  // street1, city_id, region, taxable, taxitem_id, at least one email
  let canCreateCustomer =
    !!c.street1 &&
    !!c.city_id &&
    !!c.region &&
    !!c.taxitem_id &&
    c.emails.length > 0

  return (
    <div style={Object.assign({}, sheet.container)}>
      <Content container direction="row">
        <Space size={2} inline />
        <Grid item xs container direction={'column'}>
          <Grid item xs={true}>
            <DetailsPanel customer={c} active={active} refetch={refetch} />
          </Grid>
          <Space size={2} inline={false} />
          <Grid item xs={true}>
            <CustomersWrapper>
              <DividerlessDetailToolbar>
                <ToolbarTitle>Customers</ToolbarTitle>
                <ToolbarRight>
                  <ToolbarGroup style={{justifyContent: 'flex-start'}}>
                    <Box p={2}>
                      <StatusSelect
                        value={searchStatus}
                        onChange={e => setSearchStatus(e.target.value)}
                      />
                    </Box>
                    <Box p={2}>
                      {canCreateCustomer ? (
                        <Button
                          variant="contained"
                          color="secondary"
                          size="small"
                          onClick={() => setEditCustomer(true)}
                        >
                          <PersonAdd />
                        </Button>
                      ) : (
                        <Tooltip
                          title={
                            <Typography>
                              Please fill out the address, billing information &
                              at least one email for the account before creating
                              a customer
                            </Typography>
                          }
                          arrow
                        >
                          <span>
                            <Button
                              variant="contained"
                              color="secondary"
                              size="small"
                              disabled
                            >
                              <PersonAdd />
                            </Button>
                          </span>
                        </Tooltip>
                      )}
                    </Box>
                  </ToolbarGroup>
                </ToolbarRight>
              </DividerlessDetailToolbar>
              <CustomersContent>
                {!c.customers || !c.customers.length ? (
                  <Typography>No Customers</Typography>
                ) : (
                  <Table selectable={false}>
                    <TableHead>
                      <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Address</TableCell>
                        <TableCell>State</TableCell>
                        <TableCell>Balance</TableCell>
                        <TableCell style={{textAlign: 'right'}}>
                          Last Service
                        </TableCell>
                        <TableCell style={{textAlign: 'right'}}>
                          Next Service
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody padding="dense">
                      {accountCustomers
                        .filter(_customerFilter)
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map((customer, i) => (
                          <React.Fragment>
                            <TableRow key={i}>
                              <DenseCell>
                                {_renderNameColumn({rowData: customer})}
                              </DenseCell>
                              <DenseCell>
                                {_renderCityColumn({rowData: customer})}
                              </DenseCell>
                              <DenseCell>
                                <Typography
                                  variant="body1"
                                  display="block"
                                  style={{textAlign: 'center'}}
                                >
                                  {customer.region}
                                </Typography>
                              </DenseCell>
                              <DenseCell>
                                <Link
                                  display="block"
                                  variant="body1"
                                  style={{textAlign: 'right'}}
                                  to={`/customers/${customer.id}/transactions/payments/add`}
                                >
                                  {formatMoneyStandard(
                                    customer.balances.balance,
                                  )}
                                </Link>
                              </DenseCell>
                              <DenseCell>
                                <Typography
                                  varaint="body1"
                                  display="block"
                                  style={{textAlign: 'right'}}
                                >
                                  {customer.last_routerun ? (
                                    <ScheduleDateText
                                      date={customer.last_routerun.run_at}
                                      route={customer.last_routerun.route_id}
                                    />
                                  ) : (
                                    'None'
                                  )}
                                </Typography>
                              </DenseCell>
                              <DenseCell>
                                <Typography
                                  varaint="body1"
                                  display="block"
                                  style={{textAlign: 'right'}}
                                >
                                  {customer.next_routerun ? (
                                    <ScheduleDateText
                                      date={customer.next_routerun.run_at}
                                      route={customer.next_routerun.route_id}
                                    />
                                  ) : (
                                    'None'
                                  )}
                                </Typography>
                              </DenseCell>
                            </TableRow>
                          </React.Fragment>
                        ))}
                    </TableBody>
                  </Table>
                )}
              </CustomersContent>
            </CustomersWrapper>
          </Grid>
          {c.child_accounts && c.child_accounts.length > 0 && (
            <>
              <Space size={2} inline={false} />
              <Grid item xs={true}>
                <CustomersWrapper>
                  <DividerlessDetailToolbar>
                    <ToolbarTitle>Child Accounts</ToolbarTitle>
                  </DividerlessDetailToolbar>
                  <CustomersContent>
                    <Table selectable={false}>
                      <TableHead>
                        <TableRow>
                          <TableCell>Name</TableCell>
                          <TableCell>Address</TableCell>
                          <TableCell style={{textAlign: 'center'}}>
                            State
                          </TableCell>
                          <TableCell style={{textAlign: 'center'}}>
                            Balance
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody padding="dense">
                        {c.child_accounts
                          .sort((a, b) => a.name.localeCompare(b.name))
                          .map((customer, i) => (
                            <React.Fragment>
                              <TableRow key={i}>
                                <DenseCell>
                                  {_renderAccountNameColumn({
                                    rowData: customer,
                                  })}
                                </DenseCell>
                                <DenseCell>
                                  {_renderCityColumn({rowData: customer})}
                                </DenseCell>
                                <DenseCell>
                                  <Typography
                                    variant="body1"
                                    display="block"
                                    style={{textAlign: 'center'}}
                                  >
                                    {customer.region}
                                  </Typography>
                                </DenseCell>
                                <DenseCell>
                                  <MuiLink
                                    display="block"
                                    variant="body1"
                                    style={{textAlign: 'center'}}
                                    href={`/accounts/${customer.id}/transactions/payments/add`}
                                    underline="hover"
                                  >
                                    {formatMoneyStandard(
                                      customer.balances.balance,
                                    )}
                                  </MuiLink>
                                </DenseCell>
                              </TableRow>
                            </React.Fragment>
                          ))}
                      </TableBody>
                    </Table>
                  </CustomersContent>
                </CustomersWrapper>
              </Grid>
            </>
          )}
        </Grid>
      </Content>
      <EditDetailsCustomer
        open={editCustomer}
        defaultValues={{
          account_id: c.id,
          taxitem_id: c.taxitem_id,
          taxable: c.taxable,
          account_taxable: c.taxable, // only used to help validation
          terms_id: '2',
          source_id: c.source_id,
          customertype_id: c.customertype_id,
          sales_person_id: c.sales_person_id,
          invoice_needs_po_number: c.invoice_needs_po_number,
        }}
        onSubmit={() => {
          queryClient.invalidateQueries('account')
          setEditCustomer(false)
        }}
        onCancel={() => setEditCustomer(false)}
        isError={false}
        view="client"
      />
    </div>
  )
}

let _renderNameColumn = function({rowData, rowIndex, isScrolling}) {
  return (
    rowData && (
      <Link to={`/customers/${rowData.id}`}>
        <div style={sheet.cellContainer}>
          <div style={sheet.photoWrapper}>
            <CustomerAvatar
              type={rowData.customertype.type}
              url={rowData.photo && rowData.photo.thumb_url}
            />
          </div>
          <div>
            <Typography display="block" variant="h6">
              {rowData.name}
            </Typography>
          </div>
        </div>
      </Link>
    )
  )
}

let _renderAccountNameColumn = function({rowData, rowIndex, isScrolling}) {
  return (
    rowData && (
      <Link to={`/accounts/${rowData.id}`}>
        <div style={sheet.cellContainer}>
          <div style={sheet.photoWrapper}>
            <CustomerAvatar
              type={rowData.customertype?.type || 'Commercial'}
              url={rowData.photo && rowData.photo.thumb_url}
            />
          </div>
          <div>
            <Typography display="block" variant="h6">
              {rowData.name}
            </Typography>
          </div>
        </div>
      </Link>
    )
  )
}

let _renderCityColumn = ({rowData}) =>
  rowData && (
    <div>
      <Typography variant="subtitle2" display="block">
        {rowData.city && rowData.city.name}
      </Typography>
      <Typography variant="caption" color="textSecondary" display="block">
        {rowData.street1}
      </Typography>
    </div>
  )

export default AccountDetail
