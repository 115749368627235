import PropTypes from 'prop-types'
import React, {Component} from 'react'
import styled from 'styled-components'

import {PaperToolbar} from './Toolbar'
import ToolbarRow from './ToolbarRow'
import ToolbarGroup from './ToolbarGroup'
import BorderButton from './BorderButton'
import IconMenu from '../components/IconMenu'
import {CloseButton} from './Toolbar'
import DeleteDialog from './DeleteDialog'

import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import MenuItem from '@mui/material/MenuItem'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'

import MoreVertIcon from '@mui/icons-material/MoreVert'
import Save from '@mui/icons-material/Save'
import Delete from '@mui/icons-material/Delete'
import Edit from '@mui/icons-material/Edit'
import LoadingButton from './LoadingButton'
import {Space} from './Layout'

let wrapEl = (el, children) =>
  el ? React.cloneElement(el, {children}) : children

let SaveIcon = styled(Save)`
  margin-right: ${({theme: {muiTheme}}) => muiTheme.spacing(1)};
`

export {CloseButton}

class DetailToolbar extends Component {
  static propTypes = {
    title: PropTypes.string,
    onEdit: PropTypes.func,
    onCancel: PropTypes.func,
    onDelete: PropTypes.func,
    onClose: PropTypes.func,
    closeWrapper: PropTypes.element,
    onSave: PropTypes.func,
    canSave: PropTypes.bool,
    editing: PropTypes.bool,
    deleteMessage: PropTypes.node,
    deleteTitle: PropTypes.string,
    isSaving: PropTypes.bool,
    otherMenuItems: React.Fragment,
    deleteDisabled: PropTypes.bool,
  }

  state = {confirmDelete: false}

  toggleDeleteDialog = () =>
    this.setState(({confirmDelete}) => ({confirmDelete: !confirmDelete}))

  render() {
    let {
      title,
      onEdit,
      onDelete,
      onClose,
      closeWrapper,
      cancelWrapper,
      onCancel,
      onSave,
      canSave,
      editing,
      deleteMessage,
      deleteTitle,
      isSaving,
      showSaveButton,
      otherMenuItems,
      deleteDisabled,
    } = this.props
    let {confirmDelete} = this.state

    return (
      <PaperToolbar>
        <ToolbarRow>
          {!editing ? (
            <React.Fragment>
              <ToolbarGroup first={true}>
                {wrapEl(closeWrapper, <CloseButton onClick={onClose} />)}
              </ToolbarGroup>
              <ToolbarGroup style={{flexGrow: 1}}>
                <Typography variant="h6" color="inherit">
                  {title}
                </Typography>
              </ToolbarGroup>
              <ToolbarGroup last={true}>
                <IconButton onClick={onEdit} id="detail-toolbar-edit" size="large">
                  <Edit />
                </IconButton>
                <IconMenu
                  iconButtonElement={
                    <IconButton size="large">
                      <MoreVertIcon />
                    </IconButton>
                  }
                  anchorOrigin={{horizontal: 'right', vertical: 'top'}}
                  targetOrigin={{horizontal: 'right', vertical: 'top'}}
                  id={'detailMenu'}
                >
                  {otherMenuItems}
                  <MenuItem
                    onClick={this.toggleDeleteDialog}
                    disabled={deleteDisabled}
                  >
                    <ListItemIcon>
                      <Delete />
                    </ListItemIcon>
                    <ListItemText primary="Delete" id={'detailMenuDelete'} />
                  </MenuItem>
                </IconMenu>
              </ToolbarGroup>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <ToolbarGroup first={true}>
                {wrapEl(
                  cancelWrapper,
                  <BorderButton
                    darkBorder
                    children="cancel"
                    style={{marginRight: '10px', borderColor: 'rgba(0,0,0,.2)'}}
                    onClick={onCancel}
                  />,
                )}
              </ToolbarGroup>
              <ToolbarGroup style={{flexGrow: 1}}>
                <Typography variant="h6" color="inherit">
                  {title}
                </Typography>
              </ToolbarGroup>
              <ToolbarGroup last={true}>
                {showSaveButton ? (
                  <LoadingButton
                    variant="contained"
                    style={{marginRight: '10px'}}
                    color="primary"
                    disabled={!canSave || isSaving}
                    loading={isSaving}
                    onClick={onSave}
                  >
                    <SaveIcon /> Save
                  </LoadingButton>
                ) : (
                  <Space inline sizePx={130} />
                )}
              </ToolbarGroup>
            </React.Fragment>
          )}
        </ToolbarRow>
        <Divider style={{margin: '0 0 15px 0'}} />
        <DeleteDialog
          isOpen={confirmDelete}
          onConfirm={onDelete}
          onCancel={this.toggleDeleteDialog}
          message={deleteMessage}
          title={deleteTitle}
        />
      </PaperToolbar>
    );
  }
}
export default DetailToolbar

export class DetailBottomToolbar extends Component {
  static propTypes = {
    title: PropTypes.string,
    // onEdit: PropTypes.func,
    onCancel: PropTypes.func,
    // onDelete: PropTypes.func,
    // onClose: PropTypes.func,
    // closeWrapper: PropTypes.element,
    onSave: PropTypes.func,
    canSave: PropTypes.bool,
    editing: PropTypes.bool,
    // deleteMessage: PropTypes.node,
    // deleteTitle: PropTypes.string,
    isSaving: PropTypes.bool,
  }

  state = {confirmDelete: false}

  toggleDeleteDialog = () =>
    this.setState(({confirmDelete}) => ({confirmDelete: !confirmDelete}))

  render() {
    let {
      title,
      cancelWrapper,
      onCancel,
      onSave,
      canSave,
      editing,
      isSaving,
    } = this.props

    return (
      <PaperToolbar>
        <Divider style={{margin: '0 0 15px 0'}} />
        <ToolbarRow>
          {!editing ? (
            <React.Fragment>
              <ToolbarGroup first={true}></ToolbarGroup>
              <ToolbarGroup style={{flexGrow: 1}}></ToolbarGroup>
              <ToolbarGroup last={true}></ToolbarGroup>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <ToolbarGroup first={true}>
                {wrapEl(
                  cancelWrapper,
                  <BorderButton
                    darkBorder
                    children="cancel"
                    style={{marginRight: '10px', borderColor: 'rgba(0,0,0,.2)'}}
                    onClick={onCancel}
                  />,
                )}
              </ToolbarGroup>
              <ToolbarGroup style={{flexGrow: 1}}>
                <Typography variant="h6" color="inherit">
                  {title}
                </Typography>
              </ToolbarGroup>
              <ToolbarGroup last={true}>
                <LoadingButton
                  variant="contained"
                  style={{marginRight: '10px'}}
                  color="primary"
                  disabled={!canSave || isSaving}
                  loading={isSaving}
                  onClick={onSave}
                >
                  <SaveIcon /> Save
                </LoadingButton>
              </ToolbarGroup>
            </React.Fragment>
          )}
        </ToolbarRow>
      </PaperToolbar>
    )
  }
}

let DetailRow = styled(ToolbarRow)`
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  grid-template-areas: 'toolbar-left toolbar-center toolbar-right';
  align-items: center;
`
export let ToolbarLeft = styled(ToolbarGroup)`
  grid-area: toolbar-left;
  justify-self: start;
  margin: 0;
`
export let ToolbarCenter = styled(ToolbarGroup)`
  grid-area: toolbar-center;
  justify-self: center;
  margin: 0;
`
export let ToolbarRight = styled(ToolbarGroup)`
  grid-area: toolbar-right;
  justify-self: end;
  margin: 0;
`
export let ToolbarTitle = props => (
  <ToolbarCenter>
    <Typography variant="h6" color="inherit" {...props} />
  </ToolbarCenter>
)
export class Toolbar extends Component {
  render() {
    let {children} = this.props
    return (
      <PaperToolbar>
        <DetailRow>{children}</DetailRow>
        <Divider style={{margin: '0 0 15px 0'}} />
      </PaperToolbar>
    )
  }
}
