import PropTypes from 'prop-types';
import React from 'react';
import {Caption} from './Typography'

class Label extends React.Component {
  static propTypes = {text: PropTypes.string.isRequired};

  render() {
    return (
      <div style={{display: 'block', ...this.props.style}}>
        <div style={{margin: '14px 0 5px'}}>
          <Caption>{this.props.text}</Caption>
        </div>
        <div>{this.props.children}</div>
      </div>
    )
  }
}
export default Label

