import React, { useState } from 'react'
import styled from 'styled-components'
import Login from './screens/Login'
import { TunnelPlaceholder, Tunnel } from 'react-tunnels'

import AppBar from '@mui/material/AppBar'
import theme from './styles/theme'
import { connect } from 'react-redux'
import * as snackbarActions from './ducks/snackbar'
import AppMenu from './components/AppMenu'
import Snackbar from '@mui/material/Snackbar'
import PRToolbar from './components/Toolbar'
import ToolbarRow from './components/ToolbarRow'
import { Toaster } from 'react-hot-toast'
import { useQuery } from 'react-query'
import BUILD_NUMBER from './constants/BUILD_NUMBER'
import { fetchAPI } from './schema/utils'
import CircularProgress from './components/CircularProgress'
import { PulseButton, RainbowGlowButton, RunningBorderButton } from './components/FancyButtons'
import { Redirect } from './pr-router'

export { ToolbarFab } from './components/ToolbarRow'

let PRAppBar = styled(AppBar)`
  display: block !important;
  minheight: auto !important;
  padding: 0 !important;
  transition: all 450ms cubic-bezier(0.23 1 0.32 1) 0ms !important;
  position: relative;

  @media print {
    display: none !important;
  }
`
PRAppBar.muiName = AppBar.muiName

let AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${theme.backgroundColor};
  height: 100vh;
`
let Content = styled.div`
  flex: 1 0 auto;
  position: relative;
  display: flex;
  flex-direction: column;
`

const UpdateSiteButton = () => {
  const { data: serverData, isLoading, isError } = useQuery('siteInfo', fetchSiteInfo);
  const [clicked, setClicked] = useState(false)

  async function fetchSiteInfo() {
    const response = await fetch('/site_info');
    if (!response.ok) {
      throw new Error('Failed to fetch site info');
    }
    return response.json();
  }

  if (isLoading) {
    return <></>;
  }

  if (isError) {
    return <></>; // or error message if you prefer
  }

  const localBuildNumber = BUILD_NUMBER;

  if (serverData && serverData.build_number != localBuildNumber) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: "center",
          height: "20px",
          alignItems: "center",
          padding: "1em",
          background: "black",
          color: "white",
          zIndex: 999999
        }}
        onClick={() => {
          setClicked(true)
          location.reload();
        }}
      >
        <RainbowGlowButton>
          {!clicked ? (serverData.version.includes('v') ? `🐇 PathRabbit ${serverData.version} is available! Click to update` : "A new version of 🐇 PathRabbit is available! Click to update") : "... LOADING ..."}
        </RainbowGlowButton>
      </div>
    );
  } else {
    return null;
  }
};

export let Toolbar = props => <Tunnel id="toolbar" {...props} />

class AppHandler extends React.Component {
  render() {
    const { snackbar, closeSnackbar, toolbar, children } = this.props

    return (
      <AppContainer>
        {!this.props.loggedIn ? <Redirect to="/login" /> : null}
        {!this.props.noMenuBar ? (
          <React.Fragment>
            <UpdateSiteButton />
            <PRAppBar className="mui-dark-theme">
              <AppMenu />
              <PRToolbar>
                <TunnelPlaceholder id="toolbar" multiple>
                  {({ items }) =>
                    items.map(({ children }, i) => (
                      <ToolbarRow key={i}>{children}</ToolbarRow>
                    ))
                  }
                </TunnelPlaceholder>
              </PRToolbar>
              {toolbar}
            </PRAppBar>
            {/* <Snackbar
              open={snackbar.open}
              message={snackbar.message}
              autoHideDuration={snackbar.duration}
              onRequestClose={closeSnackbar}
            /> */}
            <Toaster position="bottom-right" />
          </React.Fragment>
        ) : null}
        <Content>{children}</Content>
      </AppContainer>
    )
  }
}
AppHandler = connect(
  state => ({
    loggedIn: state.auth.get('loggedIn'),
    snackbar: state.ui.snackbar,
  }),
  snackbarActions,
)(AppHandler)
export default AppHandler
