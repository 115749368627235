import React from 'react'
import CalendarCheck from './CalendarCheck'
import CalendarClock from './CalendarClock'
import AttachMoney from '@mui/icons-material/AttachMoney'
import CurrencyUSDOff from './CurrencyUSDOff'

let typeIcons = {
  PTO: AttachMoney,
  UNPAID: CurrencyUSDOff,
  LATE: CalendarClock,
  EXCUSED: CalendarCheck,
}

let AttendanceIcon = ({type, ...props}) => {
  let Icon = typeIcons[type] || typeIcons.EXCUSED
  return <Icon {...props} />
}

export default AttendanceIcon
