import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Typography } from "@mui/material"
import { useState } from "react"
import styled from "styled-components"
import theme from "../../styles/theme"
import DangerLoadingButton from "../../components/DangerLoadingButton"
import { Delete } from "@mui/icons-material"
import { useMutation, useQuery } from "react-query"
import gql from "graphql-tag"
import { prgql } from "../../utils/graphql"
import { RedirectBack } from "../../components/Navigator"
import toaster from "../../utils/toaster"
import { Redirect } from "../../pr-router"

let Content = styled(Grid)`
  padding: 25px 50px;
`

const query = gql`
  query invoices($id: String) {
    allInvoices(filters: {customer_id: $id}){
      edges {
        edge {
          id
        }
      }
    }
  }
`
const destroyQuery = gql`
  mutation deleteCustomer($id: String!) {
    deleteCustomers(input: {id: $id}){
      message
    }
  }
`

export const Danger = ({ match, location }) => {
  let { id } = match.params
  let [open, setOpen] = useState(false)
  let [redirect, setRedirect] = useState(false)
  let { data: invoiceData } = useQuery({ queryFn: () => prgql({ query, variables: { id } }), queryKey: ['customer', id, 'invoices'] })
  let { mutateAsync: destroy, isLoading: destroyLoading } = useMutation({
    mutationFn: () => prgql({ query: destroyQuery, variables: { id } }),
    mutationKey: ['customer', id],
    onSuccess: () => {
      setRedirect(true)
      toaster.success('Customer Deleted')
    },
    onError: () => {
      setOpen(false)
      toaster.error('Something when wrong with deleting the customer')
    }
  })

  let hasInvoices = true
  if (invoiceData && invoiceData.allInvoices && invoiceData.allInvoices.edges) {
    hasInvoices = !!invoiceData.allInvoices.edges.length
  }

  return (
    <>
      <Content>
        <Button variant="contained" color="secondary" onClick={() => setOpen(true)} disabled={hasInvoices}><Delete /> Delete Customer</Button>
      </Content>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogContent>
          <DialogTitle><Typography variant="h5">WARNING</Typography></DialogTitle>
          <DialogContentText>
            <Typography>You probably don't want to do this.</Typography>
            <Typography>Most customers should be paused or stopped. You should only delete junk customers (leads we will never service, tests records, etc). </Typography>
            <Typography>A deleted customer can be restored.</Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <DangerLoadingButton onClick={() => destroy()} loading={destroyLoading}><Delete /> Delete</DangerLoadingButton>
        </DialogActions>
      </Dialog>
      {redirect && <Redirect to="/customers" />}
    </>
  )
}
