import { Map, List } from 'immutable'
import {
  LOGIN_ERROR,
  LOGIN_REDIRECT,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
} from '../constants/ActionTypes'
import {
  ADD_SECURITY_RETRY_QUEUE,
  RETRY_SECURITY_RETRY_QUEUE,
} from '../actions/authActions'

const initialState = Map({
  loggedIn: localStorage && !!localStorage.token,
  error: false,
  retryQueue: List(),
})

export default function auth(state = initialState, action) {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return state.set('loggedIn', true).set('error', false).set('shouldRedirect', false)
    case LOGIN_REDIRECT:
      window.location.replace(action.payload)

      return state.set('loggedIn', false).set('error', false)
    case LOGIN_ERROR:
      localStorage.removeItem('token')

      return state.set('error', action.error).set('loggedIn', false).set('shouldRedirect', false)
    case LOGOUT_SUCCESS:
      localStorage.removeItem('token')
      return state.set('loggedIn', false).set('shouldRedirect', false)
    case ADD_SECURITY_RETRY_QUEUE:
      return state
        .update('retryQueue', queue => queue.push(action.payload))
        .set('loggedIn', false)
    case RETRY_SECURITY_RETRY_QUEUE:
      return state.set('retryQueue', List())
    default:
      return state
  }
}
