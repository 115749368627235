import PropTypes from 'prop-types'
import React from 'react'
import gql from 'graphql-tag'
import {Column} from 'react-virtualized'
import {Link} from 'react-router-dom'
import styled from 'styled-components'
import moment from 'moment'

import TableList from '../../components/Table/TableList'
import {Body1, Subhead, Caption} from '../../components/Typography'
import AvatarBorder from '../../components/AvatarBorder'

let DescWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0 16px;
  & > * {
    margin-right: 8px;
  }
`

let getInitials = ({first, last}) =>
  [...first.slice(0, 1), ...last.slice(0, 1)].join('').toUpperCase()

let DescCol = ({cellData, rowData}) => (
  <Link to={`employees/${rowData.id}`}>
    <DescWrapper>
      <AvatarBorder>
        {getInitials({first: cellData, last: rowData.last_name})}
      </AvatarBorder>
      <Subhead display="block">
        {cellData} {rowData.last_name}
      </Subhead>
    </DescWrapper>
  </Link>
)

class EmployeeList extends React.Component {
  static propTypes = {items: PropTypes.array}
  static fragments = {
    userListDetail: gql`
      fragment UserListDetail on Users {
        id
        first_name
        last_name
        hire_date
        termination_date
        email
      }
    `,
  }
  static defaultProps = {items: []}

  getRowCount = () => {
    let {pageInfo} = this.props
    if (!pageInfo) {
      return 9999
    }
    return pageInfo.total
  }

  render() {
    const {items, loading} = this.props

    return (
      <TableList
        data={items || []}
        infinite
        loadMoreRows={this.props.onInfiniteLoad}
        rowCount={this.getRowCount()}
        loading={loading}
      >
        <Column
          dataKey="first_name"
          headerRenderer={({label}) => (
            <div style={{textAlign: 'center'}}>{label}</div>
          )}
          cellRenderer={data => <DescCol {...data} />}
          label="Name"
          flexGrow={1}
          width={0}
        />
        <Column
          dataKey="hire_date"
          headerRenderer={({label}) => (
            <div style={{textAlign: 'right'}}>{label}</div>
          )}
          cellRenderer={({cellData, rowData = {}}) => (
            <div>
              <Body1 display="block" style={{textAlign: 'right'}}>
                {moment(cellData).format('LL')}
                {rowData.termination_date &&
                  ' / ' + moment(rowData.termination_date).format('LL')}
              </Body1>
              <Caption display="block" style={{textAlign: 'right'}}>
                {moment
                  .duration(
                    moment(cellData).diff(rowData.termination_date || moment()),
                  )
                  .humanize()}
              </Caption>
            </div>
          )}
          label="Hired"
          flexGrow={1}
          width={1}
          maxWidth={250}
        />
        <Column label="" dataKey="" disableSort={true} width={75} />
      </TableList>
    )
  }
}

export default EmployeeList
