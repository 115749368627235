export default `

extend type Mutation {
    emailTransactions(input: EmailTransactionsInput): EmailTransactionsResponse
    emailCustomerTransactions(input: DefaultEmailTransactions, customerId: ID!): EmailTransactionsResponse
    emailManyCustomerTransactions(input: DefaultEmailTransactions, customerIds: [ID!]!): EmailTransactionsResponse
    emailAccountTransactions(input: DefaultEmailTransactions, accountId: ID!): EmailTransactionsResponse
    updateFromTemplate(input: UpdateFromTemplateInput): CommandResponse
    purgeUserFromSchedule(input: PurgeUserFromScheduleInput): CommandResponse
    emailCustomerCare(input: EmailCustomerCareInput, customerId: ID!): EmailTransactionsResponse
    emailChristmasCare(input: EmailChristmasCareInput, customerId: ID!): EmailTransactionsResponse
    generateWorkordersFromSchedules(input: GenerateWorkordersFromSchedulesInput): GenerateWorkordersResponse
    generateInvoicesFromWorkorders(input: GenerateInvoicesFromWorkordersInput): GenerateInvoicesResponse
    payInvoicesWithCash(input: PayInvoicesWithCashInput): PayInvoicesWithCashResponse
    payInvoicesWithCredit(input: PayInvoicesWithCreditInput): PayInvoicesWithCreditResponse
    extractFromBundle(input: ExtractFromBundleInput): CommandResponse 
    sendReminders(input: SendRemindersInput): CommandResponse 
    emailReceipt(input: EmailReceiptInput, paymentId: ID!): CommandResponse 
    sendTipsReports(input: SendTipsReportsInput): CommandResponse 
}

input EmailReceiptInput {
    emails: [String]! 
}

input SendRemindersInput {
    workorder_ids: [ID]!
    method: ReminderMethods! 
}

enum ReminderMethods {
    EMAIL
    MESSAGE
}

input EmailTransactionsInput {
    default: DefaultEmailTransactions
    principals: [ID]
    accountCustomers: Boolean
}

input DefaultEmailTransactions {
    statement: Boolean
    from: String
    invoices: DefaultEmailInvoicesOptions 
    invoices_list: [ID]
    workorders: [ID]
    invoice_report: Boolean
    payment_report: Boolean
    body: String
    send_from_user: Boolean
    address: String
    statement_as_excel: Boolean
    cc: [String]
    generated_statement: String
}

enum DefaultEmailInvoicesOptions {
    open all list
}

type EmailTransactionsResponse {
    error: Boolean
    message: String
}

input UpdateFromTemplateInput {
    from: String!
    to: String!
    preserve: [String]
    route_ids: [ID]
}

input PurgeUserFromScheduleInput {
    user_id: ID!
    from: String!
}

type CommandResponse {
    error: Boolean
    message: String
}

type GenerateWorkordersResponse {
    workorders: [Workorders]
    query: Query
    errors: [Error]
}

type PayInvoicesWithCashResponse {
    invoices: [Invoices]
    query: Query
    errors: [Error]
}

type PayInvoicesWithCreditResponse {
    invoices: [Invoices]
    query: Query
    errors: [Error]
}

type GenerateInvoicesResponse {
    invoices: [Invoices]
    query: Query
    errors: [Error]
}

input EmailCustomerCareInput {
    schedules: [ID]!
}

input EmailChristmasCareInput {
    schedule: ID!
    photos: [ID]!
}

input GenerateWorkordersFromSchedulesInput {
    ids: [ID]!
    date: String!
    route_id: ID
}

input ExtractFromBundleInput {
    workorder_id: ID!
    route_id: ID!
    date: String!
    page_numbers: String!
}

input GenerateInvoicesFromWorkordersInput {
    ids: [ID]!
}

input PayInvoicesWithCashInput {
    ids: [ID]!
}

input PayInvoicesWithCreditInput {
    ids: [ID]!
}

input SendTipsReportsInput {
    user_ids: [ID]!
    from: String!
    to: String!
}
`
