// import React from 'react'
import styled from 'styled-components'

export let Spacing = styled.div`
  display: grid;
  grid-auto-flow: ${({direction}) => direction};
  grid-auto-rows: auto;
  grid-auto-columns: auto;
  grid-gap: ${({theme}) =>
    `${theme.muiTheme.spacing(1)} ${theme.muiTheme.spacing(1)}`};
  align-items: ${({alignItems}) => alignItems};
`
Spacing.defaultProps = {
  alignItems: 'center',
  direction: 'column',
}

let spacing = [0, 8, 16, 32, 64]
let getSpacing = i =>
  spacing[i] !== undefined ? spacing[i] : spacing[spacing.length - 1]

export let Space = styled.div`
  display: ${({inline}) => (inline ? 'inline-block' : 'block')};
  ${({inline}) => (inline ? 'width' : 'height')}: ${({sizePx, size = 1}) =>
    sizePx !== undefined && sizePx !== null ? sizePx : getSpacing(size)}px;
`

export let Block = styled.div`
  display: flex;
  flex-direction: ${({column}) => (column ? 'column' : 'row')};
  padding: ${({paddingX: x, paddingY: y}) =>
    [y, x, y, x].map(v => getSpacing(v) + 'px').join(' ')};

  & > * + * {
    ${({column}) => (column ? 'margin-top' : 'margin-left')} : ${({
  spacing,
  spacingPx,
}) => spacingPx || getSpacing(spacing)}px;
  }
`
Block.defaultProps = {
  spacing: 0,
  paddingX: 0,
  paddingY: 0,
}
