import React, { useState, useEffect } from 'react'
import gql from 'graphql-tag'
import { Column } from 'react-virtualized'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import moment from 'moment'
import queryString from 'query-string'

import Input from '@mui/material/Input'
import InputAdornment from '@mui/material/InputAdornment'

import CalendarIcon from '@mui/icons-material/CalendarToday'
import {
  InputLabel,
  Typography,
} from '@mui/material'

import TableList from '../components/Table/TableList'
import { Body1, Subhead } from '../components/Typography'
import AvatarBorder from '../components/AvatarBorder'
import {
  usePagination,
} from '../components/PaginationContainer'
import { Toolbar } from '../AppHandler'
import ToolbarMegaTitle from '../components/ToolbarMegaTitle'
import ModifyQueryParams from '../components/ModifyQueryParams'
import { NavTabs, TabLink } from '../components/NavTabs'
import { UserSelectDynamic } from '../components/UsersSelect'
import { ToolbarGroup } from '../components/ToolbarRow'

function DateInput({ onClick, value }) {
  return (
    <Input
      onClick={onClick}
      value={value}
      startAdornment={
        <InputAdornment position="start">
          <CalendarIcon />
        </InputAdornment>
      }
    />
  )
}

// @todo: Make all the Activity Log screens one big master screen. I've just been copy / pasting
function ScreenToolbar({
  onChange,
  tab,
  user_id,
}) {
  return (
    <>
      <Toolbar>
        <ToolbarMegaTitle>Activity Log</ToolbarMegaTitle>
        <NavTabs
          value={tab || 'collections'}
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
        >
          <TabLink to={'/activitylog/collections'} label="COLLECTIONS" value="collections" />
          <TabLink to={'/activitylog/ar'} label="AR" value="ar" />
        </NavTabs>
      </Toolbar>
      <Toolbar>
        <ToolbarGroup first>
          <UserSelectDynamic
            selectedId={user_id}
            onChange={(e, u) =>
              onChange({ user_id: !!u ? u.id : '' })
            }
            sx={{ width: 200 }}
            inputProps={{
              variant: 'outlined',
              size: 'small',
              margin: 'dense'
            }}
            fullWidth={false}
            label={"User"}
          />
        </ToolbarGroup>
      </Toolbar>
    </>
  )
}

let DescWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0 16px;
  & > * {
    margin-right: 8px;
  }
`

let getInitials = ({ first, last }) =>
  [...first.slice(0, 1), ...last.slice(0, 1)].join('').toUpperCase()

let DescCol = ({ cellData, rowData, onClick }) => (
  <DescWrapper onClick={() => onClick(rowData)}>
    <AvatarBorder>
      {getInitials({ first: cellData, last: rowData.last_name })}
    </AvatarBorder>
    <Subhead display="block">
      {cellData ? (
        `${cellData} ${rowData.last_name}`
      ) : (
        <Typography style={{ fontStyle: 'italic' }}>No Sales Person</Typography>
      )}
    </Subhead>
  </DescWrapper>
)

let ResourceCell = ({ rowData, cellData }) => {
  if (!rowData) return
  if (rowData?.loggable_type === "App\\Payment") {
    return <Link to={`/customers/${rowData.payment?.customer?.id}/payments/${rowData.loggable_id}`}>Payment {rowData.loggable_id}</Link>
  }

  if (rowData?.loggable_type === "App\\Invoice") {
    return <a href={`/old/database/invoice.php?InvoiceNumber=${rowData.loggable_id}`}>Invoice {rowData.loggable_id}</a>
  }

  if (rowData?.loggable_type === "App\\Customer") {
    return <Link to={`/customers/${rowData.loggable_id}`}>{rowData.customer?.name}</Link>
  }

  return <Typography>{rowData?.loggable_type} {rowData.loggable_id}</Typography>
}

let MessageCell = ({ rowData, cellData }) => {
  if (!rowData) return
  if (rowData?.loggable_type === "App\\Payment") {
    return <Typography>
      {cellData} ({rowData.payment?.customer.name})
    </Typography>
  }

  if (rowData?.loggable_type === "App\\Invoice") {
    return <Typography>
      {cellData} ({rowData.invoice?.customer.name})
    </Typography>
  }

  return <Typography>
    {cellData}
  </Typography>
}

function ActivityLogList({ items = [], onInfiniteLoad, pageInfo }) {
  function getRowCount() {
    if (!pageInfo) {
      return 9999
    }
    return pageInfo.total
  }

  return (
    <TableList
      data={items}
      infinite
      loadMoreRows={onInfiniteLoad}
      rowCount={getRowCount()}
      wrapRow={({ children, rowData }) => <>{children}</>}
    >
      <Column
        dataKey="logged_at"
        headerRenderer={({ label }) => (
          <div style={{ textAlign: 'center' }}>{label}</div>
        )}
        cellRenderer={({ cellData, rowData }) =>
          moment(cellData).format('MMM DD, YYYY')
        }
        label="Logged at"
        width={150}
      />
      <Column
        dataKey="resource"
        headerRenderer={({ label }) => (
          <div style={{ textAlign: 'center' }}>{label}</div>
        )}
        cellRenderer={ResourceCell}
        label="Resource"
        width={200}
      />
      <Column
        dataKey="user"
        label="User"
        cellRenderer={({ cellData, rowData }) => (
          rowData ? (
            <Link to={`/users/${rowData.user.id}`}>
              {cellData.first_name} {cellData.last_name}
            </Link>
          ) : <></>
        )}
        width={150}
      />
      <Column
        dataKey="message"
        label="Message"
        cellRenderer={MessageCell}
        width={0}
        flexGrow={1}
      />
    </TableList>
  )
}

let logQuery = gql`
  query logs($cursor: String, $limit: Int = 25, $type: ActivityLogTypes, $user_id: String) {
    logs: allActivityLogs(
      filters: {
          type: $type,
          user_id: $user_id
      }
      orderBy: logged_at_DESC
      cursor: $cursor
      limit: $limit
    ) {
      edges {
        cursor
        edge {
          id
          logged_at
          message
          type
          loggable_type
          loggable_id
          user {
            id
            first_name
            last_name
          }
          payment {
            id
            customer {
              id
              name
            }
          }
          invoice {
            id
            customer {
              id
              name
            }
          }
          customer {
            id
            name
          }
        }
      }
      pageInfo {
        next
        current
        prev
        count
        total
      }
    }
  }
`

function ActivityLog({ location, match }) {
  let [checks, setChecks] = useState([])

  let [queryChanges, setQueryChanges] = useState({})
  let { user_id } = queryString.parse(
    location.search,
  )
  let { type = 'COLLECTIONS' } = match.params
  let { data, loading, loadMore } = usePagination({
    query: logQuery,
    variables: {
      type: type.toUpperCase(),
      user_id,
      cursor: '-1',
    },
  })

  return (
    <React.Fragment>
      <ScreenToolbar
        onChange={changes => setQueryChanges(c => ({ ...c, ...changes }))}
        tab={type}
        user_id={user_id}
      />
      <ModifyQueryParams query={queryChanges} />
      <ActivityLogList
        items={data?.logs && data?.logs.edges}
        pageInfo={data?.logs && data?.logs.pageInfo}
        onInfiniteLoad={loadMore}
        loading={loading}
      />
    </React.Fragment>
  )
}

export default ActivityLog
