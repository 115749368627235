import React from 'react'
import CircularProgress from '@mui/material/CircularProgress'

function Loading() {
  return (
    <div style={styles.container}>
      <CircularProgress />
    </div>
  )
}

export default Loading

const styles = {
  container: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}
