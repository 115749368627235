import React, {useState} from 'react'
import gql from 'graphql-tag'
import styled from 'styled-components'
import urlJoin from 'url-join'
import PropTypes from 'prop-types'
import queryString from 'query-string'
import {Route, Switch, Link} from 'react-router-dom'

import MenuItem from '@mui/material/MenuItem'
import Fab from '@mui/material/Fab'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'

import AddIcon from '@mui/icons-material/Add'
import Print from '@mui/icons-material/Print'

import Employee from './Employee'
import {NewEmployee} from './EmployeeDetails'
import {Toolbar} from '../../AppHandler'
import {Redirect} from '../../pr-router'
import ModifyQueryParams from '../../components/ModifyQueryParams'
import EmployeeList from './EmployeeList'
import UnpaidAttendance from './UnpaidAttendance'
import PrintSummary from './EmployeePrintSummary'
import ToolbarGroup from '../../components/ToolbarGroup'
import SearchBox from '../../components/SearchBox'
import PaginationContainer from '../../components/PaginationContainer'
// import AddDialog from '../../components/AddDialog'
import SelectMenu from '../../components/ToolbarDropDownMenu'
import {Space} from '../../components/Layout'
import {useDebounce} from '../../utils'
import UnpaidCommission from './UnpaidCommission'
import {useAuth} from '../../security/auth'
import UnpaidTips from './UnpaidTips'

let EmployeeListStyled = styled(EmployeeList)`
  max-width: 920px;
  margin: 20px auto;
`
let SearchBoxGroup = styled(ToolbarGroup)`
  flex-grow: 1;
`
let UnstyledLink = styled(Link)`
  &:visited {
    color: inherit;
  }
`

let PrintButton = () => (
  <Tooltip title="Print employee PTO summaries">
    <IconButton size="large">
      <Print />
    </IconButton>
  </Tooltip>
)

class ScreenToolbar extends React.Component {
  static propTypes = {
    searchQuery: PropTypes.string,
    onQueryChange: PropTypes.func,
  }
  state = {
    searchFocused: true,
    isSearching: false,
    newEmployee: false,
  }
  componentDidUpdate(prevProps) {
    if (this.props.searchBy !== prevProps.searchBy) this.refs.searchBox.focus()
  }

  updateQuery(queryChanges) {
    const {onQueryChange} = this.props

    if (onQueryChange) onQueryChange(queryChanges)
  }

  onSearch = query => {
    this.updateQuery({q: query})
  }

  handleCreate = d => this.setState({newItemDescription: d})
  render() {
    const {searchQuery, status, match, canCreateUser} = this.props
    let {newEmployee} = this.state

    return newEmployee ? (
      <Redirect
        to={{
          pathname: urlJoin(match.url, 'add'),
          state: {
            backLocation: {
              pathname: this.props.location.pathname,
              query: this.props.location.query,
            },
          },
        }}
        push
      />
    ) : (
      <Toolbar>
        <SearchBoxGroup>
          <SelectMenu
            value={status}
            onChange={e => this.updateQuery({status: e.target.value})}
          >
            <MenuItem value="all">All Employees</MenuItem>
            <MenuItem value="active">Active Employees</MenuItem>
          </SelectMenu>
          <Space inline sizePx={16} />
          <SearchBox
            ref="searchBox"
            style={{flexGrow: 1}}
            value={searchQuery}
            onSearch={this.onSearch}
            onEnter={this.onSearch}
          />
        </SearchBoxGroup>
        <ToolbarGroup last>
          <Link to="/employees/print-summaries">
            <PrintButton />
          </Link>
        </ToolbarGroup>
        {canCreateUser === 'authorized' ? (
          <Fab color="secondary" component={UnstyledLink} to="/employees/new">
            <AddIcon />
          </Fab>
        ) : (
          <></>
        )}
      </Toolbar>
    )
  }
}
export {ScreenToolbar}

let usersQuery = gql`
  query Users($cursor: String, $filters: UserFilters, $limit: Int = 25) {
    users: allUsers(cursor: $cursor, limit: $limit, filters: $filters) {
      edges {
        edge {
          ...UserListDetail
        }
        cursor
      }
      pageInfo {
        next
        current
        prev
        count
        total
      }
    }
  }
  ${EmployeeList.fragments.userListDetail}
`

function Employees({location, match}) {
  let {q: name = '', status = 'active'} = queryString.parse(location.search)
  let [queryChanges, setQueryChanges] = useState({q: name})
  let [debouncedQ] = useDebounce(queryChanges.q, 300)

  let {status: canCreateUser} = useAuth('manage users')

  return (
    <React.Fragment>
      <ScreenToolbar
        searchQuery={queryChanges.q}
        status={status}
        onQueryChange={changes => setQueryChanges(c => ({...c, ...changes}))}
        match={match}
        location={location}
        canCreateUser={canCreateUser}
      />
      <ModifyQueryParams query={{...queryChanges, q: debouncedQ}} state={{}} />
      <PaginationContainer
        query={usersQuery}
        variables={{
          filters: {name, status: status === 'all' ? undefined : status},
          cursor: '-1',
        }}
        children={({users, loadMore, loading}) => (
          <EmployeeListStyled
            items={users && users.edges}
            showTermination={status === 'all'}
            pageInfo={users && users.pageInfo}
            onInfiniteLoad={loadMore}
            loading={loading}
          />
        )}
      />
    </React.Fragment>
  )
}

let EmployeesRoute = ({match}) => (
  <Switch>
    <Route exact path={match.path} component={Employees} />
    <Route path={`${match.path}/new`} component={NewEmployee} />
    <Route
      path={`${match.path}/unpaidattendance`}
      component={UnpaidAttendance}
    />
    <Route
      path={`${match.path}/unpaidcommission`}
      component={UnpaidCommission}
    />
    <Route path={`${match.path}/unpaidtips`} component={UnpaidTips} />
    <Route path={`${match.path}/print-summaries`} component={PrintSummary} />
    <Route path={`${match.path}/:id`} component={Employee} />
  </Switch>
)

export default EmployeesRoute
