import React, { useState } from 'react'
import moment from 'moment'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import queryString from 'query-string'

import {
  Paper,
  TableBody,
  TableCell,
  Table,
  TableRow,
  Checkbox,
  IconButton,
  Button,
  Typography,
  TextField,
  DialogTitle,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Snackbar,
  Grid,
} from '@mui/material'
import { ToolbarLeft, ToolbarRight } from '../../components/DetailToolbar'
import LinearProgress from '@mui/material/LinearProgress'
import CircularProgress from '@mui/material/CircularProgress'

import {
  Email,
  Receipt,
  Cancel,
  Error,
  Close,
  ArrowLeft,
  ArrowDownward,
  ArrowDropDown,
  ChevronRight,
} from '@mui/icons-material'
import ArrowRight from '@mui/icons-material/Forward'
import ArrowRightDropdown from '@mui/icons-material/ArrowRight'

import { openFile } from '../../schema/utils'
import theme from '../../styles/theme'
import PaperToolbar, { DetailRow } from '../../components/Toolbar'
import { Mutation } from 'react-apollo'
import { Title } from '../../components/Typography'
import colors from '../../styles/colors'
import { TYPE } from '../../constants/EmailTemplates'

function EmailSentDialog({ open, handleClose, message }) {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogActions></DialogActions>
      <DialogTitle>
        {message}
        <IconButton
          key="close"
          aria-label="close"
          color="inherit"
          onClick={handleClose}
          size="large"
        >
          <Close />
        </IconButton>
      </DialogTitle>
    </Dialog>
  )
}

function ConfirmDialog({ onClose, open, numberOfCustomers, onEmail, sending }) {
  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle>Send emails</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Do you want to email statements to {numberOfCustomers} customers?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          variant="contained"
          onClick={onClose}
          autoFocus
        >
          <Cancel /> Cancel
        </Button>
        <div style={{ position: 'relative' }}>
          <Button
            color="primary"
            variant="contained"
            onClick={onEmail}
            disabled={sending}
            id="confirmSend"
          >
            <Email /> Email
          </Button>
          {sending ? <TinyCirclularProgress color="primary" size={24} /> : null}
        </div>
      </DialogActions>
    </Dialog>
  )
}

function SelectScreen({ collection, ids, onSelect, onSelectAll, checkAllState }) {
  return (
    <Table>
      <TableBody>
        <TableRow key="selectControls">
          <TableCell>
            <Checkbox
              color="primary"
              checked={checkAllState}
              onChange={e => onSelectAll(e.target.checked, collection)}
            />
          </TableCell>
        </TableRow>
        {[...collection].map(({ edge: r }, i) => {
          let principalId = r.id
          return (
            <TableRow key={r.id}>
              <TableCell>
                <Typography variant="subtitle1">
                  <Checkbox
                    color="secondary"
                    checked={
                      ids[principalId] === undefined ? true : ids[principalId]
                    }
                    onChange={e =>
                      onSelect({ id: r.id, checked: e.target.checked })
                    }
                  />{' '}
                  <Link
                    to={
                      r.type === 'Account'
                        ? `/accounts/${r.id.split('-')[1]}`
                        : `/customers/${r.id.split('-')[1]}`
                    }
                  >
                    {r.name}
                  </Link>
                </Typography>
              </TableCell>
            </TableRow>
          )
        })}
      </TableBody>
    </Table>
  )
}

let TextBoxContainer = styled.pre`
  background-color: ${colors.grey300};
  padding: 0.25em;
  border-radius: 5px;
  white-space: pre-wrap;
`

const titleForType = {
  AR_30: 'Normal',
  AR_90: 'Over 60 days',
  AR_OVER_90: 'Over 90 days',
}

let EmailBodyDiplay = ({ templates }) => (
  <Grid container direction="row" spacing={4} style={{ padding: '.5em' }}>
    {templates
      .filter(t => ['AR_30', 'AR_90', 'AR_OVER_90'].includes(t.edge.type))
      .map(t => (
        <Grid item md={3}>
          <Typography variant="body2">{titleForType[t.edge.type]}: </Typography>
          <TextBoxContainer>
            <Typography variant="caption">
              {t.edge.body}
              <br />
              {
                templates.filter(t => t.edge.type === TYPE.AR_SIGNATURE)[0].edge
                  .body
              }
            </Typography>
          </TextBoxContainer>
        </Grid>
      ))}
  </Grid>
)

let confirmQuery = gql`
  query principalIds($ids: [ID]!) {
    principalsByIds(ids: $ids) {
      edges {
        edge {
          id
          name
          statements_email_id
          type
          min_invoices {
            id
            serviced_at
            scanned_url
            customer_id
          }
          # email {
          #   email
          # }
          emails {
            id
            email
            cc_statements
            default_email
          }
        }
      }
    }
  }
`

let templatesQuery = gql`
  query Templates {
    templates: allEmailtemplates(filters: {type: system}) {
      edges {
        edge {
          id
          name
          body
          is_default
          type
        }
      }
    }
  }
`

// aaaaannd a little copy/paste from [Stack Overflow](https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript) ....
function validateEmail(email) {
  email = email.trim()
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

let findPrimaryEmail = r => {
  let email = r.emails.find(e => r.statements_email_id === e.id)
  if (!email) {
    email = r.emails.find(e => e.default_email)
  }
  if (!email) {
    email = r.emails ? r.emails[0] : undefined
  }

  return email
}

function ConfirmScreen({
  collection,
  selectedIds,
  loadingData,
  errorCount,
  accountCustomers,
}) {
  console.log('selectedIds', selectedIds)
  let { data, loading } = useQuery(confirmQuery, {
    variables: { ids: selectedIds },
  })
  let [showEmailBody, setShowEmailBody] = useState(false)
  let { data: templates, loading: loadingTemplates } = useQuery(templatesQuery)

  loadingData(loading)
  errorCount(
    !data || Object.keys(data).length === 0
      ? 0
      : [].concat
        .apply(
          [],
          data.principalsByIds.edges
            .map(({ edge: r }, i) => {
              let primary = findPrimaryEmail(r)
              let cc = r.emails.filter(e => e.cc_statements).map(e => e.email)
              let primaryEmail = !!primary ? primary.email : ''
              return [...cc, primaryEmail]
            })
            .flat(),
        )
        .filter(email => !validateEmail(email)).length,
  )

  return !data || Object.keys(data).length === 0 ? (
    <LinearProgress />
  ) : (
    <div style={{ marginTop: '1em' }}>
      <Button
        style={{ margin: '1em' }}
        variant={'heading6'}
        onClick={() => setShowEmailBody(!showEmailBody)}
      >
        Show Email body:{' '}
        {!showEmailBody ? <ArrowRightDropdown /> : <ArrowDropDown />}
      </Button>
      {showEmailBody && (
        <EmailBodyDiplay templates={templates && templates.templates.edges} />
      )}
      <Table>
        <TableBody>
          {data.principalsByIds.edges.map(({ edge: r }, i) => {
            let primaryEmail = findPrimaryEmail(r)
            let ccEmails = r.emails.filter(e => e.cc_statements)
            return (
              <React.Fragment key={r.id}>
                <TableRow>
                  <TableCell>
                    <Typography variant="subtitle1">
                      <Link
                        to={
                          r.type === 'Account'
                            ? `/accounts/${r.id.split('-')[1]}`
                            : `/customers/${r.id.split('-')[1]}`
                        }
                      >
                        {r.name}
                      </Link>{' '}
                      -{' '}
                      <Typography
                        variant="overline"
                        style={
                          [primaryEmail, ...ccEmails].filter(
                            e => !validateEmail(!!e ? e.email : ''),
                          ).length
                            ? {
                              color: theme.palette.danger.main,
                              fontWeight: 'bold',
                            }
                            : {}
                        }
                      >
                        {primaryEmail && validateEmail(primaryEmail.email)
                          ? primaryEmail.email
                          : 'Invalid email address'}

                        {ccEmails.length
                          ? ' | CC: ' +
                          ccEmails
                            .map(e => (!!e ? e.email : ''))
                            .map(e =>
                              validateEmail(e) ? e : 'Invalid CC Email',
                            )
                            .join(', ')
                          : ''}
                      </Typography>
                    </Typography>{' '}
                  </TableCell>
                </TableRow>
                {r.min_invoices
                  .slice()
                  .sort((a, b) =>
                    moment
                      .utc(a.serviced_at)
                      .isBefore(moment.utc(b.serviced_at))
                      ? -1
                      : 1,
                  )
                  .map(i => (
                    <TableRow key={i.id}>
                      <TableCell
                        style={{
                          alignItems: 'center',
                          display: 'flex',
                          height: '3.5em',
                        }}
                      >
                        <span>
                          {moment.utc(i.serviced_at).format('MM-DD-YYYY')}{' '}
                          <a
                            href={
                              '/old/database/invoice.php?InvoiceNumber=' + i.id
                            }
                            style={{ textDecoration: 'underline', color: 'blue' }}
                          >
                            {i.id}
                          </a>
                        </span>{' '}
                        {i.scanned_url && (
                          <IconButton
                            onClick={() => {
                              openFile({
                                url: '/api/invoices/' + i.id + '.pdf',
                                fileType: 'application/pdf',
                              })
                            }}
                            size="large"
                          >
                            <Receipt />
                          </IconButton>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
              </React.Fragment>
            )
          })}
        </TableBody>
      </Table>
    </div>
  )
}

let TinyCirclularProgress = styled(CircularProgress)`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -12px;
  margin-left: -12px;
`

let query = gql`
  query Principals($filters: PrincipalFilters) {
    customers: allPrincipals(limit: 500000, filters: $filters) {
      edges {
        edge {
          id
          type
          name
        }
      }
      pageInfo {
        next
        current
        prev
        count
        total
      }
    }
  }
`

let sendEmailMutation = gql`
  mutation EndEmails($principals: [ID], $generated_statement: String!) {
    emailTransactions(
      input: {
        # default: {statement: true, from: $from, invoices: open, body: $body}
        default: {generated_statement: $generated_statement}
        principals: $principals
        accountCustomers: true
      }
    ) {
      error
      message
    }
  }
`
const ArLink = props => <Link to="/ar" {...props} />

function ArEmail({ location }) {
  let [modifiedIds, setModifiedIds] = useState({})
  let [showConfirmScreen, setShowConfirmScreen] = useState(false)
  let [disableEmail, setDisableEmail] = useState(true)
  let [errorCount, setErrorCount] = useState(0)
  let [dialogOpen, setDialogOpen] = useState(false)
  let [sending, setSending] = useState(false)
  let [sentDialogOpen, setSentDialogOpen] = useState(false)

  let queryParams = queryString.parse(location.search)

  let { data, loading } = useQuery(query, {
    variables: {
      filters: {
        invoice_status: 'OPEN',
        batch_output: 'EMAIL',
        terms_id: parseInt(queryParams['searchTerms'], 10) || undefined,
        route_id: parseInt(queryParams['searchRoute'], 10) || undefined,
        customertype_id: parseInt(queryParams['searchType'], 10) || undefined,
        name: queryParams['q'],
      },
    },
  })

  function checkOff(id, checked) {
    setModifiedIds(ids => ({
      ...ids,
      [id]: checked,
    }))
  }

  function checkAll(checked, ids) {
    if (checked) {
      setModifiedIds({})
    } else {
      setModifiedIds(
        Object.assign({}, ...[...ids].map(({ edge: r }, i) => ({ [r.id]: false }))),
      )
    }
  }

  function checkAllIsChecked() {
    return !Object.values(modifiedIds).includes(false)
  }

  function getSelectedIds(ids) {
    return ids
      .filter(
        item =>
          !(item.edge.id in modifiedIds && modifiedIds[item.edge.id] === false),
      )
      .map(({ edge: r }, i) => {
        return r.id
      })
  }

  if (!data || !data.customers || loading) {
    return null
  }

  let collection = data.customers.edges

  let count = collection && collection.length
  let exclude_count = Object.values(modifiedIds).filter(id => !id).length

  return (
    <React.Fragment>
      <Paper>
        <PaperToolbar>
          <DetailRow>
            {showConfirmScreen ? (
              <>
                <ToolbarLeft>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => setShowConfirmScreen(false)}
                    style={{ marginRight: '2em' }}
                  >
                    <Cancel />
                  </Button>
                  <Title>
                    {disableEmail ? 'Preparing' : 'Email'}{' '}
                    {count - exclude_count} customers
                  </Title>
                  {errorCount ? (
                    <span style={{ marginLeft: '2em', color: 'red' }}>
                      {' '}
                      {errorCount} errors
                    </span>
                  ) : null}
                </ToolbarLeft>
                <ToolbarRight>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setDialogOpen(true)}
                    disabled={disableEmail}
                    id="send"
                  >
                    <Email />
                  </Button>
                  <Mutation
                    mutation={sendEmailMutation}
                    variables={{
                      principals: getSelectedIds(collection),
                      generated_statement: moment()
                        .subtract(1, 'month')
                        .format('YYYY-MM'),
                    }}
                  >
                    {(update, result) => (
                      <ConfirmDialog
                        onClose={() => setDialogOpen(false)}
                        open={dialogOpen}
                        sending={sending}
                        onEmail={() => {
                          setSending(true)
                          update()
                            .then(() => {
                              // do the monkey
                            })
                            .then(() => {
                              setDialogOpen(false)
                              setSending(false)
                              setShowConfirmScreen(false)
                              setSentDialogOpen(true)
                            })
                        }}
                        numberOfCustomers={count - exclude_count}
                      />
                    )}
                  </Mutation>
                </ToolbarRight>
              </>
            ) : (
              <>
                <ToolbarLeft>
                  <Button variant="contained" size="small" component={ArLink}>
                    <ArrowLeft />
                    AR
                  </Button>
                  <Title style={{ marginLeft: '1em' }}>
                    Review {count - exclude_count} customers for emailing
                  </Title>
                </ToolbarLeft>
                <ToolbarRight>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setShowConfirmScreen(true)}
                    id="forward"
                  >
                    <ArrowRight />
                  </Button>
                </ToolbarRight>
              </>
            )}
          </DetailRow>
        </PaperToolbar>
      </Paper>
      <Paper>
        {showConfirmScreen ? (
          <ConfirmScreen
            collection={collection}
            selectedIds={getSelectedIds(collection)}
            loadingData={setDisableEmail}
            errorCount={setErrorCount}
          />
        ) : (
          <SelectScreen
            collection={collection}
            ids={modifiedIds}
            onSelect={({ id, checked }) => checkOff(id, checked)}
            onSelectAll={(checked, collection) => checkAll(checked, collection)}
            checkAllState={checkAllIsChecked()}
          />
        )}
      </Paper>
      <EmailSentDialog
        open={sentDialogOpen}
        handleClose={() => setSentDialogOpen(false)}
        message={
          'Sending statements to ' + (count - exclude_count) + ' customers'
        }
      />
    </React.Fragment>
  )
}

export default ArEmail
