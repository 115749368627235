import _, {isArray} from 'lodash'

type Record = {
  id: string
  type: string
}

type JsonAPIRecord = Record & {
  attributes: {
    [key: string]: any
  }
  relationships?: {
    [key: string]: Record[] | Record
  }
}

type JsonAPIResponse = {
  data: JsonAPIRecord[] | JsonAPIRecord
  included?: JsonAPIRecord[]
  error: boolean
}

export let getAttribute = ({rowData, dataKey}: {rowData: JsonAPIRecord, dataKey: string}) =>
  _.get(rowData, ['attributes', dataKey])
export let getRelationship = ({rowData, allData, dataKey}: {rowData: JsonAPIRecord, allData: JsonAPIResponse, dataKey: string}) => {
  let rel: Record|Record[] = _.get(rowData, ['relationships', dataKey, 'data'])

  if (!rel || !allData.included){
    return null
  }

  if (!Array.isArray(rel)) {
    let {id, type} = rel
    return getIncluded({included: allData.included, type, id})
  }
  // not terribly efficient but it'll do for now
  return rel.map(({id, type}) =>
    getIncluded({included: allData.included, type, id}),
  )
}
export let getIncluded = ({included, type, id}: {included: JsonAPIRecord[], type: string, id: string}) =>
  _.find(included, resource => resource.type === type && resource.id === id)
