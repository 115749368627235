import React, { useRef, useState } from 'react'
import moment from 'moment'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import toaster from '../../utils/toaster'

import {
  Paper,
  TableBody,
  TableCell,
  Table,
  TableRow,
  IconButton,
  Button,
  Typography,
  TextField,
  DialogTitle,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Checkbox,
  FormControl,
  FormGroup,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Switch,
  FormControlLabel,
  Chip,
} from '@mui/material'
import { ToolbarLeft, ToolbarRight } from '../../components/DetailToolbar'
import LinearProgress from '@mui/material/LinearProgress'
import CircularProgress from '@mui/material/CircularProgress'

import {
  Email,
  Receipt,
  Cancel,
  Error,
  Close,
  ArrowLeft,
} from '@mui/icons-material'
import ArrowRight from '@mui/icons-material/Forward'

import { openFile } from '../../schema/utils'
import theme from '../../styles/theme'
import PaperToolbar, { DetailRow } from '../../components/Toolbar'
import { Mutation } from 'react-apollo'
import { Title } from '../../components/Typography'
import { DatePicker } from '@mui/lab'
import { Space } from '../../components/Layout'
import { PLACEHOLDER, SYSTEM_TYPES } from '../../constants/EmailTemplates'
import { Code } from '../../components/Code'
import { useCurrentUser } from '../../security/auth'
import { TYPE } from '../../constants/EmailTemplates'
import toast from 'react-hot-toast'

function EmailSentDialog({ open, handleClose, message }) {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogActions></DialogActions>
      <DialogTitle>
        {message}
        <IconButton
          key="close"
          aria-label="close"
          color="inherit"
          onClick={handleClose}
          size="large"
        >
          <Close />
        </IconButton>
      </DialogTitle>
    </Dialog>
  )
}

function ConfirmDialog({
  onClose,
  open,
  numberOfCustomers,
  onEmail,
  sending,
  isAccount,
}) {
  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle>Send emails</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Do you want to email statements to {numberOfCustomers}{' '}
          {isAccount ? 'account' : 'customer'}?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          variant="contained"
          onClick={onClose}
          autoFocus
        >
          <Cancel /> Cancel
        </Button>
        <div style={{ position: 'relative' }}>
          <Button
            color="primary"
            variant="contained"
            onClick={onEmail}
            disabled={sending}
            id="confirm-send"
          >
            <Email /> Email
          </Button>
          {sending ? <TinyCirclularProgress color="primary" size={24} /> : null}
        </div>
      </DialogActions>
    </Dialog>
  )
}

let confirmQuery = gql`
  query customer($id: String!) {
    customers(id: $id) {
      id
      name
      statements_email_id
      open_invoices {
        id
        serviced_at
        scanned_url
        workorders {
          id
        }
      }
      emails {
        id
        email
        default_email
        label
      }
    }
  }
`

let confirmAccountQuery = gql`
  query customer($id: String!) {
    customers: accounts(id: $id) {
      id
      name
      statements_email_id
      open_invoices {
        id
        serviced_at
        scanned_url
        workorders {
          id
        }
      }
      emails {
        id
        email
        default_email
        label
      }
    }
  }
`

let templatesQuery = gql`
  query Templates {
    templates: allEmailtemplates(limit: 100000, cursor: "-1") {
      edges {
        edge {
          id
          name
          body
          is_default
          type
        }
      }
    }
  }
`

// aaaaannd a little copy/paste from [Stack Overflow](https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript) ....
function validateEmail(email) {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

function getDefaultEmail(emails) {
  let email = [...emails]
    .sort(e => e.default_email)
    .filter(e => e.default_email)
    .shift()

  if (!email) {
    email = [...emails].sort(e => e.default_email).shift()
    return email && email.email
  } else {
    return email.email
  }
}

function ConfirmScreen({
  data,
  loading,
  loadingData,
  errorCount,
  textField,
  setTextField,
  invoicesCheckState,
  statementCheckState,
  workordersCheckState,
  invoicesReportCheckState,
  paymentReportCheckState,
  onInvoicesCheck,
  onInvoicesReportCheck,
  onPaymentReportCheck,
  onStatementCheck,
  onWorkordersCheck,
  statementAsExcel,
  onStatementAsExcel,
  onEmailSelect,
  selectedEmail,
  ccEmails,
  onCCEmailsSelect,
  ids,
  onCheck,
  isAccount,
  id,
  statementFrom,
  onStatementFrom,
  sendFromUser,
  onSendFromUser,
  templates = [],
}) {
  let [template, setTemplate] = useState('')
  loadingData(loading)
  errorCount(
    !data || Object.keys(data).length === 0
      ? 0
      : validateEmail(getDefaultEmail(data.emails))
        ? 0
        : 1,
  )

  let { currentUser } = useCurrentUser()

  let regularTemplates = templates.filter(e => e.edge.type === TYPE.AR_ANY)
  let systemTemplates = templates.filter(e =>
    SYSTEM_TYPES.includes(e.edge.type),
  )

  return !data || Object.keys(data).length === 0 || loading ? (
    <LinearProgress />
  ) : (
    <>
      <FormGroup style={{ margin: '1em' }}>
        <Grid container spacing={2}>
          <Grid item lg={8} style={{ margin: '1em', marginLeft: '2em' }}>
            <TextField
              variant="filled"
              multiline
              label="Email body"
              value={textField}
              onChange={e => setTextField(e.target.value)}
              style={{ width: '100%' }}
            />
            <FormControl
              variant="standard"
              style={{ width: '50%', minWidth: '200px' }}
            >
              <InputLabel>Load Template</InputLabel>
              <Select
                variant="standard"
                onChange={e => {
                  if (!!regularTemplates) {
                    setTextField(
                      regularTemplates.filter(
                        edge => edge.edge.id === e.target.value,
                      )[0].edge.body,
                    )
                  }
                }}
                value={template}
              >
                {!!regularTemplates &&
                  regularTemplates.map(e => (
                    <MenuItem key={e.edge.id} value={e.edge.id}>
                      {e.edge.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <Space sizePx={10} />
            <div>
              <FormControlLabel
                control={
                  <Switch
                    color="secondary"
                    checked={sendFromUser}
                    onChange={e => onSendFromUser(e.target.checked)}
                  />
                }
                label={sendFromUser ? 'Send from your email' : 'Send from AR'}
              />
              <TextField
                variant="filled"
                multiline
                value={
                  sendFromUser
                    ? currentUser && currentUser.email_signature
                    : systemTemplates &&
                    systemTemplates.filter(
                      edge => edge.edge.type === TYPE.AR_SIGNATURE,
                    )[0]?.edge?.body
                }
                style={{ width: '100%' }}
                disabled
              />
            </div>
          </Grid>
          <Grid item>
            {Object.values(PLACEHOLDER).map(({ value, description }, i) => (
              <Typography style={{ marginBottom: '.5em' }}>
                <Code>{value}</Code>: {description}
              </Typography>
            ))}
            <Typography style={{ marginBottom: '.5em' }}>
              <a href="https://commonmark.org/help/" target="_blank">
                Markdown
              </a>{' '}
              syntax supported
            </Typography>
          </Grid>
        </Grid>
      </FormGroup>
      <Space sizePx={10} />
      <DatePicker
        name="assigned_at"
        label="Statement From Date"
        inputFormat="dddd, MMMM D, YYYY"
        value={statementFrom}
        onChange={d => onStatementFrom(d)}
        renderInput={props => (
          <TextField
            {...props}
            name="assigned_at"
            size="small"
            variant="filled"
            style={{ marginLeft: '2em', width: '15em' }}
          />
        )}
      />
      <Space sizePx={10} />
      <FormGroup style={{ margin: '2em' }}>
        <Grid container spacing={2} direction={'column'}>
          <Grid item lg={6}>
            <TextField
              select
              variant="outlined"
              size="small"
              label="Send To"
              fullWidth
              value={selectedEmail}
              onChange={onEmailSelect}
            >
              {data.emails.map(e => (
                <MenuItem key={e.id} value={e.email}>
                  {e.label}: {e.email}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item lg={6}>
            <Select
              select
              multiple
              variant="outlined"
              size="small"
              fullWidth
              label="CC Emails"
              value={ccEmails}
              onChange={onCCEmailsSelect}
              input={<TextField select label={'CC emails'} fullWidth />}
              renderValue={selected => {
                return (
                  <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    {selected.map(value => (
                      <Chip
                        key={value}
                        label={value}
                        style={{ margin: '.1em' }}
                      />
                    ))}
                  </div>
                )
              }}
            >
              {data.emails.map(e => (
                <MenuItem key={e.id} value={e.email}>
                  {e.label}: {e.email}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>
      </FormGroup>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell
              style={{
                alignItems: 'center',
                display: 'flex',
                height: '3.5em',
                fontWeight: 'bold',
              }}
            >
              <Checkbox
                color="primary"
                checked={statementCheckState}
                onChange={e => onStatementCheck(e.target.checked)}
              />{' '}
              Statement
              <IconButton
                onClick={() => {
                  openFile({
                    url:
                      '/api/' +
                      (isAccount ? 'accounts' : 'customers') +
                      '/' +
                      id +
                      '/transactions?from=' +
                      statementFrom.format('YYYY-MM-DD'),
                    fileType: 'application/pdf',
                  })
                }}
                size="large"
              >
                <Receipt />
              </IconButton>
              <Space sizePx={10} inline />
              <FormControlLabel
                control={
                  <Switch
                    color="secondary"
                    checked={statementAsExcel}
                    onChange={e => onStatementAsExcel(e.target.checked)}
                  />
                }
                label={!statementAsExcel ? 'PDF' : 'Excel'}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              style={{
                alignItems: 'center',
                display: 'flex',
                height: '3.5em',
                fontWeight: 'bold',
              }}
            >
              <Checkbox
                color="primary"
                checked={invoicesReportCheckState}
                onChange={e =>
                  onInvoicesReportCheck(e.target.checked, data.open_invoices)
                }
              />{' '}
              Invoice Report
              <IconButton
                onClick={() => {
                  openFile({
                    url:
                      '/old/database/invoicereport.php?type=' +
                      (isAccount ? 'accounts' : 'customers') +
                      '&id=' +
                      id +
                      '&from=' +
                      statementFrom.format('YYYY-MM-DD'),
                    fileType: 'application/pdf',
                  })
                }}
                size="large"
              >
                <Receipt />
              </IconButton>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              style={{
                alignItems: 'center',
                display: 'flex',
                height: '3.5em',
                fontWeight: 'bold',
              }}
            >
              <Checkbox
                color="primary"
                checked={paymentReportCheckState}
                onChange={e => onPaymentReportCheck(e.target.checked)}
              />{' '}
              Payment Report
              <IconButton
                onClick={() => {
                  openFile({
                    url:
                      '/old/database/paymentreport.php?type=' +
                      (isAccount ? 'accounts' : 'customers') +
                      '&id=' +
                      id +
                      '&from=' +
                      statementFrom.format('YYYY-MM-DD'),
                    fileType: 'application/pdf',
                  })
                }}
                size="large"
              >
                <Receipt />
              </IconButton>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              style={{
                alignItems: 'center',
                display: 'flex',
                height: '3.5em',
                fontWeight: 'bold',
              }}
            >
              <Checkbox
                color="primary"
                checked={invoicesCheckState}
                onChange={e => {
                  onInvoicesCheck(e.target.checked, data.open_invoices)
                  if (!e.target.checked) {
                    onWorkordersCheck(false)
                  }
                }}
              />{' '}
              Invoices
              {invoicesCheckState ? (
                <>
                  <Space />
                  <Checkbox
                    color="primary"
                    checked={workordersCheckState}
                    onChange={e => onWorkordersCheck(e.target.checked)}
                  />{' '}
                  include related workorders
                </>
              ) : (
                <></>
              )}
            </TableCell>
          </TableRow>
          {data.open_invoices
            .slice()
            .sort((a, b) =>
              moment.utc(a.serviced_at).isBefore(moment.utc(b.serviced_at))
                ? -1
                : 1,
            )
            .map(i => (
              <TableRow key={i.id}>
                <TableCell
                  style={{
                    alignItems: 'center',
                    display: 'flex',
                    height: '3.5em',
                  }}
                >
                  <Checkbox
                    color="secondary"
                    checked={ids[i.id] === undefined ? true : ids[i.id]}
                    onChange={e =>
                      onCheck({ id: i.id, checked: e.target.checked })
                    }
                  />
                  <span>
                    {moment.utc(i.serviced_at).format('MM-DD-YYYY')}{' '}
                    <a
                      href={'/old/database/invoice.php?InvoiceNumber=' + i.id}
                      style={{ textDecoration: 'underline', color: 'blue' }}
                    >
                      {i.id}
                    </a>
                  </span>{' '}
                  {i.scanned_url && (
                    <IconButton
                      onClick={() => {
                        openFile({
                          url: '/api/invoices/' + i.id + '.pdf',
                          fileType: 'application/pdf',
                        })
                      }}
                      size="large"
                    >
                      {i.scanned_url ? <Receipt /> : <Error />}
                    </IconButton>
                  )}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </>
  )
}

let TinyCirclularProgress = styled(CircularProgress)`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -12px;
  margin-left: -12px;
`

let sendEmailMutation = gql`
  mutation SendEmails(
    $id: ID!
    $from: String!
    $body: String
    $statement: Boolean
    $invoices_list: [ID]
    $workorders: [ID]
    $invoice_report: Boolean
    $payment_report: Boolean
    $send_from_user: Boolean = true
    $statement_as_excel: Boolean = false
    $address: String
    $cc: [String]
  ) {
    emailCustomerTransactions(
      input: {
        statement: $statement
        from: $from
        invoices: list
        invoices_list: $invoices_list
        workorders: $workorders
        invoice_report: $invoice_report
        payment_report: $payment_report
        body: $body
        send_from_user: $send_from_user
        statement_as_excel: $statement_as_excel
        address: $address
        cc: $cc
      }
      customerId: $id
    ) {
      error
      message
    }
  }
`

let sendAccountEmailMutation = gql`
  mutation SendEmails(
    $id: ID!
    $from: String!
    $body: String
    $statement: Boolean
    $invoices_list: [ID]
    $workorders: [ID]
    $invoice_report: Boolean
    $payment_report: Boolean
    $send_from_user: Boolean = true
    $statement_as_excel: Boolean = false
    $address: String
    $cc: [String]
  ) {
    emailAccountTransactions(
      input: {
        statement: $statement
        from: $from
        invoices: list
        invoices_list: $invoices_list
        workorders: $workorders
        invoice_report: $invoice_report
        payment_report: $payment_report
        body: $body
        send_from_user: $send_from_user
        statement_as_excel: $statement_as_excel
        address: $address
        cc: $cc
      }
      accountId: $id
    ) {
      error
      message
    }
  }
`

// IS THERE A BETTER WAY???
function getDefaultText(templates) {
  return (
    (templates &&
      templates.templates &&
      templates.templates.edges &&
      templates.templates.edges.length &&
      (templates.templates.edges.filter(e => e.edge.is_default).length > 0
        ? templates.templates.edges.filter(
          e => e.edge.is_default && e.edge.type === TYPE.AR_ANY,
        )[0]
        : templates[0]
      ).edge.body) ||
    ''
  )
}

function CustomerEmail({ match }) {
  let [modifiedIds, setModifiedIds] = useState({})
  let [attachStatement, setAttachStatement] = useState(true)
  let [attachInvoicesReport, setAttachInvoicesReport] = useState(false)
  let [attachPaymentReport, setAttachPaymentReport] = useState(false)
  let [attachWorkorders, setAttachWorkorders] = useState(false)
  let [disableEmail, setDisableEmail] = useState(true)
  let [sendFromUser, setSendFromUser] = useState(true)
  let [statementAsExcel, setStatementAsExcel] = useState(false)
  let [errorCount, setErrorCount] = useState(0)
  let [dialogOpen, setDialogOpen] = useState(false)
  let [sending, setSending] = useState(false)
  let [sentDialogOpen, setSentDialogOpen] = useState(false)
  let [selectedEmail, setSelectedEmail] = useState('')
  let [ccEmails, setCCEmails] = useState([])
  let { data: templates, loading: loadingTemplates } = useQuery(templatesQuery)

  let [textField, setTextField] = useState(getDefaultText(templates))
  let [statementFrom, setStatementFrom] = useState(
    moment().subtract(1, 'month'),
  )

  function checkOff(id, checked) {
    setModifiedIds(ids => ({ ...ids, [id]: checked }))
  }

  function checkAll(checked, invoices) {
    if (checked) {
      setModifiedIds({})
    } else {
      setModifiedIds(
        Object.assign({}, ...[...invoices].map(i => ({ [i.id]: false }))),
      )
    }
  }

  function invoiceCheckAllState() {
    return !Object.values(modifiedIds).includes(false)
  }

  function getSelectedIds(invoices) {
    return (
      invoices &&
      invoices
        .filter(
          item => !(item.id in modifiedIds && modifiedIds[item.id] === false),
        )
        .map(i => i.id)
    )
  }

  function getSelectedWorkorderIds(invoices) {
    return (
      invoices &&
      invoices
        .filter(
          item => !(item.id in modifiedIds && modifiedIds[item.id] === false),
        )
        .map(i => i.workorders)
        .flat()
        .map(wo => wo.id)
    )
  }

  let isAccount = match.path.split('/')[1] === 'accounts' ? true : false

  let { data, loading } = useQuery(
    isAccount ? confirmAccountQuery : confirmQuery,
    {
      variables: { id: match.params.id },
      onCompleted: data => {
        // this is pretty evil but it works and I don't want to convert to Formik or whatever
        if (selectedEmail === '') {
          let email = (data.customers.emails || []).filter(
            e => e.id === data.customers.statements_email_id,
          )[0]?.email

          if (!email) {
            email = data.customers.emails[0]?.email
          }
          setSelectedEmail(email || '')
        }
      },
    },
  )

  let weAreLoading = false

  if (!data || !data.customers || loading) {
    weAreLoading = true
  }

  return (
    <React.Fragment>
      <Paper>
        <PaperToolbar>
          <DetailRow>
            <ToolbarLeft>
              <Link
                to={
                  isAccount
                    ? `/accounts/${match.params.id}/transactions`
                    : `/customers/${match.params.id}/transactions`
                }
              >
                <Button
                  variant="contained"
                  color="secondary"
                  style={{ marginRight: '2em' }}
                  component="a"
                >
                  <Cancel />
                </Button>
              </Link>
              <Title>
                {disableEmail ? 'Preparing' : 'Email'}{' '}
                {isAccount ? 'account' : 'customer'}
              </Title>
              {errorCount ? (
                <span style={{ marginLeft: '2em', color: 'red' }}>
                  {' '}
                  {errorCount} errors
                </span>
              ) : null}
            </ToolbarLeft>
            <ToolbarRight>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setDialogOpen(true)}
                disabled={disableEmail}
                id="send"
              >
                <Email />
              </Button>
              <Mutation
                mutation={
                  isAccount ? sendAccountEmailMutation : sendEmailMutation
                }
                variables={{
                  from: statementFrom.format('YYYY-MM-DD'),
                  id: match.params.id,
                  body: textField,
                  invoices_list: getSelectedIds(
                    data && data.customers && data.customers.open_invoices,
                  ),
                  workorders: attachWorkorders
                    ? getSelectedWorkorderIds(
                      data && data.customers && data.customers.open_invoices,
                    )
                    : [],
                  statement: attachStatement,
                  invoice_report: attachInvoicesReport,
                  payment_report: attachPaymentReport,
                  send_from_user: sendFromUser,
                  statement_as_excel: statementAsExcel,
                  cc: ccEmails,
                  address: selectedEmail,
                }}
              >
                {(update, result) => (
                  <ConfirmDialog
                    onClose={() => setDialogOpen(false)}
                    open={dialogOpen}
                    sending={sending}
                    onEmail={() => {
                      setSending(true)
                      update()
                        .then(res => {
                          setDialogOpen(false)

                          if (res.errors) {
                            toaster.error('Error sending email')
                          } else {
                            toaster.success('Email Sent')
                          }
                        })
                        .catch(e => toaster.error('Error sending email'))
                        .finally(() => setSending(false))
                    }}
                    numberOfCustomers={1}
                    isAccount={isAccount}
                  />
                )}
              </Mutation>
            </ToolbarRight>
          </DetailRow>
        </PaperToolbar>
      </Paper>
      <Paper>
        <ConfirmScreen
          loadingData={setDisableEmail}
          errorCount={setErrorCount}
          data={data && data.customers}
          loading={weAreLoading}
          textField={textField}
          setTextField={setTextField}
          ids={modifiedIds}
          onCheck={({ id, checked }) => checkOff(id, checked)}
          invoicesCheckState={invoiceCheckAllState()}
          statementCheckState={attachStatement}
          onInvoicesCheck={(checked, invoices) => checkAll(checked, invoices)}
          onStatementCheck={checked => setAttachStatement(checked)}
          onWorkordersCheck={checked => setAttachWorkorders(checked)}
          onInvoicesReportCheck={checked => setAttachInvoicesReport(checked)}
          onPaymentReportCheck={checked => setAttachPaymentReport(checked)}
          onEmailSelect={e => setSelectedEmail(e.target.value)}
          selectedEmail={selectedEmail}
          ccEmails={ccEmails}
          onCCEmailsSelect={e => setCCEmails(e.target.value)}
          invoicesReportCheckState={attachInvoicesReport}
          paymentReportCheckState={attachPaymentReport}
          workordersCheckState={attachWorkorders}
          isAccount={isAccount}
          id={match.params.id}
          statementFrom={statementFrom}
          onStatementFrom={d => setStatementFrom(d)}
          templates={
            templates && templates.templates && templates.templates.edges
          }
          sendFromUser={sendFromUser}
          onSendFromUser={v => setSendFromUser(v)}
          statementAsExcel={statementAsExcel}
          onStatementAsExcel={checked => setStatementAsExcel(checked)}
        />
      </Paper>
      <EmailSentDialog
        open={sentDialogOpen}
        handleClose={() => setSentDialogOpen(false)}
        message={'Sending email to ' + (isAccount ? 'account' : 'customer')}
      />
    </React.Fragment>
  )
}

export default CustomerEmail
