import React, {useState} from 'react'
import styled from 'styled-components'
import moment from 'moment'
import gql from 'graphql-tag'
// import {useQuery, useMutation} from '@apollo/react-hooks'
import {Formik, Field, FieldArray} from 'formik'
import * as Yup from 'yup'

import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import Divider from '@mui/material/Divider'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import MenuItem from '@mui/material/MenuItem'
import IconButton from '@mui/material/IconButton'
import {
  CircularProgress,
  Chip,
  ListItemText,
  InputLabel,
  Link as MuiLink,
  Checkbox as MuiCheckbox,
  DialogContentText,
  List,
  ListItem,
  ListItemIcon,
  Tooltip,
  Tabs,
  Tab,
  Collapse,
} from '@mui/material'
import {
  DatePicker as MuiDatePicker,
  TimePicker as MuiTimePicker,
} from '@mui/lab'

import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Event as RepeatOneIcon,
  Loop as RepeatIcon,
  Add as AddIcon,
  Email,
  ArrowForward,
  FileCopy,
  Cancel,
  ArrowForwardRounded,
  CancelOutlined,
  Clear,
  DateRange,
  KeyboardArrowDown,
  ChevronRight,
  Assignment,
  Pause,
  Close,
  AttachMoney,
  MoneyOff,
  HorizontalRule,
  Park,
} from '@mui/icons-material'

import {formatMoneyStandard} from '../../utils/moneyFormatter'
import {Space} from '../../components/Layout'
import DeleteDialog from '../../components/DeleteDialog'
import AutoComplete, {
  useAutoCompleteEndpoint,
} from '../../components/AutoComplete'
import {
  DetailToolbar,
  ToolbarTitle,
  ToolbarRight,
} from '../../components/Toolbar'
import {
  TextField,
  AutoCompleteField,
  DatePicker,
  Checkbox,
  TimePicker,
} from '../../components/forms'
import LoadingButton from '../../components/LoadingButton'
import RouteSelect from '../../components/RouteSelect'
import UsersSelect from '../../components/UsersSelect'
import _ from 'lodash'
import {Link} from 'react-router-dom'
import theme, {DarkTheme, Theme} from '../../styles/theme'
import {fetchAPI} from '../../schema/utils'
import colors from '../../styles/colors'
import {NavTabs, TabSimple} from '../../components/NavTabs'
import DangerLoadingButton from '../../components/DangerLoadingButton'
import {useAuth, useCurrentUser} from '../../security/auth'
import {PillBoxEmpty, PillBoxWorkorder} from '../Schedules/scheduleComponents'
import {getStatusIcon} from '../Workorders/Manage'
import EditWorkorder from '../Workorder/EditWorkorder'
import {useMutation, useQuery, useQueryClient} from 'react-query'
import {prgql} from '../../utils/graphql'
import Add from '@mui/icons-material/Add'
import {
  JobrequirementtypeSelect,
  TaskSelect,
  TasktypeSelect,
} from '../../components/apiSelects'
import Delete from '@mui/icons-material/Delete'
import {SaleDetailDialog} from './Sales'
import {DiscountSelect} from '../../components/DiscountSelect'
import Image, {MiniPRImage} from '../../components/Image'
import {borderRadius} from 'polished'

let gqlFragment = gql`
  fragment Selection on Saleitems {
    id
    name
    description
    taxable
  }
`

let scheduleWorkordersQuery = gql`
  query schedulesWorkorders($schedule_id: String!) {
    workorders: allWorkorders(
      filters: {
        invoiced: "false"
        schedule_id: $schedule_id
        status: [OPEN, COMPLETE]
      }
      cursor: "-1"
      limit: 100000000
    ) {
      edges {
        edge {
          id
          sequence
          assigned_at
          completed_at
          status
          route_id
          customer_id
          notes
          invoice_id
          multiday
          arrival_start_time
          arrival_end_time
          is_billable
          workorderitems {
            id
            saleitem_id
            saleitem {
              id
              name
            }
            service_id
            description
            notes
            charge
            sales_person_id
            man_minutes
            taxable
            tasks {
              id
              tasktype_id
              tasktype {
                id
                description
              }
            }
          }
          route {
            id
            name
          }
          schedule {
            id
            freq_type
          }
        }
      }
    }
  }
`

const HoverGrid = styled(Grid)`
  &:hover {
    background-color: ${colors.grey50};
  }

  margin: 0px;
`

function SaleItemSelect({onChange, selectedId, ...props}) {
  let autoProps = useAutoCompleteEndpoint({
    gqlFragment,
    selectedId,
    searchField: 'name',
  })

  return (
    <AutoComplete
      {...autoProps}
      onChange={onChange}
      itemToString={u => u.name + ' - ' + u.description}
      renderItem={({item}) => (
        <ListItemText
          id={'saleitem-' + item.id}
          primary={item.name}
          secondary={item.description}
        />
      )}
      renderNoItems={() => <ListItemText primary={'No Sale Item found'} />}
      inputVariant="filled"
      label={<InputLabel>Sale Item</InputLabel>}
      hideIcon
      {...props}
    />
  )
}

const MONTHS = [...Array(12).keys()].map(i => {
  let m = moment().month(i)
  return {
    id: m.format('MMM'),
    label: m.format('MMMM'),
  }
})

const DAYS_OF_WEEK = {
  1: 'Sunday',
  2: 'Monday',
  3: 'Tuesday',
  4: 'Wednesday',
  5: 'Thursday',
  6: 'Friday',
  7: 'Saturday',
}

const ORDINALS = {
  1: 'First',
  2: 'Second',
  3: 'Third',
  4: 'Fourth',
  5: 'Last',
}

function MonthSelect({value, ...props}) {
  return (
    <TextField
      value={value}
      label="Reschedule Month"
      variant="filled"
      fullWidth
      select
      InputLabelProps={{shrink: true}}
      {...props}
    >
      {MONTHS.map(m => (
        <MenuItem key={m.id} value={m.id}>
          {m.label}
        </MenuItem>
      ))}
    </TextField>
  )
}

let RequirementItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

let DayOfWeekSelect = ({...props}) => (
  <TextField select {...props}>
    <MenuItem value={1}>Sunday</MenuItem>
    <MenuItem value={2}>Monday</MenuItem>
    <MenuItem value={3}>Tuesday</MenuItem>
    <MenuItem value={4}>Wednesday</MenuItem>
    <MenuItem value={5}>Thursday</MenuItem>
    <MenuItem value={6}>Friday</MenuItem>
    <MenuItem value={7}>Saturday</MenuItem>
  </TextField>
)

export function Recurrence({values}) {
  if (values.freq_type === 1)
    return (
      <>
        <Grid item sm={2}>
          <DatePicker
            name="start_at"
            inputVariant="filled"
            label="Date"
            format="dddd, MMMM D, YYYY"
            fullWidth
            id="start_at_picker"
          />
        </Grid>
      </>
    )

  let freqTypeValText = {
    2: 'days ',
    3: 'weeks on ',
    4: 'months on day ',
    5: 'months on the ',
  }

  return (
    <>
      <Grid item sm={2}>
        <TextField
          name="freq_type"
          label="Frequency Type"
          variant="filled"
          fullWidth
          select
          InputLabelProps={{shrink: true}}
          id="select-freq_type"
        >
          <MenuItem value={0}>4 Week Cycle</MenuItem>
          <MenuItem value={2}>Daily</MenuItem>
          <MenuItem value={3}>Weekly</MenuItem>
          <MenuItem value={4}>Day of Month</MenuItem>
          <MenuItem value={5}>Monthly</MenuItem>
          {/* <MenuItem value={0}>Cycle 4</MenuItem> */}
        </TextField>
      </Grid>

      {values.freq_type === 0 && (
        <>
          <Grid item sm={1}>
            <TextField
              name="week"
              variant="filled"
              label="Week"
              fullWidth
              select
              id="select-week"
            >
              <MenuItem value={1}>1</MenuItem>
              <MenuItem value={2}>2</MenuItem>
              <MenuItem value={3}>3</MenuItem>
              <MenuItem value={4}>4</MenuItem>
            </TextField>
          </Grid>
          <Grid item sm={2}>
            <DayOfWeekSelect
              name="day"
              variant="filled"
              label="Day"
              fullWidth
              select
              id="select-day"
            />
          </Grid>
        </>
      )}
      {values.freq_type === 2 && (
        <Grid item style={{display: 'flex', alignItems: 'center'}}>
          <Typography style={{margin: '1em'}}>Every</Typography>
          <TextField
            name="freq_interval"
            variant="filled"
            size="small"
            size="small"
            type="number"
            inputProps={{
              style: {
                padding: '.5em',
                width: '40px',
              },
            }}
          />
          <Typography style={{margin: '1em'}}>
            {freqTypeValText[values.freq_type]}
          </Typography>
        </Grid>
      )}
      {values.freq_type >= 3 && (
        <Grid item style={{display: 'flex', alignItems: 'center'}}>
          <Typography style={{margin: '1em'}}>Every</Typography>
          <TextField
            name="freq_recurrence_factor"
            variant="filled"
            size="small"
            type="number"
            inputProps={{
              style: {
                padding: '.5em',
                width: '40px',
              },
            }}
          />
          <Typography style={{margin: '1em'}}>
            {freqTypeValText[values.freq_type]}
          </Typography>
        </Grid>
      )}
      {values.freq_type === 3 && (
        <>
          <Grid item sm={2}>
            <DayOfWeekSelect
              name="freq_interval"
              variant="filled"
              label="Day of Week"
              fullWidth
              select
            />
          </Grid>
        </>
      )}
      {values.freq_type === 4 && (
        <>
          <Grid item>
            <TextField
              name="freq_interval"
              variant="filled"
              size="small"
              type="number"
              inputProps={{
                style: {
                  padding: '.5em',
                  width: '40px',
                },
              }}
            />
          </Grid>
        </>
      )}
      {values.freq_type === 5 && (
        <>
          <Grid item sm={2}>
            <TextField
              name="freq_relative_interval"
              variant="filled"
              fullWidth
              label="Ordinal"
              select
            >
              <MenuItem value={1}>First</MenuItem>
              <MenuItem value={2}>Second</MenuItem>
              <MenuItem value={3}>Third</MenuItem>
              <MenuItem value={4}>Fourth</MenuItem>
              <MenuItem value={5}>Last</MenuItem>
            </TextField>
          </Grid>
          <Grid item sm={2}>
            <DayOfWeekSelect
              name="freq_interval"
              variant="filled"
              label="Day of Week"
              fullWidth
              select
            />
          </Grid>
        </>
      )}
    </>
  )
}

let scheduleSchema = Yup.object().shape(
  {
    services: Yup.array()
      .min(1, 'Required')
      .of(
        Yup.object().shape({
          saleitem_id: Yup.string().required('Required'),
          description: Yup.string().required('Required'),
          charge: Yup.number().required('Required'),
          man_minutes: Yup.number()
            .required('Required')
            .positive('Must be a positive number'),
        }),
      ),
    route_id: Yup.string().when('freq_type', {
      is: val => val !== 6,
      then: schema => schema.required('Required'),
      otherwise: schema => schema.optional(),
    }),
    freq_type: Yup.number().required('Required'),
    start_at: Yup.string()
      .when('freq_type', {
        is: val => val > 1 && val !== 6,
        then: schema => schema.required('Required'),
        otherwise: schema => schema.optional(),
      })
      .nullable(),
    week: Yup.number()
      .when('freq_type', {
        is: val => val === 0,
        then: schema => schema.required('Required'),
        otherwise: schema => schema.optional(),
      })
      .nullable(),
    day: Yup.number()
      .when('freq_type', {
        is: val => val === 0,
        then: schema => schema.required('Required'),
        otherwise: schema => schema.optional(),
      })
      .nullable(),
    freq_interval: Yup.number()
      .when('freq_type', {
        is: val => val >= 2 && val !== 6,
        then: schema => schema.required('Required'),
        otherwise: schema => schema.optional(),
      })
      .nullable(),
    freq_recurrence_factor: Yup.number()
      .when('freq_type', {
        is: val => val >= 3 && val !== 6,
        then: schema => schema.required('Required'),
        otherwise: schema => schema.optional(),
      })
      .nullable(),
    freq_relative_interval: Yup.number()
      .when('freq_type', {
        is: val => val === 5,
        then: schema => schema.required('Required'),
        otherwise: schema => schema.optional(),
      })
      .nullable(),
    arrival_start_time: Yup.string().when('arrival_end_time', {
      is: val => !!val && val !== '',
      then: schema => schema.required('Required if setting Arrival End Time'),
      otherwise: schema => schema.optional().nullable(),
    }),
    arrival_end_time: Yup.string().when('arrival_start_time', {
      is: val => !!val && val !== '',
      then: schema => schema.required('Required if setting Arrival Start Time'),
      otherwise: schema => schema.optional().nullable(),
    }),
  },
  ['arrival_start_time', 'arrival_end_time'],
)

function EditScheduleDetails({
  schedule,
  jobRequirements,
  children,
  defaultService,
  instanceDate,
  taxable = true,
  onSaveAndContinue,
  disableNewWorkorder = false,
  ...props
}) {
  let [jobReqOpen, setJobReqOpen] = useState(false)
  let {status: canSetCommissionRates} = useAuth('set commission rates')
  let [showDetails, setShowDetails] = useState(null)

  // Yes, theoretically I could have whatever's calling this fetch the available workorders, but that means I have to update it for all the places
  // that call this, and potentially make their screens slower. I'm opting to do it this way.
  let {
    isLoading: woLoading,
    data: woData,
    refetch: refetchWorkorders,
  } = useQuery(
    ['schedule_workorders', schedule?.id],
    () =>
      prgql({
        query: scheduleWorkordersQuery,
        variables: {schedule_id: schedule && schedule.id ? schedule.id : ''},
      }),
    {
      enabled: !(!schedule || schedule.id.includes('new')),
    },
  )
  let [wo, setWo] = useState({})
  let [editWo, setEditWo] = useState(false)
  let [autoOptions, setAutoOptions] = useState([])

  let exclusion =
    schedule && schedule.exclusions
      ? schedule.exclusions
          .filter(e =>
            moment.utc(e.reschedule_at).isSame(moment.utc(instanceDate), 'day'),
          )
          .pop()
      : {}

  let initial = {
    ...schedule,
    delete_services: [],
    exclusion: {
      reschedule_route_id: schedule ? schedule.route_id : 1,
      reschedule_at: instanceDate,
      exclude_at: instanceDate,
      ...exclusion,
    },
  }

  let Workorder = ({wo, onClick}) => (
    <PillBoxWorkorder
      onClick={onClick}
      multiday={wo.multiday}
      id={'workorders-' + wo.id}
      style={{flexDirection: 'column'}}
    >
      <Typography style={{marginLeft: '.5em', fontWeight: 'bold'}}>
        {moment.utc(wo.assigned_at).format('ddd M/D/YY')}, Rt {wo.route.name}{' '}
      </Typography>
      <div style={{display: 'flex', alignItems: 'center'}}>
        <Assignment />
        <Typography>WO {wo.id}</Typography>
        {getStatusIcon(wo.status)}
      </div>
      <Typography style={{marginLeft: '.5em'}}>
        {wo.workorderitems
          ? wo.workorderitems.map(woi => woi.saleitem?.name).join(', ')
          : ''}{' '}
      </Typography>
      <Typography style={{marginLeft: '.5em'}}>
        {wo.workorderitems
          ? wo.workorderitems.reduce((c, woi) => c + woi.man_minutes, 0)
          : 0}{' '}
        mm
      </Typography>
    </PillBoxWorkorder>
  )

  return (
    <>
      <Formik
        initialValues={initial || {}}
        validationSchema={scheduleSchema}
        {...props}
        children={({
          isSubmitting,
          isValid,
          values,
          setFieldValue,
          submitForm,
          validateForm,
          dirty,
          errors,
        }) => {
          let workorders =
            woData && woData.workorders
              ? woData.workorders.edges.map(e => e.edge)
              : []
          let manMinutesSchedule =
            values && values.services
              ? values.services.reduce((c, s) => c + s.man_minutes, 0)
              : 0
          let manMinutesWorkorders = workorders
            .map(wo => wo.workorderitems)
            .flat()
            .reduce((c, woi) => c + woi.man_minutes, 0)

          let form = (
            <>
              <Grid item xs={12}>
                <Typography variant="h6">Recurrence</Typography>
              </Grid>
              <Grid item sm={12} spacing={2} container>
                <Grid item>
                  {values.freq_type === 6 && !!initial.freq_type ? (
                    <Tooltip title="You cannot change the frequency to this type. Please create a new schedule">
                      <Chip
                        label="Recurring"
                        icon={<RepeatIcon />}
                        id="recurring"
                        style={{color: 'grey'}}
                      />
                    </Tooltip>
                  ) : (
                    <Chip
                      label="Recurring"
                      icon={<RepeatIcon />}
                      onClick={() => {
                        setFieldValue('start_at', null)
                        setFieldValue('week', null)
                        setFieldValue('day', null)
                        setFieldValue('freq_type', 2)
                      }}
                      id="recurring"
                      color={
                        (values.freq_type >= 2 && values.freq_type <= 5) ||
                        values.freq_type === 0
                          ? 'primary'
                          : undefined
                      }
                    />
                  )}
                </Grid>
                {/* I give up */}
                <Grid item style={{marginBottom: '15px'}}>
                  {values.freq_type === 6 && !!initial.freq_type ? (
                    <Tooltip title="You cannot change the frequency to this type. Please create a new schedule">
                      <Chip
                        label="One Time"
                        icon={<RepeatOneIcon />}
                        id="onetime"
                        style={{color: 'grey'}}
                      />
                    </Tooltip>
                  ) : (
                    <Chip
                      label="One Time"
                      icon={<RepeatOneIcon />}
                      onClick={() => {
                        setFieldValue(
                          'start_at',
                          moment()
                            .add(1, 'd')
                            .format('YYYY-MM-DD'),
                        )
                        setFieldValue('week', null)
                        setFieldValue('day', null)
                        setFieldValue('freq_type', 1)
                      }}
                      id="onetime"
                      color={values.freq_type === 1 ? 'primary' : undefined}
                    />
                  )}
                </Grid>
                <Grid item>
                  {values.freq_type !== 6 && !!initial.freq_type ? (
                    <Tooltip title="You cannot change the frequency to this type. Please create a new schedule">
                      <Chip
                        label="Multi-Day"
                        icon={<DateRange />}
                        id="multiday"
                        style={{color: 'grey'}}
                      />
                    </Tooltip>
                  ) : (
                    <Chip
                      label="Multi-Day"
                      icon={<DateRange />}
                      onClick={() => {
                        setFieldValue('freq_type', 6)
                      }}
                      id="multiday"
                      color={values.freq_type === 6 ? 'primary' : undefined}
                    />
                  )}
                </Grid>
              </Grid>

              {values.freq_type === 6 && (
                <Grid item>
                  <Space />
                  <div>
                    <Typography variant="h6">Workorders</Typography>
                    <Typography
                      style={{
                        color:
                          manMinutesWorkorders - manMinutesSchedule < 0
                            ? theme.palette.danger.main
                            : undefined,
                        fontWeight:
                          manMinutesWorkorders - manMinutesSchedule < 0
                            ? 'bold'
                            : undefined,
                      }}
                    >
                      {manMinutesWorkorders} / {manMinutesSchedule} Man Minutes
                    </Typography>
                  </div>
                  <Space />
                  <Grid container spacing={2} direction="row" item>
                    {!workorders.length ? (
                      <Grid item lg={6}>
                        <PillBoxEmpty style={{minWidth: '200px'}}>
                          <Typography>add workorders</Typography>
                        </PillBoxEmpty>
                      </Grid>
                    ) : (
                      workorders
                        .sort((a, b) =>
                          moment(a.assigned_at).isBefore(b.assigned_at)
                            ? -1
                            : 1,
                        )
                        .map(wo => (
                          <Grid item key={wo.id}>
                            <Workorder
                              wo={wo}
                              onClick={() => {
                                setWo(wo)
                                setEditWo(true)
                              }}
                            />
                          </Grid>
                        ))
                    )}
                  </Grid>
                  <Space />
                  <Grid item>
                    <Button
                      color="secondary"
                      variant="contained"
                      size="small"
                      disabled={
                        !isValid ||
                        schedule.id.includes('new') ||
                        disableNewWorkorder
                      }
                      onClick={() => {
                        setWo({
                          status: 'OPEN',
                          assigned_at: moment().format('YYYY-MM-DD'),
                          customer_id: schedule.customer_id,
                          schedule_id: schedule.id,
                          multiday: true,
                        })
                        setEditWo(true)
                        setAutoOptions(
                          values.services.map(s => ({
                            saleitem_id: s.saleitem_id,
                            saleitem: s.saleitem,
                            description: s.description,
                            notes: s.notes,
                            man_minutes: s.man_minutes,
                          })),
                        )
                      }}
                      id="addWorkorder"
                    >
                      <AddIcon />
                    </Button>
                    {schedule && schedule.id.includes('new') && (
                      <Typography>
                        Please save and reopen the schedule to add workorders
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              )}

              <Grid item container spacing={2}>
                {values.freq_type !== 6 && values.freq_type !== null && (
                  <>
                    <Grid item sm={2}>
                      <AutoCompleteField
                        as={RouteSelect}
                        name="route_id"
                        selectedId={values.route_id}
                        fullWidth
                        inputVariant={'filled'}
                      />
                    </Grid>

                    <Recurrence values={values} />

                    {values.freq_type !== 1 && (
                      <>
                        <Grid
                          item
                          sm={2}
                          style={{display: 'flex', alignItems: 'center'}}
                        >
                          <DatePicker
                            name="start_at"
                            inputVariant="filled"
                            label="Start"
                            format="dddd, MMMM D, YYYY"
                            fullWidth
                            clearable
                          />
                        </Grid>
                        <Grid
                          item
                          style={{display: 'flex', alignItems: 'center'}}
                        >
                          <ArrowForward />
                        </Grid>
                        <Grid
                          item
                          sm={2}
                          style={{display: 'flex', alignItems: 'center'}}
                        >
                          <DatePicker
                            name="end_at"
                            inputVariant="filled"
                            label="End"
                            format="dddd, MMMM D, YYYY"
                            fullWidth
                            clearable
                          />
                        </Grid>
                      </>
                    )}
                    <Grid item md="2" display={'flex'}>
                      <TimePicker
                        name="arrival_start_time"
                        label="Arrival Start Time"
                        placeholder="08:00 AM"
                        mask="__:__ _M"
                        inputVariant="filled"
                        clearable
                      />
                      <IconButton
                        size="small"
                        onClick={() =>
                          setFieldValue('arrival_start_time', null)
                        }
                      >
                        <Close />
                      </IconButton>
                    </Grid>
                    <Grid item md="2" display={'flex'}>
                      <TimePicker
                        name="arrival_end_time"
                        label="Arrival End Time"
                        placeholder="08:00 AM"
                        mask="__:__ _M"
                        inputVariant="filled"
                      />
                      <IconButton
                        size="small"
                        onClick={() => setFieldValue('arrival_end_time', null)}
                      >
                        <Close />
                      </IconButton>
                    </Grid>
                  </>
                )}
              </Grid>

              <Grid item sx={12}>
                <Space size={1} />
              </Grid>

              <FieldArray name="services">
                {({insert, remove, push, replace}) => (
                  <Grid item container spacing={2}>
                    <Grid item sx={12}>
                      <Typography variant="h6">
                        Services{' '}
                        {values.services?.length
                          ? `(${values.services?.length})`
                          : ''}
                      </Typography>
                    </Grid>
                    {values.services &&
                      values.services.map((s, selectedService) => (
                        <HoverGrid
                          container
                          item
                          spacing={2}
                          sm={12}
                          key={selectedService}
                        >
                          <Grid
                            item
                            xs={12}
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}
                          >
                            <Typography variant="h6">
                              {values.services[selectedService]?.saleitem
                                ?.description || 'New Service'}{' '}
                              {formatMoneyStandard(
                                values.services[selectedService]?.charge || 0,
                              )}
                            </Typography>
                            <IconButton
                              size="small"
                              style={{color: theme.palette.danger.main}}
                              onClick={() => {
                                let deleteID =
                                  values.services[selectedService].id
                                // this is probably evil; you are supposed to use setFieldValue but it doesn't like setting it to an array.
                                // Probably b/c Formik wants you to use the <FieldArray>. There might be a way to use that for this but meh
                                if (deleteID)
                                  values.delete_services = [
                                    ...values.delete_services,
                                    deleteID,
                                  ]

                                remove(selectedService)
                                // Is there a better way to do this? Probably. Do I know what it is? Absolutely not.
                                setTimeout(() => {
                                  validateForm()
                                }, 1)
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Grid>
                          <Grid item xs={2}>
                            <AutoCompleteField
                              as={SaleItemSelect}
                              name={`services.${selectedService}.saleitem_id`}
                              selectedId={
                                values.services[selectedService].saleitem_id
                              }
                              onSelectChange={i => {
                                setFieldValue(
                                  `services.${selectedService}.saleitem`,
                                  i,
                                )
                                setFieldValue(
                                  `services.${selectedService}.taxable`,
                                  taxable ? i && i.taxable : false,
                                )
                              }}
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={5}>
                            <TextField
                              variant="filled"
                              label="Service"
                              fullWidth
                              name={`services.${selectedService}.description`}
                              multiline
                            />
                          </Grid>
                          <Grid item lg={2}>
                            <TextField
                              name={`services.${selectedService}.charge`}
                              variant="filled"
                              type="number"
                              label="Amount"
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={2}>
                            <AutoCompleteField
                              as={DiscountSelect}
                              name={`services.${selectedService}.discount_id`}
                              selectedId={
                                values.services[selectedService].discount_id
                              }
                              inputProps={{variant: 'filled'}}
                              onSelectChange={i => {
                                setFieldValue(
                                  `services.${selectedService}.discount`,
                                  i,
                                )
                              }}
                              fullWidth
                            />
                          </Grid>
                          <Grid item lg={1}>
                            <TextField
                              name={`services.${selectedService}.man_minutes`}
                              variant="filled"
                              type="number"
                              label="Man Minutes"
                              fullWidth
                            />
                          </Grid>
                          <Grid item sm={3}>
                            <AutoCompleteField
                              as={UsersSelect}
                              name={`services.${selectedService}.sales_person_id`}
                              selectedUserId={
                                values.services[selectedService].sales_person_id
                              }
                              fullWidth
                              label="Sales Person"
                              inputVariant="filled"
                              onSelectChange={value => {
                                if (
                                  !values.services[selectedService]
                                    .commission_start_at
                                ) {
                                  setFieldValue(
                                    `services.${selectedService}.commission_start_at`,
                                    moment().toDate(),
                                  )
                                }
                              }}
                            />
                          </Grid>
                          <Grid item xs={2}>
                            <DatePicker
                              name={`services.${selectedService}.commission_start_at`}
                              inputVariant="filled"
                              label="Commission Start"
                              format="dddd, MMMM D, YYYY"
                              fullWidth
                              disabled={canSetCommissionRates !== 'authorized'}
                            />
                          </Grid>

                          <Grid item xs={12} container alignItems={'center'}>
                            <Typography
                              variant="subtitle1"
                              onClick={() =>
                                setShowDetails(
                                  showDetails === selectedService
                                    ? null
                                    : selectedService,
                                )
                              }
                            >
                              Service Details
                            </Typography>{' '}
                            <IconButton
                              size="small"
                              onClick={() =>
                                setShowDetails(
                                  showDetails === selectedService
                                    ? null
                                    : selectedService,
                                )
                              }
                              id="jobreqDisclosure"
                            >
                              {showDetails === selectedService ? (
                                <KeyboardArrowDown />
                              ) : (
                                <ChevronRight />
                              )}
                            </IconButton>
                          </Grid>

                          <Collapse
                            in={showDetails === selectedService}
                            style={{width: '100%'}}
                          >
                            <Grid
                              item
                              xs={12}
                              container
                              spacing={2}
                              style={{padding: '10px 30px'}}
                            >
                              <Grid item xs={12}>
                                <Typography variant="subtitle2">
                                  Tools & Materials
                                </Typography>{' '}
                              </Grid>
                              <FieldArray
                                name={`services.${selectedService}.jobrequirements`}
                              >
                                {({remove, push}) => (
                                  <Grid container item xs={12} spacing={2}>
                                    {values.services[
                                      selectedService
                                    ].jobrequirements?.map((req, i) => (
                                      <Grid item xs={12} container spacing={2}>
                                        <Grid item xs={5}>
                                          <AutoCompleteField
                                            as={JobrequirementtypeSelect}
                                            name={`services.${selectedService}.jobrequirements.${i}.jobrequirementtype_id`}
                                            selectedId={
                                              values.services[selectedService]
                                                .jobrequirements[i]
                                                .jobrequirementtype_id
                                            }
                                            fullWidth
                                          />
                                        </Grid>
                                        <Grid item xs={5}>
                                          <TextField
                                            name={`services.${selectedService}.jobrequirements.${i}.qty`}
                                            variant="filled"
                                            size="small"
                                            type="number"
                                            label="qty"
                                            fullWidth
                                          />
                                        </Grid>
                                        <Grid item xs={1}>
                                          <IconButton
                                            color="error"
                                            onClick={() => remove(i)}
                                            id={`delJobReq${i}`}
                                          >
                                            <Delete />
                                          </IconButton>
                                        </Grid>
                                      </Grid>
                                    ))}
                                    <Grid item xs={12}>
                                      <Button
                                        size="small"
                                        variant="contained"
                                        color="secondary"
                                        id="newJobReq"
                                        onClick={() =>
                                          push({
                                            id: 'new',
                                            qty: 0,
                                            jobrequirementtype_id: '',
                                          })
                                        }
                                      >
                                        <Add />
                                      </Button>
                                    </Grid>
                                  </Grid>
                                )}
                              </FieldArray>
                              <Grid item xs={12}>
                                <Typography variant="subtitle2">
                                  Tasks
                                </Typography>{' '}
                                <Typography variant="caption">
                                  Tasks must be completed first before
                                  completing the workorder on the mobile app
                                </Typography>{' '}
                              </Grid>
                              <FieldArray
                                name={`services.${selectedService}.tasks`}
                              >
                                {({remove, push}) => (
                                  <Grid container item xs={12} spacing={2}>
                                    {values.services[
                                      selectedService
                                    ].tasks?.map((req, i) => (
                                      <Grid item xs={12} container spacing={2}>
                                        <Grid item xs={6}>
                                          <AutoCompleteField
                                            as={TaskSelect}
                                            name={`services.${selectedService}.tasks.${i}.tasktype_id`}
                                            initialInputValue={
                                              values.services[selectedService]
                                                ?.tasks[i]?.description || ''
                                            }
                                            inputProps={{variant: 'filled'}}
                                            selectedId={
                                              values.services[selectedService]
                                                .tasks[i].tasktype_id
                                            }
                                            onSelectChange={v =>
                                              setFieldValue(
                                                `services.${selectedService}.tasks.${i}.description`,
                                                v.id === 'freeSolo'
                                                  ? v.description
                                                  : null,
                                              )
                                            }
                                            fullWidth
                                          />
                                        </Grid>
                                        <Grid item xs={1}>
                                          <IconButton
                                            color="error"
                                            onClick={() => remove(i)}
                                            id={`delTask${i}`}
                                          >
                                            <Delete />
                                          </IconButton>
                                        </Grid>
                                      </Grid>
                                    ))}
                                    <Grid item xs={12}>
                                      <Button
                                        size="small"
                                        variant="contained"
                                        color="secondary"
                                        id="newTask"
                                        onClick={() =>
                                          push({
                                            id: 'new',
                                            tasktype_id: '',
                                          })
                                        }
                                      >
                                        <Add />
                                      </Button>
                                    </Grid>
                                  </Grid>
                                )}
                              </FieldArray>
                              <Grid item xs={12}>
                                <Typography variant="subtitle2">
                                  Technician Notes
                                </Typography>{' '}
                                <Typography variant="caption">
                                  Extra info the tech needs to know
                                </Typography>{' '}
                              </Grid>
                              <Grid item xs={5}>
                                <TextField
                                  variant="filled"
                                  label="Technician Notes"
                                  fullWidth
                                  name={`services.${selectedService}.notes`}
                                  multiline
                                />
                              </Grid>
                            </Grid>
                          </Collapse>
                          <Grid item xs={12}>
                            <Divider />
                          </Grid>
                        </HoverGrid>
                      ))}
                    <Grid item>
                      <Button
                        color="secondary"
                        variant="contained"
                        size="small"
                        onClick={() => {
                          push(
                            defaultService || {
                              saleitem_id: 0,
                              description: '',
                              amount: 0,
                              man_minutes: 0,
                            },
                          )
                          // Is there a better way to do this? Probably. Do I know what it is? Absolutely not.
                          setTimeout(() => {
                            validateForm()
                          }, 1)
                        }}
                        id="addService"
                      >
                        <AddIcon />
                      </Button>
                    </Grid>
                  </Grid>
                )}
              </FieldArray>
              <EditWorkorder
                wo={wo}
                open={editWo}
                // onBeforeSave={async data => await onSaveAndContinue(values)}
                onSave={refetchWorkorders}
                onClose={() => setEditWo(false)}
                showMultidayVersion
                showStatusChange={!!wo && !!Number.parseInt(wo.id)}
                autocreateOptions={autoOptions}
              />
            </>
          )

          let excludeForm = (
            <>
              <Grid item xs={12}>
                <Typography variant="h6">Reschedule</Typography>
              </Grid>
              {/* I give up */}
              <Grid
                item
                sm={12}
                spacing={2}
                container
                style={{marginBottom: '15px'}}
              >
                <Grid item>
                  <Chip
                    label="Reschedule"
                    icon={<ArrowForwardRounded />}
                    onClick={() => {
                      setFieldValue(
                        'exclusion.reschedule_at',
                        moment.utc(instanceDate).add(1, 'day'),
                      )
                    }}
                    color={
                      values.exclusion.reschedule_at ? 'primary' : undefined
                    }
                    id="reschedule"
                  />
                </Grid>
                <Grid item>
                  {schedule && schedule.freq_type !== 1 && (
                    <Chip
                      label="Cancel Job"
                      icon={<CancelOutlined />}
                      onClick={() => {
                        setFieldValue('exclusion.reschedule_at', null)
                      }}
                      color={
                        !values.exclusion.reschedule_at ? 'primary' : undefined
                      }
                      id="cancel-job"
                    />
                  )}
                </Grid>
              </Grid>
              <Grid container sm={12} spacing={2}>
                <Grid item>
                  <AutoCompleteField
                    as={RouteSelect}
                    name="exclusion.reschedule_route_id"
                    selectedId={values.exclusion.reschedule_route_id}
                    fullWidth
                    disabled={!values.exclusion.reschedule_at}
                    inputVariant={'filled'}
                  />
                </Grid>
                <Grid item>
                  <DatePicker
                    name="exclusion.reschedule_at"
                    inputVariant="filled"
                    label="Reschedule to"
                    format="dddd, MMMM D, YYYY"
                    fullWidth
                    disabled={!values.exclusion.reschedule_at}
                  />
                </Grid>
              </Grid>
            </>
          )

          if (typeof children === 'function') {
            return children({
              submitForm,
              isSubmitting,
              form,
              isValid,
              excludeForm,
              values,
              setFieldValue,
              dirty,
            })
          } else {
            return form
          }
        }}
      />
    </>
  )
}

let SchedulesWrapper = styled(Paper)`
  padding: 0 16px 16px 16px;
`
let SchedulesContent = styled.div`
  display: flex;
  justify-content: center;
`
let ScheduleRow = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 0;
  ${({theme, active}) =>
    active &&
    `
  background-color: ${theme.muiTheme.palette.grey[200]};
  opacity: 0.6;
  `}
`
let ScheduleAmount = styled.div`
  flex: 0 0 7em;
`
let ScheduleDetail = styled.div`
  flex: 1 1 auto;
`
let ScheduleTiming = styled.div`
  flex: 0 0 12em;
`
let ScheduleActions = styled.div`
  flex: 0 0 6em;
`

function NextScheduleDateText({date, route}) {
  return (
    <Typography>
      <Link
        to={
          date && route
            ? `/schedules/daily?date=${moment
                .utc(date)
                .format('YYYY-MM-DD')}&routes=${route}`
            : ''
        }
      >
        {date ? moment.utc(date).format('dddd, M/D/YYYY') : 'None'}
      </Link>
    </Typography>
  )
}

let SendEmailDialog = ({isOpen, onCancel, onConfirm, loading}) => {
  return (
    <Dialog
      open={isOpen}
      onClose={onCancel}
      maxWidth="lg"
      fullWidth
      PaperProps={{style: {height: '80vh'}}}
    >
      <DialogTitle>Send customer care email</DialogTitle>
      <DialogContent>
        <iframe id="boogabooga" width={'100%'} height="100%"></iframe>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <LoadingButton
          onClick={onConfirm}
          loading={loading}
          autoFocus
          color="primary"
          variant="contained"
        >
          <Email /> Send Email
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

let SendChristmasDialog = ({
  isOpen,
  onCancel,
  onConfirm,
  loading,
  photos = [],
  selectedPhotoIds = [],
  onSelectPhotos,
}) => {
  return (
    <Dialog
      open={isOpen}
      onClose={onCancel}
      maxWidth="lg"
      fullWidth
      PaperProps={{style: {height: '80vh'}}}
    >
      <DialogTitle>Send christmas care email</DialogTitle>
      <DialogContent>
        <Typography variant="h6" color={'grey'}>
          Select Photos to attach to the email:
        </Typography>
        <div
          style={{
            display: 'flex',
            overflow: 'auto',
            gap: '5px',
            height: '100px',
          }}
        >
          {photos.map(p => (
            <MiniPRImage
              hover
              key={p.id}
              src={p.thumb_url}
              style={{
                width: '100px',
                height: '100px',
              }}
              selected={selectedPhotoIds.includes(p.id)}
              onClick={() => onSelectPhotos(p.id)}
            />
          ))}
        </div>
        <Space sizePx={10} />
        <Typography variant="h6" color={'grey'}>
          Preview
        </Typography>
        <iframe id="boogabooga" width={'100%'} height="100%"></iframe>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <LoadingButton
          onClick={onConfirm}
          loading={loading}
          autoFocus
          color="primary"
          variant="contained"
        >
          <Email /> Send Email
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

let SchedulesFragment = gql`
  fragment SchedulesDetail on Schedules {
    id
    route_id
    customer_id
    charge
    man_minutes
    sequence
    start_at
    end_at
    freq_type
    freq_interval
    freq_relative_interval
    freq_recurrence_factor
    # next_occurrence_at
    # next_occurrence_route_id
    arrival_start_time
    arrival_end_time
    week
    day
    recurrence {
      id
      next_occurrence_at
      next_occurrence_route_id
    }
    services {
      id
      saleitem_id
      saleitem {
        id
        name
        description
      }
      taxable
      customer_id
      sales_person_id
      sales_person {
        id
        first_name
        last_name
      }
      jobrequirements {
        id
        jobrequirementtype_id
        qty
      }
      tasks {
        id
        description
        tasktype_id
        tasktype {
          id
          description
        }
      }
      discount_id
      discount {
        id
        name
        type
        amount
        active
      }
      description
      notes
      man_minutes
      charge
      sales_person_id
      commission_start_at
    }
  }
`

let SchedulesQuery = gql`
  query CustomerSchedules($customerId: ID) {
    allSchedules(
      cursor: "-1"
      limit: 100
      filters: {customer_id: $customerId} # orderBy: next_occurrence_at_ASC
    ) {
      edges {
        edge {
          ...SchedulesDetail
        }
      }
    }
    allJobrequirementtypes(limit: 100000) {
      edges {
        edge {
          id
          name
        }
      }
    }
  }
  ${SchedulesFragment}
`
let UpdateScheduleQuery = gql`
  mutation updateSchedules($schedule: UpdateSchedulesInput) {
    updateSchedules(input: $schedule) {
      schedules {
        ...SchedulesDetail
      }
    }
  }
  ${SchedulesFragment}
`
let CreateScheduleQuery = gql`
  mutation createSchedules($schedule: CreateSchedulesInput) {
    createSchedules(input: $schedule) {
      schedules {
        ...SchedulesDetail
      }
    }
  }
  ${SchedulesFragment}
`
let DeleteScheduleQuery = gql`
  mutation deleteSchedules($id: String!) {
    deleteSchedules(input: {id: $id}) {
      message
    }
  }
`

let SendCustomerCareMutation = gql`
  mutation($input: EmailCustomerCareInput, $id: ID!) {
    emailCustomerCare(input: $input, customerId: $id) {
      message
    }
  }
`
let SendChristmasCareMutation = gql`
  mutation($input: EmailChristmasCareInput, $id: ID!) {
    emailChristmasCare(input: $input, customerId: $id) {
      message
    }
  }
`

let CreateExclusionMutation = gql`
  mutation createExclusions($exclusion: CreateExclusionsInput) {
    createExclusions(input: $exclusion) {
      exclusions {
        id
      }
    }
  }
`

let UpdateExclusionMutation = gql`
  mutation updateExclusions($exclusion: UpdateExclusionsInput) {
    updateExclusions(input: $exclusion) {
      exclusions {
        id
      }
    }
  }
`
let DeleteExclusionMutation = gql`
  mutation deleteExclusions($exclusion: DeleteExclusionsInput) {
    deleteExclusions(input: $exclusion) {
      message
    }
  }
`

let DeleteServiceMutation = gql`
  mutation deleteServices($service: DeleteServicesInput) {
    deleteServices(input: $service) {
      message
    }
  }
`

let ScheduleIcon = ({
  isRecurring,
  isMultiday,
  hovering,
  checked,
  onCheck,
  id,
}) => {
  return (
    <div style={{width: '50px', height: '50px'}}>
      {hovering || checked ? (
        <MuiCheckbox
          value={checked}
          onChange={onCheck}
          id={id + '-checkbox'}
          color="secondary"
        />
      ) : isMultiday ? (
        <DateRange
          style={{width: '2em', height: '2em'}}
          color="action"
          id={id}
        />
      ) : isRecurring ? (
        <RepeatIcon
          style={{width: '2em', height: '2em'}}
          color="action"
          id={id}
        />
      ) : (
        <RepeatOneIcon
          style={{width: '2em', height: '2em'}}
          color="action"
          id={id}
        />
      )}
    </div>
  )
}

export default function CustomerSchedules({
  customerId,
  active,
  customer,
  paused,
}) {
  let [scheduleDeleteId, setScheduleDeleteId] = React.useState(null)
  let [editSchedule, setEditSchedule] = React.useState(null)
  let [newScheduleId, setNewScheduleId] = React.useState(1)
  let [selectedPhotoIds, setSelectedPhotoIds] = React.useState([])
  let {loading, error, data, refetch: refetchSchedules} = useQuery(
    ['schedules'],
    () =>
      prgql({
        query: SchedulesQuery,
        variables: {customerId},
      }),
  )
  let {mutateAsync: createSchedule} = useMutation(props =>
    prgql({query: CreateScheduleQuery, ...props}),
  )
  let {
    mutateAsync: deleteSchedule,
    isLoading: deleteLoading,
  } = useMutation(props => prgql({query: DeleteScheduleQuery, ...props}))

  let {
    mutateAsync: sendCustomerCare,
    isLoading: customerCareLoading,
  } = useMutation(props => prgql({query: SendCustomerCareMutation, ...props}))

  let {
    mutateAsync: sendChristmasCare,
    isLoading: christmasCareLoading,
  } = useMutation(props => prgql({query: SendChristmasCareMutation, ...props}))

  let queryClient = useQueryClient()

  let [iconHovering, setIconHovering] = useState('')
  let [iconChecked, setIconChecked] = useState({})
  let [showSendEmail, setShowSendEmail] = useState(false)
  let [showSendChristmas, setShowSendChristmas] = useState(false)
  let [saleData, setSaleData] = useState({})
  let [showSale, setShowSale] = useState(false)

  let {status: canManageSales} = useAuth('manage sales records')
  let {currentUser} = useCurrentUser()

  function _emailPreview() {
    let response = fetchAPI({
      url: `/emails/preview?type=customercare&id=${customerId}&schedules=${Object.entries(
        iconChecked,
      )
        .filter(([key, value]) => value)
        .map(([key, value]) => key)
        .join(',')}`,
      options: {method: 'POST'},
    })
    response.then(async res => {
      let blob = await res.blob()
      let data_url = URL.createObjectURL(blob)
      document.querySelector('#boogabooga').src = data_url
    })
  }

  function _christmasPreview(selectedPhotoIds = []) {
    let response = fetchAPI({
      url: `/emails/preview?type=christmascare&id=${customerId}&schedules=${Object.entries(
        iconChecked,
      )
        .filter(([key, value]) => value)
        .map(([key, value]) => key)
        .join(',')}&photos=${selectedPhotoIds.join(',')}`,
      options: {method: 'POST'},
    })
    response.then(async res => {
      let blob = await res.blob()
      let data_url = URL.createObjectURL(blob)
      document.querySelector('#boogabooga').src = data_url
    })
  }

  async function _deepCopySchedules() {
    Promise.all(
      Object.entries(iconChecked)
        .filter(([key, value]) => value)
        .map(([key, value]) => key)
        .map(id => {
          if (!data.allSchedules.edges || !data.allSchedules.edges.length)
            return
          let {...edge} = data.allSchedules.edges
            .filter(e => e !== undefined && e.edge.id === String(id))
            .pop().edge
          let {
            id: blah,
            __typename,
            charge,
            man_minutes,
            next_occurrence_at,
            next_occurrence_route_id,
            recurrence,
            ...payload
          } = {
            ...edge,
            services: {
              create: [
                ...(edge.services
                  ? edge.services.map(s => {
                      let {
                        id,
                        saleitem,
                        __typename,
                        sales_person,
                        jobrequirements,
                        tasks,
                        discount,
                        ...service
                      } = s
                      let [...jobReqs] = jobrequirements.map(j => {
                        let {__typename, name, id, ...rest} = j
                        return rest
                      })
                      let [...tsks] = tasks.map(j => {
                        let {__typename, id, tasktype, ...rest} = j
                        return rest
                      })

                      let returnObj = {
                        ...service,
                        description: `COPY - ${service.description}`,
                      }
                      if (jobReqs.length)
                        returnObj.jobrequirements = {
                          create: jobReqs,
                          api_type: 'jobrequirements',
                        }
                      if (tsks.length)
                        returnObj.tasks = {
                          create: tsks,
                          api_type: 'tasks',
                        }

                      return returnObj
                    })
                  : []),
              ],
            },
          }
          return createSchedule({variables: {schedule: payload}})
        }),
    ).then(refetchSchedules)
  }

  function _showSaleForm(initial = {}) {
    let selectedSchedules = Object.entries(iconChecked)
      .filter(([key, value]) => value)
      .map(([key, value]) => key)
      .map(id => {
        if (!data.allSchedules.edges || !data.allSchedules.edges.length) return
        let {...edge} = data.allSchedules.edges
          .filter(e => e !== undefined && e.edge.id === String(id))
          .pop().edge
        return edge
      })

    const applyDiscount = (amount, discount) => {
      if (discount.type === 2) {
        return amount - discount.amount
      }
      return amount - amount * (discount.amount / 100)
    }

    let saleData = {
      ...initial,
      customer_id: customerId,
      sales_person_id: currentUser.id,
      // source_id:
      incoming: true,
      status: 'PENDING',
      sold_at: moment().format('YYYY-MM-DD'),
      salelines: Object.values(
        selectedSchedules
          .map(s => s.services)
          .flat(1)
          .reduce((acc, s, i) => {
            if (!acc[s.saleitem_id]) {
              acc[s.saleitem_id] = {
                id: 'new' + i,
                saleitem_id: s.saleitem_id,
                description: s.description,
                amount: s.discount
                  ? applyDiscount(s.charge, s.discount)
                  : s.charge,
                discount_id: s.discount_id,
              }
            } else {
              acc[s.saleitem_id].amount += s.discount
                ? applyDiscount(s.charge, s.discount)
                : s.charge
              acc[s.saleitem_id].description += ', ' + s.description
              acc[s.saleitem_id].discount_id =
                !!s.discount_id && s.discount_id !== '0'
                  ? s.discount_id
                  : acc[s.saleitem_id].discount_id
            }
            return acc
          }, {}),
      ),
    }

    setSaleData(saleData)
    setShowSale(true)
  }

  function _getArrivalText(schedule) {
    let arrivalStart = ''
    let arrivalEnd = ''
    if (schedule.arrival_start_time)
      arrivalStart = moment
        .utc(schedule.arrival_start_time, 'hh:mm:ss')
        .format('h:mm a')
    if (schedule.arrival_end_time)
      arrivalEnd = moment
        .utc(schedule.arrival_end_time, 'hh:mm:ss')
        .format('h:mm a')

    if (!arrivalStart && !arrivalEnd) return ''

    if (!arrivalStart || !arrivalEnd) {
      return 'around ' + (arrivalStart || arrivalEnd)
    }

    return `between ${arrivalStart} and ${arrivalEnd}`
  }

  function _getFormulaText(schedule) {
    if (schedule.freq_type === 1)
      return `One Time on ${moment.utc(schedule.start_at).format('M/D/YY')}`

    let text = ''
    switch (schedule.freq_type) {
      case 0:
        text = `(4 Week Cycle) ${DAYS_OF_WEEK[schedule.day]}, Week ${
          schedule.week
        }`
        break
      case 2:
        text = `Every ${schedule.freq_interval} days`
        break
      case 3:
        text = `Every ${schedule.freq_recurrence_factor} weeks on ${
          DAYS_OF_WEEK[schedule.freq_interval]
        }`
        break
      case 4:
        text = `Every ${schedule.freq_recurrence_factor} months on day ${schedule.freq_interval}`
        break
      case 5:
        text = `Every ${schedule.freq_recurrence_factor} months on the ${
          ORDINALS[schedule.freq_relative_interval]
        } ${DAYS_OF_WEEK[schedule.freq_interval]}`
        break
      case 6:
        text = `Multi-day job`
        break
      default:
        text = 'Every so often'
        break
    }

    if (schedule.freq_type === 6) return text

    if (schedule.end_at && schedule.start_at)
      return (
        text +
        ` from ${moment
          .utc(schedule.start_at)
          .format('M/D/YY')} to ${moment.utc(schedule.end_at).format('M/D/YY')}`
      )

    if (schedule.end_at)
      return text + ` ending on ${moment.utc(schedule.end_at).format('M/D/YY')}`

    if (schedule.start_at)
      return (
        text + ` starting on ${moment.utc(schedule.start_at).format('M/D/YY')}`
      )

    return text
  }

  if (loading && !data) {
    return (
      <SchedulesWrapper>
        <DetailToolbar>
          <ToolbarTitle style={{display: 'flex', alignItems: 'center'}}>
            {paused && <Pause />}
            Schedules
          </ToolbarTitle>
        </DetailToolbar>
        <SchedulesContent>
          <CircularProgress />
        </SchedulesContent>
      </SchedulesWrapper>
    )
  }

  if (error || !data) {
    return (
      <SchedulesWrapper>
        <DetailToolbar>
          <ToolbarTitle style={{display: 'flex', alignItems: 'center'}}>
            {paused && <Pause />}
            Schedules
          </ToolbarTitle>
        </DetailToolbar>
        <SchedulesContent>
          <Typography>Oops. Something went wrong.</Typography>
        </SchedulesContent>
      </SchedulesWrapper>
    )
  }

  return (
    <SchedulesWrapper>
      <DetailToolbar>
        <ToolbarTitle style={{display: 'flex', alignItems: 'center'}}>
          {paused && <Pause />}
          Schedules
        </ToolbarTitle>
        <ToolbarRight>
          {Object.values && Object.values(iconChecked).every(x => !x) ? (
            <>
              <Button
                id="addSchedule"
                onClick={() => {
                  setEditSchedule({
                    id: 'new' + newScheduleId,
                    customer_id: customerId,
                    week: 1,
                    day: 1,
                    commission_start_at: moment.utc().format('YYYY-MM-DD'),
                    commission_percent: 10,
                    gpm_percent: 100,
                    services: [],
                  })
                }}
              >
                <AddIcon />
              </Button>
              {/* feels a little weird to put this here but it's the best I can do. */}
              {canManageSales === 'authorized' && (
                <Tooltip title="Create rejected Sale">
                  <Button
                    id="createSale"
                    onClick={() => _showSaleForm({sold: false})}
                  >
                    <MoneyOff />
                  </Button>
                </Tooltip>
              )}
            </>
          ) : (
            <>
              <Tooltip title="Send Customer Care Email">
                <Button
                  id="emailCustomerCare"
                  onClick={() => {
                    setShowSendEmail(true)
                    _emailPreview()
                  }}
                >
                  <Email />
                </Button>
              </Tooltip>
              <Tooltip title="Send Christmas Care Email">
                <Button
                  id="emailChristmasCare"
                  onClick={() => {
                    setShowSendChristmas(true)
                    _christmasPreview(selectedPhotoIds)
                  }}
                >
                  <Park />
                </Button>
              </Tooltip>
              <Tooltip title="Duplicate">
                <Button
                  id="duplicateSchedule"
                  onClick={() => {
                    _deepCopySchedules()
                  }}
                  disabled={loading}
                >
                  <FileCopy />
                </Button>
              </Tooltip>
              {canManageSales === 'authorized' && (
                <Tooltip title="Create Sale">
                  <Button id="createSale" onClick={() => _showSaleForm()}>
                    <AttachMoney />
                  </Button>
                </Tooltip>
              )}
            </>
          )}
        </ToolbarRight>
      </DetailToolbar>

      <SaleDetailDialog
        open={showSale}
        onClose={() => {
          setShowSale(false)
        }}
        sale={saleData}
      />

      <EditScheduleModal
        editSchedule={editSchedule}
        jobRequirements={data.allJobrequirementtypes}
        setEditSchedule={setEditSchedule}
        setNewScheduleId={setNewScheduleId}
        refetchSchedules={refetchSchedules}
        refetchSchedulesLoading={loading}
        customer={customer}
        key={editSchedule && editSchedule.id}
      />

      <DeleteDialog
        title={
          scheduleDeleteId
            ? `Delete ${scheduleDeleteId.services
                .map(s => `${s.saleitem.name} - ${s.description}`)
                .join(', ')}?`
            : `Delete this Schedule?`
        }
        message="Are you sure you want to delete this schedule?"
        isOpen={!!scheduleDeleteId}
        onCancel={() => {
          setScheduleDeleteId(null)
        }}
        loading={deleteLoading}
        onConfirm={() => {
          deleteSchedule({variables: {id: scheduleDeleteId.id}})
            .then(refetchSchedules)
            .then(() => {
              setScheduleDeleteId(null)
              setIconChecked({})
            })
            .then(() => queryClient.invalidateQueries(['customer']))
        }}
      />

      <SendEmailDialog
        isOpen={showSendEmail}
        onCancel={() => setShowSendEmail(false)}
        loading={customerCareLoading}
        onConfirm={() => {
          sendCustomerCare({
            variables: {
              id: customerId,
              input: {
                schedules: Object.entries(iconChecked)
                  .filter(([key, value]) => value)
                  .map(([key, value]) => key),
              },
            },
          }).then(() => setShowSendEmail(false))
        }}
      />

      <SendChristmasDialog
        isOpen={showSendChristmas}
        onCancel={() => setShowSendChristmas(false)}
        loading={christmasCareLoading}
        photos={customer?.photos || []}
        selectedPhotoIds={selectedPhotoIds}
        onSelectPhotos={id => {
          let newIds
          if (selectedPhotoIds.includes(id)) {
            newIds = selectedPhotoIds.filter(pid => pid !== id)
          } else {
            newIds = [...selectedPhotoIds, id]
          }

          setSelectedPhotoIds(newIds)
          _christmasPreview(newIds)
        }}
        onConfirm={() => {
          sendChristmasCare({
            variables: {
              id: customerId,
              input: {
                schedule: Object.entries(iconChecked)
                  .filter(([key, value]) => value)
                  .map(([key, value]) => key)[0],
                photos: selectedPhotoIds,
              },
            },
          }).then(() => setShowSendChristmas(false))
        }}
      />

      {!data.allSchedules.edges || !data.allSchedules.edges.length ? (
        <SchedulesContent>
          <Typography>No schedules created</Typography>
        </SchedulesContent>
      ) : (
        [...data.allSchedules.edges]
          .sort((a, b) => {
            let aNextOccurance =
              a.edge.freq_type !== 1
                ? a.edge.recurrence.next_occurrence_at
                : a.edge.start_at
            let bNextOccurance =
              b.edge.freq_type !== 1
                ? b.edge.recurrence.next_occurrence_at
                : b.edge.start_at

            if (aNextOccurance === null) return -1

            return moment(aNextOccurance).isBefore(bNextOccurance) ? -1 : 1
          })
          .map(({edge: schedule}, i) => {
            let {
              id,
              service,
              charge,
              route_id,
              freq_type,
              start_at,
              // next_occurrence_at,
              // next_occurrence_route_id,
              recurrence,
              day,
              week,
              month,
              arrival_start_time,
              arrival_end_time,
              services,
              man_minutes,
              saleitem,
              discount,
            } = schedule
            let isRecurring = freq_type !== 1
            let isMultiday = freq_type === 6
            let nextOccurance = isRecurring
              ? recurrence.next_occurrence_at
              : start_at
            let hasOccurred =
              !nextOccurance ||
              moment().diff(nextOccurance, 'days') > 0 ||
              moment.utc().isAfter(customer.stop_at)
            let customerNotStarted = moment.utc().isBefore(customer.start_at)
            return (
              <React.Fragment key={id}>
                {i !== 0 && <Divider />}
                <div
                  onMouseEnter={() => setIconHovering(id)}
                  onMouseLeave={() => setIconHovering('')}
                  id={`schedule-${id}`}
                >
                  <ScheduleRow active={hasOccurred || customerNotStarted}>
                    <ScheduleIcon
                      isRecurring={isRecurring}
                      isMultiday={isMultiday}
                      hovering={iconHovering === id}
                      checked={iconChecked[id]}
                      onCheck={e =>
                        setIconChecked({...iconChecked, [id]: !iconChecked[id]})
                      }
                      id={`schedule${schedule.id}`}
                    />
                    <Space size={2} inline />
                    <ScheduleDetail>
                      <Typography variant="subtitle2">
                        <ul>
                          {services.map(s => (
                            <>
                              <li>
                                {s.saleitem.name} - {s.description}
                              </li>
                              {!!s.tasks &&
                                s.tasks.map(t => (
                                  <li
                                    style={{
                                      marginLeft: '1rem',
                                      fontStyle: 'italic',
                                      color: 'gray',
                                    }}
                                  >
                                    -{' '}
                                    {t.tasktype
                                      ? t.tasktype.description
                                      : t.description}
                                  </li>
                                ))}
                              {s.notes && (
                                <li
                                  style={{
                                    marginLeft: '1rem',
                                    fontStyle: 'italic',
                                    color: 'gray',
                                  }}
                                >
                                  ({s.notes})
                                </li>
                              )}
                              {!!s.discount && (
                                <li
                                  style={{
                                    marginLeft: '1rem',
                                    fontStyle: 'italic',
                                    color: 'gray',
                                  }}
                                >
                                  Discount: {s.discount.name}
                                </li>
                              )}
                            </>
                          ))}
                        </ul>
                      </Typography>
                      {schedule.freq_type !== 6 ? (
                        <Typography variant="body2" color="textSecondary">
                          Route {recurrence.next_occurrence_route_id}
                          {`; ${_getFormulaText(schedule)}`}
                          {` `}
                          {_getArrivalText(schedule)}
                        </Typography>
                      ) : (
                        <Typography variant="body2" color="textSecondary">
                          {`${_getFormulaText(schedule)}`}
                          {` `}
                          {_getArrivalText(schedule)}
                        </Typography>
                      )}
                    </ScheduleDetail>
                    <ScheduleAmount>
                      <Typography variant="h6" align="right">
                        {formatMoneyStandard(charge)}
                      </Typography>
                      <Typography align="right">{man_minutes} min.</Typography>
                    </ScheduleAmount>
                    <Space size={2} inline />
                    <ScheduleTiming>
                      <Typography variant="caption">
                        {isRecurring
                          ? 'Next service'
                          : hasOccurred
                          ? 'Serviced on'
                          : 'Scheduled for'}
                      </Typography>
                      <NextScheduleDateText
                        date={active ? nextOccurance : undefined}
                        route={recurrence.next_occurrence_route_id}
                      />
                    </ScheduleTiming>
                    <ScheduleActions>
                      <IconButton
                        onClick={() => setScheduleDeleteId(schedule)}
                        id={`delete-schedule-${schedule.id}`}
                        size="large"
                      >
                        <DeleteIcon />
                      </IconButton>
                      <IconButton
                        onClick={() => setEditSchedule(schedule)}
                        id={`edit-schedule-${schedule.id}`}
                        size="large"
                      >
                        <EditIcon />
                      </IconButton>
                    </ScheduleActions>
                  </ScheduleRow>
                </div>
              </React.Fragment>
            )
          })
      )}
    </SchedulesWrapper>
  )
}

export function EditScheduleModal({
  editSchedule,
  jobRequirements,
  setEditSchedule,
  setNewScheduleId,
  refetchSchedules,
  refetchSchedulesLoading = false,
  customer,
  instanceDate,
}) {
  let queryClient = useQueryClient()
  let {mutateAsync: updateSchedule} = useMutation(
    props => prgql({query: UpdateScheduleQuery, ...props}),
    {
      onSettled: () =>
        queryClient.invalidateQueries(() =>
          queryClient.invalidateQueries(['customer']),
        ),
    },
  )
  let {mutateAsync: createSchedule} = useMutation(
    props => prgql({query: CreateScheduleQuery, ...props}),
    {
      onSettled: () =>
        queryClient.invalidateQueries(() =>
          queryClient.invalidateQueries(['customer']),
        ),
    },
  )
  let {mutateAsync: createExclusion} = useMutation(
    props => prgql({query: CreateExclusionMutation, ...props}),
    {
      onSettled: () =>
        queryClient.invalidateQueries(() =>
          queryClient.invalidateQueries(['customer']),
        ),
    },
  )
  let {mutateAsync: updateExclusion} = useMutation(
    props => prgql({query: UpdateExclusionMutation, ...props}),
    {
      onSettled: () =>
        queryClient.invalidateQueries(() =>
          queryClient.invalidateQueries(['customer']),
        ),
    },
  )
  let {mutateAsync: deleteExclusion} = useMutation(
    props => prgql({query: DeleteExclusionMutation, ...props}),
    {
      onSettled: () =>
        queryClient.invalidateQueries(() =>
          queryClient.invalidateQueries(['customer']),
        ),
    },
  )
  let {mutateAsync: deleteService} = useMutation(
    props => prgql({query: DeleteServiceMutation, ...props}),
    {
      onSettled: () =>
        queryClient.invalidateQueries(() =>
          queryClient.invalidateQueries(['customer']),
        ),
    },
  )
  let [tab, setTab] = useState(instanceDate ? 'exclude' : 'edit')
  let [warn, setWarn] = useState(false)
  let [warnCoerce, setWarnCoerce] = useState(false)
  let [handledGuards, setHandledGuards] = useState([])
  let [allTimeOrForward, setAllTimeOrForward] = useState(false)
  let [outsideContract, setOutsideContract] = useState(false)

  const RECURRENCE_FIELDS = [
    'freq_type',
    'freq_interval',
    'freq_relative_interval',
    'freq_recurrence_factor',
    'week',
    'day',
  ]

  let temporaryChanges = {}

  let defaultService = {
    taxable: customer && customer.taxable,
    customer_id: customer && customer.id,
    saleitem_id: 0,
    description: '',
    amount: 0,
    man_minutes: 0,
  }

  let deleteServices = []

  function handleSave(values) {
    console.log(values)
    let attrs = [
      'route_id',
      'customer_id',
      'sequence',
      'start_at',
      'end_at',
      'freq_type',
      'freq_interval',
      'freq_relative_interval',
      'freq_recurrence_factor',
      'week',
      'day',
      'arrival_start_time',
      'arrival_end_time',
    ]
    let attrReducer = (acc, k) => {
      let v = values[k]
      if (k === 'arrival_start_time' || k === 'arrival_end_time') {
        if (Number(v)) {
          v = v.substring(0, 2) + ':' + v.substring(2, 4)
        }
      }
      acc[k] = v
      return acc
    }
    let rels = ['services']
    let relAttrs = [
      'id',
      'saleitem_id',
      'customer_id',
      'description',
      'notes',
      'taxable',
      'charge',
      'man_minutes',
      'sales_person_id',
      'commission_start_at',
      'jobrequirements',
      'tasks',
      'discount_id',
    ]
    let relReducer = (acc, k) => {
      let v = values[k]
      if (k === 'services') {
        let update = []
        let create = []
        let remove = []
        values[k].forEach(function(rel, i) {
          let relAttrReducer = (acc, k) => {
            let v = rel[k]
            if (['commission_start_at', 'sales_person_id'].includes(k) && !v) {
              v = undefined
            }
            if (['commission_start_at'].includes(k) && !!v) {
              let d = moment.utc(v)
              if (d.isValid()) {
                v = d.format('YYYY-MM-DD')
              } else {
                v = undefined
              }
            }
            if (k === 'sales_person_id' && v === 0) v = null
            if (k === 'jobrequirements') {
              let create = v
                ? v
                    .filter(req => !!req.jobrequirementtype_id)
                    .filter(req => req.id === 'new')
                    .map(req => ({
                      jobrequirementtype_id: req.jobrequirementtype_id,
                      qty: req.qty,
                    }))
                : undefined
              let update = v
                ? v
                    .filter(req => !!req.jobrequirementtype_id)
                    .filter(req => req.id !== 'new')
                    .map(req => ({
                      id: req.id,
                      jobrequirementtype_id: req.jobrequirementtype_id,
                      qty: req.qty,
                    }))
                : undefined
              let ids = v?.map(req => req.id) || []
              let remove = editSchedule.services[i]?.jobrequirements?.length
                ? editSchedule.services[i].jobrequirements
                    .filter(origReq => !ids.includes(origReq.id))
                    .map(req => ({
                      id: req.id,
                    }))
                : undefined

              v = {create, update, remove, api_type: 'jobrequirements'}
            }
            if (k === 'tasks') {
              let create = v
                ? v
                    .filter(req => !!req.tasktype_id)
                    .filter(req => req.id === 'new')
                    .map(req => ({
                      description: req.description,
                      tasktype_id: req.tasktype_id,
                    }))
                : undefined
              let update = v
                ? v
                    .filter(req => !!req.tasktype_id)
                    .filter(req => req.id !== 'new')
                    .map(req => ({
                      id: req.id,
                      description: req.description,
                      tasktype_id: req.tasktype_id,
                    }))
                : undefined
              let ids = v?.map(req => req.id) || []
              let destroy = editSchedule.services[i]?.tasks?.length
                ? editSchedule.services[i].tasks
                    .filter(origReq => !ids.includes(origReq.id))
                    .map(req => ({
                      id: req.id,
                    }))
                : undefined

              v = {create, update, destroy, api_type: 'tasks'}
            }
            acc[k] = v
            return acc
          }
          let service = relAttrs.reduce(relAttrReducer, {})
          if (service.id) {
            update.push(service)
          } else {
            create.push(service)
          }
        })

        deleteServices = values['delete_services'].map(id => ({id}))

        acc['services'] = {
          create,
          update,
          // remove, // put back in and remove the delete mutation if we want multiple schedules associated with multiple services
        }
      }
      return acc
    }

    let schedule = attrs.reduce(attrReducer, {})
    let relationships = rels.reduce(relReducer, {})

    let deleteServicesPromises = deleteServices.map(service =>
      deleteService({variables: {service}}),
    )

    switch (schedule.freq_type) {
      case 0: // cycle 4
        delete schedule.freq_interval
        delete schedule.freq_relative_interval
        delete schedule.freq_recurrence_factor
        break
      case 1:
        delete schedule.end_at
        delete schedule.week
        delete schedule.day
        delete schedule.freq_interval
        delete schedule.freq_relative_interval
        delete schedule.freq_recurrence_factor
        break
      case 2:
        delete schedule.week
        delete schedule.day
        delete schedule.freq_relative_interval
        delete schedule.freq_recurrence_factor
        break
      case 3:
      case 4:
        delete schedule.week
        delete schedule.day
        delete schedule.freq_relative_interval
      case 5:
        delete schedule.week
        delete schedule.day
        break
      case 6:
        delete schedule.route_id
        delete schedule.start_at
        delete schedule.end_at
        delete schedule.week
        delete schedule.day
        delete schedule.freq_interval
        delete schedule.freq_relative_interval
        delete schedule.freq_recurrence_factor
        break
    }
    if (schedule.freq_relative_interval === 0) {
      schedule.freq_relative_interval = 1 // I can't get formik to validate this correctly and I'm tired.
    }
    console.log('dood', relationships)

    if (values.id && values.id.includes('new')) {
      return createSchedule({variables: {schedule}})
        .then(data =>
          updateSchedule({
            variables: {
              schedule: {
                id: data.createSchedules.schedules.id,
                ...relationships,
              },
            },
          }),
        )
        .then(refetchSchedules)
    } else {
      schedule.id = values.id
      return updateSchedule({
        variables: {schedule: {...schedule, ...relationships}},
      })
        .then(() => Promise.allSettled(deleteServicesPromises))
        .then(refetchSchedules)
        .then(() => queryClient.invalidateQueries(['customer']))
    }
  }

  async function saveAndClose(values) {
    console.log('valsu', values)
    await handleSave(values)
    if (values.id && values.id.includes('new')) {
      setEditSchedule(null)
      setNewScheduleId(id => id + 1)
    } else {
      setEditSchedule(null)
      setTab(instanceDate ? 'exclude' : 'edit')
    }
  }

  async function exclude(values) {
    if (editSchedule.freq_type === 1) {
      if (
        !values.exclusion ||
        !values.exclusion.reschedule_at ||
        !values.exclusion.reschedule_route_id ||
        !values.id
      )
        return

      return await updateSchedule({
        variables: {
          schedule: {
            id: values.id,
            start_at: values.exclusion.reschedule_at,
            route_id: values.exclusion.reschedule_route_id,
          },
        },
      })
        .then(refetchSchedules)
        .then(() => {
          setEditSchedule(null)
          setTab(instanceDate ? 'exclude' : 'edit')
        })
    }

    let attrs = ['id', 'reschedule_route_id', 'reschedule_at', 'exclude_at']
    let attrReducer = (acc, k) => {
      let v = values.exclusion[k]

      if (k === 'reschedule_at' && v === null) {
        acc[k] = null
        return acc
      }
      if (k === 'exclude_at' || k === 'reschedule_at') {
        v = moment.utc(v).format('YYYY-MM-DD')
      }

      acc[k] = v
      return acc
    }

    let exclusion = attrs.reduce(attrReducer, {})
    exclusion.schedule_id = values.id

    if (
      exclusion.id &&
      moment(exclusion.reschedule_at).isSame(exclusion.exclude_at, 'day') &&
      exclusion.reschedule_route_id === values.route_id
    ) {
      return deleteExclusion({variables: {exclusion: {id: exclusion.id}}})
        .then(refetchSchedules)
        .then(() => {
          setEditSchedule(null)
          setTab(instanceDate ? 'exclude' : 'edit')
        })
    }

    if (exclusion.id) {
      return updateExclusion({variables: {exclusion}})
        .then(refetchSchedules)
        .then(() => {
          setEditSchedule(null)
          setTab(instanceDate ? 'exclude' : 'edit')
        })
    }

    return createExclusion({variables: {exclusion}})
      .then(refetchSchedules)
      .then(() => {
        setEditSchedule(null)
        setTab(instanceDate ? 'exclude' : 'edit')
      })
  }

  function warnOrSubmit(values, submit, tab, handledGuards = []) {
    if (tab === 'exclude' && !values.exclusion.reschedule_at) {
      setWarn(true)
      return
    }

    if ((tab === 'edit' || !tab) && instanceDate && values.freq_type !== 1) {
      setAllTimeOrForward(true)
      return
    }

    if (!handledGuards.includes('warnCoerce')) {
      for (let field of RECURRENCE_FIELDS) {
        if (
          values[field] !== editSchedule[field] &&
          !!values.id &&
          !values.id.includes('new')
        ) {
          setWarnCoerce(true)
          return
        }
      }
    }

    if (
      (tab === 'edit' || !tab) &&
      values.freq_type === 1 &&
      !_withinContract({
        date: values.start_at,
        customer: customer,
        account: customer.account,
      }) &&
      !handledGuards.includes('outsideContract')
    ) {
      setOutsideContract(true)
      return
    }

    submit()
  }

  function endThenSubmit(values, submit, setFieldValue) {
    if (!values.id) return

    // this feels wrong
    setFieldValue('start_at', moment.utc(instanceDate).format('YYYY-MM-DD'))
    setFieldValue('id', 'new')

    values.services.forEach((s, i) => {
      let {id, ...newS} = s

      setFieldValue(`services.${i}`, newS)
      setFieldValue(`services.${i}.jobreq_existing`, [])
    })

    updateSchedule({
      variables: {
        schedule: {
          id: values.id,
          end_at: moment.utc(instanceDate).format('YYYY-MM-DD'),
        },
      },
    }).then(submit)
  }

  function _withinContract({date, customer, account}) {
    let contract_start_at = undefined
    let contract_stop_at = undefined

    if (account && account.contract_start_at && account.contract_stop_at) {
      contract_start_at = account.contract_start_at
      contract_stop_at = account.contract_stop_at
    }

    if (customer.contract_start_at && customer.contract_stop_at) {
      contract_start_at = customer.contract_start_at
      contract_stop_at = customer.contract_stop_at
    }

    if (contract_start_at && contract_stop_at)
      return moment
        .utc(date)
        .isBetween(contract_start_at, contract_stop_at, undefined, '[]')

    return true
  }

  return (
    <EditScheduleDetails
      key={editSchedule && editSchedule.id ? editSchedule.id : undefined}
      schedule={editSchedule}
      defaultService={defaultService}
      onSaveAndContinue={async (...props) => await handleSave(...props)}
      onSubmit={async (...props) =>
        tab === 'edit' || !instanceDate
          ? await saveAndClose(...props)
          : await exclude(...props)
      }
      jobRequirements={jobRequirements}
      instanceDate={instanceDate}
      taxable={customer ? customer.taxable : true}
      disableNewWorkorder={refetchSchedulesLoading}
    >
      {({
        form,
        submitForm,
        isSubmitting,
        isValid,
        excludeForm,
        values,
        setFieldValue,
        dirty,
      }) => {
        let isNew = !editSchedule || editSchedule.id.includes('new')
        return (
          <>
            <Dialog open={!!editSchedule} maxWidth="xl" fullWidth>
              <DialogTitle>
                {customer && (
                  <Link to={`/customers/${customer.id}`}>
                    {customer.name}:{' '}
                  </Link>
                )}
                {isNew ? 'New Schedule' : 'Edit Schedule'}
              </DialogTitle>
              <DialogContent>
                {!!instanceDate && (
                  <DarkTheme>
                    <NavTabs value={tab}>
                      <TabSimple
                        label="Edit Day"
                        value="exclude"
                        style={{color: tab !== 'exclude' && colors.grey900}}
                        onClick={() => setTab('exclude')}
                      />
                      <TabSimple
                        label="Edit Schedule"
                        value="edit"
                        style={{color: tab !== 'edit' && colors.grey900}}
                        onClick={() => setTab('edit')}
                      />
                    </NavTabs>
                  </DarkTheme>
                )}
                <Grid container spacing={2}>
                  {tab === 'edit' || !instanceDate ? form : excludeForm}
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    setEditSchedule(null)
                    setTab(instanceDate ? 'exclude' : 'edit')
                  }}
                  disabled={isSubmitting}
                >
                  Cancel
                </Button>
                <LoadingButton
                  variant="contained"
                  color="primary"
                  onClick={() => warnOrSubmit(values, submitForm, tab)}
                  loading={isSubmitting}
                  disabled={!isValid}
                >
                  Save
                </LoadingButton>
              </DialogActions>
            </Dialog>
            <Dialog open={!!warn} maxWidth="md" fullWidth>
              <DialogTitle>CONFIRM CANCEL</DialogTitle>
              <DialogContent>
                Are you sure you want to cancel this job? This will also VOID
                any invoices for this job on this day.
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    setWarn(false)
                  }}
                  disabled={isSubmitting}
                >
                  Go Back
                </Button>
                <DangerLoadingButton
                  variant="contained"
                  color="primary"
                  onClick={submitForm}
                  loading={isSubmitting}
                  disabled={!isValid}
                >
                  Cancel Job
                </DangerLoadingButton>
              </DialogActions>
            </Dialog>
            <Dialog open={!!allTimeOrForward} maxWidth="md" fullWidth>
              <DialogTitle>All Time or Forward</DialogTitle>
              <DialogContent>
                Do you want to change this schedule for ALL TIME (past & future)
                or All Future Occurrences?
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    setAllTimeOrForward(false)
                  }}
                  disabled={isSubmitting}
                >
                  Cancel
                </Button>
                <DangerLoadingButton
                  variant="contained"
                  color="primary"
                  onClick={submitForm}
                  loading={isSubmitting}
                  disabled={!isValid}
                >
                  Change All Time
                </DangerLoadingButton>
                <LoadingButton
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    endThenSubmit(values, submitForm, setFieldValue)
                  }
                  loading={isSubmitting}
                  disabled={!isValid}
                >
                  Change All Future Occurrences
                </LoadingButton>
              </DialogActions>
            </Dialog>

            {/* Guards */}

            <Dialog open={!!outsideContract} maxWidth="md" fullWidth>
              <DialogTitle>Outside Contract</DialogTitle>
              <DialogContent>
                You are attempting to schedule work outside this customer's
                contract dates (if there are no contracts on this customer, then
                it is outside the account's contract dates). Are you sure you
                want to proceed?
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    setOutsideContract(false)
                  }}
                  disabled={isSubmitting}
                >
                  Cancel
                </Button>
                <LoadingButton
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setHandledGuards([...handledGuards, 'outsideContract'])
                    warnOrSubmit(values, submitForm, tab, [
                      ...handledGuards,
                      'outsideContract',
                    ])
                    setOutsideContract(false)
                  }}
                  loading={isSubmitting}
                  disabled={!isValid}
                >
                  Schedule outside contract dates
                </LoadingButton>
              </DialogActions>
            </Dialog>
            <Dialog open={!!warnCoerce} maxWidth="md" fullWidth>
              <DialogTitle>Updating Schedule</DialogTitle>
              <DialogContent>
                It looks like you are changing the recurrence of this schedule.
                Any exceptions you made in the future will be removed. Proceed?
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    setWarnCoerce(false)
                  }}
                  disabled={isSubmitting}
                >
                  Cancel
                </Button>
                <LoadingButton
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setHandledGuards([...handledGuards, 'warnCoerce'])
                    warnOrSubmit(values, submitForm, tab, [
                      ...handledGuards,
                      'warnCoerce',
                    ])
                    setWarnCoerce(false)
                  }}
                  loading={isSubmitting}
                  disabled={!isValid}
                >
                  Update schedule & remove exceptions
                </LoadingButton>
              </DialogActions>
            </Dialog>
          </>
        )
      }}
    </EditScheduleDetails>
  )
}
