import Immutable, {Map} from 'immutable'
import {SUCCESS as REDUX_API_SUCCESS} from '../redux-api'

const initialState = Map({
  customers: Map(),
  routeRuns: Map(),
  vehicles: Map(),
  users: Map(),
  inventory: Map(),
})

export default function entities(state = initialState, action) {
  // Make sure we don't merge in entities from Redux api helper
  if (
    !action.payload ||
      !action.payload.entities ||
      action.type === REDUX_API_SUCCESS
  ) {
    return state
  }

  return state.mergeDeep(Immutable.fromJS(action.payload.entities))
}

