import React from 'react'

import TextField from '@mui/material/TextField'

function ToolbarDropDownMenu({children, ...rest}) {
  return (
    <TextField select variant="outlined" size="small" {...rest} margin="dense">
      {children}
    </TextField>
  )
}

export default ToolbarDropDownMenu
