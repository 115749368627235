import PropTypes from 'prop-types'
import i from 'immutable'
import {connect, PromiseState} from 'react-refetch'
import urlJoin from 'url-join'
import compose from 'recompose/compose'
import withContext from 'recompose/withContext'
import store from '../store'
import {openSnackbar} from '../ducks/snackbar'
import {addSecurityRetryQueue} from '../actions/authActions'
import _ from 'lodash'
import cleanupUrl from './cleanupUrl'

const FetchError = function({message}) {
  this.message = message
}

let entityMap = i.Map()

let groupEntities = entities =>
  entities
    .groupBy(entity => entity.get('type'))
    .map(type =>
      type.reduce((map, entity) => map.set(entity.get('id'), entity), i.Map()),
    )

const addEntities = entities => {
  entityMap = entityMap.mergeDeep(groupEntities(i.fromJS(entities)))
}

const pickEntity = ({type, id} = {}) => {
  if (!type && !id) {
    return undefined
  }
  if (!_.isString(type)) {
    throw new Error('select.type should be a string')
  }
  if (!(_.isString(id) || _.isNumber(id))) {
    throw new Error('select.id should be a string or a number')
  }
  return entityMap.getIn([type, id.toString()])
}

const convertEntityToJS = entity => entity && entity.toJS()

const getEntity = select => {
  if (_.isArray(select)) {
    return select.map(s => convertEntityToJS(pickEntity(s)))
  }
  if (select === null) {
    return undefined
  }
  return convertEntityToJS(pickEntity(select))
}

let apiConnect = connect.defaults({
  buildRequest: function(mapping) {
    const options = {
      method: mapping.method,
      headers: mapping.headers,
      credentials: mapping.include || 'include',
      redirect: mapping.redirect,
      body: mapping.body,
    }
    const baseUrl = _.isString(mapping.baseUrl) ? mapping.baseUrl : ''
    const apiPrefix = _.isString(mapping.apiPrefix) ? mapping.apiPrefix : 'api'
    const url = cleanupUrl(urlJoin(baseUrl, apiPrefix, mapping.url))

    return new Request(url, options)
  },
  fetch: function(input, init) {
    const req = new Request(input, init)

    return fetch(req)
      .then(res => {
        if (res.status === 401)
          return new Promise((fulfill, reject) => {
            store.dispatch(
              addSecurityRetryQueue({
                reason: 'unauthorized server',
                retry: () => {
                  fetch(req).then(res => fulfill(res)).catch(reject)
                },
              }),
            )
          })

        if (res.status === 413) {
          throw new FetchError({message: `Failed to upload. File too large.`})
        }

        if (!res.ok)
          throw new FetchError({
            message: `Error fetching ${res.url}. Received status ${res.status}`,
          })
        return res
      })
      .then(res => res.json())
      .catch(err => {
        store.dispatch(openSnackbar({message: err.message}))
        throw err
      })
  },
  handleResponse: function(res) {
    const {included = [], data = []} = res

    addEntities(_.concat(included, data))

    return res
  },
})

export function selectEntity({source, select}) {
  const entities = _(source)
    .pickBy(prop => prop instanceof PromiseState)
    .values()
    .filter('fulfilled')
    .map(({value}) => _.concat(value.data, value.included))
    .flatten()
    .value()

  if (_.isPlainObject(select)) {
    return _.find(entities, _.update(select, 'id', id => id.toString()))
  }

  if (_.isArray(select)) {
    return _.intersectionWith(entities, select, _.isMatch) || []
  }

  throw new Error('Entity selector must be either an array or object')
}

const createConnect = connect => connectMappings =>
  compose(
    connect(connectMappings),
    withContext({selectEntity: PropTypes.func}, props => ({
      selectEntity: getEntity,
    })),
  )

let finalConnect = createConnect(apiConnect)
finalConnect.defaults = (...args) => createConnect(apiConnect.defaults(...args))

let ApiWrapper = ({children, ...rest}) => children(rest)
ApiWrapper.displayName = 'ApiWrapper'
let Api = finalConnect(({connect}) => connect)(ApiWrapper)
export {Api}

export default finalConnect
