import React, {ReactFragment, useState} from 'react'
import {useMutation, useQuery} from 'react-query'
import {gql} from 'graphql-tag' // Added gql import
import styled from 'styled-components' // Added styled-components import
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  LinearProgress,
} from '@mui/material'

enum RecordStatus {
  SUCCESS = 'SUCCESS',
  QB_FAILURE = 'QB-FAILURE',
  PR_FAILURE = 'PR-FAILURE',
  TRANSMITTING = 'TRANSMITTING',
  RECORDING = 'RECORDING',
}

type Error = {
  title: string
  detail: string,
  code: string
}

interface RecordStatusDialogProps {
  open: boolean
  onClose: () => void 
  status: RecordStatus
  errors: Array<Error>
}

const RecordStatusDialog = ({open, onClose, status, errors = []}: RecordStatusDialogProps) => {
  let body: JSX.Element = <></>
  switch (status) {
    case 'SUCCESS':
      body = (
        <Grid container spacing={2}>
          <Grid item>
            <p>
              Complete!
            </p>
          </Grid>
        </Grid>
      )
      break
    case 'QB-FAILURE':
      body = (
        <Grid container spacing={2}>
          <Grid item>
            <p>
              We failed to transmit the information to Quickbooks. Quickbooks
              may need to be relinked. Errors:
            </p>
          </Grid>
          <Grid item>
            <ul>
              {errors.map(e => (
                <li>
                  {e.title}: {e.detail}
                </li>
              ))}
            </ul>
          </Grid>
        </Grid>
      )
      break
    case 'PR-FAILURE':
      body = (
        <Grid container spacing={2}>
          <Grid item>
            <p>Failed to record data. Errors:</p>
          </Grid>
          <Grid item>
            <ul>
              {errors.map(e => (
                <li>
                  <code>{e.title}: {e.detail}</code>
                </li>
              ))}
            </ul>
          </Grid>
        </Grid>
      )
      break
    case 'TRANSMITTING':
      body = (
        <Grid container spacing={2}>
          <Grid item>
            <p>Transmitting to Quickbooks...</p>
          </Grid>
          <Grid item container spacing={2} alignItems="center">
            <Grid item>Pathrabbit</Grid>
            <Grid item>
              <LinearProgress
                style={{width: '100px', height: '10px', borderRadius: '5px'}}
              />
            </Grid>
            <Grid item>Quickbooks</Grid>
          </Grid>
        </Grid>
      )
      break
    case 'RECORDING':
      body = (
        <>
        <Grid container spacing={2}>
          <Grid item>
            <p>Recording...</p>
          </Grid>
          <Grid item container spacing={2} alignItems="center">
            <Grid item>Pathrabbit</Grid>
            <Grid item>
              <LinearProgress
                style={{width: '20px', height: '40px', borderRadius: '5px', rotate: '90deg'}}
              />
            </Grid>
          </Grid>
        </Grid>
        </>
      )
      break
  }

  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <DialogTitle>Recording</DialogTitle>
      <DialogContent style={{
        minHeight: '100px',
      }}>{body}</DialogContent>
      <DialogActions>
        {['SUCCESS', 'QB-FAILURE', 'PR-FAILURE'].includes(status) && (
          <Button onClick={onClose}>Close</Button>
        )}
      </DialogActions>
    </Dialog>
  )
}

export default RecordStatusDialog 
