import colors from './colors'
import PropTypes from 'prop-types'
import React from 'react'
import withContext from 'recompose/withContext'
import getContext from 'recompose/getContext'
import {
  createTheme as createMuiTheme,
  ThemeProvider as MuiThemeProvider,
  StyledEngineProvider,
  adaptV4Theme,
} from '@mui/material/styles'
import { ThemeProvider as SCThemeProvider } from 'styled-components'
import { darken, rgba } from 'polished'
import { grey } from '@mui/material/colors'

var primaryColorGroup = 'bethanyBlue'
var secondaryColorGroup = 'deepPurpleA'
const white = '#ffffff'

var zIndex = createMuiTheme().zIndex

var theme = {
  zIndex: zIndex,
  palette: {
    backgroundColor: '#f9f9fb',
    contentColor: '#ffffff',
    primary: {
      main: colors.bethanyBlue500,
      light: colors.bethanyBlue400,
      lighter: colors.bethanyBlue300,
      dark: grey[500],
      contrastText: white,
    },
    secondary: {
      main: colors.bethanyOrange500,
      light: colors.bethanyOrange400,
    },
    // not mui
    danger: {
      main: colors.red700,
      dark: darken(0.2, colors.red700),
      light: colors.red300,
      contrastText: 'rgb(255,255,255)',
    },
    info: {
      main: 'rgb(201,244,115)',
      medium: colors.green500,
      light: colors.grey50,
      medium: colors.grey100,
      dark: colors.teal700,
      muted: colors.grey300,
    },
    calendar: {
      selected: colors.bethanyOrange50,
      today: colors.bethanyBlue50,
      commercial: colors.grey50,
      large_commercial: colors.grey50,
      residential: colors.grey50,
      invoice: '#6CAC7D',
      workorder: '#D2F2C0',
      workorder_stroke: '#6CAC7D',
      multiday_workorder: colors.yellow200,
      multiday_workorder_stroke: colors.yellow600,
      progress: colors.bethanyOrange200
    },
    success: {
      main: colors.lightGreen500,
      light: colors.lightGreen100,
    },
    alert: {
      main: colors.yellow300,
    },
    trashed: {
      main: colors.grey500,
    },
    textInv2Color: rgba(colors.bethanyBlue50, 0.8),
    textInv3Color: rgba(colors.bethanyBlue100, 0.4),
    background: {
      appBar: colors.bethanyBlue500,
    },
    borderColor: grey[300],
  },
}

const invTheme = {
  ...theme,
  primaryColor: colors.bethanyBlue500,
  backgroundColor: colors.bethanyBlue500,
  tabs: { backgroundColor: colors.bethanyBlue500 },
  palette: {
    ...theme.palette,
    type: 'dark',
    primary1Color: white,
    primary2Color: colors.bethanyOrange500,
    primary3Color: rgba(colors.bethanyBlue100, 0.4),
    textColor: white,
    textColor2: rgba(colors.bethanyBlue50, 0.8),
    alternateTextColor: colors.white,
    canvasColor: colors.bethanyBlue400,
  },
}

const muiTheme = createMuiTheme(
  adaptV4Theme({
    zIndex: theme.zIndex,
    palette: theme.palette,
    overrides: {
      MuiTab: {
        root: {
          backgroundColor: colors.bethanyBlue500,
          color: theme.palette.textInv2Color,
        },
        rootPrimarySelected: {
          color: theme.palette.textInv1Color,
        },
      },
      raisedButton: { disabledColor: colors.bethanyBlue400 },
    },
  }),
)
const invMuiTheme = createMuiTheme({
  ...adaptV4Theme({
    zIndex: theme.zIndex,
    fontFamily: theme.fontFamily,
    typography: {
      useNextVariants: true,
    },
    ...invTheme,
    overrides: {
      MuiButton: {
        root: {
          color: white,
        },
      },
      MuiMenuItem: {
        root: {
          '&.Mui-selected': {
            backgroundColor: 'rgba(255, 255, 255, 0.16)',
          },
          '&.Mui-selected:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.16)',
          },
        },
      },
      MuiCheckbox: {
        root: {
          '&.Mui-checked': {
            color: 'rgba(255, 255, 255, 0.9)'
          }
        }
      }
    },
  }),
})

function assignColors(themeObject, colors, regexes) {
  var newTheme = { ...themeObject }

  Object.keys(regexes).map(prefix => {
    var colorRegex = regexes[prefix]
    return Object.keys(colors)
      .filter(colorKey => colorRegex.test(colorKey))
      .forEach(key => {
        newTheme[key.replace(colorRegex, prefix)] = colors[key]
      })
  })

  return newTheme
}

var primaryColorRegex = new RegExp(primaryColorGroup)
var secondaryColorRegex = new RegExp(secondaryColorGroup)

theme = assignColors(theme, colors, {
  primary: primaryColorRegex,
  secondary: secondaryColorRegex,
  grey: /grey/,
})

const withInvTheme = withContext({ muiTheme: PropTypes.object }, () => ({
  muiTheme: invMuiTheme,
}))

const Theme = getContext({
  prTheme: PropTypes.object,
})(({ prTheme, children }) => children(prTheme))

let ThemeProvider = ({ children }) => (
  <SCThemeProvider
    theme={{
      muiTheme: muiTheme,
      ...theme,
    }}
  >
    <StyledEngineProvider injectFirst>
      <MuiThemeProvider theme={muiTheme} children={children} />
    </StyledEngineProvider>
  </SCThemeProvider>
)
let PRThemeProvider = withContext({ prTheme: PropTypes.object }, () => ({
  prTheme: theme,
}))(ThemeProvider)

let DarkTheme = ({ children }) => (
  <SCThemeProvider theme={{ muiTheme: invMuiTheme, ...invMuiTheme }}>
    <StyledEngineProvider injectFirst>
      <MuiThemeProvider theme={invMuiTheme}>{children}</MuiThemeProvider>
    </StyledEngineProvider>
  </SCThemeProvider>
)

export default theme

export { withInvTheme, muiTheme, Theme, DarkTheme, PRThemeProvider }
