import React, {useEffect, useState} from 'react'
import {Toolbar} from '../../AppHandler'
import ToolbarGroup from '../../components/ToolbarGroup'
import ToolbarMegaTitle from '../../components/ToolbarMegaTitle'
import styled from 'styled-components'
import {DatePicker} from '@mui/lab'
import SelectMenu from '../../components/ToolbarDropDownMenu'
import {
  Typography,
  LinearProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  MenuItem,
  CircularProgress,
  InputLabel,
  TextField,
} from '@mui/material'
import {Space} from '../../components/Layout'
import {fetchAPI} from '../../schema/utils'
import moment from 'moment'
import {useQuery, useQueryClient} from 'react-query'
import _ from 'lodash'
import {ArrowForward} from '@mui/icons-material'
import queryString from 'query-string'
import ModifyQueryParams from '../../components/ModifyQueryParams'
import {UserSelectDynamic} from '../../components/UsersSelect'

let SummaryWrapper = styled.div`
  page-break-after: always;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
`

let TableWrapper = styled.div`
  margin-top: 2em;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

let GroupSelect = ({value = '', ...props}) => (
  <SelectMenu value={value} label="Group By" {...props}>
    <MenuItem value="captain">Captain</MenuItem>
    <MenuItem value="supervisor">Supervisor</MenuItem>
  </SelectMenu>
)

let getData = async ({from, to, user_id}) => {
  let data = await fetchAPI({
    url: '/reports/evaluations_by_user',
    query: {
      from: from.format('YYYY-MM-DD'),
      to: to.format('YYYY-MM-DD'),
      user_id,
    },
  })

  return JSON.parse(await data.text())
}

let munge = ({from, to, user_id}) => {
  from = !from ? moment().subtract(1, 'week') : moment(from)
  to = !to ? moment() : moment(to)
  user_id = !user_id ? undefined : user_id
  return {from, to, user_id}
}

const EvaluationReport = () => {
  let [queryChanges, setQueryChanges] = useState(
    queryString.parse(location.search),
  )

  let [cache, setCache] = useState([])

  let params = queryString.parse(location.search)
  let {from, to, user_id} = munge(params)

  const {isLoading, error, data} = useQuery(
    ['evaluations', from.format(), to.format(), user_id],
    () => getData({from, to, user_id}),
  )

  useEffect(() => {
    console.log('data is', data)
    if (data) {
      setCache(data)
    }
  }, [String(isLoading)])

  return (
    <>
      <ModifyQueryParams query={queryChanges} />
      <Toolbar>
        <ToolbarMegaTitle>Evaluations by User </ToolbarMegaTitle>
        <Space inline size={2} />
        <ToolbarGroup last>
          <DatePicker
            value={from}
            onChange={d =>
              setQueryChanges({...queryChanges, from: d.format('YYYY-MM-DD')})
            }
            renderInput={props => <TextField {...props} size="small" />}
          />
          <Space inline size={2} />
          <ArrowForward />
          <Space inline size={2} />
          <DatePicker
            value={to}
            onChange={d =>
              setQueryChanges({...queryChanges, to: d.format('YYYY-MM-DD')})
            }
            renderInput={props => <TextField {...props} size="small" />}
          />
          <Space inline size={2} />
          <UserSelectDynamic
            selectedId={user_id}
            onChange={(e, u) =>
              setQueryChanges({
                ...queryChanges,
                user_id: u?.id || '',
              })
            }
            sx={{width: 200}}
            inputProps={{
              variant: 'outlined',
              size: 'small',
              margin: 'dense',
            }}
            label={'Technician'}
          />
        </ToolbarGroup>
      </Toolbar>

      {isLoading && <LinearProgress />}

      {error && <p>Error: {error.message}</p>}

      <SummaryWrapper>
        {cache.map(({type: dataType, data}) => (
          <TableWrapper key={dataType}>
            <Typography variant="h6">{dataType}</Typography>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Evaluated At</TableCell>
                  <TableCell>Employee</TableCell>
                  <TableCell>Reviewer</TableCell>
                  <TableCell>Customer</TableCell>
                  <TableCell>Window Quality</TableCell>
                  <TableCell>Sill Quality</TableCell>
                  <TableCell>PW Quality</TableCell>
                  <TableCell>Contact</TableCell>
                  <TableCell>Comments</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map(row => (
                  <TableRow key={row.id}>
                    <TableCell>
                      {moment(row.evaluated_at).format('MM/DD/YY')}
                    </TableCell>
                    <TableCell>
                      {row.captain
                        ? `${row.captain.first_name} ${row.captain.last_name}`
                        : ''}
                    </TableCell>
                    <TableCell>
                      {row.supervisor
                        ? `${row.supervisor.first_name} ${row.supervisor.last_name}`
                        : ''}
                    </TableCell>
                    <TableCell>
                      {row.customer ? row.customer.name : ''}
                    </TableCell>
                    <TableCell>{row.window_quality}</TableCell>
                    <TableCell>{row.sill_quality}</TableCell>
                    <TableCell>{row.pw_quality}</TableCell>
                    <TableCell>{row.contact}</TableCell>
                    <TableCell>{row.comments}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableWrapper>
        ))}
      </SummaryWrapper>
    </>
  )
}

export default EvaluationReport
