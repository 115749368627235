import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import {Column} from 'react-virtualized'
import gql from 'graphql-tag'
import moment from 'moment'

import Typography from '@mui/material/Typography'

import TableList from '../../components/Table/TableList'
import {formatMoneyStandard} from '../../utils/moneyFormatter'
import {useMutation, useQuery} from 'react-query'
import {prgql, graphqlHasManyUpdate} from '../../utils/graphql'
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  Grid,
  IconButton,
  MenuItem,
  Tab,
  Tabs,
  Tooltip,
} from '@mui/material'
import {fetchAPI} from '../../schema/utils'
import {Code, CodeBlock} from '../../components/Code'
import {usePagination} from '../../components/PaginationContainer'
import {Toolbar} from '../../AppHandler'
import {
  PaperToolbar,
  ToolbarCenter,
  ToolbarLeft,
  ToolbarRight,
} from '../../components/Toolbar'
import ToolbarDropDownMenu from '../../components/ToolbarDropDownMenu'
import queryString from 'query-string'
import ModifyQueryParams from '../../components/ModifyQueryParams'
import {TYPES as LOGTYPES} from '../../constants/ActivityLogs'
import SearchBox from '../../components/SearchBox'
import {useDebounce} from '../../utils'
import {Space} from '../../components/Layout'
import {
  UserSelectDynamic as UserSelect,
  UserSelectDynamic,
} from '../../components/UsersSelect'
import {read, utils} from 'xlsx'
import {
  Add,
  ArrowBack,
  ArrowForward,
  ArrowLeft,
  ArrowRight,
  AttachMoney,
  Brightness2,
  Business,
  CheckCircle,
  Delete,
  FiberNew,
  MoneyOff,
  Pending,
} from '@mui/icons-material'
import {FieldArray, Formik} from 'formik'
import {useAuth, useCurrentUser} from '../../security/auth'
import colors from '../../styles/colors'
import {
  AutoCompleteField,
  DatePicker,
  StaticSelect,
  TextField,
  Toggle,
} from '../../components/forms'
import theme from '../../styles/theme'
import {SourceSelect} from '../../components/apiSelects'
import SaleItemSelect from '../../components/SaleItemSelect'
import LoadingButton from '../../components/LoadingButton'
import DangerLoadingButton from '../../components/DangerLoadingButton'
import * as Yup from 'yup'
import toaster from '../../utils/toaster'
import {DiscountSelect} from '../../components/DiscountSelect'

let query = gql`
  query Sales($cursor: String, $filters: SalesFilters, $limit: Int = 25) {
    logs: allSales(
      filters: $filters
      limit: $limit
      cursor: $cursor
      orderBy: sold_at_DESC
    ) {
      edges {
        edge {
          id
          sold_at
          incoming
          source_id
          customer_id
          status
          existing_customer
          source {
            id
            source
          }
          sales_person_id
          salesperson {
            id
            first_name
            last_name
          }
          salelines {
            id
            saleitem_id
            saleitem {
              id
              name
              description
            }
            description
            amount
            discount_id
            discount {
              id
              type
              amount
              name
            }
          }
        }
        cursor
      }
      pageInfo {
        next
        current
        prev
        count
        total
      }
    }
  }
`

let updateQuery = gql`
  mutation UpdateSales($input: UpdateSalesInput) {
    updateSales(input: $input) {
      sales {
        id
      }
    }
  }
`

let createQuery = gql`
  mutation CreateSales($input: CreateSalesInput) {
    createSales(input: $input) {
      sales {
        id
      }
    }
  }
`

let deleteQuery = gql`
  mutation Delete($input: DeleteSalesInput) {
    deleteSales(input: $input) {
      message
    }
  }
`

let SalesTableList = styled(TableList)`
  & .rejected {
    background-color: ${({theme}) => theme.palette.info.muted};
  }

  & {
    cursor: pointer;
  }
`

let IncomingOutGoing = ({val}) => (
  <>
    <div style={{marginRight: '5px'}}>
      <Business />
      {!val ? <ArrowForward /> : <ArrowBack />}
    </div>
    {!val ? 'Outgoing' : 'Incoming'}
  </>
)

let Status = ({val}) => (
  <>
    <div style={{marginRight: '5px'}}>
      {val === 'PENDING' ? (
        <Pending />
      ) : val === 'REJECTED' ? (
        <MoneyOff />
      ) : (
        <AttachMoney />
      )}
    </div>
    {val === 'PENDING' ? 'Pending' : val === 'REJECTED' ? 'Rejected' : 'Sold'}
  </>
)

let LightHr = styled.hr`
  border-top: white;
  border-color: ${({theme}) => theme.grey300};
  background-color: ${({theme}) => theme.grey300};
  width: 100%;
`

let DeleteDialog = ({open, onClose, id}) => {
  let {mutateAsync: destroy, isLoading} = useMutation(
    async input =>
      await prgql({
        query: deleteQuery,
        variables: {
          input,
        },
      }),
    {
      onSuccess: () => {
        toaster.success('Sale deleted')
      },
    },
  )

  return (
    !!id && (
      <Dialog open={open} maxWidth="xs" fullWidth>
        <DialogTitle>Delete Sale record</DialogTitle>
        <DialogContent style={{height: '100%'}}>
          <Typography> Do you want to delete this record? </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <DangerLoadingButton
            variant="contained"
            color="primary"
            onClick={async () => {
              await destroy({id})
              onClose()
            }}
            loading={isLoading}
          >
            Delete
          </DangerLoadingButton>
        </DialogActions>
      </Dialog>
    )
  )
}

const detailSchema = Yup.object().shape({
  sold_at: Yup.string().required('Required'),
  sales_person_id: Yup.string().required('Required'),
  source_id: Yup.string().required('Required'),
  customer_id: Yup.string().required('Required'),
  salelines: Yup.array()
    .of(
      Yup.object().shape({
        saleitem_id: Yup.string().required('Required'),
        description: Yup.string(),
        amount: Yup.number('must be a number'),
      }),
    )
    .min(1, 'Required'),
})

export const SaleDetailDialog = ({open, onClose, sale}) => {
  let {currentUser} = useCurrentUser()
  let {status: canManageSales} = useAuth('manage sales records')
  let disableEdit = canManageSales !== 'authorized'
  // let disableEdit = true
  let {mutateAsync: update} = useMutation(
    async input =>
      await prgql({
        query: updateQuery,
        variables: {
          input,
        },
      }),
    {
      onSuccess: () => {
        toaster.success('Sale updated')
      },
    },
  )
  let {mutateAsync: create} = useMutation(
    async input =>
      await prgql({
        query: createQuery,
        variables: {
          input,
        },
      }),
    {
      onSuccess: () => {
        toaster.success('Sale created')
      },
    },
  )

  return (
    !!sale && (
      <Dialog open={open} maxWidth="lg" fullWidth>
        <Formik
          initialValues={{
            sold: true,
            sold_at: moment.utc().format('YYYY-MM-DD'),
            sales_person_id: currentUser?.id,
            ...sale,
          }}
          validationSchema={detailSchema}
          onSubmit={async values => {
            let fields = [
              'id',
              'source_id',
              'customer_id',
              'sales_person_id',
              'sold_at',
              'incoming',
              'status',
            ]
            let props = {}
            fields.forEach(k => {
              props[k] = values[k]
            })

            let input = {
              ...props,
              ...graphqlHasManyUpdate({
                reldata: values.salelines,
                origData: sale.salelines,
                relname: 'salelines',
                validFields: [
                  'id',
                  'saleitem_id',
                  'description',
                  'amount',
                  'discount_id',
                ],
              }),
            }

            if (!input.id) {
              await create(input)
            } else {
              await update(input)
            }
            onClose()
          }}
        >
          {({
            values,
            setFieldValue,
            isValid,
            submitForm,
            isSubmitting,
            errors,
            validateForm,
            dirty,
          }) => {
            // console.log(errors)
            return (
              <>
                <DialogTitle>
                  Sale on {moment.utc(values.sold_at).format('ddd, MMM D YYYY')}
                </DialogTitle>
                <DialogContent style={{height: '100%'}}>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <DatePicker
                        name={'sold_at'}
                        label={'Sold at'}
                        inputVariant="filled"
                        size="small"
                        parseUtc={false}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <AutoCompleteField
                        name={'sales_person_id'}
                        label="Sales Person"
                        as={UserSelectDynamic}
                        selectedId={values.sales_person_id}
                        fullWidth
                        inputProps={{
                          variant: 'filled',
                          size: 'small',
                        }}
                        disabled={disableEdit}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <AutoCompleteField
                        name={'source_id'}
                        label="Source"
                        as={SourceSelect}
                        selectedId={values.source_id}
                        fullWidth
                        inputProps={{
                          variant: 'filled',
                          size: 'small',
                        }}
                        disabled={disableEdit}
                      />
                    </Grid>
                    <Grid item xs={5}>
                      <StaticSelect
                        name={'status'}
                        label={'Status'}
                        disabled={disableEdit}
                        variant="filled"
                        size="small"
                      >
                        <MenuItem value="PENDING">
                          <div style={{display: 'flex', alignItems: 'center'}}>
                            <Pending /> <Typography>Pending</Typography>
                          </div>
                        </MenuItem>
                        <MenuItem value="REJECTED">
                          <div style={{display: 'flex', alignItems: 'center'}}>
                            <MoneyOff /> <Typography> Rejected</Typography>
                          </div>
                        </MenuItem>
                        <MenuItem value="SOLD">
                          <div style={{display: 'flex', alignItems: 'center'}}>
                            <AttachMoney /> <Typography>Sold</Typography>
                          </div>
                        </MenuItem>
                      </StaticSelect>
                      <Space inline />
                      <Toggle
                        value={values.incoming}
                        setValue={val => setFieldValue('incoming', val)}
                        config={{
                          labels: ['Incoming', 'Outgoing'],
                          icons: [
                            <>
                              <Business />
                              <ArrowBack />
                            </>,
                            <>
                              <Business />
                              <ArrowForward />
                            </>,
                          ],
                          backgroundColors: [
                            theme.palette.info.medium,
                            theme.palette.info.light,
                          ],
                          textColors: [colors.grey800, colors.grey800],
                          borderColors: [colors.grey400, colors.grey400],
                        }}
                        disabled={disableEdit}
                      />
                    </Grid>
                    <LightHr />
                    <FieldArray name={'salelines'}>
                      {({remove, push}) => (
                        <>
                          {values.salelines?.map((saleline, i) => (
                            <Grid key={i} item container xs={12} spacing={2}>
                              <Grid item display={'flex'} alignItems={'center'}>
                                <Typography variant="subtitle2">
                                  {i + 1}
                                </Typography>
                              </Grid>
                              <Grid item xs={3}>
                                <AutoCompleteField
                                  name={`salelines.${i}.saleitem_id`}
                                  label="Saleitem"
                                  as={SaleItemSelect}
                                  selectedId={saleline.saleitem_id}
                                  fullWidth
                                  inputProps={{
                                    variant: 'filled',
                                    size: 'small',
                                  }}
                                  disabled={disableEdit}
                                />
                              </Grid>
                              <Grid item xs={3}>
                                <TextField
                                  name={`salelines.${i}.description`}
                                  variant="filled"
                                  size="small"
                                  label="Description"
                                  fullWidth
                                  disabled={disableEdit}
                                />
                              </Grid>
                              <Grid item xs={2}>
                                <TextField
                                  name={`salelines.${i}.amount`}
                                  variant="filled"
                                  size="small"
                                  label="Amount"
                                  type="number"
                                  fullWidth
                                  disabled={disableEdit}
                                />
                              </Grid>
                              <Grid item xs={2}>
                                <AutoCompleteField
                                  as={DiscountSelect}
                                  name={`salelines.${i}.discount_id`}
                                  selectedId={values.salelines[i].discount_id}
                                  inputProps={{
                                    variant: 'filled',
                                    size: 'small',
                                  }}
                                  // onSelectChange={i => {
                                  //   setFieldValue(
                                  //     `salelines.${i}.discount`,
                                  //     i,
                                  //   )
                                  // }}
                                  fullWidth
                                />
                              </Grid>
                              <Grid item xs={1}>
                                {!disableEdit && (
                                  <IconButton
                                    onClick={() => {
                                      remove(i)
                                      setTimeout(() => validateForm(), 100)
                                    }}
                                  >
                                    <Delete />
                                  </IconButton>
                                )}
                              </Grid>
                            </Grid>
                          ))}
                          <Grid item xs={12}>
                            {!disableEdit && (
                              <Button
                                variant="contained"
                                color="secondary"
                                size="small"
                                onClick={() => {
                                  push({
                                    saleitem_id: null,
                                    description: '',
                                    amount: 0,
                                    id: 'new' + values.salelines?.length,
                                  })
                                  setTimeout(() => validateForm(), 100)
                                }}
                              >
                                <Add />
                              </Button>
                            )}
                          </Grid>
                        </>
                      )}
                    </FieldArray>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  {disableEdit ? (
                    <Button onClick={onClose}>Close</Button>
                  ) : (
                    <>
                      <Button onClick={onClose}>Cancel</Button>
                      <LoadingButton
                        variant="contained"
                        color="primary"
                        onClick={submitForm}
                        loading={isSubmitting}
                        disabled={!isValid || !dirty}
                      >
                        Save
                      </LoadingButton>
                    </>
                  )}
                </DialogActions>
              </>
            )
          }}
        </Formik>
      </Dialog>
    )
  )
}

function mungeQuery(query) {
  function munge(prop, validation) {
    return validation.includes(prop) ? prop : validation[0]
  }

  let parsed = queryString.parse(query)

  let type = munge(parsed.type, ['all', ...Object.keys(LOGTYPES)])

  let search = parsed.search

  let user_id = parsed.user_id

  return {type, search, user_id}
}

function Sales({match, location, ...props}) {
  let [queryChanges, setQueryChanges] = useState({})
  let {type, search, user_id} = mungeQuery(location.search)
  let [debouncedSearch] = useDebounce(queryChanges.search, 300)

  let {data, loading: isLoading, loadMore, refetch} = usePagination({
    query,
    variables: {
      cursor: '-1',
      filters: {
        customer_id: match?.params.id,
      },
    },
  })

  let [showDetail, setShowDetail] = useState(false)
  let [sale, setSale] = useState(false)
  let [showDel, setShowDel] = useState(false)

  let getRowCount = pageInfo => {
    if (!pageInfo) {
      return 9999
    }
    return pageInfo.total
  }

  return (
    <React.Fragment>
      <PaperToolbar>
        <Toolbar>
          <ToolbarLeft></ToolbarLeft>
          <ToolbarRight></ToolbarRight>
          <Space inline size={3} />
          <Fab
            color="secondary"
            onClick={() => {
              setSale({
                customer_id: match.params.id,
              })
              setShowDetail(true)
            }}
          >
            <Add />
          </Fab>
        </Toolbar>
      </PaperToolbar>
      <SalesTableList
        data={(data && data.logs.edges) || []}
        infinite
        loadMoreRows={loadMore}
        rowCount={getRowCount(data && data.logs.pageInfo)}
        wrapRow={({children, rowData}) => (
          <div
            onClick={() => {
              setSale(rowData)
              setShowDetail(true)
            }}
            key={rowData.id}
          >
            {children}
          </div>
        )}
        rowClassName={({index}) => {
          let classnames = ''
          let sortedData = data
            ? data.logs.edges.sort((a, b) => (b.cursor > a.cursor ? -1 : 1))
            : []
          let rowData =
            sortedData && sortedData[index] && sortedData[index].edge

          if (rowData) {
            classnames += !rowData.sold ? ' rejected' : ''
          }

          return classnames
        }}
      >
        <Column
          dataKey="sold_at"
          label="Date"
          flexGrow={1}
          width={1}
          maxWidth={300}
          cellRenderer={({cellData, rowData}) => (
            <Typography>
              {moment.utc(cellData).format('M/D/YY, ddd')}
            </Typography>
          )}
        />
        <Column
          dataKey="source"
          label="Acquired"
          flexGrow={1}
          width={1}
          maxWidth={180}
          cellRenderer={({cellData, rowData}) => (
            <Typography style={{display: 'flex', alignItems: 'center'}}>
              {rowData && !rowData.existing_customer && (
                <Tooltip title="New Customer">
                  <FiberNew />
                </Tooltip>
              )}
              {cellData?.source}
            </Typography>
          )}
        />
        <Column
          dataKey="incoming"
          label="In/Out"
          flexGrow={1}
          width={1}
          maxWidth={300}
          cellRenderer={({cellData, rowData}) => (
            <Typography style={{display: 'flex', alignItems: 'center'}}>
              <IncomingOutGoing val={cellData} />
            </Typography>
          )}
        />
        <Column
          dataKey="status"
          label="Status"
          flexGrow={1}
          width={1}
          maxWidth={300}
          cellRenderer={({cellData, rowData}) => (
            <Typography style={{display: 'flex', alignItems: 'center'}}>
              <Status val={cellData} />
            </Typography>
          )}
        />
        <Column
          dataKey="salelines"
          label="Type"
          flexGrow={1}
          width={1}
          maxWidth={180}
          cellRenderer={({cellData, rowData}) => (
            <Typography>
              {!!cellData &&
                cellData
                  .map(l => l.saleitem?.name || '')
                  .filter((v, i, a) => a.indexOf(v) === i)
                  .join(', ')}
            </Typography>
          )}
        />
        <Column
          dataKey="salelines"
          label="Amount"
          flexGrow={1}
          width={1}
          maxWidth={180}
          cellRenderer={({cellData, rowData}) => (
            <Typography>
              {cellData &&
                formatMoneyStandard(
                  Math.round(cellData.reduce((c, l) => c + l.amount, 0)),
                )}
            </Typography>
          )}
        />
        <Column
          dataKey="salelines"
          label="Discount"
          flexGrow={1}
          width={1}
          maxWidth={180}
          cellRenderer={({cellData, rowData}) => (
            <Typography>
              {!!cellData && !!cellData.filter(l => !!l.discount).length && (
                <CheckCircle style={{color: 'green'}} />
              )}
            </Typography>
          )}
        />
        <Column
          dataKey="salesperson"
          label="User"
          flexGrow={1}
          width={1}
          maxWidth={140}
          cellRenderer={({cellData, rowData}) => (
            <Typography>
              {!cellData
                ? 'System'
                : `${cellData.first_name} ${cellData.last_name}`}
            </Typography>
          )}
        />
        <Column
          dataKey="sold"
          label="Sold"
          flexGrow={1}
          width={1}
          maxWidth={80}
          cellRenderer={({cellData, rowData}) => (
            <Typography>{cellData ? 'Sold' : 'Rejected'}</Typography>
          )}
        />
        <Column
          dataKey="id"
          label=""
          flexGrow={1}
          width={1}
          maxWidth={50}
          cellRenderer={({cellData}) => (
            <IconButton
              onClick={e => {
                e.stopPropagation()
                setShowDel(cellData)
              }}
            >
              <Delete />
            </IconButton>
          )}
        />
      </SalesTableList>
      <SaleDetailDialog
        open={showDetail}
        onClose={() => {
          setShowDetail(false)
          refetch()
        }}
        sale={sale}
      />
      <DeleteDialog
        open={showDel}
        onClose={() => {
          setShowDel(false)
          refetch()
        }}
        id={showDel}
      />
      <ModifyQueryParams
        query={{...queryChanges, search: debouncedSearch}}
        state={{}}
      />
    </React.Fragment>
  )
}
export default Sales
