import React, {useState} from 'react'
import styled from 'styled-components'
import {Column} from 'react-virtualized'
import gql from 'graphql-tag'
import {Query} from 'react-apollo'
import queryString from 'query-string'
import {Link} from 'react-router-dom'
import moment from 'moment'

import Chip from '@mui/material/Chip'
import Add from '@mui/icons-material/Add'
import Remove from '@mui/icons-material/Remove'
import MenuItem from '@mui/material/MenuItem'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'

import ErrorOutline from '@mui/icons-material/ErrorOutline'
import Done from '@mui/icons-material/Done'

import AttendanceCalendar from './AttendanceCalendar'
import PaginationContainer, {
  usePagination,
} from '../../components/PaginationContainer'
import TableList from '../../components/Table/TableList'
import SelectMenu from '../../components/ToolbarDropDownMenu'
import AttendanceIcon from '../../components/icons/AttendanceIcon'
import {Spacing} from '../../components/Layout'
import {
  PaperToolbar,
  DetailRow,
  ToolbarLeft,
  ToolbarCenter,
  ToolbarRight,
} from '../../components/Toolbar'
import ModifyQueryParams from '../../components/ModifyQueryParams'
import urlJoin from '../../utils/url-join'
import {formatMoneyStandard} from '../../utils/moneyFormatter'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  Modal,
  Switch,
} from '@mui/material'
import LoadingButton from '../../components/LoadingButton'
import {Formik} from 'formik'
import {AutoCompleteField, DatePicker, TextField} from '../../components/forms'
import RouteSelect from '../../components/RouteSelect'
import {useMutation} from 'react-query'
import {prgql} from '../../utils/graphql'
import DangerLoadingButton from '../../components/DangerLoadingButton'
import {Delete} from '@mui/icons-material'
import Label from '../../components/Label'

let allQuery = gql`
  query tipsQuery($cursor: String, $limit: Int = 25, $userId: String) {
    tips: allTips(
      filters: {user_id: $userId}
      limit: $limit
      cursor: $cursor
      orderBy: collected_at_DESC
    ) {
      pageInfo {
        total
      }
      edges {
        cursor
        edge {
          id
          user {
            id
          }
          collected_at
          route_id
          amount
          type_id
          route {
            id
            name
          }
        }
      }
    }
  }
`

let TypeRow = styled.div`
  display: flex;
  align-items: center;

  & > * + * {
    margin-left: 8px;
  }
`

let TipModal = ({user_id, open, onSubmit, onCancel, tip = {}}) => {
  console.log(tip)
  return (
    <Dialog maxWidth={'md'} fullWidth open={!!open}>
      <DialogTitle>{!!tip.id ? 'Edit' : 'New'} Tip</DialogTitle>
      <Formik
        initialValues={{
          user_id,
          amount: 0,
          route_id: '0',
          type_id: '1',
          collected_at: moment.utc().format('YYYY-MM-DD'),
          ...tip,
        }}
        onSubmit={values => onSubmit(values)}
      >
        {({isSubmitting, submitForm, isValid, values, setFieldValue}) => (
          <>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item>
                  <DatePicker
                    name={'collected_at'}
                    label={'Collected On'}
                    inputVariant="filled"
                    format="dddd, MMMM D, YYYY"
                    fullWidth
                  />
                </Grid>
                <Grid item>
                  <AutoCompleteField
                    as={RouteSelect}
                    name="route_id"
                    selectedId={
                      values.route_id === '0' ? undefined : values.route_id
                    }
                    fullWidth
                    variant="filled"
                    disabled={values.route_id === '0'}
                  />
                </Grid>
                <Grid item alignItems="center">
                  <FormControlLabel
                    label="Received by Office"
                    control={
                      <Switch
                        checked={values.route_id === '0'}
                        onChange={e =>
                          e.target.checked
                            ? setFieldValue('route_id', '0')
                            : setFieldValue('route_id', '1')
                        }
                      />
                    }
                  />
                </Grid>
                <Grid item>
                  <TextField
                    name={`amount`}
                    variant="filled"
                    label="Amount"
                    fullWidth
                    type="number"
                  />
                </Grid>
                <Grid item>
                  <TextField
                    select
                    name={`type_id`}
                    variant="filled"
                    label="Type"
                    fullWidth
                    value={values.type_id}
                    onChange={e => setFieldValue('type_id', e.target.value)}
                  >
                    <MenuItem key={1} value={'1'}>
                      Cash
                    </MenuItem>
                    <MenuItem key={2} value={'2'}>
                      Purchasing Card
                    </MenuItem>
                  </TextField>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={onCancel}>Cancel</Button>
              <LoadingButton
                variant="contained"
                color="primary"
                loading={isSubmitting}
                onClick={submitForm}
              >
                Save
              </LoadingButton>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  )
}

let DestroyModal = ({tip = {}, open, onSubmit, onCancel, loading}) => {
  let text = `${tip.collected_at &&
    moment.utc(tip.collected_at).format('M/D/YYYY, ddd.')}
        , ${tip && tip.route ? 'Route ' + tip.route.name : 'Office'}`
  return (
    <Dialog maxWidth={'md'} fullWidth open={!!open}>
      <DialogTitle>Delete Tip</DialogTitle>
      <DialogContent>
        <Typography>
          Are you sure you want to delete the tip on {text}?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <DangerLoadingButton
          variant="contained"
          color="primary"
          loading={loading}
          onClick={() => onSubmit(tip)}
        >
          Destroy
        </DangerLoadingButton>
      </DialogActions>
    </Dialog>
  )
}

let newTipQuery = gql`
  mutation newTip(
    $amount: Float!
    $user_id: ID!
    $collected_at: String!
    $route_id: ID
    $type_id: ID
  ) {
    createTips(
      input: {
        amount: $amount
        user_id: $user_id
        collected_at: $collected_at
        route_id: $route_id
        type_id: $type_id
      }
    ) {
      tips {
        id
      }
    }
  }
`

let editTipQuery = gql`
  mutation editTip(
    $id: String!
    $amount: Float!
    $user_id: ID!
    $collected_at: String!
    $route_id: ID
    $type_id: ID
  ) {
    updateTips(
      input: {
        id: $id
        amount: $amount
        user_id: $user_id
        collected_at: $collected_at
        route_id: $route_id
        type_id: $type_id
      }
    ) {
      tips {
        id
      }
    }
  }
`
let destroyTipQuery = gql`
  mutation deleteTip($id: String!) {
    deleteTips(input: {id: $id}) {
      message
    }
  }
`

let DescWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0 16px;
  & > * {
    margin-right: 8px;
  }
`

let DescCol = ({cellData, rowData, onClick}) => (
  <Link onClick={onClick} to="#">
    <DescWrapper>
      <Typography variant="h6">
        {rowData &&
          rowData.collected_at &&
          moment.utc(rowData.collected_at).format('M/D/YYYY, ddd.')}
        ,{' '}
        {rowData && (rowData.route ? 'Route ' + rowData.route.name : 'Office')}
      </Typography>
    </DescWrapper>
  </Link>
)

const Tips = ({match, location}) => {
  let {params = {}} = match
  let [showTipModal, setShowTipModal] = useState(false)
  let [destroyTipModal, setDestroyTipModal] = useState(false)
  let {mutateAsync: saveNew} = useMutation(({...values}) => {
    prgql({query: newTipQuery, variables: values})
  })
  let {mutateAsync: edit} = useMutation(({...values}) => {
    prgql({query: editTipQuery, variables: values})
  })
  let {mutateAsync: destroy} = useMutation(({...values}) => {
    prgql({query: destroyTipQuery, variables: values})
  })
  let {
    data,
    loadMore,
    refetch,
    ...rest
  } = usePagination({
    query: allQuery,
    variables: {userId: params.id},
  })

  let tips = null
  if (data){
    tips = data.tips
  }

  let [selectedTip, setSelectedTip] = useState(undefined)

  let _save = async values => {
    setShowTipModal(false)
    setSelectedTip(undefined)
    if (values.id) {
      await edit(values)
    } else {
      await saveNew(values)
    }
    refetch()
  }

  let _destroy = async values => {
    setDestroyTipModal(false)
    setSelectedTip(undefined)
    await destroy(values)
    refetch()
  }

  return (
    <React.Fragment>
      <PaperToolbar>
        <DetailRow>
          <ToolbarRight>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setShowTipModal(true)}
            >
              <Add />
            </Button>
          </ToolbarRight>
        </DetailRow>
      </PaperToolbar>
      <TableList
        data={tips ? tips.edges : []}
        infinite
        loadMoreRows={loadMore}
        startBottom
        rowCount={tips ? tips.pageInfo.total : 0}
      >
        <Column
          dataKey="collected_at"
          label="Collected On"
          width={300}
          cellRenderer={({cellData, rowData}) => (
            <DescCol
              cellData={cellData}
              rowData={rowData}
              onClick={() => {
                setSelectedTip(rowData)
                setShowTipModal(true)
              }}
            />
          )}
        />
        <Column
          dataKey="amount"
          label="Amount"
          width={180}
          cellRenderer={({cellData, rowData}) => {
            return (
              <Typography variant="h6" style={{textAlign: 'center'}}>
                {formatMoneyStandard(cellData)}
              </Typography>
            )
          }}
        />
        <Column
          dataKey="type_id"
          label="Type"
          width={180}
          cellRenderer={({cellData, rowData}) => {
            return (
              <Typography variant="h6" style={{textAlign: 'center'}}>
                {cellData === "1" ? "Cash" : "Purchase Card"}
              </Typography>
            )
          }}
        />
        <Column
          dataKey=""
          label=""
          width={50}
          cellRenderer={({cellData, rowData}) => {
            return (
              <IconButton
                onClick={() => {
                  setSelectedTip(rowData)
                  setDestroyTipModal(true)
                }}
                size="large">
                <Delete />
              </IconButton>
            );
          }}
        />
      </TableList>
      <TipModal
        user_id={params.id}
        open={showTipModal}
        onCancel={() => {
          setShowTipModal(false)
          setSelectedTip(undefined)
        }}
        onSubmit={_save}
        tip={selectedTip}
      />
      <DestroyModal
        open={destroyTipModal}
        tip={selectedTip}
        onCancel={() => {
          setDestroyTipModal(false)
          setSelectedTip(undefined)
        }}
        onSubmit={_destroy}
      />
    </React.Fragment>
  );
}
export default Tips
