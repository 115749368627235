import React, {useState} from 'react'
import {useMutation, useQuery, useQueryClient} from 'react-query'
import {gql, prgql} from '../../utils/graphql'
import {
  Grid,
  Typography,
  Button,
  Paper,
  FormControlLabel,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Switch,
  MenuItem,
} from '@mui/material'
import TableList, {TrashableTableList} from '../../components/Table/TableList'
import {Column} from 'react-virtualized'
import {usePagination} from '../../components/PaginationContainer'
import {Check, CheckCircle, HorizontalRule, Save} from '@mui/icons-material'
import Toolbar, {
  CloseButton,
  TrashMenuItem,
  DetailToolbar,
  ToolbarCenter,
  ToolbarIconMenu,
  ToolbarLeft,
  ToolbarRight,
} from '../../components/Toolbar'
import {NavigateBack} from '../../components/Navigator'
import styled from 'styled-components'
import {Space} from '../../components/Layout'
import {Formik} from 'formik'
import {Checkbox, StaticSelect, TextField} from '../../components/forms'
import {RedirectBack} from '../../components/Navigator'
import {Link} from 'react-router-dom'
import toaster from '../../utils/toaster'
import colors from '../../styles/colors'
import {useAuth} from '../../security/auth'
import IconButton from '@mui/material/IconButton'
import {RestoreFromTrash} from '@mui/icons-material'
import {fetchAPI} from '../../schema/utils'

let query = gql`
  query Assets($limit: Int = 20, $cursor: String, $filters: AssetFilters) {
    items: allAssets(limit: $limit, cursor: $cursor, filters: $filters) {
      edges {
        edge {
          id
          name
          assettype_id
          description
          active
        }
        cursor
      }
      pageInfo {
        next
        current
        prev
        count
        total
      }
    }
  }
`

let getQuery = gql`
  query Asset($id: String!) {
    item: assets(id: $id) {
      id
      name
      assettype_id
      description
      active
    }
  }
`

let createMutation = gql`
  mutation Create($input: CreateAssetsInput) {
    createAssets(input: $input) {
      assets {
        id
      }
    }
  }
`

let updateMutation = gql`
  mutation Update($input: UpdateAssetsInput) {
    updateAssets(input: $input) {
      assets {
        id
      }
    }
  }
`

let deleteMutation = gql`
  mutation Delete($id: String!) {
    deleteAssets(input: {id: $id}) {
      message
    }
  }
`
const ASSET_TYPES = {1: 'Truck', 2: 'Pressure Washer'}

const Assets = () => {
  let [showTrash, setShowTrash] = useState(false)
  let {data, loading, loadMore, refetch} = usePagination({
    query: query,
    variables: {
      cursor: '-1',
    },
    fetchPolicy: 'cache-and-network',
  })

  function getRowCount() {
    if (!data || !data.items || !data.items.pageInfo) {
      return 9999
    }
    return data.items.pageInfo.total
  }

  let {status: canManage} = useAuth('manage assets')

  return (
    <>
      <TrashableTableList
        data={(data && data.items && data.items.edges) || []}
        infinite
        loadMoreRows={loadMore}
        rowCount={getRowCount()}
        wrapRow={({children, rowData}) => {
          return canManage === 'authorized' && !rowData.deleted_at ? (
            <Link to={`/settings/assets/${rowData.id}`}>{children}</Link>
          ) : (
            children
          )
        }}
        rowClassName={({index}) => {
          let classnames = ''
          let sortedData = data
            ? data.items?.edges?.sort((a, b) => (b.cursor > a.cursor ? -1 : 1))
            : []
          let rowData =
            sortedData && sortedData[index] && sortedData[index].edge

          if (rowData) {
            classnames += !rowData.active ? ' trashed' : ''
          }

          return classnames
        }}
      >
        <Column
          dataKey="name"
          headerRenderer={({label}) => (
            <div style={{textAlign: 'left'}}>{label}</div>
          )}
          cellRenderer={data => (
            <Typography style={{textAlign: 'left'}}>{data.cellData}</Typography>
          )}
          label="Name"
          flexGrow={1}
          width={0}
        />
        <Column
          dataKey="assettype_id"
          headerRenderer={({label}) => (
            <div style={{textAlign: 'left'}}>{label}</div>
          )}
          cellRenderer={data => (
            <Typography style={{textAlign: 'left'}}>
              {ASSET_TYPES[data.cellData] || data.cellData}
            </Typography>
          )}
          label="Type"
          flexGrow={1}
          width={0}
        />
        <Column
          dataKey="description"
          headerRenderer={({label}) => (
            <div style={{textAlign: 'left'}}>{label}</div>
          )}
          cellRenderer={data => (
            <Typography style={{textAlign: 'left'}}>{data.cellData}</Typography>
          )}
          label="Description"
          flexGrow={1}
          width={0}
        />
      </TrashableTableList>
    </>
  )
}
let PaperContainer = styled(Paper)`
  width: 80%;
  margin: 16px auto 0;
  padding: 2em;
`
let LeftIcon = styled.div`
  margin-right: ${({theme}) => theme.muiTheme.spacing(1)};
`

let Form = ({onSubmit, initial = {}, formikkey, onDelete = () => {}}) => {
  const [warn, setWarn] = useState(false)

  return (
    <>
      <Formik initialValues={initial} onSubmit={onSubmit} key={formikkey}>
        {({submitForm, values, setValues, setFieldValue}) => (
          <>
            <DetailToolbar>
              <ToolbarLeft>
                <NavigateBack
                  defaultBack={location.pathname}
                  marker="AssetsBack"
                >
                  <CloseButton />
                </NavigateBack>
              </ToolbarLeft>
              <ToolbarCenter>
                <Typography variant="h6">
                  {values.id ? 'Edit' : 'Add'} Asset
                </Typography>
              </ToolbarCenter>
              <ToolbarRight>
                <Space sizePx={16} inline />
                <Button color="primary" onClick={submitForm}>
                  <LeftIcon>
                    <Save />
                  </LeftIcon>
                  Save
                </Button>
                {/* <ToolbarIconMenu> */}
                {/*   <TrashMenuItem */}
                {/*     onClick={() => setWarn(true)} */}
                {/*   /> */}
                {/* </ToolbarIconMenu> */}
              </ToolbarRight>
            </DetailToolbar>
            <Grid container spacing={2}>
              <Grid item xs={3} style={{display: 'flex', alignItems: 'center'}}>
                <TextField
                  fullWidth
                  label="Name"
                  name="name"
                  variant={'outlined'}
                />
              </Grid>
              <Grid item xs={9} style={{display: 'flex', alignItems: 'center'}}>
                <TextField
                  fullWidth
                  label="Description"
                  name="description"
                  variant={'outlined'}
                />
              </Grid>
              <Grid item xs={3} style={{display: 'flex', alignItems: 'center'}}>
                <StaticSelect select variant="outlined" name={'assettype_id'}>
                  {Object.entries(ASSET_TYPES).map(([key, value]) => (
                    <MenuItem value={Number.parseInt(key)}>{value}</MenuItem>
                  ))}
                </StaticSelect>
              </Grid>
              <Grid item xs={2}>
                <FormControlLabel
                  label="Active"
                  control={
                    <Checkbox
                      color="secondary"
                      name={'active'}
                      checked={values.active}
                      onChange={e =>
                        setValues({...values, active: e.target.checked})
                      }
                      fullWidth
                    />
                  }
                />
              </Grid>
            </Grid>
            {/* <Dialog open={warn} onClose={() => setWarn(false)}> */}
            {/*   <DialogTitle>Trash Record</DialogTitle> */}
            {/*   <DialogContent> */}
            {/*     Are you sure you want to trash this record? (You can restore it from trash later) */}
            {/*   </DialogContent> */}
            {/*   <DialogActions> */}
            {/*     <Button onClick={() => setWarn(false)}>Cancel</Button> */}
            {/*     <Button onClick={() => onDelete(values.id)} variant="contained">Trash</Button> */}
            {/*   </DialogActions> */}
            {/* </Dialog> */}
          </>
        )}
      </Formik>
    </>
  )
}

export const Asset = ({match, location}) => {
  let [goBack, setGoBack] = useState(false)

  let queryClient = useQueryClient()
  let {data, isError, isLoading} = useQuery(
    ['single', match.params.id],
    async () =>
      await prgql({query: getQuery, variables: {id: match.params.id}}),
  )

  let {mutateAsync} = useMutation('update', async input => {
    return await prgql({
      query: updateMutation,
      variables: {input},
    })
  })

  let {mutateAsync: destroy} = useMutation('delete', async ({id}) => {
    return await prgql({
      query: deleteMutation,
      variables: {id},
    })
  })

  return (
    <>
      <PaperContainer>
        {isLoading ? (
          <div>Loading...</div>
        ) : isError ? (
          <div>Error</div>
        ) : (
          <Form
            formikkey={match.params.id}
            initial={data && data.item}
            onSubmit={async values => {
              let {is_system, ...saveValues} = values
              await mutateAsync(saveValues)
              await queryClient.invalidateQueries(['single', match.params.id])
              toaster.success('Asset saved')
              setGoBack(true)
            }}
            onDelete={async id => {
              await destroy({id})
              toaster.success('Asset trashed')
              setGoBack(true)
            }}
          />
        )}
      </PaperContainer>
      {/* This is extremely evil. we need a hook that'll do this */}
      {goBack && (
        <RedirectBack
          defaultBack={location.pathname}
          marker="AssetsBack"
          location="/settings/assets"
        />
      )}
    </>
  )
}

export const NewAsset = ({match, location}) => {
  let [goBack, setGoBack] = useState(false)

  let {mutateAsync} = useMutation('create', async input => {
    return await prgql({
      query: createMutation,
      variables: {input},
    })
  })

  return (
    <>
      <PaperContainer>
        {
          <Form
            formikkey={match.params.id}
            initial={{
              name: '',
            }}
            onSubmit={async values => {
              let {...saveValues} = values
              await mutateAsync(saveValues)
              setGoBack(true)
              toaster.success('Asset Created')
            }}
          />
        }
      </PaperContainer>
      {/* This is extremely evil. we need a hook that'll do this */}
      {goBack && (
        <RedirectBack defaultBack={location.pathname} marker="AssetsBack" />
      )}
    </>
  )
}

export default Assets
