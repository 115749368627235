import PropTypes from 'prop-types'
import React, { useReducer } from 'react'
import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'

import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogActions from '@mui/material/DialogActions'
import Divider from '@mui/material/Divider'
import TextField from '@mui/material/TextField'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'

import EditIcon from '@mui/icons-material/Edit'
import ViewList from '@mui/icons-material/ViewList'

import { Body1, Subhead } from '../../components/Typography'
import { Column } from 'react-virtualized'
import TableList from '../../components/Table/TableList'
import { formatMoneyStandard } from '../../utils/moneyFormatter'
import moment from 'moment'
import PrincipalAvatar from '../../components/PrincipalAvatar'
import styled from 'styled-components'
import { Block, Space } from '../../components/Layout'
import { Business, Pause, Person, People, PlayArrow } from '@mui/icons-material'
import { Tooltip } from '@mui/material'
import theme from '../../styles/theme'
import { Link } from 'react-router-dom'
import { useDebounce } from '../../utils'
import { debounce } from 'lodash'

const sheet = {
  cellContainer: { display: 'flex', alignItems: 'center', padding: '5px 20px' },
  photoWrapper: { marginRight: '20px' },
}

let ArTableList = styled(TableList)`
  & .badData {
    background-color: rgba(255, 0, 0, 0.54);
  }

  & .stopped {
    background-color: ${theme.palette.danger.light};
  }

  & .stopped .name {
    text-decoration: line-through;
  }

  & .paused {
    background-color: ${theme.palette.info.muted};
  }
`

let TinyCirclularProgress = styled(CircularProgress)`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -12px;
  margin-left: -12px;
`

let hasRecurringDebt = function(rowData) {
  if (
    rowData &&
    rowData.thirtyDay &&
    rowData &&
    rowData.sixtyDay &&
    rowData &&
    rowData.ninetyDay &&
    rowData &&
    rowData.overNinetyDay
  ) {
    return true
  } else {
    return false
  }
}

let hasOutstandingDebt = function(rowData) {
  if ((rowData && rowData.ninetyDay) || (rowData && rowData.overNinetyDay)) {
    return true
  } else {
    return false
  }
}

let CellContent = styled(Body1).attrs(() => ({ display: 'block' }))`
  text-align: right;
  ${({ mainBalance }) =>
    mainBalance &&
    `
    text-decoration: underline;
    color: blue;
  `}

  ${({ hasRecurringDebt }) => hasRecurringDebt && `color: rgba(255, 0, 0, 0.54);`}
    
  ${({ hasOutstandingDebt }) => hasOutstandingDebt && `font-weight: bold;`};

  ${({ noBalance }) =>
    noBalance &&
    `color: #999;
  font-weight: normal;
  `}
`

let NameContent = styled(Subhead).attrs(() => ({ display: 'block' }))`
  ${({ hasRecurringDebt }) =>
    hasRecurringDebt && `color: rgba(255, 0, 0, 0.54);`} ${({
      hasOutstandingDebt,
    }) => hasOutstandingDebt && `font-weight: bold;`};
  display: flex;
  align-items: center;
`

let NoteDivider = styled(Divider)`
  margin: 16px 0;
`
let NoteCell = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
let NoteCellText = styled(Typography).attrs(() => ({ variant: 'body1' }))`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
let PrincipalNotesLink = styled.a`
  flex: 1;
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  padding: 4px;
  cursor: pointer;
  color: inherit;

  &:hover {
    background: #eee;
    border-radius: 8px;
  }
`
let NoteGroup = styled(Block)`
  flex: 1;
  overflow: hidden;
`
let PrincipalNotesIcon = styled(EditIcon)`
  color: ${({ theme }) => theme.muiTheme.palette.text.secondary};
  display: none;
  ${PrincipalNotesLink}:hover & {
    display: block;
  }
`

let AR_NOTE = gql`
  fragment arNote on ARNotes {
    id
    note
    user_id
    recorded_at
    user {
      id
      first_name
      last_name
    }
  }
`

let CREATE_AR_NOTE = gql`
  mutation newARNote($note: CreateARNotesInput) {
    createARNotes(input: $note) {
      createdNote: aRNotes {
        ...arNote
      }
    }
  }
  ${AR_NOTE}
`

let PAUSE_CUSTOMER_SERVICES = gql`
  mutation Pause($id: String!, $paused: Boolean!) {
    updateCustomers(input: {id: $id, services_paused: $paused}) {
      customers {
        id
        services_paused
      }
    }
  }
`

// a comment
let useCreateNote = ({ newNote, principalsId, customerId, accountId }) =>
  useMutation(CREATE_AR_NOTE, {
    variables: {
      note: {
        note: newNote,
        customer_id: customerId,
        account_id: accountId,
      },
    },
    update: (
      cache,
      {
        data: {
          createARNotes: { createdNote },
        },
      },
    ) => {
      console.log(principalsId, customerId, accountId)
      let fragment = gql`
        fragment principalARNote on Principals {
          last_arnote {
            ...arNote
          }
        }
        ${AR_NOTE}
      `
      let id = 'Principals:' + principalsId
      let note = cache.readFragment({
        id,
        fragment,
        fragmentName: 'principalARNote',
      })
      cache.writeFragment({
        id,
        fragment,
        fragmentName: 'principalARNote',
        data: {
          ...note,
          last_arnote: createdNote,
        },
      })

      if (customerId) {
        let fragment = gql`
          fragment customerARNote on Customers {
            last_arnote {
              ...arNote
            }
          }
          ${AR_NOTE}
        `
        let id = 'Customers:' + customerId
        let note = cache.readFragment({
          id,
          fragment,
          fragmentName: 'customerARNote',
        })
        cache.writeFragment({
          id,
          fragment,
          fragmentName: 'customerARNote',
          data: {
            ...note,
            last_arnote: createdNote,
          },
        })
      }
    },
  })

let arNoteInitialState = { isOpen: false, newNote: '' }
function arNoteReducer(state, { type, data }) {
  switch (type) {
    case 'OPEN_DIALOG':
      return { ...state, isOpen: true }
    case 'CLOSE_DIALOG':
      return { ...state, newNote: '', isOpen: false }
    case 'CHANGE_NOTE':
      return { ...state, newNote: data }
    default:
      throw new Error('unknown action')
  }
}

let ARNote = ({
  principalsId,
  customerId,
  accountId,
  note,
  name,
  username,
  date,
}) => {
  let [{ isOpen, newNote }, dispatch] = useReducer(
    arNoteReducer,
    arNoteInitialState,
  )
  let [createNote, { loading }] = useCreateNote({
    principalsId,
    customerId,
    accountId,
    newNote,
  })

  function openDialog() {
    dispatch({ type: 'OPEN_DIALOG' })
  }
  function closeDialog() {
    dispatch({ type: 'CLOSE_DIALOG' })
  }
  function handleNoteChange(e) {
    dispatch({ type: 'CHANGE_NOTE', data: e.target.value })
  }
  function handleClose() {
    closeDialog()
  }
  function handleSave() {
    createNote().then(closeDialog)
  }

  return (
    <NoteCell className="ar-notes">
      <PrincipalNotesLink onClick={openDialog} id={'arnote-' + principalsId}>
        <NoteGroup column spacingPx={4}>
          {note && <NoteCellText>{note}</NoteCellText>}
          <Typography variant="caption">
            {note ? `${username} on ${date}` : 'No previous note'}
          </Typography>
        </NoteGroup>
        <PrincipalNotesIcon />
      </PrincipalNotesLink>
      <IconButton
        href={
          customerId
            ? `/old/database/ar.php?JobID=${customerId}`
            : `/old/database/accountar.php?AccountID=${accountId}`
        }
        size="large"
      >
        <ViewList />
      </IconButton>
      <Dialog open={isOpen} onClose={handleClose} fullWidth>
        <DialogTitle>{name}</DialogTitle>
        <DialogContent>
          {note && (
            <>
              <Typography variant="caption" gutterBottom>
                Last note by {username} on {date}
              </Typography>
              <DialogContentText>{note}</DialogContentText>
              <NoteDivider />
            </>
          )}
          <TextField
            value={newNote}
            onChange={handleNoteChange}
            variant="filled"
            label="New AR Note"
            fullWidth
            multiline
            autoFocus
            inputProps={{
              name: 'new-arnote',
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={handleSave}
            color="primary"
            variant="contained"
            disabled={newNote === '' || loading}
          >
            {loading && (
              <>
                <CircularProgress size={24} />
                <Space inline />
              </>
            )}
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </NoteCell>
  )
}

function BalanceCell({ balances, total, mainBalance, style = {}, moneyFormat }) {
  return (
    <CellContent
      // hasRecurringDebt={hasRecurringDebt(balances)}
      hasOutstandingDebt={hasOutstandingDebt(balances)}
      mainBalance={mainBalance}
      noBalance={!total}
      style={style}
    >
      {formatMoneyStandard(total || 0, moneyFormat)}
    </CellContent>
  )
}

function PauseButton({ rowData, cellData, onRefetch }) {
  let customerId = rowData.id.split('-')[1]

  let [pause, { loading }] = useMutation(PAUSE_CUSTOMER_SERVICES, {
    update: cache => {
      let fragment = gql`
        fragment customerPause on Customers {
          services_paused
        }
      `
      let id = 'Customers:' + customerId
      // don't need this. Apollo handles automatically.
      // cache.writeFragment({
      //   id,
      //   fragment,
      //   fragmentName: 'customerPause',
      //   data: {
      //     __typename: 'Customers',
      //     services_paused: !cellData,
      //   },
      // })
      // console.log('hey yo ' + customerId)
      // onRefetch()
    },
  })

  if (rowData) {
    return (
      <IconButton
        id={'pause-' + customerId}
        disabled={loading}
        onClick={() =>
          pause({
            variables: {
              id: customerId,
              paused: !cellData,
            },
          })
        }
        size="large"
      >
        {loading ? (
          <CircularProgress size={24} />
        ) : !cellData ? (
          <Pause />
        ) : (
          <PlayArrow />
        )}
      </IconButton>
    )
  }
}

class ArList extends React.Component {
  static propTypes = { customers: PropTypes.array }
  static fragments = {
    principal: gql`
      fragment PrincipalDetail on Principals {
        id
        name
        balance
        thirtyDay
        sixtyDay
        ninetyDay
        overNinetyDay
        terms_id
        batch_output
        type
        last_arnote {
          ...arNote
        }
      }
      ${AR_NOTE}
    `,
    customer: gql`
      fragment CustomerARDetail on Customers {
        id
        name
        services_paused
        account_id
        stop_at
        balances {
          id
          balance
          sixtyDay
          thirtyDay
          ninetyDay
          overNinetyDay
        }
        terms_id
        batch_output
        customertype {
          id
          type
        }
        last_arnote {
          ...arNote
        }
        credit_items {
          id
          amount
        }
      }
      ${AR_NOTE}
    `,
  }
  static defaultProps = { customers: [] }

  parent = this

  getRowCount = () => {
    let { pageInfo } = this.props
    if (!pageInfo) {
      return 9999
    }
    return pageInfo.total
  }

  debouncedRefetch = debounce(this.props.onRefetch, 500)

  render() {
    const { customers, pageInfo, loading, display = 'customers', hideCols = [] } = this.props

    return (
      <ArTableList
        data={customers}
        infinite
        loadMoreRows={this.props.onInfiniteLoad}
        rowCount={pageInfo ? pageInfo.total : 9999}
        rowClickable
        loading={loading}
        rowClassName={({ index }) => {
          let classnames = ''
          let sortedData = !!customers
            ? customers.sort((a, b) => (b.cursor > a.cursor ? -1 : 1))
            : []
          let rowData =
            sortedData && sortedData[index] && sortedData[index].edge

          if (!!rowData) {
            classnames += rowData.id === 'customers-0' ? 'badData' : ''
            classnames += rowData.services_paused ? ' paused' : ''
            classnames += !!rowData.stop_at ? ' stopped' : ''
          }

          return classnames
        }}
      >
        <Column
          dataKey="name"
          headerRenderer={({ label }) => (
            <div style={{ textAlign: 'center' }}>{label}</div>
          )}
          cellRenderer={props => this._renderNameColumn({ ...props, display })}
          label="Name"
          flexGrow={1}
          width={0}
        />
        <Column
          dataKey="balance"
          label="Balance"
          width={75}
          headerRenderer={({ label }) => (
            <div style={{ textAlign: 'right' }}>{label}</div>
          )}
          cellRenderer={({ cellData, rowData }) => (
            <a
              href={
                rowData && rowData.type === 'Account'
                  ? `/accounts/${rowData.id.split('-')[1]
                  }/transactions/payments/add`
                  : rowData && rowData.type === 'Customer'
                    ? `/customers/${rowData.id.split('-')[1]
                    }/transactions/payments/add`
                    : './'
              }
            >
              <BalanceCell balances={rowData} total={cellData} mainBalance />
            </a>
          )}
        />
        {display === 'customers' && !hideCols.includes('credit') && (
          <Column
            dataKey="credit_items"
            label="Credit"
            width={75}
            headerRenderer={({ label }) => (
              <div style={{ textAlign: 'right' }}>{label}</div>
            )}
            cellRenderer={({ cellData, rowData }) => (
              <BalanceCell balances={rowData} total={-cellData?.reduce((carry, ci) => carry + ci.amount, 0)} moneyFormat={
                {
                  pos: '%s%v',
                  neg: '(%s%v)',
                  zero: '(%s%v)',
                }
              } />
            )}
          />
        )}
        {!hideCols.includes('thirtyDay') &&
          <Column
            dataKey="thirtyDay"
            label="1 - 30"
            width={75}
            headerRenderer={({ label }) => (
              <div style={{ textAlign: 'right' }}>{label}</div>
            )}
            cellRenderer={({ cellData, rowData }) => (
              <BalanceCell balances={rowData} total={cellData} />
            )}
          />}
        {!hideCols.includes('sixtyDay') &&
          <Column
            dataKey="sixtyDay"
            label="31 - 60"
            width={75}
            headerRenderer={({ label }) => (
              <div style={{ textAlign: 'right' }}>{label}</div>
            )}
            cellRenderer={({ cellData, rowData }) => (
              <BalanceCell balances={rowData} total={cellData} />
            )}
          />
        }
        {!hideCols.includes('ninetyDay') &&
          <Column
            dataKey="ninetyDay"
            label="61 - 90"
            width={75}
            headerRenderer={({ label }) => (
              <div style={{ textAlign: 'right' }}>{label}</div>
            )}
            cellRenderer={({ cellData, rowData }) => (
              <BalanceCell balances={rowData} total={cellData} />
            )}
          />
        }
        {!hideCols.includes('overNinetyDay') &&
          <Column
            dataKey="overNinetyDay"
            label="> 90"
            width={75}
            headerRenderer={({ label }) => (
              <div style={{ textAlign: 'right' }}>{label}</div>
            )}
            cellRenderer={({ cellData, rowData }) => (
              <BalanceCell balances={rowData} total={cellData} />
            )}
          />
        }
        <Column
          dataKey="note"
          label="AR notes"
          ter
          width={450}
          headerRenderer={({ label }) => (
            <div style={{ textAlign: 'center' }}>{label}</div>
          )}
          cellRenderer={({ cellData, rowData }) => {
            if (rowData) {
              let customerId, accountId
              let principalId = rowData.id.split('-')[1]
              if (rowData.type === 'Account') {
                accountId = principalId
              } else {
                customerId = principalId
              }
              let username =
                rowData.last_arnote &&
                rowData.last_arnote.user &&
                rowData.last_arnote.user.first_name +
                ' ' +
                rowData.last_arnote.user.last_name
              return (
                <ARNote
                  note={rowData.last_arnote && rowData.last_arnote.note}
                  name={rowData.name}
                  customerId={customerId}
                  accountId={accountId}
                  principalsId={rowData.id}
                  username={username}
                  date={
                    rowData.last_arnote &&
                    rowData.last_arnote.recorded_at &&
                    moment
                      .utc(rowData.last_arnote.recorded_at)
                      .local()
                      .format('M/D/YYYY')
                  }
                />
              )
            }
          }}
        />
        {display === 'customers' && (
          <Column
            width={48}
            dataKey="services_paused"
            label="Paused"
            headerRenderer={({ label }) => (
              <Tooltip title="Paused customers will not generate workorders but will still show on the schedule">
                <div style={{ textAlign: 'center' }}>{label}</div>
              </Tooltip>
            )}
            cellRenderer={({ cellData, rowData }) => (
              <PauseButton
                cellData={cellData}
                rowData={rowData}
                onRefetch={this.debouncedRefetch}
              />
            )}
          />
        )}
      </ArTableList>
    )
  }

  _renderNameColumn = ({ rowData, rowIndex, isScrolling, display }) =>
    rowData && (
      <Link
        to={
          rowData.type === 'Account'
            ? `/accounts/${rowData.id.split('-')[1]}`
            : `/customers/${rowData.id.split('-')[1]}`
        }
      >
        <div style={sheet.cellContainer}>
          <div style={sheet.photoWrapper}>
            <PrincipalAvatar
              type={rowData.batch_output}
            // url={rowData.photo && rowData.photo.thumb_url}
            />
          </div>
          <div>
            <NameContent
              className="name"
              hasOutstandingDebt={hasOutstandingDebt(rowData)}
            // hasRecurringDebt={hasRecurringDebt(rowData)}
            >
              {display === 'customers' &&
                (!rowData.account_id || rowData.account_id === '191') && (
                  <Business style={{ marginRight: '.25em' }} />
                )}
              {display === 'principals' &&
                (rowData.type === 'Customer' ? (
                  <Tooltip title="Customer">
                    <Person style={{ marginRight: '.25em' }} />
                  </Tooltip>
                ) : (
                  <Tooltip title="Account">
                    <People style={{ marginRight: '.25em' }} />
                  </Tooltip>
                ))}
              {rowData.name}
            </NameContent>
          </div>
        </div>
      </Link>
    )
}

export default ArList
