import React, {useState} from 'react'
import {useMutation, useQuery, useQueryClient} from 'react-query'
import {gql, prgql} from '../../utils/graphql'
import {
  Grid,
  Typography,
  Button,
  Paper,
  Tabs,
  Tab,
  Toolbar,
  FormControlLabel,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Chip,
  Switch,
  IconButton,
} from '@mui/material'
import TableList, {TrashableTableList} from '../../components/Table/TableList'
import {Column} from 'react-virtualized'
import {usePagination} from '../../components/PaginationContainer'
import {
  Brightness2,
  Brightness5,
  RestoreFromTrash,
  Save,
  Settings,
} from '@mui/icons-material'
import {
  CloseButton,
  DeleteMenuItem,
  DetailToolbar,
  ToolbarCenter,
  ToolbarIconMenu,
  ToolbarLeft,
  ToolbarRight,
  TrashMenuItem,
} from '../../components/Toolbar'
import {NavigateBack} from '../../components/Navigator'
import styled from 'styled-components'
import {Space} from '../../components/Layout'
import {Formik} from 'formik'
import {TextField} from '../../components/forms'
import {RedirectBack} from '../../components/Navigator'
import {Link} from 'react-router-dom'
import {PLACEHOLDER} from '../../constants/EmailTemplates'
import {Code} from '../../components/Code'
import toaster from '../../utils/toaster'
import colors from '../../styles/colors'
import {useAuth} from '../../security/auth'
import {fetchAPI} from '../../schema/utils'
import {WhiteBackgroundSwitch} from '../../components/WhiteBackgroundSwitch'

let query = gql`
  query Routes($limit: Int = 20, $cursor: String, $filters: RoutesFilters) {
    routes: allRoutes(limit: $limit, cursor: $cursor, filters: $filters) {
      edges {
        edge {
          id
          name
          description
          location
          active
          shift_id
          deleted_at
        }
        cursor
      }
      pageInfo {
        next
        current
        prev
        count
        total
      }
    }
  }
`

let getQuery = gql`
  query Route($id: String!) {
    route: routes(id: $id) {
      id
      name
      description
      location
      active
      shift_id
    }
  }
`

let createMutation = gql`
  mutation Create($input: CreateRoutesInput) {
    createRoutes(input: $input) {
      routes {
        id
      }
    }
  }
`

let updateMutation = gql`
  mutation Update($input: UpdateRoutesInput) {
    updateRoutes(input: $input) {
      routes {
        id
        name
      }
    }
  }
`

let deleteMutation = gql`
  mutation Delete($id: String!) {
    deleteRoutes(input: {id: $id}) {
      message
    }
  }
`

let SunIcon = styled(Brightness5)`
  color: ${colors.grey700};
`

let MoonIcon = styled(Brightness2)`
  color: ${colors.grey700};
`

const Routes = () => {
  let [showTrash, setShowTrash] = useState(false)
  let {data, loading, loadMore, refetch} = usePagination({
    query: query,
    variables: {
      // order: 'name_ASC',
      cursor: '-1',
      filters: {
        trashed: showTrash,
      },
    },
    fetchPolicy: 'cache-and-network',
  })
  let {mutateAsync: restore} = useMutation(
    'restore',
    async ({id}) =>
      await fetchAPI({url: `/routes/${id}/restore`, options: {method: 'POST'}}),
  )

  function getRowCount() {
    if (!data || !data.routes || !data.routes.pageInfo) {
      return 9999
    }
    return data.routes.pageInfo.total
  }

  let {status: canManageRouterunTemplates} = useAuth(
    'manage routerun templates',
  )

  return (
    <>
      <Toolbar>
        <FormControlLabel
          style={{marginLeft: '2em'}}
          control={
            <WhiteBackgroundSwitch
              checked={showTrash}
              onChange={e => {
                setShowTrash(e.target.checked)
              }}
              color="secondary"
            />
          }
          label={'Show Trash'}
        />
      </Toolbar>
      <TrashableTableList
        data={(data && data.routes && data.routes.edges) || []}
        infinite
        loadMoreRows={loadMore}
        rowCount={getRowCount()}
        wrapRow={({children, rowData}) =>
          canManageRouterunTemplates === 'authorized' && !rowData.deleted_at ? (
            <Link to={`/settings/routes/${rowData.id}`}>{children}</Link>
          ) : (
            children
          )
        }
        rowClassName={({index}) => {
          let classnames = ''
          let sortedData =
            !!data && data.routes
              ? data.routes?.edges?.sort((a, b) =>
                  b.cursor > a.cursor ? -1 : 1,
                )
              : []
          let rowData =
            sortedData && sortedData[index] && sortedData[index].edge

          if (rowData) {
            classnames += rowData.deleted_at ? ' trashed' : ''
          }

          return classnames
        }}
      >
        <Column
          dataKey="name"
          headerRenderer={({label}) => (
            <div style={{textAlign: 'center'}}>{label}</div>
          )}
          cellRenderer={data => (
            <Typography style={{display: 'flex', alignItems: 'center'}}>
              {data.rowData?.shift_id === '1' ? <SunIcon /> : <MoonIcon />}{' '}
              &nbsp; Route {data.cellData}
            </Typography>
          )}
          label="Name"
          flexGrow={1}
          width={0}
        />
        <Column
          dataKey="description"
          headerRenderer={({label}) => (
            <div style={{textAlign: 'center'}}>{label}</div>
          )}
          cellRenderer={data => (
            <Typography variant="body2">{data.cellData}</Typography>
          )}
          label="Description"
          flexGrow={4}
          width={0}
        />
        {showTrash && canManageRouterunTemplates === 'authorized' && (
          <Column
            dataKey="id"
            cellRenderer={data => (
              <LeftIcon>
                <IconButton
                  onClick={async () => {
                    await restore({id: data.cellData})
                    refetch()
                  }}
                >
                  <RestoreFromTrash />
                </IconButton>
              </LeftIcon>
            )}
          />
        )}
      </TrashableTableList>
    </>
  )
}
let PaperContainer = styled(Paper)`
  width: 80%;
  margin: 16px auto 0;
  padding: 2em;
`
let LeftIcon = styled.div`
  margin-right: ${({theme}) => theme.muiTheme.spacing(1)};
`

let ShiftChip = styled(Chip).attrs(() => ({
  classes: {
    root: 'root',
  },
}))`
  &.root {
    background-color: ${({dark}) => (dark ? 'black' : 'white')};
    border: 2px solid;
    border-color: ${({dark}) => (dark ? colors.grey50 : colors.grey400)}
    color: ${({dark}) => (dark ? colors.grey50 : colors.grey800)}
    font-weight: bold;
    padding: 10px;
  }
`

let ShiftToggle = ({value, setValue}) => (
  <>
    {value === '1' ? (
      <ShiftChip
        clickable
        avatar={<SunIcon />}
        label="Day"
        onClick={() => setValue(2)}
      />
    ) : (
      <ShiftChip
        clickable
        dark
        avatar={<MoonIcon style={{color: colors.grey400}} />}
        label="Night"
        onClick={() => setValue(1)}
      />
    )}
  </>
)

let Form = ({onSubmit, initial = {}, formikkey, onDelete = () => {}}) => {
  const [warn, setWarn] = useState(false)

  return (
    <>
      <Formik initialValues={initial} onSubmit={onSubmit} key={formikkey}>
        {({submitForm, values, setValues, setFieldValue}) => (
          <>
            <DetailToolbar>
              <ToolbarLeft>
                <NavigateBack
                  defaultBack={location.pathname}
                  marker="RoutesBack"
                >
                  <CloseButton />
                </NavigateBack>
              </ToolbarLeft>
              <ToolbarCenter>
                <Typography variant="h6">
                  {values.id ? 'Edit' : 'Add'} Route
                </Typography>
              </ToolbarCenter>
              <ToolbarRight>
                <Space sizePx={16} inline />
                <Button color="primary" onClick={submitForm}>
                  <LeftIcon>
                    <Save />
                  </LeftIcon>
                  Save
                </Button>
                <ToolbarIconMenu>
                  <TrashMenuItem onClick={() => setWarn(true)} />
                </ToolbarIconMenu>
              </ToolbarRight>
            </DetailToolbar>
            <Grid container spacing={2}>
              <Grid
                item
                xs={10}
                style={{display: 'flex', alignItems: 'center'}}
              >
                <Typography variant="h6" style={{marginRight: '16px'}}>
                  Route
                </Typography>
                <TextField
                  fullWidth
                  label="Name"
                  name="name"
                  variant={'outlined'}
                />
              </Grid>
              <Grid item xs={2}>
                <ShiftToggle
                  value={values.shift_id}
                  setValue={v => setFieldValue('shift_id', String(v))}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Description"
                  name="description"
                  multiline
                  variant={`outlined`}
                />
              </Grid>
            </Grid>
            <Dialog open={warn} onClose={() => setWarn(false)}>
              <DialogTitle>Delete Record</DialogTitle>
              <DialogContent>
                Are you sure you want to Trash this record? (You can restore it
                from trash later)
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setWarn(false)}>Cancel</Button>
                <Button onClick={() => onDelete(values.id)} variant="contained">
                  Trash
                </Button>
              </DialogActions>
            </Dialog>
          </>
        )}
      </Formik>
    </>
  )
}

export const Route = ({match, location}) => {
  let [goBack, setGoBack] = useState(false)

  let queryClient = useQueryClient()
  let {data, isError, isLoading} = useQuery(
    ['single', match.params.id],
    async () =>
      await prgql({query: getQuery, variables: {id: match.params.id}}),
  )

  let {mutateAsync} = useMutation('update', async input => {
    return await prgql({
      query: updateMutation,
      variables: {input},
    })
  })

  let {mutateAsync: destroy} = useMutation('delete', async ({id}) => {
    return await prgql({
      query: deleteMutation,
      variables: {id},
    })
  })

  return (
    <>
      <PaperContainer>
        {isLoading ? (
          <div>Loading...</div>
        ) : isError ? (
          <div>Error</div>
        ) : (
          <Form
            formikkey={match.params.id}
            initial={data && data.route}
            onSubmit={async values => {
              let {is_system, ...saveValues} = values
              await mutateAsync(saveValues)
              await queryClient.invalidateQueries(['single', match.params.id])
              toaster.success('Route saved')
              setGoBack(true)
            }}
            onDelete={async id => {
              await destroy({id})
              toaster.success('Route trashed')
              setGoBack(true)
            }}
          />
        )}
      </PaperContainer>
      {/* This is extremely evil. we need a hook that'll do this */}
      {goBack && (
        <RedirectBack
          defaultBack={location.pathname}
          marker="RoutesBack"
          location="/settings/routes"
        />
      )}
    </>
  )
}

export const NewRoute = ({match, location}) => {
  let [goBack, setGoBack] = useState(false)

  let {mutateAsync} = useMutation('create', async input => {
    return await prgql({
      query: createMutation,
      variables: {input},
    })
  })

  return (
    <>
      <PaperContainer>
        {
          <Form
            formikkey={match.params.id}
            initial={{
              name: '',
              active: true,
              shift_id: '1',
            }}
            onSubmit={async values => {
              let {is_system, ...saveValues} = values
              await mutateAsync(saveValues)
              setGoBack(true)
              toaster.success('Route Created')
            }}
          />
        }
      </PaperContainer>
      {/* This is extremely evil. we need a hook that'll do this */}
      {goBack && (
        <RedirectBack defaultBack={location.pathname} marker="RoutesBack" />
      )}
    </>
  )
}

export default Routes
