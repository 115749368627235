import axios from 'axios'
import {normalize} from 'normalizr'
import {addSecurityRetryQueue} from '../actions/authActions'

export const CALL_API = Symbol('Call API')

export const API_DOMAIN = ''
export const API_ROOT = '/api/'

function callApi(endpoint, params) {
  return axios({url: API_ROOT + endpoint, params, withCredentials: true})
}

export default store => next => action => {
  const callAPI = action[CALL_API]
  if (typeof callAPI === 'undefined') {
    return next(action)
  }

  // transform shouldn't be used often. Just an escape hatch.
  const defaultTransform = res => res
  const {endpoint, params = {}, schema, types, transform} = callAPI
  if (typeof endpoint !== 'string') {
    throw new Error('Specify a string endpoint URL.')
  }
  if (!schema) {
    throw new Error('Specify on of the exported Schemas.')
  }
  if (!Array.isArray(types) || types.length !== 3) {
    throw new Error('Expected an array of three action types.')
  }
  if (!types.every(type => typeof type === 'string')) {
    throw new Error('Expected action types to be strings.')
  }

  function actionWith(data) {
    const finalAction = Object.assign({}, action, data)
    delete finalAction[CALL_API]
    return finalAction
  }

  const [requestType, successType, failureType] = types
  next(actionWith({type: requestType}))

  return new Promise((fulfill, reject) => {
    callApi(endpoint, params, schema, transform).then(fulfill).catch(res => {
      if (res.status === 401) {
        fulfill(new Promise((fulfill, reject) => {
          next(
            addSecurityRetryQueue({
              reason: 'unauthorized server',
              retry: () => {
                axios(res.config).then(fulfill).catch(reject)
              },
            }),
          )
        }))
      }
      reject(res)
    })
  })
    .then(transform)
    .then(res => {
      if (!res && transform !== defaultTransform) {
        throw new Error('Your transform did not return a response')
      }
      // queries with the different pages are still same query
      const {...uniqueParams} = params
      return {
        ...normalize(res.data.data, schema),
        endpoint,
        params: uniqueParams,
        meta: res.data.meta,
      }
    })
    .then(
      response => next(actionWith({payload: response, type: successType})),
      error => {
        if (error.status === 401) {
          return next({type: failureType})
        }

        return next(
          actionWith({
            type: failureType,
            error: error.message || 'Something bad happened',
          }),
        )
      },
    )
    .catch(error => console.error(error.stack))
}

