import PropTypes from 'prop-types'
import React, { Component, useEffect, useState } from 'react'
import _, { round } from 'lodash'
import { Redirect } from '../../pr-router'

import { monthDayYear } from '../../utils/dateFormatter'

import DataBlock from '../../components/DataBlock'

import {
  Paper,
  Divider,
  TextField as MuiTextfield,
  MenuItem,
  Button,
  Typography,
  ListItemIcon,
  ListItemText,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Select,
  CircularProgress,
  InputAdornment,
  Tooltip,
  styled,
  IconButton,
} from '@mui/material'
import { Headline, Body1, Subhead, Title } from '../../components/Typography'
import { Link } from '../../pr-router'
import moment from 'moment'
import TableList from '../../components/Table/TableList'
import { Column } from 'react-virtualized'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { gql, prgql } from '../../utils/graphql'
import { fetchAPI } from '../../schema/utils'
import { getAttribute, getIncluded, getRelationship } from '../../utils/json-api'
import TableCell from '../../components/Table/TableCell'
import { Formik } from 'formik'
import {
  AutoCompleteField,
  Checkbox,
  DatePicker,
  TextField,
} from '../../components/forms'
import { PaymentTypeSelectStatic } from '../../components/PaymentTypeSelect'
import { RedirectBack } from '../../components/Navigator'
import toaster from '../../utils/toaster'
import { formatMoneyStandard } from '../../utils/moneyFormatter'
import { Delete, Edit, Mail, MoreVertOutlined, Save } from '@mui/icons-material'
import LoadingButton from '../../components/LoadingButton'
import { useAuth } from '../../security/auth'
import {
  CloseButton,
  PaperToolbar,
  ToolbarGroup,
  ToolbarRow,
} from '../../components/Toolbar'
import IconMenu from '../../components/IconMenu'
import DeleteDialog from '../../components/DeleteDialog'
import BorderButton from '../../components/BorderButton'
import { Space } from '../../components/Layout'

let wrapEl = (el, children) =>
  el ? React.cloneElement(el, { children }) : children

let SaveIcon = styled(Save)`
  margin-right: ${({ theme: { muiTheme } }) => muiTheme?.spacing(1)};
`

class DetailToolbar extends Component {
  static propTypes = {
    title: PropTypes.string,
    onEdit: PropTypes.func,
    onCancel: PropTypes.func,
    onDelete: PropTypes.func,
    onClose: PropTypes.func,
    closeWrapper: PropTypes.element,
    onSave: PropTypes.func,
    canSave: PropTypes.bool,
    editing: PropTypes.bool,
    deleteMessage: PropTypes.node,
    deleteTitle: PropTypes.string,
    isSaving: PropTypes.bool,
    otherMenuItems: React.Fragment,
    deleteDisabled: PropTypes.bool,
  }

  state = { confirmDelete: false }

  toggleDeleteDialog = () =>
    this.setState(({ confirmDelete }) => ({ confirmDelete: !confirmDelete }))

  render() {
    let {
      title,
      onEdit,
      onDelete,
      onClose,
      closeWrapper,
      cancelWrapper,
      onCancel,
      onSave,
      canSave,
      editing,
      deleteMessage,
      deleteTitle,
      isSaving,
      showSaveButton,
      otherMenuItems,
      deleteDisabled,
    } = this.props
    let { confirmDelete } = this.state

    return (
      <PaperToolbar>
        <ToolbarRow>
          {!editing ? (
            <React.Fragment>
              <ToolbarGroup first={true}>
                {wrapEl(closeWrapper, <CloseButton onClick={onClose} />)}
              </ToolbarGroup>
              <ToolbarGroup style={{ flexGrow: 1 }}>
                <Typography variant="h6" color="inherit">
                  {title}
                </Typography>
              </ToolbarGroup>
              <ToolbarGroup last={true}>
                <IconButton
                  onClick={onEdit}
                  id="detail-toolbar-edit"
                  size="large"
                >
                  <Edit />
                </IconButton>
                <IconMenu
                  iconButtonElement={
                    <IconButton size="large">
                      <MoreVertOutlined />
                    </IconButton>
                  }
                  anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                  targetOrigin={{ horizontal: 'right', vertical: 'top' }}
                  id={'detailMenu'}
                >
                  {otherMenuItems}
                  <MenuItem
                    onClick={this.toggleDeleteDialog}
                    disabled={deleteDisabled}
                  >
                    <ListItemIcon>
                      <Delete />
                    </ListItemIcon>
                    <ListItemText primary="Delete" id={'detailMenuDelete'} />
                  </MenuItem>
                </IconMenu>
              </ToolbarGroup>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <ToolbarGroup first={true}>
                {wrapEl(
                  cancelWrapper,
                  <BorderButton
                    darkBorder
                    children="cancel"
                    style={{ marginRight: '10px', borderColor: 'rgba(0,0,0,.2)' }}
                    onClick={onCancel}
                  />,
                )}
              </ToolbarGroup>
              <ToolbarGroup style={{ flexGrow: 1 }}>
                <Typography variant="h6" color="inherit">
                  {title}
                </Typography>
              </ToolbarGroup>
              <ToolbarGroup last={true}>
                {showSaveButton ? (
                  <LoadingButton
                    variant="contained"
                    style={{ marginRight: '10px' }}
                    color="primary"
                    disabled={!canSave || isSaving}
                    loading={isSaving}
                    onClick={onSave}
                  >
                    <SaveIcon /> Save
                  </LoadingButton>
                ) : (
                  <Space inline sizePx={130} />
                )}
              </ToolbarGroup>
            </React.Fragment>
          )}
        </ToolbarRow>
        <Divider style={{ margin: '0 0 5px 0' }} />
        <DeleteDialog
          isOpen={confirmDelete}
          onConfirm={onDelete}
          onCancel={this.toggleDeleteDialog}
          message={deleteMessage}
          title={deleteTitle}
        />
      </PaperToolbar>
    )
  }
}

export class DetailBottomToolbar extends Component {
  static propTypes = {
    title: PropTypes.string,
    onCancel: PropTypes.func,
    onSave: PropTypes.func,
    canSave: PropTypes.bool,
    editing: PropTypes.bool,
    isSaving: PropTypes.bool,
  }

  state = { confirmDelete: false }

  toggleDeleteDialog = () =>
    this.setState(({ confirmDelete }) => ({ confirmDelete: !confirmDelete }))

  render() {
    let {
      title,
      cancelWrapper,
      onCancel,
      onSave,
      canSave,
      editing,
      isSaving,
    } = this.props

    return (
      <PaperToolbar>
        <Divider style={{ margin: '0 0 0px 0' }} />
        <ToolbarRow>
          {!editing ? (
            <React.Fragment>
              <ToolbarGroup first={true}></ToolbarGroup>
              <ToolbarGroup style={{ flexGrow: 1 }}></ToolbarGroup>
              <ToolbarGroup last={true}></ToolbarGroup>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <ToolbarGroup first={true}>
                {wrapEl(
                  cancelWrapper,
                  <BorderButton
                    darkBorder
                    children="cancel"
                    style={{ marginRight: '10px', borderColor: 'rgba(0,0,0,.2)' }}
                    onClick={onCancel}
                  />,
                )}
              </ToolbarGroup>
              <ToolbarGroup style={{ flexGrow: 1 }}>
                <Typography variant="h6" color="inherit">
                  {title}
                </Typography>
              </ToolbarGroup>
              <ToolbarGroup last={true}>
                <LoadingButton
                  variant="contained"
                  style={{ marginRight: '10px' }}
                  color="primary"
                  disabled={!canSave || isSaving}
                  loading={isSaving}
                  onClick={onSave}
                >
                  <SaveIcon /> Save
                </LoadingButton>
              </ToolbarGroup>
            </React.Fragment>
          )}
        </ToolbarRow>
      </PaperToolbar>
    )
  }
}

// My deepest and sincerest apologies for the possible mess I have created.
// Payment and Account payment are effectively the same thing except for the
// data structure they work with.
//
// Customers = Payments > Paymentitems > invoices / refunds
// Accounts = AccountPayments > Payments > Paymentitems > invoices / refunds
//
// So, hypotheticaly, you don't *need* to make a different screen for each.
// And, since this screen has a lot of logical complexity too it, I didn't
// want to make two versions of it that hold the exact same logic. If users
// start requesting lots of specific differences between the two, then you
// will have to make a new screen for each.
//
// ~ Matt Krell, 2023-06-07

// static propTypes = {
//   match: PropTypes.shape({url: PropTypes.string}).isRequired,
//   customerId: PropTypes.string.isRequired,
//   paymentId: PropTypes.string,
// };
const Payment = ({ match, customerId, paymentId }) => {
  let [state, setState] = useState({
    payment: { attributes: {}, relationships: { paymentitems: { data: [] } } },
    goBack: false,
    goBackUrl: '',
    isEditing: false,
  })
  let [emailDialog, setEmailDialog] = useState(false)

  let isAccount = match.path.split('/')[1] === 'accounts' ? true : false
  let parentType = isAccount ? 'accounts' : 'customers'
  let resourceType = isAccount ? 'accountpayments' : 'payments'
  let subResourceType = isAccount ? 'payments' : 'paymentitems'

  let getPaymentCreditStats = ({ values, credit, totalRefunds = 0 }) => {
    let paymentUsed =
      Object.values(values.invoices).reduce((acc, i) => acc + i.pmt_amt, 0) +
      totalRefunds
    let creditUsed = Object.values(values.invoices).reduce(
      (acc, i) => acc + i.crd_amt,
      0,
    )
    let paymentLeft = Math.max(values.amount - paymentUsed, 0)
    let creditLeft = Math.max(credit - creditUsed, 0)
    let paymentPool = paymentLeft + creditLeft

    return { paymentUsed, paymentLeft, creditUsed, creditLeft, paymentPool }
  }

  let { goBack, goBackUrl, isEditing } = state

  const queryClient = useQueryClient()
  const { status: canCreateCreditMemos } = useAuth('create creditmemos')

  let { data, isLoading } = useQuery([resourceType, paymentId], async () => {
    if (!!paymentId) {
      let include = isAccount
        ? 'payments.paymentitems.related,payments.paymentitems.invoice,payments.paymentitems.refund_rel,paymenttype'
        : 'paymentitems.invoice,paymentitems.related,paymenttype'

      return await fetchAPI({
        url: `${resourceType}/${paymentId}`,
        query: {
          include,
        },
      }).then(res => res.json())
    } else {
      // make a fake response so that we don't have to make a separate component just for the "new payment" form
      // This is probably evil but it works.
      let promise = new Promise((resolve, reject) => {
        resolve({
          data: {
            attributes: {
              amount: 0,
              reference: '',
              paymenttype_id: '1',
              memo: '',
              paid_at: moment
                .utc()
                .local()
                .format('YYYY-MM-DD'),
              customer_id: isAccount ? undefined : customerId,
              account_id: isAccount ? customerId : undefined,
            },
            relationships: {
              paymentitems: {
                data: [],
              },
            },
          },
        })
      })
      return await promise.then(res => res)
    }
  })
  let { data: balanceData, isLoading: balanceLoading } = useQuery(
    ['balances-payment', customerId, isAccount],
    async () => {
      return await fetchAPI({
        url: `${parentType}/${customerId}`,
        query: {
          include: 'balances',
        },
      }).then(res => res.json())
    },
  )
  let { data: openInvoicesData, isLoading: openInvoicesLoading } = useQuery(
    ['open_invoices', customerId],
    async () => {
      return await fetchAPI({
        url: `invoices`,
        query: {
          include: 'balances',
          status: 'OPEN',
          customer_id: isAccount ? undefined : customerId,
          account_id: isAccount ? customerId : undefined,
          limit: 100000000,
          cursor: -1,
        },
      }).then(res => res.json())
    },
  )
  let { data: emailsData, isLoading: emailsLoading } = useQuery(
    ['emails', customerId, isAccount],
    async () => {
      return await fetchAPI({
        url: `emails`,
        query: {
          emailable_id: customerId,
          emailable_type: isAccount ? 'App\\Account' : 'App\\Customer',
          limit: 100000000,
          cursor: -1,
        },
      }).then(res => res.json())
    },
  )
  let { mutateAsync: deletePayment } = useMutation({
    mutationFn: async () => {
      return await fetchAPI({
        url: `${resourceType}/${paymentId}`,
        options: {
          method: 'DELETE',
        },
      })
    },
    onSettled: res => {
      if (res.ok) {
        toaster.success('Payment deleted')
      } else {
        toaster.error('Error deleting payment')
      }
      queryClient.invalidateQueries([resourceType, paymentId])
    },
  })
  let { mutateAsync: createPayment, isLoading: createLoading } = useMutation({
    mutationFn: async ({ attributes, paymentitems }) => {
      return await fetchAPI({
        url: resourceType,
        options: {
          method: 'POST',
          body: JSON.stringify({
            data: {
              attributes,
              relationships: {
                [subResourceType]: {
                  data: paymentitems,
                },
              },
            },
          }),
        },
      })
    },
    onSettled: res => {
      if (res.ok) {
        toaster.success('Payment created')
      } else {
        toaster.error('Error creating payment')
      }
      queryClient.invalidateQueries([resourceType, paymentId])
    },
  })
  let { mutateAsync: updatePayment, isLoading: updateLoading } = useMutation({
    mutationFn: async ({ attributes, paymentitems }) => {
      return await fetchAPI({
        url: `${resourceType}/${paymentId}`,
        options: {
          method: 'PATCH',
          body: JSON.stringify({
            data: {
              attributes,
              relationships: {
                [subResourceType]: {
                  data: paymentitems,
                },
              },
            },
          }),
        },
      })
    },
    onSettled: res => {
      if (res.ok) {
        toaster.success('Payment updated')
      } else {
        toaster.error('Error updated payment')
      }
      queryClient.invalidateQueries([resourceType, paymentId])
    },
  })
  let { mutateAsync: applyCredit, isLoading: creditLoading } = useMutation({
    mutationFn: async ({ credititems }) => {
      return await fetchAPI({
        url: `${resourceType}/commands/`,
        options: {
          method: 'POST',
          body: JSON.stringify({
            data: {
              attributes: {
                command: 'applycredit',
                payload: {
                  customer_id: customerId,
                  credititems,
                },
              },
            },
          }),
        },
      })
    },
    onSettled: res => {
      if (res.ok) {
        toaster.success('Credit applied')
      } else {
        toaster.error('Error applying credit')
      }
      queryClient.invalidateQueries([resourceType, paymentId])
    },
  })
  let { mutateAsync: sendReceipt, isLoading: sendReceiptLoading } = useMutation({
    mutationFn: ({ variables }) =>
      prgql({
        query: gql`
          mutation email($emails: [String!]!, $paymentId: ID!) {
            emailReceipt(input: {emails: $emails}, paymentId: $paymentId) {
              error
              message
            }
          }
        `,
        variables,
      }),
    onSettled: res => {
      if (!res.data?.emailReceipt?.error) {
        toaster.success('Receipt sent')
      } else {
        toaster.error('Error sending receipt')
      }
    },
  })

  function _invalidateTransactionQueries() {
    queryClient.invalidateQueries(['transactions'])
    queryClient.invalidateQueries(['balances-payment'])
    queryClient.invalidateQueries(['balances-transactions'])
    queryClient.invalidateQueries(['diagnostics', isAccount, customerId])
    queryClient.invalidateQueries(['open_invoices', customerId])
  }

  // Wait, so you are fetching EVERYTHING you need and then waiting for the screen to load?
  // Yep. If u wanna figure out how to avoid that, be my guest.
  // ~ Matt Krell, 2023-05-31
  if (isLoading || balanceLoading || openInvoicesLoading)
    return <div>Loading...</div>

  let payment = data?.data

  if (!payment)
    return (
      <RedirectBack
        url={goBackUrl}
        defaultBack={`/${parentType}/${customerId}/transactions`}
      />
    )

  let credit = getIncluded({
    included: balanceData.included,
    type: 'balances',
    id: parentType + '-' + customerId,
  })?.attributes.applicable_credit

  let allPaymentItems = isAccount
    ? getRelationship({
      allData: data,
      rowData: payment,
      dataKey: 'payments',
    })?.flatMap(p =>
      getRelationship({ allData: data, rowData: p, dataKey: 'paymentitems' }),
    ) || []
    : getRelationship({
      allData: data,
      rowData: payment,
      dataKey: 'paymentitems',
    }) || []

  // exclude credit from this payment
  credit -= allPaymentItems
    .filter(pi => pi.attributes.invoice_id === '0')
    .reduce((acc, i) => acc + i.attributes.amount, 0)
  allPaymentItems = allPaymentItems.filter(
    pi => pi.attributes.invoice_id !== '0',
  )

  // separate out invoice-related paymentitems and refund-related paymentitems
  let invoicePaymentItems = allPaymentItems.filter(
    pi => pi.attributes.relatedable_type === 'App\\Invoice',
  )
  let refundPaymentItems = allPaymentItems.filter(
    pi => pi.attributes.relatedable_type === 'App\\Refund',
  )

  // get open invoices
  let piInvIds = invoicePaymentItems.map(pi => pi.attributes.invoice_id)
  let openInvoices =
    openInvoicesData?.data.filter(i => !piInvIds.includes(i.id)) || []

  let invoiceRows = [...invoicePaymentItems, ...openInvoices]
  let refundRows = refundPaymentItems

  // calculate the amount of credit / payment applied to each row
  let calculated = invoiceRows.reduce((acc, i) => {
    let id = ''
    let pmt_amt = 0
    let crd_amt = 0
    let checked = false
    let applicable_balance = 0
    let customer_id = 0

    if (i.type === 'invoices') {
      id = i.id
      customer_id = i.attributes.customer_id
      applicable_balance =
        pmt_amt +
        crd_amt +
        getAttribute({
          rowData: i,
          dataKey: 'balance_due',
        })
    } else {
      id = i.attributes.invoice_id
      customer_id = getAttribute({
        rowData: getRelationship({
          allData: data,
          rowData: i,
          dataKey: 'related',
        }),
        dataKey: 'customer_id',
      })

      pmt_amt = i.attributes.amount
      checked = true
      applicable_balance =
        pmt_amt +
        crd_amt +
        getAttribute({
          rowData: getRelationship({
            allData: data,
            rowData: i,
            dataKey: 'related',
          }),
          dataKey: 'balance_due',
        })
    }

    // invoices are spread into this array last, so we can safely ignore them if they are in here already
    if (!acc[id]) {
      acc[id] = {
        id,
        pmt_amt,
        crd_amt,
        applicable_balance,
        checked,
        customer_id,
      }
    }

    return acc
  }, {})

  let isNew = !paymentId

  return (
    <>
      <SendReceiptDialog
        open={emailDialog}
        onCancel={() => setEmailDialog(false)}
        emailsLoading={emailsLoading}
        availableEmails={emailsData?.data || []}
        sending={sendReceiptLoading}
        onSend={async id => {
          const email = emailsData?.data.find(e => e.id === id)
          if (email) {
            await sendReceipt({
              variables: {
                paymentId: payment.id,
                emails: [email.attributes.email],
              },
            })
            setEmailDialog(false)
          } else {
            toaster.error(
              'Please select or create an email to send the receipt to',
            )
          }
        }}
      />
      <Formik
        initialValues={{
          id: isNew ? undefined : payment.id,
          ...payment.attributes,
          invoices: calculated,
        }}
        validate={values => {
          let err = {}

          let paymentAmt = values.amount
          let appliedAmt = Object.values(values.invoices).reduce(
            (acc, i) => acc + (Number.parseFloat(i.pmt_amt) || 0),
            0,
          )
          let appliedCrd = Object.values(values.invoices).reduce(
            (acc, i) => acc + (Number.parseFloat(i.crd_amt) || 0),
            0,
          )

          if (Math.round(paymentAmt * 100) < Math.round(appliedAmt * 100)) {
            Object.entries(values.invoices)
              .filter(([i, v]) => v.checked)
              .forEach(([i, v]) => {
                if (!err.invoices) err.invoices = {}
                if (!err.invoices[i]) err.invoices[i] = {}
                err.invoices[i].pmt_amt = 'Too high!'
              }, {})
          }

          if (Math.round(credit * 100) < Math.round(appliedCrd * 100)) {
            Object.entries(values.invoices)
              .filter(([i, v]) => v.checked)
              .forEach(([i, v]) => {
                if (!err.invoices) err.invoices = {}
                if (!err.invoices[i]) err.invoices[i] = {}
                err.invoices[i].crd_amt = 'Too high!'
              }, {})
          }

          Object.entries(values.invoices)
            .filter(([i, v]) => v.checked)
            .forEach(([i, v]) => {
              if (
                Math.round((values.invoices[i]?.crd_amt || 0) * 100) +
                Math.round((values.invoices[i]?.pmt_amt || 0) * 100) >
                Math.round((values.invoices[i]?.applicable_balance || 0) * 100)
              ) {
                if (!err.invoices) err.invoices = {}
                if (!err.invoices[i]) err.invoices[i] = {}
                err.invoices[i].pmt_amt = 'Overpayment!'
                err.invoices[i].crd_amt = 'Overpayment!'
              }
            }, {})

          return err
        }}
        onSubmit={async values => {
          let {
            invoices: inv,
            recorded,
            recorded_at,
            recordedpayment_id,
            updated_at,
            created_at,
            created_user_id,
            id,
            ...attributes
          } = values

          let invoices = Object.values(values.invoices).filter(i => i.checked)
          let credititems = invoices
            .filter(i => i.crd_amt > 0)
            .map(i => ({ invoice_id: i.id, amount: i.crd_amt }))

          let paymentitems = []
          if (isAccount) {
            paymentitems = invoices
              .filter(i => i.pmt_amt > 0)
              .map(i => ({
                attributes: {
                  ...attributes,
                  amount: i.pmt_amt,
                  customer_id: i.customer_id,
                  accountpayment_id: paymentId,
                },
                relationships: {
                  paymentitems: {
                    data: [{ attributes: { invoice_id: i.id, amount: i.pmt_amt } }],
                  },
                },
              }))
          } else {
            paymentitems = invoices
              .filter(i => i.pmt_amt > 0)
              .map(i => ({ attributes: { invoice_id: i.id, amount: i.pmt_amt } }))
          }

          // if this is a new payment and there's a payment amount, make a new payment
          // otherwise, if it's new, edit it.
          // otherwise, do nothing (the user is just applying credit & we don't need to make a payment)
          if (isNew && attributes.amount > 0) {
            await createPayment({
              attributes,
              paymentitems,
            })
          } else if (!isNew) {
            await updatePayment({
              attributes,
              paymentitems,
            })
          }

          // if we are apply credit, do that
          if (credititems.length > 0) {
            await applyCredit({ credititems })
          }

          _invalidateTransactionQueries()

          let toggle = isNew
            ? () => setState({ goBack: true })
            : () => setState(({ isEditing }) => ({ isEditing: !isEditing }))
          toggle()
        }}
      >
        {({
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          setFieldTouched,
          errors,
          touched,
          isValid,
          resetForm,
          isSubmitting,
          setFieldError,
          validateForm,
        }) => {
          let {
            paymentUsed,
            paymentLeft,
            creditUsed,
            creditLeft,
            paymentPool,
          } = getPaymentCreditStats({
            values,
            credit,
            totalRefunds: refundRows.reduce(
              (acc, r) => acc + r.attributes.amount,
              0,
            ),
          })

          let _checkInvoice = id => {
            let checked = values.invoices[id].checked

            if (checked) {
              // remove numbers
              setFieldValue(`invoices.${id}.pmt_amt`, 0)
              setFieldValue(`invoices.${id}.crd_amt`, 0)
            } else {
              // fill as much as you can
              let applicable_balance = values.invoices[id].applicable_balance
              let pmt_amt = Math.min(applicable_balance, paymentLeft)
              let crd_amt = 0
              if (pmt_amt < applicable_balance) {
                crd_amt = Math.min(applicable_balance - pmt_amt, creditLeft)
              }

              setFieldValue(`invoices.${id}.pmt_amt`, round(pmt_amt, 2))
              setFieldValue(`invoices.${id}.crd_amt`, round(crd_amt, 2))
            }

            setFieldValue(
              `invoices.${id}.checked`,
              !values.invoices[id].checked,
            )

            // Because of the often-extremely-painful asyncronous nature of Javascript, this ensures that the form
            // is validated AFTER we've set all our values. Maybe using a different form
            // library would fix this?
            // ~ Matt Krell, 2023-06-15
            setTimeout(() => {
              validateForm()
            }, 1)
          }

          let _uncheckAllInvoices = e => {
            setFieldValue('amount', e.target.value)
            Object.values(values.invoices)
              .filter(i => i.checked)
              .forEach(i => {
                _checkInvoice(i.id)
              })
          }

          return goBack ? (
            <RedirectBack
              defaultBack={`/${parentType}/${customerId}/transactions`}
            />
          ) : (
            <>
              <PaymentEdit
                isAccount={isAccount}
                onBack={() => setState({ goBack: true })}
                onDelete={async () => {
                  await deletePayment(payment.id)
                  _invalidateTransactionQueries()
                  setState({ goBack: true })
                }}
                toggleEdit={() => {
                  let toggle = isNew
                    ? () => setState({ goBack: true })
                    : () => setState(({ isEditing }) => ({ isEditing: !isEditing }))
                  toggle()
                }}
                onAmountChange={_uncheckAllInvoices}
                payment={payment}
                isNew={isNew}
                canSave={true}
                paymentLeft={paymentLeft}
                creditLeft={creditLeft}
                isEditing={isEditing || isNew}
                allData={data}
                values={values}
                isSaving={isSubmitting}
                onEmailReceipt={() => setEmailDialog(true)}
                hasRefunds={!!refundRows.length}
                canCreateCreditMemos={canCreateCreditMemos === 'authorized'}
              />
              <PaymentItems
                match={match}
                paymentPool={paymentPool}
                paymentLeft={paymentLeft}
                creditLeft={creditLeft}
                paymentItems={invoiceRows}
                refunds={refundRows}
                onCheckInvoice={_checkInvoice}
                isEditing={isEditing || isNew}
                allData={data}
                values={values}
                toggleEdit={() => {
                  let toggle = isNew
                    ? () => setState({ goBack: true })
                    : () => setState(({ isEditing }) => ({ isEditing: !isEditing }))
                  toggle()
                }}
                isSaving={isSubmitting}
                onSave={handleSubmit}
                isAccount={isAccount}
                isValid={isValid}
                setFieldError={setFieldError}
                setFieldValue={setFieldValue}
                customerId={customerId}
              />
            </>
          )
        }}
      </Formik>
    </>
  )
}

// let Container = ({children}) => (
//   <div style={{display: 'flex', flexDirection: 'column'}}>{children}</div>
// )
//
// let Row = ({style, children}) => (
//   <div style={{padding: '0 10px', height: '72px', display: 'flex', ...style}}>
//     {children}
//   </div>
// )

let RowTextField = props => (
  <div style={{ padding: '5px 5px' }}>
    <TextField size="small" {...props} />
  </div>
)

let DataEdit = ({ isEditing, children, ...datatBlockProps }) => (
  <div style={{}}>
    {!isEditing ? (
      <DataBlock spacing={'dense'} {...datatBlockProps} />
    ) : (
      children
    )}
  </div>
)

let Money = ({ value = 0, style = {} }) => (
  <div style={{ textAlign: 'right', ...style }}>{formatMoneyStandard(value)}</div>
)

let SendReceiptDialog = ({
  open,
  onCancel,
  onSend,
  availableEmails = [],
  emailsLoading,
  sending,
}) => {
  let [selectedId, setSelectedId] = useState(null)

  // ugh
  useEffect(() => {
    setSelectedId(
      availableEmails.filter(e => e.attributes.default_email)[0]?.id ||
      availableEmails[0]?.id,
    )
  }, [availableEmails.map(e => e.id).join(',')])

  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <DialogTitle>Send Receipt Email</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography>Email a copy of the receipt to:</Typography>
          </Grid>
          <Grid item xs={12}>
            {emailsLoading ? (
              <CircularProgress />
            ) : (
              <Select
                variant="standard"
                value={selectedId}
                onChange={e => setSelectedId(e.target.value)}
              >
                {availableEmails.map(email => (
                  <MenuItem
                    key={email.id}
                    selected={email.id === selectedId}
                    onClick={() => setSelectedId(email.id)}
                    value={email.id}
                  >
                    {email.attributes.label}: {email.attributes.email}
                  </MenuItem>
                ))}
              </Select>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <LoadingButton
          loading={sending}
          onClick={() => onSend(selectedId)}
          color="primary"
          variant="contained"
        >
          Send Receipt
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

const PaymentEdit = ({
  isAccount,
  isEditing,
  isSaving,
  hasRefunds,
  payment,
  paymentLeft,
  creditLeft,
  onDelete,
  onBack,
  onSave,
  canSave,
  isNew,
  toggleEdit,
  allData,
  values,
  onAmountChange,
  onEmailReceipt,
  canCreateCreditMemos,
}) => {
  let {
    paid_at = '',
    paymenttype_id = 1,
    amount = 0,
    memo = '',
    notes = '',
    reference = '',
    recorded_at = '',
    recorded = false,
  } = payment.attributes || {
    paid_at: '',
    paymenttype_id: 1,
    amount: 0,
    memo: '',
    notes: '',
    reference: '',
    recorded_at: '',
    recorded: false,
  }

  return (
    <>
      <Paper>
        <DetailToolbar
          editing={isEditing}
          title={
            isNew
              ? `Add ${isAccount ? 'Account ' : ' '}Payment`
              : `${isAccount ? 'Account ' : ' '}Payment #${payment.id}`
          }
          onEdit={toggleEdit}
          onCancel={toggleEdit}
          onDelete={onDelete}
          onClose={onBack}
          onSave={onSave}
          canSave={canSave}
          isSaving={isSaving}
          deleteDisabled={hasRefunds}
          deleteMessage={
            <div>
              This may change the recorded payment record.
              <br />
              If there are refunds associated with this payment they will be
              deleted, also.
              <br />
              Are you sure you want to delete this payment?
            </div>
          }
          otherMenuItems={
            !isAccount ? (
              <>
                <MenuItem onClick={onEmailReceipt}>
                  <ListItemIcon>
                    <Mail />
                  </ListItemIcon>
                  <ListItemText primary="Email Receipt" />
                </MenuItem>
              </>
            ) : (
              undefined
            )
          }
        />
        <Grid container style={{ padding: '16px' }} spacing={1}>
          <Grid item lg={2}>
            <DataEdit
              label="Payment Type"
              data={
                getRelationship({
                  rowData: payment,
                  allData,
                  dataKey: 'paymenttype',
                })?.attributes?.type || 'None'
              }
              isEditing={isEditing}
            >
              <PaymentTypeSelectStatic
                id={'paymenttype_id'}
                name="paymenttype_id"
                label="Payment Type"
                fullWidth
                filterOptions={edge => {
                  if (edge.edge.id === values.paymenttype_id) {
                    return true
                  }

                  return !canCreateCreditMemos
                    ? edge.edge.id !== '5' && edge.edge.id !== '4'
                    : true
                }}
                style={{ minWidth: '195px' }}
              />
            </DataEdit>
          </Grid>
          <Grid item lg={2}>
            <DataEdit
              label="Amount"
              data={<Money value={amount} style={{ textAlign: 'left' }} />}
              isEditing={isEditing && !recorded}
            >
              <RowTextField
                label="Amount"
                name={'amount'}
                data-key="amount"
                autoFocus
                fullWidth
                type={'number'}
                onChange={onAmountChange || undefined}
              />
            </DataEdit>
          </Grid>
          <Grid item lg={2}>
            <DataEdit
              label="Reference"
              data={reference || 'None'}
              isEditing={isEditing}
            >
              <RowTextField
                label="Reference"
                name={'reference'}
                autoFocus
                fullWidth
              />
            </DataEdit>
          </Grid>
          <Grid item lg={2}>
            <DataEdit label="Memo" data={memo || 'None'} isEditing={isEditing}>
              <RowTextField label="Memo" name={'memo'} autoFocus fullWidth />
            </DataEdit>
          </Grid>
          <Grid item lg={2}>
            <DataEdit
              label="Paid On"
              data={monthDayYear(paid_at)}
              isEditing={isEditing && !recorded}
            >
              <DatePicker
                name={'paid_at'}
                label={'Paid On'}
                inputVariant="outlined"
                size="small"
                format="dddd, MMMM D, YYYY"
                fullWidth
              />
            </DataEdit>
          </Grid>
          <Grid item lg={4}>
            <DataEdit
              label="Infield Notes"
              data={notes || 'None'}
              isEditing={isEditing}
            >
              <RowTextField
                label="Infield Notes"
                name={'notes'}
                autoFocus
                fullWidth
                multiline
              />
            </DataEdit>
          </Grid>
          {recorded && (
            <Grid item lg={2}>
              <DataBlock
                label="Recorded"
                data={
                  monthDayYear(recorded_at) +
                  ` (RP #${payment.attributes.recordedpayment_id})`
                }
              />
            </Grid>
          )}
        </Grid>
        <Grid
          container
          style={{ paddingBottom: '16px' }}
          alignItems="center"
          justifyContent="center"
        >
          <Grid
            item
            lg={12}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography variant="h6">
              {'Payment available: '}
              <Money value={paymentLeft} style={{ display: 'inline-block' }} />
              {' | Credit available: '}
              <Money value={creditLeft} style={{ display: 'inline-block' }} />
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </>
  )
}

let PaymentItems = ({
  paymentItems: rows,
  refunds = [],
  allData,
  match,
  isEditing,
  isSaving,
  onSave,
  toggleEdit,
  onCheckInvoice,
  values,
  paymentPool,
  isAccount,
  isValid,
  customerId,
}) => {
  // static propTypes = {
  //   paymentItems: PropTypes.object,
  //   paymentPool: PropTypes.number.isRequired,
  //   refunds: PropTypes.object,
  //   onAddItem: PropTypes.func,
  //   onRemoveItem: PropTypes.func,
  //   isEditing: PropTypes.bool,
  // }

  let renderId = ({ rowData, dataKey, transactionPath = 'invoices' }) => {
    let invoice =
      rowData.type === 'invoices'
        ? rowData
        : getRelationship({ rowData, allData: allData, dataKey: 'related' })

    return (
      <TableCell
        textStyles={{ textAlign: 'right' }}
        text={
          <Subhead>
            {transactionPath === 'invoices' ? (
              <a
                href={`/old/database/invoice.php?InvoiceNumber=${invoice?.id}`}
              >
                {invoice?.id}
              </a>
            ) : (
              <a
                href={`/old/database/refundedit.php?RefundID=${invoice?.id
                  }&redirect=/${isAccount ? 'accounts' : 'customers'
                  }/${customerId}/transactions`}
              >
                {invoice?.id}
              </a>
            )}
          </Subhead>
        }
      />
    )
  }

  let _canChange = id => {
    if (!isEditing) {
      return false
    }
    if (values.invoices[id]?.checked) {
      return true
    }
    if (paymentPool === 0 && _getBalanceDue(id) > 0) {
      return false
    }
    return true
  }
  let _isChecked = id => {
    return values.invoices[id]?.checked
  }

  let renderRefundId = ({ rowData, cellData, dataKey }) =>
    renderId({ rowData, cellData, dataKey, transactionPath: 'refunds' })

  let renderDate = ({ rowData }) => {
    let invoice =
      rowData.type === 'invoices'
        ? rowData
        : getRelationship({ rowData, allData: allData, dataKey: 'related' })

    return (
      <TableCell
        align="flex-end"
        textStyles={{ textAlign: 'right' }}
        text={
          <Body1>
            {moment
              .utc(getAttribute({ rowData: invoice, dataKey: 'serviced_at' }))
              .format('M/D/YYYY')}
          </Body1>
        }
      />
    )
  }

  let _getBalanceDue = id =>
    values.invoices[id]?.applicable_balance -
    values.invoices[id]?.pmt_amt -
    values.invoices[id]?.crd_amt

  return (
    <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
      {!!refunds.length && (
        <Paper style={{ marginTop: '10px' }}>
          <Title block style={{ padding: '10px 10px 10px 15px' }}>
            Refunds
          </Title>
          <Divider />
          <div
            style={{ height: '200px', display: 'flex', flexDirection: 'column' }}
          >
            <TableList
              data={refunds}
              rowCount={refunds.length}
              dataType="api"
              id="refunds"
            >
              <Column
                label="Date"
                width={150}
                attribute="refunded_at"
                cellRenderer={renderDate}
              />
              <Column
                label="Refund #"
                dataKey="id"
                width={150}
                cellRenderer={renderRefundId}
              />
              <Column
                label="Notes"
                width={150}
                dataKey="notes"
                cellRenderer={({ rowData, dataKey }) =>
                  getAttribute({
                    rowData: getRelationship({
                      rowData,
                      dataKey: 'related',
                      allData,
                    }),
                    dataKey,
                  })
                }
              />
              <Column
                label="Amount"
                attribute="amount"
                cellRenderer={({ rowData }) => {
                  return <Money value={rowData?.attributes?.amount || 0} />
                }}
                width={150}
              />
            </TableList>
          </div>
        </Paper>
      )}
      <Paper
        style={{
          marginTop: '10px',
          flexGrow: '1',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {!!refunds.length && (
          <Title block style={{ padding: '10px 15px 10px 15px' }}>
            Invoices
          </Title>
        )}
        <Divider />
        <div style={{ display: 'flex', flexDirection: 'column', flex: '1' }}>
          <TableList
            data={rows || []}
            rowCount={rows.length}
            noRowsText={'No open invoices'}
            dataType="api"
          >
            <Column
              width={50}
              label=""
              attribute="balance_due"
              cellRenderer={({ rowData }) => {
                let id =
                  rowData.type === 'invoices'
                    ? rowData.id
                    : rowData.attributes.invoice_id
                return (
                  <Checkbox
                    color="secondary"
                    name={'invoices.' + id + '.checked'}
                    disabled={!_canChange(id)}
                    onChange={() => onCheckInvoice(id)}
                  />
                )
              }}
            />
            <Column
              label="Date"
              width={80}
              attribute="assigned_at"
              cellRenderer={renderDate}
            />
            <Column
              label="Invoice #"
              dataKey="id"
              width={80}
              cellRenderer={renderId}
            />
            {isAccount && (
              <Column
                label="Customer"
                width={0}
                minWidth={150}
                flexGrow={3}
                dataKey="service_name"
                cellRenderer={({ rowData, dataKey }) => {
                  const text =
                    rowData.type === 'invoices'
                      ? rowData?.attributes?.service_name
                      : getAttribute({
                        rowData: getRelationship({
                          rowData,
                          dataKey: 'related',
                          allData,
                        }),
                        dataKey,
                      })
                  return <Body1 title={text}>{text}</Body1>
                }}
              />
            )}
            <Column
              label="Provider PO"
              width={0}
              minWidth={100}
              flexGrow={1}
              dataKey="provider_po"
              cellRenderer={({ rowData, dataKey }) => {
                let text =
                  rowData.type === 'invoices'
                    ? rowData?.attributes?.provider_po
                    : getAttribute({
                      rowData: getRelationship({
                        rowData,
                        dataKey: 'related',
                        allData,
                      }),
                      dataKey,
                    })

                return <Typography>{text}</Typography>
              }}
            />
            <Column
              label="Notes"
              width={0}
              minWidth={150}
              flexGrow={3}
              dataKey="notes"
              cellRenderer={({ rowData, dataKey }) => {
                let text =
                  rowData.type === 'invoices'
                    ? rowData?.attributes?.notes
                    : getAttribute({
                      rowData: getRelationship({
                        rowData,
                        dataKey: 'related',
                        allData,
                      }),
                      dataKey,
                    })

                return <Typography>{text}</Typography>
              }}
            />
            <Column
              label="Orig. Amt."
              width={0}
              minWidth={80}
              dataKey="charge"
              cellRenderer={({ rowData, dataKey }) => (
                <Typography variant="body2">
                  <Money
                    value={
                      rowData.type === 'invoices'
                        ? rowData?.attributes?.charge
                        : getAttribute({
                          rowData: getRelationship({
                            rowData,
                            dataKey: 'related',
                            allData,
                          }),
                          dataKey,
                        })
                    }
                  />
                </Typography>
              )}
            />
            <Column
              label="Amt. Due"
              width={0}
              minWidth={80}
              dataKey="balance_due"
              cellRenderer={({ rowData, dataKey }) => {
                let balance_due = _getBalanceDue(
                  rowData.type === 'invoices'
                    ? rowData.id
                    : rowData.attributes.invoice_id,
                  values,
                )
                return (
                  <Typography variant="body2">
                    <Money value={balance_due} />
                  </Typography>
                )
              }}
            />
            <Column
              label="Payment"
              dataKey="id"
              cellRenderer={({ rowData }) => {
                let id =
                  rowData.type === 'invoices'
                    ? rowData.id
                    : rowData.attributes.invoice_id
                const val = values.invoices[id].pmt_amt
                return isEditing ? (
                  <TextField
                    name={'invoices.' + id + '.pmt_amt'}
                    type={'number'}
                    disabled={!_canChange(id) || !_isChecked(id)}
                    inputProps={{
                      style: {
                        textAlign: 'right',
                      },
                      min: 0,
                    }}
                  />
                ) : (
                  <Typography variant="body2">
                    <Money value={val} />
                  </Typography>
                )
              }}
              width={0}
              minWidth={85}
            />
            <Column
              label="Credit"
              dataKey="id"
              cellRenderer={({ rowData }) => {
                let id =
                  rowData.type === 'invoices'
                    ? rowData.id
                    : rowData.attributes.invoice_id
                const val = values.invoices[id].crd_amt
                return isEditing ? (
                  <TextField
                    name={'invoices.' + id + '.crd_amt'}
                    type={'number'}
                    disabled={!_canChange(id) || !_isChecked(id)}
                    inputProps={{
                      style: {
                        textAlign: 'right',
                      },
                      min: 0,
                    }}
                  />
                ) : (
                  <Typography variant="body2">
                    <Money value={val} />
                  </Typography>
                )
              }}
              width={0}
              minWidth={85}
            />
          </TableList>
        </div>
        {isEditing && (
          <DetailBottomToolbar
            editing={isEditing}
            onCancel={toggleEdit}
            onSave={onSave}
            canSave={isValid}
            isSaving={isSaving}
          />
        )}
      </Paper>
    </div>
  )
}

export default Payment
