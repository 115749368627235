import React, { useEffect, useState } from 'react'
import { Link, Route, Switch } from 'react-router-dom'
import * as Yup from 'yup'

import Button from '@mui/material/Button'
import { Toolbar } from '../../AppHandler'
import { Space, Spacing } from '../../components/Layout'
import {
  FormControlLabel,
  Menu,
  MenuItem,
  LinearProgress,
  Fab,
  Switch as MuiSwitch,
} from '@mui/material'
import Typography from '@mui/material/Typography'
import {
  ArrowDropDown,
  Loop as RepeatIcon,
  Event as RepeatOneIcon,
  Assignment,
  Cancel,
  Edit,
  RadioButtonUnchecked,
  CheckCircle,
  Block,
  ErrorOutline,
  MonetizationOn,
  FlashOn,
  Print,
} from '@mui/icons-material'
import _ from 'lodash'
import { ToolbarCenter, ToolbarLeft } from '../../components/Toolbar'
import styled from 'styled-components'
import gql from 'graphql-tag'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { prgql } from '../../utils/graphql'
import moment from 'moment'
import ModifyQueryParams from '../../components/ModifyQueryParams'
import queryString from 'query-string'
import EditWorkorder from '../Workorder/EditWorkorder'
import { print } from '../../utils'
import Manage from './Manage'
import Voided from './Voided'
import { fragments } from '../Workorders/WorkordersList'

let manageQuery = gql`
  query ManageWorkorders($from: String, $to: String, $customer_id: String!) {
    customer: customers(id: $customer_id) {
      ...ManageWorkordersCustomerDetail
    }
    wos: allWorkorders(
      filters: {from: $from, to: $to, customer_id: $customer_id}
      cursor: "-1"
      limit: 100000000
    ) {
      edges {
        edge {
          id
          sequence
          assigned_at
          completed_at
          status
          route_id
          customer_id
          notes
          notes_resolved
          invoice_id
          multiday
          multiday_estimated_total
          arrival_start_time
          arrival_end_time
          is_billable
          route {
            id
            name
          }
          workorderitems {
            ...WorkorderitemDetail
            saleitem {
              id
              name
            }
            salesperson {
              id
              first_name
              last_name
            }
          }
          invoice {
            id
            terms_id
            terms {
              id
              terms
            }
            status
            charge
          }
          schedule {
            id
            sequence
            freq_type
          }
          customer {
            id
            name
            street1
            city {
              id
              name
            }
            unresolved_tech {
              id
            }
            region
            postcode
            taxable
            taxitem {
              id
              rate
            }
          }
        }
      }
    }
    invs: allInvoices(
      filters: {from: $from, to: $to, customer_id: $customer_id}
      cursor: "-1"
      limit: 100000000
    ) {
      edges {
        edge {
          id
          customer_id
          route {
            id
            name
          }
          status
          charge
          terms_id
          terms {
            id
            terms
          }
        }
      }
    }
  }

  fragment ManageWorkordersCustomerDetail on Customers {
    id
    name
    po_needed
    taxitem {
      id
      rate
    }
    balances {
      id
      balance
    }
    next_routerun {
      id
      run_at
      route_id
    }
    last_routerun {
      id
      run_at
      route_id
    }
    terms {
      id
      terms
    }
    credit_items {
      id
      amount
    }
    open_workorders {
      id
    }
  }
  ${fragments.workorderitem}
`

let generateWorkordersMutation = gql`
  mutation GenerateWorkordersFromSchedules(
    $route_id: ID
    $date: String!
    $ids: [ID]!
  ) {
    generateWorkordersFromSchedules(
      input: {ids: $ids, route_id: $route_id, date: $date}
    ) {
      workorders {
        id
        schedule {
          id
        }
      }
    }
  }
`

let generateInvoicesMutation = gql`
  mutation GenerateInvoicesFromWorkorders($ids: [ID]!) {
    generateInvoicesFromWorkorders(input: {ids: $ids}) {
      invoices {
        id
        terms_id
      }
    }
  }
`

let batchWorkordersMutation = gql`
  mutation BatchWorkorders($input: [UpdateManyWorkordersInput]) {
    updateManyWorkorders(input: $input) {
      workorders {
        id
        status
      }
    }
  }
`

let batchInvoicesMutation = gql`
  mutation BatchInvoices($input: [UpdateManyInvoicesInput]) {
    updateManyInvoices(input: $input) {
      invoices {
        id
        status
      }
    }
  }
`

let payInvoicesMutation = gql`
  mutation PayInvoices($input: PayInvoicesWithCashInput) {
    payInvoicesWithCash(input: $input) {
      invoices {
        id
        status
      }
    }
  }
`

function ScreenToolbar({ match, loading, onNewWorkorder }) {
  let [anchorEl, setAnchorEl] = useState(null)

  return (
    <React.Fragment>
      <Toolbar>
        <ToolbarLeft style={{ justifyContent: 'flex-start' }}>
          <Route
            path={`${match.path}/:tab`}
            children={({ match: tabMatch }) => {
              let { params = {} } = tabMatch || {}
              let tabsMap = {
                manage: 1,
                voided: 2,
              }
              let activeTab = tabsMap[params.tab] || 0

              return (
                <>
                  <Button
                    onClick={e => {
                      setAnchorEl(e.currentTarget)
                    }}
                    component="nav"
                    style={{ textTransform: 'none' }}
                  >
                    {params.tab === 'manage' ? (
                      <>
                        <Assignment />
                        <Space inline />
                        <Typography variant="h6">Manage Workorders</Typography>
                      </>
                    ) : (
                      <>
                        <Cancel />
                        <Space inline />
                        <Typography variant="h6">Voided Workorders</Typography>
                      </>
                    )}
                    <Space inline />
                    <ArrowDropDown />
                  </Button>
                  <Menu
                    id="manage-workorders-nav-menu"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={() => {
                      setAnchorEl(null)
                    }}
                  >
                    <MenuItem
                      selected={params.tab === 'manage'}
                      component={Link}
                      to={`${match.url}/manage`}
                      onClick={() => setAnchorEl(null)}
                    >
                      <Assignment />
                      <Space inline />
                      Manage Workorders
                    </MenuItem>
                    <MenuItem
                      selected={params.tab === 'voided'}
                      component={Link}
                      to={`${match.url}/voided`}
                      onClick={() => setAnchorEl(null)}
                    >
                      <Cancel />
                      <Space inline />
                      Voided Workorders
                    </MenuItem>
                  </Menu>
                </>
              )
            }}
          />
        </ToolbarLeft>
        <Space inline size={3} />
        <Fab color="secondary" onClick={onNewWorkorder}>
          <Typography variant="subtitle2">+</Typography>
          <Assignment />
        </Fab>
      </Toolbar>
      {loading && <LinearProgress />}
    </React.Fragment>
  )
}

function munge(params) {
  let { from, to, route_id } = params
  from = moment(from || new Date())
    .subtract(2, 'months')
    .startOf('day')
    .toDate()
  to = moment(to || new Date())
    .add(2, 'months')
    .startOf('day')
    .toDate()

  route_id = route_id ? String(route_id) : '1'

  return { from, to, route_id }
}

let getKeysFromRow = row => {
  if (!row) return { woKey: null, inv: null }

  let { workorder, invoice, schedule } = row

  let woKey = workorder
    ? `wo-${workorder.id}`
    : !!schedule
      ? `wo-s-${schedule.id}`
      : null
  let invKey = invoice
    ? `inv-${invoice.id}`
    : !workorder && schedule
      ? `inv-s-${schedule.id}`
      : `inv-wo-${workorder.id}`

  let rowKey = ''
  if (invoice) rowKey = `r-inv-${invoice.id}`
  if (workorder) rowKey = `r-wo-${workorder.id}`

  return { woKey, invKey, rowKey }
}

let checkAllRows = (val, displayRows = []) => {
  return displayRows.reduce((carry, row) => {
    let { rowKey } = getKeysFromRow(row)
    carry[rowKey] = val
    return carry
  }, {})
}

let checkedKeys = values => {
  let checked = []
  for (let key in values) {
    if (values[key]) {
      checked.push(key)
    }
  }
  return checked
}

let filterRowDataByKeys = (rows, keys) => {
  return rows.filter(row => {
    let { rowKey } = getKeysFromRow(row)
    return keys.includes(rowKey)
  })
}

let make_workorders_filter = r => !r.workorder && !r.invoice && !!r.schedule
let open_workorders_filter = r =>
  !!r.workorder && r.workorder.status === 'COMPLETE'
let complete_workorders_filter = r =>
  !!r.workorder && r.workorder.status === 'OPEN'
let void_workorders_filter = r => !!r.workorder && r.workorder.status !== 'VOID'
let make_invoices_filter = r =>
  !!r.workorder && r.workorder.status === 'COMPLETE' && !r.invoice
let void_invoices_filter = r => !!r.invoice && r.invoice.status !== 'VOID'
let paid_invoices_filter = r => !!r.invoice && r.invoice.status === 'PAID'
let pay_invoices_filter = r => !!r.invoice && r.invoice.status === 'OPEN'
let any_invoices_filter = r => !!r.invoice

let CustomerWorkorders = ({ match, location }) => {
  let [queryChanges, setQueryChanges] = useState(
    queryString.parse(location.search),
  )
  let [wo, setWo] = useState({})
  let [edit, setEdit] = useState(false)
  let [manageMode, setManageMode] = useState(false)
  let [checks, setChecks] = useState({})
  let [lastCheck, setLastCheck] = useState(undefined)

  let params = queryString.parse(location.search)
  let { from, to, route_id } = munge(params)

  let { data, isLoading, refetch } = useQuery(
    [
      'manage-workorder',
      moment.utc(from).format('YYYY-MM-DD'),
      moment.utc(to).format('YYYY-MM-DD'),
      route_id,
    ],
    () => {
      return prgql({
        query: manageQuery,
        variables: {
          from: moment.utc(from).format('YYYY-MM-DD'),
          to: moment.utc(to).format('YYYY-MM-DD'),
          customer_id: match.params.id,
        },
      })
    },
  )
  const queryClient = useQueryClient()

  let { mutateAsync: genWorkorders, isLoading: genWoIsLoading } = useMutation(
    variables =>
      prgql({
        query: generateWorkordersMutation,
        variables: variables,
      }),
    {
      mutationKey: 'genWo',
      onSuccess: () => queryClient.invalidateQueries('manage-workorder'),
    },
  )

  let { mutateAsync: genInvoices, isLoading: genInvIsLoading } = useMutation(
    variables =>
      prgql({
        query: generateInvoicesMutation,
        variables: variables,
      }),
    {
      mutationKey: 'genInv',
      onSuccess: () => queryClient.invalidateQueries('manage-workorder'),
    },
  )

  let {
    mutateAsync: batchWorkorders,
    isLoading: batchWorkordersLoading,
  } = useMutation(
    variables =>
      prgql({
        query: batchWorkordersMutation,
        variables: variables,
      }),
    {
      mutationKey: 'batchWo',
      onSuccess: () => queryClient.invalidateQueries('manage-workorder'),
    },
  )

  let {
    mutateAsync: batchInvoices,
    isLoading: batchInvoicesLoading,
  } = useMutation(
    variables =>
      prgql({
        query: batchInvoicesMutation,
        variables: variables,
      }),
    {
      mutationKey: 'batchInv',
      onSuccess: () => queryClient.invalidateQueries('manage-workorder'),
    },
  )

  let { mutateAsync: payInvoices, isLoading: payInvoicesLoading } = useMutation(
    variables =>
      prgql({
        query: payInvoicesMutation,
        variables: variables,
      }),
    {
      mutationKey: 'payInv',
      onSuccess: () => queryClient.invalidateQueries('manage-workorder'),
    },
  )

  let _makeWorkordersAction = async () => {
    let schedule_ids = filterRowDataByKeys(rows, checkedKeys(checks))
      .filter(make_workorders_filter)
      .map(({ schedule }) => schedule.id)

    let data = await genWorkorders({
      date: moment.utc(from).format('YYYY-MM-DD'),
      route_id: route_id,
      ids: schedule_ids,
    })

    await refetch()
  }

  let _completeWorkordersAction = async () => {
    let workorders = filterRowDataByKeys(rows, checkedKeys(checks))
      .filter(complete_workorders_filter)
      .map(({ workorder }) => ({
        id: workorder.id,
        status: 'COMPLETE',
        completed_at: moment.utc(workorder.assigned_at).format('YYYY-MM-DD'),
      }))

    await batchWorkorders({
      input: workorders,
    })
    await refetch()
  }

  let _openWorkordersAction = async () => {
    let workorders = filterRowDataByKeys(rows, checkedKeys(checks))
      .filter(open_workorders_filter)
      .map(({ workorder }) => ({ id: workorder.id, status: 'OPEN' }))

    await batchWorkorders({
      input: workorders,
    })
    await refetch()
  }

  let _voidWorkordersAction = async () => {
    let workorders = filterRowDataByKeys(rows, checkedKeys(checks))
      .filter(void_workorders_filter)
      .map(({ workorder }) => ({ id: workorder.id, status: 'VOID' }))

    await batchWorkorders({
      input: workorders,
    })
    await refetch()
  }

  let _makeInvoicesAction = async () => {
    let workorderIds = filterRowDataByKeys(rows, checkedKeys(checks))
      .filter(make_invoices_filter)
      .map(({ workorder }) => workorder.id)

    await genInvoices({
      ids: workorderIds,
    })

    await refetch()
  }

  let _voidInvoicesAction = async () => {
    let invoices = filterRowDataByKeys(rows, checkedKeys(checks))
      .filter(void_invoices_filter)
      .map(({ invoice }) => ({ id: invoice.id, status: 'VOID' }))

    await batchInvoices({
      input: invoices,
    })
    await refetch()
  }

  let _payInvoicesWithCashAction = async () => {
    let invoiceIds = filterRowDataByKeys(rows, checkedKeys(checks))
      .filter(pay_invoices_filter)
      .map(({ invoice }) => invoice.id)

    await payInvoices({
      input: {
        ids: invoiceIds,
      },
    })
    await refetch()
  }

  let _restoreAction = async values => {
    let openValidWorkorders =
      data && data.wos
        ? data.wos.edges
          .map(e => e.edge)
          .filter(w => w.status === 'VOID')
          .map(w => w.id)
        : []
    let inactiveValidInvoices =
      data && data.invs
        ? data.invs.edges
          .map(e => e.edge)
          .filter(w => w.status === 'VOID')
          .map(w => w.id)
        : []

    let workorders = values
      .filter(v => /^wo-[0-9]+$/.test(v))
      .map(v => v.replace('wo-', ''))
      .filter(v => openValidWorkorders.includes(v))
      .map(v => ({ id: v, status: 'OPEN' }))
    let invoices = values
      .filter(v => /^inv-[0-9]+$/.test(v))
      .map(v => v.replace('inv-', ''))
      .filter(v => inactiveValidInvoices.includes(v))
      .map(v => ({ id: v, status: 'OPEN' }))

    await batchWorkorders({
      input: workorders,
    })
    await batchInvoices({
      input: invoices,
    })
    await refetch()
  }

  let workorders =
    data && data.wos
      ? data.wos.edges.map(e => e.edge).filter(w => w.status !== 'VOID')
      : []
  let invoices =
    data && data.invs
      ? data.invs.edges.map(e => e.edge).filter(w => w.status !== 'VOID')
      : []
  let generatedInvoiceIds = workorders.map(wo => wo.invoice_id)
  let ungeneratedInvoices = invoices.filter(
    i => !generatedInvoiceIds.includes(i.id),
  )

  let rows = []

  workorders.map(wo =>
    rows.push({
      workorder: wo,
      invoice: wo.invoice && wo.invoice.status !== 'VOID' ? wo.invoice : null,
      schedule: wo.schedule,
      customer: data.customer,
      sequence: wo.sequence,
    }),
  )
  ungeneratedInvoices.map(i =>
    rows.push({
      workorder: null,
      invoice: i,
      schedule: null,
      sequence: 10000,
      customer: data.customer,
    }),
  )
  rows = rows.map(row => {
    let { rowKey } = getKeysFromRow(row)
    row['rowKey'] = rowKey
    return row
  })

  let allRowsChecked = true
  for (let k in checkAllRows(true, rows)) {
    if (!checks[k]) {
      allRowsChecked = false
      break
    }
  }
  let someRowsChecked = false
  for (let k in checks) {
    if (checks[k]) {
      someRowsChecked = true
      break
    }
  }

  let _availableActions = rows => {
    if (!rows || !rows.length) return {}

    let config = {}

    config['make-workorders'] = !!rows.filter(make_workorders_filter).length
    config['open-workorders'] = !!rows.filter(open_workorders_filter).length
    config['complete-workorders'] = !!rows.filter(complete_workorders_filter)
      .length
    config['void-workorders'] =
      !!rows.filter(void_workorders_filter).length &&
      !rows.filter(any_invoices_filter).length

    config['make-invoices'] = !!rows.filter(make_invoices_filter).length
    config['void-invoices'] =
      !!rows.filter(void_invoices_filter).length &&
      !rows.filter(paid_invoices_filter).length
    config['pay-invoices'] = !!rows.filter(pay_invoices_filter).length

    return config
  }

  return (
    <React.Fragment>
      <ScreenToolbar
        match={match}
        loading={isLoading || genInvIsLoading || genWoIsLoading}
        onNewWorkorder={() => {
          setWo({
            status: 'OPEN',
            assigned_at: moment().format('YYYY-MM-DD'),
            customer_id: match.params.id,
          })
          setEdit(true)
        }}
        manageMode={manageMode}
        onManageMode={e => setManageMode(e.target.checked)}
      />
      <Switch>
        <Route
          path={`${match.path}/manage`}
          exact
          render={() => (
            <Manage
              customer={data && data.customer}
              rows={rows}
              fromDate={from}
              toDate={to}
              onChangeFromDate={date => {
                setQueryChanges({
                  ...queryChanges,
                  from: moment(date)
                    .utc()
                    .startOf('day')
                    .format('YYYY-MM-DD'),
                })
                setChecks({})
              }}
              onChangeToDate={date => {
                setQueryChanges({
                  ...queryChanges,
                  to: moment(date)
                    .utc()
                    .startOf('day')
                    .format('YYYY-MM-DD'),
                })
                setChecks({})
              }}
              onClickWo={wo => {
                setWo(wo)
                setEdit(true)
              }}
              // onMakeWorkordersAction={_makeWorkordersAction}
              onCompleteWorkordersAction={_completeWorkordersAction}
              onOpenWorkordersAction={_openWorkordersAction}
              onVoidWorkordersAction={_voidWorkordersAction}
              onMakeInvoicesAction={_makeInvoicesAction}
              onVoidInvoicesAction={_voidInvoicesAction}
              onPayInvoicesWithCashAction={_payInvoicesWithCashAction}
              actionsLoading={
                genInvIsLoading ||
                genWoIsLoading ||
                batchInvoicesLoading ||
                batchWorkordersLoading ||
                payInvoicesLoading
              }
              onCheckAll={e =>
                someRowsChecked && !allRowsChecked
                  ? setChecks({})
                  : setChecks({
                    ...checks,
                    ...checkAllRows(e.target.checked, rows),
                  })
              }
              allRowsChecked={allRowsChecked}
              someRowsChecked={someRowsChecked}
              checks={checks}
              onCheck={({ e, rowKey, sortedRowKeys }) => {
                let setCheckKeys = {
                  ...checks,
                  [rowKey]: e.target.checked,
                }

                if (e.shiftKey && !!lastCheck) {
                  let startIndex = Math.min(
                    sortedRowKeys.indexOf(lastCheck),
                    sortedRowKeys.indexOf(rowKey),
                  )
                  let endIndex = Math.max(
                    sortedRowKeys.indexOf(lastCheck),
                    sortedRowKeys.indexOf(rowKey),
                  )
                  let newChecks = sortedRowKeys
                    .slice(startIndex, endIndex)
                    .reduce((carry, k) => {
                      carry[k] = e.target.checked
                      return carry
                    }, {})
                  setCheckKeys = {
                    ...setCheckKeys,
                    ...newChecks,
                    [lastCheck]: e.target.checked,
                  }
                }

                setChecks(setCheckKeys)

                setLastCheck(rowKey)
              }}
              availableActions={_availableActions(
                filterRowDataByKeys(rows, checkedKeys(checks)),
              )}
            />
          )}
        />
        <Route
          path={`${match.path}/voided`}
          exact
          render={() => (
            <Voided
              data={data}
              date={from}
              customer={data && data.customer}
              onChangeDate={date =>
                setQueryChanges({
                  ...queryChanges,
                  date: moment(date)
                    .utc()
                    .startOf('day')
                    .format('YYYY-MM-DD'),
                })
              }
              routeId={route_id}
              onChangeRoute={route =>
                setQueryChanges({
                  ...queryChanges,
                  route_id: route.id,
                })
              }
              onRestoreAction={_restoreAction}
              disableActions={
                genInvIsLoading ||
                genWoIsLoading ||
                batchInvoicesLoading ||
                batchWorkordersLoading
              }
              fromDate={from}
              toDate={to}
              onChangeFromDate={date => {
                setQueryChanges({
                  ...queryChanges,
                  from: moment(date)
                    .utc()
                    .startOf('day')
                    .format('YYYY-MM-DD'),
                })
                setChecks({})
              }}
              onChangeToDate={date => {
                setQueryChanges({
                  ...queryChanges,
                  to: moment(date)
                    .utc()
                    .startOf('day')
                    .format('YYYY-MM-DD'),
                })
                setChecks({})
              }}
            />
          )}
        />
      </Switch>
      <ModifyQueryParams query={queryChanges} />
      <EditWorkorder
        key={wo.id}
        wo={wo}
        open={edit}
        onSave={refetch}
        onClose={() => setEdit(false)}
        showMultidayVersion={!!wo.multiday}
        showStatusChange
        initialTab={wo.id ? 'preview' : 'edit'}
      />
    </React.Fragment>
  )
}

export default CustomerWorkorders
