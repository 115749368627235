import React, {useState, useRef, useEffect} from 'react'
import NavigationArrowBack from '@mui/icons-material/ArrowBack'
import styled from 'styled-components'
import gql from 'graphql-tag'
import url from 'url'

import Typography from '@mui/material/Typography'
import {
  Box,
  Chip,
  CircularProgress,
  Grid,
  IconButton,
  Paper,
} from '@mui/material'
import {
  Chat,
  Contacts,
  Description,
  Edit,
  Email,
  Handshake,
  Home,
  Phone,
} from '@mui/icons-material'
import theme, {PRThemeProvider} from '../../styles/theme'
import {useMutation, useQuery, useQueryClient} from 'react-query'
import {prgql} from '../../utils/graphql'
import {Toolbar} from '../../AppHandler'
import ToolbarGroup from '../../components/ToolbarGroup'
import {Link, Route, Switch} from '../../pr-router'
import {NavigateBack, RedirectBack} from '../../components/Navigator'
import ToolbarMegaTitle from '../../components/ToolbarMegaTitle'
import DeleteDialog, {TrashDialog} from '../../components/DeleteDialog'
import {Space} from '../../components/Layout'
import {EditDetails} from './EditDetails'
import customers from '../../schema/customers'
import {Caption} from '../../components/Typography'
import {formatPhone} from '../../utils'
import {
  DeleteMenuItem,
  ToolbarIconMenu,
  TrashMenuItem,
} from '../../components/Toolbar'
import DetailHeader from '../../components/DetailHeader'

const sheet = {
  customerDetail: {minWidth: '920px', margin: '20px 120px'},
  toolbarStyle: {},
  toolbarGroup: {},
  toolbarTitle: {color: theme.palette.textInv1Color},
  toolbarIcon: {fill: theme.palette.textInv1Color},
  toolbarTab: {},
  detailsWrapper: {position: 'fixed', top: 0, right: 0, bottom: 0, left: 0},
  container: {},
  photoColumn: {flex: 'none', width: '33%', marginRight: '15px'},
  rowIcon: {color: theme.iconGrey, marginRight: '5px'},
  photoIcon: {
    color: 'white',
    backgroundColor: theme.iconGrey,
    padding: '9px',
    borderRadius: '100%',
    fontSize: '179px',
  },
  image: {width: '100%'},
  imageCell: {maxWidth: '33.33333333%', flex: '1 1 99px', paddingRight: '4px'},
  thumbRow: {display: 'flex', flexWrap: 'wrap', marginRight: '-4px'},
  imageSecondary: {maxWidth: '100%', cursor: 'pointer'},
  label: {fontWeight: 'bold'},
  cellContainer: {display: 'flex', alignItems: 'center', padding: '5px 20px'},
  photoWrapper: {marginRight: '20px'},
}

export const fragments = {
  contact: gql`
    fragment ContactDetail on Contacts {
      id
      name
      prefix
      first_name
      middle_name
      last_name
      suffix
      street1
      postcode
      city_id
      city {
        id
        name
      }
      region
      notes
      phones {
        id
        number
        label
        phonetype
      }
      emails {
        id
        email
        label
      }
      customers {
        __typename
        id
        name
      }
      accounts {
        __typename
        id
        name
      }
      salesstages {
        id
        name
      }
      sales_person_id
      sales_person {
        id
        first_name
        last_name
      }
      created_user {
        id
        first_name
        last_name
      }
      created_at
      updated_at
    }
  `,
}

export const contactQuery = gql`
  query ContactQuery($id: String!) {
    contacts(id: $id) {
      ...ContactDetail
    }
  }
  ${fragments.contact}
`

const deleteQuery = gql`
  mutation deleteContacts($id: String!) {
    deleteContacts(input: {id: $id}) {
      message
    }
  }
`

const CustomerTypeTag = styled(Typography)`
  background-color: ${({theme}) => theme.grey600};
  padding: 4px;
  border-radius: 4px;
  margin-left: 8px;
  opacity: 0.9;
`
function DeleteContact({id, showDelete, onCancel, onDelete}) {
  const {mutateAsync: deleteMut} = useMutation(() =>
    prgql({query: deleteQuery, variables: {id}}),
  )
  return (
    <TrashDialog
      title="Trash Contact?"
      message="Are you sure you want to trash this Contact? (you can restore from trash later)"
      isOpen={showDelete}
      onCancel={onCancel}
      onConfirm={() => {
        deleteMut().then(onDelete)
      }}
    />
  )
}

function ScreenToolbar({match}) {
  const [showDelete, setShowDelete] = useState(false)
  const [goBack, setGoBack] = useState(false)
  const [showEdit, setShowEdit] = useState(false)
  const {data, isLoading: loading, isError: error} = useQuery(
    ['contact', match.params.id],
    async () =>
      await prgql({query: contactQuery, variables: {id: match.params.id}}),
  )

  const queryClient = useQueryClient()

  const contactId = match.params.id

  const requiresBillingInfo =
    data && data.customers && !!data.customers.customers.length

  return (
    <>
      <Toolbar style={sheet.toolbarStyle}>
        <ToolbarGroup first={true}>
          <Route
            path={`${match.path}?`}
            exact
            render={() => (
              <NavigateBack
                defaultBack={url.resolve(match.url, '.')}
                marker="ContactBack"
              >
                <IconButton size="large">
                  <NavigationArrowBack />
                </IconButton>
              </NavigateBack>
            )}
          />
        </ToolbarGroup>
        <ToolbarGroup>
          <ToolbarMegaTitle>
            {loading && !data ? (
              'Loading'
            ) : error ? (
              'Oops. Something went wrong.'
            ) : !data.contacts ? (
              'Contact not found'
            ) : (
              <>
                {data.contacts.name}
                <CustomerTypeTag display="inline">Contact</CustomerTypeTag>
              </>
            )}
          </ToolbarMegaTitle>
        </ToolbarGroup>
        <ToolbarGroup last={true}>
          {!loading && !error && (
            <IconButton
              // href={`/old/database/editaccount.php?AccountID=${customerId}`}
              onClick={() => setShowEdit(true)}
              size="large"
            >
              <Edit />
            </IconButton>
          )}
          <ToolbarIconMenu>
            <TrashMenuItem
              onClick={() => {
                setShowDelete(true)
              }}
            />
          </ToolbarIconMenu>
          <DeleteContact
            id={contactId}
            showDelete={showDelete}
            onCancel={() => {
              setShowDelete(false)
            }}
            onDelete={setGoBack}
          />
          {goBack && (
            <RedirectBack
              defaultBack={url.resolve(match.url, '.')}
              marker="ContactBack"
            />
          )}
        </ToolbarGroup>
      </Toolbar>
      {/* <Toolbar> */}
      {/* <ToolbarGroup style={{flexGrow: 1}}> */}
      {/* <Route */}
      {/*   path={`${match.path}/:tab?`} */}
      {/*   children={({match: tabsMatch}) => ( */}
      {/*     <CustomerTabs */}
      {/*       baseUrl={match.url} */}
      {/*       selectedTab={tabsMatch ? tabsMatch.params.tab : ''} */}
      {/*       customerId={contactId} */}
      {/*     /> */}
      {/*   )} */}
      {/* /> */}
      {/* </ToolbarGroup> */}
      {/* </Toolbar> */}
      <EditDetails
        key={contactId}
        contactId={contactId}
        open={showEdit}
        onSubmit={() => {
          setShowEdit(false)
          queryClient.refetchQueries(['contact'])
        }}
        onCancel={() => setShowEdit(false)}
        data={data?.contacts}
      />
    </>
  )
}

const Content = styled(Grid)`
  padding: 25px 50px;
`
const InfoRow = styled.div`
  display: flex;
  align-items: top;
  margin-bottom: 16px;
`
const InfoColumn = styled.div`
  flex: 1 1 auto;
`
const RowIcon = styled.div`
  color: ${({theme}) => theme.muiTheme.palette.action.active};
  margin-right: 16px;
`
const ClickRowIcon = styled.div`
  color: ${({theme}) => theme.palette.secondary.main};
  margin-right: 16px;
  &:hover {
    color: ${({theme}) => theme.palette.secondary.light};
  }
`
const LargeIcon = styled.div`
  color: ${({theme}) => theme.muiTheme.palette.action.disabled};
  width: 66%;
  height: 66%;
`
const DetailsWrapper = styled(Paper)`
  padding: 16px;
`
function InlineLabel({label, children}) {
  return (
    <div style={{paddingRight: '20px'}}>
      <Caption style={{width: '45px', marginRight: '8px'}}>{label}</Caption>
      {children}
    </div>
  )
}
const DescriptionIcon = styled(RowIcon).attrs(() => ({as: Description}))``
const HomeIcon = styled(RowIcon).attrs(() => ({as: Home}))``
const PhoneIcon = styled(RowIcon).attrs(() => ({as: Phone}))``
const EmailIcon = styled(RowIcon).attrs(() => ({as: Email}))``
const ChatIcon = styled(RowIcon).attrs(() => ({as: Chat}))``
const ContactIcon = styled(RowIcon).attrs(() => ({as: Contacts}))``

export function ContactDetail({data: c}) {
  return (
    <div style={Object.assign({}, sheet.container)}>
      <Content container direction="row">
        <Space size={2} inline />
        <Grid item xs container direction={'column'}>
          <Grid item xs={true}>
            <DetailsWrapper>
              <div style={{display: 'flex'}}>
                <div style={{marginRight: '20px', flex: 1}}>
                  <InfoRow>
                    <HomeIcon />
                    <InfoColumn>
                      <a
                        href={`https://maps.google.com/maps?q=${
                          c.street1
                        }+${c.city && c.city.name}+${c.region}+${c.postcode}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Typography variant="body2">{c.street1}</Typography>
                        <Typography variant="body2">
                          {c.city?.name}, {c.region} {c.postcode}
                        </Typography>
                        <Typography variant="body2">
                          {c.city && c.city.county}
                        </Typography>
                      </a>
                    </InfoColumn>
                  </InfoRow>
                  <InfoRow id="phoneInfo">
                    <PhoneIcon />
                    <InfoColumn>
                      {c.phones?.map(p => (
                        <InlineLabel label={p.label} key={p.id}>
                          <Typography variant="body2" display="inline">
                            <a href={'tel:' + p.number.replace(/\D/g, '')}>
                              {formatPhone(p.number)}
                            </a>
                          </Typography>
                        </InlineLabel>
                      ))}
                    </InfoColumn>
                  </InfoRow>
                  <InfoRow id="emailInfo">
                    <EmailIcon />
                    <InfoColumn as="ul" style={{listStyle: 'none'}}>
                      {(c.emails || []).map(email => (
                        <li key={email.id}>
                          <Typography
                            variant="body1"
                            style={{
                              fontWeight: email.default_email
                                ? 'bold'
                                : undefined,
                            }}
                          >
                            {email.label ? email.label + ': ' : ''}
                          </Typography>
                          <a href={'mailto:' + email.email}>
                            <Typography variant="body1">
                              {email.email}
                            </Typography>
                          </a>
                        </li>
                      ))}
                    </InfoColumn>
                  </InfoRow>
                  {c.salesstages?.length ? (
                    <InfoRow>
                      <Handshake color="action" />
                      <InfoColumn>
                        {c.salesstages.map(s => (
                          <Chip
                            key={s.id}
                            style={{marginLeft: '.5em'}}
                            label={
                              <Box display={'flex'} alignItems={'center'}>
                                <Space inline />
                                {s.name}
                              </Box>
                            }
                          />
                        ))}
                      </InfoColumn>
                    </InfoRow>
                  ) : (
                    <></>
                  )}
                </div>
                <div style={{marginRight: '20px', flex: 1}}>
                  <InfoRow>
                    <ChatIcon />
                    <Typography variant="body1">
                      Created by {c.created_user?.first_name}{' '}
                      {c.created_user?.last_name}
                      {c.sales_person &&
                        `; Sold by ${c.sales_person.first_name} ${c.sales_person.last_name}`}
                    </Typography>
                  </InfoRow>
                  <InfoRow>
                    <DescriptionIcon />
                    <InfoColumn>
                      <Typography as={'pre'}>{c.notes}</Typography>
                    </InfoColumn>
                  </InfoRow>
                </div>
              </div>
            </DetailsWrapper>
          </Grid>
          <Space size={2} inline={false} />
          <Grid item xs container direction={'row'} spacing={1}>
            <Grid item xs={6}>
              <DetailsWrapper>
                <DetailHeader>Customers</DetailHeader>
                {c.customers?.map((customer, index) => (
                  <div key={index}>
                    <Link to={'/customers/' + customer.id}>
                      <Typography variant="h6">{customer.name}</Typography>
                    </Link>
                  </div>
                ))}
              </DetailsWrapper>
            </Grid>
            <Grid item xs={6}>
              <DetailsWrapper>
                <DetailHeader>Accounts</DetailHeader>
                {c.accounts?.map((account, index) => (
                  <div key={index}>
                    <Link to={'/accounts/' + account.id}>
                      <Typography variant="h6">{account.name}</Typography>
                    </Link>
                  </div>
                ))}
              </DetailsWrapper>
            </Grid>
          </Grid>
        </Grid>
      </Content>
    </div>
  )
}

export default function Contact({match, location}) {
  const {id} = match.params

  const {data, isLoading: loading, isError: error, refetch} = useQuery(
    ['contact', id],
    () => prgql({query: contactQuery, variables: {id}}),
  )
  // let mediaMatches = useMediaQuery('(min-width:1680px)')

  return (
    <>
      <ScreenToolbar match={match} location={location} />
      {loading && !data ? (
        <Grid container spacing={2}>
          <CircularProgress />
        </Grid>
      ) : error || !data.contacts ? (
        <Typography>Something broke, please refresh</Typography>
      ) : (
        <ContactDetail data={data.contacts} />
      )}
    </>
  )
}
