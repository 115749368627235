import React, { useState, useEffect } from 'react'
import gql from 'graphql-tag'
import { Column } from 'react-virtualized'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import moment from 'moment'
import queryString from 'query-string'

import { DatePicker } from '@mui/lab'
import Button from '@mui/material/Button'
import Input from '@mui/material/Input'
import InputAdornment from '@mui/material/InputAdornment'
import SelectMenu from '../../components/ToolbarDropDownMenu'
import MenuItem from '@mui/material/MenuItem'

import CalendarIcon from '@mui/icons-material/CalendarToday'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TableHead,
  TableCell,
  Table,
  TableBody,
  TableRow,
  FormControlLabel,
  Checkbox,
  Typography,
  TextField,
} from '@mui/material'

import TableList from '../../components/Table/TableList'
import { Body1, Subhead } from '../../components/Typography'
import AvatarBorder from '../../components/AvatarBorder'
import { usePagination } from '../../components/PaginationContainer'
import { Toolbar } from '../../AppHandler'
import ToolbarGroup from '../../components/ToolbarGroup'
import ToolbarMegaTitle from '../../components/ToolbarMegaTitle'
import ModifyQueryParams from '../../components/ModifyQueryParams'
import { Space } from '../../components/Layout'
import { formatMoneyStandard } from '../../utils/moneyFormatter'
import { useMutation } from 'react-apollo'
import { ArrowForward } from '@mui/icons-material'

function DateInput({ onClick, value }) {
  return (
    <Input
      onClick={onClick}
      value={value}
      startAdornment={
        <InputAdornment position="start">
          <CalendarIcon />
        </InputAdornment>
      }
    />
  )
}

let usersQuery = gql`
  query CommissionUsers {
    allUsers(filters: {commission: true, status: active}) {
      edges {
        edge {
          id
          first_name
          last_name
        }
      }
    }
  }
`

function ScreenToolbar({ to, from, includeOpen, onChange = () => { }, userId }) {
  let { data, loading, loadmore, refetch } = usePagination({
    query: usersQuery,
    variables: {
      cursor: '-1',
    },
  })

  return (
    <Toolbar>
      <ToolbarGroup first>
        <ToolbarMegaTitle>Sales Commissions</ToolbarMegaTitle>
        <Space inline size={2} />
        <SelectMenu
          value={userId}
          onChange={e => onChange({ userId: e.target.value })}
        >
          <MenuItem value="all">All Employees</MenuItem>
          {!loading &&
            data.allUsers.edges.map(i => (
              <MenuItem value={i.edge.id}>
                {i.edge.first_name} {i.edge.last_name}
              </MenuItem>
            ))}
        </SelectMenu>
        <Space inline size={2} />
        <DatePicker
          variant="filled"
          label="As of"
          value={moment.utc(from)}
          inputFormat="MMMM D, YYYY"
          onChange={d => onChange({ from: d.format('YYYY-MM-DD') })}
          renderInput={props => (
            <TextField {...props} margin="dense" size="small" />
          )}
          autoOk
        />
        <Space inline size={2} />
        <ArrowForward />
        <Space inline size={2} />
        <DatePicker
          variant="filled"
          label="As of"
          value={moment.utc(to)}
          inputFormat="MMMM D, YYYY"
          onChange={d => onChange({ to: d.format('YYYY-MM-DD') })}
          renderInput={props => (
            <TextField {...props} margin="dense" size="small" />
          )}
          autoOk
        />
        <Space inline size={2} />
        <FormControlLabel
          label="Include Open Invoices"
          control={
            <Checkbox
              color="secondary"
              checked={includeOpen === 'true' ? true : false}
              onChange={e =>
                onChange({ includeOpen: e.target.checked ? 'true' : 'false' })
              }
            />
          }
        />
      </ToolbarGroup>
      <ToolbarGroup></ToolbarGroup>
    </Toolbar>
  )
}

let DescWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0 16px;
  & > * {
    margin-right: 8px;
  }
`

let getInitials = ({ first, last }) =>
  [...first.slice(0, 1), ...last.slice(0, 1)].join('').toUpperCase()

let DescCol = ({ cellData, rowData, onClick }) => (
  <DescWrapper onClick={() => onClick(rowData)}>
    <AvatarBorder>
      {getInitials({ first: cellData, last: rowData.last_name })}
    </AvatarBorder>
    <Subhead display="block">
      {cellData ? (
        `${cellData} ${rowData.last_name}`
      ) : (
        <Typography style={{ fontStyle: 'italic' }}>No Sales Person</Typography>
      )}
    </Subhead>
  </DescWrapper>
)

function CommissionList({
  items = [],
  onInfiniteLoad,
  pageInfo,
  onDisplayInvoices,
}) {
  function getRowCount() {
    if (!pageInfo) {
      return 9999
    }
    return pageInfo.total
  }
  if (!items) {
    items = []
  }

  let totalSales = items.reduce((acc, e) => acc + e.edge.total_sales, 0)
  let scheduledSales = items.reduce((acc, e) => acc + e.edge.scheduled_sales, 0)
  let scheduledJobs = items.reduce((acc, e) => acc + e.edge.scheduled_jobs, 0)
  let totalComm = items.reduce((acc, e) => acc + e.edge.commission, 0)
  let totalPaid = items.reduce((acc, e) => acc + e.edge.paid_commission, 0)
  let totalUnpaid = items.reduce((acc, e) => acc + e.edge.unpaid_commission, 0)

  return (
    <TableList
      data={items}
      infinite
      loadMoreRows={onInfiniteLoad}
      rowCount={getRowCount()}
      wrapRow={({ children, rowData }) => <>{children}</>}
    >
      <Column
        dataKey="first_name"
        headerRenderer={({ label }) => (
          <div style={{ textAlign: 'center' }}>{label}</div>
        )}
        cellRenderer={data => <DescCol {...data} onClick={onDisplayInvoices} />}
        label="Name"
        flexGrow={1}
        width={0}
      />
      <Column
        dataKey="scheduled_sales"
        headerRenderer={({ label }) => (
          <div style={{ textAlign: 'right' }}>{label}</div>
        )}
        cellRenderer={({ cellData, rowData = {} }) => (
          <Body1 display="block" style={{ textAlign: 'right' }}>
            {formatMoneyStandard(cellData)}
          </Body1>
        )}
        label={'Scheduled Sales - ' + formatMoneyStandard(scheduledSales)}
        flexGrow={1}
        width={1}
        maxWidth={250}
      />
      <Column
        dataKey="scheduled_jobs"
        headerRenderer={({ label }) => (
          <div style={{ textAlign: 'right' }}>{label}</div>
        )}
        cellRenderer={({ cellData, rowData = {} }) => (
          <Body1 display="block" style={{ textAlign: 'right' }}>
            {cellData}
          </Body1>
        )}
        label={'Scheduled Sales - ' + scheduledJobs}
        flexGrow={1}
        width={1}
        maxWidth={250}
      />
      <Column
        dataKey="total_sales"
        headerRenderer={({ label }) => (
          <div style={{ textAlign: 'right' }}>{label}</div>
        )}
        cellRenderer={({ cellData, rowData = {} }) => (
          <Body1 display="block" style={{ textAlign: 'right' }}>
            {formatMoneyStandard(cellData)}
          </Body1>
        )}
        label={'Invoiced Sales - ' + formatMoneyStandard(totalSales)}
        flexGrow={1}
        width={1}
        maxWidth={250}
      />
      <Column
        dataKey="commission"
        headerRenderer={({ label }) => (
          <div style={{ textAlign: 'right' }}>{label}</div>
        )}
        cellRenderer={({ cellData, rowData = {} }) => (
          <Body1 display="block" style={{ textAlign: 'right' }}>
            {formatMoneyStandard(cellData)}
          </Body1>
        )}
        label={'Commission - ' + formatMoneyStandard(totalComm)}
        flexGrow={1}
        width={1}
        maxWidth={250}
      />
      <Column
        dataKey="paid_commission"
        headerRenderer={({ label }) => (
          <div style={{ textAlign: 'right' }}>{label}</div>
        )}
        cellRenderer={({ cellData, rowData = {} }) => (
          <Body1 display="block" style={{ textAlign: 'right' }}>
            {formatMoneyStandard(cellData)}
          </Body1>
        )}
        label={'Paid Commission - ' + formatMoneyStandard(totalPaid)}
        flexGrow={1}
        width={1}
        maxWidth={250}
      />
      <Column
        dataKey="unpaid_commission"
        headerRenderer={({ label }) => (
          <div style={{ textAlign: 'right' }}>{label}</div>
        )}
        cellRenderer={({ cellData, rowData = {} }) => (
          <Body1 display="block" style={{ textAlign: 'right' }}>
            {formatMoneyStandard(cellData)}
          </Body1>
        )}
        label={'Unpaid Commission - ' + formatMoneyStandard(totalUnpaid)}
        flexGrow={1}
        width={1}
        maxWidth={250}
      />
    </TableList>
  )
}
CommissionList.fragments = {
  commissionByUserListDetail: gql`
    fragment CommissionByUserListDetail on CommissionByUsers {
      id
      first_name
      last_name
      unpaid_commission
      paid_commission
      commission
      total_sales
      scheduled_sales
      scheduled_jobs
      invoiceitems {
        id
        invoice_id
        invoice_status
        charge
        commission
        gpm_percent
        commission_percent
        commission_status
        commission_complete_at
      }
    }
  `,
}

let commissionQuery = gql`
  query CommissionByUsers(
    $cursor: String
    $limit: Int = 50
    $to: String
    $from: String
    $includeOpen: Boolean = false
    $userId: Int
  ) {
    commissionByUser: allCommissionByUsers(
      cursor: $cursor
      limit: $limit
      filters: {
        to: $to
        from: $from
        includeOpen: $includeOpen
        userId: $userId
      }
    ) {
      edges {
        edge {
          ...CommissionByUserListDetail
        }
        cursor
      }
      pageInfo {
        next
        current
        prev
        count
        total
      }
    }
  }
  ${CommissionList.fragments.commissionByUserListDetail}
`

function StatisticsCommissions({ location, match }) {
  let [dialogData, setDialogData] = useState(null)

  let handleDisplayInvoices = data => setDialogData(data)

  let [queryChanges, setQueryChanges] = useState({})
  let {
    to = moment.utc().format('YYYY-MM-DD'),
    from = moment
      .utc()
      .subtract(1, 'month')
      .format('YYYY-MM-DD'),
    includeOpen = 'false',
    userId = 'all',
  } = queryString.parse(location.search)

  let { data, loading, loadMore, refetch } = usePagination({
    query: commissionQuery,
    variables: {
      to,
      from,
      includeOpen: includeOpen === 'true' ? true : false,
      userId: userId === 'all' ? undefined : Number.parseInt(userId),
      cursor: '-1',
    },
  })

  useEffect(() => {
    refetch()
  }, [to, from, includeOpen, userId])

  return (
    <React.Fragment>
      <ScreenToolbar
        to={to}
        from={from}
        includeOpen={includeOpen}
        onChange={changes => setQueryChanges(c => ({ ...c, ...changes }))}
        userId={userId}
      />
      <ModifyQueryParams query={queryChanges} />
      <CommissionList
        items={data && data.commissionByUser && data.commissionByUser.edges}
        pageInfo={
          data && data.commissionByUser && data.commissionByUser.pageInfo
        }
        onInfiniteLoad={loadMore}
        onDisplayInvoices={handleDisplayInvoices}
      />

      {!!dialogData && (
        <Dialog open={!!dialogData} maxWidth="lg" fullWidth>
          <DialogTitle>
            {dialogData.first_name} {dialogData.last_name} Commissions
          </DialogTitle>
          <DialogContent>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell>Invoice Status</TableCell>
                  <TableCell align="right">Charge</TableCell>
                  <TableCell align="right">GPM %</TableCell>
                  <TableCell align="right">Commission %</TableCell>
                  <TableCell align="right">Commission</TableCell>
                  <TableCell>Commission Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dialogData.invoiceitems.map(i => (
                  <TableRow key={i.id}>
                    <TableCell>
                      <a
                        href={`/old/database/invoice.php?InvoiceNumber=${i.invoice_id}`}
                      >
                        {i.invoice_id}
                      </a>
                    </TableCell>
                    <TableCell>{i.invoice_status}</TableCell>
                    <TableCell align="right">
                      {formatMoneyStandard(i.charge)}
                    </TableCell>
                    <TableCell align="right">{i.gpm_percent}%</TableCell>
                    <TableCell align="right">{i.commission_percent}%</TableCell>
                    <TableCell align="right">
                      {formatMoneyStandard(i.commission)}
                    </TableCell>
                    <TableCell>{i.commission_status}</TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell colSpan={2} />
                  <TableCell
                    align="right"
                    style={{
                      fontWeight: 'bold',
                    }}
                  >
                    {formatMoneyStandard(
                      dialogData.invoiceitems.reduce((t, i) => t + i.charge, 0),
                    )}
                  </TableCell>
                  <TableCell colSpan={2} />
                  <TableCell
                    align="right"
                    style={{
                      fontWeight: 'bold',
                    }}
                  >
                    {formatMoneyStandard(
                      dialogData.invoiceitems.reduce(
                        (t, i) => t + i.commission,
                        0,
                      ),
                    )}
                  </TableCell>
                  <TableCell colSpan={1} />
                </TableRow>
              </TableBody>
            </Table>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDialogData(null)}>Cancel</Button>
          </DialogActions>
        </Dialog>
      )}
    </React.Fragment>
  )
}

export default StatisticsCommissions
