const defaultState = {open: false, message: '', duration: 10000}
const OPEN_SNACKBAR = 'OPEN_SNACKBAR'
const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR'
function reducer(state = defaultState, action = {}) {
  switch (action.type) {
    case OPEN_SNACKBAR:
      return {
        open: true,
        message: action.message,
        duration: action.duration || defaultState.duration,
      }
    case CLOSE_SNACKBAR:
      return defaultState
    default:
      return state
  }
}
export default reducer

export function openSnackbar({message, duration}) {
  return {type: OPEN_SNACKBAR, message, duration}
}
export function closeSnackbar() {
  return {type: CLOSE_SNACKBAR}
}

