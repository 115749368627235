
type CategoryType = {
  [key: string]: {
    value: string;
    label: string;
  };
};

const Categories: CategoryType = {
  COMMERCIAL: {
    value: 'COMMERCIAL',
    label: 'Small Commercial'
  },
  RESIDENTIAL_WINDOWCLEANING: {
    value: 'RESIDENTIAL_WINDOWCLEANING',
    label: 'Residential Window Cleaning'
  },
  RESIDENTIAL_POWERWASHING: {
    value: 'RESIDENTIAL_POWERWASHING',
    label: 'Residential Powerwashing'
  },
  LARGE_COMMERCIAL: {
    value: 'LARGE_COMMERCIAL',
    label: 'Large Commercial'
  },
  CHRISTMAS: {
    value: 'CHRISTMAS',
    label: 'Christmas'
  }
}


export default Categories
