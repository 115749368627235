import React, {useState, useEffect} from 'react'
import gql from 'graphql-tag'
import {Column} from 'react-virtualized'
import {Link} from 'react-router-dom'
import styled from 'styled-components'
import moment from 'moment'
import queryString from 'query-string'

import {DatePicker} from '@mui/lab'
import Button from '@mui/material/Button'
import Input from '@mui/material/Input'
import InputAdornment from '@mui/material/InputAdornment'
import TextField from '@mui/material/TextField'
import LoadingButton from '../../components/LoadingButton'

import CalendarIcon from '@mui/icons-material/CalendarToday'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TableHead,
  TableCell,
  Table,
  TableBody,
  TableRow,
  FormControlLabel,
  Checkbox,
  Typography,
} from '@mui/material'

import TableList from '../../components/Table/TableList'
import {Body1, Subhead} from '../../components/Typography'
import AvatarBorder from '../../components/AvatarBorder'
import {usePagination} from '../../components/PaginationContainer'
import {Toolbar} from '../../AppHandler'
import ToolbarGroup from '../../components/ToolbarGroup'
import ToolbarMegaTitle from '../../components/ToolbarMegaTitle'
import ModifyQueryParams from '../../components/ModifyQueryParams'
import {Space} from '../../components/Layout'
import {formatMoneyStandard} from '../../utils/moneyFormatter'
import {useMutation} from 'react-apollo'
import {useQuery} from 'react-query'
import {fetchAPI} from '../../schema/utils'

function DateInput({onClick, value}) {
  return (
    <Input
      onClick={onClick}
      value={value}
      startAdornment={
        <InputAdornment position="start">
          <CalendarIcon />
        </InputAdornment>
      }
    />
  )
}

let UpdateTipsMutation = gql`
  mutation payTipsByUser($tips: [UpdateManyTipsInput]) {
    updateManyTips(input: $tips) {
      tips {
        id
      }
    }
  }
`

function ScreenToolbar({
  to,
  includeOpen,
  onChange = () => {},
  tips,
  onPaid,
  users,
  showPast,
  onShowPast,
}) {
  let [dialogOpen, setDialogOpen] = useState(false)
  let [markPaid, {loading: markPaidLoading}] = useMutation(UpdateTipsMutation, {
    onCompleted: () => {
      setDialogOpen(false)
      onPaid()
    },
  })

  let mutationData =
    tips &&
    tips
      .map(e => e.tips)
      .flat(1)
      // .map((i) => console.log(i))
      .map(i => ({
        id: String(i.id),
        paid: true,
      }))

  return (
    <Toolbar>
      <ToolbarGroup first>
        <ToolbarMegaTitle>
          {!showPast && 'Unpaid'} Tips by User
        </ToolbarMegaTitle>
        <Space inline size={2} />
        <DatePicker
          variant="filled"
          label="As of"
          onChange={d => onChange({to: d.format('YYYY-MM-DD')})}
          inputFormat="MMMM D, YYYY"
          value={moment.utc(to)}
          renderInput={props => (
            <TextField
              {...props}
              variant="outlined"
              margin="dense"
              size="small"
            />
          )}
          autoOk
          name="asof"
        />
      </ToolbarGroup>
      <ToolbarGroup></ToolbarGroup>
      <ToolbarGroup last>
        {!showPast && (
          <Button onClick={() => setDialogOpen(true)}>
            {!users || !users.length ? 'Mark All Paid' : 'Mark Selected Paid'}
          </Button>
        )}
        <Dialog open={dialogOpen}>
          <DialogTitle>
            Confirm Marking {!users || !users.length ? 'All' : 'Selected'}{' '}
            Commissions Paid
          </DialogTitle>
          <DialogContent>
            Are you sure you want to mark{' '}
            {!users || !users.length ? 'ALL' : users.join(', ')} unpaid
            commissions as Paid?
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setDialogOpen(false)
              }}
            >
              Cancel
            </Button>
            <LoadingButton
              variant="contained"
              color="primary"
              onClick={() => markPaid({variables: {tips: mutationData}})}
              loading={markPaidLoading}
            >
              Mark Paid
            </LoadingButton>
          </DialogActions>
        </Dialog>
      </ToolbarGroup>
    </Toolbar>
  )
}

let DescWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0 16px;
  & > * {
    margin-right: 8px;
  }
`

let getInitials = ({first, last}) =>
  [...first.slice(0, 1), ...last.slice(0, 1)].join('').toUpperCase()

// <Link to={`/employees/${rowData.id}/attendance?attendanceType=pto`}>

let DescCol = ({cellData, rowData, onClick}) => (
  <DescWrapper onClick={() => onClick(rowData)}>
    <Subhead display="block">
      {cellData ? (
        `${cellData}`
      ) : (
        <Typography style={{fontStyle: 'italic'}}>No Person</Typography>
      )}
    </Subhead>
  </DescWrapper>
)

function UnpaidTipsList({
  items = [],
  onDisplayDetails,
  checks = [],
  onCheckChange,
}) {
  console.log(items)

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>Actions</TableCell>
          <TableCell>Name</TableCell>
          <TableCell>Unpaid Tips</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {items.map(item => (
          <TableRow>
            <TableCell>
              <Checkbox
                color="secondary"
                name={item.user_id}
                onChange={e => {
                  onCheckChange({id: item.user_id, checked: e.target.checked})
                }}
                checked={checks.includes(item.user_id)}
              />
            </TableCell>
            <TableCell>
              <DescCol
                cellData={item.name}
                rowData={item}
                onClick={onDisplayDetails}
              />
            </TableCell>
            <TableCell>{formatMoneyStandard(item.unpaid)}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

let getData = async url => {
  let data = await fetchAPI({
    url,
  })

  return JSON.parse(await data.text())
}

function UnpaidTips({location, match}) {
  let [dialogData, setDialogData] = useState(null)
  let [checks, setChecks] = useState([])

  let handleDisplay = data => setDialogData(data)

  let [queryChanges, setQueryChanges] = useState({})
  let {
    to = moment.utc().format('YYYY-MM-DD'),
    includeOpen = 'false',
  } = queryString.parse(location.search)

  let {data, isLoading: loading, refetch} = useQuery(['unpaidTips', to], () => {
    return getData(`/statistics/unpaid_tips?to=${to}`)
  })

  return (
    <React.Fragment>
      <ScreenToolbar
        to={to}
        includeOpen={includeOpen}
        onChange={changes => setQueryChanges(c => ({...c, ...changes}))}
        tips={
          data &&
          (!checks || !checks.length
            ? data
            : data.filter(e => checks.includes(e.user_id)))
        }
        users={
          data &&
          data.filter(e => checks.includes(e.user_id)).map(e => `${e.name}`)
        }
        onPaid={refetch}
      />
      <ModifyQueryParams query={queryChanges} />
      <UnpaidTipsList
        items={data || []}
        checks={checks}
        onDisplayDetails={handleDisplay}
        onCheckChange={({id, checked}) => {
          if (checked) {
            setChecks([...checks, id])
          } else {
            setChecks([...checks.filter(v => v !== id)])
          }
        }}
      />

      {!!dialogData && (
        <Dialog open={!!dialogData} maxWidth="lg" fullWidth>
          <DialogTitle>{dialogData.name} Tips</DialogTitle>
          <DialogContent>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Tip</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Amount</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dialogData.tips.map(i => (
                  <TableRow key={i.id}>
                    <TableCell>{i.collected_on}</TableCell>
                    <TableCell>
                      {i.type_id === 1 ? 'Cash' : 'Purchase Card'}
                    </TableCell>
                    <TableCell align="right">
                      {formatMoneyStandard(i.amount)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDialogData(null)}>Cancel</Button>
          </DialogActions>
        </Dialog>
      )}
    </React.Fragment>
  )
}

export default UnpaidTips
