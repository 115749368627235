import React, {useState} from 'react'
import styled from 'styled-components'
import {Link, Route} from 'react-router-dom'

import Menu from '@mui/material/Menu'
import MenuItemMui from '@mui/material/MenuItem'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import PersonIcon from '@mui/icons-material/Person'
import PeopleIcon from '@mui/icons-material/People'
import ContactsIcon from '@mui/icons-material/Contacts'

import {Space} from '../../components/Layout'

let MenuItem = styled(MenuItemMui)`
  &:visited {
    color: inherit;
  }
`

export default function CustomersToolbarNav() {
  let [anchorEl, setAnchorEl] = useState(null)
  return (
    <Route
      path="/:customerAccountNavRoute"
      render={({match: {params}, location}) => (
        <>
          <Button
            onClick={e => {
              setAnchorEl(e.currentTarget)
            }}
            component="nav"
            style={{textTransform: 'none'}}
          >
            {params.customerAccountNavRoute === 'accounts' ? (
              <>
                <PeopleIcon />
                <Space inline />
                <Typography variant="h6">Accounts</Typography>
              </>
            ) : params.customerAccountNavRoute === 'customers' ? (
              <>
                <PersonIcon />
                <Space inline />
                <Typography variant="h6">Customers</Typography>
              </>
            ) : (
              <>
                <ContactsIcon />
                <Space inline />
                <Typography variant="h6">Contacts</Typography>
              </>
            )}
            <Space inline />
            <ArrowDropDownIcon />
          </Button>
          <Menu
            id="customer-nav-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={() => {
              setAnchorEl(null)
            }}
          >
            <MenuItem
              selected={params.customerAccountNavRoute === 'customers'}
              component={Link}
              to={{
                pathname: '/customers',
                search: location?.search,
              }}
            >
              <PersonIcon />
              <Space inline />
              Customers
            </MenuItem>
            <MenuItem
              selected={params.customerAccountNavRoute === 'accounts'}
              component={Link}
              to={{
                pathname: '/accounts',
                search: location?.search,
              }}
            >
              <PeopleIcon />
              <Space inline />
              Accounts
            </MenuItem>
            <MenuItem
              selected={params.customerAccountNavRoute === 'contacts'}
              component={Link}
              to={{
                pathname: '/contacts',
                search: location?.search,
              }}
            >
              <ContactsIcon />
              <Space inline />
              Contacts
            </MenuItem>
          </Menu>
        </>
      )}
    />
  )
}
