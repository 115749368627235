import React, {Component} from 'react'
import styled from 'styled-components'

import FormControl from '@mui/material/FormControl'
import Input from '@mui/material/Input'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'

import DownIcon from '@mui/icons-material/ExpandMore'
import UpIcon from '@mui/icons-material/ExpandLess'

let InputField = styled(Input).attrs(() => ({classes: {input: 'input'}}))`
  & .input {
    width: 40px;
  }
`
let constrain = ({min, max}, v) => {
  // allow setting a blank value
  if (v === '') {
    return v
  }
  if (v < min) {
    return min
  }
  if (v > max) {
    return max
  }
  return v
}
class NumberInput extends Component {
  static defaultProps = {
    step: 1,
  }
  state = {
    value: '',
  }
  componentWillMount() {
    let {defaultValue, value} = this.props
    if (!value && defaultValue !== null && defaultValue !== undefined) {
      this.setState({value: defaultValue})
    }
  }
  isControlled = () => !!this.props.value
  getCurrentValue = () => {
    if (this.isControlled()) {
      return this.props.value
    } else {
      return this.state.value
    }
  }
  onChange = vFn => {
    let {min, max} = this.props
    let currV = this.getCurrentValue()
    let value = constrain({min, max}, vFn(currV))
    if (isNaN(value)) {
      value = 0
    }
    if (this.isControlled()) {
      this.props.onChange(value)
    } else {
      this.setState({value})
      this.props.onChange(value)
    }
  }
  handleBlur = () => {
    let v = this.getCurrentValue()
    let {min} = this.props
    if (!v && v !== 0) {
      this.onChange(v => min || 0)
    }
  }
  handleChange = e => {
    this.onChange(v =>
      e.target.value === '' ? '' : parseInt(e.target.value, 10),
    )
  }
  increment = () => this.onChange(v => parseInt(v, 10) + this.props.step)
  decrement = () => this.onChange(v => parseInt(v, 10) - this.props.step)
  incDisabled = () =>
    this.props.max !== undefined &&
    this.getCurrentValue() + this.props.step > this.props.max
  decDisabled = () =>
    this.props.min !== undefined &&
    this.getCurrentValue() - this.props.step < this.props.min
  render() {
    let {
      value: v,
      min,
      max,
      defaultValue,
      onChange,
      children,
      ...rest
    } = this.props
    let value = this.getCurrentValue()
    return (
      <FormControl variant="standard">
        {children}
        <InputField
          value={value}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          {...rest}
          disableUnderline
          startAdornment={
            <InputAdornment position="start">
              <IconButton onClick={this.decrement} disabled={this.decDisabled()} size="large">
                <DownIcon />
              </IconButton>
            </InputAdornment>
          }
          endAdornment={
            <InputAdornment position="end">
              <IconButton onClick={this.increment} disabled={this.incDisabled()} size="large">
                <UpIcon />
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>
    );
  }
}

export default NumberInput
