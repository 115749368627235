// @flow
// import {resolve} from './utils'
export default `
  type Users @model(url: "/users", filters: "UserFilters") {
    id: String
    first_name: String @updateable
    last_name: String @updateable
    username: String @updateable
    hire_date: String @updateable
    termination_date: String @updateable
    active: Boolean @updateable
    days_absent: Int
    license_checked_date: String @updateable
    license_expired_date: String @updateable
    email: String @updateable
    phone: String @updateable
    email_feedback: Boolean @updateable
    max_leave_hours: Int @updateable 
    employee_id: String
    pw_cert: Int @updateable
    ww_cert: Int @updateable
    photo_id: Int @updateable
    auth_until: String @updateable
    password: String @updateable
    bio: String @updateable
    email_signature: String @updateable
    beta_tester: Boolean 
    account_manager_commission_percent: Float @updateable
    account_manager_gpm_percent: Float @updateable
    commission_percent: Float @updateable
    gpm_percent: Float @updateable
    commission_days: Int @updateable
    created_at: String
    updated_at: String
    expense_statistics: Userexpensestatistics @relationship @notUpdateable
    attendance_statistics: Userattendancestatistics @relationship @notUpdateable
    photo: Photos @relationship @notUpdateable
    roles: [Roles] @relationship
    permissions: [Permissions] @relationship @notUpdateable
    session_information: Sessioninformations @relationship @notUpdateable 
  }

  type Roles @model(url: "/roles", filters: "RoleFilters") {
    id: String
    name: String @updateable
    permissions: [Permissions] @relationship
    users: [Users] @relationship
  }

  input RoleFilters {
    name: String
  }

  type Permissions @model(url: "/permissions", filters: "RoleFilters") {
    id: String
    name: String @updateable
  }

  type Userexpensestatistics {
    id: ID 
    losses_this_year: Float 
    all_losses: Float
    requisitions_this_year: Float
    all_requisitions: Float
  }

  type Userattendancestatistics {
    id: ID 
    pto_hours: Int
    sick_hours: Int
    leave_hours: Int
    unpaid_hours: Int
  }

  input UserFilters {
    name: String
    status: UserStatus
    commission: Boolean
    mobile_contact: Boolean
  }

  enum UserStatus {
    active
    inactive
  }

  type Tips @model(url: "/tips", filters: "TipFilters")  {
    id: ID 
    user_id: ID @updateable
    amount: Float @updateable
    route_id: ID @updateable
    collected_at: String @updateable
    type_id: ID @updateable
    paid: Boolean @updateable
    user: Users @relationship
    route: Routes @relationship @notUpdateable
  }

  input TipFilters {
    collected_at: String
    route_id: String
    user_id: String
  }

  type Attendances 
    @model(url: "/attendances", queryName: "attendance", filters: "AttendanceFilters")
   {
    id: String
    user_id: String @updateable
    taken_at: String @updateable
    hours: Int @updateable
    type: AttendanceTypes @updateable
    notes: String @updateable
    notified: Boolean @updateable
    paid: Boolean @updateable
    automated: Boolean
  }

  input AttendanceFilters {
    type: AttendanceTypes
    user_id: String
    from: String
    to: String
  }

  type AttendanceBalances 
    @model(url: "/users/{userId:String!}/sick", queryName: "sickPTOAttendance")
    @model(url: "/users/{userId:String!}/leave", queryName: "leavePTOAttendance", onlyQueryFields: true)
    @model(url: "/users/{userId:String!}/pto", queryName: "PTOAttendance", onlyQueryFields: true)
    @model(url: "/users/{userId:String!}/unpaid", queryName: "unpaidAttendance", onlyQueryFields: true)
   {
    id: String
    attendance_id: String
    user_id: String
    taken_at: String
    hours: Int
    type: AttendanceTypes
    notes: String
    notified: Boolean
    balance: Int
    paid: Boolean @updateable
    automated: Boolean
  }

  type UnpaidAttendanceByUsers 
    @model(url: "/unpaid_pto_by_user", filters: "UnpaidAttendanceFilters")
  {
    id: String
    first_name: String
    last_name: String
    unpaid_pto_hours: Int
    user: Users @relationship
    attendances: [ Attendances ] @relationship
  }

  input UnpaidAttendanceFilters {
    from: String
    to: String
  }

  enum AttendanceTypes {
    PTO
    UNPAID
    EXCUSED
    LATE
  }

  input UnpaidCommissionFilters {
    from: String
    to: String
    includeOpen: Boolean
  }

  type UnpaidCommissionByUsers 
    @model(url: "/unpaid_commission_by_user", filters: "CommissionFilters")
  {
    id: String
    first_name: String
    last_name: String
    commission: Float
    paid_commission: Float
    unpaid_commission: Float
    has_credit_memo: Boolean
    user: Users @relationship
    invoiceitems: [ Invoiceitems ] @relationship
  }

  type CommissionByUsers 
    @model(url: "/commission_by_user", filters: "CommissionFilters")
  {
    id: String
    first_name: String
    last_name: String
    commission: Float
    paid_commission: Float
    unpaid_commission: Float
    total_sales: Float
    scheduled_sales: Float
    scheduled_jobs: Float
    user: Users @relationship
    invoiceitems: [ Invoiceitems ] @relationship
  }

  input CommissionFilters {
    from: String
    to: String
    userId: Int
    includeOpen: Boolean
    commissionStatus: CommissionStatus
  }

  type Accesstimecontrols 
  @model(url: "/users/{userId:String!}/accesstimecontrols", queryName:"accesstimecontrolsByUser", onlyQueryFields: true)
  @model(url: "/accesstimecontrols", queryName:"accesstimecontrols")
  {
    id: String
    day_of_week: Int @updateable
    user_id: String @updateable
    start: Float @updateable
    end: Float @updateable
  }

  type Losses 
  @model(url: "/users/{userId:String!}/losses", queryName:"lossesByUser", onlyQueryFields: true)
  @model(url: "/losses", queryName:"losses")
  {
    id: String
    user_id: String @updateable
    loss_at: String @updateable
    cost: Float @updateable
    notes: String @updateable
  }

  type ActivityLogs @model(url: "/activity_logs", filters: "ActivityLogFilters") {
    id: ID 
    user_id: ID @updateable
    loggable_id: ID @updateable
    loggable_type: String @updateable
    type: ActivityLogTypes @updateable
    message: String @updateable
    data: String @updateable
    data_text: String
    logged_at: String @updateable
    successful: Boolean @updateable
    user: Users @relationship
    documents: [Documents] @relationship @notUpdateable

    # our parser doesn't do union types yet, so this will work for now
    # loggable: Payments | Customers | Accounts | Workorders @relationship
    payment: Payments @relationship
    customer: Customers @relationship
    account: Accounts @relationship
    workorder: Workorders @relationship
    invoice: Invoices @relationship @notUpdateable
  }

  input ActivityLogFilters {
    user_id: String,
    type: ActivityLogTypes,
    from: String,
    to: String,
    # Use the complex id format (customers-123)
    resource: String,
    search: String,
  }

  enum ActivityLogTypes {
    EMAIL
    MESSAGE
    COLLECTIONS
    AR
  }

  type Sessioninformations {
    id: String
    user_id: String
    client_id: String
    expires_at: String
    signed_in_at: String
  }

`
// let resolvers: ResolverMap = {
//   Query: {
//     users: (root, query, context, info) =>
//       resolve({url: '/users', query, info}),
//   },
// }
// export {resolvers}
