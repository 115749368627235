import React, {Component} from 'react'
import styled from 'styled-components'
import gql from 'graphql-tag'
import {graphql} from 'react-apollo'
import compose from 'lodash/flowRight'

import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction'
import ListSubheader from '@mui/material/ListSubheader'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Avatar from '@mui/material/Avatar'
import InputLabel from '@mui/material/InputLabel'

import Delete from '@mui/icons-material/Delete'
import DoneAll from '@mui/icons-material/DoneAll'
import EditIcon from '@mui/icons-material/Edit'
import CloseIcon from '@mui/icons-material/Done'
import ProcessIcon from '@mui/icons-material/DataUsage'

import {
  DetailToolbar,
  ToolbarLeft,
  CloseButton,
  ToolbarTitle,
  ToolbarRight,
  ToolbarIconMenu,
  DeleteMenuItem,
} from '../components/Toolbar'
import NumberInput from '../components/NumberInput'
import InventoryItemSelect from '../components/InventoryItemSelect'
import DeleteDialog from '../components/DeleteDialog'
import DataBlock from '../components/DataBlock'
import CircularProgress from '../components/CircularProgress'
import {formatMoneyStandard} from '../utils/moneyFormatter'
import UsersSelect from '../components/UsersSelect'
import {NavigateBack, RedirectBack} from '../components/Navigator/index'

let Content = styled.div`
  padding: 16px;
`
let InfoSection = styled.div`
  display: flex;
  justify-content: center;
`
let CounterWrapper = styled.div`
  position: relative;
`
let ProgressAvatar = styled(Avatar)`
  background-color: transparent;
  color: ${({theme}) => theme.muiTheme.palette.grey[600]};
`
let ProgressCounter = ({value, children}) => (
  <CounterWrapper>
    <CircularProgress percent={0.4} size={64} value={value} color="default">
      <ProgressAvatar>{children}</ProgressAvatar>
    </CircularProgress>
  </CounterWrapper>
)
let LeftIcon = styled.div`
  margin-right: ${({theme}) => theme.muiTheme.spacing(1)}px;
`
let calcUnitCost = ({quantity, amount}) => (amount * 100) / quantity / 100
let RequisitionToolbar = ({
  loading,
  processing,
  requisition,
  onClose,
  onGoBack,
  onOpen,
  onProcess,
  onDelete,
}) =>
  loading ? (
    <DetailToolbar>
      <ToolbarLeft>
        <NavigateBack defaultBack="/requisitions" marker="RequisitionBack">
          <CloseButton />
        </NavigateBack>
      </ToolbarLeft>
      <ToolbarTitle>Loading</ToolbarTitle>
    </DetailToolbar>
  ) : (
    <DetailToolbar>
      <ToolbarLeft>
        <NavigateBack
          defaultBack="/requisitions"
          marker="RequisitionBack"
          onGoBack={onGoBack}
        >
          <CloseButton />
        </NavigateBack>
      </ToolbarLeft>
      <ToolbarTitle>
        {!loading ? 'Requistion ' + requisition.id : 'Loading'}
      </ToolbarTitle>
      <ToolbarRight>
        {requisition.closed ? (
          <Button onClick={onOpen}>Open</Button>
        ) : (
          <React.Fragment>
            {processing ? (
              <Button onClick={onProcess}>
                <LeftIcon>
                  <EditIcon />
                </LeftIcon>
                Edit
              </Button>
            ) : (
              <Button onClick={onProcess}>
                <LeftIcon>
                  <ProcessIcon />
                </LeftIcon>
                Process
              </Button>
            )}
            <Button onClick={onClose}>
              <LeftIcon>
                <CloseIcon />
              </LeftIcon>
              Close
            </Button>
          </React.Fragment>
        )}
        <ToolbarIconMenu>
          <DeleteMenuItem onClick={onDelete} />
        </ToolbarIconMenu>
      </ToolbarRight>
    </DetailToolbar>
  )

let AvatarContainer = styled.div`
  display: flex;
  align-items: center;
`
let ItemActions = styled(ListItemSecondaryAction)`
  display: flex;
  align-items: center;
`
let RequisitionItem = ({
  item,
  unitCost,
  closed,
  onDelete,
  onQuantityChange,
  onFilledChange,
  processing,
  onFillAll,
}) => (
  <ListItem>
    <Grid container spacing={2} alignItems="center">
      <Grid item>
        <ListItemAvatar>
          <AvatarContainer>
            <ProgressCounter
              value={(item.quantity_filled / item.quantity) * 100}
            >
              {item.quantity}
            </ProgressCounter>
          </AvatarContainer>
        </ListItemAvatar>
      </Grid>
      <Grid item>
        <ListItemText primary={item.quantity_filled} secondary="Filled" />
      </Grid>
      <Grid item>
        <ListItemText
          primary={
            <Typography variant="body2">
              {item.inventoryitem_description +
                ' - ' +
                formatMoneyStandard(unitCost)}
            </Typography>
          }
          secondary={formatMoneyStandard(item.amount)}
        />
      </Grid>
      {closed ? null : processing ? (
        <ItemActions key="processing">
          <NumberInput
            defaultValue={item.quantity_filled}
            onChange={onFilledChange}
            min={0}
          >
            <InputLabel>Filled</InputLabel>
          </NumberInput>
          <IconButton onClick={onFillAll}>
            <DoneAll />
          </IconButton>
        </ItemActions>
      ) : (
        <ItemActions key="requesting">
          <NumberInput
            defaultValue={item.quantity}
            onChange={quantity =>
              onQuantityChange({id: item.id, unit_cost: unitCost, quantity})
            }
            min={1}
          >
            <InputLabel>Quantity</InputLabel>
          </NumberInput>
          <IconButton onClick={onDelete}>
            <Delete />
          </IconButton>
        </ItemActions>
      )}
    </Grid>
  </ListItem>
)

let requisitionFrag = gql`
  fragment RequisitionDetails on Requisitions {
    id
    closed
    total_items
    total_cost
    user {
      id
      first_name
      last_name
    }
    requisitionitems {
      id
      quantity
      quantity_filled
      amount
      inventoryitem_description
      inventoryitem_id
    }
  }
`

let requisitionQuery = gql`
  query RequisitionQuery($id: String!) {
    requisitions(id: $id) {
      ...RequisitionDetails
    }
  }
  ${requisitionFrag}
`
class Requisition extends Component {
  state = {showDelete: false, goBack: false, processing: false}
  handleQuantityChange = ({id, unit_cost, quantity}) => {
    if (quantity > 0) {
      this.props.update({
        id,
        quantity,
        amount: (quantity * (unit_cost * 100)) / 100,
      })
    }
  }
  handleFill = ({id}) => v => {
    this.props.updateFilled({id, quantity_filled: v ? v : 0})
  }
  handleAddInventory = item => {
    let existing = this.props.data.requisitions.requisitionitems.find(
      ({inventoryitem_id}) => inventoryitem_id === item.id,
    )
    if (existing) {
      this.handleQuantityChange({id: existing.id, unit_cost: item.unit_cost})(
        existing.quantity + 1,
      )
    } else {
      this.props.updateRequisition({
        id: this.props.data.requisitions.id,
        requisitionitems: {
          create: [
            {
              quantity: 1,
              quantity_filled: 0,
              inventoryitem_id: item.id,
              amount: item.unit_cost,
            },
          ],
        },
      })
    }
  }
  toggleDelete = () => {
    this.setState(({showDelete}) => ({
      showDelete: !showDelete,
    }))
  }
  handleDelete = async () => {
    await this.props.onDelete(this.props.data.requisitions.id)
    this.setState({goBack: true})
  }
  handleOpen = () => {
    this.props.onOpenRequisition(this.props.data.requisitions.id)
  }
  handleClose = () => {
    this.props.onCloseRequisition(this.props.data.requisitions.id)
  }
  handleProcess = () => {
    this.setState(({processing}) => ({processing: !processing}))
  }
  handleUserChange = user => {
    if (user) {
      this.props.changeUser({id: this.props.data.requisitions.id, user})
    }
  }
  onGoBack = () => {
    // If we don't have any requisition items then delete the requisition
    if (this.props.data.requisitions.requisitionitems.length < 1) {
      this.props.onDelete(this.props.data.requisitions.id)
    }
  }
  render() {
    let {data, onDeleteItem} = this.props
    if (this.state.goBack) {
      return (
        <RedirectBack defaultBack="/requisitions" marker="RequisitionBack" />
      )
    }
    return (
      <Card>
        <RequisitionToolbar
          loading={data.loading}
          processing={this.state.processing}
          requisition={data.requisitions}
          onClose={this.handleClose}
          onOpen={this.handleOpen}
          onProcess={this.handleProcess}
          onDelete={this.toggleDelete}
          onGoBack={this.onGoBack}
        />
        <DeleteDialog
          title="Delete Requisition?"
          message="Are you sure you want to delete this requisition?"
          isOpen={this.state.showDelete}
          onCancel={this.toggleDelete}
          onConfirm={this.handleDelete}
        />
        <Content>
          {!data.loading ? (
            <React.Fragment>
              <InfoSection>
                <DataBlock
                  label="Status"
                  data={
                    <Typography variant="h3">
                      {data.requisitions.closed ? 'Closed' : 'Open'}
                    </Typography>
                  }
                />
                <DataBlock
                  label="Total Cost"
                  data={
                    <Typography variant="h3">
                      {formatMoneyStandard(
                        data.requisitions.requisitionitems.reduce(
                          (acc, {amount}) => acc + amount,
                          0,
                        ),
                      )}
                    </Typography>
                  }
                />
                {!data.requisitions.closed && this.state.processing ? (
                  <DataBlock
                    label="For"
                    data={
                      <UsersSelect
                        selectedUserId={data.requisitions.user.id}
                        onChange={this.handleUserChange}
                      />
                    }
                  />
                ) : (
                  <DataBlock
                    label="For"
                    data={
                      <Typography variant="h3">
                        {data.requisitions.user.first_name +
                          ' ' +
                          data.requisitions.user.last_name}
                      </Typography>
                    }
                  />
                )}
              </InfoSection>
              {!data.requisitions.closed && !this.state.processing && (
                <List subheader={<ListSubheader>Add Items</ListSubheader>}>
                  <ListItem>
                    <InventoryItemSelect
                      fullWidth
                      onChange={this.handleAddInventory}
                    />
                  </ListItem>
                </List>
              )}
              <List>
                {data.requisitions.requisitionitems.map(item => (
                  <React.Fragment key={item.id}>
                    <RequisitionItem
                      item={item}
                      unitCost={calcUnitCost(item)}
                      onQuantityChange={this.handleQuantityChange}
                      onFilledChange={this.handleFill({id: item.id})}
                      onFillAll={() =>
                        this.handleFill({id: item.id})(item.quantity)
                      }
                      onDelete={() => onDeleteItem(item.id)}
                      processing={this.state.processing}
                      closed={data.requisitions.closed}
                    />
                    <Divider />
                  </React.Fragment>
                ))}
              </List>
            </React.Fragment>
          ) : (
            'Loading'
          )}
        </Content>
      </Card>
    )
  }
}
let deleteQuery = gql`
  mutation deleteRequisitionItem($id: String!) {
    deleteRequisitionItems(input: {id: $id}) {
      message
    }
  }
`
let deleteRequisitionQuery = gql`
  mutation deleteRequisition($id: String!) {
    deleteRequisitions(input: {id: $id}) {
      message
    }
  }
`
let updateItemQuery = gql`
  mutation updateRequisitionItem($input: UpdateRequisitionItemsInput) {
    updateRequisitionItems(input: $input) {
      requisitionItems {
        id
        quantity
        amount
      }
    }
  }
`
let changeUserQuery = gql`
  mutation updateRequisitionUser(
    $input: UpdateRequisitionsInput
    $id: String!
  ) {
    updateRequisitions(input: $input) {
      requisitions {
        id
      }
      query {
        requisitions(id: $id) {
          ...RequisitionDetails
        }
      }
    }
  }
  ${requisitionFrag}
`
let updateItemFilledQuery = gql`
  mutation updateRequisitionItemFilled($input: UpdateRequisitionItemsInput) {
    updateRequisitionItems(input: $input) {
      requisitionItems {
        id
        quantity_filled
      }
    }
  }
`
let updateRequisition = gql`
  mutation updateRequisition($input: UpdateRequisitionsInput, $id: String!) {
    updateRequisitions(input: $input) {
      requisitions {
        id
        closed
        total_items
      }
      query {
        requisitions(id: $id) {
          ...RequisitionDetails
        }
      }
    }
  }
  ${requisitionFrag}
`

let createRequisitionsRes = attrs => ({
  __typename: 'Mutation',
  updateRequisitions: {
    __typename: 'UpdateRequisitionsPayload',
    requisitions: {
      __typename: 'Requisitions',
      ...attrs,
    },
  },
})
let createItemRes = attrs => ({
  __typename: 'Mutation',
  updateRequisitionItems: {
    __typename: 'UpdateRequisitionItemsPayload',
    requisitionItems: {
      __typename: 'RequisitionItems',
      ...attrs,
    },
  },
})
export default compose(
  graphql(requisitionQuery, {
    options: ({match}) => ({
      variables: {id: match.params.id},
    }),
  }),
  graphql(deleteQuery, {
    props: ({ownProps, mutate}) => ({
      onDeleteItem: id =>
        mutate({
          variables: {id},
          optimisticResponse: {
            __typename: 'Mutation',
            deleteRequisitionItems: {
              __typename: 'DeleteRequisitionItemsPayload',
              message: 'success',
            },
          },
          update: proxy => {
            let queryOptions = {
              query: requisitionQuery,
              variables: {id: ownProps.match.params.id},
            }
            let data = proxy.readQuery(queryOptions)
            let requisitionitems = data.requisitions.requisitionitems.filter(
              item => item.id !== id,
            )
            proxy.writeQuery({
              ...queryOptions,
              data: {
                ...data,
                requisitions: {...data.requisitions, requisitionitems},
              },
            })
          },
        }),
    }),
  }),
  graphql(updateItemQuery, {
    props: ({ownProps, mutate}) => ({
      update: input =>
        mutate({
          variables: {input},
          optimisticResponse: createItemRes(input),
        }),
    }),
  }),
  graphql(updateItemFilledQuery, {
    props: ({ownProps, mutate}) => ({
      updateFilled: input =>
        mutate({
          variables: {input},
          optimisticResponse: createItemRes(input),
        }),
    }),
  }),
  graphql(updateRequisition, {
    props: ({mutate}) => ({
      updateRequisition: input =>
        mutate({
          variables: {input, id: input.id},
        }),
      onCloseRequisition: id =>
        mutate({
          variables: {input: {id, closed: true}, id},
          optimisticResponse: createRequisitionsRes({
            id,
            closed: true,
          }),
        }),
      onOpenRequisition: id =>
        mutate({
          variables: {input: {id, closed: false}, id},
          optimisticResponse: createRequisitionsRes({
            id,
            closed: false,
          }),
        }),
    }),
  }),
  graphql(changeUserQuery, {
    props: ({mutate}) => ({
      changeUser: ({id, user}) =>
        mutate({variables: {id, input: {id, user_id: user.id}}}),
    }),
  }),
  graphql(deleteRequisitionQuery, {
    props: ({mutate}) => ({
      onDelete: id => mutate({variables: {id}}),
    }),
  }),
)(Requisition)
