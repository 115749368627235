export default `
  type Emailtemplates @model(url: "/emailtemplates", filters: EmailtemplatesFilters) {
    id: String 
    name: String @updateable
    body: String @updateable
    type: EmailtemplateTypes @updateable
    is_default: Boolean @updateable
    is_system: Boolean 
  }

  input EmailtemplatesFilters {
    name: String
    type: EmailtemplateTypes
  }

  enum EmailtemplateTypes {
    AR_30
    AR_90
    AR_OVER_90
    AR_ANY
    AR_SIGNATURE
    AR_INCOME
    system
  }
`
