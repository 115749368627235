import React, {Component} from 'react'
import styled, {withTheme} from 'styled-components'
import {lighten, desaturate} from 'polished'

let ProgressWrapper = styled.div`
  position: relative;
  margin: 6px;
`
let ProgressText = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`
let ProgressCircle = styled.circle`
  transition: all 0.2s linear;
`
class CircularProgress extends Component {
  static defaultProps = {
    size: 80,
    width: 6,
    value: 25,
    color: 'default',
  }
  render() {
    let {
      size,
      width,
      value,
      children,
      color,
      strokeColor,
      secondaryStrokeColor,
      theme,
    } = this.props
    let mainStrokeColor, backgroundStrokeColor
    if (color === 'default') {
      mainStrokeColor = theme.muiTheme.palette.grey[400]
      backgroundStrokeColor = theme.muiTheme.palette.grey[300]
    } else {
      mainStrokeColor = theme.muiTheme.palette[color].light
      backgroundStrokeColor = desaturate(
        0.6,
        lighten(0.3, theme.muiTheme.palette[color].light),
      )
    }
    mainStrokeColor = strokeColor || mainStrokeColor
    backgroundStrokeColor = secondaryStrokeColor || backgroundStrokeColor
    let radius = 21 - width / 2
    let circum = 2 * radius * Math.PI
    let percent = value / 100
    return (
      <ProgressWrapper style={{width: size, height: size}}>
        <ProgressText>{children}</ProgressText>
        <svg width={`${size}px`} height={`${size}px`} viewBox="0 0 42 42">
          <circle
            cx="21"
            cy="21"
            r={21 - width / 2}
            stroke={backgroundStrokeColor}
            strokeWidth={width}
            fill="transparent"
          />
          <ProgressCircle
            cx="21"
            cy="21"
            r={radius}
            stroke={mainStrokeColor}
            strokeWidth={width}
            strokeDasharray={`${circum * percent} ${circum * (1 - percent)}`}
            strokeDashoffset={circum * 0.25}
            fill="transparent"
          />
        </svg>
      </ProgressWrapper>
    )
  }
}
export default withTheme(CircularProgress)
