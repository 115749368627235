import React, { Component } from 'react'
import gql from 'graphql-tag'

import {
  ListItemText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material'

import AutoComplete, { useAutoCompleteEndpoint } from './AutoComplete'
import SelectMenu from '../components/ToolbarDropDownMenu'
import { StaticSelect } from './forms'
import { useQuery } from 'react-query'
import { fetchAPI } from '../schema/utils'

let gqlFragment = gql`
  fragment Taxitem on Taxitems {
    id
    name
    rate
  }
`

export const TaxitemSelect = ({
  onChange,
  selectedId,
  idKey = 'taxitem',
  label = <InputLabel>Taxitem</InputLabel>,
  ...props
}) => {
  let autoProps = useAutoCompleteEndpoint({
    gqlFragment,
    selectedId,
    searchField: 'name',
  })

  return (
    <AutoComplete
      {...autoProps}
      onChange={onChange}
      itemToString={u => (u ? `${u.name}` : '')}
      renderItem={({ item }) => (
        <ListItemText id={`${idKey}-` + item.id} primary={`${item.name}`} />
      )}
      renderNoItems={() => <ListItemText primary={'No Taxitems'} />}
      label={label}
      hideIcon
      {...props}
    />
  )
}

export let TaxitemSelectStatic = ({
  value = '1',
  showAll = true,
  variant = 'menu',
  ...props
}) => {
  let { data, loading } = useAutoCompleteEndpoint({
    gqlFragment,
    selectedId: value,
    searchField: 'name',
  })

  return loading ? (
    <SelectMenu value={value} label="Taxitem" {...props}>
      <MenuItem disabled>...loading...</MenuItem>
    </SelectMenu>
  ) : (
    <SelectMenu value={value} label="Taxitem" {...props}>
      {data.map(({ edge: taxitem }) => (
        <MenuItem key={taxitem.id} value={taxitem.id.toString()}>
          {' '}
          {taxitem.name}
        </MenuItem>
      ))}
    </SelectMenu>
  )
}

export let TaxitemSelectForm = ({ value = '1', showAll = true, ...props }) => {
  let { data, loading } = useAutoCompleteEndpoint({
    gqlFragment,
    selectedId: value,
    searchField: 'name',
  })

  return (
    <StaticSelect {...props}>
      {loading ? (
        <MenuItem disabled>...loading...</MenuItem>
      ) : (
        data.map(({ edge: taxitem }) => (
          <MenuItem key={taxitem.id} value={taxitem.id.toString()}>
            {' '}
            {taxitem.name}
          </MenuItem>
        ))
      )}
    </StaticSelect>
  )
}

export let TaxitemSelectUnified = ({
  taxitem_id,
  taxable,
  onChange,
  name,
  ...props
}) => {
  let { data, isLoading: loading } = useQuery(
    'taxitems',
    async () =>
      await fetchAPI({
        url: 'taxitems',
      }).then(r => r.json()),
  )

  let taxitems = data?.data || []

  let options = []
  let Blank = (
    <MenuItem key={'blank'} value={''}>
      &nbsp;
    </MenuItem>
  )
  options.push(Blank)
  if (taxitems.length) {
    for (let taxitem of taxitems) {
      let Taxable = (
        <MenuItem key={taxitem.id} value={taxitem.id.toString()}>
          {taxitem.attributes.name}
        </MenuItem>
      )

      let Exempt = (
        <MenuItem
          key={taxitem.id + '-exempt'}
          value={taxitem.id.toString() + '-exempt'}
        >
          {taxitem.attributes.name} (Exempt)
        </MenuItem>
      )

      if (taxitem.attributes.rate > 0) {
        options.push(Taxable)
      }
      options.push(Exempt)
    }
  }

  return (
    <Select
      variant="standard"
      name={name}
      onChange={onChange}
      value={
        !!taxitem_id
          ? `${taxitem_id}${taxable ? '' : '-exempt'}`
          : undefined
      }
      // input={<TextField {...props} select />}
      {...props}
    >
      {loading && <MenuItem disabled>...loading...</MenuItem>}
      {...options}
    </Select>
  )
}
