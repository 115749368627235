export default `
  type RouteRuns @model(url: "/routeruns", filters: "RouteRunsFilters") {
    id: ID
    run_at: String @updateable
    day_id: String
    route_id: String @updateable
    user_id: String @updateable
    notes: String @updateable
    scheduler_notes: String @updateable
    vehicle_id: String @updateable
    total_miles: Float @updateable
    travel_time: Float @updateable
    income_capacity: Float @updateable
    time_capacity: Float @updateable
    start_time: String @updateable
    total_charge: Float
    shift_id: Int @updateable
    week: Int @updateable
    override_booking_rules: Boolean @updateable
    reconciled: Boolean @updateable
    collection_matches: Boolean @updateable
    equipment_returned: Boolean @updateable
    reconciliation_notes: String @updateable
    created_at: String
    updated_at: String
    user: Users @relationship @notUpdateable
    users: [Users] @relationship 
    schedules: [Schedules] @relationship @notUpdateable
    schedulesPerf: [Schedules]
    cities: [Cities] @relationship @notUpdateable
    min_invoices: [Invoices] @relationship @notUpdateable
    invoices: [Invoices] @relationship @notUpdateable
    workorders: [Workorders] @relationship @notUpdateable
    assets: [Assets] @relationship 
    route: Routes @relationship @notUpdateable
  }
  
  input RouteRunsFilters {
    status: RouteRunStatus
    date: String
    route: Int
    routes: [ID]
    from: String
    to: String
    user_id: ID
    shift_id: ID
    id: ID
  }
  enum RouteRunStatus {
    completed active planned
  }

extend type Query {
  fullSchedule(filters: RouteRunsFilters): QueryRouteRunsConnection
}

type Schedules @model(url: "/schedules", filters: "SchedulesFilters") {
    id: ID
    route_id: ID @updateable
    customer_id: ID @updateable
    sequence: Int @updateable
    start_at: String @updateable
    end_at: String @updateable
    freq_type: Int @updateable
    freq_interval: Int @updateable
    freq_relative_interval: Int @updateable
    freq_recurrence_factor: Int @updateable
    week: Int @updateable
    day: Int @updateable
    created_at: String
    created_user_id: String
    updated_at: String
    updated_user_id: String
    arrival_start_time: String @updateable
    arrival_end_time: String @updateable

    charge: Float 
    estimated_total: Float 
    man_minutes: Int 
    # frequency: String
    recurrence: Recurrences @relationship @notUpdateable
    services: [Services] @relationship 
    customer: Customers @relationship @notUpdateable
    city: Cities @relationship @notUpdateable
    exclusions: [Exclusions] @relationship
  }

  type Recurrences {
    id: ID
    next_occurrence_at: String
    next_occurrence_route_id: String
  }


type Services @model(url: "/services", filters: "ServicesFilters") {
    id: ID
    schedule_id: ID @updateable
    customer_id: ID @updateable
    saleitem_id: String @updateable
    description: String @updateable
    notes: String @updateable
    charge: Float @updateable
    taxable: Boolean @updateable
    man_minutes: Int @updateable
    commission_start_at: String @updateable
    sales_person_id: ID @updateable
    discount_id: ID @updateable
    created_at: String
    updated_at: String

    sales_person: Users @relationship
    jobrequirements: [Jobrequirements] @relationship 
    saleitem: Saleitems @relationship @notUpdateable
    customer: Customers @relationship @notUpdateable
    discount: Discounts @relationship @notUpdateable
    tasks: [Tasks] @relationship
  }

type Tasks @model(url: "/tasks") {
  id: ID
  taskable_id: ID @updateable
  taskable_type: String @updateable
  tasktype_id: ID @updateable
  description: String @updateable
  complete: Boolean @updateable
  tasktype: Tasktypes @relationship
}

input TasktypeFilters {
  id: ID
  description: String
  trashed: Boolean
}

type Tasktypes @model(url: "/tasktypes", filters: "TasktypeFilters") {
  id: ID
  description: String @updateable
  deleted_at: String 
}

extend type Mutation {
  resequenceSchedule(input: ResequenceScheduleInput, scheduleId: ID!): Schedules
  reschedule(input: RescheduleInput, scheduleId: ID!): Schedules
}

input RescheduleInput {
  on_date: String!
  to_date: String!
  to_route_id: ID!
}

input ResequenceScheduleInput {
  sequence: Int!
  date: String!
  route_id: ID
}

input SchedulesFilters {
  date: String
  route: ID
  customer_id: ID
}

input ServicesFilters {
  sales_person_id: ID
}

type Exclusions @model(url: "/exclusions") {
  id: ID
  schedule_id: ID @updateable
  exclude_at: String @updateable
  reschedule_at: String @updateable
  reschedule_route_id: ID @updateable
}

input RoutesFilters {
  name: String
  trashed: Boolean
}

type Routes @model(url: "/routes", filters: "RoutesFilters") {
  id: ID
  name: String @updateable
  description: String @updateable
  location: String @updateable
  active: Boolean @updateable
  shift_id: String @updateable
  shift: Shift @relationship 
  deleted_at: String
}

type Saleitems @model(url: "/saleitems", filters: "SaleitemsFilters") {
  id: ID
  name: String @updateable
  description: String @updateable
  taxable: Boolean @updateable
  is_billable: Boolean @updateable
  conf_photo_required: Boolean @updateable
  created_at: String
  updated_at: String
  deleted_at: String
}

input SaleitemsFilters {
  id: ID
  name: String
  description: String
  trashed: Boolean
}

type Jobrequirements @model(url: "/jobrequirements"){
  id: ID 
  services: [Services] @relationship
  qty: Int @updateable
  jobrequirementtype_id: String @updateable
  jobrequirementtype: Jobrequirementtypes @relationship @notUpdateable
}

input JobrequirementtypeFilters {
  id: ID
  name: String
}

type Jobrequirementtypes @model(url: "/jobrequirementtypes", filters: "JobrequirementtypeFilters"){
  id: ID 
  name: String @updateable
  type: Int @updateable
}

type Assets @model(url: "/assets", filters: "AssetFilters") {
  id: ID 
  name: String @updateable
  description: String @updateable
  active: Boolean @updateable
  assettype_id: Int @updateable
}

input AssetFilters {
  id: ID
}

type Shift @model(url: "/shifts"){
  id: ID 
  name: String @updateable
  description: String @updateable
  start_time: String @updateable
}

type Routeruntemplates @model(url: "/routerun_templates"){
  id: ID
  day: Int @updateable
  # week: Int @updateable
  route_id: String @updateable
  vehicle_id: String @updateable
  notes: String @updateable
  income_capacity: Float @updateable
  routeruntemplateitems: [Routeruntemplateitems] @relationship
  assets: [Assets] @relationship
  route: Routes @relationship @notUpdateable
}

type Routeruntemplateitems @model(url: "/routerun_templateitems"){
  id: ID
  routerun_template_id: String 
  user_id: String @updateable
  leader: Boolean @updateable
  user: Users @relationship @notUpdateable
}

`
