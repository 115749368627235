import {
  FETCH_ROUTE_RUNS_REQUEST,
  FETCH_ROUTE_RUNS_SUCCESS,
  FETCH_ROUTE_RUNS_FAILURE,
} from '../actions/routeRunsActions'

export default function weeklyOverviewPage(state = {isLoading: false}, action) {
  switch (action.type) {
    case FETCH_ROUTE_RUNS_REQUEST: {
      return {...state, isLoading: true}
    }

    case FETCH_ROUTE_RUNS_SUCCESS: {
      return {...state, isLoading: false}
    }

    case FETCH_ROUTE_RUNS_FAILURE: {
      return {...state, isLoading: false}
    }

    default:
      return state
  }
}

