import React from 'react'
import gql from 'graphql-tag'
import { ListItemText, InputLabel, MenuItem, TextField, ListItem } from '@mui/material'

import AutoComplete, { Autocomplete, useAutoCompleteEndpoint } from './AutoComplete'

let gqlFragment = gql`
  fragment Account on Accounts {
    id
    name
    taxable
    stop_at
  }
`

function AccountSelect({ selectedId, label = 'Account', idKey = 'account', inputProps, ...props }) {
  let autoProps = useAutoCompleteEndpoint({
    gqlFragment,
    selectedId,
    searchField: 'name',
    order: 'name_ASC',
    limit: 100000
  })

  return (
    <Autocomplete
      {...autoProps}
      getOptionLabel={u => (u ? `${u.name}` : '')}
      renderInput={(params) => <TextField {...params} {...inputProps} label={label} />}
      renderOption={(props, option) => <ListItem {...props} id={`${idKey}-${option.id}`} key={option.id}>{option.name}</ListItem>}
      label={<InputLabel>Account</InputLabel>}
      {...props}
    />
  )
}

export default AccountSelect
