import React, {useState} from 'react'
import {useMutation, useQuery, useQueryClient} from 'react-query'
import {gql, prgql} from '../../utils/graphql'
import {
  Grid,
  Typography,
  Button,
  Paper,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Switch,
  FormControlLabel,
} from '@mui/material'
import TableList from '../../components/Table/TableList'
import {Column} from 'react-virtualized'
import {usePagination} from '../../components/PaginationContainer'
import {Save, Settings} from '@mui/icons-material'
import Toolbar, {
  CloseButton,
  DeleteMenuItem,
  DetailToolbar,
  ToolbarCenter,
  ToolbarIconMenu,
  ToolbarLeft,
  ToolbarRight,
} from '../../components/Toolbar'
import {NavigateBack} from '../../components/Navigator'
import styled from 'styled-components'
import {Space} from '../../components/Layout'
import {Formik} from 'formik'
import {TextField} from '../../components/forms'
import {RedirectBack} from '../../components/Navigator'
import {Link} from 'react-router-dom'
import ArrowRightAlt from '@mui/icons-material/ArrowRightAlt'
import theme from '../../styles/theme'
import ArrowLeftAlt from '../../components/ArrowLeftAlt'
import toaster from '../../utils/toaster'
import {useAuth} from '../../security/auth'
import colors from '../../styles/colors'
import {fetchAPI} from '../../schema/utils'
import {WhiteBackgroundSwitch} from '../../components/WhiteBackgroundSwitch'

let query = gql`
  query Roles($limit: Int = 20, $cursor: String) {
    roles: allRoles(limit: $limit, cursor: $cursor) {
      edges {
        edge {
          id
          name
          users {
            id
            active
          }
        }
        cursor
      }
      pageInfo {
        next
        current
        prev
        count
        total
      }
    }
  }
`

let getQuery = gql`
  query Role($id: String!) {
    role: roles(id: $id) {
      id
      name
      permissions {
        id
        name
      }
      users {
        id
        first_name
        last_name
        active
      }
    }
  }
`

let permissionsQuery = gql`
  query Permissions {
    permissions: allPermissions(limit: 100000, cursor: "-1") {
      edges {
        edge {
          id
          name
        }
      }
    }
  }
`

let createMutation = gql`
  mutation Create($input: CreateRolesInput) {
    createRoles(input: $input) {
      roles {
        id
      }
    }
  }
`

let updateMutation = gql`
  mutation Update($input: UpdateRolesInput) {
    updateRoles(input: $input) {
      roles {
        id
        name
      }
    }
  }
`

let deleteMutation = gql`
  mutation Delete($id: String!) {
    deleteRoles(input: {id: $id}) {
      message
    }
  }
`

let RepList = styled.ul`
  margin-left: 2em;
`
let RepListItem = styled.li`
  padding: 0.5em;
  list-style: disc;
`

const Roles = () => {
  let {data, loading, loadMore} = usePagination({
    query: query,
    variables: {
      // order: 'name_ASC',
      cursor: '-1',
    },
  })

  let {data: report, isLoading: reportLoading} = useQuery(
    ['userRoles'],
    async () =>
      await fetchAPI({url: '/reports/users_by_role'}).then(r => r.json()),
  )

  let [showReport, setShowReport] = useState(false)

  function getRowCount() {
    if (!data || !data.roles || !data.roles.pageInfo) {
      return 9999
    }
    return data.roles.pageInfo.total
  }

  return (
    <>
      <Toolbar>
        <FormControlLabel
          style={{marginLeft: '2em'}}
          control={
            <WhiteBackgroundSwitch
              checked={showReport}
              onChange={e => setShowReport(e.target.checked)}
              color="secondary"
            />
          }
          label={'show report'}
        />
      </Toolbar>
      {showReport ? (
        <div
          style={{
            marginLeft: '60px',
            margin: '20px',
            marginBottom: '60px',
          }}
        >
          {reportLoading
            ? 'loading'
            : Object.entries(report || {}).map(([key, val]) => (
                <div key={key}>
                  <Typography variant="h6" style={{marginTop: '20px'}}>
                    {key}
                  </Typography>
                  <RepList>
                    {Object.entries(val).map(([key, val]) => (
                      <RepListItem>
                        <Link to={val}>{key}</Link>{' '}
                      </RepListItem>
                    ))}
                  </RepList>
                </div>
              ))}
        </div>
      ) : (
        <TableList
          data={(data && data.roles && data.roles.edges) || []}
          infinite
          loadMoreRows={loadMore}
          rowCount={getRowCount()}
          wrapRow={({children, rowData}) =>
            rowData.name === 'Administrator' ? (
              <Typography style={{fontStyle: 'italic'}}>{children}</Typography>
            ) : (
              <Link to={`/settings/roles/${rowData.id}`}>{children}</Link>
            )
          }
        >
          <Column
            dataKey="name"
            headerRenderer={({label}) => (
              <div style={{textAlign: 'center'}}>{label}</div>
            )}
            cellRenderer={data => (
              <Typography style={{display: 'flex', alignItems: 'center'}}>
                {data.cellData}
              </Typography>
            )}
            label="Name"
            flexGrow={1}
            width={0}
          />
          <Column
            dataKey="users"
            headerRenderer={({label}) => (
              <div style={{textAlign: 'center'}}>{label}</div>
            )}
            cellRenderer={data => (
              <Typography style={{display: 'flex', alignItems: 'center'}}>
                {data?.cellData?.filter(u => !!u.active).length + ' users'}
              </Typography>
            )}
            label="Users"
            flexGrow={1}
            width={0}
          />
        </TableList>
      )}
    </>
  )
}
let PaperContainer = styled(Paper)`
  width: 80%;
  margin: 16px auto 0;
  padding: 2em;
`
let LeftIcon = styled.div`
  margin-right: ${({theme}) => theme.muiTheme.spacing(1)};
`
let PermissionsContainer = styled.ul`
  border: solid #e2e2e2 1px;
  border-radius: 4px;
  padding: 1em;
`
let PermissionChip = styled.li`
  background-color: ${({active}) =>
    active ? theme.palette.secondary.light : '#e2e2e2'};
  color: ${({active}) => (active ? 'white' : 'inherit')}
  border-radius: 50%;
  border-radius: 20px;
  padding: 8px 15px;
  margin: 5px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: ${theme.palette.primary.main};
    color: #fff;
  }
`

let Form = ({onSubmit, initial = {}, formikkey, onDelete = () => {}}) => {
  const [warn, setWarn] = useState(false)

  let {status: canManageRoles} = useAuth('manage roles')

  let {
    data: permissions,
    isLoading: permissionsLoading,
  } = useQuery('permissions', () => prgql({query: permissionsQuery}))

  return (
    <>
      <Formik initialValues={initial} onSubmit={onSubmit} key={formikkey}>
        {({submitForm, values, setValues}) => {
          let active = values.permissions.map(({name}) => name)
          let available = (permissions?.permissions?.edges || []).filter(
            e => !active.includes(e.edge.name),
          )

          return (
            <>
              <DetailToolbar>
                <ToolbarLeft>
                  <NavigateBack
                    defaultBack={location.pathname}
                    marker="RoleBack"
                  >
                    <CloseButton />
                  </NavigateBack>
                </ToolbarLeft>
                <ToolbarCenter>
                  <Typography variant="h6">
                    {values.id ? 'Edit' : 'Add'} Role
                  </Typography>
                </ToolbarCenter>
                <ToolbarRight>
                  <Space sizePx={16} inline />
                  <Button
                    color="primary"
                    onClick={submitForm}
                    disabled={canManageRoles !== 'authorized'}
                  >
                    <LeftIcon>
                      <Save />
                    </LeftIcon>
                    Save
                  </Button>
                  <ToolbarIconMenu>
                    <DeleteMenuItem
                      onClick={() => setWarn(true)}
                      disabled={
                        !values.id ||
                        values.is_system ||
                        canManageRoles !== 'authorized'
                      }
                    />
                  </ToolbarIconMenu>
                </ToolbarRight>
              </DetailToolbar>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Name"
                    name="name"
                    variant={'outlined'}
                    disabled={canManageRoles !== 'authorized'}
                  />
                </Grid>
                <Grid item xs={12} display={'flex'}>
                  <Grid item xs={5}>
                    <Typography variant="h6">Available</Typography>
                    <PermissionsContainer>
                      {available.map(e => (
                        <PermissionChip
                          key={e.edge.id}
                          onClick={() =>
                            canManageRoles === 'authorized' &&
                            setValues({
                              ...values,
                              permissions: [...values.permissions, {...e.edge}],
                            })
                          }
                        >
                          {e.edge.name}
                        </PermissionChip>
                      ))}
                    </PermissionsContainer>
                  </Grid>
                  <Grid
                    item
                    display={'flex'}
                    alignItems={'center'}
                    xs={1}
                    justifyContent={'center'}
                    flexDirection={'column'}
                  >
                    <ArrowRightAlt fontSize="large" />
                    <ArrowLeftAlt fontSize="large" />
                  </Grid>
                  <Grid item xs={5}>
                    <Typography variant="h6">Active</Typography>
                    <PermissionsContainer>
                      {values.permissions.map(p => (
                        <PermissionChip
                          active
                          key={p.id}
                          onClick={() =>
                            canManageRoles === 'authorized' &&
                            setValues({
                              ...values,
                              permissions: [
                                ...values.permissions.filter(
                                  per => per.id !== p.id,
                                ),
                              ],
                            })
                          }
                        >
                          {p.name}
                        </PermissionChip>
                      ))}
                    </PermissionsContainer>
                  </Grid>
                </Grid>
              </Grid>
              <Dialog open={warn} onClose={() => setWarn(false)}>
                <DialogTitle>Delete Record</DialogTitle>
                <DialogContent>
                  Are you sure you want to delete this record?
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => setWarn(false)}>Cancel</Button>
                  <Button onClick={() => onDelete(values.id)}>Delete</Button>
                </DialogActions>
              </Dialog>
            </>
          )
        }}
      </Formik>
    </>
  )
}

export const Role = ({match, location}) => {
  let [goBack, setGoBack] = useState(false)

  let queryClient = useQueryClient()
  let {data, isError, isLoading} = useQuery(
    ['single', match.params.id],
    async () =>
      await prgql({query: getQuery, variables: {id: match.params.id}}),
  )

  let {mutateAsync} = useMutation('update', async input => {
    return await prgql({
      query: updateMutation,
      variables: {input},
    })
  })

  let {mutateAsync: deleteTemplate} = useMutation('delete', async ({id}) => {
    return await prgql({
      query: deleteMutation,
      variables: {id},
    })
  })

  return (
    <>
      <PaperContainer>
        {isLoading ? (
          <div>Loading...</div>
        ) : isError ? (
          <div>Error</div>
        ) : (
          <Form
            formikkey={match.params.id}
            initial={data && data.role}
            onSubmit={async values => {
              let {users, ...saveValues} = {
                ...values,
                permissions: {set: values.permissions},
              }
              await mutateAsync(saveValues)
              await queryClient.invalidateQueries(['single', match.params.id])
              setGoBack(true)
              toaster.success('Role saved')
            }}
            onDelete={async id => {
              await deleteTemplate({id})
              toaster.success('Role deleted')
              setGoBack(true)
            }}
          />
        )}
      </PaperContainer>
      {/* This is extremely evil. we need a hook that'll do this */}
      {goBack && (
        <RedirectBack
          defaultBack={location.pathname}
          marker="RoleBack"
          location="/settings/roles"
        />
      )}
    </>
  )
}

export const NewRole = ({match, location}) => {
  let [goBack, setGoBack] = useState(false)

  let {mutateAsync} = useMutation('create', async input => {
    return await prgql({
      query: createMutation,
      variables: {input},
    })
  })

  return (
    <>
      <PaperContainer>
        {
          <Form
            formikkey={match.params.id}
            initial={{
              name: '',
              permissions: [],
            }}
            onSubmit={async values => {
              let saveValues = {
                ...values,
                permissions: {set: values.permissions},
              }
              await mutateAsync(saveValues)
              toaster.success('Role Created')
              setGoBack(true)
            }}
          />
        }
      </PaperContainer>
      {/* This is extremely evil. we need a hook that'll do this */}
      {goBack && (
        <RedirectBack defaultBack={location.pathname} marker="RoleBack" />
      )}
    </>
  )
}

export default Roles
