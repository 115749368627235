import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Button from '@mui/material/Button'
import Fab from '@mui/material/Fab'

let RowContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0 20px;
  ${p => p.hasFab && 'padding-right: 95px;'} height: 56px;
  position: relative;
`
let fabStyle = {
  position: 'absolute',
  bottom: `${-56 / 2}px`,
  right: '25px',
  zIndex: 1,
}

export let ToolbarFab = styled(Fab)`
  position: absolute;
  bottom: ${-56 / 2}px;
  right: 40px;

  &:visited {
    // for when Fab is <a>
    color: inherit;
  }
`
ToolbarFab.displayName = 'ToolbarFab'

class ToolbarRow extends React.Component {
  static propTypes = {}
  static defaultProps = {}
  render() {
    const {style, className} = this.props
    let hasFab = false

    const children = React.Children.map(this.props.children, component => {
      if (
        (component.type === Button && component.props.variant === 'fab') ||
        component.type === ToolbarFab ||
        component.type === Fab
      ) {
        hasFab = true
        return React.cloneElement(component, {style: fabStyle})
      }

      return component
    })

    return (
      <RowContainer hasFab={hasFab} style={style} className={className}>
        {children}
      </RowContainer>
    )
  }
}

export let ToolbarGroup = styled.div`
  display: flex;
  align-items: center;
  margin-right: ${p => (p.last ? 'none' : '32px')};
  ${p =>
    !p.last &&
    !p.first &&
    `
  flex: 1;
  justify-content: center;
  `};
`
ToolbarGroup.propTypes = {
  last: PropTypes.bool,
  first: PropTypes.bool,
}

export default ToolbarRow
