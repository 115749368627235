// @flow
export default `

  enum DataSources {
    Airtable
    PathRabbit
  }

  enum DataSourceTypes {
    AIRTABLE_PW
    AIRTABLE_PW2
    AIRTABLE_PRO
    AIRTABLE_CMA
    AIRTABLE_SF
    PATHRABBIT_WW
    PATHRABBIT
  }

  type Estimates @model(url: "/estimates") {
    id: String
    airtable_id: String
    airtable_url: String
    data_source: DataSources
    data_source_type: DataSourceTypes @updateable
    approved: Boolean
    customer_id: Int @updateable
    account_id: Int @updateable
    name: String @updateable
    created_at: String
    updated_at: String
    subtotal: Float
    total: Float
    user_id: Int @updateable
    user: Users @relationship
    updated_id: Int @updateable
    discount_id: String @updateable
    discount: Discounts @relationship

    proposal_notes: String @updateable
    photo_comments: String @updateable
    contract_start_at: String @updateable
    contract_stop_at: String @updateable

    submitted_to_name: String @updateable
    submitted_to_email: String @updateable
    submitted_to_phone: String @updateable

    archived: Boolean @updateable

    customer: Customers @relationship 
    estimateitems: [Estimateitems] @relationship

    photos: [Photos] @relationship

    # discount: Int @updateable
    # story_check: Int
    # constructioncleanup_qty: Float @updateable
    # constructioncleanupinout_qty: Float @updateable
    # casement_qty: Float @updateable
    # casementinout_qty: Float @updateable
    # casementstorm_qty: Float @updateable
    # casementstorminout_qty: Float @updateable
    # frenchcasement_qty: Float @updateable
    # frenchcasementinout_qty: Float @updateable
    # slider_qty: Float @updateable
    # sliderinout_qty: Float @updateable
    # sliderstorm_qty: Float @updateable
    # sliderstorminout_qty: Float @updateable
    # doublehung_qty: Float @updateable
    # doublehunginout_qty: Float @updateable
    # doublehungstorm_qty: Float @updateable
    # doublehungstorminout_qty: Float @updateable
    # doublehunghalffrench_qty: Float @updateable
    # doublehunghalffrenchinout_qty: Float @updateable
    # doublehunghalffrenchstorm_qty: Float @updateable
    # doublehunghalffrenchstorminout_qty: Float @updateable
    # doublehungfrench_qty: Float @updateable
    # doublehungfrenchinout_qty: Float @updateable
    # doublehungfrenchstorm_qty: Float @updateable
    # doublehungfrenchstorminout_qty: Float @updateable
    # transome_qty: Float @updateable
    # transomeinout_qty: Float @updateable
    # arches_qty: Float @updateable
    # archesinout_qty: Float @updateable
    # sidelight_qty: Float @updateable
    # sidelightinout_qty: Float @updateable
    # fixedverysmall_qty: Float @updateable
    # fixedverysmallinout_qty: Float @updateable
    # fixedsmall_qty: Float @updateable
    # fixedsmallinout_qty: Float @updateable
    # fixedmedium_qty: Float @updateable
    # fixedmediuminout_qty: Float @updateable
    # fixedlarge_qty: Float @updateable
    # fixedlargeinout_qty: Float @updateable
    # fixedxlarge_qty: Float @updateable
    # fixedxlargeinout_qty: Float @updateable
    # slidingglassdoor_qty: Float @updateable
    # slidingglassdoorinout_qty: Float @updateable
    # slidingglassdoorfrench_qty: Float @updateable
    # slidingglassdoorfrenchinout_qty: Float @updateable
    # regulardoor_qty: Float @updateable
    # regulardoorinout_qty: Float @updateable
    # halffrenchdoor_qty: Float @updateable
    # halffrenchdoorinout_qty: Float @updateable
    # fullfrenchdoor_qty: Float @updateable
    # fullfrenchdoorinout_qty: Float @updateable
    # skylight_qty: Float @updateable
    # skylightinout_qty: Float @updateable
    # jalice_qty: Float @updateable
    # jaliceinout_qty: Float @updateable
    # chandelieroctagon1tier_qty: Float @updateable
    # chandelieroctagon2tier_qty: Float @updateable
    # chandelieroctagon3tier_qty: Float @updateable
    # mirror_qty: Float @updateable
    # fandusting_qty: Float @updateable
    # grids_qty: Float @updateable
    # wipingframe_qty: Float @updateable
    # screenwashing_check: Boolean @updateable
    # screenhandling_qty: Float @updateable
    # chemical_qty: Float @updateable
    # misc_total: Float @updateable
    # misc_description: String @updateable
    # trackcleaning_qty: Float @updateable
  }

  type Prices @model(url: "/prices") {
    id: String
    twostory_rate: Float @updateable
    twostoryinout_rate: Float @updateable
    threestory_rate: Float @updateable
    threestoryinout_rate: Float @updateable
    constructioncleanup_rate: Float @updateable
    constructioncleanupinout_rate: Float @updateable
    casement_rate: Float @updateable
    casementinout_rate: Float @updateable
    casementstorm_rate: Float @updateable
    casementstorminout_rate: Float @updateable
    frenchcasement_rate: Float @updateable
    frenchcasementinout_rate: Float @updateable
    slider_rate: Float @updateable
    sliderinout_rate: Float @updateable
    sliderstorm_rate: Float @updateable
    sliderstorminout_rate: Float @updateable
    doublehung_rate: Float @updateable
    doublehunginout_rate: Float @updateable
    doublehungstorm_rate: Float @updateable
    doublehungstorminout_rate: Float @updateable
    doublehunghalffrench_rate: Float @updateable
    doublehunghalffrenchinout_rate: Float @updateable
    doublehunghalffrenchstorm_rate: Float @updateable
    doublehunghalffrenchstorminout_rate: Float @updateable
    doublehungfrench_rate: Float @updateable
    doublehungfrenchinout_rate: Float @updateable
    doublehungfrenchstorm_rate: Float @updateable
    doublehungfrenchstorminout_rate: Float @updateable
    transome_rate: Float @updateable
    transomeinout_rate: Float @updateable
    arches_rate: Float @updateable
    archesinout_rate: Float @updateable
    sidelight_rate: Float @updateable
    sidelightinout_rate: Float @updateable
    fixedverysmall_rate: Float @updateable
    fixedverysmallinout_rate: Float @updateable
    fixedsmall_rate: Float @updateable
    fixedsmallinout_rate: Float @updateable
    fixedmedium_rate: Float @updateable
    fixedmediuminout_rate: Float @updateable
    fixedlarge_rate: Float @updateable
    fixedlargeinout_rate: Float @updateable
    fixedxlarge_rate: Float @updateable
    fixedxlargeinout_rate: Float @updateable
    slidingglassdoor_rate: Float @updateable
    slidingglassdoorinout_rate: Float @updateable
    slidingglassdoorfrench_rate: Float @updateable
    slidingglassdoorfrenchinout_rate: Float @updateable
    regulardoor_rate: Float @updateable
    regulardoorinout_rate: Float @updateable
    halffrenchdoor_rate: Float @updateable
    halffrenchdoorinout_rate: Float @updateable
    fullfrenchdoor_rate: Float @updateable
    fullfrenchdoorinout_rate: Float @updateable
    skylight_rate: Float @updateable
    skylightinout_rate: Float @updateable
    jalice_rate: Float @updateable
    jaliceinout_rate: Float @updateable
    chandelieroctagon1tier_rate: Float @updateable
    chandelieroctagon2tier_rate: Float @updateable
    chandelieroctagon3tier_rate: Float @updateable
    mirror_rate: Float @updateable
    fandusting_rate: Float @updateable
    grids_rate: Float @updateable
    wipingframe_rate: Float @updateable
    screenwashing_rate: Float @updateable
    screenhandling_rate: Float @updateable
    chemical_rate: Float @updateable
    trackcleaning_rate: Float @updateable
    markup_rate: Float @updateable
    seniordiscount_rate: Float @updateable
    created_at: String
    updated_at: String
  }

input EstimateserviceFilters {
  id: ID
  label: String
  trashed: Boolean
  saleitem_id: String
  category: String
}

type Estimateitems @model(url: "/estimateitems") {
  id: ID
  qty: Int @updateable
  estimate_id: ID @updateable
  description: String @updateable
  estimateservice_id: ID @updateable
  selected: Boolean @updateable
  customer_id: ID @updateable
  frequency: Int @updateable
  estimateitemprices: [Estimateitemprices] @relationship
  price_override: Float @updateable

  estimateservice: Estimateservices @relationship
  attributeoptions: [Attributeoptions] @relationship
}

type Estimateitemprices @model(url: "/estimateitemprices"){
  id: ID
  frequency: Int @updateable
  price: Float @updateable
  estimateitem_id: ID @updateable
}

type Estimateservices @model(url: "/estimateservices", filters: "EstimateserviceFilters") {
  id: ID
  saleitem_id: ID @updateable
  label: String @updateable
  qty_label: String @updateable
  notes: String @updateable
  category: String @updateable
  price: Float @updateable
  min_price: Float @updateable
  ignore_qty: Int @updateable
  t2_threshhold: Int @updateable
  t2_price: Float @updateable
  t3_threshhold: Int @updateable
  t3_price: Float @updateable
  t4_threshhold: Int @updateable
  t4_price: Float @updateable
  t5_threshhold: Int @updateable
  t5_price: Float @updateable
  is_custom_price: Boolean @updateable
  deleted_at: String 

  prices: [Estimateitemprices] @relationship 

  saleitem: Saleitems @relationship 
  attributes: [Attributes] @relationship 
}

type Attributes @model(url: "/attributes"){
  id: ID
  estimateservice_id: ID @updateable
  label: String @updateable
  multiselect: Boolean @updateable

  attributeoptions: [Attributeoptions] @relationship 
}

type Attributeoptions @model(url: "/attributeoptions"){
  id: ID
  attribute_id: ID @updateable
  label: String @updateable
  price: Float  @updateable

  attribute: Attributes @relationship
}

`
