import React, {useState, useRef, useEffect} from 'react'
import styled from 'styled-components'
import urlJoin from 'url-join'

import MenuItem from '@mui/material/MenuItem'
import {
  IconButton,
  Popover,
  Box,
  Typography,
  TextField,
  Button,
  Tooltip,
} from '@mui/material'

import {
  Assignment,
  AssignmentIndOutlined,
  Clear,
  FilterList,
} from '@mui/icons-material'

import {Toolbar, ToolbarFab as Fab} from '../../AppHandler'
import Routes from '../../constants/Routes'
import {Redirect} from '../../pr-router'
import {DarkTheme, PRThemeProvider} from '../../styles/theme'
import ToolbarGroup from '../../components/ToolbarGroup'
import SearchBox from '../../components/SearchBox'
import SelectMenu from '../../components/ToolbarDropDownMenu'
import {Space} from '../../components/Layout'
import EditWorkorder from '../Workorder/EditWorkorder'
import {DatePicker} from '@mui/lab'
import moment from 'moment'
import RouteSelect from '../../components/RouteSelect'

import {print} from '../../utils'

let SearchBarDateTextField = props => (
  <DarkTheme>
    <TextField
      {...props}
      size="small"
      variant="outlined"
      InputProps={{inputProps: {style: {}}}}
      error={false}
    />
  </DarkTheme>
)

let SearchBoxGroup = styled(ToolbarGroup)`
  flex-grow: 1;
`

let StatusSelect = ({value = 'all', ...props}) => (
  <SelectMenu value={value} label="Status" {...props}>
    <MenuItem value="all">All Statuses</MenuItem>
    <MenuItem value="OPEN">Open</MenuItem>
    <MenuItem value="COMPLETE">Complete</MenuItem>
    <MenuItem value="VOID">Void</MenuItem>
  </SelectMenu>
)

let NotesSelect = ({value = 'all', ...props}) => (
  <SelectMenu value={value} label="Notes" {...props}>
    <MenuItem value="all">All</MenuItem>
    <MenuItem value="true">Has Notes</MenuItem>
    <MenuItem value="false">No Notes</MenuItem>
    <MenuItem value="unresolved">Unresolved</MenuItem>
    <MenuItem value="resolved">Resolved</MenuItem>
  </SelectMenu>
)

let NotCompletedSelect = ({value = 'all', ...props}) => (
  <SelectMenu
    value={value}
    label="Not Completed"
    style={{minWidth: '150px'}}
    {...props}
  >
    <MenuItem value="all">All</MenuItem>
    <MenuItem value="true">Marked</MenuItem>
    <MenuItem value="false">Not Marked</MenuItem>
  </SelectMenu>
)

let InvoicedSelect = ({value = 'all', ...props}) => (
  <SelectMenu
    value={value}
    label="Invoiced"
    style={{minWidth: '150px'}}
    {...props}
  >
    <MenuItem value="all">All</MenuItem>
    <MenuItem value="true">Invoiced</MenuItem>
    <MenuItem value="false">Not Invoiced</MenuItem>
  </SelectMenu>
)

let MultidaySelect = ({value = 'all', ...props}) => (
  <SelectMenu
    value={value}
    label="Multiday"
    style={{minWidth: '150px'}}
    {...props}
  >
    <MenuItem value="all">All</MenuItem>
    <MenuItem value="true">Multiday</MenuItem>
    <MenuItem value="false">Regular</MenuItem>
  </SelectMenu>
)

let BillableSelect = ({value = 'all', ...props}) => (
  <SelectMenu
    value={value}
    label="Billable"
    style={{minWidth: '150px'}}
    {...props}
  >
    <MenuItem value="all">All</MenuItem>
    <MenuItem value="true">Billable</MenuItem>
    <MenuItem value="false">Not Billable</MenuItem>
  </SelectMenu>
)

// let RouteSelect = ({value = 'all', ...props}) => (
//   <SelectMenu value={value} label="Route" {...props}>
//     <MenuItem value="all">All Routes</MenuItem>
//     {Routes.map(route => (
//       <MenuItem key={route.id} value={route.id.toString()}>
//         {' '}
//         {'Route ' + route.id.toString()}
//       </MenuItem>
//     ))}
//   </SelectMenu>
// )

function getFilterDescription({
  searchStatus,
  searchRoute,
  searchTaxable,
  searchFrom,
  searchTo,
  searchHasNotes,
  searchInvoiced,
  searchMultiday,
  searchBillable,
}) {
  let getLabel = (v, label) =>
    v === 'all' || v === undefined || v === null || v === '' ? '' : label
  let filters = [
    getLabel(searchStatus, 'Status'),
    getLabel(searchRoute, 'Route'),
    getLabel(searchTaxable, 'Tax'),
    getLabel(searchFrom, 'Assigned From'),
    getLabel(searchTo, 'Assigned To'),
    getLabel(searchHasNotes, 'Has Notes'),
    getLabel(searchInvoiced, 'Invoiced'),
    getLabel(searchMultiday, 'Multiday'),
    getLabel(searchBillable, 'Billable'),
  ]
  let showFilters = !!filters.join('')
  return showFilters
    ? `Filtering by: ${filters.filter(v => !!v).join('; ')}`
    : ''
}

function WorkordersToolbar({
  location,
  searchQuery,
  searchStatus,
  searchBy,
  searchRoute,
  searchTaxable,
  searchFrom,
  searchTo,
  searchHasNotes,
  searchNotCompleted,
  searchInvoiced,
  searchMultiday,
  searchBillable,
  match,
  onQueryChange = () => {},
  onRefetch,
}) {
  let [newWorkorderName, setNewWorkorderName] = useState(null)
  let [addDialogIsOpen, setAddDialogIsOpen] = useState(false)
  let searchInput = useRef(null)
  let [filterEl, setFilterEl] = useState(null)
  let filtersOpen = !!filterEl

  // focus search when searchBy changes
  useEffect(() => {
    searchInput.current &&
      searchInput.current.focus &&
      searchInput.current.focus()
  }, [searchBy])

  const createFilterSelector = key =>
    function(e) {
      onQueryChange({[key]: e.target.value})
    }
  const createFilterTypeaheadSelector = key =>
    function(e) {
      onQueryChange({[key]: e ? e.id : null})
    }
  const createDateFilterSelector = key =>
    function(momentDate) {
      if (!momentDate) {
        onQueryChange({[key]: null})
      } else {
        let date = momentDate.format('YYYY-MM-DD')
        onQueryChange({[key]: date})
      }
    }

  let onSelectSearchBy = createFilterSelector('searchBy')
  let onSelectSearchStatus = createFilterSelector('searchStatus')
  let onSelectSearchRoute = createFilterTypeaheadSelector('searchRoute')
  let onSelectSearchFrom = createDateFilterSelector('searchFrom')
  let onSelectSearchTo = createDateFilterSelector('searchTo')
  let onSelectSearchHasNotes = createFilterSelector('searchHasNotes')
  let onSelectSearchNotCompleted = createFilterSelector('searchNotCompleted')
  let onSelectSearchInvoiced = createFilterSelector('searchInvoiced')
  let onSelectSearchMultiday = createFilterSelector('searchMultiday')
  let onSelectSearchBillable = createFilterSelector('searchBillable')
  let handleCreateWorkorder = name => setNewWorkorderName(name)

  return newWorkorderName ? (
    <Redirect
      to={{
        pathname: urlJoin(match.url, 'add'),
        state: {
          newWorkorder: {name: newWorkorderName},
          backLocation: {
            pathname: location.pathname,
            query: location.query,
          },
        },
      }}
    />
  ) : (
    <Toolbar>
      <Typography variant="h6">Workorders</Typography>
      <Space inline />
      <SearchBoxGroup>
        <SearchBox
          ref={searchInput}
          style={{flexGrow: 1}}
          value={searchQuery}
          onSearch={q => onQueryChange({q})}
          onEnter={() => {}}
          autoFocus
        />
        <Space inline />
        <SelectMenu
          value={searchBy}
          onChange={onSelectSearchBy}
          label="Search By"
          id="searchby"
          name="searchby"
        >
          <MenuItem value="id">Workorder Number</MenuItem>
          <MenuItem value="name">Customer Name</MenuItem>
          <MenuItem value="notes">Notes</MenuItem>
        </SelectMenu>
      </SearchBoxGroup>
      <ToolbarGroup style={{justifyContent: 'flex-start'}}>
        <IconButton onClick={el => setFilterEl(el.currentTarget)} size="large">
          <FilterList />
        </IconButton>
        <Space inline />
        <Typography variant="caption">
          {getFilterDescription({
            searchStatus,
            searchRoute,
            searchTaxable,
            searchFrom,
            searchTo,
            searchHasNotes,
            searchInvoiced,
            searchBillable,
            searchMultiday,
          })}
        </Typography>
        <Space inline />
        <Tooltip title={'Print a blank workorder'}>
          <IconButton
            onClick={() => print(`/workorders/template`)}
            size="large"
          >
            <AssignmentIndOutlined />
            {/* There's gotta be a better icon out there but it's good enough for now */}
          </IconButton>
        </Tooltip>
        <Popover
          open={filtersOpen}
          anchorEl={filterEl}
          onClose={() => setFilterEl(null)}
          anchorOrigin={{vertical: 'bottom'}}
        >
          <Box p={2} style={{display: 'flex', alignItems: 'center'}}>
            <StatusSelect
              value={searchStatus}
              onChange={onSelectSearchStatus}
            />
            <Space inline />
            {/* Something about this component is screwy with margin so wrapping it in a div helps it */}
            <div>
              <RouteSelect
                selectedId={searchRoute}
                onChange={onSelectSearchRoute}
                variant="outlined"
                size="small"
                inputVariant="outlined"
                inputMargin="dense"
                clearable
              />
            </div>
            <Space inline />
            <DatePicker
              label="Assigned From"
              emptyLabel=""
              value={!!searchFrom ? moment(searchFrom).utc() : ''}
              onChange={onSelectSearchFrom}
              renderInput={props => (
                <TextField {...props} size="small" error={false} />
              )}
              invalidDateMessage=""
              clearable
            />
            <Space inline />
            <DatePicker
              label="Assigned To"
              emptyLabel=""
              value={!!searchTo ? moment(searchTo).utc() : ''}
              onChange={onSelectSearchTo}
              renderInput={props => (
                <TextField {...props} size="small" error={false} />
              )}
              invalidDateMessage=""
              clearable
            />
          </Box>
          <Box p={2} pt={0} style={{display: 'flex', alignItems: 'center'}}>
            <NotesSelect
              value={searchHasNotes}
              onChange={onSelectSearchHasNotes}
            />
            <Space inline />
            <NotCompletedSelect
              value={searchNotCompleted}
              onChange={onSelectSearchNotCompleted}
            />
            <Space inline />
            <InvoicedSelect
              value={searchInvoiced}
              onChange={onSelectSearchInvoiced}
            />
            <Space inline />
            <MultidaySelect
              value={searchMultiday}
              onChange={onSelectSearchMultiday}
            />
            <Space inline />
            <BillableSelect
              value={searchBillable}
              onChange={onSelectSearchBillable}
            />
          </Box>
        </Popover>
      </ToolbarGroup>
      {/* <ToolbarGroup>
      </ToolbarGroup> */}
      <Fab
        color="secondary"
        onClick={() => {
          setAddDialogIsOpen(true)
        }}
        id={'createWorkorder'}
      >
        <Typography variant="subtitle2">+</Typography>
        <Assignment />
      </Fab>
      <PRThemeProvider>
        <EditWorkorder
          open={addDialogIsOpen}
          onClose={() => setAddDialogIsOpen(false)}
          onSave={() => {
            setAddDialogIsOpen(false)
            onRefetch()
          }}
        />
      </PRThemeProvider>
    </Toolbar>
  )
}
export default WorkordersToolbar
