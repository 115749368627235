import React, {useState} from 'react'
import {useMutation, useQuery, useQueryClient} from 'react-query'
import {gql, prgql} from '../../utils/graphql'
import {
  Grid,
  Typography,
  Button,
  Paper,
  FormControlLabel,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Switch,
  FormLabel,
} from '@mui/material'
import TableList from '../../components/Table/TableList'
import {Column} from 'react-virtualized'
import {usePagination} from '../../components/PaginationContainer'
import {
  AttachMoney,
  Bedtime,
  Check,
  CheckCircle,
  HorizontalRule,
  Percent,
  Save,
} from '@mui/icons-material'
import Toolbar, {
  CloseButton,
  TrashMenuItem,
  DetailToolbar,
  ToolbarCenter,
  ToolbarIconMenu,
  ToolbarLeft,
  ToolbarRight,
} from '../../components/Toolbar'
import {NavigateBack} from '../../components/Navigator'
import styled from 'styled-components'
import {Space} from '../../components/Layout'
import {Formik} from 'formik'
import {Checkbox, TextField, Toggle} from '../../components/forms'
import {RedirectBack} from '../../components/Navigator'
import {Link} from 'react-router-dom'
import toaster from '../../utils/toaster'
import colors from '../../styles/colors'
import {useAuth} from '../../security/auth'
import IconButton from '@mui/material/IconButton'
import {RestoreFromTrash} from '@mui/icons-material'
import {fetchAPI} from '../../schema/utils'
import {formatMoneyStandard} from '../../utils/moneyFormatter'
import {WhiteBackgroundSwitch} from '../../components/WhiteBackgroundSwitch'

let query = gql`
  query Disounts($limit: Int = 20, $cursor: String, $filters: DiscountFilters) {
    items: allDiscounts(
      limit: $limit
      cursor: $cursor
      filters: $filters
      orderBy: active_DESC
    ) {
      edges {
        edge {
          id
          name
          type
          amount
          active
          deleted_at
        }
        cursor
      }
      pageInfo {
        next
        current
        prev
        count
        total
      }
    }
  }
`

let getQuery = gql`
  query Discount($id: String!) {
    item: discounts(id: $id) {
      id
      name
      type
      amount
      active
    }
  }
`

let createMutation = gql`
  mutation Create($input: CreateDiscountsInput) {
    createDiscounts(input: $input) {
      discounts {
        id
      }
    }
  }
`

let updateMutation = gql`
  mutation Update($input: UpdateDiscountsInput) {
    updateDiscounts(input: $input) {
      discounts {
        id
        name
        type
        amount
        active
      }
    }
  }
`

let deleteMutation = gql`
  mutation Delete($id: String!) {
    deleteDiscounts(input: {id: $id}) {
      message
    }
  }
`

let TrashableTableList = styled(TableList)`
  & .inactive {
    background-color: ${({theme}) => theme.palette.info.medium};
  }
  & .trashed {
    text-decoration: line-through;
    background-color: ${({theme}) => theme.palette.info.muted};
  }
`

const DISCOUNT_TYPES = {
  PERCENT: 1,
  FIXED: 2,
}

const Discounts = () => {
  let [showTrash, setShowTrash] = useState(false)
  let {data, loading, loadMore, refetch} = usePagination({
    query: query,
    variables: {
      // order: 'name_ASC',
      cursor: '-1',
      filters: {
        trashed: showTrash,
      },
    },
    fetchPolicy: 'cache-and-network',
  })
  let {mutateAsync: restore} = useMutation(
    'restore',
    async ({id}) =>
      await fetchAPI({
        url: `/discounts/${id}/restore`,
        options: {method: 'POST'},
      }),
  )

  function getRowCount() {
    if (!data || !data.items || !data.items.pageInfo) {
      return 9999
    }
    return data.items.pageInfo.total
  }

  let {status: canManage} = useAuth('manage discounts')

  return (
    <>
      <Toolbar>
        <FormControlLabel
          style={{marginLeft: '2em'}}
          control={
            <WhiteBackgroundSwitch
              checked={showTrash}
              onChange={e => {
                setShowTrash(e.target.checked)
              }}
              color="secondary"
            />
          }
          label={'Show Trash'}
        />
      </Toolbar>
      <TrashableTableList
        data={(data && data.items && data.items.edges) || []}
        infinite
        loadMoreRows={loadMore}
        rowCount={getRowCount()}
        wrapRow={({children, rowData}) => {
          return canManage === 'authorized' && !rowData.deleted_at ? (
            <Link to={`/settings/discounts/${rowData.id}`}>{children}</Link>
          ) : (
            children
          )
        }}
        rowClassName={({index}) => {
          let classnames = ''
          let sortedData = data
            ? data.items?.edges?.sort((a, b) => (b.cursor > a.cursor ? -1 : 1))
            : []
          let rowData =
            sortedData && sortedData[index] && sortedData[index].edge

          if (rowData) {
            classnames += rowData.deleted_at ? ' trashed' : ''
          }

          if (rowData) {
            classnames += !rowData.active ? ' inactive' : ''
          }

          return classnames
        }}
      >
        <Column
          dataKey="name"
          headerRenderer={({label}) => (
            <div style={{textAlign: 'left'}}>{label}</div>
          )}
          cellRenderer={data => (
            <Typography style={{textAlign: 'left', display: 'flex'}}>
              {!data.rowData?.active && <Bedtime />}
              {data.cellData}
            </Typography>
          )}
          label="Name"
          flexGrow={1}
          width={0}
        />
        <Column
          dataKey="amount"
          headerRenderer={({label}) => (
            <div style={{textAlign: 'left'}}>{label}</div>
          )}
          cellRenderer={({rowData}) => (
            <Typography style={{textAlign: 'left'}}>
              {rowData?.type === DISCOUNT_TYPES.PERCENT
                ? `${rowData?.amount}%`
                : formatMoneyStandard(rowData?.amount)}
            </Typography>
          )}
          label="Amount"
          flexGrow={1}
          width={0}
        />
        {showTrash && canManage === 'authorized' && (
          <Column
            dataKey="id"
            cellRenderer={data => (
              <LeftIcon>
                <IconButton
                  onClick={async () => {
                    await restore({id: data.cellData})
                    refetch()
                  }}
                >
                  <RestoreFromTrash />
                </IconButton>
              </LeftIcon>
            )}
          />
        )}
      </TrashableTableList>
    </>
  )
}
let PaperContainer = styled(Paper)`
  width: 80%;
  margin: 16px auto 0;
  padding: 2em;
`
let LeftIcon = styled.div`
  margin-right: ${({theme}) => theme.muiTheme.spacing(1)};
`

let Form = ({onSubmit, initial = {}, formikkey, onDelete = () => {}}) => {
  const [warn, setWarn] = useState(false)

  return (
    <>
      <Formik initialValues={initial} onSubmit={onSubmit} key={formikkey}>
        {({submitForm, values, setValues, setFieldValue}) => (
          <>
            <DetailToolbar>
              <ToolbarLeft>
                <NavigateBack
                  defaultBack={location.pathname}
                  marker="DiscountsBack"
                >
                  <CloseButton />
                </NavigateBack>
              </ToolbarLeft>
              <ToolbarCenter>
                <Typography variant="h6">
                  {values.id ? 'Edit' : 'Add'} Source
                </Typography>
              </ToolbarCenter>
              <ToolbarRight>
                <Space sizePx={16} inline />
                <Button color="primary" onClick={submitForm}>
                  <LeftIcon>
                    <Save />
                  </LeftIcon>
                  Save
                </Button>
                <ToolbarIconMenu>
                  <TrashMenuItem onClick={() => setWarn(true)} />
                </ToolbarIconMenu>
              </ToolbarRight>
            </DetailToolbar>
            <Grid container spacing={2}>
              <Grid
                item
                xs={10}
                style={{display: 'flex', alignItems: 'center'}}
              >
                <TextField
                  fullWidth
                  label="Name"
                  name="name"
                  variant={'outlined'}
                />
              </Grid>
              <Grid item xs={2} style={{display: 'flex', alignItems: 'center'}}>
                <FormControlLabel
                  control={<Checkbox color="secondary" />}
                  label="Active"
                  name="active"
                />
              </Grid>
              <Grid item xs={2} style={{display: 'flex', alignItems: 'center'}}>
                <Toggle
                  value={values.type === DISCOUNT_TYPES.PERCENT ? true : false}
                  setValue={v =>
                    setFieldValue(
                      'type',
                      v ? DISCOUNT_TYPES.PERCENT : DISCOUNT_TYPES.FIXED,
                    )
                  }
                  config={{
                    labels: ['Percent', 'Fixed'],
                    icons: [<Percent />, <AttachMoney />],
                  }}
                />
              </Grid>
              <Grid item xs={5} style={{display: 'flex', alignItems: 'center'}}>
                <TextField
                  fullWidth
                  label="Amount"
                  name="amount"
                  variant={'outlined'}
                  type="number"
                />
              </Grid>
            </Grid>
            <Dialog open={warn} onClose={() => setWarn(false)}>
              <DialogTitle>Trash Record</DialogTitle>
              <DialogContent>
                Are you sure you want to trash this record? (You can restore it
                from trash later)
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setWarn(false)}>Cancel</Button>
                <Button onClick={() => onDelete(values.id)} variant="contained">
                  Trash
                </Button>
              </DialogActions>
            </Dialog>
          </>
        )}
      </Formik>
    </>
  )
}

export const Discount = ({match, location}) => {
  let [goBack, setGoBack] = useState(false)

  let queryClient = useQueryClient()
  let {data, isError, isLoading} = useQuery(
    ['single', match.params.id],
    async () =>
      await prgql({query: getQuery, variables: {id: match.params.id}}),
  )

  let {mutateAsync} = useMutation('update', async input => {
    return await prgql({
      query: updateMutation,
      variables: {input},
    })
  })

  let {mutateAsync: destroy} = useMutation('delete', async ({id}) => {
    return await prgql({
      query: deleteMutation,
      variables: {id},
    })
  })

  return (
    <>
      <PaperContainer>
        {isLoading ? (
          <div>Loading...</div>
        ) : isError ? (
          <div>Error</div>
        ) : (
          <Form
            formikkey={match.params.id}
            initial={data && data.item}
            onSubmit={async values => {
              let {is_system, ...saveValues} = values
              console.log(values)
              await mutateAsync(saveValues)
              await queryClient.invalidateQueries(['single', match.params.id])
              toaster.success('Discount saved')
              setGoBack(true)
            }}
            onDelete={async id => {
              await destroy({id})
              toaster.success('Discount trashed')
              setGoBack(true)
            }}
          />
        )}
      </PaperContainer>
      {/* This is extremely evil. we need a hook that'll do this */}
      {goBack && (
        <RedirectBack
          defaultBack={location.pathname}
          marker="DiscountsBack"
          location="/settings/discounts"
        />
      )}
    </>
  )
}

export const NewDiscount = ({match, location}) => {
  let [goBack, setGoBack] = useState(false)

  let {mutateAsync} = useMutation('create', async input => {
    return await prgql({
      query: createMutation,
      variables: {input},
    })
  })

  return (
    <>
      <PaperContainer>
        {
          <Form
            formikkey={match.params.id}
            initial={{
              name: '',
              active: true,
              type: 1,
            }}
            onSubmit={async values => {
              let {...saveValues} = values
              await mutateAsync(saveValues)
              setGoBack(true)
              toaster.success('Discount Created')
            }}
          />
        }
      </PaperContainer>
      {/* This is extremely evil. we need a hook that'll do this */}
      {goBack && (
        <RedirectBack defaultBack={location.pathname} marker="DiscountsBack" />
      )}
    </>
  )
}

export default Discounts
