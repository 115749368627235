import React, {useState} from 'react'
import gql from 'graphql-tag'
import {Route, Switch} from 'react-router-dom'
import queryString from 'query-string'

import {csv, useDebounce} from '../../utils'
import {usePagination} from '../../components/PaginationContainer'
import Customer from '../Customer'
// import AddCustomer from '../Customer/CustomerNew'
import CustomersToolbar from './CustomersToolbar'
import ModifyQueryParams from '../../components/ModifyQueryParams'
import CustomersList from './CustomersList'
import {BILL, COD, PURCHASING_CARD} from '../../constants/TermCodes'
import Routes from '../../constants/Routes'
import {fragments} from './CustomersList'
import {FormControlLabel, Switch as MuiSwitch} from '@mui/material'
import colors from '../../styles/colors'
import styled from 'styled-components'
import {useMutation} from 'react-query'
import {fetchAPI} from '../../schema/utils'
import toaster from '../../utils/toaster'
import {WhiteBackgroundSwitch} from '../../components/WhiteBackgroundSwitch'

let customerQuery = gql`
  query Customers(
    $cursor: String
    $name: String
    $address: String
    $city_name: String
    $region: String
    $phone: String
    $contact: String
    $account_name: String
    $notes: String
    $email: String
    $searchStatus: CustomerStatus
    $searchSalesStatus: CustomerSalesStatus
    $searchTerms: String
    $searchType: String
    $searchRoute: String
    $searchSource: String
    $searchStopReason: String
    $searchSalesPerson: String
    $searchEmployeeContact: String
    $searchTaxable: Boolean
    $searchContract: Boolean
    $searchCreatedYear: String
    $searchSalesstage: String
    $searchPendingSale: Boolean
    $order: CustomersOrderFields
    $limit: Int
    $showTrash: Boolean
  ) {
    customers: allCustomers(
      cursor: $cursor
      limit: $limit
      filters: {
        name: $name
        address: $address
        city_name: $city_name
        region: $region
        phone: $phone
        contact: $contact
        account_name: $account_name
        notes: $notes
        email: $email
        status: $searchStatus
        sales_status: $searchSalesStatus
        terms_id: $searchTerms
        route_id: $searchRoute
        customertype: $searchType
        source_id: $searchSource
        stopreason_id: $searchStopReason
        taxable: $searchTaxable
        contract: $searchContract
        sales_person_id: $searchSalesPerson
        user_id: $searchEmployeeContact
        year: $searchCreatedYear
        trashed: $showTrash
        salesstages: $searchSalesstage
        pending_sale: $searchPendingSale
      }
      orderBy: $order
    ) {
      edges {
        cursor
        edge {
          ...CustomerDetail
          schedules {
            id
          }
        }
      }
      pageInfo {
        next
        current
        prev
        count
        total
      }
    }
  }
  ${fragments.customer}
`

function mungeQuery(query, isLeadView) {
  let parsed = queryString.parse(query)
  function munge(prop, validation, defaultProp = undefined) {
    return validation.includes(prop)
      ? prop
      : !!defaultProp && validation.includes(defaultProp)
      ? defaultProp
      : validation[0]
  }
  let searchStatus = munge(parsed.searchStatus, ['all', 'active', 'inactive'])
  let searchSalesStatus = munge(parsed.searchSalesStatus, [
    'all',
    'lead',
    'sold',
  ])
  let searchType = munge(parsed.searchType, [
    'all',
    'commercial',
    'residential',
    'large_commercial',
  ])
  let searchTerms = munge(parsed.searchTerms, [
    'all',
    BILL,
    COD,
    PURCHASING_CARD,
  ])
  let searchRoute =
    parsed.searchRoute === 'all' ? undefined : parsed.searchRoute
  let searchTaxable =
    parsed.searchTaxable === 'true'
      ? true
      : parsed.searchTaxable === 'false'
      ? false
      : 'all'
  let searchPendingSale =
    parsed.searchPendingSale === 'true'
      ? true
      : parsed.searchPendingSale === 'false'
      ? false
      : 'all'
  let searchContract =
    parsed.searchContract === 'true'
      ? true
      : parsed.searchContract === 'false'
      ? false
      : 'all'
  let searchCreatedYear =
    parsed.searchCreatedYear === 'all' ? undefined : parsed.searchCreatedYear
  let searchBy = munge(parsed.searchBy, [
    'name',
    'address',
    'city_name',
    'region',
    'phone',
    'contact',
    'account_name',
    'notes',
    'email',
  ])
  let order = munge(
    parsed.order,
    [
      'start_at_DESC',
      'name_ASC',
      'name_DESC',
      'start_at_ASC',
      'created_at_ASC',
      'created_at_DESC',
      'stop_at_ASC',
      'stop_at_DESC',
      'city_name_ASC',
      'city_name_DESC',
      'contract_stop_at_ASC',
      'contract_stop_at_DESC',
      'contract_start_at_ASC',
      'contract_start_at_DESC',
      'credit_ASC',
      'credit_DESC',
    ],
    isLeadView && 'created_at_DESC',
  )
  let showTrash = parsed.showTrash === 'true'
  return {
    searchStatus,
    searchSalesStatus,
    searchType,
    searchTerms,
    searchRoute,
    searchBy,
    searchSource: parsed.searchSource,
    searchStopReason: parsed.searchStopReason,
    searchSalesPerson: parsed.searchSalesPerson,
    searchEmployeeContact: parsed.searchEmployeeContact,
    searchSalesstage: parsed.searchSalesstage,
    searchPendingSale,
    searchTaxable,
    searchContract,
    searchCreatedYear,
    showTrash,
    order,
    q: parsed.q || '',
  }
}

function outputCSV({
  name,
  address,
  city_name,
  phone,
  region,
  email,
  notes,
  searchStatus,
  searchSalesStatus,
  searchType,
  searchTerms,
  searchRoute,
  searchSource,
  searchStopReason,
  searchSalesPerson,
  searchEmployeeContact,
  searchTaxable,
  searchContract,
  searchCreatedYear,
  searchSalesstage: salesstages,
  searchPendingSale,
  showTrash: trashed,
}) {
  const query = queryString.stringify({
    name,
    address,
    city_name,
    phone,
    region,
    email,
    notes,
    status: searchStatus,
    sales_status: searchSalesStatus,
    terms_id: searchTerms,
    route_id: searchRoute,
    customertype: searchType,
    source_id: searchSource,
    stopreason_id: searchStopReason,
    taxable: searchTaxable,
    contract: searchContract,
    sales_person_id: searchSalesPerson,
    user_id: searchEmployeeContact,
    year: searchCreatedYear,
    pending_sale: searchPendingSale,
    trashed,
    salesstages,
  })
  csv(`/customers?${query}`)
}

export function Customers({location, match}) {
  let isLeadView = false
  switch (match.path) {
    case '/customers':
      break
    case '/leads':
      isLeadView = true
      break
  }

  const {
    searchStatus,
    searchType,
    searchBy,
    searchTerms,
    searchRoute,
    searchSource,
    searchStopReason,
    searchSalesPerson,
    searchEmployeeContact,
    searchTaxable,
    searchContract,
    searchSalesStatus,
    searchCreatedYear,
    searchSalesstage,
    searchPendingSale,
    showTrash,
    q,
    order,
  } = mungeQuery(location.search, isLeadView)
  let [queryChanges, setQueryChanges] = useState({q})
  let [debouncedQ] = useDebounce(queryChanges.q, 300)

  let mungeSearch = q => (q === 'all' ? undefined : q)

  let search = {
    [searchBy]: q === '' ? undefined : q,
    searchStatus: mungeSearch(searchStatus),
    searchSalesStatus: mungeSearch(searchSalesStatus),
    searchType: mungeSearch(searchType),
    searchTerms: mungeSearch(searchTerms),
    searchRoute: mungeSearch(searchRoute),
    searchSource,
    searchStopReason,
    searchSalesPerson,
    searchEmployeeContact,
    searchTaxable: mungeSearch(searchTaxable),
    searchContract: mungeSearch(searchContract),
    searchCreatedYear,
    searchSalesstage: mungeSearch(searchSalesstage),
    searchPendingSale: mungeSearch(searchPendingSale),
    showTrash,
  }

  let {data, loading, loadMore, refetch} = usePagination({
    query: customerQuery,
    variables: {
      ...search,
      order,
      cursor: '-1',
    },
  })

  let {mutateAsync: restore} = useMutation(
    'restore',
    async ({id}) =>
      await fetchAPI({
        url: `/customers/${id}/restore`,
        options: {method: 'POST'},
      }),
    {
      onSuccess: () => {
        refetch()
        toaster.success('Restored customer (you may need to refresh)')
      },
    },
  )

  return (
    <React.Fragment>
      <CustomersToolbar
        searchQuery={queryChanges.q}
        searchStatus={searchStatus}
        searchType={searchType}
        searchBy={searchBy}
        searchTerms={searchTerms}
        searchRoute={searchRoute}
        searchSource={searchSource}
        searchTaxable={searchTaxable}
        searchContract={searchContract}
        searchStopReason={searchStopReason}
        searchSalesPerson={searchSalesPerson}
        searchSalesStatus={searchSalesStatus}
        searchEmployeeContact={searchEmployeeContact}
        searchCreatedYear={searchCreatedYear}
        searchSalesstage={searchSalesstage}
        searchPendingSale={searchPendingSale}
        onCSV={() => outputCSV(search)}
        onQueryChange={changes => setQueryChanges(c => ({...c, ...changes}))}
        match={match}
        location={location}
      />
      <ModifyQueryParams query={{...queryChanges, q: debouncedQ}} state={{}} />
      <FormControlLabel
        style={{marginLeft: '2em'}}
        control={
          <WhiteBackgroundSwitch
            checked={showTrash}
            onChange={e =>
              setQueryChanges(c => ({...c, showTrash: e.target.checked}))
            }
            color="secondary"
          />
        }
        label={'Show Trash'}
      />
      <CustomersList
        {...search}
        loading={loading}
        customers={data && data.customers && data.customers.edges}
        pageInfo={data && data.customers && data.customers.pageInfo}
        onInfiniteLoad={loadMore}
        onQueryChange={changes => setQueryChanges(c => ({...c, ...changes}))}
        order={order}
        isLeadView={isLeadView}
        restore={restore}
      />
    </React.Fragment>
  )
}

export default ({match}) => (
  <div style={{display: 'flex', flexDirection: 'column', flex: 1}}>
    <Switch>
      <Route path={match.path} exact component={Customers} />
      {/* <Route path={`${match.path}/add`} component={AddCustomer} /> */}
      <Route path={`${match.path}/:id`} component={Customer} />
    </Switch>
  </div>
)
