import PropTypes from 'prop-types'
import React, {Component} from 'react'
import styled from 'styled-components'
import {Theme} from '../styles/theme'
import LoadingButton from './LoadingButton'

import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  DialogTitle,
} from '@mui/material'

import Delete from '@mui/icons-material/Delete'
import {DeleteOutline} from '@mui/icons-material'

let DangerButton = styled(LoadingButton).attrs(() => ({
  classes: {
    root: 'root',
  },
}))`
  background-color: ${({theme, loading}) =>
    loading
      ? theme.muiTheme.palette.action.disabled
      : theme.palette.danger.main};
  &:hover {
    background-color: ${({theme}) => theme.palette.danger.dark};
  }
  & {
    ${({loading}) => !loading && 'color: white;'}
  }
`

class DeleteDialog extends Component {
  static propTypes = {
    title: PropTypes.string,
    message: PropTypes.node,
    isOpen: PropTypes.bool,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func,
    deleteLabel: PropTypes.string,
    cancelLabel: PropTypes.string,
    loading: PropTypes.bool,
  }

  static defaultProps = {
    onConfirm: () => {},
    onCancel: () => {},
    deleteLabel: 'delete',
    cancelLabel: 'cancel',
    loading: false,
  }

  render() {
    let {
      message,
      onCancel,
      onConfirm,
      isOpen,
      deleteLabel,
      cancelLabel,
      loading,
      title,
    } = this.props

    return (
      <Theme>
        {({danger}) => (
          <Dialog open={isOpen} onClose={onCancel}>
            {title && <DialogTitle>{title}</DialogTitle>}
            <DialogContent>
              <DialogContentText>{message}</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button children={cancelLabel} onClick={onCancel} />
              <DangerButton onClick={onConfirm} loading={loading} autoFocus>
                <Delete />
                {deleteLabel}
              </DangerButton>
            </DialogActions>
          </Dialog>
        )}
      </Theme>
    )
  }
}

export class TrashDialog extends Component {
  static propTypes = {
    title: PropTypes.string,
    message: PropTypes.node,
    isOpen: PropTypes.bool,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func,
    deleteLabel: PropTypes.string,
    cancelLabel: PropTypes.string,
    loading: PropTypes.bool,
  }

  static defaultProps = {
    onConfirm: () => {},
    onCancel: () => {},
    deleteLabel: 'trash',
    cancelLabel: 'cancel',
    loading: false,
  }

  render() {
    let {
      message,
      onCancel,
      onConfirm,
      isOpen,
      deleteLabel,
      cancelLabel,
      loading,
      title,
    } = this.props

    return (
      <Theme>
        {({danger}) => (
          <Dialog open={isOpen} onClose={onCancel}>
            {title && <DialogTitle>{title}</DialogTitle>}
            <DialogContent>
              <DialogContentText>{message}</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button children={cancelLabel} onClick={onCancel} />
              <LoadingButton
                onClick={onConfirm}
                loading={loading}
                autoFocus
                variant="contained"
              >
                <DeleteOutline />
                {deleteLabel}
              </LoadingButton>
            </DialogActions>
          </Dialog>
        )}
      </Theme>
    )
  }
}

export default DeleteDialog
