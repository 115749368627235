import Typography from '@mui/material/Typography'
import styled from 'styled-components'

let ToolbarMegaTitle = styled(Typography).attrs(() => ({
  variant: 'h6',
  color: 'inherit',
}))`
  /* font-size: 2em !important; */
`

export default ToolbarMegaTitle
