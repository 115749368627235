import React, {useEffect, useState} from 'react'
import {Link, Route, Switch} from 'react-router-dom'
import * as Yup from 'yup'

import Button from '@mui/material/Button'
import {Toolbar} from '../../AppHandler'
import {Space, Spacing} from '../../components/Layout'
import {
  Paper,
  TableHead,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
  Tooltip,
  TableSortLabel,
  MenuItem,
  Menu,
  TableFooter,
  Grid,
  Badge,
  TextField,
} from '@mui/material'
import Typography from '@mui/material/Typography'
import {
  Assignment,
  Receipt,
  RadioButtonUnchecked,
  CheckCircle,
  Block,
  ErrorOutline,
  MonetizationOn,
  MoneyOff,
  ArrowDropDown,
  Print,
  Edit,
  FlashOn,
  ArrowForward,
  ModeComment,
  Comment,
} from '@mui/icons-material'
import _ from 'lodash'
import {DatePicker} from '@mui/lab'
import styled from 'styled-components'
import {
  getWorkorderIcon,
  PillBoxInvoice,
  PillBoxWorkorder,
} from '../Schedules/scheduleComponents'
import {formatMoneyStandard} from '../../utils/moneyFormatter'
import moment from 'moment'
import RouteSelect, {RouteSelectStatic} from '../../components/RouteSelect'
import colors from '../../styles/colors'
import Label from '../../components/Label'

let DashboardWrapper = styled.div`
  padding: 8px;
  grid-column-gap: 8px;
  display: flex;
  flex-direction: row;
`

let DenseTableCell = styled(TableCell)`
  padding-left: 0.5em;
  padding-right: 0.5em;
`

let TinyCell = styled(DenseTableCell)`
  width: 50px;
`

let SlightlyTinyCell = styled(DenseTableCell)`
  width: 100px;
`

let SmallCell = styled(DenseTableCell)`
  width: 150px;
`

let WoCell = styled(DenseTableCell)`
  // width: 200px;
  margin: 1em;
`

let FlexCellContents = styled.div`
  display: flex;
  align-items: center;
`

// Yes, I know about MUI's grid. But it was causing the buttons to overflow the parent card for some reason.
let ButtonGroupContainer = styled.div`
  display: flex;
`

let ButtonGroupColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 0.25em;
`

let MakeIcon = ({menu}) => <FlashOn style={{marginRight: menu && '.5em'}} />
let OpenIcon = ({menu}) => (
  <RadioButtonUnchecked style={{marginRight: menu && '.5em'}} />
)
let CompleteIcon = ({menu}) => (
  <CheckCircle style={{marginRight: menu && '.5em'}} />
)
let VoidIcon = ({menu}) => <Block style={{marginRight: menu && '.5em'}} />
let InactiveIcon = ({menu}) => (
  <ErrorOutline style={{marginRight: menu && '.5em'}} />
) //<CheckCircleOutline />
let PaidIcon = ({menu}) => (
  <MonetizationOn style={{marginRight: menu && '.5em'}} />
)
let BaddebtIcon = ({menu}) => <MoneyOff style={{marginRight: menu && '.5em'}} />

let _icon = status => {
  switch (status) {
    case 'OPEN':
      return <OpenIcon />
    case 'COMPLETE':
      return <CompleteIcon />
    case 'VOID':
      return <VoidIcon />
    case 'INACTIVE':
      return <InactiveIcon />
    case 'PAID':
      return <PaidIcon />
    case 'BAD_DEBT':
      return <BaddebtIcon />
  }
}

let Invoice = ({inv}) => (
  <Tooltip title={inv.status}>
    <a
      href={'/old/database/invoice.php?InvoiceNumber=' + inv.id}
      style={{color: colors.grey900}}
    >
      <PillBoxInvoice>
        <FlexCellContents>
          <Receipt />
          INV {inv.id}
          {_icon(inv.status)}
        </FlexCellContents>
      </PillBoxInvoice>
    </a>
  </Tooltip>
)

let StyledBadge = styled(Badge)`
  text-align: center;
`

let Workorder = ({wo, onClick, po_needed}) => {
  let editable = wo.status === 'OPEN'
  return (
    <Tooltip title={wo.status + (wo.multiday ? ' (MULTIDAY)' : '')}>
      {editable ? (
        <StyledBadge
          badgeContent={
            wo.notes && (wo.notes_resolved ? <ModeComment /> : <Comment />)
          }
        >
          <PillBoxWorkorder
            onClick={editable && onClick}
            style={{cursor: !editable && 'auto'}}
            multiday={wo.multiday}
            id={`workorder${wo.id}`}
          >
            <FlexCellContents>
              {getWorkorderIcon(po_needed)}
              WO {wo.id}
              {_icon(wo.status)}
            </FlexCellContents>
          </PillBoxWorkorder>
        </StyledBadge>
      ) : (
        <Link to={`/workorders/${wo.id}`} style={{color: 'inherit'}}>
          <StyledBadge
            badgeContent={
              wo.notes && (wo.notes_resolved ? <ModeComment /> : <Comment />)
            }
          >
            <PillBoxWorkorder multiday={wo.multiday} id={`workorder${wo.id}`}>
              <FlexCellContents>
                {getWorkorderIcon(po_needed)}
                WO {wo.id}
                {_icon(wo.status)}
              </FlexCellContents>
            </PillBoxWorkorder>
          </StyledBadge>
        </Link>
      )}
    </Tooltip>
  )
}

let BlankRow = styled(TableRow)`
  border: 0px;
`
let BlankCell = styled(TableCell)`
  border: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
`

let BoldCell = styled(TableCell)`
  border: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  font-weight: bold;
`

let SummaryFooter = ({paymentData = {}}) => {
  // Is there a better way to do this? Dunno. Do I care? Absolutely not.
  // Do I wish that JS was more like PHP? All types of YES.
  let total = 0
  for (let key in paymentData) {
    total += paymentData[key]
  }

  return (
    <>
      <BlankRow>
        <BlankCell colSpan={6} />
        <BoldCell colSpan={1}>Open Invoices</BoldCell>
        <BoldCell>{formatMoneyStandard(paymentData.OPEN)}</BoldCell>
        <BlankCell colSpan={1} />
      </BlankRow>
      <BlankRow>
        <BlankCell colSpan={6} />
        <BoldCell colSpan={1}>Paid Invoices</BoldCell>
        <BoldCell>{formatMoneyStandard(paymentData.PAID)}</BoldCell>
        <BlankCell colSpan={1} />
      </BlankRow>
      <BlankRow>
        <BlankCell colSpan={6} />
        <BoldCell colSpan={1}>Open & Paid</BoldCell>
        <BoldCell>
          {formatMoneyStandard(
            (paymentData.OPEN || 0) + (paymentData.PAID || 0),
          )}
        </BoldCell>
        <BlankCell colSpan={1} />
      </BlankRow>
    </>
  )
}

let Manage = ({
  fromDate,
  toDate,
  customer,
  rows,
  onChangeFromDate,
  onChangeToDate,
  onClickWo,
  manageMode,
  onCheck,
  onCheckAll,
  checks,
  allRowsChecked,
  someRowsChecked,
  onMakeWorkordersAction,
  onCompleteWorkordersAction,
  onOpenWorkordersAction,
  onVoidWorkordersAction,
  onMakeInvoicesAction,
  onVoidInvoicesAction,
  onPayInvoicesWithCashAction,
  availableActions = {},
  actionsLoading,
}) => {
  let [sortBy, setSortBy] = useState(undefined)
  let [sortDir, setSortDir] = useState('asc')

  let [editAnchorEl, setEditAnchorEl] = useState(null)

  let _onSort = col => {
    if (sortBy !== col) {
      setSortBy(col)
    } else {
      switch (sortDir) {
        case 'desc':
          setSortBy(undefined)
          setSortDir('asc')
          break
        case 'asc':
          setSortDir('desc')
          break
      }
    }
  }

  let _getCharge = r =>
    !!r.invoice
      ? r.invoice.charge
      : r.workorder
      ? !r.workorder.multiday
        ? r.workorder.workorderitems.reduce(
            (carry, woi) => carry + woi.charge,
            0,
          )
        : r.workorder.multiday_estimated_total
      : r.schedule.services.reduce((carry, s) => carry + s.charge, 0)

  let _getTerms = r =>
    !!r.invoice ? r.invoice.terms.terms : r.customer.terms.terms

  let _sortFunction = (a, b) => {
    switch (sortBy) {
      // case 'name':
      //   return sortDir === 'asc'
      //     ? a.customer.name.localeCompare(b.customer.name)
      //     : b.customer.name.localeCompare(a.customer.name)
      case 'charge':
        let aCharge = _getCharge(a)
        let bCharge = _getCharge(b)
        return sortDir === 'asc' ? aCharge - bCharge : bCharge - aCharge
      case 'workorder':
        let aId = a.workorder ? a.workorder.id : 0
        let bId = b.workorder ? b.workorder.id : 0
        return sortDir === 'asc' ? aId - bId : bId - aId
      case 'assigned':
        let aAssigned = a.workorder ? a.workorder.assigned_at : null
        let bAssigned = b.workorder ? b.workorder.assigned_at : null
        return sortDir === 'asc'
          ? moment(bAssigned).isBefore(aAssigned)
            ? 1
            : -1
          : moment(aAssigned).isBefore(bAssigned)
          ? 1
          : -1
      case 'completed':
        let aCompleted = a.workorder ? a.workorder.completed_at : null
        let bCompleted = b.workorder ? b.workorder.completed_at : null
        return sortDir === 'asc'
          ? moment(bCompleted).isBefore(aCompleted)
            ? 1
            : -1
          : moment(aCompleted).isBefore(bCompleted)
          ? 1
          : -1
      case 'invoice':
        let aInvId = a.invoice ? a.invoice.id : 0
        let bInvId = b.invoice ? b.invoice.id : 0
        return sortDir === 'asc' ? aInvId - bInvId : bInvId - aInvId
      case 'terms':
        return sortDir === 'asc'
          ? a.customer.terms.id - b.customer.terms.id
          : b.customer.terms.id - a.customer.terms.id
      case 'credit':
        return sortDir === 'asc'
          ? a.customer.credit_items.reduce((carry, c) => carry + c.amount, 0) -
              b.customer.credit_items.reduce((carry, c) => carry + c.amount, 0)
          : b.customer.credit_items.reduce((carry, c) => carry + c.amount, 0) -
              a.customer.credit_items.reduce((carry, c) => carry + c.amount, 0)
      case 'sequence':
      default:
        return (a, b) => a.sequence - b.sequence
    }
  }

  rows = rows.sort(_sortFunction)
  let sortedRowKeys = rows.map(({rowKey}) => rowKey)

  let paymentData = rows.reduce((carry, row) => {
    if (!row.invoice) return carry

    let key = row.invoice.status
    carry[key] = (carry[key] || 0) + row.invoice.charge
    return carry
  }, {})

  return (
    <DashboardWrapper>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <Paper style={{height: '100%'}}>
            <div
              style={{display: 'flex', alignItems: 'center', padding: '1em'}}
            >
              <DatePicker
                value={fromDate}
                onChange={onChangeFromDate}
                label="from"
                renderInput={props => <TextField {...props} size='small'/>}
              />
              <ArrowForward />
              <DatePicker
                value={toDate}
                onChange={onChangeToDate}
                label="to"
                renderInput={props => <TextField {...props} size='small'/>}
              />
            </div>
            <Space size={2} />
          </Paper>
        </Grid>
        <Grid item xs={9}>
          {rows && (
            <Paper
              style={{
                overflow: 'overlay',
                background: manageMode && colors.yellow100,
                height: '100%',
              }}
            >
              <div style={{display: 'flex', alignItems: 'center'}}>
                <Space inline size={3} />
                <Button
                  onClick={e => {
                    setEditAnchorEl(e.currentTarget)
                  }}
                  component="nav"
                  style={{textTransform: 'none'}}
                >
                  <Edit />
                  <Space inline />
                  <Typography>Edit</Typography>
                  <Space inline />
                  <ArrowDropDown />
                </Button>
                <Menu
                  id="manage-workorders-edit-menu"
                  anchorEl={editAnchorEl}
                  open={Boolean(editAnchorEl)}
                  onClose={() => {
                    setEditAnchorEl(null)
                  }}
                >
                  <Typography variant="caption" style={{marginLeft: '1em'}}>
                    Workorders
                  </Typography>
                  {/* <MenuItem
                    disabled={
                      !availableActions['make-workorders'] || actionsLoading
                    }
                    onClick={onMakeWorkordersAction}
                  >
                    <MakeIcon menu /> Make
                  </MenuItem> */}
                  <MenuItem
                    disabled={
                      !availableActions['complete-workorders'] || actionsLoading
                    }
                    onClick={onCompleteWorkordersAction}
                  >
                    <CompleteIcon menu /> Complete
                  </MenuItem>
                  <MenuItem
                    disabled={
                      !availableActions['open-workorders'] || actionsLoading
                    }
                    onClick={onOpenWorkordersAction}
                  >
                    <OpenIcon menu /> Open
                  </MenuItem>
                  <MenuItem
                    disabled={
                      !availableActions['void-workorders'] || actionsLoading
                    }
                    onClick={onVoidWorkordersAction}
                  >
                    <VoidIcon menu /> Void
                  </MenuItem>
                  <hr></hr>
                  <Typography variant="caption" style={{marginLeft: '1em'}}>
                    Invoices
                  </Typography>
                  <MenuItem
                    disabled={
                      !availableActions['make-invoices'] || actionsLoading
                    }
                    onClick={onMakeInvoicesAction}
                  >
                    <MakeIcon menu /> Make
                  </MenuItem>
                  <MenuItem
                    disabled={
                      !availableActions['void-invoices'] || actionsLoading
                    }
                    onClick={onVoidInvoicesAction}
                  >
                    <VoidIcon menu /> Void
                  </MenuItem>
                  <MenuItem
                    disabled={
                      !availableActions['pay-invoices'] || actionsLoading
                    }
                    onClick={onPayInvoicesWithCashAction}
                  >
                    <PaidIcon menu /> Pay Cash
                  </MenuItem>
                </Menu>
                <Space inline size={2} />
                {!!customer && (
                  <Typography variant="subhead">
                    Balance: {formatMoneyStandard(customer.balances.balance)}
                  </Typography>
                )}
                <Space inline size={2} />
                {!!customer && (
                  <Typography variant="subhead">
                    Credit:{' '}
                    {formatMoneyStandard(
                      customer.credit_items.reduce(
                        (carry, ci) => carry + ci.amount,
                        0,
                      ),
                    )}
                  </Typography>
                )}
                <Space inline size={2} />
                {!!customer && (
                  <Typography variant="subhead">
                    Open Workorders:{' '}
                    {customer.open_workorders
                      ? customer.open_workorders.length
                      : 0}
                  </Typography>
                )}
              </div>
              <Table
                style={{tableLayout: 'fixed', minWidth: '1200px'}}
                size="small"
                key={sortBy + sortDir}
              >
                <TableHead>
                  <TableRow>
                    <TinyCell>
                      <Checkbox
                        color="secondary"
                        checked={allRowsChecked}
                        onClick={onCheckAll}
                        indeterminate={someRowsChecked && !allRowsChecked}
                      />
                    </TinyCell>
                    <TableCell colSpan={1}>
                      <TableSortLabel
                        active={sortBy === 'assigned'}
                        direction={sortDir}
                        onClick={() => _onSort('assigned')}
                      >
                        Assigned
                      </TableSortLabel>
                    </TableCell>
                    <TableCell colSpan={1}>
                      {/* <TableSortLabel
                        active={sortBy === 'route'}
                        direction={sortDir}
                        onClick={() => _onSort('route')}
                      > */}
                      Route
                      {/* </TableSortLabel> */}
                    </TableCell>
                    <WoCell colSpan={1}>
                      <TableSortLabel
                        active={sortBy === 'workorder'}
                        direction={sortDir}
                        onClick={() => _onSort('workorder')}
                      >
                        Workorders
                      </TableSortLabel>
                    </WoCell>
                    <TableCell colSpan={1}>
                      <TableSortLabel
                        active={sortBy === 'completed'}
                        direction={sortDir}
                        onClick={() => _onSort('completed')}
                      >
                        Completed
                      </TableSortLabel>
                    </TableCell>
                    <WoCell colSpan={1}>
                      <TableSortLabel
                        active={sortBy === 'invoice'}
                        direction={sortDir}
                        onClick={() => _onSort('invoice')}
                      >
                        Invoices
                      </TableSortLabel>
                    </WoCell>
                    <TableCell>Description</TableCell>
                    <SlightlyTinyCell>
                      <TableSortLabel
                        active={sortBy === 'charge'}
                        direction={sortDir}
                        onClick={() => _onSort('charge')}
                      >
                        Charge
                      </TableSortLabel>
                    </SlightlyTinyCell>
                    <SlightlyTinyCell>
                      <TableSortLabel
                        active={sortBy === 'terms'}
                        direction={sortDir}
                        onClick={() => _onSort('terms')}
                      >
                        Terms
                      </TableSortLabel>
                    </SlightlyTinyCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map(
                    ({
                      workorder,
                      invoice,
                      schedule,
                      customer,
                      sequence,
                      rowKey,
                    }) => {
                      return (
                        <TableRow key={rowKey} selected={!!checks[rowKey]}>
                          <SmallCell>
                            <Checkbox
                              color="secondary"
                              checked={!!checks[rowKey]}
                              onClick={e => onCheck({e, rowKey, sortedRowKeys})}
                              name={rowKey}
                            />
                          </SmallCell>
                          <TableCell>
                            {!!workorder &&
                              moment
                                .utc(workorder.assigned_at)
                                .format('ddd, MMM D')}
                          </TableCell>
                          <TableCell>
                            {!invoice && workorder && workorder.multiday && '~'}
                          </TableCell>
                          <DenseTableCell>
                            <FlexCellContents>
                              {workorder && (
                                <Workorder
                                  wo={workorder}
                                  onClick={() => onClickWo(workorder)}
                                  po_needed={customer.po_needed}
                                />
                              )}
                            </FlexCellContents>
                          </DenseTableCell>
                          <TableCell>
                            {workorder && workorder.completed_at
                              ? moment
                                  .utc(workorder.completed_at)
                                  .format('ddd, MMM D')
                              : null}
                          </TableCell>
                          <DenseTableCell>
                            <FlexCellContents>
                              {invoice && <Invoice inv={invoice} />}
                            </FlexCellContents>
                          </DenseTableCell>
                          <TableCell>
                            {workorder &&
                              workorder.workorderitems
                                .map(
                                  woi =>
                                    `${woi.saleitem.name} - ${woi.description}`,
                                )
                                .join(', ')}
                          </TableCell>
                          <DenseTableCell>
                            {!invoice && workorder && workorder.multiday && '~'}
                            {formatMoneyStandard(
                              _getCharge({schedule, invoice, workorder}),
                            )}
                          </DenseTableCell>
                          <DenseTableCell>
                            {_getTerms({
                              schedule,
                              invoice,
                              workorder,
                              customer,
                            })}
                          </DenseTableCell>
                        </TableRow>
                      )
                    },
                  )}
                  {rows.length ? (
                    <SummaryFooter paymentData={paymentData} />
                  ) : (
                    <></>
                  )}
                </TableBody>
              </Table>
            </Paper>
          )}
        </Grid>
      </Grid>
    </DashboardWrapper>
  )
}

export default Manage
