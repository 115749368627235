import React, {useState} from 'react'
import styled from 'styled-components'
import gql from 'graphql-tag'
import moment from 'moment'

import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'
import CircularProgress from '@mui/material/CircularProgress'

import {Grid, MenuItem, TextField, Chip, Tooltip} from '@mui/material'

import SaveIcon from '@mui/icons-material/Save'
import AccessTimeIcon from '@mui/icons-material/AccessTime'

import {
  DetailToolbar,
  ToolbarLeft,
  CloseButton,
  ToolbarTitle,
  ToolbarRight,
  ToolbarIconMenu,
  DeleteMenuItem,
} from '../../components/Toolbar'
import {NavigateBack, RedirectBack} from '../../components/Navigator/index'
import Loading from '../../components/Loading'
import {Space} from '../../components/Layout'
import DeleteDialog from '../../components/DeleteDialog'
import {TimePicker as MuiTimePicker} from '@mui/lab'
import {useMutation, useQuery} from 'react-apollo'

let START_WORK_HOURS = 600
let END_WORK_HOURS = 1730

let TimePicker = function({
  name,
  valueFormat = 'HHmm',
  as = MuiTimePicker,
  twentyFourInt = true,
  value,
  onChange,
  ...props
}) {
  let Comp = as
  if (twentyFourInt) {
    value = String(value).padStart(4, 0)
  }

  return (
    <Comp
      onChange={d => onChange({[name]: Number(d.format(valueFormat))})}
      value={moment(value, valueFormat)}
      name={name}
      renderInput={ps => <TextField {...ps} {...props} />}
      {...props}
    />
  )
}

let LeftIcon = styled.div`
  margin-right: ${({theme}) => theme.muiTheme.spacing(1)};
`

let BrightMenuItem = styled(MenuItem)`
  color: ${({theme}) => theme.palette.secondary.main};
  font-weight: bold;
`

let ScreenToolbar = ({
  defaultBack,
  canSave,
  accesstimecontrol,
  onSave,
  isSaving,
  isNew,
  onDelete,
}) => (
  <DetailToolbar>
    <ToolbarLeft>
      <NavigateBack
        defaultBack={defaultBack}
        marker="EmployeeAccesstimecontrolBack"
      >
        <CloseButton />
      </NavigateBack>
    </ToolbarLeft>
    <ToolbarTitle>{accesstimecontrol && 'Access time'}</ToolbarTitle>
    <ToolbarRight>
      <Space sizePx={16} inline />
      <Button onClick={onSave} disabled={!canSave && !isNew} color="primary">
        <LeftIcon>
          {isSaving ? (
            <CircularProgress size={24} thickness={3} />
          ) : (
            <SaveIcon />
          )}
        </LeftIcon>
        Save
      </Button>
      {!isNew && (
        <ToolbarIconMenu>
          <DeleteMenuItem onClick={onDelete} />
        </ToolbarIconMenu>
      )}
    </ToolbarRight>
  </DetailToolbar>
)

let accesstimecontrolEntryQuery = gql`
  query accesstimecontrolEntryQuery($id: String!) {
    accesstimecontrols(id: $id) {
      id
      user_id
      day_of_week
      start
      end
    }
  }
`

let updateAccesstimecontrolQuery = gql`
  mutation accesstimecontrolEntryQuery($input: UpdateAccesstimecontrolsInput) {
    updateAccesstimecontrols(input: $input) {
      accesstimecontrols {
        id
        user_id
        day_of_week
        start
        end
      }
    }
  }
`
let createAccesstimecontrolQuery = gql`
  mutation createAccesstimecontrolsEntryQuery(
    $input: CreateAccesstimecontrolsInput
  ) {
    createAccesstimecontrols(input: $input) {
      accesstimecontrols {
        id
        user_id
        day_of_week
        start
        end
      }
    }
  }
`

let deleteQuery = gql`
  mutation deleteAccesstimecontrolEntry($id: String!) {
    deleteAccesstimecontrols(input: {id: $id}) {
      message
    }
  }
`

let DetailEdit = ({loading, data, onChange}) => {
  let [optionSelected, setOptionSelected] = useState(undefined)

  if (optionSelected === undefined) {
    setOptionSelected(
      data.end === 2400 && data.start === 0
        ? 'allday'
        : data.end === END_WORK_HOURS && data.start === START_WORK_HOURS
        ? 'work'
        : 'custom',
    )
  }

  return loading || !data ? (
    <Loading />
  ) : (
    <>
      <Grid container spacing={3}>
        <Grid item xs={5}>
          <TextField
            name="day_of_week"
            variant="filled"
            label="Day of Week"
            fullWidth
            select
            value={data.day_of_week}
            onChange={d => onChange({day_of_week: Number(d.target.value)})}
          >
            <BrightMenuItem value="7">
              <strong>Weekends</strong>
            </BrightMenuItem>
            <BrightMenuItem value="8">
              <strong>Weekdays</strong>
            </BrightMenuItem>
            <MenuItem value="0">Sunday</MenuItem>
            <MenuItem value="1">Monday</MenuItem>
            <MenuItem value="2">Tuesday</MenuItem>
            <MenuItem value="3">Wednesday</MenuItem>
            <MenuItem value="4">Thursday</MenuItem>
            <MenuItem value="5">Friday</MenuItem>
            <MenuItem value="6">Saturday</MenuItem>
          </TextField>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item>
          <Tooltip
            title={
              moment(String(START_WORK_HOURS).padStart(4, 0), 'HHmm').format(
                'LT',
              ) +
              ' to ' +
              moment(String(END_WORK_HOURS).padStart(4, 0), 'HHmm').format('LT')
            }
          >
            <Chip
              label="Work Hours"
              color={optionSelected === 'work' ? 'primary' : undefined}
              onClick={() => {
                setOptionSelected('work')
                onChange({start: START_WORK_HOURS, end: END_WORK_HOURS})
              }}
            />
          </Tooltip>
        </Grid>
        <Grid item>
          <Chip
            label="All Day"
            color={optionSelected === 'allday' ? 'primary' : undefined}
            onClick={() => {
              setOptionSelected('allday')
              onChange({start: 0, end: 2400})
            }}
          />
        </Grid>
        <Grid item>
          <Chip
            label="Custom"
            icon={<AccessTimeIcon />}
            color={optionSelected === 'custom' ? 'primary' : undefined}
            onClick={() => setOptionSelected('custom')}
          />
        </Grid>
      </Grid>
      {optionSelected === 'custom' ? (
        <>
          <Space sizePx={20} />
          <Grid container spacing={3}>
            <Grid item xs={5}>
              <TimePicker
                onChange={onChange}
                twentyFourInt={true}
                label="Start"
                name="start"
                value={data.start}
              ></TimePicker>
            </Grid>
            <Grid item xs={5}>
              <TimePicker
                onChange={onChange}
                label="End"
                name="end"
                value={data.end}
              ></TimePicker>
            </Grid>
          </Grid>
        </>
      ) : null}
    </>
  )
}

let PaperContainer = styled(Paper)`
  max-width: 960px;
  margin: 16px auto 0;
`

let Content = styled.div`
  padding: 16px;
`

let DeleteAccesstimecontrol = ({
  id,
  showDelete,
  toggleDelete,
  onDelete,
  deleteMut,
}) => (
  <DeleteDialog
    title="Delete Access time control?"
    message="Are you sure you want to delete this Access time control?"
    isOpen={showDelete}
    onCancel={toggleDelete}
    onConfirm={() => {
      deleteMut({variables: {id}}).then(onDelete)
    }}
  />
)

let mergeChanges = (original, changes) => {
  let defaultAccesstimecontrol = {
    day_of_week: 7,
    start: START_WORK_HOURS,
    end: END_WORK_HOURS,
  }
  return {...defaultAccesstimecontrol, ...original, ...changes}
}

let normalizeData = original => {
  if ('end' in original && original.end === 0) {
    original.end = 2400
  }

  return original
}

let AccessTimeControlEntry = ({match}) => {
  let {params = {}} = match
  let [changes, setChanges] = useState({})
  let [goBack, setGoBackState] = useState(false)
  let [showDelete, setShowDelete] = useState(false)

  let [
    updateAccesstimecontrol,
    {loading: loadingUpdate, resultData},
  ] = useMutation(updateAccesstimecontrolQuery)

  let [deleteAccessTimeControl] = useMutation(deleteQuery)

  let {loading, error, data, refetch: refetchAccessTimeControl} = useQuery(
    accesstimecontrolEntryQuery,
    {
      variables: {id: params.controlId},
    },
  )

  let canSave = Object.keys(changes).length > 0
  let defaultBack = `/employees/${params.id}/accesstimecontrols`
  let setGoBack = () => setGoBackState(true)
  let toggleDelete = () => setShowDelete(({showDelete}) => !showDelete)

  return (
    <PaperContainer>
      <ScreenToolbar
        accesstimecontrol={mergeChanges(data.accesstimecontrols, changes)}
        defaultBack={defaultBack}
        onSave={() =>
          updateAccesstimecontrol({
            variables: {
              input: {
                id: params.controlId,
                ...normalizeData(changes),
              },
            },
          }).then(setGoBack)
        }
        onDelete={() => setShowDelete(true)}
        canSave={canSave}
        isSaving={loadingUpdate}
      />
      <Content>
        <DetailEdit
          data={mergeChanges(data.accesstimecontrols, changes)}
          loading={loading}
          onChange={newChanges =>
            setChanges(() => ({...changes, ...newChanges}))
          }
        />
      </Content>
      <DeleteAccesstimecontrol
        id={params.controlId}
        showDelete={showDelete}
        toggleDelete={toggleDelete}
        onDelete={setGoBack}
        deleteMut={deleteAccessTimeControl}
      />
      {goBack && (
        <RedirectBack
          defaultBack={defaultBack}
          marker="EmployeeAccesstimecontrolBack"
        />
      )}
    </PaperContainer>
  )
}

export default AccessTimeControlEntry

let NewAccesstimecontrolEntry = ({match}) => {
  let {params = {}} = match
  let [changes, setChanges] = useState({})
  let [goBack, setGoBack] = useState(false)

  let [createAccesstimecontrol, {loading}] = useMutation(
    createAccesstimecontrolQuery,
  )

  let canSave = Object.keys(changes).length > 0
  let defaultBack = `/employees/${params.id}/accesstimecontrols`
  let computedAccesstimecontrol = mergeChanges({}, changes)

  return (
    <PaperContainer>
      <ScreenToolbar
        accesstimecontrol={computedAccesstimecontrol}
        defaultBack={defaultBack}
        onSave={() =>
          createAccesstimecontrol({
            variables: {
              input: {
                user_id: params.id,
                ...normalizeData(computedAccesstimecontrol),
              },
            },
          }).then(() => setGoBack(true))
        }
        canSave={canSave}
        isSaving={loading}
        isNew
      />
      <Content>
        <DetailEdit
          isNew
          data={computedAccesstimecontrol}
          onChange={newChanges =>
            setChanges(() => ({...changes, ...newChanges}))
          }
        />
      </Content>
      {goBack && (
        <RedirectBack
          defaultBack={defaultBack}
          marker="EmployeeAccesstimecontrolBack"
        />
      )}
    </PaperContainer>
  )
}

export {NewAccesstimecontrolEntry}
