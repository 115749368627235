import React from 'react'
import gql from 'graphql-tag'
import { ListItemText, InputLabel, MenuItem } from '@mui/material'

import AutoComplete, { useAutoCompleteEndpoint } from './AutoComplete'
import moment from 'moment'

let gqlFragment = gql`
  fragment Customer on Customers {
    id
    name
    taxable
    stop_at
  }
`

function CustomerSelect({ onChange, selectedId, extraFilters, ...props }) {
  let autoProps = useAutoCompleteEndpoint({
    gqlFragment,
    selectedId,
    searchField: 'name',
    extraFilters,
  })

  return (
    <AutoComplete
      {...autoProps}
      onChange={onChange}
      itemToString={u => u.name}
      renderItem={({ item }) => (
        <ListItemText id={'customer-' + item.id} primary={item.name} />
      )}
      renderNoItems={() => <ListItemText primary={'No Customers found'} />}
      label={<InputLabel>Customer</InputLabel>}
      emptySearchFilter={e =>
        e.edge.stop_at === null || moment().isBefore(e.edge.stop_at)
      }
      hideIcon
      {...props}
    />
  )
}

export default CustomerSelect
