import React, {Component} from 'react'
import {debounce} from 'lodash'
import gql from 'graphql-tag'
import {Query} from 'react-apollo'

import ListItemText from '@mui/material/ListItemText'

import AutoComplete from './AutoComplete'
import {formatMoneyStandard} from '../utils/moneyFormatter'

let usersQuery = gql`
  query ActiveUsers(
    $limit: Int = 50
    $description: String
    $selectedId: String = ""
    $withSelected: Boolean = true
  ) {
    items: allInventoryItems(
      limit: $limit
      filters: {description: $description}
      orderBy: description_ASC
    ) {
      edges {
        edge {
          id
          description
          unit_cost
          part_number
        }
      }
    }
    selected: inventoryItems(id: $selectedId) @include(if: $withSelected) {
      id
      description
      unit_cost
      part_number
    }
  }
`
class InventorySelect extends Component {
  constructor(props) {
    super(props)

    this.onSearch = debounce(v => {
      this.setState({q: v})
    }, 100)
  }

  state = {q: ''}

  render() {
    let {onChange, selectedId, ...props} = this.props
    return (
      <Query
        query={usersQuery}
        variables={{
          limit: 50,
          selectedId,
          withSelected: !!selectedId,
          description: this.state.q,
        }}
      >
        {({data, refetch}) => (
          <AutoComplete
            onChange={onChange}
            data={data.items && data.items.edges}
            onSearch={this.onSearch}
            itemToString={u => (u ? u.description : '')}
            selectedItem={data.selected}
            placeholder="Search for Inventory Item"
            getItem={({edge}) => edge}
            renderItem={({item}) => (
              <ListItemText
                primary={
                  item.description + ' - ' + formatMoneyStandard(item.unit_cost)
                }
              />
            )}
            renderNoItems={() => (
              <ListItemText primary="No inventory item found" />
            )}
            {...props}
          />
        )}
      </Query>
    )
  }
}
export default InventorySelect
