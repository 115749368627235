import { ToastOptions, toast } from 'react-hot-toast'
import theme from '../styles/theme'

const toaster = {
  success: (message: JSX.Element | string, options: ToastOptions = {}) => {
    toast(message, {
      style: {
        background: theme.palette.success.main,
      },
      ...options,
    })
  },

  error: (message: JSX.Element | string, options: ToastOptions = {}) => {
    toast(message, {
      style: {
        background: theme.palette.danger.main,
        color: theme.palette.danger.contrastText,
      },
      ...options,
    })
  },
}

export default toaster
