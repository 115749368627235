import React, { Component } from 'react'
import styled from 'styled-components'

import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import FormGroup from '@mui/material/FormGroup'
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'

import { graphql } from 'react-apollo'
import compose from 'lodash/flowRight'
import gql from 'graphql-tag'
import ModifyQueryParams from '../../components/ModifyQueryParams'
import moment from 'moment'
import queryString from 'query-string'
import { formatMoneyStandard } from '../../utils/moneyFormatter'
import { LinearProgress, Typography } from '@mui/material'

let DashboardWrapper = styled.div`
  padding: 16px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 16px;
`

let BoldText = styled.div`
  font-weight: bold;
`

let TopCell = styled(TableCell).attrs(() => ({ classes: { root: 'root' } }))`
  &.root {
    background-color: #222;
    color: white;
  }
`
let BottomCell = styled(TableCell).attrs(() => ({ classes: { root: 'root' } }))`
  &.root {
    background-color: #f2f2f2;
  }
`
let LeftButton = styled(Button).attrs(() => ({ classes: { root: 'root' } }))`
  &.root {
    margin-left: 2em;
  }
`

class YearSelect extends Component {
  state = {
    year: moment().format('YYYY'),
  }

  render() {
    return (
      <Card>
        <CardContent>
          <FormGroup row>
            <TextField
              variant="standard"
              type="number"
              label="Year"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={e =>
                this.setState({
                  year: e.target.value,
                })
              }
              defaultValue={this.state.year} />
            <Button
              variant="contained"
              color="primary"
              onClick={() => this.props.onClick(this.state.year)}
            >
              Go
            </Button>
            <LeftButton
              variant="contained"
              onClick={() => this.props.collapseButtonOnClick()}
            >
              {this.props.collapseButtonContent()}
            </LeftButton>
          </FormGroup>
        </CardContent>
      </Card>
    );
  }
}

class StatisticsWeekly extends Component {
  state = {
    queryChanges: {},
    this_year: moment().format('YYYY'),
    open: 0,
  }
  render() {
    let { data } = this.props
    let statistics = data?.allWeeklystatistics?.edges

    return (
      <DashboardWrapper>
        <Paper>
          <Typography variant="subtitle2" display="block" style={{ opacity: 0.5 }}>Sales & job count derived from invoice subtotals. Vehicle overhead is no longer tracked. Payroll is derived from timestation.</Typography>
          <YearSelect
            onClick={year =>
              this.setState({
                queryChanges: { year: year },
              })
            }
            collapseButtonContent={f =>
              this.state.open !== 'all' ? 'Open Rows' : 'Collapse Rows'
            }
            collapseButtonOnClick={f =>
              this.state.open !== 'all'
                ? this.setState({ open: 'all' })
                : this.setState({ open: null })
            }
          // value={params.year || this.state.this_year}
          />
          {data?.loading && <LinearProgress />}
          <ModifyQueryParams query={this.state.queryChanges} />
          {statistics ? (

            <Table>
              <TableHead>
                <TableRow>
                  <TopCell>Route</TopCell>
                  <TopCell>Begin</TopCell>
                  <TopCell>End</TopCell>
                  <TopCell>Week</TopCell>
                  <TopCell>Sales</TopCell>
                  <TopCell>Number Jobs</TopCell>
                  <TopCell>Avg. Sales per Job</TopCell>
                  <TopCell>Miles</TopCell>
                  <TopCell>CPM</TopCell>
                  <TopCell>Vehicle Overhead</TopCell>
                  <TopCell>ST Pay</TopCell>
                  <TopCell>OT Pay</TopCell>
                  <TopCell>Payroll</TopCell>
                  <TopCell>DL/S</TopCell>
                  <TopCell>Man Hours</TopCell>
                  <TopCell>Sales per Man Hour</TopCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {[...statistics].reverse().map(({ edge: r }, i) => {
                  return (
                    <React.Fragment key={r.id}>
                      <TableRow>
                        <BottomCell>
                          <BoldText>
                            <Button
                              size="small"
                              onClick={f =>
                                this.state.open === i
                                  ? this.setState({ open: null })
                                  : this.setState({ open: i })
                              }
                            >
                              {this.state.open === i ? (
                                <KeyboardArrowDown />
                              ) : (
                                <KeyboardArrowRight />
                              )}
                              All
                            </Button>
                          </BoldText>
                        </BottomCell>
                        <BottomCell>
                          <BoldText>{r.begin}</BoldText>
                        </BottomCell>
                        <BottomCell>
                          <BoldText>{r.end}</BoldText>
                        </BottomCell>
                        <BottomCell align="right">
                          <BoldText>{r.week}</BoldText>
                        </BottomCell>
                        <BottomCell align="right">
                          <BoldText>{formatMoneyStandard(r.sales)}</BoldText>
                        </BottomCell>
                        <BottomCell align="right">
                          <BoldText>{r.number_jobs}</BoldText>
                        </BottomCell>
                        <BottomCell align="right">
                          <BoldText>
                            {formatMoneyStandard(r.average_sales_per_job)}
                          </BoldText>
                        </BottomCell>
                        <BottomCell align="right">
                          <BoldText>{r.miles}</BoldText>
                        </BottomCell>
                        <BottomCell align="right">
                          <BoldText>{formatMoneyStandard(r.cpm)}</BoldText>
                        </BottomCell>
                        <BottomCell align="right">
                          <BoldText>
                            {formatMoneyStandard(r.vehicle_overhead)}
                          </BoldText>
                        </BottomCell>
                        <BottomCell align="right">
                          <BoldText>
                            {formatMoneyStandard(r.standard_payroll)}
                          </BoldText>
                        </BottomCell>
                        <BottomCell align="right">
                          <BoldText>
                            {formatMoneyStandard(r.overtime_payroll)}
                          </BoldText>
                        </BottomCell>
                        <BottomCell align="right">
                          <BoldText>
                            {formatMoneyStandard(r.total_payroll)}
                          </BoldText>
                        </BottomCell>
                        <BottomCell>
                          <BoldText>{r.direct_labor_over_sales}</BoldText>
                        </BottomCell>
                        <BottomCell align="right">
                          <BoldText>{r.man_hours}</BoldText>
                        </BottomCell>
                        <BottomCell align="right">
                          <BoldText>
                            {formatMoneyStandard(r.sales_per_man_hour)}
                          </BoldText>
                        </BottomCell>
                      </TableRow>
                      {this.state.open === i || this.state.open === 'all'
                        ? r.routestatistics.map(r => (
                          <TableRow key={r.id}>
                            <TableCell>
                              <BoldText>{r.route}</BoldText>
                            </TableCell>
                            <TableCell>{r.begin}</TableCell>
                            <TableCell>{r.end}</TableCell>
                            <TableCell align="right">{r.week}</TableCell>
                            <TableCell align="right">
                              {formatMoneyStandard(r.sales)}
                            </TableCell>
                            <TableCell align="right">{r.number_jobs}</TableCell>
                            <TableCell align="right">
                              {formatMoneyStandard(r.average_sales_per_job)}
                            </TableCell>
                            <TableCell align="right">{r.miles}</TableCell>
                            <TableCell align="right">
                              {formatMoneyStandard(r.cpm)}
                            </TableCell>
                            <TableCell align="right">
                              {formatMoneyStandard(r.vehicle_overhead)}
                            </TableCell>
                            <TableCell align="right">
                              {formatMoneyStandard(r.standard_payroll)}
                            </TableCell>
                            <TableCell align="right">
                              {formatMoneyStandard(r.overtime_payroll)}
                            </TableCell>
                            <TableCell align="right">
                              {formatMoneyStandard(r.total_payroll)}
                            </TableCell>
                            <TableCell>{r.direct_labor_over_sales}</TableCell>
                            <TableCell align="right">{r.man_hours}</TableCell>
                            <TableCell align="right">
                              {formatMoneyStandard(r.sales_per_man_hour)}
                            </TableCell>
                          </TableRow>
                        ))
                        : null}
                    </React.Fragment>
                  )
                })}
              </TableBody>
            </Table>
          ) : null}
        </Paper>
      </DashboardWrapper>
    )
  }
}
let query = gql`
  query statistics($filters: WeeklystatisticsFilters = {}) {
    allWeeklystatistics(filters: $filters) {
      edges {
        edge {
          id
          begin
          end
          week
          sales
          number_jobs
          average_sales_per_job
          miles
          cpm
          vehicle_overhead
          standard_payroll
          overtime_payroll
          total_payroll
          direct_labor_over_sales
          man_hours
          sales_per_man_hour
          routestatistics {
            id
            route
            begin
            end
            week
            sales
            number_jobs
            average_sales_per_job
            miles
            cpm
            vehicle_overhead
            standard_payroll
            overtime_payroll
            total_payroll
            direct_labor_over_sales
            man_hours
            sales_per_man_hour
          }
        }
      }
    }
  }
`
export default compose(
  graphql(query, {
    options: props => {
      let params = queryString.parse(props.location.search)

      return {
        variables: { filters: { year: parseInt(params.year, 10) || moment().year() } },
      }
    },
  }),
)(StatisticsWeekly)
