import React, { Component } from 'react'
import styled from 'styled-components'
import moment from 'moment'
import _ from 'lodash'
import gql from 'graphql-tag'
import { graphql } from 'react-apollo'
import compose from 'lodash/flowRight'

import CircularProgress from '@mui/material/CircularProgress'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid'
import Avatar from '@mui/material/Avatar'
import LinearProgress from '@mui/material/LinearProgress'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import Chip from '@mui/material/Chip'

import CarIcon from '@mui/icons-material/DirectionsCar'
import UserIcon from '@mui/icons-material/Person'
import colors from '../styles/colors'

let LoadingContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`
let Loading = () => (
  <LoadingContainer>
    <CircularProgress size={200} />
  </LoadingContainer>
)

let RowContainer = styled.div`
  display: flex;
  align-items: stretch;
  padding: 2px;
  flex-wrap: wrap;
`

let RouteCard = styled(Card).attrs(() => ({ classes: { root: 'root' } }))`
  &.root {
    width: 200px;
    display: flex;
  }
`

let VehicleWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 38px;
`

let StyledChip = styled(Chip)`
  font-weight: bold;
  background-color: white;
`

let ProgressBar = styled(LinearProgress).attrs(() => ({
  classes: {
    root: 'root',
    barColorPrimary: 'colorPrimary',
    barColorSecondary: 'colorSecondary',
  },
}))`
  &.root {
    flex-grow: 1;
    height: 40px;
    background-color: #4db6ac;
  }

  & .colorPrimary {
    background-color: #009688;
  }

  & .colorSecondary {
    background-color: #009688;
  }
`

let RouteNumber = styled.span`
  font-weight: bold;
  font-size: 20px;
  margin: 6px;
  width: 20px;
  justify-content: center;
`

class Bar extends Component {
  render() {
    let value = this.props.value
    let display = this.props.display
    return (
      <div
        style={{
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexGrow: 1,
        }}
      >
        <div style={{ position: 'absolute', zIndex: '100' }}>{display}</div>
        <ProgressBar
          variant="determinate"
          value={value}
          color={this.props.color}
        />
      </div>
    )
  }
}

class RouterunsStatus extends Component {
  state = {
    queryChanges: {},
    today: moment().format('YYYY-MM-DD'),
    lastUpdate: moment(),
    now: moment(),
  }

  componentDidMount() {
    // handle polling fetching so that the variables
    // are calculated dynamically
    this.intervalID = setInterval(() => {
      let today = moment().format('YYYY-MM-DD')
      let todayPlus6 = moment()
        .add(6, 'days')
        .format('YYYY-MM-DD')

      this.props.data.refetch({ filters: { from: today, to: todayPlus6 } }).then(() => {
        // this triggers after fetch STARTS, not after it COMPLETES. whatever.
        this.setState({ lastUpdate: moment() })
      })
    }, 1000 * 10)

    this.renderTimer = setInterval(() => this.setState({ now: moment() }), 1000)
  }

  componentWillUnmount() {
    clearInterval(this.intervalID)
    clearInterval(this.renderTimer)
  }

  sortByTime(a, b) {
    let aTime = moment(a, 'HH:mm:ss')
    let bTime = moment(b, 'HH:mm:ss')

    if (aTime.isSame(bTime)) {
      return 0
    }

    return aTime.isBefore(bTime) ? -1 : 1
  }

  render() {
    let { data } = this.props
    if (!data) {
      return null
    }
    if (data.loading && !data.allRouteRuns) {
      return <Loading />
    }
    let routeruns = data?.allRouteRuns?.edges

    routeruns = _.filter(routeruns, item => {
      let users = item.edge.users
      return _.size(users) > 0
    })

    routeruns = _.groupBy(routeruns, item => {
      return item.edge.run_at
    })

    return (
      <div style={{ backgroundColor: '#ececec', overflowX: 'hidden' }}>
        <Typography variant='subtitle2' style={{ marginLeft: '1em' }}>
          last update: {this.state.lastUpdate.format('YYYY-MM-DD HH:mm:ss')} ({moment().diff(this.state.lastUpdate, 'seconds')} seconds ago)
        </Typography>
        {_.chain(routeruns)
          .map((day, daystring) => {
            return (
              <React.Fragment key={daystring}>
                <Grid container spacing={1}>
                  <Grid item xs>
                    <Typography
                      variant="h5"
                      component="h3"
                      style={{
                        margin: '15px',
                        marginTop: '12px',
                        marginBottom: '1px',
                        color: '#6d6e6d',
                      }}
                    >
                      {moment.utc(daystring).format('dddd, MMMM Do')}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid
                  container
                  spacing={2}
                  style={{ justifyContent: 'flex-start', paddingLeft: '1em' }}
                >
                  {day
                    .sort((a, b) =>
                      this.sortByTime(a.edge.start_time, b.edge.start_time),
                    )
                    .map(routerun => {
                      routerun = routerun.edge

                      let total_charge = _.sum(
                        _.map(routerun.schedules, item => {
                          return item.charge
                        }),
                      )

                      let income_capacity = routerun.income_capacity || 0

                      let cities = _.chain(routerun.cities)
                        .map(city => {
                          return city.name
                        })
                        .chunk(2)
                        .first()
                        .value()

                      if (cities) {
                        cities = cities.join(', ')
                      } else {
                        cities = ' '
                      }

                      return (
                        <Grid
                          item
                          xs
                          style={{ display: 'flex', flexGrow: '0' }}
                          key={routerun.id}
                        >
                          <RouteCard
                            style={{
                              backgroundColor: moment(
                                routerun.start_time,
                                'HH:mm:ss',
                              ).isAfter(moment('5 PM', 'h a'))
                                ? colors.grey300
                                : undefined,
                            }}
                          >
                            <CardContent
                              style={{
                                flexGrow: '1',
                                padding: '5px',
                                display: 'flex',
                                flexDirection: 'column',
                              }}
                            >
                              <Typography variant="h5">
                                <RowContainer
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    direction: 'row',
                                    alignItems: 'center',
                                    paddingTop: '1px',
                                  }}
                                >
                                  <RouteNumber>
                                    {routerun.route.name}
                                  </RouteNumber>
                                  {/* <Bar */}
                                  {/*   value={ */}
                                  {/*     total_charge > income_capacity */}
                                  {/*       ? 100 */}
                                  {/*       : (total_charge / income_capacity) * 100 */}
                                  {/*   } */}
                                  {/*   color={ */}
                                  {/*     total_charge > income_capacity */}
                                  {/*       ? 'secondary' */}
                                  {/*       : 'primary' */}
                                  {/*   } */}
                                  {/*   display={ */}
                                  {/*     <Typography> */}
                                  {/*       {moment( */}
                                  {/*         routerun.start_time, */}
                                  {/*         'HH:mm:ss', */}
                                  {/*       ).format('h:mm a')} */}
                                  {/*     </Typography> */}
                                  {/*   } */}
                                  {/* /> */}
                                  <div style={{
                                    position: 'relative',
                                    display: 'flex',
                                    // justifyContent: 'center',
                                    // alignItems: 'center',
                                    flexGrow: 1,
                                  }}>
                                    <StyledChip
                                      style={{ margin: '3px', backgroundColor: "#009688", color: 'white' }}
                                      key={''}
                                      label={`${moment(
                                        routerun.start_time,
                                        'HH:mm:ss',
                                      ).format('h:mm a')}`}
                                      color={'primary'}
                                      variant="outlined"
                                    />
                                  </div>
                                </RowContainer>
                              </Typography>
                              <Divider />
                              <RowContainer
                                style={{
                                  alignItems: 'flext-start',
                                  flexWrap: 'nowrap',
                                }}
                              >
                                <VehicleWrapper>
                                  <CarIcon style={{ marginRight: '2px' }} />
                                </VehicleWrapper>
                                <div>
                                  {routerun.assets.map((asset, i) => (
                                    <StyledChip
                                      style={{ margin: '3px' }}
                                      key={asset.id}
                                      label={`${asset.name}`}
                                      color={i === 0 ? 'primary' : undefined}
                                      variant="outlined"
                                    />
                                  ))}
                                </div>
                              </RowContainer>
                              <Divider />
                              <RowContainer
                                style={{
                                  alignItems: 'flext-start',
                                  flexWrap: 'nowrap',
                                }}
                              >
                                <VehicleWrapper>
                                  <UserIcon style={{ marginRight: '2px' }} />
                                </VehicleWrapper>
                                <div>
                                  {routerun.users.map((user, i) => (
                                    <StyledChip
                                      style={{ margin: '3px' }}
                                      key={user.id}
                                      label={`${user.first_name} ${user.last_name}`}
                                      color={i === 0 ? 'primary' : undefined}
                                      variant="outlined"
                                    />
                                  ))}
                                </div>
                              </RowContainer>
                              <Divider />
                              <RowContainer>
                                <Typography variant="body2">
                                  {routerun.notes}
                                </Typography>
                              </RowContainer>
                              <RowContainer
                                style={{
                                  display: 'flex',
                                  alignItems: 'flex-end',
                                  flexGrow: '2',
                                }}
                              >
                                <Typography variant="caption">
                                  {cities}
                                </Typography>
                              </RowContainer>
                            </CardContent>
                          </RouteCard>
                        </Grid>
                      )
                    })}
                </Grid>
                <Grid item xs>
                  <Divider
                    variant="inset"
                    style={{ margin: '10px', marginTop: '25px' }}
                  />
                </Grid>
              </React.Fragment>
            )
          })
          .value()}
      </div>
    )
  }
}

let query = gql`
  query schedules($filters: RouteRunsFilters = {}) {
    allRouteRuns(filters: $filters, limit: 900) {
      edges {
        edge {
          id
          run_at
          route_id
          route {
            id
            name
          }
          assets {
            id
            name
          }
          user_id
          notes
          vehicle_id
          start_time
          income_capacity
          total_charge
          users {
            id
            first_name
            last_name
          }
          cities {
            name
          }
          schedules {
            id
            charge
          }
        }
      }
    }
  }
`

export default compose(
  graphql(query, {
    options: props => {
      let today = moment().format('YYYY-MM-DD')
      let todayPlus6 = moment()
        .add(6, 'days')
        .format('YYYY-MM-DD')

      return {
        variables: { filters: { from: today, to: todayPlus6 } },
      }
    },
  }),
)(RouterunsStatus)
