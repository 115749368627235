import React, { Component } from 'react'
import styled from 'styled-components'
import {
  ClickAwayListener,
  Collapse,
  Fab as FAB,
  Fade,
  Popper,
} from '@mui/material'

let DialButtons = styled.div`
  width: 56px;
  display: flex;
  flex-direction: column;
  align-items: center;
`
let DialButton = styled.div`
  margin-top: 16px;
`
// let TransparentPopover = styled(Popover)`
//   background-color: transparent !important;
//   box-shadow: none !important;
//   overflow-y: visible !important;
// `
let TransparentPopover = styled(Popper)`
  background-color: transparent !important;
  box-shadow: none !important;
  overflow-y: visible !important;
`

class SpeedDialFAB extends Component {
  state = { open: false, anchorEl: null }
  handleTouchTap = e => {
    e.preventDefault()
    this.setState({ open: !this.state.open, anchorEl: e.currentTarget })
  }
  handleRequestClose = () => this.setState({ open: false })
  render() {
    let { dialButtons, ...props } = this.props
    let { open, anchorEl } = this.state

    return (
      <ClickAwayListener onClickAway={this.handleRequestClose}>
        <div>
          <FAB
            {...props}
            onClick={this.handleTouchTap}
            ref={r => {
              this.FAB = r
            }}
            id={'speedDial'}
          />
          <TransparentPopover
            open={open}
            anchorEl={anchorEl}
            // onclo={this.handleRequestClose}
            transition
          >
            {({ TransitionProps }) => (
              <Fade {...TransitionProps}>
                <DialButtons>
                  {dialButtons.map((b, i) => (
                    <DialButton
                      onClick={() => this.handleRequestClose()}
                      children={React.cloneElement(b)}
                      key={i}
                    />
                  ))}
                </DialButtons>
              </Fade>
            )}
          </TransparentPopover>
        </div>
      </ClickAwayListener>
    )
  }
}

export default SpeedDialFAB
