import React from 'react'
import styled from 'styled-components'
import {useQuery, useMutation} from 'react-apollo'
import gql from 'graphql-tag'

import {TextField, Button, Typography} from '@mui/material'

import {Toolbar} from '../AppHandler'
import ToolbarGroup from '../components/ToolbarGroup'
import {Space} from '../components/Layout'
import LoadingButton from '../components/LoadingButton'

let pricingFrag = gql`
  fragment PricingDetail on Prices {
    id
    twostory_rate
    twostoryinout_rate
    threestory_rate
    threestoryinout_rate
    constructioncleanup_rate
    constructioncleanupinout_rate
    casement_rate
    casementinout_rate
    casementstorm_rate
    casementstorminout_rate
    frenchcasement_rate
    frenchcasementinout_rate
    slider_rate
    sliderinout_rate
    sliderstorm_rate
    sliderstorminout_rate
    doublehung_rate
    doublehunginout_rate
    doublehungstorm_rate
    doublehungstorminout_rate
    doublehunghalffrench_rate
    doublehunghalffrenchinout_rate
    doublehunghalffrenchstorm_rate
    doublehunghalffrenchstorminout_rate
    doublehungfrench_rate
    doublehungfrenchinout_rate
    doublehungfrenchstorm_rate
    doublehungfrenchstorminout_rate
    transome_rate
    transomeinout_rate
    arches_rate
    archesinout_rate
    sidelight_rate
    sidelightinout_rate
    fixedverysmall_rate
    fixedverysmallinout_rate
    fixedsmall_rate
    fixedsmallinout_rate
    fixedmedium_rate
    fixedmediuminout_rate
    fixedlarge_rate
    fixedlargeinout_rate
    fixedxlarge_rate
    fixedxlargeinout_rate
    slidingglassdoor_rate
    slidingglassdoorinout_rate
    slidingglassdoorfrench_rate
    slidingglassdoorfrenchinout_rate
    regulardoor_rate
    regulardoorinout_rate
    halffrenchdoor_rate
    halffrenchdoorinout_rate
    fullfrenchdoor_rate
    fullfrenchdoorinout_rate
    skylight_rate
    skylightinout_rate
    jalice_rate
    jaliceinout_rate
    chandelieroctagon1tier_rate
    chandelieroctagon2tier_rate
    chandelieroctagon3tier_rate
    mirror_rate
    fandusting_rate
    grids_rate
    wipingframe_rate
    screenwashing_rate
    screenhandling_rate
    chemical_rate
    trackcleaning_rate
    markup_rate
    seniordiscount_rate
  }
`
let userQuery = gql`
  query currentUser {
    currentUser {
      id
      first_name
      last_name
    }
  }
`
let pricingQuery = gql`
  query pricing {
    prices(id: "1") {
      ...PricingDetail
    }
  }
  ${pricingFrag}
`
let pricingMut = gql`
  mutation update($input: UpdatePricesInput) {
    updatePrices(input: $input) {
      prices {
        ...PricingDetail
      }
    }
  }
  ${pricingFrag}
`

let Container = styled.div`
  margin: 24px;
`

function useAuth(compareFn) {
  let [status, setStatus] = React.useState('loading')
  let {data, error, loading} = useQuery(userQuery)
  React.useEffect(() => {
    if (error) {
      setStatus('error')
    } else if (loading) {
      setStatus('loading')
    } else if (data && data.currentUser) {
      setStatus('denied')
    }
  }, [loading, data, error])
  return {status}
}

export default function Screen() {
  let [changes, setChanges] = React.useState({})
  let {data, error, loading} = useQuery(pricingQuery)
  let [update, {loading: isSaving}] = useMutation(pricingMut)
  let {status: authStatus} = useAuth('ooga booga')

  function handleSave() {
    update({variables: {input: {id: '1', ...changes}}}).then(() => {
      setChanges({})
    })
  }

  if (error || authStatus === 'error') {
    return <div>Oops, something went wrong</div>
  }
  if (loading || !data || authStatus === 'loading') {
    return <div>loading</div>
  }
  if (authStatus !== 'authorized') {
    return (
      <div>
        <Typography variant="h2">Permission denied</Typography>
      </div>
    )
  }
  let {id, __typename, ...priceEntries} = data.prices
  return (
    <Container>
      <Toolbar>
        <ToolbarGroup first>
          <Typography variant="h6">Edit Pricing</Typography>
        </ToolbarGroup>
        <ToolbarGroup />
        <ToolbarGroup last>
          <LoadingButton
            variant="contained"
            onClick={handleSave}
            loading={isSaving}
            disabled={Object.keys(changes).length === 0}
          >
            Save
          </LoadingButton>
        </ToolbarGroup>
      </Toolbar>
      {Object.entries(priceEntries).map(([k, v]) => (
        <div>
          <TextField
            type="number"
            variant="filled"
            label={k}
            value={changes[k] !== undefined ? changes[k] : v}
            onChange={e => {
              let num = parseFloat(e.target.value)
              setChanges(c => ({...c, [k]: num}))
            }}
          />
          <Space size={2} />
        </div>
      ))}
    </Container>
  )
}
