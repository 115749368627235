import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import {fetchAPI} from '../schema/utils'
import {BrokenImage, Photo} from '@mui/icons-material'
import colors from '../styles/colors'

let FallbackImage = styled.div`
  background-color: ${({theme}) => theme.grey300};
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: opacity ease-in 1000ms;
  opacity: 100;
  display: flex;
  align-items: center;
  justify-content: center;
`
let ImageWrapper = styled.div`
  position: relative;
`
let PhotoIcon = styled(Photo)`
  width: 66%;
  height: 66%;
  color: ${({theme}) => theme.grey400};
`

let BrokenIcon = styled(BrokenImage)`
  width: 66%;
  height: 66%;
  color: ${({theme}) => theme.grey400};
`

const miniImgStyle = `
& {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
}
`
const hoverStyle = `
&:hover {
  opacity: .7;
  cursor: pointer;
}

`
export const MiniPRImage = styled(Image)`
  ${miniImgStyle}
  ${props => props.hover && hoverStyle}
  ${props => props.selected && `border: 2px ${colors.orange500} solid;`}
`

// There is a non-zero chance that a particular size of photo isn't generated.
// If we are concerned that SOMETHING is always shown, you should create a fallback
// system derived from all possible sizes of the photo. I, for one, do not care.
// ~ Matt Krell, 2025-02-19
export default function Image({src, children, fallbackSrc, ...props}) {
  let [blob, setBlob] = useState(null)
  let [loading, setLoading] = useState(false)
  let [error, setError] = useState(false)
  let [curSrc, setCurSrc] = useState(src)

  useEffect(() => {
    console.log(src)
    if (src) {
      let srcUrl = new URL(curSrc)
      setLoading(true)
      fetchAPI({
        url: srcUrl.pathname + srcUrl.search,
        options: {mode: 'cors', cache: 'force-cache'},
        prefixUrl: false,
      })
        .then(res => res.blob())
        .then(blob => {
          setBlob(URL.createObjectURL(blob))
          setLoading(false)
        })
    }
  }, [curSrc])

  if (typeof children === 'function') {
    return children({src: blob, loading})
  } else {
    return (
      <ImageWrapper style={props.style} className={props.className}>
        {!loading && (
          <img
            src={blob}
            alt="something interesting"
            onError={() => {
              if (fallbackSrc && fallbackSrc !== curSrc) {
                setCurSrc(fallbackSrc)
              } else {
                setError(true)
              }
            }}
            {...props}
          />
        )}
        {(loading || !blob) && (
          <FallbackImage
            className={props.className}
            style={{...(props.style || {}), ...(loading ? {} : {opacity: '0'})}}
          >
            <PhotoIcon />
          </FallbackImage>
        )}
        {error && (
          <FallbackImage
            className={props.className}
            style={{...(props.style || {})}}
          >
            <BrokenIcon />
          </FallbackImage>
        )}
      </ImageWrapper>
    )
  }
}
