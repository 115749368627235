import PropTypes from 'prop-types'
import React, { useState } from 'react'
import styled from 'styled-components'
import urlJoin from 'url-join'
import moment from 'moment'

import IconButton from '@mui/material/IconButton'
import MenuItem from '@mui/material/MenuItem'
import Tooltip from '@mui/material/Tooltip'

import BarChart from '@mui/icons-material/BarChart'
import Print from '@mui/icons-material/Print'
import CommunicationEmail from '@mui/icons-material/Email'
import { Button, Typography, Menu, Popover, Box, FormControlLabel, Checkbox } from '@mui/material'
import { Schedule, ArrowDropDown, Business, Store, Person, FilterList, ViewColumn } from '@mui/icons-material'
import { Space } from '../../components/Layout'

import { Toolbar } from '../../AppHandler'
import { BILL, COD, PURCHASING_CARD } from '../../constants/TermCodes'
import Routes from '../../constants/Routes'
import { Redirect, Link } from '../../pr-router'
import ToolbarGroup from '../../components/ToolbarGroup'
import SearchBox from '../../components/SearchBox'
import SelectMenu from '../../components/ToolbarDropDownMenu'
import {
  COMMERCIAL,
  RESIDENTIAL,
  LARGE_COMMERCIAL,
} from '../../constants/CustomerTypes'
import { capitalize, debounce } from 'lodash'

let SearchBoxGroup = styled(ToolbarGroup)`
  flex-grow: 1;
`

let OrderSelect = ({ value = 'balances', ...props }) => (
  <SelectMenu
    value={value}
    label="Sort By"
    {...props}
    style={{ marginRight: '1em' }}
  >
    <MenuItem value="balances">Balances</MenuItem>
    <MenuItem value="name">Name</MenuItem>
  </SelectMenu>
)

let TermsSelect = ({ value = 'all', ...props }) => (
  <SelectMenu
    value={value}
    label="Terms"
    {...props}
    style={{ marginRight: '1em' }}
  >
    <MenuItem value="all">All Terms</MenuItem>
    <MenuItem value={BILL}>Bill</MenuItem>
    <MenuItem value={COD}>C.O.D.</MenuItem>
    <MenuItem value={PURCHASING_CARD}>Purchasing Card</MenuItem>
  </SelectMenu>
)

let RouteSelect = ({ value = 'all', ...props }) => (
  <SelectMenu
    value={value}
    label="Route"
    {...props}
    style={{ marginRight: '1em' }}
  >
    <MenuItem value={'all'}>All Routes</MenuItem>
    {Routes.map(route => (
      <MenuItem key={route.id} value={route.id.toString()}>
        {' '}
        {'Route ' + route.id.toString()}
      </MenuItem>
    ))}
  </SelectMenu>
)

let TypeSelect = ({ value = 'all', ...props }) => (
  <SelectMenu
    value={value}
    label="Type"
    {...props}
    style={{ marginRight: '1em' }}
  >
    <MenuItem value="all">All Types</MenuItem>
    <MenuItem value={COMMERCIAL}>Commercial</MenuItem>
    <MenuItem value={RESIDENTIAL}>Residential</MenuItem>
    <MenuItem value={LARGE_COMMERCIAL}>Large Commercial</MenuItem>
  </SelectMenu>
)

let PausedSelect = ({ value = 'all', ...props }) => (
  <SelectMenu
    value={value}
    label="Service Status"
    {...props}
    style={{ marginRight: '1em' }}
  >
    <MenuItem value="all">All</MenuItem>
    <MenuItem value={'true'}>Paused</MenuItem>
    <MenuItem value={'stopped'}>Stopped</MenuItem>
    <MenuItem value={'false'}>Servicing</MenuItem>
  </SelectMenu>
)

let CreditSelect = ({ value = 'all', ...props }) => (
  <SelectMenu
    value={value}
    label="Credit"
    {...props}
    style={{ marginRight: '1em' }}
  >
    <MenuItem value="all">All</MenuItem>
    <MenuItem value={'true'}>Has Credit</MenuItem>
    <MenuItem value={'false'}>No Credit</MenuItem>
  </SelectMenu>
)
let InvoiceStatusSelect = ({ value = 'all', ...props }) => (
  <SelectMenu
    value={value}
    label="Invoice Status"
    {...props}
    style={{ marginRight: '1em' }}
  >
    <MenuItem value="all">All</MenuItem>
    <MenuItem value={'OPEN'}>OPEN</MenuItem>
  </SelectMenu>
)

let SearchBySelect = ({ value = 'name', ...props }) => (
  <SelectMenu
    value={value}
    label="Search By"
    {...props}
    style={{ marginLeft: '.5em' }}
  >
    <MenuItem value="name">Name</MenuItem>
    <MenuItem value="balance">Balance</MenuItem>
  </SelectMenu>
)

const ArSummaryLink = props => (
  <Link to="/ar/summary" {...props} color="action" />
)

const createFilterSelector = key =>
(function(e) {
  this.setState({ [key]: e.target.value })
  this.updateQuery({ [key]: e.target.value })
})

const createColumnHandler = (key) =>
(function(e) {
  let checked = e.target.checked
  let newState = this.props.hideCols || []

  if (checked) {
    newState.splice(newState.indexOf(key), 1)
  } else {
    newState.push(key)
  }

  this.updateQuery({ hideCols: newState.join(',') })
})

const createStupidFilterSelector = key =>
(function(value) {
  this.setState({ [key]: value })
  this.updateQuery({ [key]: value })
})

const DisplaySwitcher = ({ tab, onChange }) => {
  let [anchorEl, setAnchorEl] = useState(null)

  return (
    <>
      <Button
        onClick={e => {
          setAnchorEl(e.currentTarget)
        }}
        component="nav"
        style={{ textTransform: 'none' }}
      >
        {tab === 'principals' ? (
          <>
            <Business />
            <Space inline />
            <Typography variant="h6">AR Aging (Principals)</Typography>
          </>
        ) : (
          <>
            <Person />
            <Space inline />
            <Typography variant="h6">AR Aging (Customers)</Typography>
          </>
        )}
        <Space inline />
        <ArrowDropDown />
      </Button>
      <Menu
        id="araging-nav-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => {
          setAnchorEl(null)
        }}
      >
        <MenuItem
          selected={tab === 'principals'}
          onClick={() => {
            setAnchorEl(null)
            onChange('principals')
          }}
        >
          <Business />
          <Space inline />
          AR Aging (Principals)
        </MenuItem>
        <MenuItem
          selected={tab === 'customers'}
          onClick={() => {
            setAnchorEl(null)
            onChange('customers')
          }}
        >
          <Person />
          <Space inline />
          AR Aging (Customers)
        </MenuItem>
      </Menu>
    </>
  )
}

function getFilterDescription({
  searchTerms,
  searchType,
  searchRoute,
  searchPaused,
  searchCredit,
  searchInvoiceStatus
}) {
  let getLabel = (v, label) =>
    v === 'all' || v === undefined || v === null || v === '' ? '' : label
  let filters = [
    getLabel(searchTerms, "Terms"),
    getLabel(searchType, "Type"),
    getLabel(searchRoute, "Route"),
    getLabel(searchPaused, "Paused"),
    getLabel(searchCredit, "Credit"),
    getLabel(searchInvoiceStatus, "Invoice Status"),
  ]
  let showFilters = !!filters.join('')
  return showFilters
    ? `Filtering by: ${filters.filter(v => !!v).join('; ')}`
    : ''
}

class ArToolbar extends React.Component {
  static propTypes = {
    searchQuery: PropTypes.string,
    searchStatus: PropTypes.string,
    searchType: PropTypes.string,
    searchBy: PropTypes.string,
    searchTerms: PropTypes.string,
    searchRoute: PropTypes.string,
    searchPaused: PropTypes.string,
    searchCredit: PropTypes.string,
    searchInvoiceStatus: PropTypes.string,
    onQueryChange: PropTypes.func,
    onRefetch: PropTypes.func,
    hideCols: PropTypes.arrayOf(['thirtyDay', 'sixtyDay', 'ninetyDay', 'overNinetyDay', 'credit'])
  }
  state = {
    searchFocused: true,
    addDialogIsOpen: false,
    newCustomerName: false,
    filterEl: null,
    colEl: null,
  }
  componentDidUpdate(prevProps) {
    if (this.props.searchBy !== prevProps.searchBy) this.refs.searchBox?.focus()
  }

  updateQuery(queryChanges) {
    const { onQueryChange } = this.props

    if (onQueryChange) onQueryChange(queryChanges)
  }

  onSearch(query) {
    this.updateQuery({ q: query })
  }

  debouncedRefetch = debounce(this.props.onRefetch, 0)

  onSelectSearchTerms = createFilterSelector('searchTerms').bind(this)
  onSelectSearchRoute = createFilterSelector('searchRoute').bind(this)
  onSelectSearchOrder = createFilterSelector('searchOrder').bind(this)
  onSelectSearchType = createFilterSelector('searchType').bind(this)
  onSelectSearchDisplay = createStupidFilterSelector('display').bind(this)
  onSelectSearchBy = createFilterSelector('searchBy').bind(this)
  onSelectSearchPaused = createFilterSelector('searchPaused').bind(this)
  onSelectSearchCredit = createFilterSelector('searchCredit').bind(this)
  onSelectSearchInvoiceStatus = createFilterSelector('searchInvoiceStatus').bind(this)
  handleCreateCustomer = name => this.setState({ newCustomerName: name })

  onHideCredit = createColumnHandler('credit').bind(this)
  onHideThirtyDay = createColumnHandler('thirtyDay').bind(this)
  onHideSixtyDay = createColumnHandler('sixtyDay').bind(this)
  onHideNinetyDay = createColumnHandler('ninetyDay').bind(this)
  onHideOverNinetyDay = createColumnHandler('overNinetyDay').bind(this)

  render() {
    const {
      searchBy = 'name',
      searchQuery,
      searchTerms,
      searchRoute,
      searchOrder,
      searchType,
      searchDisplay,
      searchPaused,
      searchCredit,
      searchInvoiceStatus,
      hideCols,
      onRefetch,
      match,
    } = this.props
    let { newCustomerName, filterEl, colEl } = this.state
    let filtersOpen = !!filterEl
    let colOpen = !!colEl

    // There's probably a cooler way to do this but meh
    let _linkQueryString = () => {
      let params = []
      if (searchQuery !== '') params.push('q=' + searchQuery)
      if (searchTerms !== 'all') params.push('searchTerms=' + searchTerms)
      if (searchRoute !== 'all') params.push('searchRoute=' + searchRoute)
      if (searchType !== 'all') params.push('searchType=' + searchType)
      if (searchCredit !== 'all') params.push('searchCredit=' + searchCredit)
      if (searchInvoiceStatus !== 'all') params.push('searchInvoiceStatus=' + searchInvoiceStatus)
      return params.join('&')
    }

    return newCustomerName ? (
      <Redirect
        to={{
          pathname: urlJoin(match.url, 'add'),
          state: {
            newCustomer: { name: newCustomerName },
            backLocation: {
              pathname: this.props.location.pathname,
              query: this.props.location.query,
            },
          },
        }}
      />
    ) : (
      <Toolbar>
        <DisplaySwitcher
          tab={searchDisplay}
          onChange={val => this.onSelectSearchDisplay(val)}
        />
        <SearchBoxGroup>
          <SearchBox
            ref="searchBox"
            style={{ flexGrow: 1 }}
            value={searchQuery}
            onSearch={this.onSearch.bind(this)}
          />
          <SearchBySelect
            value={searchBy}
            onChange={props => {
              this.onSelectSearchBy(props)
              this.debouncedRefetch()
            }}
          />
        </SearchBoxGroup>
        <ToolbarGroup style={{ justifyContent: 'flex-start' }}>

          {/* Column control */}
          <IconButton onClick={el => this.setState({ colEl: el.currentTarget })} size="large">
            <ViewColumn />
          </IconButton>
          <Popover
            open={colOpen}
            anchorEl={colEl}
            onClose={() => this.setState({ colEl: null })}
            anchorOrigin={{ vertical: 'bottom' }}
          >
            <Box p={2} style={{ maxWidth: '650px' }}>
              <FormControlLabel label='Credit' control={<Checkbox checked={!hideCols?.includes('credit')} onClick={this.onHideCredit} />} />
              <FormControlLabel label='1 - 30' control={<Checkbox checked={!hideCols?.includes('thirtyDay')} onClick={this.onHideThirtyDay} />} />
              <FormControlLabel label='31 - 60' control={<Checkbox checked={!hideCols?.includes('sixtyDay')} onClick={this.onHideSixtyDay} />} />
              <FormControlLabel label='61 - 90' control={<Checkbox checked={!hideCols?.includes('ninetyDay')} onClick={this.onHideNinetyDay} />} />
              <FormControlLabel label='> 90' control={<Checkbox checked={!hideCols?.includes('overNinetyDay')} onClick={this.onHideOverNinetyDay} />} />
            </Box>
          </Popover>
          <Typography variant="caption">
            {hideCols?.length ? `${hideCols.length} cols hidden` : ''}
          </Typography>

          {/* Filters */}
          <IconButton onClick={el => this.setState({ filterEl: el.currentTarget })} size="large" id='filters'>
            <FilterList />
          </IconButton>
          <Popover
            open={filtersOpen}
            anchorEl={filterEl}
            onClose={() => this.setState({ filterEl: null })}
            anchorOrigin={{ vertical: 'bottom' }}
          >
            <Box p={2} style={{ maxWidth: '650px' }} id='filters-menu'>
              <OrderSelect
                value={searchOrder}
                onChange={this.onSelectSearchOrder}
              />
              <TermsSelect
                value={searchTerms}
                onChange={this.onSelectSearchTerms}
              />
              <RouteSelect
                value={searchRoute}
                onChange={this.onSelectSearchRoute}
              />
              <TypeSelect value={searchType} onChange={this.onSelectSearchType} />
              {searchDisplay === 'customers' && (
                <PausedSelect
                  value={searchPaused}
                  onChange={props => {
                    this.onSelectSearchPaused(props)
                    this.debouncedRefetch()
                  }}
                />
              )}
              {searchDisplay === 'customers' && (
                <CreditSelect
                  value={searchCredit}
                  onChange={props => {
                    this.onSelectSearchCredit(props)
                    this.debouncedRefetch()
                  }}
                />
              )}
              {searchDisplay === 'customers' && (
                <InvoiceStatusSelect
                  value={searchInvoiceStatus}
                  onChange={props => {
                    this.onSelectSearchInvoiceStatus(props)
                    this.debouncedRefetch()
                  }}
                />
              )}
            </Box>
          </Popover>
          <Typography variant="caption" style={{ marginRight: "2em" }}>
            Order by: &nbsp;
            {capitalize(searchOrder) || 'Balances'}
          </Typography>
          <Typography variant="caption">
            {getFilterDescription({
              searchOrder,
              searchTerms,
              searchType,
              searchRoute,
              searchPaused,
              searchCredit,
              searchInvoiceStatus
            })}
          </Typography>
        </ToolbarGroup>
        <ToolbarGroup last={true}>
          <ArSummaryLink>
            <Tooltip title="AR Summary">
              <IconButton tooltip="AR Summary" color="default" size="large">
                <BarChart />
              </IconButton>
            </Tooltip>
          </ArSummaryLink>
          <Link to={'/ar/print?' + _linkQueryString()}>
            <Tooltip title="Print statements">
              <IconButton type="submit" tooltip="Print" size="large">
                <Print />
              </IconButton>
            </Tooltip>
          </Link>
          <Link to={'/ar/email?' + _linkQueryString()}>
            <Tooltip title="Email statements">
              <IconButton type="submit" tooltip="Email" size="large">
                <CommunicationEmail />
              </IconButton>
            </Tooltip>
          </Link>
        </ToolbarGroup>
      </Toolbar>
    );
  }
}
export default ArToolbar
