import PropTypes from 'prop-types'
import React from 'react'
import gql from 'graphql-tag'
import {Column} from 'react-virtualized'
import moment from 'moment'
import {Link} from 'react-router-dom'

import Typography from '@mui/material/Typography'
import MuiLink from '@mui/material/Link'

import TableList, {TrashableTableList} from '../../components/Table/TableList'
import {formatMoneyStandard} from '../../utils/moneyFormatter'
import CustomerAvatar from '../../components/CustomerAvatar'
import {Chip, IconButton, TableSortLabel, useMediaQuery} from '@mui/material'
import {Restore, RestoreFromTrash} from '@mui/icons-material'

const sheet = {
  cellContainer: {display: 'flex', alignItems: 'center', padding: '5px 20px'},
  photoWrapper: {marginRight: '20px'},
}

let sortIsActive = (order, sortKey, isDefault = false) => {
  if (order === undefined && isDefault) {
    return true
  }

  let activeSortKey = order.replace(/_ASC|_DESC/g, '')

  return activeSortKey === sortKey
}

let sortDirection = (order, isActive, defaultDir = 'asc') => {
  if (order === undefined || !isActive) {
    return defaultDir
  }

  let activeSortDirection = order.match(/_ASC|_DESC/gm, '')[0]

  return activeSortDirection === '_ASC' ? 'asc' : 'desc'
}

export const fragments = {
  customer: gql`
    fragment CustomerDetail on Customers {
      id
      name
      city_name
      street1
      region
      start_at
      stop_at
      contract_start_at
      contract_stop_at
      created_at
      customertype {
        id
        type
      }
      city {
        id
        name
      }
      balances {
        id
        balance
      }
      photo {
        id
        thumb_url
      }
      credit_items {
        id
        amount
      }
      stopreason {
        id
        reason
      }
      user_id
      user {
        id
        first_name
        last_name
      }
      deleted_at
      salesstages {
        id
        name
      }
    }
  `,
}

let CustomersList = ({
  customers = [],
  pageInfo,
  loading,
  onQueryChange,
  order,
  onInfiniteLoad,
  restore,
}) => {
  // static propTypes = {customers: PropTypes.array}
  // static defaultProps = {customers: []}

  let getRowCount = () => {
    if (!pageInfo) {
      return 9999
    }
    return pageInfo.total
  }

  const createOrderSelector = (key, defaultDir = '_ASC') =>
    function() {
      let value = ''
      if (order !== key + '_ASC' && order !== key + '_DESC') {
        value = key + defaultDir
      } else {
        value = order === key + '_ASC' ? key + '_DESC' : key + '_ASC'
      }
      onQueryChange({order: value})
    }

  let onNameOrder = createOrderSelector('name')
  let onCityOrder = createOrderSelector('city_name')
  let onStartOrder = createOrderSelector('start_at', '_DESC')
  let onStopOrder = createOrderSelector('stop_at', '_DESC')
  let onConStartOrder = createOrderSelector('contract_start_at', '_DESC')
  let onConStopOrder = createOrderSelector('contract_stop_at', '_DESC')
  let onCreditOrder = createOrderSelector('credit', '_DESC')
  let onCreatedOrder = createOrderSelector('created_at', '_DESC')

  let smallLaptopWidth = useMediaQuery('(min-width:1440px)')
  let phoneWidth = useMediaQuery('(min-width:1280px)')

  return (
    <TrashableTableList
      data={customers}
      infinite
      loadMoreRows={onInfiniteLoad}
      rowCount={pageInfo ? pageInfo.total : 9999}
      loading={loading}
      key={'blah' + String(phoneWidth) + String(smallLaptopWidth)}
      rowClassName={({index}) => {
        let classnames = ''
        let sortedData = customers
          ? customers?.sort((a, b) => (b.cursor > a.cursor ? -1 : 1))
          : []
        let rowData = sortedData && sortedData[index] && sortedData[index].edge

        if (rowData) {
          classnames += rowData.deleted_at ? ' trashed' : ''
        }

        return classnames
      }}
    >
      <Column
        dataKey="name"
        headerRenderer={({label}) => (
          <TableSortLabel
            active={sortIsActive(order, 'name')}
            direction={sortDirection(order, sortIsActive(order, 'name'))}
            onClick={onNameOrder}
          >
            {label}
          </TableSortLabel>
        )}
        cellRenderer={props =>
          _renderNameColumn({
            ...props,
            isLeadView: !props?.rowData?.schedules?.length,
            restore,
          })
        }
        label="Name"
        flexGrow={1}
        width={0}
      />
      <Column
        dataKey="city"
        headerRenderer={({label}) => (
          <TableSortLabel
            active={sortIsActive(order, 'city_name')}
            direction={sortDirection(order, sortIsActive(order, 'city_name'))}
            onClick={onCityOrder}
          >
            {label}
          </TableSortLabel>
        )}
        cellRenderer={_renderCityColumn}
        label="Address"
        flexGrow={1}
        width={1}
        maxWidth={250}
      />
      <Column
        dataKey="region"
        label="State"
        width={40}
        headerRenderer={({label}) => (
          <div style={{textAlign: 'center'}}>{label}</div>
        )}
        cellRenderer={({cellData}) => (
          <Typography
            variant="body1"
            display="block"
            style={{textAlign: 'center'}}
          >
            {cellData}
          </Typography>
        )}
      />
      <Column
        dataKey="salesstages"
        cellRenderer={({cellData}) =>
          cellData?.map(stage => <Chip key={stage.id} label={stage.name} />)
        }
        label="Sales Stage"
        flexGrow={1}
        width={1}
        maxWidth={180}
      />
      <Column
        dataKey="balances"
        label="Balance"
        width={75}
        headerRenderer={({label}) => (
          <div style={{textAlign: 'right'}}>{label}</div>
        )}
        cellRenderer={({cellData, rowData}) => (
          <Link
            display="block"
            variant="body1"
            style={{textAlign: 'right'}}
            to={`/customers/${rowData && rowData.id}/transactions`}
          >
            {cellData && formatMoneyStandard(cellData.balance)}
          </Link>
        )}
      />
      {phoneWidth && (
        <Column
          dataKey="credit"
          label="Credit"
          width={75}
          headerRenderer={({label}) => (
            <TableSortLabel
              style={{float: 'right'}}
              active={sortIsActive(order, 'credit')}
              direction={sortDirection(order, sortIsActive(order, 'credit'))}
              onClick={onCreditOrder}
            >
              {label}
            </TableSortLabel>
          )}
          cellRenderer={({cellData, rowData}) => (
            <Typography
              display="block"
              variant="body1"
              style={{textAlign: 'right'}}
            >
              {rowData &&
                formatMoneyStandard(
                  rowData.credit_items.reduce(
                    (carry, ci) => ci.amount + carry,
                    0,
                  ),
                )}
            </Typography>
          )}
        />
      )}
      <Column
        dataKey="created_at"
        label="Created"
        width={130}
        headerRenderer={({label}) => (
          <TableSortLabel
            active={sortIsActive(order, 'created_at')}
            direction={sortDirection(
              order,
              sortIsActive(order, 'created_at'),
              'desc',
            )}
            onClick={onCreatedOrder}
          >
            {label}
          </TableSortLabel>
        )}
        cellRenderer={({cellData}) => (
          <Typography
            varaint="body1"
            display="block"
            style={{textAlign: 'right'}}
          >
            {moment.utc(cellData).format('ll')}
          </Typography>
        )}
      />
      <Column
        dataKey="service_status"
        label="Service Status"
        width={220}
        headerRenderer={({label}) => <div>{label}</div>}
        cellRenderer={({rowData}) => (
          <Typography
            varaint="body1"
            display="block"
            style={{textAlign: 'right'}}
          >
            {rowData?.stop_at
              ? (rowData?.stopreason?.reason || 'Stopped') +
                ' (' +
                moment.utc(rowData?.stop_at).format('ll') +
                ')'
              : !rowData?.schedules?.length
              ? `Lead (${rowData?.user?.first_name} ${rowData?.user?.last_name})`
              : `Started ${moment.utc(rowData?.start_at).format('ll')}`}
          </Typography>
        )}
      />
    </TrashableTableList>
  )
}

let _renderNameColumn = function({
  rowData,
  rowIndex,
  isScrolling,
  isLeadView,
  restore,
}) {
  return (
    rowData && (
      <Link to={!rowData.deleted_at ? `/customers/${rowData.id}` : null}>
        <div style={sheet.cellContainer}>
          <div style={sheet.photoWrapper}>
            {rowData.deleted_at ? (
              <IconButton onClick={() => restore({id: rowData.id})}>
                {' '}
                <RestoreFromTrash />
              </IconButton>
            ) : (
              <CustomerAvatar
                type={rowData.customertype.type}
                url={rowData.photo && rowData.photo.thumb_url}
              />
            )}
          </div>
          <div>
            <Typography
              display="block"
              variant="h6"
              style={{
                fontStyle: isLeadView && 'italic',
                fontWeight: isLeadView && 'unset',
                textDecoration: !!rowData.stop_at && 'line-through',
              }}
            >
              {rowData.name}
            </Typography>
          </div>
        </div>
      </Link>
    )
  )
}

let _renderCityColumn = ({rowData}) =>
  rowData && (
    <div>
      <Typography display="block">
        {rowData.city && rowData.city.name}
      </Typography>
      <Typography variant="caption" color="textSecondary" display="block">
        {rowData.street1}
      </Typography>
    </div>
  )

export default CustomersList
