import React, {useEffect, useState} from 'react'
import {Link, Route, Switch} from 'react-router-dom'
import * as Yup from 'yup'

import Button from '@mui/material/Button'
import {Toolbar} from '../../AppHandler'
import {Space, Spacing} from '../../components/Layout'
import {
  Paper,
  TableHead,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
  Tooltip,
  TableSortLabel,
  MenuItem,
  Menu,
} from '@mui/material'
import Typography from '@mui/material/Typography'
import {
  Assignment,
  Receipt,
  RadioButtonUnchecked,
  CheckCircle,
  Block,
  ErrorOutline,
  MonetizationOn,
  MoneyOff,
  Edit,
  ArrowDropDown,
  RestoreFromTrash,
} from '@mui/icons-material'
import _ from 'lodash'
import {DatePicker} from '@mui/lab'
import styled from 'styled-components'
import {
  getWorkorderIcon,
  PillBoxInvoice,
  PillBoxWorkorder,
} from '../Schedules/scheduleComponents'
import {formatMoneyStandard} from '../../utils/moneyFormatter'
import moment from 'moment'
import RouteSelect, {RouteSelectStatic} from '../../components/RouteSelect'
import colors from '../../styles/colors'
import {StaticDatePicker} from '@mui/x-date-pickers'

let DashboardWrapper = styled.div`
  padding: 8px;
  grid-column-gap: 8px;
  display: flex;
  flex-direction: row;
`

let DenseTableCell = styled(TableCell)`
  padding-left: 0.5em;
  padding-right: 0.5em;
`

let TinyCell = styled(DenseTableCell)`
  width: 50px;
`

let SlightlyTinyCell = styled(DenseTableCell)`
  width: 100px;
`

let SmallCell = styled(DenseTableCell)`
  width: 150px;
`

let WoCell = styled(DenseTableCell)`
  width: 200px;
  margin: 1em;
`

let FlexCellContents = styled.div`
  display: flex;
  align-items: center;
`

// Yes, I know about MUI's grid. But it was causing the buttons to overflow the parent card for some reason.
let ButtonGroupContainer = styled.div`
  display: flex;
`

let ButtonGroupColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 0.25em;
`

let OpenIcon = () => <RadioButtonUnchecked />
let CompleteIcon = () => <CheckCircle />
let VoidIcon = () => <Block />
let InactiveIcon = () => <ErrorOutline /> //<CheckCircleOutline />
let PaidIcon = () => <MonetizationOn />
let BaddebtIcon = () => <MoneyOff />

let _icon = status => {
  switch (status) {
    case 'OPEN':
      return <OpenIcon />
    case 'COMPLETE':
      return <CompleteIcon />
    case 'VOID':
      return <VoidIcon />
    case 'INACTIVE':
      return <InactiveIcon />
    case 'PAID':
      return <PaidIcon />
    case 'BAD_DEBT':
      return <BaddebtIcon />
  }
}

let Invoice = ({inv}) => (
  <Tooltip title={inv.status}>
    <a
      href={'/old/database/invoice.php?InvoiceNumber=' + inv.id}
      style={{color: colors.grey900}}
    >
      <PillBoxInvoice>
        <FlexCellContents>
          <Receipt />
          INV {inv.id}
          {_icon(inv.status)}
        </FlexCellContents>
      </PillBoxInvoice>
    </a>
  </Tooltip>
)

let Workorder = ({wo, po_needed}) => {
  let editable = wo.status === 'OPEN'
  return (
    <Tooltip title={wo.status}>
      <PillBoxWorkorder
        style={{cursor: !editable && 'auto'}}
        multiday={wo.multiday}
      >
        <FlexCellContents>
          {getWorkorderIcon(po_needed)}
          WO {wo.id}
          {_icon(wo.status)}
        </FlexCellContents>
      </PillBoxWorkorder>
    </Tooltip>
  )
}

let _getKeysFromRow = row => {
  if (!row) return {woKey: null, inv: null}

  let {workorder, invoice, schedule} = row

  let woKey = workorder
    ? `wo-${workorder.id}`
    : !!schedule
    ? `wo-s-${schedule.id}`
    : null
  let invKey = invoice ? `inv-${invoice.id}` : null

  return {woKey, invKey}
}

let _checkAllWorkorders = (val, displayRows = []) => {
  return displayRows.reduce((carry, row) => {
    let {woKey} = _getKeysFromRow(row)
    carry[woKey] = val
    return carry
  }, {})
}

let _checkAllInvoices = (val, displayRows = []) => {
  return displayRows.reduce((carry, row) => {
    let {invKey} = _getKeysFromRow(row)
    carry[invKey] = val
    return carry
  }, {})
}

let _checkedKeys = values => {
  let checked = []
  for (let key in values) {
    if (values[key]) {
      checked.push(key)
    }
  }
  return checked
}

let Voided = ({
  data,
  date,
  onChangeDate,
  routeId,
  onChangeRoute,
  onRestoreAction,
  disableActions,
}) => {
  let [checks, setChecks] = useState({})
  let [sortBy, setSortBy] = useState(undefined)
  let [sortDir, setSortDir] = useState('asc')

  let [editAnchorEl, setEditAnchorEl] = useState(null)

  let day =
    data && data.run && data.run.edges[0] ? data.run.edges[0].edge : false
  let workorders =
    data && data.wos
      ? data.wos.edges.map(e => e.edge).filter(w => w.status === 'VOID')
      : []
  let invoices =
    data && data.invs
      ? data.invs.edges.map(e => e.edge).filter(w => w.status === 'VOID')
      : []

  let rows = []

  workorders.map(wo =>
    rows.push({
      workorder: wo,
      invoice: null,
      schedule: null,
      customer: wo.customer,
      sequence: wo.sequence,
    }),
  )
  invoices.map(i =>
    rows.push({
      workorder: null,
      invoice: i,
      schedule: null,
      sequence: 10000,
      customer: i.customer,
    }),
  )

  let allWos = true
  for (let k in _checkAllWorkorders(true, rows)) {
    if (!checks[k]) {
      allWos = false
      break
    }
  }
  let allInvs = true
  for (let k in _checkAllInvoices(true, rows)) {
    if (!checks[k]) {
      allInvs = false
      break
    }
  }

  let _onSort = col => {
    if (sortBy !== col) {
      setSortBy(col)
    } else {
      switch (sortDir) {
        case 'desc':
          setSortBy(undefined)
          setSortDir('asc')
          break
        case 'asc':
          setSortDir('desc')
          break
      }
    }
  }

  let _getCharge = r =>
    !!r.invoice
      ? r.invoice.charge
      : r.workorder.workorderitems.reduce((carry, woi) => carry + woi.charge, 0)

  let _sortFunction = (a, b) => {
    switch (sortBy) {
      case 'name':
        return sortDir === 'asc'
          ? a.customer.name.localeCompare(b.customer.name)
          : b.customer.name.localeCompare(a.customer.name)
      case 'charge':
        let aCharge = _getCharge(a)
        let bCharge = _getCharge(b)
        return sortDir === 'asc' ? aCharge - bCharge : bCharge - aCharge
      case 'workorder':
        let aId = a.workorder ? a.workorder.id : 0
        let bId = b.workorder ? b.workorder.id : 0
        return sortDir === 'asc' ? aId - bId : bId - aId
      case 'invoice':
        let aInvId = a.invoice ? a.invoice.id : 0
        let bInvId = b.invoice ? b.invoice.id : 0
        return sortDir === 'asc' ? aInvId - bInvId : bInvId - aInvId
      case 'terms':
        return sortDir === 'asc'
          ? a.customer.terms.id - b.customer.terms.id
          : b.customer.terms.id - a.customer.terms.id
      case 'sequence':
      default:
        return (a, b) => a.sequence - b.sequence
    }
  }
  console.log(data)

  return (
    <DashboardWrapper>
      <Paper>
        <StaticDatePicker value={date} onChange={onChangeDate} />
        <div style={{margin: '10px'}}>
          <RouteSelectStatic
            value={routeId}
            onChange={e => onChangeRoute({id: e.target.value})}
            showAll={false}
          />
        </div>
        <Space size={2} />
        <ButtonGroupContainer>
          <ButtonGroupColumn></ButtonGroupColumn>

          <ButtonGroupColumn></ButtonGroupColumn>
        </ButtonGroupContainer>
      </Paper>
      {data && (
        <Paper style={{overflow: 'overlay', background: colors.grey200}}>
          <Typography variant="body2" style={{margin: '10px 0px 0px 10px'}}>
            Week {day.week}
          </Typography>
          <div style={{display: 'flex'}}>
            <Space inline size={3} />
            <Button
              onClick={e => {
                setEditAnchorEl(e.currentTarget)
              }}
              component="nav"
              style={{textTransform: 'none'}}
            >
              <Edit />
              <Space inline />
              <Typography>Edit</Typography>
              <Space inline />
              <ArrowDropDown />
            </Button>
            <Menu
              id="manage-workorders-edit-menu"
              anchorEl={editAnchorEl}
              open={Boolean(editAnchorEl)}
              onClose={() => {
                setEditAnchorEl(null)
              }}
            >
              <MenuItem
                disabled={disableActions}
                onClick={() => {
                  onRestoreAction(_checkedKeys(checks))
                  setChecks({})
                }}
              >
                <RestoreFromTrash /> Restore
              </MenuItem>
            </Menu>
          </div>
          <Table
            style={{tableLayout: 'fixed', minWidth: '1200px'}}
            size="small"
            key={sortBy + sortDir}
          >
            <TableHead>
              <TableRow>
                {/* <TinyCell /> */}
                <WoCell colSpan={1}>
                  <Checkbox
                    checked={allWos}
                    onClick={e =>
                      setChecks({
                        ...checks,
                        ..._checkAllWorkorders(e.target.checked, rows),
                        ..._checkAllInvoices(e.target.checked, rows),
                      })
                    }
                  />
                  <TableSortLabel
                    active={sortBy === 'workorder'}
                    direction={sortDir}
                    onClick={() => _onSort('workorder')}
                  >
                    Workorders
                  </TableSortLabel>
                </WoCell>
                <TableCell>
                  <TableSortLabel
                    active={sortBy === 'name'}
                    direction={sortDir}
                    onClick={() => _onSort('name')}
                  >
                    Customer Name
                  </TableSortLabel>
                </TableCell>
                <SlightlyTinyCell>
                  <TableSortLabel
                    active={sortBy === 'charge'}
                    direction={sortDir}
                    onClick={() => _onSort('charge')}
                  >
                    Charge
                  </TableSortLabel>
                </SlightlyTinyCell>
                <SlightlyTinyCell>Balance</SlightlyTinyCell>
                <SlightlyTinyCell>Credit</SlightlyTinyCell>
                <SlightlyTinyCell>
                  <TableSortLabel
                    active={sortBy === 'terms'}
                    direction={sortDir}
                    onClick={() => _onSort('terms')}
                  >
                    Terms
                  </TableSortLabel>
                </SlightlyTinyCell>
                <SmallCell>Last Serviced</SmallCell>
                <SmallCell>Next Service</SmallCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .sort(_sortFunction)
                .map(({workorder, invoice, schedule, customer, sequence}) => {
                  let {woKey, invKey} = _getKeysFromRow({
                    workorder,
                    invoice,
                    schedule,
                  })

                  return (
                    <TableRow key={woKey + invKey}>
                      <DenseTableCell>
                        <FlexCellContents>
                          {!!woKey && (
                            <Checkbox
                              name={woKey}
                              checked={!!checks[woKey]}
                              onClick={e =>
                                setChecks({
                                  ...checks,
                                  [woKey]: e.target.checked,
                                })
                              }
                            />
                          )}
                          {workorder && (
                            <Workorder
                              wo={workorder}
                              po_needed={customer && customer.po_needed}
                            />
                          )}
                          {!!invKey && (
                            <Checkbox
                              name={invKey}
                              checked={!!checks[invKey]}
                              onClick={e =>
                                setChecks({
                                  ...checks,
                                  [invKey]: e.target.checked,
                                })
                              }
                            />
                          )}
                          {invoice && <Invoice inv={invoice} />}
                        </FlexCellContents>
                      </DenseTableCell>
                      <DenseTableCell>
                        <Link to={`/customers/${customer.id}`}>
                          {customer.name}
                        </Link>
                      </DenseTableCell>
                      <DenseTableCell>
                        {formatMoneyStandard(
                          _getCharge({schedule, invoice, workorder}),
                        )}
                      </DenseTableCell>
                      <DenseTableCell>
                        <Link to={`/customers/${customer.id}/transactions`}>
                          {formatMoneyStandard(customer.balances.balance)}
                        </Link>
                      </DenseTableCell>
                      <DenseTableCell>
                        {formatMoneyStandard(
                          customer.credit_items.reduce(
                            (carry, ci) => carry + ci.amount,
                            0,
                          ),
                        )}
                      </DenseTableCell>
                      <DenseTableCell>
                        {customer.terms.id === '6'
                          ? 'P.C.'
                          : customer.terms.terms}
                      </DenseTableCell>
                      <DenseTableCell>
                        {customer && customer.last_routerun && (
                          <Link
                            to={`/schedules/daily?date=${moment(
                              customer.last_routerun.run_at,
                            ).format('YYYY-MM-DD')}&routes=${
                              customer.last_routerun.route_id
                            }`}
                          >
                            {moment(customer.last_routerun.run_at).format(
                              'ddd, MMM D',
                            )}
                          </Link>
                        )}
                      </DenseTableCell>
                      <DenseTableCell>
                        {customer && customer.next_routerun && (
                          <Link
                            to={`/schedules/daily?date=${moment(
                              customer.next_routerun.run_at,
                            ).format('YYYY-MM-DD')}&routes=${
                              customer.next_routerun.route_id
                            }`}
                          >
                            {moment(customer.next_routerun.run_at).format(
                              'ddd, MMM D',
                            )}
                          </Link>
                        )}
                      </DenseTableCell>
                    </TableRow>
                  )
                })}
            </TableBody>
          </Table>
        </Paper>
      )}
    </DashboardWrapper>
  )
}

export default Voided
