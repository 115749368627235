import React from 'react'
import styled from 'styled-components'
import gql from 'graphql-tag'
import {Query} from 'react-apollo'
import moment from 'moment'

import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import NavigationArrowBack from '@mui/icons-material/ArrowBack'

import {Toolbar} from '../../AppHandler'
import ToolbarGroup from '../../components/ToolbarGroup'
import ToolbarMegaTitle from '../../components/ToolbarMegaTitle'
import {NavigateBack} from '../../components/Navigator'

let SummaryWrapper = styled.div`
  page-break-after: always;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
`
let StatsRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
let StatWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 350px;
  margin: 40px;
  padding: 24px;
  border: solid grey 1px;
  border-radius: 20px;
`
let Stat = ({value, secondValue, label}) => (
  <StatWrapper>
    <Typography variant="h3" gutterBottom>
      {value}
    </Typography>
    <Typography variant="h3" gutterBottom>
      {secondValue}
    </Typography>
    <Typography variant="h4" gutterBottom>
      {label}
    </Typography>
  </StatWrapper>
)

let query = gql`
  query UserSummaries {
    users: allUsers(cursor: "-1", limit: 100, filters: {status: active}) {
      edges {
        edge {
          id
          first_name
          last_name
          attendance_statistics {
            pto_hours
            unpaid_hours
          }
        }
      }
    }
  }
`

let hrsToDays = hrs => {
  let rounded = parseFloat(hrs / 8).toFixed(1)
  if (rounded.split('.').pop() === '0') {
    rounded = parseInt(rounded)
  }
  return rounded
}

let PrintSummary = () => (
  <Query query={query} fetchPolicy="cache-and-network">
    {({data, error, loading}) => (
      <div>
        <Toolbar>
          <ToolbarGroup first>
            <NavigateBack defaultBack="employees" marker="EmployeeSummaryPrint">
              <Button>
                <NavigationArrowBack />
              </Button>
            </NavigateBack>
            <ToolbarMegaTitle>Employee PTO Summaries</ToolbarMegaTitle>
          </ToolbarGroup>
          <ToolbarGroup />
          <ToolbarGroup last />
        </Toolbar>
        {loading ? (
          <SummaryWrapper>
            <Typography variant="h2">Loading...</Typography>
          </SummaryWrapper>
        ) : error ? (
          <SummaryWrapper>
            <Typography variant="h2">Oops. Something went wrong.</Typography>
          </SummaryWrapper>
        ) : (
          data.users &&
          data.users.edges &&
          data.users.edges.map(
            ({
              edge: {
                id,
                first_name,
                last_name,
                attendance_statistics: {pto_hours, unpaid_hours},
              },
            }) => (
              <React.Fragment key={id}>
                <SummaryWrapper>
                  <Typography variant="h2" gutterBottom>
                    {first_name} {last_name}
                  </Typography>
                  <Typography variant="subtitle1" gutterBottom>
                    As of {moment().format('MMMM Do YYYY')}
                  </Typography>
                  <StatsRow>
                    <Stat
                      value={`${hrsToDays(pto_hours)} days`}
                      secondValue={`(${pto_hours} hrs.)`}
                      label="PTO"
                    />
                    <Stat
                      value={`${hrsToDays(unpaid_hours)} days`}
                      secondValue={`(${unpaid_hours} hrs.)`}
                      label="Unpaid Time Off"
                    />
                  </StatsRow>
                </SummaryWrapper>
                <Divider />
              </React.Fragment>
            ),
          )
        )}
      </div>
    )}
  </Query>
)

export default PrintSummary
