import PropTypes from 'prop-types'
import styled from 'styled-components'

let BaseText = styled.span`
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  display: ${p => (p.display || p.block ? 'block' : 'inline')};
  color: ${p => (p.contrast === 'light' ? 'rgba(255,255,255,0.87)' : 'rgba(0,0,0,0.87)')};
`
BaseText.propTypes = {
  contrast: PropTypes.oneOf(['light', 'dark']),
  block: PropTypes.bool,
  display: PropTypes.string,
}

export const Display1 = styled(BaseText)`
  font-size: 34px; line-height: 40px;
  color: ${p => (p.contrast === 'light' ? 'rgba(255,255,255,0.87)' : 'rgba(0,0,0,0.54)')};
`

export const Headline = styled(BaseText)`
  font-size: 24px; line-height: 32px;
`

export const Title = styled(BaseText)`
  font-size: 20px; font-weight: 500; letter-spacing: 0.005em;
`

export const Subhead = styled(BaseText)`
  font-size: 16px; line-height: 24px; letter-spacing: 0.01em;
`

export const Body1 = styled(BaseText)`
  font-size: 14px; line-height: 24px; letter-spacing: 0.01em;
`

export const Body2 = styled(Body1)`
  font-weight: 500;
`

export const Caption = styled(BaseText)`
  font-size: 12px; line-height: 24px; letter-spacing: 0.02em;
  color: ${p => (p.contrast === 'light' ? 'rgba(255,255,255,0.54)' : 'rgba(0,0,0,0.54)')};
`

