import React, { useState, useRef } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import gql from 'graphql-tag'
import moment from 'moment'
import queryString from 'query-string'
import { Formik, Field } from 'formik'
import * as Yup from 'yup'
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  InfoWindow,
  MarkerClusterer,
} from '@react-google-maps/api'

import {
  Typography,
  Input,
  InputAdornment,
  Grid,
  MenuItem,
  Chip,
  TextField as MuiTextField,
  Select,
  OutlinedInput,
  FormControl,
  InputLabel,
  Paper,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  Switch,
  FormControlLabel,
} from '@mui/material'
import {
  CalendarToday as CalendarIcon,
  Event as RepeatOneIcon,
  Loop as RepeatIcon,
  ArrowRight,
  Forward,
} from '@mui/icons-material'
import { DatePicker as MuiDatePicker } from '@mui/lab'
import SelectMenu from '../../components/ToolbarDropDownMenu'

import { Toolbar } from '../../AppHandler'
import ToolbarGroup from '../../components/ToolbarGroup'
import { Space } from '../../components/Layout'
import RouteSelectDynamic from '../../components/RouteSelect'
import ModifyQueryParams from '../../components/ModifyQueryParams'
import { TextField, AutoCompleteField, DatePicker } from '../../components/forms'
import { formatMoneyStandard } from '../../utils/moneyFormatter'
import Routes from '../../constants/Routes'
import { useQuery } from 'react-query'
import { fetchAPI } from '../../schema/utils'

let ChipRoute = styled.div`
  background-color: ${({ theme }) => theme.palette.secondary.main};
  color: ${({ theme }) => theme.palette.primary.contrastText};
  border-radius: 8px;
  padding: 0em;
  text-align: center;
  margin-right: 0.1em;
  min-width: 30px;
`

let RouteSelectMulti = ({ routes, selectedRoutes, onSelect }) => {
  let children = Routes.map(r => (
    <MenuItem value={r.id} key={r.id}>
      {r.id}
    </MenuItem>
  ))

  return (
    <FormControl variant="outlined" size="small" margin="dense">
      <InputLabel variant="outlined" size="small" margin="dense">
        Routes
      </InputLabel>
      <Select
        label={'Routes'}
        name="routes"
        value={selectedRoutes}
        multiple
        input={<OutlinedInput size="small" margin="dense" />}
        onChange={onSelect}
        renderValue={selected => {
          return (
            <div style={{ display: 'flex' }}>
              {selected
                .sort((a, b) => a - b)
                .map(v => {
                  let r = routes.find(
                    r => Number.parseInt(r.id) === Number.parseInt(v),
                  )
                  return r && <ChipRoute key={v}>{r.id}</ChipRoute>
                })}
            </div>
          )
        }}
        style={{ minWidth: '100px' }}
        children={children}
      />
    </FormControl>
  )
}

function RouteMap({ markers = [], selected, onSelect }) {
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: 'AIzaSyAUqKr6nXvuRt93mP6VWjo_V0k2_V_yduc',
  })
  let [infoWindow, setInfoWindow] = useState(null)
  let markersRef = useRef({})
  let clustersRef = useRef({})

  let onLoad = React.useCallback(
    map => {
      let latlng = markers
        // .filter(m => m.position.lat !== 0 && m.position.lng !== 0)
        .map(m => new window.google.maps.LatLng(m.position.lat, m.position.lng))
      let latlngBounds = new window.google.maps.LatLngBounds()
      latlng.forEach(ll => latlngBounds.extend(ll))

      map.fitBounds(latlngBounds)
    },
    [markers],
  )

  let renderMap = () => {
    return (
      <GoogleMap
        zoom={8}
        mapContainerStyle={{ width: '100%', height: '800px', flex: 1 }}
        onLoad={onLoad}
      >
        {infoWindow && infoWindow.id !== selected && (
          <InfoWindow anchor={infoWindow.anchor} position={infoWindow.position} options={{ pixelOffset: !!infoWindow.position && new window.google.maps.Size(0, -30) }}>
            {infoWindow.infoContent}
          </InfoWindow>
        )}
        <MarkerClusterer
          // averageCenter
          // enableRetinaIcons
          // gridSize={60}
          onClusteringBegin={(clusterer) => {
            setInfoWindow(null)
          }}
          onMouseOver={(cluster) => {
            let id = `${cluster.getCenter().lat()},${cluster.getCenter().lng()}`
            let markerIds = cluster.getMarkers().map(m => m.getTitle())
            let rawData = markers.filter(m => markerIds.includes(String(m.id))).map(m => m.rawData)
            let content = (<>
              <Typography variant="h6">
                {rawData.sort((a, b) => b.unique_customers - a.unique_customers)[0].city_name}
              </Typography>
              <Typography variant='body1'>
                {formatMoneyStandard(rawData.reduce((acc, d) => acc + Number.parseFloat(d.totalAmount), 0))}
              </Typography>
              <Typography variant='body1'>
                {rawData.reduce((acc, d) => acc + Number.parseInt(d.unique_customers), 0)} customers
              </Typography>
            </>)

            if (id !== selected && infoWindow?.id !== id) {
              setInfoWindow({
                id,
                position: cluster.getCenter(),
                offset: -10,
                infoContent: content,
              })
            }
          }}
          onMouseOut={e => {
            setInfoWindow(null)
          }}
        >
          {(clusterer) => {
            return markers.map(({ id, infoContent, infoContentPreview, ...m }) => {
              let mRef = markersRef.current[id]
              return (
                <>
                  <Marker
                    key={id}
                    {...m}
                    onLoad={m => {
                      markersRef.current[id] = m
                    }}
                    onClick={() => onSelect({ id })}
                    onMouseOver={e => {
                      let currentMarker =
                        markersRef.current && markersRef.current[id]
                      if (currentMarker && id !== selected) {
                        setInfoWindow({
                          id,
                          anchor: currentMarker,
                          infoContent: infoContentPreview,
                        })
                      }
                    }}
                    onMouseOut={e => {
                      setInfoWindow(null)
                    }}
                    clusterer={clusterer}
                    title={String(id)}
                  />
                  {id === selected && mRef && (
                    <InfoWindow
                      anchor={mRef}
                      onCloseClick={() => onSelect({ id: null })}
                    >
                      {infoContent}
                    </InfoWindow>
                  )}
                </>
              )
            })
          }}
        </MarkerClusterer>
      </GoogleMap>
    )
  }

  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>
  }

  return isLoaded ? renderMap() : <div>Loading...</div>
}

const DataList = ({ data }) => {
  return (<div
    style={{
      margin: '1.5em'
    }}
  >
    <Paper>
      <Table>
        <TableHead>
          <TableCell>City</TableCell>
          <TableCell>Total</TableCell>
        </TableHead>
        <TableBody>
          {
            data.map((d, i) => (
              <TableRow key={i}>
                <TableCell>{d.city_name}</TableCell>
                <TableCell>{formatMoneyStandard(d.totalAmount)}</TableCell>
              </TableRow>
            ))
          }
        </TableBody>
      </Table>
    </Paper>
  </div>)
}

function Heatmap({ location }) {
  let {
    routes = '',
    end_date = moment.utc().format('YYYY-MM-DD'),
    start_date = moment.utc().subtract(1, 'year').format('YYYY-MM-DD'),
    customertype_id = '',
    map = '',
  } = queryString.parse(location.search)

  let [queryChanges, setQueryChanges] = React.useState({})
  let [openDialog, setOpenDialog] = React.useState(false)
  let [selectedMarker, setSelectedMarker] = React.useState(null)

  let { data, isLoading: loading, isError: error } = useQuery(
    ['invoicing_heatmap', routes, end_date, start_date, customertype_id],
    () => fetchAPI({ url: `/reports/invoicing_heatmap?routes=${routes}&end_date=${end_date}&start_date=${start_date}&customertype_id=${customertype_id}` }).then(r => r.json())
  )

  let routeIds = routes.split(',')

  let [selected, setSelected] = React.useState({})

  // Reset selected when page changes
  React.useEffect(() => {
    setSelected({})
  }, [routes, end_date, start_date])

  function handleCheck(id) {
    setSelected(s => ({ ...s, [id]: !s[id] }))
  }

  return (
    <>
      <Toolbar>
        <ModifyQueryParams query={{ ...queryChanges }} />
        <ToolbarGroup first>
          <Typography variant="h6">Invoicing Geography</Typography>
          <Space inline size={2} />
          <Space inline />
        </ToolbarGroup>
        <ToolbarGroup last>
          <MuiDatePicker
            label="Start Date"
            value={moment.utc(start_date)}
            inputFormat="ddd. MMMM D, YYYY"
            onChange={d =>
              setQueryChanges(c => ({ ...c, start_date: d.format('YYYY-MM-DD') }))
            }
            renderInput={props => (
              <MuiTextField {...props} margin="dense" size="small" />
            )}
            autoOk
          />
          <Space inline />
          <Forward />
          <Space inline />
          <MuiDatePicker
            label="End Date"
            value={moment.utc(end_date)}
            inputFormat="ddd. MMMM D, YYYY"
            onChange={d =>
              setQueryChanges(c => ({ ...c, end_date: d.format('YYYY-MM-DD') }))
            }
            renderInput={props => (
              <MuiTextField {...props} margin="dense" size="small" />
            )}
            autoOk
          />
          <Space inline />
          <RouteSelectMulti
            selectedRoutes={routeIds
              .filter(r => r !== '')
              .map(r => Number.parseInt(r))}
            routes={Routes}
            onSelect={e =>
              setQueryChanges(c => ({
                ...c,
                routes: e.target.value.length ? e.target.value.join(',') : '',
              }))
            }
          />
          <Space inline />
          <SelectMenu
            label="Customer Type"
            value={customertype_id}
            onChange={e => setQueryChanges(c => ({ ...c, customertype_id: e.target.value }))}
            style={{ minWidth: 150 }}
          >
            <MenuItem value="">All</MenuItem>
            <MenuItem key={1} value={1}>
              Commercial
            </MenuItem>
            <MenuItem key={2} value={2}>
              Residential
            </MenuItem>
            <MenuItem key={3} value={3}>
              Large Commercial
            </MenuItem>
          </SelectMenu>
          <Space inline sizePx={20} />
          <FormControlLabel label="Show Map" control={
            <Switch checked={map === 'true'}
              color='secondary'
              onChange={e => setQueryChanges(c => ({ ...c, map: e.target.checked ? 'true' : 'false' }))} />
          } />
        </ToolbarGroup>
      </Toolbar>

      {loading ? (
        <div>Loading...</div>
      ) : error ? (
        <div>Error...</div>
      ) : (
        map === 'true' ?
          <RouteMap
            markers={data.map((s, i) => {
              let content = (
                <>
                  <Typography variant="h6">
                    {s.city_name}
                  </Typography>
                  <Typography variant="body1">
                    {formatMoneyStandard(s.totalAmount)}
                  </Typography>
                  <Typography variant="body1">{s.unique_customers} customers</Typography>
                  {/* <Typography variant="body1">{s.customer_ids}</Typography> */}
                </>
              )
              return {
                position: {
                  lat: s.avg_latitude,
                  lng: s.avg_longitude,
                },
                label: s.unique_customers,
                title: s.city_name,
                id: i,
                infoContentPreview: <div>{content}</div>,
                infoContent: <div>{content}</div>,
                rawData: s
              }
            })}
            selected={selectedMarker}
            onSelect={({ id }) => {
              setSelectedMarker(id)
              if (!id) {
                setSelected({})
              } else {
                handleCheck(id)
              }
            }}
          /> :
          <DataList data={data} />
      )}
    </>
  )
}

export default Heatmap
