import React, {useState} from 'react'

import gql from 'graphql-tag'
import {Column} from 'react-virtualized'
import {Link} from 'react-router-dom'
import styled from 'styled-components'
import moment from 'moment'
import queryString from 'query-string'

import Chip from '@mui/material/Chip'
import {DatePicker} from '@mui/lab'
import Button from '@mui/material/Button'
import Input from '@mui/material/Input'
import InputAdornment from '@mui/material/InputAdornment'
import Switch from '@mui/material/Switch'
import CircularProgress from '@mui/material/CircularProgress'

import CalendarIcon from '@mui/icons-material/CalendarToday'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'
import AccessTimeIcon from '@mui/icons-material/AccessTime'

import TableList from '../../components/Table/TableList'
import {Body1, Subhead} from '../../components/Typography'
import AvatarBorder from '../../components/AvatarBorder'
import PaginationContainer from '../../components/PaginationContainer'
import urlJoin from '../../utils/url-join'
import {Toolbar} from '../../AppHandler'
import ToolbarGroup from '../../components/ToolbarGroup'
import ToolbarMegaTitle from '../../components/ToolbarMegaTitle'
import ModifyQueryParams from '../../components/ModifyQueryParams'
import {Space} from '../../components/Layout'
import {
  PaperToolbar,
  DetailRow,
  ToolbarRight,
  ToolbarLeft,
} from '../../components/Toolbar'
import Add from '@mui/icons-material/Add'
import {useMutation} from 'react-apollo'
import {TextField} from '@mui/material'

let DescWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0 16px;
  & > * {
    margin-right: 8px;
  }
`
const SUNDAY = 0
const MONDAY = 1
const TUESDAY = 2
const WEDNESDAY = 3
const THURSDAY = 4
const FRIDAY = 5
const SATURDAY = 6
const WEEKENDS = 7
const WEEKDAYS = 8

let DayOfWeekCol = function({cellData, rowData}) {
  let dayOfWeekRef = {
    0: 'Sunday',
    1: 'Monday',
    2: 'Tuesday',
    3: 'Wednesday',
    4: 'Thursday',
    5: 'Friday',
    6: 'Saturday',
    7: 'Weekends',
    8: 'Weekdays',
  }

  return (
    <DescWrapper>
      <AccessTimeIcon />
      <Subhead display="block">
        {cellData == WEEKENDS
          ? 'Weekends'
          : cellData == WEEKDAYS
          ? 'Weekdays'
          : dayOfWeekRef[cellData]}
      </Subhead>
    </DescWrapper>
  )
}

let GreenChip = styled(Chip)`
  background-color: ${({theme}) => theme.palette.success.main};
`

let DisplayTime = ({rowData}) => (
  <React.Fragment>
    {rowData.start == 0 && rowData.end == 2400 ? (
      <GreenChip label="All Day" />
    ) : (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Chip
          label={moment(String(rowData.start).padStart(4, 0), 'HHmm').format(
            'LT',
          )}
        />
        <ArrowRightAltIcon />
        <Chip
          label={moment(String(rowData.end).padStart(4, 0), 'HHmm').format(
            'LT',
          )}
        />
      </div>
    )}
  </React.Fragment>
)

let OverrideLabel = styled(Subhead)`
  font-weight: bold;
  color: ${({theme}) => theme.palette.danger.main};
`
let DangerDatePicker = styled(DatePicker)`
  color: ${({theme}) => theme.palette.danger.main};
`

let Override = ({active, onChange, until}) => {
  return (
    <>
      <Switch
        color="secondary"
        onChange={e => {
          !active ? onChange(until) : onChange(null)
        }}
        checked={active}
      />
      {!active ? (
        <Subhead>Grant full access</Subhead>
      ) : (
        <>
          <OverrideLabel>Grant full access until: </OverrideLabel>
          <Space inline sizePx="10" />
          <DangerDatePicker
            value={until}
            onChange={d => onChange(d)}
            renderInput={props => <TextField {...props} variant="filled" />}
          />
        </>
      )}
    </>
  )
}

let RenderCol = ({cellData, rowData}) => (
  <DescWrapper>
    <Subhead display="block">
      <DisplayTime rowData={rowData} />
    </Subhead>
  </DescWrapper>
)

function AccessTimeControlList({items = [], onInfiniteLoad, pageInfo, match}) {
  function getRowCount() {
    if (!pageInfo) {
      return 9999
    }
    return pageInfo.total
  }

  return (
    <TableList
      data={items}
      infinite
      loadMoreRows={onInfiniteLoad}
      rowCount={getRowCount()}
      wrapRow={({children, rowData}) => (
        <Link to={urlJoin`${match.url}${rowData['id']}`} key={rowData.id}>
          {children}
        </Link>
      )}
    >
      <Column
        dataKey="day_of_week"
        headerRenderer={({label}) => (
          <div style={{textAlign: 'center'}}>{label}</div>
        )}
        cellRenderer={data => <DayOfWeekCol {...data} />}
        label="Day of Week"
        flexGrow={1}
        width={0}
      />
      <Column
        dataKey="start"
        headerRenderer={({label}) => (
          <div style={{textAlign: 'center'}}>{label}</div>
        )}
        cellRenderer={data => <RenderCol {...data} />}
        label="Time"
        flexGrow={1}
        width={0}
      />
    </TableList>
  )
}

let controlsQuery = gql`
  query controls($cursor: String = "1", $limit: Int = 50, $userId: String!) {
    controls: allAccesstimecontrolsByUser(
      cursor: $cursor
      limit: $limit
      userId: $userId
    ) {
      edges {
        edge {
          id
          user_id
          day_of_week
          start
          end
        }
        cursor
      }
      pageInfo {
        next
        current
        prev
        count
        total
      }
    }
  }
`

let userMutation = gql`
  mutation createAccesstimecontrolsEntryQuery($input: UpdateUsersInput) {
    updateUsers(input: $input) {
      users {
        id
        username
        auth_until
      }
    }
  }
`

let TinyCirclularProgress = styled(CircularProgress)`
  top: 50%;
  left: 50%;
`

function AccessTimeControls({location, match, employee}) {
  let [updateUser, {loading, result}] = useMutation(userMutation)

  let authUntil =
    employee && employee.auth_until
      ? moment.utc(employee.auth_until)
      : moment().add(1, 'day')

  return (
    <React.Fragment>
      <PaperToolbar>
        <DetailRow>
          <ToolbarLeft>
            {loading ? (
              <TinyCirclularProgress color="primary" size={24} />
            ) : (
              <Space inline sizePx="24" />
            )}
            <Override
              active={employee && employee.auth_until ? true : false}
              until={authUntil}
              onChange={d =>
                updateUser({
                  variables: {
                    input: {
                      id: employee.id,
                      auth_until: d ? moment.utc(d).format('YYYY-MM-DD') : null,
                    },
                  },
                })
              }
            />
          </ToolbarLeft>
          <ToolbarRight>
            <Link to={urlJoin`${match.url}new`}>
              <Button variant="contained" color="secondary">
                <Add />
              </Button>
            </Link>
          </ToolbarRight>
        </DetailRow>
      </PaperToolbar>
      <PaginationContainer
        query={controlsQuery}
        variables={{
          cursor: '-1',
          userId: match.params.id,
        }}
        children={function({controls, loadMore}) {
          return (
            <AccessTimeControlList
              items={controls && controls.edges}
              pageInfo={controls && controls.pageInfo}
              onInfiniteLoad={loadMore}
              match={match}
            />
          )
        }}
      />
    </React.Fragment>
  )
}

export default AccessTimeControls
