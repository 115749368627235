import React, {useState} from 'react'
import {useMutation, useQuery} from 'react-query'
import {gql} from 'graphql-tag' // Added gql import
import styled from 'styled-components' // Added styled-components import
import {prgql} from '../../utils/graphql'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  TableFooter,
  Grid,
  LinearProgress,
  CircularProgress,
  Fab,
} from '@mui/material'
import {Toolbar} from '../../AppHandler' // Added Toolbar import
import {Space} from '../../components/Layout'
import {Add, SettingsApplications} from '@mui/icons-material'
import {Link, Switch} from 'react-router-dom'
import {Route} from '../../pr-router'
import Integrations from './Integrations'
import {NavTabs, TabLink} from '../../components/NavTabs'
import EmailTemplates, {EmailTemplate, NewEmailTemplate} from './EmailTemplates'
import Roles, {NewRole, Role} from './Roles'
import {useAuth} from '../../security/auth'
import RouterunTemplates from './RouterunTemplate'
import Routes, {NewRoute, Route as RouteSingle} from './Routes'
import Taxitems, {NewTaxitem, Taxitem} from './Taxitem'
import Saleitems, {NewSaleitem, Saleitem} from './Saleitem'
import Sources, {NewSource, Source} from './Source'
import Assets, {Asset, NewAsset} from './Asset'
import StopReasons, {NewStopReason, StopReason} from './Stopreason'
import Discounts, {Discount, NewDiscount} from './Discounts'
import Tasktypes, {NewTasktype, Tasktype} from './Tasktypes'
import Estimateservices, {
  Estimateservice,
  NewEstimateservice,
} from './Estimateservices'
import Salesstages, {NewSalesstage, Salesstage} from './Salesstages'

let UnstyledLink = styled(Link)`
  &:visited {
    color: inherit;
  }
`

function SettingsTabs({baseUrl, selectedTab}) {
  return (
    <NavTabs
      value={selectedTab}
      variant="scrollable"
      scrollButtons
      allowScrollButtonsMobile
    >
      <TabLink
        to={baseUrl + '/templates'}
        label="Email Templates"
        value="templates"
      />
      <TabLink
        to={baseUrl + '/integrations'}
        label="Integrations"
        value="integrations"
      />
      <TabLink to={baseUrl + '/roles'} label="Roles" value="roles" />
      <TabLink
        to={baseUrl + '/routeruntemplate'}
        label="Routerun Template"
        value="routeruntemplate"
      />
      <TabLink to={baseUrl + '/routes'} label="Routes" value="routes" />
      <TabLink to={baseUrl + '/assets'} label="Assets" value="assets" />
      <TabLink
        to={baseUrl + '/taxitems'}
        label="Tax Authorities"
        value="taxitems"
      />
      <TabLink
        to={baseUrl + '/saleitems'}
        label="Sale items"
        value="saleitems"
      />
      <TabLink to={baseUrl + '/sources'} label="Sources" value="sources" />
      <TabLink
        to={baseUrl + '/stopreasons'}
        label="Stop Reasons"
        value="stopreasons"
      />
      <TabLink
        to={baseUrl + '/discounts'}
        label="Discounts"
        value="discounts"
      />
      <TabLink
        to={baseUrl + '/tasktypes'}
        label="tasktypes"
        value="tasktypes"
      />
      <TabLink
        to={baseUrl + '/estimateservices'}
        label="Estimate Services"
        value="estimateservices"
      />
      <TabLink
        to={baseUrl + '/salesstages'}
        label="Sales Stage"
        value="salesstages"
      />
    </NavTabs>
  )
}

const SettingsToolbar = ({match, location, ...permissions}) => {
  const settings = [
    {path: 'roles', canManage: permissions.canManageRoles},
    {path: 'routes', canManage: permissions.canManageRoutes},
    {path: 'taxitems', canManage: permissions.canManageTaxitems},
    {path: 'saleitems', canManage: permissions.canManageSaleitems},
    {path: 'sources', canManage: permissions.canManageSources},
    {path: 'assets', canManage: permissions.canManageAssets},
    {path: 'stopreasons', canManage: permissions.canManageStopreasons},
    {path: 'discounts', canManage: permissions.canManageDiscounts},
    {path: 'tasktypes'},
    {path: 'estimateservices'},
    {path: 'salesstages'},
    {path: 'templates'},
  ]

  return (
    <>
      <Toolbar>
        <Typography
          variant="h6"
          style={{alignItems: 'center', display: 'flex'}}
        >
          <SettingsApplications />
          <Space inline />
          Settings
        </Typography>
      </Toolbar>
      <Toolbar>
        <Route
          path={`${match.path}/:tab?`}
          children={({match: tabsMatch}) => (
            <SettingsTabs
              baseUrl={match.url}
              selectedTab={tabsMatch ? tabsMatch.params.tab : ''}
            />
          )}
        />
        {settings.map(({path, canManage}) =>
          location.pathname === `${match.path}/${path}` &&
          (canManage === undefined || canManage) ? (
            <Fab
              key={path}
              color="secondary"
              component={UnstyledLink}
              to={`${match.path}/${path}/new`}
            >
              <Add />
            </Fab>
          ) : (
            <></>
          ),
        )}
      </Toolbar>
    </>
  )
}

const Settings = ({match, location}) => {
  const {data, isLoading, isError} = useQuery('quickbooks', async () => {
    let json = ''
    json = (await fetch('/quickbooks/info')).json()
    return json
  })

  let {status: canManageRoles} = useAuth('manage roles')
  let {status: canManageRouterunTemplates} = useAuth(
    'manage routerun templates',
  )
  let {status: canManageTaxitems} = useAuth('manage taxitems')
  let {status: canManageSaleitems} = useAuth('manage saleitems')
  let {status: canManageSources} = useAuth('manage sources')
  let {status: canManageAssets} = useAuth('manage assets')
  let {status: canManageStopreasons} = useAuth('manage stopreasons')
  let {status: canManageDiscounts} = useAuth('manage discounts')

  const [open, setOpen] = useState(false)

  let connectionDisplay = ''
  if (!data || isLoading) {
    connectionDisplay = 'Loading...'
  } else if (isError) {
    connectionDisplay = 'Error...'
  } else {
    if (data.error) {
      if (
        data.errors &&
        data.errors.filter(e => e.code === 'VQ-2').length > 0
      ) {
        connectionDisplay = (
          <a href="/quickbooks/authorize" target="_blank">
            Connect to Intuit
          </a>
        )
      } else {
        connectionDisplay = data.message
      }
    } else {
      connectionDisplay = (
        <a onClick={() => setOpen(true)}>
          {' '}
          {data.data.name + ' (Click to disconnect)'}
        </a>
      )
    }
  }

  return (
    <>
      <SettingsToolbar
        match={match}
        location={location}
        canManageRoles={canManageRoles === 'authorized'}
        canManageRoutes={canManageRouterunTemplates === 'authorized'}
        canManageTaxitems={canManageTaxitems === 'authorized'}
        canManageSaleitems={canManageSaleitems === 'authorized'}
        canManageSources={canManageSources === 'authorized'}
        canManageAssets={canManageAssets === 'authorized'}
        canManageStopreasons={canManageStopreasons === 'authorized'}
        canManageDiscounts={canManageDiscounts === 'authorized'}
      />{' '}
      <Switch>
        <Route
          exact
          path={`${match.path}/integrations`}
          component={Integrations}
        />
        <Route
          exact
          path={`${match.path}/templates`}
          component={EmailTemplates}
        />
        <Route
          path={`${match.path}/templates/new`}
          exact
          component={NewEmailTemplate}
        />
        <Route path={`${match.path}/templates/:id`} component={EmailTemplate} />
        <Route exact path={`${match.path}/roles`} component={Roles} />
        <Route path={`${match.path}/roles/new`} exact component={NewRole} />
        <Route path={`${match.path}/roles/:id`} component={Role} />
        <Route exact path={`${match.path}/routes`} component={Routes} />
        <Route path={`${match.path}/routes/new`} exact component={NewRoute} />
        <Route path={`${match.path}/routes/:id`} component={RouteSingle} />
        <Route
          path={`${match.path}/routeruntemplate`}
          component={RouterunTemplates}
        />
        <Route exact path={`${match.path}/taxitems`} component={Taxitems} />
        <Route
          path={`${match.path}/taxitems/new`}
          exact
          component={NewTaxitem}
        />
        <Route path={`${match.path}/taxitems/:id`} component={Taxitem} />
        <Route exact path={`${match.path}/saleitems`} component={Saleitems} />
        <Route
          path={`${match.path}/saleitems/new`}
          exact
          component={NewSaleitem}
        />
        <Route path={`${match.path}/saleitems/:id`} component={Saleitem} />
        <Route exact path={`${match.path}/sources`} component={Sources} />
        <Route path={`${match.path}/sources/new`} exact component={NewSource} />
        <Route path={`${match.path}/sources/:id`} component={Source} />
        <Route exact path={`${match.path}/assets`} component={Assets} />
        <Route path={`${match.path}/assets/new`} exact component={NewAsset} />
        <Route path={`${match.path}/assets/:id`} component={Asset} />
        <Route
          exact
          path={`${match.path}/stopreasons`}
          component={StopReasons}
        />
        <Route
          path={`${match.path}/stopreasons/new`}
          exact
          component={NewStopReason}
        />
        <Route path={`${match.path}/stopreasons/:id`} component={StopReason} />
        <Route exact path={`${match.path}/discounts`} component={Discounts} />
        <Route
          path={`${match.path}/discounts/new`}
          exact
          component={NewDiscount}
        />
        <Route path={`${match.path}/discounts/:id`} component={Discount} />
        <Route exact path={`${match.path}/tasktypes`} component={Tasktypes} />
        <Route
          path={`${match.path}/tasktypes/new`}
          exact
          component={NewTasktype}
        />
        <Route path={`${match.path}/tasktypes/:id`} component={Tasktype} />
        <Route
          exact
          path={`${match.path}/estimateservices`}
          component={Estimateservices}
        />
        <Route
          path={`${match.path}/estimateservices/new`}
          exact
          component={NewEstimateservice}
        />
        <Route
          path={`${match.path}/estimateservices/:id`}
          component={Estimateservice}
        />
        <Route
          exact
          path={`${match.path}/salesstages`}
          component={Salesstages}
        />
        <Route
          path={`${match.path}/salesstages/new`}
          exact
          component={NewSalesstage}
        />
        <Route path={`${match.path}/salesstages/:id`} component={Salesstage} />
      </Switch>
    </>
  )
}

export default Settings
