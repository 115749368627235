import React, { Component, useState } from 'react'
import styled from 'styled-components'
import { graphql } from 'react-apollo'
import compose from 'lodash/flowRight'
import gql from 'graphql-tag'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import ModifyQueryParams from '../../components/ModifyQueryParams'
import moment from 'moment'
import queryString from 'query-string'
import { formatMoneyStandard } from '../../utils/moneyFormatter'
import ArrowLeft from '@mui/icons-material/ArrowLeft'
import { Link } from 'react-router-dom'
import { fetchAPI } from '../../schema/utils'
import { useQuery } from 'react-query'

let DashboardWrapper = styled.div`
  padding: 16px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 16px;
  grid-row-gap: 16px;
`

let BoldText = styled.div`
  font-weight: bold;
`

let TopCell = styled(TableCell).attrs(() => ({ classes: { root: 'root' } }))`
  &.root {
    background-color: #222;
    color: white;
  }
`

let BottomCell = styled(TableCell).attrs(() => ({ classes: { root: 'root' } }))`
  &.root {
    background-color: #f2f2f2;
  }
`

const ArSummary = ({ data, ...props }) => {
  let [state, setState] = useState({
    queryChanges: {},
    today: moment().format('YYYY-MM-DD'),
  })
  if (
    !data ||
    !data.allArsummarystatistics ||
    data.allArsummarystatistics.loading
  ) {
    return null
  }
  let statistics = data.allArsummarystatistics.edges[0].edge

  let { data: breakdownData } = useQuery(['ar_aging_rollup'], async () => await fetchAPI({ url: 'reports/ar_aging_rollup' }).then(r => r.json()))

  return (
    <DashboardWrapper>
      <ModifyQueryParams query={state.queryChanges} />
      <Paper>
        <div style={{ margin: '1em' }}>
          <Typography component="h2" variant="h5" gutterBottom>
            <Button variant="contained" size="small" component={Link} to="/ar">
              <ArrowLeft />
              AR
            </Button>
            <span style={{ marginLeft: '.5em' }}>AR Summary</span>
          </Typography>
        </div>
        <Table>
          <TableHead>
            <TableRow>
              <TopCell align="right">Balance </TopCell>
              <TopCell align="right">1 - 30 </TopCell>
              <TopCell align="right">31 - 60 </TopCell>
              <TopCell align="right">61 - 90 </TopCell>
              <TopCell align="right">&gt; 90 </TopCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <BottomCell align="right">
                <BoldText>{formatMoneyStandard(statistics.balance)}</BoldText>
              </BottomCell>
              <BottomCell align="right">
                <BoldText>
                  {formatMoneyStandard(statistics.thirtyDay)}
                </BoldText>
              </BottomCell>
              <BottomCell align="right">
                <BoldText>
                  {formatMoneyStandard(statistics.sixtyDay)}
                </BoldText>
              </BottomCell>
              <BottomCell align="right">
                <BoldText>
                  {formatMoneyStandard(statistics.ninetyDay)}
                </BoldText>
              </BottomCell>
              <BottomCell align="right">
                <BoldText>
                  {formatMoneyStandard(statistics.overNinetyDay)}
                </BoldText>
              </BottomCell>
            </TableRow>
          </TableBody>
        </Table>
      </Paper>
      <div></div>
      <Paper>
        <div style={{ margin: '1em' }}>
          <Typography variant="h6" gutterBottom>
            <span style={{ marginLeft: '.5em' }}>Customer Breakdown</span>
          </Typography>
        </div>
        <Table>
          <TableHead>
            <TableRow>
              <TopCell align="right">Name </TopCell>
              <TopCell align="right">Balance </TopCell>
              <TopCell align="right">Credit </TopCell>
              <TopCell align="right">1 - 30 </TopCell>
              <TopCell align="right">31 - 60 </TopCell>
              <TopCell align="right">61 - 90 </TopCell>
              <TopCell align="right">&gt; 90 </TopCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {breakdownData?.map(item => (
              <TableRow>
                <BottomCell align="right">
                  <BoldText>{item.taxname}</BoldText>
                </BottomCell>
                <BottomCell align="right">
                  <BoldText>
                    {formatMoneyStandard(item.balance)}
                  </BoldText>
                </BottomCell>
                <BottomCell align="right">
                  <BoldText>
                    {formatMoneyStandard(-item.credit,
                      {
                        pos: '%s%v',
                        neg: '(%s%v)',
                        zero: '(%s%v)',
                      }
                    )}
                  </BoldText>
                </BottomCell>
                <BottomCell align="right">
                  <BoldText>
                    {formatMoneyStandard(item.thirtyDay)}
                  </BoldText>
                </BottomCell>
                <BottomCell align="right">
                  <BoldText>
                    {formatMoneyStandard(item.sixtyDay)}
                  </BoldText>
                </BottomCell>
                <BottomCell align="right">
                  <BoldText>
                    {formatMoneyStandard(item.ninetyDay)}
                  </BoldText>
                </BottomCell>
                <BottomCell align="right">
                  <BoldText>
                    {formatMoneyStandard(item.overNinetyDay)}
                  </BoldText>
                </BottomCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </DashboardWrapper>
  );
}

let query = gql`
  query statistics {
    allArsummarystatistics {
      edges {
        edge {
          balance
          thirtyDay
          sixtyDay
          ninetyDay
          overNinetyDay
        }
      }
    }
  }
`
export default compose(
  graphql(query, {
    options: props => {
      let params = queryString.parse(props.location.search)

      return {
        variables: { filters: { date: params.date } },
      }
    },
  }),
)(ArSummary)
