import React, { useState } from 'react'
import url from 'url'
import { Switch } from 'react-router-dom'
import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'
import styled from 'styled-components'

import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'

import NavigationArrowBack from '@mui/icons-material/ArrowBack'
import EditIcon from '@mui/icons-material/Edit'

// import CustomerDetail from './AccountDetail'
import { Toolbar } from '../../AppHandler'
import { ToolbarIconMenu, DeleteMenuItem } from '../../components/Toolbar'
import ToolbarGroup from '../../components/ToolbarGroup'
import ToolbarMegaTitle from '../../components/ToolbarMegaTitle'
import theme from '../../styles/theme'
import { Route } from '../../pr-router'
import { NavigateBack, RedirectBack } from '../../components/Navigator'
import DeleteDialog from '../../components/DeleteDialog'
import { NavTabs, TabLink } from '../../components/NavTabs'
import CustomerEmail from '../Customer/CustomerEmail'
import AccountDetail, { customerQuery as accountQuery } from './AccountDetail'
import ActivityLog from '../Customer/ActivityLog'
import CustomerTransactions from '../Customer/CustomerTransactions'
import CustomerEstimates from '../Customer/CustomerEstimates'
import { prgql } from '../../utils/graphql'
import { useQuery, useQueryClient } from 'react-query'
import EditDetails from './EditDetails'
import Estimate, { NewEstimate } from '../Customer/Estimate'
// import CustomerEdit from './CustomerEdit'
// import CustomerFeedbacks from './CustomerFeedbacks'
// import CustomerEstimates from './CustomerEstimates'
// import CustomerPhotos from './CustomerPhotos'
// import CustomerDocuments from './CustomerDocuments'
// import Feedback from './FeedbackEdit'
// import EstimateEdit from './EstimateEdit'
// import EstimateAdd from './EstimateAdd'
// import CustomerTransactions from './CustomerTransactions'

const sheet = {
  customerDetail: { minWidth: '920px', margin: '20px 120px' },
  toolbarStyle: {},
  toolbarGroup: {},
  toolbarTitle: { color: theme.palette.textInv1Color },
  toolbarIcon: { fill: theme.palette.textInv1Color },
  toolbarTab: {},
  detailsWrapper: { position: 'fixed', top: 0, right: 0, bottom: 0, left: 0 },
}

function CustomerTabs({ baseUrl, selectedTab, customerId }) {
  return (
    <NavTabs
      value={selectedTab || 'main'}
      variant="scrollable"
      scrollButtons
      allowScrollButtonsMobile
    >
      {/* <TabLink to={baseUrl} label="Info" value="main" /> */}
      <TabLink to={baseUrl} label="Info" value="main" />
      <TabLink
        component="a"
        href={`/old/database/feedback.php?AccountID=${customerId}`}
        label="Feedbacks"
        value="feedbacks"
      />
      <TabLink
        to={baseUrl + '/estimates'}
        label="Estimates"
        value="estimates"
      />
      <TabLink
        component="a"
        href={`/old/database/documents_account.php?AccountID=${customerId}`}
        label="Documents"
        value="documents"
      />
      <TabLink
        component="a"
        href={`/old/database/accountar.php?AccountID=${customerId}`}
        label="AR"
        value="ar"
      />
      <TabLink
        to={baseUrl + '/transactions'}
        label="Transactions"
        value="transactions"
      />
      <TabLink
        component="a"
        href={`/old/database/accountrecord.php?AccountID=${customerId}&display=1`}
        label="Schedule"
        value="schedule"
      />
      <TabLink to={baseUrl + '/activity'} label="activity" value="activity" />
    </NavTabs>
  )
}

let deleteQuery = gql`
  mutation deleteCustomer($id: String!) {
    deleteCustomers(input: {id: $id}) {
      message
    }
  }
`
function DeleteCustomer({ id, showDelete, onCancel, onDelete }) {
  let [deleteMut] = useMutation(deleteQuery, { variables: { id } })
  return (
    <DeleteDialog
      title="Delete Customer?"
      message="Are you sure you want to delete this customer?"
      isOpen={showDelete}
      onCancel={onCancel}
      onConfirm={() => {
        deleteMut().then(onDelete)
      }}
    />
  )
}

let CustomerToolbarQuery = gql`
  query CustomerToolbarQuery($id: String!) {
    accounts(id: $id) {
      id
      name
    }
  }
`

let CustomerTypeTag = styled(Typography)`
  background-color: ${({ theme }) => theme.grey600};
  padding: 4px;
  border-radius: 4px;
  margin-left: 8px;
  opacity: 0.9;
`

function ScreenToolbar({ match }) {
  let [showDelete, setShowDelete] = useState(false)
  let [goBack, setGoBack] = useState(false)
  let [showEdit, setShowEdit] = useState(false)
  let { data, isLoading: loading, isError: error } = useQuery(
    ['account', match.params.id],
    async () =>
      await prgql({ query: accountQuery, variables: { id: match.params.id } }),
  )

  let queryClient = useQueryClient()

  let customerId = match.params.id

  let requiresBillingInfo =
    data && data.customers && !!data.customers.customers.length

  return (
    <>
      <Toolbar style={sheet.toolbarStyle}>
        <ToolbarGroup first={true}>
          <Route
            path={`${match.path}/(feedbacks|estimates|documents|ar|transactions)?`}
            exact
            render={() => (
              <NavigateBack
                defaultBack={url.resolve(match.url, '.')}
                marker="AccountBack"
              >
                <IconButton size="large">
                  <NavigationArrowBack />
                </IconButton>
              </NavigateBack>
            )}
          />
        </ToolbarGroup>
        <ToolbarGroup>
          <ToolbarMegaTitle>
            {loading && !data ? (
              'Loading'
            ) : error ? (
              'Oops. Something went wrong.'
            ) : !data.customers ? (
              'Account not found'
            ) : (
              <>
                {data.customers.name}
                <CustomerTypeTag display="inline">Account</CustomerTypeTag>
              </>
            )}
          </ToolbarMegaTitle>
        </ToolbarGroup>
        <ToolbarGroup last={true}>
          {!loading && !error && (
            <IconButton
              // href={`/old/database/editaccount.php?AccountID=${customerId}`}
              onClick={() => setShowEdit(true)}
              size="large"
            >
              <EditIcon />
            </IconButton>
          )}
          {/* <ToolbarIconMenu>
          <DeleteMenuItem
            onClick={() => {
              setShowDelete(true)
            }}
          />
        </ToolbarIconMenu> */}
          <DeleteCustomer
            id={customerId}
            showDelete={showDelete}
            onCancel={() => {
              setShowDelete(false)
            }}
            onDelete={setGoBack}
          />
          {goBack && (
            <RedirectBack
              defaultBack={url.resolve(match.url, '.')}
              marker="CustomerBack"
            />
          )}
        </ToolbarGroup>
      </Toolbar>
      <Toolbar>
        {/* <ToolbarGroup style={{flexGrow: 1}}> */}
        <Route
          path={`${match.path}/:tab?`}
          children={({ match: tabsMatch }) => (
            <CustomerTabs
              baseUrl={match.url}
              selectedTab={tabsMatch ? tabsMatch.params.tab : ''}
              customerId={customerId}
            />
          )}
        />
        {/* </ToolbarGroup> */}
      </Toolbar>
      <EditDetails
        customerId={customerId}
        open={showEdit}
        onSubmit={() => {
          setShowEdit(false)
          queryClient.refetchQueries(['account'])
        }}
        onCancel={() => setShowEdit(false)}
        data={data}
        requiresBillingInfo={requiresBillingInfo}
      />
    </>
  )
}

export default function Account({ match, location }) {
  return (
    <>
      <ScreenToolbar match={match} location={location} />
      <Switch>
        <Route path={match.path} exact component={AccountDetail} />
        <Route
          path={`${match.path}/transactions/email`}
          component={CustomerEmail}
        />
        <Route
          path={`${match.path}/transactions`}
          component={CustomerTransactions}
        />
        <Route path={`${match.path}/estimates/new`} component={NewEstimate} />
        <Route
          path={`${match.path}/estimates/:estimateId(\\d+)`}
          component={Estimate}
        />
        <Route path={`${match.path}/estimates`} component={CustomerEstimates} />
        <Route
          path={`${match.path}/activity`}
          render={matchProps => (
            <ActivityLog
              {...matchProps}
              resourceId={match.params.id}
              resource="accounts"
            />
          )}
        />
      </Switch>
    </>
  )
}
