import React, { useEffect, useState } from 'react'
import { Toolbar } from '../../AppHandler'
import ToolbarGroup from '../../components/ToolbarGroup'
import ToolbarMegaTitle from '../../components/ToolbarMegaTitle'
import styled from 'styled-components'
import { DatePicker } from '@mui/lab'
import { Typography, LinearProgress, Grid, TextField } from '@mui/material'
import { Space } from '../../components/Layout'
import { fetchAPI } from '../../schema/utils'
import moment from 'moment'
import { useQuery, useQueryClient } from 'react-query'
import _ from 'lodash'

let getData = async date => {
  date = moment(date)
  let data = await fetchAPI({
    url: '/reports/dispatch',
    query: {
      date: date.format(),
    },
  })

  return JSON.parse(await data.text())
}

let ReqList = styled.ul`
  margin-left: 2em;
`

let ReqListItem = styled.li`
  list-style: disc;
`

let SummaryWrapper = styled.div`
  page-break-after: always;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
`

function Dispatch() {
  const queryClient = useQueryClient()
  let [date, setDate] = useState(moment())
  let [currTime, setCurrTime] = useState(moment())
  let { data, isLoading, dataUpdatedAt } = useQuery(['dispatch', { date }], () => getData(date), { refetchInterval: 1000 * 10 })

  useEffect(() => {
    let interval = setInterval(() => setCurrTime(moment()), 1000)
    return () => clearInterval(interval)
  })

  return (
    <>
      <Toolbar>
        <ToolbarMegaTitle>Dispatch for </ToolbarMegaTitle>
        <Space inline />
        <ToolbarGroup last>
          <DatePicker
            value={date}
            onChange={d => setDate(d)}
            renderInput={props => (
              <TextField
                {...props}
                variant="outlined"
                margin="dense"
                size="small"
              />
            )}
          />
        </ToolbarGroup>
      </Toolbar>

      {isLoading && <LinearProgress />}

      <Typography>Last Updated: {moment(dataUpdatedAt).format('YYYY-MM-DD HH:mm:ss')} ({currTime.diff(dataUpdatedAt, 'seconds')} seconds ago)</Typography>

      {data && (
        <SummaryWrapper>
          <Typography variant="h2">All Supplies</Typography>
          <Space size={3} />
          <Grid container spacing={2}>
            {_.chunk(data.all_supplies, 3).map((reqs, k) => (
              <Grid item xs={3} key={'all-' + k}>
                <ul>
                  {reqs.map(r => (
                    <li key={'req-' + r.id}>
                      <Typography variant="subtitle1">
                        ({r.number}) {r.requirement.name}
                      </Typography>
                    </li>
                  ))}
                </ul>
              </Grid>
            ))}
          </Grid>
          <Space size={3} />
          <Typography variant="h2">Per Truck</Typography>
          <Space size={3} />
          <Grid container spacing={2}>
            {_.chunk(data.supplies_per_truck, 3).map((routes, k) => (
              <Grid item xs={4} key={'grid-' + k}>
                <ul>
                  {routes.map(r => (
                    <>
                      <li key={'route-' + r.route_id}>
                        <Typography variant="body1">
                          <strong style={{ fontWeight: 'bold' }}>
                            Truck {r.vehicle}
                          </strong>{' '}
                          - {r.users} on Route {r.route_id}
                        </Typography>
                        <ReqList>
                          {r.job_requirements.length ? (
                            r.job_requirements.map(re => (
                              <ReqListItem key={r.route_id + '-' + re.id}>
                                <Typography variant="body2">
                                  ({re.qty}) {re.name}
                                </Typography>
                              </ReqListItem>
                            ))
                          ) : (
                            <ReqListItem key={r.route_id + '-none'}>
                              <Typography variant="body2">none</Typography>
                            </ReqListItem>
                          )}
                        </ReqList>
                      </li>
                      <Space />
                    </>
                  ))}
                </ul>
              </Grid>
            ))}
          </Grid>
        </SummaryWrapper>
      )}
    </>
  )
}

export default Dispatch
