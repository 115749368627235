import React from 'react'
import gql from 'graphql-tag'
import {Column} from 'react-virtualized'
import styled from 'styled-components'
import moment from 'moment'
import queryString from 'query-string'
import {Redirect} from 'react-router-dom'
import {graphql} from 'react-apollo'
import compose from 'lodash/flowRight'

import MenuItem from '@mui/material/MenuItem'
import Fab from '@mui/material/Fab'

import AddIcon from '@mui/icons-material/Add'

import {Toolbar} from '../AppHandler'
import TableList from '../components/Table/TableList'
import PaginationContainer from '../components/PaginationContainer'
import {formatMoneyStandard} from '../utils/moneyFormatter'
import {ToolbarGroup} from '../components/Toolbar'
import SelectMenu from '../components/ToolbarDropDownMenu'
import ModifyQueryParams from '../components/ModifyQueryParams'
import UsersSelect from '../components/UsersSelect'

let Align = styled.div.attrs(p => ({to: p.to || 'center'}))`
  text-align: ${({to}) => to};
`
let GroupGrid = styled(ToolbarGroup)`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 16px;
`

class POToolbar extends React.Component {
  static defaultProps = {
    onChange: () => {},
    onNew: () => {},
    status: 'all',
  }
  createHandleChange = prop => e =>
    this.props.onChange({[prop]: e.target.value})
  render() {
    let {status, user_id, onNew} = this.props
    return (
      <Toolbar>
        <GroupGrid first>
          <SelectMenu
            value={status}
            onChange={this.createHandleChange('status')}
          >
            <MenuItem value="all">All Purchase Orders</MenuItem>
            <MenuItem value="open">Open Purchase Orders</MenuItem>
          </SelectMenu>
          <UsersSelect
            onChange={user =>
              this.props.onChange({user_id: user ? user.id : null})
            }
            selectedUserId={user_id}
          />
        </GroupGrid>
        <ToolbarGroup last />
        <Fab color="secondary" onClick={onNew}>
          <AddIcon />
        </Fab>
      </Toolbar>
    )
  }
}

let purchaseordersQuery = gql`
  query PurchaseOrders(
    $cursor: String
    $limit: Int = 25
    $filters: POFilters = {}
  ) {
    pos: allPOs(
      cursor: $cursor
      limit: $limit
      filters: $filters
      orderBy: created_at_DESC
    ) @connection(key: "POsListPOs") {
      pageInfo {
        next
        current
        prev
        count
        total
      }
      edges {
        cursor
        edge {
          id
          closed
          created_at
          user {
            id
            first_name
            last_name
          }
          poitems {
            id
            amount
          }
        }
      }
    }
  }
`
let newPOQuery = gql`
  mutation createPO($userId: ID) {
    createPOs(input: {user_id: $userId}) {
      pOs {
        id
      }
    }
  }
`
let currentUserQuery = gql`
  query currentUser {
    currentUser {
      id
      first_name
      last_name
    }
  }
`
class PurchaseOrders extends React.Component {
  state = {queryChanges: null, redirectId: null}
  handleNew = () => {
    this.props.createPO(this.props.data.currentUser.id).then(({newId}) => {
      this.setState({redirectId: newId})
    })
  }
  render() {
    let {location} = this.props
    let {status = 'open', user_id} = queryString.parse(location.search)
    let filters = {closed: status === 'all' ? undefined : false, user_id}
    return this.state.redirectId ? (
      <Redirect push to={`/pos/${this.state.redirectId}`} />
    ) : (
      <React.Fragment>
        <POToolbar
          status={status}
          user_id={user_id}
          onChange={queryChanges => this.setState({queryChanges})}
          onNew={this.handleNew}
        />
        <ModifyQueryParams query={this.state.queryChanges} />
        <PaginationContainer
          query={purchaseordersQuery}
          variables={{cursor: '-1', filters}}
        >
          {({pos, loadMore, loading}) => (
            <TableList
              data={pos ? pos.edges : []}
              infinite
              loadMoreRows={loadMore}
              rowCount={pos ? pos.pageInfo.total : 9999}
              rowClickable
              onRowClick={({rowData}) => {
                this.setState({redirectId: rowData.id})
              }}
              loading={loading}
            >
              <Column
                dataKey="id"
                label="id"
                width={50}
                cellRenderer={({cellData}) => (
                  <Align to="right">{cellData}</Align>
                )}
              />
              <Column
                dataKey="created_at"
                label="date"
                width={150}
                cellRenderer={({cellData}) => (
                  <Align to="right">{moment(cellData).format('LL')}</Align>
                )}
              />
              <Column
                dataKey="user"
                label="user"
                cellRenderer={({cellData}) =>
                  cellData ? `${cellData.first_name} ${cellData.last_name}` : ''
                }
                width={0}
                flexGrow={1}
              />
              <Column
                dataKey="poitems"
                label="No. Items"
                width={70}
                cellRenderer={({cellData = []}) => (
                  <Align to="right">{cellData.length}</Align>
                )}
              />
              <Column
                dataKey="poitems"
                label="cost"
                width={0}
                flexGrow={1}
                headerRenderer={({label}) => <Align to="right">{label}</Align>}
                cellRenderer={({cellData = []}) => (
                  <Align to="right">
                    {formatMoneyStandard(
                      cellData.length &&
                        cellData.reduce((t, {amount}) => t + amount, 0),
                    )}
                  </Align>
                )}
              />
              <Column label="" dataKey="" disableSort={true} width={75} />
            </TableList>
          )}
        </PaginationContainer>
      </React.Fragment>
    )
  }
}

export default compose(
  graphql(currentUserQuery),
  graphql(newPOQuery, {
    props: ({mutate}) => ({
      createPO: userId =>
        mutate({variables: {userId}}).then(({data}) => ({
          newId: data.createPOs.pOs.id,
        })),
    }),
  }),
)(PurchaseOrders)
