import React from 'react'
import styled from 'styled-components'
import {Column} from 'react-virtualized'
import gql from 'graphql-tag'
import moment from 'moment'
import Typography from '@mui/material/Typography'

import PaginationContainer from '../../components/PaginationContainer'
import TableList from '../../components/Table/TableList'
import {formatMoneyStandard} from '../../utils/moneyFormatter'
import {Link} from 'react-router-dom'

let allQuery = gql`
  query Mentions($cursor: String, $limit: Int = 25, $userId: ID) {
    feedbacks: allFeedbacks(
      filters: {technician_id: $userId}
      limit: $limit
      cursor: $cursor
      orderBy: created_at_DESC
    ) {
      pageInfo {
        total
      }
      edges {
        cursor
        edge {
          id
          user {
            id
            first_name
            last_name
          }
          from {
            id
            first_name
            last_name
          }
          customer_id
          customer {
            id
            name
            account_id
          }
          feedback
          created_at
          type
          closed_at
        }
      }
    }
  }
`

let TypeRow = styled.div`
  display: flex;
  align-items: center;

  & > * + * {
    margin-left: 8px;
  }
`

let Mentions = ({match, location}) => {
  let {params = {}} = match
  return (
    <>
      <PaginationContainer
        query={allQuery}
        variables={{userId: params.id}}
        children={({feedbacks, loadMore, ...rest}) => {
          return (
            <TableList
              data={feedbacks ? feedbacks.edges : []}
              infinite
              loadMoreRows={loadMore}
              startBottom
              wrapRow={({children, rowData}) => (
                <a
                  href={`/old/database/feedback.php?JobID=${
                    rowData?.customer_id
                  }&AccountID=${
                    rowData?.customer?.account_id === '191'
                      ? '0'
                      : rowData?.customer?.account_id
                  }&action=1&CustomerFeedbackID=${rowData?.id}`}
                  key={rowData.id}
                >
                  {children}
                </a>
              )}
              rowCount={feedbacks ? feedbacks.pageInfo.total : 0}
            >
              <Column
                dataKey="created_at"
                label="Date"
                width={180}
                cellRenderer={({cellData, rowData}) => (
                  <Typography variant="h6">
                    {cellData && moment.utc(cellData).format('MM/DD/YYYY')}
                  </Typography>
                )}
              />
              <Column
                dataKey="from"
                label="From"
                width={180}
                cellRenderer={({cellData, rowData}) => (
                  <Typography variant="h6">
                    {cellData && cellData.first_name + ' ' + cellData.last_name}
                  </Typography>
                )}
              />
              <Column
                dataKey="user"
                label="To"
                width={180}
                cellRenderer={({cellData, rowData}) => (
                  <Typography variant="h6">
                    {!!cellData
                      ? cellData.first_name + ' ' + cellData.last_name
                      : 'Next Technician'}
                  </Typography>
                )}
              />
              <Column
                dataKey="feedback"
                label="feedback"
                cellRenderer={({cellData, rowData}) => (
                  <Typography
                    style={{
                      textDecoration: !!rowData.closed_at
                        ? 'line-through'
                        : undefined,
                    }}
                  >
                    {cellData}
                  </Typography>
                )}
              />
            </TableList>
          )
        }}
      />
    </>
  )
}
export default Mentions
