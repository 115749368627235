import React from 'react'
import {Column} from 'react-virtualized'
import gql from 'graphql-tag'
import {Link} from 'react-router-dom'
import moment from 'moment'
import {formatMoneyStandard} from '../../utils/moneyFormatter'

import Add from '@mui/icons-material/Add'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'

import PaginationContainer from '../../components/PaginationContainer'
import TableList from '../../components/Table/TableList'
import {PaperToolbar, DetailRow, ToolbarRight} from '../../components/Toolbar'
import ModifyQueryParams from '../../components/ModifyQueryParams'
import urlJoin from '../../utils/url-join'

let lossQuery = gql`
  query lossQuery($cursor: String, $limit: Int = 25, $userId: String!) {
    losses: allLossesByUser(
      cursor: $cursor
      limit: $limit
      userId: $userId
      orderBy: loss_at_ASC
    ) {
      pageInfo {
        total
      }
      edges {
        cursor
        edge {
          id
          user_id
          loss_at
          cost
          notes
        }
      }
    }
  }
`

class Losses extends React.Component {
  state = {
    queryChanges: null,
  }

  render() {
    let {match} = this.props
    let {params = {}} = match
    let queries = {
      loss: lossQuery,
    }
    return (
      <React.Fragment>
        <PaperToolbar>
          <DetailRow>
            <ToolbarRight>
              <Link to={urlJoin`${match.url}new`}>
                <Button variant="contained" color="secondary">
                  <Add />
                </Button>
              </Link>
            </ToolbarRight>
          </DetailRow>
        </PaperToolbar>
        <ModifyQueryParams query={this.state.queryChanges} />
        <PaginationContainer
          key={'losses'}
          query={queries['loss']}
          variables={{userId: params.id}}
          children={({losses, loadMore, loading}) => (
            <TableList
              data={losses ? losses.edges : []}
              infinite
              startBottom
              loadMoreRows={loadMore}
              loading={loading}
              wrapRow={({children, rowData}) => (
                <Link
                  to={urlJoin`${match.url}${rowData['id']}`}
                  key={rowData.id}
                >
                  {children}
                </Link>
              )}
              rowCount={losses ? losses.pageInfo.total : 0}
            >
              <Column
                dataKey="loss_at"
                label="Date"
                width={180}
                cellRenderer={({cellData}) => (
                  <Typography variant="h6" align="right">
                    {moment.utc(cellData).format('M/D/YYYY, ddd.')}
                  </Typography>
                )}
              />
              <Column
                dataKey="cost"
                label="Cost"
                width={140}
                cellRenderer={({cellData}) => {
                  return (
                    <Typography variant="subtitle1" align="center">
                      {formatMoneyStandard(cellData)}
                    </Typography>
                  )
                }}
              />
              <Column
                dataKey="notes"
                label="Notes"
                width={0}
                flexGrow={1}
                cellRenderer={({cellData}) => (
                  <Typography variant="body1">{cellData}</Typography>
                )}
              />
            </TableList>
          )}
        />
      </React.Fragment>
    )
  }
}
export default Losses
