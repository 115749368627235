import React, {useState, useRef, useEffect} from 'react'
import queryString from 'query-string'
import styled from 'styled-components'
import {Column} from 'react-virtualized'
import gql from 'graphql-tag'

import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'

import PeopleAddIcon from '@mui/icons-material/GroupAdd'

import {Toolbar, ToolbarFab as Fab} from '../../AppHandler'
import ToolbarGroup from '../../components/ToolbarGroup'
import ModifyQueryParams from '../../components/ModifyQueryParams'
import TableList, {TrashableTableList} from '../../components/Table/TableList'
import SearchBox from '../../components/SearchBox'
import {csv, useDebounce} from '../../utils'
import SelectMenu from '../../components/ToolbarDropDownMenu'
import {usePagination} from '../../components/PaginationContainer'
import CustomersNav from './CustomersToolbarNav'
import {Space} from '../../components/Layout'
import {Link} from 'react-router-dom'
import {useAuth, useCurrentUser} from '../../security/auth'
import {
  Box,
  Button,
  Chip,
  FormControl,
  FormControlLabel,
  IconButton,
  Menu,
  Popover,
  Tooltip,
} from '@mui/material'
import {
  ArrowForward,
  FilterList,
  Label,
  People,
  Person,
  RestoreFromTrash,
} from '@mui/icons-material'
import theme, {PRThemeProvider} from '../../styles/theme'
import {useMutation, useQueryClient} from 'react-query'
import {Redirect} from '../../pr-router'
import {Record} from 'immutable'
import {EditDetails} from '../Contact/EditDetails'
import {WhiteBackgroundSwitch} from '../../components/WhiteBackgroundSwitch'
import {fetchAPI} from '../../schema/utils'
import {SalesstageSelect} from '../../components/apiSelects'
import {UserSelectDynamic} from '../../components/UsersSelect'

const SearchBoxGroup = styled(ToolbarGroup)`
  flex-grow: 1;
`

function getFilterDescription({
  searchSalesstage,
  searchSalesPerson,
  searchCreatedUser,
}) {
  const getLabel = (v, label) =>
    v === 'all' || v === undefined || v === null || v === '' ? '' : label
  const filters = [
    getLabel(searchSalesstage, 'Sales Stage'),
    getLabel(searchSalesPerson, 'Sales Person'),
    getLabel(searchCreatedUser, 'Created User'),
  ]
  const showFilters = !!filters.join('')
  return showFilters
    ? `Filtering by: ${filters.filter(v => !!v).join('; ')}`
    : ''
}

const queryToLabels = {
  searchBy: {
    name: 'Accounts.Name',
    address: 'Accounts.Address',
    city_name: 'Cities.City',
    state: 'Accounts.State',
    phone: 'Accounts.Phone',
    contact: 'Accounts.Contact',
    notes: 'Accounts.notes',
  },
  searchStatus: {active: 'Active', inactive: 'Inactive'},
  searchSalesStatus: {lead: 'Lead', sold: 'Sold'},
}

function ContactToolbar({
  q,
  searchBy,
  // searchTaxable,
  // searchEmployeeContact,
  // searchSalesStatus,
  // searchStatus,
  onQueryChange = () => {},
  searchSalesstage,
  searchSalesPerson,
  searchCreatedUser,
  match,
  onCSV,
}: {
  q: string,
  searchBy: string,
  onQueryChange: (...args: any[]) => any,
  searchSalesstage: string,
  searchSalesPerson: string,
  searchCreatedUser: string,
  match: any,
  onCSV: () => void,
}) {
  const [addDialogIsOpen, setAddDialogIsOpen] = useState(false)
  const [redirectToId, setRedirectToId] = useState<string | null>(null)

  const queryClient = useQueryClient()

  const searchEl = useRef<HTMLElement | null>(null)

  const {status: canExportList} = useAuth('export account list')
  const [filterEl, setFilterEl] = useState(null)
  const filtersOpen = !!filterEl

  useEffect(() => {
    searchEl.current && searchEl.current.focus()
  }, [searchBy])

  return redirectToId ? (
    <Redirect
      to={{
        pathname: `/contacts/${redirectToId}`,
        state: {
          backLocation: {
            pathname: location.pathname,
            query: location.query,
          },
        },
      }}
    />
  ) : (
    <Toolbar>
      <SearchBoxGroup>
        <CustomersNav />
        <Space inline size={2} />
        <SearchBox
          style={{flexGrow: 1}}
          ref={searchEl}
          value={q || ''}
          onSearch={q => onQueryChange({q})}
          autoFocus
        />
        <Space inline />
        <SelectMenu
          value={searchBy || 'name'}
          label="Search By"
          onChange={e => onQueryChange({searchBy: e.target.value})}
        >
          <MenuItem value="name">Name</MenuItem>
          <MenuItem value="address">Street</MenuItem>
          <MenuItem value="city_name">City</MenuItem>
          <MenuItem value="region">State</MenuItem>
          <MenuItem value="phone">Phone</MenuItem>
          <MenuItem value="contact">Contact</MenuItem>
          <MenuItem value="email">Email</MenuItem>
          <MenuItem value="notes">Notes</MenuItem>
        </SelectMenu>
      </SearchBoxGroup>
      <ToolbarGroup style={{justifyContent: 'flex-start'}}>
        <IconButton onClick={el => setFilterEl(el.currentTarget)} size="large">
          <FilterList />
        </IconButton>
        <Popover
          open={filtersOpen}
          anchorEl={filterEl}
          onClose={() => setFilterEl(null)}
          anchorOrigin={{vertical: 'bottom'}}
        >
          <Box p={2} style={{maxWidth: '650px'}}>
            <SalesstageSelect
              name={'searchsalesstage'}
              selectedId={searchSalesstage}
              onChange={(e, u) =>
                onQueryChange({searchSalesstage: u?.id || ''})
              }
              sx={{width: 227}}
              inputProps={{
                variant: 'outlined',
                size: 'small',
                margin: 'dense',
              }}
              label={'Sales Stage'}
            />
            <FormControl>
              <UserSelectDynamic
                selectedId={searchSalesPerson}
                onChange={(e, u) =>
                  onQueryChange({searchSalesPerson: u?.id || ''})
                }
                sx={{width: 227}}
                inputProps={{
                  variant: 'outlined',
                  size: 'small',
                  margin: 'dense',
                }}
                label={'Sales Person'}
              />
            </FormControl>
            <Space inline />
            <FormControl>
              <UserSelectDynamic
                selectedId={searchCreatedUser}
                onChange={(e, u) =>
                  onQueryChange({searchCreatedUser: u?.id || ''})
                }
                sx={{width: 227}}
                inputProps={{
                  variant: 'outlined',
                  size: 'small',
                  margin: 'dense',
                }}
                label={'Created User'}
              />
            </FormControl>
            <Space inline />
          </Box>
        </Popover>
        <Typography variant="caption">
          {getFilterDescription({
            searchSalesstage: searchSalesstage,
            searchCreatedUser,
            searchSalesPerson,
          })}
        </Typography>
      </ToolbarGroup>
      <ToolbarGroup last={true}>
        {canExportList === 'authorized' && (
          <>
            <Tooltip title="CSV">
              <Button onClick={onCSV}>CSV</Button>
            </Tooltip>
          </>
        )}
      </ToolbarGroup>
      <Fab
        color="secondary"
        onClick={() => {
          setAddDialogIsOpen(true)
        }}
        id="newAccount"
      >
        <PeopleAddIcon />
      </Fab>
      <PRThemeProvider>
        <EditDetails
          open={addDialogIsOpen}
          onSubmit={id => {
            setAddDialogIsOpen(false)
            queryClient.refetchQueries(['account'])
            setRedirectToId(id)
          }}
          onCancel={() => setAddDialogIsOpen(false)}
        />
      </PRThemeProvider>
    </Toolbar>
  )
}

const sortIsActive = (order, sortKey, isDefault = false) => {
  if (order === undefined && isDefault) {
    return true
  }

  const activeSortKey = order.replace(/_ASC|_DESC/g, '')

  return activeSortKey === sortKey
}

const sortDirection = (order, isActive, defaultDir = 'asc') => {
  if (order === undefined || !isActive) {
    return defaultDir
  }

  const activeSortDirection = order.match(/_ASC|_DESC/gm, '')[0]

  return activeSortDirection === '_ASC' ? 'asc' : 'desc'
}

const NameText = styled(Typography).attrs(() => ({
  variant: 'h6',
}))`
  flex: 1 0 auto;
`

const LeftIcon = styled.div`
  margin-right: ${({theme}) => theme.muiTheme.spacing(1)};
`

function ContactList({
  contacts,
  loadMore,
  pageInfo,
  loading,
  showTrash = false,
  refetch,
}) {
  const [showCompanyMenu, setShowCompanyMenu] = useState<{
    target: HTMLElement | null,
    companies: Array<Record<string, any>>,
  }>({
    target: null,
    companies: [],
  })

  const {mutateAsync: restore} = useMutation(
    'restore',
    async ({id}) =>
      await fetchAPI({
        url: `/contacts/${id}/restore`,
        options: {method: 'POST'},
      }),
  )

  function renderNameColumn({rowData, rowIndex, isScrolling, isLeadView}) {
    return (
      rowData && (
        <Link to={'/contacts/' + rowData.id}>
          <div
            style={{display: 'flex', alignItems: 'center', padding: '5px 20px'}}
          >
            <NameText
              display="block"
              style={{
                fontStyle: isLeadView && 'italic',
                fontWeight: isLeadView && 'unset',
              }}
            >
              {rowData && rowData.name}
            </NameText>
          </div>
        </Link>
      )
    )
  }

  function renderCompanies({cellData, rowData, onCompanyMenuClick}) {
    const customers = rowData?.customers
    const accounts = rowData?.accounts

    if (!customers && !accounts) {
      return <></>
    }

    const companies = [...(customers || []), ...(accounts || [])]

    if (companies.length > 1) {
      return (
        <>
          <Button
            onClick={e =>
              onCompanyMenuClick({target: e.currentTarget, companies})
            }
          >
            Multiple Companies
          </Button>
        </>
      )
    } else {
      return (
        <div
          style={{
            display: 'flex',
            alignContent: 'flex-start',
          }}
        >
          {companies.map(c => (
            <Link
              key={c.__typename + c.id}
              to={
                c.__typename === 'Customers'
                  ? '/customers/' + c.id
                  : '/accounts/' + c.id
              }
            >
              <span
                key={c.__typename + c.id}
                style={{
                  marginRight: '1em',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {c.__typename === 'Customers' ? <Person /> : <People />}{' '}
                {c.name} <ArrowForward />
              </span>
            </Link>
          ))}
        </div>
      )
    }
  }

  const _renderCityColumn = ({rowData}) =>
    rowData && (
      <div>
        <Typography display="block">
          {rowData.city && rowData.city.name}
        </Typography>
        <Typography variant="caption" color="textSecondary" display="block">
          {rowData.street1}
        </Typography>
      </div>
    )

  return (
    <>
      <TrashableTableList
        data={contacts || []}
        infinite
        loadMoreRows={loadMore}
        rowCount={pageInfo ? pageInfo.total : 9999}
        loading={loading}
        rowClassName={({index}) => {
          let classnames = ''
          const sortedData = contacts
            ? contacts?.sort((a, b) => (b.cursor > a.cursor ? -1 : 1))
            : []
          const rowData =
            sortedData && sortedData[index] && sortedData[index].edge

          if (rowData) {
            classnames += rowData.deleted_at ? ' trashed' : ''
          }

          return classnames
        }}
      >
        <Column
          dataKey="name"
          label="Name"
          width={1}
          flexGrow={1}
          cellRenderer={props =>
            renderNameColumn({
              ...props,
            })
          }
        />
        <Column
          dataKey="city"
          cellRenderer={_renderCityColumn}
          label="Address"
          flexGrow={1}
          width={1}
          maxWidth={250}
        />
        <Column
          dataKey="region"
          label="State"
          width={40}
          headerRenderer={({label}) => (
            <div style={{textAlign: 'center'}}>{label}</div>
          )}
          cellRenderer={({cellData}) => (
            <Typography
              variant="body1"
              display="block"
              style={{textAlign: 'center'}}
            >
              {cellData}
            </Typography>
          )}
        />
        <Column
          dataKey="salesstages"
          cellRenderer={({cellData}) =>
            cellData?.map(stage => <Chip key={stage.id} label={stage.name} />)
          }
          label="Sales Stage"
          flexGrow={1}
          width={1}
          maxWidth={200}
        />
        <Column
          dataKey="companies"
          cellRenderer={props =>
            renderCompanies({...props, onCompanyMenuClick: setShowCompanyMenu})
          }
          label="Companies"
          flexGrow={1}
          width={1}
          // maxWidth={250}
        />
        {showTrash && (
          <Column
            dataKey="id"
            cellRenderer={data => (
              <LeftIcon>
                <IconButton
                  onClick={async () => {
                    await restore({id: data.cellData})
                    refetch()
                  }}
                >
                  <RestoreFromTrash />
                </IconButton>
              </LeftIcon>
            )}
          />
        )}
      </TrashableTableList>
      <Menu
        anchorEl={showCompanyMenu.target}
        open={!!showCompanyMenu.target}
        onClose={() =>
          setShowCompanyMenu({
            target: null,
            companies: showCompanyMenu.companies,
          })
        }
      >
        {showCompanyMenu.companies.map(c => (
          <Link
            key={c.__typename + c.id}
            to={
              c.__typename === 'Customers'
                ? '/customers/' + c.id
                : '/accounts/' + c.id
            }
          >
            <MenuItem>
              {c.__typename === 'Customers' ? <Person /> : <People />} {c.name}{' '}
              <ArrowForward />
            </MenuItem>
          </Link>
        ))}
      </Menu>
    </>
  )
}

const ContactsQuery = gql`
  query Contacts(
    $cursor: String = "-1"
    $limit: Int
    $name: String
    $notes: String
    $address: String
    $city_name: String
    $phone: String
    $region: String
    $email: String
    $showTrash: Boolean
    $searchSalesstage: String
    $searchSalesPerson: String
    $searchCreatedUser: String
  ) {
    contacts: allContacts(
      cursor: $cursor
      limit: $limit
      filters: {
        name: $name
        address: $address
        city_name: $city_name
        phone: $phone
        region: $region
        email: $email
        notes: $notes
        trashed: $showTrash
        salesstages: $searchSalesstage
        sales_person_id: $searchSalesPerson
        created_user_id: $searchCreatedUser
      }
    ) {
      edges {
        cursor
        edge {
          id
          name
          street1
          city {
            id
            name
          }
          region
          customers {
            __typename
            id
            name
          }
          accounts {
            __typename
            id
            name
          }
          created_at
          updated_at
          deleted_at
          salesstages {
            id
            name
          }
        }
      }
      pageInfo {
        next
        current
        prev
        count
        total
      }
    }
  }
`

function mungeQuery(query) {
  const parsed = queryString.parse(query)
  function munge(prop, validation, defaultProp = undefined) {
    return validation.includes(prop)
      ? prop
      : !!defaultProp && validation.includes(defaultProp)
      ? defaultProp
      : validation[0]
  }
  const searchBy = munge(parsed.searchBy, [
    'name',
    'address',
    'city_name',
    'region',
    'phone',
    'contact',
    'account_name',
    'notes',
    'email',
  ])
  const showTrash = parsed.showTrash === 'true'
  return {
    searchBy,
    showTrash,
    searchSalesstage: parsed.searchSalesstage,
    searchSalesPerson: parsed.searchSalesPerson,
    searchCreatedUser: parsed.searchCreatedUser,
    q: parsed.q || '',
  }
}

function outputCSV({
  name,
  address,
  city_name,
  phone,
  region,
  email,
  notes,
  showTrash,
  searchSalesstage,
}) {
  const query = queryString.stringify({
    name: name,
    address: address,
    city_name: city_name,
    phone: phone,
    region: region,
    email: email,
    notes: notes,
    trashed: showTrash,
    salesstages: searchSalesstage,
  })
  csv(`/contacts?${query}`)
}

function Contacts({location, match}) {
  const {
    searchBy,
    q,
    showTrash,
    searchSalesstage,
    searchSalesPerson,
    searchCreatedUser,
    ...params
  } = mungeQuery(location.search)

  const [queryChanges, setQueryChanges] = useState<{
    q: string,
    searchBy: string,
    showTrash: boolean,
    searchSalesstage: string,
    searchSalesPerson: string,
    searchCreatedUser: string,
  }>({
    q,
    searchBy,
    searchSalesstage,
    searchSalesPerson,
    searchCreatedUser,
    showTrash,
  })

  const [debouncedSearch] = useDebounce(queryChanges.q, 300)
  const vars = {
    [searchBy]: q === '' ? undefined : q,
    showTrash,
    searchSalesstage,
    searchSalesPerson,
    searchCreatedUser,
    ...params,
  }

  const {data, loading, error, loadMore, refetch} = usePagination({
    query: ContactsQuery,
    variables: {
      cursor: '-1',
      ...vars,
    },
    fetchPolicy: 'cache-and-network',
  })

  return (
    <>
      <ContactToolbar
        searchBy={queryChanges.searchBy}
        q={queryChanges.q}
        // searchStatus={queryChanges.searchStatus}
        // searchTaxable={queryChanges.searchTaxable}
        // searchEmployeeContact={queryChanges.searchEmployeeContact}
        // searchSalesStatus={queryChanges.searchSalesStatus}
        searchSalesstage={queryChanges.searchSalesstage}
        searchSalesPerson={queryChanges.searchSalesPerson}
        searchCreatedUser={queryChanges.searchCreatedUser}
        onQueryChange={changes => setQueryChanges(c => ({...c, ...changes}))}
        match={match}
        onCSV={() => outputCSV(vars)}
      />
      <FormControlLabel
        style={{marginLeft: '2em'}}
        control={
          <WhiteBackgroundSwitch
            checked={showTrash}
            onChange={e =>
              setQueryChanges(c => ({...c, showTrash: e.target.checked}))
            }
            color="secondary"
          />
        }
        label={'Show Trash'}
      />
      <ModifyQueryParams query={{...queryChanges, q: debouncedSearch}} />
      <ContactList
        loading={loading}
        contacts={data && data.contacts && data.contacts.edges}
        pageInfo={data && data.contacts && data.contacts.pageInfo}
        loadMore={loadMore}
        refetch={refetch}
        showTrash={showTrash}
      />
    </>
  )
}
export default Contacts
