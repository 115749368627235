import React, { useEffect, useState } from 'react'
import { Toolbar } from '../../AppHandler'
import ToolbarGroup from '../../components/ToolbarGroup'
import ToolbarMegaTitle from '../../components/ToolbarMegaTitle'
import styled from 'styled-components'
import { DatePicker } from '@mui/lab'
import SelectMenu from '../../components/ToolbarDropDownMenu'
import {
  Typography,
  LinearProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  MenuItem,
  CircularProgress,
  InputLabel,
  TextField,
  IconButton,
  Collapse,
  Box,
  Select,
} from '@mui/material'
import { Space } from '../../components/Layout'
import { fetchAPI } from '../../schema/utils'
import moment from 'moment'
import { useQuery, useQueryClient } from 'react-query'
import _, { round } from 'lodash'
import { ArrowForward, Close, KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import queryString from 'query-string'
import ModifyQueryParams from '../../components/ModifyQueryParams'
import { formatMoneyStandard } from '../../utils/moneyFormatter'
import { TaxitemSelect } from '../../components/TaxitemsSelect'
import { VictoryAxis, VictoryBar, VictoryCandlestick, VictoryChart, VictoryLabel, VictoryLegend, VictoryLine, VictoryTheme, VictoryTooltip, VictoryVoronoiContainer } from 'victory'
import theme from '../../styles/theme'
import { gql, prgql } from '../../utils/graphql'
import colors from '../../styles/colors'

let SummaryWrapper = styled.div`
  page-break-after: always;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
`

let TableWrapper = styled.div`
  margin-top: 2em;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  min-width: 800px;
`

let ChartWrapper = styled.div`
  margin-top: 2em;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  min-width: 800px;
`

function Row({ meta, data, loading }) {
  const [open, setOpen] = React.useState(false);

  return loading ? <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
    <TableCell colSpan={6}><LinearProgress /></TableCell>
  </TableRow> : (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell align="left">{meta.start_date}</TableCell>
        <TableCell component="th" scope="row">
          {meta.cycle4_week}
        </TableCell>
        <TableCell component="th" scope="row">
          {formatMoneyStandard(data.average)}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Breakdown
              </Typography>
              <Table size="small" aria-label="breakdown">
                <TableHead>
                  <TableRow>
                    <TableCell align='center'>Route</TableCell>
                    <TableCell align='center'>Average</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.averageByRoute.map((row) => (
                    <TableRow key={row.route}>
                      <TableCell component="th" scope="row" align='center'>
                        {row.route}
                      </TableCell>
                      <TableCell align='center'>{formatMoneyStandard(row.performance)}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

let RouteSelect = ({ children, routes, selectedRoutes, setSelectedRoutes }) => (
  <Select
    variant="standard"
    label={'Routes'}
    name="routes"
    value={selectedRoutes}
    multiple
    input={
      <TextField variant="outlined" size="small" children={children} select />
    }
    // onChange={(e) => setSelectedRoutes(e.target.value)}
    onChange={setSelectedRoutes}
    renderValue={selected => {
      return (
        <div style={{ display: 'flex' }}>
          {selected
            .sort((a, b) => a - b)
            .map(v => {
              let r = routes.find(r => r.edge.id === String(v))
              return r && <ChipRoute key={v}>{r.edge.name}</ChipRoute>
            })}
        </div>
      )
    }}
    style={{ minWidth: '100px' }}
    children={children}
  />
)

let ChipRoute = styled.div`
  background-color: ${({ theme }) => theme.palette.secondary.main};
  color: ${({ theme }) => theme.palette.primary.contrastText};
  border-radius: 8px;
  padding: 0.25em;
  text-align: center;
  margin-right: 0.1em;
  min-width: 30px;
`

let getData = async ({ date }) => {
  const data = await fetchAPI({
    url: '/reports/average_routerun_income',
    query: {
      date: date.format('YYYY-MM-DD'),
    },
  })
  return await data.json()
}

let getHistory = async ({ from, to, route_group, group_by, selected_routes }) => {
  const data = await fetchAPI({
    url: '/reports/average_routerun_income_historical',
    query: {
      from: from.format('YYYY-MM-DD'),
      to: to.format('YYYY-MM-DD'),
      route_group,
      group_by,
      selected_routes: selected_routes.join(','),
    },
  })
  return await data.json()
}


let allRoutesQuery = gql`
  query AllRoutes {
    allRoutes(limit: 100) {
      edges {
        edge {
          id
          name
          active
        }
      }
    }
  }
`
let fetchRoutes = () => {
  return prgql({
    query: allRoutesQuery,
  })
}

const chart_timeframes = {
  '30day': {
    props: {
      from: moment().subtract(30, 'day'),
      group_by: 'day',
    },
    label: '30 days'
  },
  '90day': {
    props: {
      from: moment().subtract(90, 'day'),
      group_by: 'day',
    },
    label: '90 days'
  },
  year: {
    props: {
      from: moment().startOf('week').subtract(1, 'day').subtract(1, 'year'),
      group_by: 'week',
    },
    label: 'Year'
  },
}

let munge = ({ date, chart_timeframe, selectedRoutes }) => {
  date = !date ? moment() : moment(date)
  chart_timeframe = Object.keys(chart_timeframes).includes(chart_timeframe) ? chart_timeframe : 'year'
  selectedRoutes = selectedRoutes ? String(selectedRoutes).split(',') : []
  return { date, chart_timeframe, selectedRoutes }
}

const SchedulerReport = () => {
  let [queryChanges, setQueryChanges] = useState(
    queryString.parse(location.search),
  )
  let [lastClickedRoute, setLastClickedRoute] = useState({})

  let params = queryString.parse(location.search)
  let { date, chart_timeframe, selectedRoutes } = munge(params)
  const date2 = date.clone().add(1, 'week')
  const date3 = date.clone().add(2, 'week')
  const date4 = date.clone().add(3, 'week')

  const dateKey = date.format('YYYY-MM-DD')
  const dateKey2 = date2.format('YYYY-MM-DD')
  const dateKey3 = date3.format('YYYY-MM-DD')
  const dateKey4 = date4.format('YYYY-MM-DD')

  const { isLoading: firstWeekLoading, error: firstWeekError, data: firstWeek } = useQuery(
    ['incomePerMan', dateKey],
    () => getData({ date }),
  )
  const { isLoading: secondWeekLoading, error: secondWeekError, data: secondWeek } = useQuery(
    ['incomePerMan', dateKey2],
    () => getData({ date: date2 }),
  )
  const { isLoading: thirdWeekLoading, error: thirdWeekError, data: thirdWeek } = useQuery(
    ['incomePerMan', dateKey3],
    () => getData({ date: date3 }),
  )
  const { isLoading: fourthWeekLoading, error: fourthWeekError, data: fourthWeek } = useQuery(
    ['incomePerMan', dateKey4],
    () => getData({ date: date4 }),
  )

  let { data: allRoutes, isLoading } = useQuery('allRoutes', fetchRoutes)

  // just gonna hard code the routes for now
  const { isLoading: allHistoryLoading, error: historyError, data: allHistory } = useQuery(
    ['incomePerManHistory', chart_timeframe, selectedRoutes],
    () => getHistory({ ...chart_timeframes[chart_timeframe].props, to: moment().startOf('week').subtract(1, 'day'), selected_routes: selectedRoutes }),
  )
  const { isLoading: rollingAverageLoading, error: rollingAverageError, data: rollingAverage } = useQuery(
    ['rollingAverage', chart_timeframe, selectedRoutes],
    () => getHistory({ ...chart_timeframes[chart_timeframe].props, group_by: 'day', to: moment().startOf('week').subtract(1, 'day'), selected_routes: selectedRoutes }),
  )

  let routes = allRoutes ? allRoutes.allRoutes.edges : []

  return (
    <>
      <ModifyQueryParams query={queryChanges} />
      <Toolbar>
        <ToolbarMegaTitle>Scheduler's report </ToolbarMegaTitle>
        <Space inline size={2} />
        <ToolbarGroup last>
          <DatePicker
            value={date}
            onChange={d =>
              setQueryChanges({ ...queryChanges, date: d.format('YYYY-MM-DD') })
            }
            renderInput={props => <TextField {...props} size="small" />}
          />
        </ToolbarGroup>
      </Toolbar>

      <SummaryWrapper>
        <TableWrapper>
          <Typography variant="h6">Income Per Man</Typography>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>Week Of</TableCell>
                <TableCell>Cycle4 Week</TableCell>
                <TableCell>Average</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <Row {...firstWeek} loading={firstWeekLoading} />
              <Row {...secondWeek} loading={secondWeekLoading} />
              <Row {...thirdWeek} loading={thirdWeekLoading} />
              <Row {...fourthWeek} loading={fourthWeekLoading} />
            </TableBody>
          </Table>
        </TableWrapper>
        <Space sizePx={50} />
        <ChartWrapper>
          <Typography variant="h6">Historical Data</Typography>

          <div>
            <Select label="Timeframe" size="small" type='select' select variant='outlined' value={chart_timeframe} onChange={e => setQueryChanges({ ...queryChanges, chart_timeframe: e.target.value })} >
              {Object.entries(chart_timeframes).map(([k, v]) => <MenuItem key={k} value={k}>{v.label}</MenuItem>)}
            </Select>

            <Space sizePx={20} inline />

            {selectedRoutes.length > 0 &&
              <IconButton onClick={() => setQueryChanges({ ...queryChanges, selectedRoutes: [] })}><Close /></IconButton>
            }
            <RouteSelect
              routes={routes}
              setSelectedRoutes={(e, child) => {
                let checks = e.target.value
                if (!!lastClickedRoute.props && e.shiftKey) {
                  let routeIds = routes.map(r => r.edge.id)
                  let firstPos = routeIds.indexOf(
                    lastClickedRoute.props.value,
                  )
                  let secondPos = routeIds.indexOf(child.props.value)
                  let range = _.range(
                    Math.min(firstPos, secondPos),
                    Math.max(firstPos, secondPos),
                  )
                  let between = routeIds.filter((val, index) =>
                    range.includes(index),
                  )
                  if (checks.includes(child.props.value)) {
                    e.target.value = [
                      ...e.target.value,
                      ...between.filter(v => !e.target.value.includes(v)),
                    ]
                  } else {
                    e.target.value = e.target.value.filter(
                      v =>
                        !between.includes(v) &&
                        v !== lastClickedRoute.props.value,
                    )
                  }
                }
                setLastClickedRoute(child)
                setQueryChanges({ ...queryChanges, selectedRoutes: e.target.value.join(',') })
              }}
              selectedRoutes={selectedRoutes}
            >
              {routes &&
                routes.map(r => (
                  <MenuItem value={r.edge.id} key={r.edge.id}>
                    {r.edge.name}
                  </MenuItem>
                ))}
            </RouteSelect>
          </div>

          <Space sizePx={30} />
          {/* if customization gets too complicated use https://airbnb.io/visx/ */}
          {(allHistoryLoading || rollingAverageLoading) && <LinearProgress style={{ width: '100%', maxWidth: "1000px" }} />}
          <div style={{ width: '100%', maxWidth: "1000px", border: `1px solid ${theme.palette.info.muted}` }}>
            <VictoryChart
              height={400}
              width={1000}
              theme={VictoryTheme.material}
              domainPadding={{ x: 2, y: 10 }}
              scale={{ y: 'linear', x: 'linear' }}
              containerComponent={<VictoryVoronoiContainer
                style={{ maxWidth: 1000 }}
              />}
            >
              <VictoryAxis
                dependentAxis
                tickFormat={x => `$${x}`}
              />
              <VictoryAxis
                tickFormat={x => moment.unix(x).format('MM/DD/YYYY')}
              />
              {!!allHistory &&
                <VictoryLine
                  style={{ data: { strokeWidth: 1, stroke: colors.teal600 } }}
                  labels={({ datum }) =>
                    `$${datum['performance']}
                  ${moment(datum['run_at']).format('YYYY-MM-DD')}`}
                  labelComponent={<VictoryTooltip dy={-5} />}
                  data={allHistory}
                  x={'timestamp'}
                  y={'performance'}
                />
              }
              {!!rollingAverage &&
                <VictoryLine
                  style={{ data: { strokeWidth: 2, stroke: colors.bethanyBlue400 } }}
                  labels={({ datum }) =>
                    `$${datum['avg_performance']}
                ${moment(datum['run_at']).format('YYYY-MM-DD')}`}
                  labelComponent={<VictoryTooltip dy={-5} style={{ fontWeight: 'semibold' }} flyoutStyle={{ fill: colors.bethanyBlue50, stroke: 'black', strokeWidth: 2, fontWeight: 'bold' }} />}
                  data={rollingAverage}
                  x={'timestamp'}
                  y={'avg_performance'}
                />
              }
              <VictoryLegend
                title="Legend"
                centerTitle
                orientation="horizontal"
                gutter={20}
                style={{ border: { fill: 'transparent' } }}
                data={[
                  { name: 'Average production grouped by ' + chart_timeframes[chart_timeframe].props.group_by, symbol: { fill: colors.teal600 } },
                  { name: 'Rolling Average', symbol: { fill: colors.bethanyBlue400 } },
                ]}
              />
            </VictoryChart>
          </div>
        </ChartWrapper>
      </SummaryWrapper>
    </>
  )
}

export default SchedulerReport
