import Immutable, {Map} from 'immutable'
import {
  FETCH_INVENTORY_SUCCESS,
  CHECK_FOR_INVENTORY,
  CREATE_INVENTORY,
} from '../actions/inventoryActions'

const initialState = Map({
  noQuery: Map(),
  checkForInventory: '',
  newInventory: Map(),
})

export default function inventory(state = initialState, action) {
  switch (action.type) {
    case FETCH_INVENTORY_SUCCESS:
      let result
      const payload = Immutable.fromJS(action.payload)
      const query = payload.get('params')
      const prevResult = state.getIn([query, 'result'])
      const fetchedPage = payload.getIn(['meta', 'pagination', 'current_page'])
      const current_page = state.getIn([
        query,
        'meta',
        'pagination',
        'current_page',
      ])

      if (prevResult && fetchedPage > current_page) {
        result = prevResult.concat(payload.get('result'))
      } else {
        result = payload.get('result')
      }

      const queryData = Map({result, meta: payload.get('meta')})

      return state.set(query, queryData)

    case CHECK_FOR_INVENTORY:
      return state.set('checkForInventory', action.payload)

    case CREATE_INVENTORY:
      return state.set('newInventory', Map({name: action.payload}))

    default:
      return state
  }
}

