import {useState} from 'react'
import {useMutation, useQuery, useQueryClient} from 'react-query'
import {gql, prgql} from '../../utils/graphql'
import {
  Grid,
  Typography,
  Button,
  Paper,
  Tabs,
  Tab,
  Toolbar,
  FormControlLabel,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
} from '@mui/material'
import TableList from '../../components/Table/TableList'
import {Column} from 'react-virtualized'
import {usePagination} from '../../components/PaginationContainer'
import {Save, Settings} from '@mui/icons-material'
import {
  CloseButton,
  DeleteMenuItem,
  DetailToolbar,
  ToolbarCenter,
  ToolbarIconMenu,
  ToolbarLeft,
  ToolbarRight,
} from '../../components/Toolbar'
import {NavigateBack} from '../../components/Navigator'
import styled from 'styled-components'
import {Space} from '../../components/Layout'
import {Formik} from 'formik'
import {TextField} from '../../components/forms'
import {RedirectBack} from '../../components/Navigator'
import {Link} from 'react-router-dom'
import {PLACEHOLDER} from '../../constants/EmailTemplates'
import {Code} from '../../components/Code'
import toaster from '../../utils/toaster'

let query = gql`
  query Templates($limit: Int = 20, $cursor: String) {
    templates: allEmailtemplates(limit: $limit, cursor: $cursor) {
      edges {
        edge {
          id
          name
          body
          is_default
          is_system
        }
        cursor
      }
      pageInfo {
        next
        current
        prev
        count
        total
      }
    }
  }
`

let getQuery = gql`
  query Template($id: String!) {
    template: emailtemplates(id: $id) {
      id
      is_default
      is_system
      name
      body
    }
  }
`

let createMutation = gql`
  mutation Create($input: CreateEmailtemplatesInput) {
    createEmailtemplates(input: $input) {
      emailtemplates {
        id
      }
    }
  }
`

let updateMutation = gql`
  mutation Update($input: UpdateEmailtemplatesInput) {
    updateEmailtemplates(input: $input) {
      emailtemplates {
        id
        name
      }
    }
  }
`

let deleteMutation = gql`
  mutation Delete($id: String!) {
    deleteEmailtemplates(input: {id: $id}) {
      message
    }
  }
`

const EmailTemplates = () => {
  let {data, loading, loadMore} = usePagination({
    query: query,
    variables: {
      // order: 'name_ASC',
      cursor: '-1',
    },
  })

  function getRowCount() {
    if (!data || !data.templates || !data.templates.pageInfo) {
      return 9999
    }
    return data.templates.pageInfo.total
  }

  return (
    <>
      <TableList
        data={(data && data.templates && data.templates.edges) || []}
        infinite
        loadMoreRows={loadMore}
        rowCount={getRowCount()}
        wrapRow={({children, rowData}) => (
          <Link to={`/settings/templates/${rowData.id}`}>{children}</Link>
        )}
      >
        <Column
          dataKey="name"
          headerRenderer={({label}) => (
            <div style={{textAlign: 'center'}}>{label}</div>
          )}
          cellRenderer={data => (
            <Typography style={{display: 'flex', alignItems: 'center'}}>
              {data.rowData && data.rowData.is_system && <Settings />}
              {data.cellData}
            </Typography>
          )}
          label="Name"
          flexGrow={1}
          width={0}
        />
        <Column
          dataKey="body"
          headerRenderer={({label}) => (
            <div style={{textAlign: 'center'}}>{label}</div>
          )}
          cellRenderer={data => (
            <Typography variant="body2">{data.cellData}</Typography>
          )}
          label="Body"
          flexGrow={4}
          width={0}
        />
      </TableList>
    </>
  )
}
let PaperContainer = styled(Paper)`
  width: 80%;
  margin: 16px auto 0;
  padding: 2em;
`
let LeftIcon = styled.div`
  margin-right: ${({theme}) => theme.muiTheme.spacing(1)};
`

let Form = ({onSubmit, initial = {}, formikkey, onDelete = () => {}}) => {
  const [warn, setWarn] = useState(false)

  return (
    <>
      <Formik initialValues={initial} onSubmit={onSubmit} key={formikkey}>
        {({submitForm, values, setValues}) => (
          <>
            <DetailToolbar>
              <ToolbarLeft>
                <NavigateBack
                  defaultBack={location.pathname}
                  marker="EmailtemplateBack"
                >
                  <CloseButton />
                </NavigateBack>
              </ToolbarLeft>
              <ToolbarCenter>
                <Typography variant="h6">
                  {values.id ? 'Edit' : 'Add'} Template
                </Typography>
              </ToolbarCenter>
              <ToolbarRight>
                <Space sizePx={16} inline />
                <Button color="primary" onClick={submitForm}>
                  <LeftIcon>
                    <Save />
                  </LeftIcon>
                  Save
                </Button>
                <ToolbarIconMenu>
                  <DeleteMenuItem
                    onClick={() => setWarn(true)}
                    disabled={!values.id || values.is_system}
                  />
                </ToolbarIconMenu>
              </ToolbarRight>
            </DetailToolbar>
            <Grid container spacing={2}>
              <Grid item xs={10}>
                <TextField
                  fullWidth
                  label="Name"
                  name="name"
                  variant={'outlined'}
                  disabled={values.is_system}
                />
              </Grid>
              <Grid item xs={2}>
                <FormControlLabel
                  label="Is Default"
                  control={
                    <Checkbox
                      color="secondary"
                      name={'is_default'}
                      checked={values.is_default}
                      onChange={e =>
                        setValues({...values, is_default: e.target.checked})
                      }
                      fullWidth
                      label="Is Default"
                      disabled={values.is_system}
                    />
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Body"
                  name="body"
                  multiline
                  variant={`outlined`}
                />
              </Grid>
              <Grid item xs={12}>
                {Object.values(PLACEHOLDER).map(({value, description}, i) => (
                  <Typography style={{marginBottom: '.5em'}}>
                    <Code>{value}</Code>: {description}
                  </Typography>
                ))}
                <Typography style={{marginBottom: '.5em'}}>
                  <a href="https://commonmark.org/help/" target="_blank">
                    Markdown
                  </a>{' '}
                  syntax supported
                </Typography>
              </Grid>
            </Grid>
            <Dialog open={warn} onClose={() => setWarn(false)}>
              <DialogTitle>Delete Record</DialogTitle>
              <DialogContent>
                Are you sure you want to delete this record?
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setWarn(false)}>Cancel</Button>
                <Button onClick={() => onDelete(values.id)}>Delete</Button>
              </DialogActions>
            </Dialog>
          </>
        )}
      </Formik>
    </>
  )
}

export const EmailTemplate = ({match, location}) => {
  let [goBack, setGoBack] = useState(false)

  let queryClient = useQueryClient()
  let {data, isError, isLoading} = useQuery(
    ['single', match.params.id],
    async () =>
      await prgql({query: getQuery, variables: {id: match.params.id}}),
  )

  let {mutateAsync} = useMutation('update', async input => {
    return await prgql({
      query: updateMutation,
      variables: {input},
    })
  })

  let {mutateAsync: deleteTemplate} = useMutation('delete', async ({id}) => {
    return await prgql({
      query: deleteMutation,
      variables: {id},
    })
  })

  return (
    <>
      <PaperContainer>
        {isLoading ? (
          <div>Loading...</div>
        ) : isError ? (
          <div>Error</div>
        ) : (
          <Form
            formikkey={match.params.id}
            initial={data && data.template}
            onSubmit={async values => {
              let {is_system, ...saveValues} = values
              await mutateAsync(saveValues)
              await queryClient.invalidateQueries(['single', match.params.id])
              toaster.success('Template saved')
              setGoBack(true)
            }}
            onDelete={async id => {
              await deleteTemplate({id})
              toaster.success('Template deleted')
              setGoBack(true)
            }}
          />
        )}
      </PaperContainer>
      {/* This is extremely evil. we need a hook that'll do this */}
      {goBack && (
        <RedirectBack
          defaultBack={location.pathname}
          marker="EmailtemplateBack"
          location="/settings/templates"
        />
      )}
    </>
  )
}

export const NewEmailTemplate = ({match, location}) => {
  let [goBack, setGoBack] = useState(false)

  let {mutateAsync} = useMutation('create', async input => {
    return await prgql({
      query: createMutation,
      variables: {input},
    })
  })

  return (
    <>
      <PaperContainer>
        {
          <Form
            formikkey={match.params.id}
            initial={{
              name: '',
              body: '',
              type: 'AR_ANY',
            }}
            onSubmit={async values => {
              let {is_system, ...saveValues} = values
              await mutateAsync(saveValues)
              setGoBack(true)
              toaster.success('Template Created')
            }}
          />
        }
      </PaperContainer>
      {/* This is extremely evil. we need a hook that'll do this */}
      {goBack && (
        <RedirectBack
          defaultBack={location.pathname}
          marker="EmailtemplateBack"
        />
      )}
    </>
  )
}

export default EmailTemplates
