import React, {useState, useRef, useEffect} from 'react'
import styled from 'styled-components'
import urlJoin from 'url-join'

import MenuItem from '@mui/material/MenuItem'
import Tooltip from '@mui/material/Tooltip'
import Button from '@mui/material/Button'
import {
  IconButton,
  Popover,
  Box,
  Typography,
  InputLabel,
  TextField as MuiTextField,
  FormControl,
} from '@mui/material'

import PersonAdd from '@mui/icons-material/PersonAdd'
import Label from '@mui/icons-material/Label'
import {FilterList} from '@mui/icons-material'

import {Toolbar, ToolbarFab as Fab} from '../../AppHandler'
import {BILL, COD, PURCHASING_CARD} from '../../constants/TermCodes'
import Routes from '../../constants/Routes'
import {Redirect} from '../../pr-router'
import {PRThemeProvider} from '../../styles/theme'
import ToolbarGroup from '../../components/ToolbarGroup'
import SearchBox from '../../components/SearchBox'
import SelectMenu from '../../components/ToolbarDropDownMenu'
import {Space} from '../../components/Layout'
import {SalesstageSelect, SourceSelect} from '../../components/apiSelects'
import CustomersNav from './CustomersToolbarNav'
import {StopReasonSelectDynamic} from '../../components/StopReasonSelect'
import UsersSelect, {UserSelectDynamic} from '../../components/UsersSelect'
import {useAuth} from '../../security/auth'
import EditDetails from '../Customer/EditDetails'
import {useQueryClient} from 'react-query'
import {DatePicker} from '@mui/lab'
import moment from 'moment'
import {RouteSelectStatic} from '../../components/RouteSelect'

let SearchBoxGroup = styled(ToolbarGroup)`
  flex-grow: 1;
`

let StatusSelect = ({value = 'all', ...props}) => (
  <SelectMenu value={value} label="Status" {...props}>
    <MenuItem value="all">All Statuses</MenuItem>
    <MenuItem value="active">Active</MenuItem>
    <MenuItem value="inactive">Inactive</MenuItem>
  </SelectMenu>
)

let SalesStatusSelect = ({value = 'all', ...props}) => (
  <SelectMenu value={value} label="Sales" {...props}>
    <MenuItem value="all">All Statuses</MenuItem>
    <MenuItem value="lead">Lead</MenuItem>
    <MenuItem value="sold">Client</MenuItem>
  </SelectMenu>
)

let PendingSaleSelect = ({value = 'all', ...props}) => (
  <SelectMenu value={value} label="Pending Sale" {...props}>
    <MenuItem value="all">Any Status</MenuItem>
    <MenuItem value="true">Has Pending</MenuItem>
    <MenuItem value="false">All Confirmed</MenuItem>
  </SelectMenu>
)

let TypeSelect = ({value = 'all', ...props}) => (
  <SelectMenu value={value} label="Type" {...props}>
    <MenuItem value="all">All Types</MenuItem>
    <MenuItem value="commercial">Commercial</MenuItem>
    <MenuItem value="residential">Residential</MenuItem>
    <MenuItem value="large_commercial">Large Commercial</MenuItem>
  </SelectMenu>
)

let TermsSelect = ({value = 'all', ...props}) => (
  <SelectMenu value={value} label="Terms" {...props}>
    <MenuItem value="all">All Terms</MenuItem>
    <MenuItem value={BILL}>Bill</MenuItem>
    <MenuItem value={COD}>C.O.D.</MenuItem>
    <MenuItem value={PURCHASING_CARD}>Purchasing Card</MenuItem>
  </SelectMenu>
)

let TaxSelect = ({value = 'all', ...props}) => (
  <SelectMenu value={value} label="Tax" {...props}>
    <MenuItem value="all">All</MenuItem>
    <MenuItem value="true">Taxable</MenuItem>
    <MenuItem value="false">Tax Exempt</MenuItem>
  </SelectMenu>
)

let ContractSelect = ({value = 'all', ...props}) => (
  <SelectMenu value={value} label="Contract" {...props}>
    <MenuItem value="all">All</MenuItem>
    <MenuItem value="true">Has Contract</MenuItem>
    <MenuItem value="false">No Contract</MenuItem>
  </SelectMenu>
)

let RouteSelect = ({value = 'all', ...props}) => (
  <SelectMenu value={value} label="Route" {...props}>
    <MenuItem value="all">All Routes</MenuItem>
    {Routes.map(route => (
      <MenuItem key={route.id} value={route.id.toString()}>
        {' '}
        {'Route ' + route.id.toString()}
      </MenuItem>
    ))}
  </SelectMenu>
)

let CreatedYearSelect = ({value = 'all', ...props}) => (
  <SelectMenu value={value} label="Created Year" {...props}>
    <MenuItem value="all">All Years</MenuItem>
    <MenuItem value={moment().format('YYYY')}>
      {moment().format('YYYY')}
    </MenuItem>
    <MenuItem
      value={moment()
        .subtract(1, 'year')
        .format('YYYY')}
    >
      {moment()
        .subtract(1, 'year')
        .format('YYYY')}
    </MenuItem>
    <MenuItem
      value={moment()
        .subtract(2, 'year')
        .format('YYYY')}
    >
      {moment()
        .subtract(2, 'year')
        .format('YYYY')}
    </MenuItem>
  </SelectMenu>
)

let queryToLabels = {
  searchBy: {
    name: 'Customer.Name',
    address: 'Customer.Address',
    city_name: 'Cities.City',
    state: 'Customer.State',
    phone: 'Customer.Phone',
    contact: 'Customer.Contact',
    account_name: 'Accounts.Name',
    notes: 'Customer.Instructions',
  },
  searchStatus: {active: 'Active', inactive: 'Inactive'},
  searchType: {commercial: 1, residential: 2, large_commercial: 3},
}

function getFilterDescription({
  searchStatus,
  searchSalesStatus,
  searchType,
  searchTerms,
  searchRoute,
  searchSource,
  searchTaxable,
  searchContract,
  searchStopReason,
  searchSalesPerson,
  searchEmployeeContact,
  searchCreatedYear,
  searchSalesstage,
  searchPendingSale,
}) {
  let getLabel = (v, label) =>
    v === 'all' || v === undefined || v === null || v === '' ? '' : label
  let filters = [
    getLabel(searchStatus, 'Status'),
    getLabel(searchType, 'Type'),
    getLabel(searchTerms, 'Terms'),
    getLabel(searchRoute, 'Route'),
    getLabel(searchSource, 'Source'),
    getLabel(searchTaxable, 'Tax'),
    getLabel(searchContract, 'Contract'),
    getLabel(searchStopReason, 'Stop Reason'),
    getLabel(searchSalesPerson, 'Sales Person'),
    getLabel(searchEmployeeContact, 'Employee Contact'),
    getLabel(searchSalesStatus, 'Sales Status'),
    getLabel(searchCreatedYear, 'Created Year'),
    getLabel(searchSalesstage, 'Sales Stage'),
    getLabel(searchPendingSale, 'Pending Sale'),
  ]
  let showFilters = !!filters.join('')
  return showFilters
    ? `Filtering by: ${filters.filter(v => !!v).join('; ')}`
    : ''
}

function CustomersToolbar({
  location,
  searchQuery,
  searchStatus,
  searchSalesStatus,
  searchType,
  searchBy,
  searchTerms,
  searchRoute,
  searchSource,
  searchStopReason,
  searchSalesPerson,
  searchEmployeeContact,
  searchTaxable,
  searchContract,
  searchCreatedYear,
  searchSalesstage,
  searchPendingSale,
  match,
  onQueryChange = () => {},
  onCSV,
}) {
  let [addDialogIsOpen, setAddDialogIsOpen] = useState(false)
  let searchInput = useRef(null)
  let [filterEl, setFilterEl] = useState(null)
  let filtersOpen = !!filterEl
  let [redirectToId, setRedirectToId] = useState(null)

  let {status: canExportList} = useAuth('export customer list')

  let queryClient = useQueryClient()

  // focus search when searchBy changes
  useEffect(() => {
    searchInput.current &&
      searchInput.current.focus &&
      searchInput.current.focus()
  }, [searchBy])

  const createFilterSelector = key =>
    function(e) {
      onQueryChange({[key]: e.target.value})
    }

  let onSelectSearchBy = createFilterSelector('searchBy')
  let onSelectSearchStatus = createFilterSelector('searchStatus')
  let onSelectSearchSalesStatus = createFilterSelector('searchSalesStatus')
  let onSelectSearchType = createFilterSelector('searchType')
  let onSelectSearchTerms = createFilterSelector('searchTerms')
  let onSelectSearchRoute = createFilterSelector('searchRoute')
  let onSelectSearchTaxable = createFilterSelector('searchTaxable')
  let onSelectSearchContract = createFilterSelector('searchContract')
  let onSelectSearchCreatedYear = createFilterSelector('searchCreatedYear')
  let onSelectSearchSource = (e, s) =>
    onQueryChange({searchSource: s ? s.id : ''})
  let onSelectSearchStopReason = s =>
    onQueryChange({searchStopReason: s ? s.id : ''})
  let onSelectSearchSalesPerson = (e, s) =>
    onQueryChange({searchSalesPerson: s ? s.id : ''})
  let onSelectSearchEmployeeContact = (e, s) =>
    onQueryChange({searchEmployeeContact: s ? s.id : ''})
  let onSelectSearchSalesStage = (e, s) =>
    onQueryChange({searchSalesstage: s ? s.id : ''})
  let onSelectSearchPendingSale = createFilterSelector('searchPendingSale')

  let labelsUrl = `/old/database/printlabels.php?SearchField=${queryToLabels
    .searchBy[searchBy] || queryToLabels.searchBy.name}&Search=${searchQuery ||
    ''}&order=&active=${queryToLabels.searchStatus[searchStatus] || ''}&route=${
    searchRoute === 'all' ? '' : searchRoute
  }&type=${queryToLabels.searchType[searchType] || ''}&terms=${
    searchTerms === 'all' ? '' : searchTerms
  }&contract=${searchContract === 'all' ? '' : searchContract}`

  return redirectToId ? (
    <Redirect
      to={{
        pathname: '/customers/' + redirectToId,
        state: {
          backLocation: {
            pathname: location.pathname,
            query: location.query,
          },
        },
      }}
    />
  ) : (
    <Toolbar>
      <SearchBoxGroup>
        <CustomersNav location={location} />
        <Space inline size={2} />
        <SearchBox
          ref={searchInput}
          style={{flexGrow: 1}}
          value={searchQuery}
          onSearch={q => onQueryChange({q})}
          onEnter={() => {}}
          autoFocus
        />
        <Space inline />
        <SelectMenu
          value={searchBy}
          onChange={onSelectSearchBy}
          label="Search By"
        >
          <MenuItem value="name">Name</MenuItem>
          <MenuItem value="address">Street</MenuItem>
          <MenuItem value="city_name">City</MenuItem>
          <MenuItem value="region">State</MenuItem>
          <MenuItem value="phone">Phone</MenuItem>
          <MenuItem value="contact">Contact</MenuItem>
          <MenuItem value="account_name">Account</MenuItem>
          <MenuItem value="notes">Notes</MenuItem>
          <MenuItem value="email">Email</MenuItem>
        </SelectMenu>
      </SearchBoxGroup>
      <ToolbarGroup style={{justifyContent: 'flex-start'}}>
        <IconButton onClick={el => setFilterEl(el.currentTarget)} size="large">
          <FilterList />
        </IconButton>
        <Popover
          open={filtersOpen}
          anchorEl={filterEl}
          onClose={() => setFilterEl(null)}
          anchorOrigin={{vertical: 'bottom'}}
        >
          <Box p={2} style={{maxWidth: '650px'}}>
            <StatusSelect
              value={searchStatus}
              onChange={onSelectSearchStatus}
            />
            <Space inline />
            <TypeSelect value={searchType} onChange={onSelectSearchType} />
            <Space inline />
            <TermsSelect value={searchTerms} onChange={onSelectSearchTerms} />
            <Space inline />
            {/* <RouteSelect value={searchRoute} onChange={onSelectSearchRoute} /> */}
            <RouteSelectStatic
              value={searchRoute}
              onChange={onSelectSearchRoute}
            />
            <Space inline />
            <TaxSelect value={searchTaxable} onChange={onSelectSearchTaxable} />
            <Space inline />
            <ContractSelect
              value={searchContract}
              onChange={onSelectSearchContract}
            />
            <Space inline />
            <SalesStatusSelect
              value={searchSalesStatus}
              onChange={onSelectSearchSalesStatus}
            />
            <Space inline />
            <FormControl>
              <SourceSelect
                selectedId={searchSource}
                onChange={onSelectSearchSource}
                sx={{width: 227}}
                inputProps={{
                  variant: 'outlined',
                  size: 'small',
                  margin: 'dense',
                }}
              />
            </FormControl>
            <Space inline />
            <StopReasonSelectDynamic
              selectedId={searchStopReason}
              onChange={onSelectSearchStopReason}
              inputVariant="outlined"
              inputMargin="dense"
              fullWidth={false}
              frameStyle={{marginTop: '8px'}}
            />
            <Space inline />
            <FormControl>
              <UserSelectDynamic
                selectedId={searchSalesPerson}
                onChange={onSelectSearchSalesPerson}
                sx={{width: 227}}
                inputProps={{
                  variant: 'outlined',
                  size: 'small',
                  margin: 'dense',
                }}
                label={'Sales Person'}
              />
            </FormControl>
            <Space inline />
            <FormControl>
              <UserSelectDynamic
                selectedId={searchEmployeeContact}
                onChange={onSelectSearchEmployeeContact}
                sx={{width: 227}}
                inputProps={{
                  variant: 'outlined',
                  size: 'small',
                  margin: 'dense',
                }}
                label={'Employee Contact'}
              />
            </FormControl>
            <Space inline />
            <CreatedYearSelect
              value={searchCreatedYear}
              onChange={onSelectSearchCreatedYear}
            />
            <Space inline />
            <FormControl>
              <SalesstageSelect
                selectedId={searchSalesstage}
                onChange={onSelectSearchSalesStage}
                sx={{width: 227}}
                inputProps={{
                  variant: 'outlined',
                  size: 'small',
                  margin: 'dense',
                }}
                label={'Sales Stage'}
              />
            </FormControl>
            <Space inline />
            <PendingSaleSelect
              value={searchPendingSale}
              onChange={onSelectSearchPendingSale}
            />
          </Box>
        </Popover>
        <Typography variant="caption">
          {getFilterDescription({
            searchStatus,
            searchType,
            searchTerms,
            searchRoute,
            searchSource,
            searchTaxable,
            searchContract,
            searchStopReason,
            searchSalesPerson,
            searchEmployeeContact,
            searchSalesStatus,
            searchCreatedYear,
            searchSalesstage,
            searchPendingSale,
          })}
        </Typography>
      </ToolbarGroup>
      <ToolbarGroup last={true}>
        {canExportList === 'authorized' && (
          <>
            <Tooltip title="Labels">
              <IconButton tooltip="Labels" href={labelsUrl} size="large">
                <Label />
              </IconButton>
            </Tooltip>
            <Tooltip title="export to csv">
              <Button onClick={onCSV}>CSV</Button>
            </Tooltip>
          </>
        )}
      </ToolbarGroup>
      <Fab
        color="secondary"
        onClick={() => {
          setAddDialogIsOpen(true)
        }}
        id="newCustomer"
      >
        <PersonAdd />
      </Fab>
      <PRThemeProvider>
        <EditDetails
          open={addDialogIsOpen}
          onSubmit={id => {
            setAddDialogIsOpen(false)
            queryClient.refetchQueries(['customer'])
            setRedirectToId(id)
          }}
          onCancel={() => setAddDialogIsOpen(false)}
          view={match.path === '/leads' ? 'lead' : 'client'}
        />
      </PRThemeProvider>
    </Toolbar>
  )
}
export default CustomersToolbar
