import React, { useState } from 'react'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'

import Button from '@mui/material/Button'
import {
  Box,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  MenuItem,
  Select,
} from '@mui/material'

import theme from '../styles/theme'
import { Formik } from 'formik'
import {
  Checkbox,
  TextField,
  Radio,
  PhoneNumber,
  MultiSelect,
} from '../components/forms'
import LoadingButton from '../components/LoadingButton'
import Delete from '@mui/icons-material/Delete'
import Add from '@mui/icons-material/Add'
import { split } from 'lodash'
import * as Yup from 'yup'
import { Space } from './Layout'
import styled from 'styled-components'
import colors from '../styles/colors'

let _mungFormikCreate = values => {
  let creates = Object.values(
    Object.entries(values).reduce((carry, [key, value]) => {
      let [id, field] = key.split('-')
      if (
        id.indexOf('new') === -1 ||
        (field === 'number' && field === 'label' && value === '')
      ) {
        return carry
      }

      if (!carry[id]) {
        carry[id] = {}
      }

      if (field !== 'delete') {
        carry[id][field] = value
      }

      return carry
    }, {}),
  )

  creates = creates.filter(v => v.email !== '')

  return creates
}

let _mungFormikUpdate = values => {
  return Object.values(
    Object.entries(values).reduce((carry, [key, value]) => {
      let [id, field] = key.split('-')
      if (field === 'delete' || isNaN(Number(id))) {
        return carry
      }

      if (!carry[id]) {
        carry[id] = {}
      }

      if (field !== 'delete') {
        carry[id].id = id
        carry[id][field] = value
      }

      return carry
    }, {}),
  )
}

let _mungFormikDelete = values => {
  return Object.values(
    Object.entries(values).reduce((carry, [key, value]) => {
      let [id, field] = key.split('-')
      if (field !== 'delete') {
        return carry
      }

      if (!carry[id]) {
        carry[id] = {}
      }

      carry[id].id = id
      carry[id].type = 'phones'
      return carry
    }, {}),
  )
}

let settingsSchema = Yup.object().shape({
  statements_email_id: Yup.string().nullable().when('batch_output', {
    is: 'Email',
    then: () => Yup.string().required('Required'),
    otherwise: () => Yup.string()
  }),
  recorded_invoices_email_id: Yup.string().nullable().when('email_recorded_invoices', {
    is: true,
    then: () => Yup.string().required('Required'),
    otherwise: () => Yup.string()
  }),
})

let ShiftChip = styled(Chip).attrs(() => ({
  classes: {
    root: 'root',
  },
}))`
  &.root {
    background-color: ${({ dark }) => dark ? 'black' : 'white'};
    border: 2px solid;
    border-color: ${({ dark }) => dark ? colors.grey50 : colors.grey400}
    color: ${({ dark }) => dark ? colors.grey50 : colors.grey800}
    font-weight: bold;
    padding: 10px;
  }
`

let DocumentTypeToggle = ({ value, setValue }) => (
  <>
    {!value ? (
      <ShiftChip
        clickable
        label="PDF"
        onClick={() => setValue(true)}
        id="statement_format_type"
      />
    ) : (
      <ShiftChip
        clickable
        dark
        label="XLSX"
        onClick={() => setValue(false)}
        id="statement_format_type"
      />
    )}
  </>
)

// This component does NOT handle database update, just handling the form. Both Customer and Account have exactly the same settings with slightly different
// implementations, so I'm leaving the other screens to handle updating the server's state.

let EditCommunications = function({
  open,
  customer: {
    id,
    batch_output = 'Print',
    batch_output_attach_statement = true,
    batch_output_attach_invoices = false,
    batch_output_attach_workorders = false,
    batch_output_statement_as_excel = false,
    email_recorded_invoices = false,
    email_recorded_invoices_attach_invoices = 'Open',
    email_recorded_invoices_attach_workorders = false,
    emails = [],
    statements_email_id = null,
    recorded_invoices_email_id = null,
    attach_confirmation_photos_to_invoice_to_customer = false,
  },
  onSubmit,
  onCancel,
}) {
  let formikValues = {
    id,
    batch_output,
    batch_output_attach_statement,
    batch_output_attach_invoices,
    batch_output_attach_workorders,
    batch_output_statement_as_excel,
    statements_cc: emails.filter(e => e.cc_statements).map(e => e.id),
    email_recorded_invoices,
    email_recorded_invoices_attach_invoices,
    email_recorded_invoices_attach_workorders,
    statements_email_id,
    recorded_invoices_email_id,
    recorded_invoices_cc: emails
      .filter(e => e.cc_recorded_invoices)
      .map(e => e.id),
    attach_confirmation_photos_to_invoice_to_customer
  }

  let _mungeFormikUpdate = values => {
    let statementCC = values.statements_cc
    let invoicesCC = values.recorded_invoices_cc

    let munged = {
      ...values,
      emails: emails.map(e => {
        let statementCCEmail = statementCC.find(id => id === e.id)
        let invoicesCCEmail = invoicesCC.find(id => id === e.id)
        return {
          id: e.id,
          cc_statements:
            !!statementCCEmail &&
            values.statements_email_id !== e.id,
          cc_recorded_invoices:
            !!invoicesCCEmail &&
            values.recorded_invoices_email_id !== e.id,
        }
      }),
    }
    return munged
  }

  return (
    <Dialog open={!!open} maxWidth="md" fullWidth>
      <Formik
        initialValues={formikValues}
        // validationSchema={schema}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true)
          await onSubmit(_mungeFormikUpdate(values))
        }}
        validationSchema={settingsSchema}
      >
        {({
          isSubmitting,
          submitForm,
          values,
          setValues,
          isValid,
          setFieldValue,
          validateForm,
          setTouched,
        }) => {
          return (
            <>
              <DialogTitle>Edit Communication Settings</DialogTitle>
              <DialogContent>
                <Grid container spacing={2}>
                  <Grid item container md={6} direction={'column'}>
                    <Typography>Statements</Typography>
                    <Space />
                    <TextField
                      select
                      variant="outlined"
                      size="small"
                      name={'batch_output'}
                      label={'Batch Output'}
                      id='select-batch_output'
                    >
                      {/* For backwards compatibility; It really shouldn't work like this. */}
                      <MenuItem value={'None'}>None</MenuItem>
                      <MenuItem value={'Print'}>Print</MenuItem>
                      <MenuItem value={'Email'}>Email</MenuItem>
                    </TextField>
                    <Space />
                    <FormControlLabel
                      control={
                        <Checkbox name={'batch_output_attach_statement'} />
                      }
                      label={'Attach Statement'}
                    />
                    <Space />
                    <FormControlLabel
                      control={
                        <Checkbox name={'batch_output_attach_invoices'} />
                      }
                      label={'Attach Invoices'}
                    />
                    <Space />
                    <FormControlLabel
                      control={
                        <Checkbox name={'batch_output_attach_workorders'} />
                      }
                      label={'Attach Workorders'}
                    />
                    <Space />
                    <FormControlLabel
                      control={
                        <DocumentTypeToggle value={values.batch_output_statement_as_excel} setValue={v => setFieldValue('batch_output_statement_as_excel', v)} />
                      }
                      label={<>&nbsp;Document Type</>}
                    />
                    <Space />
                    <TextField
                      select
                      variant="outlined"
                      size="small"
                      name={'statements_email_id'}
                      label={'Email'}
                      id='select-statements_email_id'
                    >
                      {emails.map(e => (
                        <MenuItem key={e.id} value={e.id}>
                          {e.label}: {e.email}
                        </MenuItem>
                      ))}
                    </TextField>
                    <Space />
                    <MultiSelect
                      name={'statements_cc'}
                      variant={'outlined'}
                      label={'CC emails'}
                      fullWidth
                      id={'select-statements_cc'}
                      chip={value => {
                        let cc = emails.find(e => e.id === value)
                        return (
                          <Chip
                            key={cc.id}
                            label={cc.email}
                            style={{ margin: '.1em' }}
                          />
                        )
                      }}
                    >
                      {emails.map(e => (
                        <MenuItem value={e.id} key={e.id}>
                          {e.label}: {e.email}
                        </MenuItem>
                      ))}
                    </MultiSelect>
                  </Grid>
                  <Grid item container md={6} direction={'column'}>
                    <Typography>Recorded Invoices</Typography>
                    <Space />
                    <TextField
                      select
                      variant="outlined"
                      size="small"
                      name={'email_recorded_invoices'}
                      label={'After Marked Recorded'}
                      id='select-email_recorded_invoices'
                    >
                      {/* For backwards compatibility; It really shouldn't work like this. */}
                      <MenuItem value={false}>Do nothing</MenuItem>
                      <MenuItem value={true}>Email</MenuItem>
                    </TextField>
                    <Space />
                    <TextField
                      select
                      variant="outlined"
                      size="small"
                      name={'email_recorded_invoices_attach_invoices'}
                      label={'Attach Invoices'}
                      id='select-email_recorded_invoices_attach_invoices'
                    >
                      <MenuItem value={'None'}>None</MenuItem>
                      <MenuItem value={'Open'}>Open</MenuItem>
                      <MenuItem value={'All'}>All</MenuItem>
                    </TextField>
                    <Space />
                    <FormControlLabel
                      control={
                        <Checkbox
                          name={'email_recorded_invoices_attach_workorders'}
                        />
                      }
                      label={'Attach Workorders'}
                    />
                    <TextField
                      select
                      variant="outlined"
                      size="small"
                      name={'recorded_invoices_email_id'}
                      label={'Email'}
                      id='select-recorded_invoices_email_id'
                    >
                      {emails.map(e => (
                        <MenuItem key={e.id} value={e.id}>
                          {e.label}: {e.email}
                        </MenuItem>
                      ))}
                    </TextField>
                    <Space />
                    <MultiSelect
                      name={'recorded_invoices_cc'}
                      variant={'outlined'}
                      label={'CC emails'}
                      fullWidth
                      id={'select-recorded_invoices_cc'}
                      chip={value => {
                        let cc = emails.find(e => e.id === value)
                        return (
                          <Chip
                            key={cc.id}
                            label={cc.email}
                            style={{ margin: '.1em' }}
                          />
                        )
                      }}
                    >
                      {emails.map(e => (
                        <MenuItem value={e.id} key={e.id}>
                          {e.label}: {e.email}
                        </MenuItem>
                      ))}
                    </MultiSelect>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button onClick={onCancel}>Cancel</Button>
                <LoadingButton
                  variant="contained"
                  color="primary"
                  onClick={submitForm}
                  loading={isSubmitting}
                  disabled={!isValid}
                >
                  Save
                </LoadingButton>
              </DialogActions>
            </>
          )
        }}
      </Formik>
    </Dialog>
  )
}

export default EditCommunications
