import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import MenuItem from '@mui/material/MenuItem'

// Wrap MenuItem with Link to make them play nice together
class MenuItemLink extends Component {
  render() {
    let {to, external, replace, key, primaryText, onClick} = this.props
    return (
      <li onClick={onClick}>
        {external ? (
          <MenuItem component="a" href={to}>
            {primaryText}
          </MenuItem>
        ) : (
          <MenuItem
            component={to ? Link : undefined}
            to={to}
            replace={replace}
            key={key}
          >
            {primaryText}
          </MenuItem>
        )}
      </li>
    )
  }
}
MenuItemLink.muiName = MenuItem.muiName

export default MenuItemLink
