import React from 'react'
import styled from 'styled-components'
import {Column} from 'react-virtualized'
import gql from 'graphql-tag'
import {Query} from 'react-apollo'
import queryString from 'query-string'
import {Link} from 'react-router-dom'
import moment from 'moment'

import Chip from '@mui/material/Chip'
import Add from '@mui/icons-material/Add'
import Remove from '@mui/icons-material/Remove'
import MenuItem from '@mui/material/MenuItem'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'

import ErrorOutline from '@mui/icons-material/ErrorOutline'
import Done from '@mui/icons-material/Done'

import AttendanceCalendar from './AttendanceCalendar'
import PaginationContainer from '../../components/PaginationContainer'
import TableList from '../../components/Table/TableList'
import SelectMenu from '../../components/ToolbarDropDownMenu'
import AttendanceIcon from '../../components/icons/AttendanceIcon'
import {Spacing} from '../../components/Layout'
import {
  PaperToolbar,
  DetailRow,
  ToolbarLeft,
  ToolbarCenter,
  ToolbarRight,
} from '../../components/Toolbar'
import ModifyQueryParams from '../../components/ModifyQueryParams'
import urlJoin from '../../utils/url-join'

let TransactionChip = styled(({negative, ...props}) => (
  <Chip {...props} />
)).attrs(() => ({color: 'primary'}))`
  background-color: ${({negative, theme}) =>
    negative ? theme.palette.danger.light : theme.palette.primary.light};
`

let allQuery = gql`
  query attendanceQuery($cursor: String, $limit: Int = 25, $userId: String!) {
    attendance: allAttendance(
      cursor: $cursor
      limit: $limit
      orderBy: taken_at_ASC
      filters: {user_id: $userId}
    ) {
      pageInfo {
        total
      }
      edges {
        cursor
        edge {
          id
          taken_at
          hours
          type
          notes
        }
      }
    }
  }
`
let balanceFragment = gql`
  fragment Balance on AttendanceBalances {
    id
    attendance_id
    taken_at
    hours
    type
    notes
    balance
    paid
    automated
  }
`
let ptoQuery = gql`
  query sickRunningBalance(
    $cursor: String
    $limit: Int = 25
    $userId: String!
  ) {
    attendance: allPTOAttendance(
      cursor: $cursor
      limit: $limit
      userId: $userId
    ) {
      pageInfo {
        total
      }
      edges {
        cursor
        edge {
          ...Balance
        }
      }
    }
  }
  ${balanceFragment}
`
let sickQuery = gql`
  query sickRunningBalance(
    $cursor: String
    $limit: Int = 25
    $userId: String!
  ) {
    attendance: allSickPTOAttendance(
      cursor: $cursor
      limit: $limit
      userId: $userId
    ) {
      pageInfo {
        total
      }
      edges {
        cursor
        edge {
          ...Balance
        }
      }
    }
  }
  ${balanceFragment}
`
let leaveQuery = gql`
  query leaveRunningBalance(
    $cursor: String
    $limit: Int = 25
    $userId: String!
  ) {
    attendance: allLeavePTOAttendance(
      cursor: $cursor
      limit: $limit
      userId: $userId
    ) {
      pageInfo {
        total
      }
      edges {
        cursor
        edge {
          ...Balance
        }
      }
    }
  }
  ${balanceFragment}
`
let unpaidQuery = gql`
  query unpaidRunningBalance(
    $cursor: String
    $limit: Int = 25
    $userId: String!
  ) {
    attendance: allUnpaidAttendance(
      cursor: $cursor
      limit: $limit
      userId: $userId
    ) {
      pageInfo {
        total
      }
      edges {
        cursor
        edge {
          ...Balance
        }
      }
    }
  }
  ${balanceFragment}
`

let lateQuery = gql`
  query attendanceQuery($cursor: String, $limit: Int = 25, $userId: String!) {
    attendance: allAttendance(
      cursor: $cursor
      limit: $limit
      orderBy: taken_at_ASC
      filters: {user_id: $userId, type: LATE}
    ) {
      pageInfo {
        total
      }
      edges {
        cursor
        edge {
          id
          taken_at
          hours
          type
          notes
        }
      }
    }
  }
`

let excusedQuery = gql`
  query attendanceQuery($cursor: String, $limit: Int = 25, $userId: String!) {
    attendance: allAttendance(
      cursor: $cursor
      limit: $limit
      orderBy: taken_at_ASC
      filters: {user_id: $userId, type: EXCUSED}
    ) {
      pageInfo {
        total
      }
      edges {
        cursor
        edge {
          id
          taken_at
          hours
          type
          notes
        }
      }
    }
  }
`
let hoursBalanceQuery = gql`
  query hoursBalance($userId: String!) {
    users(id: $userId) {
      id
      attendance_statistics {
        id
        pto_hours
        unpaid_hours
      }
    }
  }
`

let HoursBalances = ({userId}) => (
  <Query
    query={hoursBalanceQuery}
    variables={{userId}}
    fetchPolicy="cache-and-network"
  >
    {({loading, error, data}) =>
      loading ? (
        <div>Loading...</div>
      ) : (
        <Spacing>
          <Chip
            icon={<AttendanceIcon type="PTO" />}
            label={data.users && data.users.attendance_statistics.pto_hours}
          />
          <Chip
            icon={<AttendanceIcon type="UNPAID" />}
            label={data.users && data.users.attendance_statistics.unpaid_hours}
          />
        </Spacing>
      )
    }
  </Query>
)

let capitalize = (s = '') =>
  s.slice(0, 1).toUpperCase() + s.slice(1).toLowerCase()

let BEHAVIOR_TYPES = ['LATE', 'UNEXCUSED', 'EXCUSED']

let TypeRow = styled.div`
  display: flex;
  align-items: center;

  & > * + * {
    margin-left: 8px;
  }
`

class Attendance extends React.Component {
  state = {
    queryChanges: null,
  }

  render() {
    let {match, location} = this.props
    let {params = {}} = match
    let {attendanceType = 'all'} = queryString.parse(location.search)
    let queries = {
      all: allQuery,
      pto: ptoQuery,
      sick: sickQuery,
      leave: leaveQuery,
      unpaid: unpaidQuery,
      late: lateQuery,
      excused: excusedQuery,
    }
    return (
      <React.Fragment>
        <PaperToolbar>
          <DetailRow>
            <ToolbarLeft>
              <SelectMenu
                value={attendanceType}
                onChange={e =>
                  this.setState({
                    queryChanges: {attendanceType: e.target.value},
                  })
                }
              >
                <MenuItem value="calendar">Attendance Calendar</MenuItem>
                <MenuItem value="all">All Attendance</MenuItem>
                <MenuItem value="unpaid">Unpaid Time Off</MenuItem>
                <MenuItem value="pto">PTO</MenuItem>
                <MenuItem value="sick">PTO - Sick</MenuItem>
                <MenuItem value="leave">PTO - Paid Leave</MenuItem>
                <MenuItem value="late">Late</MenuItem>
                <MenuItem value="excused">Excused</MenuItem>
              </SelectMenu>
            </ToolbarLeft>
            <ToolbarCenter>
              <HoursBalances userId={params.id} />
            </ToolbarCenter>
            <ToolbarRight>
              <Link to={urlJoin`${match.url}new`}>
                <Button variant="contained" color="secondary">
                  <Add />
                </Button>
              </Link>
            </ToolbarRight>
          </DetailRow>
        </PaperToolbar>
        <ModifyQueryParams query={this.state.queryChanges} />
        {attendanceType === 'calendar' ? (
          <AttendanceCalendar />
        ) : (
          <PaginationContainer
            key={attendanceType}
            query={queries[attendanceType]}
            variables={{userId: params.id}}
            children={({attendance, loadMore}) => (
              <TableList
                data={attendance ? attendance.edges : []}
                infinite
                startBottom
                loadMoreRows={loadMore}
                wrapRow={({children, rowData}) => (
                  <Link
                    to={urlJoin`${match.url}${rowData &&
                      rowData[
                        attendanceType === 'all' ? 'id' : 'attendance_id'
                      ]}`}
                    key={rowData?.id}
                  >
                    {children}
                  </Link>
                )}
                rowCount={attendance ? attendance.pageInfo.total : 0}
              >
                <Column
                  dataKey="taken_at"
                  label="Date"
                  width={180}
                  cellRenderer={({cellData}) => (
                    <Typography variant="h6" align="right">
                      {moment.utc(cellData).format('M/D/YYYY, ddd.')}
                    </Typography>
                  )}
                />
                <Column
                  dataKey="type"
                  label="Type"
                  width={140}
                  cellRenderer={({cellData}) => {
                    let formatType = t =>
                      t === 'PERSONAL' ? 'Unpaid Day Off' : capitalize(t)
                    return (
                      <TypeRow>
                        <AttendanceIcon type={cellData} />
                        <Typography variant="subtitle1">
                          {formatType(cellData)}
                        </Typography>
                      </TypeRow>
                    )
                  }}
                />
                {attendanceType === 'pto' && (
                  <Column
                    dataKey="paid"
                    label="Payroll Status"
                    width={140}
                    cellRenderer={({cellData, rowData}) => {
                      return (
                        rowData &&
                        rowData.hours < 0 &&
                        !rowData.automated && (
                          <TypeRow>
                            {cellData ? <Done /> : <ErrorOutline />}
                            <Typography variant="subtitle1">
                              {cellData ? 'Paid' : 'Unpaid'}
                            </Typography>
                          </TypeRow>
                        )
                      )
                    }}
                  />
                )}
                <Column
                  dataKey="notes"
                  label="Notes"
                  width={0}
                  flexGrow={1}
                  cellRenderer={({cellData}) => (
                    <Typography variant="body1">{cellData}</Typography>
                  )}
                />

                {attendanceType !== 'late' && attendanceType !== 'excused' && (
                  <Column
                    dataKey="hours"
                    label="Earned"
                    width={80}
                    cellRenderer={({cellData, rowData}) =>
                      rowData &&
                      // Only show if one of the balance types
                      !BEHAVIOR_TYPES.includes(rowData.type) && (
                        <TransactionChip
                          negative={cellData < 0}
                          icon={cellData < 0 ? <Remove /> : <Add />}
                          label={Math.abs(cellData)}
                        />
                      )
                    }
                  />
                )}
                {attendanceType !== 'all' &&
                  attendanceType !== 'late' &&
                  attendanceType !== 'excused' && (
                    <Column dataKey="balance" label="Balance" width={60} />
                  )}
              </TableList>
            )}
          />
        )}
      </React.Fragment>
    )
  }
}
export default Attendance
