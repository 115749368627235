import React from 'react'

import { ListItemText, InputLabel, MenuItem } from '@mui/material'
import 'react-select/dist/react-select.css'
import gql from 'graphql-tag'
import AutoComplete, { useAutoCompleteEndpoint } from './AutoComplete'
import SelectMenu from '../components/ToolbarDropDownMenu'
import { useField, useFormikContext } from 'formik'

let gqlFragment = gql`
  fragment Paymenttype on Paymenttypes {
    id
    type
  }
`

function PaymentTypeSelect({ onChange, selectedId, ...props }) {
  let autoProps = useAutoCompleteEndpoint({
    gqlFragment,
    selectedId,
    searchField: 'type',
  })

  return (
    <AutoComplete
      {...autoProps}
      onChange={onChange}
      itemToString={u => u.type}
      renderItem={({ item }) => (
        <ListItemText id={'route-' + item.id} primary={item.type} />
      )}
      renderNoItems={() => <ListItemText primary={'No Types found'} />}
      label={<InputLabel>Payment Type</InputLabel>}
      hideIcon
      {...props}
    />
  )
}

export let PaymentTypeSelectStatic = ({
  value = '1',
  filterOptions,
  idKey = 'paymenttype',
  name,
  inputProps,
  ...props
}) => {
  let [{ ...field }, { touched, error }] = useField(name)
  let { isSubmitting, setFieldValue } = useFormikContext()

  let { data, loading } = useAutoCompleteEndpoint({
    gqlFragment,
    selectedId: value,
    searchField: 'type',
  })

  if (filterOptions === undefined) {
    filterOptions = () => true
  }

  inputProps = {
    ...field,
    helperText: touched && error,
    error: touched && error,
    ...inputProps
  }

  return loading ? (
    <SelectMenu value={value} label="Payment Type"
      helperText={touched && error}
      error={touched && error}
      disabled={isSubmitting}
      inputProps={inputProps}
      {...props}
    >
      <MenuItem disabled>...loading...</MenuItem>
    </SelectMenu>
  ) : (
    <SelectMenu value={value} label="Payment Type"
      helperText={touched && error}
      error={touched && error}
      disabled={isSubmitting}
      inputProps={inputProps}
      {...props}
    >
      {data.filter(filterOptions).map(({ edge: type }) => (
        <MenuItem key={type.id} value={type.id} id={`${idKey}-${type.id}`}>
          {' '}
          {type.type.toString()}
        </MenuItem>
      ))}
    </SelectMenu>
  )
}

export default PaymentTypeSelect
