import React, {useState} from 'react'
import {useQuery, useMutation, useQueryClient} from 'react-query'
import {gql} from 'graphql-tag' // Added gql import
import styled from 'styled-components' // Added styled-components import
import {prgql} from '../../utils/graphql'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  TableFooter,
  Link,
  Tabs,
  Tab,
  Checkbox,
  FormLabel,
  FormControl,
  FormControlLabel,
  TableSortLabel,
  TextField,
} from '@mui/material'
import {Toolbar} from '../../AppHandler' // Added Toolbar import
import {Space} from '../../components/Layout'
import {formatMoneyStandard} from '../../utils/moneyFormatter'
import DangerLoadingButton from '../../components/DangerLoadingButton'
import multicheck from '../../utils/multicheck'
import {ArrowDownward, ArrowDropDown, ArrowRight} from '@mui/icons-material'
import ModifyQueryParams from '../../components/ModifyQueryParams'
import queryString from 'query-string'
import moment from 'moment'
import {DatePicker} from '@mui/lab'
import _ from 'lodash'
import RecordStatusDialog from '../../components/RecordStatusDialog'
import {fetchAPI} from '../../schema/utils'
import {
  ToolbarCenter,
  ToolbarIconMenu,
  ToolbarLeft,
  ToolbarMenuItem,
  ToolbarRight,
} from '../../components/Toolbar'
import {useAuth} from '../../security/auth'
import {useCurrentUser} from '../../security/auth'

let query = gql`
  query UnrecordedPayments {
    allPayments(
      filters: {recordable: true, paymenttype: "1,2,3,6"}
      limit: 1000000
    ) {
      edges {
        edge {
          id
          accountpayment_id
          paymenttype_id
          workorder_id
          paid_at
          paymenttype {
            id
            type
            qb_id
          }
          customer {
            id
            name
            taxable
            taxitem_id
            account_id
            account {
              id
              account_job_id
            }
            taxitem {
              id
              name
            }
          }
          charge: amount
          created_user_id
          created: created_user {
            id
            first_name
            last_name
          }
          recorded
        }
      }
    }
  }
`

let DashboardWrapper = styled.div`
  padding: 8px;
  grid-column-gap: 8px;
  display: flex;
  flex-direction: column;
  max-width: 1200px;
`
let selectedFilter = ({checks, payment}) =>
  checks[payment.id] === undefined || checks[payment.id] === true
let taxitemAll = ({checks = {}, payments = {}}) =>
  payments.every(payment => selectedFilter({payment, checks}))
let taxitemSome = ({checks = {}, payments = {}}) =>
  payments.some(payment => selectedFilter({payment, checks}))

let Details = ({flatData, checks, onCheck, onCheckMany, sort, onSort, tab}) => {
  const sortedRowKeys = flatData.flatMap(row =>
    row.payments.map(payment => payment.id),
  )

  let _allChecked = function() {
    let allChecked = true
    flatData.forEach(row => {
      row.payments.forEach(payment => {
        if (!selectedFilter({payment, checks})) {
          allChecked = false
        }
      })
    })
    return allChecked
  }
  let allChecked = _allChecked()
  let _someChecked = function() {
    let allChecked = false
    flatData.forEach(row => {
      row.payments.forEach(payment => {
        if (selectedFilter({payment, checks})) {
          allChecked = true
        }
      })
    })
    return allChecked
  }
  let someChecked = _someChecked()

  const [sortCol, sortDir] = sort.split('_')

  return (
    <>
      <Typography variant="h6" style={{alignSelf: 'center'}}>
        Details
      </Typography>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Payment Type</TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortCol === 'name'}
                  direction={sortDir}
                  onClick={e => onSort('name')}
                >
                  <Checkbox
                    color="secondary"
                    id="checkAll"
                    checked={allChecked}
                    onClick={e =>
                      onCheckMany({
                        rowKeys: sortedRowKeys,
                        checked:
                          someChecked && !allChecked ? false : e.target.checked,
                      })
                    }
                    indeterminate={someChecked && !allChecked}
                    name="allbox"
                  />
                  Customer Name
                </TableSortLabel>
              </TableCell>
              <TableCell>Payment</TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortCol === 'total'}
                  direction={sortDir}
                  onClick={e => onSort('total')}
                >
                  Total
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortCol === 'taxitem'}
                  direction={sortDir}
                  onClick={e => onSort('taxitem')}
                >
                  Tax Authority
                </TableSortLabel>
              </TableCell>
              {tab === 'all' && (
                <TableCell>
                  <TableSortLabel
                    active={sortCol === 'created'}
                    direction={sortDir}
                    onClick={e => onSort('created')}
                  >
                    User
                  </TableSortLabel>
                </TableCell>
              )}
              {tab === 'all' && (
                <TableCell>
                  <TableSortLabel
                    active={sortCol === 'date'}
                    direction={sortDir}
                    onClick={e => onSort('date')}
                  >
                    Date
                  </TableSortLabel>
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {flatData.map((row, index) => (
              <React.Fragment key={index}>
                <TableRow
                  style={{
                    backgroundColor: index % 2 === 0 ? '#f2f2f2' : 'white',
                  }}
                >
                  <TableCell rowSpan={row.payments.length + 1}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={taxitemAll({checks, payments: row.payments})}
                          color="secondary"
                          onClick={e =>
                            onCheckMany({
                              rowKeys: row.payments.map(payment => payment.id),
                              checked:
                                taxitemSome({checks, payments: row.payments}) &&
                                !taxitemAll({checks, payments: row.payments})
                                  ? false
                                  : e.target.checked,
                            })
                          }
                          indeterminate={
                            taxitemSome({checks, payments: row.payments}) &&
                            !taxitemAll({checks, payments: row.payments})
                          }
                          name={row.groupName}
                        />
                      }
                      label={`${row.groupName}`}
                    />
                  </TableCell>
                </TableRow>
                {row.payments.map(payment => (
                  <TableRow
                    key={payment.id}
                    style={{
                      backgroundColor: index % 2 === 0 ? '#f2f2f2' : 'white',
                    }}
                  >
                    <TableCell>
                      <Checkbox
                        id={payment.id}
                        color="secondary"
                        checked={
                          checks[payment.id] === undefined
                            ? true
                            : checks[payment.id]
                        }
                        onClick={e =>
                          onCheck({e, sortedRowKeys, rowKey: payment.id})
                        }
                      />
                      {payment.customer.name}
                    </TableCell>
                    <TableCell>
                      <Link
                        href={
                          payment.accountpayment_id
                            ? `/accounts/${payment.customer.account_id}/transactions/payments/${payment.accountpayment_id}`
                            : `/customers/${payment.customer.id}/transactions/payments/${payment.id}&JobID=`
                        }
                        target="_blank"
                        underline="hover"
                      >
                        {payment.accountpayment_id
                          ? `Account Payment ${payment.accountpayment_id}`
                          : `Payment ${payment.id}`}
                      </Link>
                    </TableCell>
                    <TableCell>{formatMoneyStandard(payment.charge)}</TableCell>
                    <TableCell>
                      {payment.customer.taxitem.name}{' '}
                      {!payment.customer.taxable && '(Exempt)'}
                    </TableCell>
                    {tab === 'all' && (
                      <TableCell>
                        {!payment.workorder_id
                          ? 'Technician'
                          : !payment.created
                          ? 'No User'
                          : `${payment.created.first_name} ${payment.created.last_name}`}
                      </TableCell>
                    )}
                    {tab === 'all' && (
                      <TableCell>
                        {!payment.paid_at
                          ? ''
                          : moment.utc(payment.paid_at).format('MM/DD/YY')}
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

const RecordPaymentToolbar = ({tabs, tab, onTabClick}) => {
  // You know that meme with the three headed dragon and the one on the right is cross eyed and tongue out? That's me.
  // Also, I hate JS. #phpmasterrace
  const tabsLookup = {
    ...tabs.reduce(
      (acc, t, index) => ({
        ...acc,
        [t.tabId]: index,
      }),
      {},
    ),
  }
  const tabsReverseLookup = Object.keys(tabsLookup).reduce((ret, key) => {
    ret[tabsLookup[key]] = key
    return ret
  }, {})

  return (
    <>
      <Toolbar>
        <ToolbarLeft style={{justifyContent: 'flex-start', flex: 2}}>
          <Typography variant="h6">Record Payment</Typography>
        </ToolbarLeft>
        <ToolbarRight style={{justifyContent: 'flex-end', flex: 2}}>
          <ToolbarIconMenu>
            <ToolbarMenuItem
              component="a"
              href="/old/database/recordpayment.php"
              label="Old recordpayments"
            />
            <ToolbarMenuItem
              component="a"
              href="/old/database/recordpaymentinfield.php"
              label="Old Technician Recordpayments"
            />
          </ToolbarIconMenu>
        </ToolbarRight>
      </Toolbar>
      <Toolbar>
        <Tabs
          value={tabsLookup[tab]}
          onChange={(v, newValue) => onTabClick(tabsReverseLookup[newValue])}
          indicatorColor="secondary"
          textColor="inherit"
        >
          {/* <Tab label="All Unrecorded" key={'all'} /> */}
          {tabs.map((t, index) => (
            <Tab key={t.tabId} label={t.tabName} />
          ))}
        </Tabs>
      </Toolbar>
    </>
  )
}

const SummaryText = styled.span`
  color: ${props => (props.fade ? 'rgba(0, 0, 0, 0.5)' : 'black')};
  font-weight: ${props => (props.bold ? '500' : 'normal')};
`

const filterDisplayedData = (row, tab) => {
  if (tab === 'all') {
    return row
  } else if (tab === 'tech') {
    return !!row.edge.workorder_id
  } else {
    return (row.edge.created_user_id || '0') === tab && !row.edge.workorder_id
  }
}

const filterDateIfTechnician = (row, tab, date) => {
  if (tab !== 'tech') {
    return true
  }

  return moment.utc(row.edge.paid_at).isSame(date, 'day')
}

const sorts = {
  name_asc: (a, b) => a.customer.name.localeCompare(b.customer.name),
  name_desc: (a, b) => b.customer.name.localeCompare(a.customer.name),
  taxitem_asc: (a, b) => {
    const aName =
      a.customer.taxitem.name + (a.customer.taxable ? '' : ' (Exempt)')
    const bName =
      b.customer.taxitem.name + (b.customer.taxable ? '' : ' (Exempt)')
    return aName.localeCompare(bName)
  },
  taxitem_desc: (a, b) => {
    const aName =
      a.customer.taxitem.name + (a.customer.taxable ? '' : ' (Exempt)')
    const bName =
      b.customer.taxitem.name + (b.customer.taxable ? '' : ' (Exempt)')
    return bName.localeCompare(aName)
  },
  total_asc: (a, b) => (a.charge > b.charge ? 1 : -1),
  total_desc: (a, b) => (b.charge > a.charge ? 1 : -1),
  created_asc: (a, b) =>
    Number.parseInt(a.created_user_id || 0) >
    Number.parseInt(b.created_user_id || 0)
      ? 1
      : -1,
  created_desc: (a, b) =>
    Number.parseInt(b.created_user_id || 0) >
    Number.parseInt(a.created_user_id || 0)
      ? 1
      : -1,

  date_asc: (a, b) => (moment(a.paid_at).isAfter(b.paid_at) ? 1 : -1),
  date_desc: (a, b) => (moment(b.paid_at).isAfter(a.paid_at) ? 1 : -1),
}

function munge(params) {
  let {tab, showDetails, sort, date} = params
  tab = tab ? String(tab) : ''

  showDetails = showDetails === undefined ? false : showDetails !== 'false'

  sort = Object.keys(sorts).includes(sort) ? sort : 'name_asc'

  date = !!date ? moment.utc(date) : moment.utc().subtract(1, 'day')

  return {tab, showDetails, sort, date}
}

const RecordPayments = ({location}) => {
  let [confirm, setConfirm] = useState(false)
  let [statusDialog, setStatusDialog] = useState(false)
  let [status, setStatus] = useState('SUCCESS')
  let [errors, setErrors] = useState([])
  let [checks, setChecks] = useState({})
  let [lastCheck, setLastCheck] = useState('')
  let [queryChanges, setQueryChanges] = useState(
    queryString.parse(location.search),
  )
  let queryClient = useQueryClient()
  let params = queryString.parse(location.search)
  let {tab, showDetails, sort, date} = munge(params)

  const _sortHandler = column => {
    const [sortCol, sortDir] = sort.split('_')

    let newSortDir = 'asc'
    if (column === sortCol) {
      newSortDir = sortDir === 'asc' ? 'desc' : 'asc'
    }

    setQueryChanges({...params, sort: `${column}_${newSortDir}`})
  }

  const {data, isLoading, error} = useQuery('unrecordedPayments', () =>
    prgql({query}),
  )
  const {mutateAsync: record, mutate: recordOld} = useMutation(ids =>
    fetchAPI({
      url: '/recordedpayments/record?' + (ids ? 'ids=' + ids.join(',') : ''),
      options: {method: 'POST'},
    }),
  )
  const {mutateAsync: transmit} = useMutation(id =>
    fetchAPI({
      url: `/recordedpayments/${id}/transmit`,
      options: {method: 'POST'},
    }),
  )
  const {status: canSeeAllTabs} = useAuth('manage recordpayments screen')
  const {currentUser} = useCurrentUser()

  async function handleConfirm(ids) {
    setConfirm(false)
    setStatusDialog(true)

    // recording
    setStatus('RECORDING')
    let res
    try {
      res = await record(ids)
    } catch (error) {
      console.log(error)
      setStatus('PR-FAILURE')
      setErrors([{title: 'API failure', detail: error.message}])
      return
    }

    let json = await res.json()
    if (!res.ok) {
      setStatus('PR-FAILURE')
      setErrors(json.errors)
      return
    }
    queryClient.refetchQueries('unrecordedPayments')

    // parse the id of the returned object
    let id = json.data.id

    // transmitting
    setStatus('TRANSMITTING')
    try {
      res = await transmit(id)
    } catch (e) {
      console.log(e)
      setStatus('PR-FAILURE')
      setErrors([{title: 'API failure', detail: e.message}])
      return
    }

    json = await res.json()
    if (!res.ok) {
      setStatus('QB-FAILURE')
      setErrors(json.errors)
      return
    }

    setStatus('SUCCESS')
    return
  }

  if (isLoading) return <p>Loading...</p>

  // Group data by taxitem_id and taxable fields
  const groupedDataSummary = {}
  let groupedData = {}
  let tabsArray = []
  if (data && data.allPayments && data.allPayments.edges) {
    // get the unique users for the tabs
    const tabs = new Map()
    data.allPayments.edges.forEach(edge => {
      const tabId = edge.edge.workorder_id
        ? 'tech'
        : edge.edge.created_user_id || '0'
      let tabName = 'No User'
      if (edge.edge.created) {
        tabName = `${edge.edge.created.first_name} ${edge.edge.created.last_name}`
      }
      if (edge.edge.workorder_id) {
        tabName = `Technician`
      }
      tabs.set(tabId, {tabId, tabName})
    })

    tabsArray.push({tabId: 'all', tabName: 'All Unrecorded'})
    tabsArray = tabsArray.concat(Array.from(tabs.values()))
    tabsArray = tabsArray.filter(tab => {
      if (tab.tabId === 'tech') {
        return true
      }

      if (canSeeAllTabs === 'authorized') {
        return true
      }

      if (
        currentUser === 'error' ||
        currentUser === null ||
        tab.tabId !== currentUser.id
      ) {
        return false
      }

      return true
    })

    if (tab === '') {
      if (canSeeAllTabs === 'authorized') {
        tab = 'all'
      } else if (!!currentUser && currentUser.id) {
        tab = currentUser.id
      }
    }

    let filteredData = data.allPayments.edges
      .filter(e => filterDisplayedData(e, tab))
      .filter(e => filterDateIfTechnician(e, tab, date))

    filteredData.forEach(edge => {
      const {customer, charge, id} = edge.edge
      const isSelected = checks[id] === undefined ? true : checks[id]
      const taxItemId = customer.taxitem_id
      const isTaxable = customer.taxable
      const taxItemName = customer.taxitem.name
      const exemptSuffix = isTaxable ? '' : ' (Exempt)'
      const key = `${taxItemId}-${isTaxable}`
      if (!groupedDataSummary[key]) {
        groupedDataSummary[key] = {
          taxItemName: `${taxItemName}${exemptSuffix}`,
          totalCharge: 0,
          selectedCharge: 0,
        }
      }
      groupedDataSummary[key].totalCharge += charge
      groupedDataSummary[key].selectedCharge += isSelected ? charge : 0
    })

    groupedData = filteredData.reduce((acc, edge) => {
      let payment = edge.edge
      const groupName = payment.paymenttype.type
      if (!acc[groupName]) {
        acc[groupName] = []
      }
      acc[groupName].push(payment)
      return acc
    }, {})
  }

  // flatten data to an array of objects for easier rendering
  const flatData = Object.entries(groupedData).flatMap(
    ([groupName, payments]) => {
      return {
        groupName,
        payments: payments.sort(sorts[sort]),
      }
    },
  )

  const selectedCount = flatData
    .flatMap(row => row.payments)
    .filter(payment => selectedFilter({checks, payment})).length
  const allCount = flatData.flatMap(row => row.payments).length
  // happy accident, also handles selecting NO payments
  const selectedSameType =
    _.chain(flatData)
      .flatMap(row => row.payments)
      .filter(payment => selectedFilter({checks, payment}))
      .map(payment => payment.paymenttype.qb_id)
      .uniq()
      .value().length === 1
  const selectedIds = _.chain(flatData)
    .flatMap(row => row.payments)
    .filter(payment => selectedFilter({checks, payment}))
    .map(payment => payment.id)
    .value()

  return (
    <>
      <RecordPaymentToolbar
        tabs={tabsArray}
        tab={tab}
        onTabClick={v => {
          setQueryChanges({...queryChanges, tab: v})
          setChecks({})
        }}
      />
      <DashboardWrapper>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Button
            href="/old/database/recordedpaymentrecord.php"
            label="Past Records"
            style={{alignSelf: 'start'}}
          >
            Past Records
          </Button>
          {tab === 'tech' && (
            <DatePicker
              style={{alignSelf: 'end'}}
              label="Date"
              value={date}
              onChange={date =>
                setQueryChanges({
                  ...queryChanges,
                  date: date.format('YYYY-MM-DD'),
                })
              }
              PopoverProps={{PaperProps: {style: {background: 'white'}}}}
              renderInput={props => (
                <TextField
                  {...props}
                  variant="outlined"
                  margin="dense"
                  size="small"
                />
              )}
            />
          )}
        </div>
        {error && <Typography>Error</Typography>}
        {!error && tab !== '' ? (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Tax Item Name</TableCell>
                  <TableCell>Total Charge</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.values(groupedDataSummary)
                  .sort((a, b) => a.taxItemName.localeCompare(b.taxItemName))
                  .map((group, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <SummaryText
                          bold={group.selectedCharge > 0}
                          fade={group.selectedCharge <= 0}
                        >
                          {group.taxItemName}
                        </SummaryText>
                      </TableCell>
                      <TableCell>
                        <SummaryText
                          bold={group.selectedCharge > 0}
                          fade={group.selectedCharge <= 0}
                        >
                          {formatMoneyStandard(group.selectedCharge)}{' '}
                        </SummaryText>
                        {/* It kinda bothers me that the users don't want this, since it's not clear that you are NOT selecting everything available if you don't see this.  */}
                        {/* <SummaryText fade> */}
                        {/*   of {formatMoneyStandard(group.totalCharge)} */}
                        {/* </SummaryText> */}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell>
                    <Typography>
                      <SummaryText>Total</SummaryText>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>
                      <SummaryText bold={true}>
                        {formatMoneyStandard(
                          Object.values(groupedDataSummary).reduce(
                            (acc, g) => acc + g.selectedCharge,
                            0,
                          ),
                        )}
                      </SummaryText>
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        ) : (
          <Typography>Please select a tab</Typography>
        )}
        <Space />
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
          <Button
            onClick={() =>
              setQueryChanges({
                ...queryChanges,
                showDetails: String(!showDetails),
              })
            }
          >
            {!showDetails ? 'Show' : 'Hide'} Details{' '}
            {selectedCount < allCount
              ? `(${selectedCount} of ${allCount} selected)`
              : ''}{' '}
            {!showDetails ? <ArrowRight /> : <ArrowDropDown />}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setConfirm(true)}
            disabled={!selectedSameType}
            id="record"
          >
            Record
          </Button>
        </div>
        {showDetails && (
          <Details
            flatData={flatData}
            checks={checks}
            sort={sort}
            onSort={_sortHandler}
            onCheck={({e, rowKey, sortedRowKeys}) =>
              multicheck({
                e,
                rowKey,
                sortedRowKeys,
                checks,
                lastCheck,
                onSetChecks: setChecks,
                onSetLastCheck: setLastCheck,
              })
            }
            onCheckMany={({rowKeys, checked}) =>
              setChecks({
                ...checks,
                ...rowKeys.reduce((acc, rowKey) => {
                  acc[rowKey] = checked
                  return acc
                }, {}),
              })
            }
            tab={tab}
          />
        )}
        <Dialog open={confirm}>
          <DialogTitle>Record Payments</DialogTitle>
          <DialogContent>
            <p>
              Are you sure you want to record these Payments? This cannot be
              undone.
            </p>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={() => setConfirm(false)}>
              Cancel
            </Button>
            <DangerLoadingButton
              color="primary"
              onClick={() => handleConfirm(selectedIds)}
              id="confirm"
            >
              Record
            </DangerLoadingButton>
          </DialogActions>
        </Dialog>
      </DashboardWrapper>
      <ModifyQueryParams query={queryChanges} />
      <RecordStatusDialog
        open={statusDialog}
        errors={errors}
        status={status}
        onClose={() => setStatusDialog(false)}
      />
    </>
  )
}

export default RecordPayments
