import React, { useEffect, useState } from 'react'

import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import {
  ArrowDropDown,
  ArrowRight,
  Loop as RepeatIcon,
  Event as RepeatOneIcon,
  Receipt,
  Schedule,
} from '@mui/icons-material'

import {
  Table,
  TableRow,
  Paper,
  TableCell,
  TableHead,
  TableBody,
  Tooltip,
  TextField,
} from '@mui/material'
import styled from 'styled-components'
import _ from 'lodash'
import moment from 'moment'
import colors from '../../styles/colors'
import {
  ConflictDialog,
  Day,
  DraggableAsset,
  DraggableEmployee,
  EditRouterunModal,
  MassRescheduleModal,
  SchedulesList,
  SidebarDrop,
} from './scheduleComponents'
import { EditScheduleModal } from '../Customer/CustomerSchedules'
import { useQuery, useQueryClient } from 'react-query'

import { Space } from '../../components/Layout'
import { formatMoneyStandard } from '../../utils/moneyFormatter'
import gql from 'graphql-tag'
import { prgql } from '../../utils/graphql'
import { useDrop } from 'react-dnd'
import { DatePicker, StaticDatePicker } from '@mui/lab'
import EditWorkorder from '../Workorder/EditWorkorder'
import { discountedCharge } from '../../utils'

let SchedulesCell = styled(TableCell)`
  vertical-align: top;
  background-color: ${colors.grey200};
`

function _createMissingElements(count, el) {
  let i = 0
  let output = []
  while (i < count) {
    output.push(el)
    i++
  }
  return output
}

function datesAreOnSameDay(first, second, thing) {
  // console.log(first, second, thing)
  if (second === undefined) {
    second = new Date()
  }
  return (
    first.getFullYear() === second.getFullYear() &&
    first.getMonth() === second.getMonth() &&
    first.getDate() === second.getDate()
  )
}

let DashboardWrapper = styled.div`
  padding: 8px;
  grid-column-gap: 8px;
  display: flex;
  flex-direction: row;
`

function minutesToHours(minutes) {
  return Math.round((minutes / 60 + Number.EPSILON) * 100) / 100
}

let VividTooltip = styled(Tooltip).attrs(() => ({
  classes: {
    tooltip: 'tooltip',
  },
}))`
  & .tooltip {
    // Won't select the tooltip, make it vivid black if you can
    color: red;
  }
`

let Today = ({ ...props }) => (
  <Button
    variant="contained"
    color="primary"
    style={{
      maxWidth: '100px',
      alignSelf: 'center',
      marginTop: '-20px',
      marginBottom: '5px',
    }}
    {...props}
  >
    Today
  </Button>
)

export function Daily({
  data,
  loading,
  selectedDate,
  setSelectedDate,
  onUpdate,
  unscheduledAssets,
  unscheduledUsers,
  unavailableUsers,
  viewMode,
  summaryMode,
  supervisorMode,
  openRow,
  setOpenRow,
  cycle4Week = 0,
  filterRemaining = false,
}) {
  let [scheduleDeleteId, setScheduleDeleteId] = React.useState(null)
  let [editSchedule, setEditSchedule] = React.useState(null)
  let [newScheduleId, setNewScheduleId] = React.useState(1)

  let [editRouterun, setEditRouterun] = useState(null)
  let [rescheduleRouterun, setRescheduleRouterun] = useState(null)
  let [editWorkorder, setEditWorkorder] = useState({})
  let [openEditWorkorder, setOpenEditWorkorder] = useState(false)

  let today = moment()
    .startOf('day')
    .toDate()

  if (!data || !data.allRouteRuns) {
    return (
      <DashboardWrapper>
        <Paper
          id="unscheduledSidebar"
          style={{ display: 'flex', flexDirection: 'column' }}
        >
          <StaticDatePicker
            value={moment.utc(selectedDate)}
            onChange={d => setSelectedDate(d.toDate())}
            renderInput={props => (
              <TextField
                {...props}
                variant="outlined"
                margin="dense"
                size="small"
              />
            )}
          />
          <Today onClick={() => setSelectedDate(moment().toDate())} />
        </Paper>
        <Paper></Paper>
      </DashboardWrapper>
    )
  }

  let d = data.allRouteRuns.edges.filter(e =>
    datesAreOnSameDay(new Date(e.edge.run_at), selectedDate, e.edge.run_at),
  )

  let generatedScheduleIds = d
    .map(e => e.edge.workorders)
    .flat()
    .filter(i => !['VOID'].includes(i.status))
    .map(wo => (wo.schedule_id))
    .flat()
  let ungeneratedSchedules = d
    .map(e => e.edge.schedules)
    .flat()
    .filter(s => !generatedScheduleIds.includes(s.id))
    .filter(s => !s.customer.services_paused)

  let dollars =
    d.reduce(
      (carry, e) =>
        carry +
        e.edge.workorders
          .filter(i => !['VOID'].includes(i.status) && !i.multiday)
          .filter(i => i.customer.services_paused !== true)
          .reduce(
            (c, s) => s.workorderitems.reduce((c, i) => discountedCharge(i) + c, 0) + c,
            0,
          ) +
        e.edge.workorders
          .filter(i => !['VOID'].includes(i.status) && i.multiday)
          .filter(i => i.customer.services_paused !== true)
          .reduce((c, s) => s.multiday_estimated_total + c, 0),
      0,
    ) +
    (moment.utc(selectedDate).isBefore(moment.utc().startOf('day'))
      ? 0
      : ungeneratedSchedules.reduce(
        (c, s) => s.services.reduce((c, i) => discountedCharge(i) + c, 0) + c,
        0,
      ))

  let dollarCapacity = d.reduce(
    (carry, e) =>
      carry + (e.edge.users.length === 0 ? 0 : e.edge.income_capacity),
    0,
  )

  let hours = minutesToHours(
    d.reduce(
      (carry, e) =>
        carry +
        e.edge.workorders
          .filter(i => !['VOID'].includes(i.status))
          .filter(i => i.customer.services_paused !== true)
          .reduce(
            (c, s) =>
              s.workorderitems.reduce((c, i) => i.man_minutes + c, 0) + c,
            0,
          ),
      0,
    ) +
    (moment.utc(selectedDate).isBefore(moment.utc().startOf('day'))
      ? 0
      : ungeneratedSchedules.reduce(
        (c, s) => s.services.reduce((c, i) => i.man_minutes + c, 0) + c,
        0,
      )),
  )

  let hoursCapacity = minutesToHours(
    d.reduce(
      (carry, e) =>
        carry +
        e.edge.users.filter(
          u => !(u.roles?.map(({ name }) => name) || []).includes('Supervisor'),
        ).length *
        6.15 *
        60,
      0,
    ),
  )

  return (
    <DashboardWrapper>
      <Paper
        id="unscheduledSidebar"
        style={{ display: 'flex', flexDirection: 'column' }}
      >
        <StaticDatePicker
          value={moment.utc(selectedDate)}
          onChange={d => setSelectedDate(d.toDate())}
          renderInput={props => (
            <TextField
              {...props}
              variant="outlined"
              margin="dense"
              size="small"
            />
          )}
        />
        <Today onClick={() => setSelectedDate(moment().toDate())} />
        <hr style={{ width: '80%', opacity: '50%' }} />
        {data ? (
          <SidebarDrop>
            <div>
              <Typography>Employees</Typography>
              <hr style={{ width: '100%' }} />
              {unscheduledUsers.map(u => {
                let Employee = () => (
                  <DraggableEmployee
                    key={'user-' + u.id}
                    parent={{ type: 'unscheduled', id: 0 }}
                    id={u.id}
                    onUpdate={onUpdate}
                    disableDrag={
                      viewMode ||
                      !!unavailableUsers
                        .map(a => a.user_id)
                        .find(v => v === u.id)
                    }
                    unavailable={
                      !!unavailableUsers
                        .map(a => a.user_id)
                        .find(v => v === u.id)
                    }
                  >
                    <Typography variant="body1" id={'unscheduled-user-' + u.id}>
                      {u.first_name + ' ' + u.last_name}
                    </Typography>
                  </DraggableEmployee>
                )

                return !!unavailableUsers.find(v => v.user_id === u.id) ? (
                  <VividTooltip
                    title={unavailableUsers.find(v => v.user_id === u.id).type}
                    placement="right"
                  >
                    <div>
                      <Employee />
                    </div>
                  </VividTooltip>
                ) : (
                  <Employee />
                )
              })}
            </div>
            <div style={{ marginLeft: '10px' }}>
              <Typography>Assets</Typography>
              <hr style={{ width: '100%' }} />
              {unscheduledAssets.map(u => (
                <DraggableAsset
                  key={'asset-' + u.id}
                  parent={{ type: 'unscheduled', id: 0 }}
                  id={u.id}
                  onUpdate={onUpdate}
                  type={u.assettype_id}
                  disableDrag={viewMode}
                >
                  <Typography variant="body1">{u.name}</Typography>
                </DraggableAsset>
              ))}
            </div>
          </SidebarDrop>
        ) : (
          undefined
        )}
      </Paper>
      {!data ||
        !data.allRouteRuns ||
        !data.allRouteRuns.edges ||
        !data.allRouteRuns.edges.length ? (
        <Paper></Paper>
      ) : (
        <Paper style={{ overflow: 'overlay' }}>
          <Table style={{ tableLayout: 'fixed', minWidth: '800px' }}>
            <TableHead>
              <TableRow>
                <TableCell colSpan={1} style={{ borderBottom: '0px' }}>
                  <Typography>Week {cycle4Week}</Typography>
                </TableCell>
                <TableCell
                  colSpan={6}
                  style={{ textAlign: 'center', borderBottom: '0px' }}
                >
                  <Typography>
                    Income: {formatMoneyStandard(dollars)} /{' '}
                    {formatMoneyStandard(dollarCapacity)} &nbsp; | &nbsp; Time:{' '}
                    {hours} / {hoursCapacity} hrs
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell></TableCell>
                <TableCell colSpan={2}>
                  <Typography variant="h6">
                    {moment(selectedDate)
                      .utc()
                      .format('ddd, MMM D')}
                  </Typography>
                </TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {_.chunk(
                data.allRouteRuns.edges
                  .filter(e =>
                    datesAreOnSameDay(
                      new Date(e.edge.run_at),
                      selectedDate,
                      e.edge.run_at,
                    ),
                  )
                  .sort(
                    (a, b) =>
                      Number(a.edge.route.name) - Number(b.edge.route.name),
                  ),
                7,
              ).map((c, index) => (
                <>
                  <TableRow key={index + '-controls'}>
                    <TableCell
                      style={{
                        padding: '0px .5em ',
                        borderBottom: '0px',
                      }}
                      colSpan={7}
                    >
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Button
                          variant="text"
                          style={{ textTransform: 'none' }}
                          onClick={() =>
                            openRow === index
                              ? setOpenRow(null)
                              : setOpenRow(index)
                          }
                        >
                          <Typography
                            variant="h6"
                            style={{ display: 'flex', alignItems: 'center' }}
                          >
                            Schedules
                            {openRow === index ? (
                              <ArrowDropDown />
                            ) : (
                              <ArrowRight />
                            )}
                          </Typography>{' '}
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    {c.map(e => (
                      <Day
                        data={e.edge}
                        dayView
                        onUpdate={onUpdate}
                        key={`daily-day-${e.edge.id}`}
                        onClick={() => setEditRouterun(e.edge)}
                        viewMode={viewMode}
                        summaryMode={summaryMode}
                        supervisorMode={supervisorMode}
                      />
                    ))}
                    {_createMissingElements(7 - c.length, <TableCell />)}
                  </TableRow>
                  {openRow === index && (
                    <TableRow key={'schedules' + index}>
                      {c.map((e, index) => (
                        <SchedulesList
                          schedules={e.edge.schedules}
                          workorders={e.edge.workorders.filter(
                            i => !['VOID'].includes(i.status),
                          )}
                          onOpenSchedule={s => setEditSchedule(s)}
                          onOpenWorkorder={w => {
                            setEditWorkorder(w)
                            setOpenEditWorkorder(true)
                          }}
                          onMassReschedule={setRescheduleRouterun}
                          // Cheating? Sure! Do we care? No!
                          past={moment(e.edge.run_at, 'YYYY-MM-DDTHH:mm:ss')
                            .isBefore(today)}
                          parent={e.edge}
                          onUpdate={onUpdate}
                          key={index}
                          viewMode={viewMode}
                          filterRemaining={filterRemaining}
                        />
                      ))}
                      {_createMissingElements(7 - c.length, <SchedulesCell />)}
                    </TableRow>
                  )}
                </>
              ))}
              {!data.allRouteRuns.edges.filter(e =>
                datesAreOnSameDay(
                  new Date(e.edge.run_at),
                  selectedDate,
                  e.edge.run_at,
                ),
              ).length && (
                  <TableRow>
                    <TableCell colSpan={7}>
                      <Typography>
                        No matching routeruns. Try turning off the All Assigned
                        filter
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
            </TableBody>
          </Table>
        </Paper>
      )}
      <EditScheduleModal
        editSchedule={editSchedule}
        jobRequirements={data.allJobrequirementtypes}
        setEditSchedule={setEditSchedule}
        setNewScheduleId={setNewScheduleId}
        refetchSchedules={onUpdate}
        customer={editSchedule && editSchedule.customer}
        instanceDate={selectedDate}
        key={editSchedule && editSchedule.id}
      />
      <EditRouterunModal
        editRouterun={editRouterun}
        setEditRouterun={setEditRouterun}
        onUpdate={s => onUpdate(s)}
      />
      <MassRescheduleModal
        rescheduleRouterun={rescheduleRouterun}
        setRescheduleRouterun={setRescheduleRouterun}
        onRefetch={() => onUpdate({})}
        isLoading={loading}
      />
      <EditWorkorder
        key={editWorkorder.id}
        wo={editWorkorder}
        open={openEditWorkorder}
        onSave={() => onUpdate({})}
        onClose={() => setOpenEditWorkorder(false)}
        showStatusChange={true}
        showCustomerLink={true}
        showMultidayVersion={!!editWorkorder.multiday}
        initialTab={editWorkorder.id ? 'preview' : 'edit'}
      />
    </DashboardWrapper>
  )
}
