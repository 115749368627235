import React, {useEffect, useState} from 'react'

import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  TextField as MuiTextField,
  Select,
  MenuItem,
  Collapse,
  Divider,
  InputAdornment,
} from '@mui/material'
import {Field, FieldArray, Formik} from 'formik'
import {
  AutoCompleteField,
  Checkbox,
  DatePicker,
  TextField,
  TimePicker,
} from '../../components/forms'
import MuiCheckbox from '@mui/material/Checkbox'
import RouteSelect from '../../components/RouteSelect'
import CustomerSelect from '../../components/CustomerSelect'
import gql from 'graphql-tag'
import SaleItemSelect from '../../components/SaleItemSelect'
import {Space} from '../../components/Layout'
import {useAuth} from '../../security/auth'
import UsersSelect from '../../components/UsersSelect'
import styled from 'styled-components'
import {
  Add,
  Block,
  Cancel,
  CheckCircle,
  ChevronRight,
  Close,
  Delete,
  Flare,
  FlashOn,
  KeyboardArrowDown,
  MonetizationOn,
  RadioButtonUnchecked,
} from '@mui/icons-material'
import colors from '../../styles/colors'
import theme, {DarkTheme} from '../../styles/theme'
import LoadingButton from '../../components/LoadingButton'
import * as Yup from 'yup'
import {useMutation} from 'react-apollo'
import moment from 'moment'
import {NavTabs, TabSimple} from '../../components/NavTabs'
import {Link} from 'react-router-dom'
import {TimePicker as MuiTimePicker} from '@mui/lab'
import {WorkorderDetails} from '.'
import {DiscountSelect} from '../../components/DiscountSelect'
import {TaskSelect, TasktypeSelect} from '../../components/apiSelects'

let LightHr = styled.hr`
  border-top: white;
  border-color: ${({theme}) => theme.grey300};
  background-color: ${({theme}) => theme.grey300};
  width: 100%;
`

let workorderSchema = Yup.object().shape(
  {
    customer_id: Yup.string()
      .required('Required')
      .notOneOf(['0']),
    notes: Yup.string().nullable(),
    // notes_resolved: Yup.boolean().ooptio,
    assigned_at: Yup.string().required('Required'),
    // multiday: Yup.boolean(),
    route_id: Yup.string()
      .required('Required')
      .notOneOf(['0']),
    schedule_id: Yup.string()
      .optional()
      .nullable(),
    arrival_start_time: Yup.string().when('arrival_end_time', {
      is: val => !!val && val !== '',
      then: schema => schema.required('Required if setting Arrival End Time'), // if you can figure out how to get this to do a before/after check of the dates, be my guest
      otherwise: schema => schema.optional().nullable(),
    }),
    arrival_end_time: Yup.string().when('arrival_start_time', {
      is: val => !!val && val !== '',
      then: schema => schema.required('Required if setting Arrival Start Time'),
      otherwise: schema => schema.optional().nullable(),
    }),
    workorderitems: Yup.array()
      .min(1, 'Required')
      .of(
        Yup.object().shape({
          saleitem_id: Yup.string()
            .required('Required')
            .notOneOf(['0']),
          description: Yup.string().required('Required'),
          notes: Yup.string().nullable(),
          charge: Yup.number().optional(),
          sales_person_id: Yup.string().nullable(),
          discount_id: Yup.string().nullable(),
          man_minutes: Yup.number().required('Required'),
          taxable: Yup.boolean(),
        }),
      ),
  },
  ['arrival_start_time', 'arrival_end_time'],
)

let UpdateWorkorderQuery = gql`
  mutation updateWorkorders($workorder: UpdateWorkordersInput) {
    updateWorkorders(input: $workorder) {
      workorders {
        id
      }
    }
  }
`
let CreateWorkorderQuery = gql`
  mutation createWorkorders($workorder: CreateWorkordersInput) {
    createWorkorders(input: $workorder) {
      workorders {
        id
      }
    }
  }
`
let DeleteItemMutation = gql`
  mutation deleteWorkorderitems($workorderitem: DeleteWorkorderitemsInput) {
    deleteWorkorderitems(input: $workorderitem) {
      message
    }
  }
`

let EditWorkorder = ({
  open,
  wo = {},
  onClose,
  onSave,
  onBeforeSave = () => Promise.resolve(),
  showStatusChange = false,
  showCustomerLink = false,
  showMultidayVersion = false,
  autocreateOptions = [],
  initialTab,
}) => {
  let [updateWorkorder] = useMutation(UpdateWorkorderQuery)
  let [createWorkorder] = useMutation(CreateWorkorderQuery)
  let [deleteItem] = useMutation(DeleteItemMutation)
  let [showWarn, setShowWarn] = useState(false)
  let [showWarnStatus, setShowWarnStatus] = useState(false)
  let [taxable, setTaxable] = useState(true)
  let [tab, setTab] = useState(initialTab || 'edit')
  let [showDetails, setShowDetails] = useState(null)

  let {status: canEditDeleteWorkorderitems} = useAuth(
    'edit delete workorderitems',
  )
  let workorderitemsDisabled = canEditDeleteWorkorderitems !== 'authorized'

  let formikValues = {
    status: 'OPEN',
    multiday: showMultidayVersion,
    ...wo,
    workorderitems: wo.workorderitems
      ? wo.workorderitems.map(woi => {
          let newWoi = {...woi}
          if (!newWoi.sales_person_id) newWoi.sales_person_id = undefined
          return newWoi
        })
      : [],
    deleteItems: [],
  }

  let {status: canSetCommissionRates} = useAuth('set commission rates')

  let _submit = (values, submit) => {
    return submit()
  }

  let handleSave = values => {
    let deleteItems = []
    let attrs = [
      'id',
      'assigned_at',
      'customer_id',
      'schedule_id',
      'notes',
      'route_id',
      'status',
      'multiday',
      'arrival_start_time',
      'arrival_end_time',
      'notes_resolved',
      'is_billable',
    ]
    let attrReducer = (acc, k) => {
      let v = values[k]
      if (k === 'arrival_start_time' || k === 'arrival_end_time') {
        if (Number(v)) {
          v = v.substring(0, 2) + ':' + v.substring(2, 4)
        }
      }
      if (k === 'notes_resolved' && !values['notes']) {
        v = false
      }
      acc[k] = v
      return acc
    }
    let rels = ['workorderitems']
    let relAttrs = [
      'id',
      'charge',
      'saleitem_id',
      'description',
      'notes',
      'taxable', // technically?
      'sales_person_id',
      'commission_start_at',
      'man_minutes',
      'discount_id',
      'tasks',
    ]
    let relReducer = (acc, k) => {
      let v = values[k]
      if (k === 'workorderitems') {
        let update = []
        let create = []
        let remove = []
        values[k].forEach(function(rel) {
          let relAttrReducer = (acc, k) => {
            let v = rel[k]
            if (['sales_person_id'].includes(k) && !v) {
              v = undefined
            }
            if (['commission_start_at'].includes(k) && !!v) {
              v = moment(v).format('YYYY-MM-DD')
            }
            if (k === 'sales_person_id' && v === 0) v = null
            if (k === 'tasks') {
              let create = v
                ? v
                    .filter(req => !!req.tasktype_id)
                    .filter(req => req.id === 'new')
                    .map(req => ({
                      tasktype_id: req.tasktype_id,
                      description: req.description,
                      complete: req.complete,
                    }))
                : undefined
              let update = v
                ? v
                    .filter(req => !!req.tasktype_id)
                    .filter(req => req.id !== 'new')
                    .map(req => ({
                      id: req.id,
                      tasktype_id: req.tasktype_id,
                      description: req.description,
                      complete: req.complete,
                    }))
                : undefined
              let ids = v?.map(req => req.id) || []

              let originalTasks = formikValues.workorderitems.filter(
                woi => woi.id === rel.id,
              )[0]?.tasks

              let destroy = originalTasks
                ? originalTasks
                    .filter(origReq => !ids.includes(origReq.id))
                    .map(req => ({
                      id: req.id,
                    }))
                : undefined

              v = {create, update, destroy, api_type: 'tasks'}
            }
            acc[k] = v
            return acc
          }
          let woitem = relAttrs.reduce(relAttrReducer, {})
          if (!values['is_billable']) {
            woitem.charge = 0
          }
          if (woitem.id) {
            update.push(woitem)
          } else {
            create.push(woitem)
          }
        })

        deleteItems = values['deleteItems'].map(id => ({id}))

        acc['workorderitems'] = {
          create,
          update,
        }
      }
      return acc
    }

    let wo = attrs.reduce(attrReducer, {})
    let relationships = rels.reduce(relReducer, {})

    let deleteItemsPromises = deleteItems.map(workorderitem =>
      deleteItem({variables: {workorderitem}}),
    )

    if (wo.id) {
      return onBeforeSave()
        .then(
          async () =>
            await updateWorkorder({
              variables: {workorder: {...wo, ...relationships}},
            }),
        )
        .then(() => Promise.allSettled(deleteItemsPromises))
        .then(onSave)
        .then(onClose)
    } else {
      return onBeforeSave()
        .then(
          async schedule_id =>
            await createWorkorder({variables: {workorder: wo}}),
        )
        .then(data => {
          updateWorkorder({
            variables: {
              workorder: {
                id: data.data.createWorkorders.workorders.id,
                ...relationships,
              },
            },
          })
        })
        .then(onSave)
        .then(onClose)
    }
  }

  let handleStatus = values => {
    return updateWorkorder({
      variables: {
        workorder: {
          id: values.id,
          status: values.status,
        },
      },
    })
      .then(() => setShowWarnStatus(false))
      .then(onClose)
      .then(onSave)
  }

  return (
    <>
      <Dialog open={open && !!wo} maxWidth="lg" fullWidth>
        <DialogTitle>
          {wo.id ? 'Edit' : 'Create'}{' '}
          <Link to={`/workorders/${wo.id}`}>Workorder {wo.id}</Link>{' '}
          {showMultidayVersion && ' [MULTIDAY] '}
          {showCustomerLink && wo.customer && (
            <>
              -{' '}
              <Link to={`/customers/${wo.customer.id}`}>
                {wo.customer.name}
              </Link>
            </>
          )}
        </DialogTitle>

        {showStatusChange && (
          <DarkTheme>
            <NavTabs value={tab}>
              {wo.id && (
                <TabSimple
                  label="Preview"
                  value="preview"
                  style={{color: tab !== 'preview' && colors.grey900}}
                  onClick={() => setTab('preview')}
                />
              )}
              <TabSimple
                label="Edit"
                value="edit"
                style={{color: tab !== 'edit' && colors.grey900}}
                onClick={() => setTab('edit')}
              />
              <TabSimple
                label="Change Status"
                value="status"
                style={{color: tab !== 'status' && colors.grey900}}
                onClick={() => setTab('status')}
              />
            </NavTabs>
          </DarkTheme>
        )}
        {tab === 'preview' && wo.id && (
          <>
            <DialogContent>
              <WorkorderDetails wo={wo} showInlineControls />
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose}>Close</Button>
            </DialogActions>
          </>
        )}
        {tab === 'status' && (
          <Formik
            initialValues={formikValues}
            validate={values => true}
            onSubmit={v => handleStatus(v)}
            validationSchema={workorderSchema}
          >
            {({
              isSubmitting,
              submitForm,
              values,
              setValues,
              setFieldValue,
              validateForm,
              isValid,
            }) => (
              <>
                <DialogContent>
                  <Grid sm={12} spacing={2} container>
                    <Grid item>
                      <Chip
                        label="OPEN"
                        icon={<RadioButtonUnchecked />}
                        onClick={() => {
                          setFieldValue('status', 'OPEN')
                        }}
                        id="open"
                        color={values.status === 'OPEN' ? 'primary' : undefined}
                      />
                    </Grid>
                    <Grid item>
                      <Chip
                        label="COMPLETE"
                        icon={<CheckCircle />}
                        onClick={() => {
                          setFieldValue('status', 'COMPLETE')
                        }}
                        id="complete"
                        color={
                          values.status === 'COMPLETE' ? 'primary' : undefined
                        }
                      />
                    </Grid>
                    <Grid item>
                      <Chip
                        label="VOID"
                        icon={<Block />}
                        onClick={() => {
                          setFieldValue('status', 'VOID')
                        }}
                        id="void"
                        color={values.status === 'VOID' ? 'primary' : undefined}
                      />
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button onClick={onClose}>Cancel</Button>
                  <LoadingButton
                    variant="contained"
                    color="primary"
                    onClick={() => setShowWarnStatus(true)}
                    loading={isSubmitting}
                    disabled={!isValid}
                  >
                    Save
                  </LoadingButton>
                </DialogActions>
                <Dialog open={showWarnStatus}>
                  <DialogTitle>Confirm Change Status</DialogTitle>
                  <DialogContent>
                    <Typography>
                      Are you sure you want to change this workorders status to{' '}
                      <strong style={{fontWeight: 'bold'}}>
                        {values.status}
                      </strong>
                      ?
                    </Typography>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => setShowWarnStatus(false)}>
                      Cancel
                    </Button>
                    <LoadingButton
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        submitForm()
                      }}
                      loading={isSubmitting}
                      disabled={!isValid}
                    >
                      {values.status}
                    </LoadingButton>
                  </DialogActions>
                </Dialog>
              </>
            )}
          </Formik>
        )}

        {tab === 'edit' && (
          <Formik
            initialValues={formikValues}
            validate={values => true}
            onSubmit={v => handleSave(v)}
            validationSchema={workorderSchema}
            isInitialValid={false}
          >
            {({
              isSubmitting,
              submitForm,
              values,
              setValues,
              setFieldValue,
              validateForm,
              isValid,
              errors,
            }) => {
              return (
                <>
                  <DialogContent>
                    <Grid container spacing={2}>
                      <Grid item md="4">
                        <AutoCompleteField
                          as={CustomerSelect}
                          name="customer_id"
                          selectedId={values.customer_id}
                          inputVariant={'filled'}
                          onSelectChange={c => {
                            if (!c) return
                            // console.log(c)
                            setTaxable(c.taxable)
                            values.workorderitems.forEach((woi, i) => {
                              let saleitemTaxable = woi.saleitem
                                ? woi.saleitem.taxable
                                : true
                              setFieldValue(
                                `workorderitems.${i}.taxable`,
                                saleitemTaxable && c.taxable,
                              )
                            })
                          }}
                          fullWidth
                        />
                      </Grid>
                      <Grid item md="2">
                        {!showMultidayVersion && (
                          <FormControlLabel
                            label="WO is billable"
                            control={
                              <MuiCheckbox
                                color="secondary"
                                name={'is_billable'}
                                checked={values.is_billable}
                                onChange={e =>
                                  setValues({
                                    ...values,
                                    is_billable: e.target.checked,
                                  })
                                }
                                fullWidth
                                label="WO is billable"
                              />
                            }
                          />
                        )}
                      </Grid>
                      <Grid item md="4">
                        <DatePicker
                          name={'assigned_at'}
                          label={'Assigned At'}
                          // inputFormat="dddd, MMMM D, YYYY"
                          renderInput={ps => (
                            <MuiTextField
                              name={'assigned_at'}
                              {...ps}
                              variant="filled"
                              size="small"
                              fullWidth
                            />
                          )}
                        />
                      </Grid>
                      <Grid item md="2">
                        <AutoCompleteField
                          as={RouteSelect}
                          name="route_id"
                          selectedId={values.route_id}
                          fullWidth
                          inputVariant="filled"
                          inputMargin="dense"
                        />
                      </Grid>
                      <Grid item md="4">
                        <TextField
                          name={'notes'}
                          variant="filled"
                          fullWidth
                          label="Notes"
                        />
                      </Grid>
                      <Grid item md="2">
                        {!!values.notes && (
                          <FormControlLabel
                            label="Notes Resolved"
                            control={
                              <MuiCheckbox
                                color="secondary"
                                name={'notes_resolved'}
                                checked={values.notes_resolved}
                                onChange={e =>
                                  setValues({
                                    ...values,
                                    notes_resolved: e.target.checked,
                                  })
                                }
                                fullWidth
                                label="Notes Resolved"
                              />
                            }
                          />
                        )}
                      </Grid>
                      <Grid item md="3" container>
                        <TimePicker
                          as={MuiTimePicker}
                          name="arrival_start_time"
                          label="Arrival Start Time"
                          placeholder="08:00 AM"
                          mask="__:__ _M"
                          inputVariant="filled"
                        />
                        <IconButton
                          style={{alignSelf: 'center'}}
                          onClick={() =>
                            setFieldValue('arrival_start_time', null)
                          }
                        >
                          <Close />
                        </IconButton>
                      </Grid>
                      <Grid item md="3" container>
                        <TimePicker
                          as={MuiTimePicker}
                          name="arrival_end_time"
                          label="Arrival End Time"
                          placeholder="08:00 AM"
                          mask="__:__ _M"
                          inputVariant="filled"
                        />
                        <IconButton
                          style={{alignSelf: 'center'}}
                          onClick={() =>
                            setFieldValue('arrival_end_time', null)
                          }
                        >
                          <Close />
                        </IconButton>
                      </Grid>
                    </Grid>
                    <Space size={3} />
                    <Grid container spacing={2} direction="column">
                      <FieldArray name="workorderitems">
                        {({insert, remove, push, replace}) => (
                          <>
                            {values.workorderitems.map((woi, i) => (
                              <>
                                <LightHr />
                                <Grid item container spacing={1} key={woi.id}>
                                  <Grid
                                    item
                                    container
                                    justifyContent="space-between"
                                  >
                                    <Grid item container xs={6}>
                                      <Typography variant="body1">
                                        #{i + 1}
                                      </Typography>
                                      {woi.service_id ? (
                                        <Tooltip title="generated from schedule">
                                          <FlashOn
                                            style={{color: colors.yellow400}}
                                          />
                                        </Tooltip>
                                      ) : (
                                        ''
                                      )}
                                    </Grid>
                                    <Grid item>
                                      <IconButton
                                        size="small"
                                        style={{
                                          color: theme.palette.danger.main,
                                        }}
                                        disabled={workorderitemsDisabled}
                                        onClick={() => {
                                          let deleteID =
                                            values.workorderitems[i].id
                                          // this is probably evil; you are supposed to use setFieldValue but it doesn't like setting it to an array.
                                          // Probably b/c Formik wants you to use the <FieldArray>. There might be a way to use that for this but meh
                                          if (deleteID)
                                            values.deleteItems = [
                                              ...values.deleteItems,
                                              deleteID,
                                            ]

                                          remove(i)
                                          // Is there a better way to do this? Probably. Do I know what it is? Absolutely not.
                                          setTimeout(() => {
                                            validateForm()
                                          }, 1)
                                        }}
                                      >
                                        <Delete />
                                      </IconButton>
                                    </Grid>
                                  </Grid>
                                  <Grid item container spacing={1}>
                                    <Grid item xs={2}>
                                      <AutoCompleteField
                                        as={SaleItemSelect}
                                        disabled={workorderitemsDisabled}
                                        name={`workorderitems.${i}.saleitem_id`}
                                        selectedId={
                                          values.workorderitems[i].saleitem_id
                                        }
                                        onSelectChange={si => {
                                          setTimeout(
                                            () =>
                                              setFieldValue(
                                                `workorderitems.${i}.saleitem`,
                                                si,
                                              ),
                                            1,
                                          )
                                          setTimeout(
                                            () =>
                                              setFieldValue(
                                                `workorderitems.${i}.taxable`,
                                                taxable
                                                  ? si && si.taxable
                                                  : false,
                                              ),
                                            1,
                                          )
                                        }}
                                        searchFilterType={
                                          values.multiday ||
                                          (!values.multiday &&
                                            values.is_billable)
                                            ? 'billable'
                                            : 'nonbillable'
                                        }
                                        fullWidth
                                      />
                                    </Grid>
                                    <Grid item xs={5}>
                                      <TextField
                                        disabled={workorderitemsDisabled}
                                        name={`workorderitems.${i}.description`}
                                        variant="filled"
                                        label="Description"
                                        fullWidth
                                        multiline
                                      />
                                    </Grid>
                                    {!showMultidayVersion &&
                                      values.is_billable && (
                                        <Grid item xs={1}>
                                          <TextField
                                            disabled={workorderitemsDisabled}
                                            name={`workorderitems.${i}.charge`}
                                            variant="filled"
                                            label="Charge"
                                            fullWidth
                                            type="number"
                                          />
                                        </Grid>
                                      )}
                                    <Grid item xs={1}>
                                      <TextField
                                        disabled={workorderitemsDisabled}
                                        name={`workorderitems.${i}.man_minutes`}
                                        variant="filled"
                                        label="Man Minutes"
                                        fullWidth
                                        type="number"
                                      />
                                    </Grid>

                                    {/* Commissions */}
                                    {!showMultidayVersion &&
                                      values.is_billable && (
                                        <>
                                          <Grid item xs={3}>
                                            <AutoCompleteField
                                              as={DiscountSelect}
                                              disabled={workorderitemsDisabled}
                                              name={`workorderitems.${i}.discount_id`}
                                              inputProps={{variant: 'filled'}}
                                              selectedId={
                                                values.workorderitems[i]
                                                  .discount_id
                                              }
                                              onSelectChange={si => {
                                                setTimeout(
                                                  () =>
                                                    setFieldValue(
                                                      `workorderitems.${i}.discount`,
                                                      si,
                                                    ),
                                                  1,
                                                )
                                              }}
                                              fullWidth
                                            />
                                          </Grid>
                                          <Grid item sm={3}>
                                            <AutoCompleteField
                                              disabled={workorderitemsDisabled}
                                              as={UsersSelect}
                                              name={`workorderitems.${i}.sales_person_id`}
                                              selectedUserId={
                                                values.workorderitems[i]
                                                  .sales_person_id
                                              }
                                              fullWidth
                                              label="Sales Person"
                                              inputVariant="filled"
                                              onSelectChange={value => {
                                                if (
                                                  !values.workorderitems[i]
                                                    .commission_start_at
                                                ) {
                                                  setFieldValue(
                                                    `workorderitems.${i}.commission_start_at`,
                                                    moment().toDate(),
                                                  )
                                                }
                                              }}
                                            />
                                          </Grid>
                                          <Grid item xs={4}>
                                            <DatePicker
                                              name={`workorderitems.${i}.commission_start_at`}
                                              label={'Commission Start At'}
                                              renderInput={ps => (
                                                <MuiTextField
                                                  // name={`workorderitems.${i}.commission_start_at`}
                                                  {...ps}
                                                  variant="filled"
                                                  fullWidth
                                                  disabled={
                                                    canSetCommissionRates !==
                                                      'authorized' ||
                                                    workorderitemsDisabled
                                                  }
                                                />
                                              )}
                                            />
                                          </Grid>
                                        </>
                                      )}
                                  </Grid>
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  container
                                  alignItems={'center'}
                                >
                                  <Typography
                                    variant="subtitle1"
                                    onClick={() =>
                                      setShowDetails(
                                        showDetails === i ? null : i,
                                      )
                                    }
                                  >
                                    Service Details
                                  </Typography>{' '}
                                  <IconButton
                                    size="small"
                                    onClick={() =>
                                      setShowDetails(
                                        showDetails === i ? null : i,
                                      )
                                    }
                                    id="jobreqDisclosure"
                                  >
                                    {showDetails === i ? (
                                      <KeyboardArrowDown />
                                    ) : (
                                      <ChevronRight />
                                    )}
                                  </IconButton>
                                </Grid>
                                <Collapse
                                  in={showDetails === i}
                                  style={{width: '100%'}}
                                >
                                  <Grid
                                    item
                                    xs={12}
                                    container
                                    spacing={2}
                                    style={{padding: '10px 30px'}}
                                  >
                                    <Grid item xs={12}>
                                      <Typography variant="subtitle2">
                                        Tasks
                                      </Typography>{' '}
                                      <Typography variant="caption">
                                        Tasks must be completed first before
                                        completing the workorder on the mobile
                                        app
                                      </Typography>{' '}
                                    </Grid>
                                    <FieldArray
                                      name={`workorderitems.${i}.tasks`}
                                    >
                                      {({remove, push}) => (
                                        <Grid
                                          container
                                          item
                                          xs={12}
                                          spacing={2}
                                        >
                                          {values.workorderitems[i].tasks?.map(
                                            (req, ii) => (
                                              <Grid
                                                item
                                                xs={12}
                                                container
                                                spacing={2}
                                              >
                                                <Grid item xs={5}>
                                                  <AutoCompleteField
                                                    as={TaskSelect}
                                                    disabled={
                                                      workorderitemsDisabled
                                                    }
                                                    name={`workorderitems.${i}.tasks.${ii}.tasktype_id`}
                                                    initialInputValue={
                                                      values.workorderitems[i]
                                                        ?.tasks[ii]
                                                        ?.description || ''
                                                    }
                                                    inputProps={{
                                                      variant: 'filled',
                                                    }}
                                                    selectedId={
                                                      values.workorderitems[i]
                                                        ?.tasks[ii].tasktype_id
                                                    }
                                                    onSelectChange={v =>
                                                      setFieldValue(
                                                        `workorderitems.${i}.tasks.${ii}.description`,
                                                        v.id === 'freeSolo'
                                                          ? v.description
                                                          : null,
                                                      )
                                                    }
                                                    fullWidth
                                                  />
                                                </Grid>
                                                <Grid item xs={2}>
                                                  <FormControlLabel
                                                    label="Complete"
                                                    control={
                                                      <MuiCheckbox
                                                        color="secondary"
                                                        name={`workorderitems.${i}.tasks.${ii}.complete`}
                                                        checked={
                                                          values.workorderitems[
                                                            i
                                                          ]?.tasks[ii].complete
                                                        }
                                                        onChange={e =>
                                                          setFieldValue(
                                                            `workorderitems.${i}.tasks.${ii}.complete`,
                                                            e.target.checked,
                                                          )
                                                        }
                                                        fullWidth
                                                        label="Complete"
                                                      />
                                                    }
                                                  />
                                                </Grid>
                                                <Grid item xs={1}>
                                                  <IconButton
                                                    color="error"
                                                    disabled={
                                                      workorderitemsDisabled
                                                    }
                                                    onClick={() => remove(ii)}
                                                    id={`delTask${ii}`}
                                                  >
                                                    <Delete />
                                                  </IconButton>
                                                </Grid>
                                              </Grid>
                                            ),
                                          )}
                                          <Grid item xs={12}>
                                            <Button
                                              size="small"
                                              variant="contained"
                                              color="secondary"
                                              id="newTask"
                                              disabled={workorderitemsDisabled}
                                              onClick={() =>
                                                push({
                                                  id: 'new',
                                                  tasktype_id: '',
                                                })
                                              }
                                            >
                                              <Add />
                                            </Button>
                                          </Grid>
                                        </Grid>
                                      )}
                                    </FieldArray>
                                    <Grid item xs={12}>
                                      <Typography variant="subtitle2">
                                        Technician Notes
                                      </Typography>{' '}
                                      <Typography variant="caption">
                                        Extra info the tech needs to know
                                      </Typography>{' '}
                                    </Grid>
                                    <Grid item xs={5}>
                                      <TextField
                                        disabled={workorderitemsDisabled}
                                        name={`workorderitems.${i}.notes`}
                                        variant="filled"
                                        label="Technician Notes"
                                        fullWidth
                                        multiline
                                      />
                                    </Grid>
                                  </Grid>
                                </Collapse>
                                <Grid item xs={12}>
                                  <Divider />
                                </Grid>
                              </>
                            ))}
                            <Space />
                            <Grid item xs={12} container>
                              <Grid item xs={1}>
                                {values.is_billable ||
                                (!values.is_billable &&
                                  values.workorderitems.length === 0) ? (
                                  <Button
                                    color="secondary"
                                    variant="contained"
                                    size="small"
                                    onClick={() => {
                                      push({
                                        saleitem_id: '0',
                                        description: '',
                                        notes: '',
                                        charge: 0,
                                        sales_person_id: '0',
                                      })
                                      setTimeout(() => {
                                        validateForm()
                                      }, 1)
                                    }}
                                    style={{width: '30px'}}
                                    id={'addWorkorderItem'}
                                    disabled={workorderitemsDisabled}
                                  >
                                    <Add />
                                  </Button>
                                ) : (
                                  <></>
                                )}
                              </Grid>
                              <Grid item xs={2}>
                                {!!autocreateOptions?.length &&
                                (values.is_billable ||
                                  (!values.is_billable &&
                                    values.workorderitems.length === 0)) ? (
                                  <MuiTextField
                                    select
                                    disabled={workorderitemsDisabled}
                                    label={
                                      <Typography
                                        style={{
                                          display: 'flex',
                                          alignItems: 'center',
                                        }}
                                      >
                                        <Add />
                                        Add service
                                      </Typography>
                                    }
                                    variant="outlined"
                                    size="small"
                                    value={''}
                                    fullWidth
                                  >
                                    {autocreateOptions.map((o, i) => (
                                      <MenuItem
                                        key={i}
                                        value={o}
                                        onClick={() => {
                                          push({
                                            saleitem_id: o.saleitem_id,
                                            description: o.description,
                                            man_minutes: o.man_minutes,
                                            notes: o.notes,
                                            charge: 0,
                                            sales_person_id: '0',
                                          })
                                          setTimeout(() => {
                                            validateForm()
                                          }, 1)
                                        }}
                                      >
                                        {o.saleitem?.name} {o.description} (
                                        {o.man_minutes} min)
                                      </MenuItem>
                                    ))}
                                  </MuiTextField>
                                ) : (
                                  <></>
                                )}
                              </Grid>
                            </Grid>
                          </>
                        )}
                      </FieldArray>
                    </Grid>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={onClose}>Cancel</Button>
                    <LoadingButton
                      variant="contained"
                      color="primary"
                      onClick={() => _submit(values, submitForm)}
                      loading={isSubmitting}
                      disabled={!isValid}
                      id="saveWorkorder"
                    >
                      Save
                    </LoadingButton>
                  </DialogActions>
                </>
              )
            }}
          </Formik>
        )}
      </Dialog>
    </>
  )
}

export default EditWorkorder
