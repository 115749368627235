import React from 'react'
import gql from 'graphql-tag'
import { ListItemText, InputLabel, MenuItem, colors } from '@mui/material'

import AutoComplete, { useAutoCompleteEndpoint } from './AutoComplete'
import Routes from '../constants/Routes'
import SelectMenu from '../components/ToolbarDropDownMenu'
import { useField, useFormikContext } from 'formik'
import { Brightness2, Brightness5 } from '@mui/icons-material'
import styled from 'styled-components'

let gqlFragment = gql`
  fragment Route on Routes {
    id
    name
    description
    shift_id
  }
`

function RouteSelect({ onChange, selectedId, ...props }) {
  let autoProps = useAutoCompleteEndpoint({
    gqlFragment,
    selectedId,
    searchField: 'name',
  })

  return (
    <AutoComplete
      {...autoProps}
      onChange={onChange}
      itemToString={u => u.name}
      renderItem={({ item }) => (
        <ListItemText id={'route-' + item.id} primary={item.name} />
      )}
      renderNoItems={() => <ListItemText primary={'No Routes found'} />}
      label={<InputLabel>Route</InputLabel>}
      hideIcon
      {...props}
    />
  )
}

export let RouteSelectStatic = ({ value = 'all', showAll = true, ...props }) => {
  let { data, loading } = useAutoCompleteEndpoint({
    gqlFragment,
    selectedId: value,
    searchField: 'name',
  })

  return loading ? (
    <SelectMenu value={value} label="Route" {...props}>
      <MenuItem disabled>...loading...</MenuItem>
    </SelectMenu>
  ) : (
    <SelectMenu value={value} label="Route" {...props}>
      {showAll && <MenuItem value={'all'}>All Routes</MenuItem>}
      {data.map(e => (
        <MenuItem key={e.edge?.id} value={e.edge?.id.toString()}>
          {' '}
          {'Route ' + (e.edge?.name?.toString() || e.edge?.id?.toString())}
        </MenuItem>
      ))}
    </SelectMenu>
  )
}

let SunIcon = styled(Brightness5)`
  color: ${colors.grey700};
`

let MoonIcon = styled(Brightness2)`
  color: ${colors.grey700};
`

export function RouteSelectFormik({ value, showAll = true, filter = () => true, name, children, ...props }) {
  let [field, { touched, error }] = useField({ name })
  let { isSubmitting } = useFormikContext()
  let { data, loading } = useAutoCompleteEndpoint({
    gqlFragment,
    selectedId: value,
    searchField: 'name',
  })

  return <SelectMenu label="Route" {...props} helperText={touched && error} error={touched && error} {...field}>
    {showAll && <MenuItem value={'all'}>All Routes</MenuItem>}

    {loading ? <MenuItem disabled>...loading...</MenuItem> : data.filter(filter).map(e => (
      <MenuItem key={e.edge?.id} value={e.edge?.id.toString()}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {e.edge?.shift_id === '1' ? <SunIcon /> : <MoonIcon />} &nbsp;
          {'Route ' + (e.edge?.name?.toString() || e.edge?.id?.toString())}
        </div>
      </MenuItem>
    ))}
  </SelectMenu>


  // return (
  //   <Select
  //     variant="standard"
  //     {...field}
  //     helperText={touched && error}
  //     error={touched && error}
  //     disabled={isSubmitting}
  //     children={children}
  //     input={<TextField {...props} children={children} select />}
  //     {...props}
  //   />
  // )
}

export default RouteSelect
