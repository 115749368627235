// @flow
export default `
  type Invoices @model(url: "/invoices", filters: "InvoicesFilters"){
    id: String
    relationships: InvoiceRel
    assigned_at: String
    serviced_at: String
    service_name: String
    service_address: String
    service_city_id: String
    service_state: String
    service_zip: String
    route_id: String
    routerun_id: String
    customer_id: String
    terms_id: String
    provider_po: String
    subtotal: Float
    tax: Float
    charge: Float
    balance_due: Float
    taxable: Boolean
    scanned_url: String
    status: String @updateable
    customer: Customers @relationship @notUpdateable
    terms: Terms @relationship @notUpdateable
    route: Routes @relationship @notUpdateable
    workorders: [Workorders] @relationship @notUpdateable
    recorded: Boolean @updateable
  }

  input InvoicesFilters {
    id: ID
    assigned_at: String
    route_id: String
    customer_id: String
    from: String
    to: String
    recorded: Boolean
    recordable: Boolean
  }

  enum CommissionStatus {
    PAID 
    UNPAID 
    CANCELED
  }

  # not entirely sure if any of these actually work
  type InvoiceRel {
    invoiceitems: [Invoiceitems]
    customer: Customers
    schedule: Schedules
    paymentitems: [Paymentitems]
    billcity: Cities
    servicecity: Cities
    routerun: Routeruns
    terms: Terms
    taxitem: Taxitems
    recordedincome: Recordedincomes
  }

  type Invoiceitems @model(url: "/invoiceitems", filters: "InvoiceitemFilters"){
    id: String
    relationships: InvoiceitemsRel
    invoice_id: String
    service_id: String
    saleitem_id: String
    description: String
    notes: String
    taxable: Boolean
    charge: Float
    invoice_status: String
    invoice_credit_memo: Boolean
    sales_person_id: String
    commission_percent: Float 
    commission: Float 
    gpm_percent: Float
    commission_status: CommissionStatus @updateable
    commission_complete_at: String @updateable
    discount_id: ID @updateable
    original_charge: Float 
    discount_type: Int
    discount_amount: Float
    saleitem: Saleitems @relationship @notUpdateable
    invoice: Invoices @relationship @notUpdateable
    sales_person: Users @relationship @notUpdateable
    discount: Discounts @relationship @notUpdateable
  }
  type InvoiceitemsRel {
    invoice: Invoices
    saleitem: Saleitems
    # service: Services
  }

  type Paymentitems @model(url: "/paymentitems"){
    id: ID
    payment_id: String @updateable
    invoice_id: String @updateable
    relatedable_id: String @updateable
    relatedable_type: String @updateable
    refund: Boolean @updateable
    amount: Float @updateable
  }

  type Cities @model(url: "/cities", filters: "CityFilters"){
    id: String
    name: String
    county: String
  }

  input CityFilters {
    name: String
  }

  type Routeruns {
    id: String
  }

  type Terms {
    id: String
    terms: String
    created_at: String
    updated_at: String
  }

  type Taxitems @model(url: "/taxitems", filters: "TaxitemFilters") {
    id: String
    name: String @updateable
    rate: Float @updateable
    linked_to_qb: Boolean
    deleted_at: String
  }

  input TaxitemFilters {
    name: String
    trashed: Boolean
  }

  type Recordedincomes {
    id: String
  }

  input InvoiceitemFilters {
    sales_person_id: ID
    commission_status: CommissionStatus
    not_from_void: String
    commission_complete_at: String
  }

  type Workorders @model(url: "/workorders", filters: "WorkordersFilters"){
    id: String
    customer_id: String @updateable
    schedule_id: String @updateable
    route_id: String @updateable
    invoice_id: String @updateable
    status: String @updateable
    not_completed: Boolean @updateable
    collected_payment: Boolean @updateable
    signature_unavailable: Boolean @updateable
    assigned_at: String @updateable
    completed_at: String @updateable
    description: String @updateable
    notes: String @updateable
    notes_resolved: Boolean @updateable
    sequence: Int @updateable
    arrival_start_time: String @updateable
    arrival_end_time: String @updateable
    created_at: String
    updated_at: String
    customer: Customers @relationship @notUpdateable
    invoice: Invoices @relationship @notUpdateable
    workorderitems: [Workorderitems] @relationship 
    routerun: RouteRuns @relationship @notUpdateable
    route: Routes @relationship @notUpdateable
    schedule: Schedules @relationship @notUpdateable
    multiday: Boolean @updateable
    reminder_sent: Boolean 
    multiday_estimated_total: Float 
    is_billable: Boolean @updateable
    photos: [Photos] @relationship
    payments: [Payments] @relationship @notUpdateable
    terms_id: ID @updateable
    terms: Terms @relationship @notUpdateable
    attachment: Attachments @relationship @notUpdateable
  }

  type Attachments {
    id: String
    scanned_url: String
  }

  input WorkordersFilters {
    id: ID
    customer_name: String
    customer_id: String
    notes: String
    status: [WorkorderStatus]
    route_id: String
    assigned_at: String
    from: String
    to: String
    schedule_id: String
    invoiced: String
    customertype: ID
    has_notes: String
    not_completed: String
    multiday: String,
    billable: String,
  }

  enum WorkorderStatus {
    OPEN
    COMPLETE
    VOID
  }

  type Workorderitems @model(url: "/workorderitems"){
    id: String
    workorder_id: ID @updateable
    saleitem_id: ID @updateable
    service_id: ID @updateable
    description: String @updateable
    man_minutes: Int @updateable
    notes: String @updateable
    taxable: Boolean @updateable
    charge: Float @updateable
    sales_person_id: ID @updateable
    commission_start_at: String @updateable
    discount_id: ID @updateable
    saleitem: Saleitems @relationship @notUpdateable
    service: Services @relationship @notUpdateable
    salesperson: Users @relationship @notUpdateable
    discount: Discounts @relationship @notUpdateable
    tasks: [Tasks] @relationship
  }

  extend type Mutation {
    resequenceWorkorder(input: ResequenceWorkorderInput, workorderId: ID!): Workorders 
  }

  input ResequenceWorkorderInput {
    sequence: Int!
    date: String!
    route_id: ID
  }

  input DiscountFilters {
    trashed: Boolean
    active: Boolean
    name: String
    status: DiscountStatus 
  }

  enum DiscountStatus {
    active
    inactive
  }

  type Discounts @model(url: "/discounts", filters: "DiscountFilters"){
    id: ID
    name: String @updateable
    type: Int @updateable
    amount: Float @updateable
    active: Boolean @updateable
    created_at: String
    updated_at: String
    deleted_at: String
  }

`
