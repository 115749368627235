import React from 'react'
import styled from 'styled-components'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'

let getMarginPosition = ({right}) => (right ? 'left' : 'right')
let getSpacing = ({theme}) => theme.muiTheme.spacing(1)
let ButtonIcon = styled.div`
  ${'margin-' + getMarginPosition}: ${getSpacing}px;
`

class PRButton extends React.Component {
  render() {
    let {loading, icon, iconPosition, children, ...props} = this.props
    icon = loading ? <CircularProgress size={24} thickness={3} /> : icon

    return (
      <Button {...props} disabled={loading}>
        {icon && iconPosition === 'left' && <ButtonIcon>{icon}</ButtonIcon>}
        {children}
        {icon && iconPosition === 'right' && (
          <ButtonIcon right>{icon}</ButtonIcon>
        )}
      </Button>
    )
  }
}
PRButton.defaultProps = {
  iconPosition: 'left',
}

export default PRButton
