import { AccountBalance, ArrowRightAlt, AttachMoney, Payment, Redeem, SvgIconComponent, Toll } from "@mui/icons-material";
import { Receipt } from "@mui/icons-material";
import ArrowLeftAlt from "../components/ArrowLeftAlt";
import { ComponentType, ReactFragment } from "react";
import theme from "../styles/theme";

export enum TransactionType {
    BALANCE_FORWARD = 'Balance Forward',
    INVOICE = 'Invoice',
    REFUND = 'Refund',
    TRANSFER_OUT = 'Transfer Out',
    ACCOUNT_TRANSFER_OUT = 'Account Transfer Out',
    PAYMENT = 'Payment',
    ACCOUNT_PAYMENT = 'Account Payment',
    ACCOUNT_PAYMENT_EXTERNAL = 'Account Payment (external)',
    TRANSFER_IN = 'Transfer In',
    ACCOUNT_TRANSFER_IN = 'Account Transfer In',
}
type HrefRenderFunction = (id: number, parentId?: number, redirect?: string) => string;

type TransactionTypeInfo = {
    [key in TransactionType]: {
      sign: number;
      icon: SvgIconComponent | ComponentType<SvgIconComponent>;
      color: string | undefined;
      href?: HrefRenderFunction;
    };
  };

export const TRANSACTION_TYPE_INFO: TransactionTypeInfo = {
    [TransactionType.BALANCE_FORWARD]: {
        sign: 1,
        icon: Toll,
        color: undefined,
    },
    [TransactionType.INVOICE]: {
        sign: 1,
        icon: Receipt,
        color: theme.palette.danger.main,
        href: (id: number) => `/old/database/invoice.php?InvoiceNumber=${id}`
    },
    [TransactionType.REFUND]: {
        sign: 1,
        icon: Redeem,
        color: theme.palette.success.main,
        href: (id:number, parentId?: number, redirect?: string) => `/old/database/refundedit.php?JobID=${parentId}&RefundID=${id}&redirect=${redirect}`
    },
    [TransactionType.TRANSFER_OUT]: {
        sign: 1,
        icon: ArrowRightAlt,
        color: undefined 
    },
    [TransactionType.ACCOUNT_TRANSFER_OUT]: {
        sign: 1,
        icon: ArrowRightAlt,
        color: undefined
    },
    [TransactionType.PAYMENT]: {
        sign: -1,
        icon: AttachMoney,
        color: theme.palette.success.main
    },
    [TransactionType.ACCOUNT_PAYMENT]: {
        sign: -1,
        icon: AccountBalance,
        color: theme.palette.success.main
    },
    [TransactionType.ACCOUNT_PAYMENT_EXTERNAL]: {
        sign: -1,
        icon: AccountBalance,
        color: theme.palette.success.main
    },
    [TransactionType.TRANSFER_IN]: {
        sign: -1,
        icon: ArrowLeftAlt,
        color: undefined
    },
    [TransactionType.ACCOUNT_TRANSFER_IN]: {
        sign: -1,
        icon: ArrowLeftAlt,
        color: undefined 
    }
}
