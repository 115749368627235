import React from 'react'
import gql from 'graphql-tag'
import { Chip, MenuItem, Select, TextField } from '@mui/material'

import { useAutoCompleteEndpoint } from './AutoComplete'
import SelectMenu from '../components/ToolbarDropDownMenu'
import { useAuth } from '../security/auth'
import { useQuery } from 'react-query'
import { prgql } from '../utils/graphql'

let gqlFragment = gql`
  fragment Role on Roles {
    id
    name
  }
`

let query = gql`
  query AllRoles {
    data: allRoles(limit: 100) {
      edges {
        edge {
          id
          name
        }
      }
    }
  }
`

export let RoleSelectStatic = ({ value = 'all', showAll = true, ...props }) => {
  let { status: isAdministrator } = useAuth('fhqwhgads')

  let { data, isLoading: loading } = useQuery({ queryKey: 'roles', queryFn: () => prgql({ query }) })

  return loading ? (
    <SelectMenu value={value} label="Role" {...props}>
      <MenuItem disabled>...loading...</MenuItem>
    </SelectMenu>
  ) : (
    <Select
      label={'Roles'}
      value={value}
      multiple
      input={<TextField {...props} select label="Roles" />}
      renderValue={selected => {
        return (
          <div style={{ display: 'flex' }}>
            {selected
              .sort((a, b) => a - b)
              .map(v => {
                let r = data.data.edges.find(r => r.edge.id === String(v))
                return r && <Chip key={v} label={r.edge.name} style={{ marginRight: '2px' }} />
              })}
          </div>
        )
      }}
      style={{ minWidth: '100px' }}
      {...props}
    >
      {data.data.edges
        .filter(({ edge: role }) =>
          isAdministrator !== 'authorized'
            ? role.name !== 'Administrator'
            : true,
        )
        .map(({ edge: role }) => (
          <MenuItem key={role.id} value={role.id.toString()}>
            {role.name}
          </MenuItem>
        ))}
    </Select>
  )
}

export default RoleSelectStatic
