import React from 'react'
import styled from 'styled-components'

import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as authActions from '../../actions/authActions'
import { Redirect } from '../../pr-router'
import { DarkTheme } from '../../styles/theme'
import BethanyLogo from '../../components/icons/BethanyLogo'

let Container = styled.div`
  background: linear-gradient(135deg, #003768 5%, #e86c1f 95%);
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  grid-gap: 48px;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 2000;
`
let Controls = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-gap: 16px;
`
let Logo = styled(BethanyLogo)`
  justify-self: end;
  width: 128px;
  height: 128px;
`

class Login extends React.Component {
  state = { username: '', password: '' }
  componentWillReceiveProps({ loggedIn, location }) {
    const { search } = location
    let params = new URLSearchParams(search)
    // If the 'intended' param is present we need
    // to direct the user back to the old database
    if (loggedIn && params.has('intended')) {
      window.location.replace(params.get('intended'))
    }
  }
  login() {
    const actions = bindActionCreators(authActions, this.props.dispatch)
    const username = this.state.username.trim()
    const password = this.state.password.trim()
    actions.login(username, password)
  }

  onKeyPress(e) {
    // enter
    if (e.charCode === 13) this.login()
  }

  render() {
    const { loggedIn, error, shouldRedirect } = this.props
    const { state } = this.props.location
    const { nextPathname } = state || {}
    let redirectTo = nextPathname || '/'

    return (
      <DarkTheme>
        <Container>
          {loggedIn && <Redirect to={redirectTo} />}
          <Logo />
          <Controls>
            <TextField
              variant="standard"
              error={!!error}
              label="User name"
              name="username"
              onKeyPress={this.onKeyPress.bind(this)}
              onChange={e => this.setState({ username: e.target.value })} />
            <TextField
              variant="standard"
              error={!!error}
              label="password"
              name="password"
              type="password"
              onChange={e => this.setState({ password: e.target.value })}
              onKeyPress={this.onKeyPress.bind(this)}
              helperText={error ? 'Wrong User Name or Password' : ''} />
            <Button
              variant="contained"
              color="primary"
              onClick={this.login.bind(this)}
            >
              Login
            </Button>
          </Controls>
        </Container>
      </DarkTheme>
    );
  }
}
Login = connect(state => ({
  currentUser: state.auth.get('currentUser'),
  error: state.auth.get('error'),
  loggedIn: state.auth.get('loggedIn'),
  shouldRedirect: state.auth.get('shouldRedirect'),
}))(Login)
export default Login
