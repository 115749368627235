import PropTypes from 'prop-types'
import React, {Component} from 'react'
import _ from 'lodash'
import {Api} from '../../api/api-connect'
import Dropzone from 'react-dropzone'
import Paper from '@mui/material/Paper'
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  ImageListItem,
} from '@mui/material'
import {Link} from '../../pr-router'
import Divider from '@mui/material/Divider'
import {PaperToolbar} from '../../components/Toolbar'
import ToolbarRow from '../../components/ToolbarRow'
import ToolbarGroup from '../../components/ToolbarGroup'
import IconButton from '@mui/material/IconButton'
// import FlatButton from '@mui/material/FlatButton'
// import RaisedButton from '@mui/material/RaisedButton'
import Fab from '@mui/material/Fab'
import Edit from '@mui/icons-material/Edit'
import Check from '@mui/icons-material/Check'
import Delete from '@mui/icons-material/Delete'
import Close from '@mui/icons-material/Close'
import AddAPhoto from '@mui/icons-material/AddAPhoto'
import Dialog from '@mui/material/Dialog'
import DeleteDialog from '../../components/DeleteDialog'
import TextField from '@mui/material/TextField'
import TableList from '../../components/Table/TableList'
import EntityColumn from '../../components/Table/EntityColumn'
import {Theme} from '../../styles/theme'
import {Caption, Body1} from '../../components/Typography'
import filesize from 'filesize'
import LoadingButton from '../../components/LoadingButton'
import Checkbox from '@mui/material/Checkbox'

let PhotosWrapper = props => (
  <Paper
    style={{
      width: '800px',
      margin: '30px auto',
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
    }}
    {...props}
  />
)
let CustomerImage = props => (
  <img style={{width: '100px', margin: 'auto'}} alt="customer" {...props} />
)
let size = 36
let ItemIcon = props => (
  <IconButton
    style={{width: size, height: size, padding: '1px'}}
    {...props}
    size="large"
  />
)

let DialogWrapper = props => (
  <div style={{display: 'flex', alignItems: 'center'}} {...props} />
)

let DialogDescription = props => (
  <div
    style={{
      flexGrow: 1,
      alignSelf: 'stretch',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-around',
      marginLeft: '72px',
    }}
    {...props}
  />
)

class PhotosToolbar extends Component {
  static propTypes = {backTo: PropTypes.string, onAddPhoto: PropTypes.func}
  static defaultProps = {
    backTo: '',
    onAddPhoto: () => {},
  }
  render() {
    let {backTo, onAddPhoto} = this.props
    return (
      <PaperToolbar>
        <ToolbarRow>
          <ToolbarGroup first>
            <Link to={backTo}>
              <IconButton onClick={() => {}} size="large">
                <Close />
              </IconButton>
            </Link>
          </ToolbarGroup>
          <ToolbarGroup />
          <ToolbarGroup last />
          <Fab onClick={onAddPhoto} secondary>
            <AddAPhoto />
          </Fab>
        </ToolbarRow>
      </PaperToolbar>
    )
  }
}

let PhotoTile = () => (
  <Theme>
    {theme => (
      <div
        style={{
          width: '100%',
          height: '100%',
          backgroundColor: theme.palette.primary1Color,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <AddAPhoto color="white" style={{width: '64px', height: '64px'}} />
      </div>
    )}
  </Theme>
)

export class AddPhotoDialog extends Component {
  static propTypes = {
    onSave: PropTypes.func,
    loading: PropTypes.bool,
    onClose: PropTypes.func,
  }
  static defaultProps = {
    onSave: () => {},
    loading: false,
    onClose: () => {},
  }
  freshState = () => ({newPhoto: {description: '', isDefault: false}})
  state = this.freshState()
  handleChange = changeset =>
    this.setState(({newPhoto}) => ({newPhoto: {...newPhoto, ...changeset}}))
  handleDrop = photos => {
    this.handleChange({photo: photos[0]})
    // this.descriptionField.focus()
  }
  handleSave = () =>
    this.props.onSave(this.state.newPhoto, () =>
      this.setState(this.freshState()),
    )
  validate = p => ({cantSave: !p.photo})
  render() {
    let {onSave: _onSave, loading, onClose, ...props} = this.props
    let {
      newPhoto: {photo, description, isDefault},
    } = this.state
    let errors = this.validate(this.state.newPhoto)
    return (
      <Dialog
        title="Add Photo"
        actions={[
          <Button label="Cancel" primary onClick={props.onRequestClose} />,
          <LoadingButton
            loading={loading}
            buttonElement={<Button />}
            disabled={errors.cantSave}
            label="Add Photo"
            primary
            onClick={this.handleSave}
          />,
        ]}
        {...props}
      >
        <DialogTitle>Upload Photo</DialogTitle>
        <DialogContent>
          <DialogWrapper>
            <Dropzone onDrop={this.handleDrop} multiple={false}>
              {photo ? (
                <ImageListItem
                  title={filesize(photo.size)}
                  subtitle="Click or drag-and-drop to change"
                >
                  <img src={photo.preview} alt="preview" />
                </ImageListItem>
              ) : (
                <ImageListItem title="Click or Drag-and-Drop">
                  <PhotoTile />
                </ImageListItem>
              )}
            </Dropzone>
            <DialogDescription>
              {/* <TextField
              value={description}
              floatingLabelText="description"
              ref={input => {
                this.descriptionField = input
              }}
              onChange={(e, v) => this.handleChange({description: v})}
            /> */}
              {/* <Checkbox
              checked={isDefault}
              label="Make default photo"
              onCheck={(e, isChecked) =>
                this.handleChange({isDefault: isChecked})}
            /> */}
            </DialogDescription>
          </DialogWrapper>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={this.handleSave}>Save</Button>
        </DialogActions>
      </Dialog>
    )
  }
}

class CustomerPhotos extends Component {
  static propTypes = {customerId: PropTypes.string.isRequired}
  state = {
    photoChanges: {},
    editingPhoto: false,
    isAddingPhoto: false,
    deletePhoto: false,
    defaultPhotoChanged: Symbol(),
  }
  isEditing = p => p.id === this.state.editingPhoto
  editPhoto = p => this.setState({editingPhoto: p.id})
  cancelEdit = () => this.setState({editingPhoto: false})
  updatePhoto = ({id, attributes}) =>
    this.setState(({photoChanges}) => ({
      photoChanges: {
        ...photoChanges,
        [id]: {...photoChanges[id], ...attributes},
      },
    }))
  handleTextChange = (e, value) =>
    this.updatePhoto({
      id: e.target.dataset.id,
      attributes: {[e.target.dataset.key]: value},
    })
  render() {
    let {customerId} = this.props
    let {
      photoChanges,
      isAddingPhoto,
      confirmDeletePhoto,
      defaultPhoto,
      defaultPhotoChanged,
    } = this.state

    let photosUrl = `customers/${customerId}/photos`
    let refreshPhotos = {
      photos: {url: photosUrl, refreshing: true, force: true},
    }
    let setDefaultPhoto = p => ({
      url: `customers/${customerId}`,
      method: 'PUT',
      body: JSON.stringify({data: {relationships: {photo: {data: p}}}}),
      then: () => this.setState({defaultPhotoChanged: Symbol()}),
    })
    let connect = {
      photos: photosUrl,
      defaultPhoto: {
        url: `customers/${customerId}?include=photo`,
        comparison: defaultPhotoChanged,
        then: c => ({value: c.data.relationships.photo}),
      },
      savePhoto: p => ({
        savedPhoto: {
          url: `photos/${p.id}`,
          method: 'PUT',
          body: JSON.stringify({data: {attributes: photoChanges[p.id]}}),
          then: () => this.cancelEdit(),
        },
        setDefaultPhoto: defaultPhoto
          ? setDefaultPhoto(defaultPhoto)
          : undefined,
      }),
      createPhoto: ({photo, description, isDefault}, cb) => {
        let body = new FormData()
        body.append('photo', photo)
        return {
          newPhoto: {
            url: `customers/${customerId}/photos`,
            method: 'POST',
            force: true,
            headers: {'Content-Type': undefined},
            body,
            then: p => {
              this.setState({isAddingPhoto: false})
              cb()
              return {
                url: `photos/${p.data.id}`,
                method: 'PUT',
                andThen: ({data}) => ({
                  ...refreshPhotos,
                  setDefaultPhoto: isDefault
                    ? setDefaultPhoto(data)
                    : undefined,
                }),
                body: JSON.stringify({
                  data: {attributes: {description: description}},
                }),
              }
            },
          },
        }
      },
      deletePhoto: ({id}) => ({
        deletedPhoto: {
          url: `customers/${customerId}/photos/${id}`,
          method: 'DELETE',
          then: () =>
            this.setState({
              confirmDeletePhoto: false,
              defaultPhotoChanged: Symbol(),
            }),
          andThen: () => refreshPhotos,
        },
      }),
    }
    return (
      <Api connect={connect}>
        {({
          photos,
          defaultPhoto,
          savePhoto,
          createPhoto,
          deletePhoto,
          deletedPhoto,
          newPhoto,
        }) => (
          <PhotosWrapper>
            <PhotosToolbar
              backTo={`/customers/${customerId}`}
              onAddPhoto={() => this.setState({isAddingPhoto: true})}
            />
            <Divider />
            {photos.fulfilled ? (
              <TableList
                rowGetter={({index}) => photos.value.data[index]}
                rowCount={photos.value.data.length}
                rowHeight={120}
                headerHeight={0}
                disableHeader
              >
                <EntityColumn
                  attribute="medium_url"
                  cellRenderer={({entityData, entity}) => (
                    <a href={entity.attributes.url} target="_blank">
                      <CustomerImage src={entityData} />
                    </a>
                  )}
                  width={100}
                />
                <EntityColumn
                  attribute="description"
                  width={200}
                  flexGrow={1}
                  cellRenderer={({entityData, entity}) =>
                    this.isEditing(entity) ? (
                      <TextField
                        variant="standard"
                        defaultValue={entityData}
                        multiLine
                        fullWidth
                        floatingLabelText="description"
                        onChange={this.handleTextChange}
                        data-id={entity.id}
                        data-key="description"
                        autoFocus
                      />
                    ) : (
                      <Body1>{entityData}</Body1>
                    )
                  }
                />
                <EntityColumn
                  width={100}
                  cellRenderer={({entity}) => {
                    let isDefault =
                      _.get(defaultPhoto, 'value.data.id') === entity.id
                    return this.isEditing(entity) ? (
                      <Checkbox
                        color="secondary"
                        defaultChecked={isDefault}
                        disabled={isDefault}
                        label="Default"
                        onCheck={(e, isChecked) =>
                          this.setState({
                            defaultPhoto: isChecked ? entity : undefined,
                          })
                        }
                      />
                    ) : isDefault ? (
                      <Caption>default</Caption>
                    ) : null
                  }}
                />
                <EntityColumn
                  findEntity={false}
                  width={100}
                  style={{textAlign: 'right'}}
                  cellRenderer={({entity}) =>
                    this.isEditing(entity) ? (
                      <div>
                        <ItemIcon onClick={() => savePhoto(entity)}>
                          <Check />
                        </ItemIcon>
                        <ItemIcon onClick={this.cancelEdit}>
                          <Close />
                        </ItemIcon>
                      </div>
                    ) : (
                      <div>
                        <ItemIcon onClick={() => this.editPhoto(entity)}>
                          <Edit />
                        </ItemIcon>
                        <ItemIcon
                          onClick={() =>
                            this.setState({confirmDeletePhoto: entity})
                          }
                        >
                          <Delete />
                        </ItemIcon>
                      </div>
                    )
                  }
                />
              </TableList>
            ) : null}
            <AddPhotoDialog
              open={isAddingPhoto}
              loading={newPhoto && newPhoto.pending}
              onRequestClose={() => this.setState({isAddingPhoto: false})}
              onSave={createPhoto}
            />
            <DeleteDialog
              isOpen={!!confirmDeletePhoto}
              loading={deletedPhoto && deletedPhoto.pending}
              onConfirm={() => deletePhoto(confirmDeletePhoto)}
              onCancel={() => this.setState({confirmDeletePhoto: false})}
              message={
                <div style={{display: 'flex', alignItems: 'center'}}>
                  <div style={{maxWidth: '400px', overflow: 'hidden'}}>
                    <img
                      src={
                        confirmDeletePhoto &&
                        confirmDeletePhoto.attributes.large_url
                      }
                      alt="To delete"
                      style={{height: '200px'}}
                    />
                  </div>
                  <div
                    style={{
                      flexGrow: 1,
                      textAlign: 'center',
                      padding: '15px',
                    }}
                  >
                    Are you sure you want to delete this photo?
                  </div>
                </div>
              }
            />
          </PhotosWrapper>
        )}
      </Api>
    )
  }
}

export default CustomerPhotos
