import React, {useEffect} from 'react'
import DangerLoadingButton from '../../components/DangerLoadingButton'
import styled from 'styled-components'
import _ from 'lodash'

import Table from '@mui/material/Table'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import FileCopy from '@mui/icons-material/FileCopy'
import Delete from '@mui/icons-material/Delete'
import Print from '@mui/icons-material/Print'
import Assignment from '@mui/icons-material/Assignment'
import Button from '@mui/material/Button'
import gql from 'graphql-tag'
import {useState} from 'react'
import {
  PaperToolbar,
  DetailRow,
  ToolbarRight,
  ToolbarLeft,
  ToolbarIconMenu,
} from '../../components/Toolbar'
import Add from '@mui/icons-material/Add'
import Typography from '@mui/material/Typography'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  FormControlLabel,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  Switch,
  TextField,
  Tooltip,
} from '@mui/material'
import {formatMoneyStandard} from '../../utils/moneyFormatter'
import {fetchAPI} from '../../schema/utils'
import CircularProgress from '@mui/material/CircularProgress'
import {Chip, Badge} from '@mui/material'
import {RESIDENTIAL} from '../../constants/CustomerTypes'
import toaster from '../../utils/toaster'
import LoadingButton, {LoadingMenuItem} from '../../components/LoadingButton'
import {useMutation, useQuery, useQueryClient} from 'react-query'
import {prgql} from '../../utils/graphql'
import {Check} from '@mui/icons-material'
import {
  AirplaneTicket,
  Archive,
  Cabin,
  Cancel,
  Download,
  Feed,
  Forward,
  Grading,
  LocationCity,
  Park,
  Restore,
  Save,
  SmsFailed,
  Toc,
} from '@mui/icons-material'
import {Link} from 'react-router-dom'
import {ToolbarFab} from '../../components/ToolbarRow'
import SpeedDialFAB from '../../components/SpeedDialFAB'
import urlJoin from '../../utils/url-join'
import {Toolbar} from '../../AppHandler'
import {
  estimatePrice,
  estimateserviceFragment,
  mungeEstimateDataForCalculation,
} from './Estimate'
import {Space} from '../../components/Layout'
import toast from 'react-hot-toast'
import moment from 'moment'
import ModifyQueryParams from '../../components/ModifyQueryParams'
import queryString from 'query-string'
import colors from '../../styles/colors'
import {WhiteBackgroundSwitch} from '../../components/WhiteBackgroundSwitch'

const styles = {
  numberFormat: {textAlign: 'right'},
  centeredColumn: {textAlign: 'center'},
  actionColumn: {textAlign: 'right'},
}

let PathRabbitEstimate = () => (
  <Tooltip title="Residential Window Cleaning">
    <Fab variant="contained" color="secondary">
      +<Assignment />
    </Fab>
  </Tooltip>
)

let Airtable = ({style, type}) => (
  <span
    style={{fontWeight: 900, fontSize: '1.5em', lineHeight: '1em', ...style}}
  >
    A <AirtableTypeIcon type={type} style={{fontSize: '1em'}} />
  </span>
)

let TinyCirclularProgress = styled(CircularProgress)`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -12px;
  margin-left: -12px;
`

let AirtableTypeIcon = ({type, style}) =>
  type === 'AIRTABLE_PW' || type === 'AIRTABLE_PW2' || type === 'estimates' ? (
    <Cabin style={{...style}} />
  ) : type === 'AIRTABLE_PRO' || type === 'proposals' ? (
    <LocationCity style={{...style}} />
  ) : type === 'AIRTABLE_CMA' || type === 'christmas' ? (
    <Park style={{...style}} />
  ) : type === 'AIRTABLE_SF' || type === 'salesform' ? (
    <Toc style={{...style}} />
  ) : (
    <Feed style={{...style}} />
  )

let tooltipText = ({type}) =>
  type === 'AIRTABLE_PW' || type === 'AIRTABLE_PW2' || type === 'estimates'
    ? 'Residential Power Washing'
    : type === 'AIRTABLE_PRO' || type === 'proposals'
    ? 'Commercial Proposal'
    : type === 'AIRTABLE_CMA' || type === 'christmas'
    ? 'Christmas Lights'
    : type === 'AIRTABLE_SF' || type === 'salesform'
    ? 'Sales Form'
    : 'Other'

let AirtableEstimate = ({
  cust,
  loading,
  setLoading,
  type = 'estimates',
  isAccount = false,
}) => (
  <Tooltip title={tooltipText({type})}>
    <Fab
      variant="contained"
      color="secondary"
      onClick={() => _newEstimateAirtable(cust, setLoading, type, isAccount)}
      disabled={loading}
    >
      +<Airtable type={type} />
    </Fab>
    {loading ? <TinyCirclularProgress color="primary" size={24} /> : null}
  </Tooltip>
)

let PrintDialog = ({isOpen, id, onCancel}) => {
  const [selectedItemsLayout, setSelectedItemsLayout] = useState(false)

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={onCancel}
        maxWidth="lg"
        fullWidth
        PaperProps={{style: {height: '80vh'}}}
      >
        <DialogTitle>Print Estimate</DialogTitle>
        <DialogContent>
          <TextField
            select
            variant="outlined"
            size="small"
            margin="dense"
            onChange={e => {
              setSelectedItemsLayout(e.target.value)
              _printPreview(id, e.target.value)
            }}
            label="Print Layout"
            value={selectedItemsLayout}
            style={{width: '200px'}}
          >
            <MenuItem value={false}>Print All Items</MenuItem>
            <MenuItem value={true}>Print Selected Items</MenuItem>
          </TextField>
          <iframe id="boogabooga" width={'100%'} height="100%"></iframe>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

let estimatesQuery = gql`
  query Estimates($id: String!) {
    customers(id: $id) {
      __typename
      id
      name
      customertype_id
      estimates {
        id
        name
        customer_id
        data_source
        data_source_type
        airtable_url
        subtotal
        total
        approved
        archived

        created_at
        updated_at

        discount_id
        discount {
          id
          name
          type
          amount
        }
        estimateitems {
          id
          description
          qty
          selected
          frequency
          price_override
          estimateservice_id
          estimateservice {
            ...EstimateserviceDetails
          }
          attributeoptions {
            id
            label
            price
            attribute_id
          }
        }
      }
    }
  }
  ${estimateserviceFragment}
`

let estimatesAccountsQuery = gql`
  query Estimates($id: String!) {
    customers: accounts(id: $id) {
      __typename
      id
      name
      customertype_id
      estimates {
        id
        name
        account_id
        data_source
        data_source_type
        airtable_url
        subtotal
        total
        approved
        archived
        discount_id
        discount {
          id
          name
          type
          amount
        }
        estimateitems {
          id
          description
          qty
          selected
          frequency
          estimateservice_id
          estimateservice {
            ...EstimateserviceDetails
          }
          attributeoptions {
            id
            label
            price
            attribute_id
          }
        }
      }
    }
  }
  ${estimateserviceFragment}
`

let airtableEstimatesQuery = gql`
  query AirtableEstimates($id: String!) {
    customers(id: $id) {
      id
      name
      airtable_estimates {
        id
        name
        customer_id
        data_source
        data_source_type
        airtable_url
        discount_id
        subtotal
        total
        approved
      }
    }
  }
`

let airtableEstimatesAccountQuery = gql`
  query AirtableEstimates($id: String!) {
    customers: accounts(id: $id) {
      id
      name
      airtable_estimates {
        id
        name
        customer_id
        data_source
        data_source_type
        airtable_url
        discount_id
        subtotal
        total
        approved
      }
    }
  }
`

let updateMutation = gql`
  mutation Update($input: UpdateEstimatesInput) {
    updateEstimates(input: $input) {
      estimates {
        id
      }
    }
  }
`

let _newEstimateAirtable = async (
  cust,
  setLoading,
  type = 'estimates',
  isAccount = false,
) => {
  setLoading(true)
  let queryParms = !isAccount
    ? {
        customerId: cust.id,
      }
    : {accountId: cust.id}
  let res = await fetchAPI({
    url: '/airtable/' + type,
    query: queryParms,
    options: {method: 'POST'},
  })
  if (!res.ok) {
    toaster.error('Something went wrong; Airtable may be down')
    setLoading(false)
    return
  }
  let url = await res.text()

  setLoading(false)
  window.open(url)
}

let _link = est =>
  est.data_source === 'Airtable'
    ? est.airtable_url
    : `/old/database/estimate.php?${
        est.account_id
          ? 'account_id=' + est.account_id
          : 'JobID=' + est.customer_id
      }&action=1&EstimateID=${est.id}`

let _newEstimateLink = cust =>
  `/old/database/estimate.php?${
    cust.__typename === 'Accounts' ? 'account_id' : 'JobID'
  }=${cust.id}&action=1`

let _deleteEstimateLink = est =>
  est.data_source === 'Airtable'
    ? '#'
    : `/old/database/estimate.php?${
        est.account_id
          ? 'account_id=' + est.account_id
          : 'JobID=' + est.customer_id
      }&action=3&EstimateID=${est.id}`
let _duplicateEstimateLink = est =>
  est.data_source === 'Airtable'
    ? '#'
    : `/old/database/estimate.php?${
        est.account_id
          ? 'account_id=' + est.account_id
          : 'JobID=' + est.customer_id
      }&action=5&EstimateID=${est.id}`
let _printEstimateLink = est =>
  est.data_source === 'Airtable'
    ? '#'
    : `/old/database/estimatesheet.php?JobID=${est.customer_id}&EstimateID=${est.id}`

function _printPreview(id, printSelected) {
  let response = fetchAPI({
    url: `/estimates/${id}?include=estimateitems.estimateservice&printSelected=${
      printSelected ? 'true' : 'false'
    }`,
    options: {
      method: 'GET',
      headers: {
        Accept: 'application/pdf',
      },
    },
  })
  response.then(async res => {
    let blob = await res.blob()
    let data_url = URL.createObjectURL(blob)
    document.querySelector('#boogabooga').src = data_url
  })
}

const AIRTABLE_DATA_SOURCE_TYPE_URL = {
  AIRTABLE_PW: 'estimates',
  AIRTABLE_PW2: 'estimates',
  AIRTABLE_PRO: 'proposals',
  AIRTABLE_CMA: 'christmas',
  AIRTABLE_SF: 'salesform',
}

function CustomerEstimates({match, location}) {
  let isAccount = (match.path.split('/')[1] || undefined) !== 'customers'
  let queryClient = useQueryClient()

  let {data, isLoading: loading} = useQuery(
    ['estimates', match.params.id],
    async () =>
      await prgql({
        query: !isAccount ? estimatesQuery : estimatesAccountsQuery,
        variables: {
          id: match.params.id,
        },
      }),
  )
  let {data: airtable_data, isLoading: airtable_loading} = useQuery(
    ['estimates', match.params.id, 'airtable', isAccount],
    async () =>
      prgql({
        query: !isAccount
          ? airtableEstimatesQuery
          : airtableEstimatesAccountQuery,
        variables: {
          id: match.params.id,
        },
      }),
  )

  const {
    mutateAsync: duplicatePREstimate,
    isLoading: duplicateLoading,
  } = useMutation('duplicateEstimate', async id => {
    await fetchAPI({
      url: `/estimates/${id}/duplicate`,
      options: {method: 'POST'},
    })
  })

  const {mutateAsync: deletePREstimate, isLoading: deleteLoading} = useMutation(
    'deleteEstimate',
    async id => {
      await fetchAPI({url: `/estimates/${id}`, options: {method: 'DELETE'}})
    },
  )

  let {mutateAsync, isLoading: mutateLoading} = useMutation(
    'updateestlist',
    async input => {
      return await prgql({
        query: updateMutation,
        variables: {input},
      })
    },
  )

  let _duplicateAirtableEstimate = async (
    airtable_id,
    setLoading,
    type = 'proposals',
  ) => {
    setLoading(airtable_id)
    let res = await fetchAPI({
      url: `/airtable/${type}/${airtable_id}/duplicate`,
      options: {method: 'POST'},
    })

    if (!res.ok) {
      toaster.error('Something went wrong; Airtable may be down')
      setLoading(false)
      return
    }
    let url = await res.text()
    queryClient.invalidateQueries('estimates')
    setLoading(false)
  }

  let _deleteProposal = async (airtable_id, type = 'proposals') => {
    setAirDelLoading(true)
    let res = await fetchAPI({
      url: `/airtable/${type}/${airtable_id}`,
      options: {method: 'DELETE'},
    })

    if (!res.ok) {
      toaster.error('Something went wrong; Airtable may be down')
      setAirDelLoading(false)
      return
    }
    let url = await res.text()
    queryClient.invalidateQueries('estimates')

    setAirDelLoading(false)
  }

  let query = queryString.parse(location.search)
  query.archived = query.archived === 'true' ? true : false

  let [airLoading, setAirLoading] = useState(false)
  let [airCmasLoading, setAirCmasLoading] = useState(false)
  let [airDupLoading, setAirDupLoading] = useState(false)
  let [airDelLoading, setAirDelLoading] = useState(false)
  let [deleteModal, setDeleteModal] = useState(false)
  let [deleteAirtableModal, setDeleteAirtableModal] = useState(false)
  let [deleteType, setDeleteType] = useState(false)
  let [printEstimate, setPrintEstimate] = useState(false)
  let [docName, setDocName] = useState(false)
  let [queryChanges, setQueryChanges] = useState({
    archived: query.archived || false,
  })

  if (loading && airtable_loading) {
    return <>Loading</>
  }

  let isDisabled = est => est.data_source_type === 'AIRTABLE_PW' || est.approved

  let estimates = [
    ...(data?.customers?.estimates.filter(
      e => e.archived === queryChanges.archived,
    ) || []),
    ...(airtable_data?.customers?.airtable_estimates || []),
  ]

  return (
    <>
      <div>
        <Toolbar>
          <ToolbarLeft style={{justifyContent: 'flex-start'}}>
            <Typography variant="h6">Estimates</Typography>
            <FormControlLabel
              style={{marginLeft: '2em'}}
              control={
                <WhiteBackgroundSwitch
                  checked={query.archived}
                  onChange={e => {
                    setQueryChanges({...query, archived: !query.archived})
                  }}
                  color="secondary"
                />
              }
              label={'Show Archived'}
            />
          </ToolbarLeft>
          <ToolbarFab>
            <SpeedDialFAB
              dialButtons={[
                <Link to={urlJoin`${match.url}/new`} key={1}>
                  <Tooltip title="Create Estimate">
                    <Fab color="secondary" id="newEstimate">
                      +<Grading />
                    </Fab>
                  </Tooltip>
                </Link>,
                <a key={2} href={_newEstimateLink(data?.customers || {})}>
                  <PathRabbitEstimate />
                </a>,
                // <AirtableEstimate
                //   key={3}
                //   cust={data.customers}
                //   setLoading={setAirLoading}
                //   loading={airLoading}
                //   type={AIRTABLE_DATA_SOURCE_TYPE_URL['AIRTABLE_PW']}
                //   isAccount={isAccount}
                // />,
                // <AirtableEstimate
                //   key={4}
                //   cust={data.customers}
                //   setLoading={setAirLoading}
                //   loading={airLoading}
                //   type={AIRTABLE_DATA_SOURCE_TYPE_URL['AIRTABLE_PRO']}
                //   isAccount={isAccount}
                // />,
                <AirtableEstimate
                  key={5}
                  cust={data.customers}
                  setLoading={setAirLoading}
                  loading={airLoading}
                  type={AIRTABLE_DATA_SOURCE_TYPE_URL['AIRTABLE_CMA']}
                  isAccount={isAccount}
                />,
                <AirtableEstimate
                  key={6}
                  cust={data.customers}
                  setLoading={setAirLoading}
                  loading={airLoading}
                  type={AIRTABLE_DATA_SOURCE_TYPE_URL['AIRTABLE_SF']}
                  isAccount={isAccount}
                />,
              ]}
            >
              <Add />
            </SpeedDialFAB>
          </ToolbarFab>
        </Toolbar>

        <Table selectable={false}>
          <TableHead displaySelectAll={false} adjustForCheckbox={false}>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Quote Description</TableCell>
              <TableCell>Subtotal</TableCell>
              <TableCell>Total</TableCell>
              <TableCell>Approved</TableCell>
              <TableCell style={styles.numberFormat}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody displayRowCheckbox={false} showRowHover={true}>
            {estimates.map(est => {
              return (
                <TableRow
                  key={est.id}
                  style={{backgroundColor: est.archived ? colors.grey200 : ''}}
                >
                  <TableCell size="small" style={{width: '100px'}}>
                    {/* <a href={_printEstimateLink(est)} style={{width: '1em'}}> */}
                    <Button
                      variant="contained"
                      disabled={est.data_source === 'Airtable'}
                      onClick={() => {
                        setPrintEstimate(est.id)
                        _printPreview(est.id)
                      }}
                    >
                      <Print />
                    </Button>
                    {/* </a> */}
                  </TableCell>
                  <TableCell>
                    {est.data_source === 'PathRabbit' &&
                    est.data_source_type === 'PATHRABBIT_WW' ? (
                      <a href={_link(est)}>
                        <Typography variant="h6" align="left">
                          <Assignment
                            style={{
                              marginRight: '0.5em',
                              marginBottom: '-0.20em',
                            }}
                          />
                          {est.name}{' '}
                          {!!est.discount_id && est.discount_id !== 1 ? (
                            <Badge
                              style={{marginLeft: '1.5em'}}
                              color="primary"
                              badgeContent="Discount"
                              variant="standard"
                            />
                          ) : (
                            ''
                          )}
                          {est.archived ? <Archive /> : <></>}
                          <Typography variant="subtitle1">
                            {est.updated_at
                              ? moment(est.updated_at).format('MMM DD, YYYY')
                              : moment(est.created_at).format('MMM DD, YYYY')}
                          </Typography>
                        </Typography>
                      </a>
                    ) : est.data_source === 'PathRabbit' &&
                      est.data_source_type === 'PATHRABBIT' ? (
                      <Link
                        to={`/${isAccount ? 'accounts' : 'customers'}/${
                          isAccount ? est.account_id : est.customer_id
                        }/estimates/${est.id}`}
                      >
                        <Typography variant="h6" align="left">
                          <Grading
                            style={{
                              marginRight: '0.5em',
                              marginBottom: '-0.20em',
                            }}
                          />
                          {est.name}{' '}
                          {!!est.discount && est.discount !== 1 ? (
                            <Badge
                              style={{marginLeft: '1.5em'}}
                              color="primary"
                              badgeContent="Discount"
                              variant="standard"
                            />
                          ) : (
                            ''
                          )}
                          {est.archived ? (
                            <Archive
                              style={{
                                marginRight: '0.5em',
                                marginBottom: '-0.20em',
                              }}
                            />
                          ) : (
                            <></>
                          )}
                          <Typography variant="subtitle1">
                            {est.updated_at
                              ? moment(est.updated_at).format('MMM DD, YYYY')
                              : moment(est.created_at).format('MMM DD, YYYY')}
                          </Typography>
                        </Typography>
                      </Link>
                    ) : (
                      <a href={_link(est)} target="_blank" rel="noreferrer">
                        <Typography variant="h6" align="left">
                          <Airtable
                            style={{
                              marginRight: '0.5em',
                              marginBottom: '-0.20em',
                            }}
                            type={est.data_source_type}
                          />
                          {est.name}{' '}
                          {est.discount === 3 ? (
                            <Badge
                              style={{marginLeft: '1.5em'}}
                              color="primary"
                              badgeContent="Discount"
                              variant="standard"
                            />
                          ) : (
                            ''
                          )}
                          <Typography variant="subtitle1">
                            {est.updated_at
                              ? moment(est.updated_at).format('MMM DD, YYYY')
                              : moment(est.created_at).format('MMM DD, YYYY')}
                          </Typography>
                        </Typography>
                      </a>
                    )}
                  </TableCell>
                  <TableCell>
                    {est.data_source_type == 'PATHRABBIT_WW'
                      ? formatMoneyStandard(est.subtotal)
                      : '--'}
                  </TableCell>
                  <TableCell style={{fontWeight: 'bold'}}>
                    {est.data_source_type != 'AIRTABLE_PW'
                      ? formatMoneyStandard(
                          est.data_source_type === 'PATHRABBIT'
                            ? estimatePrice(
                                mungeEstimateDataForCalculation(est),
                              )
                            : est.total,
                        )
                      : '--'}
                  </TableCell>
                  <TableCell style={{fontWeight: 'bold'}}>
                    {est.approved ? <Check /> : <></>}
                  </TableCell>
                  <TableCell style={styles.actionColumn}>
                    <ToolbarIconMenu>
                      {est.data_source === 'Airtable' ? (
                        <LoadingMenuItem
                          variant="contained"
                          disabled={
                            est.data_source_type === 'AIRTABLE_PW' ||
                            est.data_source_type === 'AIRTABLE_SF' ||
                            !!airDupLoading
                          }
                          onClick={() =>
                            _duplicateAirtableEstimate(
                              est.id,
                              setAirDupLoading,
                              AIRTABLE_DATA_SOURCE_TYPE_URL[
                                est.data_source_type
                              ],
                            )
                          }
                          loading={airDupLoading === est.id}
                        >
                          <FileCopy /> Duplicate
                        </LoadingMenuItem>
                      ) : est.data_source_type === 'PATHRABBIT' ? (
                        <LoadingMenuItem
                          loading={duplicateLoading}
                          variant="contained"
                          onClick={async () => {
                            await duplicatePREstimate(est.id)
                            queryClient.invalidateQueries('estimates')
                          }}
                        >
                          <FileCopy /> Duplicate
                        </LoadingMenuItem>
                      ) : (
                        <a
                          href={_duplicateEstimateLink(est)}
                          style={{marginRight: '1em'}}
                        >
                          <MenuItem
                            variant="contained"
                            disabled={est.data_source === 'Airtable'}
                          >
                            <FileCopy /> Duplicate
                          </MenuItem>
                        </a>
                      )}
                      <LoadingMenuItem
                        variant="contained"
                        loading={mutateLoading}
                        disabled={est.data_source === 'Airtable'}
                        onClick={async () => {
                          await mutateAsync({
                            id: est.id,
                            archived: !est.archived,
                          })
                          queryClient.invalidateQueries('estimates')
                        }}
                      >
                        {est.archived ? (
                          <>
                            <Restore /> Restore
                          </>
                        ) : (
                          <>
                            <Archive /> Archive
                          </>
                        )}
                      </LoadingMenuItem>
                      {est.data_source === 'Airtable' ? (
                        <LoadingMenuItem
                          variant="contained"
                          style={{color: !isDisabled(est) && 'red'}}
                          disabled={isDisabled(est)}
                          onClick={() => {
                            setDeleteAirtableModal(est.id)
                            setDeleteType(
                              AIRTABLE_DATA_SOURCE_TYPE_URL[
                                est.data_source_type
                              ],
                            )
                          }}
                        >
                          <Delete /> Delete
                        </LoadingMenuItem>
                      ) : est.data_source_type === 'PATHRABBIT' ? (
                        <LoadingMenuItem
                          onClick={() => {
                            setDeleteModal(est.id)
                          }}
                          variant="contained"
                          style={{color: 'red'}}
                        >
                          <Delete /> Delete
                        </LoadingMenuItem>
                      ) : (
                        <a href={_deleteEstimateLink(est)}>
                          <LoadingMenuItem
                            variant="contained"
                            style={{color: 'red'}}
                          >
                            <Delete /> Delete
                          </LoadingMenuItem>
                        </a>
                      )}
                    </ToolbarIconMenu>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
        <Dialog open={!!deleteModal}>
          <DialogTitle>Delete Proposal</DialogTitle>
          <DialogContent>
            Are you sure you want to delete this proposal?
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={() => setDeleteModal(false)}>
              CANCEL
            </Button>
            <DangerLoadingButton
              variant="contained"
              loading={deleteLoading}
              onClick={async () => {
                await deletePREstimate(deleteModal)
                setDeleteModal(false)
                queryClient.invalidateQueries('estimates')
              }}
            >
              <Delete />
              Delete proposal
            </DangerLoadingButton>
          </DialogActions>
        </Dialog>
        <Dialog open={!!deleteAirtableModal}>
          <DialogTitle>Delete Proposal</DialogTitle>
          <DialogContent>
            Are you sure you want to delete this proposal?
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              onClick={() => setDeleteAirtableModal(false)}
            >
              CANCEL
            </Button>
            <DangerLoadingButton
              variant="contained"
              loading={airDelLoading}
              onClick={() => {
                _deleteProposal(deleteAirtableModal, deleteType)
                setDeleteAirtableModal(false)
              }}
            >
              <Delete />
              Delete proposal
            </DangerLoadingButton>
          </DialogActions>
        </Dialog>
        <PrintDialog
          isOpen={!!printEstimate}
          id={printEstimate}
          onCancel={() => setPrintEstimate(false)}
        />
      </div>
      <ModifyQueryParams query={{...queryChanges}} state={{}} />
    </>
  )
}

export default CustomerEstimates
