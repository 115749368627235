// @flow
import styled from 'styled-components'
import {darken} from 'polished'
import Avatar from '@mui/material/Avatar'

let AvatarBorder = styled(Avatar)`
  border: 2px solid ${({theme: {muiTheme: m}}) => m.palette.grey[400]} !important;
  background-color: transparent !important;
  color: ${({theme: {muiTheme: m}}) =>
    darken(0.1, m.palette.grey[400])} !important;
`
export default AvatarBorder
