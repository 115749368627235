import React from 'react'
import gql from 'graphql-tag'
import { ListItemText, InputLabel, MenuItem } from '@mui/material'

import AutoComplete, { useAutoCompleteEndpoint } from './AutoComplete'
import Routes from '../constants/Routes'
import SelectMenu from '../components/ToolbarDropDownMenu'

let gqlFragment = gql`
  fragment Selection on Saleitems {
    id
    name
    description
    taxable
    is_billable
  }
`

function SaleItemSelect({
  onChange,
  selectedId,
  searchFilterType = 'all',
  ...props
}) {
  let autoProps = useAutoCompleteEndpoint({
    gqlFragment,
    selectedId,
    searchField: 'name',
  })

  let allSearchFilter = undefined
  switch (searchFilterType) {
    case 'all':
      allSearchFilter = e => true
      break
    case 'billable':
      allSearchFilter = e => e.edge.is_billable === true
      break
    case 'nonbillable':
      allSearchFilter = e => e.edge.is_billable === false
      break
    default:
      allSearchFilter = e => true
  }

  return (
    <AutoComplete
      {...autoProps}
      onChange={onChange}
      itemToString={u => u.name + ' - ' + u.description}
      renderItem={({ item }) => (
        <ListItemText
          id={'saleitem-' + item.id}
          primary={item.name}
          secondary={item.description}
        />
      )}
      renderNoItems={() => <ListItemText primary={'No Sale Item found'} />}
      inputVariant="filled"
      label={<InputLabel>Sale Item</InputLabel>}
      allSearchFilter={allSearchFilter}
      hideIcon
      {...props}
    />
  )
}

export default SaleItemSelect
