import React, {useState} from 'react'
import moment from 'moment'
import gql from 'graphql-tag'
import {useQuery} from '@apollo/react-hooks'
import {useMutation} from 'react-query'
import {Link} from 'react-router-dom'
import styled from 'styled-components'
import queryString from 'query-string'

import {
  Paper,
  TableBody,
  TableCell,
  Table,
  TableRow,
  Checkbox,
  IconButton,
  Button,
  Typography,
  TextField,
  DialogTitle,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Snackbar,
  TableHead,
  MenuItem,
} from '@mui/material'
import {ToolbarLeft, ToolbarRight} from '../../components/DetailToolbar'
import LinearProgress from '@mui/material/LinearProgress'
import CircularProgress from '@mui/material/CircularProgress'

import {
  Email,
  Receipt,
  Cancel,
  Error,
  Close,
  ArrowLeft,
  Textsms,
} from '@mui/icons-material'
import ArrowRight from '@mui/icons-material/Forward'

import {openFile} from '../../schema/utils'
import theme from '../../styles/theme'
import PaperToolbar, {DetailRow, ToolbarGroup} from '../../components/Toolbar'
import {Mutation} from 'react-apollo'
import {Title} from '../../components/Typography'
import {Toolbar} from '../../AppHandler'
import ToolbarMegaTitle from '../../components/ToolbarMegaTitle'
import _ from 'lodash'
import {prgql} from '../../utils/graphql'
import {DatePicker} from '@mui/lab'
import ModifyQueryParams from '../../components/ModifyQueryParams'
import ToolbarDropDownMenu from '../../components/ToolbarDropDownMenu'
import {Space} from '../../components/Layout'
import PHONETYPES from '../../constants/Phonetypes'
import {map} from 'lodash'

function EmailSentDialog({open, handleClose, message}) {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogActions></DialogActions>
      <DialogTitle>
        {message}
        <IconButton
          key="close"
          aria-label="close"
          color="inherit"
          onClick={handleClose}
          size="large"
        >
          <Close />
        </IconButton>
      </DialogTitle>
    </Dialog>
  )
}

function ConfirmDialog({onClose, open, numberOfWorkorders, onEmail, sending}) {
  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle>Send reminders</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Do you want to send Email and Text reminders for {numberOfWorkorders}{' '}
          workorders? <br />
          <br />
          Customers with invalid emails will not receive an email reminder.{' '}
          <br />
          <br />
          Customers with invalid cell numbers will not recieve a text reminder.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          variant="contained"
          onClick={onClose}
          autoFocus
        >
          <Cancel /> Cancel
        </Button>
        <div style={{position: 'relative'}}>
          <Button
            color="primary"
            variant="contained"
            onClick={onEmail}
            disabled={sending}
          >
            <Email />
            &nbsp; + &nbsp;
            <Textsms /> &nbsp; Send Email and Text Reminders
          </Button>
          {sending ? <TinyCirclularProgress color="primary" size={24} /> : null}
        </div>
      </DialogActions>
    </Dialog>
  )
}

function SelectScreen({collection, ids, onSelect, onSelectAll, checkAllState}) {
  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>Workorder</TableCell>
          <TableCell>Customer</TableCell>
          <TableCell>Type</TableCell>
          <TableCell>When</TableCell>
          <TableCell>Arrival Start</TableCell>
          <TableCell>Arrival End</TableCell>
          <TableCell>Services</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow key="selectControls">
          <TableCell>
            <Checkbox
              color="primary"
              checked={checkAllState}
              onChange={e => onSelectAll(e.target.checked, collection)}
            />
          </TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
        </TableRow>
        {[...collection].map(({edge: wo}, i) => {
          console.log(wo)
          return (
            <TableRow key={wo.id}>
              <TableCell style={{minWidth: '200px'}}>
                <Typography variant="subtitle1">
                  <Checkbox
                    color="secondary"
                    checked={ids[wo.id] === undefined ? true : ids[wo.id]}
                    onChange={e =>
                      onSelect({id: wo.id, checked: e.target.checked})
                    }
                  />{' '}
                  <Link to={`workorders/${wo.id}`}>WO {wo.id}</Link>
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle1">
                  {' '}
                  <Link to={`/customers/${wo.customer.id}`}>
                    {wo.customer.name}
                  </Link>
                </Typography>
              </TableCell>
              <TableCell>{wo.customer.customertype.type}</TableCell>
              <TableCell>
                <Link
                  to={`/schedules/daily?date=${moment
                    .utc(wo.assigned_at)
                    .format('YYYY-MM-DD')}&routes=${wo.route.id}`}
                >
                  {moment.utc(wo.assigned_at).format('YYYY-MM-DD')}, Route{' '}
                  {wo.route.name}
                </Link>
              </TableCell>
              <TableCell>
                {!!wo.arrival_start_time
                  ? moment
                      .utc(wo.arrival_start_time, 'hh:mm:ss')
                      .format('h:mm a')
                  : ''}
              </TableCell>
              <TableCell>
                {!!wo.arrival_end_time
                  ? moment.utc(wo.arrival_end_time, 'hh:mm:ss').format('h:mm a')
                  : ''}
              </TableCell>
              <TableCell>{`
                ${
                  wo.workorderitems
                    ? wo.workorderitems
                        .map(woi => `${woi.saleitem.name} ${woi.description}`)
                        .join(' | ')
                    : '[no services]'
                }
              `}</TableCell>
            </TableRow>
          )
        })}
      </TableBody>
    </Table>
  )
}

// aaaaannd a little copy/paste from [Stack Overflow](https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript) ....
function validateEmail(email) {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

function validateCell(cell) {
  return !!cell && cell.phonetype !== PHONETYPES.TEXT
}

function validateArrival(arrivalString) {
  return !!arrivalString && moment.utc(arrivalString, 'hh:mm:ss').isValid()
}

function get_email_for_workorder_group(edges) {
  let first = edges[0].edge
  let customer = first.customer
  let email =
    customer.emails && customer.emails.filter(e => e.default_email).length
      ? customer.emails.filter(e => e.default_email)[0]
      : customer.emails && customer.emails.length
      ? customer.emails[0]
      : {}
  return email
}
function get_phone_for_workorder_group(edges) {
  let first = edges[0].edge
  let customer = first.customer
  // super wrong right now but it'll do
  let cell =
    customer.phones &&
    customer.phones.filter(p => p.phonetype === PHONETYPES.TEXT).length
      ? customer.phones.filter(p => p.phonetype === 2)[0]
      : {}
  return cell
}

function ConfirmScreen({data, errorCount}) {
  // let {data, loading} = useQuery(confirmQuery, {
  //   variables: {ids: selectedIds},
  // })

  // loadingData(loading)
  errorCount(
    !data || Object.keys(data).length === 0
      ? 0
      : [].concat
          .apply(
            [],
            Object.values(data).map((edges, i) =>
              get_email_for_workorder_group(edges),
            ),
          )
          .filter(email => !validateEmail(email.email)).length +
          [].concat
            .apply(
              [],
              Object.values(data).map((edges, i) =>
                get_phone_for_workorder_group(edges),
              ),
            )
            .filter(cell => !validateCell(cell.number)).length +
          [].concat
            .apply(
              [],
              Object.values(data)
                .map((edges, i) => edges.map(e => e.edge.arrival_start_time))
                .flat(),
            )
            .filter(arrival => !validateArrival(arrival)).length +
          [].concat
            .apply(
              [],
              Object.values(data)
                .map((edges, i) => edges.map(e => e.edge.arrival_end_time))
                .flat(),
            )
            .filter(arrival => !validateArrival(arrival)).length,
  )

  return !data || Object.keys(data).length === 0 ? (
    <LinearProgress />
  ) : (
    <>
      {/* <TextField
        variant="filled"
        multiline
        label="Sample Email"
        value={'sample text'}
        style={{margin: '1em', marginLeft: '2em', width: '30em'}}
        disabled
      />
      <TextField
        variant="filled"
        multiline
        label="Sample Text"
        value={'sample text'}
        style={{margin: '1em', marginLeft: '2em', width: '30em'}}
        disabled
      /> */}
      <Table>
        <TableBody>
          {Object.values(data).map((edges, i) => {
            let first = edges[0].edge
            let email = get_email_for_workorder_group(edges)
            let cell = get_phone_for_workorder_group(edges)

            return (
              <React.Fragment key={i}>
                <TableRow>
                  <TableCell colSpan={2}>
                    <Typography variant="subtitle1">
                      <Link to={`/customers/${first.customer.id}`}>
                        {first.customer.name}
                      </Link>{' '}
                      -{' '}
                      <Typography
                        variant="overline"
                        style={
                          !validateEmail(email.email)
                            ? {
                                color: theme.palette.danger.main,
                                fontWeight: 'bold',
                              }
                            : {}
                        }
                      >
                        {!!email && validateEmail(email.email)
                          ? 'Email: ' + email.email
                          : 'Invalid email address'}
                      </Typography>
                      &nbsp;&nbsp; |&nbsp;&nbsp;
                      <Typography
                        variant="overline"
                        style={
                          !cell.number
                            ? {
                                color: theme.palette.danger.main,
                                fontWeight: 'bold',
                              }
                            : {}
                        }
                      >
                        {!!cell
                          ? 'Cell: ' + cell.number
                          : 'Invalid cell number'}
                      </Typography>
                    </Typography>{' '}
                  </TableCell>
                </TableRow>
                {edges.map(({edge}) => (
                  <TableRow id={edge.id}>
                    <TableCell>
                      Arrival:{' '}
                      <span
                        style={
                          !validateArrival(edge.arrival_start_time)
                            ? {
                                color: theme.palette.danger.main,
                                fontWeight: 'bold',
                              }
                            : {}
                        }
                      >
                        {!validateArrival(edge.arrival_start_time)
                          ? 'invalid date'
                          : edge.arrival_start_time}
                      </span>{' '}
                      -{' '}
                      <span
                        style={
                          !validateArrival(edge.arrival_end_time)
                            ? {
                                color: theme.palette.danger.main,
                                fontWeight: 'bold',
                              }
                            : {}
                        }
                      >
                        {!validateArrival(edge.arrival_end_time)
                          ? 'invalid date'
                          : edge.arrival_end_time}
                      </span>
                    </TableCell>
                    <TableCell>
                      {`
                      WO ${edge.id} 
                      -- ${
                        edge.workorderitems
                          ? edge.workorderitems
                              .map(
                                woi =>
                                  `${woi.saleitem.name} ${woi.description}`,
                              )
                              .join(' | ')
                          : '[no services]'
                      }
                    `}
                    </TableCell>
                  </TableRow>
                ))}
              </React.Fragment>
            )
          })}
        </TableBody>
      </Table>
    </>
  )
}

let TinyCirclularProgress = styled(CircularProgress)`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -12px;
  margin-left: -12px;
`

let query = gql`
  query workorders($date: String, $customertype: ID) {
    workorders: allWorkorders(
      filters: {assigned_at: $date, status: OPEN, customertype: $customertype}
      limit: 30000
    ) {
      edges {
        edge {
          id
          route {
            id
            name
          }
          assigned_at
          arrival_start_time
          arrival_end_time
          customer {
            id
            name
            phones {
              id
              number
              label
              phonetype
            }
            emails {
              id
              email
              default_email
            }
            customertype {
              id
              type
            }
          }
          workorderitems {
            id
            description
            saleitem {
              id
              name
            }
            charge
          }
        }
      }
    }
  }
`

let sendRemindersMutation = gql`
  mutation reminders($workorder_ids: [ID]!, $method: ReminderMethods!) {
    sendReminders(input: {method: $method, workorder_ids: $workorder_ids}) {
      error
      message
    }
  }
`

function mungeQuery(query) {
  let parsed = queryString.parse(query)
  function munge(prop, validation) {
    return validation.includes(prop) ? prop : validation[0]
  }
  let date =
    !!parsed.date && moment.utc(parsed.date).isValid
      ? moment.utc(parsed.date).format('YYYY-MM-DD')
      : moment
          .utc()
          .add(2, 'days')
          .format('YYYY-MM-DD')
  let customertype = munge(parsed.customertype, ['all', '1', '2', '3'])
  return {
    date,
    customertype,
  }
}

function Reminders({location}) {
  let [modifiedIds, setModifiedIds] = useState({})
  let [showConfirmScreen, setShowConfirmScreen] = useState(false)
  let [disableEmail, setDisableEmail] = useState(true)
  let [errorCount, setErrorCount] = useState(0)
  let [dialogOpen, setDialogOpen] = useState(false)
  let [sending, setSending] = useState(false)
  let [sentDialogOpen, setSentDialogOpen] = useState(false)
  // let [textField, setTextField] = useState(
  //   'Hello,\n\nAttached please find your statement dated ' +
  //     moment().format('MM-DD-YYYY') +
  //     '. If you have any questions please do not hesitate to call us at (609) 561-3353.\n\nSincerely,\nBethany Associates',
  // )

  const {date, customertype} = mungeQuery(location.search)
  let [queryChanges, setQueryChanges] = useState(
    queryString.parse(location.search),
  )

  let mungeSearch = q => (q === 'all' ? undefined : q)

  let {data, loading} = useQuery(query, {
    variables: {
      date,
      customertype: mungeSearch(customertype),
    },
  })

  let {
    mutate: sendReminders,
    isLoading: sendingReminders,
  } = useMutation(async ({variables}) =>
    prgql({query: sendRemindersMutation, variables}),
  )

  function checkOff(id, checked) {
    setModifiedIds(ids => ({
      ...ids,
      [id]: checked,
    }))
  }

  function checkAll(checked, ids) {
    if (checked) {
      setModifiedIds({})
    } else {
      setModifiedIds(
        Object.assign({}, ...[...ids].map(({edge: r}, i) => ({[r.id]: false}))),
      )
    }
  }

  function checkAllIsChecked() {
    return !Object.values(modifiedIds).includes(false)
  }

  function getSelectedIds(ids) {
    return ids
      .filter(
        item =>
          !(item.edge.id in modifiedIds && modifiedIds[item.edge.id] === false),
      )
      .map(({edge: r}, i) => {
        return r.id
      })
  }

  if (!data || !data.workorders || loading) {
    return null
  }

  let edges = data.workorders.edges

  let grouped = _.chain(edges)
    .filter(
      item =>
        !(item.edge.id in modifiedIds && modifiedIds[item.edge.id] === false),
    )
    .groupBy(e => e.edge.customer.id)
    .value()

  let count = edges && edges.length
  let exclude_count = Object.values(modifiedIds).filter(id => !id).length

  let emailValidWorkorders = Object.values(grouped)
    .filter(g => validateEmail(get_email_for_workorder_group(g).email))
    .flat()
    .map(e => e.edge.id)
  let messageValidWorkorders = Object.values(grouped)
    .filter(g => validateCell(get_phone_for_workorder_group(g).number))
    .flat()
    .map(e => e.edge.id)

  return (
    <React.Fragment>
      <ModifyQueryParams query={queryChanges} />
      <Toolbar>
        {showConfirmScreen ? (
          <>
            <ToolbarGroup first>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => setShowConfirmScreen(false)}
                style={{marginRight: '2em'}}
              >
                <Cancel />
              </Button>
            </ToolbarGroup>
            <ToolbarGroup>
              <ToolbarMegaTitle>
                {'Send notification for'} {count - exclude_count} Workorders
              </ToolbarMegaTitle>
              {errorCount ? (
                <span style={{marginLeft: '2em', color: 'red'}}>
                  {' '}
                  {errorCount} errors
                </span>
              ) : null}
            </ToolbarGroup>
            <ToolbarGroup last>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setDialogOpen(true)}
                id="sendEmail"
              >
                <Email />
                &nbsp; + &nbsp;
                <Textsms />
              </Button>
              <ConfirmDialog
                onClose={() => setDialogOpen(false)}
                open={dialogOpen}
                sending={sendingReminders}
                onEmail={async () => {
                  await Promise.allSettled([
                    sendReminders({
                      variables: {
                        workorder_ids: emailValidWorkorders,
                        method: 'EMAIL',
                      },
                    }),
                    sendReminders({
                      variables: {
                        workorder_ids: messageValidWorkorders,
                        method: 'MESSAGE',
                      },
                    }),
                  ])

                  setDialogOpen(false)
                  setShowConfirmScreen(false)
                  setSentDialogOpen(true)
                }}
                numberOfWorkorders={count - exclude_count}
              />
            </ToolbarGroup>
          </>
        ) : (
          <>
            <ToolbarGroup first={true}>
              <ToolbarMegaTitle style={{marginLeft: '1em'}}>
                Reminders
              </ToolbarMegaTitle>
            </ToolbarGroup>
            <ToolbarGroup>
              <DatePicker
                label="Date"
                value={moment(date).utc()}
                onChange={d =>
                  setQueryChanges({
                    ...queryChanges,
                    date: moment(d)
                      .utc()
                      .startOf('day')
                      .format('YYYY-MM-DD'),
                  })
                }
                renderInput={props => <TextField size="small" {...props} />}
                PopoverProps={{PaperProps: {style: {background: 'white'}}}}
              />
              <Space inline />
              <ToolbarDropDownMenu
                value={customertype}
                onChange={e =>
                  setQueryChanges({
                    ...queryChanges,
                    customertype: e.target.value,
                  })
                }
                label="Customer Type"
                id="customertype"
                name="customertype"
              >
                <MenuItem value="all">All Types &nbsp;&nbsp;&nbsp;</MenuItem>
                <MenuItem value="1">Commercial</MenuItem>
                <MenuItem value="2">Residential</MenuItem>
                <MenuItem value="3">Large Commercial</MenuItem>
              </ToolbarDropDownMenu>
            </ToolbarGroup>
            <ToolbarGroup last={true}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setShowConfirmScreen(true)}
                id="forward"
              >
                <ArrowRight />
              </Button>
            </ToolbarGroup>
          </>
        )}
        {/* </DetailRow> */}
      </Toolbar>
      {/* </Paper> */}
      <Paper>
        {showConfirmScreen ? (
          <ConfirmScreen
            data={grouped}
            selectedIds={getSelectedIds(edges)}
            loadingData={setDisableEmail}
            errorCount={setErrorCount}
            // textField={textField}
            // setTextField={setTextField}
          />
        ) : (
          <SelectScreen
            collection={edges}
            ids={modifiedIds}
            onSelect={({id, checked}) => checkOff(id, checked)}
            onSelectAll={(checked, collection) => checkAll(checked, collection)}
            checkAllState={checkAllIsChecked()}
          />
        )}
      </Paper>
      <EmailSentDialog
        open={sentDialogOpen}
        handleClose={() => setSentDialogOpen(false)}
        message={
          'Sending reminders for ' + (count - exclude_count) + ' workorders'
        }
      />
    </React.Fragment>
  )
}

export default Reminders
