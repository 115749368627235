import React, {useEffect, useState} from 'react'
import {Toolbar} from '../AppHandler'
import ToolbarGroup from '../components/ToolbarGroup'
import ToolbarMegaTitle from '../components/ToolbarMegaTitle'
import styled from 'styled-components'
import {DatePicker} from '@mui/lab'
import {
  Typography,
  LinearProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  CircularProgress,
} from '@mui/material'
import {Space} from '../components/Layout'
import {fetchAPI} from '../schema/utils'
import moment from 'moment'
import {useQuery, useQueryClient} from 'react-query'
import _ from 'lodash'

let getData = async url => {
  let data = await fetchAPI({
    url,
  })

  return JSON.parse(await data.text())
}

let ReqList = styled.ul`
  margin-left: 2em;
`

let ReqListItem = styled.li`
  list-style: disc;
`

let SummaryWrapper = styled.div`
  page-break-after: always;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
`

function Diagnostics() {
  let {data: invoices, isLoading: invoicesLoading} = useQuery(
    ['invoices'],
    () => getData('/diagnostics/invoices'),
  )
  let {data: accountpayments, isLoading: accountpaymentsLoading} = useQuery(
    ['account_payments'],
    () => getData('/diagnostics/account_payments'),
  )
  let {data: payments, isLoading: paymentsLoading} = useQuery(
    ['payments'],
    () => getData('/diagnostics/payments'),
  )
  let {data: refunds, isLoading: refundsLoading} = useQuery(['refunds'], () =>
    getData('/diagnostics/refunds'),
  )
  let {
    data: workorderlessInvoices,
    isLoading: workorderlessInvoicesLoading,
  } = useQuery(['workorderless_invoices'], () =>
    getData('/diagnostics/payments'),
  )
  let {
    data: paidInvoicesNotMarked,
    isLoading: paidInvoicesNotMarkedLoading,
  } = useQuery(['invoices_not_marked'], () =>
    getData('/diagnostics/invoices_not_marked'),
  )

  // https://docs.google.com/document/d/16w0fzA825v4zvdovr-x9HuSsahHBl45Rm3g-3Vc6MMk/edit

  return (
    <>
      <Toolbar>
        <ToolbarMegaTitle>Diagnostics</ToolbarMegaTitle>
        <Space inline />
      </Toolbar>

      <SummaryWrapper>
        <Grid container spacing={4} direction="column">
          <Grid item spacing={1}>
            <Typography variant="h6">
              invoices with mismatch payment items
            </Typography>
          </Grid>
          <Grid item>
            {invoicesLoading ? (
              <CircularProgress />
            ) : (
              <Table>
                <TableHead>
                  <TableCell>InvoiceNumber</TableCell>
                  <TableCell>InvoiceStatusID</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Account</TableCell>
                  <TableCell>ServiceDate</TableCell>
                  <TableCell>TotalCharge</TableCell>
                  <TableCell>PaymentItemAmount</TableCell>
                  <TableCell>Difference</TableCell>
                </TableHead>
                <TableBody>
                  {invoices.map(row => (
                    <TableRow key={row.InvoiceNumber}>
                      <TableCell>
                        <a
                          href={`/old/database/invoice.php?InvoiceNumber=${row.InvoiceNumber}`}
                        >
                          {row.InvoiceNumber}
                        </a>
                      </TableCell>
                      <TableCell>{row.InvoiceStatusID}</TableCell>
                      <TableCell>
                        <a href={`/customers/${row.JobID}/transactions`}>
                          {row.Name}
                        </a>
                      </TableCell>
                      <TableCell>
                        <a href={`/accounts/${row.AccountID}/transactions`}>
                          {row.AccountName}
                        </a>
                      </TableCell>
                      <TableCell>{row.ServiceDate}</TableCell>
                      <TableCell>{row.TotalCharge}</TableCell>
                      <TableCell>{row.PaymentItemAmount}</TableCell>
                      <TableCell>{row.Difference}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </Grid>
          <Grid item>
            <Typography variant="h6">
              Account Payments with mismatch payments
            </Typography>
          </Grid>
          <Grid item>
            {accountpaymentsLoading ? (
              <CircularProgress />
            ) : (
              <Table>
                <TableHead>
                  <TableCell>AccountPaymentID</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>PaymentDate</TableCell>
                  <TableCell>Amount</TableCell>
                  <TableCell>PaymentAmount</TableCell>
                  <TableCell>Difference</TableCell>
                </TableHead>
                <TableBody>
                  {accountpayments.map(row => (
                    <TableRow key={row.AccountPaymentID}>
                      <TableCell>
                        <a
                          href={`/accounts/${row.AccountID}/transactions/payments/${row.AccountPaymentID}`}
                        >
                          {row.AccountPaymentID}
                        </a>
                      </TableCell>
                      <TableCell>
                        <a href={`/accounts/${row.AccountID}/transactions`}>
                          {row.Name}
                        </a>
                      </TableCell>
                      <TableCell>{row.PaymentDate}</TableCell>
                      <TableCell>{row.Amount}</TableCell>
                      <TableCell>{row.PaymentAmount}</TableCell>
                      <TableCell>{row.Difference}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </Grid>
          <Grid item>
            <Typography variant="h6">
              Payments with mismatch paymentitems
            </Typography>
          </Grid>
          <Grid item>
            {paymentsLoading ? (
              <CircularProgress />
            ) : (
              <Table>
                <TableHead>
                  <TableCell>PaymentID</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Account</TableCell>
                  <TableCell>PaymentDate</TableCell>
                  <TableCell>Amount</TableCell>
                  <TableCell>PaymentAmount</TableCell>
                  <TableCell>Difference</TableCell>
                </TableHead>
                <TableBody>
                  {payments.map(row => (
                    <TableRow key={row.PaymentID}>
                      <TableCell>
                        <a
                          href={`/customers/${row.JobID}/transactions/payments/${row.PaymentID}`}
                        >
                          {row.PaymentID}
                        </a>
                      </TableCell>
                      <TableCell>
                        <a href={`/customers/${row.JobID}/transactions`}>
                          {row.Name}
                        </a>
                      </TableCell>
                      <TableCell>
                        <a href={`/accounts/${row.AccountID}/transactions`}>
                          {row.AccountName}
                        </a>
                      </TableCell>
                      <TableCell>{row.PaymentDate}</TableCell>
                      <TableCell>{row.Amount}</TableCell>
                      <TableCell>{row.PaymentItemAmount}</TableCell>
                      <TableCell>{row.Difference}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </Grid>
          <Grid item>
            <Typography variant="h6">
              Refunds with mismatched Paymentitems
            </Typography>
          </Grid>
          <Grid item>
            {refundsLoading ? (
              <CircularProgress />
            ) : (
              <Table>
                <TableHead>
                  <TableCell>RefundID</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Account</TableCell>
                  <TableCell>RefundDate</TableCell>
                  <TableCell>Amount</TableCell>
                  <TableCell>RefundAmount</TableCell>
                  <TableCell>Difference</TableCell>
                </TableHead>
                <TableBody>
                  {refunds.map(row => (
                    <TableRow key={row.RefundID}>
                      <TableCell>
                        <a
                          href={`/old/database/refundedit.php?JobID=${row.JobID}&RefundID=${row.RefundID}&redirect=/diagnostics`}
                        >
                          {row.RefundID}
                        </a>
                      </TableCell>
                      <TableCell>
                        <a href={`/customers/${row.JobID}/transactions`}>
                          {row.Name}
                        </a>
                      </TableCell>
                      <TableCell>
                        <a href={`/accounts/${row.AccountID}/transactions`}>
                          {row.AccountName}
                        </a>
                      </TableCell>
                      <TableCell>{row.RefundDate}</TableCell>
                      <TableCell>{row.Amount}</TableCell>
                      <TableCell>{row.PaymentItemAmount}</TableCell>
                      <TableCell>{row.Difference}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </Grid>
          <Grid item>
            <Typography variant="h6">
              Invoices with no associated Workorders
            </Typography>
          </Grid>
          <Grid item>
            {workorderlessInvoicesLoading ? (
              <CircularProgress />
            ) : (
              <Table>
                <TableHead>
                  <TableCell>InvoiceNumber</TableCell>
                  <TableCell>InvoiceStatusID</TableCell>
                  <TableCell>CustomerName</TableCell>
                  <TableCell>AccountName</TableCell>
                  <TableCell>ServiceDate</TableCell>
                  <TableCell>TotalCharge</TableCell>
                  <TableCell>NumberWorkorders</TableCell>
                  <TableCell>NumberVoidedWorkorders</TableCell>
                </TableHead>
                <TableBody>
                  {workorderlessInvoices.map(row => (
                    <TableRow key={row.InvoiceNumber}>
                      <TableCell>
                        <a
                          href={`/old/database/invoice.php?InvoiceNumber=${row.InvoiceNumber}`}
                        >
                          {row.InvoiceNumber}
                        </a>
                      </TableCell>
                      <TableCell>{row.InvoiceStatusID}</TableCell>
                      <TableCell>
                        <a href={`/customers/${row.JobID}/transactions`}>
                          {row.Name}
                        </a>
                      </TableCell>
                      <TableCell>
                        <a href={`/accounts/${row.AccountID}/transactions`}>
                          {row.AccountName}
                        </a>
                      </TableCell>
                      <TableCell>{row.ServiceDate}</TableCell>
                      <TableCell>{row.TotalCharge}</TableCell>
                      <TableCell>{row.NumberWorkorders}</TableCell>
                      <TableCell>{row.NumberVoidedWorkorders}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </Grid>
          <Grid item>
            <Typography variant="h6">
              Fully paid invoices not marked as Paid
            </Typography>
          </Grid>
          <Grid item>
            {paidInvoicesNotMarkedLoading ? (
              <CircularProgress />
            ) : (
              <Table>
                <TableHead>
                  <TableCell>InvoiceNumber</TableCell>
                  <TableCell>InvoiceStatusID</TableCell>
                  <TableCell>CustomerName</TableCell>
                  <TableCell>AccountName</TableCell>
                  <TableCell>PaymentAmount</TableCell>
                  <TableCell></TableCell>
                </TableHead>
                <TableBody>
                  {paidInvoicesNotMarked.map(row => (
                    <TableRow key={row.InvoiceNumber}>
                      <TableCell>
                        <a
                          href={`/old/database/invoice.php?InvoiceNumber=${row.InvoiceNumber}`}
                        >
                          {row.InvoiceNumber}
                        </a>
                      </TableCell>
                      <TableCell>{row.InvoiceStatusID}</TableCell>
                      <TableCell>
                        <a href={`/customers/${row.JobID}/transactions`}>
                          {row.Name}
                        </a>
                      </TableCell>
                      <TableCell>
                        <a href={`/accounts/${row.AccountID}/transactions`}>
                          {row.AccountName}
                        </a>
                      </TableCell>
                      <TableCell>{row.PaymentAmount}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </Grid>
        </Grid>
      </SummaryWrapper>
    </>
  )
}

export default Diagnostics
