import React, { useState, useRef } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import gql from 'graphql-tag'
import { useQuery, useMutation } from '@apollo/react-hooks'
import moment from 'moment'
import queryString from 'query-string'
import { Formik, Field } from 'formik'
import * as Yup from 'yup'
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  InfoWindow,
} from '@react-google-maps/api'

import {
  Typography,
  Input,
  InputAdornment,
  Grid,
  MenuItem,
  Chip,
  TextField as MuiTextField,
  Select,
  OutlinedInput,
  FormControl,
  InputLabel,
} from '@mui/material'
import {
  CalendarToday as CalendarIcon,
  Event as RepeatOneIcon,
  Loop as RepeatIcon,
} from '@mui/icons-material'
import { DatePicker as MuiDatePicker } from '@mui/lab'

import { Toolbar } from '../../AppHandler'
import ToolbarGroup from '../../components/ToolbarGroup'
import { Space } from '../../components/Layout'
import { RouteSelectStatic as RouteSelect } from '../../components/RouteSelect'
import RouteSelectDynamic from '../../components/RouteSelect'
import ModifyQueryParams from '../../components/ModifyQueryParams'
import { TextField, AutoCompleteField, DatePicker } from '../../components/forms'
import { formatMoneyStandard } from '../../utils/moneyFormatter'
import Routes from '../../constants/Routes'

let ChipRoute = styled.div`
  background-color: ${({ theme }) => theme.palette.secondary.main};
  color: ${({ theme }) => theme.palette.primary.contrastText};
  border-radius: 8px;
  padding: 0em;
  text-align: center;
  margin-right: 0.1em;
  min-width: 30px;
`

let RouteSelectMulti = ({ routes, selectedRoutes, onSelect }) => {
  let children = Routes.map(r => (
    <MenuItem value={r.id} key={r.id}>
      {r.id}
    </MenuItem>
  ))

  return (
    <FormControl variant="outlined" size="small" margin="dense">
      <InputLabel variant="outlined" size="small" margin="dense">
        Routes
      </InputLabel>
      <Select
        label={'Routes'}
        name="routes"
        value={selectedRoutes}
        multiple
        input={<OutlinedInput size="small" margin="dense" />}
        onChange={onSelect}
        renderValue={selected => {
          return (
            <div style={{ display: 'flex' }}>
              {selected
                .sort((a, b) => a - b)
                .map(v => {
                  let r = routes.find(
                    r => Number.parseInt(r.id) === Number.parseInt(v),
                  )
                  return r && <ChipRoute key={v}>{r.id}</ChipRoute>
                })}
            </div>
          )
        }}
        style={{ minWidth: '100px' }}
        children={children}
      />
    </FormControl>
  )
}

function RouteMap({ markers = [], selected, onSelect }) {
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: 'AIzaSyAUqKr6nXvuRt93mP6VWjo_V0k2_V_yduc',
  })
  let [infoWindow, setInfoWindow] = useState(null)
  let markersRef = useRef({})

  let onLoad = React.useCallback(
    map => {
      let latlng = markers
        // .filter(m => m.position.lat !== 0 && m.position.lng !== 0)
        .map(m => new window.google.maps.LatLng(m.position.lat, m.position.lng))
      let latlngBounds = new window.google.maps.LatLngBounds()
      latlng.forEach(ll => latlngBounds.extend(ll))

      map.fitBounds(latlngBounds)
    },
    [markers],
  )

  let renderMap = () => {
    return (
      <GoogleMap
        zoom={8}
        mapContainerStyle={{ width: '100%', height: '800px', flex: 1 }}
        onLoad={onLoad}
      >
        {infoWindow && infoWindow.id !== selected && (
          <InfoWindow anchor={infoWindow.anchor}>
            {infoWindow.infoContent}
          </InfoWindow>
        )}
        {markers.map(({ id, infoContent, infoContentPreview, ...m }) => {
          let mRef = markersRef.current[id]
          return (
            <>
              <Marker
                key={id}
                {...m}
                onLoad={m => {
                  markersRef.current[id] = m
                }}
                onClick={() => onSelect({ id })}
                onMouseOver={e => {
                  let currentMarker =
                    markersRef.current && markersRef.current[id]
                  if (currentMarker && id !== selected) {
                    setInfoWindow({
                      id,
                      anchor: currentMarker,
                      infoContent: infoContentPreview,
                    })
                  }
                }}
                onMouseOut={e => {
                  setInfoWindow(null)
                }}
              />
              {id === selected && mRef && (
                <InfoWindow
                  anchor={mRef}
                  onCloseClick={() => onSelect({ id: null })}
                >
                  {infoContent}
                </InfoWindow>
              )}
            </>
          )
        })}
      </GoogleMap>
    )
  }

  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>
  }

  return isLoaded ? renderMap() : <div>Loading...</div>
}

function DateInput({ onClick, value }) {
  return (
    <Input
      onClick={onClick}
      value={value}
      startAdornment={
        <InputAdornment position="start">
          <CalendarIcon />
        </InputAdornment>
      }
    />
  )
}

let scheduleSchema = Yup.object().shape({
  route_id: Yup.string(),
})

function EditScheduleDetails({
  schedule,
  defaultAssignedAt = moment().add(1, 'd'),
  children,
  ...props
}) {
  return (
    <Formik
      initialValues={schedule || { assigned_at: null }}
      validationSchema={scheduleSchema}
      {...props}
      children={({
        isSubmitting,
        isValid,
        values,
        setFieldValue,
        submitForm,
      }) => {
        let form = (
          <>
            <Grid container spacing={2}>
              <Grid item sm={12} spacing={2} container>
                <Grid item>
                  <Chip
                    label="Every 4 Weeks"
                    icon={<RepeatIcon />}
                    onClick={() => {
                      setFieldValue('assigned_at', null)
                      setFieldValue('week', 1)
                      setFieldValue('day', 'Monday')
                    }}
                    color={!values.assigned_at ? 'primary' : undefined}
                  />
                </Grid>
                <Grid item>
                  <Chip
                    label="One Time"
                    icon={<RepeatOneIcon />}
                    onClick={() => {
                      setFieldValue(
                        'assigned_at',
                        moment(defaultAssignedAt).format('YYYY-MM-DD'),
                      )
                      setFieldValue('week', null)
                      setFieldValue('day', null)
                    }}
                    color={values.assigned_at ? 'primary' : undefined}
                  />
                </Grid>
              </Grid>

              <Grid item sm={2}>
                <AutoCompleteField
                  as={RouteSelectDynamic}
                  name="route_id"
                  selectedId={values.route_id}
                  fullWidth
                />
              </Grid>
              {values.assigned_at ? (
                <>
                  <Grid item sm={4}>
                    <DatePicker
                      name="assigned_at"
                      inputVariant="filled"
                      label="Date"
                      format="dddd, MMMM D, YYYY"
                      fullWidth
                    />
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item sm={3}>
                    <TextField
                      name="week"
                      variant="filled"
                      label="Week"
                      fullWidth
                      select
                    >
                      <MenuItem>None</MenuItem>
                      <MenuItem value={1}>1</MenuItem>
                      <MenuItem value={2}>2</MenuItem>
                      <MenuItem value={3}>3</MenuItem>
                      <MenuItem value={4}>4</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item sm={3}>
                    <TextField
                      name="day"
                      variant="filled"
                      label="Day"
                      fullWidth
                      select
                    >
                      <MenuItem>None</MenuItem>
                      <MenuItem value="Sunday">Sunday</MenuItem>
                      <MenuItem value="Monday">Monday</MenuItem>
                      <MenuItem value="Tuesday">Tuesday</MenuItem>
                      <MenuItem value="Wednesday">Wednesday</MenuItem>
                      <MenuItem value="Thursday">Thursday</MenuItem>
                      <MenuItem value="Friday">Friday</MenuItem>
                      <MenuItem value="Saturday">Saturday</MenuItem>
                    </TextField>
                  </Grid>
                </>
              )}
            </Grid>
          </>
        )
        if (typeof children === 'function') {
          return children({ submitForm, isSubmitting, form, isValid })
        } else {
          return form
        }
      }}
    />
  )
}

let schedulesQuery = gql`
  query routeRunSchedulesMove($date: String, $routes: [ID]) {
    allRouteRuns(filters: {from: $date, to: $date, routes: $routes}) {
      edges {
        edge {
          id
          run_at
          schedules {
            id
            services {
              id
              saleitem_id
              saleitem {
                id
                name
                description
              }
              charge
              man_minutes
              description
            }
            charge
            man_minutes
            route_id
            start_at
            end_at
            freq_type
            freq_interval
            freq_relative_interval
            freq_recurrence_factor
            day
            week
            customer {
              id
              name
              city {
                id
                name
              }
              lat
              lng
              geo_location {
                id
                lat
                lng
              }
            }
          }
          workorders {
            id
            assigned_at
            sequence
            completed_at
            status
            route_id
            customer_id
            notes
            customer {
              id
              name
              city {
                id
                name
              }
              lat
              lng
              geo_location {
                id
                lat
                lng
              }
            }
            invoice_id
            workorderitems {
              id
              saleitem_id
              service_id
              description
              man_minutes
              notes
              charge
              sales_person_id
            }
            schedule {
              id
              charge
              route_id
              start_at
              end_at
              freq_type
              freq_interval
              freq_relative_interval
              freq_recurrence_factor
              day
              week
            }
          }
        }
      }
    }
  }
`
let moveSchedulesMut = gql`
  mutation moveSchedules($input: [UpdateManySchedulesInput]) {
    updateManySchedules(input: $input) {
      schedules {
        id
        service
      }
    }
  }
`

function Screen({ location }) {
  let {
    routes = '1',
    date = moment.utc().format('YYYY-MM-DD'),
  } = queryString.parse(location.search)

  let [queryChanges, setQueryChanges] = React.useState({})
  let [openDialog, setOpenDialog] = React.useState(false)
  let [selectedMarker, setSelectedMarker] = React.useState(null)

  let routeIds = routes.split(',')

  let { data, loading, error, refetch } = useQuery(schedulesQuery, {
    variables: {
      date,
      routes: routeIds,
    },
    fetchPolicy: 'cache-and-network',
  })
  let [moveSchedules] = useMutation(moveSchedulesMut)

  let schedules =
    (data &&
      data.allRouteRuns &&
      data.allRouteRuns.edges[0] &&
      data.allRouteRuns.edges.map(e => e.edge.schedules).flat()) ||
    []
  let workorders =
    (data &&
      data.allRouteRuns &&
      data.allRouteRuns.edges[0] &&
      data.allRouteRuns.edges.map(e => e.edge.workorders).flat()) ||
    []
  let generatedScheduleIds = workorders
    .filter(i => !['VOID'].includes(i.status))
    .map(wo => (wo.schedule ? wo.schedule.id : '0'))
    .flat()
  let ungeneratedSchedules = schedules.filter(
    s => !generatedScheduleIds.includes(s.id),
  )
  let items = []
  items = items
    .concat(workorders, ungeneratedSchedules)
    .sort((a, b) => a.sequence - b.sequence)

  let [selected, setSelected] = React.useState({})
  let checked = Object.values(selected).filter(s => s)
  let allChecked = checked.length === schedules.length
  let someChecked = !!checked.length

  // Reset selected when page changes
  React.useEffect(() => {
    setSelected({})
  }, [routes, date])

  function handleCheck(id) {
    setSelected(s => ({ ...s, [id]: !s[id] }))
  }
  function handleCheckAll() {
    if (allChecked) {
      setSelected({})
    } else {
      setSelected(schedules.reduce((acc, { id }) => ({ ...acc, [id]: true }), {}))
    }
  }

  function handleSave(s) {
    console.log('saving schedules', s)
    return moveSchedules({
      variables: {
        input: Object.keys(selected).map(id => ({
          id,
          ...s,
        })),
      },
    }).then(() => {
      setOpenDialog(false)
      setSelected({})
      refetch()
    })
  }

  return (
    <>
      <Toolbar>
        <ModifyQueryParams query={{ ...queryChanges }} />
        <ToolbarGroup first>
          <Typography variant="h6">Schedule Map</Typography>
          <Space inline size={2} />
          <Space inline />
        </ToolbarGroup>
        <ToolbarGroup last>
          <MuiDatePicker
            label="As of"
            value={moment.utc(date)}
            inputFormat="ddd. MMMM D, YYYY"
            onChange={d =>
              setQueryChanges(c => ({ ...c, date: d.format('YYYY-MM-DD') }))
            }
            renderInput={props => (
              <MuiTextField {...props} margin="dense" size="small" />
            )}
            autoOk
          />
          <Space inline />
          <RouteSelectMulti
            selectedRoutes={routeIds
              .filter(r => r !== '')
              .map(r => Number.parseInt(r))}
            routes={Routes}
            onSelect={e =>
              setQueryChanges(c => ({
                ...c,
                routes: e.target.value.length ? e.target.value.join(',') : '',
              }))
            }
          />
        </ToolbarGroup>
      </Toolbar>

      {loading ? (
        <div>Loading...</div>
      ) : error ? (
        <div>Error... {error.message}</div>
      ) : (
        <RouteMap
          markers={items.map((s, i) => {
            let description =
              s.__typename === 'Schedules'
                ? s.services.map(s => s.description).join(', ')
                : s.workorderitems.map(s => s.description).join(', ')
            let charge =
              s.__typename === 'Schedules'
                ? s.charge
                : s.workorderitems.reduce((carry, woi) => carry + woi.charge, 0)
            let man_minutes =
              s.__typename === 'Schedules'
                ? s.man_minutes
                : s.workorderitems.reduce(
                  (carry, woi) => carry + woi.man_minutes,
                  0,
                )

            let content = (
              <>
                <Typography variant="h6">
                  <Link to={`/customers/${s.customer.id}`}>
                    {s.customer.name}
                  </Link>
                </Typography>
                {routeIds.length && (
                  <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                    Route {s.route_id}
                  </Typography>
                )}
                <Typography variant="body1">{description}</Typography>
                <Typography variant="body1">
                  {formatMoneyStandard(charge)} | {man_minutes} man minutes
                </Typography>
                <Typography variant="body1">{getScheduleText(s)}</Typography>
              </>
            )
            return {
              position: {
                lat: s.customer.geo_location.lat,
                lng: s.customer.geo_location.lng,
              },
              label: (i + 1).toString(),
              title: s.customer.name,
              id: s.id,
              infoContentPreview: <div>{content}</div>,
              infoContent: <div>{content}</div>,
            }
          })}
          selected={selectedMarker}
          onSelect={({ id }) => {
            setSelectedMarker(id)
            if (!id) {
              setSelected({})
            } else {
              handleCheck(id)
            }
          }}
        />
      )}
    </>
  )
}

const DAYS_OF_WEEK = {
  1: 'Sunday',
  2: 'Monday',
  3: 'Tuesday',
  4: 'Wednesday',
  5: 'Thursday',
  6: 'Friday',
  7: 'Saturday',
}

const ORDINALS = {
  1: 'First',
  2: 'Second',
  3: 'Third',
  4: 'Fourth',
  5: 'Last',
}

function getScheduleText(schedule) {
  if (schedule.freq_type === 1)
    return `One Time on ${moment.utc(schedule.start_at).format('M/D/YY')}`

  let text = ''
  switch (schedule.freq_type) {
    case 0:
      text = `(4Wk) ${DAYS_OF_WEEK[schedule.day]}, Week ${schedule.week}`
      break
    case 2:
      text = `Every ${schedule.freq_interval} days`
      break
    case 3:
      text = `Every ${schedule.freq_recurrence_factor} weeks on ${DAYS_OF_WEEK[schedule.freq_interval]
        }`
      break
    case 4:
      text = `Every ${schedule.freq_recurrence_factor} months on day ${schedule.freq_interval}`
      break
    case 5:
      text = `Every ${schedule.freq_recurrence_factor} months on the ${ORDINALS[schedule.freq_relative_interval]
        } ${DAYS_OF_WEEK[schedule.freq_interval]}`
      break
    default:
      if (schedule.__typename === 'Workorders') {
        let s = schedule.schedule
        if (!s) text = 'Workorder'
        else text = getScheduleText(s)
      } else {
        text = 'Every so often'
      }
      break
  }

  if (schedule.end_at && schedule.start_at)
    return (
      text +
      ` from ${moment.utc(schedule.start_at).format('M/D/YY')} to ${moment
        .utc(schedule.end_at)
        .format('M/D/YY')}`
    )

  if (schedule.end_at)
    return text + ` ending on ${moment.utc(schedule.end_at).format('M/D/YY')}`

  if (schedule.start_at)
    return (
      text + ` starting on ${moment.utc(schedule.start_at).format('M/D/YY')}`
    )

  return text
}

export default Screen
