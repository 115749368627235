import React from 'react'
import styled from 'styled-components'
import {List, ListItemButton, ListItemText, Paper} from '@mui/material'

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  padding: 1em;
`

const Column = styled(Paper)`
  width: 45%;
  padding: 1em;
`

const Dashboard = () => {
  const reportLinks = {
    Statistics: [
      {
        title: 'Weekly Profit',
        href: '/old/database/statistics_weekly_profit.php',
        description: 'Used to calculate finance report',
      },
      {
        title: 'Monthly Sales by Year',
        href: '/old/database/statistics_monthly_sales.php',
        description: 'Current & projected workorders by month',
      },
      {
        title: 'Monthly Invoicing by Year',
        href: '/old/database/statistics_monthly_sales_invoices.php',
        description: 'Current & projected invoices (subtotal) by month',
      },
      {
        title: 'Sale Items by Customer Type',
        href: '/old/database/statistics_sales_by_item_customer_type.php',
        description: 'Current and projected invoices grouped by month',
      },
      {
        title: 'Tracked Sales',
        href: '/reports/trackedsales',
        description: 'Sales recorded at time of sale',
      },
      {
        title: 'Route Statistics',
        href: '/statistics/route',
        description: 'Detailed breakdown of work per day',
      },
      {
        title: 'Weekly Statistics',
        href: '/statistics/week',
        description: 'Route Statistics, grouped by week',
      },
      {
        title: 'Yearly Statistics',
        href: '/statistics/year',
        description: 'Route Statistics, grouped by year',
      },
      {
        title: 'Weekly Sales Statistics',
        href: '/statistics/weeklysales',
        description: 'Weekly Sales, invoiced and projected',
      },
      {
        title: "Scheduler's Report",
        href: '/reports/schedulerreport',
        description:
          'Income per routerun per man, averaged over a week. Should match Weekly Schedule',
      },
      {
        title: 'Lost Jobs',
        href: '/reports/lostjobs',
        description:
          'Annualized representation of customers ended (uses schedules to calculate)',
      },
    ],
    Reports: [
      {
        title: 'Feedbacks By Type',
        href: '/reports/feedbacksbytype',
        description: 'Feedbacks created over given time',
      },
      {
        title: 'Evaluations by User',
        href: '/reports/evaluationsbyuser',
        description: 'Evaluations created over given time',
      },
      {
        title: 'Production in Tax Authority',
        href: '/reports/productionintaxauthority',
        description:
          'Employee performance grouped by employee and tax authority',
      },
      {
        title: 'Sales Commissions',
        href: '/statistics/commissions',
        description: 'Tracks sales commissions earned over given time',
      },
      {
        title: 'Estimates',
        href: '/reports/estimates',
        description: 'Simple list of recently created estimates',
      },
      {
        title: 'Tips',
        href: '/statistics/tips',
        description: 'Tracks tips earned over given time',
      },
      {
        title: 'Invoicing Geography',
        href: '/reports/heatmap',
        description: 'Map of invoices grouped by city',
      },
      {
        title: 'BETA User Activity',
        href: '/reports/useractivity',
        description: 'Tracks user activity over given time',
      },
    ],
    // "BEING DELETED SOON": [
    //   { title: 'Employee Performance', href: '/old/database/statistics_employee_performance.php', description: 'Tracks employee performance over given time' },
    //   { title: 'Sale Items', href: '/old/database/statistics_sales_by_item.php', description: 'Current and projected invoices by saleitem (horrible performance, needs fixing)' },
    //   { title: 'Acquired Statistics', href: '/old/database/statistics_acquired.php', description: 'New customers gained over given time' },
    //   { title: 'Sales by Customer Type', href: '/old/database/statistics_sales_by_type.php', description: 'Current and projected invoices grouped by customer type' },
    //   { title: 'Weekly Route Stats', href: '/old/database/statistics_weekly.php', description: 'Total Invoices per week with averages' },
    //   { title: 'Manpower Utilization', href: '/old/database/statistics_manpower_utilization.php', description: 'Summary of man day to invoice dollars' },
    // ]
  }

  return (
    <Container>
      {Object.entries(reportLinks).map(([type, links]) => (
        <Column
          key={type}
          style={{
            backgroundColor: type === 'BEING DELETED SOON' ? 'red' : undefined,
          }}
        >
          <List>
            <ListItemText primary={type} />
            {links.map((link, index) => (
              <ListItemButton
                key={index}
                component="a"
                href={link.href}
                target="_blank"
                rel="noopener noreferrer"
                disabled={link.disable}
              >
                <ListItemText
                  primary={link.title}
                  secondary={link.description}
                />
              </ListItemButton>
            ))}
          </List>
        </Column>
      ))}
    </Container>
  )
}

export default Dashboard
