import React, { useEffect, useState } from 'react'
import { Toolbar } from '../../AppHandler'
import ToolbarGroup from '../../components/ToolbarGroup'
import ToolbarMegaTitle from '../../components/ToolbarMegaTitle'
import styled from 'styled-components'
import { DatePicker } from '@mui/lab'
import SelectMenu from '../../components/ToolbarDropDownMenu'
import {
  Typography,
  LinearProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  MenuItem,
  CircularProgress,
  InputLabel,
  TextField,
  IconButton,
  Collapse,
  Box,
  Select,
} from '@mui/material'
import { Space } from '../../components/Layout'
import { fetchAPI } from '../../schema/utils'
import moment from 'moment'
import { useQuery, useQueryClient } from 'react-query'
import _, { round } from 'lodash'
import { ArrowForward, ArrowRight, Close, KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import queryString from 'query-string'
import ModifyQueryParams from '../../components/ModifyQueryParams'
import { formatMoneyStandard } from '../../utils/moneyFormatter'
import { TaxitemSelect } from '../../components/TaxitemsSelect'
import { VictoryArea, VictoryAxis, VictoryBar, VictoryCandlestick, VictoryChart, VictoryLabel, VictoryLine, VictoryTheme, VictoryTooltip, VictoryVoronoiContainer } from 'victory'
import theme from '../../styles/theme'
import { gql, prgql } from '../../utils/graphql'

let SummaryWrapper = styled.div`
  page-break-after: always;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
`

let TableWrapper = styled.div`
  margin-top: 2em;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  min-width: 800px;
`

let ChartWrapper = styled.div`
  margin-top: 2em;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  min-width: 800px;
`

let getHistory = async ({ from, to }) => {
  const data = await fetchAPI({
    url: '/reports/user_activity',
    query: {
      from: from.format('YYYY-MM-DD'),
      to: to.format('YYYY-MM-DD'),
    },
  })
  return await data.json()
}

let munge = ({ from, to }) => {
  from = !from ? moment().subtract(4, 'weeks') : moment(from)
  to = !to ? moment() : moment(to)
  return { from, to }
}

const UserActivity = () => {
  let [queryChanges, setQueryChanges] = useState(
    queryString.parse(location.search),
  )

  let params = queryString.parse(location.search)
  let { from, to, } = munge(params)

  // just gonna hard code the routes for now
  const { isLoading: dataLoading, error: dataError, data: data } = useQuery(
    ['user_activity', from.format('YYYY-MM-DD'), to.format('YYYY-MM-DD')],
    () => getHistory({ to, from }),
  )

  let xField = 'time_interval'
  let yField = 'user_count'

  return (
    <>
      <ModifyQueryParams query={queryChanges} />
      <Toolbar>
        <ToolbarMegaTitle>Activity report </ToolbarMegaTitle>
        <Space inline size={2} />
        <ToolbarGroup last>
          <DatePicker
            value={from}
            onChange={d =>
              setQueryChanges({ ...queryChanges, from: d.format('YYYY-MM-DD') })
            }
            renderInput={props => <TextField {...props} size="small" />}
          />
          <ArrowRight />
          <DatePicker
            value={to}
            onChange={d =>
              setQueryChanges({ ...queryChanges, to: d.format('YYYY-MM-DD') })
            }
            renderInput={props => <TextField {...props} size="small" />}
          />
        </ToolbarGroup>
      </Toolbar>

      <SummaryWrapper>
        <ChartWrapper>
          {dataLoading ? (
            <p>Loading...</p>
          ) : dataError ? (
            <p>Error loading data</p>
          ) : (
            <>
              {/* {data.meta.yLabels.map((x, i) => ( */}
              {/*   <Typography variant="h6" key={i}>{x}</Typography> */}
              {/**/}
              {/* ))} */}
              <VictoryChart
                height={400}
                width={1000}
                minDomain={{ y: 0 }}
                theme={VictoryTheme.material}
                domainPadding={{ x: 2, y: 10 }}
                scale={{ y: 'linear', x: 'linear' }}
                containerComponent={
                  <VictoryVoronoiContainer
                    labels={({ datum }) => `${datum.user_count} users: 
                            ${datum.users.replace(/,/g, '\n')}
                            ${moment.unix(datum.time_interval).format('MM/DD hh:mm A')}
                            `}
                    labelComponent={<VictoryTooltip cornerRadius={0} dy={-5} flyoutStyle={{ fill: "white" }} />}
                  />
                }
              >
                <VictoryAxis
                  tickFormat={(x) => `${moment.unix(x).format('MM/DD hh:mm A')}`}
                  // tickFormat={(x) => x}
                  // tickValues={data.meta.yLabels}
                  tickCount={5}
                />
                <VictoryAxis
                  dependentAxis
                  tickFormat={x => `${x}`}
                  tickValues={data.meta.xLabels}
                />
                <VictoryArea
                  data={data.data}
                  x={xField}
                  y={yField}
                />
              </VictoryChart>
            </>
          )}
        </ChartWrapper>
      </SummaryWrapper >
    </>
  )
}

export default UserActivity
