import React, {useEffect, useState} from 'react'
import {Toolbar} from '../../AppHandler'
import ToolbarGroup from '../../components/ToolbarGroup'
import ToolbarMegaTitle from '../../components/ToolbarMegaTitle'
import styled from 'styled-components'
import {DatePicker} from '@mui/lab'
import SelectMenu from '../../components/ToolbarDropDownMenu'
import {
  Typography,
  LinearProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  MenuItem,
  CircularProgress,
  InputLabel,
  TextField,
} from '@mui/material'
import {Space} from '../../components/Layout'
import {fetchAPI} from '../../schema/utils'
import moment from 'moment'
import {useQuery, useQueryClient} from 'react-query'
import _ from 'lodash'
import {ArrowForward} from '@mui/icons-material'
import queryString from 'query-string'
import ModifyQueryParams from '../../components/ModifyQueryParams'
import {formatMoneyStandard} from '../../utils/moneyFormatter'
import {TaxitemSelect} from '../../components/TaxitemsSelect'

let SummaryWrapper = styled.div`
  page-break-after: always;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
`

let TableWrapper = styled.div`
  margin-top: 2em;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  min-width: 800px;
`

let getData = async ({from, to, taxitem_id}) => {
  const data = await fetchAPI({
    url: '/reports/production_in_tax_authority',
    query: {
      from: from.format('YYYY-MM-DD'),
      to: to.format('YYYY-MM-DD'),
      taxitem_id,
    },
  })
  return await data.json()
}

let munge = ({from, to, taxitem_id}) => {
  from = !from ? moment().subtract(1, 'week') : moment(from)
  to = !to ? moment() : moment(to)
  taxitem_id = !taxitem_id ? undefined : taxitem_id
  return {from, to, taxitem_id}
}

const ProductionInTaxAuthorityReport = () => {
  let [queryChanges, setQueryChanges] = useState(
    queryString.parse(location.search),
  )

  let params = queryString.parse(location.search)
  let {from, to, taxitem_id} = munge(params)

  const fromKey = from.format('YYYY-MM-DD')
  const toKey = to.format('YYYY-MM-DD')

  const {isLoading, error, data: employees} = useQuery(
    ['evaluations', fromKey, toKey, taxitem_id],
    () => getData({from, to, taxitem_id}),
  )

  return (
    <>
      <ModifyQueryParams query={queryChanges} />
      <Toolbar>
        <ToolbarMegaTitle>Production in Tax Authority </ToolbarMegaTitle>
        <Space inline size={2} />
        <ToolbarGroup last>
          <DatePicker
            value={from}
            onChange={d =>
              setQueryChanges({...queryChanges, from: d.format('YYYY-MM-DD')})
            }
            renderInput={props => <TextField {...props} size="small" />}
          />
          <Space inline size={2} />
          <ArrowForward />
          <Space inline size={2} />
          <DatePicker
            value={to}
            onChange={d =>
              setQueryChanges({...queryChanges, to: d.format('YYYY-MM-DD')})
            }
            renderInput={props => <TextField {...props} size="small" />}
          />
          <Space inline size={3} />
          <TaxitemSelect
            selectedId={taxitem_id}
            onChange={s =>
              setQueryChanges({...queryChanges, taxitem_id: s ? s.id : ''})
            }
            inputVariant="outlined"
            inputMargin="dense"
            fullWidth={false}
            label={<InputLabel>Taxitem</InputLabel>}
          />
        </ToolbarGroup>
      </Toolbar>

      {isLoading && <LinearProgress />}

      {error && <p>Error: {error.message}</p>}

      <SummaryWrapper>
        {employees &&
          Object.values(employees).map(({name, data}) => (
            <TableWrapper key={name}>
              <Typography variant="h6">{name}</Typography>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell style={{width: '50%', minWidth: '200px'}}>
                      Tax Authority
                    </TableCell>
                    <TableCell>Amount</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.entries(data).map(([taxAuthority, amount]) => (
                    <TableRow key={`${name}-${taxAuthority}`}>
                      <TableCell>{taxAuthority}</TableCell>
                      <TableCell>{formatMoneyStandard(amount)}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableWrapper>
          ))}
      </SummaryWrapper>
    </>
  )
}

export default ProductionInTaxAuthorityReport
