import React, { useState, useEffect } from 'react'
import gql from 'graphql-tag'
import { Column } from 'react-virtualized'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import moment from 'moment'
import queryString from 'query-string'

import { DatePicker } from '@mui/lab'
import Button from '@mui/material/Button'
import Input from '@mui/material/Input'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import LoadingButton from '../../components/LoadingButton'

import CalendarIcon from '@mui/icons-material/CalendarToday'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TableHead,
  TableCell,
  Table,
  TableBody,
  TableRow,
  FormControlLabel,
  Checkbox,
  Typography,
  Switch,
} from '@mui/material'

import TableList from '../../components/Table/TableList'
import { Body1, Subhead } from '../../components/Typography'
import AvatarBorder from '../../components/AvatarBorder'
import { usePagination } from '../../components/PaginationContainer'
import { Toolbar } from '../../AppHandler'
import ToolbarGroup from '../../components/ToolbarGroup'
import ToolbarMegaTitle from '../../components/ToolbarMegaTitle'
import ModifyQueryParams from '../../components/ModifyQueryParams'
import { Space } from '../../components/Layout'
import { formatMoneyStandard } from '../../utils/moneyFormatter'
import { useMutation } from 'react-apollo'

function DateInput({ onClick, value }) {
  return (
    <Input
      onClick={onClick}
      value={value}
      startAdornment={
        <InputAdornment position="start">
          <CalendarIcon />
        </InputAdornment>
      }
    />
  )
}

let UpdateInvoicesQuery = gql`
  mutation payCommissionByUser($invoices: [UpdateManyInvoiceitemsInput]) {
    updateManyInvoiceitems(input: $invoices) {
      invoiceitems {
        id
        commission_complete_at
        commission_status
      }
    }
  }
`

function ScreenToolbar({
  to,
  onChange = () => { },
  invoices,
  onPaid,
  users,
  showPast,
  onlyToday,
}) {
  let [dialogOpen, setDialogOpen] = useState(false)
  let [markPaid, { loading: markPaidLoading }] = useMutation(
    UpdateInvoicesQuery,
    {
      onCompleted: () => {
        setDialogOpen(false)
        onPaid()
      },
    },
  )

  let mutationData =
    invoices &&
    invoices
      .map(e => e.edge.invoiceitems)
      .flat(1)
      .map(i => ({
        id: i.id,
        commission_status: 'PAID',
        commission_complete_at: moment.utc().format('YYYY-MM-DD'),
      }))

  console.log(to)
  return (
    <Toolbar>
      <ToolbarGroup first>
        <ToolbarMegaTitle>
          {!showPast && 'Unpaid'} Commissions by User
        </ToolbarMegaTitle>
        <Space inline size={4} />
        <FormControlLabel
          label={
            onlyToday
              ? 'Only show commissions made available on '
              : 'Show all available commissions up to '
          }
          control={
            <Switch
              color="secondary"
              checked={onlyToday}
              onChange={e =>
                onChange({ onlyToday: e.target.checked ? 'true' : 'false' })
              }
              name="onlytoday"
            />
          }
        />
        <DatePicker
          label="As of"
          onChange={d => onChange({ to: d.format('YYYY-MM-DD') })}
          inputFormat="MMMM D, YYYY"
          value={moment.utc(to)}
          renderInput={props => (
            <TextField
              {...props}
              variant="outlined"
              margin="dense"
              size="small"
            />
          )}
          autoOk
          name="asof"
        />
        <Space inline size={4} />
        <FormControlLabel
          label="Show Recorded Commissions"
          control={
            <Checkbox
              checked={showPast}
              onChange={e =>
                onChange({ showPast: e.target.checked ? 'true' : 'false' })
              }
              name="showpast"
            />
          }
        />
      </ToolbarGroup>
      <ToolbarGroup></ToolbarGroup>
      <ToolbarGroup last>
        {!showPast && (
          <Button onClick={() => setDialogOpen(true)}>
            {!users || !users.length ? 'Mark All Paid' : 'Mark Selected Paid'}
          </Button>
        )}
        <Dialog open={dialogOpen}>
          <DialogTitle>
            Confirm Marking {!users || !users.length ? 'All' : 'Selected'}{' '}
            Commissions Paid
          </DialogTitle>
          <DialogContent>
            Are you sure you want to mark{' '}
            {!users || !users.length ? 'ALL' : users.join(', ')} unpaid
            commissions as Paid?
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setDialogOpen(false)
              }}
            >
              Cancel
            </Button>
            <LoadingButton
              variant="contained"
              color="primary"
              onClick={() => markPaid({ variables: { invoices: mutationData } })}
              loading={markPaidLoading}
            >
              Mark Paid
            </LoadingButton>
          </DialogActions>
        </Dialog>
      </ToolbarGroup>
    </Toolbar>
  )
}

let DescWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0 16px;
  & > * {
    margin-right: 8px;
  }
`

let getInitials = ({ first, last }) =>
  [...first.slice(0, 1), ...last.slice(0, 1)].join('').toUpperCase()

// <Link to={`/employees/${rowData.id}/attendance?attendanceType=pto`}>

let DescCol = ({ cellData, rowData, onClick }) => (
  <DescWrapper onClick={() => onClick(rowData)}>
    <AvatarBorder>
      {getInitials({ first: cellData, last: rowData.last_name })}
    </AvatarBorder>
    <Subhead display="block">
      {cellData ? (
        `${cellData} ${rowData.last_name}`
      ) : (
        <Typography style={{ fontStyle: 'italic' }}>No Sales Person</Typography>
      )}
    </Subhead>
  </DescWrapper>
)

function UnpaidCommissionList({
  items = [],
  onInfiniteLoad,
  pageInfo,
  onDisplayInvoices,
  checks = [],
  onCheckChange,
  showPast = false,
  onPastInfiniteLoad,
}) {
  function getRowCount() {
    if (!pageInfo) {
      return 9999
    }
    return pageInfo.total
  }

  return (
    <TableList
      data={items || []}
      infinite
      loadMoreRows={onInfiniteLoad}
      rowCount={getRowCount()}
      wrapRow={({ children, rowData }) => <>{children}</>}
    >
      {!showPast && (
        <Column
          dataKey="id"
          headerRenderer={({ label }) => (
            <div style={{ textAlign: 'center' }}>{label}</div>
          )}
          cellRenderer={({ cellData }) => (
            <div>
              <Checkbox
                name={cellData}
                onChange={e => {
                  onCheckChange({ id: cellData, checked: e.target.checked })
                }}
                checked={checks.includes(cellData)}
              />
            </div>
          )}
          label="Actions"
          flexGrow={0}
          width={40}
        />
      )}
      <Column
        dataKey="first_name"
        headerRenderer={({ label }) => (
          <div style={{ textAlign: 'center' }}>{label}</div>
        )}
        cellRenderer={data => <DescCol {...data} onClick={onDisplayInvoices} />}
        label="Name"
        flexGrow={1}
        width={0}
      />
      <Column
        dataKey="unpaid_commission"
        headerRenderer={({ label }) => (
          <div style={{ textAlign: 'right' }}>{label}</div>
        )}
        cellRenderer={({ cellData, rowData = {} }) => (
          <Body1 display="block" style={{ textAlign: 'right' }}>
            {rowData.has_credit_memo && '(Credit Memo) '}
            {formatMoneyStandard(cellData)}
          </Body1>
        )}
        label="Unpaid Commission"
        flexGrow={1}
        width={1}
        maxWidth={250}
      />
      <Column
        label=""
        dataKey="id"
        disableSort={true}
        width={200}
        cellRenderer={({ id }) => ''}
      />
    </TableList>
  )
}
UnpaidCommissionList.fragments = {
  unpaidCommissionByUserListDetail: gql`
    fragment UnpaidCommissionByUserListDetail on UnpaidCommissionByUsers {
      id
      first_name
      last_name
      unpaid_commission
      has_credit_memo
      invoiceitems {
        id
        charge
        invoice_status
        invoice_credit_memo
        invoice_id
        commission
        gpm_percent
        commission_percent
        commission_status
        commission_complete_at
      }
    }
  `,
}

let unpaidQuery = gql`
  query UnapidCommissionByUsers(
    $cursor: String
    $limit: Int = 50
    $to: String
    $from: String
    $includeOpen: Boolean = false
  ) {
    unpaidCommissionByUser: allUnpaidCommissionByUsers(
      cursor: $cursor
      limit: $limit
      filters: {
        to: $to
        from: $from
        includeOpen: $includeOpen
        commissionStatus: UNPAID
      }
    ) {
      edges {
        edge {
          ...UnpaidCommissionByUserListDetail
        }
        cursor
      }
      pageInfo {
        next
        current
        prev
        count
        total
      }
    }
  }
  ${UnpaidCommissionList.fragments.unpaidCommissionByUserListDetail}
`

let paidQuery = gql`
  query PaidInvoiceItems(
    $cursor: String = "-1"
    $limit: Int = 1000000
    $to: String
  ) {
    invoiceitems: allInvoiceitems(
      cursor: $cursor
      limit: $limit
      filters: {commission_complete_at: $to}
    ) {
      edges {
        edge {
          id
          charge
          invoice_status
          invoice_id
          commission
          gpm_percent
          commission_percent
          commission_status
          commission_complete_at
          sales_person {
            id
            first_name
            last_name
          }
        }
        cursor
      }
      pageInfo {
        next
        current
        prev
        count
        total
      }
    }
  }
`

function UnpaidCommission({ location, match }) {
  let [dialogData, setDialogData] = useState(null)
  let [checks, setChecks] = useState([])

  let handleDisplayInvoices = data => setDialogData(data)

  let [queryChanges, setQueryChanges] = useState({})
  let {
    to = moment.utc().format('YYYY-MM-DD'),
    showPast = 'false',
    onlyToday = 'false',
  } = queryString.parse(location.search)
  showPast = showPast === 'true'
  onlyToday = onlyToday === 'true'

  let { data, loading, loadMore, refetch } = usePagination({
    query: unpaidQuery,
    variables: {
      to,
      from: onlyToday ? to : undefined,
      cursor: '-1',
    },
  })

  let {
    data: pastData,
    loading: pastLoading,
    loadMore: pastLoadMore,
    refetch: pastRefetch,
  } = usePagination({
    query: paidQuery,
    variables: {
      to,
      cursor: '-1',
    },
  })

  useEffect(() => {
    refetch()
    pastRefetch()
  }, [to, onlyToday])

  let pastCommissions =
    pastLoading || !pastData?.invoiceitems?.edges
      ? []
      : pastData.invoiceitems.edges.reduce((acc, edge) => {
        let e = edge.edge
        if (!e.sales_person) return
        if (!acc[e.sales_person.id])
          acc[e.sales_person.id] = { invoiceitems: [] }
        acc[e.sales_person.id].id = e.sales_person.id
        acc[e.sales_person.id].first_name = e.sales_person.first_name
        acc[e.sales_person.id].last_name = e.sales_person.last_name
        acc[e.sales_person.id].unpaid_commission =
          (acc[e.sales_person.id].unpaid_commission || 0) + e.commission
        acc[e.sales_person.id].invoiceitems.push(e)
        acc[e.sales_person.id].id = e.sales_person.id
        return acc
      }, {})
  pastCommissions = Object.values(pastCommissions)
    .sort((a, b) => a.first_name.localeCompare(b.first_name))
    .map((c, index) => ({ edge: c, cursor: index }))
  let pastPageInfo = {
    count: pastCommissions.length,
    current: -1,
    next: pastCommissions.length,
    prev: -1,
    total: pastCommissions.length,
  }

  return (
    <React.Fragment>
      <ScreenToolbar
        to={to}
        onChange={changes => setQueryChanges(c => ({ ...c, ...changes }))}
        invoices={
          data &&
          data.unpaidCommissionByUser &&
          (!checks || !checks.length
            ? data.unpaidCommissionByUser.edges
            : data.unpaidCommissionByUser.edges.filter(e =>
              checks.includes(e.edge.id),
            ))
        }
        users={
          (data &&
            data.unpaidCommissionByUser &&
            data.unpaidCommissionByUser.edges &&
            data.unpaidCommissionByUser.edges
              .filter(e => checks.includes(e.edge.id))
              .map(e => `${e.edge.first_name} ${e.edge.last_name}`)) ||
          []
        }
        onPaid={refetch}
        showPast={showPast}
        onlyToday={onlyToday}
      />
      <ModifyQueryParams query={queryChanges} />
      {data &&
        data.unpaidCommissionByUser &&
        data.unpaidCommissionByUser.edges && (
          <UnpaidCommissionList
            items={
              !showPast
                ? data &&
                data.unpaidCommissionByUser &&
                data.unpaidCommissionByUser.edges
                : pastCommissions
            }
            pageInfo={
              !showPast
                ? data &&
                data.unpaidCommissionByUser &&
                data.unpaidCommissionByUser.pageInfo
                : pastPageInfo
            }
            onInfiniteLoad={loadMore}
            onDisplayInvoices={handleDisplayInvoices}
            checks={checks}
            onCheckChange={({ id, checked }) => {
              if (checked) {
                setChecks([...checks, id])
              } else {
                setChecks([...checks.filter(v => v !== id)])
              }
            }}
            showPast={showPast}
          />
        )}

      {!!dialogData && (
        <Dialog open={!!dialogData} maxWidth="lg" fullWidth>
          <DialogTitle>
            {dialogData.first_name} {dialogData.last_name} Commissions
          </DialogTitle>
          <DialogContent>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell>Invoice Status</TableCell>
                  <TableCell align="right">Charge</TableCell>
                  <TableCell align="right">GPM %</TableCell>
                  <TableCell align="right">Commission %</TableCell>
                  <TableCell align="right">Commission</TableCell>
                  <TableCell>Commission Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dialogData.invoiceitems.map(i => (
                  <TableRow key={i.id}>
                    <TableCell>
                      <a
                        href={`/old/database/invoice.php?InvoiceNumber=${i.invoice_id}`}
                      >
                        {i.invoice_id}
                      </a>
                    </TableCell>
                    <TableCell>{i.invoice_status}</TableCell>
                    <TableCell align="right">
                      {formatMoneyStandard(i.charge)}
                    </TableCell>
                    <TableCell align="right">{i.gpm_percent}%</TableCell>
                    <TableCell align="right">{i.commission_percent}%</TableCell>
                    <TableCell align="right">
                      {!!i.invoice_credit_memo && "(Credit Memo) "}
                      {formatMoneyStandard(i.commission)}
                    </TableCell>
                    <TableCell>{i.commission_status}</TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell colSpan={5} />
                  <TableCell
                    align="right"
                    style={{
                      fontWeight: 'bold',
                    }}
                  >
                    {formatMoneyStandard(
                      dialogData.invoiceitems.reduce(
                        (t, i) => t + i.commission,
                        0,
                      ),
                    )}
                  </TableCell>
                  <TableCell colSpan={1} />
                </TableRow>
              </TableBody>
            </Table>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDialogData(null)}>Cancel</Button>
          </DialogActions>
        </Dialog>
      )}
    </React.Fragment>
  )
}

export default UnpaidCommission
