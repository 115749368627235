import React from 'react'
import _ from 'lodash'

import 'react-select/dist/react-select.css'
import AutoComplete, {useAutoCompleteEndpoint} from './AutoComplete'
import gql from 'graphql-tag'
import {InputLabel, ListItemText} from '@mui/material'

let gqlFragment = gql`
  fragment StopReason on StopReasons {
    id
    reason
  }
`

export const StopReasonSelectDynamic = ({onChange, selectedId, ...props}) => {
  let autoProps = useAutoCompleteEndpoint({
    gqlFragment,
    selectedId,
    searchField: 'reason',
  })

  return (
    <AutoComplete
      {...autoProps}
      onChange={onChange}
      itemToString={u => u.reason}
      renderItem={({item}) => (
        <ListItemText id={'stopreason-' + item.id} primary={item.reason} />
      )}
      renderNoItems={() => <ListItemText primary={'No Stop Reasons found'} />}
      label={<InputLabel>Stop Reason</InputLabel>}
      hideIcon
      {...props}
    />
  )
}
