import {CALL_API} from '../middleware/api'
import {schema} from 'normalizr'

export const FETCH_INVENTORY_REQUEST = 'FETCH_INVENTORY_REQUEST'
export const FETCH_INVENTORY_SUCCESS = 'FETCH_INVENTORY_SUCCESS'
export const FETCH_INVENTORY_FAILURE = 'FETCH_INVENTORY_FAILURE'
export const CHECK_FOR_INVENTORY = 'CHECK_FOR_INVENTORY'
export const CREATE_INVENTORY = 'CREATE_INVENTORY'

const endpoint = 'inventoryitems'
const inventory = new schema.Entity('inventory')

export function findById(id) {
  return {
    [CALL_API]: {
      types: [
        FETCH_INVENTORY_REQUEST,
        FETCH_INVENTORY_SUCCESS,
        FETCH_INVENTORY_FAILURE,
      ],
      endpoint: `${endpoint}/${id}`,
      schema: inventory,
    },
  }
}

export function searchInventory(
  query = '',
  {searchField = 'description', page = 1, limit = 25} = {},
) {
  const queryHash = {[searchField]: query}
  const params = Object.assign({}, queryHash, {page, limit})

  return {
    [CALL_API]: {
      types: [
        FETCH_INVENTORY_REQUEST,
        FETCH_INVENTORY_SUCCESS,
        FETCH_INVENTORY_FAILURE,
      ],
      endpoint,
      params,
      schema: [inventory],
    },
  }
}

export function checkForInventory(name) {
  return dispatch => {
    dispatch({type: CHECK_FOR_INVENTORY, payload: name})
    dispatch(searchInventory(name))
  }
}

export function createInventory(name) {
  return {type: CREATE_INVENTORY, payload: name}
}
