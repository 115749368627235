import styled from 'styled-components'
import colors from '../styles/colors'

interface PillBoxProps {
  direction?: 'row' | 'row-reverse' | 'column' | 'column-reverse';
}

const hoverCSS = `
&:hover {
  background-color: ${colors.grey100};
  cursor: pointer;
}
`

const PillBox = styled.div`
  border-radius: 8px;
  padding: 0.25em;
  margin-bottom: 0.25em;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-direction: ${({direction}) => direction || 'inherit'};
  border: ${colors.grey200} 2px solid;

  ${({hover}) => (hover ? hoverCSS : '')}
`

export default PillBox
