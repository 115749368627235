import React from 'react'

class Viewport extends React.Component {
  state = {width: 0, height: 0}

  componentDidMount() {
    this.updateViewportDimensions()
    window.addEventListener('resize', this.updateViewportDimensions)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateViewportDimensions)
  }

  updateViewportDimensions = () => {
    this.setState({width: window.innerWidth, height: window.innerHeight})
  }

  render() {
    let {width, height} = this.state
    return this.props.children({width, height})
  }
}

export default Viewport
