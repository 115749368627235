import {createStore, combineReducers, applyMiddleware} from 'redux'
import thunk from 'redux-thunk'
import logger from '../middleware/logger'
import api from '../middleware/api'
import * as reducers from '../reducers/'
import * as ducks from '../ducks/'
import {reducer as apiReducer, middleware as reduxApi} from '../redux-api'
import _ from 'lodash'

const reducer = combineReducers({
  ...reducers,
  ui: combineReducers({...ducks}),
  api: apiReducer,
})
const finalCreateStore = _.flowRight([
  applyMiddleware(api, reduxApi, thunk, logger),
])(createStore)
const store = finalCreateStore(reducer, {})

export default store

