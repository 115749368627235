// @flow
export default `

  type Principals @model(url: "/principals", filters: "PrincipalFilters") {
    id: String
    name: String
    balance: Float 
    thirtyDay: Float 
    sixtyDay: Float 
    ninetyDay: Float 
    overNinetyDay: Float 
    type: String
    terms_id: String
    batch_output: String
    statements_email_id: ID
    last_arnote: ARNotes @relationship
    min_invoices: [Invoices] @relationship
    email: Emails @relationship
    emails: [Emails] @relationship
  }

  input PrincipalFilters {
    invoice_status: String
    route_id: Int
    terms_id: Int
    customertype_id: Int
    batch_output: String
    name: String
    order: String
    balance: Float
  }
`
