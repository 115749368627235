import React from 'react'
import styled from 'styled-components'
import {Column} from 'react-virtualized'
import gql from 'graphql-tag'
import {Query} from 'react-apollo'
import queryString from 'query-string'
import {Link} from 'react-router-dom'
import moment from 'moment'

import Chip from '@mui/material/Chip'
import Add from '@mui/icons-material/Add'
import Remove from '@mui/icons-material/Remove'
import MenuItem from '@mui/material/MenuItem'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'

import ErrorOutline from '@mui/icons-material/ErrorOutline'
import Done from '@mui/icons-material/Done'

import AttendanceCalendar from './AttendanceCalendar'
import PaginationContainer from '../../components/PaginationContainer'
import TableList from '../../components/Table/TableList'
import SelectMenu from '../../components/ToolbarDropDownMenu'
import AttendanceIcon from '../../components/icons/AttendanceIcon'
import {Spacing} from '../../components/Layout'
import {
  PaperToolbar,
  DetailRow,
  ToolbarLeft,
  ToolbarCenter,
  ToolbarRight,
} from '../../components/Toolbar'
import ModifyQueryParams from '../../components/ModifyQueryParams'
import urlJoin from '../../utils/url-join'
import { formatMoneyStandard } from '../../utils/moneyFormatter'

let TransactionChip = styled(({negative, ...props}) => (
  <Chip {...props} />
)).attrs(() => ({color: 'primary'}))`
  background-color: ${({negative, theme}) =>
    negative ? theme.palette.danger.light : theme.palette.primary.light};
`

let allQuery = gql`
  query servicesQuery($cursor: String, $limit: Int = 25, $userId: ID) {
    invoiceitems: allInvoiceitems(
      filters: {sales_person_id: $userId, not_from_void: "true"}
      limit: $limit
      cursor: $cursor
      orderBy: commission_complete_at_ASC
    ) {
      pageInfo {
        total
      }
      edges {
        cursor
        edge {
          id
          invoice {
            id
            customer {
              id
              name
            }
            serviced_at
          }
          saleitem {
            id
            name
          }
          description
          charge
          commission_status
          commission_complete_at
          commission_percent
          gpm_percent
          commission
        }
      }
    }
  }
`

let capitalize = (s = '') =>
  s.slice(0, 1).toUpperCase() + s.slice(1).toLowerCase()

let BEHAVIOR_TYPES = ['LATE', 'UNEXCUSED', 'EXCUSED']

let TypeRow = styled.div`
  display: flex;
  align-items: center;

  & > * + * {
    margin-left: 8px;
  }
`

class Commissions extends React.Component {
  state = {
    queryChanges: null,
  }

  render() {
    let {match, location} = this.props
    let {params = {}} = match
    return (
      <React.Fragment>
        <PaginationContainer
          query={allQuery}
          variables={{userId: params.id}}
          children={({invoiceitems, loadMore, ...rest}) => {
            console.log('inv', invoiceitems)
            return (
              <TableList
                data={invoiceitems ? invoiceitems.edges : []}
                infinite
                loadMoreRows={loadMore}
                startBottom
                // wrapRow={({children, rowData}) => (
                //   <Link to={urlJoin`${match.url}${'/'}`} key={rowData.id}>
                //     {children}
                //   </Link>
                // )}
                rowCount={invoiceitems ? invoiceitems.pageInfo.total : 0}
              >
                <Column
                  dataKey="invoice.id"
                  label="Invoice"
                  width={180}
                  cellRenderer={({cellData, rowData}) => (
                    <Typography variant="h6">
                      {rowData && rowData.invoice.id}
                    </Typography>
                  )}
                />
                <Column
                  dataKey="invoice.serviced_at"
                  label="Serviced On"
                  width={180}
                  cellRenderer={({cellData, rowData}) => (
                    <Typography variant="h6">
                      {rowData && rowData.invoice.serviced_at && moment.utc(rowData.invoice.serviced_at).format('M/D/YYYY, ddd.')}
                    </Typography>
                  )}
                />
                <Column
                  dataKey="commission_status"
                  label="Comm Status"
                  width={100}
                  cellRenderer={({cellData, rowData}) => {
                    return (
                        <TypeRow>
                          <Typography variant="subtitle1">
                            {cellData}
                          </Typography>
                        </TypeRow>
                      )
                  }}
                />
                <Column
                  dataKey="commission_complete_at"
                  label="Paid On"
                  width={180}
                  cellRenderer={({cellData, rowData}) => {
                    
                    console.log(moment.utc(cellData).format('M/D/YYYY, ddd.') === 'Invalid date' ? {cellData, rowData} : null)
                    return (
                    <Typography variant="h6">
                      {!!cellData && moment.utc(cellData).format('M/D/YYYY, ddd.')}
                    </Typography>
                  )}}
                />
                <Column
                  dataKey="name"
                  label="Customer Name"
                  width={250}
                  cellRenderer={({cellData, rowData}) => (
                    <Typography variant="body1">
                      {rowData && rowData.invoice.customer.name}
                    </Typography>
                  )}
                />
                <Column
                  dataKey="description"
                  label="Service"
                  width={0}
                  flexGrow={1}
                  cellRenderer={({cellData, rowData}) => (
                    <Typography variant="body1">{rowData && rowData.saleitem.name} {cellData}</Typography>
                  )}
                />
                <Column
                  dataKey="charge"
                  label="Charge"
                  width={100}
                  cellRenderer={({cellData, rowData}) => (
                    <Typography variant="body1">
                      {formatMoneyStandard(cellData)}
                    </Typography>
                  )}
                />
                <Column
                  dataKey="commission_percent"
                  label="Commission %"
                  width={100}
                  cellRenderer={({cellData, rowData}) => (
                    <Typography variant="body1">
                      {cellData}%
                    </Typography>
                  )}
                />
                <Column
                  dataKey="gpm_percent"
                  label="GPM"
                  width={100}
                  cellRenderer={({cellData, rowData}) => (
                    <Typography variant="body1">
                      {cellData}%
                    </Typography>
                  )}
                />
                <Column
                  dataKey="commission"
                  label="Commission"
                  width={140}
                  cellRenderer={({cellData, rowData}) => (
                    <Typography variant="h6">
                      {formatMoneyStandard(cellData)}
                    </Typography>
                  )}
                />
              </TableList>
            )
          }}
        />
      </React.Fragment>
    )
  }
}
export default Commissions
