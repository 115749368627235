// @flow
import React from 'react'
import { useRef, useState, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import _ from 'lodash'
import {
  Column,
  AutoSizer,
  Table,
  InfiniteLoader,
  defaultTableRowRenderer,
} from 'react-virtualized'
import 'react-virtualized/styles.css'
import LinearProgress from '@mui/material/LinearProgress'
import Collapse from '@mui/material/Collapse'
import Box from '@mui/material/Box'
import Entity from '../Entity'
import Relationship from '../../api/Relationship'
import { Subhead } from '../Typography'
import { RestoreScroll } from '../Navigator'

let SelectParentAndRel = ({ select, relationship, children }) => (
  <Entity
    select={select}
    children={entity =>
      relationship ? (
        <Relationship
          entity={entity}
          name={relationship}
          children={rel => children({ parent: entity, related: rel })}
        />
      ) : (
        children({ parent: entity, related: null })
      )
    }
  />
)

// type BTableProps<T> = {
//   data: T[],
//   noRowsText?: string,
//   children?: React$Element<Column | EntityColumn>,
// }

let cellDataGetter = _ref => {
  var dataKey = _ref.dataKey,
    rowData = _ref.rowData

  if (!rowData) {
    return undefined
  }

  if (typeof rowData.get === 'function') {
    return rowData.get(dataKey)
  } else {
    return rowData[dataKey]
  }
}

let StyledTable = styled(Table).attrs(({ rowClassName }) => {
  return {
    rowClassName: props =>
      (!!rowClassName ? rowClassName(props) : '') + ' prTableRow',
  }
})`
  & .prTableRow {
    cursor: ${({ rowClickable }) => (rowClickable ? 'pointer' : 'auto')};
  }
`
let HeaderRow = styled.div`
  position: relative;
`
let HeaderProgressBar = styled(p => (
  <Collapse {...p}>
    <LinearProgress />
  </Collapse>
))`
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
`

let defaultWrapRow = ({ children }) => children

export function BaseTableList({ ...props }) {
  ////////////////////////////////////////////////////////////////////////////
  // Code to ensure that the user can keep on scrolling after scrollTop is set
  // The github issue here has it's own refs and storage for this value, but
  // we're ok b/c we handle that in RestoreScroll
  // https://github.com/bvaughn/react-virtualized/issues/1371
  ////////////////////////////////////////////////////////////////////////////
  const [forceScrollTop, setForceScrollTop] = useState()

  useEffect(() => {
    const scrollTop = props.scrollTop

    if (scrollTop !== null) {
      setForceScrollTop(scrollTop)
    }
  }, [props.scrollTop])

  useEffect(() => {
    // We need to reset scrollTop value once it was consumed by the list because otherwise the user will be unable to scroll
    if (forceScrollTop !== undefined) {
      setForceScrollTop(undefined)
    }
  }, [forceScrollTop])
  ////////////////////////////////////////////////////////////////////////////

  let rowRenderer = renderProps => {
    if (!renderProps.rowData) {
      return (
        <div
          className={renderProps.className}
          style={renderProps.style}
          key={renderProps.key}
        >
          Loading
        </div>
      )
    }
    let wrapRow = props.wrapRow || defaultWrapRow
    return wrapRow({
      children: defaultTableRowRenderer(renderProps),
      rowData: renderProps.rowData,
    })
  }

  let headerRowRenderer = ({ className, columns, style }) => {
    return (
      <HeaderRow className={className} style={style}>
        <HeaderProgressBar in={props.loading} />
        {columns}
      </HeaderRow>
    )
  }

  let noRowsRenderer = () => (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
      }}
    >
      <Subhead style={{ color: 'rgba(0, 0, 0, 0.6)' }}>
        {props.noRowsText}
      </Subhead>
    </div>
  )

  const { noRowsText, children, scrollTop, ...other } = props

  const columns = React.Children.toArray(children).map(column =>
    React.cloneElement(column, { cellDataGetter }),
  )

  // function _createEntityColumn(column) {
  //   let {
  //     attribute,
  //     relationship,
  //     cellRenderer,
  //     dataKey,
  //     findEntity,
  //     ...other
  //   } = column.props

  //   return (
  //     <Column
  //       dataKey={dataKey || 'relationships'}
  //       cellDataGetter={cellDataGetter}
  //       {...other}
  //       cellRenderer={({rowData, ...other}) =>
  //         findEntity ? (
  //           <SelectParentAndRel
  //             select={rowData}
  //             relationship={relationship}
  //             children={({parent, related}) =>
  //               cellRenderer({
  //                 ...other,
  //                 entityData: related
  //                   ? related.attributes[attribute]
  //                   : parent.attributes[attribute],
  //                 cellData: dataKey
  //                   ? parent.attributes[dataKey]
  //                   : rowData.attributes,
  //                 entity: relationship ? relationship : parent,
  //                 rowData: parent,
  //                 attribute,
  //                 relationship,
  //               })
  //             }
  //           />
  //         ) : (
  //           cellRenderer({
  //             ...other,
  //             entityData: attribute
  //               ? _.get(rowData, ['attributes', attribute])
  //               : rowData,
  //             cellData: dataKey
  //               ? _.get(rowData, ['attributes', dataKey])
  //               : rowData,
  //             entity: rowData,
  //             attribute,
  //             relationship,
  //             rowData,
  //           })
  //         )
  //       }
  //     />
  //   )
  // }

  // Only render if width is non-zero
  // Autosizer renders even when initial measurments
  // haven't been made
  return (
    <StyledTable
      ref={props.tableRef}
      headerHeight={35}
      headerStyle={{
        textTransform: 'none',
        fontWeight: 'bold',
        fontSize: '.9em',
        // color: theme.palette.textColor2,
        textAlign: 'center',
        padding: '5px 0 0 0',
      }}
      rowStyle={({ index }) =>
        index === -1
          ? {
            backgroundColor: 'white',
            boxShadow: 'rgba(0,0,0,.11) 0 1px 6px, rgba(0,0,0,.11) 0 1px 4px',
          }
          : { borderBottom: '1px solid rgba(0,0,0,.1)' }
      }
      rowHeight={56}
      rowRenderer={rowRenderer}
      headerRowRenderer={headerRowRenderer}
      noRowsRenderer={noRowsText ? noRowsRenderer : () => { }}
      rowCount={props.data && props.data.length}
      children={columns}
      scrollTop={forceScrollTop}
      {...other}
    />
  )
}

// type WrapChildProps<T> = T & {
//   wrap: boolean,
//   component: React.Component<*, *>,
// }

// type DummyProps = {
//   children: ({}) => React$Element<*>,
// }
class DummyChild extends React.Component {
  render() {
    return this.props.children({})
  }
}

class WrapChildFn extends React.Component {
  render() {
    let { component: Component, wrap, childRef, ...props } = this.props
    if (this.props.wrap) {
      return <Component ref={childRef} {...props} />
    }
    return <DummyChild {...props} />
  }
}

// type TableListProps = {
//   loadMoreRows?: (args: {startIndex: number, stopIndex: number}) => Promise<*>,
//   infinite?: boolean,
// }

let TableWrapper = styled.div`
  background-color: white;
  flex: 1 0 auto;
  overflow: hidden;
`

const STATUS_LOADED = 'STATUS_LOADED'
const STATUS_LOADING = 'STATUS_LOADING'

class TableList extends React.Component {
  constructor(props) {
    super(props)
    this.loadMoreRows = _.debounce(this.loadMoreRows, 50)
  }
  static defaultProps = {
    loaderRef: () => { },
  }
  // props: TableListProps & BTableProps<*>
  loaded = []
  loadMoreRows = args => {
    let { startIndex, stopIndex } = args
    let setStatus = status => {
      for (let i = startIndex; i <= stopIndex; i++) {
        this.loaded[i] = status
      }
    }
    setStatus(STATUS_LOADING)
    return this.props.loadMoreRows(args).then(res => {
      setStatus(STATUS_LOADED)
      return res
    })
  }
  // TODO(Tim): find more performant way to calculate this for each row
  isRowLoaded = ({ index }) =>
    this.loaded[index] === STATUS_LOADING ||
    (this.loaded[index] === STATUS_LOADED &&
      this.props.data.find(({ cursor }) => cursor === index))
  componentDidMount() {
    this.loader && this.loader.resetLoadMoreRowsCache(true)
  }
  render() {
    let {
      loadMoreRows,
      isRowLoaded,
      infinite,
      startBottom,
      loaderRef,
      hideStats,
      dataType = 'graphql',
      ...props
    } = this.props

    let _getRow =
      dataType === 'graphql'
        ? ({ index }) => {
          let row = props.data?.find(({ cursor }) => cursor === index)
          return row && row.edge
        }
        : dataType === 'api'
          ? ({ index }) => props.data[index]
          : ({ index }) => props.data[index]

    return (
      <TableWrapper>
        {!hideStats && (
          <Box
            position="absolute"
            bottom={0}
            right={0}
            zIndex="drawer"
            p={1}
            mr={3}
            mb={2}
            boxShadow={3}
            borderRadius={16}
            bgcolor="grey.500"
            color="background.paper"
          >
            {props.rowCount} total
          </Box>
        )}
        <WrapChildFn
          component={InfiniteLoader}
          wrap={infinite}
          isRowLoaded={isRowLoaded || this.isRowLoaded}
          loadMoreRows={this.loadMoreRows}
          minimumBatchSize={25}
          rowCount={props.rowCount}
          threshold={30}
          childRef={i => {
            this.loader = i
            loaderRef(i)
          }}
          children={({ onRowsRendered, registerChild }) => (
            <AutoSizer>
              {({ width, height }) =>
                width ? (
                  <RestoreScroll
                    children={({ scrollPos, updateScroll }) => (
                      <BaseTableList
                        width={width}
                        height={height}
                        gridStyle={!hideStats && { paddingBottom: '64px' }}
                        rowGetter={_getRow}
                        {...props}
                        scrollToIndex={
                          startBottom &&
                            props.rowCount > 0 &&
                            scrollPos === undefined
                            ? props.rowCount - 1
                            : undefined
                        }
                        scrollTop={props.rowCount > 0 ? scrollPos : 0}
                        onScroll={({ scrollTop }) => {
                          updateScroll(scrollTop)
                        }}
                        tableRef={registerChild}
                        onRowsRendered={onRowsRendered}
                      />
                    )}
                  />
                ) : null
              }
            </AutoSizer>
          )}
        />
      </TableWrapper>
    )
  }
}

export let TrashableTableList = styled(TableList)`
  & .trashed {
    text-decoration: line-through;
    background-color: ${({ theme }) => theme.palette.info.muted};
  }
`

export default TableList
