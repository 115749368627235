import React, { useEffect, useState } from 'react'

import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import {
  ArrowDropDown,
  ArrowRight,
  Loop as RepeatIcon,
  Event as RepeatOneIcon,
  Receipt,
  Schedule,
  Brightness5,
  Brightness2,
} from '@mui/icons-material'

import {
  Table,
  TableRow,
  Paper,
  TableCell,
  TableHead,
  TableBody,
  TextField,
} from '@mui/material'
import styled from 'styled-components'
import _ from 'lodash'
import moment from 'moment'
import colors from '../../styles/colors'
import theme from '../../styles/theme'
import {
  ConflictDialog,
  Day,
  EditRouterunModal,
  MassRescheduleModal,
  SchedulesList,
} from './scheduleComponents'
import { EditScheduleModal } from '../Customer/CustomerSchedules'
import { Space } from '../../components/Layout'
import { formatMoneyStandard } from '../../utils/moneyFormatter'
import { getDatesBetweenDates, discountedCharge } from '../../utils'
import { DatePicker, StaticDatePicker } from '@mui/lab'
import EditWorkorder from '../Workorder/EditWorkorder'

let SchedulesCell = styled(TableCell)`
  vertical-align: top;
  background-color: ${colors.grey200};
`

function datesAreOnSameDay(first, second, thing) {
  return (
    first.getFullYear() === second.getFullYear() &&
    first.getMonth() === second.getMonth() &&
    first.getDate() === second.getDate()
  )
}

function _calBackgroundColor({ selected, today }) {
  if (selected) return theme.palette.calendar.selected
  if (today) return theme.palette.calendar.today
}

function _calBackgroundColorDrawer({ selected, today }) {
  if (selected) return colors.grey300
  if (today) return colors.grey300
}

let DashboardWrapper = styled.div`
  padding: 8px;
  grid-column-gap: 8px;
  display: flex;
  flex-direction: row;
`

let SunIcon = styled(Brightness5)`
  color: ${colors.grey700};
`

let MoonIcon = styled(Brightness2)`
  color: ${colors.grey700};
`

function _createMissingElements(count, el) {
  let i = 0
  let output = []
  while (i < count) {
    output.push(el)
    i++
  }
  return output
}

// chatGippity
const averageArray = numbers => numbers.reduce((acc, val) => acc + val, 0) / (numbers.length || 1)

function minutesToHours(minutes) {
  return Math.round((minutes / 60 + Number.EPSILON) * 100) / 100
}

export function Weekly({
  data,
  loading,
  startDate,
  endDate,
  selectedDate,
  setSelectedDate,
  onUpdate,
  viewMode,
  summaryMode,
  supervisorMode,
  openRow,
  setOpenRow,
  cycle4Week = 0,
  filterRemaining = false,
}) {
  let [scheduleDeleteId, setScheduleDeleteId] = React.useState(null)
  let [editSchedule, setEditSchedule] = React.useState(null)
  let [editDate, setEditDate] = React.useState(null)
  let [newScheduleId, setNewScheduleId] = React.useState(1)

  let [editRouterun, setEditRouterun] = useState(null)
  let [rescheduleRouterun, setRescheduleRouterun] = useState(null)
  let [editWorkorder, setEditWorkorder] = useState({})
  let [openEditWorkorder, setOpenEditWorkorder] = useState(false)

  let today = moment
    .utc()
    .startOf('day')
    .toDate()

  let dateRange = getDatesBetweenDates(startDate, endDate)

  if (!data || !data.allRouteRuns) {
    return (
      <DashboardWrapper>
        <Paper
          id="unscheduledSidebar"
          style={{ display: 'flex', flexDirection: 'column' }}
        >
          <StaticDatePicker
            value={moment.utc(selectedDate)}
            onChange={d => setSelectedDate(d.toDate())}
            renderInput={props => (
              <TextField
                {...props}
                variant="outlined"
                margin="dense"
                size="small"
              />
            )}
          />
        </Paper>
        <Paper></Paper>
      </DashboardWrapper>
    )
  }

  let debug = {}
  let debug2 = {}
  let incomePerMan = []
  let totalProduction = 0
  let totalManDays = 0

  let dollars = data.allRouteRuns.edges.reduce((carry, e) => {
    let generatedScheduleIds = e.edge.workorders
      .filter(i => !['VOID'].includes(i.status))
      .map(wo => (wo.schedule_id || '0'))
      .flat()
    let ungeneratedSchedules = e.edge.schedules
      .filter(s => !generatedScheduleIds.includes(s.id))
      .filter(i => i.customer.services_paused !== true)
      .filter(s => !s.customer.services_paused)

    let val = e.edge.workorders
      .filter(i => !['VOID'].includes(i.status) && !i.multiday)
      .filter(i => i.customer.services_paused !== true)
      .reduce(
        (c, s) => s.workorderitems.reduce((c, i) => discountedCharge(i) + c, 0) + c,
        0,
      ) +
      e.edge.workorders
        .filter(i => !['VOID'].includes(i.status) && i.multiday)
        .filter(i => i.customer.services_paused !== true)
        .reduce((c, s) => s.multiday_estimated_total + c, 0) +
      (moment.utc(e.edge.run_at).isBefore(moment.utc().startOf('day'))
        ? 0
        : ungeneratedSchedules.reduce(
          (c, s) => s.services.reduce((c, i) => discountedCharge(i) + c, 0) + c,
          0,
        ))

    debug[e.edge.run_at] = (debug[e.edge.run_at] || 0) + val

    if (e.edge.users.length !== 0
      && (e.edge.workorders.filter(i => !['VOID'].includes(i.status) && i.is_billable).length
        + ungeneratedSchedules.length) > 0
    ) {
      // incomePerMan.push(val / e.edge.users.length)

      totalManDays += e.edge.users.length
      totalProduction += val

      debug2[e.edge.run_at + ' ' + e.edge.route_id] = (debug2[e.edge.run_at + ' ' + e.edge.route_id] || 0) + val
    }

    return (
      carry + val
    )
  }, 0)

  let dollarCapacity = data.allRouteRuns.edges.reduce(
    (carry, e) =>
      carry + (e.edge.users.length === 0 ? 0 : e.edge.income_capacity),
    0,
  )

  let debugHours = {}

  let hours = minutesToHours(
    data.allRouteRuns.edges.reduce((carry, e) => {
      let generatedScheduleIds = e.edge.workorders
        .filter(i => !['VOID'].includes(i.status))
        .map(wo => (wo.schedule_id || "0"))
        .flat()
      let ungeneratedSchedules = e.edge.schedules
        .filter(s => !generatedScheduleIds.includes(s.id))
        .filter(i => i.customer.services_paused !== true)
        .filter(s => !s.customer.services_paused)

      let val = e.edge.workorders
        .filter(i => !['VOID'].includes(i.status))
        .filter(i => i.customer.services_paused !== true)
        .reduce(
          (c, s) => s.workorderitems.reduce((c, i) => i.man_minutes + c, 0) + c,
          0,
        ) +
        (moment.utc(e.edge.run_at).isBefore(moment.utc().startOf('day'))
          ? 0
          : ungeneratedSchedules.reduce(
            (c, s) => s.services.reduce((c, i) => i.man_minutes + c, 0) + c,
            0,
          ))

      debugHours[e.edge.run_at] = (debugHours[e.edge.run_at] || 0) + minutesToHours(val)

      return (
        carry + val
      )
    }, 0),
  )

  let hoursCapacity = minutesToHours(
    data.allRouteRuns.edges.reduce(
      (carry, e) =>
        carry +
        e.edge.users.filter(
          u => !(u.roles?.map(({ name }) => name) || []).includes('Supervisor'),
        ).length *
        6.15 *
        60,
      0,
    ),
  )


  return (
    <DashboardWrapper>
      <Paper
        id="unscheduledSidebar"
        style={{ display: 'flex', flexDirection: 'column' }}
      >
        <StaticDatePicker
          value={moment.utc(selectedDate)}
          onChange={d => setSelectedDate(d.toDate())}
          renderInput={props => (
            <TextField
              {...props}
              variant="outlined"
              margin="dense"
              size="small"
            />
          )}
        />
        <hr style={{ width: '80%', opacity: '50%' }} />
      </Paper>
      {!data ||
        !data.allRouteRuns ||
        !data.allRouteRuns.edges ||
        !data.allRouteRuns.edges.length ? (
        <Paper>No Data</Paper>
      ) : (
        <Paper style={{ overflow: 'overlay' }}>
          <Table style={{ tableLayout: 'fixed', minWidth: '800px' }}>
            <TableHead>
              <TableRow key={1}>
                <TableCell colSpan={1} style={{ borderBottom: '0px' }}>
                  <Typography>Week {cycle4Week}</Typography>
                </TableCell>
                <TableCell
                  colSpan={6}
                  style={{ textAlign: 'center', borderBottom: '0px' }}
                >
                  <Typography>
                    Income: {formatMoneyStandard(dollars)} /{' '}
                    {formatMoneyStandard(dollarCapacity)} &nbsp;
                    | &nbsp; Production Per Man: {formatMoneyStandard(totalProduction / totalManDays)} &nbsp; {' '}
                    | &nbsp; Time:{' '}
                    {hours} / {hoursCapacity} hrs
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow key={2}>
                {/* <TableCell key={1}></TableCell> */}
                {dateRange.map(d => (
                  <TableCell
                    key={moment.utc(d).format('dddMMMD')}
                    style={{
                      backgroundColor: _calBackgroundColor({
                        selected:
                          selectedDate && datesAreOnSameDay(d, selectedDate),
                        today: datesAreOnSameDay(today, d),
                      }),
                    }}
                    onClick={() => setSelectedDate(d)}
                  >
                    {moment.utc(d).format('ddd, MMM D')}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.values(
                _.groupBy(
                  data.allRouteRuns ? data.allRouteRuns.edges : [],
                  r => r.edge.route_id,
                ),
              )
                .sort((a, b) => a[0].edge.route.name - b[0].edge.route.name)
                .map(e => {
                  return (
                    <>
                      <TableRow key={e[0].edge.route_id + '-controls'}>
                        <TableCell
                          key={e[0].edge.route_id}
                          style={{
                            padding: '0px .5em ',
                            borderBottom: '0px',
                            backgroundColor: _calBackgroundColor({
                              selected:
                                selectedDate &&
                                datesAreOnSameDay(startDate, selectedDate),
                              today: datesAreOnSameDay(today, startDate),
                            }),
                          }}
                        // colSpan={7}
                        >
                          <div style={{ height: '35px' }}>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                position: 'absolute',
                              }}
                            >
                              <Button
                                variant="text"
                                style={{
                                  textTransform: 'none',
                                  marginRight: '.5em',
                                }}
                                onClick={() =>
                                  openRow === e[0].edge.route_id
                                    ? setOpenRow(null)
                                    : setOpenRow(e[0].edge.route_id)
                                }
                              >
                                <Typography
                                  variant="h6"
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                >
                                  {e[0].edge.route.shift.id === '1' ? (
                                    <SunIcon />
                                  ) : (
                                    <MoonIcon />
                                  )}
                                  <Space inline size={1} />
                                  Route {e[0].edge.route.name}
                                  {openRow === e[0].edge.route_id ? (
                                    <ArrowDropDown />
                                  ) : (
                                    <ArrowRight />
                                  )}
                                </Typography>{' '}
                              </Button>
                              <Typography style={{ textAlign: 'center' }}>
                                {' '}
                                {moment(
                                  e[0].edge.route.shift.start_time,
                                  'HH:mm:ss',
                                ).format('h:mm a')}
                              </Typography>
                            </div>
                          </div>
                        </TableCell>
                        {getDatesBetweenDates(
                          moment(startDate)
                            .add(1, 'day')
                            .toDate(),
                          endDate,
                        ).map(d => (
                          <TableCell
                            style={{
                              backgroundColor: _calBackgroundColor({
                                selected:
                                  selectedDate &&
                                  datesAreOnSameDay(d, selectedDate),
                                today: datesAreOnSameDay(today, d),
                              }),
                              borderBottom: 0,
                            }}
                          />
                        ))}
                      </TableRow>
                      <TableRow key={e[0].edge.route_id}>
                        {dateRange.map(d => {
                          let routeruns = e.filter(e =>
                            datesAreOnSameDay(new Date(e.edge.run_at), d),
                          )

                          return (
                            <Day
                              data={routeruns.length ? routeruns[0].edge : null}
                              backgroundColor={_calBackgroundColor({
                                selected:
                                  selectedDate &&
                                  datesAreOnSameDay(d, selectedDate),
                                today: datesAreOnSameDay(today, d),
                              })}
                              onUpdate={onUpdate}
                              key={moment.utc(d).format('YYYY-MM-DD')}
                              onClick={() => setEditRouterun(routeruns[0].edge)}
                              viewMode={viewMode}
                              summaryMode={summaryMode}
                              supervisorMode={supervisorMode}
                            />
                          )
                        })}
                      </TableRow>

                      {openRow === e[0].edge.route_id && (
                        <TableRow key={e[0].edge.route_id + '-sched'}>
                          {dateRange.map(d => {
                            let routeruns = e.filter(e =>
                              datesAreOnSameDay(new Date(e.edge.run_at), d),
                            )

                            return (
                              <SchedulesList
                                schedules={
                                  routeruns.length
                                    ? routeruns[0].edge.schedules
                                    : []
                                }
                                workorders={
                                  routeruns.length
                                    ? routeruns[0].edge.workorders.filter(
                                      i => !['VOID'].includes(i.status),
                                    )
                                    : []
                                }
                                onOpenWorkorder={w => {
                                  setEditWorkorder(w)
                                  setOpenEditWorkorder(true)
                                }}
                                parent={
                                  routeruns.length ? routeruns[0].edge : {}
                                }
                                past={moment(d)
                                  .utc()
                                  .isBefore(today)}
                                backgroundColor={_calBackgroundColorDrawer({
                                  selected:
                                    selectedDate &&
                                    datesAreOnSameDay(d, selectedDate),
                                  today: datesAreOnSameDay(today, d),
                                })}
                                onOpenSchedule={s => {
                                  setEditSchedule(s)
                                  setEditDate(d)
                                }}
                                onMassReschedule={setRescheduleRouterun}
                                key={moment(d).format('YYYY-MM-DD')}
                                onUpdate={onUpdate}
                                viewMode={viewMode}
                                filterRemaining={filterRemaining}
                              />
                            )
                          })}
                        </TableRow>
                      )}
                    </>
                  )
                })}
            </TableBody>
          </Table>
        </Paper>
      )}
      <EditScheduleModal
        editSchedule={editSchedule}
        jobRequirements={data.allJobrequirementtypes}
        setEditSchedule={setEditSchedule}
        setNewScheduleId={setNewScheduleId}
        refetchSchedules={onUpdate}
        customer={editSchedule && editSchedule.customer}
        instanceDate={editDate}
        key={editSchedule && editSchedule.id}
      />
      <EditRouterunModal
        editRouterun={editRouterun}
        setEditRouterun={setEditRouterun}
        onUpdate={s => onUpdate(s)}
      />
      <MassRescheduleModal
        rescheduleRouterun={rescheduleRouterun}
        setRescheduleRouterun={setRescheduleRouterun}
        onRefetch={() => onUpdate({})}
        isLoading={loading}
      />
      <EditWorkorder
        key={editWorkorder.id}
        wo={editWorkorder}
        open={openEditWorkorder}
        onSave={() => onUpdate({})}
        onClose={() => setOpenEditWorkorder(false)}
        showStatusChange={true}
        showCustomerLink={true}
        showMultidayVersion={!!editWorkorder.multiday}
        initialTab={editWorkorder.id ? 'preview' : 'edit'}
      />
    </DashboardWrapper>
  )
}
