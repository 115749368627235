import React, {useRef} from 'react'
import styled from 'styled-components'
import theme from '../styles/theme'
import {Body2} from './Typography'

import Search from '@mui/icons-material/Search'
import Close from '@mui/icons-material/Close'

const height = '36px'
let SearchContainer = styled.div`
  display: flex;
  align-items: center;
  height: ${height};
  background-color: ${theme.primary400};
`
let SearchIcon = styled(Search)`
  width: 72px;
  line-height: ${height} !important;
  text-align: center;
  color: ${theme.white};
`
let CloseIcon = styled(Close)`
  width: 45px;
  cursor: pointer;
  line-height: ${height} !important;
  text-align: center;
  color: ${theme.white};
`
let SearchInput = styled.input`
  color: white;
  font-size: 16px;
  width: 100%;
  line-height: ${height};
  background-color: transparent;
  border: none;
`
// may need to debounce componets that were using this prior

let SearchBox = React.forwardRef(
  (
    {
      style,
      className,
      onSearch = () => {},
      onClear,
      onEnter,
      value,
      autoFocus,
      focusOnClear = true,
      placeholder = 'Search',
    },
    ref,
  ) => {
    let inputEl = useRef(null)
    function clearSearch() {
      if (typeof onClear === 'function') {
        onClear()
      } else {
        onSearch('')
      }
      if (focusOnClear) inputEl.current.focus()
    }

    function handleSearch(e) {
      const query = e.target.value
      onSearch(query)
    }

    function handleEnter() {
      ;(onEnter || onSearch)(value)
    }

    function onKeyPress(e) {
      // enter
      if (e.charCode === 13) handleEnter()
      // escape
      if (e.charCode === 27) clearSearch()
    }

    return (
      <SearchContainer style={style} className={className}>
        <SearchIcon />
        <Body2 style={{flexGrow: 1}}>
          <SearchInput
            placeholder={placeholder}
            value={value}
            onKeyPress={onKeyPress}
            onChange={handleSearch}
            ref={el => {
              if (ref) ref.current = el
              inputEl.current = el
            }}
            autoFocus={autoFocus}
          />
        </Body2>
        {value ? <CloseIcon onClick={clearSearch} /> : null}
      </SearchContainer>
    )
  },
)
export default SearchBox
