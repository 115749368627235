import styled from 'styled-components'
import React from 'react'
import { Button, CircularProgress, MenuItem } from '@mui/material'

let LoadingButtonWrapped = styled(Button)`
  position: relative;
`
let LoadingSpinner = styled(CircularProgress)`
  position: absolute;
`
let LoadingMenuItemWrapped = styled(MenuItem)`
  position: relative;
`
function LoadingButton({ loading, disabled, children, ...props }) {
  return (
    <LoadingButtonWrapped {...props} disabled={disabled || loading}>
      {children}
      {loading && <LoadingSpinner size={24} />}
    </LoadingButtonWrapped>
  )
}

export function LoadingMenuItem({ loading, disabled, children, ...props }) {
  return (
    <LoadingMenuItemWrapped {...props} disabled={disabled || loading}>
      {children}
      {loading && <LoadingSpinner size={24} />}
    </LoadingMenuItemWrapped>
  )
}

export default LoadingButton
