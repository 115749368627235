import React, {useState} from 'react'
import gql from 'graphql-tag'
import {Link} from 'react-router-dom'
import {useQuery} from '@apollo/react-hooks'
import queryString from 'query-string'

import Print from '@mui/icons-material/Print'
import Checkbox from '@mui/material/Checkbox'
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  CircularProgress,
} from '@mui/material'
import {Title} from '../../components/Typography'

import ArrowLeft from '@mui/icons-material/ArrowLeft'

import {
  PaperToolbar,
  DetailRow,
  ToolbarLeft,
  ToolbarRight,
} from '../../components/Toolbar'
import {openFile} from '../../schema/utils'

let principalsQuery = gql`
  query Principals($filters: PrincipalFilters) {
    customers: allPrincipals(limit: 1000000000, filters: $filters) {
      edges {
        edge {
          id
          name
          type
          batch_output
        }
      }
      pageInfo {
        next
        current
        prev
        count
        total
      }
    }
  }
`

const ArLink = props => <Link to="/ar" {...props} />

function ArPrint({location}) {
  let queries = {
    principals: principalsQuery,
  }

  let queryParams = queryString.parse(location.search)

  let {data, loading} = useQuery(queries.principals, {
    variables: {
      filters: {
        invoice_status: 'OPEN',
        order: 'name',
        batch_output: 'PRINT',
        terms_id: parseInt(queryParams['searchTerms'], 10) || undefined,
        route_id: parseInt(queryParams['searchRoute'], 10) || undefined,
        customertype_id: parseInt(queryParams['searchType'], 10) || undefined,
        name: queryParams['q'],
      },
    },
  })
  let [modifiedIds, setModifiedIds] = useState({})
  let [sending, setSending] = useState(false)

  function checkOff(id, checked) {
    setModifiedIds(ids => ({
      ...ids,
      [id]: checked,
    }))
  }

  function checkAll(checked, ids) {
    if (checked) {
      setModifiedIds({})
    } else {
      setModifiedIds(
        Object.assign({}, ...[...ids].map(({edge: r}, i) => ({[r.id]: false}))),
      )
    }
  }

  function checkAllIsChecked() {
    return !Object.values(modifiedIds).includes(false)
  }

  function getSelectedIds(ids) {
    return ids
      .filter(
        item =>
          !(item.edge.id in modifiedIds && modifiedIds[item.edge.id] === false),
      )
      .map(({edge: r}, i) => {
        return r.id
      })
  }

  if (!data || !data.customers || loading) {
    return null
  }

  let principals = data.customers.edges

  let count = principals && principals.length
  let exclude_count = Object.values(modifiedIds).filter(id => !id).length

  return (
    <React.Fragment>
      <PaperToolbar>
        <DetailRow>
          <ToolbarRight>
            <form method="POST" action="/old/database/print_statement.php">
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  let ids = getSelectedIds(principals).join(',')
                  setSending(true)
                  openFile({
                    url: '/old/database/print_statement.php?ids=' + ids,
                    fileType: 'application/pdf',
                  }).then(() => setSending(false))
                }}
              >
                {sending ? (
                  <CircularProgress size={24} color="secondary" />
                ) : (
                  <Print />
                )}
              </Button>
            </form>
          </ToolbarRight>
          <ToolbarLeft>
            <Button variant="contained" size="small" component={ArLink}>
              <ArrowLeft />
              AR
            </Button>
            <Title style={{marginLeft: '2em'}}>
              Print statements for {count - exclude_count} customers
            </Title>
          </ToolbarLeft>
        </DetailRow>
      </PaperToolbar>
      <Table>
        <TableBody>
          <TableRow key="selectControls">
            <TableCell>
              <Checkbox
                color="primary"
                checked={checkAllIsChecked()}
                onChange={e => checkAll(e.target.checked, principals)}
              />
            </TableCell>
          </TableRow>
          {[...principals].map(({edge: r}, i) => {
            let principalId = r.id
            return (
              <TableRow key={r.id}>
                <TableCell>
                  <Typography variant="subtitle1">
                    <Checkbox
                      color="secondary"
                      checked={
                        modifiedIds[principalId] === undefined
                          ? true
                          : modifiedIds[principalId]
                      }
                      onChange={e => checkOff(r.id, e.target.checked)}
                    />{' '}
                    <a
                      href={
                        r.type === 'Account'
                          ? `/old/database/accountrecord.php?AccountID=${
                              r.id.split('-')[1]
                            }`
                          : `/old/database/customerrecord.php?JobID=${
                              r.id.split('-')[1]
                            }`
                      }
                    >
                      {r.name}
                    </a>
                  </Typography>
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </React.Fragment>
  )
}

export default ArPrint
