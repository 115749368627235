import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import _ from 'lodash'

import DialogBase from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import List from '@mui/material/List'
import ListSubheader from '@mui/material/ListSubheader'
import Avatar from '@mui/material/Avatar'
import CircularProgress from '@mui/material/CircularProgress'

import PageviewIcon from '@mui/icons-material/Pageview'

let Root = styled.div`
  overflow: hidden;
`
let Dialog = styled(DialogBase).attrs(() => ({classes: {paper: 'paper'}}))`
  & .paper {
    align-self: start;
  }
`

const styles = {
  textField: {width: '100%', margin: '0 10px'},
  dialog: {paddingTop: '50px'},
}

class AddDialog extends React.Component {
  constructor(props) {
    super(props)
    this.state = {newName: props.newName, isTyping: false}
  }

  static propTypes = {
    open: PropTypes.bool,
    onCreate: PropTypes.func,
    setSearch: PropTypes.func,
    onClose: PropTypes.func,
  }
  static defaultProps = {
    open: false,
    existing: {},
    onCreate: () => {},
    checkFor: () => {},
    setSearch: () => {},
    onClose: () => {},
    initialName: '',
    title: 'Create Item',
    listTitle: 'Existing Items',
    renderExisting: () => {},
  }
  componentWillMount() {
    this.searchForExisting = _.debounce(this.searchForExisting, 500)
  }

  searchForExisting = name => {
    this.setState({newName: name, isTyping: false})
    this.props.search(name)
  }
  handleChangeName = name => {
    this.setState({isTyping: true})
    this.searchForExisting(name)
  }
  onHide = () => {
    this.setState({newName: ''})
    this.props.onClose()
  }
  createNewAndView = () => {
    this.props.onCreate(this.state.newName)
  }
  render() {
    const {
      existing,
      loading,
      open,
      title,
      listTitle,
      hintText,
      renderExisting,
      children,
      ...other
    } = this.props
    const {isTyping, newName} = this.state

    return (
      <Dialog
        style={styles.dialog}
        bodyStyle={{minHeight: '170px'}}
        ref="dialog"
        title={title}
        open={open}
        repositionOnUpdate={false}
        onClose={this.onHide}
        fullWidth
        {...other}
      >
        <Root>
          <DialogTitle>{title}</DialogTitle>
          <DialogContent>
            <NewInput
              hintText={hintText}
              onChange={this.handleChangeName}
              onCreate={this.createNewAndView}
            />
            <List>
              <ListSubheader>{listTitle}</ListSubheader>
              {newName && !loading && !isTyping
                ? _.map(existing, renderExisting)
                : null}
            </List>
          </DialogContent>
          <div style={{display: 'flex', justifyContent: 'center'}}>
            {this.state.isTyping || loading ? <CircularProgress /> : null}
          </div>
        </Root>
      </Dialog>
    )
  }
}

class NewInput extends React.Component {
  constructor(props) {
    super(props)
    this.state = {newName: ''}
  }

  static propTypes = {
    onChange: PropTypes.func,
    onCreate: PropTypes.func,
  }
  static defaultProps = {
    onChange: () => {},
    onCreate: () => {},
    hintText: 'Item name',
  }
  changeName = event => {
    const name = event.target.value
    this.setState({newName: name})
    this.props.onChange(name)
  }
  render() {
    return (
      <div style={{display: 'flex', alignItems: 'center'}}>
        <Avatar>
          <PageviewIcon />
        </Avatar>
        <TextField
          variant="standard"
          hintText={this.props.hintText}
          style={styles.textField}
          ref="nameField"
          onChange={this.changeName}
          autoFocus={true} />
        <Button
          variant="contained"
          children="Create"
          color="primary"
          onClick={() => this.props.onCreate(this.state.newName)}
        />
      </div>
    );
  }
}

export default AddDialog
