import PropTypes from 'prop-types'
import React from 'react'
import gql from 'graphql-tag'
import {Column} from 'react-virtualized'
import {Link} from 'react-router-dom'
import styled from 'styled-components'

import TableList from '../../components/Table/TableList'
import {formatMoneyStandard} from '../../utils/moneyFormatter'
import {Body1, Subhead} from '../../components/Typography'
import AvatarBorder from '../../components/AvatarBorder'

let DescWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0 16px;
  & > * {
    margin-right: 8px;
  }
`

let DescCol = ({cellData, rowData}) => (
  <Link to={`/inventory/${rowData.id}`}>
    <DescWrapper>
      <AvatarBorder>{rowData.count.toString()}</AvatarBorder>
      <Subhead display="block">{cellData}</Subhead>
    </DescWrapper>
  </Link>
)

class InventoryList extends React.Component {
  static propTypes = {inventoryitems: PropTypes.array}
  static fragments = {
    inventoryitems: gql`
      fragment InventoryItemListDetail on InventoryItems {
        id
        description
        count
        unit_cost
        min
        max
      }
    `,
  }
  static defaultProps = {inventoryitems: []}

  getRowCount = () => {
    let {pageInfo} = this.props
    if (!pageInfo) {
      return 9999
    }
    return pageInfo.total
  }

  render() {
    const {inventoryitems, loading} = this.props

    return (
      <TableList
        data={inventoryitems}
        infinite
        loadMoreRows={this.props.onInfiniteLoad}
        rowCount={this.getRowCount()}
        loading={loading}
      >
        <Column
          dataKey="description"
          headerRenderer={({label}) => (
            <div style={{textAlign: 'center'}}>{label}</div>
          )}
          cellRenderer={data => <DescCol {...data} />}
          label="Description"
          flexGrow={1}
          width={0}
        />
        <Column
          dataKey="unit_cost"
          headerRenderer={({label}) => (
            <div style={{textAlign: 'right'}}>{label}</div>
          )}
          cellRenderer={({cellData}) => (
            <Body1 display="block" style={{textAlign: 'right'}}>
              {formatMoneyStandard(cellData)}
            </Body1>
          )}
          label="Unit Cost"
          flexGrow={1}
          width={1}
          maxWidth={250}
        />
        <Column
          dataKey="min"
          label="Min"
          width={40}
          headerRenderer={({label}) => (
            <div style={{textAlign: 'right'}}>{label}</div>
          )}
          cellRenderer={({cellData}) => (
            <Body1 display="block" style={{textAlign: 'right'}}>
              {cellData}
            </Body1>
          )}
        />
        <Column
          dataKey="max"
          label="Max"
          width={40}
          headerRenderer={({label}) => (
            <div style={{textAlign: 'right'}}>{label}</div>
          )}
          cellRenderer={({cellData}) => (
            <Body1 display="block" style={{textAlign: 'right'}}>
              {cellData}
            </Body1>
          )}
        />
        <Column label="" dataKey="" disableSort={true} width={75} />
      </TableList>
    )
  }
}

export default InventoryList
