import PropTypes from 'prop-types'
import React, {forwardRef} from 'react'
import {Link} from 'react-router-dom'
import moment from 'moment'
import styled from 'styled-components'
import MenuItemLink from './MenuItemLink'
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import * as authActions from '../actions/authActions'
import {graphql} from 'react-apollo'
import compose from 'lodash/flowRight'
import gql from 'graphql-tag'

import MuiButton from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import HomeIcon from '@mui/icons-material/Home'
import {useAuth} from '../security/auth'

let MenuContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`
let NavMenu = styled.ul`
  list-style-type: none;
  display: flex;
  flex-direction: row;
`
let NavMenuItem = styled.div``
NavMenuItem.displayName = 'NavMenuItem'

let Button = styled(MuiButton).attrs(() => ({classes: {label: 'label'}}))`
  & {
    color: ${({theme}) => theme.palette.textInv3Color};
  }
`

class MenuDropdown extends React.Component {
  static contextTypes = {prTheme: PropTypes.object}

  state = {
    anchorEl: null,
  }

  handleClick = event => {
    this.setState({anchorEl: event.currentTarget})
  }

  handleClose = () => {
    this.setState({anchorEl: null})
  }

  render() {
    let {title, children} = this.props
    const {anchorEl} = this.state
    return (
      <NavMenuItem>
        <Button children={title} onClick={this.handleClick} />
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
        >
          {React.Children.map(
            children,
            item =>
              React.cloneElement(item, {
                ...item.props,
                onClick: () => {
                  this.handleClose()
                  if (item.props.onClick) {
                    item.props.onClick()
                  }
                },
              }),
            this,
          )}
        </Menu>
      </NavMenuItem>
    )
  }
}

let Item = forwardRef(({href, title, old, onClick, external}, ref) => (
  <MenuItemLink
    to={old ? '/old/database' + href : href}
    primaryText={title}
    external={old || external}
    onClick={onClick}
    ref={ref}
  />
))

let getRouteRunUrl = run => {
  if (!run) return undefined
  let runDate = moment(run.run_at)
  let dy = runDate.format('DD')
  let mo = runDate.format('MM')
  let yr = runDate.format('YYYY')
  return `/schedules/daily?date=${yr}-${mo}-${dy}&routes=${run.route_id}`
}
let AppMenu = props => {
  let contextTypes = {prTheme: PropTypes.object}
  let logOut = () => {
    const actions = bindActionCreators(authActions, props.dispatch)
    actions.logout()
  }

  let {loading, nextRun, currentUser} = props

  let {status: accessArAging} = useAuth('access ar aging')
  let {status: accessAdministration} = useAuth('access administration')
  let {status: accessStatistics} = useAuth('access statistics')
  let {status: accessAccounting} = useAuth('access accounting')
  let {status: canManageRouterunTemplates} = useAuth(
    'manage routerun templates',
  )

  return (
    <MenuContainer>
      <NavMenu>
        <Button component={Link} to="/">
          <HomeIcon />
        </Button>
        <MenuDropdown title="Customers">
          <Item title="Customers" href="/customers" />
          <Item title="Accounts" href="/accounts" />
          <Item title="Contacts" href="/contacts" />
          <Item title="Feedbacks" href="/feedbacks.php" old />
          <Item title="Sales Leads" href="/feedbacks.php?type=sales" old />
          <Item title="Delinquent" href="/feedbacks.php?type=delinquent" old />
          <Item title="AR Feedbacks" href="/feedbacks.php?type=ar" old />
          <Item title="Evaluations" href="/evaluationslist.php?" old />
        </MenuDropdown>
        <MenuDropdown title="Schedules">
          {/* <Item title="Weekly Overview" href="/weeklyoverview.php" old />
            <Item title="Weekly Schedule" href="/weeklyschedule.php" old /> */}
          {/* 
            <Item title="Daily Schedule" href="/dailyschedule.php" old /> */}
          <Item title="Daily Schedule" href="/schedules/daily" />
          <Item title="Weekly Schedule" href="/schedules/weekly" />
          <Item title="Route Overview" href="/schedules/overview" />
          <Item title="Daily Overview" href="/dailyoverview.php" old />
          <Item title="Monthly Overview" href="/monthlyoverview.php" old />
          <Item title="Irregular Assigments" href="/irregular.php" old />
          <Item title="Reminders" href="/reminders" />
          {canManageRouterunTemplates === 'authorized' ? (
            <Item title="Routerun Template" href="/settings/routeruntemplate" />
          ) : (
            <></>
          )}
          {/* <Item title="Specialty Assigments" href="/speciality.php" old />
            <Item title="Re-Assignments" href="/reassignments.php" old /> */}
        </MenuDropdown>
        <MenuDropdown title="Workorders">
          {/* <Item title="Inactive Invoices" href="/inactiveinvoices.php" old /> */}
          <Item title="All Workorders" href="/workorders" />
          <Item title="Manage Workorders" href="/manageworkorders/manage" />
          <Item title="All Invoices" href="/invoices.php" old />
          <Item title="All Payments" href="/payments.php" old />
          <Item title="A/R Summary" href="/ar/summary" />
          {accessArAging === 'authorized' ? (
            <Item title="A/R Aging" href="/ar" />
          ) : (
            <></>
          )}
          <Item title="Collections Log" href="/activitylog" />
        </MenuDropdown>
        <MenuDropdown title="Employees">
          <Item title="List of Employees" href="/employees" />
          <Item title="Unpaid Attendance" href="/employees/unpaidattendance" />
          <Item title="Unpaid Commission" href="/employees/unpaidcommission" />
          <Item title="Unpaid Tips" href="/employees/unpaidtips" />
          <Item title="Attendance Report" href="/attendance.php" old />
          <Item title="Employee Sales" href="/employeesales.php" old />
        </MenuDropdown>
        {/* {accessStatistics === 'authorized' ? ( */}
        {/*   <MenuDropdown title="Statistics"> */}
        {/*     <Item */}
        {/*       title="Weekly Route Stats" */}
        {/*       href="/statistics_weekly.php" */}
        {/*       old */}
        {/*     /> */}
        {/*     <Item */}
        {/*       title="Weekly Profit" */}
        {/*       href="/statistics_weekly_profit.php" */}
        {/*       old */}
        {/*     /> */}
        {/*     <Item */}
        {/*       title="Monthly Sales by Year" */}
        {/*       href="/statistics_monthly_sales.php" */}
        {/*       old */}
        {/*     /> */}
        {/*     <Item */}
        {/*       title="Monthly Invoicing by Year" */}
        {/*       href="/statistics_monthly_sales_invoices.php" */}
        {/*       old */}
        {/*     /> */}
        {/*     <Item title="Sale Items" href="/statistics_sales_by_item.php" old /> */}
        {/*     <Item */}
        {/*       title="Sale Items by Customer Type" */}
        {/*       href="/statistics_sales_by_item_customer_type.php" */}
        {/*       old */}
        {/*     /> */}
        {/*     <Item */}
        {/*       title="Sales by Customer Type" */}
        {/*       href="/statistics_sales_by_type.php" */}
        {/*       old */}
        {/*     /> */}
        {/*     <Item */}
        {/*       title="Tracked Sales" */}
        {/*       href="/reports/trackedsales" */}
        {/*     /> */}
        {/*     <Item */}
        {/*       title="Manpower Utilization" */}
        {/*       href="/statistics_manpower_utilization.php" */}
        {/*       old */}
        {/*     /> */}
        {/*     <Item */}
        {/*       title="Employee Performance" */}
        {/*       href="/statistics_employee_performance.php" */}
        {/*       old */}
        {/*     /> */}
        {/*     <Item title="Sales Commissions" href="/statistics/commissions" /> */}
        {/*     <Item title="Tips" href="/statistics/tips" /> */}
        {/*     <Item */}
        {/*       title="Acquired Statistics" */}
        {/*       href="/statistics_acquired.php" */}
        {/*       old */}
        {/*     /> */}
        {/*     <Item title="Route Statistics" href="/statistics/route" /> */}
        {/*     <Item title="Weekly Statistics" href="/statistics/week" /> */}
        {/*     <Item */}
        {/*       title="Weekly Sales Statistics" */}
        {/*       href="/statistics/weeklysales" */}
        {/*     /> */}
        {/*     <Item title="Yearly Statistics" href="/statistics/year" /> */}
        {/*     <Item title="Feedbacks By Type" href="/reports/feedbacksbytype" /> */}
        {/*     <Item */}
        {/*       title="Evaluations by User" */}
        {/*       href="/reports/evaluationsbyuser" */}
        {/*     /> */}
        {/*     <Item */}
        {/*       title="Production in Tax Authority" */}
        {/*       href="/reports/productionintaxauthority" */}
        {/*     /> */}
        {/*   </MenuDropdown> */}
        {/* ) : ( */}
        {/*   '' */}
        {/* )} */}
        {accessAccounting === 'authorized' ? (
          <MenuDropdown title="Accounting">
            <Item title="Unrecorded Income" href="/recordincome" />
            <Item title="Unrecorded Payments" href="/recordpayments" />
            <Item title="Unrecorded Bad Debts" href="/recordbaddebt.php" old />
            <Item title="Unrecorded Refunds" href="/recordrefund.php" old />
            <Item
              title="Unrecorded Credit Memos"
              href="/recordcreditmemo.php"
              old
            />
          </MenuDropdown>
        ) : (
          ''
        )}
        {accessAdministration === 'authorized' ? (
          <MenuDropdown title="Administration">
            <Item title="Settings" href="/settings/templates" />
            <Item title="User Activity" href="/activity.php" old />
            <Item title="NEW User Activity" href="/activitylog/ar" />
            {/* <Item title="Route Crosscheck" href="/routeruns/miles" /> */}
            <Item title="Job Requirements" href="/jobrequirements" />
            {/* <Item title="Assets" href="/assets.php" old /> */}
            {/* <Item title="Inventory" href="/inventory" /> */}
            {/* <Item title="Requisitions" href="/requisitions" /> */}
            {/* <Item title="Purchase Orders" href="/pos" /> */}
            <Item title="Routerun Summary" href="/routerunsummary" />
            <Item title="Dispatch Summary" href="/routeruns/dispatch" />
            <Item title="Pricing" href="/pricing" />
            <Item title="Diagnostics" href="/diagnostics" />
            {accessStatistics === 'authorized' ? (
              <Item title="Reports" href="/reports" />
            ) : (
              <></>
            )}
          </MenuDropdown>
        ) : (
          ''
        )}
      </NavMenu>
      {loading || currentUser === null ? null : (
        <NavMenu>
          <MenuDropdown
            title={`${currentUser.first_name} ${currentUser.last_name}`}
            orientation="left"
          >
            <Item title="My Work" href={getRouteRunUrl(nextRun)} />
            <Item title="My Profile" href={'/employees/' + currentUser.id} />
            {/* <Item */}
            {/*   title="My Stewardship" */}
            {/*   href={`/stewardship.php?EmployeeID=${currentUser.id}`} */}
            {/*   old */}
            {/* /> */}
            {/* <Item */}
            {/*   title="My Requisitions" */}
            {/*   href={`/requisitions?user_id=${currentUser.id}`} */}
            {/* /> */}
            <Item title="Logout" onClick={logOut} />
          </MenuDropdown>
        </NavMenu>
      )}
    </MenuContainer>
  )
}
let userQuery = gql`
  query currentUser {
    currentUser {
      id
      first_name
      last_name
    }
  }
`
let routeRunsQuery = gql`
  query nextRoutes($userID: ID) {
    nextRuns: allRouteRuns(filters: {status: planned, user_id: $userID}) {
      edges {
        edge {
          id
          run_at
          route_id
        }
      }
    }
  }
`
AppMenu = compose(
  connect(state => ({
    currentUser: state.auth.get('currentUser'),
    loggedIn: state.auth.get('loggedIn'),
  })),
  graphql(userQuery, {props: ({data}) => ({...data})}),
  graphql(routeRunsQuery, {
    skip: ({currentUser}) => !currentUser,
    options: ({currentUser}) => ({
      variables: {
        userID: currentUser.id,
      },
    }),
    props: ({data}) => ({
      nextRun:
        data.nextRuns && data.nextRuns.edges && data.nextRuns.edges.length
          ? data.nextRuns.edges[0].edge
          : {},
    }),
  }),
)(AppMenu)

export default AppMenu
