import React from 'react'
import moment from 'moment'
import {useField, useFormikContext} from 'formik'

import MuiTextField from '@mui/material/TextField'
import MuiCheckbox from '@mui/material/Checkbox'
import MuiRadio from '@mui/material/Radio'
import {
  DatePicker as MuiDatePicker,
  TimePicker as MuiTimePicker,
} from '@mui/lab'
import {Chip, IconButton, Select} from '@mui/material'
import colors from '../styles/colors'

// // ugly hack b/c package is still using common js, and Vite won't handle this when building.
// // https://github.com/vitejs/vite/issues/2139#issuecomment-824557740
// import S from 'material-ui-phone-number'
// const MuiPhoneNumber = S.default ? S.default : S

import {MuiTelInput, matchIsValidTel} from 'mui-tel-input'
import {Brightness2, Brightness5, Clear} from '@mui/icons-material'
import styled from 'styled-components'

export function TextField({name, ...props}) {
  let [field, {touched, error}] = useField(name)
  let {isSubmitting} = useFormikContext()
  return (
    <MuiTextField
      variant="standard"
      {...field}
      helperText={touched && error}
      error={touched && error}
      disabled={isSubmitting}
      {...props}
    />
  )
}

export function PhoneNumber({name, allowEmpty = false, ...props}) {
  let [{onChange, ...field}, {touched, error}] = useField({
    name,
    validate: v => {
      if (allowEmpty && !v) return undefined
      return matchIsValidTel(v) ? undefined : 'Not a valid phone number'
    },
  })
  let {isSubmitting, setFieldValue} = useFormikContext()

  return (
    <MuiTelInput
      {...field}
      flagSize="small"
      error={touched && error}
      onChange={v => setFieldValue(name, v)}
      onlyCountries={['US']}
      defaultCountry="US"
      forceCallingCode={true}
      InputProps={{
        endAdornment: allowEmpty && (
          <IconButton
            onClick={() => setFieldValue(name, '')}
            disabled={isSubmitting}
          >
            <Clear />
          </IconButton>
        ),
      }}
      {...props}
    />
  )
}

export function StaticSelect({name, children, ...props}) {
  let [field, {touched, error}] = useField({name, multiple: true})
  let {isSubmitting} = useFormikContext()
  return (
    <Select
      variant="standard"
      {...field}
      helperText={touched && error}
      error={touched && error}
      disabled={isSubmitting}
      children={children}
      input={<TextField {...props} children={children} select />}
      {...props}
    />
  )
}

export function MultiSelect({name, children, chip, ...props}) {
  let [field, {touched, error}] = useField({name, multiple: true})
  let {isSubmitting} = useFormikContext()
  return (
    <Select
      variant="standard"
      {...field}
      helperText={touched && error}
      error={touched && error}
      disabled={isSubmitting}
      multiple
      input={<TextField {...props} children={children} select />}
      renderValue={selected => {
        return (
          <div style={{display: 'flex', flexWrap: 'wrap'}}>
            {selected.map(chip)}
          </div>
        )
      }}
      children={children}
    />
  )
}

// There's a weird bug with this. If you don't seed a boolean value to it right away, it'll instead set it's first value to ['on'].
// I think this is MUI's fault but I'm not sure. I tried intercepting the value first, as well as tinkering with useField's config,
// both didn't work. You'll just need to ensure the initial value is a boolean.
// ~ Matt Krell, 2024-03-20
export function Checkbox({name, disabled = false, ...props}) {
  let [field, {touched, error}] = useField({name, type: 'checkbox', ...props})
  let {isSubmitting} = useFormikContext()
  return (
    <MuiCheckbox {...field} {...props} disabled={isSubmitting || disabled} />
  )
}

export function Radio({name, ...props}) {
  let [field, {touched, error}] = useField({name, type: 'radio', ...props})
  let {isSubmitting} = useFormikContext()
  return <MuiRadio {...field} {...props} disabled={isSubmitting} />
}

export function DatePicker({
  name,
  valueFormat = 'YYYY-MM-DD',
  as = MuiDatePicker,
  inputVariant,
  ...props
}) {
  let Comp = as
  let [{value, onChange, ...field}, {touched, error}] = useField(name)
  let {isSubmitting, setFieldValue} = useFormikContext()
  return (
    <Comp
      renderInput={p => (
        <MuiTextField name={name} {...p} variant={inputVariant} {...props} />
      )}
      onChange={d => setFieldValue(name, d ? d.format(valueFormat) : null)}
      value={!value ? null : moment.utc(value)}
      name={name}
      disabled={isSubmitting}
      helperText={touched && error ? error : undefined}
      error={touched && error ? true : undefined}
      {...field}
      {...props}
    />
  )
}

export function TimePicker({
  name,
  valueFormat = 'HHmm',
  as = MuiTimePicker,
  inputVariant,
  ...props
}) {
  let Comp = as
  let [{value, onChange, ...field}, {touched, error}] = useField(name)
  let {isSubmitting, setFieldValue} = useFormikContext()

  return (
    <Comp
      renderInput={p => (
        <MuiTextField name={name} {...p} variant={inputVariant} {...props} />
      )}
      onChange={d => setFieldValue(name, d ? d.format(valueFormat) : null)}
      value={value ? moment(value, valueFormat).toDate() : null}
      name={name}
      disabled={isSubmitting}
      helperText={touched && error ? error : undefined}
      error={touched && error ? true : undefined}
      {...field}
      {...props}
    />
  )
}

export function AutoCompleteField({
  name,
  getSelectedValue = i => (i ? i.id : ''),
  as,
  onSelectChange = i => {},
  inputProps = {},
  ...props
}) {
  let [{value, onChange, ...field}, {touched, error}] = useField(name)
  let {isSubmitting, setFieldValue} = useFormikContext()
  let Comp = as
  return (
    <Comp
      inputProps={{
        ...field,
        helpertext: touched && error,
        error: touched && error,
        ...inputProps,
      }}
      onChange={(selected, value) => {
        let selectedValue = selected
        if (selected?.nativeEvent instanceof Event) {
          selectedValue = value
        }
        setFieldValue(name, getSelectedValue(selectedValue))
        onSelectChange(selectedValue)
      }}
      helperText={touched && error}
      error={touched && error}
      disabled={isSubmitting}
      {...props}
    />
  )
}

let ToggleChip = styled(Chip).attrs(() => ({
  classes: {
    root: 'root',
  },
}))`
  &.root {
    background-color: ${({backgroundColor}) => backgroundColor};
    border: 2px solid;
    border-color: ${({borderColor}) => borderColor}
    color: ${({textColor}) => textColor}
    font-weight: bold;
    padding: 10px;
  }
`
let SunIcon = styled(Brightness5)`
  color: ${colors.grey700};
`
let MoonIcon = styled(Brightness2)`
  color: ${colors.grey700};
`

export const Toggle = ({value, setValue, config, ...props}) => {
  const conf = {
    textColors: [colors.grey800, colors.grey50],
    borderColors: [colors.grey400, colors.grey50],
    backgroundColors: ['white', 'black'],
    labels: ['On', 'Off'],
    icons: [<SunIcon />, <MoonIcon style={{color: colors.grey400}} />],
    ...config,
  }

  let variant = value ? 0 : 1

  return (
    <ToggleChip
      clickable
      avatar={conf.icons[variant]}
      label={conf.labels[variant]}
      textColor={conf.textColors[variant]}
      backgroundColor={conf.backgroundColors[variant]}
      borderColor={conf.borderColors[variant]}
      onClick={() => setValue(!value)}
      {...props}
    />
  )
}
