import React, {useEffect, useState} from 'react'

import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import {
  ArrowDropDown,
  ArrowRight,
  Loop as RepeatIcon,
  Event as RepeatOneIcon,
  Receipt,
  Schedule,
  Brightness5,
  Brightness2,
} from '@mui/icons-material'

import {
  Table,
  TableRow,
  Paper,
  TableCell,
  TableHead,
  TableBody,
} from '@mui/material'
import styled from 'styled-components'
import _ from 'lodash'
import moment from 'moment'
import colors from '../../styles/colors'
import theme from '../../styles/theme'
import {
  ConflictDialog,
  Day,
  EditRouterunModal,
  MassRescheduleModal,
  SchedulesList,
} from './scheduleComponents'
import {EditScheduleModal} from '../Customer/CustomerSchedules'
import {Space} from '../../components/Layout'
import {formatMoneyStandard} from '../../utils/moneyFormatter'
import {getDatesBetweenDates} from '../../utils'
import {DatePicker, StaticDatePicker} from '@mui/lab'
import EditWorkorder from '../Workorder/EditWorkorder'

let SchedulesCell = styled(TableCell)`
  vertical-align: top;
  background-color: ${colors.grey200};
`

function datesAreOnSameDay(first, second, thing) {
  return (
    first.getFullYear() === second.getFullYear() &&
    first.getMonth() === second.getMonth() &&
    first.getDate() === second.getDate()
  )
}

function _calBackgroundColor({selected, today}) {
  if (selected) return theme.palette.calendar.selected
  if (today) return theme.palette.calendar.today
}

function _calBackgroundColorDrawer({selected, today}) {
  if (selected) return colors.grey300
  if (today) return colors.grey300
}

let DashboardWrapper = styled.div`
  padding: 8px;
  grid-column-gap: 8px;
  display: flex;
  flex-direction: row;
`

let WeekRow = styled(TableRow)`
  font-weight: bold;
`

let SunIcon = styled(Brightness5)`
  color: ${colors.grey700};
`

let MoonIcon = styled(Brightness2)`
  color: ${colors.grey700};
`

function _createMissingElements(count, el) {
  let i = 0
  let output = []
  while (i < count) {
    output.push(el)
    i++
  }
  return output
}

function minutesToHours(minutes) {
  return Math.round((minutes / 60 + Number.EPSILON) * 100) / 100
}

export function Overview({
  data,
  loading,
  startDate,
  endDate,
  selectedDate,
  setSelectedDate,
  onUpdate,
  viewMode,
  summaryMode,
  openRow,
  setOpenRow,
  cycle4Week = 0,
  filterRemaining = false,
}) {
  let [scheduleDeleteId, setScheduleDeleteId] = React.useState(null)
  let [editSchedule, setEditSchedule] = React.useState(null)
  let [editDate, setEditDate] = React.useState(null)
  let [newScheduleId, setNewScheduleId] = React.useState(1)

  let [editRouterun, setEditRouterun] = useState(null)
  let [rescheduleRouterun, setRescheduleRouterun] = useState(null)
  let [editWorkorder, setEditWorkorder] = useState({})
  let [openEditWorkorder, setOpenEditWorkorder] = useState(false)

  let today = moment
    .utc()
    .startOf('day')
    .toDate()

  let dateRange = getDatesBetweenDates(startDate, endDate)

  if (!data || !data.allRouteRuns) {
    return (
      <DashboardWrapper>
        <Paper
          id="unscheduledSidebar"
          style={{display: 'flex', flexDirection: 'column'}}
        >
          <StaticDatePicker
            value={moment.utc(selectedDate)}
            onChange={d => setSelectedDate(d.toDate())}
            renderInput={props => (
              <TextField
                {...props}
                variant="outlined"
                margin="dense"
                size="small"
              />
            )}
          />
        </Paper>
        <Paper></Paper>
      </DashboardWrapper>
    )
  }

  // let dollars = data.allRouteRuns.edges.reduce((carry, e) => {
  //   let generatedScheduleIds = e.edge.workorders
  //     .filter(i => !['VOID'].includes(i.status))
  //     .map(wo => (wo.schedule ? wo.schedule.id : '0'))
  //     .flat()
  //   let ungeneratedSchedules = e.edge.schedules
  //     .filter(s => !generatedScheduleIds.includes(s.id))
  //     .filter(s => !s.customer.services_paused)
  //
  //   return (
  //     carry +
  //     e.edge.workorders
  //       .filter(i => !['VOID'].includes(i.status) && !i.multiday)
  //       .reduce(
  //         (c, s) => s.workorderitems.reduce((c, i) => i.charge + c, 0) + c,
  //         0,
  //       ) +
  //     e.edge.workorders
  //       .filter(i => !['VOID'].includes(i.status) && i.multiday)
  //       .reduce((c, s) => s.multiday_estimated_total + c, 0) +
  //     (moment.utc(e.edge.run_at).isBefore(moment.utc().startOf('day'))
  //       ? 0
  //       : ungeneratedSchedules.reduce(
  //           (c, s) => s.services.reduce((c, i) => i.charge + c, 0) + c,
  //           0,
  //         ))
  //   )
  // }, 0)
  //
  // let dollarCapacity = data.allRouteRuns.edges.reduce(
  //   (carry, e) =>
  //     carry + (e.edge.users.length === 0 ? 0 : e.edge.income_capacity),
  //   0,
  // )
  //
  // let hours = minutesToHours(
  //   data.allRouteRuns.edges.reduce((carry, e) => {
  //     let generatedScheduleIds = e.edge.workorders
  //       .filter(i => !['VOID'].includes(i.status))
  //       .map(wo => (wo.schedule ? wo.schedule.id : '0'))
  //       .flat()
  //     let ungeneratedSchedules = e.edge.schedules
  //       .filter(s => !generatedScheduleIds.includes(s.id))
  //       .filter(s => !s.customer.services_paused)
  //
  //     return (
  //       carry +
  //       e.edge.workorders.reduce(
  //         (c, s) => s.workorderitems.reduce((c, i) => i.man_minutes + c, 0) + c,
  //         0,
  //       ) +
  //       (moment.utc(e.edge.run_at).isBefore(moment.utc().startOf('day'))
  //         ? 0
  //         : ungeneratedSchedules.reduce(
  //             (c, s) => s.services.reduce((c, i) => i.man_minutes + c, 0) + c,
  //             0,
  //           ))
  //     )
  //   }, 0),
  // )
  //
  // let hoursCapacity = minutesToHours(
  //   data.allRouteRuns.edges.reduce(
  //     (carry, e) =>
  //     // blah
  //     0,
  //   ),
  // )

  return (
    <DashboardWrapper>
      <Paper
        id="unscheduledSidebar"
        style={{display: 'flex', flexDirection: 'column'}}
      >
        <StaticDatePicker
          value={moment.utc(selectedDate)}
          onChange={d => setSelectedDate(d.toDate())}
          renderInput={props => (
            <TextField
              {...props}
              variant="outlined"
              margin="dense"
              size="small"
            />
          )}
        />
        <hr style={{width: '80%', opacity: '50%'}} />
      </Paper>
      {!data ||
      !data.allRouteRuns ||
      !data.allRouteRuns.edges ||
      !data.allRouteRuns.edges.length ? (
        <Paper>No Data</Paper>
      ) : (
        <Paper style={{overflow: 'overlay'}}>
          <Table style={{tableLayout: 'fixed', minWidth: '800px'}}>
            <TableBody>
              {Object.entries(
                _.groupBy(
                  (data.allRouteRuns
                    ? data.allRouteRuns.edges
                    : []
                  ).sort((a, b) =>
                    moment(a.edge.run_at).isBefore(b.edge.run_at) ? -1 : 1,
                  ),
                  r => moment(r.edge.run_at).week(),
                ),
              ).map(([i, e]) => {
                let currentDateRange =
                  _.groupBy(dateRange, d => moment.utc(d).week())[i] || []

                return (
                  <>
                    <WeekRow key={e[0].edge.route_id + '-week'}>
                      {currentDateRange.map(d => (
                        <TableCell
                          key={moment.utc(d).format('dddMMMD')}
                          style={{
                            backgroundColor: _calBackgroundColor({
                              selected:
                                selectedDate &&
                                datesAreOnSameDay(d, selectedDate),
                              today: datesAreOnSameDay(today, d),
                            }),
                          }}
                          onClick={() => setSelectedDate(d)}
                        >
                          {moment.utc(d).format('ddd, MMM D')}
                        </TableCell>
                      ))}
                    </WeekRow>
                    <TableRow key={e[0].edge.route_id + '-controls'}>
                      <TableCell
                        key={e[0].edge.route_id}
                        style={{
                          padding: '0px .5em ',
                          borderBottom: '0px',
                          backgroundColor: _calBackgroundColor({
                            selected:
                              selectedDate &&
                              datesAreOnSameDay(startDate, selectedDate),
                            today: datesAreOnSameDay(today, startDate),
                          }),
                        }}
                        // colSpan={7}
                      >
                        <div style={{height: '35px'}}>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              position: 'absolute',
                            }}
                          >
                            <Button
                              variant="text"
                              style={{
                                textTransform: 'none',
                                marginRight: '.5em',
                              }}
                              onClick={() =>
                                openRow === i ? setOpenRow(null) : setOpenRow(i)
                              }
                            >
                              <Typography
                                variant="h6"
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                Week {e[0].edge.week || cycle4Week}
                                {openRow === i ? (
                                  <ArrowDropDown />
                                ) : (
                                  <ArrowRight />
                                )}
                              </Typography>{' '}
                            </Button>
                          </div>
                        </div>
                      </TableCell>
                      {currentDateRange.slice(1).map(d => (
                        <TableCell
                          style={{
                            backgroundColor: _calBackgroundColor({
                              selected:
                                selectedDate &&
                                datesAreOnSameDay(d, selectedDate),
                              today: datesAreOnSameDay(
                                today,
                                moment.utc(d).toDate(),
                              ),
                            }),
                            borderBottom: 0,
                          }}
                        />
                      ))}
                    </TableRow>
                    <TableRow key={e[0].edge.route_id}>
                      {currentDateRange.map(d => {
                        let routeruns = e.filter(e =>
                          datesAreOnSameDay(new Date(e.edge.run_at), d),
                        )

                        return (
                          <Day
                            data={routeruns.length ? routeruns[0].edge : null}
                            backgroundColor={_calBackgroundColor({
                              selected:
                                selectedDate &&
                                datesAreOnSameDay(d, selectedDate),
                              today: datesAreOnSameDay(today, d),
                            })}
                            onUpdate={onUpdate}
                            key={moment.utc(d).format('YYYY-MM-DD')}
                            onClick={() => setEditRouterun(routeruns[0].edge)}
                            viewMode={viewMode}
                            summaryMode={summaryMode}
                          />
                        )
                      })}
                    </TableRow>

                    {openRow === i && (
                      <TableRow key={e[0].edge.route_id + '-sched'}>
                        {currentDateRange.map(d => {
                          let routeruns = e.filter(e =>
                            datesAreOnSameDay(new Date(e.edge.run_at), d),
                          )

                          return (
                            <SchedulesList
                              schedules={
                                routeruns.length
                                  ? routeruns[0].edge.schedules
                                  : []
                              }
                              workorders={
                                routeruns.length
                                  ? routeruns[0].edge.workorders.filter(
                                      i => !['VOID'].includes(i.status),
                                    )
                                  : []
                              }
                              onOpenWorkorder={w => {
                                setEditWorkorder(w)
                                setOpenEditWorkorder(true)
                              }}
                              parent={routeruns.length ? routeruns[0].edge : {}}
                              past={moment(d)
                                .utc()
                                .isBefore(today)}
                              backgroundColor={_calBackgroundColorDrawer({
                                selected:
                                  selectedDate &&
                                  datesAreOnSameDay(d, selectedDate),
                                today: datesAreOnSameDay(today, d),
                              })}
                              onOpenSchedule={s => {
                                setEditSchedule(s)
                                setEditDate(d)
                              }}
                              onMassReschedule={setRescheduleRouterun}
                              key={moment(d).format('YYYY-MM-DD')}
                              onUpdate={onUpdate}
                              viewMode={viewMode}
                              filterRemaining={filterRemaining}
                            />
                          )
                        })}
                      </TableRow>
                    )}
                  </>
                )
              })}
            </TableBody>
          </Table>
        </Paper>
      )}
      <EditScheduleModal
        editSchedule={editSchedule}
        jobRequirements={data.allJobrequirementtypes}
        setEditSchedule={setEditSchedule}
        setNewScheduleId={setNewScheduleId}
        refetchSchedules={onUpdate}
        customer={editSchedule && editSchedule.customer}
        instanceDate={editDate}
        key={editSchedule && editSchedule.id}
      />
      <EditRouterunModal
        editRouterun={editRouterun}
        setEditRouterun={setEditRouterun}
        onUpdate={s => onUpdate(s)}
      />
      <MassRescheduleModal
        rescheduleRouterun={rescheduleRouterun}
        setRescheduleRouterun={setRescheduleRouterun}
        onRefetch={() => onUpdate({})}
        isLoading={loading}
      />
      <EditWorkorder
        key={editWorkorder.id}
        wo={editWorkorder}
        open={openEditWorkorder}
        onSave={() => onUpdate({})}
        onClose={() => setOpenEditWorkorder(false)}
        showStatusChange={true}
        showCustomerLink={true}
        showMultidayVersion={!!editWorkorder.multiday}
        initialTab={editWorkorder.id ? 'preview' : 'edit'}
      />
    </DashboardWrapper>
  )
}
