import { formatMoney } from 'accounting'

type FormatOptions = { pos: string, neg: string, zero: string }

export function formatMoneyStandard(money: Number, format: FormatOptions | undefined) {
  format = {
    ...format,
  }

  return formatMoney(money, {
    format
  })
}
