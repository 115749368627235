import React, { useState } from 'react'
import _ from 'lodash'
import urlJoin from '../../utils/url-join'
import PropTypes from 'prop-types'
import queryString from 'query-string'
import Media from 'react-media'
import ModifyQueryParams from '../../components/ModifyQueryParams'
import PaymentScreen from './Payment'
// import Refund from './RefundAdd'
// import Invoice from './Invoice'
import SpeedDialFAB from '../../components/SpeedDialFAB'

import { getAttribute } from '../../utils/json-api'

import { formatMoneyStandard } from '../../utils/moneyFormatter'

import { Column } from 'react-virtualized'

import TableList from '../../components/Table/TableList'
import TableCell from '../../components/Table/TableCell'
import { Body1, Body2, Subhead, Display1 } from '../../components/Typography'
import DataBlock, { HorizontalDataBlock } from '../../components/DataBlock'

import moment from 'moment'
import {
  Dialog,
  MenuItem,
  IconButton,
  Button,
  Paper,
  Divider,
  Fab as FAB,
  Select,
  Typography,
  Link as MuiLink,
  Tooltip,
  TableRow,
  Table,
  Checkbox,
  FormLabel,
  FormControlLabel,
  TextField,
} from '@mui/material'
import { DatePicker } from '@mui/lab'
import { fetchAPI } from '../../schema/utils'
import { gql, prgql } from '../../utils/graphql'

import {
  CloudDownload,
  Code,
  Add as ContentAdd,
  Email,
  Money,
  Payment,
  Print,
  Receipt,
  Redeem,
} from '@mui/icons-material'

import { PaperToolbar } from '../../components/Toolbar'
import ToolbarRow, { ToolbarFab } from '../../components/ToolbarRow'
import ToolbarGroup from '../../components/ToolbarGroup'
import { Space } from '../../components/Layout'
import { useQuery } from 'react-query'
import {
  TRANSACTION_TYPE_INFO,
  TransactionType,
} from '../../constants/Transactions'
import { Link, Route, Switch } from 'react-router-dom'
import theme from '../../styles/theme'
import multicheck from '../../utils/multicheck'
import { print } from '../../utils'
import { useAuth } from '../../security/auth'
import colors from '../../styles/colors'

const TransactionIcon = ({ type }) => {
  let Icon = TRANSACTION_TYPE_INFO[type].icon
  return <Icon />
}

const tabs = [
  { value: 'all', label: 'Everything' },
  { value: 'invoice', label: 'Invoices' },
  { value: 'payment', label: 'Payments' },
  { value: 'refund', label: 'Refunds' },
]
let getValidTabType = type =>
  tabs.map(t => t.value).includes(type) ? type : ''

let Content = ({ sidebar, main }) => {
  return (
    <div style={{ display: 'flex', flex: 1 }}>
      <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
        {main}
      </div>
      <div style={{ width: '220px', padding: '0 0 0 16px' }}>{sidebar}</div>
    </div>
  )
}

let BalanceSummary = ({ balances }) => (
  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
    <DataBlock
      label="Balance"
      data={<Display1>{formatMoneyStandard(balances.balance)}</Display1>}
      valueId={'balance'}
    />
    <div
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        flexDirection: 'column',
        width: '70%',
      }}
    >
      {balances.applicable_credit !== balances.credit ? (
        <HorizontalDataBlock
          label="Credit"
          align={'right'}
          data={
            <Tooltip
              arrow
              placement="left"
              title={
                <Typography>
                  Account Credit:{' '}
                  {formatMoneyStandard(balances.applicable_credit)} <br />
                  Direct Payments:{' '}
                  {formatMoneyStandard(
                    balances.credit - balances.applicable_credit,
                  )}
                </Typography>
              }
            >
              <Typography>
                {formatMoneyStandard(balances.applicable_credit)}
              </Typography>
              <Typography variant="caption">
                of {formatMoneyStandard(balances.credit)}
              </Typography>
            </Tooltip>
          }
          valueId={'credit'}
        />
      ) : (
        <HorizontalDataBlock
          label="Credit"
          data={formatMoneyStandard(balances.credit)}
          valueId={'credit'}
        />
      )}
      <HorizontalDataBlock
        label="1 - 30"
        data={formatMoneyStandard(balances.thirtyDay)}
        valueId={'thirtyDay'}
      />
      <HorizontalDataBlock
        label="31 - 60"
        data={formatMoneyStandard(balances.sixtyDay)}
        valueId={'sixtyDay'}
      />
      <HorizontalDataBlock
        label="61 - 90"
        data={formatMoneyStandard(balances.ninetyDay)}
        valueId={'ninetyDay'}
      />
      <HorizontalDataBlock
        label="Over 90"
        data={formatMoneyStandard(balances.overNinetyDay)}
        valueId={'overNinetyDay'}
      />
    </div>
  </div>
)

const SubToolbar = ({
  location,
  basePath,
  onUpdateQuery,
  selectedInvoiceIds = [],
  from,
  type,
  id,
  diagnosticsData,
  isAccount,
  accountJobID,
  showVoidedInvoices,
  canCreateRefunds,
  canCreateInvs,
}) => {
  let _onDateChange = date => onUpdateQuery({ from: date.format('YYYY-MM-DD') })
  let _onChangeType = e =>
    onUpdateQuery({ type: e.target.value !== 'all' ? e.target.value : '' })
  let _onShowVoidedInvoices = e =>
    onUpdateQuery({ showVoidedInvoices: e.target.checked ? 'true' : 'false' })

  let diagnosticsError = ''
  if (!!diagnosticsData) {
    if (!diagnosticsData.invoice_balance_equals_running_balance) {
      diagnosticsError = `Invoice balance does not equal running balance (${formatMoneyStandard(
        diagnosticsData.diff_running_balance_invoice_balance,
      )})`
    } else if (!diagnosticsData.running_balance_equals_regular_balance) {
      diagnosticsError = `Running balance does not equal regular balance (${formatMoneyStandard(
        diagnosticsData.diff_regular_balance_running_balance,
      )}) (this is probably a bug, not an issue with their transactions)`
    }
  }

  return (
    <PaperToolbar
      style={{
        border: !!diagnosticsError ? `solid ${theme.palette.danger.main} ` : '',
      }}
    >
      <ToolbarRow>
        <ToolbarGroup first>
          <Typography>View:</Typography>
          <Space inline size={1} />
          <Select
            variant="standard"
            value={getValidTabType(type) || 'all'}
            onChange={_onChangeType}
          >
            {tabs.map(t => (
              <MenuItem value={t.value} key={t.value}>
                {t.label}
              </MenuItem>
            ))}
          </Select>
          <Space inline size={3} />
          <Typography>From: </Typography>
          <Space inline size={1} />
          <DatePicker
            format="MMMM Qo, YYYY"
            hintText="Show from"
            defaultDate={moment(from)
              .local()
              .toDate()}
            value={moment(from)
              .local()
              .toDate()}
            container="dialog"
            autoOk={true}
            onChange={_onDateChange}
            renderInput={props => (
              <TextField
                {...props}
                style={{
                  width: '150px',
                }}
                size="small"
              />
            )}
          />
        </ToolbarGroup>
        <ToolbarGroup>
          {type === 'payment' && (
            <Button
              href={`/old/database/paymentreport.php?type=${isAccount ? 'account' : 'customer'
                }&id=${id}&from=${from}`}
            >
              Print Payment Report
            </Button>
          )}
          {type === 'invoice' && !!selectedInvoiceIds.length && (
            <Button
              onClick={() =>
                print(
                  `/invoices/print?invoice_ids=${selectedInvoiceIds.join(',')}`,
                )
              }
            >
              <Print style={{ marginRight: '.25em' }} /> Print Invoices
            </Button>
          )}
          {type === 'invoice' && (
            <Button
              href={`/old/database/invoicereport.php?type=${isAccount ? 'account' : 'customer'
                }&id=${id}&from=${from}`}
            >
              <Print style={{ marginRight: '.25em' }} /> Print Invoice Report
            </Button>
          )}
          <Space inline size={1} />
          {type === 'invoice' && (
            <FormControlLabel
              control={
                <Checkbox
                  color="secondary"
                  onClick={_onShowVoidedInvoices}
                  checked={showVoidedInvoices}
                />
              }
              label={'Show Voided Invoices'}
            />
          )}
          {type === 'all' && !!diagnosticsError && (
            <Typography color="error" style={{ fontWeight: 'bold' }}>
              ERROR: {diagnosticsError}
            </Typography>
          )}
        </ToolbarGroup>
        <ToolbarGroup last>
          <IconButton
            tooltip="Print"
            href={`/old/database/print_statement.php?ids=${isAccount ? 'accounts' : 'customers'
              }-${id}&from=${from}`}
            size="large"
          >
            <Print />
          </IconButton>
          <Link to={urlJoin`${basePath}/email`}>
            <IconButton tooltip="Email" size="large">
              <Email />
            </IconButton>
          </Link>
          <IconButton
            tooltip="Export"
            href={`/old/database/export_statement.php?ids=${isAccount ? 'accounts' : 'customers'
              }-${id}&from=${from}`}
            size="large"
          >
            <CloudDownload />
          </IconButton>
        </ToolbarGroup>
        <ToolbarFab>
          <SpeedDialFAB
            dialButtons={[
              <Link to={urlJoin`${basePath}/payments/add`}>
                <Tooltip title="Add Payment">
                  <FAB color="secondary">
                    +<TransactionIcon type="Payment" />
                  </FAB>
                </Tooltip>
              </Link>,
              canCreateRefunds ? (
                <Tooltip title="Add Refund">
                  <FAB
                    href={`/old/database/refundedit.php?${isAccount ? 'AccountID' : 'JobID'
                      }=${id}${isAccount ? `&JobID=${accountJobID}` : ''
                      }&redirect=${basePath}`}
                    color="secondary"
                    size="small"
                  >
                    +<TransactionIcon type="Refund" />
                  </FAB>
                </Tooltip>
              ) : (
                <></>
              ),
              canCreateInvs && !isAccount ? (
                <Tooltip title="Create Manual Invoice">
                  <FAB
                    href={`/old/database/invoiceedit.php?JobID=${id}&redirect=${basePath}`}
                    color="secondary"
                    size="small"
                  >
                    +<TransactionIcon type="Invoice" />
                  </FAB>
                </Tooltip>
              ) : (
                <></>
              ),
            ]}
          >
            <ContentAdd />
          </SpeedDialFAB>
        </ToolbarFab>
      </ToolbarRow>
    </PaperToolbar>
  )
}

// route paths for transaction types
const TRANSACTION_PATHS = {
  Payment: 'payments',
  Refunds: 'refunds',
  Invoice: 'invoices',
  ['Account Payment']: 'payments', // we'll let payment screens figure out what context we're in.
}

function mungeQuery(query) {
  let parsed = queryString.parse(query)

  let type = getValidTabType(parsed.type) || 'all'

  let from = !!parsed.from
    ? parsed.from
    : moment()
      .subtract(6, 'months')
      .format('YYYY-MM-DD')

  let showVoidedInvoices = parsed.showVoidedInvoices === 'true'

  return { type, from, showVoidedInvoices }
}

// A bit of a mess, feel free to clean up
let RefLink = ({
  rowData,
  basePath,
  children,
  parentType,
  parentId,
  accountId = 0,
}) => {
  let typePath = TRANSACTION_PATHS[rowData.attributes.type]
  let typeLink = typePath
    ? urlJoin`${basePath}/${typePath}/${rowData.attributes.link_id ||
      rowData.id}`
    : ''
  // if we are account and it's a direct payment, go to the CUSTOMER's transactions page & open.
  if (
    (parentType === 'accounts' &&
      [TransactionType.PAYMENT].includes(rowData.attributes.type)) ||
    [TransactionType.TRANSFER_IN, TransactionType.TRANSFER_OUT].includes(
      rowData.attributes.type,
    )
  ) {
    typeLink = urlJoin`/customers/${rowData.attributes.customer_id
      }/transactions/payments/${rowData.attributes.link_id || rowData.id}`
  }
  if (
    parentType === 'customers' &&
    rowData.attributes.type === TransactionType.ACCOUNT_PAYMENT
  ) {
    typeLink = urlJoin`/accounts/${accountId}/transactions/payments/${rowData
      .attributes.link_id || rowData.id}`
  }
  // if we are a customer and it's an account payment, go to the ACCOUNTS transactions page & open.
  return TRANSACTION_TYPE_INFO[rowData.attributes.type].href ? (
    <MuiLink
      href={TRANSACTION_TYPE_INFO[rowData.attributes.type].href(
        rowData.attributes.link_id || rowData.id,
        rowData.attributes.customer_id,
        `/${parentType}/${parentId}/transactions`,
      )}
      children={children}
      underline="hover"
    />
  ) : rowData.attributes.type !== TransactionType.BALANCE_FORWARD ? (
    <Link to={{ ...location, pathname: typeLink }} children={children} />
  ) : (
    <span children={children} />
  )
}

let CustomerTransactions = ({ basePath, location, match, ...props }) => {
  let { id } = match.params

  let [queryChanges, setQueryChanges] = useState({})
  let [invoicesChecked, setInvoicesChecked] = useState({})
  let [invoicesLastChecked, setInvoicesLastChecked] = useState(undefined)

  let isAccount = match.path.split('/')[1] === 'accounts' ? true : false
  let { status: canCreateRefunds } = useAuth('create refunds')
  let { status: canCreateInvs } = useAuth('create blank invoices')

  let createBalanceForward = ({ transaction, date = moment() }) => {
    const amnt = !!transaction
      ? transaction.attributes.balance -
      transaction.attributes.amount *
      TRANSACTION_TYPE_INFO[transaction.attributes.type].sign
      : 0
    return {
      type: 'transactions',
      attributes: {
        type: 'Balance Forward',
        customer_name: 'Balance Forward',
        customer_id: 'Balance Forward',
        transacted_at: date.format('YYYY-MM-DD hh:mm:ss'),
        amount: amnt,
        balance: amnt,
        memo: '',
        reference: '',
        provider_reference: '',
        other_info: '',
        other_info: '',
      },
    }
  }

  let _renderDate = ({ rowData, dataKey }) => (
    <TableCell
      align="flex-end"
      textStyles={{ textAlgin: 'right' }}
      text={
        <Body1>
          {!!getAttribute({ rowData, dataKey }) && moment.utc(getAttribute({ rowData, dataKey })).format('M/D/YYYY')}
        </Body1>
      }
    />
  )
  let _renderTransaction = ({ rowData, colored }) => {
    if (!rowData) return <TableCell align="flex-end" />

    return (
      <RefLink
        rowData={rowData}
        basePath={basePath}
        parentId={id}
        parentType={isAccount ? 'accounts' : 'customers'}
        accountId={balancesData && balancesData?.customers?.account_id}
      >
        <TableCell
          multiline={false}
          align="flex-start"
          text={
            <Subhead
              style={{
                color:
                  !(
                    rowData.attributes.type === 'Invoice' &&
                    rowData.attributes.reference !== 'OPEN'
                  ) && colored
                    ? TRANSACTION_TYPE_INFO[rowData.attributes.type].color
                    : undefined,
              }}
            >
              {rowData.attributes.type === 'Payment' && isAccount && 'Direct'}{' '}
              {rowData.attributes.type}{' '}
              {rowData.attributes.link_id || rowData.id}
            </Subhead>
          }
          preText={
            <TransactionIcon type={rowData.attributes.type || 'Invoice'} />
          }
          textStyles={{ flex: 1, textAlign: 'left' }}
          preTextStyles={{ flex: 0.2, textAlign: 'left' }}
        />
      </RefLink>
    )
  }
  let _renderRef = ({ rowData, colored }) => (
    <TableCell
      align="center"
      text={
        <Body1
          style={{
            color: colored
              ? TRANSACTION_TYPE_INFO[rowData.attributes.type].color
              : undefined,
          }}
        >
          {rowData.attributes.type === 'Payment' ||
            rowData.attributes.type === 'Account Payment'
            ? rowData.attributes.other_info
            : ''}{' '}
          {getAttribute({ rowData, dataKey: 'reference' })}
          {rowData.attributes.type === 'Invoice' &&
            rowData.attributes.provider_reference &&
            ` (${rowData.attributes.provider_reference})`}
        </Body1>
      }
    />
  )
  let _renderMoney = ({ rowData, dataKey, calculated, colored }) => (
    <TableCell
      align="flex-end"
      textStyles={{ textAlign: 'right' }}
      text={
        <Body2
          style={{
            color: colored
              ? TRANSACTION_TYPE_INFO[rowData.attributes.type].color
              : undefined,
          }}
        >
          {formatMoneyStandard(
            (!!calculated
              ? TRANSACTION_TYPE_INFO[rowData.attributes.type].sign
              : 1) * getAttribute({ rowData, dataKey }),
          )}
        </Body2>
      }
    />
  )

  let isOpenInvoice = ({ rowData }) =>
    rowData?.attributes.type === 'Invoice' &&
    rowData?.attributes.reference === 'OPEN'
  let isSettledInvoice = ({ rowData }) =>
    rowData?.attributes.type === 'Invoice' &&
    rowData?.attributes.reference !== 'OPEN'

  let { type, from, showVoidedInvoices } = mungeQuery(location.search)

  // fetch data
  let { data: transactionsData, isLoading: transactionsLoading } = useQuery(
    ['transactions', from, type, isAccount, id],
    async () =>
      await fetchAPI({
        url: isAccount
          ? `accounts/${id}/transactions`
          : `customers/${id}/transactions`,
        query: {
          type: type === 'all' ? undefined : type,
          limit: 10000000000,
          from,
          cursor: '-1',
        },
      }).then(res => res.json()),
  )

  let { data: balancesData, isLoading: balancesLoading } = useQuery(
    ['balances-transactions', id, isAccount],
    async () =>
      await prgql({
        query: isAccount
          ? gql`
        query {
          customers: accounts(id: "${id}") {
            id
            balances {
              id
              balance
              thirtyDay
              sixtyDay
              ninetyDay
              overNinetyDay
              credit
              applicable_credit
            }
            # This is probably bad but whatever
            account_job {
              id
            }
          }
        }`
          : gql`
        query {
          customers(id: "${id}") {
            id
            # This is probably bad but whatever
            account_id
            balances {
              id
              balance
              thirtyDay
              sixtyDay
              ninetyDay
              overNinetyDay
              credit
              applicable_credit
            }
          }
        }`,
      }),
  )

  let { data: diagnosticsData, isLoading: diagnosticsLoading } = useQuery(
    ['diagnostics', isAccount, id],
    async () =>
      await fetchAPI({
        url: `diagnostics/transactions`,
        query: {
          resource: isAccount ? `accounts-${id}` : `customers-${id}`,
        },
      }).then(res => res.json()),
  )

  // there might be a way to get the transactions endpoint to do this but I don't mind this.
  let { data: voidedInvoicesData, isLoading: voidedInvoicesLoading } = useQuery(
    ['voidedInvoices', from, isAccount, id],
    async () =>
      await fetchAPI({
        url: `invoices`,
        query: {
          limit: 10000000000,
          from,
          cursor: '-1',
          status: 'VOID',
          account_id: isAccount ? id : undefined,
          customer_id: isAccount ? undefined : id,
        },
      })
        .then(res => res.json())
        .then(json => {
          return {
            ...json,
            data: json.data?.map(i => ({
              ...i,
              attributes: {
                amount: i.attributes.charge,
                transacted_at: i.attributes.serviced_at,
                type: 'Invoice',
                link_id: i.id,
                customer_name: i.attributes.service_name,
                reference: i.attributes.status,
                provider_reference: i.attributes.provider_po,
              },
            })),
          }
        }),
  )

  let total = 0
  if (type !== 'all') {
    total = (showVoidedInvoices && type === 'invoice' ? voidedInvoicesData : transactionsData)?.data?.reduce(
      (acc, cur) => acc + cur.attributes.amount,
      0,
    )
  }

  // nasty, I know
  let displayData =
    showVoidedInvoices && type === 'invoice'
      ? voidedInvoicesData
      : transactionsData
  let displayLoading =
    showVoidedInvoices && type === 'invoice'
      ? voidedInvoicesLoading
      : transactionsLoading

  return (
    <>
      <Content
        sidebar={
          <div>
            <Button
              href={
                isAccount
                  ? `/old/database/accounttransactions.php?AccountID=${id}`
                  : `/old/database/transactions.php?JobID=${id}`
              }
            >
              go to Old Transactions
            </Button>
            <BalanceSummary
              balances={
                balancesLoading
                  ? {}
                  : (balancesData && balancesData?.customers?.balances) || {}
              }
            />
          </div>
        }
        main={
          <Paper
            style={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <SubToolbar
              location={location}
              basePath={basePath}
              onUpdateQuery={setQueryChanges}
              from={from}
              type={type}
              id={id}
              showVoidedInvoices={showVoidedInvoices}
              selectedInvoiceIds={Object.entries(invoicesChecked)
                .filter(([key, value]) => !!value)
                .map(([key, value]) => key)}
              diagnosticsData={diagnosticsData}
              isAccount={isAccount}
              accountJobID={balancesData?.customers?.account_job?.id}
              canCreateRefunds={canCreateRefunds === 'authorized'}
              canCreateInvs={canCreateInvs === 'authorized'}
            />
            <Divider />
            <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
              <TableList
                // data={transactionsLoading ? [] : transactionsData?.data.map((row, i) => ({
                //   ...row, cursor: Math.max(parseInt(transactionsData.meta.cursor.current, 10), 0) + i,
                // }))}
                // scrollToIndex={loading ? 0 : transactions.value.data.length - 1}
                startBottom
                data={
                  displayLoading
                    ? []
                    : type !== 'all'
                      ? displayData?.data
                      : [
                        createBalanceForward({
                          transaction: displayData?.data[0],
                          date: moment(from).local(),
                        }),
                        ...displayData?.data,
                      ]
                }
                rowCount={
                  displayLoading
                    ? 0
                    : displayData?.meta.cursor.count + (type !== 'all' ? 0 : 1)
                }
                noRowsText={
                  displayLoading ? 'Loading\u2026' : 'No transactions.'
                }
                dataType="api"
                id={`transactions`}
              >
                {type === 'invoice' && (
                  <Column
                    label=""
                    dataKey=""
                    cellRenderer={({ rowData }) => (
                      <Checkbox
                        checked={
                          !!invoicesChecked[
                          rowData?.attributes?.link_id || rowData?.id
                          ]
                        }
                        disabled={rowData?.attributes?.type === 'Total'}
                        color="secondary"
                        onClick={e =>
                          multicheck({
                            e,
                            sortedRowKeys: displayLoading
                              ? []
                              : displayData?.data.map(
                                rowData =>
                                  rowData.attributes?.link_id || rowData.id,
                              ),
                            rowKey: rowData.attributes?.link_id || rowData.id,
                            checks: invoicesChecked,
                            lastCheck: invoicesLastChecked,
                            onSetChecks: setInvoicesChecked,
                            onSetLastCheck: setInvoicesLastChecked,
                          })
                        }
                      />
                    )}
                    width={30}
                  />
                )}
                <Column
                  label="Date"
                  dataKey="transacted_at"
                  cellRenderer={_renderDate}
                  width={100}
                />
                <Column
                  label="Transaction"
                  dataKey="type"
                  flexGrow={1}
                  minWidth={250}
                  cellRenderer={props =>
                    _renderTransaction({
                      ...props,
                      colored: isOpenInvoice(props),
                    })
                  }
                />
                {isAccount && (
                  <Column
                    label="Customer"
                    dataKey="customer_name"
                    cellRenderer={props => <Body1>{getAttribute(props)}</Body1>}
                    width={300}
                    flexGrow={0}
                  />
                )}
                <Column
                  label="Ref."
                  dataKey="reference"
                  cellRenderer={props =>
                    _renderRef({ ...props, colored: isOpenInvoice(props) })
                  }
                  width={150}
                />
                <Column
                  label="Amount"
                  dataKey="amount"
                  width={100}
                  flexGrow={0}
                  cellRenderer={props =>
                    _renderMoney({
                      ...props,
                      calculated: true,
                      colored: !isSettledInvoice(props),
                    })
                  }
                />
                {type === 'all' && (
                  <Column
                    label="Balance"
                    dataKey="balance"
                    width={100}
                    flexGrow={0}
                    cellRenderer={_renderMoney}
                  />
                )}
              </TableList>
            </div>
            {type !== 'all' && (
              <div style={{ display: 'flex', justifyContent: 'flex-end', backgroundColor: colors.grey100 }}>
                <Typography style={{ marginRight: '100px' }}>Total: {formatMoneyStandard(total)}</Typography>
              </div>
            )}
          </Paper>
        }
      />
      <ModifyQueryParams query={{ ...queryChanges }} />
    </>
  )
}

let Centered = ({ children }) => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'center',
      flex: 1,
      margin: '0px 16px ',
      flexDirection: 'column',
    }}
  >
    {children}
  </div>
)

let TransactionRoute = ({ component, idParam, ...props }) => (
  <Route
    render={renderProps => (
      <Centered
        children={React.createElement(component, {
          ...renderProps,
          customerId: renderProps.match.params.id,
          [idParam]:
            renderProps.match.params[idParam] !== 'add' &&
            renderProps.match.params[idParam],
        })}
      />
    )}
    {...props}
  />
)

export default ({ match, location }) => (
  <div style={{ display: 'flex', flex: 1, padding: '16px' }}>
    <Switch>
      <TransactionRoute
        path={`${match.path}/payments/:paymentId`}
        component={PaymentScreen}
        idParam="paymentId"
      />
      {/* <TransactionRoute
        path={`${match.path}/refunds/:refundId`}
        component={Refund}
        idParam="refundId"
      /> */}
      {/* <TransactionRoute
        path={`${match.path}/invoices/:invoiceId`}
        component={Invoice}
        idParam="invoiceId"
      /> */}
    </Switch>
    <Media query="(max-width: 1999px)">
      {matches => (
        <Route
          path={`${match.path}/:transactionType?`}
          render={props => (
            <CustomerTransactions basePath={match.url} {...props} />
          )}
          exact={matches}
        />
      )}
    </Media>
  </div>
)
