import React, { Component } from 'react'
import styled from 'styled-components'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'
import CardHeader from '@mui/material/CardHeader'
import Avatar from '@mui/material/Avatar'
import { graphql } from 'react-apollo'
import gql from 'graphql-tag'
import compose from 'lodash/flowRight'
import { CardContent } from '@mui/material'
import {
  Book,
  BugReport,
  Code,
  DriveEta,
  Lightbulb,
  ListAlt,
  Person,
  Web,
} from '@mui/icons-material'

let DashboardWrapper = styled.div`
  padding: 16px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 16px;
  grid-row-gap: 16px;
`
let delinquents = ({ type }) => type === 'Delinquent'
let sales = ({ type }) => type === 'Sales'
let ar = ({ type }) => type === 'AR'
let notSalesOrDeliqunets = ({ type }) =>
  !delinquents({ type }) && !sales({ type }) && !ar({ type })
class Dashboard extends Component {
  render() {
    let { data } = this.props
    if (!data || !data.feedbacks || data.feedbacks.loading) {
      return null
    }
    let edges =
      (data &&
        data.feedbacks &&
        data.feedbacks.edges &&
        data.feedbacks.edges.map(({ edge }) => edge)) ||
      []
    return (
      <DashboardWrapper>
        <Card>
          <a href="/old/database/feedbacks.php?order=Supervisor">
            <CardHeader
              avatar={
                <Avatar>
                  {edges.filter(notSalesOrDeliqunets).length.toString()}
                </Avatar>
              }
              title="Feedbacks"
            />
          </a>
        </Card>
        <Card>
          <a href="/old/database/feedbacks.php?order=Supervisor&type=sales">
            <CardHeader
              avatar={<Avatar>{edges.filter(sales).length.toString()}</Avatar>}
              title="Sales"
            />
          </a>
        </Card>
        <Card>
          <a href="/old/database/feedbacks.php?order=Supervisor&type=delinquent">
            <CardHeader
              avatar={
                <Avatar>{edges.filter(delinquents).length.toString()}</Avatar>
              }
              title="Delinquents"
            />
          </a>
        </Card>
        <Card>
          <a href="/old/database/feedbacks.php?order=Supervisor&type=ar">
            <CardHeader
              avatar={<Avatar>{edges.filter(ar).length.toString()}</Avatar>}
              title="AR Feedbacks"
            />
          </a>
        </Card>
        <Card>
          <a
            href="https://airtable.com/appHAvFfVfsZrXTIH/interfaces"
            target="_blank"
          >
            <CardHeader
              avatar={
                <Avatar>
                  <ListAlt />
                </Avatar>
              }
              title="Inventory"
            />
          </a>
        </Card>
        <Card>
          <a
            href="https://airtable.com/appozBiGfHomtPd6D/interfaces"
            target="_blank"
          >
            <CardHeader
              avatar={
                <Avatar>
                  <Person />
                </Avatar>
              }
              title="HR"
            />
          </a>
        </Card>
        <Card>
          <a
            href="https://airtable.com/appJVF3XSAHDfFHoB/pag3qZPeIm6wxHsmc"
            target="_blank"
          >
            <CardHeader
              avatar={
                <Avatar>
                  <Book />
                </Avatar>
              }
              title="Training"
            />
          </a>
        </Card>
        <Card>
          <a
            href="https://airtable.com/appFFBVxXKEi6VoE2/interfaces"
            target="_blank"
          >
            <CardHeader
              avatar={
                <Avatar>
                  <DriveEta />
                </Avatar>
              }
              title="Asset Management"
            />
          </a>
        </Card>
        <Card>
          <a
            href="https://app.asana.com/0/1201626295858587/list"
            target="_blank"
          >
            <CardHeader
              avatar={
                <Avatar>
                  <Code />
                </Avatar>
              }
              title="Software Development"
            />
          </a>
        </Card>
        <Card>
          <a href="https://airtable.com/appwx8Yz0RMmWg956/pagGUTg8ssexmJZfn/form" target="_blank">
            <CardHeader
              avatar={
                <Avatar>
                  <BugReport />
                </Avatar>
              }
              title="Report a bug"
            />
          </a>
        </Card>
        <Card>
          <a href="https://airtable.com/appwx8Yz0RMmWg956/paguEBBJoIaA9Xgoz/form" target="_blank">
            <CardHeader
              avatar={
                <Avatar>
                  <Lightbulb />
                </Avatar>
              }
              title="Submit a Feature Request"
            />
          </a>
        </Card>
      </DashboardWrapper>
    )
  }
}
let userQuery = gql`
  query currentUser {
    currentUser {
      id
    }
  }
`
let query = gql`
  query dashboardQuery($userId: ID) {
    feedbacks: allFeedbacks(
      cursor: "-1"
      limit: 1000000
      filters: {user_id: $userId, status: open}
    ) {
      edges {
        edge {
          id
          type
          feedback
          resolution
          customer_id
        }
      }
    }
  }
`
export default compose(
  graphql(userQuery, {
    props: ({ data: { currentUser } }) => ({
      user: currentUser,
    }),
  }),
  graphql(query, {
    skip: ({ user }) => !user,
    options: props => ({
      variables: { userId: props.user.id },
    }),
  }),
)(Dashboard)
