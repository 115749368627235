import React, {useState} from 'react'
import {useMutation, useQuery, useQueryClient} from 'react-query'
import {gql} from 'graphql-tag' // Added gql import
import styled from 'styled-components' // Added styled-components import
import {prgql} from '../../utils/graphql'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  TableFooter,
} from '@mui/material'
import {Toolbar} from '../../AppHandler'
import {Space} from '../../components/Layout'
import {formatMoneyStandard} from '../../utils/moneyFormatter'
import DangerLoadingButton from '../../components/DangerLoadingButton'
import {fetchAPI} from '../../schema/utils'
import RecordStatusDialog from '../../components/RecordStatusDialog'
import {
  ToolbarIconMenu,
  ToolbarLeft,
  ToolbarMenuItem,
  ToolbarRight,
} from '../../components/Toolbar'

let query = gql`
  query UnrecordedIncome {
    allInvoices(filters: {recordable: true}, limit: 1000000000) {
      edges {
        edge {
          id
          customer {
            id
            name
            taxable
            taxitem_id
            taxitem {
              id
              name
            }
          }
          charge
          recorded
        }
      }
    }
  }
`

let DashboardWrapper = styled.div`
  padding: 8px;
  grid-column-gap: 8px;
  display: flex;
  flex-direction: column;
  max-width: 1000px;
`

const RecordIncomeToolbar = () => (
  <Toolbar>
    <ToolbarLeft style={{justifyContent: 'flex-start', flex: 2}}>
      <Typography variant="h6">Record Income</Typography>
    </ToolbarLeft>
    <ToolbarRight style={{justifyContent: 'flex-end', flex: 2}}>
      <ToolbarIconMenu>
        <ToolbarMenuItem
          component="a"
          href="/old/database/recordincome.php"
          label="Old Recordincome"
        />
      </ToolbarIconMenu>
    </ToolbarRight>
  </Toolbar>
)

const RecordIncome = () => {
  let [confirm, setConfirm] = useState(false)
  let [statusDialog, setStatusDialog] = useState(false)
  let [status, setStatus] = useState('SUCCESS')
  let [errors, setErrors] = useState([])
  let queryClient = useQueryClient()
  const {data, isLoading, error} = useQuery('unrecordedIncome', () =>
    prgql({query}),
  )
  const {mutateAsync: record} = useMutation(() =>
    fetchAPI({url: '/recordedincomes/record', options: {method: 'POST'}}),
  )
  const {mutateAsync: transmit} = useMutation(id =>
    fetchAPI({
      url: `/recordedincomes/${id}/transmit`,
      options: {method: 'POST'},
    }),
  )

  async function handleConfirm() {
    setConfirm(false)
    setStatusDialog(true)

    // recording
    setStatus('RECORDING')
    let res
    try {
      res = await record()
    } catch (error) {
      console.log(error)
      setStatus('PR-FAILURE')
      setErrors([{title: 'API failure', detail: error.message}])
      return
    }

    let json = await res.json()
    if (!res.ok) {
      setStatus('PR-FAILURE')
      setErrors(json.errors)
      return
    }

    // parse the id of the returned object
    let id = json.data.id

    // transmitting
    setStatus('TRANSMITTING')
    try {
      res = await transmit(id)
    } catch (e) {
      console.log(e)
      setStatus('PR-FAILURE')
      setErrors([{title: 'API failure', detail: e.message}])
      return
    }

    json = await res.json()
    if (!res.ok) {
      setStatus('QB-FAILURE')
      setErrors(json.errors)
      return
    }
    queryClient.refetchQueries('unrecordedIncome')

    setStatus('SUCCESS')
    return
  }

  if (isLoading) return <p>Loading...</p>

  // Group data by taxitem_id and taxable fields
  const groupedData = {}
  if (data) {
    data.allInvoices.edges.forEach(edge => {
      const {customer, charge, id} = edge.edge
      const taxItemId = customer.taxitem_id
      const isTaxable = customer.taxable
      const taxItemName = customer.taxitem.name
      const exemptSuffix = isTaxable ? '' : ' (Exempt)'
      const key = `${taxItemId}-${isTaxable}`
      if (!groupedData[key]) {
        groupedData[key] = {
          taxItemName: `${taxItemName}${exemptSuffix}`,
          totalCharge: 0,
          taxItemId,
          isTaxable: isTaxable ? 1 : 0,
        }
      }
      groupedData[key].totalCharge += charge
    })
  }

  return (
    <>
      <RecordIncomeToolbar /> {/* Added RecordIncomeToolbar component */}
      <DashboardWrapper>
        <Button
          href="/old/database/recordedincomerecord.php"
          style={{alignSelf: 'start'}}
        >
          Past Records
        </Button>{' '}
        {!error ? (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Tax Item Name</TableCell>
                  <TableCell>Total Charge</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.values(groupedData)
                  .sort((a, b) => a.taxItemName.localeCompare(b.taxItemName))
                  .map((group, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <a
                          href={`/old/database/prerecordedincomedetail.php?TaxItemID=${group.taxItemId}&Taxable=${group.isTaxable}`}
                        >
                          {group.taxItemName}
                        </a>
                      </TableCell>
                      <TableCell>
                        {formatMoneyStandard(group.totalCharge)}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell>Total</TableCell>
                  <TableCell>
                    {formatMoneyStandard(
                      Object.values(groupedData).reduce(
                        (acc, g) => acc + g.totalCharge,
                        0,
                      ),
                    )}
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        ) : (
          <Typography>Error</Typography>
        )}
        <Space />
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
          <Button href="/old/database/prerecordedincomedetail.php" disabled>
            Details
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setConfirm(true)}
            disabled={Object.values(groupedData).length === 0}
          >
            Record
          </Button>
        </div>
        <Dialog open={confirm}>
          <DialogTitle>Record Income</DialogTitle>
          <DialogContent>
            <p>
              Are you sure you want to record this income? This cannot be
              undone.
            </p>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setConfirm(false)}>Cancel</Button>
            <DangerLoadingButton
              color="primary"
              onClick={() => handleConfirm()}
              id="confirm"
            >
              Record
            </DangerLoadingButton>
          </DialogActions>
        </Dialog>
      </DashboardWrapper>
      <RecordStatusDialog
        open={statusDialog}
        errors={errors}
        status={status}
        onClose={() => setStatusDialog(false)}
      />
    </>
  )
}

export default RecordIncome
