import PropTypes from 'prop-types';
import React from 'react';
import {Api} from '../api/api-connect'
import _ from 'lodash'

function selectEntity({source, select}) {
  const entities = _.concat(source.data, source.included)

  if (_.isPlainObject(select)) {
    return _.find(entities, _.update(select, 'id', id => id.toString()))
  }

  if (_.isArray(select)) {
    return _.intersectionWith(entities, select, _.isMatch) || []
  }

  if (_.isString(select)) {
    let relationship = _.get(source, ['data', 'relationships', select, 'data'])
    return relationship
      ? _.find(entities, {id: relationship.id, type: relationship.type})
      : undefined
  }

  throw new Error('Entity selector must be either a string, array, or object')
}

const SelectorType = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  type: PropTypes.string.isRequired,
})

class Entity extends React.Component {
  static propTypes = {
    source: PropTypes.object,
    select: PropTypes.oneOfType([
      SelectorType,
      PropTypes.arrayOf(SelectorType),
    ]),
    // Fall back endpoint to search
    endpoint: PropTypes.string,
    children: PropTypes.func.isRequired,
  };

  static contextTypes = {selectEntity: PropTypes.func};

  render() {
    const {source, select, endpoint, children} = this.props
    let entity

    if (source) {
      entity = selectEntity({source, select})
    } else {
      entity = this.context.selectEntity(select)
    }

    if (entity === undefined) {
      return (
        <Api connect={{entity: `${endpoint || select.type}/${select.id}`}}>
          {({entity}) => entity.fulfilled ? children(entity.value.data) : null}
        </Api>
      )
    }
    return children(entity)
  }
}
export default Entity
export {selectEntity}

