import React, {useEffect, useState} from 'react'
import {Link, Route, Switch} from 'react-router-dom'
import * as Yup from 'yup'

import Button from '@mui/material/Button'
import {Toolbar} from '../../AppHandler'
import {Space, Spacing} from '../../components/Layout'
import {
  FormControlLabel,
  Menu,
  MenuItem,
  LinearProgress,
  Fab,
  Switch as MuiSwitch,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material'
import Typography from '@mui/material/Typography'
import {
  ArrowDropDown,
  Loop as RepeatIcon,
  Event as RepeatOneIcon,
  Assignment,
  Cancel,
  Edit,
  RadioButtonUnchecked,
  CheckCircle,
  Block,
  ErrorOutline,
  MonetizationOn,
  FlashOn,
  Print,
  ArrowLeft,
  ArrowBack,
} from '@mui/icons-material'
import _ from 'lodash'
import {ToolbarCenter, ToolbarLeft} from '../../components/Toolbar'
import styled from 'styled-components'
import gql from 'graphql-tag'
import {useQuery, useMutation, useQueryClient} from 'react-query'
import {prgql} from '../../utils/graphql'
import moment from 'moment'
import ModifyQueryParams from '../../components/ModifyQueryParams'
import queryString from 'query-string'
import EditWorkorder from '../Workorder/EditWorkorder'
import {print} from '../../utils'
import Manage from './Manage'
import Voided from './Voided'
import {formatMoneyStandard} from '../../utils/moneyFormatter'
import LoadingButton from '../../components/LoadingButton'
import multicheck from '../../utils/multicheck'
import {useAuth} from '../../security/auth'
import {fragments} from './WorkordersList'

// This is less performant, but slightly safer than fetching the routerun and getting it's rels. If the routerun relationship had trouble for some reason, this would still
// get the missing data.
let manageQuery = gql`
  query ManageWorkorders($date: String, $route_id: String, $route_id_int: Int) {
    run: allRouteRuns(
      limit: 1
      filters: {from: $date, to: $date, route: $route_id_int}
      cursor: "-1"
    ) {
      edges {
        edge {
          id
          run_at
          route_id
          reconciliation_notes
          collection_matches
          reconciled
          users {
            id
            first_name
            last_name
          }
          route {
            id
            name
          }
          schedules {
            id
            sequence
            customer {
              ...ManageWorkordersCustomer
            }
            services {
              id
              charge
            }
          }
          week
        }
      }
    }
    wos: allWorkorders(
      filters: {assigned_at: $date, route_id: $route_id}
      cursor: "-1"
      limit: 100000000
    ) {
      edges {
        edge {
          id
          sequence
          assigned_at
          completed_at
          status
          route_id
          multiday
          multiday_estimated_total
          customer_id
          notes
          invoice_id
          arrival_start_time
          arrival_end_time
          not_completed
          notes_resolved
          terms_id
          is_billable
          terms {
            id
            terms
          }
          photos {
            id
            type
          }
          workorderitems {
            ...WorkorderitemDetail
          }
          invoice {
            id
            terms_id
            terms {
              id
              terms
            }
            status
            charge
          }
          schedule {
            id
            freq_type
            sequence
          }
          customer {
            ...ManageWorkordersCustomer
          }
          payments {
            id
            amount
            paymenttype_id
            # paymenttype {
            #   id
            #   type
            # }
          }
        }
      }
    }
  }

  fragment ManageWorkordersCustomer on Customers {
    street1
    city {
      id
      name
    }
    region
    postcode
    taxable
    id
    terms_id
    name
    services_paused
    po_needed
    taxitem {
      id
      rate
    }
    balances {
      id
      balance
    }
    next_routerun {
      id
      run_at
      route_id
    }
    last_routerun {
      id
      run_at
      route_id
    }
    terms {
      id
      terms
    }
    credit_items {
      id
      amount
    }
    credit_payments {
      id
      notes
    }
  }
  ${fragments.workorderitem}
`

let tipsQuery = gql`
  query tips($date: String!, $route_id: String!) {
    allTips(filters: {collected_at: $date, route_id: $route_id}) {
      edges {
        edge {
          id
          user_id
          user {
            id
            first_name
            last_name
          }
          route_id
          collected_at
          amount
        }
      }
    }
  }
`

let tipsCreateMutation = gql`
  mutation create(
    $user_id: ID!
    $amount: Float!
    $route_id: ID!
    $collected_at: String!
  ) {
    createTips(
      input: {
        user_id: $user_id
        amount: $amount
        route_id: $route_id
        collected_at: $collected_at
      }
    ) {
      tips {
        id
      }
    }
  }
`

let tipsUpdateMutation = gql`
  mutation update($input: [UpdateManyTipsInput]) {
    updateManyTips(input: $input) {
      tips {
        id
      }
    }
  }
`

let tipsDeleteMutation = gql`
  mutation delete($id: String!) {
    deleteTips(input: {id: $id}) {
      message
    }
  }
`

let reconcileMutation = gql`
  mutation reconcile(
    $id: String!
    $collection_matches: Boolean!
    $reconciliation_notes: String!
  ) {
    updateRouteRuns(
      input: {
        id: $id
        reconciled: true
        collection_matches: $collection_matches
        reconciliation_notes: $reconciliation_notes
      }
    ) {
      routeRuns {
        id
        reconciled
        reconciliation_notes
        collection_matches
      }
    }
  }
`

let generateWorkordersMutation = gql`
  mutation GenerateWorkordersFromSchedules(
    $route_id: ID
    $date: String!
    $ids: [ID]!
  ) {
    generateWorkordersFromSchedules(
      input: {ids: $ids, route_id: $route_id, date: $date}
    ) {
      workorders {
        id
        schedule {
          id
        }
      }
    }
  }
`

let generateInvoicesMutation = gql`
  mutation GenerateInvoicesFromWorkorders($ids: [ID]!) {
    generateInvoicesFromWorkorders(input: {ids: $ids}) {
      invoices {
        id
        terms_id
      }
    }
  }
`

let batchWorkordersMutation = gql`
  mutation BatchWorkorders($input: [UpdateManyWorkordersInput]) {
    updateManyWorkorders(input: $input) {
      workorders {
        id
        status
      }
    }
  }
`

let batchInvoicesMutation = gql`
  mutation BatchInvoices($input: [UpdateManyInvoicesInput]) {
    updateManyInvoices(input: $input) {
      invoices {
        id
        status
      }
    }
  }
`

let payInvoicesMutation = gql`
  mutation PayInvoices($input: PayInvoicesWithCashInput) {
    payInvoicesWithCash(input: $input) {
      invoices {
        id
        status
      }
    }
  }
`

let creditInvoicesMutation = gql`
  mutation CreditInvoices($input: PayInvoicesWithCreditInput) {
    payInvoicesWithCredit(input: $input) {
      invoices {
        id
        status
      }
    }
  }
`

let ConfListItem = styled.li`
  padding: 0.75em;
`
let ConfList = styled.ul`
  padding: 0.5em;
`

const ConfirmPayCash = ({open, onCancel, onConfirm, selectedRows, loading}) => {
  let total = selectedRows.reduce((acc, {invoice}) => acc + invoice.charge, 0)

  return (
    <Dialog open={!!open} maxWidth={'sm'} fullWidth>
      <DialogTitle>Confirm Pay Cash</DialogTitle>
      <DialogContent>
        <Typography>
          This action will{' '}
          <span style={{fontWeight: 'bold'}}>create a new cash-payment</span>{' '}
          for the following invoices:
        </Typography>
        <ConfList>
          {selectedRows.map(({invoice, customer}) => (
            <ConfListItem key={invoice.id}>
              <span style={{fontWeight: 'bold'}}>{invoice.id}</span>:{' '}
              {formatMoneyStandard(invoice.charge)} -{' '}
              <span style={{fontStyle: 'italic'}}>{customer.name}</span>
            </ConfListItem>
          ))}
        </ConfList>
        <Typography style={{marginBottom: '1em'}}>
          <span style={{fontWeight: 'bold'}}>Total Cash payments</span>:{' '}
          {formatMoneyStandard(total)}
        </Typography>
        <Typography>
          This can be difficult to undo if you do this by mistake.
        </Typography>
        <Typography>Proceed?</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <LoadingButton
          variant="contained"
          color="primary"
          onClick={onConfirm}
          loading={!!loading}
        >
          Make new cash payments
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

const ConfirmApplyCredit = ({
  open,
  onCancel,
  onConfirm,
  selectedRows,
  loading,
}) => {
  let total = selectedRows.reduce(
    (carry, {invoice, customer}) =>
      carry +
      Math.min(
        invoice.charge,
        customer.credit_items.reduce((carry, ci) => carry + ci.amount, 0),
      ),
    0,
  )
  return (
    <Dialog open={!!open} maxWidth={'sm'} fullWidth>
      <DialogTitle>Confirm Apply Credit</DialogTitle>
      <DialogContent>
        <Typography>
          This action will{' '}
          <span style={{fontWeight: 'bold'}}>apply existing credit</span> for
          the following invoices:
        </Typography>
        <ConfList>
          {selectedRows.map(({invoice, customer}) => (
            <ConfListItem key={invoice.id}>
              <span style={{fontWeight: 'bold'}}>{invoice.id}</span>:{' '}
              {formatMoneyStandard(invoice.charge)}
              <span style={{margin: '0 .5em', marginBottom: '-.5em'}}>
                {<ArrowBack />}
              </span>
              {formatMoneyStandard(
                Math.min(
                  invoice.charge,
                  customer.credit_items.reduce(
                    (carry, ci) => carry + ci.amount,
                    0,
                  ),
                ),
              )}{' '}
              - <span style={{fontStyle: 'italic'}}>{customer.name}</span>
            </ConfListItem>
          ))}
        </ConfList>
        <Typography style={{marginBottom: '1em'}}>
          <span style={{fontWeight: 'bold'}}>Total Credit to be applied</span>:{' '}
          {formatMoneyStandard(total)}
        </Typography>
        <Typography>
          This can be difficult to undo if you do this by mistake.
        </Typography>
        <Typography>Proceed?</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <LoadingButton
          variant="contained"
          color="primary"
          onClick={onConfirm}
          loading={!!loading}
        >
          Apply Credit
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

function ScreenToolbar({match, loading, onNewWorkorder}) {
  let [anchorEl, setAnchorEl] = useState(null)

  return (
    <React.Fragment>
      <Toolbar>
        <ToolbarLeft style={{justifyContent: 'flex-start'}}>
          <Route
            path={`${match.path}/:tab`}
            children={({match: tabMatch}) => {
              let {params = {}} = tabMatch || {}
              let tabsMap = {
                manage: 1,
                voided: 2,
              }
              let activeTab = tabsMap[params.tab] || 0

              return (
                <>
                  <Button
                    onClick={e => {
                      setAnchorEl(e.currentTarget)
                    }}
                    component="nav"
                    style={{textTransform: 'none'}}
                  >
                    {params.tab === 'manage' ? (
                      <>
                        <Assignment />
                        <Space inline />
                        <Typography variant="h6">Manage Workorders</Typography>
                      </>
                    ) : (
                      <>
                        <Cancel />
                        <Space inline />
                        <Typography variant="h6">Voided Workorders</Typography>
                      </>
                    )}
                    <Space inline />
                    <ArrowDropDown />
                  </Button>
                  <Menu
                    id="manage-workorders-nav-menu"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={() => {
                      setAnchorEl(null)
                    }}
                  >
                    <MenuItem
                      selected={params.tab === 'manage'}
                      component={Link}
                      to="/manageworkorders/manage"
                      onClick={() => setAnchorEl(null)}
                    >
                      <Assignment />
                      <Space inline />
                      Manage Workorders
                    </MenuItem>
                    <MenuItem
                      selected={params.tab === 'voided'}
                      component={Link}
                      to="/manageworkorders/voided"
                      onClick={() => setAnchorEl(null)}
                    >
                      <Cancel />
                      <Space inline />
                      Voided Workorders
                    </MenuItem>
                  </Menu>
                </>
              )
            }}
          />
        </ToolbarLeft>
        <Space inline size={3} />
        <Fab color="secondary" onClick={onNewWorkorder}>
          <Typography variant="subtitle2">+</Typography>
          <Assignment />
        </Fab>
      </Toolbar>
      {loading && <LinearProgress />}
    </React.Fragment>
  )
}

function munge(params) {
  let {date, route_id} = params
  date = moment(date || new Date())
    .startOf('day')
    .toDate()

  route_id = route_id ? String(route_id) : '1'

  return {date, route_id}
}

let getKeysFromRow = row => {
  if (!row) return {woKey: null, inv: null}

  let {workorder, invoice, schedule} = row

  let woKey = workorder
    ? `wo-${workorder.id}`
    : schedule
    ? `wo-s-${schedule.id}`
    : null
  let invKey = invoice
    ? `inv-${invoice.id}`
    : !workorder && schedule
    ? `inv-s-${schedule.id}`
    : `inv-wo-${workorder.id}`

  let rowKey = ''
  if (invoice) rowKey = `r-inv-${invoice.id}`
  if (workorder) rowKey = `r-wo-${workorder.id}`
  if (schedule)
    rowKey = `r-s-${schedule.id}${workorder && '-wo-' + workorder.id}`

  return {woKey, invKey, rowKey}
}

let checkAllRows = (val, displayRows = []) => {
  return displayRows.reduce((carry, row) => {
    let {rowKey} = getKeysFromRow(row)
    carry[rowKey] = val
    return carry
  }, {})
}

let checkedKeys = values => {
  let checked = []
  for (let key in values) {
    if (values[key]) {
      checked.push(key)
    }
  }
  return checked
}

let filterRowDataByKeys = (rows, keys) => {
  return rows.filter(row => {
    let {rowKey} = getKeysFromRow(row)
    return keys.includes(rowKey)
  })
}

let make_workorders_filter = r => !r.workorder && !r.invoice && !!r.schedule
let open_workorders_filter = r =>
  !!r.workorder && r.workorder.status === 'COMPLETE'
let complete_workorders_filter = r =>
  !!r.workorder && r.workorder.status === 'OPEN'
let void_workorders_filter = r => !!r.workorder && r.workorder.status !== 'VOID'
let print_workorders_filter = r => !!r.workorder
let print_invoices_filter = r => !!r.workorder
let make_invoices_filter = r =>
  !!r.workorder && r.workorder.status === 'COMPLETE' && !r.invoice
let void_invoices_filter = r => !!r.invoice && r.invoice.status !== 'VOID'
let paid_invoices_filter = r => !!r.invoice && r.invoice.status === 'PAID'
let pay_invoices_filter = r => !!r.invoice && r.invoice.status === 'OPEN'
let credit_invoices_filter = r =>
  !!r.invoice && r.invoice.status === 'OPEN' && r.customer.credit_items.length
let any_invoices_filter = r => !!r.invoice

let ManageWorkorders = ({match, location}) => {
  let [queryChanges, setQueryChanges] = useState(
    queryString.parse(location.search),
  )
  let [wo, setWo] = useState({})
  let [edit, setEdit] = useState(false)
  let [manageMode, setManageMode] = useState(false)
  let [checks, setChecks] = useState({})
  let [lastCheck, setLastCheck] = useState(undefined)
  let [openCashConfirm, setOpenCashConfirm] = useState(false)
  let [openCreditConfirm, setOpenCreditConfirm] = useState(false)
  let [dangerousActionsLoading, setDangerousActionsLoading] = useState(false)

  let {status: canEditDeleteWorkorderitems} = useAuth('void invoice')
  let disableVoidInvoice = canEditDeleteWorkorderitems !== 'authorized'

  let params = queryString.parse(location.search)
  let {date, route_id} = munge(params)

  let {data, isLoading, refetch} = useQuery(
    ['manage-workorder', moment.utc(date).format('YYYY-MM-DD'), route_id],
    () => {
      return prgql({
        query: manageQuery,
        variables: {
          date: moment.utc(date).format('YYYY-MM-DD'),
          route_id: route_id,
          route_id_int: Number.parseInt(route_id),
        },
      })
    },
  )
  let {data: tipsData, isLoading: tipsLoading, refetch: refetchTips} = useQuery(
    ['tips', moment.utc(date).format('YYYY-MM-DD'), route_id],
    () => {
      return prgql({
        query: tipsQuery,
        variables: {
          date: moment.utc(date).format('YYYY-MM-DD'),
          route_id: route_id,
        },
      })
    },
  )
  const queryClient = useQueryClient()

  let {mutateAsync: genWorkorders, isLoading: genWoIsLoading} = useMutation(
    variables =>
      prgql({
        query: generateWorkordersMutation,
        variables: variables,
      }),
    {
      mutationKey: 'genWo',
      onSuccess: () => queryClient.invalidateQueries('manage-workorder'),
    },
  )

  let {mutateAsync: genInvoices, isLoading: genInvIsLoading} = useMutation(
    variables =>
      prgql({
        query: generateInvoicesMutation,
        variables: variables,
      }),
    {
      mutationKey: 'genInv',
      onSuccess: () => queryClient.invalidateQueries('manage-workorder'),
    },
  )

  let {
    mutateAsync: batchWorkorders,
    isLoading: batchWorkordersLoading,
  } = useMutation(
    variables =>
      prgql({
        query: batchWorkordersMutation,
        variables: variables,
      }),
    {
      mutationKey: 'batchWo',
      onSuccess: () => queryClient.invalidateQueries('manage-workorder'),
    },
  )

  let {
    mutateAsync: batchInvoices,
    isLoading: batchInvoicesLoading,
  } = useMutation(
    variables =>
      prgql({
        query: batchInvoicesMutation,
        variables: variables,
      }),
    {
      mutationKey: 'batchInv',
      onSuccess: () => queryClient.invalidateQueries('manage-workorder'),
    },
  )

  let {mutateAsync: payInvoices, isLoading: payInvoicesLoading} = useMutation(
    variables =>
      prgql({
        query: payInvoicesMutation,
        variables: variables,
      }),
    {
      mutationKey: 'payInv',
      onSuccess: () => queryClient.invalidateQueries('manage-workorder'),
    },
  )

  let {
    mutateAsync: creditInvoices,
    isLoading: creditInvoicesLoading,
  } = useMutation(
    variables =>
      prgql({
        query: creditInvoicesMutation,
        variables: variables,
      }),
    {
      mutationKey: 'creditInv',
      onSuccess: () => queryClient.invalidateQueries('manage-workorder'),
    },
  )

  let {mutateAsync: createTips, isLoading: createTipsLoading} = useMutation(
    variables =>
      prgql({
        query: tipsCreateMutation,
        variables: variables,
      }),
    {
      mutationKey: 'createTips',
      onSuccess: () => queryClient.invalidateQueries('tips'),
    },
  )

  let {mutateAsync: updateTips, isLoading: updateTipsLoading} = useMutation(
    variables =>
      prgql({
        query: tipsUpdateMutation,
        variables: variables,
      }),
    {
      mutationKey: 'updateTips',
      onSuccess: () => queryClient.invalidateQueries('tips'),
    },
  )

  let {mutateAsync: deleteTips, isLoading: deleteTipsLoading} = useMutation(
    variables =>
      prgql({
        query: tipsDeleteMutation,
        variables: variables,
      }),
    {
      mutationKey: 'deleteTips',
      onSuccess: () => queryClient.invalidateQueries('tips'),
    },
  )

  let _makeWorkordersAction = async () => {
    let schedule_ids = filterRowDataByKeys(rows, checkedKeys(checks))
      .filter(make_workorders_filter)
      .map(({schedule}) => schedule.id)

    let data = await genWorkorders({
      date: moment.utc(date).format('YYYY-MM-DD'),
      route_id: route_id,
      ids: schedule_ids,
    })

    // safer this way
    setChecks({})

    await refetch()
  }

  let _completeWorkordersAction = async () => {
    let workorders = filterRowDataByKeys(rows, checkedKeys(checks))
      .filter(complete_workorders_filter)
      .map(({workorder}) => ({
        id: workorder.id,
        status: 'COMPLETE',
        completed_at: moment.utc(workorder.assigned_at).format('YYYY-MM-DD'),
      }))

    await batchWorkorders({
      input: workorders,
    })
    await refetch()
  }

  let _openWorkordersAction = async () => {
    let workorders = filterRowDataByKeys(rows, checkedKeys(checks))
      .filter(open_workorders_filter)
      .map(({workorder}) => ({id: workorder.id, status: 'OPEN'}))

    await batchWorkorders({
      input: workorders,
    })
    await refetch()
  }

  let _voidWorkordersAction = async () => {
    let workorders = filterRowDataByKeys(rows, checkedKeys(checks))
      .filter(void_workorders_filter)
      .map(({workorder}) => ({id: workorder.id, status: 'VOID'}))

    await batchWorkorders({
      input: workorders,
    })
    await refetch()
  }

  let _makeInvoicesAction = async () => {
    let workorderIds = filterRowDataByKeys(rows, checkedKeys(checks))
      .filter(make_invoices_filter)
      .map(({workorder}) => workorder.id)

    await genInvoices({
      ids: workorderIds,
    })

    await refetch()
  }

  let _voidInvoicesAction = async () => {
    let invoices = filterRowDataByKeys(rows, checkedKeys(checks))
      .filter(void_invoices_filter)
      .map(({invoice}) => ({id: invoice.id, status: 'VOID'}))

    await batchInvoices({
      input: invoices,
    })
    await refetch()
  }

  let _payInvoicesWithCashAction = async () => {
    let invoiceIds = filterRowDataByKeys(rows, checkedKeys(checks))
      .filter(pay_invoices_filter)
      .map(({invoice}) => invoice.id)

    console.log('DOOOD')
    return payInvoices({
      input: {
        ids: invoiceIds,
      },
    }).then(() => {
      console.log('hey yo, looks like we got past the pay invoices thing')
      refetch()
    })
  }

  let _payInvoicesWithCreditAction = async () => {
    let invoiceIds = filterRowDataByKeys(rows, checkedKeys(checks))
      .filter(pay_invoices_filter)
      .map(({invoice}) => invoice.id)

    await creditInvoices({
      input: {
        ids: invoiceIds,
      },
    })
    return refetch()
  }

  let _restoreAction = async values => {
    let openValidWorkorders =
      data && data.wos
        ? data.wos.edges
            .map(e => e.edge)
            .filter(w => w.status === 'VOID')
            .map(w => w.id)
        : []
    let inactiveValidInvoices =
      data && data.invs
        ? data.invs.edges
            .map(e => e.edge)
            .filter(w => w.status === 'VOID')
            .map(w => w.id)
        : []

    let workorders = values
      .filter(v => /^wo-[0-9]+$/.test(v))
      .map(v => v.replace('wo-', ''))
      .filter(v => openValidWorkorders.includes(v))
      .map(v => ({id: v, status: 'OPEN'}))
    let invoices = values
      .filter(v => /^inv-[0-9]+$/.test(v))
      .map(v => v.replace('inv-', ''))
      .filter(v => inactiveValidInvoices.includes(v))
      .map(v => ({id: v, status: 'OPEN'}))

    await batchWorkorders({
      input: workorders,
    })
    await batchInvoices({
      input: invoices,
    })
    await refetch()
  }

  let _printAction = () => {
    let workorderIds = filterRowDataByKeys(rows, checkedKeys(checks))
      .filter(print_workorders_filter)
      .map(({workorder}) => workorder.id)
      .join(',')

    let day = data && data.run ? data.run.edges[0].edge : false
    if (!day) return

    print(`/routeruns/${day.id}/print?workorder_ids=${workorderIds}`)
  }

  let _printInvAction = () => {
    let invoiceIds = filterRowDataByKeys(rows, checkedKeys(checks))
      .filter(print_invoices_filter)
      .map(({invoice}) => invoice.id)
      .join(',')

    let day = data && data.run ? data.run.edges[0].edge : false
    if (!day) return

    print(`/invoices/print?invoice_ids=${invoiceIds}`)
  }

  let day =
    data && data.run && data.run.edges && data.run.edges.length
      ? data.run.edges[0].edge
      : false
  let workorders =
    data && data.wos
      ? data.wos.edges.map(e => e.edge).filter(w => w.status !== 'VOID')
      : []

  let generatedScheduleIds = workorders
    .filter(i => !['VOID'].includes(i.status))
    .map(wo => (wo.schedule ? [wo.schedule.id] : []))
    .flat()
  let ungeneratedSchedules = day
    ? day.schedules.filter(s => !generatedScheduleIds.includes(s.id))
    : []
  let generatedInvoiceIds = workorders.map(wo => wo.invoice_id)

  let rows = []

  workorders.map(wo =>
    rows.push({
      workorder: wo,
      invoice: wo.invoice && wo.invoice.status !== 'VOID' ? wo.invoice : null,
      schedule: wo.schedule,
      customer: wo.customer,
      sequence: wo.sequence,
    }),
  )
  ungeneratedSchedules.map(s =>
    rows.push({
      workorder: null,
      invoice: null,
      schedule: s,
      customer: s.customer,
      sequence: s.sequence,
    }),
  )
  rows = rows.map(row => {
    let {rowKey} = getKeysFromRow(row)
    row['rowKey'] = rowKey
    return row
  })

  let allRowsChecked = true
  for (let k in checkAllRows(true, rows)) {
    if (!checks[k]) {
      allRowsChecked = false
      break
    }
  }
  let someRowsChecked = false
  for (let k in checks) {
    if (checks[k]) {
      someRowsChecked = true
      break
    }
  }

  let tips = tipsData && tipsData.allTips ? tipsData.allTips.edges : []

  let _availableActions = rows => {
    if (!rows || !rows.length) return {}

    let config = {}

    config['make-workorders'] = !!rows.filter(make_workorders_filter).length
    config['open-workorders'] = !!rows.filter(open_workorders_filter).length
    config['complete-workorders'] = !!rows.filter(complete_workorders_filter)
      .length
    config['void-workorders'] =
      !!rows.filter(void_workorders_filter).length &&
      !rows.filter(any_invoices_filter).length

    config['make-invoices'] = !!rows.filter(make_invoices_filter).length
    config['void-invoices'] =
      !!rows.filter(void_invoices_filter).length &&
      !rows.filter(paid_invoices_filter).length &&
      !disableVoidInvoice
    config['pay-invoices'] = !!rows.filter(pay_invoices_filter).length
    config['credit-invoices'] = !!rows.filter(credit_invoices_filter).length
    config['print-workorders'] = !!rows.filter(print_workorders_filter).length
    config['print-invoices'] = !!rows.filter(print_invoices_filter).length

    return config
  }

  return (
    <React.Fragment>
      <ScreenToolbar
        match={match}
        loading={isLoading || genInvIsLoading || genWoIsLoading}
        onNewWorkorder={() => {
          setWo({
            status: 'OPEN',
            assigned_at: moment(date).format('YYYY-MM-DD'),
            route_id,
          })
          setEdit(true)
        }}
        manageMode={manageMode}
        onManageMode={e => setManageMode(e.target.checked)}
      />
      <Switch>
        <Route
          path={`${match.path}/manage`}
          exact
          render={() => (
            <Manage
              onSubmitTips={async ({creates, updates, deletes}) => {
                // console.log('creates', creates)
                // console.log('updates', updates)
                // console.log('deletes', deletes)

                let requests = []
                for (const item of creates) {
                  requests.push(
                    createTips({
                      ...item,
                      route_id,
                      collected_at: moment(date).format('YYYY-MM-DD'),
                    }),
                  )
                }

                for (const item of deletes) {
                  requests.push(deleteTips({...item}))
                }

                if (updates.length) {
                  requests.push(updateTips({input: updates}))
                }
                console.log(requests)

                await Promise.all(requests)
                // refetch();
                // setTips(false);
              }}
              tips={tips}
              day={day}
              rows={rows}
              date={date}
              onChangeDate={date => {
                setQueryChanges({
                  ...queryChanges,
                  date: moment(date)
                    .utc()
                    .startOf('day')
                    .format('YYYY-MM-DD'),
                })
                setChecks({})
              }}
              routeId={route_id}
              onChangeRoute={route => {
                setQueryChanges({
                  ...queryChanges,
                  route_id: route.id,
                })
                setChecks({})
              }}
              onClickWo={wo => {
                setWo(wo)
                setEdit(true)
              }}
              onMakeWorkordersAction={_makeWorkordersAction}
              onCompleteWorkordersAction={_completeWorkordersAction}
              onOpenWorkordersAction={_openWorkordersAction}
              onVoidWorkordersAction={_voidWorkordersAction}
              onMakeInvoicesAction={_makeInvoicesAction}
              onVoidInvoicesAction={_voidInvoicesAction}
              // onPayInvoicesWithCashAction={_payInvoicesWithCashAction}
              // onPayInvoicesWithCreditAction={_payInvoicesWithCreditAction}
              onPayInvoicesWithCashAction={() => setOpenCashConfirm(true)}
              onPayInvoicesWithCreditAction={() => setOpenCreditConfirm(true)}
              onPrintAction={_printAction}
              onPrintInvAction={_printInvAction}
              actionsLoading={
                genInvIsLoading ||
                genWoIsLoading ||
                batchInvoicesLoading ||
                batchWorkordersLoading ||
                payInvoicesLoading
              }
              onCheckAll={e =>
                someRowsChecked && !allRowsChecked
                  ? setChecks({})
                  : setChecks({
                      ...checks,
                      ...checkAllRows(e.target.checked, rows),
                    })
              }
              allRowsChecked={allRowsChecked}
              someRowsChecked={someRowsChecked}
              checks={checks}
              onCheck={({e, rowKey, sortedRowKeys}) =>
                multicheck({
                  e,
                  rowKey,
                  sortedRowKeys,
                  checks,
                  lastCheck,
                  onSetChecks: c => setChecks(c),
                  onSetLastCheck: k => setLastCheck(k),
                })
              }
              availableActions={_availableActions(
                filterRowDataByKeys(rows, checkedKeys(checks)),
              )}
            />
          )}
        />
        <Route
          path={`${match.path}/voided`}
          exact
          render={() => (
            <Voided
              data={data}
              date={date}
              onChangeDate={date =>
                setQueryChanges({
                  ...queryChanges,
                  date: moment(date)
                    .utc()
                    .startOf('day')
                    .format('YYYY-MM-DD'),
                })
              }
              routeId={route_id}
              onChangeRoute={route =>
                setQueryChanges({
                  ...queryChanges,
                  route_id: route.id,
                })
              }
              onRestoreAction={_restoreAction}
              disableActions={
                genInvIsLoading ||
                genWoIsLoading ||
                batchInvoicesLoading ||
                batchWorkordersLoading
              }
            />
          )}
        />
      </Switch>
      <ModifyQueryParams query={queryChanges} />
      <EditWorkorder
        key={wo.id}
        wo={wo}
        open={edit}
        onSave={refetch}
        onClose={() => setEdit(false)}
        showMultidayVersion={!!wo.multiday}
        showStatusChange={true}
        initialTab={wo.id ? 'preview' : 'edit'}
      />
      <ConfirmPayCash
        open={openCashConfirm}
        selectedRows={filterRowDataByKeys(rows, checkedKeys(checks)).filter(
          pay_invoices_filter,
        )}
        onCancel={() => setOpenCashConfirm(false)}
        onConfirm={async () => {
          setDangerousActionsLoading(true)
          console.log('sldkjf')
          await _payInvoicesWithCashAction()
          console.log('aaaaa')
          setDangerousActionsLoading(false)
          setOpenCashConfirm(false)
        }}
        loading={dangerousActionsLoading}
      />
      <ConfirmApplyCredit
        open={openCreditConfirm}
        selectedRows={filterRowDataByKeys(rows, checkedKeys(checks)).filter(
          credit_invoices_filter,
        )}
        onCancel={() => setOpenCreditConfirm(false)}
        onConfirm={async () => {
          setDangerousActionsLoading(true)
          await _payInvoicesWithCreditAction()
          setDangerousActionsLoading(false)
          setOpenCreditConfirm(false)
        }}
        loading={dangerousActionsLoading}
      />
    </React.Fragment>
  )
}

export default ManageWorkorders
