import React, { Component } from 'react'
import styled from 'styled-components'
import { graphql } from 'react-apollo'
import compose from 'lodash/flowRight'
import gql from 'graphql-tag'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import FormGroup from '@mui/material/FormGroup'
import ModifyQueryParams from '../../components/ModifyQueryParams'
import moment from 'moment'
import queryString from 'query-string'
import { formatMoneyStandard } from '../../utils/moneyFormatter'
import { LinearProgress, Typography } from '@mui/material'
import { RouteSelectStatic } from '../../components/RouteSelect'
import { Space } from '../../components/Layout'

let DashboardWrapper = styled.div`
  padding: 16px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 16px;
`

let BoldText = styled.div`
  font-weight: bold;
`

let TopCell = styled(TableCell).attrs(() => ({ classes: { root: 'root' } }))`
  &.root {
    background-color: #222;
    color: white;
  }
`

let VariedRow = styled(TableRow)`
  &:nth-of-type(odd) {
    background-color: #fafafa;
  }
`

class TheSelect extends Component {
  render() {

    let val = {
      ...this.props.defaultSelected,
      year: moment().format('YYYY'),
      route: 1,
      ...this.state
    }

    return (
      <Card>
        <CardContent>
          <FormGroup row style={{ display: 'flex', alignItems: 'center' }}>
            <RouteSelectStatic
              value={val.route}
              onChange={e => this.setState({
                route: e.target.value,
              })}
              showAll={false}
            />
            <Space inline sizePx={20} />
            <TextField
              variant="outlined"
              size="small"
              type="number"
              label="Year"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={e =>
                this.setState({
                  year: e.target.value,
                })
              }
              value={val.year}
            />
            <Space inline sizePx={20} />
            <Button
              variant="contained"
              color="primary"
              onClick={() =>
                this.props.onClick(val.route, val.year)
              }
            >
              Go
            </Button>
          </FormGroup>
        </CardContent>
      </Card>
    );
  }
}

// let delinquents = ({type}) => type === 'Delinquent'
// let sales = ({type}) => type === 'Sales'
// let notSalesOrDeliqunets = ({type}) => !delinquents({type}) && !sales({type})
class StatisticsRoute extends Component {
  state = {
    queryChanges: {},
    today: moment().format('YYYY-MM-DD'),
  }
  render() {
    let { data, location } = this.props
    let statistics = data?.allStatistics?.edges
    let params = queryString.parse(location.search)

    return (
      <DashboardWrapper>
        <Paper>
          <Typography variant="subtitle2" display="block" style={{ opacity: 0.5 }}>Sales & job count derived from invoice subtotals. Vehicle overhead is no longer tracked. Payroll is derived from timestation.</Typography>
          <TheSelect
            defaultSelected={{ year: params.year, route: params.route }}
            onClick={(route, year) =>
              this.setState({
                queryChanges: {
                  year: year,
                  route: route,
                },
              })
            }
          />
          <ModifyQueryParams query={this.state.queryChanges} />
          {data?.loading && <LinearProgress />}
          {statistics ? (

            <Table>
              <TableHead>
                <TableRow>
                  <TopCell>Route</TopCell>
                  <TopCell>Week</TopCell>
                  <TopCell>Date</TopCell>
                  <TopCell>Sales</TopCell>
                  <TopCell>Number Jobs</TopCell>
                  <TopCell>Avg. Sales per Job</TopCell>
                  <TopCell>Miles</TopCell>
                  <TopCell>CPM</TopCell>
                  <TopCell>Vehicle Overhead</TopCell>
                  <TopCell>Captain</TopCell>
                  <TopCell>Man Hours</TopCell>
                  <TopCell>ST Hrs</TopCell>
                  <TopCell>OT Hrs</TopCell>
                  <TopCell>ST Pay</TopCell>
                  <TopCell>OT Pay</TopCell>
                  <TopCell>Payroll</TopCell>
                  <TopCell>Pr/S</TopCell>
                  <TopCell>Sales per Man Hour</TopCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {statistics.map(({ edge: r }, i) => {
                  return (
                    <VariedRow key={r.id}>
                      <TableCell align="right">
                        <BoldText>{r.route}</BoldText>
                      </TableCell>
                      <TableCell align="right">
                        <BoldText>{r.week}</BoldText>
                      </TableCell>
                      <TableCell>
                        <BoldText>{r.date}</BoldText>
                      </TableCell>
                      <TableCell align="right">
                        {formatMoneyStandard(r.sales)}
                      </TableCell>
                      <TableCell align="right">{r.number_jobs}</TableCell>
                      <TableCell align="right">
                        {formatMoneyStandard(r.average_sales_per_job)}
                      </TableCell>
                      <TableCell align="right">{r.miles}</TableCell>
                      <TableCell align="right">
                        {formatMoneyStandard(r.cpm)}
                      </TableCell>
                      <TableCell align="right">
                        {formatMoneyStandard(r.vehicle_overhead)}
                      </TableCell>
                      <TableCell>{r.captain}</TableCell>
                      <TableCell align="right">{r.man_hours}</TableCell>
                      <TableCell align="right">{r.standard_hours}</TableCell>
                      <TableCell align="right">{r.overtime_hours}</TableCell>
                      <TableCell align="right">
                        {formatMoneyStandard(r.standard_payroll)}
                      </TableCell>
                      <TableCell align="right">
                        {formatMoneyStandard(r.overtime_payroll)}
                      </TableCell>
                      <TableCell align="right">
                        {formatMoneyStandard(r.total_payroll)}
                      </TableCell>
                      <TableCell>{r.payroll_over_sales}</TableCell>
                      <TableCell align="right">
                        {formatMoneyStandard(r.sales_per_man_hour)}
                      </TableCell>
                    </VariedRow>
                  )
                })}
              </TableBody>
            </Table>
          ) : <></>}
        </Paper>
      </DashboardWrapper>
    )
  }
}
let query = gql`
  query statistics($filters: StatisticsFilters = {}) {
    allStatistics(filters: $filters) {
      edges {
        edge {
          id
          route
          week
          date
          sales
          number_jobs
          average_sales_per_job
          miles
          cpm
          vehicle_overhead
          captain
          man_hours
          standard_hours
          overtime_hours
          standard_payroll
          overtime_payroll
          total_payroll
          payroll_over_sales
          sales_per_man_hour
        }
      }
    }
  }
`
export default compose(
  graphql(query, {
    options: props => {
      let params = queryString.parse(props.location.search)

      return {
        variables: {
          filters: {
            route: parseInt(params.route, 10) || 1,
            year: parseInt(params.year, 10) || moment().year(),
          },
        },
      }
    },
  }),
)(StatisticsRoute)
