import React, { Component, useState } from 'react'
import gql from 'graphql-tag'

import { Autocomplete, useAutoCompleteEndpoint } from './AutoComplete'
import { ListItem, TextField } from '@mui/material'

let gqlFragment = gql`
  fragment City on Cities {
    id
    name
    county
  }
`

export const CitySelect = ({
  selectedId,
  idKey = 'city',
  label = "Cities",
  name,
  inputProps = {},
  ...props
}) => {
  let autoProps = useAutoCompleteEndpoint({
    gqlFragment,
    selectedId,
    searchField: 'name',
  })

  return (
    <Autocomplete
      {...autoProps}
      style={{ flex: 1 }}
      getOptionLabel={u => (u ? `${u.name}` : '')}
      renderInput={(params) => <TextField {...params} {...inputProps} label={label} />}
      renderOption={(props, option) => <ListItem {...props} id={`${idKey}-${option.id}`} key={option.id}>{option.name}</ListItem>}
      {...props}
    />
  )
}

