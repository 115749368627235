import React, {useState, useRef, useEffect} from 'react'
import NavigationArrowBack from '@mui/icons-material/ArrowBack'
import styled from 'styled-components'
import gql from 'graphql-tag'
import url from 'url'

import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'
import {
  mungeFormikRelationshipAssociate,
  mungeFormikRelationshipUpdate,
} from '../../utils'
import {
  AccordionSummary,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Tooltip,
  Dialog,
  Paper,
} from '@mui/material'
import {
  Add,
  ChevronRight,
  Create,
  Delete,
  Description,
  Edit,
  ExpandMore,
  More,
  People,
  Person,
} from '@mui/icons-material'
import theme, {PRThemeProvider} from '../../styles/theme'
import {useMutation, useQuery, useQueryClient} from 'react-query'
import {prgql} from '../../utils/graphql'
import * as Yup from 'yup'
import {FieldArray, Formik} from 'formik'
import {
  AutoCompleteField,
  PhoneNumber,
  StaticSelect,
  TextField,
} from '../../components/forms'
import AccountSelect from '../../components/AccountSelect'
import {CitySelect} from '../../components/CitySelect'
import STATES from '../../constants/States'
import LoadingButton from '../../components/LoadingButton'
import CustomerSelect from '../../components/CustomerSelect'
import {DocumentNode} from 'graphql'
import {ContactDetail} from './Contact'
import {SalesstageSelect} from '../../components/apiSelects'
import UsersSelect, {UserSelectDynamic} from '../../components/UsersSelect'

const contactEditMutation: DocumentNode = gql`
  mutation UpdateContact($input: UpdateContactsInput) {
    contacts: updateContacts(input: $input) {
      contacts {
        id
      }
    }
  }
`
const contactCreateMutation: DocumentNode = gql`
  mutation CreateContacts($input: CreateContactsInput) {
    contacts: createContacts(input: $input) {
      contacts {
        id
      }
    }
  }
`

// extract this into a separate file if you end up making a proper detail page.
const StyledDialogTitle = styled(DialogTitle)`
  background-color: ${theme.palette.info.medium};
`
const StyledDialogContent = styled(DialogContent)`
  background-color: ${theme.palette.info.medium};
`
const StyledDialogActions = styled(DialogActions)`
  background-color: ${theme.palette.info.medium};
`

const ContainerPaper = styled(Paper)`
  padding: 1em;
  margin-bottom: 1em;
`

const Content = styled(Grid)`
  padding: 0px 50px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const EditDetails: React.FC<{
  open: boolean,
  contactId?: string,
  onSubmit: (id: string) => void,
  onCancel: () => void,
  onViewEdit: () => void,
  isLoading?: boolean,
  isError?: boolean,
  data?: Record<string, any>,
  mode: 'view' | 'edit',
}> = function({
  open,
  contactId,
  onSubmit,
  onCancel,
  onViewEdit,
  isLoading,
  isError,
  data,
  mode = 'edit',
}) {
  const {mutateAsync: update} = useMutation((variables: {[key: string]: any}) =>
    prgql({
      query: contactEditMutation,
      variables: {input: {id: contactId, ...variables}},
    }),
  )

  const {mutateAsync: create} = useMutation((variables: {[key: string]: any}) =>
    prgql({
      query: contactCreateMutation,
      variables: {input: {...variables}},
    }),
  )

  const c = data || {}
  const formikValues = {
    emails: [],
    phones: [],
    customers: [],
    accounts: [],
    salesstages: [],
    region: '',
    ...c,
  }

  // to assist with tabbing through photos and emails
  const [autofocusPhone, setAutofocusPhone] = useState(null)
  const [autofocusEmail, setAutofocusEmail] = useState(null)
  const [editingEmailNotes, setEditingEmailNotes] = useState(null)
  const [showExpandedName, setShowExpandedName] = useState(
    c.prefix || c.middle_name || c.suffix,
  )

  const fixPhoneNumber = phone => {
    const number = phone.number?.replace('+1', '').replace(/\s+/g, '')
    return {...phone, number}
  }

  const _mungeFormikUpdate = (values: {
    [k: string]: any,
  }): {[k: string]: any} => {
    const fields = [
      'id',
      'prefix',
      'first_name',
      'middle_name',
      'last_name',
      'suffix',
      'street1',
      'city_id',
      'region',
      'postcode',
      'notes',
      'sales_person_id',
    ]

    const formValues = {}

    for (const prop of fields) {
      let val = values[prop]
      if (prop === 'region') {
        val = val.toUpperCase()
      }
      formValues[prop] = val
    }

    return formValues
  }

  const schema = Yup.object().shape({
    first_name: Yup.string(),
    last_name: Yup.string(),
    street1: Yup.string(),
    city_id: Yup.string(),
    region: Yup.string(),
    postcode: Yup.string().min(5),
    notes: Yup.string(),
    sales_person_id: Yup.string(),

    phones: Yup.array()
      .of(
        Yup.object().shape({
          label: Yup.string().required('Label required'),
          number: Yup.string().required('Number required'),
          phonetype: Yup.number()
            .required('Select a phone type')
            .oneOf([1, 2, 3]),
        }),
      )
      .when('emails', (emails: any, schema: any) =>
        !emails || emails.length === 0
          ? schema.min(1, 'Must have a phone or an email')
          : schema.min(0),
      ),
    emails: Yup.array().of(
      Yup.object().shape(
        {
          email: Yup.string()
            // Yup busted?
            // .matches(EMAIL_REGEX, "Doesn't look like an email")
            .required('Email required'),
          label: Yup.string().required('Email label required'),
        },
        'Not valid',
      ),
    ),
    customers: Yup.array().of(
      Yup.object().shape({
        id: Yup.string().required('Select Customer'),
      }),
    ),
    accounts: Yup.array().of(
      Yup.object().shape({
        id: Yup.string().required('Select Account'),
      }),
    ),
  })

  return (
    <Dialog open={!!open} maxWidth="xl" fullWidth>
      {isLoading || isError ? (
        <>
          <DialogTitle>Loading Contact Details</DialogTitle>
          <DialogContent>
            {isLoading && (
              <Grid container spacing={2}>
                <CircularProgress />
              </Grid>
            )}
            {isError && 'Something broke, please refresh'}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                onCancel()
              }}
            >
              Cancel
            </Button>
          </DialogActions>
        </>
      ) : mode === 'view' ? (
        <>
          <DialogTitle>Contact Details</DialogTitle>
          <DialogContent>
            <Content>
              <Typography variant="h6">
                {c.first_name} {c.last_name}
              </Typography>
              <IconButton onClick={onViewEdit}>
                <Edit />
              </IconButton>
            </Content>
            <ContactDetail data={c} />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                onCancel()
              }}
            >
              Close
            </Button>
          </DialogActions>
        </>
      ) : (
        <>
          <Formik
            initialValues={formikValues}
            validationSchema={schema}
            initialErrors={{name: 'Please fill out the form'}}
            onSubmit={async (values, {setSubmitting}) => {
              setSubmitting(true)
              const detailFields = _mungeFormikUpdate(values)
              const phoneProps = mungeFormikRelationshipUpdate({
                relname: 'phones',
                reldata: values.phones?.map(fixPhoneNumber),
                origData: c,
              })
              const emailProps = mungeFormikRelationshipUpdate({
                relname: 'emails',
                reldata: values.emails,
                origData: c,
              })
              const customerProps = mungeFormikRelationshipAssociate({
                relname: 'customers',
                reldata: values.customers,
                origData: c,
              })
              const accountsProps = mungeFormikRelationshipAssociate({
                relname: 'accounts',
                reldata: values.accounts,
                origData: c,
              })
              const salesstages = mungeFormikRelationshipAssociate({
                relname: 'salesstages',
                reldata: values.salesstages,
                origData: c,
              })

              // console.log(
              //   detailFields,
              //   phoneProps,
              //   emailProps,
              //   customerProps,
              //   accountsProps,
              // )

              let res
              if (contactId) {
                res = await update({
                  ...detailFields,
                  ...phoneProps,
                  ...emailProps,
                  ...customerProps,
                  ...accountsProps,
                  ...salesstages,
                })
              } else {
                res = await create({
                  ...detailFields,
                  ...phoneProps,
                  ...emailProps,
                  ...customerProps,
                  ...accountsProps,
                  ...salesstages,
                })
              }

              onSubmit(res.contacts?.contacts?.id)
            }}
          >
            {({
              isSubmitting,
              submitForm,
              isValid,
              values,
              setFieldValue,
              errors,
            }) => {
              // console.log(errors)
              //
              const expandedNameFieldsUsed =
                values.prefix || values.middle_name || values.suffix
              return (
                <>
                  <StyledDialogTitle>Edit Contact Details</StyledDialogTitle>
                  <StyledDialogContent>
                    <ContainerPaper>
                      <Grid container spacing={2}>
                        <Grid
                          item
                          container
                          lg={6}
                          spacing={1}
                          alignContent="center"
                        >
                          <Grid item lg={12}>
                            <Typography variant="h6">Details</Typography>
                          </Grid>
                          <Grid item container lg={12} gap={0.5}>
                            {showExpandedName || expandedNameFieldsUsed ? (
                              <TextField
                                label="Prefix"
                                name="prefix"
                                variant="filled"
                                size="small"
                                sx={{maxWidth: '60px'}}
                              />
                            ) : (
                              <></>
                            )}
                            <TextField
                              label="First Name"
                              name="first_name"
                              variant="filled"
                              size="small"
                              sx={{
                                flex: 1,
                              }}
                            />
                            {showExpandedName || expandedNameFieldsUsed ? (
                              <TextField
                                label="Middle Name"
                                name="middle_name"
                                variant="filled"
                                size="small"
                                sx={{
                                  flex: 1,
                                }}
                              />
                            ) : (
                              <></>
                            )}
                            <TextField
                              label="Last Name"
                              name="last_name"
                              variant="filled"
                              size="small"
                              sx={{
                                flex: 1,
                              }}
                            />
                            {showExpandedName || expandedNameFieldsUsed ? (
                              <TextField
                                label="Suffix"
                                name="suffix"
                                variant="filled"
                                size="small"
                                sx={{maxWidth: '60px'}}
                              />
                            ) : (
                              <></>
                            )}
                            <IconButton
                              onClick={() =>
                                setShowExpandedName(!showExpandedName)
                              }
                              disabled={
                                values.prefix ||
                                values.middle_name ||
                                values.suffix
                              }
                            >
                              <More />
                            </IconButton>
                          </Grid>
                          <Grid item lg={12}>
                            <TextField
                              label="Street"
                              name="street1"
                              variant="filled"
                              size="small"
                              fullWidth
                            />
                          </Grid>
                          <Grid
                            item
                            lg={6}
                            container
                            alignItems={'center'}
                            direction={'row'}
                          >
                            <AutoCompleteField
                              as={CitySelect}
                              name="city_id"
                              selectedId={values.city_id}
                              inputProps={{
                                variant: 'filled',
                                size: 'small',
                              }}
                            />
                            <IconButton
                              href="/old/database/items.php?item=City"
                              size="small"
                            >
                              <Create />
                            </IconButton>
                          </Grid>
                          <Grid item lg={3}>
                            <StaticSelect
                              name={'region'}
                              label="State"
                              variant="filled"
                              size="small"
                              fullWidth
                              style={{marginTop: '0px'}}
                            >
                              {STATES.map(({id, name}) => (
                                <MenuItem value={id} key={id}>
                                  {id}
                                </MenuItem>
                              ))}
                            </StaticSelect>
                          </Grid>
                          <Grid item lg={3}>
                            <TextField
                              label="Postcode"
                              name="postcode"
                              variant="filled"
                              size="small"
                              fullWidth
                            />
                          </Grid>
                          <Grid item lg={12}>
                            <AutoCompleteField
                              as={SalesstageSelect}
                              name="salesstages.0.id"
                              selectedId={values.salesstages[0]?.id}
                              inputProps={{
                                variant: 'filled',
                                size: 'small',
                              }}
                            />
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          container
                          lg={6}
                          spacing={1}
                          alignContent={'flex-start'}
                        >
                          <Grid item lg={12}>
                            <Typography variant="h6">&nbsp;</Typography>
                          </Grid>
                          <Grid item lg={12}>
                            <AutoCompleteField
                              as={UserSelectDynamic}
                              name="sales_person_id"
                              selectedId={values.sales_person_id}
                              label={'Sales Person'}
                              inputProps={{
                                variant: 'filled',
                                size: 'small',
                              }}
                            />
                          </Grid>
                          <Grid item lg={12}>
                            <TextField
                              label="Notes"
                              name="notes"
                              variant="filled"
                              size="small"
                              fullWidth
                              multiline
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </ContainerPaper>
                    <ContainerPaper>
                      <Grid container spacing={2}>
                        <Grid item lg={12} direction="row" container>
                          <Grid item lg={12}>
                            <Typography variant="h6">Contact info</Typography>
                          </Grid>
                          <FieldArray name={'phones'}>
                            {({remove, push}) => (
                              <Grid
                                item
                                lg={6}
                                container
                                spacing={1}
                                direction="row"
                                alignItems="center"
                                alignContent="flex-start"
                              >
                                <Grid item lg={12}>
                                  <Typography variant="subtitle2">
                                    Phones
                                  </Typography>
                                </Grid>
                                {values.phones?.map((p, i) => (
                                  <Grid
                                    key={p.id}
                                    item
                                    lg={12}
                                    container
                                    spacing={1}
                                    direction="row"
                                    alignItems="center"
                                    alignContent="flex-start"
                                  >
                                    <Grid item lg={3}>
                                      <TextField
                                        variant="filled"
                                        name={`phones.${i}.label`}
                                        label="Label"
                                        size="small"
                                      />
                                    </Grid>
                                    <Grid item lg={5}>
                                      <PhoneNumber
                                        variant="filled"
                                        name={`phones.${i}.number`}
                                        label="Number"
                                        size="small"
                                        fullWidth
                                        autoFocus={autofocusPhone === i}
                                      />
                                    </Grid>
                                    <Grid item lg={2}>
                                      <TextField
                                        variant="filled"
                                        name={`phones.${i}.phonetype`}
                                        label="type"
                                        size="small"
                                        select
                                        fullWidth
                                      >
                                        <MenuItem value={1}>Voice</MenuItem>
                                        <MenuItem value={2}>Text</MenuItem>
                                        <MenuItem value={3}>Fax</MenuItem>
                                      </TextField>
                                    </Grid>
                                    <Grid item lg={1}>
                                      <IconButton
                                        onClick={() => remove(i)}
                                        size="large"
                                      >
                                        <Delete
                                          style={{
                                            color: theme.palette.danger.main,
                                          }}
                                        />
                                      </IconButton>
                                    </Grid>
                                  </Grid>
                                ))}
                                <Grid item lg={1}>
                                  <Button
                                    id="addNewPhone"
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => {
                                      setAutofocusPhone(values.phones.length) // length === index of the item we're going to create. Paradoxical, I know
                                      push({
                                        id: 'new' + (values.phones.length + 1),
                                        label: 'main',
                                        number: null,
                                        phonetype: 1,
                                      })
                                    }}
                                    size="small"
                                  >
                                    <Add />
                                  </Button>
                                </Grid>
                              </Grid>
                            )}
                          </FieldArray>
                          <FieldArray name={'emails'}>
                            {({remove, push}) => (
                              <Grid
                                item
                                lg={6}
                                container
                                spacing={1}
                                direction="row"
                                alignItems="center"
                                alignContent="flex-start"
                              >
                                <Grid item lg={12}>
                                  <Typography variant="subtitle2">
                                    Emails
                                  </Typography>
                                </Grid>
                                {values.emails?.map((p, i) => (
                                  <Grid
                                    key={p.id}
                                    item
                                    lg={12}
                                    container
                                    spacing={1}
                                    direction="row"
                                    alignItems="center"
                                    alignContent="flex-start"
                                  >
                                    <Grid
                                      item
                                      lg={4}
                                      style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                      }}
                                    >
                                      <Tooltip title="Edit Notes" arrow>
                                        <IconButton
                                          size="small"
                                          style={{width: '25px'}}
                                          onClick={() =>
                                            setEditingEmailNotes(
                                              editingEmailNotes === null ||
                                                editingEmailNotes !== i
                                                ? i
                                                : null,
                                            )
                                          }
                                        >
                                          {editingEmailNotes === i ? (
                                            <ExpandMore />
                                          ) : (
                                            <ChevronRight />
                                          )}
                                        </IconButton>
                                      </Tooltip>
                                      <TextField
                                        variant="filled"
                                        name={`emails.${i}.label`}
                                        label="Label"
                                        size="small"
                                      />
                                    </Grid>
                                    <Grid item lg={7}>
                                      <TextField
                                        variant="filled"
                                        name={`emails.${i}.email`}
                                        label="Email"
                                        size="small"
                                        fullWidth
                                        autoFocus={autofocusEmail === i}
                                      />
                                    </Grid>
                                    <Grid item lg={1}>
                                      <IconButton
                                        onClick={() => remove(i)}
                                        size="large"
                                      >
                                        <Delete
                                          style={{
                                            color: theme.palette.danger.main,
                                          }}
                                        />
                                      </IconButton>
                                    </Grid>
                                    {editingEmailNotes === i && (
                                      <Grid item md={12}>
                                        <TextField
                                          name={`emails.${i}.notes`}
                                          label="Notes"
                                          variant="outlined"
                                          size="small"
                                          fullWidth
                                        />
                                      </Grid>
                                    )}
                                  </Grid>
                                ))}
                                <Grid item lg={1}>
                                  <Button
                                    id="addNewEmail"
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => {
                                      setAutofocusEmail(values.emails.length) // length === index of the item we're going to create. Paradoxical, I know
                                      push({
                                        id: 'new' + (values.emails.length + 1),
                                        label: 'Main',
                                        email: '',
                                      })
                                    }}
                                    size="small"
                                  >
                                    <Add />
                                  </Button>
                                </Grid>
                              </Grid>
                            )}
                          </FieldArray>
                        </Grid>
                      </Grid>
                    </ContainerPaper>
                    <ContainerPaper>
                      <Grid container spacing={2}>
                        <Grid item lg={12} direction="row" container>
                          <Grid item lg={12}>
                            <Typography variant="h6">Companies</Typography>
                          </Grid>
                          <FieldArray name={'customers'}>
                            {({remove, push}) => (
                              <Grid
                                item
                                lg={6}
                                container
                                spacing={1}
                                direction="row"
                                alignItems="center"
                                alignContent="flex-start"
                              >
                                <Grid
                                  item
                                  lg={12}
                                  alignItems={'center'}
                                  direction={'row'}
                                  display={'flex'}
                                >
                                  <Person />
                                  <Typography variant="subtitle2">
                                    Customers
                                  </Typography>
                                </Grid>
                                {values.customers?.map((c, i) => (
                                  <Grid
                                    key={i}
                                    item
                                    lg={12}
                                    container
                                    spacing={1}
                                    direction="row"
                                    alignItems="center"
                                    alignContent="flex-start"
                                  >
                                    <Grid item lg={10}>
                                      <AutoCompleteField
                                        as={CustomerSelect}
                                        name={`customers.${i}.id`}
                                        selectedId={values.customers[i].id}
                                        inputVariant={'filled'}
                                        inputProps={{size: 'small'}}
                                        fullWidth
                                      />
                                    </Grid>
                                    <Grid item lg={1}>
                                      <IconButton
                                        onClick={() => remove(i)}
                                        size="large"
                                      >
                                        <Delete
                                          style={{
                                            color: theme.palette.danger.main,
                                          }}
                                        />
                                      </IconButton>
                                    </Grid>
                                  </Grid>
                                ))}
                                <Grid item lg={1}>
                                  <Button
                                    id="addNewCustomer"
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => {
                                      push({
                                        id:
                                          'new' + (values.customers.length + 1),
                                      })
                                    }}
                                    size="small"
                                  >
                                    <Add />
                                  </Button>
                                </Grid>
                              </Grid>
                            )}
                          </FieldArray>
                          <FieldArray name={'accounts'}>
                            {({remove, push}) => (
                              <Grid
                                item
                                lg={6}
                                container
                                spacing={1}
                                direction="row"
                                alignItems="center"
                                alignContent="flex-start"
                              >
                                <Grid
                                  item
                                  lg={12}
                                  alignItems={'center'}
                                  direction={'row'}
                                  display={'flex'}
                                >
                                  <People style={{marginRight: '.2em'}} />
                                  <Typography variant="subtitle2">
                                    Accounts
                                  </Typography>
                                </Grid>
                                {values.accounts?.map((c, i) => (
                                  <Grid
                                    key={i}
                                    item
                                    lg={12}
                                    container
                                    spacing={1}
                                    direction="row"
                                    alignItems="center"
                                    alignContent="flex-start"
                                  >
                                    <Grid item lg={10}>
                                      <AutoCompleteField
                                        as={AccountSelect}
                                        name={`accounts.${i}.id`}
                                        selectedId={values.accounts[i].id}
                                        inputProps={{
                                          variant: 'filled',
                                          size: 'small',
                                        }}
                                        fullWidth
                                        initialInputValue={
                                          values.accounts[i].name
                                        }
                                      />
                                    </Grid>
                                    <Grid item lg={1}>
                                      <IconButton
                                        onClick={() => remove(i)}
                                        size="large"
                                      >
                                        <Delete
                                          style={{
                                            color: theme.palette.danger.main,
                                          }}
                                        />
                                      </IconButton>
                                    </Grid>
                                  </Grid>
                                ))}
                                <Grid item lg={1}>
                                  <Button
                                    id="addNewAccount"
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => {
                                      push({
                                        id:
                                          'new' + (values.accounts.length + 1),
                                      })
                                    }}
                                    size="small"
                                  >
                                    <Add />
                                  </Button>
                                </Grid>
                              </Grid>
                            )}
                          </FieldArray>
                        </Grid>
                      </Grid>
                    </ContainerPaper>
                  </StyledDialogContent>
                  <StyledDialogActions>
                    <Button
                      onClick={() => {
                        onCancel()
                      }}
                    >
                      Cancel
                    </Button>
                    <LoadingButton
                      variant="contained"
                      color="primary"
                      onClick={submitForm}
                      loading={isSubmitting}
                      disabled={!isValid || isLoading}
                    >
                      Save
                    </LoadingButton>
                  </StyledDialogActions>
                </>
              )
            }}
          </Formik>
        </>
      )}
    </Dialog>
  )
}
