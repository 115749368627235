// @flow
// import type {ResolverMap} from './types'
export default `
  type Yearlystatistics @model(url: "/statistics/yearly", filters: "YearlystatisticsFilters") {
    id: String
    sales: Int
    number_jobs: Int 
    average_sales_per_job: Float 
    miles: Int 
    cpm: Float 
    vehicle_overhead: Float 
    standard_payroll: Float
    overtime_payroll: Float
    total_payroll: Float
    direct_labor_over_sales: String
    man_hours: Float
    sales_per_man_hour: Float
    total_direct_costs: Float
    direct_costs_over_sales: String 
    yearlyroutestatistics: [Yearlyroutestatistics] @relationship
  }
  
  input YearlystatisticsFilters {
    start_date: String
    end_date: String
  }

  type Yearlyroutestatistics @model(url: "/statistics/yearly") {
    id: ID 
    route: Int
    sales: Int
    number_jobs: Int 
    average_sales_per_job: Float 
    miles: Int 
    cpm: Float 
    vehicle_overhead: Float 
    standard_payroll: Float
    overtime_payroll: Float
    total_payroll: Float
    direct_labor_over_sales: String
    man_hours: Float
    sales_per_man_hour: Float
    total_direct_costs: Float
    direct_costs_over_sales: String 
    percent_of_cost: String
    percent_of_sales: String
  }

  type Weeklystatistics @model(url: "/statistics/weekly", filters: "WeeklystatisticsFilters") {
    id: String
    begin: String
    end: String
    week: Int
    sales: Float 
    number_jobs: Int 
    average_sales_per_job: Float 
    miles: Int 
    cpm: Float 
    vehicle_overhead: Float 
    standard_payroll: Float
    overtime_payroll: Float
    total_payroll: Float
    direct_labor_over_sales: String
    man_hours: Float
    sales_per_man_hour: Float
    routestatistics: [Routestatistics] @relationship
  }

  input WeeklystatisticsFilters {
    year: Int 
  }

  type Routestatistics @model(url: "/statistics/weekly") {
    id: String
    route: Int
    begin: String
    end: String
    week: Int
    sales: Float 
    number_jobs: Int 
    average_sales_per_job: Float 
    miles: Int 
    cpm: Float 
    vehicle_overhead: Float 
    standard_payroll: Float
    overtime_payroll: Float
    total_payroll: Float
    direct_labor_over_sales: String
    man_hours: Float
    sales_per_man_hour: Float
  }

  type Statistics @model(url: "/statistics/route", filters: "StatisticsFilters") {
    id: String
    routerun_id: Int
    route: Int
    week: Int
    date: String
    sales: Float 
    number_jobs: Int 
    average_sales_per_job: Float 
    miles: Int 
    cpm: Float 
    vehicle_overhead: Float 
    captain: String
    man_hours: Float
    standard_hours: Float
    overtime_hours: Float
    standard_payroll: Float
    overtime_payroll: Float
    total_payroll: Float
    payroll_over_sales: String
    sales_per_man_hour: Float
  }

  type Weeklysalesstatistics @model(url: "/statistics/weekly_sales",  filters: "StatisticsWeeklySalesFilters") {
    id: String
    week: Int
    year: Int
    sales: Float 
    projected: Boolean 
  }

  type Arsummarystatistics @model(url: "/statistics/ar_summary") {
    id: String
    balance: Float
    thirtyDay: Float
    sixtyDay: Float
    ninetyDay: Float
    overNinetyDay: Float
  }

  input StatisticsFilters {
    year: Int 
    route: Int
  }

  input StatisticsWeeklySalesFilters {
    start_date: String 
    end_date: String 
  }

  input SalesFilters {
    customer_id: ID
  }

  enum SaleStatus {
    SOLD
    REJECTED
    PENDING
  }

  type Sales @model(url: "/sales", filters: "SalesFilters") {
    id: ID
    customer_id: ID @updateable
    sales_person_id: ID @updateable
    source_id: ID @updateable
    incoming: Boolean @updateable
    sold_at: String @updateable
    status: SaleStatus @updateable
    existing_customer: Boolean
    customer: Customers @relationship 
    salesperson: Users @relationship 
    source: Sources @relationship @notUpdateable
    salelines: [Salelines] @relationship
  }

  type Salelines @model(url: "/salelines") {
    id: ID
    sale_id: ID @updateable
    saleitem_id: ID @updateable
    description: String @updateable
    amount: Float @updateable
    discount_id: ID @updateable
    saleitem: Saleitems @relationship @notUpdateable
    discount: Discounts @relationship 
  }

`
let resolvers = {
  Query: {},
}
export {resolvers}
