import axios from 'axios'
import {normalize} from 'normalizr'
import {addSecurityRetryQueue, login} from '../actions/authActions'
import {Map} from 'immutable'

export const CALL_API = Symbol('Call API')

export const API_DOMAIN = ''
export const API_ROOT = ''

function callApi(endpoint, params) {
  return axios({
    url: API_ROOT + endpoint,
    params: Object.assign({}, params, {random: Math.random()}),
    withCredentials: true,
  })
}

function processEntities(entities, transform) {
  if (typeof transform !== 'function') return entities
  if (Array.isArray(entities)) return entities.map(transform)
  return transform(entities)
}

const getCached = ({queryHash, key}, store) => {
  if (Map.isMap(queryHash)) // We're looking for a query
    return store.getState().api.getIn(['queries', key, queryHash])

  // We're looking for an entity
  return store.getState().api.getIn(['entities', key, queryHash.toString()])
}

export default store => next => action => {
  const callAPI = action[CALL_API]
  if (typeof callAPI === 'undefined') {
    return next(action)
  }

  // transform shouldn't be used often. Just an escape hatch.
  const defaultTransform = res => res
  const {
    url,
    params = {},
    schema,
    types,
    key,
    transform,
    transformEntity,
    queryHash,
    bypassCache,
  } = callAPI
  if (typeof url !== 'string') {
    throw new Error('Specify a string endpoint URL.')
  }
  if (!schema) {
    throw new Error('Specify on of the exported Schemas.')
  }
  if (!Array.isArray(types) || types.length !== 3) {
    throw new Error('Expected an array of three action types.')
  }
  if (!types.every(type => typeof type === 'string')) {
    throw new Error('Expected action types to be strings.')
  }

  function actionWith(data) {
    const finalAction = Object.assign({}, action, data)
    delete finalAction[CALL_API]
    return finalAction
  }

  // check if cached
  const cached = getCached({queryHash, key}, store)
  if (cached && !bypassCache) return cached

  const [requestType, successType, failureType] = types
  next(actionWith({type: requestType, key}))

  return new Promise((fulfill, reject) => {
    callApi(url, params, schema, transform).then(fulfill).catch(res => {
      if (res.status === 401) {
        fulfill(new Promise((fulfill, reject) => {
          // try to login if login credentials present
          next(
            addSecurityRetryQueue({
              reason: 'unauthorized server',
              retry: () => {
                axios(res.config).then(fulfill).catch(reject)
              },
            }),
          )
        }))

        const savedUser = window.localStorage.getItem('currentUser')
        const savedPassword = window.localStorage.getItem('currentPassword')

        if (savedUser && savedPassword) {
          login(savedUser, savedPassword)(next)
        }
      }
      reject(res)
    })
  })
    .then(transform)
    .then(res => {
      const finalData = processEntities(res.data.data, transformEntity)
      if (!res && transform !== defaultTransform) {
        throw new Error('Your transform did not return a response')
      }
      if (!finalData && transformEntity !== defaultTransform) {
        throw new Error('Your entity transform did not return a response')
      }
      // queries with the different pages are still same query
      const {...uniqueParams} = params
      return {
        ...normalize(finalData, schema),
        url,
        key,
        params: uniqueParams,
        meta: res.data.meta,
        queryHash,
      }
    })
    .then(
      response => next(actionWith({payload: response, type: successType})),
      error => {
        if (error.status === 401) {
          return next({type: failureType})
        }
        if (error.stack) console.error(error.stack)

        return next(
          actionWith({
            type: failureType,
            key,
            url,
            error: error.message || 'Something bad happened',
          }),
        )
      },
    )
    .catch(error => console.error(error.stack))
}

