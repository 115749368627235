import React, {useState, useRef, useEffect} from 'react'
import queryString from 'query-string'
import styled from 'styled-components'
import {Column} from 'react-virtualized'
import gql from 'graphql-tag'

import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'
import MuiLink from '@mui/material/Link'

import PeopleAddIcon from '@mui/icons-material/GroupAdd'

import {Toolbar, ToolbarFab as Fab} from '../../AppHandler'
import ToolbarGroup from '../../components/ToolbarGroup'
import ModifyQueryParams from '../../components/ModifyQueryParams'
import TableList from '../../components/Table/TableList'
import SearchBox from '../../components/SearchBox'
import {formatMoneyStandard} from '../../utils/moneyFormatter'
import {csv, useDebounce} from '../../utils'
import CustomerAvatarBase from '../../components/CustomerAvatar'
import SelectMenu from '../../components/ToolbarDropDownMenu'
import AvatarBorder from '../../components/AvatarBorder'
import {usePagination} from '../../components/PaginationContainer'
import CustomersNav from './CustomersToolbarNav'
import {Space} from '../../components/Layout'
import {Link} from 'react-router-dom'
import {useAuth} from '../../security/auth'
import {
  Box,
  Button,
  Chip,
  IconButton,
  Popover,
  TableSortLabel,
  Tooltip,
} from '@mui/material'
import {FilterList, Label} from '@mui/icons-material'
import moment from 'moment'
import EditDetails from '../Account/EditDetails'
import {PRThemeProvider} from '../../styles/theme'
import {useQueryClient} from 'react-query'
import urlJoin from '../../utils/url-join'
import {UserSelectDynamic} from '../../components/UsersSelect'
import {Redirect} from '../../pr-router'
import {SalesstageSelect} from '../../components/apiSelects'

let SearchBoxGroup = styled(ToolbarGroup)`
  flex-grow: 1;
`
let StatusSelect = ({value = 'all', ...props}) => (
  <SelectMenu value={value} label="Status" {...props}>
    <MenuItem value="all" children="All Statuses" />
    <MenuItem value="active" children="Active" />
    <MenuItem value="inactive" children="Inactive" />
  </SelectMenu>
)

let TaxSelect = ({value = 'all', ...props}) => (
  <SelectMenu value={value} label="Tax" {...props}>
    <MenuItem value="all">All</MenuItem>
    <MenuItem value="true">Taxable</MenuItem>
    <MenuItem value="false">Tax Exempt</MenuItem>
  </SelectMenu>
)

let SalesStatusSelect = ({value = 'all', ...props}) => (
  <SelectMenu value={value} label="Sales" {...props}>
    <MenuItem value="all">All Statuses</MenuItem>
    <MenuItem value="lead">Lead</MenuItem>
    <MenuItem value="sold">Client</MenuItem>
  </SelectMenu>
)

function getFilterDescription({
  searchStatus,
  searchSalesStatus,
  searchType,
  searchTerms,
  searchRoute,
  searchSource,
  searchTaxable,
  searchContract,
  searchStopReason,
  searchSalesPerson,
  searchEmployeeContact,
  searchSalesstage,
}) {
  let getLabel = (v, label) =>
    v === 'all' || v === undefined || v === null || v === '' ? '' : label
  let filters = [
    getLabel(searchStatus, 'Status'),
    // getLabel(searchTerms, 'Terms'),
    getLabel(searchTaxable, 'Tax'),
    // getLabel(searchContract, 'Contract'),
    // getLabel(searchSalesPerson, 'Sales Person'),
    getLabel(searchEmployeeContact, 'Employee Contact'),
    getLabel(searchSalesStatus, 'Sales Status'),
    getLabel(searchSalesstage, 'Sales Stage'),
  ]
  let showFilters = !!filters.join('')
  return showFilters
    ? `Filtering by: ${filters.filter(v => !!v).join('; ')}`
    : ''
}

let queryToLabels = {
  searchBy: {
    name: 'Accounts.Name',
    address: 'Accounts.Address',
    city_name: 'Cities.City',
    state: 'Accounts.State',
    phone: 'Accounts.Phone',
    contact: 'Accounts.Contact',
    notes: 'Accounts.notes',
  },
  searchStatus: {active: 'Active', inactive: 'Inactive'},
  searchSalesStatus: {lead: 'Lead', sold: 'Sold'},
}

function AccountToolbar({
  q,
  searchBy,
  searchTaxable,
  searchEmployeeContact,
  searchSalesStatus,
  searchStatus,
  searchSalesstage,
  onQueryChange = () => {},
  match,
  onCSV,
}) {
  let [addDialogIsOpen, setAddDialogIsOpen] = useState(false)
  let [redirectToId, setRedirectToId] = useState(null)

  let queryClient = useQueryClient()

  let searchEl = useRef(null)

  let {status: canExportList} = useAuth('export account list')
  let [filterEl, setFilterEl] = useState(null)
  let filtersOpen = !!filterEl

  let labelsUrl = `/old/database/printlabelsaccount.php?SearchField=${queryToLabels
    .searchBy[searchBy] || queryToLabels.searchBy.name}&Search=${q ||
    ''}&order=&active=${queryToLabels.searchStatus[searchStatus] || ''}
  `

  useEffect(() => {
    searchEl.current && searchEl.current.focus()
  }, [searchBy])

  return redirectToId ? (
    <Redirect
      to={{
        pathname: '/accounts/' + redirectToId,
        state: {
          backLocation: {
            pathname: location.pathname,
            query: location.query,
          },
        },
      }}
    />
  ) : (
    <Toolbar>
      <SearchBoxGroup>
        <CustomersNav />
        <Space inline size={2} />
        <SearchBox
          style={{flexGrow: 1}}
          ref={searchEl}
          value={q || ''}
          onSearch={q => onQueryChange({q})}
          autoFocus
        />
        <Space inline />
        <SelectMenu
          value={searchBy || 'name'}
          label="Search By"
          onChange={e => onQueryChange({searchBy: e.target.value})}
        >
          <MenuItem value="name" children="Name" />
          <MenuItem value="address" children="Street" />
          <MenuItem value="city_name" children="City" />
          <MenuItem value="region" children="State" />
          <MenuItem value="phone" children="Phone" />
          <MenuItem value="contact" children="Contact" />
          <MenuItem value="email" children="Email" />
          <MenuItem value="notes" children="Notes" />
        </SelectMenu>
      </SearchBoxGroup>
      <ToolbarGroup style={{justifyContent: 'flex-start'}}>
        <IconButton onClick={el => setFilterEl(el.currentTarget)} size="large">
          <FilterList />
        </IconButton>
        <Popover
          open={filtersOpen}
          anchorEl={filterEl}
          onClose={() => setFilterEl(null)}
          anchorOrigin={{vertical: 'bottom'}}
        >
          <Box p={2} style={{maxWidth: '650px'}}>
            <StatusSelect
              value={searchStatus}
              onChange={e => onQueryChange({searchStatus: e.target.value})}
            />
            <Space inline />
            <TaxSelect
              value={searchTaxable}
              onChange={e => onQueryChange({searchTaxable: e.target.value})}
            />
            <Space inline />
            <SalesStatusSelect
              value={searchSalesStatus}
              onChange={e => onQueryChange({searchSalesStatus: e.target.value})}
            />
            <UserSelectDynamic
              selectedId={searchEmployeeContact}
              label={'Employee Contact'}
              onChange={(e, u) =>
                onQueryChange({searchEmployeeContact: u?.id || ''})
              }
              sx={{width: 200}}
              inputProps={{
                variant: 'outlined',
                size: 'small',
                margin: 'dense',
              }}
            />
            <SalesstageSelect
              selectedId={searchSalesstage}
              onChange={(e, u) =>
                onQueryChange({searchSalesstage: u?.id || ''})
              }
              sx={{width: 227}}
              inputProps={{
                variant: 'outlined',
                size: 'small',
                margin: 'dense',
              }}
              label={'Sales Stage'}
            />
          </Box>
        </Popover>
        <Typography variant="caption">
          {getFilterDescription({
            searchStatus: searchStatus,
            searchTaxable: searchTaxable,
            searchEmployeeContact: searchEmployeeContact,
            searchSalesStatus: searchSalesStatus,
            searchSalesstage: searchSalesstage,
          })}
        </Typography>
      </ToolbarGroup>
      <ToolbarGroup last={true}>
        {canExportList === 'authorized' && (
          <>
            <Tooltip title="Labels">
              <IconButton tooltip="Labels" href={labelsUrl} size="large">
                <Label />
              </IconButton>
            </Tooltip>
            <Tooltip title="CSV">
              <Button onClick={onCSV}>CSV</Button>
            </Tooltip>
          </>
        )}
      </ToolbarGroup>
      <Fab
        color="secondary"
        onClick={() => {
          setAddDialogIsOpen(true)
        }}
        id="newAccount"
      >
        <PeopleAddIcon />
      </Fab>
      <PRThemeProvider>
        <EditDetails
          open={addDialogIsOpen}
          onSubmit={id => {
            setAddDialogIsOpen(false)
            queryClient.refetchQueries(['account'])
            setRedirectToId(id)
          }}
          onCancel={() => setAddDialogIsOpen(false)}
        />
      </PRThemeProvider>
    </Toolbar>
  )
}

let AvatarWrapper = styled.div`
  margin-left: 20px;
  display: flex;
  align-items: center;
`
let NameText = styled(Typography).attrs(() => ({
  variant: 'h6',
}))`
  flex: 1 0 auto;
`
let CustomerAvatar = styled(CustomerAvatarBase)`
  margin-left: 8px;
`

function AccountsList({
  accounts,
  loadMore,
  pageInfo,
  loading,
  isLeadView = false,
}) {
  function renderAvatars({customers}) {
    return customers.map(({id, customertype, photo}) => (
      <CustomerAvatar
        key={id}
        type={customertype && customertype.type}
        url={photo && photo.thumb_url}
      />
    ))
  }

  function renderNameColumn({rowData, rowIndex, isScrolling, isLeadView}) {
    let customers = (rowData && rowData.customers) || []
    let withPhotos = customers
      .filter(c => c.photo && c.photo.thumb_url)
      .slice(0, 2)
    let notDisplayedCnt = customers.length - withPhotos.length
    return (
      rowData && (
        <Link to={`/accounts/${rowData.id}`}>
          <div
            style={{display: 'flex', alignItems: 'center', padding: '5px 20px'}}
          >
            <NameText
              display="block"
              style={{
                fontStyle: isLeadView && 'italic',
                fontWeight: isLeadView && 'unset',
              }}
            >
              {rowData && rowData.name}
            </NameText>
            <AvatarWrapper>
              {Boolean(notDisplayedCnt) && (
                <AvatarBorder
                  style={notDisplayedCnt > 9 ? {fontSize: '16px'} : undefined}
                >
                  {notDisplayedCnt < 99 ? '+' + notDisplayedCnt : '>99'}
                </AvatarBorder>
              )}
              {renderAvatars({customers: withPhotos})}
            </AvatarWrapper>
          </div>
        </Link>
      )
    )
  }

  function renderCity({rowData}) {
    return (
      <div>
        <Typography display="block">{rowData && rowData.city_name}</Typography>
        <Typography display="block" variant="caption" color="textSecondary">
          {rowData && rowData.street1}
        </Typography>
      </div>
    )
  }

  function renderBalance({cellData, rowData}) {
    return (
      <Link
        display="block"
        align="right"
        to={`/accounts/${rowData && rowData.id}/transactions/payments/add`}
      >
        {formatMoneyStandard(cellData && cellData.balance)}
      </Link>
    )
  }

  return (
    <TableList
      data={accounts || []}
      infinite
      loadMoreRows={loadMore}
      rowCount={pageInfo ? pageInfo.total : 9999}
      loading={loading}
    >
      <Column
        dataKey="name"
        label="Name"
        width={1}
        flexGrow={1}
        cellRenderer={props =>
          renderNameColumn({
            ...props,
            isLeadView: !props?.rowData?.customers?.filter(
              c => c.schedules?.length > 0,
            ).length,
          })
        }
      />
      <Column
        dataKey="city_name"
        label="Address"
        headerRenderer={({label}) => (
          <div style={{textAlign: 'left'}}>{label}</div>
        )}
        width={1}
        maxWidth={250}
        flexGrow={1}
        cellRenderer={renderCity}
      />
      <Column
        dataKey="region"
        label="State"
        width={40}
        cellRenderer={({cellData}) => (
          <Typography display="block" align="center">
            {cellData}
          </Typography>
        )}
      />
      <Column
        dataKey="salesstages"
        cellRenderer={({cellData}) =>
          cellData?.map(stage => <Chip key={stage.id} label={stage.name} />)
        }
        label="Sales Stage"
        flexGrow={1}
        width={1}
        maxWidth={200}
      />
      <Column
        dataKey="balances"
        label="Balance"
        width={75}
        cellRenderer={renderBalance}
      />
      <Column
        dataKey="contact"
        label="Employee Contact"
        headerRenderer={({label}) => (
          <div style={{textAlign: 'center'}}>{label}</div>
        )}
        cellRenderer={({rowData}) => (
          <Typography
            variant="body1"
            display="block"
            style={{textAlign: 'right'}}
          >
            {rowData
              ? `${rowData.user.first_name} ${rowData.user.last_name}`
              : ''}
          </Typography>
        )}
        width={150}
      />
      <Column
        dataKey="created_at"
        label="Created"
        width={130}
        // headerRenderer={({label}) => (
        //   <TableSortLabel
        //     active={sortIsActive(order, 'created_at')}
        //     direction={sortDirection(
        //       order,
        //       sortIsActive(order, 'created_at'),
        //       'desc',
        //     )}
        //     onClick={onCreatedOrder}
        //   >
        //     {label}
        //   </TableSortLabel>
        // )}
        cellRenderer={({cellData}) => (
          <Typography
            varaint="body1"
            display="block"
            style={{textAlign: 'right'}}
          >
            {cellData ? moment.utc(cellData).format('ll') : ''}
          </Typography>
        )}
      />
    </TableList>
  )
}

let AccountsQuery = gql`
  query Accounts(
    $cursor: String = "-1"
    $limit: Int
    $name: String
    $notes: String
    $address: String
    $city_name: String
    $phone: String
    $contact: String
    $region: String
    $email: String
    $searchStatus: CustomerStatus
    $searchTaxable: Boolean
    $searchSalesStatus: CustomerSalesStatus
    $searchEmployeeContact: String
    $searchSalesstage: String
  ) {
    accounts: allAccounts(
      orderBy: name_ASC
      cursor: $cursor
      limit: $limit
      filters: {
        name: $name
        address: $address
        city_name: $city_name
        phone: $phone
        contact: $contact
        region: $region
        email: $email
        status: $searchStatus
        sales_status: $searchSalesStatus
        taxable: $searchTaxable
        notes: $notes
        user_id: $searchEmployeeContact
        salesstages: $searchSalesstage
      }
    ) {
      edges {
        cursor
        edge {
          id
          name
          city_name
          street1
          region
          balances {
            id
            balance
          }
          customers {
            id
            name
            customertype {
              id
              type
            }
            photo {
              id
              thumb_url
            }
            schedules {
              id
            }
          }
          user {
            id
            first_name
            last_name
          }
          salesstages {
            id
            name
          }
          created_at
          updated_at
        }
      }
      pageInfo {
        next
        current
        prev
        count
        total
      }
    }
  }
`

function outputCSV({
  name,
  address,
  city_name,
  phone,
  region,
  email,
  notes,
  // searchStatus,
  searchSalesStatus,
  // searchType,
  // searchTerms,
  // searchRoute,
  // searchSource,
  // searchStopReason,
  // searchSalesPerson,
  searchEmployeeContact,
  searchTaxable,
  // searchContract,
  // searchCreatedYear,
  searchSalesstage: salesstages,
  showTrash: trashed,
}) {
  const query = queryString.stringify({
    name,
    address,
    city_name,
    phone,
    region,
    email,
    notes,
    // status: searchStatus,
    sales_status: searchSalesStatus,
    // terms_id: searchTerms,
    // route_id: searchRoute,
    // customertype: searchType,
    // source_id: searchSource,
    // stopreason_id: searchStopReason,
    taxable: searchTaxable,
    // contract: searchContract,
    // sales_person_id: searchSalesPerson,
    user_id: searchEmployeeContact,
    // year: searchCreatedYear,
    trashed,
    salesstages,
  })
  csv(`/accounts?${query}`)
}

function Accounts({location, match}) {
  let isLeadView = false
  switch (match.path) {
    case '/accounts':
      break
    case '/accountleads':
      isLeadView = true
      break
  }

  let {
    searchBy = 'name',
    q = '',
    searchStatus = 'active',
    searchTaxable = 'all',
    searchEmployeeContact = undefined,
    searchSalesStatus = 'all',
    searchSalesstage = undefined,
    ...params
  } = queryString.parse(location.search)
  let [queryChanges, setQueryChanges] = useState({
    q,
    searchBy,
    searchStatus,
    searchTaxable,
    searchEmployeeContact,
    searchSalesStatus,
    searchSalesstage,
  })
  let [debouncedSearch] = useDebounce(queryChanges.q, 300)
  let vars = {
    [searchBy]: q === '' ? undefined : q,
    searchStatus: searchStatus === 'all' ? undefined : searchStatus,
    searchSalesStatus:
      searchSalesStatus === 'all' ? undefined : searchSalesStatus,
    searchEmployeeContact,
    searchSalesstage,
    ...(searchTaxable !== 'all' && {searchTaxable: searchTaxable === 'true'}),
    ...params,
  }

  let {data, loading, loadMore} = usePagination({
    query: AccountsQuery,
    variables: {
      cursor: '-1',
      ...vars,
    },
  })

  return (
    <>
      <AccountToolbar
        searchBy={queryChanges.searchBy}
        q={queryChanges.q}
        searchStatus={queryChanges.searchStatus}
        searchTaxable={queryChanges.searchTaxable}
        searchEmployeeContact={queryChanges.searchEmployeeContact}
        searchSalesStatus={queryChanges.searchSalesStatus}
        searchSalesstage={queryChanges.searchSalesstage}
        onQueryChange={changes => setQueryChanges(c => ({...c, ...changes}))}
        match={match}
        onCSV={() => outputCSV(vars)}
      />
      <ModifyQueryParams query={{...queryChanges, q: debouncedSearch}} />
      <AccountsList
        loading={loading}
        accounts={data && data.accounts && data.accounts.edges}
        pageInfo={data && data.accounts && data.accounts.pageInfo}
        loadMore={loadMore}
      />
    </>
  )
}
export default Accounts
