import React, {useState} from 'react'
import gql from 'graphql-tag'
import queryString from 'query-string'

import {useDebounce} from '../../utils'
import {usePagination} from '../../components/PaginationContainer'
import WorkordersToolbar from './WorkordersToolbar'
import ModifyQueryParams from '../../components/ModifyQueryParams'
import WorkordersList from './WorkordersList'
import Routes from '../../constants/Routes'
import {fragments} from './WorkordersList'
import moment from 'moment'
import _ from 'lodash'

let workorderQuery = gql`
  query Workorders(
    $cursor: String
    $name: String
    $id: ID
    $notes: String
    $searchStatus: WorkorderStatus
    $searchRoute: String
    $searchFrom: String
    $searchTo: String
    $searchHasNotes: String
    $searchNotCompleted: String
    $searchInvoiced: String
    $searchMultiday: String
    $searchBillable: String
    $order: WorkordersOrderFields
    $limit: Int
  ) {
    workorders: allWorkorders(
      cursor: $cursor
      limit: $limit
      filters: {
        customer_name: $name
        notes: $notes
        id: $id
        status: [$searchStatus]
        route_id: $searchRoute
        from: $searchFrom
        to: $searchTo
        has_notes: $searchHasNotes
        not_completed: $searchNotCompleted
        invoiced: $searchInvoiced
        multiday: $searchMultiday
        billable: $searchBillable
      }
      orderBy: $order
    ) {
      edges {
        cursor
        edge {
          ...WorkorderDetail
        }
      }
      pageInfo {
        next
        current
        prev
        count
        total
      }
    }
  }
  ${fragments.workorder}
`

function mungeQuery(query) {
  let parsed = queryString.parse(query)
  function munge(prop, validation) {
    return validation.includes(prop) ? prop : validation[0]
  }
  let searchStatus = munge(parsed.searchStatus, [
    'all',
    'OPEN',
    'COMPLETE',
    'VOID',
  ])
  let searchHasNotes = munge(parsed.searchHasNotes, [
    'all',
    'true',
    'false',
    'unresolved',
    'resolved',
  ])
  let searchNotCompleted = munge(parsed.searchNotCompleted, [
    'all',
    'true',
    'false',
  ])
  let searchInvoiced = munge(parsed.searchInvoiced, ['all', 'true', 'false'])
  let searchMultiday = munge(parsed.searchMultiday, ['all', 'true', 'false'])
  let searchBillable = munge(parsed.searchBillable, ['all', 'true', 'false'])
  let searchRoute = munge(parsed.searchRoute, [
    'all',
    ..._.range(1, 100).map(i => String(i)),
  ])
  let searchFrom =
    !!parsed.searchFrom && moment.utc(parsed.searchFrom).isValid
      ? moment.utc(parsed.searchFrom).format('YYYY-MM-DD')
      : 'all'
  let searchTo =
    !!parsed.searchTo && moment.utc(parsed.searchTo).isValid
      ? moment.utc(parsed.searchTo).format('YYYY-MM-DD')
      : 'all'
  let searchTaxable =
    parsed.searchTaxable === 'true'
      ? true
      : parsed.searchTaxable === 'false'
      ? false
      : 'all'
  let searchBy = munge(parsed.searchBy, ['id', 'name', 'notes'])
  let order = munge(parsed.order, [
    'id_DESC',
    'id_ASC',
    'name_ASC',
    'name_DESC',
    'status_ASC',
    'status_DESC',
    'assigned_at_ASC',
    'assigned_at_DESC',
  ])
  return {
    searchStatus,
    searchRoute,
    searchBy,
    searchTaxable,
    searchFrom,
    searchTo,
    searchHasNotes,
    searchNotCompleted,
    searchInvoiced,
    searchMultiday,
    searchBillable,
    order,
    q: parsed.q || '',
  }
}

function Workorders({location, match}) {
  const {
    searchStatus,
    searchBy,
    searchRoute,
    searchTaxable,
    searchFrom,
    searchTo,
    searchHasNotes,
    searchNotCompleted,
    searchInvoiced,
    searchMultiday,
    searchBillable,
    q,
    order,
  } = mungeQuery(location.search)
  let [queryChanges, setQueryChanges] = useState({q})
  let [debouncedQ] = useDebounce(queryChanges.q, 300)

  let mungeSearch = q => (q === 'all' ? undefined : q)

  let search = {
    [searchBy]: q === '' ? undefined : q,
    searchStatus: mungeSearch(searchStatus),
    searchRoute: mungeSearch(searchRoute),
    searchTaxable: mungeSearch(searchTaxable),
    searchFrom: mungeSearch(searchFrom),
    searchTo: mungeSearch(searchTo),
    searchHasNotes: mungeSearch(searchHasNotes),
    searchNotCompleted: mungeSearch(searchNotCompleted),
    searchInvoiced: mungeSearch(searchInvoiced),
    searchMultiday: mungeSearch(searchMultiday),
    searchBillable: mungeSearch(searchBillable),
  }

  let {data, loading, loadMore, refetch} = usePagination({
    query: workorderQuery,
    variables: {
      ...search,
      order,
      cursor: '-1',
    },
  })

  return (
    <React.Fragment>
      <WorkordersToolbar
        searchQuery={queryChanges.q}
        searchStatus={searchStatus}
        searchBy={searchBy}
        searchRoute={searchRoute}
        searchTaxable={searchTaxable}
        searchFrom={searchFrom}
        searchTo={searchTo}
        searchHasNotes={searchHasNotes}
        searchNotCompleted={searchNotCompleted}
        searchInvoiced={searchInvoiced}
        searchMultiday={searchMultiday}
        searchBillable={searchBillable}
        onQueryChange={changes => setQueryChanges(c => ({...c, ...changes}))}
        match={match}
        location={location}
        onRefetch={refetch}
      />
      <ModifyQueryParams query={{...queryChanges, q: debouncedQ}} state={{}} />
      <WorkordersList
        {...search}
        loading={loading}
        workorders={data && data.workorders && data.workorders.edges}
        pageInfo={data && data.workorders && data.workorders.pageInfo}
        onInfiniteLoad={loadMore}
        onQueryChange={changes => setQueryChanges(c => ({...c, ...changes}))}
        order={order}
      />
    </React.Fragment>
  )
}

export default Workorders
