import React from 'react'
import gql from 'graphql-tag'

import {
  ListItemText,
  InputLabel,
  TextField,
  ListItem,
  createFilterOptions,
} from '@mui/material'

import AutoComplete, {
  Autocomplete,
  useAutoCompleteEndpoint,
} from './AutoComplete'
import {estimateserviceFragment} from '../screens/Customer/Estimate'

let sourceFragment = gql`
  fragment Selection on Sources {
    id
    source
  }
`

export function SourceSelect({
  selectedId,
  idKey = 'source',
  label = 'Sources',
  name,
  inputProps = {},
  ...props
}) {
  let autoProps = useAutoCompleteEndpoint({
    gqlFragment: sourceFragment,
    selectedId,
    searchField: 'source',
  })

  return (
    <Autocomplete
      {...autoProps}
      getOptionLabel={u => (u ? `${u.source}` : '')}
      renderInput={params => (
        <TextField {...params} {...inputProps} label={label} />
      )}
      renderOption={(props, option) => (
        <ListItem {...props} id={`${idKey}-${option.id}`} key={option.id}>
          {option.source}
        </ListItem>
      )}
      {...props}
    />
  )
}

let jobrequirementtypeFragment = gql`
  fragment Jobrequirementtype on Jobrequirementtypes {
    id
    name
  }
`

export function JobrequirementtypeSelect({onChange, selectedId, ...props}) {
  let autoProps = useAutoCompleteEndpoint({
    gqlFragment: jobrequirementtypeFragment,
    selectedId,
    searchField: 'name',
  })

  return (
    <AutoComplete
      {...autoProps}
      onChange={onChange}
      itemToString={u => u.name}
      renderItem={({item}) => (
        <ListItemText
          primary={item.name}
          id={'jobrequirementtype-' + item.id}
          key={item.id}
        />
      )}
      renderNoItems={() => (
        <ListItemText primary={'No job requirement Found'} />
      )}
      inputVariant="filled"
      label={<InputLabel>Type</InputLabel>}
      hideIcon
      {...props}
    />
  )
}

let tasktypeFragment = gql`
  fragment Tasktype on Tasktypes {
    id
    description
    deleted_at
  }
`

export function TasktypeSelect({onChange, selectedId, ...props}) {
  let autoProps = useAutoCompleteEndpoint({
    gqlFragment: tasktypeFragment,
    selectedId,
    searchField: 'description',
  })

  return (
    <AutoComplete
      {...autoProps}
      onChange={onChange}
      itemToString={u => u.description}
      renderItem={({item}) => (
        <ListItemText
          primary={item.description}
          id={'tasktype-' + item.id}
          key={item.id}
        />
      )}
      renderNoItems={() => <ListItemText primary={'No task type Found'} />}
      inputVariant="filled"
      label={<InputLabel>Type</InputLabel>}
      hideIcon
      {...props}
    />
  )
}

const filter = createFilterOptions()

export function TaskSelect({
  selectedId,
  idKey = 'tasktype',
  label = 'tasks',
  name,
  inputProps = {},
  onChange,
  ...props
}) {
  let autoProps = useAutoCompleteEndpoint({
    gqlFragment: tasktypeFragment,
    selectedId,
    searchField: 'description',
  })

  return (
    <Autocomplete
      {...autoProps}
      getOptionLabel={u => (u ? `${u.description}` : '')}
      renderInput={params => (
        <TextField
          {...params}
          {...inputProps}
          label={label}
          // save if the user tabs out of the field
          onBlur={event => {
            const inputValue = event.target.value
            const isExisting = autoProps.data.some(
              option => inputValue === option.edge.description,
            )

            if (inputValue !== '' && !isExisting) {
              onChange({
                id: 'freeSolo',
                description: inputValue,
              })
            }

            if (inputProps.onBlur) {
              inputProps.onBlur(event)
            }
          }}
        />
      )}
      renderOption={(props, option) => (
        <ListItem {...props} id={`${idKey}-${option.id}`} key={option.id}>
          {option.id === 'freeSolo'
            ? `Add "${option.description}"`
            : option.description}
        </ListItem>
      )}
      freeSolo
      filterOptions={(options, params) => {
        const filtered = filter(options, params)
        const {inputValue} = params

        // Don't show the "create" option if there's an exact match in the template
        const isExisting = options.some(
          option => inputValue === option.description,
        )
        if (inputValue !== '' && !isExisting) {
          filtered.push({
            id: 'freeSolo',
            description: `${inputValue}`,
          })
        }

        return filtered
      }}
      onChange={onChange}
      {...props}
    />
  )
}

export function EstimateserviceSelect({
  onChange,
  selectedId,
  extraFilters,
  ...props
}) {
  let autoProps = useAutoCompleteEndpoint({
    gqlFragment: estimateserviceFragment,
    selectedId,
    searchField: 'label',
    extraFilters,
  })

  return (
    <AutoComplete
      {...autoProps}
      onChange={onChange}
      itemToString={u => u.label}
      renderItem={({item}) => (
        <ListItemText
          primary={item.label}
          id={'estimateservice-' + item.id}
          key={item.id}
        />
      )}
      renderNoItems={() => <ListItemText primary={'No service Found'} />}
      inputVariant="filled"
      label={<InputLabel>Service</InputLabel>}
      hideIcon
      {...props}
    />
  )
}

let salesstageFragment = gql`
  fragment Salesstage on Salesstages {
    id
    name
  }
`

export function SalesstageSelect({
  selectedId,
  idKey = 'salesstage',
  label = 'Sales Stage',
  name,
  inputProps = {},
  ...props
}) {
  let autoProps = useAutoCompleteEndpoint({
    gqlFragment: salesstageFragment,
    selectedId,
    searchField: 'name',
  })

  return (
    <Autocomplete
      {...autoProps}
      getOptionLabel={u => (u ? `${u.name}` : '')}
      renderInput={params => (
        <TextField {...params} {...inputProps} label={label} />
      )}
      renderOption={(props, option) => (
        <ListItem {...props} id={`${idKey}-${option.id}`} key={option.id}>
          {option.name}
        </ListItem>
      )}
      {...props}
    />
  )
}
