// @flow
// import type {ResolverMap} from './types'
export default `
  type Feedbacks @model(url: "/feedbacks", filters: "FeedbacksFilters") {
    id: String
    account_id: String @updateable
    customer_id: String @updateable
    from_id: String @updateable
    user_id: String @updateable
    type: String @updateable
    feedback: String @updateable
    resolution: String @updateable
    opened_at: String 
    closed_at: String @updateable
    updated_id: String
    created_at: String
    updated_at: String
    from: Users @relationship
    user: Users @relationship
    customer: Customers @relationship @notUpdateable
    account: Accounts @relationship @notUpdateable
  }
  
  input FeedbacksFilters {
    type: FeedbacksType
    status: FeedbacksStatus
    group: FeedbacksGroup
    feedback: String
    resolution: String
    from_id: ID
    user_id: ID
    technician_id: ID
  }

  enum FeedbacksStatus { open closed }
  enum FeedbacksType {
    sales
    other
    complement
    complaint
    service
    delinquent
    ar
  }

  enum FeedbacksGroup {
    standard
    technician
  }
`
let resolvers = {
  Query: {},
}
export {resolvers}
