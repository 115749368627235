import {useState} from 'react'
import {useMutation, useQuery, useQueryClient} from 'react-query'
import {gql, prgql} from '../../utils/graphql'
import {
  Grid,
  Typography,
  Button,
  Paper,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Chip,
  MenuItem,
  Fab,
} from '@mui/material'
import TableList from '../../components/Table/TableList'
import {Column} from 'react-virtualized'
import {usePagination} from '../../components/PaginationContainer'
import {Add, Save} from '@mui/icons-material'
import {
  CloseButton,
  DeleteMenuItem,
  DetailToolbar,
  ToolbarCenter,
  ToolbarIconMenu,
  ToolbarLeft,
  ToolbarRight,
} from '../../components/Toolbar'
import {NavigateBack} from '../../components/Navigator'
import styled from 'styled-components'
import {Space} from '../../components/Layout'
import {Formik} from 'formik'
import {TextField} from '../../components/forms'
import {RedirectBack} from '../../components/Navigator'
import {Link, Switch} from 'react-router-dom'
import toaster from '../../utils/toaster'
import {Route} from '../../pr-router'
import {Toolbar} from '../../AppHandler' // Added Toolbar import

let query = gql`
  query Items($limit: Int = 20, $cursor: String) {
    items: allJobrequirementtypes(limit: $limit, cursor: $cursor) {
      edges {
        edge {
          id
          name
          type
        }
        cursor
      }
      pageInfo {
        next
        current
        prev
        count
        total
      }
    }
  }
`

let getQuery = gql`
  query Item($id: String!) {
    item: jobrequirementtypes(id: $id) {
      id
      name
      type
    }
  }
`

let createMutation = gql`
  mutation Create($input: CreateJobrequirementtypesInput) {
    createJobrequirementtypes(input: $input) {
      jobrequirementtypes {
        id
      }
    }
  }
`

let updateMutation = gql`
  mutation Update($input: UpdateJobrequirementtypesInput) {
    updateJobrequirementtypes(input: $input) {
      jobrequirementtypes {
        id
        name
      }
    }
  }
`

let deleteMutation = gql`
  mutation Delete($id: String!) {
    deleteJobrequirementtypes(input: {id: $id}) {
      message
    }
  }
`

const TYPES = {
  1: 'Tool',
  2: 'Material',
}

const JobRequirementsList = () => {
  let {data, loading, loadMore} = usePagination({
    query: query,
    variables: {
      // order: 'name_ASC',
      cursor: '-1',
    },
  })

  function getRowCount() {
    if (!data || !data.items || !data.items.pageInfo) {
      return 9999
    }
    return data.items.pageInfo.total
  }

  return (
    <>
      <TableList
        data={(data && data.items && data.items.edges) || []}
        infinite
        loadMoreRows={loadMore}
        rowCount={getRowCount()}
        wrapRow={({children, rowData}) => (
          <Link to={`/jobrequirements/${rowData.id}`}>{children}</Link>
        )}
      >
        <Column
          dataKey="name"
          headerRenderer={({label}) => (
            <div style={{textAlign: 'center'}}>{label}</div>
          )}
          cellRenderer={data => (
            <Typography style={{display: 'flex', alignItems: 'center'}}>
              {data.cellData}
            </Typography>
          )}
          label="Name"
          flexGrow={1}
          width={0}
        />
        <Column
          dataKey="type"
          headerRenderer={({label}) => (
            <div style={{textAlign: 'center'}}>{label}</div>
          )}
          cellRenderer={data => (
            <Typography style={{display: 'flex', alignItems: 'center'}}>
              <Chip label={TYPES[data.cellData]} />
            </Typography>
          )}
          label="Type"
          flexGrow={1}
          width={0}
        />
      </TableList>
    </>
  )
}
let PaperContainer = styled(Paper)`
  width: 80%;
  margin: 16px auto 0;
  padding: 2em;
`
let LeftIcon = styled.div`
  margin-right: ${({theme}) => theme.muiTheme.spacing(1)};
`
let Form = ({onSubmit, initial = {}, formikkey, onDelete = () => {}}) => {
  const [warn, setWarn] = useState(false)

  return (
    <>
      <Formik initialValues={initial} onSubmit={onSubmit} key={formikkey}>
        {({submitForm, values, setValues}) => {
          return (
            <>
              <DetailToolbar>
                <ToolbarLeft>
                  <NavigateBack
                    defaultBack={location.pathname}
                    marker="JobrequirementBack"
                  >
                    <CloseButton />
                  </NavigateBack>
                </ToolbarLeft>
                <ToolbarCenter>
                  <Typography variant="h6">
                    {values.id ? 'Edit' : 'Add'} Job Requirement
                  </Typography>
                </ToolbarCenter>
                <ToolbarRight>
                  <Space sizePx={16} inline />
                  <Button color="primary" onClick={submitForm}>
                    <LeftIcon>
                      <Save />
                    </LeftIcon>
                    Save
                  </Button>
                  <ToolbarIconMenu>
                    <DeleteMenuItem
                      onClick={() => setWarn(true)}
                      disabled={!values.id}
                    />
                  </ToolbarIconMenu>
                </ToolbarRight>
              </DetailToolbar>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Name"
                    name="name"
                    variant={'outlined'}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    select
                    label="Type"
                    name="type"
                    variant={'outlined'}
                  >
                    <MenuItem value={1}>Tool</MenuItem>
                    <MenuItem value={2}>Material</MenuItem>
                  </TextField>
                </Grid>
              </Grid>
              <Dialog open={warn} onClose={() => setWarn(false)}>
                <DialogTitle>Delete Record</DialogTitle>
                <DialogContent>
                  Are you sure you want to delete this record?
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => setWarn(false)}>Cancel</Button>
                  <Button onClick={() => onDelete(values.id)}>Delete</Button>
                </DialogActions>
              </Dialog>
            </>
          )
        }}
      </Formik>
    </>
  )
}

export const JobRequirement = ({match, location}) => {
  let [goBack, setGoBack] = useState(false)

  let queryClient = useQueryClient()
  let {data, isError, isLoading} = useQuery(
    ['single-jobrequirement', match.params.id],
    async () =>
      await prgql({query: getQuery, variables: {id: match.params.id}}),
  )

  let {mutateAsync} = useMutation('update', async input => {
    return await prgql({
      query: updateMutation,
      variables: {input},
    })
  })

  let {mutateAsync: deleteItem} = useMutation('delete', async ({id}) => {
    return await prgql({
      query: deleteMutation,
      variables: {id},
    })
  })

  return (
    <>
      <PaperContainer>
        {isLoading ? (
          <div>Loading...</div>
        ) : isError ? (
          <div>Error</div>
        ) : (
          <Form
            formikkey={match.params.id}
            initial={data && data.item}
            onSubmit={async values => {
              let {users, ...saveValues} = {
                ...values,
              }
              await mutateAsync(saveValues)
              await queryClient.invalidateQueries([
                'single-jobrequirement',
                match.params.id,
              ])
              setGoBack(true)
              toaster.success('Job requirement saved')
            }}
            onDelete={async id => {
              await deleteItem({id})
              toaster.success('Job requirement deleted')
              setGoBack(true)
            }}
          />
        )}
      </PaperContainer>
      {/* This is extremely evil. we need a hook that'll do this */}
      {goBack && (
        <RedirectBack
          defaultBack={location.pathname}
          marker="JobrequirementBack"
          location="/jobrequirements"
        />
      )}
    </>
  )
}

export const NewJobRequirement = ({match, location}) => {
  let [goBack, setGoBack] = useState(false)

  let {mutateAsync} = useMutation('create', async input => {
    return await prgql({
      query: createMutation,
      variables: {input},
    })
  })

  return (
    <>
      <PaperContainer>
        {
          <Form
            formikkey={match.params.id}
            initial={{
              name: '',
              type: 1,
            }}
            onSubmit={async values => {
              let saveValues = {
                ...values,
              }
              await mutateAsync(saveValues)
              toaster.success('Job Requirement Created')
              setGoBack(true)
            }}
          />
        }
      </PaperContainer>
      {/* This is extremely evil. we need a hook that'll do this */}
      {goBack && (
        <RedirectBack
          defaultBack={location.pathname}
          marker="JobrequirementtypeBack"
        />
      )}
    </>
  )
}

let UnstyledLink = styled(Link)`
  &:visited {
    color: inherit;
  }
`

const ScreenToolbar = ({match, location}) => (
  <>
    <Toolbar>
      <Typography variant="h6" style={{alignItems: 'center', display: 'flex'}}>
        Job Requirements
      </Typography>
      {location.pathname === `${match.path}` ? (
        <Fab
          color="secondary"
          component={UnstyledLink}
          to={`${match.path}/new`}
        >
          <Add />
        </Fab>
      ) : (
        <></>
      )}
    </Toolbar>
  </>
)

const JobRequirements = ({match, location}) => {
  return (
    <>
      <ScreenToolbar match={match} location={location} />{' '}
      <Switch>
        <Route exact path={`${match.path}`} component={JobRequirementsList} />
        <Route path={`${match.path}/new`} exact component={NewJobRequirement} />
        <Route path={`${match.path}/:id`} component={JobRequirement} />
      </Switch>
    </>
  )
}

export default JobRequirements
