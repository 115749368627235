import PropTypes from 'prop-types'
import React, { Component } from 'react'
import styled from 'styled-components'
import theme, { DarkTheme } from '../styles/theme'
import { withInvTheme } from '../styles/theme'

import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import MenuItem from '@mui/material/MenuItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'

import MoreVertIcon from '@mui/icons-material/MoreVert'
import ArrowBack from '@mui/icons-material/ArrowBack'
import Delete from '@mui/icons-material/Delete'

import IconMenu from '../components/IconMenu'
import ToolbarRow from './ToolbarRow'
import ToolbarGroup from './ToolbarGroup'
import { Block, CheckCircle, RadioButtonUnchecked, DeleteOutline, Restore } from '@mui/icons-material'
export { ToolbarRow, ToolbarGroup }

let ToolbarContainer = styled.div`
  // display: flex;
  // flex-flow: column nowrap;
  padding: none;
  padding-right: ${p => (p.hasFab ? '95px' : 'none')};
  background-color: ${p => (p.paper ? 'white' : theme.primaryColor)};
`
let fabStyle = { position: 'absolute', bottom: `${-56 / 2}px`, right: '40px' }

export let CloseButton = props => (
  <IconButton {...props} size="large">
    <ArrowBack />
  </IconButton>
)

class Toolbar extends React.Component {
  static contextTypes = { muiTheme: PropTypes.object }
  render() {
    const { style, paper } = this.props
    let hasFab = false

    const toolbarChildren = React.Children.map(
      this.props.children,
      component => {
        if (component.type === Button && component.props.variant === 'fab') {
          hasFab = true
          return React.cloneElement(component, { style: fabStyle })
        }

        return component
      },
    )

    return (
      <ToolbarContainer style={style} hasFab={hasFab} paper={paper}>
        {paper ? toolbarChildren : <DarkTheme>{toolbarChildren}</DarkTheme>}
      </ToolbarContainer>
    )
  }
}

export default withInvTheme(Toolbar)

let PaperToolbar = props => <Toolbar {...props} paper />
export { PaperToolbar }

export let DetailRow = styled(ToolbarRow)`
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  grid-template-areas: 'toolbar-left toolbar-center toolbar-right';
  align-items: center;
`
export let ToolbarLeft = styled(ToolbarGroup)`
  grid-area: toolbar-left;
  justify-self: start;
  margin: 0;
`
export let ToolbarCenter = styled(ToolbarGroup)`
  grid-area: toolbar-center;
  justify-self: center;
  margin: 0;
`
export let ToolbarRight = styled(ToolbarGroup)`
  grid-area: toolbar-right;
  justify-self: end;
  margin: 0;
`
export let ToolbarTitle = props => (
  <ToolbarCenter>
    <Typography variant="h6" color="inherit" {...props} />
  </ToolbarCenter>
)
export class DeleteMenuItem extends Component {
  static defaultProps = {
    text: 'Delete',
  }
  render() {
    let { text, ...props } = this.props
    return (
      <MenuItem {...props}>
        <ListItemIcon>
          <Delete />
        </ListItemIcon>
        <ListItemText primary={text} />
      </MenuItem>
    )
  }
}
export class TrashMenuItem extends Component {
  static defaultProps = {
    text: 'Trash',
  }
  render() {
    let { text, ...props } = this.props
    return (
      <MenuItem {...props}>
        <ListItemIcon>
          <DeleteOutline />
        </ListItemIcon>
        <ListItemText primary={text} />
      </MenuItem>
    )
  }
}
export class VoidMenuItem extends Component {
  static defaultProps = {
    text: 'Void',
  }
  render() {
    let { text, ...props } = this.props
    return (
      <MenuItem {...props}>
        <ListItemIcon>
          <Block />
        </ListItemIcon>
        <ListItemText primary={text} />
      </MenuItem>
    )
  }
}
export class OpenMenuItem extends Component {
  static defaultProps = {
    text: 'Open',
  }
  render() {
    let { text, ...props } = this.props
    return (
      <MenuItem {...props}>
        <ListItemIcon>
          <RadioButtonUnchecked />
        </ListItemIcon>
        <ListItemText primary={text} />
      </MenuItem>
    )
  }
}
export class CompleteMenuItem extends Component {
  static defaultProps = {
    text: 'Complete',
  }
  render() {
    let { text, ...props } = this.props
    return (
      <MenuItem {...props}>
        <ListItemIcon>
          <CheckCircle />
        </ListItemIcon>
        <ListItemText primary={text} />
      </MenuItem>
    )
  }
}
export let ToolbarMenuItem = ({ icon, label, ...props }) => (
  <MenuItem {...props}>
    {icon && <ListItemIcon>{icon}</ListItemIcon>}
    <ListItemText primary={label} />
  </MenuItem>
)

export let ToolbarIconMenu = ({ id, children, ...props }) => (
  <IconMenu
    iconButtonElement={
      <IconButton size="large">
        <MoreVertIcon />
      </IconButton>
    }
    anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
    targetOrigin={{ horizontal: 'right', vertical: 'top' }}
    id={id}
  >
    {children}
  </IconMenu>
)
export class DetailToolbar extends Component {
  render() {
    let { children } = this.props
    return (
      <PaperToolbar>
        <DetailRow>{children}</DetailRow>
        <Divider style={{ margin: '0 0 15px 0' }} />
      </PaperToolbar>
    )
  }
}

// yeah I don't know what I'm doing
export class DividerlessDetailToolbar extends Component {
  render() {
    let { children } = this.props
    return (
      <PaperToolbar>
        <DetailRow>{children}</DetailRow>
      </PaperToolbar>
    )
  }
}
