import {CALL_API} from '../middleware/api'
import {schema} from 'normalizr'
import _ from 'lodash'
import moment from 'moment'

const endpoint = 'routeruns'

export const FETCH_ROUTE_RUNS_REQUEST = 'FETCH_ROUTE_RUNS_REQUEST'
export const FETCH_ROUTE_RUNS_SUCCESS = 'FETCH_ROUTE_RUNS_SUCCESS'
export const FETCH_ROUTE_RUNS_FAILURE = 'FETCH_ROUTE_RUNS_FAILURE'

const user = new schema.Entity('users')
const routeRun = new schema.Entity('routeRuns')
routeRun.define({users: [user]})

export function findRouteRunsByWeek({date}) {
  const beginningOfWeek = moment(date).weekday(1).format('YYYY-MM-DD')
  const endOfWeek = moment(date).weekday(7).format('YYYY-MM-DD')

  const params = {
    from: beginningOfWeek,
    to: endOfWeek,
    limit: 999,
    include: 'users',
  }

  return {
    [CALL_API]: {
      types: [
        FETCH_ROUTE_RUNS_REQUEST,
        FETCH_ROUTE_RUNS_SUCCESS,
        FETCH_ROUTE_RUNS_FAILURE,
      ],
      endpoint,
      params,
      schema: [routeRun],
      transform: res => {
        const newData = res.data.data.map(routeRun => {
          routeRun.users = routeRun.users.data
          routeRun.members = _.cloneDeep(routeRun.users)
          routeRun.users.forEach(user => delete user.leader)

          return routeRun
        })

        return {...res, data: {...res.data, data: newData}}
      },
    },
  }
}
