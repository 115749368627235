import {Switch} from '@mui/material'
import styled from 'styled-components'
import colors from '../styles/colors'

export const WhiteBackgroundSwitch = styled(Switch).attrs(() => ({
  classes: {
    track: 'track',
  },
}))`
  & .track {
    background-color: ${colors.grey400};
  }
`
