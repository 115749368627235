import React, { Component } from 'react'
import styled from 'styled-components'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import { graphql } from 'react-apollo'
import compose from 'lodash/flowRight'
import gql from 'graphql-tag'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import FormGroup from '@mui/material/FormGroup'
import ModifyQueryParams from '../../components/ModifyQueryParams'
import moment from 'moment'
import queryString from 'query-string'
import { formatMoneyStandard } from '../../utils/moneyFormatter'
import { DatePicker } from '@mui/lab'
import { LinearProgress, TextField, Typography } from '@mui/material'

let DashboardWrapper = styled.div`
  padding: 16px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 16px;
`

let BoldText = styled.div`
  font-weight: bold;
`

let TopCell = styled(TableCell).attrs(() => ({ classes: { root: 'root' } }))`
  &.root {
    background-color: #222;
    color: white;
  }
`

let BottomCell = styled(TableCell).attrs(() => ({ classes: { root: 'root' } }))`
  &.root {
    background-color: #f2f2f2;
  }
`

let DateSelect = ({ onChange, value }) => (
  <DatePicker
    variant="filled"
    label="Start Date"
    onChange={onChange}
    value={value}
    style={{ marginRight: '2em' }}
    format="MMMM D, YYYY"
    renderInput={props => <TextField {...props} />}
  />
)

let DateSelect2 = ({ onChange, value }) => (
  <DatePicker
    variant="filled"
    label="End Date"
    onChange={onChange}
    value={value}
    style={{ marginRight: '2em' }}
    format="MMMM D, YYYY"
    renderInput={props => <TextField {...props} />}
  />
)

// let delinquents = ({type}) => type === 'Delinquent'
// let sales = ({type}) => type === 'Sales'
// let notSalesOrDeliqunets = ({type}) => !delinquents({type}) && !sales({type})
class StatisticsYearly extends Component {
  state = {
    queryChanges: {},
    today: moment().format('YYYY-MM-DD'),
  }
  render() {
    let { data } = this.props
    let statistics = data?.allYearlystatistics?.edges[0].edge
    let params = queryString.parse(this.props.location.search)

    return (
      <DashboardWrapper>
        <Paper>
          <Typography variant="subtitle2" display="block" style={{ opacity: 0.5 }}>Sales & job count derived from invoice subtotals. Vehicle overhead is no longer tracked. Payroll is derived from timestation.</Typography>
          <Card>
            <CardContent>
              <FormGroup style={{ flexDirection: 'row' }}>
                <DateSelect
                  onChange={e =>
                    this.setState({
                      queryChanges: { start_date: e.format('YYYY-MM-DD') },
                    })
                  }
                  value={
                    params.start_date ||
                    moment()
                      .subtract(52, 'weeks')
                      .format('YYYY-MM-DD')
                  }
                />
                <DateSelect2
                  onChange={e =>
                    this.setState({
                      queryChanges: { end_date: e.format('YYYY-MM-DD') },
                    })
                  }
                  value={params.end_date || this.state.today}
                />
              </FormGroup>
            </CardContent>
          </Card>
          <ModifyQueryParams query={this.state.queryChanges} />
          {data?.loading && <LinearProgress />}
          {statistics ? (

            <Table>
              <TableHead>
                <TableRow>
                  <TopCell>Route</TopCell>
                  <TopCell>Sales</TopCell>
                  <TopCell>Percent of Sales</TopCell>
                  <TopCell>Number Jobs</TopCell>
                  <TopCell>Avg. Sales per Job</TopCell>
                  <TopCell>Miles</TopCell>
                  <TopCell>CPM</TopCell>
                  <TopCell>Vehicle Overhead</TopCell>
                  <TopCell>ST Pay</TopCell>
                  <TopCell>OT Pay</TopCell>
                  <TopCell>Payroll</TopCell>
                  <TopCell>DL/S</TopCell>
                  <TopCell>Man Hours</TopCell>
                  <TopCell>DC</TopCell>
                  <TopCell>DC/S</TopCell>
                  <TopCell>Percent of Cost</TopCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {statistics.yearlyroutestatistics.map(r => {
                  return (
                    <TableRow key={r.id}>
                      <TableCell align="right">
                        <BoldText>{r.route}</BoldText>
                      </TableCell>
                      <TableCell align="right">
                        {formatMoneyStandard(r.sales)}
                      </TableCell>
                      <TableCell>{r.percent_of_sales}</TableCell>
                      <TableCell align="right">{r.number_jobs}</TableCell>
                      <TableCell align="right">
                        {formatMoneyStandard(r.average_sales_per_job)}
                      </TableCell>
                      <TableCell align="right">{r.miles}</TableCell>
                      <TableCell align="right">
                        {formatMoneyStandard(r.cpm)}
                      </TableCell>
                      <TableCell align="right">
                        {formatMoneyStandard(r.vehicle_overhead)}
                      </TableCell>
                      <TableCell align="right">
                        {formatMoneyStandard(r.standard_payroll)}
                      </TableCell>
                      <TableCell align="right">
                        {formatMoneyStandard(r.overtime_payroll)}
                      </TableCell>
                      <TableCell align="right">
                        {formatMoneyStandard(r.total_payroll)}
                      </TableCell>
                      <TableCell>{r.direct_labor_over_sales}</TableCell>
                      <TableCell align="right">{r.man_hours}</TableCell>
                      <TableCell align="right">
                        {formatMoneyStandard(r.total_direct_costs)}
                      </TableCell>
                      <TableCell>{r.direct_costs_over_sales}</TableCell>
                      <TableCell>{r.percent_of_cost}</TableCell>
                    </TableRow>
                  )
                })}
                <TableRow>
                  <BottomCell align="right">
                    <BoldText>All</BoldText>
                  </BottomCell>
                  <BottomCell align="right">
                    <BoldText>{formatMoneyStandard(statistics.sales)}</BoldText>
                  </BottomCell>
                  <BottomCell>
                    <BoldText>100%</BoldText>
                  </BottomCell>
                  <BottomCell align="right">
                    <BoldText>{statistics.number_jobs}</BoldText>
                  </BottomCell>
                  <BottomCell>
                    <BoldText>
                      {formatMoneyStandard(statistics.average_sales_per_job)}
                    </BoldText>
                  </BottomCell>
                  <BottomCell align="right">
                    <BoldText>{statistics.miles}</BoldText>
                  </BottomCell>
                  <BottomCell align="right">
                    <BoldText>{formatMoneyStandard(statistics.cpm)}</BoldText>
                  </BottomCell>
                  <BottomCell align="right">
                    <BoldText>
                      {formatMoneyStandard(statistics.vehicle_overhead)}
                    </BoldText>
                  </BottomCell>
                  <BottomCell align="right">
                    <BoldText>
                      {formatMoneyStandard(statistics.standard_payroll)}
                    </BoldText>
                  </BottomCell>
                  <BottomCell align="right">
                    <BoldText>
                      {formatMoneyStandard(statistics.overtime_payroll)}
                    </BoldText>
                  </BottomCell>
                  <BottomCell align="right">
                    <BoldText>
                      {formatMoneyStandard(statistics.total_payroll)}
                    </BoldText>
                  </BottomCell>
                  <BottomCell>
                    <BoldText>{statistics.direct_labor_over_sales}</BoldText>
                  </BottomCell>
                  <BottomCell align="right">
                    <BoldText>{statistics.man_hours}</BoldText>
                  </BottomCell>
                  <BottomCell align="right">
                    <BoldText>
                      {formatMoneyStandard(statistics.total_direct_costs)}
                    </BoldText>
                  </BottomCell>
                  <BottomCell>
                    <BoldText>{statistics.direct_costs_over_sales}</BoldText>
                  </BottomCell>
                  <BottomCell>
                    <BoldText>100%</BoldText>
                  </BottomCell>
                </TableRow>
              </TableBody>
            </Table>
          ) : null}
        </Paper>
      </DashboardWrapper>
    )
  }
}
let query = gql`
  query statistics($filters: YearlystatisticsFilters = {}) {
    allYearlystatistics(filters: $filters) {
      edges {
        edge {
          id
          sales
          number_jobs
          average_sales_per_job
          miles
          cpm
          vehicle_overhead
          standard_payroll
          overtime_payroll
          total_payroll
          direct_labor_over_sales
          man_hours
          total_direct_costs
          direct_costs_over_sales
          yearlyroutestatistics {
            id
            route
            sales
            number_jobs
            average_sales_per_job
            miles
            cpm
            vehicle_overhead
            standard_payroll
            overtime_payroll
            total_payroll
            direct_labor_over_sales
            man_hours
            sales_per_man_hour
            total_direct_costs
            direct_costs_over_sales
            percent_of_cost
            percent_of_sales
          }
        }
      }
    }
  }
`
export default compose(
  graphql(query, {
    options: props => {
      let params = queryString.parse(props.location.search)

      return {
        variables: {
          filters: { end_date: params.end_date, start_date: params.start_date },
        },
      }
    },
  }),
)(StatisticsYearly)
