import axios from 'axios'
import gql from 'graphql-tag'
import _ from 'lodash'
import React from 'react'
import { useQuery } from 'react-apollo'
import { API_DOMAIN } from '../middleware/api'

let currentUser = null

const auth = {
  login: function(credentials) {
    return axios
      .post(API_DOMAIN + '/login', credentials, { withCredentials: true })
      .then(res => {
        auth.setUser(res.data)
        // securityRetryQueue.retryAll();
        return res
      })
  },
  userIsSet: () => !!currentUser,
  isLoggedIn: state => !!state.auth.get('loggedIn'),
  isInGroup: group => _.contains(this.currentUser.groups, group),
}

let userQuery = gql`
  query currentUser {
    currentUser {
      id
      first_name
      last_name
      email_signature
      roles {
        id
        name
      }
      permissions {
        id
        name
      }
      session_information {
        id
        client_id
        expires_at
      }
    }
  }
`

type AuthStatuses = 'error' | 'loading' | 'authorized' | 'denied' | string

export function useAuth(permission: string): { status: AuthStatuses } {
  let [status, setStatus] = React.useState('loading')
  let { data, error, loading } = useQuery(userQuery)
  React.useEffect(() => {
    if (error) {
      setStatus('error')
    } else if (loading) {
      setStatus('loading')
    } else if (data && data.currentUser) {
      let roles: Array<string> =
        data.currentUser?.roles?.map(({ name }) => name) || []
      let permissions: Array<string> =
        data.currentUser.permissions?.map(({ name }) => name) || []

      if (roles.includes('Administrator') || permissions.includes(permission)) {
        setStatus('authorized')
      } else {
        setStatus('denied')
      }
    }
  }, [loading, data, error])
  return { status }
}

export function useCurrentUser() {
  let [currentUser, setStatus] = React.useState(null)
  let { data, error, loading } = useQuery(userQuery)
  React.useEffect(() => {
    if (error) {
      setStatus('error')
    } else if (loading) {
      setStatus(null)
    } else if (data && data.currentUser) {
      setStatus(data.currentUser)
    }
  }, [loading, data, error])
  return { currentUser }
}

export default auth
