import PropTypes from 'prop-types';
import React from 'react';
import {Api} from './api-connect'
import path from 'path'

class Relationship extends React.Component {
  static propTypes = {
    entity: PropTypes.shape({relationships: PropTypes.object}).isRequired,
    name: PropTypes.string.isRequired,
    endpoint: PropTypes.string,
    children: PropTypes.func.isRequired,
  };

  static contextTypes = {selectEntity: PropTypes.func};

  render() {
    const {entity, name, children, endpoint} = this.props
    let relationship = entity.relationships[name].data
    if (!relationship) {
      return children()
    }

    let related = this.context.selectEntity(relationship)
    if (!related) {
      return (
        <Api
          connect={{
            related: path.join(endpoint || relationship.type, relationship.id),
          }}
        >
          {({related}) => related.fulfilled && children(related.value.data)}
        </Api>
      )
    }

    return children(related)
  }
}
export default Relationship

