import { graphql as apolloGraphql } from 'react-apollo'
import { DocumentNode, graphql as graphqlACTUAL } from 'graphql'

import gql from 'graphql-tag'
import schema, {
  dataIdFromObject,
  getCacheRedirectsFromSchema,
  createEntityLoader,
} from '../schema'
import { print } from 'graphql/language/printer'
export { gql }

export function graphql(query, options = {}) {
  let { loadMoreFns } = options
  return apolloGraphql(query, {
    props: props => {
      let {
        data: { fetchMore, ...queries },
      } = props
      let fns = {}
      if (loadMoreFns) {
        fns = Object.entries(loadMoreFns).reduce(
          (acc, [k, { fieldName, cursorVariable } = {}]) => ({
            ...acc,
            [k]: createLoadMore({
              field: queries[fieldName],
              fieldName,
              fetchMore,
              cursorVariable,
            }),
            [fieldName]: queries[fieldName] && queries[fieldName].edges,
          }),
          {},
        )
      }
      return { ...queries, ...fns }
    },
    ...options,
  })
}

export function createLoadMore({
  field,
  fieldName,
  fetchMore,
  cursorVariable = 'cursor',
}) {
  return () =>
    field.pageInfo.next &&
    fetchMore({
      variables: { [cursorVariable]: field.pageInfo.next },
      updateQuery: (prev, { fetchMoreResult }) => ({
        [fieldName]: {
          ...fetchMoreResult[fieldName],
          edges: [
            ...prev[fieldName].edges,
            ...fetchMoreResult[fieldName].edges,
          ],
        },
      }),
    })
}

export async function prgql({
  query,
  variables,
  operationName,
}: {
  query: DocumentNode,
  variables: { [key: string]: any },
  operationName?: any,
}) {
  let result = await graphqlACTUAL(
    schema,
    print(query),
    null,
    { entityLoader: createEntityLoader() },
    variables,
    operationName,
  )
  // JSON.parse() saying "Unexpected character 'u'"? That means there's a graphql error in the result.
  console.log(result.errors)
  return JSON.parse(JSON.stringify(result.data)) // Shananigans because GraphQL creates an object with no prototypes https://github.com/graphql/graphql-js/pull/504#issuecomment-386821587
}


// @todo: use this method on the other places like in Account and Customer details
export const graphqlHasManyUpdate = ({
  origData = [],
  reldata = [],
  relname,
  validFields = [], // Array of valid fields
}) => {
  const filterProps = (obj, validFields) => {
    return Object.fromEntries(Object.entries(obj).filter(([key]) => validFields.includes(key)));
  }

  // good golly this is so much easier than what i was doing before
  let ids = reldata.map(p => p.id)
  let creates = reldata
    .filter(p => p.id.includes('new'))
    .map(({ id, ...p }) => validFields.length === 0 ? p : filterProps(p, validFields))
  let updates = reldata.filter(p => !p.id.includes('new')).map(p => validFields.length === 0 ? p : filterProps(p, validFields))
  let deletes = origData
    .filter(p => !ids.includes(p.id))
    .map(p => ({ id: p.id }))

  return {
    [relname]: {
      create: creates,
      update: updates,
      destroy: deletes,
    },
  }
}
