// @flow
import _ from 'lodash'
import urlJoin from 'url-join'
import DataLoader from 'dataloader'
import queryString from 'query-string'
import { visit } from 'graphql/language'
// import type {
//   Entity,
//   FetchOptions,
//   ResolveOptions,
//   GetResolverOptions,
//   JApiRes,
//   JApiEntity,
//   Field,
//   FieldResolver,
//   GraphqlAst,
// } from './types'
import store from '../store'
import { addSecurityRetryQueue } from '../actions/authActions'

function parseSelfLink(link = '') {
  let matches = link.match(/^.*\/api\/(?<endpoint>.*)\/(?<id>[^/]*)$/)
  return matches === null ? { endpoint: '', id: '' } : matches.groups
}

// type Serializeable = {id: string, type: string}
function serializeId({ id, type }) {
  return `${type}:${id}`
}
let defaultCreateEntity = ({
  data,
  included,
  _resourceUrl,
  cursor = {},
  total,
  isConnection = true,
} = {}) => {
  let mapEntity = e => {
    let cachedEntity = included.get(serializeId(e)) || {}
    let cachedRels = cachedEntity.relationships || {}
    let rels = _.mapValues(cachedRels, rel => rel.data)
    let { endpoint, id } = parseSelfLink(
      cachedEntity.links ? cachedEntity.links.self : _resourceUrl,
    )
    return {
      id: e.id,
      _apiType: e.type,
      _resourceUrl: Array.isArray(data)
        ? `${_resourceUrl}/${e.id}`
        : _resourceUrl
          ? _resourceUrl
          : urlJoin(endpoint, id),
      _endpointUrl: endpoint,
      _gqlType: e.type.slice(0, 1).toUpperCase() + e.type.slice(1),
      ...cachedEntity.attributes,
      ...rels,
      _links: cachedEntity.links,
      _included: included,
    }
  }

  if (Array.isArray(data)) {
    return isConnection
      ? {
        edges: data.map((data, i) => ({
          edge: mapEntity(data),
          cursor: Math.max(parseInt(cursor.current, 10), 0) + i,
        })),
        pageInfo: { ...cursor, total: total || 0 },
      }
      : data.map(mapEntity)
  }
  return mapEntity(data)
}

export function resolveCachedEntity(entityRef, included) {
  return entityRef
    ? defaultCreateEntity({ data: entityRef, included, isConnection: false })
    : null
}

export async function fetchAPI({
  url,
  query,
  options = {},
  prefixUrl = true,
} = {}) {
  let createRequest = () => {
    let { headers: headersOverride, ...restOptions } = options
    let headers = new Headers()
    headers.set('accept', 'application/json')
    headers.set(
      'Authorization',
      `Bearer ${localStorage.getItem && localStorage.getItem('token')}`,
    )
    for (let [k, v] of Object.entries(headersOverride || {})) {
      headers.set(k, v)
    }
    let mainUrl = url.includes('://')
      ? url.replace('http://pathrabbit.dev', '')
      : prefixUrl
        ? urlJoin('/api', url)
        : url
    let search = query
      ? '?' +
      queryString.stringify({
        cursor: -1,
        ...query,
      })
      : ''
    return new Request(mainUrl + search, {
      headers,
      credentials: 'same-origin',
      ...restOptions,
    })
  }
  let req = createRequest()
  let res = await fetch(req)
  if (res.status === 401) {
    let retry = new Promise((fulfill, reject) => {
      store.dispatch(
        addSecurityRetryQueue({
          reason: 'unauthorized server',
          retry: () => {
            let req = createRequest()
            fetch(req)
              .then(res => fulfill(res))
              .catch(reject)
          },
        }),
      )
    })
    res = await retry
  }
  return res
}

export const fetchEndpoint = async ({
  url,
  query,
  options = {},
  createEntity = defaultCreateEntity,
  prefixUrl = true,
  isConnection = true,
} = {}) => {
  let res = await fetchAPI({ url, query, options, prefixUrl })
  let {
    data,
    included = [],
    meta,
    error,
    errors,
    message,
    ...rest // eslint-disable-line
  } = await res.json()

  if (res.status === 404) {
    return null
  }

  if (error && errors.length === 0) {
    throw new Error(message)
  }

  if (error) {
    return { message, errors, data, included }
  }

  // data won't be set on delete operation
  if (!data) {
    return { message, error }
  }

  let entities = Array.isArray(data)
    ? [...included, ...data]
    : [...included, data]
  let entityCache = new Map()
  entities.forEach(e => {
    entityCache.set(serializeId(e), e)
  })
  return createEntity({
    data,
    included: entityCache,
    _resourceUrl: url,
    cursor: meta && meta.cursor,
    total: meta && meta.total,
    isConnection,
  })
}

// type LoaderRel = {relationship: string, includes: string, id: string}
export const createIncludeLoader = (url) =>
  new DataLoader((keys) => {
    let include = keys
      .map(k => k.includes)
      .join(',')
      .split(',')
    // dedupe includes
    include = [...new Set(include)]
    let ids = [...new Set(keys.map(({ id }) => id))]
    if (ids.length === 1 && ids[0] === undefined) {
      return Promise.resolve(keys.map(() => null))
    }
    return (fetchEndpoint({
      url: urlJoin(url, 'batch'),
      query: {
        include: include.join(','),
        ids: ids.join(','),
        limit: ids.length,
      },
      createEntity: defaultCreateEntity,
      isConnection: false,
    })).then((res) => {
      return keys.map(({ relationship, id }) => {
        let entity = res.find(({ id: entityId }) => entityId === id)
        if (entity && relationship) {
          // if .load() called with {relationship} resolve
          // nested relationship
          return resolveCachedEntity(entity[relationship], entity._included)
        } else {
          // if .load() not called with {relationship}
          // resolve root entity
          return entity
        }
      })
    })
  })

export function createEntityLoader() {
  let loaders = {}

  return new DataLoader(keys => {
    return Promise.all(
      keys.map(({ url, includes, relationship, id }) => {
        if (!(url in loaders)) {
          loaders[url] = createIncludeLoader(url)
        }
        let loader = loaders[url]
        return loader.load({ includes, relationship, id })
      }),
    )
  })
}

function getFieldFromPath(type, path) {
  return path.reduce(
    (field, nextFieldName) => {
      let nextFields = field.type.ofType
        ? field.type.ofType.getFields()
        : field.type.getFields()
      return nextFields[nextFieldName]
    },
    { type },
  )
}

export const getIncludes = ({
  info,
  node,
}) => {
  let rootNode

  if (node) {
    rootNode = node
  } else {
    let fieldName = info.fieldName
    rootNode = _.find(
      info.fieldNodes,
      node => _.get(node, 'name.value') === fieldName,
    )
  }

  let includePaths = []
  let currentPath = []
  let toInclude = []
  visit(rootNode, {
    FragmentSpread: {
      enter(node) {
        return info.fragments[node.name.value]
      },
    },
    Field: {
      enter(node, key, parent, path, ancestors) {
        let fieldPath = ancestors
          .filter(n => n.kind === 'Field')
          .map(f => f.name.value)
          .concat(node.name.value)
        let schemaField = getFieldFromPath(info.parentType, fieldPath)
        if (schemaField && schemaField.relationship) {
          toInclude.push(schemaField.relationshipIncludeName || node.name.value)
        }
      },
      leave(node, key, parent, path) {
        let lastInclude = toInclude.length
          ? toInclude[toInclude.length - 1]
          : currentPath[currentPath.length - 1]
        if (lastInclude === node.name.value) {
          if (toInclude.length) {
            let includePath = [...currentPath, ...toInclude]
            includePaths = [...includePaths, includePath]
            currentPath = [...includePath]
            toInclude = []
          }
          currentPath.pop()
        }
      },
    },
  })

  return includePaths.map(path => path.join('.')).join(',')
}

/* generic resolver logic
    Single Resource - customer(id: String!)
    start with endpoint
    if relationships { rel, otherfield } /endpoint/:id?include=rel
    if relationships field { rel(args) } /endpoint/:id/rel?args
    if relationships field { rel(args), otherfield } /endpoint/:id & /endpoint/:id/rel?args
*/
export const resolve = ({
  url,
  createEntity = defaultCreateEntity,
  query,
  info,
  prefixUrl,
  isConnection,
}) => {
  let include = getIncludes({ info })
  let fullQuery = { include: include !== '' ? include : undefined, ...query }
  return fetchEndpoint({
    url,
    query: fullQuery,
    createEntity,
    prefixUrl,
    isConnection,
  })
}

// type ResolveEntityOptions = {
//   endpoint: string,
//   include: Array<string>,
// }
export function resolveEntity({
  endpoint,
  include,
} = {}) {
  return (root, { id }, context, info) =>
    resolve({ url: urlJoin('/', endpoint, id), info })
}

// type RelResolveMap = {
//   [key: string]: 'nested' | 'include' | GetResolverOptions,
// }
export const resolveRels = (resolvers) => (root) => {
  if (_.isEmpty(root)) return {}

  let includeLoader = createIncludeLoader(root._links.self)

  return {
    ...root.relationships,
    ..._.mapValues(resolvers, (r, key) => (args, context, info) => {
      if (root.relationships && root.relationships[info.fieldName]) {
        return root.relationships[info.fieldName]
      }
      if (r === 'include') {
        return includeLoader.load({
          relationship: key,
          includes: getIncludes({ info, includePath: key }),
        })
      }
      if (r === 'nested') {
        return resolve({
          info,
          context,
          query: args,
          url: `${root._links.self}/${info.fieldName}`,
        })
      }
      let { then = res => res, ...resolveConfig } = r({
        root,
        args,
        context,
        info,
      })
      return resolve({ info, context, ...resolveConfig }).then(then)
    }),
  }
}

export async function openFile({ url, fileType, prefixUrl = false, fileName }) {
  let res = await fetchAPI({
    url,
    options: {
      responseType: 'arraybuffer',
      headers: { Accept: fileType },
    },
    prefixUrl,
  })
  let data = await res.blob()
  let file = new Blob([data], { type: fileType })
  let fileUrl = URL.createObjectURL(file)
  let link = document.createElement('a')
  link.href = fileUrl
  link.target = '_blank'
  if (fileName) {
    link.download = fileName
  }
  link.click()
}
