import React from 'react'
import styled from 'styled-components'
import {Query, Mutation} from 'react-apollo'
import gql from 'graphql-tag'
import moment from 'moment'

import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'
import TextField from '@mui/material/TextField'
import CircularProgress from '@mui/material/CircularProgress'
import {DatePicker} from '@mui/lab'

import SaveIcon from '@mui/icons-material/Save'

import {
  DetailToolbar,
  ToolbarLeft,
  CloseButton,
  ToolbarTitle,
  ToolbarRight,
  ToolbarIconMenu,
  DeleteMenuItem,
} from '../../components/Toolbar'
import {NavigateBack, RedirectBack} from '../../components/Navigator/index'
import Loading from '../../components/Loading'
import {Space} from '../../components/Layout'
import DeleteDialog from '../../components/DeleteDialog'
import {InputAdornment} from '@mui/material'

let LeftIcon = styled.div`
  margin-right: ${({theme}) => theme.muiTheme.spacing(1)};
`

let ScreenToolbar = ({
  defaultBack,
  canSave,
  loss,
  onSave,
  isSaving,
  isNew,
  onDelete,
}) => (
  <DetailToolbar>
    <ToolbarLeft>
      <NavigateBack defaultBack={defaultBack} marker="EmployeeLossBack">
        <CloseButton />
      </NavigateBack>
    </ToolbarLeft>
    <ToolbarTitle>
      {loss && moment.utc(loss.loss_at).format('dddd, MMMM D, YYYY')}
    </ToolbarTitle>
    <ToolbarRight>
      <Space sizePx={16} inline />
      <Button onClick={onSave} disabled={!canSave} color="primary">
        <LeftIcon>
          {isSaving ? (
            <CircularProgress size={24} thickness={3} />
          ) : (
            <SaveIcon />
          )}
        </LeftIcon>
        Save
      </Button>
      {!isNew && (
        <ToolbarIconMenu>
          <DeleteMenuItem onClick={onDelete} />
        </ToolbarIconMenu>
      )}
    </ToolbarRight>
  </DetailToolbar>
)

let lossEntryQuery = gql`
  query lossEntryQuery($id: String!) {
    losses(id: $id) {
      id
      loss_at
      cost
      notes
    }
  }
`

let updateLossQuery = gql`
  mutation lossEntryQuery($input: UpdateLossesInput) {
    updateLosses(input: $input) {
      losses {
        id
        loss_at
        cost
        notes
      }
    }
  }
`
let createLossQuery = gql`
  mutation createLossesEntryQuery($input: CreateLossesInput) {
    createLosses(input: $input) {
      losses {
        id
        loss_at
        cost
        notes
      }
    }
  }
`

let deleteQuery = gql`
  mutation deleteLossEntry($id: String!) {
    deleteLosses(input: {id: $id}) {
      message
    }
  }
`

let EditRow = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 16px;
`

let CostInput = ({cost, onChange}) => (
  <TextField
    label="Cost"
    value={cost}
    variant="filled"
    InputProps={{
      startAdornment: <InputAdornment position="start">$</InputAdornment>,
    }}
    onChange={e => {
      onChange({
        cost: parseFloat(e.target.value) || '',
      })
    }}
  />
)

class DetailEdit extends React.Component {
  state = {isCustom: false}

  render() {
    let {loading, data, onChange} = this.props
    let createHandler = key => e => onChange({[key]: e.target.value})

    return loading || !data ? (
      <Loading />
    ) : (
      <>
        <EditRow>
          <DatePicker
            label="Date"
            value={moment.utc(data.loss_at)}
            inputFormat="MMMM D, YYYY"
            onChange={d => onChange({loss_at: d.format('YYYY-MM-DD')})}
            renderInput={props => <TextField {...props} variant="filled" />}
          />
          <CostInput cost={data.cost} onChange={onChange} />
        </EditRow>
        <Space sizePx={16} />
        <TextField
          label="Notes"
          variant="filled"
          multiline
          onChange={createHandler('notes')}
          fullWidth
          value={data.notes}
        />
      </>
    )
  }
}

let PaperContainer = styled(Paper)`
  max-width: 960px;
  margin: 16px auto 0;
`

let Content = styled.div`
  padding: 16px;
`

let DeleteLoss = ({id, showDelete, toggleDelete, onDelete}) => (
  <Mutation mutation={deleteQuery} variables={{id}}>
    {deleteMut => (
      <DeleteDialog
        title="Delete Loss?"
        message="Are you sure you want to delete this Loss?"
        isOpen={showDelete}
        onCancel={toggleDelete}
        onConfirm={() => {
          deleteMut().then(onDelete)
        }}
      />
    )}
  </Mutation>
)

let mergeChanges = (original, changes) => {
  let defaultLoss = {
    cost: 0,
    notes: '',
    loss_at: moment().format('YYYY-MM-DD'),
  }
  return {...defaultLoss, ...original, ...changes}
}

class LossEntry extends React.Component {
  state = {
    goBack: false,
    changes: {},
    showDelete: false,
  }

  render() {
    let {match} = this.props
    let {params = {}} = match
    let {changes, goBack, showDelete} = this.state
    let canSave = Object.keys(changes).length > 0
    let defaultBack = `/employees/${params.id}/losses`
    let setGoBack = () => this.setState({goBack: true})
    let toggleDelete = () =>
      this.setState(({showDelete}) => ({showDelete: !showDelete}))

    return (
      <Query query={lossEntryQuery} variables={{id: params.lossId}}>
        {({data, loading, error}) => (
          <PaperContainer>
            <Mutation
              mutation={updateLossQuery}
              variables={{
                input: {
                  id: params.lossId,
                  ...changes,
                },
              }}
            >
              {(update, result) => (
                <ScreenToolbar
                  loss={mergeChanges(data.losses, changes)}
                  defaultBack={defaultBack}
                  onSave={() => update().then(setGoBack)}
                  onDelete={() => this.setState({showDelete: true})}
                  canSave={canSave}
                  isSaving={result && result.loading}
                />
              )}
            </Mutation>
            <Content>
              <DetailEdit
                data={mergeChanges(data.losses, changes)}
                loading={loading}
                onChange={newChanges =>
                  this.setState(({changes}) => ({
                    changes: {...changes, ...newChanges},
                  }))
                }
              />
            </Content>
            <DeleteLoss
              id={params.lossId}
              showDelete={showDelete}
              toggleDelete={toggleDelete}
              onDelete={setGoBack}
            />
            {goBack && (
              <RedirectBack
                defaultBack={defaultBack}
                marker="EmployeeLossBack"
              />
            )}
          </PaperContainer>
        )}
      </Query>
    )
  }
}

export default LossEntry

export class NewLossEntry extends React.Component {
  state = {
    goBack: false,
    changes: {},
  }

  render() {
    let {match} = this.props
    let {params = {}} = match
    let {changes, goBack} = this.state
    let canSave = Object.keys(changes).length > 0
    let defaultBack = `/employees/${params.id}/losses`
    let computedLoss = mergeChanges({}, changes)

    return (
      <PaperContainer>
        <Mutation
          mutation={createLossQuery}
          variables={{
            input: {user_id: params.id, ...computedLoss},
          }}
        >
          {(update, result) => (
            <ScreenToolbar
              loss={computedLoss}
              defaultBack={defaultBack}
              onSave={() =>
                update().then(() => {
                  this.setState({goBack: true})
                })
              }
              canSave={canSave}
              isSaving={result && result.loading}
            />
          )}
        </Mutation>
        <Content>
          <DetailEdit
            isNew
            data={computedLoss}
            onChange={newChanges =>
              this.setState(({changes}) => ({
                changes: {...changes, ...newChanges},
              }))
            }
          />
        </Content>
        {goBack && (
          <RedirectBack defaultBack={defaultBack} marker="EmployeeLossBack" />
        )}
      </PaperContainer>
    )
  }
}
