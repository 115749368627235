// @flow
import {parse} from 'graphql/language'
import {makeExecutableSchema} from 'graphql-tools'
// import type {ITypedef, IResolvers} from 'graphql-tools'
// import type {
//   ObjectTypeDefinitionNode,
//   FieldDefinitionNode,
//   DocumentNode,
// } from 'graphql/language'
import {GraphQLSchema} from 'graphql/type'
import {GraphQLObjectType} from 'graphql/type/definition'
// import type {GraphQLField} from 'graphql/type/definition'
import _ from 'lodash'

let getSourceSlice = ({start, end, source}) => source.body.slice(start, end)

// type DirectiveArgs = {[key: string]: mixed}
// type SourceDirectiveFn = ({
//   astParentDefinition: ObjectTypeDefinitionNode,
//   astDefinition: FieldDefinitionNode,
//   fieldType: string,
//   addArguments: (newArgs: string) => void,
//   addTypes: (typeDefs: string) => void,
//   transformType: (newType: string) => void,
// }) => void
// type SchemaDirectiveFn = <TSource, TContext>({
//   astParentDefinition: ObjectTypeDefinitionNode,
//   astDefinition: FieldDefinitionNode,
//   parentDefinition: GraphQLObjectType,
//   definition: GraphQLField<TSource, TContext>,
//   args: DirectiveArgs,
// }) => void
// type DirectiveConfig = {
//   source: SourceDirectiveFn,
//   schema: SchemaDirectiveFn,
//   types: string,
// }
// type DirectiveMap = {
//   [name: string]: DirectiveConfig,
// }

// type CreateSchemaOptions = {
//   typeDefs: ITypedef[],
//   resolvers: IResolvers,
//   directives: DirectiveMap,
// }
export function createSchemaWithDirectives({
  typeDefs,
  resolvers,
  directives,
}) {
  let sourceDirectives = Object.keys(directives).reduce((dirMap, dirName) => {
    let dir = directives[dirName]
    if (!dir.source && !dir.sourceType) return dirMap
    return {...dirMap, [dirName]: {field: dir.source, type: dir.sourceType}}
  }, {})
  let schemaDirectives = Object.keys(directives).reduce((dirMap, dirName) => {
    let dir = directives[dirName]
    if (!dir.schema && !dir.schemaType) return dirMap
    return {...dirMap, [dirName]: {field: dir.schema, type: dir.schemaType}}
  }, {})
  let directiveDefs = Object.values(directives).reduce(
    (acc, dir) => (dir && dir.types ? [...acc, dir.types] : acc),
    [],
  )
  let {source, resolvers: directiveResolvers} = transformSourceByDirectives({
    typeDefs,
    directives: sourceDirectives,
  })

  let schema
  try {
    schema = makeExecutableSchema({
      typeDefs: [source, ...directiveDefs],
      resolvers: _.merge(resolvers, ...directiveResolvers),
    })
  } catch (e) {
    console.error(
      'Error generating modified source schema from directives',
      source,
    )
    throw e
  }
  addCustomDirectives(schema, {
    typeDefs: [source],
    directives: schemaDirectives,
  })
  return schema
}

// type TransformSourceOptions = {
//   typeDefs: ITypedef[],
//   directives: {[key: string]: SourceDirectiveFn},
// }
export function transformSourceByDirectives({
  typeDefs,
  directives,
}){
  let source = typeDefs.join('\n')
  let documentAST = parse(source)

  let transforms = []
  let newTypeDefs = []
  let resolvers = []

  let addResolvers = r => resolvers.push(r)

  executeDirectives({
    documentAST,
    directives,
    executor: ({astParentDefinition, astDefinition, directive, args}) => {
      // if parent, this is a field.
      let loc = astDefinition.type.loc
      if (!loc) return
      let start = loc.start
      let end = loc.end
      let fieldType = loc.source.body.slice(start, end)
      directive({
        astParentDefinition,
        astDefinition,
        fieldType,
        addArguments: newArgs => {
          let argLoc, formattedArg
          let nameLoc = astDefinition.name.loc
          if (!nameLoc) return
          if (!astDefinition.arguments.length) {
            argLoc = nameLoc.end
            formattedArg = '(' + newArgs + ')'
          } else {
            let lastArgLoc =
              astDefinition.arguments[astDefinition.arguments.length - 1].loc
            if (!lastArgLoc) return
            argLoc = lastArgLoc.end
            formattedArg = ' ' + newArgs
          }
          transforms.push({
            start: argLoc,
            end: argLoc,
            replace: formattedArg,
          })
        },
        addTypes: typeDefs => {
          newTypeDefs.push(typeDefs)
        },
        addResolvers,
        transformType: newType => {
          transforms.push({
            start,
            end,
            replace: newType,
          })
        },
        args,
      })
    },
    typeExecutor: ({astDefinition, directive, args}) => {
      if (!astDefinition.name.loc) return
      let name = getSourceSlice(astDefinition.name.loc)
      directive({
        astDefinition,
        name,
        args,
        addTypes: typeDefs => {
          newTypeDefs.push(typeDefs)
        },
        addResolvers,
      })
    },
  })

  transforms.sort((ta, tb) => (ta.start - tb.start) * -1).forEach(t => {
    let start = source.slice(0, t.start)
    let end = source.slice(t.end)
    source = start + t.replace + end
  })

  return {source: source + '\n' + newTypeDefs.join('\n'), resolvers}
}

// type TransformSchemaOptions = {
//   typeDefs: ITypedef[],
//   directives: {[key: string]: SchemaDirectiveFn},
// }
export function addCustomDirectives(
  schema,
  {typeDefs, directives},
) {
  let documentAST = parse(typeDefs.join('\n'))

  executeDirectives({
    documentAST,
    directives,
    executor: ({astParentDefinition, astDefinition, directive, args}) => {
      let schemaType = schema.getType(astParentDefinition.name.value)
      let schemaField = schemaType._fields[astDefinition.name.value]
      directive({
        astParentDefinition,
        astDefinition,
        parentDefinition: schemaType,
        definition: schemaField,
        args,
      })
    },
    typeExecutor: ({astDefinition, directive, args}) => {
      let schemaType = schema.getType(astDefinition.name.value)
      if (!(schemaType instanceof GraphQLObjectType)) return
      directive({astDefinition, definition: schemaType, args})
    },
  })
}

// type ExecuteDirectivesOptions<TDirFn> = {
//   documentAST: DocumentNode,
//   directives: {
//     [name: string]: TDirFn,
//   },
//   executor: ({
//     astParentDefinition: ObjectTypeDefinitionNode,
//     astDefinition: FieldDefinitionNode,
//     directive: TDirFn,
//     args: DirectiveArgs,
//   }) => void,
// }
function executeDirectives({
  documentAST,
  directives,
  executor,
  typeExecutor,
}) {
  let argsArrayToMap = args =>
    args.reduce(
      (argMap, arg) => ({
        ...argMap,
        [arg.name.value]: arg.value.value ? arg.value.value : null,
      }),
      {},
    )

  for (let astDef of documentAST.definitions) {
    if (
      astDef.kind !== 'ObjectTypeDefinition' &&
      astDef.kind !== 'ObjectTypeExtension'
    )
      continue
    let def = astDef
    // Execute Type directives
    if (Array.isArray(def.directives)) {
      for (let directive of def.directives) {
        let customDir = directives[directive.name.value]
        if (!customDir || !customDir.type) continue
        let args = argsArrayToMap(directive.arguments || [])
        typeExecutor &&
          typeExecutor({
            astDefinition: def,
            directive: customDir.type,
            args,
          })
      }
    }
    // Execute Field directives
    for (let field of def.fields) {
      let dirs = field.directives
      if (!Array.isArray(dirs)) continue
      for (let directive of dirs) {
        let customDir = directives[directive.name.value]
        let directiveArgs = directive.arguments || []
        if (!customDir || !customDir.field) continue
        let args = argsArrayToMap(directiveArgs)
        executor({
          astParentDefinition: def,
          astDefinition: field,
          directive: customDir.field,
          args,
        })
      }
    }
  }
}
