import React, {useState} from 'react'
import gql from 'graphql-tag'
import {graphql} from 'react-apollo'
import styled from 'styled-components'
import {Redirect, Link} from '../../pr-router'
import {PRThemeProvider} from '../../styles/theme'
import urlJoin from 'url-join'
import PropTypes from 'prop-types'
import queryString from 'query-string'

import Fab from '@mui/material/Fab'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Tooltip from '@mui/material/Tooltip'
import Button from '@mui/material/Button'

import AddIcon from '@mui/icons-material/Add'

import {Toolbar} from '../../AppHandler'

import ModifyQueryParams from '../../components/ModifyQueryParams'
import InventoryList from './InventoryList'
import ToolbarGroup from '../../components/ToolbarGroup'
import SearchBox from '../../components/SearchBox'
import PaginationContainer from '../../components/PaginationContainer'
import AddDialog from '../../components/AddDialog'
import {useDebounce} from '../../utils'

let InventoryListStyled = styled(InventoryList)`
  max-width: 920px;
  margin: 20px auto;
`
let SearchBoxGroup = styled(ToolbarGroup)`
  flex-grow: 1;
`

let addQuery = gql`
  query ExistingItems($description: String) {
    existing: allInventoryItems(filters: {description: $description}) {
      edges {
        edge {
          id
          description
        }
      }
    }
  }
`
let InventoryAddDialog = ({
  loading,
  onCreate,
  existing,
  search,
  open,
  onClose,
}) => (
  <AddDialog
    onCreate={onCreate}
    loading={loading}
    open={open}
    onClose={onClose}
    search={search}
    title="Create Inventory Item"
    listTitle="Existing Inventory"
    hintText="Inventory name"
    existing={existing && existing.edges}
    renderExisting={({edge: {id, description}}) => (
      <Link to={'/inventory/' + id}>
        <ListItem key={id}>
          <ListItemText primary={description} />
        </ListItem>
      </Link>
    )}
  />
)
InventoryAddDialog = graphql(addQuery, {
  options: p => ({variables: {description: ''}}),
  props: ({data: {refetch, existing, ...queries}}) => ({
    existing,
    search: description => refetch({description}),
    ...queries,
  }),
})(InventoryAddDialog)

class InventoryToolbar extends React.Component {
  static propTypes = {
    searchQuery: PropTypes.string,
    onQueryChange: PropTypes.func,
  }
  state = {
    searchFocused: true,
    addDialogIsOpen: false,
    isSearching: false,
    newItemDescription: false,
  }
  componentDidUpdate(prevProps) {
    if (this.props.searchBy !== prevProps.searchBy) this.refs.searchBox.focus()
  }

  updateQuery(queryChanges) {
    const {onQueryChange} = this.props

    if (onQueryChange) onQueryChange(queryChanges)
  }

  onSearch = query => {
    this.updateQuery({q: query})
  }

  handleCreate = d => this.setState({newItemDescription: d})
  render() {
    const {searchQuery, match} = this.props
    let {newItemDescription} = this.state

    return newItemDescription ? (
      <Redirect
        to={{
          pathname: urlJoin(match.url, 'add'),
          state: {
            newItem: {description: newItemDescription},
            backLocation: {
              pathname: this.props.location.pathname,
              query: this.props.location.query,
            },
          },
        }}
        push
      />
    ) : (
      <Toolbar>
        <SearchBoxGroup>
          <SearchBox
            ref="searchBox"
            style={{flexGrow: 1}}
            value={searchQuery}
            onSearch={this.onSearch}
            onEnter={this.onSearch}
          />
        </SearchBoxGroup>
        <ToolbarGroup last={true}>
          <Tooltip title="Export inventory to csv">
            <Button href="/old/database/inventory_csv.php">CSV</Button>
          </Tooltip>

          </ToolbarGroup>
        <Fab
          onClick={() => this.setState({addDialogIsOpen: true})}
          color="secondary"
        >
          <AddIcon />
        </Fab>
        <PRThemeProvider>
          <InventoryAddDialog
            params={this.props.params}
            onCreate={this.handleCreate}
            open={this.state.addDialogIsOpen}
            onClose={() => this.setState({addDialogIsOpen: false})}
          />
        </PRThemeProvider>
      </Toolbar>
    )
  }
}
export {InventoryToolbar}

let inventoryQuery = gql`
  query InventoryItems(
    $cursor: String
    $filters: InventoryItemFilters
    $limit: Int = 25
  ) {
    inventoryitems: allInventoryItems(
      cursor: $cursor
      limit: $limit
      filters: $filters
      orderBy: description_ASC
    ) @connection(key: "inventoryitems", filter: ["filters"]) {
      edges {
        edge {
          ...InventoryItemListDetail
        }
        cursor
      }
      pageInfo {
        next
        current
        prev
        count
        total
      }
    }
  }
  ${InventoryList.fragments.inventoryitems}
`

function Inventory({location, match}) {
  const {q: description = ''} = queryString.parse(location.search)
  let [queryChanges, setQueryChanges] = useState({q: description})
  let [debouncedChanges] = useDebounce(queryChanges, 300)

  return (
    <React.Fragment>
      <InventoryToolbar
        searchQuery={queryChanges.q}
        onQueryChange={changes => setQueryChanges(changes)}
        match={match}
        location={location}
      />
      <ModifyQueryParams query={debouncedChanges} state={{}} />
      <PaginationContainer
        query={inventoryQuery}
        variables={{
          filters: {description},
          cursor: '-1',
        }}
        children={({inventoryitems, loadMore, loading}) => (
          <InventoryListStyled
            inventoryitems={inventoryitems && inventoryitems.edges}
            pageInfo={inventoryitems && inventoryitems.pageInfo}
            onInfiniteLoad={loadMore}
            loading={loading}
          />
        )}
      />
    </React.Fragment>
  )
}

export default Inventory
