import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Column } from 'react-virtualized'
import gql from 'graphql-tag'
import moment from 'moment'

import Typography from '@mui/material/Typography'

import TableList from '../../components/Table/TableList'
import { formatMoneyStandard } from '../../utils/moneyFormatter'
import { useQuery } from 'react-query'
import { prgql } from '../../utils/graphql'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Tab,
  Tabs,
} from '@mui/material'
import { fetchAPI } from '../../schema/utils'
import { Code, CodeBlock } from '../../components/Code'
import { usePagination } from '../../components/PaginationContainer'
import { Toolbar } from '../../AppHandler'
import {
  ToolbarCenter,
  ToolbarLeft,
  ToolbarRight,
} from '../../components/Toolbar'
import ToolbarDropDownMenu from '../../components/ToolbarDropDownMenu'
import queryString from 'query-string'
import ModifyQueryParams from '../../components/ModifyQueryParams'
import { TYPES as LOGTYPES } from '../../constants/ActivityLogs'
import SearchBox from '../../components/SearchBox'
import { useDebounce } from '../../utils'
import { Space } from '../../components/Layout'
import { UserSelectDynamic as UserSelect } from '../../components/UsersSelect'
import { read, utils } from 'xlsx'

let query = gql`
  query logs($cursor: String, $filters: ActivityLogFilters, $limit: Int = 25) {
    logs: allActivityLogs(
      filters: $filters
      limit: $limit
      cursor: $cursor
      orderBy: logged_at_DESC
    ) {
      edges {
        edge {
          id
          message
          type
          logged_at
          successful
          user {
            id
            first_name
            last_name
          }
          data
          documents {
            id
            name
            description
            url
          }
        }
        cursor
      }
      pageInfo {
        next
        current
        prev
        count
        total
      }
    }
  }
`

let LogsTableList = styled(TableList)`
  & .error {
    background-color: rgba(255, 0, 0, 0.54);
  }

  & {
    cursor: pointer;
  }
`

let PreviewWindow = styled.iframe`
  width: 80%;
  height: 75vh;
  border: ${({ theme }) => `${theme.palette.primary.main} 3px solid`};
  border-radius: 15px;
`

let PreviewWindowDiv = styled.div`
  width: 80%;
  height: 75vh;
  border: ${({ theme }) => `${theme.palette.primary.main} 3px solid`};
  border-radius: 15px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`

let Sheet = styled.div`
  padding: 1em;

  td {
    padding: 0.15em;
    border: 1px solid #ccc;
  }
`

let SheetPreview = ({ sheet }) => {
  const [tab, setTab] = useState(Object.keys(sheet.Sheets || [])[0])

  let html = ''
  if (sheet.Sheets[tab]) {
    html = utils.sheet_to_html(sheet.Sheets[tab])
  }

  return (
    <PreviewWindowDiv>
      <div style={{ overflow: 'scroll', display: 'flex', flexDirection: 'row' }}>
        <Tabs
          indicatorColor="secondary"
          textColor="inherit"
          orientation="vertical"
          variant="scrollable"
          style={{ flex: 1, padding: '.25em' }}
          value={Object.keys(sheet.Sheets).indexOf(tab)}
        >
          {Object.keys(sheet.Sheets).map(key => {
            return (
              <Tab
                key={key}
                label={key}
                onClick={() => setTab(key)}
                style={{
                  background: 'white',
                  color: 'black',
                }}
              />
            )
          })}
        </Tabs>
        <Sheet dangerouslySetInnerHTML={{ __html: html }} style={{ flex: 5 }} />
      </div>
    </PreviewWindowDiv>
  )
}

let PreviewDialog = ({ showDetail, setShowDetail, log, blobs }) => {
  return (
    <Dialog open={showDetail} maxWidth="xl" fullWidth>
      <DialogTitle>
        Log Details{' '}
        {moment
          .utc(log.logged_at)
          .local()
          .format('YYYY-MM-DD HH:mm:ss')}
      </DialogTitle>
      <DialogContent style={{ height: '100%' }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography>
              Logged at:{' '}
              {moment
                .utc(log.logged_at)
                .local()
                .format('YYYY-MM-DD HH:mm:ss')}
            </Typography>
            <Typography>Message: {log.message}</Typography>
          </Grid>
          {!!log && log.type === 'EMAIL' && !!log.data && (
            // NOT I.E. COMPATIBLE. not that we care.
            <Grid item xs={12} container alignItems="center" direction="column">
              <Typography variant="subtitle1" align="left">
                Email Body
              </Typography>
              <PreviewWindow srcDoc={log.data}></PreviewWindow>
            </Grid>
          )}
          {!!log && log.type === 'MESSAGE' && !!log.data && (
            <Grid item xs={12} container alignItems="center" direction="column">
              <Typography variant="subtitle1" align="left">
                Message
              </Typography>
              <CodeBlock>{log.data}</CodeBlock>
              <Space />
              <Typography variant="caption">
                Note: log system does not track if technician photos were sent
                or not
              </Typography>
            </Grid>
          )}
          {log.documents?.map(d => {
            const [name, ext] = d.name.split('.')

            let _render = () => {
              if (ext && ext === 'xlsx') {
                const sheet = read(blobs[d.id], { type: 'binary' })
                return <SheetPreview key={d.id} sheet={sheet} />
              }

              return <PreviewWindow src={blobs[d.id]}></PreviewWindow>
            }

            return (
              <Grid
                item
                xs={12}
                container
                alignItems="center"
                direction="column"
                key={d.id}
              >
                <Typography variant="subtitle1" align="left">
                  {d.description}
                </Typography>
                {!!blobs[d.id] ? (
                  _render()
                ) : (
                  <PreviewWindow>Loading</PreviewWindow>
                )}
              </Grid>
            )
          })}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setShowDetail(false)}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}

function mungeQuery(query) {
  function munge(prop, validation) {
    return validation.includes(prop) ? prop : validation[0]
  }

  let parsed = queryString.parse(query)

  let type = munge(parsed.type, ['all', ...Object.keys(LOGTYPES)])

  let search = parsed.search

  let user_id = parsed.user_id

  return { type, search, user_id }
}

// @todo: Make all the Activity Log screens one big master screen. I've just been copy / pasting
function ActivityLog({
  match,
  location,
  resourceId,
  resource = 'customers',
  ...props
}) {
  let [queryChanges, setQueryChanges] = useState({})
  let { params = {} } = match
  let { type, search, user_id } = mungeQuery(location.search)
  let [debouncedSearch] = useDebounce(queryChanges.search, 300)

  let { data, loading: isLoading, loadMore, refetch } = usePagination({
    query,
    variables: {
      cursor: '-1',
      filters: {
        resource: `${resource}-${resourceId}`,
        type: type === 'all' ? undefined : type,
        search: !!search ? search : undefined,
        user_id,
      },
    },
  })

  let [blobs, setBlobs] = useState({})
  let [showDetail, setShowDetail] = useState(false)
  let [log, setLog] = useState({})

  useEffect(() => {
    if (!!log) {
      log.documents?.forEach(fetchDoc)
    }
  }, [String(isLoading), log?.id])

  async function fetchDoc(d) {
    let response = await fetchAPI({
      url: d.url,
      options: { method: 'GET' },
    })
    const [name, ext] = d.name.split('.')

    if (ext && ext === 'xlsx') {
      let buffer = await response.arrayBuffer()
      setBlobs(prev => ({ ...prev, [d.id]: buffer }))
    } else {
      let blob = await response.blob()
      let data_url = URL.createObjectURL(blob)
      setBlobs(prev => ({ ...prev, [d.id]: data_url }))
    }
  }

  let getRowCount = pageInfo => {
    if (!pageInfo) {
      return 9999
    }
    return pageInfo.total
  }

  return (
    <React.Fragment>
      <Toolbar>
        <ToolbarLeft>
          <ToolbarDropDownMenu
            value={type}
            onChange={e =>
              setQueryChanges({ ...queryChanges, type: e.target.value })
            }
            label="Log Type"
          >
            <MenuItem value={'all'}>All Logs</MenuItem>
            {Object.keys(LOGTYPES).map(t => (
              <MenuItem key={t} value={t}>
                {LOGTYPES[t]}s
              </MenuItem>
            ))}
          </ToolbarDropDownMenu>
          <Space inline sizePx={8} />
          <UserSelect
            selectedId={queryChanges.user_id}
            onChange={(e, s) =>
              setQueryChanges({ ...queryChanges, user_id: s ? s.id : '' })
            }
            sx={{ width: 200 }}
            inputProps={{
              variant: 'outlined',
              size: 'small',
              margin: 'dense'
            }}
            label={'User'}
          />
          <Space inline sizePx={8} />
          <SearchBox
            onSearch={q => setQueryChanges({ ...queryChanges, search: q })}
            value={queryChanges.search || ''}
            onEnter={() => { }}
            placeholder="Search message, contents"
            style={{ flexGrow: 1 }}
          />
        </ToolbarLeft>
        <ToolbarRight></ToolbarRight>
      </Toolbar>
      <LogsTableList
        data={(data && data.logs.edges) || []}
        infinite
        loadMoreRows={loadMore}
        rowCount={getRowCount(data && data.logs.pageInfo)}
        wrapRow={({ children, rowData }) => (
          <div
            onClick={() => {
              setLog(rowData)
              setShowDetail(true)
            }}
            key={rowData.id}
          >
            {children}
          </div>
        )}
        rowClassName={({ index }) => {
          let classnames = ''
          let sortedData = !!data
            ? data.logs.edges.sort((a, b) => (b.cursor > a.cursor ? -1 : 1))
            : []
          let rowData =
            sortedData && sortedData[index] && sortedData[index].edge

          if (!!rowData) {
            classnames += !rowData.successful ? ' error' : ''
          }

          return classnames
        }}
      >
        <Column
          dataKey="logged_at"
          label="Date"
          flexGrow={1}
          width={1}
          maxWidth={300}
          cellRenderer={({ cellData, rowData }) => (
            <Typography>
              {moment
                .utc(cellData)
                .local()
                .format('M/D/YY, ddd. [at] hh:mm A')}
            </Typography>
          )}
        />
        <Column
          dataKey="type"
          label="Type"
          flexGrow={1}
          width={1}
          maxWidth={140}
          cellRenderer={({ cellData, rowData }) => (
            <Typography>{LOGTYPES[cellData] || cellData}</Typography>
          )}
        />
        <Column
          dataKey="user"
          label="User"
          flexGrow={1}
          width={1}
          maxWidth={140}
          cellRenderer={({ cellData, rowData }) => (
            <Typography>
              {!cellData
                ? 'System'
                : `${cellData.first_name} ${cellData.last_name}`}
            </Typography>
          )}
        />
        <Column dataKey="message" label="Message" flexGrow={3} width={1} />
      </LogsTableList>
      <PreviewDialog
        showDetail={showDetail}
        setShowDetail={setShowDetail}
        log={log}
        blobs={blobs}
      />
      <ModifyQueryParams
        query={{ ...queryChanges, search: debouncedSearch }}
        state={{}}
      />
    </React.Fragment>
  )
}
export default ActivityLog
