import React, {useState} from 'react'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'

import Button from '@mui/material/Button'
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
} from '@mui/material'

import theme from '../styles/theme'
import {Formik} from 'formik'
import {
  Checkbox,
  TextField,
  Radio,
  PhoneNumber,
  StaticSelect,
} from '../components/forms'
import LoadingButton from '../components/LoadingButton'
import Delete from '@mui/icons-material/Delete'
import Add from '@mui/icons-material/Add'
import {split} from 'lodash'
import * as Yup from 'yup'

let _mungFormikCreate = values => {
  let creates = Object.values(
    Object.entries(values).reduce((carry, [key, value]) => {
      let [id, field] = key.split('-')
      if (
        id.indexOf('new') === -1 ||
        (field === 'number' && field === 'label' && value === '')
      ) {
        return carry
      }

      if (!carry[id]) {
        carry[id] = {}
      }

      if (field !== 'delete') {
        let v =
          field === 'number'
            ? value.replace('+1', '').replace(/\s+/g, '')
            : value
        carry[id][field] = v
      }

      return carry
    }, {}),
  )

  creates = creates.filter(v => v.email !== '')

  return creates
}

let _mungFormikUpdate = values => {
  return Object.values(
    Object.entries(values).reduce((carry, [key, value]) => {
      let [id, field] = key.split('-')
      if (field === 'delete' || isNaN(Number(id))) {
        return carry
      }

      if (!carry[id]) {
        carry[id] = {}
      }

      if (field !== 'delete') {
        carry[id].id = id
        let v =
          field === 'number'
            ? value.replace('+1', '').replace(/\s+/g, '')
            : value
        carry[id][field] = v
      }

      return carry
    }, {}),
  )
}

let _mungFormikDelete = values => {
  return Object.values(
    Object.entries(values).reduce((carry, [key, value]) => {
      let [id, field] = key.split('-')
      if (field !== 'delete') {
        return carry
      }

      if (!carry[id]) {
        carry[id] = {}
      }

      carry[id].id = id
      carry[id].type = 'phones'
      return carry
    }, {}),
  )
}

let EditPhones = function({phones, setEditPhones, onSubmit}) {
  let [newId, setNewId] = useState(1)

  let formikValues =
    phones &&
    phones.reduce((carry, phone) => {
      carry[phone.id + '-number'] = phone.number
      carry[phone.id + '-label'] = phone.label
      carry[phone.id + '-phonetype'] = phone.phonetype
      return carry
    }, {})

  let _removePhone = (id, formValues, setFormValues) => {
    let newEmails = phones.filter(email => email.id !== id)
    setEditPhones(newEmails)
    let set = {...formValues}
    if (id.indexOf('new') === -1) {
      set[id + '-delete'] = true
    }
    delete set[id + '-number']
    delete set[id + '-label']
    delete set[id + '-phonetype']

    setFormValues(set)
  }

  let _addPhone = (newid, formValues, setFormValues) => {
    let e = [...phones]
    e.push({
      id: 'new' + newid,
      number: '',
      label: '',
      phonetype: 1,
    })
    setEditPhones(e)
    let set = {...formValues}
    set['new' + newid + '-number'] = ''
    set['new' + newid + '-label'] = ''
    setFormValues(set)
    return {number: 'new' + newid + '-number', label: 'new' + newid + '-label'}
  }

  let _checkValid = values => {
    let valid = true
    Object.entries(values).forEach(([key, value]) => {
      let [id, field] =
        key === 'default-email' ? value.split('-') : key.split('-')
      if ((field === 'number' || field === 'label') && value === '') {
        valid = false
      }
    })

    return valid
  }

  // Yes, this is every bit as insane as you think it is. But I am NOT remaking this component!
  let _createFormikSchema = values => {
    let genSchema = {}
    for (let key in values) {
      genSchema[key] = Yup.string()
        .min(1)
        .required('Required')
    }
    return genSchema
  }
  let _setTouched = values => {
    let genSchema = {}
    for (let key in values) {
      genSchema[key] = true
    }
    return genSchema
  }
  let _setErrors = values => {
    let genSchema = {}
    for (let key in values) {
      genSchema[key] = values[key] === ''
    }
    return genSchema
  }

  let schema = Yup.object().shape(_createFormikSchema(formikValues))

  return (
    <Dialog open={!!phones} maxWidth="xl">
      <Formik
        initialValues={formikValues}
        validationSchema={schema}
        onSubmit={(values, {setSubmitting}) => {
          setSubmitting(true)
          onSubmit(
            _mungFormikUpdate(values),
            _mungFormikDelete(values),
            _mungFormikCreate(values),
          )
        }}
      >
        {({
          isSubmitting,
          submitForm,
          values,
          setValues,
          isValid,
          validateForm,
          setTouched,
        }) => {
          schema = Yup.object().shape(_createFormikSchema(values))

          return (
            <>
              <DialogTitle>Edit Phones</DialogTitle>
              <DialogContent>
                {phones &&
                  phones
                    .filter(phones => !phones.delete)
                    .map(phone => (
                      <Grid container spacing={6} key={phone.id}>
                        <Grid item>
                          <TextField
                            variant="filled"
                            label="Label"
                            style={{width: '200px', marginRight: '2em'}}
                            name={phone.id + '-label'}
                          />
                          <PhoneNumber
                            variant="filled"
                            label="Phone Number"
                            style={{width: '500px', marginRight: '2em'}}
                            name={phone.id + '-number'}
                          />
                          <StaticSelect
                            select
                            variant="filled"
                            name={phone.id + '-phonetype'}
                            id={phone.id + '-phonetype'}
                          >
                            <MenuItem value={1}>Voice</MenuItem>
                            <MenuItem value={2}>Text</MenuItem>
                            <MenuItem value={3}>Fax</MenuItem>
                          </StaticSelect>
                          <IconButton
                            onClick={() => {
                              _removePhone(phone.id, values, setValues)
                              setTimeout(() => validateForm(values), 1) // helps revalidate correctly if you delete an invalid item
                            }}
                            size="large"
                          >
                            <Delete
                              style={{color: theme.palette.danger.main}}
                            />
                          </IconButton>
                        </Grid>
                      </Grid>
                    ))}
                <Grid container spacing={6}>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => {
                        let {number, label} = _addPhone(
                          newId,
                          values,
                          setValues,
                        )
                        setNewId(newId + 1)
                        setTimeout(() => {
                          setTouched(
                            _setTouched({
                              ...values,
                              [number]: true,
                              [label]: true,
                            }),
                          )
                          validateForm()
                        }, 1)
                      }}
                      id="addNewPhone"
                    >
                      <Add />
                    </Button>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setEditPhones(null)}>Cancel</Button>
                <LoadingButton
                  variant="contained"
                  color="primary"
                  onClick={submitForm}
                  loading={isSubmitting}
                  disabled={!_checkValid(values) || !isValid}
                >
                  Save
                </LoadingButton>
              </DialogActions>
            </>
          )
        }}
      </Formik>
    </Dialog>
  )
}

export default EditPhones
