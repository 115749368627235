import React from 'react'
import { useQuery } from '@apollo/react-hooks'

let mergeEdges = (prev, next) => {
  if (!prev) {
    prev = []
  }
  let seen = next.reduce((acc, { cursor }) => ({ ...acc, [cursor]: true }), {})
  return [...prev.filter(({ cursor }) => !seen[cursor]), ...next]
}

let getConnectionName = queryAST => {
  let selections = queryAST.definitions.find(
    ops => ops.kind === 'OperationDefinition',
  ).selectionSet.selections
  let fields = selections.filter(s => s.kind === 'Field')
  // get the selection that has an @connection directive
  // or get the first selection
  let connection =
    fields.find(s => !!s.directives.find(d => d.name.value === 'connection')) ||
    fields[0]
  return connection.alias ? connection.alias.value : connection.name.value
}

export function usePagination({ query, variables, fetchPolicy = 'cache-first' }) {
  let { data, loading, error, fetchMore, refetch } = useQuery(query, {
    variables: { ...variables, cursor: '-1' },
    // don't refetch at the beginning, this blows out our results
    // if we are scrolled in a list. Let the <TableList> determine
    // what rows are stale and need to be refetched
    fetchPolicy,
  })

  let connectionName = React.useMemo(() => getConnectionName(query), [query])

  let numRows =
    data &&
    data[connectionName] &&
    data[connectionName].pageInfo &&
    data[connectionName].pageInfo.total
  // If cached list has 0 items, we need to refetch to ensure
  // we get fresh results from backend. Ignore this if we are doing cache-and-network
  React.useEffect(() => {
    if (!loading && numRows === 0 && fetchPolicy !== 'cache-and-network' && !!refetch) {
      try {
        refetch()
      } catch (error){
        console.error(error)
      }
    }
  }, [numRows, loading])

  function loadMore({ startIndex, stopIndex }) {
    return fetchMore({
      variables: {
        cursor: startIndex === 0 ? '-1' : startIndex.toString(),
        limit: stopIndex - startIndex + 1,
      },
      updateQuery: (prev, { fetchMoreResult }) => ({
        [connectionName]: {
          ...fetchMoreResult[connectionName],
          edges: mergeEdges(
            prev ? prev[connectionName].edges : [],
            fetchMoreResult[connectionName].edges,
          ),
        },
      }),
    })
  }

  return { data, loadMore, loading, error, refetch }
}

function PaginationContainer({ query, variables, children }) {
  let { data, loading, error, loadMore, refetch } = usePagination({
    query,
    variables,
  })

  return children({ ...data, loading, error, loadMore, refetch })
}

export default PaginationContainer
