export const TYPE = {
  AR_30: 'AR_30',
  AR_90: 'AR_90',
  AR_OVER_90: 'AR_OVER_90',
  AR_INCOME: 'AR_INCOME',
  AR_SIGNATURE: 'AR_SIGNATURE',
  AR_ANY: 'AR_ANY',
}

export const SYSTEM_TYPES = [
  TYPE.AR_30,
  TYPE.AR_90,
  TYPE.AR_OVER_90,
  TYPE.AR_INCOME,
  TYPE.AR_SIGNATURE,
] 

export const PLACEHOLDER = {
  NAME: {
    value: '<name>',
    description: 'Name of the Customer or Account',
  },
  TODAY: {
    value: '<today>',
    description: 'Today, formatted as MM-DD-YYYY',
  },
}