/**
 * Update the checked state of multiple rows based on user input.
 *
 * @param {Object} params - The function parameters.
 * @param {Object} params.e - The event object triggered by the user action.
 * @param {string} params.rowKey - The key of the row being checked/unchecked.
 * @param {Array<string>} params.sortedRowKeys - The keys of all rows, sorted in the current order.
 * @param {Object} params.checks - The current checked state of all rows, represented as an object where the key is the row key and the value is a boolean.
 * @param {string|null} params.lastCheck - The key of the last row that was checked using shift+click, or null if none.
 * @param {(checks: {[key: string]: boolean}) => void} params.onSetChecks - A callback function that updates the checked state of all rows.
 * @param {(lastCheck: string) => void} params.onSetLastCheck - A callback function that updates the key of the last row that was checked using shift+click.
 */
export default function multicheck({
  e,
  rowKey,
  sortedRowKeys,
  checks,
  lastCheck,
  onSetChecks,
  onSetLastCheck,
}: {
  e: React.ChangeEvent<HTMLInputElement>,
  rowKey: string,
  sortedRowKeys: Array<string>,
  checks: {[key: string]: boolean},
  lastCheck: string | null,
  onSetChecks: (checks: {[key: string]: boolean}) => void,
  onSetLastCheck: (lastCheck: string) => void,
}) {
  let setCheckKeys: {[key: string]: boolean} = {
    ...checks,
    [rowKey]: e.target.checked,
  }

  if (e.shiftKey === undefined) {
    console.error(
      "multicheck: shiftKey property not found on event object. This could be because you used this function in a checkbox's onChange instead of onClick",
    )
  }

  if (e.shiftKey && !!lastCheck) {
    let startIndex = Math.min(
      sortedRowKeys.indexOf(lastCheck),
      sortedRowKeys.indexOf(rowKey),
    )
    let endIndex = Math.max(
      sortedRowKeys.indexOf(lastCheck),
      sortedRowKeys.indexOf(rowKey),
    )
    let newChecks = sortedRowKeys
      .slice(startIndex, endIndex)
      .reduce((carry, k) => {
        carry[k] = e.target.checked
        return carry
      }, {})
    setCheckKeys = {
      ...setCheckKeys,
      ...newChecks,
      [lastCheck]: e.target.checked,
    }
  }

  onSetChecks(setCheckKeys)

  onSetLastCheck(rowKey)
}
