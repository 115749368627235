import {CALL_API} from '../middleware/api'
import {schema} from 'normalizr'

export const FETCH_CUSTOMER_REQUEST = 'FETCH_CUSTOMER_REQUEST'
export const FETCH_CUSTOMER_SUCCESS = 'FETCH_CUSTOMER_SUCCESS'
export const FETCH_CUSTOMER_FAILURE = 'FETCH_CUSTOMER_FAILURE'
export const CHECK_FOR_CUSTOMER = 'CHECK_FOR_CUSTOMER'
export const CREATE_CUSTOMER = 'CREATE_CUSTOMER'

const endpoint = 'customers'
const customer = new schema.Entity('customers')

export function findById(id) {
  return {
    [CALL_API]: {
      types: [
        FETCH_CUSTOMER_REQUEST,
        FETCH_CUSTOMER_SUCCESS,
        FETCH_CUSTOMER_FAILURE,
      ],
      endpoint: `${endpoint}/${id}`,
      schema: customer,
    },
  }
}

export function searchCustomers(
  query = '',
  {searchField = 'name', page = 1, limit = 25} = {},
) {
  const queryHash = {[searchField]: query}
  const params = Object.assign({}, queryHash, {page, limit})

  return {
    [CALL_API]: {
      types: [
        FETCH_CUSTOMER_REQUEST,
        FETCH_CUSTOMER_SUCCESS,
        FETCH_CUSTOMER_FAILURE,
      ],
      endpoint,
      params,
      schema: [customer],
    },
  }
}

export function checkForCustomer(name) {
  return dispatch => {
    dispatch({type: CHECK_FOR_CUSTOMER, payload: name})
    dispatch(searchCustomers(name))
  }
}

export function createCustomer(name) {
  return {type: CREATE_CUSTOMER, payload: name}
}
