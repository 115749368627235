import React, { useEffect, useState } from 'react'
import { Toolbar } from '../../AppHandler'
import ToolbarGroup from '../../components/ToolbarGroup'
import ToolbarMegaTitle from '../../components/ToolbarMegaTitle'
import styled from 'styled-components'
import { DatePicker } from '@mui/lab'
import SelectMenu from '../../components/ToolbarDropDownMenu'
import {
  Typography,
  LinearProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  MenuItem,
  CircularProgress,
  InputLabel,
  TextField,
  IconButton,
  Collapse,
  Box,
} from '@mui/material'
import { Space } from '../../components/Layout'
import { fetchAPI } from '../../schema/utils'
import moment from 'moment'
import { useQuery, useQueryClient } from 'react-query'
import _, { round } from 'lodash'
import { ArrowForward, Forward, KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import queryString from 'query-string'
import ModifyQueryParams from '../../components/ModifyQueryParams'
import { formatMoneyStandard } from '../../utils/moneyFormatter'
import { TaxitemSelect } from '../../components/TaxitemsSelect'
import { Link } from '../../pr-router'

let SummaryWrapper = styled.div`
  page-break-after: always;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
`

let TableWrapper = styled.div`
  margin-top: 2em;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  min-width: 800px;
`

function Row({ data, period }) {
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell align="left">{data.summary.label}</TableCell>
        <TableCell component="th" scope="row">
          {formatMoneyStandard(data.summary.annualized_schedules)}
        </TableCell>
        <TableCell component="th" scope="row">
          {formatMoneyStandard(data.summary.invoices_over_period)}
        </TableCell>
        <TableCell component="th" scope="row">
          {data.summary.count}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Breakdown
              </Typography>
              <Table size="small" aria-label="breakdown">
                <TableHead>
                  <TableRow>
                    <TableCell align='left'>Customer / Account</TableCell>
                    <TableCell align='center'>Annualized</TableCell>
                    <TableCell align='center'>Invoices {period} days before closing</TableCell>
                    <TableCell align='center'>Type</TableCell>
                    <TableCell align='center'>Lifetime</TableCell>
                    <TableCell align='center'>Stop Reason</TableCell>
                    <TableCell align='center'>Closed</TableCell>
                    <TableCell align='center'>Locations</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.entries(data.data).sort((a, b) => b[1].annualized - a[1].annualized).map(([key, value]) => (
                    <TableRow key={key}>
                      <TableCell component="th" scope="row" align='left'>
                        <Link to={value.link}>
                          {value.name}
                        </Link>
                      </TableCell>
                      <TableCell align='center'>{formatMoneyStandard(value.annualized)}</TableCell>
                      <TableCell align='center'>{formatMoneyStandard(value.invoices_sum)}</TableCell>
                      <TableCell component="th" scope="row" align='center'>
                        {value.type}
                      </TableCell>
                      <TableCell component="th" scope="row" align='center'>
                        {value.lifetime}
                      </TableCell>
                      <TableCell component="th" scope="row" align='center'>
                        {value.stop_reason}
                      </TableCell>
                      <TableCell component="th" scope="row" align='center'>
                        {value.stopped}
                      </TableCell>
                      <TableCell component="th" scope="row" align='center'>
                        {value.locations}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

let getData = async ({ end_date, start_date, group_by }) => {
  const data = await fetchAPI({
    url: '/reports/lost_jobs',
    query: {
      start_date: start_date.format('YYYY-MM-DD'),
      end_date: end_date.format('YYYY-MM-DD'),
      group_by,
    },
  })
  return await data.json()
}

let munge = ({ start_date, end_date, group_by }) => {
  start_date = !start_date ? moment().subtract(1, 'year') : moment(start_date)
  end_date = !end_date ? moment() : moment(end_date)
  group_by = !['stop_reason', 'type'].includes(group_by) ? 'type' : group_by
  return { start_date, end_date, group_by }
}

const LostJobs = () => {
  let [queryChanges, setQueryChanges] = useState(
    queryString.parse(location.search),
  )

  let params = queryString.parse(location.search)
  let { start_date, end_date, group_by } = munge(params)

  const { isLoading, error, data } = useQuery(
    ['lostJobs', start_date.format('YYYY-MM-DD'), end_date.format('YYYY-MM-DD'), group_by],
    () => getData({ start_date, end_date, group_by }),
  )

  return (
    <>
      <ModifyQueryParams query={queryChanges} />
      <Toolbar>
        <ToolbarMegaTitle>Income Per Man </ToolbarMegaTitle>
        <Space inline size={2} />
        <ToolbarGroup last>
          <DatePicker
            label="Start Date"
            value={moment.utc(start_date)}
            inputFormat="ddd. MMMM D, YYYY"
            onChange={d =>
              setQueryChanges(c => ({ ...c, start_date: d.format('YYYY-MM-DD') }))
            }
            renderInput={props => (
              <TextField {...props} margin="dense" size="small" />
            )}
            autoOk
          />
          <Space inline />
          <Forward />
          <Space inline />
          <DatePicker
            label="End Date"
            value={moment.utc(end_date)}
            inputFormat="ddd. MMMM D, YYYY"
            onChange={d =>
              setQueryChanges(c => ({ ...c, end_date: d.format('YYYY-MM-DD') }))
            }
            renderInput={props => (
              <TextField {...props} margin="dense" size="small" />
            )}
            autoOk
          />
          <Space inline />
          <SelectMenu
            label="Group By"
            value={group_by}
            onChange={e => setQueryChanges(c => ({ ...c, group_by: e.target.value }))}
            style={{ minWidth: 150 }}
          >
            <MenuItem value={'type'}>
              Customer Type
            </MenuItem>
            <MenuItem value={'stop_reason'}>
              Stop Reason
            </MenuItem>
          </SelectMenu>
        </ToolbarGroup>
      </Toolbar>

      <SummaryWrapper>
        <TableWrapper key={name}>
          <Typography variant="h6">{name}</Typography>
          {isLoading ? <LinearProgress /> :
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>Group</TableCell>
                  <TableCell>Annualized Total</TableCell>
                  <TableCell>Total Invoices {end_date.diff(start_date, 'days') - 1} days before closing </TableCell>
                  <TableCell>Jobs</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data && Object.entries(data).map(([key, data]) => (
                  <Row key={key} data={data} period={end_date.diff(start_date, 'days') - 1} />
                ))}
              </TableBody>
            </Table>
          }

        </TableWrapper>
      </SummaryWrapper>
    </>
  )
}

export default LostJobs
