import React from 'react'
import {Map, List, fromJS} from 'immutable'
import urlJoin from 'url-join'
import {normalize, schema} from 'normalizr'
import {connect} from 'react-redux'
import {createSelector} from 'reselect'
import {CALL_API} from './middleware'
import _ from 'lodash'

export const REQUEST = 'FETCH_ENDPOINT_REQUEST'
export const SUCCESS = 'FETCH_ENDPOINT_SUCCESS'
export const ERROR = 'FETCH_ENDPOINT_ERROR'

export function configureApi({urlRoot, defaults}) {
  return function createEndpoint({url, schema, transform, transformEntity}) {
    const fullUrl = urlJoin(urlRoot, url)

    return {
      endpoint: {
        url: fullUrl,
        defaultProps: {page: 1, limit: defaults.limit},
        schema,
        transform,
      },
      actions: {
        fetch(params, {bypassCache = false} = {}) {
          return {
            [CALL_API]: {
              types: [REQUEST, SUCCESS, ERROR],
              url: fullUrl,
              params,
              key: schema.getKey(),
              schema: [schema],
              transform,
              transformEntity,
              queryHash: fromJS(params),
              bypassCache,
            },
          }
        },
        fetchById(id, {bypassCache = false} = {}) {
          return {
            [CALL_API]: {
              types: [REQUEST, SUCCESS, ERROR],
              url: urlJoin(fullUrl, id),
              key: schema.getKey(),
              schema,
              transform,
              transformEntity,
              queryHash: id,
              bypassCache,
            },
          }
        },
      },
    }
  }
}

export function createContainer(getConfig) {
  return function wrapComponent(Component) {
    class apiContainer extends React.Component {
      constructor(props) {
        super(props)
        this.state = {isLoading: false}
      }

      update({bypassCache = false} = {}) {
        const config = getConfig(this.props)

        this.setState({isLoading: true})

        const fetches = Object.keys(config).map(function(connection) {
          const {
            actions,
            initialVariables,
            include = [],
            id,
            endpoint: {defaultProps},
          } = config[connection]

          if (id) {
            // we are connecting to a single entity
            return this.props.dispatch(actions.fetchById(id))
            // console.count('componentDidMount ' + id);
          } else {
            // we are connecting to a list
            return this.props.dispatch(
              actions.fetch(
                {
                  ...defaultProps,
                  ...initialVariables,
                  include: include.join(','),
                },
                {bypassCache},
              ),
            )
          }
        })

        Promise.all(fetches).then(() => this.setState({isLoading: false}))
      }

      forceFetch() {
        this.update({bypassCache: true})
      }

      componentDidMount() {
        this.update()
      }

      shouldComponentUpdate() {
        return false
      }

      static displayName = Component.displayName + 'ApiWrapper'

      render() {
        const {config} = this.props

        const connectionSelectors = Object.keys(config).map(connection => {
          const {
            id,
            initialVariables,
            include,
            endpoint: {schema, defaultProps},
          } = config[connection]
          const entityName = schema.getKey()

          if (id) {
            // get schema relational entities
            const relConnections = _(Object.keys(schema))
              .intersection(include)
              .map(key => ({api}) => {
                const entityName =
                  schema[key].data.getItemSchema().getKey() ||
                  schema[key].data.getKey()
                return [entityName, api.getIn(['entities', entityName])]
              })
              .value()

            // select entity store based on relational entities
            // map enities to parent ids
            const entitySelector = createSelector(
              [
                ({api}) => api.getIn(['entities', entityName, id.toString()]),
                ...relConnections,
              ],
              (entity, ...relEntities) => {
                const entityWithRel = relEntities.reduce(
                  (mergedEntity, [entityName, entities]) =>
                    mergedEntity.updateIn([entityName, 'data'], users =>
                      users.map(id => entities.get(id.toString())),
                    ),
                  entity,
                )

                return {[connection]: entityWithRel.toJS() || {}}
              },
            )

            return entitySelector
          }

          const queryHash = fromJS({
            ...defaultProps,
            ...initialVariables,
            include: include.join(','),
          })
          const entitiesSelector = ({api}) =>
            api.getIn(['entities', entityName])
          const querySelector = ({api}) =>
            api.getIn(['queries', entityName, queryHash], List())

          return createSelector(
            [entitiesSelector, querySelector],
            (entities, query) => ({
              [connection]: query.map(id => entities.get(id.toString())).toJS(),
            }),
          )
        })

        const connectionsSelector = createSelector(
          [...connectionSelectors],
          (...connections) =>
            connections.reduce((cPrev, cNext) => ({...cPrev, ...cNext})),
        )

        const ConnectedComponent = connect(connectionsSelector)(Component)

        return (
          <ConnectedComponent
            {...this.props}
            forceFetch={this.forceFetch.bind(this)}
            isLoading={this.state.isLoading}
          />
        )
      }
    }

    const propsSelector = (state, props) => props
    const configSelector = createSelector([propsSelector], props => ({
      config: getConfig(props),
    }))

    return connect(configSelector)(apiContainer)
    // return connect()(apiContainer);
  }
}

const initialState = Map({queries: Map({}), entities: Map({})})
export function reducer(state = initialState, {type, payload}) {
  switch (type) {
    case SUCCESS:
      return state
        .mergeDeepIn(['entities'], fromJS(payload.entities))
        .setIn(
          ['queries', payload.key, payload.queryHash],
          fromJS(payload.result),
        )
    default:
      return state
  }
}

export {normalize}
export {schema}
export {default as middleware} from './middleware'
