import React from 'react'

import Avatar from '@mui/material/Avatar'

import Home from '@mui/icons-material/Home'
import Store from '@mui/icons-material/Store'

import {fetchAPI} from '../schema/utils'

class Image extends React.Component {
  state = {
    src: null,
  }

  componentDidMount() {
    let srcUrl = new URL(this.props.src)
    fetchAPI({
      url: srcUrl.pathname + srcUrl.search,
      options: {mode: 'cors', cache: 'force-cache'},
      prefixUrl: false,
    })
      .then(res => res.blob())
      .then(blob => {
        this.setState({
          src: URL.createObjectURL(blob),
        })
      })
  }

  render() {
    return this.props.children({src: this.state.src})
  }
}

class CustomerAvatar extends React.Component {
  static defaultProps = {
    type: 'Commercial',
    size: 40,
  }
  getIcon = type => (type === 'Residential' ? Home : Store)
  render() {
    const {type, url, ...props} = this.props
    let AvatarIcon = this.getIcon(type)

    return url ? (
      <Image src={url}>{({src}) => <Avatar src={src} {...props} />}</Image>
    ) : (
      <Avatar {...props}>
        <AvatarIcon />
      </Avatar>
    )
  }
}

export default CustomerAvatar
