import React, {useRef, useState} from 'react'
import {useMutation, useQuery, useQueryClient} from 'react-query'
import {gql, prgql} from '../../utils/graphql'
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  Tooltip,
  Typography,
  Checkbox as MuiCheckbox,
} from '@mui/material'
import {NavigateBack} from '../../components/Navigator'
import styled, {css} from 'styled-components'
import {FieldArray, Formik} from 'formik'
import {RedirectBack} from '../../components/Navigator'
import {Link} from 'react-router-dom'
import toaster from '../../utils/toaster'
import {fetchAPI} from '../../schema/utils'
import {
  AutoCompleteField,
  Checkbox,
  DatePicker,
  PhoneNumber,
  TextField,
} from '../../components/forms'
import colors from '../../styles/colors'
import {
  Add,
  AddAPhoto,
  ArrowDownward,
  ArrowForward,
  ArrowRight,
  Camera,
  Check,
  ChevronRight,
  Close,
  ContentCopy,
  Delete,
  ExpandMore,
  HorizontalRule,
  Lens,
  LensRounded,
  Person,
  Photo,
  Save,
  Search,
} from '@mui/icons-material'
import {EstimateserviceSelect} from '../../components/apiSelects'
import LoadingButton from '../../components/LoadingButton'
import {
  CloseButton,
  DetailToolbar,
  ToolbarCenter,
  ToolbarLeft,
  ToolbarRight,
} from '../../components/Toolbar'
import {Space} from '../../components/Layout'
import {DiscountSelect} from '../../components/DiscountSelect'
import {discountedCharge, formatPhone, getRandomString} from '../../utils'
import {formatMoneyStandard} from '../../utils/moneyFormatter'
import theme from '../../styles/theme'
import {TextField as MuiTextField} from '@mui/material'
import * as Yup from 'yup'
import Categories from '../../constants/EstimateserviceCategories'
import {Redirect} from '../../pr-router'
import DESIREDFREQUENCY from '../../constants/DesiredFrequency'
import CustomerSelect from '../../components/CustomerSelect'
import EditDetails from './EditDetails'
import Image from '../../components/Image'
import _, {round} from 'lodash'
import SaleItemSelect from '../../components/SaleItemSelect'
import {customerQuery} from './CustomerDetail'
import {customerQuery as accountQuery} from '../Account/AccountDetail'
import {Caption} from '../../components/Typography'

export const estimateserviceFragment = gql`
  fragment EstimateserviceDetails on Estimateservices {
    id
    label
    notes
    price
    min_price
    ignore_qty
    qty_label
    t2_threshhold
    t2_price
    t3_threshhold
    t3_price
    t4_threshhold
    t4_price
    t5_threshhold
    t5_price
    saleitem_id
    is_custom_price
    saleitem {
      id
      name
    }
    attributes {
      id
      label
      multiselect
      attributeoptions {
        id
        label
        price
      }
    }
    deleted_at
  }
`

let optionsQuery = gql`
  query ServiceOptions($search: String, $category: String) {
    options: allEstimateservices(
      filters: {label: $search, category: $category}
      cursor: "-1"
      limit: 10000
    ) {
      edges {
        edge {
          ...EstimateserviceDetails
        }
        cursor
      }
      pageInfo {
        count
        total
      }
    }
  }
  ${estimateserviceFragment}
`

let saleitemsQuery = gql`
  query SaleItems {
    saleitems: allSaleitems(cursor: "-1", limit: 10000) {
      edges {
        edge {
          id
          name
          description
        }
        cursor
      }
      pageInfo {
        count
        total
      }
    }
  }
`

let getQuery = gql`
  query Estimate($id: String!) {
    item: estimates(id: $id) {
      id
      name
      proposal_notes
      photo_comments
      contract_start_at
      contract_stop_at
      submitted_to_name
      submitted_to_email
      submitted_to_phone
      discount_id
      user {
        id
        first_name
        last_name
      }
      discount {
        id
        name
        type
        amount
      }
      photos {
        id
        name
        thumb_url
        url
      }
      estimateitems {
        id
        description
        qty
        selected
        frequency
        customer_id
        estimateitemprices {
          id
          frequency
          price
          estimateitem_id
        }
        price_override
        estimateservice_id
        estimateservice {
          ...EstimateserviceDetails
        }
        attributeoptions {
          id
          label
          price
          attribute_id
        }
      }
    }
  }
  ${estimateserviceFragment}
`

let createMutation = gql`
  mutation NewEstimate($input: CreateEstimatesInput) {
    createEstimates(input: $input) {
      estimates {
        id
      }
    }
  }
`

let updateMutation = gql`
  mutation Update($input: UpdateEstimatesInput) {
    updateEstimates(input: $input) {
      estimates {
        id
      }
    }
  }
`

let deleteMutation = gql`
  mutation Delete($id: String!) {
    deleteEstimates(input: {id: $id}) {
      message
    }
  }
`

let deletePhotoMutation = gql`
  mutation Delete($id: String!) {
    deletePhotos(input: {id: $id}) {
      message
    }
  }
`

let Container = styled.div`
  padding-left: 1em;
  padding-right: 1em;
  margin: 16px auto 0;
  background-color: ${theme.palette.backgroundColor};
  width: 100%;
  height: 100%;
  box-sizing: border-box; /* Ensures padding is included in the width calculation */
`

let PaperContainer = styled(Paper)`
  margin: 16px auto 0;
  padding: 1em;
`

let PlainContainer = styled.div`
  margin: 16px auto 0;
  padding: 1em;
`

let ItemsContainer = styled.div`
  margin: 16px auto 0;
  padding: 1em;
  border: 3px dashed ${colors.grey300};
  border-radius: 25px;
  gap: 3px;
  display: flex;
  flex-direction: column;
`

let Sidebar = styled(Paper)`
  width: 20%;
  margin: 16px auto 0;
  padding: 1em;
  display: flex;
  flex-direction: column;
  gap: 5px;
  min-height: 200px;
`

let OptionArray = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`

let Bottombar = styled(Paper)`
  margin: 2px auto 0;
  padding: .25em;
  display: flex;
  flex-direction: row-reverse;
  gap: 5px;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 1000
  left: 0;
  align-items: center;
`

let EstimateItem = styled.div`
  background-color: ${({selected}) =>
    selected ? theme.palette.success.light : 'white'}
  padding: 0.5em;
  width: 100%;
  border: 1px ${colors.grey500} solid;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.4);
`

let EstimateItemContents = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  width: 100%;
`

let MainContainer = styled.div`
  min-width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-bottom: 100px;
`

let LeftIcon = styled.div`
  margin-right: ${({theme}) => theme.muiTheme.spacing(1)};
`

let SearchDivider = styled.span`
  width: 100%;
  border: 1px solid ${theme.palette.info.light};
  margin-top: 0.5em;
  margin-bottom: 0.5em;
`

let LoadingBar = styled(LinearProgress)`
  width: 100%;
  // border: 1px solid ${theme.palette.info.light};
  margin-top: .5em;
  margin-bottom: .5em;
`

let DeletePhotoButton = styled(IconButton)`
  & {
    position: absolute;
    top: -8px;
    right: -8px;
    background-color: ${colors.grey400};
    padding: 1px;
  }

  &:hover {
    background-color: ${colors.grey500};
  }
`

function InlineLabel({label, children, bold}) {
  return (
    <div style={{paddingRight: '20px'}}>
      <Caption
        style={{
          width: '45px',
          marginRight: '8px',
          fontWeight: bold ? 'bold' : undefined,
          lineHeight: '.5em',
        }}
      >
        {label}
      </Caption>
      {children}
    </div>
  )
}

const miniImgStyle = `
    & {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 4px;
    }
`
const hoverStyle = `
      &:hover {
        border: 2px ${colors.orange500} solid;
        cursor: pointer;
      }
`
let MiniPRImage = styled(Image)`
  ${miniImgStyle}
  ${props => props.hover && hoverStyle}
`
let MiniHTMLImage = styled.img`
  ${miniImgStyle}
  ${props => props.hover && hoverStyle}
`

let MiniImage = ({as, src, onClick, ...props}) => {
  const Component = as

  return (
    <>
      <Component src={src} {...props} onClick={onClick} />
    </>
  )
}

let ContentHeader = ({children}) => (
  <Typography variant="subtitle1" color={colors.grey700} fontWeight={'300'}>
    {...children}
  </Typography>
)

let SearchServices = styled(MuiTextField)``

const mungeValues = (values, parentId, oldValues, isAccount) => {
  console.log(values, oldValues)

  let input = {
    data_source_type: 'PATHRABBIT',
    [isAccount ? 'account_id' : 'customer_id']: Number.parseInt(parentId),
  }

  let deleteItems = []
  let attrs = [
    'id',
    'name',
    'discount_id',
    'proposal_notes',
    'photo_comments',
    'contract_start_at',
    'contract_stop_at',
    'submitted_to_name',
    'submitted_to_email',
    'submitted_to_phone',
  ]
  let attrReducer = (acc, k) => {
    let v = values[k]
    // id prop is not allowed in creation
    if (k === 'id' && (v === undefined || v.includes('new'))) {
      v = undefined
    }
    acc[k] = v
    return acc
  }

  let rels = ['estimateitems']
  let relAttrs = [
    'id',
    'qty',
    'description',
    'estimateservice_id',
    'attributeoptions',
    'estimateitemprices',
    'selected',
    'frequency',
    'customer_id',
    'selected',
    'price_override',
  ]

  let relReducer = (acc, k) => {
    let v = values[k]
    if (k === 'estimateitems') {
      let update = []
      let create = []
      let destroy = []
      let origIds = oldValues?.estimateitems?.map(e => e.id) || []
      values[k].forEach(function(rel) {
        let relAttrReducer = (acc, k) => {
          let v = rel[k]

          // attributeoptions
          if (k === 'attributeoptions') {
            const singleSelect = rel.estimateservice?.attributes?.filter(
              attr => !attr.multiselect,
            )
            const multiSelect = rel.estimateservice?.attributes?.filter(
              attr => !!attr.multiselect,
            )

            let set = singleSelect
              ? singleSelect
                  .filter(attr => !!attr.selected)
                  .map(attr => ({
                    id: attr.selected,
                  }))
              : []

            let set2 = multiSelect
              ? multiSelect
                  .filter(attr => !!attr.selected && attr.selected.length)
                  .reduce((acc, attr) => {
                    let os = attr.selected.map(id => ({id}))
                    return [...acc, ...os]
                  }, [])
              : []

            v = {set: [...set, ...set2], api_type: 'attributeoptions'}
          }

          if (k === 'estimateitemprices') {
            console.log('yo')
            let create = []
            let update = []
            let destroy = []
            const origIds =
              oldValues?.estimateitems
                ?.filter(e => e.id === rel.id)[0]
                ?.estimateitemprices.map(e => e.id) || []

            if (rel.estimateitemprices) {
              rel.estimateitemprices.forEach(function(item) {
                if (origIds.includes(item.id)) {
                  update.push(item)
                } else {
                  let {id, estimateitem_id, ...item2} = item
                  create.push(item2)
                }
              })
            }

            const updateIds = update.map(u => u.id)
            origIds.forEach(id => {
              if (!updateIds.includes(id)) {
                destroy.push({id})
              }
            })

            v = {create, update, destroy, api_type: 'estimateitemprices'}
          }

          // Clear out price_override if it's the same as the standard line price
          if (
            k === 'price_override' &&
            (v === '' ||
              round(linePrice({...rel, price_override: null}), 2) ===
                round(Number.parseFloat(v), 2))
          ) {
            v = null
          }

          acc[k] = v
          return acc
        }
        let item = relAttrs.reduce(relAttrReducer, {})
        if (origIds.includes(item.id)) {
          update.push(item)
        } else {
          let {id, ...item2} = item
          create.push(item2)
        }
      })

      const updateIds = update.map(u => u.id)
      origIds.forEach(id => {
        if (!updateIds.includes(id)) {
          destroy.push({id})
        }
      })

      acc['estimateitems'] = {
        create,
        update,
        destroy,
      }
    }
    return acc
  }

  let est = attrs.reduce(attrReducer, {})
  let relationships = rels.reduce(relReducer, {})

  input = {
    ...input,
    ...est,
    ...relationships,
  }

  console.log('input', input)

  return input
}

const uploadPhotos = async (images, id) => {
  if (!images || !images.length) return

  images.forEach(async image => {
    const formData = new FormData()

    formData.append(`photo`, image)
    formData.append('description', image.name)

    try {
      let res = await fetchAPI({
        url: `/estimates/${id}/photos`,
        options: {
          method: 'POST',
          body: formData,
          Headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      })

      if (!res.ok) {
        toaster.error("Couldn't upload image " + image.name)
      }
    } catch (e) {
      console.error(e)
      toaster.error("Couldn't upload image " + image.name)
    }
  })
}

const linePrice = estimateitem => {
  let price = 0
  const service = estimateitem.estimateservice

  if (!service) {
    return price
  }

  if (
    estimateitem.price_override !== null &&
    estimateitem.price_override !== ''
  ) {
    return Number.parseFloat(estimateitem.price_override)
  }

  const tiers = ['t2', 't3', 't4', 't5']

  const initQty = estimateitem.qty - (service.ignore_qty || 0)
  const qty = initQty < 0 && service.ignore_qty ? 0 : initQty

  const attrs = service.attributes || []
  const attrPrice = attrs.reduce((acc, attr) => {
    if (attr.multiselect) {
      const selected = attr.selected || []
      const options = (attr.attributeoptions || []).filter(o =>
        selected.includes(o.id),
      )
      return acc + options.reduce((acc, o) => acc + (o.price || 0), 0)
    } else {
      const selected = attr.selected || '0'
      const option = (attr.attributeoptions || [])
        .filter(o => o.id === selected)
        .pop()
      return acc + (option?.price || 0)
    }
  }, 0)

  price = (service.price + attrPrice) * (qty || 0)

  for (const tier of tiers) {
    if (
      !!service[`${tier}_threshhold`] &&
      qty >= service[`${tier}_threshhold`]
    ) {
      price = (service[`${tier}_price`] + attrPrice) * qty
    }
  }

  // console.log(price, service.min_price)

  if (service.min_price === 0) return price

  return Math.max(price, service.min_price)
}

export const mungeEstimateDataForCalculation = estimate => {
  let initialData
  if (estimate) {
    initialData = estimate
    initialData.estimateitems = initialData.estimateitems.map(ei => {
      if (!ei.estimateservice) {
        return ei
      }

      let newItem = {
        ...ei,
        estimateservice: {
          ...ei.estimateservice,
          attributes: ei.estimateservice.attributes.map(attr => {
            const options = ei.attributeoptions?.filter(
              o => o.attribute_id === attr.id,
            )
            let selected = attr.multiselect
              ? options.map(o => o.id)
              : options.pop()?.id
            return {
              ...attr,
              selected,
            }
          }),
        },
      }

      // fill in price_override if it's null with the current pricing rate
      if (newItem.price_override === null || newItem.price_override === '') {
        newItem.price_override = round(linePrice(newItem), 2)
      }

      return newItem
    })
  }
  return initialData
}

export const estimatePrice = (
  mungedEstimate,
  beforeDiscount = false,
  onlySelected = true,
) => {
  let estimateitems = mungedEstimate.estimateitems || []
  if (onlySelected) {
    estimateitems = estimateitems.filter(e => e.selected)
  }

  let price = estimateitems.reduce((acc, item) => {
    return acc + linePrice(item)
  }, 0)

  if (beforeDiscount) return price

  return discountedCharge({
    charge: price,
    discount: mungedEstimate.discount,
  })
}

function mungeForFormDisplay(est) {
  return {
    ...est,
    serviceGroups: Object.entries(
      Object.groupBy(
        est.estimateitems || [],
        esti => esti.estimateservice?.saleitem_id || '0',
      ),
    ).map(([key, estimateitems]) => ({saleitem_id: key, estimateitems})),
  }
}

function mungeForSaveFormat(est) {
  return {
    ...est,
    estimateitems: est.serviceGroups.map(group => group.estimateitems).flat(1),
  }
}

const schema = Yup.object().shape({
  name: Yup.string().required('Required'),
  serviceGroups: Yup.array()
    .min(1, 'Add a service')
    .of(
      Yup.object().shape({
        estimateitems: Yup.array()
          .min(1, 'Select a service')
          .of(
            Yup.object().shape({
              // saleitem_id: Yup.string()
              //   .required('Required')
              //   .notOneOf(['0']),
              description: Yup.string().nullable(),
              qty: Yup.number().nullable(),
              estimateservice_id: Yup.string().required(
                'Select Estimate Service',
              ),
              price_override: Yup.number().nullable(),
              frequency: Yup.number().min(
                1,
                'Select a frequency for the service',
              ),
            }),
          ),
      }),
    ),
})

let Form = ({
  onSubmit,
  onClose,
  initial = {},
  formikkey,
  isAccount,
  parentId,
  customerData,
  customerDataLoading,
}) => {
  const [showNewCustomer, setShowNewCustomer] = useState(false)
  const [fieldKeyForNewCust, setFieldKeyForNewCustomer] = useState(0)
  const [images, setImages] = useState([])
  const fileInputRef = useRef(null)
  const [showPhoto, setShowPhoto] = useState(false)
  const [photoSrc, setPhotoSrc] = useState('')
  const [confirmSave, setConfirmSave] = useState(false)

  const c = customerData?.customers || false

  const handleFileSelect = event => {
    const files = Array.from(event.target.files)
    const validFiles = files.filter(file => file.type.startsWith('image/'))

    if (validFiles.length) {
      setImages(prevImages => [...prevImages, ...validFiles])
    }

    // Reset input value so the same file can be selected again
    event.target.value = ''
  }

  const removeImage = index => {
    setImages(prevImages => prevImages.filter((_, i) => i !== index))
  }

  let {
    data: saleitems,
    isError: saleitemsError,
    isLoading: saleitemsLoading,
  } = useQuery(['saleitems'], async () => await prgql({query: saleitemsQuery}))

  const groupedData = mungeForFormDisplay(initial)

  // super messy and I'm beyond caring at this point
  function mapErrors(errors) {
    return Object.entries(errors)
      .map(([key, val]) => {
        if (key === 'serviceGroups') {
          if (!Array.isArray(val)) {
            return
          }

          let errs = []

          val.forEach(v => {
            if (!v) {
              return
            }

            if (typeof v.estimateitems === 'string') {
              errs.push(v.estimateitems)
            } else if (Array.isArray(v.estimateitems)) {
              v.estimateitems.forEach(item => {
                if (Array.isArray(item)) {
                  // If item is an array, destructure and process
                  const [key, val] = item
                  Object.entries(val || {}).forEach(([key, val]) =>
                    errs.push(val),
                  )
                } else if (typeof item === 'object' && item !== null) {
                  // If item is an object, process its entries
                  Object.entries(item).forEach(([key, val]) => errs.push(val))
                } else {
                  // Handle unexpected item types
                  errs.push(`Unexpected item type: ${typeof item}`)
                }
              })
            }

            console.log(v.estimateitems)
          })

          return errs.join(', ')
        } else {
          if (Array.isArray(val)) {
            const obj = [...val].pop()
            if (!obj) {
              return
            }
            return `${Object.entries(obj)
              .map(([key, val]) => val)
              .join(',')}`
          }
        }

        return `${val}`
      })
      .join(', ')
  }

  const saleItemDic = saleitems
    ? saleitems.saleitems?.edges.reduce(
        (acc, e) => ({
          ...acc,
          [e.edge.id]: e.edge,
        }),
        {},
      )
    : {}

  return (
    <Formik
      initialValues={groupedData}
      onSubmit={values => {
        return onSubmit(mungeForSaveFormat({...values, images}))
      }}
      key={formikkey}
      validationSchema={schema}
    >
      {({
        submitForm,
        values,
        setValues,
        setFieldValue,
        isSubmitting,
        isValid,
        dirty,
        errors,
      }) => (
        <React.Fragment>
          <DetailToolbar>
            <ToolbarLeft>
              <CloseButton
                onClick={e => {
                  if (dirty) {
                    setConfirmSave(true)
                  } else {
                    onClose(e)
                  }
                }}
              />
            </ToolbarLeft>
            <ToolbarCenter>
              <Typography variant="h6">
                {values.id ? 'Edit' : 'Add'} Estimate
              </Typography>
            </ToolbarCenter>
            <ToolbarRight>
              <Space sizePx={16} inline />
              <Button
                color="primary"
                onClick={submitForm}
                disabled={!isValid || isSubmitting || !dirty}
              >
                <LeftIcon>
                  <Save />
                </LeftIcon>
                Save
              </Button>
              {/* <ToolbarIconMenu> */}
              {/*   <TrashMenuItem */}
              {/*     onClick={() => setWarn(true)} */}
              {/*   /> */}
              {/* </ToolbarIconMenu> */}
            </ToolbarRight>
          </DetailToolbar>
          {values.user ? (
            <Typography style={{opacity: 0.5}}>
              Created by: {values.user.first_name} {values.user.last_name}
            </Typography>
          ) : (
            <></>
          )}
          <Grid container>
            <Grid item xl={3} md={4}>
              {!c || customerDataLoading ? (
                <></>
              ) : (
                <PlainContainer>
                  <Typography variant="h6">{c.name}</Typography>
                  <a
                    href={`https://maps.google.com/maps?q=${
                      c.street1
                    }+${c.city && c.city.name}+${c.region}+${c.postcode}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Typography variant="subtitle2">{c.street1} </Typography>
                    <Typography variant="body2">
                      {c.city && c.city.name}, {c.region} {c.postcode}{' '}
                    </Typography>
                    <Typography variant="body2">
                      {c.city && c.city.county}
                    </Typography>
                  </a>
                  <Space sizePx={20} />
                  <Typography>Phones</Typography>
                  {c.phones.map(p => (
                    <InlineLabel label={p.label} key={p.id}>
                      <Typography variant="body2" display="inline">
                        <a href={'tel:' + p.number.replace(/\D/g, '')}>
                          {formatPhone(p.number)}
                        </a>
                      </Typography>
                    </InlineLabel>
                  ))}

                  <Space sizePx={20} />
                  <Typography>Email</Typography>
                  {(c.emails || []).map(email => (
                    <InlineLabel
                      label={email.label}
                      key={email.id}
                      bold={email.default_email}
                    >
                      <Typography variant="body2" display="inline">
                        <a href={'mailto:' + email.email}>{email.email}</a>
                      </Typography>
                    </InlineLabel>
                  ))}
                </PlainContainer>
              )}
            </Grid>
            <Grid item xl={9} md={8}>
              <PaperContainer style={{minWidth: '100%'}}>
                <ContentHeader>Basic info</ContentHeader>
                <Grid container style={{minWidth: '100%'}} gap={2}>
                  <Grid item xl={6} sm={8}>
                    <TextField
                      name={'name'}
                      variant="filled"
                      label={'Name'}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xl={5} sm={8}>
                    <TextField
                      name={'proposal_notes'}
                      variant="filled"
                      label={'Service Delivery notes'}
                      multiline
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <Space />
                <ContentHeader>Contract details</ContentHeader>
                <Grid container style={{minWidth: '100%'}} gap={2}>
                  <Grid
                    item
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '500px',
                    }}
                  >
                    <DatePicker
                      name={'contract_start_at'}
                      label={'Contract Start At'}
                      inputVariant="filled"
                      size="small"
                      format="dddd, MMMM D, YYYY"
                      fullWidth
                    />
                    <IconButton
                      size="small"
                      onClick={() => setFieldValue('contract_start_at', null)}
                    >
                      <Close />
                    </IconButton>
                    <Space inline />
                    <ArrowForward />
                    <Space inline />
                    <DatePicker
                      name={'contract_stop_at'}
                      label={'Contract Stop At'}
                      inputVariant="filled"
                      size="small"
                      format="dddd, MMMM D, YYYY"
                      fullWidth
                    />
                    <IconButton
                      size="small"
                      onClick={() => setFieldValue('contract_stop_at', null)}
                    >
                      <Close />
                    </IconButton>
                  </Grid>
                </Grid>
                <Space />
                <ContentHeader>Contact Info</ContentHeader>
                <Grid container style={{minWidth: '100%'}} gap={2}>
                  <Grid item lg={3}>
                    <TextField
                      fullWidth
                      name={'submitted_to_name'}
                      label="Submitted to "
                      variant="filled"
                      size="small"
                    />
                  </Grid>
                  <Grid item lg={3}>
                    <TextField
                      fullWidth
                      name={'submitted_to_email'}
                      label="Submitted to email"
                      variant="filled"
                      size="small"
                      inputMode="email"
                    />
                  </Grid>
                  <Grid item lg={3}>
                    <PhoneNumber
                      fullWidth
                      name={'submitted_to_phone'}
                      label="Submitted to phone"
                      variant="filled"
                      size="small"
                      allowEmpty
                    />
                  </Grid>
                </Grid>
                <Space />
                <ContentHeader>Photos</ContentHeader>
                <Grid container style={{minWidth: '100%'}} gap={2}>
                  <Grid item xl={5}>
                    <TextField
                      name={'photo_comments'}
                      variant="filled"
                      label={'Photo Comments'}
                      multiline
                      style={{minWidth: '500px'}}
                      size="small"
                    />
                    <Space inline />
                    {images.length + (values.photos?.length || 0) == 0 && (
                      <IconButton
                        id="addPhotos"
                        onClick={() => fileInputRef.current.click()}
                        style={{alignSelf: 'center'}}
                      >
                        <AddAPhoto />
                      </IconButton>
                    )}
                    <input
                      type="file"
                      multiple
                      accept="image/*"
                      style={{display: 'none'}}
                      ref={fileInputRef}
                      onChange={handleFileSelect}
                    />
                  </Grid>
                  {images.length + (values.photos?.length || 0) > 0 && (
                    <Grid
                      item
                      md={12}
                      style={{
                        display: 'flex',
                        gap: '10px',
                        flexWrap: 'wrap',
                        marginBottom: '16px',
                      }}
                    >
                      <FieldArray name="photos">
                        {({remove}) =>
                          values.photos?.map((p, i) => (
                            <div
                              key={p.id}
                              style={{
                                position: 'relative',
                                width: '100px',
                                height: '100px',
                              }}
                            >
                              <MiniImage
                                src={p.thumb_url}
                                as={MiniPRImage}
                                onClick={() => {
                                  setShowPhoto(true)
                                  setPhotoSrc(p.url)
                                }}
                                hover={true}
                              />
                              <DeletePhotoButton
                                size="small"
                                onClick={() => remove(i)}
                              >
                                <Close />
                              </DeletePhotoButton>
                            </div>
                          )) || <></>
                        }
                      </FieldArray>
                      {images.map((image, index) => (
                        <div
                          key={index}
                          style={{
                            position: 'relative',
                            width: '100px',
                            height: '100px',
                          }}
                        >
                          <MiniImage
                            as={MiniHTMLImage}
                            src={URL.createObjectURL(image)}
                            onClick={() => {
                              setShowPhoto(true)
                              setPhotoSrc(URL.createObjectURL(image))
                            }}
                            hover
                          />
                          <DeletePhotoButton
                            size="small"
                            onClick={() => removeImage(index)}
                          >
                            <Close />
                          </DeletePhotoButton>
                        </div>
                      ))}

                      <IconButton
                        id="addPhotos"
                        onClick={() => fileInputRef.current.click()}
                        style={{alignSelf: 'center'}}
                      >
                        <AddAPhoto />
                      </IconButton>
                    </Grid>
                  )}
                </Grid>
              </PaperContainer>
            </Grid>
          </Grid>
          <Space />
          <FieldArray name="serviceGroups">
            {({remove: removeGroup, push}) => (
              <MainContainer>
                {/* Summary */}
                <Typography
                  variant="h6"
                  style={{display: 'flex', alignItems: 'center'}}
                >
                  Summary
                </Typography>

                {values.serviceGroups?.length ? (
                  values.serviceGroups.map((group, i) => (
                    <div key={group.saleitem_id + '-' + i}>
                      <Typography
                        style={{display: 'flex', alignItems: 'center'}}
                      >
                        <FormControlLabel
                          label={
                            <span style={{fontWeight: 'bold'}}>
                              {saleItemDic[group.saleitem_id]?.description ||
                                'Service'}{' '}
                              -{' '}
                              {formatMoneyStandard(
                                group.estimateitems.reduce(
                                  (acc, item) => acc + item.price_override,
                                  0,
                                ),
                              )}
                            </span>
                          }
                          control={
                            <MuiCheckbox
                              checked={
                                group.estimateitems?.filter(ei => ei.selected)
                                  .length === group.estimateitems?.length
                              }
                              indeterminate={
                                group.estimateitems?.filter(ei => ei.selected)
                                  .length > 0 &&
                                group.estimateitems?.filter(ei => ei.selected)
                                  .length < group.estimateitems?.length
                              }
                              onChange={e => {
                                group.estimateitems?.forEach((ei, ii) =>
                                  setFieldValue(
                                    `serviceGroups.${i}.estimateitems.${ii}.selected`,
                                    e.target.checked,
                                  ),
                                )
                              }}
                            />
                          }
                        />
                      </Typography>
                      {group.estimateitems?.map((ei, ii) => (
                        <Typography key={ei.id}>
                          <FormControlLabel
                            style={{marginLeft: '20px'}}
                            label={`${ei.estimateservice?.label} ${
                              ei.description ? '(' + ei.description + ')' : ''
                            } - ${formatMoneyStandard(ei.price_override)}`}
                            control={
                              <Checkbox
                                name={`serviceGroups.${i}.estimateitems.${ii}.selected`}
                              />
                            }
                          />
                        </Typography>
                      ))}
                    </div>
                  ))
                ) : (
                  <></>
                )}

                <Space />

                {/* Details */}
                <Typography
                  variant="h6"
                  style={{display: 'flex', alignItems: 'center'}}
                >
                  Details
                </Typography>

                {values.serviceGroups?.map((group, i) => {
                  return (
                    <ItemsContainer
                      key={group.saleitem_id + '-' + i}
                      style={{width: '100%'}}
                    >
                      <FieldArray name={`serviceGroups.${i}.estimateitems`}>
                        {({remove, push}) => (
                          <>
                            {saleItemDic[group.saleitem_id]?.description ? (
                              <Typography variant="h6">
                                {saleItemDic[group.saleitem_id]?.description}
                              </Typography>
                            ) : (
                              <AutoCompleteField
                                as={SaleItemSelect}
                                name={`serviceGroups.${i}.saleitem_id`}
                                selectedId={values.serviceGroups[i].saleitem_id}
                                onSelectChange={s => {
                                  push({
                                    id: 'new' + getRandomString(5),
                                    estimateservice_id: null,
                                    estimateservice: null,
                                    selected: false,
                                    qty: 0,
                                    price_override: 0,
                                    frequency: DESIREDFREQUENCY.ONE_TIME.value,
                                  })
                                }}
                              />
                            )}

                            {group.estimateitems?.map((est, ii) => (
                              <EstimateItem
                                key={est.id}
                                selected={!!est.selected}
                              >
                                {isAccount && (
                                  <>
                                    <ContentHeader>Location</ContentHeader>
                                    <EstimateItemContents>
                                      <div
                                        style={{
                                          display: 'flex',
                                          alignItems: 'center',
                                        }}
                                      >
                                        <AutoCompleteField
                                          as={CustomerSelect}
                                          name={`serviceGroups.${i}.estimateitems.${ii}.customer_id`}
                                          inputVariant={'filled'}
                                          inputSize={'small'}
                                          selectedId={
                                            values.serviceGroups[i]
                                              .estimateitems[ii]?.customer_id
                                          }
                                          extraFilters={{
                                            account_id: {
                                              string: String(parentId),
                                            },
                                          }}
                                          inputProps={{
                                            style: {width: '500px'},
                                          }}
                                        />
                                        <Space inline />
                                        <Button
                                          variant="contained"
                                          color="secondary"
                                          size="small"
                                          onClick={() => {
                                            setShowNewCustomer(true)
                                            setFieldKeyForNewCustomer(
                                              `serviceGroups.${i}.estimateitems.${ii}.customer_id`,
                                            )
                                          }}
                                        >
                                          {' '}
                                          <Add /> <Person />
                                        </Button>
                                      </div>
                                    </EstimateItemContents>
                                    <SearchDivider />
                                  </>
                                )}
                                <ContentHeader>Service Details</ContentHeader>
                                <EstimateItemContents>
                                  <div
                                    style={{
                                      display: 'flex',
                                      gap: '10px',
                                      flexGrow: '1',
                                      flexDirection: 'column',
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: 'flex',
                                        gap: '5px',
                                        flexGrow: '1',
                                        alignItems: 'center',
                                        flexWrap: 'wrap',
                                      }}
                                    >
                                      <Checkbox
                                        name={`serviceGroups.${i}.estimateitems.${ii}.selected`}
                                      />
                                      <Typography style={{minWidth: '30px'}}>
                                        {
                                          values.serviceGroups[i]
                                            ?.estimateitems[ii]?.estimateservice
                                            ?.saleitem?.name
                                        }
                                      </Typography>
                                      <AutoCompleteField
                                        as={EstimateserviceSelect}
                                        name={`serviceGroups.${i}.estimateitems.${ii}.estimateservice_id`}
                                        inputProps={{
                                          variant: 'filled',
                                          size: 'small',
                                          style: {width: '400px'},
                                        }}
                                        selectedId={
                                          values.serviceGroups[i]
                                            ?.estimateitems[ii]
                                            .estimateservice_id
                                        }
                                        onSelectChange={v =>
                                          setFieldValue(
                                            `serviceGroups.${i}.estimateitems.${ii}.estimateservice`,
                                            v,
                                          )
                                        }
                                        extraFilters={{
                                          saleitem_id: {
                                            string: String(
                                              values.serviceGroups[i]
                                                .saleitem_id,
                                            ),
                                          },
                                        }}
                                      />
                                      {est.estimateservice?.attributes?.map(
                                        (attr, iii) => (
                                          <FormControl
                                            variant="filled"
                                            key={attr.id}
                                          >
                                            <InputLabel>
                                              {attr.label}
                                            </InputLabel>
                                            <Select
                                              variant="filled"
                                              size="small"
                                              multiple={attr.multiselect}
                                              value={
                                                values.serviceGroups[i]
                                                  .estimateitems[ii]
                                                  ?.estimateservice?.attributes[
                                                  iii
                                                ].selected || []
                                              }
                                              onChange={e => {
                                                // set it as selected
                                                setFieldValue(
                                                  `serviceGroups.${i}.estimateitems.${ii}.estimateservice.attributes.${iii}.selected`,
                                                  e.target.value,
                                                )

                                                // recalculate
                                                const updatedValues = _.set(
                                                  {...values},
                                                  `serviceGroups.${i}.estimateitems.${ii}.estimateservice.attributes.${iii}.selected`,
                                                  e.target.value,
                                                )
                                                const price = round(
                                                  linePrice({
                                                    ...updatedValues
                                                      .serviceGroups[i]
                                                      .estimateitems[ii],
                                                    price_override: null,
                                                  }),
                                                  2,
                                                )

                                                // update override
                                                setFieldValue(
                                                  `serviceGroups.${i}.estimateitems.${ii}.price_override`,
                                                  price,
                                                )
                                              }}
                                              style={{minWidth: '100px'}}
                                              name={`serviceGroups.${i}.estimateitems.${ii}.estimateservice.attributes.${iii}`}
                                              id={`g-${i}-estimateitems-${ii}-attribute-${iii}`}
                                            >
                                              {attr.attributeoptions.map(v => (
                                                <MenuItem
                                                  key={v.id}
                                                  value={v.id}
                                                >
                                                  {v.label}
                                                </MenuItem>
                                              ))}
                                            </Select>
                                          </FormControl>
                                        ),
                                      )}
                                    </div>
                                    {!!values.serviceGroups[i].estimateitems[ii]
                                      .estimateservice?.notes && (
                                      <Typography
                                        color={colors.grey500}
                                        variant="subtitle1"
                                      >
                                        <pre>
                                          {
                                            values.serviceGroups[i]
                                              .estimateitems[ii].estimateservice
                                              ?.notes
                                          }
                                        </pre>
                                      </Typography>
                                    )}
                                    <div style={{display: 'flex'}}>
                                      <TextField
                                        name={`serviceGroups.${i}.estimateitems.${ii}.description`}
                                        variant={'outlined'}
                                        size={'small'}
                                        label="Description"
                                        multiline
                                        fullWidth
                                      />
                                    </div>
                                  </div>
                                  {!values.serviceGroups[i].estimateitems[ii]
                                    .estimateitemprices?.length ? (
                                    <div
                                      style={{
                                        display: 'flex',
                                        flexWrap: 'wrap',
                                        width: '20%',
                                        gap: '5px',
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: 'flex',
                                          gap: '5px',
                                          alignItems: 'center',
                                          flexDirection: 'row',
                                          flexGrow: 1,
                                        }}
                                      >
                                        {!values.serviceGroups[i].estimateitems[
                                          ii
                                        ].estimateservice?.is_custom_price ? (
                                          <>
                                            <TextField
                                              name={`serviceGroups.${i}.estimateitems.${ii}.qty`}
                                              variant={'filled'}
                                              type={'number'}
                                              label={'Qty'}
                                              size={'small'}
                                              onChange={async e => {
                                                setFieldValue(
                                                  `serviceGroups.${i}.estimateitems.${ii}.qty`,
                                                  Number.parseInt(
                                                    e.target.value,
                                                  ),
                                                )
                                                const price = round(
                                                  linePrice({
                                                    ...values.serviceGroups[i]
                                                      .estimateitems[ii],
                                                    price_override: null,
                                                    qty: Number.parseInt(
                                                      e.target.value,
                                                    ),
                                                  }),
                                                  2,
                                                )
                                                setFieldValue(
                                                  `serviceGroups.${i}.estimateitems.${ii}.price_override`,
                                                  price,
                                                )
                                              }}
                                              InputProps={{
                                                endAdornment: (
                                                  <InputAdornment position="end">
                                                    <Typography variant="caption">
                                                      {
                                                        values.serviceGroups[i]
                                                          .estimateitems[ii]
                                                          .estimateservice
                                                          ?.qty_label
                                                      }
                                                    </Typography>
                                                  </InputAdornment>
                                                ),
                                              }}
                                            />
                                          </>
                                        ) : (
                                          <Typography
                                            color={colors.grey500}
                                            style={{
                                              minWidth: '100px',
                                              width: '50%',
                                              border: `2px dashed ${colors.grey300}`,
                                              borderRadius: '5px',
                                              height: '50px',
                                              textAlign: 'center',
                                              display: 'flex',
                                              justifyContent: 'center',
                                              alignItems: 'center', // Vertical align
                                            }}
                                          >
                                            Custom Price
                                          </Typography>
                                        )}
                                        <TextField
                                          name={`serviceGroups.${i}.estimateitems.${ii}.price_override`}
                                          variant={'filled'}
                                          type={'number'}
                                          label={'Price'}
                                          size={'small'}
                                          InputProps={{
                                            startAdornment: (
                                              <InputAdornment position="start">
                                                <Typography variant="caption">
                                                  $
                                                </Typography>
                                              </InputAdornment>
                                            ),
                                          }}
                                        />
                                      </div>
                                      <div
                                        style={{
                                          display: 'flex',
                                          gap: '5px',
                                          flexDirection: 'row',
                                          flexFlow: 'wrap',
                                        }}
                                      >
                                        <TextField
                                          select
                                          name="frequency"
                                          variant={'filled'}
                                          onChange={e =>
                                            setFieldValue(
                                              `serviceGroups.${i}.estimateitems.${ii}.frequency`,
                                              e.target.value,
                                            )
                                          }
                                          value={
                                            values.serviceGroups[i]
                                              .estimateitems[ii].frequency
                                          }
                                          size="small"
                                          defaultValue={0}
                                          label="Frequency"
                                          style={{
                                            width: '170px',
                                          }}
                                        >
                                          {Object.values(DESIREDFREQUENCY).map(
                                            ({value, label}) => (
                                              <MenuItem
                                                value={value}
                                                key={value}
                                              >
                                                {value === 0 ? (
                                                  <span
                                                    style={{
                                                      fontStyle: 'italic',
                                                    }}
                                                  >
                                                    Select Frequency
                                                  </span>
                                                ) : (
                                                  label
                                                )}
                                              </MenuItem>
                                            ),
                                          )}
                                        </TextField>
                                        <Button
                                          size="small"
                                          onClick={() =>
                                            setFieldValue(
                                              `serviceGroups.${i}.estimateitems.${ii}.estimateitemprices`,
                                              [
                                                {
                                                  id:
                                                    'new' + getRandomString(5),
                                                  price: linePrice(
                                                    values.serviceGroups[i]
                                                      .estimateitems[ii],
                                                  ),
                                                },
                                              ],
                                            )
                                          }
                                          style={{
                                            alignSelf: 'flex-start',
                                            color: colors.grey600,
                                            alignItems: 'center',
                                            display: 'flex',
                                            width: '135px',
                                          }}
                                        >
                                          <Add />
                                          {/* Sue me */}
                                          <span
                                            style={{
                                              marginTop: '.3em',
                                              lineHeight: '.90em',
                                            }}
                                          >
                                            Add Another Frequency
                                          </span>
                                        </Button>
                                      </div>
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                  <IconButton
                                    style={{alignSelf: 'center'}}
                                    onClick={() =>
                                      push({
                                        ...est,
                                        id: 'new' + getRandomString(5),
                                        selected: false,
                                        estimateitemprices:
                                          est.estimateitemprices?.map(p => ({
                                            ...p,
                                            id: 'new' + getRandomString(5),
                                          })) || [],
                                      })
                                    }
                                  >
                                    <ContentCopy />
                                  </IconButton>
                                  <IconButton
                                    style={{alignSelf: 'center'}}
                                    color="warning"
                                    onClick={() => remove(ii)}
                                  >
                                    <Delete />
                                  </IconButton>
                                </EstimateItemContents>

                                {/* pricing */}
                                {values.serviceGroups[i].estimateitems[ii]
                                  .estimateitemprices?.length ? (
                                  <>
                                    <SearchDivider />
                                    <ContentHeader>
                                      Quantity & Price
                                    </ContentHeader>
                                    <EstimateItemContents
                                      style={{
                                        marginTop: '.25em',
                                        marginBottom: '.25em',
                                      }}
                                    >
                                      <TextField
                                        select
                                        name="frequency"
                                        variant={'filled'}
                                        onChange={e =>
                                          setFieldValue(
                                            `serviceGroups.${i}.estimateitems.${ii}.frequency`,
                                            e.target.value,
                                          )
                                        }
                                        value={
                                          values.serviceGroups[i].estimateitems[
                                            ii
                                          ].frequency
                                        }
                                        size="small"
                                        defaultValue={0}
                                        label="Frequency"
                                        style={{
                                          width: '200px',
                                        }}
                                      >
                                        {Object.values(DESIREDFREQUENCY).map(
                                          ({value, label}) => (
                                            <MenuItem value={value} key={value}>
                                              {value === 0 ? (
                                                <span
                                                  style={{
                                                    fontStyle: 'italic',
                                                  }}
                                                >
                                                  Select Frequency
                                                </span>
                                              ) : (
                                                label
                                              )}
                                            </MenuItem>
                                          ),
                                        )}
                                      </TextField>
                                      {!values.serviceGroups[i].estimateitems[
                                        ii
                                      ].estimateservice?.is_custom_price && (
                                        <>
                                          <TextField
                                            name={`serviceGroups.${i}.estimateitems.${ii}.qty`}
                                            variant={'filled'}
                                            type={'number'}
                                            label={'Qty'}
                                            size={'small'}
                                            onChange={async e => {
                                              setFieldValue(
                                                `serviceGroups.${i}.estimateitems.${ii}.qty`,
                                                Number.parseInt(e.target.value),
                                              )
                                              const price = round(
                                                linePrice({
                                                  ...values.serviceGroups[i]
                                                    .estimateitems[ii],
                                                  price_override: null,
                                                  qty: Number.parseInt(
                                                    e.target.value,
                                                  ),
                                                }),
                                                2,
                                              )
                                              setFieldValue(
                                                `serviceGroups.${i}.estimateitems.${ii}.price_override`,
                                                price,
                                              )
                                            }}
                                            InputProps={{
                                              endAdornment: (
                                                <InputAdornment position="end">
                                                  <Typography variant="caption">
                                                    {
                                                      values.serviceGroups[i]
                                                        .estimateitems[ii]
                                                        .estimateservice
                                                        ?.qty_label
                                                    }
                                                  </Typography>
                                                </InputAdornment>
                                              ),
                                            }}
                                          />
                                          <Space inline />
                                        </>
                                      )}
                                      <TextField
                                        name={`serviceGroups.${i}.estimateitems.${ii}.price_override`}
                                        variant={'filled'}
                                        type={'number'}
                                        label={'Price'}
                                        size={'small'}
                                        InputProps={{
                                          startAdornment: (
                                            <InputAdornment position="start">
                                              <Typography variant="caption">
                                                $
                                              </Typography>
                                            </InputAdornment>
                                          ),
                                        }}
                                      />
                                    </EstimateItemContents>
                                    <FieldArray
                                      name={`serviceGroups.${i}.estimateitems.${ii}.estimateitemprices`}
                                    >
                                      {({remove, push}) => (
                                        <>
                                          {values.serviceGroups[
                                            i
                                          ].estimateitems[
                                            ii
                                          ].estimateitemprices?.map(
                                            (p, iii) => (
                                              <EstimateItemContents
                                                key={p.id}
                                                style={{marginTop: '.25em'}}
                                              >
                                                <TextField
                                                  select
                                                  name={`frequency_${p.id}`}
                                                  variant={'filled'}
                                                  onChange={e =>
                                                    setFieldValue(
                                                      `serviceGroups.${i}.estimateitems.${ii}.estimateitemprices.${iii}.frequency`,
                                                      e.target.value,
                                                    )
                                                  }
                                                  value={
                                                    values.serviceGroups[i]
                                                      .estimateitems[ii]
                                                      .estimateitemprices[iii]
                                                      .frequency
                                                  }
                                                  size="small"
                                                  defaultValue={0}
                                                  label="Frequency"
                                                  style={{
                                                    width: '200px',
                                                  }}
                                                >
                                                  {Object.values(
                                                    DESIREDFREQUENCY,
                                                  ).map(({value, label}) => (
                                                    <MenuItem
                                                      value={value}
                                                      key={value}
                                                    >
                                                      {value === 0 ? (
                                                        <span
                                                          style={{
                                                            fontStyle: 'italic',
                                                          }}
                                                        >
                                                          Select Frequency
                                                        </span>
                                                      ) : (
                                                        label
                                                      )}
                                                    </MenuItem>
                                                  ))}
                                                </TextField>
                                                <TextField
                                                  name={`serviceGroups.${i}.estimateitems.${ii}.estimateitemprices.${iii}.price`}
                                                  variant={'filled'}
                                                  type={'number'}
                                                  label={'Custom Price'}
                                                  size={'small'}
                                                  InputProps={{
                                                    startAdornment: (
                                                      <InputAdornment position="start">
                                                        <Typography variant="caption">
                                                          $
                                                        </Typography>
                                                      </InputAdornment>
                                                    ),
                                                  }}
                                                />
                                                <IconButton
                                                  style={{alignSelf: 'center'}}
                                                  color="warning"
                                                  onClick={() => remove(iii)}
                                                >
                                                  <Delete />
                                                </IconButton>
                                              </EstimateItemContents>
                                            ),
                                          )}
                                          <EstimateItemContents>
                                            <Button
                                              size="small"
                                              onClick={() =>
                                                push({
                                                  id:
                                                    'new' + getRandomString(5),
                                                  price: linePrice(
                                                    values.serviceGroups[i]
                                                      .estimateitems[ii],
                                                  ),
                                                })
                                              }
                                              style={{
                                                alignSelf: 'flex-start',
                                                color: colors.grey600,
                                                alignItems: 'center',
                                                display: 'flex',
                                              }}
                                            >
                                              <Add />
                                              {/* Sue me */}
                                              <span style={{marginTop: '.3em'}}>
                                                Add Frequency
                                              </span>
                                            </Button>
                                          </EstimateItemContents>
                                        </>
                                      )}
                                    </FieldArray>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </EstimateItem>
                            ))}
                            {!values.serviceGroups[i].estimateitems?.length ? (
                              <IconButton
                                style={{alignSelf: 'flex-end'}}
                                color="warning"
                                onClick={() => removeGroup(i)}
                              >
                                <Delete />
                              </IconButton>
                            ) : (
                              <></>
                            )}
                            {values.serviceGroups[i].saleitem_id &&
                            values.serviceGroups[i].saleitem_id !== '0' ? (
                              <Button
                                variant="contained"
                                color="secondary"
                                style={{width: '50px'}}
                                size="small"
                                onClick={() =>
                                  push({
                                    id: 'new' + getRandomString(5),
                                    estimateservice_id: null,
                                    estimateservice: null,
                                    selected: false,
                                    qty: 0,
                                    price_override: 0,
                                    frequency: DESIREDFREQUENCY.ONE_TIME.value,
                                  })
                                }
                                id={`newEstimateitem-${i}`}
                              >
                                <Add />
                              </Button>
                            ) : (
                              <></>
                            )}
                          </>
                        )}
                      </FieldArray>
                    </ItemsContainer>
                  )
                })}
                <Button
                  variant="contained"
                  style={{alignSelf: 'center'}}
                  color="secondary"
                  size="small"
                  onClick={() =>
                    push({
                      saleitem_id: 0,
                      estimateitems: [],
                    })
                  }
                  id="newService"
                >
                  <Add /> New Service
                </Button>
              </MainContainer>
            )}
          </FieldArray>
          <Bottombar elevation={24}>
            <Tooltip
              placement="top"
              title={
                <Typography>
                  {isValid && !dirty && 'No changes made'}
                  {isValid && dirty && <Check />}
                  {mapErrors(errors)}
                </Typography>
              }
              arrow
            >
              <span id="saveEstimate">
                <LoadingButton
                  variant="contained"
                  onClick={submitForm}
                  loading={isSubmitting}
                  disabled={!isValid || isSubmitting || !dirty}
                >
                  <Save /> SAVE
                </LoadingButton>
              </span>
            </Tooltip>
            <Button
              onClick={e => {
                if (dirty) {
                  setConfirmSave(true)
                } else {
                  onClose(e)
                }
              }}
            >
              Close
            </Button>
            <div
              style={{
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <AutoCompleteField
                as={DiscountSelect}
                name={`discount_id`}
                selectedId={values.discount_id}
                inputProps={{variant: 'filled', size: 'small'}}
                onSelectChange={i => {
                  setFieldValue(`discount`, i)
                }}
                style={{
                  width: '300px',
                }}
              />
              <Space inline sizePx={50} />
              <Typography variant="h5">Selected Total: </Typography>
              <Space inline sizePx={10} />
              {!!values.discount_id && !!values.discount && (
                <div>
                  <Typography
                    variant="h5"
                    style={{position: 'relative', display: 'inline-block'}}
                  >
                    {formatMoneyStandard(
                      estimatePrice(mungeForSaveFormat(values), true, true),
                    )}
                    <span
                      style={{
                        content: '""',
                        position: 'absolute',
                        left: -5,
                        top: '.55em', // Start from the top instead of the bottom
                        width: '110%',
                        height: '4px',
                        backgroundColor: 'red',
                      }}
                    ></span>
                  </Typography>
                  <Space inline sizePx={20} />
                </div>
              )}
              <Typography variant="h5" id="estimatePrice">
                {formatMoneyStandard(
                  estimatePrice(mungeForSaveFormat(values), false, true),
                )}
              </Typography>
              <Space inline sizePx={50} />
              <Typography variant="subtitle1">(All Items Total: </Typography>
              <Space inline sizePx={10} />
              <Typography variant="subtitle1" id="allItemsPrice">
                {formatMoneyStandard(
                  estimatePrice(mungeForSaveFormat(values), false, false),
                )}
                )
              </Typography>
            </div>
          </Bottombar>
          {isAccount && (
            <EditDetails
              open={!!showNewCustomer}
              onSubmit={id => {
                setFieldValue(fieldKeyForNewCust, id)
                setShowNewCustomer(false)
              }}
              onCancel={() => {
                setShowNewCustomer(false)
              }}
              defaultValues={{
                account_id: parentId,
              }}
            />
          )}
          <Dialog
            open={showPhoto}
            maxWidth={'md'}
            fullWidth
            onClose={() => setShowPhoto(false)}
          >
            {photoSrc.includes('blob:') ? (
              <MiniHTMLImage src={photoSrc} />
            ) : (
              <MiniPRImage src={photoSrc} />
            )}
          </Dialog>
          <Dialog open={confirmSave} onClose={() => setConfirmSave(false)}>
            <DialogContent>
              <Typography variant="h6">Save changes?</Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setConfirmSave(false)}>
                Keep editing
              </Button>
              <Button onClick={onClose} variant="contained" color="inherit">
                Close without saving
              </Button>
              <LoadingButton
                variant="contained"
                onClick={submitForm}
                loading={isSubmitting}
                disabled={!isValid || isSubmitting || !dirty}
              >
                <Save /> SAVE
              </LoadingButton>
            </DialogActions>
          </Dialog>
        </React.Fragment>
      )}
    </Formik>
  )
}

export const Estimate = ({match, location}) => {
  let [goBack, setGoBack] = useState(false)

  let isAccount = (match.path.split('/')[1] || undefined) !== 'customers'

  let queryClient = useQueryClient()
  let {data, isError, isLoading} = useQuery(
    ['estimate', match.params.estimateId],
    async () =>
      await prgql({query: getQuery, variables: {id: match.params.estimateId}}),
  )

  let {
    data: customerData,
    isLoading: customerDataLoading,
    isError: customerDataError,
  } = useQuery(
    ['customer', match.params.id],
    async () =>
      await prgql({query: customerQuery, variables: {id: match.params.id}}),
  )

  let {
    data: accountData,
    isLoading: accountDataLoading,
    isError: accountDataError,
  } = useQuery(
    ['account', match.params.id],
    async () =>
      await prgql({query: accountQuery, variables: {id: match.params.id}}),
  )

  let {mutateAsync} = useMutation('update', async input => {
    return await prgql({
      query: updateMutation,
      variables: {input},
    })
  })

  let {mutateAsync: destroy} = useMutation('delete', async ({id}) => {
    return await prgql({
      query: deleteMutation,
      variables: {id},
    })
  })

  let {mutateAsync: destroyPhoto} = useMutation('deletePhoto', async ({id}) => {
    return await prgql({
      query: deletePhotoMutation,
      variables: {id},
    })
  })

  const destroyPhotos = async (photos = [], oldPhotos = []) => {
    const photosToDelete = oldPhotos.filter(
      oldPhoto => !photos.some(photo => photo.id === oldPhoto.id),
    )

    await Promise.all(photosToDelete.map(photo => destroyPhoto({id: photo.id})))

    return photosToDelete
  }

  // prepare graphql data to work with form.
  let initialData
  if (data && data?.item) {
    initialData = mungeEstimateDataForCalculation(data.item)
  }

  return (
    <>
      <Container>
        {isLoading ? (
          <div>Loading...</div>
        ) : isError ? (
          <div>Error</div>
        ) : (
          <Form
            formikkey={match.params.id + (isAccount ? 'a' : 'c')}
            initial={initialData}
            onSubmit={async values => {
              let input = mungeValues(
                values,
                match.params.id,
                initialData,
                isAccount,
              )

              await mutateAsync(input)
              await uploadPhotos(values.images || [], match.params.estimateId)
              await destroyPhotos(values.photos || [], initialData.photos || [])
              // I spent hours trying to figure this out so let me explain.
              // I don't upload photos with GraphQL, so that layer doesn't know that new photos have been added.
              // You can await all you want, but deliberately calling a refresh doesn't get through react-query's thick head that
              // there's new data there.
              //
              // This code ensures that the query results get nuked AFTER whatever shenanigans react-query does when we navigate back,
              // thus ensuring that Formik always has the latest data when you load in. Otherwise, react-query will
              // pull the results from the cache and Formik won't load in pictures.
              //
              // If I didn't load in the formik form first and went to a detail page, this would be irrelavent.
              // ~ Matt Krell, 2024-11-26
              setTimeout(() =>
                queryClient.removeQueries({
                  queryKey: ['estimate', match.params.estimateId],
                }),
              )
              toaster.success('Estimate saved')
              setGoBack(true)
            }}
            onDelete={async id => {
              await destroy({id})
              toaster.success('Estimate trashed')
              setGoBack(true)
            }}
            onClose={() => setGoBack(true)}
            isAccount={isAccount}
            parentId={match.params.id}
            customerData={isAccount ? accountData : customerData}
            customerDataLoading={
              isAccount ? accountDataLoading : customerDataLoading
            }
          />
        )}
        {/* This is extremely evil. we need a hook that'll do this */}
        {goBack && (
          <Redirect
            to={`/${isAccount ? 'accounts' : 'customers'}/${
              match.params.id
            }/estimates`}
            marker={isAccount ? 'EstimatesAccountBack' : 'EstimatesBack'}
          />
        )}
      </Container>
    </>
  )
}

export const NewEstimate = ({match, location}) => {
  let [goBack, setGoBack] = useState(false)

  let isAccount = (match.path.split('/')[1] || undefined) !== 'customers'

  let {mutateAsync} = useMutation('create', async input => {
    return await prgql({
      query: createMutation,
      variables: {input},
    })
  })

  let {
    data: customerData,
    isLoading: customerDataLoading,
    isError: customerDataError,
  } = useQuery(
    ['customer', match.params.id],
    async () =>
      await prgql({query: customerQuery, variables: {id: match.params.id}}),
  )

  let {
    data: accountData,
    isLoading: accountDataLoading,
    isError: accountDataError,
  } = useQuery(
    ['account', match.params.id],
    async () =>
      await prgql({query: accountQuery, variables: {id: match.params.id}}),
  )

  return (
    <>
      <Container>
        <Form
          formikkey={match.params.id + (isAccount ? 'a' : 'c')}
          initial={{
            name: 'Standard Estimate',
          }}
          onSubmit={async values => {
            let input = mungeValues(values, match.params.id, null, isAccount)
            const res = await mutateAsync(input)
            await uploadPhotos(
              values.images || [],
              res?.createEstimates.estimates.id,
            )
            setGoBack(true)
            toaster.success('Estimate Created')
          }}
          onClose={() => setGoBack(true)}
          isAccount={isAccount}
          parentId={match.params.id}
          customerData={isAccount ? accountData : customerData}
          customerDataLoading={
            isAccount ? accountDataLoading : customerDataLoading
          }
        />
      </Container>
      {/* This is extremely evil. we need a hook that'll do this */}
      {goBack && (
        <Redirect
          to={`/${isAccount ? 'accounts' : 'customers'}/${
            match.params.id
          }/estimates`}
          marker={isAccount ? 'EstimatesAccountBack' : 'EstimatesBack'}
        />
      )}
    </>
  )
}

export default Estimate
