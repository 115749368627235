import React, {useEffect, useState} from 'react'
import {Link, Route, Switch} from 'react-router-dom'
import * as Yup from 'yup'

import Button from '@mui/material/Button'
import {Toolbar} from '../../AppHandler'
import {Space, Spacing} from '../../components/Layout'
import {
  Chip,
  ClickAwayListener,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Menu,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import Typography from '@mui/material/Typography'
import {
  ArrowDropDown,
  ArrowRight,
  Loop as RepeatIcon,
  Event as RepeatOneIcon,
  Receipt,
  Schedule,
  PlusOne,
  ArrowLeft,
  ChevronLeft,
  ChevronRight,
  Cancel,
  Clear,
  ArrowForward,
  Map,
} from '@mui/icons-material'

import {LinearProgress, IconButton, Switch as MuiSwitch} from '@mui/material'
import gql from 'graphql-tag'
import {
  useQuery as useReactQuery,
  useMutation as useReactQueryMutation,
} from 'react-query'
import _, {parseInt} from 'lodash'
import moment from 'moment'
import {
  ToolbarIconMenu,
  ToolbarLeft,
  ToolbarMenuItem,
  ToolbarRight,
} from '../../components/Toolbar'
import {DatePicker} from '@mui/lab'
import {Daily} from './Daily'
import {Weekly} from './Weekly'
import LoadingButton from '../../components/LoadingButton'
import {useMutation} from 'react-apollo'
import executableSchema from '../../schema'
import {prgql} from '../../utils/graphql'
import {useQueryClient} from 'react-query'
import {map} from 'lodash'
import queryString from 'query-string'
import ModifyQueryParams from '../../components/ModifyQueryParams'
import styled from 'styled-components'
import {useDebounce} from '../../utils'
import SelectMenu from '../../components/ToolbarDropDownMenu'
import theme, {DarkTheme, PRThemeProvider, Theme} from '../../styles/theme'
import {ThemeProvider, StyledEngineProvider} from '@mui/material/styles'
import {Formik} from 'formik'
import {Checkbox} from '../../components/forms'
import {DatePicker as FormDatePicker} from '../../components/forms'
import DangerLoadingButton from '../../components/DangerLoadingButton'
import {Overview} from './Overview'
import {RouteSelectStatic} from '../../components/RouteSelect'
import {lookupQuery} from './scheduleComponents'
import {useAuth} from '../../security/auth'
import {fragments} from '../Workorders/WorkordersList'

let SchedulesFragment = gql`
  fragment SchedulesDetail on Schedules {
    id
    route_id
    customer_id
    charge
    man_minutes
    sequence
    start_at
    end_at
    freq_type
    freq_interval
    freq_relative_interval
    freq_recurrence_factor
    arrival_start_time
    arrival_end_time
    estimated_total
    week
    day
    services {
      id
      saleitem_id
      saleitem {
        id
        name
        description
      }
      customer_id
      description
      notes
      taxable
      man_minutes
      charge
      sales_person_id
      tasks {
        id
        tasktype_id
      }
      discount_id
      discount {
        id
        name
        type
        amount
        active
      }
      jobrequirements {
        id
        jobrequirementtype_id
        jobrequirementtype {
          id
          name
          type
        }
        qty
      }
      commission_start_at
    }
  }
`

let routerunsQuery = gql`
  query Routeruns($filters: RouteRunsFilters) {
    # allRouteRuns(filters: $filters, limit: 100000) {
    allRouteRuns: fullSchedule(filters: $filters) {
      edges {
        edge {
          id
          run_at
          route_id
          route {
            id
            name
            shift {
              id
              name
              description
              start_time
            }
          }
          income_capacity
          time_capacity
          start_time
          notes
          scheduler_notes
          shift_id
          week
          users {
            id
            first_name
            last_name
          }
          user_id
          workorders {
            id
            assigned_at
            sequence
            completed_at
            status
            route_id
            customer_id
            notes
            notes_resolved
            multiday
            multiday_estimated_total
            arrival_start_time
            arrival_end_time
            reminder_sent
            not_completed
            is_billable
            customer {
              id
              name
              street1
              key_access
              po_needed
              needs_service_confirmation
              rain_sensitive
              move_obstruction_for_service
              city {
                id
                name
              }
              unresolved_tech {
                id
              }
              region
              postcode
              taxable
              taxitem {
                id
                rate
              }
              services_paused
            }
            invoice_id
            workorderitems {
              ...WorkorderitemDetail
            }
            schedule_id
            # schedule {
            #   id
            # }
          }
          schedules {
            ...SchedulesDetail
            customer {
              id
              name
              street1
              po_needed
              key_access
              needs_service_confirmation
              rain_sensitive
              move_obstruction_for_service
              city {
                id
                name
              }
              unresolved_tech {
                id
              }
              region
              postcode
              taxable
              taxitem {
                id
                rate
              }
              services_paused
            }
            exclusions {
              id
              exclude_at
              reschedule_at
              reschedule_route_id
            }
          }
          assets {
            id
            name
            assettype_id
          }
        }
      }
    }
    allJobrequirementtypes(limit: 100000) {
      edges {
        edge {
          id
          name
          type
        }
      }
    }
  }
  ${SchedulesFragment}
  ${fragments.workorderitem}
`

let allRoutesQuery = gql`
  query AllRoutes {
    allRoutes(limit: 100) {
      edges {
        edge {
          id
          name
          active
        }
      }
    }
  }
`

let attendanceQuery = gql`
  query attendance($from: String!, $to: String!) {
    attendance: allAttendance(filters: {from: $from, to: $to}) {
      edges {
        edge {
          id
          user_id
          taken_at
          type
          hours
          automated
        }
      }
    }
  }
`

let createNewRouterunMutation = gql`
  mutation CreateRouterun($input: CreateRouteRunsInput) {
    createRouteRuns(input: $input) {
      routeRuns {
        id
      }
    }
  }
`

let updateFromTemplateMutation = gql`
  mutation($input: UpdateFromTemplateInput) {
    updateFromTemplate(input: $input) {
      message
    }
  }
`

function datesAreOnSameDay(first, second, thing) {
  return (
    first.getFullYear() === second.getFullYear() &&
    first.getMonth() === second.getMonth() &&
    first.getDate() === second.getDate()
  )
}

let mungeSearch = q => (q === 'all' ? undefined : q)

function munge(params) {
  let {
    date,
    routes,
    shift,
    editMode,
    summaryMode,
    openRow,
    openRoute,
    showWork,
    remaining,
    openWeek,
    overviewRoute,
  } = params
  date = moment(date || new Date())
    .utc()
    .startOf('day')
    .toDate()

  routes = routes ? String(routes).split(',') : []

  shift = mungeSearch(shift)

  editMode = editMode !== 'false'

  summaryMode = summaryMode === undefined ? false : summaryMode !== 'false'

  openRow = openRow !== null ? parseInt(openRow) : null

  openRoute = openRoute !== null ? openRoute : null

  showWork = showWork === undefined ? true : showWork !== 'false'

  remaining = remaining === undefined ? false : remaining !== 'false'

  openWeek = openWeek !== null ? openWeek : null

  overviewRoute = overviewRoute ? overviewRoute : '1'

  return {
    date,
    routes,
    shift,
    editMode,
    summaryMode,
    openRow,
    openRoute,
    showWork,
    remaining,
    openWeek,
    overviewRoute,
  }
}

let CreateRouterunDialog = ({isOpen, onClose, validRoutes, day, onSubmit}) => {
  let [route, setRoute] = useState(validRoutes[0] || 0)
  let [loading, setLoading] = useState(false)
  let [createRouterun] = useMutation(createNewRouterunMutation)

  return (
    <Dialog open={isOpen} maxWidth="xs" fullWidth onClose={onClose}>
      <DialogTitle>Create Routerun</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item sm={6}>
            <Typography variant="h6">
              {moment(day)
                .utc()
                .format('dddd, MMMM D, YYYY')}{' '}
            </Typography>
          </Grid>
          <Grid item sm={6}>
            <TextField
              name="Route"
              variant="filled"
              label={'route'}
              value={route}
              fullWidth
              select
              onChange={e => setRoute(e.target.value)}
            >
              {validRoutes.map(r => (
                <MenuItem value={r} key={r}>
                  Route {r}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <LoadingButton
          onClick={() => {
            setLoading(true)
            createRouterun({
              variables: {
                input: {
                  run_at: moment(day)
                    .utc()
                    .format('YYYY-MM-DD'),
                  route_id: route,
                  shift_id: 1,
                },
              },
            })
              .then(setLoading(false))
              .then(onClose)
              .then(onSubmit)
          }}
          loading={loading}
        >
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

let updateFromTemplateSchema = Yup.object().shape({
  update: Yup.array().min(1),
  from: Yup.date().required('Required'),
  to: Yup.date()
    .when(
      'from',
      (st, schema) => {
        return schema.min(st)
      },
      'end date has to be after start date',
    )
    .required('Required'),
})

let UpdateFromTemplateDialog = ({
  isOpen,
  from,
  to,
  onClose,
  onSubmit,
  routes,
  allRoutes,
}) => {
  let {mutate: updateFromTemplate, isError, isLoading} = useReactQueryMutation(
    async ({variables}) =>
      prgql({query: updateFromTemplateMutation, variables}),
    {
      onSuccess: () => {
        setConfirmOpen(false)
        onClose()
        onSubmit()
      },
    },
  )

  let [confirmOpen, setConfirmOpen] = useState(false)

  let isDaily = moment.utc(from).isSame(to)

  let preserveOptions = [
    'users',
    'user_id',
    'notes',
    'start_time',
    'income_capacity',
    'assets',
  ]

  return (
    <Formik
      initialValues={{
        update: ['users'],
        from: from,
        to: to,
      }}
      validationSchema={updateFromTemplateSchema}
    >
      {({values, isValid}) => (
        <>
          <Dialog open={isOpen} maxWidth="sm" fullWidth onClose={onClose}>
            <DialogTitle>Update From Template</DialogTitle>
            <DialogContent>
              <Grid container>
                <Grid item>
                  <FormDatePicker
                    name="from"
                    size="small"
                    inputVariant="outlined"
                  />
                </Grid>
                <Grid item style={{alignSelf: 'center'}}>
                  <ArrowForward />
                </Grid>
                <Grid item>
                  <FormDatePicker
                    name="to"
                    size="small"
                    inputVariant="outlined"
                  />
                </Grid>
              </Grid>
              <Typography>
                <br />
                <br />
                <ul>
                  <li>
                    <Checkbox color="secondary" name={'update'} value="users" />{' '}
                    Update Employees
                  </li>
                  <li>
                    <Checkbox
                      color="secondary"
                      name={'update'}
                      value="user_id"
                    />{' '}
                    Update Leader
                  </li>
                  <li>
                    <Checkbox color="secondary" name={'update'} value="notes" />{' '}
                    Update Notes
                  </li>
                  <li>
                    <Checkbox
                      color="secondary"
                      name={'update'}
                      value="start_time"
                    />{' '}
                    Update Time
                  </li>
                  <li>
                    <Checkbox
                      color="secondary"
                      name={'update'}
                      value="income_capacity"
                    />{' '}
                    Update Capacity
                  </li>
                  <li>
                    <Checkbox
                      color="secondary"
                      name={'update'}
                      value="assets"
                    />{' '}
                    Update Assets
                  </li>
                </ul>
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose}>Cancel</Button>
              <Button
                disabled={!isValid}
                variant={'contained'}
                color="primary"
                onClick={() => setConfirmOpen(true)}
              >
                Update from Template
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog open={confirmOpen}>
            <DialogTitle>Confirm Update</DialogTitle>
            <DialogContent>
              <Typography>
                Update{' '}
                <Typography variant="code">
                  {values.update
                    .map(v => {
                      switch (v) {
                        case 'users':
                          return 'Employees'
                        case 'user_id':
                          return 'Leader'
                        case 'notes':
                          return 'Notes'
                        case 'start_time':
                          return 'Time'
                        case 'income_capacity':
                          return 'Capacity'
                      }
                    })
                    .join(', ')}{' '}
                </Typography>
                <span style={{fontWeight: 'bold'}}>
                  {routes.length
                    ? `for Routes ${allRoutes &&
                        allRoutes.allRoutes.edges
                          .filter(r => routes.indexOf(r.edge.id) !== -1)
                          .map(r => r.edge.name)
                          .join(', ')}`
                    : 'for ALL routeruns on ALL routes'}{' '}
                </span>
                {isDaily ? (
                  <>
                    {' '}
                    on{' '}
                    <span style={{fontWeight: 'bold'}}>
                      {moment.utc(from).format('dddd, MMMM D, YYYY')}
                    </span>
                    ?
                  </>
                ) : (
                  <>
                    from{' '}
                    <span style={{fontWeight: 'bold'}}>
                      {moment.utc(values.from).format('dddd, MMMM D, YYYY')}
                    </span>{' '}
                    to{' '}
                    <span style={{fontWeight: 'bold'}}>
                      {moment.utc(values.to).format('dddd, MMMM D, YYYY')}
                    </span>{' '}
                    from the template?
                  </>
                )}
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setConfirmOpen(false)}>Cancel</Button>
              <DangerLoadingButton
                onClick={async () => {
                  updateFromTemplate({
                    variables: {
                      input: {
                        from: moment.utc(values.from).format('YYYY-MM-DD'),
                        to: moment.utc(values.to).format('YYYY-MM-DD'),
                        preserve: preserveOptions.filter(
                          v => values.update.indexOf(v) === -1,
                        ),
                        route_ids: routes,
                      },
                    },
                  })
                }}
                loading={isLoading}
                variant="contained"
                color="primary"
                id="danger-update-from-template"
              >
                Update from Template
              </DangerLoadingButton>
            </DialogActions>
          </Dialog>
        </>
      )}
    </Formik>
  )
}

let ShiftSelect = ({value = 'all', ...props}) => (
  <SelectMenu value={value} label="Shift" {...props}>
    <MenuItem value="all">All Shifts</MenuItem>
    <MenuItem value="1">Day Shift</MenuItem>
    <MenuItem value="2">Night Shift</MenuItem>
  </SelectMenu>
)

let RouteSelect = ({children, routes, selectedRoutes, setSelectedRoutes}) => (
  <Select
    variant="standard"
    label={'Routes'}
    name="routes"
    value={selectedRoutes}
    multiple
    input={
      <TextField variant="outlined" size="small" children={children} select />
    }
    // onChange={(e) => setSelectedRoutes(e.target.value)}
    onChange={setSelectedRoutes}
    renderValue={selected => {
      return (
        <div style={{display: 'flex'}}>
          {selected
            .sort((a, b) => a - b)
            .map(v => {
              let r = routes.find(r => r.edge.id === String(v))
              return r && <ChipRoute key={v}>{r.edge.name}</ChipRoute>
            })}
        </div>
      )
    }}
    style={{minWidth: '100px'}}
    children={children}
  />
)

let ChipRoute = styled.div`
  background-color: ${({theme}) => theme.palette.secondary.main};
  color: ${({theme}) => theme.palette.primary.contrastText};
  border-radius: 8px;
  padding: 0.25em;
  text-align: center;
  margin-right: 0.1em;
  min-width: 30px;
`

let SearchBarTextField = props => (
  <DarkTheme>
    <TextField {...props} size="small" variant="outlined" />
  </DarkTheme>
)

function ScreenToolbar({
  match,
  loading,
  selectedDate,
  setSelectedDate,
  onDateBack,
  onDateForward,
  validRoutes,
  routes,
  selectedRoutes,
  setSelectedRoutes,
  clearSelectedRoutes,
  selectedShift,
  setSelectedShift,
  editMode,
  setEditMode,
  summaryMode,
  setSummaryMode,
  remaining,
  setRemaining,
  showWork,
  setShowWork,
  onUpdateFromTemplate,
  refetch,
  overview,
  overviewRoute,
  setOverviewRoute,
}) {
  let [anchorEl, setAnchorEl] = useState(null)
  let [createRouterun, setCreateRouterun] = useState(false)
  let [lastClickedRoute, setLastClickedRoute] = useState({})

  return (
    <>
      <Toolbar>
        <ToolbarLeft style={{justifyContent: 'flex-start', flex: 3}}>
          <Route
            path={`${match.path}/:tab`}
            children={({match: tabMatch}) => {
              let {params = {}} = tabMatch || {}

              return (
                <>
                  <Button
                    onClick={e => {
                      setAnchorEl(e.currentTarget)
                    }}
                    component="nav"
                    style={{textTransform: 'none'}}
                  >
                    {params.tab === 'daily' ? (
                      <>
                        <Schedule />
                        <Space inline />
                        <Typography variant="h6">Daily Schedule</Typography>
                      </>
                    ) : params.tab === 'weekly' ? (
                      <>
                        <Schedule />
                        <Space inline />
                        <Typography variant="h6">Weekly Schedule</Typography>
                      </>
                    ) : (
                      <>
                        <Map />
                        <Space inline />
                        <Typography variant="h6">Route Overview</Typography>
                      </>
                    )}
                    <Space inline />
                    <ArrowDropDown />
                  </Button>
                  <Menu
                    id="customer-nav-menu"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={() => {
                      setAnchorEl(null)
                    }}
                  >
                    <MenuItem
                      selected={params.tab === 'daily'}
                      component={Link}
                      to="/schedules/daily"
                      onClick={() => setAnchorEl(null)}
                    >
                      <Schedule />
                      <Space inline />
                      Daily Schedule
                    </MenuItem>
                    <MenuItem
                      selected={params.tab === 'weekly'}
                      component={Link}
                      to="/schedules/weekly"
                      onClick={() => setAnchorEl(null)}
                    >
                      <Schedule />
                      <Space inline />
                      Weekly Schedule
                    </MenuItem>
                    <MenuItem
                      selected={params.tab === 'overview'}
                      component={Link}
                      to="/schedules/overview"
                      onClick={() => setAnchorEl(null)}
                    >
                      <Map />
                      <Space inline />
                      Route Overview
                    </MenuItem>
                  </Menu>
                </>
              )
            }}
          />
          <Space size={3} inline />
          <FormControlLabel
            control={
              <MuiSwitch
                checked={editMode}
                onChange={setEditMode}
                color="secondary"
              />
            }
            label={editMode ? 'Edit' : 'View'}
          />
          <Space size={2} inline />
          <FormControlLabel
            control={
              <MuiSwitch
                checked={summaryMode}
                onChange={setSummaryMode}
                color="secondary"
              />
            }
            label={summaryMode ? 'Summary' : 'All Info'}
          />
          <Space size={2} inline />
          <FormControlLabel
            control={
              <MuiSwitch
                checked={showWork}
                onChange={setShowWork}
                color="secondary"
              />
            }
            label={showWork ? 'All Assigned' : 'All Runs'}
          />
          <Space size={2} inline />
          <FormControlLabel
            control={
              <MuiSwitch
                checked={remaining}
                onChange={setRemaining}
                color="secondary"
              />
            }
            label={remaining ? 'Remaining Jobs' : 'All Jobs'}
          />
        </ToolbarLeft>
        <ToolbarRight style={{justifyContent: 'flex-end', flex: 2}}>
          {selectedRoutes && selectedRoutes.length ? (
            <IconButton onClick={clearSelectedRoutes} size="large">
              <Clear />
            </IconButton>
          ) : (
            ''
          )}
          {overview ? (
            <>
              <RouteSelectStatic
                value={overviewRoute}
                onChange={setOverviewRoute}
                showAll={false}
              />
            </>
          ) : (
            <>
              <RouteSelect
                routes={routes}
                setSelectedRoutes={(e, child) => {
                  let checks = e.target.value
                  if (!!lastClickedRoute.props && e.shiftKey) {
                    let routeIds = routes.map(r => r.edge.id)
                    let firstPos = routeIds.indexOf(
                      lastClickedRoute.props.value,
                    )
                    let secondPos = routeIds.indexOf(child.props.value)
                    let range = _.range(
                      Math.min(firstPos, secondPos),
                      Math.max(firstPos, secondPos),
                    )
                    let between = routeIds.filter((val, index) =>
                      range.includes(index),
                    )
                    if (checks.includes(child.props.value)) {
                      e.target.value = [
                        ...e.target.value,
                        ...between.filter(v => !e.target.value.includes(v)),
                      ]
                    } else {
                      e.target.value = e.target.value.filter(
                        v =>
                          !between.includes(v) &&
                          v !== lastClickedRoute.props.value,
                      )
                    }
                  }
                  setLastClickedRoute(child)
                  setSelectedRoutes(e)
                }}
                selectedRoutes={selectedRoutes}
              >
                {routes &&
                  routes.map(r => (
                    <MenuItem value={r.edge.id} key={r.edge.id}>
                      {r.edge.name}
                    </MenuItem>
                  ))}
              </RouteSelect>
              <Space inline size={2} />
              <ShiftSelect value={selectedShift} onChange={setSelectedShift} />
            </>
          )}
          <Space inline size={2} />
          <IconButton margin="dense" onClick={onDateBack} size="large">
            <ChevronLeft />
          </IconButton>
          <PRThemeProvider>
            <DatePicker
              label="Selected Date"
              value={moment(selectedDate).utc()}
              onChange={d => setSelectedDate(d.toDate())}
              PopoverProps={{PaperProps: {style: {background: 'white'}}}}
              renderInput={props => (
                <SearchBarTextField {...props} label={''} />
              )}
            />
          </PRThemeProvider>
          <IconButton margin="dense" onClick={onDateForward} size="large">
            <ChevronRight />
          </IconButton>
          <Space inline size={2} />
          <ToolbarIconMenu id={'actions'}>
            <ToolbarMenuItem
              label={'Add Routerun'}
              onClick={() => setCreateRouterun(true)}
              disabled={!editMode}
            />
            <ToolbarMenuItem
              label={'Update from Template'}
              onClick={onUpdateFromTemplate}
              disabled={!editMode}
            />
            <ToolbarMenuItem
              component="a"
              href={`/old/database/dailyoverview.php?m=${moment
                .utc(selectedDate)
                .format('MM')}&d=${moment
                .utc(selectedDate)
                .format('DD')}&y=${moment.utc(selectedDate).format('YYYY')}`}
              label={'Go to Old Daily Overview'}
            />
            <ToolbarMenuItem
              component="a"
              href={`/old/database/customer_csv.php?date=${moment
                .utc(selectedDate)
                .format('YYYY-MM-DD')}`}
              label={'CSV export selected day'}
            />
            {editMode ? (
              <Link
                to={`/moveschedules?date=${moment
                  .utc(selectedDate)
                  .format('YYYY-MM-DD')}&route=1`}
                style={{color: 'white'}}
              >
                <ToolbarMenuItem label={'Mass-move Schedules'} />
              </Link>
            ) : (
              <ToolbarMenuItem label={'Mass-move Schedules'} disabled />
            )}
          </ToolbarIconMenu>
        </ToolbarRight>
      </Toolbar>
      {loading && <LinearProgress />}
      <CreateRouterunDialog
        isOpen={createRouterun}
        onClose={() => setCreateRouterun(false)}
        onSubmit={refetch}
        validRoutes={validRoutes}
        day={selectedDate}
      />
    </>
  )
}

let Schedules = ({match, location}) => {
  let queryClient = useQueryClient()

  let [queryChanges, setQueryChanges] = useState(
    queryString.parse(location.search),
  )
  let [templateDialog, setTemplateDialog] = useState(false)
  let {status: isNotSupervisor} = useAuth('see scheduling dollars')

  let params = queryString.parse(location.search)
  let {
    date,
    routes,
    shift,
    editMode,
    summaryMode,
    openRow,
    openRoute,
    showWork,
    remaining,
    openWeek,
    overviewRoute,
  } = munge(params)

  let startDate = moment
    .utc(date)
    .startOf('week')
    .toDate()
  let endDate = moment
    .utc(date)
    .endOf('week')
    .toDate()
  let overviewEndDate = moment
    .utc(date)
    .add(3, 'weeks')
    .endOf('week')
    .toDate()

  let fetchRouteruns = () => {
    return prgql({
      query: routerunsQuery,
      variables: {
        filters: {
          from: moment(startDate)
            .utc()
            .startOf('day')
            .format('YYYY-MM-DD'),
          to: moment(endDate)
            .utc()
            .startOf('day')
            .format('YYYY-MM-DD'),
          shift_id: shift,
        },
      },
    })
  }

  let fetchOverviewRouteruns = () => {
    return prgql({
      query: routerunsQuery,
      variables: {
        filters: {
          from: moment(startDate)
            .utc()
            .startOf('day')
            .format('YYYY-MM-DD'),
          to: moment(overviewEndDate)
            .utc()
            .startOf('day')
            .format('YYYY-MM-DD'),
          route: parseInt(overviewRoute),
        },
      },
    })
  }

  let fetchRoutes = () => {
    return prgql({
      query: allRoutesQuery,
    })
  }

  let querySignature = ['routeruns', startDate, endDate]
  let overViewSignature = ['overview', overviewRoute, ...querySignature]

  // firing our two queries off simultaneously will do strange things with routerun generation
  let viewMode = 'normal'
  if (location.pathname === '/schedules/overview') {
    viewMode = 'overview'
  }

  let {data, isLoading: loading, refetch} = useReactQuery({
    queryKey: querySignature,
    queryFn: fetchRouteruns,
    enabled: viewMode === 'normal',
  })

  let {
    data: overviewData,
    isLoading: overviewLoading,
    refetch: overviewRefetch,
  } = useReactQuery({
    queryKey: overViewSignature,
    queryFn: fetchOverviewRouteruns,
    enabled: viewMode === 'overview',
  })

  let {data: allRoutes, isLoading} = useReactQuery('allRoutes', fetchRoutes)
  let {data: lookup} = useReactQuery('lookup', () =>
    prgql({query: lookupQuery}),
  )
  let {data: attendance} = useReactQuery(
    ['attendance', startDate, endDate],
    () =>
      prgql({
        query: attendanceQuery,
        variables: {
          from: moment(startDate)
            .utc()
            .startOf('day')
            .format('YYYY-MM-DD'),
          to: moment(endDate)
            .utc()
            .startOf('day')
            .format('YYYY-MM-DD'),
        },
      }),
  )

  let _validRoutes = data => {
    if (data && data.allRouteRuns && data.allRouteRuns.edges && allRoutes) {
      let currentRoutes = data.allRouteRuns.edges
        .filter(e => datesAreOnSameDay(new Date(e.edge.run_at), date))
        .map(r => r.edge.route_id)
      let routes = allRoutes.allRoutes.edges
        .map(e => e.edge.id)
        .filter(r => currentRoutes.indexOf(r) === -1)
      return routes
    }

    return []
  }

  // I was there, Gandalf. I was there when all this optimistic updating
  // was really simple.
  let _modifyEdgeForOnUpdate = ({self, parent, destination, e, data}) => {
    let _resequence = items => {
      let counter = 1
      let sequence = destination.sequence
      if (sequence >= items.length + 1) {
        sequence = items.length + 1
      }

      return items
        .sort((a, b) => a.sequence - b.sequence)
        .map(i => {
          if (i.id !== self.id) {
            if (counter === sequence) {
              counter = counter + 1
            }
            let ret = {...i, sequence: counter}
            counter = counter + 1
            return ret
          } else {
            return {...i, sequence: sequence}
          }
        })
    }

    if (
      !!self &&
      (self.type === 'users' ||
        self.type === 'schedules' ||
        self.type === 'assets' ||
        self.type === 'workorders')
    ) {
      // let dest = data.allRouteRuns.edges
      //   .filter(r => r.edge.id === destination.id)
      //   .pop()
      let start = data.allRouteRuns.edges
        .filter(r => r.edge.id === parent.id)
        .pop()
      let item = start
        ? start.edge[self.type].find(s => s.id === self.id)
        : lookup[self.type].edges.map(e => e.edge).find(e => e.id === self.id)

      if (self.type === 'schedules') {
        if (
          item.freq_type !== 1 &&
          destination.id !== parent.id &&
          !!item.exclusions &&
          !item.exclusions.length
        )
          item.exclusions = [{id: 0, reschedule_at: destination.run_at}]
        if (
          item.freq_type !== 1 &&
          destination.id !== parent.id &&
          !!item.exclusions &&
          item.exclusions.length
        ) {
          let exclusion = item.exclusions.filter(e =>
            moment.utc(e.reschedule_at).isSame(parent.run_at, 'day'),
          )
          if (
            exclusion &&
            !moment(exclusion.exclude_at).isSame(destination.run_at, 'day')
          ) {
            item.exclusions = [
              {...exclusion, reschedule_at: destination.run_at},
            ]
          } else {
            item.exclusions = [
              ...item.exclusions.filter(e => e.id !== exclusion.id),
            ]
          }
        }
      }

      if (e.edge.id === destination.id || e.edge.id === parent.id) {
        let set =
          destination.id === parent.id
            ? [...e.edge[self.type]]
            : e.edge.id === destination.id
            ? [...e.edge[self.type], item]
            : e.edge[self.type].filter(s => s.id !== item.id)

        let newEdge = {
          edge: {
            ...e.edge,
            [self.type]: set,
          },
        }

        if (destination.sequence !== undefined) {
          let schedulesWithWorkorders = newEdge.edge.workorders
            .map(w => w.schedule_id || '0')
            .flat()
          let collection = [...newEdge.edge.workorders]
            .concat([
              ...newEdge.edge.schedules.filter(
                s => !schedulesWithWorkorders.includes(s.id),
              ),
            ])
            .sort((a, b) => a.sequence - b.sequence)
          let sorted = _resequence(collection)
          newEdge.edge.schedules = sorted
            .filter(s => s.type === 'schedules')
            .concat(
              newEdge.edge.schedules.filter(s =>
                schedulesWithWorkorders.includes(s.id),
              ),
            )
          newEdge.edge.workorders = sorted.filter(s => s.type === 'workorders')
        }

        return newEdge
      }
    }

    return e
  }

  let _onUpdate = async ({self, parent, destination}) => {
    await queryClient.cancelQueries('routeruns')

    if (!Array.isArray(self)) {
      self = [self]
    }

    if (viewMode === 'normal') {
      queryClient.setQueryData(querySignature, old => ({
        ...old,
        allRouteRuns: {
          edges: old.allRouteRuns.edges.map(e => {
            let edge = {...e}
            for (const s of self) {
              edge = {
                ..._modifyEdgeForOnUpdate({
                  self: s,
                  parent,
                  destination,
                  e: edge,
                  data: data,
                }),
              }
            }

            return edge
          }),
        },
      }))
    }

    if (viewMode === 'overview') {
      queryClient.setQueryData(overViewSignature, old => ({
        ...old,
        allRouteRuns: {
          edges: old.allRouteRuns.edges.map(e => {
            let edge = {...e}
            for (const s of self) {
              edge = {
                ..._modifyEdgeForOnUpdate({
                  self: s,
                  parent,
                  destination,
                  e: edge,
                  data: overviewData,
                }),
              }
            }

            return edge
          }),
        },
      }))
    }

    refetch()
    overviewRefetch()
  }

  let _filterRoutes = (edges, routes) => {
    if (routes === undefined || routes.length === 0) {
      return edges
    }
    return edges.filter(e => routes.includes(e.edge.route_id))
  }

  let _filterShift = (edges, shift) => {
    if (shift === undefined || !shift || shift === 'all') {
      return edges
    }
    return edges.filter(e => e.edge.route.shift.id === shift)
  }

  let _filterWork = (edges, filterActive = false) => {
    if (!filterActive) return edges
    return edges.filter(
      e =>
        (e.edge.schedules && e.edge.schedules.length > 0) ||
        e.edge.users.length > 0 ||
        e.edge.workorders.length > 0,
    )
  }

  let isDaily = `${match.path}/daily` === location.pathname
  let isOverview = `${match.path}/overview` === location.pathname

  let allData = data &&
    data.allRouteRuns && {
      ...data,
    }

  // this is kinda awful. Pipe operator, anyone?
  let filteredData = data &&
    data.allRouteRuns && {
      ...data,
      allRouteRuns: {
        edges: [
          ..._filterWork(
            _filterRoutes(_filterShift(data.allRouteRuns.edges, shift), routes),
            showWork,
          ),
        ],
      },
    }

  let filteredOverviewData = overviewData &&
    overviewData.allRouteRuns && {
      ...overviewData,
      allRouteRuns: {
        edges: [..._filterWork(overviewData.allRouteRuns.edges, showWork)],
      },
    }

  // this should work b/c the data is always in the same week
  let cycle4Week =
    data && data.allRouteRuns && data.allRouteRuns.edges.length
      ? data.allRouteRuns.edges[0].edge.week
      : 0

  let d =
    data && data.allRouteRuns
      ? data.allRouteRuns.edges.filter(e =>
          datesAreOnSameDay(new Date(e.edge.run_at), date),
        )
      : []
  let scheduledUsers = _.chain(d.map(e => e.edge.users))
    .flattenDeep()
    .uniqBy(u => u.id)
    .value()
  let unscheduledUsers = lookup
    ? _.chain(lookup.users.edges)
        .map(e => e.edge)
        .filter(u =>
          u.permissions.some(p => p.name === 'available to schedule'),
        )
        .reject(u => _.find(scheduledUsers, s => s.id === u.id))
        .value()
    : []
  let scheduledAssets = _.chain(d.map(e => e.edge.assets))
    .flattenDeep()
    .uniqBy(u => u.id)
    .value()
  let unscheduledAssets = lookup
    ? _.chain(lookup.assets.edges)
        .map(e => e.edge)
        .reject(u => _.find(scheduledAssets, s => s.id === u.id))
        .reject(u => !u.active)
        .value()
    : []
  let unavailableUsers = attendance
    ? _.chain(attendance.attendance ? attendance.attendance.edges : [])
        .map(e => e.edge)
        .filter(e => moment.utc(e.taken_at).isSame(moment.utc(date), 'day'))
        .reject(u => u.hours >= 0 || u.automated === true)
        .uniqWith((a, b) => a.user_id === b.user_id)
        .value()
    : []

  return (
    <React.Fragment>
      <ScreenToolbar
        match={match}
        title={`thing`}
        loading={loading || overviewLoading}
        selectedDate={date}
        setSelectedDate={date =>
          setQueryChanges({
            ...queryChanges,
            date: moment(date)
              .utc()
              .startOf('day')
              .format('YYYY-MM-DD'),
          })
        }
        onDateBack={() =>
          setQueryChanges({
            ...queryChanges,
            date: moment
              .utc(date)
              .subtract(1, isDaily ? 'day' : 'week')
              .startOf('day')
              .format('YYYY-MM-DD'),
          })
        }
        onDateForward={() =>
          setQueryChanges({
            ...queryChanges,
            date: moment
              .utc(date)
              .add(1, isDaily ? 'day' : 'week')
              .startOf('day')
              .format('YYYY-MM-DD'),
          })
        }
        validRoutes={_validRoutes(allData)}
        routes={allRoutes ? allRoutes.allRoutes.edges : []}
        selectedRoutes={routes}
        setSelectedRoutes={e => {
          setQueryChanges({...queryChanges, routes: e.target.value.join(',')})
        }}
        clearSelectedRoutes={e => {
          setQueryChanges({...queryChanges, routes: ''})
        }}
        selectedShift={shift}
        setSelectedShift={e => {
          setQueryChanges({...queryChanges, shift: e.target.value})
        }}
        refetch={refetch}
        onUpdateFromTemplate={e => {
          e.stopPropagation()
          setTemplateDialog(true)
        }}
        editMode={editMode}
        setEditMode={e =>
          setQueryChanges({...queryChanges, editMode: String(e.target.checked)})
        }
        summaryMode={summaryMode}
        setSummaryMode={e =>
          setQueryChanges({
            ...queryChanges,
            summaryMode: String(e.target.checked),
          })
        }
        showWork={showWork}
        setShowWork={e =>
          setQueryChanges({...queryChanges, showWork: String(e.target.checked)})
        }
        remaining={remaining}
        setRemaining={e =>
          setQueryChanges({
            ...queryChanges,
            remaining: String(e.target.checked),
          })
        }
        overview={isOverview}
        overviewRoute={overviewRoute}
        setOverviewRoute={e =>
          setQueryChanges({...queryChanges, overviewRoute: e.target.value})
        }
      />
      <UpdateFromTemplateDialog
        isOpen={templateDialog}
        onClose={() => setTemplateDialog(false)}
        onSubmit={refetch}
        from={isDaily ? date : startDate}
        to={isDaily ? date : endDate}
        routes={routes}
        allRoutes={allRoutes}
      />
      <ModifyQueryParams query={queryChanges} />
      <Switch>
        <Route
          path={`${match.path}/daily`}
          exact
          render={() => (
            <Daily
              data={filteredData}
              loading={loading}
              onUpdate={_onUpdate}
              selectedDate={date}
              setSelectedDate={date =>
                setQueryChanges({
                  ...queryChanges,
                  date: moment(date)
                    .utc()
                    .startOf('day')
                    .format('YYYY-MM-DD'),
                })
              }
              openRow={openRow}
              setOpenRow={row =>
                setQueryChanges({
                  ...queryChanges,
                  openRow: String(row),
                })
              }
              unscheduledAssets={unscheduledAssets}
              unscheduledUsers={unscheduledUsers}
              unavailableUsers={unavailableUsers}
              viewMode={!editMode}
              summaryMode={summaryMode}
              cycle4Week={cycle4Week}
              filterRemaining={remaining}
              supervisorMode={isNotSupervisor !== 'authorized'}
            />
          )}
        />
        <Route
          path={`${match.path}/weekly`}
          exact
          render={() => (
            <Weekly
              data={filteredData}
              loading={loading}
              startDate={startDate}
              endDate={endDate}
              selectedDate={date}
              setSelectedDate={date =>
                setQueryChanges({
                  ...queryChanges,
                  date: moment(date)
                    .utc()
                    .startOf('day')
                    .format('YYYY-MM-DD'),
                })
              }
              openRow={openRoute}
              setOpenRow={route => {
                setQueryChanges({
                  ...queryChanges,
                  openRoute: String(route),
                })
              }}
              onUpdate={_onUpdate}
              viewMode={!editMode}
              summaryMode={summaryMode}
              cycle4Week={cycle4Week}
              filterRemaining={remaining}
              supervisorMode={isNotSupervisor !== 'authorized'}
            />
          )}
        />
        <Route
          path={`${match.path}/overview`}
          exact
          render={() => (
            <Overview
              data={filteredOverviewData}
              loading={overviewLoading}
              startDate={startDate}
              endDate={overviewEndDate}
              selectedDate={date}
              setSelectedDate={date =>
                setQueryChanges({
                  ...queryChanges,
                  date: moment(date)
                    .utc()
                    .startOf('day')
                    .format('YYYY-MM-DD'),
                })
              }
              openRow={openWeek}
              setOpenRow={route => {
                setQueryChanges({
                  ...queryChanges,
                  openWeek: String(route),
                })
              }}
              onUpdate={_onUpdate}
              viewMode={!editMode}
              summaryMode={summaryMode}
              cycle4Week={cycle4Week}
              filterRemaining={remaining}
            />
          )}
        />
      </Switch>
    </React.Fragment>
  )
}

export default Schedules
