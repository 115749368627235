import React, {useState} from 'react'
import styled from 'styled-components'
import gql from 'graphql-tag'
import {useMutation} from '@apollo/react-hooks'
import {useQuery} from 'react-query'
import moment from 'moment'
import {withGoogleMap, GoogleMap, Marker} from 'react-google-maps'

import ImageListItem from '@mui/material/ImageListItem'
import ImageListItemBar from '@mui/material/ImageListItemBar'
import AddAPhoto from '@mui/icons-material/AddAPhoto'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import Divider from '@mui/material/Divider'
import MuiLink from '@mui/material/Link'
import Grid from '@mui/material/Grid'

import Description from '@mui/icons-material/Description'
import Home from '@mui/icons-material/Home'
import Store from '@mui/icons-material/Store'
import Phone from '@mui/icons-material/Phone'
import Email from '@mui/icons-material/Email'
import History from '@mui/icons-material/History'
import Money from '@mui/icons-material/Money'
import Receipt from '@mui/icons-material/Receipt'
import Chat from '@mui/icons-material/Chat'
import Person from '@mui/icons-material/Person'
import DateRange from '@mui/icons-material/DateRange'
import Note from '@mui/icons-material/Note'
import FileCopy from '@mui/icons-material/FileCopy'

import {Body1, Caption} from '../../components/Typography'
import theme from '../../styles/theme'
import Image from '../../components/Image'
import {formatMoneyStandard} from '../../utils/moneyFormatter'
import {Space} from '../../components/Layout'
import Stat from '../../components/Stat'
import CustomerSchedules from './CustomerSchedules'
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useMediaQuery,
} from '@mui/material'
import {
  Notes,
  Edit,
  Gavel,
  InsertDriveFile,
  Pause,
  AssignmentLate,
  VpnKey,
  NewReleases,
  PersonOutline,
  Warning,
  Alarm,
  EmojiFlags,
  PermPhoneMsg,
  WaterDrop,
  Add,
  Handshake,
} from '@mui/icons-material'
import Tooltip from '@mui/material/Tooltip'
import EditEmails from '../../components/EditEmails'
import {fetchAPI} from '../../schema/utils'
import {formatPhone} from '../../utils'
import EditPhones from '../../components/EditPhones'
import {Link} from 'react-router-dom'
import EditCommunications from '../../components/EditCommunications'
import {prgql} from '../../utils/graphql'
import colors from '../../styles/colors'
import Cone from '../../icons/Cone'
import {fragments} from '../Contact/Contact'
import PillBox from '../../components/PillBox'
import {EditDetails as EditContacts} from '../Contact/EditDetails'
import DetailHeader from '../../components/DetailHeader'

let Content = styled(Grid)`
  padding: 25px 50px;
`
let InfoRow = styled.div`
  display: flex;
  align-items: top;
  margin-bottom: 16px;
`
let InfoColumn = styled.div`
  flex: 1 1 auto;
`
let RowIcon = styled.div`
  color: ${({theme}) => theme.muiTheme.palette.action.active};
  margin-right: 16px;
`
let ClickRowIcon = styled.div`
  color: ${({theme}) => theme.palette.secondary.main};
  margin-right: 16px;
  &:hover {
    color: ${({theme}) => theme.palette.secondary.light};
  }
`
let LargeIcon = styled.div`
  color: ${({theme}) => theme.muiTheme.palette.action.disabled};
  width: 66%;
  height: 66%;
`
let PhotoWrapper = styled(ImageListItem)`
  width: 300px !important;
  height: 300px !important;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
`
let PhotoFallback = styled.div`
  width: 300px;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({theme}) => theme.grey300};
`
let DetailsWrapper = styled(Paper)`
  padding: 16px;
`
let DescriptionIcon = styled(RowIcon).attrs(() => ({as: Description}))``
let NotesIcon = styled(RowIcon).attrs(() => ({as: Notes}))``
let EstimateNoteIcon = styled(RowIcon).attrs(() => ({as: Note}))``
let HomeIcon = styled(RowIcon).attrs(() => ({as: Home}))``
let CopyIcon = styled(ClickRowIcon).attrs(() => ({as: FileCopy}))``
let HomeIconLarge = styled(LargeIcon).attrs(() => ({as: Home}))``
let StoreIcon = styled(RowIcon).attrs(() => ({as: Store}))``
let StoreIconLarge = styled(LargeIcon).attrs(() => ({as: Store}))``
let PhoneIcon = styled(RowIcon).attrs(() => ({as: Phone}))``
let EmailIcon = styled(RowIcon).attrs(() => ({as: Email}))``
let HistoryIcon = styled(RowIcon).attrs(() => ({as: History}))``
let MoneyIcon = styled(RowIcon).attrs(() => ({as: Money}))``
let ContractIcon = styled(RowIcon).attrs(() => ({as: Gavel}))``
let ReceiptIcon = styled(RowIcon).attrs(() => ({as: Receipt}))``
let ChatIcon = styled(RowIcon).attrs(() => ({as: Chat}))``
let PersonIcon = styled(RowIcon).attrs(() => ({as: Person}))``
let DateRangeIcon = styled(RowIcon).attrs(() => ({as: DateRange}))``
let EditIcon = styled(ClickRowIcon).attrs(() => ({as: Edit}))``
let AddIcon = styled(ClickRowIcon).attrs(() => ({as: Add}))``
let ExternalPoIcon = styled(RowIcon).attrs(() => ({as: AssignmentLate}))``
let KeyIcon = styled(RowIcon).attrs(() => ({as: VpnKey}))``
let InvoiceNeedsPOIcon = styled(RowIcon).attrs(() => ({as: NewReleases}))``
let ServiceConfirmationIcon = styled(RowIcon).attrs(() => ({
  as: PermPhoneMsg,
}))``
let RainSensitiveIcon = styled(RowIcon).attrs(() => ({as: WaterDrop}))``
let ObstructionIcon = styled(RowIcon).attrs(() => ({as: Cone}))``
const sheet = {
  container: {},
  photoColumn: {flex: 'none', width: '33%', marginRight: '15px'},
  rowIcon: {color: theme.iconGrey, marginRight: '5px'},
  photoIcon: {
    color: 'white',
    backgroundColor: theme.iconGrey,
    padding: '9px',
    borderRadius: '100%',
    fontSize: '179px',
  },
  image: {width: '100%'},
  imageCell: {maxWidth: '33.33333333%', flex: '1 1 99px', paddingRight: '4px'},
  thumbRow: {display: 'flex', flexWrap: 'wrap', marginRight: '-4px'},
  imageSecondary: {maxWidth: '100%', cursor: 'pointer'},
  label: {fontWeight: 'bold'},
}

export let CustomerMap = withGoogleMap(props => (
  <GoogleMap defaultZoom={12} defaultCenter={props.defaultCenter}>
    {props.markers.map(marker => (
      <Marker defaultAnimation={2} {...marker} />
    ))}
  </GoogleMap>
))

export function DocumentDialog({isOpen, onClose, document, account = false}) {
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      maxWidth="lg"
      fullWidth
      PaperProps={{style: {height: '80vh'}}}
    >
      <DialogTitle>
        <a
          href={
            document.data_source === 'PathRabbit'
              ? account
                ? `/old/database/documents_account.php?AccountID=${document.account_id}&action=1&DocumentID=${document.id} `
                : `/old/database/documents.php?JobID=${document.customer_id}&action=1&DocumentID=${document.id}`
              : document.airtable_url
          }
        >
          {document.description}
        </a>
      </DialogTitle>
      <DialogContent>
        <iframe id="documentPreview" width={'100%'} height="100%"></iframe>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}

function InlineLabel({label, children}) {
  return (
    <div style={{paddingRight: '20px'}}>
      <Caption style={{width: '45px', marginRight: '8px'}}>{label}</Caption>
      {children}
    </div>
  )
}

function ScheduleDateText({date, route}) {
  return (
    <Link
      to={`/schedules/daily?date=${moment
        .utc(date)
        .format('YYYY-MM-DD')}&routes=${route}`}
    >
      {moment.utc(date).format('dddd, M/D/YYYY')}
    </Link>
  )
}

function getCustomerAge({start_at, stop_at}) {
  if (!start_at) return 'none'
  return moment(start_at).from(stop_at || moment(), true)
}

function getContractDateDescription({contract_start_at, contract_stop_at}) {
  if (!contract_start_at) contract_start_at = undefined
  if (!contract_stop_at) contract_stop_at = undefined
  let isBetween = moment
    .utc()
    .isBetween(moment.utc(contract_start_at), moment.utc(contract_stop_at))
  if (isBetween)
    return `Contract started ${moment
      .utc(contract_start_at)
      .format(dateFmt)} (${moment
      .utc(contract_stop_at)
      .diff(moment.utc(), 'days')} days left) `
  if (contract_start_at && moment.utc().isBefore(contract_start_at))
    return `Contract starts ${moment
      .utc(contract_start_at)
      .format(dateFmt)} (in ${moment
      .utc(contract_start_at)
      .diff(moment.utc(), 'days')} days) `
  if (contract_stop_at && moment.utc().isAfter(contract_stop_at))
    return `Contract ended ${moment.utc(contract_stop_at).format(dateFmt)}`
  return null
}

export let customerQuery = gql`
  query CustomerQuery($id: String!) {
    customers(id: $id) {
      id
      name
      customertype_id
      customertype {
        id
        type
      }
      batch_output
      start_at
      stop_at
      days_open
      time_open
      lat
      lng
      street1
      contract_start_at
      contract_stop_at

      services_paused
      po_needed
      key_access
      invoice_needs_po_number
      needs_service_confirmation
      rain_sensitive
      move_obstruction_for_service

      batch_output_attach_statement
      batch_output_attach_invoices
      batch_output_attach_workorders
      batch_output_statement_as_excel
      email_recorded_invoices
      email_recorded_invoices_attach_invoices
      email_recorded_invoices_attach_workorders

      attach_confirmation_photos_to_invoice

      statements_email_id
      recorded_invoices_email_id
      city_id
      city {
        id
        name
        county
      }
      region
      postcode
      notes
      scheduling_notes
      estimate_notes
      user_id
      user {
        id
        first_name
        last_name
      }
      sales_person_id
      sales_person {
        id
        first_name
        last_name
      }
      contact
      days_open
      time_open
      photo {
        id
        url
      }
      photos {
        id
        thumb_url
      }
      phone
      other_phone
      fax
      phones {
        id
        number
        label
        phonetype
      }
      emails {
        id
        email
        label
        default_email
        cc_statements
        cc_recorded_invoices
        notes
      }
      terms_id
      terms {
        id
        terms
      }
      taxable
      taxitem_id
      taxitem {
        id
        name
      }
      source_id
      source {
        id
        source
      }
      stopreason_id
      stopreason {
        id
        reason
      }
      balances {
        id
        balance
      }
      sales: salesstat {
        id
        sales
      }
      account_id
      account {
        id
        contract_start_at
        contract_stop_at
        name
        street1
        taxable
        city {
          id
          name
          county
        }
        region
        postcode
        emails {
          id
          email
          label
          default_email
          notes
        }
      }
      last_billable_routerun {
        id
        run_at
        route_id
      }
      next_routerun {
        id
        run_at
        route_id
      }
      last_routerun {
        id
        run_at
        route_id
      }
      lat
      lng
      geo_location {
        id
        lat
        lng
      }
      documents {
        id
        airtable_id
        airtable_url
        data_source
        name
        important
        description
        customer_id
      }
      schedules {
        id
      }
      contacts {
        ...ContactDetail
      }
      salesstages {
        id
        name
      }
    }
  }
  ${fragments.contact}
`

let airtableDocsQuery = gql`
  query CustomerQuery($id: String!) {
    customers(id: $id) {
      id
      name
      airtable_documents {
        id
        airtable_id
        airtable_url
        data_source
        name
        important
        description
        customer_id
      }
    }
  }
`

let dateFmt = 'MMM. D, YYYY'

let renderDetails = c => {
  return (
    <>
      <InfoRow>
        <HistoryIcon />
        <div>
          {customerIsLead(c) ? (
            <Typography variant="body1">
              Lead Customer
              {c.source ? ` via ${c.source.source}` : null}
            </Typography>
          ) : (
            <Typography variant="body1">
              Acquired on {moment.utc(c.start_at).format(dateFmt)}
              {c.source ? ` via ${c.source.source}` : null}
            </Typography>
          )}
        </div>
      </InfoRow>
      {c.user ? (
        <InfoRow>
          <ChatIcon />
          <Typography variant="body1">
            Relates to {c.user.first_name} {c.user.last_name}
            {c.sales_person &&
              `; Sold by ${c.sales_person.first_name} ${c.sales_person.last_name}`}
          </Typography>
        </InfoRow>
      ) : null}

      <InfoRow>
        <MoneyIcon />
        <Typography variant="body1">
          {formatMoneyStandard(c.sales ? c.sales.sales : 0)} in sales over{' '}
          {getCustomerAge(c)}
        </Typography>
      </InfoRow>

      {getContractDateDescription({
        contract_start_at: c.contract_start_at,
        contract_stop_at: c.contract_stop_at,
      }) ? (
        <InfoRow>
          <ContractIcon />
          <Typography variant="body1">
            {getContractDateDescription({
              contract_start_at: c.contract_start_at,
              contract_stop_at: c.contract_stop_at,
            })}
          </Typography>
        </InfoRow>
      ) : null}

      <InfoRow>
        <ReceiptIcon />
        <InfoColumn>
          <InlineLabel label="Terms">
            <Typography variant="body1" display="inline">
              {c.terms && c.terms.terms}
            </Typography>
          </InlineLabel>

          <InlineLabel label="Tax">
            <Typography variant="body1" display="inline">
              {c.taxable ? 'Taxable' : 'Exempt'} (
              {c.taxitem ? c.taxitem.name : 'No tax authority set'})
            </Typography>
          </InlineLabel>
          {/* attach_confirmation_photos_to_invoice */}

          <InlineLabel label="Conf Photos on Invoice">
            <Typography variant="body1" display="inline">
              {c.attach_confirmation_photos_to_invoice ? 'Attached' : '---'}
            </Typography>
          </InlineLabel>
        </InfoColumn>
      </InfoRow>
      {c.po_needed ? (
        <InfoRow>
          <ExternalPoIcon />
          <InfoColumn>
            <Typography variant="body1" display="inline">
              Paper Receipt / PO needed
            </Typography>
          </InfoColumn>
        </InfoRow>
      ) : (
        <></>
      )}
      {c.key_access ? (
        <InfoRow>
          <KeyIcon />
          <InfoColumn>
            <Typography variant="body1" display="inline">
              Key access required
            </Typography>
          </InfoColumn>
        </InfoRow>
      ) : (
        <></>
      )}
      {c.invoice_needs_po_number ? (
        <InfoRow>
          <InvoiceNeedsPOIcon style={{color: 'red', fontWeight: 'bold'}} />
          <InfoColumn>
            <Typography
              variant="body1"
              display="inline"
              style={{color: 'red', fontWeight: 'bold'}}
            >
              Invoice needs PO Number
            </Typography>
          </InfoColumn>
        </InfoRow>
      ) : (
        <></>
      )}
      {c.needs_service_confirmation ? (
        <InfoRow>
          <ServiceConfirmationIcon />
          <InfoColumn>
            <Typography variant="body1" display="inline">
              Needs confirmation before service
            </Typography>
          </InfoColumn>
        </InfoRow>
      ) : (
        <></>
      )}
      {c.rain_sensitive ? (
        <InfoRow>
          <RainSensitiveIcon />
          <InfoColumn>
            <Typography variant="body1" display="inline">
              Rain sensitive
            </Typography>
          </InfoColumn>
        </InfoRow>
      ) : (
        <></>
      )}
      {c.move_obstruction_for_service ? (
        <InfoRow>
          <ObstructionIcon />
          <InfoColumn>
            <Typography variant="body1" display="inline">
              Service requires customers to move obstructions (shopping carts,
              etc.)
            </Typography>
          </InfoColumn>
        </InfoRow>
      ) : (
        <></>
      )}
    </>
  )
}

let renderComms = c => {
  return (
    <Grid container>
      <Grid item md={6}>
        <InfoRow>
          <ReceiptIcon />
          <InfoColumn>
            <InlineLabel label="Statement batch">
              <Typography variant="body1" display="inline">
                {c.batch_output ? c.batch_output : 'None'}
              </Typography>
            </InlineLabel>
            <InlineLabel label="Attach Statement">
              <Typography variant="body1" display="inline">
                {c.batch_output_attach_statement ? 'YES' : '----'}
              </Typography>
            </InlineLabel>
            <InlineLabel label="Attach Invoices">
              <Typography variant="body1" display="inline">
                {c.batch_output_attach_invoices ? 'YES' : '----'}
              </Typography>
            </InlineLabel>
            <InlineLabel label="Attach Work Orders">
              <Typography variant="body1" display="inline">
                {c.batch_output_attach_workorders ? 'YES' : '----'}
              </Typography>
            </InlineLabel>
            <InlineLabel label="Format">
              <Typography variant="body1" display="inline">
                {c.batch_output_statement_as_excel ? 'Excel' : 'PDF'}
              </Typography>
            </InlineLabel>
            <InlineLabel label="Email">
              <Typography variant="body1" display="inline">
                {c.statements_email_id &&
                c.emails.filter(e => e.id === c.statements_email_id).length
                  ? c.emails.filter(e => e.id === c.statements_email_id)[0]
                      .email
                  : 'None'}
              </Typography>
            </InlineLabel>
            <InlineLabel label="CC">
              <Typography variant="body1" display="inline">
                {c.emails
                  .filter(e => e.cc_statements)
                  .map(e => e.email)
                  .join(', ')}
              </Typography>
            </InlineLabel>
          </InfoColumn>
        </InfoRow>
      </Grid>
      <Grid item md={6}>
        <InfoRow>
          <ReceiptIcon />
          <InfoColumn>
            <InlineLabel label="Recorded Invoices">
              <Typography variant="body1" display="inline">
                {c.email_recorded_invoices ? 'Email' : 'None'}
              </Typography>
            </InlineLabel>
            <InlineLabel label="Attach Invoices">
              <Typography variant="body1" display="inline">
                {c.email_recorded_invoices_attach_invoices}
              </Typography>
            </InlineLabel>
            <InlineLabel label="Attach Work Orders">
              <Typography variant="body1" display="inline">
                {c.email_recorded_invoices_attach_workorders ? 'YES' : '----'}
              </Typography>
            </InlineLabel>
            <InlineLabel label="Email">
              <Typography variant="body1" display="inline">
                {c.recorded_invoices_email_id &&
                c.emails.filter(e => e.id === c.recorded_invoices_email_id)
                  .length
                  ? c.emails.filter(
                      e => e.id === c.recorded_invoices_email_id,
                    )[0].email
                  : 'None'}
              </Typography>
              <InlineLabel label="CC">
                <Typography variant="body1" display="inline">
                  {c.emails
                    .filter(e => e.cc_recorded_invoices)
                    .map(e => e.email)
                    .join(', ')}
                </Typography>
              </InlineLabel>
            </InlineLabel>
          </InfoColumn>
        </InfoRow>
      </Grid>
    </Grid>
  )
}

let updateEmailMutation = gql`
  mutation emails(
    $id: String!
    $emails: [UpdateEmailsInput]!
    $createEmails: [CreateEmailsInput]!
  ) {
    updateCustomers(
      input: {id: $id, emails: {update: $emails, create: $createEmails}}
    ) {
      customers {
        name
        emails {
          id
          email
          default_email
          notes
        }
      }
    }
  }
`

let updatePhoneMutation = gql`
  mutation phones(
    $id: String!
    $phones: [UpdatePhonesInput]!
    $createPhones: [CreatePhonesInput]!
  ) {
    updateCustomers(
      input: {id: $id, phones: {update: $phones, create: $createPhones}}
    ) {
      customers {
        name
        phones {
          id
          number
        }
      }
    }
  }
`

let updateCommsMutation = gql`
  mutation comms(
    $id: String!
    $batch_output: String!
    $batch_output_attach_statement: Boolean!
    $batch_output_attach_invoices: Boolean!
    $batch_output_attach_workorders: Boolean!
    $batch_output_statement_as_excel: Boolean!
    $email_recorded_invoices: Boolean!
    $email_recorded_invoices_attach_invoices: String!
    $email_recorded_invoices_attach_workorders: Boolean!
    $statements_email_id: ID
    $recorded_invoices_email_id: ID
    $emails: [UpdateEmailsInput]
  ) {
    updateCustomers(
      input: {
        id: $id
        batch_output: $batch_output
        batch_output_attach_statement: $batch_output_attach_statement
        batch_output_attach_invoices: $batch_output_attach_invoices
        batch_output_attach_workorders: $batch_output_attach_workorders
        batch_output_statement_as_excel: $batch_output_statement_as_excel
        email_recorded_invoices: $email_recorded_invoices
        email_recorded_invoices_attach_invoices: $email_recorded_invoices_attach_invoices
        email_recorded_invoices_attach_workorders: $email_recorded_invoices_attach_workorders
        statements_email_id: $statements_email_id
        recorded_invoices_email_id: $recorded_invoices_email_id
        emails: {update: $emails}
      }
    ) {
      customers {
        id
        batch_output
        batch_output_attach_statement
        batch_output_attach_invoices
        batch_output_attach_workorders
        batch_output_statement_as_excel
        email_recorded_invoices
        email_recorded_invoices_attach_invoices
        email_recorded_invoices_attach_workorders
        statements_email_id
        recorded_invoices_email_id
        emails {
          id
          cc_statements
          cc_recorded_invoices
        }
      }
    }
  }
`

let setDefaultEmailsMutation = gql`
  mutation defaults(
    $id: String!
    $statements_email_id: ID!
    $recorded_invoices_email_id: ID!
  ) {
    updateCustomers(
      input: {
        id: $id
        statements_email_id: $statements_email_id
        recorded_invoices_email_id: $recorded_invoices_email_id
      }
    ) {
      customers {
        id
        statements_email_id
        recorded_invoices_email_id
      }
    }
  }
`
let customerIsLead = c => !c.schedules?.length

function DetailsPanel({customer: c, active, refetch}) {
  let [editEmails, setEditEmails] = useState(false)
  let [editPhones, setEditPhones] = useState(false)
  let [updateEmails] = useMutation(updateEmailMutation)
  let [updatePhones] = useMutation(updatePhoneMutation)
  let [updateComms] = useMutation(updateCommsMutation)
  let [setDefaultEmails] = useMutation(setDefaultEmailsMutation)
  let [showEmailEdit, setShowEmailEdit] = useState(false)
  let [showCommsEdit, setShowCommsEdit] = useState(false)
  let [showContactsEdit, setShowContactsEdit] = useState(false)
  let [showPhoneEdit, setShowPhoneEdit] = useState(false)
  let [showCopyInfo, setShowCopyInfo] = useState(false)
  let [showDocument, setShowDocument] = useState(false)
  let [showComms, setShowComms] = useState(false)
  let [showContacts, setShowContacts] = useState(false)
  let [showContactData, setShowContactData] = useState(false)
  let [selectedDocument, setSelectedDocument] = useState({})
  let [contactEditMode, setContactEditMode] = useState('view')

  function _documentPreview(doc) {
    let response = fetchAPI({
      url: `/documents/${doc.id}.pdf`,
      options: {method: 'get'},
    })
    response.then(async res => {
      let blob = await res.blob()
      let data_url = URL.createObjectURL(blob)
      document.querySelector('#documentPreview').src = data_url
    })
  }

  let billingInfoCompleted =
    !!c.street1 &&
    !!c.city_id &&
    !!c.region &&
    !!c.taxitem_id &&
    c.emails.length > 0

  return (
    <DetailsWrapper>
      {c.account ? (
        <DetailHeader>
          <Typography variant="body2" color="textSecondary" display="inline">
            Customer of
          </Typography>
          <Space size={1} inline />
          <Link variant="h6" display="inline" to={`/accounts/${c.account.id}`}>
            <Typography variant="h6" display="inline">
              {c.account.name}
            </Typography>
          </Link>
        </DetailHeader>
      ) : null}
      {!active ? (
        <DetailHeader warning={!active}>
          <Typography variant="body1" color="inherit">
            Stopped on {moment(c.stop_at).format(dateFmt)}
            {c.stopreason ? '; ' + c.stopreason.reason : null}
          </Typography>
        </DetailHeader>
      ) : null}
      {c.services_paused ? (
        <DetailHeader
          caution={true}
          style={{display: 'flex', alignItems: 'center'}}
        >
          <Pause />
          <Typography variant="body1" color="inherit">
            Services paused
          </Typography>
        </DetailHeader>
      ) : null}
      {customerIsLead(c) ? (
        <DetailHeader
          caution={true}
          style={{display: 'flex', alignItems: 'center'}}
        >
          <PersonOutline />
          <Typography variant="body1" color="inherit">
            Lead
          </Typography>
        </DetailHeader>
      ) : null}
      {!customerIsLead(c) && !billingInfoCompleted ? (
        <DetailHeader
          warning={true}
          style={{display: 'flex', alignItems: 'center'}}
        >
          <Warning />
          <Typography variant="body1" color="inherit">
            Missing Billing info
          </Typography>
        </DetailHeader>
      ) : null}
      {!!c.account && !c.account.taxable && c.taxable ? (
        <DetailHeader
          caution={true}
          style={{display: 'flex', alignItems: 'center'}}
        >
          <EmojiFlags />
          <Typography variant="body1" color="inherit">
            Taxable Customer under tax-exempt Account
          </Typography>
        </DetailHeader>
      ) : null}
      <div style={{display: 'flex'}}>
        <div style={{marginRight: '20px', flex: 1}}>
          <InfoRow
            onMouseEnter={() => setShowCopyInfo(true)}
            onMouseLeave={() => setShowCopyInfo(false)}
          >
            {showCopyInfo ? (
              <CopyIcon
                onClick={async () => {
                  let email = (c.emails || [])
                    .filter(e => e.default_email)
                    .map(e => `${e.label ? e.label + ': ' : ''} ${e.email}`)[0]
                  if (!email) {
                    email =
                      (c.emails || []).map(
                        e => `${e.label ? e.label + ': ' : ''} ${e.email}`,
                      )[0] || ''
                  }
                  // let phone = (c.phones || [])
                  //   .filter(e => e.phonetype == 1)
                  //   .map(e => `${e.label ? e.label + ': ' : ''} ${e.number}`)[0]
                  // if (!phone) {
                  //   phone =
                  //     (c.phones || []).map(
                  //       e =>
                  //         `${e.label ? e.label + ': ' : ''}${formatPhone(
                  //           e.number,
                  //         )}`,
                  //     )[0] || ''
                  // }
                  let accountEmail = ((!!c.account && c.account.emails) || [])
                    .filter(e => e.default_email)
                    .map(e => `${e.label ? e.label + ': ' : ''} ${e.email}`)[0]
                  if (!accountEmail) {
                    accountEmail =
                      ((!!c.account && c.account.emails) || []).map(
                        e => `${e.label ? e.label + ': ' : ''} ${e.email}`,
                      )[0] || ''
                  }

                  let accountText = c.account
                    ? `

Account: 
${c.account.name}
${c.account.street1}
${c.account.city && c.account.city.name}, ${c.account.region} ${
                        c.account.postcode
                      } 
Email: ${accountEmail}
`
                    : ''

                  let text = `
${c.name}
${c.street1}
${c.city && c.city.name}, ${c.region} ${c.postcode} 
Email: ${email}
${accountText}
`
                  await navigator.clipboard.writeText(text)
                  alert('Copied customer info!')
                }}
              />
            ) : c.customertype.id === '2' ? (
              <HomeIcon />
            ) : (
              <StoreIcon />
            )}
            <InfoColumn>
              <a
                href={`https://maps.google.com/maps?q=${c.street1}+${c.city &&
                  c.city.name}+${c.region}+${c.postcode}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Typography variant="subtitle2">{c.street1} </Typography>
                <Typography variant="body2">
                  {c.city && c.city.name}, {c.region} {c.postcode}{' '}
                </Typography>
                <Typography variant="body2">
                  {c.city && c.city.county}
                </Typography>
              </a>
            </InfoColumn>
          </InfoRow>
          <InfoRow
            onMouseEnter={() => setShowPhoneEdit(true)}
            onMouseLeave={() => setShowPhoneEdit(false)}
            id="phoneInfo"
          >
            {showPhoneEdit ? (
              <EditIcon
                onClick={() => setEditPhones(c.phones)}
                id="editPhones"
              />
            ) : (
              <PhoneIcon />
            )}
            <InfoColumn>
              {c.phones.map(p => (
                <InlineLabel label={p.label} key={p.id}>
                  <Typography variant="body2" display="inline">
                    <a href={'tel:' + p.number.replace(/\D/g, '')}>
                      {formatPhone(p.number)}
                    </a>
                  </Typography>
                </InlineLabel>
              ))}
            </InfoColumn>
          </InfoRow>
          <InfoRow
            onMouseEnter={() => setShowEmailEdit(true)}
            onMouseLeave={() => setShowEmailEdit(false)}
            id="emailInfo"
          >
            {showEmailEdit ? (
              <EditIcon
                onClick={() => setEditEmails(c.emails)}
                id="editEmails"
              />
            ) : (
              <EmailIcon />
            )}
            <InfoColumn as="ul" style={{listStyle: 'none'}}>
              {(c.emails || []).map(email => (
                <li key={email.id}>
                  <Typography
                    variant="body1"
                    style={{
                      fontWeight: email.default_email ? 'bold' : undefined,
                    }}
                  >
                    {email.label ? email.label + ': ' : ''}
                  </Typography>
                  <a href={'mailto:' + email.email}>
                    <Typography variant="body1">{email.email}</Typography>
                  </a>
                </li>
              ))}
            </InfoColumn>
          </InfoRow>
          {c.days_open || c.time_open ? (
            <InfoRow>
              <DateRangeIcon />
              <InfoColumn>
                <Typography>
                  Open{c.days_open ? ' ' + c.days_open : null}
                  {c.time_open ? ` @ ${c.time_open}` : null}
                </Typography>
              </InfoColumn>
            </InfoRow>
          ) : null}
        </div>
        <div style={{flex: 2}}>{renderDetails(c)}</div>
      </div>
      {c.salesstages?.length ? (
        <InfoRow>
          <Handshake color="action" />
          <InfoColumn>
            {c.salesstages.map(s => (
              <Chip
                key={s.id}
                style={{marginLeft: '.5em'}}
                label={
                  <Box display={'flex'} alignItems={'center'}>
                    <Space inline />
                    {s.name}
                  </Box>
                }
              />
            ))}
          </InfoColumn>
        </InfoRow>
      ) : (
        <></>
      )}
      {c.notes && (
        <InfoRow>
          <Tooltip title="Billing notes">
            <DescriptionIcon />
          </Tooltip>
          <Typography>{c.notes}</Typography>
        </InfoRow>
      )}
      {c.scheduling_notes && (
        <InfoRow>
          <Tooltip title="Scheduling notes">
            <NotesIcon />
          </Tooltip>
          <Typography>{c.scheduling_notes}</Typography>
        </InfoRow>
      )}
      {c.estimate_notes && (
        <InfoRow>
          <Tooltip title="Estimate notes">
            <EstimateNoteIcon />
          </Tooltip>
          <Typography>{c.estimate_notes}</Typography>
        </InfoRow>
      )}
      <Space size={2} />
      <Divider />
      <Space size={2} />
      <Stat.Label
        onMouseEnter={() => setShowContactsEdit(true)}
        onMouseLeave={() => setShowContactsEdit(false)}
        style={{display: 'flex', alignItems: 'center'}}
        id="contactInfo"
      >
        Contacts
        {showContactsEdit ? (
          <AddIcon
            style={{height: '1em'}}
            onClick={() => {
              setShowContactData({customers: [{id: c.id}]})
              setShowContacts(true)
              setContactEditMode('edit')
            }}
            id={'editContacts'}
          />
        ) : (
          <></>
        )}
      </Stat.Label>
      <Box gap={1} display={'flex'} flexWrap={'wrap'}>
        {c.contacts?.map(con => (
          <PillBox
            key={con.id}
            direction="column"
            hover
            onClick={() => {
              setShowContacts(true)
              setShowContactData(con)
            }}
          >
            <Typography variant="body2" style={{fontStyle: 'italic'}}>
              {con.name}
            </Typography>
            {con.emails.map(e => (
              <Typography variant="body2" key={e.id}>
                {e.email}
              </Typography>
            ))}
            {con.phones.map(e => (
              <Typography variant="body2" key={e.id}>
                {formatPhone(e.number)}
              </Typography>
            ))}
          </PillBox>
        ))}
      </Box>
      <Space size={2} />
      <Divider />
      <Space size={2} />
      <Stat.Label
        onMouseEnter={() => setShowCommsEdit(true)}
        onMouseLeave={() => setShowCommsEdit(false)}
        style={{display: 'flex', alignItems: 'center'}}
        id={'Communications'}
      >
        Communications
        {showCommsEdit ? (
          <EditIcon
            style={{height: '1em'}}
            onClick={() => setShowComms(true)}
            id={'editCommunications'}
          />
        ) : (
          <></>
        )}
      </Stat.Label>
      {renderComms(c)}
      <Divider />
      <Space size={2} />
      <div
        style={{
          display: 'flex',
          flexFlow: 'row wrap',
          justifyContent: 'space-around',
          marginBottom: '8px',
        }}
      >
        {c.balances ? (
          <>
            <Stat>
              <Stat.Label>Balance</Stat.Label>
              <Stat.Number>
                {formatMoneyStandard(c.balances.balance)}
              </Stat.Number>
            </Stat>
          </>
        ) : null}
        <Stat>
          <Stat.Label>Last Billable Service</Stat.Label>
          <Stat.Number variant="h6">
            {c.last_routerun ? (
              <ScheduleDateText
                date={c.last_billable_routerun.run_at}
                route={c.last_billable_routerun.route_id}
              />
            ) : (
              'None'
            )}
          </Stat.Number>
        </Stat>
        <Stat>
          <Stat.Label>Last Service</Stat.Label>
          <Stat.Number variant="h6">
            {c.last_routerun ? (
              <ScheduleDateText
                date={c.last_routerun.run_at}
                route={c.last_routerun.route_id}
              />
            ) : (
              'None'
            )}
          </Stat.Number>
        </Stat>
        <Stat>
          <Stat.Label>Next Service</Stat.Label>
          <Stat.Number variant="h6">
            {c.next_routerun ? (
              <ScheduleDateText
                date={c.next_routerun.run_at}
                route={c.next_routerun.route_id}
              />
            ) : (
              'None'
            )}
          </Stat.Number>
        </Stat>
      </div>
      {c.documents && !![...c.documents].filter(d => d.important).length && (
        <>
          <Divider />
          <Space size={2} />
          <div
            style={{
              display: 'flex',
              flexFlow: 'row wrap',
              // justifyContent: 'space-around',
              marginBottom: '8px',
            }}
          >
            <Space size={2} inline />
            {[...c.documents]
              .filter(d => d.important)
              .map(d => (
                <>
                  <Typography
                    component="a"
                    variant={'subtitle1'}
                    onClick={() => {
                      if (d.data_source === 'Airtable') {
                        window.open(d.airtable_url)
                        return
                      } else {
                        setSelectedDocument(d)
                        setShowDocument(true)
                        _documentPreview(d)
                      }
                    }}
                    style={{
                      cursor: 'pointer',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                    key={d.id}
                  >
                    <InsertDriveFile />
                    {d.description}
                  </Typography>
                  <Space size={2} inline />
                </>
              ))}
          </div>
        </>
      )}
      <EditEmails
        emails={editEmails}
        setEditEmails={setEditEmails}
        onSubmit={(e, d, n) => {
          let body = JSON.stringify({
            data: d,
          })
          updateEmails({
            variables: {id: c.id, emails: e, deleteEmails: d, createEmails: n},
          }).then(async res => {
            setEditEmails(null)
            if (!(c.emails && c.emails.length)) {
              let emails =
                res.data &&
                res.data.updateCustomers &&
                res.data.updateCustomers.customers
                  ? res.data.updateCustomers.customers.emails
                  : []
              let defaultEmail = emails.filter(e => e.default_email).pop()
              if (!defaultEmail) defaultEmail = emails.pop()
              if (defaultEmail) {
                await setDefaultEmails({
                  variables: {
                    id: c.id,
                    statements_email_id: defaultEmail.id,
                    recorded_invoices_email_id: defaultEmail.id,
                  },
                })
              }
            }
            refetch()
          })
          fetchAPI({
            url: '/emails/batch',
            options: {
              method: 'DELETE',
              body,
            },
          })
        }}
      />
      <EditPhones
        phones={editPhones}
        setEditPhones={setEditPhones}
        onSubmit={(e, d, n) => {
          let body = JSON.stringify({
            data: d,
          })
          updatePhones({
            variables: {id: c.id, phones: e, deletePhones: d, createPhones: n},
          }).then(() => {
            setEditPhones(null)
            refetch()
          })
          fetchAPI({
            url: '/phones/batch',
            options: {
              method: 'DELETE',
              body,
            },
          })
        }}
      />
      <DocumentDialog
        isOpen={showDocument}
        document={selectedDocument}
        onClose={() => setShowDocument(false)}
      />
      <EditCommunications
        customer={c}
        open={showComms}
        onSubmit={async values => {
          await updateComms({variables: values})
          setShowComms(false)
          refetch()
        }}
        onCancel={() => setShowComms(false)}
      />
      <EditContacts
        key={showContactData?.id}
        open={showContacts}
        contactId={showContactData?.id}
        data={showContactData}
        mode={contactEditMode}
        onSubmit={() => {
          setShowContacts(false)
          setContactEditMode('view')
          refetch()
        }}
        onCancel={() => {
          setContactEditMode('view')
          if (!(contactEditMode === 'edit' && showContactData.id)) {
            setShowContacts(false)
          }
        }}
        onViewEdit={() => setContactEditMode('edit')}
      />
    </DetailsWrapper>
  )
}

export function CustomerPhoto({customer: c}) {
  let photos = c.photos
  return (
    <a href={`/old/database/photo.php?JobID=${c.id}`}>
      <PhotoWrapper>
        {c.photo ? (
          <Image
            src={c.photo.url}
            alt={'customer ' + c.name}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            }}
          />
        ) : c.customertype.id === '2' ? (
          <PhotoFallback>
            <HomeIconLarge />
          </PhotoFallback>
        ) : (
          <PhotoFallback>
            <StoreIconLarge />
          </PhotoFallback>
        )}
        <ImageListItemBar
          title={`${photos.length ? photos.length : 'No'} Photo${
            photos.length === 1 ? '' : 's'
          }`}
          actionIcon={
            <IconButton style={{color: 'white'}} size="large">
              <AddAPhoto color="inherit" />
            </IconButton>
          }
        />
      </PhotoWrapper>
    </a>
  )
}

function CustomerDetail({match, location}) {
  let {id} = match.params

  let {data, isLoading: loading, isError: error, refetch} = useQuery(
    ['customer', id],
    () => prgql({query: customerQuery, variables: {id}}),
  )
  // let {
  //   data: airtable_data,
  //   // isLoading: airtable_loading,
  //   // isError: airtable_error,
  //   // refetch: airtable_refetch,
  // } = useQuery(['airtable_docs', id], () =>
  //   prgql({ query: airtableDocsQuery, variables: { id } }),
  // )
  let mediaMatches = useMediaQuery('(min-width:1680px)')

  if ((loading && !data) || error || !data.customers) return null

  const c = data.customers

  c.documents = [
    ...c.documents,
    // ...(airtable_data?.customers?.airtable_documents || []),
  ]

  let active = !c.stop_at || moment.utc(c.stop_at).isAfter(moment())
  return (
    <div style={Object.assign({}, sheet.container)}>
      <Content container direction="row">
        <Grid item>
          <CustomerPhoto customer={c} />
          <Space size={2} />
          <CustomerMap
            containerElement={
              <div
                style={{
                  width: '300px',
                  height: '300px',
                  overflow: 'hidden',
                  borderRadius: '16px',
                }}
              />
            }
            mapElement={<div style={{width: '300px', height: '300px'}} />}
            markers={[
              {
                position: {lat: c.geo_location.lat, lng: c.geo_location.lng},
                key: c.name,
              },
            ]}
            defaultCenter={{lat: c.geo_location.lat, lng: c.geo_location.lng}}
          />
        </Grid>
        <Space size={2} inline />
        <Grid item xs container direction={mediaMatches ? 'row' : 'column'}>
          <Grid item xs={mediaMatches}>
            <DetailsPanel
              customer={c}
              active={active}
              paused={c.services_paused}
              refetch={refetch}
            />
          </Grid>
          <Space size={2} inline={mediaMatches} />
          <Grid item xs={mediaMatches}>
            <CustomerSchedules
              customerId={id}
              active={active}
              paused={c.services_paused}
              customer={c}
            />
          </Grid>
        </Grid>
      </Content>
    </div>
  )
}

export default CustomerDetail
