// @flow
export default `
  type Payments @model(url: "/payments", filters: "PaymentFilters") {
    id: ID 
    paymenttype_id: ID @updateable
    reference: String @updateable
    memo: String @updateable
    notes: String @updateable
    recorded: Boolean @updateable
    amount: Float @updateable
    customer_id: ID @updateable
    paid_at: String @updateable
    customer: Customers @relationship
    account: Accounts @relationship
    paymentitems: [Paymentitems] @relationship
    invoices: [Invoices] @relationship
    workorder_id: ID @updateable
    accountpayment_id: ID @updateable
    created_user_id: ID @updateable
    created_user: Users @relationship
    paymenttype: Paymenttypes @relationship @notUpdateable
  }

  type Paymenttypes @model(url: "/paymenttypes", filters: "PaymenttypeFilters") {
    id: ID
    type: String
    qb_id: String
  }

  input PaymentFilters {
    recordable: Boolean
    paid_at: String
    field: Boolean
    paymenttype: String
  }

  input PaymenttypeFilters {
    type: String
  }

  type CustomerTypes {
    id: String
    type: String
  }
  
  type Emails @model(url: "/emails"){
    id: String
    customer_id: ID @updateable
    default_email: Boolean @updateable
    email: String @updateable
    label: String @updateable
    user_id: ID @updateable
    account_id: ID @updateable
    cc_statements: Boolean @updateable
    cc_recorded_invoices: Boolean @updateable
    notes: String @updateable
  }

  type Phones @model(url: "/phones"){
    id: String
    phoneable_id: String @updateable
    phoneable_type: String @updateable
    country: String @updateable
    area: String @updateable
    number: String @updateable
    extension: String @updateable
    label: String @updateable
    phonetype: Int @updateable
    created_at: String @updateable
    updated_at: String @updateable
  }

  type Contacts @model(url: "/contacts", filters: "ContactFilters") {
    id: String
    name: String 
    prefix: String @updateable
    first_name: String @updateable
    middle_name: String @updateable
    last_name: String @updateable
    suffix: String @updateable
    street1: String @updateable
    city_id: String @updateable
    region: String @updateable
    postcode: String @updateable
    city: Cities @relationship @notUpdateable
    notes: String @updateable
    sales_person_id: String @updateable
    created_user_id: String @updateable
    emails: [Emails] @relationship
    phones: [Phones] @relationship
    customers: [Customers] @relationship
    accounts: [Accounts] @relationship
    salesstages: [Salesstages] @relationship
    sales_person: Users @relationship @notUpdateable
    created_user: Users @relationship @notUpdateable
    created_at: String
    updated_at: String
    deleted_at: String
  }

  input ContactFilters {
    name: String
    address: String
    city_name: String
    region: String
    phone: String
    notes: String
    email: String
    trashed: Boolean
    salesstages: String
    sales_person_id: String
    created_user_id: String
  }

  type Salesstages @model(url: "/salesstages", filters: "SalesstageFilters") {
    id: String
    name: String @updateable
    created_at: String
    updated_at: String
    deleted_at: String
  }

  input SalesstageFilters {
    name: String 
    trashed: Boolean
  }

  type Refunds {id: String}

  input SourceFilters {
    source: String
    trashed: Boolean
  }

  type Sources @model(url: "/sources", filters: "SourceFilters") {
    id: ID 
    source: String @updateable
    deleted_at: String
  }

  input StopReasonsFilters {
    reason: String
    trashed: Boolean 
  }

  type StopReasons @model(url: "/stopreasons", filters: "StopReasonsFilters") {id: ID, reason: String @updateable, deleted_at: String}

  type Accounts @model(url: "/accounts", filters: "AccountFilters") { 
    id: String
    customertype_id: String @updateable
    attention: String @updateable
    name: String @updateable 
    street1: String @updateable
    street2: String @updateable
    street3: String
    city_id: String @updateable
    city_name: String
    region: String @updateable
    postcode: String @updateable
    country_id: String
    phone: String
    other_phone: String
    fax: String
    contact: String @updateable
    start_at: String
    stop_at: String
    active: Boolean @updateable
    account_job_id: String
    taxable: Boolean @updateable
    taxitem_id: String @updateable
    source_id: String @updateable
    user_id: String @updateable
    sales_person_id: String @updateable
    notes: String @updateable
    stopreason_id: String @updateable
    batch_output: String @updateable
    created_user_id: String
    updated_user_id: String
    parent_id: String @updateable
    created_at: String
    updated_at: String
    balances: Balances @relationship @notUpdateable
    open_invoices: [Invoices] @relationship @notUpdateable
    customers: [Customers] @relationship @notUpdateable
    city: Cities @relationship @notUpdateable
    customertype: CustomerTypes @relationship @notUpdateable
    documents: [Documents] @relationship @notUpdateable
    emails: [Emails] @relationship 
    phones: [Phones] @relationship
    photos: [Photos] @relationship @notUpdateable
    refunds: [Refunds] @relationship @notUpdateable
    source: Sources @relationship @notUpdateable
    stopreason: StopReasons @relationship @notUpdateable
    taxitem: TaxItems @relationship @notUpdateable
    user: Users @relationship @notUpdateable
    sales_person: Users @relationship @notUpdateable
    terms: Terms @relationship @notUpdateable
    salesstat: Salesstats @relationship @notUpdateable
    parent_account: Accounts @relationship @notUpdateable
    child_accounts: [Accounts] @relationship @notUpdateable
    airtable_estimates: [Estimates] @relationship @notUpdateable
    contract_start_at: String @updateable
    contract_stop_at: String @updateable
    activity_logs: [ActivityLogs] @relationship @notUpdateable
    contacts: [Contacts] @relationship
    salesstages: [Salesstages] @relationship

    estimates: [Estimates] @relationship @notUpdateable

    batch_output_attach_statement: Boolean @updateable
    batch_output_attach_invoices: Boolean @updateable
    batch_output_attach_workorders: Boolean @updateable
    batch_output_statement_as_excel: Boolean @updateable

    email_recorded_invoices: Boolean @updateable
    email_recorded_invoices_attach_invoices: String @updateable
    email_recorded_invoices_attach_workorders: Boolean @updateable

    statements_email_id: ID @updateable
    recorded_invoices_email_id: ID @updateable

    account_job: Customers @relationship @notUpdateable

    invoice_needs_po_number: Boolean @updateable
  }

  input AccountFilters {
    name: String
    address: String
    city_name: String
    region: String
    phone: String
    notes: String
    email: String
    contact: String
    status: CustomerStatus
    taxable: Boolean
    sales_status: CustomerSalesStatus
    user_id: String
    year: String
    salesstages: String
  }

  union TransactionDetail = Payments | Invoices

  type TransactionRel {
    detail: TransactionDetail
  }

  type Transactions {
    id: String
    amount: Float
    balance: Float
    memo: String
    reference: String
    type: String
    relationships: TransactionRel
  }

  type Customers @model(url: "/customers", filters: "CustomerFilters") {
    id: String
    name: String @updateable
    notes: String @updateable
    scheduling_notes: String @updateable
    estimate_notes: String @updateable
    account_id: String @updateable
    account_job: Int
    account: Accounts @relationship
    customertype_id: String @updateable
    customertype: CustomerTypes @relationship @notUpdateable
    attention: String @updateable
    street1: String @updateable
    street2: String
    street3: String
    city_id: String @updateable
    city_name: String
    region: String @updateable
    postcode: String @updateable
    phone: String
    other_phone: String
    fax: String
    country_id: String
    contact: String @updateable
    start_at: String @updateable
    stop_at: String @updateable
    terms_id: String @updateable
    days_open: String @updateable
    time_open: String @updateable
    stopreason_id: String @updateable
    source_id: String @updateable
    user_id: String @updateable
    sales_person_id: String @updateable
    photo_id: String
    taxable: Boolean @updateable
    taxitem_id: String @updateable
    taxitem: TaxItems @relationship @notUpdateable
    lat: Float @updateable
    lng: Float @updateable
    photo_url: String
    batch_output: String @updateable
    credit: String   #just for sorting, get credit_items
    created_user_id: String
    updated_user_id: String
    contract_start_at: String @updateable
    contract_stop_at: String @updateable
    services_paused: Boolean @updateable 
    po_needed: Boolean @updateable 
    key_access: Boolean @updateable 
    invoice_needs_po_number: Boolean @updateable
    needs_service_confirmation: Boolean @updateable
    rain_sensitive: Boolean @updateable
    attach_confirmation_photos_to_invoice: Boolean @updateable
    move_obstruction_for_service: Boolean @updateable

    city: Cities @relationship @notUpdateable
    photo: Photos @relationship @notUpdateable
    photos(limit: Int): [Photos] @relationship @notUpdateable
    phones: [Phones] @relationship
    balances: Balances @relationship @notUpdateable
    salesstat: Salesstats @relationship @notUpdateable
    transactions(from: String, limit: Int): [Transactions] @relationship @notUpdateable
    invoices(limit: Int): [Invoices] @relationship @notUpdateable
    open_invoices: [Invoices] @relationship @notUpdateable 
    emails: [Emails] @relationship
    terms: Terms @relationship @notUpdateable
    source: Sources @relationship @notUpdateable
    stopreason: StopReasons @relationship @notUpdateable
    user: Users @relationship @notUpdateable
    sales_person: Users @relationship @notUpdateable
    last_routerun: RouteRuns @relationship @notUpdateable
    last_billable_routerun: RouteRuns @relationship @notUpdateable
    next_routerun: RouteRuns @relationship @notUpdateable
    geo_location: GeoLocations @relationship @notUpdateable
    estimates: [Estimates] @relationship @notUpdateable
    airtable_estimates: [Estimates] @relationship @notUpdateable
    unresolved_tech: [Feedbacks] @relationship @notUpdateable
    credit_items: [Paymentitems] @relationship @notUpdateable
    credit_payments: [Payments] @relationship @notUpdateable
    open_workorders: [Workorders] @relationship @notUpdateable
    documents: [Documents] @relationship @notUpdateable
    airtable_documents: [Documents] @relationship @notUpdateable
    evaluations: [Evaluations] @relationship @notUpdateable
    last_arnote: ARNotes @relationship @notUpdateable
    activity_logs: [ActivityLogs] @relationship @notUpdateable
    schedules: [Schedules] @relationship
    contacts: [Contacts] @relationship
    salesstages: [Salesstages] @relationship

    batch_output_attach_statement: Boolean @updateable
    batch_output_attach_invoices: Boolean @updateable
    batch_output_attach_workorders: Boolean @updateable
    batch_output_statement_as_excel: Boolean @updateable

    email_recorded_invoices: Boolean @updateable
    email_recorded_invoices_attach_invoices: String @updateable
    email_recorded_invoices_attach_workorders: Boolean @updateable

    statements_email_id: ID @updateable
    recorded_invoices_email_id: ID @updateable

    created_at: String
    updated_at: String
    deleted_at: String
  }

  input CustomerFilters {
    account_id: ID
    account_name: String
    customertype: String
    customertype_id: ID
    name: String
    contact: String
    address: String
    postcode: String
    phone: String
    email: String
    region: String
    notes: String
    status: CustomerStatus
    route_id: String
    taxable: Boolean
    terms_id: String
    city_name: String
    source_id: String
    stopreason_id: String
    sales_person_id: String
    user_id: String
    sales_status: CustomerSalesStatus
    contract: Boolean
    invoice_status: String
    order: String
    services_paused: Boolean
    services_stopped: Boolean
    year: String
    credit: Boolean 
    trashed: Boolean
    balance: Float
    salesstages: String
    pending_sale: Boolean
  }

  type Photos @model(url: "/photos", filters: PhotoFilters) {
    id: String
    url: String
    thumb_url: String
    medium_url: String
    large_url: String
    name: String
    description: String @updateable
    type: PhotoTypes @updateable
    sequence: Int @updateable
    lat: Float @updateable
    lng: Float @updateable
    created_at: String
    updated_at: String
  }

  input PhotoFilters {
    photoable_id: String,
    photoable_type: String,
  }

  enum PhotoTypes {
    SIGNATURE
    CONFIRMATION
  }

  type Balances {
    id: String
    balance: Float
    ninetyDay: Float
    overNinetyDay: Float
    sixtyDay: Float
    thirtyDay: Float
    credit: Float
    applicable_credit: Float
  }

  type Salesstats {
    id: ID,
    sales: Float
  }

  enum SearchBy {
    account_name
    name
    city_name
    contact
    phone
    address
  }

  enum CustomerStatus { active inactive }
  enum CustomerSalesStatus { lead sold }
  enum CustomerType { residential commercial }

  type ARNotes @model(url: "/arnotes") {
    id: String
    customer_id: ID @updateable
    account_id: ID @updateable
    recorded_at: String @updateable
    user_id: ID @updateable
    note: String @updateable
    created_at: String
    updated_at: String

    account: Accounts @relationship @notUpdateable
    customer: Customers @relationship @notUpdateable
    user: Users @relationship @notUpdateable
  }

  type TaxItems {
    id: ID
    name: String
    rate: Float
  }

  type GeoLocations {
    id: String
    lat: Float
    lng: Float
  }

  type Documents @model(url: "/documents", filters: DocumentFilters) {
    id: String
    airtable_id: String
    airtable_url: String
    data_source: DataSources
    name: String
    important: Boolean
    show_on_mobile_app: Boolean
    description: String
    customer_id: ID,
    account_id: ID,
    url: String
  }

  input DocumentFilters {
    customer_id: String
    show_on_mobile_app: Boolean
  }
 
  input EvaluationFilters {
    customer_id: ID
  }

  type Evaluations @model(url: "/evaluations", filters: EvaluationFilters) {
    id: String 
    customer_id: ID @updateable
    evaluated_at: String @updateable
    supervisor_id: ID @updateable
    team_captain_id: ID @updateable
    pw_quality: String @updateable
    window_quality: String @updateable
    sill_quality: String @updateable
    contact: String @updateable
    comments: String @updateable
    supervisor: Users @relationship @notUpdateable
    team_captain: Users @relationship @notUpdateable
    customer: Customers @relationship @notUpdateable
  }
`
