// @flow
import _ from 'lodash'
import moment from 'moment'
import {
  resolve,
  resolveRels,
  createEntityLoader,
  fetchAPI,
  fetchEndpoint,
} from './utils'
import customersSchema from './customers'
import feedbacksSchema from './feedbacks'
import usersSchema from './users'
import invoicesSchema from './invoices'
import inventorySchema from './inventory'
import schedulesSchema from './schedules'
import statisticsSchema from './statistics'
import principalsSchema from './principals'
import commandsSchema from './commands'
import miscSchema from './misc'
import estimatesSchema from './estimates'

import * as directives from './directives'
import {createSchemaWithDirectives} from './schemaDirectives'

// import type {ResolverMap} from './types'

let rootSchema = `
  type Query {
    currentUser: Users
    lastRouteRunForSchedule(scheduleId: ID!, date: String): RouteRuns
    nextRouteRunForSchedule(scheduleId: ID!, date: String, next_customer_routerun: Boolean): RouteRuns
  }

  input CustomerInput {
    id: String!
    name: String
    notes: String
  }

  type Mutation {
    updateCustomer(customer: CustomerInput!): Customers
  }

  schema {
    query: Query
    mutation: Mutation
  }
`

let rootResolvers = {
  Query: {
    currentUser: (root, args, context, info) =>
      resolve({url: '/current-user', info, prefixUrl: false}),
    nextRouteRunForSchedule: (root, args, context, info) =>
      resolve({
        url: `/nextschedulerouterun/${args.scheduleId}`,
        query: {
          next_customer_routerun: args.next_customer_routerun,
          date: args.date
            ? moment.utc(args.date).format('YYYY-MM-DD')
            : undefined,
        },
        info,
        isConnection: false,
      }),
    lastRouteRunForSchedule: (root, args, context, info) =>
      resolve({
        url: `/lastschedulerouterun/${args.scheduleId}`,
        query: {
          ...args,
          date: args.date
            ? moment.utc(args.date).format('YYYY-MM-DD')
            : undefined,
        },
        info,
        isConnection: false,
      }),
    fullSchedule: (root, args, context, info) => {
      return resolve({
        url: `/routeruns/schedule`,
        query: {
          ...args?.filters,
        },
        info,
        isConnection: true,
      })
    },
  },
  Mutation: {
    emailTransactions: async (root, args, context, info) => {
      let body = JSON.stringify({
        data: {
          attributes: {
            command: 'emailtransactions',
            payload: {
              default: args.input.default,
              principals: args.input.principals.map(id => ({
                id,
                type: 'principals',
              })),
              accountCustomers: args.input.accountCustomers,
            },
          },
        },
      })

      let res = await fetchAPI({
        url: '/principals/commands',
        options: {method: 'POST', body},
      })

      return res.json()
    },
    emailCustomerTransactions: async (root, args, context, info) => {
      let input = args.input

      if (input.invoices === 'list') {
        input.invoices = input.invoices_list
        input.invoices_list = undefined
      }

      let body = JSON.stringify({
        data: {
          attributes: {
            command: 'emailtransactions',
            payload: input,
          },
        },
      })

      let res = await fetchAPI({
        url: `/customers/${args.customerId}/commands`,
        options: {method: 'POST', body},
      })

      return res.json()
    },
    emailManyCustomerTransactions: async (root, args, context, info) => {
      let input = args.input

      let payload = {}
      payload.default = input
      payload.ids = args.customerIds
      if (input.invoices === 'list') {
        payload.default.invoices = input.invoices_list
        payload.default.invoices_list = undefined
      }

      let body = JSON.stringify({
        data: {
          attributes: {
            command: 'emailmanytransactions',
            payload: payload,
          },
        },
      })

      let res = await fetchAPI({
        url: `/customers/commands`,
        options: {method: 'POST', body},
      })

      return res.json()
    },
    emailAccountTransactions: async (root, args, context, info) => {
      let input = args.input

      if (input.invoices === 'list') {
        input.invoices = input.invoices_list
        input.invoices_list = undefined
      }

      let body = JSON.stringify({
        data: {
          attributes: {
            command: 'emailtransactions',
            payload: input,
          },
        },
      })

      let res = await fetchAPI({
        url: `/accounts/${args.accountId}/commands`,
        options: {method: 'POST', body},
      })

      return res.json()
    },
    emailReceipt: async (root, args, context, info) => {
      let input = args.input

      let body = JSON.stringify({
        data: {
          attributes: {
            command: 'emailreceipt',
            payload: input,
          },
        },
      })

      let res = await fetchAPI({
        url: `/payments/${args.paymentId}/commands`,
        options: {method: 'POST', body},
      })

      return res.json()
    },
    resequenceSchedule: async (root, args, context, info) => {
      let input = args.input

      let body = JSON.stringify({
        data: {
          attributes: {
            ...input,
          },
        },
      })

      let res = await fetchAPI({
        url: `/schedules/${args.scheduleId}/reorder`,
        options: {method: 'POST', body},
      })

      return res.json()
    },

    updateFromTemplate: async (root, args, context, info) => {
      let input = args.input

      let body = JSON.stringify({
        data: {
          attributes: {
            command: 'updatefromtemplate',
            payload: input,
          },
        },
      })

      let res = await fetchAPI({
        url: `/routeruns/commands`,
        options: {method: 'POST', body},
      })

      return res.json()
    },
    purgeUserFromSchedule: async (root, args, context, info) => {
      let input = args.input

      let body = JSON.stringify({
        data: {
          attributes: {
            command: 'purgeuserfromschedule',
            payload: input,
          },
        },
      })

      let res = await fetchAPI({
        url: `/routeruns/commands`,
        options: {method: 'POST', body},
      })

      return res.json()
    },

    emailCustomerCare: async (root, args, context, info) => {
      let input = args.input

      let body = JSON.stringify({
        data: {
          attributes: {
            command: 'emailcustomercare',
            payload: input,
          },
        },
      })

      let res = await fetchAPI({
        url: `/customers/${args.customerId}/commands`,
        options: {method: 'POST', body},
      })

      return res.json()
    },

    emailChristmasCare: async (root, args, context, info) => {
      let input = args.input

      let body = JSON.stringify({
        data: {
          attributes: {
            command: 'emailchristmascare',
            payload: input,
          },
        },
      })

      let res = await fetchAPI({
        url: `/customers/${args.customerId}/commands`,
        options: {method: 'POST', body},
      })

      return res.json()
    },

    generateWorkordersFromSchedules: async (root, args, context, info) => {
      let input = args.input

      let body = JSON.stringify({
        data: {
          attributes: {
            command: 'generatefromschedules',
            payload: input,
          },
        },
      })

      let data = await fetchEndpoint({
        url: `/workorders/commands`,
        options: {method: 'POST', body},
        isConnection: false,
      })

      return {workorders: data, query: {}, errors: data.errors}
    },

    generateInvoicesFromWorkorders: async (root, args, context, info) => {
      let input = args.input

      let body = JSON.stringify({
        data: {
          attributes: {
            command: 'generatefromworkorders',
            payload: input,
          },
        },
      })

      let data = await fetchEndpoint({
        url: `/invoices/commands`,
        options: {method: 'POST', body},
        isConnection: false,
      })

      return {invoices: data, query: {}, errors: data.errors}
    },
    payInvoicesWithCash: async (root, args, context, info) => {
      let input = args.input

      let body = JSON.stringify({
        data: {
          attributes: {
            command: 'payinvoiceswithcash',
            payload: input,
          },
        },
      })

      let data = await fetchEndpoint({
        url: `/invoices/commands`,
        options: {method: 'POST', body},
        isConnection: false,
      })

      return {invoices: data, query: {}, errors: data.errors}
    },
    payInvoicesWithCredit: async (root, args, context, info) => {
      let input = args.input

      let body = JSON.stringify({
        data: {
          attributes: {
            command: 'payinvoiceswithcredit',
            payload: input,
          },
        },
      })

      let data = await fetchEndpoint({
        url: `/invoices/commands`,
        options: {method: 'POST', body},
        isConnection: false,
      })

      return {invoices: data, query: {}, errors: data.errors}
    },
    resequenceWorkorder: async (root, args, context, info) => {
      let input = args.input

      let body = JSON.stringify({
        data: {
          attributes: {
            ...input,
          },
        },
      })

      let res = await fetchAPI({
        url: `/workorders/${args.workorderId}/reorder`,
        options: {method: 'POST', body},
      })

      return res.json()
    },
    reschedule: async (root, args, context, info) => {
      let input = args.input

      let body = JSON.stringify({
        data: {
          attributes: {
            ...input,
          },
        },
      })

      let res = await fetchAPI({
        url: `/schedules/${args.scheduleId}/reschedule`,
        options: {method: 'POST', body},
      })

      return res.json()
    },
    extractFromBundle: async (root, args, context, info) => {
      let input = args.input

      let body = JSON.stringify({
        data: {
          attributes: {
            command: 'extractfrombundle',
            payload: input,
          },
        },
      })

      let res = await fetchAPI({
        url: `/workorders/commands`,
        options: {method: 'POST', body},
      })

      return res.json()
    },
    sendReminders: async (root, args, context, info) => {
      let input = args.input

      let body = JSON.stringify({
        data: {
          attributes: {
            command: 'sendreminders',
            payload: input,
          },
        },
      })

      let res = await fetchAPI({
        url: `/workorders/commands`,
        options: {method: 'POST', body},
      })

      return res.json()
    },
    sendTipsReports: async (root, args, context, info) => {
      let input = args.input

      let body = JSON.stringify({
        data: {
          attributes: {
            command: 'sendtipsreports',
            payload: input,
          },
        },
      })

      let res = await fetchAPI({
        url: `/users/commands`,
        options: {method: 'POST', body},
      })

      return res.json()
    },
  },
  RouteRuns: {
    schedulesPerf: (root, args, context, info) =>
      resolve({
        url: '/schedules',
        query: {
          date: moment.utc(root.run_at).format('YYYY-MM-DD'),
          route: root.route_id,
        },
        info,
        isConnection: false,
      }),
  },
  TransactionDetail: {__resolveType: ({_gqlType}) => _gqlType},
  Invoices: {
    relationships: resolveRels({
      invoiceitems: 'include',
      billcity: 'include',
      servicecity: 'include',
    }),
  },
  Invoiceitems: {relationships: resolveRels({saleitem: 'include'})},
}

export let dataIdFromObject = o => o.id && `${o.__typename}:${o.id}`

export let getCacheRedirectsFromSchema = ({schema}) => {
  let hasResolveDirective = field => {
    let directives = field.astNode.directives
    let resolveDir = directives.find(({name}) => name.value === 'redirect')
    return !!resolveDir
  }

  let queryType = schema.getQueryType()
  let queryFields = queryType.getFields()
  let redirects = Object.entries(queryFields).reduce(
    (acc, [fieldName, fieldType]) => {
      if (!hasResolveDirective(fieldType)) {
        return acc
      }
      return {
        ...acc,
        [fieldName]: ({id}) =>
          dataIdFromObject({id, __typename: fieldType.type.name}),
      }
    },
    {},
  )
  return {Query: redirects}
}

let schema = [
  // ConnectionDirective,
  rootSchema,
  customersSchema,
  invoicesSchema,
  feedbacksSchema,
  inventorySchema,
  usersSchema,
  schedulesSchema,
  statisticsSchema,
  principalsSchema,
  commandsSchema,
  miscSchema,
  estimatesSchema,
]
let resolvers = _.merge(rootResolvers)

export {resolvers, createEntityLoader}

let executableSchema = createSchemaWithDirectives({
  typeDefs: schema,
  resolvers,
  directives,
})
executableSchema._typeMap.Query._fields.currentUser.connection =
  'I Love Catherine'
console.log('schema', executableSchema)
export default executableSchema
