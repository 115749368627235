export default `
type InventoryItems @model(url: "/inventoryitems", filters: "InventoryItemFilters") {
  id: ID
  description: String @updateable
  count: Int @updateable
  unit_cost: Float @updateable
  inventoryitemtype_id: String @updateable
  part_number: String @updateable
  min: Int @updateable
  max: Int @updateable
  merchant_id: String @updateable
  brand_id: String @updateable
  fund_id: String @updateable
  created_at: String
  updated_at: String
  inventoryitemtype: InventoryItemTypes @relationship
  merchant: Merchants @relationship
  brand: Brands @relationship
  fund: Funds @relationship
  requisitionitems: [RequisitionItems] @relationship
  poitems: [POItems] @relationship
}

input InventoryItemFilters {
  description: String
  status: InventoryStatus
  reorder: InventoryReorder
}
enum InventoryStatus {
  stocked
  not_stocked
  over_stocked
  under_stocked
}
enum InventoryReorder {
  requisitions
  stock
  ordered
  all_not_ordered
  all
}

type InventoryItemTypes @model(url: "/inventoryitemtypes") {
  id: ID
  type: String @updateable
  created_at: String
  updated_at: String
}

type Merchants @model(url: "/merchants") {
  id: ID
  name: String @updateable
  phone_number: String @updateable
  created_at: String
  updated_at: String
}

type Brands @model(url: "/brands", filters: "BrandFilters") {
  id: ID
  name: String @updateable
}
input BrandFilters {
  name: String
}

type Funds @model(url: "/funds", filters: "FundFilters") {
  id: ID
  name: String @updateable
}
input FundFilters {
  name: String
}

type Requisitions @model(url: "/requisitions", filters: "RequisitionFilters") {
  id: ID
  user_id: ID @updateable
  closed: Boolean @updateable
  closed_id: String @updateable
  closed_at: String @updateable
  created_at: String
  updated_at: String
  total_cost: Float
  total_items: Int
  requisitionitems: [RequisitionItems] @relationship
  user: Users @relationship
  closed_by: Users @relationship
}

input RequisitionFilters {
  user_id: String
  closed: Boolean
  closed_id: String
}

type RequisitionItems @model(url: "/requisitionitems") {
  id: ID
  requisition_id: String
  inventoryitem_id: String @updateable
  quantity: Int @updateable
  quantity_filled: Int @updateable
  amount: Float @updateable
  inventoryitem_description: String
  inventoryitem_unit_cost: String
  created_at: String
  updated_at: String
  inventoryitem: InventoryItems @relationship
  requisition: Requisitions @relationship
}

type POs @model(url: "/pos", filters: "POFilters") {
  id: ID
  user_id: ID @updateable
  closed: Boolean @updateable
  closed_id: String @updateable
  closed_at: String @updateable
  created_at: String
  updated_at: String
  total_cost: Float
  total_items: Int
  poitems: [POItems] @relationship
  user: Users @relationship
  closed_by: Users @relationship
}

input POFilters {
  user_id: String
  closed: Boolean
  closed_id: String
}

type POItems @model(url: "/poitems") {
  id: ID
  po_id: String @updateable
  inventoryitem_id: String @updateable
  description: String @updateable
  url: String @updateable
  merchant_id: String @updateable
  order_number: String @updateable
  quantity: Int @updateable
  quantity_filled: Int @updateable
  amount: Float @updateable
  inventoryitem: InventoryItems @relationship
  po: POs @relationship
}
`
