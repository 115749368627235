import styled from "styled-components";

export const Code = styled.span`
  font-family: monospace;
  white-space: pre;
  margin-right: ${({theme}) => theme.muiTheme.spacing(.5)};
  margin-left: ${({theme}) => theme.muiTheme.spacing(.5)};
  padding: ${({theme}) => theme.muiTheme.spacing(.25)};
  border-radius: ${({theme}) => theme.muiTheme.spacing(1)};
  background-color: ${({theme}) => theme.muiTheme.palette.info.dark};
  color: ${({theme}) => theme.muiTheme.palette.primary.contrastText};
`

export const CodeBlock = styled.div`
  font-family: monospace;
  white-space: pre-wrap;
  margin-right: ${({theme}) => theme.muiTheme.spacing(.5)};
  margin-left: ${({theme}) => theme.muiTheme.spacing(.5)};
  padding: ${({theme}) => theme.muiTheme.spacing(.5)};
  border-radius: ${({theme}) => theme.muiTheme.spacing(1)};
  background-color: ${({theme}) => theme.muiTheme.palette.info.muted};
`