import React, { useState } from 'react';
import _ from 'lodash'
import { Autocomplete, useAutoCompleteEndpoint } from './AutoComplete';
import { ListItem, TextField } from '@mui/material';
import gql from 'graphql-tag';
import { formatMoneyStandard } from '../utils/moneyFormatter';

let gqlFragment = gql`
  fragment Discount on Discounts {
    id
    name
    type
    amount
    active
    created_at
    updated_at
    deleted_at
  }
`

export const DiscountSelect = ({
  selectedId,
  idKey = 'discount',
  label = 'Discount',
  inputProps,
  ...props
}) => {
  let [shouldFilterOutInactive, setShouldFilterOutInactive] = useState(true)
  let { onSearch, ...autoProps } = useAutoCompleteEndpoint({
    gqlFragment,
    selectedId,
    searchField: 'name',
    extraFilters: shouldFilterOutInactive ? {
      status: { enum: 'active' }
    } : undefined
  })

  let discountFormula = (discount) => {
    if (discount.type === 2) {
      return formatMoneyStandard(discount.amount) + ' off'
    }
    return discount.amount + '% off'
  }

  return (
    <Autocomplete
      {...autoProps}
      onSearch={(searchString) => {
        setShouldFilterOutInactive(!searchString)
        onSearch(searchString)
      }}
      getOptionLabel={option => (option ? `${option.name} (${discountFormula(option)})` : '')}
      renderInput={(params) => <TextField {...params} {...inputProps} label={label} />}
      renderOption={(props, option) => <ListItem {...props} id={`${idKey}-${option.id}`} key={option.id}>{`${option.name} (${discountFormula(option)})`}</ListItem>}
      {...props}
    />
  )
}
