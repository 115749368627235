import React, { Component, useState } from 'react'
import gql from 'graphql-tag'
import { graphql } from 'react-apollo'

import ListItemText from '@mui/material/ListItemText'

import Person from '@mui/icons-material/Person'

import AutoComplete, { Autocomplete, useAutoCompleteEndpoint } from './AutoComplete'
import { InputLabel, ListItem, TextField } from '@mui/material'

let usersQuery = gql`
  query ActiveUsers(
    $limit: Int = 100
    $cursor: String = "-1"
    $name: String
    $selectedUserId: String = ""
    $withSelected: Boolean = true
  ) {
    users: allUsers(
      limit: $limit
      cursor: $cursor
      filters: {status: active, name: $name}
    ) {
      edges {
        edge {
          id
          first_name
          last_name
        }
      }
    }
    selectedUser: users(id: $selectedUserId) @include(if: $withSelected) {
      id
      first_name
      last_name
    }
  }
`
class UsersSelect extends Component {
  onSearch = v => {
    let {
      data: { refetch },
    } = this.props
    refetch({ name: v })
  }
  render() {
    let { onChange, data, ...props } = this.props
    return (
      <AutoComplete
        onChange={onChange}
        data={data.users && data.users.edges}
        onSearch={this.onSearch}
        itemToString={u => (u ? `${u.first_name} ${u.last_name}` : '')}
        selectedItem={data.selectedUser}
        placeholder="Filter by User"
        icon={<Person />}
        getItem={({ edge }) => edge}
        renderItem={({ item }) => (
          <ListItemText
            primary={`${item.first_name} ${item.last_name}`}
            id={`user-select-${item.id}`}
          />
        )}
        renderNoItems={() => <ListItemText primary="No user found" />}
        {...props}
      />
    )
  }
}
export default graphql(usersQuery, {
  options: ({ selectedUserId }) => ({
    variables: { limit: 100, selectedUserId, withSelected: !!selectedUserId },
  }),
})(UsersSelect)

let gqlFragment = gql`
  fragment User on Users {
    id
    first_name
    last_name
    active
  }
`

export const UserSelectDynamic = ({
  selectedId,
  idKey = 'user',
  label = 'Users',
  inputProps,
  ...props
}) => {
  let [shouldFilterOutInactive, setShouldFilterOutInactive] = useState(true)
  let { onSearch, ...autoProps } = useAutoCompleteEndpoint({
    gqlFragment,
    selectedId,
    searchField: 'name',
    extraFilters: shouldFilterOutInactive ? {
      status: { enum: 'active' }
    } : undefined
  })

  return (
    <Autocomplete
      {...autoProps}
      onSearch={(searchString) => {
        setShouldFilterOutInactive(!searchString)
        onSearch(searchString)
      }}
      getOptionLabel={u => (u ? `${u.first_name} ${u.last_name}` : '')}
      renderInput={(params) => <TextField {...params} {...inputProps} label={label} />}
      renderOption={(props, option) => <ListItem {...props} id={`${idKey}-${option.id}`} key={option.id}>{`${option.first_name} ${option.last_name}`}</ListItem>}
      {...props}
    />
  )
}
