import React from 'react'
import styled from 'styled-components'

import Typography from '@mui/material/Typography'

let StatRoot = styled.div``

export default function Stat({children, ...props}) {
  return <StatRoot {...props}>{children}</StatRoot>
}

export function StatLabel({children, ...props}) {
  return (
    <Typography variant="subtitle1" color="textSecondary" {...props}>
      {children}
    </Typography>
  )
}
Stat.Label = StatLabel

let sizeVariants = {
  regular: 'h6',
  large: 'h4',
}

export function StatNumber({children, size = 'regular', ...props}) {
  return (
    <Typography variant={sizeVariants[size]} {...props}>
      {children}
    </Typography>
  )
}
Stat.Number = StatNumber

export function StatHelpText({children, ...props}) {
  return (
    <Typography variant="caption" color="textSecondary" {...props}>
      {children}
    </Typography>
  )
}
Stat.HelpText = StatHelpText
