import PropTypes from 'prop-types'
import React from 'react'
import gql from 'graphql-tag'
import { Column } from 'react-virtualized'
import moment from 'moment'
import { Link } from 'react-router-dom'

import Typography from '@mui/material/Typography'

import TableList from '../../components/Table/TableList'
import { formatMoneyStandard } from '../../utils/moneyFormatter'
import { TableSortLabel, Tooltip } from '@mui/material'
import { Comment, Error, ModeComment } from '@mui/icons-material'
import { discountedCharge } from '../../utils'

const sheet = {
  cellContainer: { display: 'flex', alignItems: 'center', padding: '5px 20px' },
  photoWrapper: { marginRight: '20px' },
}

let sortIsActive = (order, sortKey, isDefault = false) => {
  if (order === undefined && isDefault) {
    return true
  }

  let activeSortKey = order.replace(/_ASC|_DESC/g, '')

  return activeSortKey === sortKey
}

let sortDirection = (order, isActive, defaultDir = 'asc') => {
  if (order === undefined || !isActive) {
    return defaultDir
  }

  let activeSortDirection = order.match(/_ASC|_DESC/gm, '')[0]

  return activeSortDirection === '_ASC' ? 'asc' : 'desc'
}

export const fragments = {
  workorder: gql`
    fragment WorkorderDetail on Workorders {
      id
      assigned_at
      completed_at
      status
      route_id
      multiday
      multiday_estimated_total
      schedule_id
      not_completed
      is_billable
      route {
        id
        name
      }
      customer_id
      notes
      notes_resolved
      not_completed
      customer {
        id
        name
        street1
        po_needed
        customertype {
          id
          type
        }
        city {
          id
          name
        }
        region
        postcode
        taxable
        taxitem {
          id
          rate
        }
        photo {
          id
          thumb_url
          url
        }
        photos {
          id
          thumb_url
          url
        }
      }
      invoice_id
      workorderitems {
        id
        saleitem_id
        service_id
        description
        notes
        charge
        sales_person_id
        commission_start_at
        discount_id
        discount {
          id
          name
          type
          amount
          active
        }
      }
    }
  `,
  workorderitem: gql`
    fragment WorkorderitemDetail on Workorderitems {
      id
      saleitem_id
      service_id
      description
      notes
      charge
      sales_person_id
      commission_start_at
      man_minutes
      taxable
      discount_id
      tasks {
        id
        tasktype_id
        complete
        description
        tasktype {
          id
          description
        }
      }
      discount {
        id
        name
        type
        amount
        active
      }
    }
  `,
}

let WorkordersList = ({
  workorders = [],
  pageInfo,
  loading,
  onQueryChange,
  order,
  onInfiniteLoad,
}) => {
  let getRowCount = () => {
    if (!pageInfo) {
      return 9999
    }
    return pageInfo.total
  }

  const createOrderSelector = (key, defaultDir = '_ASC') =>
  (function() {
    let value = ''
    if (order !== key + '_ASC' && order !== key + '_DESC') {
      value = key + defaultDir
    } else {
      value = order === key + '_ASC' ? key + '_DESC' : key + '_ASC'
    }
    onQueryChange({ order: value })
  })

  let onNameOrder = createOrderSelector('name')
  let onIdOrder = createOrderSelector('id', '_DESC')
  let onStatusOrder = createOrderSelector('status', '_ASC')
  let onWhenOrder = createOrderSelector('assigned_at', '_ASC')

  return (
    <TableList
      data={workorders}
      infinite
      loadMoreRows={onInfiniteLoad}
      rowCount={pageInfo ? pageInfo.total : 9999}
      loading={loading}
    >
      <Column
        dataKey="id"
        headerRenderer={({ label }) => (
          <TableSortLabel
            active={sortIsActive(order, 'id')}
            direction={sortDirection(order, sortIsActive(order, 'id'))}
            onClick={onIdOrder}
          >
            {label}
          </TableSortLabel>
        )}
        cellRenderer={_renderIdColumn}
        label="ID"
        flexGrow={1}
        width={0}
      />
      <Column
        dataKey="name"
        headerRenderer={({ label }) => (
          <div style={{ textAlign: 'center' }}>{label}</div>
        )}
        cellRenderer={_renderNameColumn}
        label="Name"
        flexGrow={5}
        width={0}
      />
      <Column
        dataKey="when"
        label="When"
        minWidth={210}
        // headerRenderer={({label}) => (
        //   <div style={{textAlign: 'center'}}>{label}</div>
        // )}
        headerRenderer={({ label }) => (
          <TableSortLabel
            active={sortIsActive(order, 'when')}
            direction={sortDirection(order, sortIsActive(order, 'when'))}
            onClick={onWhenOrder}
          >
            {label}
          </TableSortLabel>
        )}
        cellRenderer={({ rowData }) => {
          if (!rowData) return ''

          let format =
            moment.utc(rowData.assigned_at).year() === moment.utc().year()
              ? 'ddd, MMM D'
              : "ddd, MMM D, 'YY"

          return (
            rowData && (
              <Link
                to={`/schedules/daily?date=${moment
                  .utc(rowData.assigned_at)
                  .format('YYYY-MM-DD')}&routes=${rowData.route_id}`}
              >
                {`Route ${rowData.route && rowData.route.name} on ${moment
                  .utc(rowData.assigned_at)
                  .format(format)}`}
              </Link>
            )
          )
        }}
      />
      <Column
        dataKey="status"
        label="Status"
        width={150}
        headerRenderer={({ label }) => (
          <TableSortLabel
            active={sortIsActive(order, 'status')}
            direction={sortDirection(order, sortIsActive(order, 'status'))}
            onClick={onStatusOrder}
          >
            {label}
          </TableSortLabel>
        )}
        cellRenderer={({ cellData, rowData }) => (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Typography style={{ marginRight: '5px' }}>{cellData}</Typography>
            {rowData && !!rowData.notes && (
              <Tooltip title="Has notes">
                {rowData.notes_resolved ? <ModeComment /> : <Comment />}
              </Tooltip>
            )}{' '}
            {cellData === 'OPEN' && rowData && rowData.not_completed && (
              <Tooltip title="Not completed">
                <Error />
              </Tooltip>
            )}
          </div>
        )}
      />
      <Column
        dataKey="total"
        label="Total"
        width={150}
        headerRenderer={({ label }) => (
          <div style={{ textAlign: 'center' }}>{label}</div>
        )}
        cellRenderer={_renderTotalColumn}
      />
    </TableList>
  )
}

let _renderIdColumn = function({ rowData, rowIndex, isScrolling }) {
  return (
    rowData && (
      <Link to={`/workorders/${rowData.id}`}>
        <div style={sheet.cellContainer}>
          <div>
            <Typography display="block" variant="h6">
              {rowData.id}
            </Typography>
          </div>
        </div>
      </Link>
    )
  )
}

let _renderNameColumn = function({ rowData, rowIndex, isScrolling }) {
  return (
    rowData && (
      <Link to={`/workorders/${rowData.id}`}>
        <div style={sheet.cellContainer}>
          <div style={sheet.photoWrapper}></div>
          <div>
            <Typography display="block" variant="h6">
              {rowData.multiday && `[MULTI ${rowData.schedule_id}]`}{' '}
              {rowData.customer.name}
            </Typography>
          </div>
        </div>
      </Link>
    )
  )
}

let _renderTotalColumn = function({ rowData, rowIndex, isScrolling }) {
  if (!rowData) return 0
  let subtotal = rowData.workorderitems.reduce(
    (acc, woi) => acc + discountedCharge(woi),
    0,
  )
  let tax = rowData.customer.taxable
    ? rowData.customer.taxitem.rate * subtotal
    : 0
  let total = subtotal + tax
  return (
    rowData && (
      <div style={sheet.cellContainer}>
        <div>
          <Typography display="block" variant="h6">
            {!rowData.multiday && rowData.is_billable ? (
              formatMoneyStandard(total)
            ) : (
              <>&#8212;</>
            )}
          </Typography>
        </div>
      </div>
    )
  )
}

let _renderCityColumn = ({ rowData }) =>
  rowData && (
    <div>
      <Typography variant="subtitle2" display="block">
        {rowData.city && rowData.city.name}
      </Typography>
      <Typography variant="caption" color="textSecondary" display="block">
        {rowData.street1}
      </Typography>
    </div>
  )

export default WorkordersList
