import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import shouldUpdate from 'recompose/shouldUpdate'
import _ from 'lodash'

let CellContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 5px;
`
let CellTextContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  flex: 1 0 auto;
  justify-content: align;
  ${p =>
    !p.multiline &&
    `
  flex-flow: row nowrap; align-items: center;
  `}
`
let CellText = styled.div`
  margin-right: 15px;
`
let IconContainer = styled.div`
  margin-right: 20px;
`

class TableCell extends React.Component {
  static propTypes = {
    icon: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    text: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
    subText: PropTypes.element,
    align: PropTypes.oneOf([
      'flex-start',
      'flex-end',
      'center',
      'space-between',
      'space-around',
    ]),
    multiline: PropTypes.bool,
    textStyles: PropTypes.object,
  }
  static defaultProps = {
    multiline: true,
    justifyContent: 'flex-start',
    textStyles: {},
    subTextStyles: {},
  }
  render() {
    const {
      style,
      icon,
      text,
      subText,
      preText,
      multiline,
      textStyles,
    } = this.props
    return (
      <CellContainer style={style}>
        {icon ? this._renderIcon(icon) : null}
        <CellTextContainer multiline={multiline}>
          {preText ? this._renderPreText(preText) : null}
          <CellText style={textStyles}>{text}</CellText>
          {subText ? this._renderSubText(subText) : null}
        </CellTextContainer>
      </CellContainer>
    )
  }

  _renderIcon = icon => {
    return <IconContainer>{icon}</IconContainer>
  }
  _renderSubText = subText => {
    const {subTextStyles} = this.props
    return <div style={subTextStyles}>{subText}</div>
  }
  _renderPreText = preText => {
    const {preTextStyles} = this.props
    return <div style={preTextStyles}>{preText}</div>
  }
}

export default shouldUpdate((props, nextProps) => {
  const shouldUpdate = !_.isEqual(props, nextProps)
  return shouldUpdate
})(TableCell)
