import styled from "styled-components"
import theme from "../styles/theme"
import colors from "../styles/colors"

export let RainbowGlowButton = styled.button`
 & {
    width: 100%;
    height: 20px;
    border: none;
    outline: none;
    color: #fff;
    background: #013767;
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 10px;
    font-weight: bold;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.9)
}

&:before {
    content: '';
    background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
    position: absolute;
    top: -2px;
    left:-2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: glowing 20s linear infinite;
    opacity: 0;
    transition: opacity .3s ease-in-out;
    border-radius: 10px;
}

&:active {
    color: #000
}

&:active:after {
    background: transparent;
}

&:hover:before {
    opacity: 1;
}

&:after {
    z-index: -1;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: #111;
    left: 0;
    top: 0;
    border-radius: 10px;
}

@keyframes glowing {
    0% { background-position: 0 0; }
    50% { background-position: 400% 0; }
    100% { background-position: 0 0; }
}
`

export let RunningBorderButton = styled(({ children, ...props }) => <button {...props}>
  {children}
  <span></span>
  <span></span>
  <span></span>
  <span></span>
</button>)`
&{
    width:200px;
    height:70px;
    background: linear-gradient(to left top, ${colors.bethanyBlue400} 10%, ${colors.bethanyBlue300} 100%);
    border-style: none;
    color:#fff;
    font-size: 20px;
    letter-spacing: 3px;
    font-weight: 600;
    outline: none;
    cursor: pointer;
    position: relative;
    padding: 0px;
    overflow: hidden;
    transition: all .5s;
    box-shadow: 0px 1px 2px rgba(0,0,0,.2);
}
& span{
    position: absolute;
    display: block;
}
& span:nth-child(1){
    height: 3px;
    width:200px;
    top:0px;
    left:-200px;
    background: linear-gradient(to right, rgba(0,0,0,0), #f6e58d);
    border-top-right-radius: 1px;
    border-bottom-right-radius: 1px;
    animation: span1 2s linear infinite;
    animation-delay: 1s;
}

@keyframes span1{
    0%{
        left:-200px
    }
    100%{
        left:200px;
    }
}
& span:nth-child(2){
    height: 70px;
    width: 3px;
    top:-70px;
    right:0px;
    background: linear-gradient(to bottom, rgba(0,0,0,0), #f6e58d);
    border-bottom-left-radius: 1px;
    border-bottom-right-radius: 1px;
    animation: span2 2s linear infinite;
    animation-delay: 2s;
}
@keyframes span2{
    0%{
        top:-70px;
    }
    100%{
        top:70px;
    }
}
& span:nth-child(3){
    height:3px;
    width:200px;
    right:-200px;
    bottom: 0px;
    background: linear-gradient(to left, rgba(0,0,0,0), #f6e58d);
    border-top-left-radius: 1px;
    border-bottom-left-radius: 1px;
    animation: span3 2s linear infinite;
    animation-delay: 3s;
}
@keyframes span3{
    0%{
        right:-200px;
    }
    100%{
        right: 200px;
    }
}

& span:nth-child(4){
    height:70px;
    width:3px;
    bottom:-70px;
    left:0px;
    background: linear-gradient(to top, rgba(0,0,0,0), #f6e58d);
    border-top-right-radius: 1px;
    border-top-left-radius: 1px;
    animation: span4 2s linear infinite;
    animation-delay: 4s;
}
@keyframes span4{
    0%{
        bottom: -70px;
    }
    100%{
        bottom:70px;
    }
}

&:hover{
    transition: all .5s;
    transform: rotate(-3deg) scale(1.1);
    box-shadow: 0px 3px 5px rgba(0,0,0,.4);
}
&:hover span{
    animation-play-state: paused;
}
`

export let PulseButton = styled.button`
& {
	margin: 15px auto;
	font-size: 20px;
	color: #ffffff;
  font-weight: bold;
	cursor: pointer;
	border-radius: 100px;
	padding: 15px 20px;
	border: 0px solid #000;		
	}

/* Initiate Auto-Pulse animations */
& {
	animation: borderPulse 1000ms infinite ease-out, colorShift 10000ms infinite ease-in;
}

/* Initiate color change for pulse-on-hover */
button.pulse-button-hover {
	animation: colorShift 10000ms infinite ease-in;
}

/* Continue animation and add shine on hover */
&:hover,
&:focus {
	animation: hoverShine 200ms;
  box-shadow: 0px 0px 10px 10px rgb(255,215,0,1), 0px 0px 25px 25px rgba(255,215,0,0);
  background: ${colors.bethanyBlue400}
}

/* Declate color shifting animation */
@keyframes colorShift {
	0%, 100% {
			background: ${colors.bethanyBlue400};
	}
	50% {
		background: ${colors.bethanyBlue300};
	}
}

/* Declare border pulse animation */
@keyframes borderPulse {
  0% {
    box-shadow: inset 0px 0px 0px 5px rgba(255, 255, 255,.4), 0px 0px 0px 0px rgba(255,255,255,1);
  }
  100% {
    box-shadow: inset 0px 0px 0px 3px rgba(117, 117, 255,.2), 0px 0px 0px 10px rgba(255,255,255,0);
  }
}

/* Declare shine on hover animation */
@keyframes hoverShine {
	0%{
		background-image: linear-gradient(135deg, rgba(255,255,255,.4) 0%, rgba(255,255,255,0) 50%, rgba(255,255,255,0) 100%);
	}
	50%{
		background-image: linear-gradient(135deg, rgba(255,255,255,0) 0%, rgba(255,255,255,.4) 50%, rgba(255,255,255,0) 100%);
	}
	100%{
		background-image: linear-gradient(135deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 50%, rgba(255,255,255,.4) 100%);
	}
}
`
