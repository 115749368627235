// @flow
import React, {useEffect} from 'react'
import _ from 'lodash'
import 'typeface-roboto'
import CssBaseline from '@mui/material/CssBaseline'
import {LocalizationProvider} from '@mui/lab'
import {StylesProvider} from '@mui/styles'
import MomentUtils from '@date-io/moment'
// for @mui/lab config
import AdapterDateFns from '@mui/lab/AdapterDateFns'

import {PRThemeProvider} from './styles/theme'

import {graphql} from 'graphql'
import 'graphiql/graphiql.css'
import GraphiQL from 'graphiql'
import schema, {
  dataIdFromObject,
  getCacheRedirectsFromSchema,
  createEntityLoader,
} from './schema'
import {ApolloClient} from 'apollo-client'
import {ApolloProvider} from 'react-apollo'
import {ApolloProvider as ApolloHooksProvider} from '@apollo/react-hooks'
import {ApolloLink, Observable} from 'apollo-link'
import {onError} from 'apollo-link-error'
import {InMemoryCache} from 'apollo-cache-inmemory'
import {persistCache} from 'apollo-cache-persist'
import {print} from 'graphql/language/printer'
import {Provider} from 'react-redux'
import store from './store'
import {TunnelProvider} from 'react-tunnels'

import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
  useLocation,
} from 'react-router-dom'
import {NavigationProvider} from './components/Navigator'

import {auth} from './security'
import AppHandler from './AppHandler'
import Login from './screens/Login/'
import Dashboard from './screens/Dashboard'
import Customers from './screens/Customers'
import Accounts from './screens/Customers/Accounts'
import Account from './screens/Account/Account'
// import Feedbacks from './screens/Feedbacks'
import Inventory from './screens/Inventory'
import InventoryItem, {NewInventoryItem} from './screens/InventoryItem'
import Requisitions from './screens/Requisitions'
import Requisition from './screens/Requisition'
import PurchaseOrders from './screens/PurchaseOrders'
import PurchaseOrder from './screens/PurchaseOrder'
import StatisticsYearly from './screens/Statistics/StatisticsYearly'
import StatisticsWeekly from './screens/Statistics/StatisticsWeekly'
import StatisticsRoute from './screens/Statistics/StatisticsRoute'
import RouterunsStatus from './screens/RouterunsStatus'
import Employees from './screens/Employees'
import Ar from './screens/Ar'
import RouteRunMiles from './screens/RouteRuns/RouteRunMiles'
import StatisticsWeeklySales from './screens/Statistics/StatisticsWeeklySales'
import MoveSchedules from './screens/Schedules/MoveSchedules'
import Pricing from './screens/Pricing'
import MapRoute from './screens/MapRoute'
import StatisticsCommissions from './screens/Statistics/StatisticsCommissions'
import Dispatch from './screens/RouteRuns/Dispatch'
import {QueryClient, QueryClientProvider, useQuery} from 'react-query'
import Schedules from './screens/Schedules'
import {DndProvider} from 'react-dnd'
import {HTML5Backend} from 'react-dnd-html5-backend'
import {ReactQueryDevtools} from 'react-query/devtools'
import Diagnostics from './screens/Diagnostics'
import Workorder from './screens/Workorder'
import Workorders from './screens/Workorders'
import ManageWorkorders from './screens/Workorders/ManageWorkorders'
import Reminders from './screens/Customer/Reminders'
import StatisticsTips from './screens/Statistics/StatisticsTips'
import ActivityLog from './screens/ActivityLog'
import FeedbackReport from './screens/Reports/Feedbacks'
import EvaluationReport from './screens/Reports/Evaluations'
import RecordIncome from './screens/Record/RecordIncome'
import RecordPayments from './screens/Record/RecordPayments'
import Settings from './screens/Settings'
import ProductionInTaxAuthorityReport from './screens/Reports/ProductionInTaxAuthority'
import JobRequirements, {
  JobRequirement,
  NewJobRequirement,
} from './screens/Inventory/JobRequirements'
import {fetchAPI} from './schema/utils'
import BUILD_NUMBER from './constants/BUILD_NUMBER'
import TrackedSales from './screens/Statistics/TrackedSales'
import Reports from './screens/Statistics'
import Heatmap from './screens/Reports/Heatmap'
import SchedulerReport from './screens/Reports/SchedulerReport'
import LostJobs from './screens/Reports/LostJobs'
import UserActivity from './screens/Reports/UserActivity'
import Contacts from './screens/Customers/Contacts'
import Contact from './screens/Contact/Contact'
import EstimateReport from './screens/Reports/EstimatesReport'

let graphqlQuery = ({query, variables, operationName}) =>
  graphql(
    schema,
    query,
    null,
    {entityLoader: createEntityLoader()},
    variables,
    operationName,
  )
// graphql({
//   schema,
//   source: query,
//   rootValue: null,
//   contextValue: {entityLoader: createEntityLoader()},
//   variables,
//   operationName,
// })

let link = new ApolloLink(
  operation =>
    new Observable(observer => {
      const {query, ...args} = operation
      graphqlQuery({query: print(query), ...args})
        // attach the raw response to the context for usage
        .then(response => {
          operation.setContext({response})
          return response
        })
        .then(result => {
          observer.next(result)
          observer.complete()
          return result
        })
        .catch(err => {
          observer.error(err)
        })
    }),
)
let errorHandler = onError(({graphQLErrors, networkError}) => {
  if (graphQLErrors)
    graphQLErrors.map(({message, locations, path, source}) => {
      // eslint-disable-next-line no-console
      // console.log(source.body)
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      )
    })

  // eslint-disable-next-line
  if (networkError) console.log(`[Network error]: ${networkError}`)
})
let cache = new InMemoryCache({
  entityIdForNode: dataIdFromObject,
  addTypename: true,
  resolverRedirects: getCacheRedirectsFromSchema({schema}),
})

const PrivateRoute = ({component: Component, render, noMenuBar, ...rest}) => (
  <Route
    {...rest}
    render={props =>
      !auth.isLoggedIn(store.getState()) ? (
        <Redirect
          to={{pathname: '/login', state: {nextPathname: props.location}}}
        />
      ) : _.isFunction(render) ? (
        render(props)
      ) : (
        <AppHandler {...props} noMenuBar={noMenuBar}>
          <Component {...props} />
        </AppHandler>
      )
    }
  />
)

let queryClient = new QueryClient()

const ViewportManager = () => {
  const location = useLocation()
  console.log(location)
  const screenSize = window.screen.width

  useEffect(() => {
    const currentUrl = location.pathname

    const deviceWidth = document.createElement('meta')
    deviceWidth.name = 'viewport'
    deviceWidth.content = 'width=device-width, initial-scale=1'
    deviceWidth.id = 'viewport-meta-tag'

    const smartPhone = document.createElement('meta')
    smartPhone.name = 'viewport'
    smartPhone.content = 'width=980, initial-scale=1'
    smartPhone.id = 'viewport-meta-tag2'

    const addViewportTag = tag => {
      document.head.appendChild(tag)
    }

    const removeViewportTag = tag => {
      const meta = document.getElementById(tag.id)
      if (meta) {
        document.head.removeChild(meta)
      }
    }

    if (currentUrl === '/login') {
      addViewportTag(deviceWidth)
      removeViewportTag(smartPhone)
    } else {
      if (screenSize <= 980) {
        addViewportTag(smartPhone)
        removeViewportTag(deviceWidth)
      } else {
        addViewportTag(deviceWidth)
        removeViewportTag(smartPhone)
      }
    }

    return () => {
      removeViewportTag(deviceWidth)
      removeViewportTag(smartPhone)
    }
  }, [location.pathname, screenSize])

  return null // This component does not render anything
}

let Providers = ({children}) => {
  let [client, setClient] = React.useState(null)

  React.useEffect(() => {
    async function setupClient() {
      await persistCache({
        storage: window.localStorage,
        cache,
      })

      const client = new ApolloClient({
        link: errorHandler.concat(link),
        cache,
        connectToDevTools: true,
        defaultOptions: {
          query: {fetchPolicy: 'cache-and-network'},
          watchQuery: {fetchPolicy: 'cache-and-network'},
        },
      })
      setClient(client)
    }
    setupClient()
  }, [])

  return (
    client && (
      <StylesProvider injectFirst>
        <PRThemeProvider>
          <DndProvider backend={HTML5Backend}>
            <QueryClientProvider client={queryClient}>
              <ApolloProvider client={client}>
                <ApolloHooksProvider client={client}>
                  <LocalizationProvider dateAdapter={MomentUtils}>
                    <Provider store={store}>
                      <BrowserRouter>
                        <ViewportManager />
                        <TunnelProvider>
                          <NavigationProvider>{children}</NavigationProvider>
                        </TunnelProvider>
                      </BrowserRouter>
                    </Provider>
                  </LocalizationProvider>
                </ApolloHooksProvider>
              </ApolloProvider>
              <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
          </DndProvider>
        </PRThemeProvider>
      </StylesProvider>
    )
  )
}

let App = () => (
  <Providers>
    <CssBaseline />
    <div>
      <Switch>
        <Route path="/login" component={Login} />
        <Route
          path="/graphiql"
          render={() => (
            <div style={{height: '100vh'}}>
              <GraphiQL fetcher={graphqlQuery} schema={schema} />
            </div>
          )}
        />
        <PrivateRoute path="/" exact component={Dashboard} />
        <PrivateRoute path="/customers" component={Customers} />
        <PrivateRoute path="/ar" component={Ar} />
        <PrivateRoute path="/accounts" exact component={Accounts} />
        <PrivateRoute path="/accounts/:id" component={Account} />
        <PrivateRoute path="/contacts" exact component={Contacts} />
        <PrivateRoute path="/contacts/:id" component={Contact} />
        {/* <PrivateRoute path="/feedbacks" component={Feedbacks} /> */}
        <PrivateRoute path="/inventory" exact component={Inventory} />
        <PrivateRoute path="/inventory/add" component={NewInventoryItem} />
        <PrivateRoute path="/inventory/:id" component={InventoryItem} />
        <PrivateRoute path="/reminders" exact component={Reminders} />
        <PrivateRoute path="/requisitions" exact component={Requisitions} />
        <PrivateRoute path="/requisitions/:id" component={Requisition} />
        <PrivateRoute path="/pos" exact component={PurchaseOrders} />
        <PrivateRoute path="/pos/:id" component={PurchaseOrder} />
        <PrivateRoute path="/statistics/year" component={StatisticsYearly} />
        <PrivateRoute path="/statistics/week" component={StatisticsWeekly} />
        <PrivateRoute
          path="/statistics/weeklysales"
          component={StatisticsWeeklySales}
        />
        <PrivateRoute path="/statistics/route" component={StatisticsRoute} />
        <PrivateRoute
          path="/statistics/commissions"
          component={StatisticsCommissions}
        />
        <PrivateRoute path="/statistics/tips" component={StatisticsTips} />
        <PrivateRoute path="/employees" component={Employees} />
        <PrivateRoute path="/routeruns/miles" component={RouteRunMiles} />
        <PrivateRoute path="/routeruns/dispatch" component={Dispatch} />
        <PrivateRoute
          path="/routerunsummary"
          component={RouterunsStatus}
          noMenuBar
        />
        <PrivateRoute path="/moveschedules" component={MoveSchedules} />
        <PrivateRoute path="/pricing" component={Pricing} />
        <PrivateRoute path="/maproute" component={MapRoute} />
        <PrivateRoute path="/schedules" component={Schedules} />
        <PrivateRoute path="/diagnostics" component={Diagnostics} />
        <PrivateRoute path="/workorders" exact component={Workorders} />
        <PrivateRoute path="/manageworkorders" component={ManageWorkorders} />
        <PrivateRoute path="/workorders/:id" component={Workorder} />
        <PrivateRoute path="/activitylog/:type" component={ActivityLog} />
        <PrivateRoute
          path="/reports/feedbacksbytype"
          component={FeedbackReport}
        />
        <PrivateRoute
          path="/reports/evaluationsbyuser"
          component={EvaluationReport}
        />
        <PrivateRoute
          path="/reports/productionintaxauthority"
          component={ProductionInTaxAuthorityReport}
        />
        <PrivateRoute path="/reports/trackedsales" component={TrackedSales} />
        <PrivateRoute
          path="/reports/schedulerreport"
          component={SchedulerReport}
        />
        <PrivateRoute path="/reports/heatmap" component={Heatmap} />
        <PrivateRoute path="/reports/lostjobs" component={LostJobs} />
        <PrivateRoute path="/reports/useractivity" component={UserActivity} />
        <PrivateRoute path="/reports/estimates" component={EstimateReport} />
        <PrivateRoute path="/reports" component={Reports} exact />
        <PrivateRoute path="/recordincome" component={RecordIncome} />
        <PrivateRoute path="/recordpayments" component={RecordPayments} />
        <PrivateRoute path="/settings" component={Settings} />
        <PrivateRoute path={`/jobrequirements`} component={JobRequirements} />
      </Switch>
    </div>
  </Providers>
)

export default App
