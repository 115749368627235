import React, {Component} from 'react'
import {Link} from 'react-router-dom'

class PRLink extends Component {
  static propTypes = Link.propTypes;

  render() {
    return <Link {...this.props} />
  }
}
export default PRLink

