import React, {Component} from 'react'
import styled from 'styled-components'
import {Column} from 'react-virtualized'
import {graphql} from 'react-apollo'
import compose from 'lodash/flowRight'
import gql from 'graphql-tag'

import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction'
import ListSubheader from '@mui/material/ListSubheader'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import Divider from '@mui/material/Divider'
import MenuItem from '@mui/material/MenuItem'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Avatar from '@mui/material/Avatar'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import InputBase from '@mui/material/Input'

import Delete from '@mui/icons-material/Delete'
import DoneAll from '@mui/icons-material/DoneAll'
import ProcessIcon from '@mui/icons-material/DataUsage'
import EditIcon from '@mui/icons-material/Edit'
import CloseIcon from '@mui/icons-material/Done'
import SearchIcon from '@mui/icons-material/Search'
import ClearIcon from '@mui/icons-material/Close'
import AddIcon from '@mui/icons-material/Add'

import NumberInput from '../components/NumberInput'
import DeleteDialog from '../components/DeleteDialog'
import DataBlock from '../components/DataBlock'
import CircularProgress from '../components/CircularProgress'
import PaginationContainer from '../components/PaginationContainer'
import TableList from '../components/Table/TableList'
import {NavigateBack, RedirectBack} from '../components/Navigator/index'
import {Spacing} from '../components/Layout'
import {formatMoneyStandard} from '../utils/moneyFormatter'
import UsersSelect from '../components/UsersSelect'
import SelectMenu from '../components/ToolbarDropDownMenu'
import {
  DetailToolbar,
  ToolbarLeft,
  CloseButton,
  ToolbarTitle,
  ToolbarRight,
  ToolbarIconMenu,
  DeleteMenuItem,
} from '../components/Toolbar'

let Content = styled.div`
  padding: 16px;
`
let InfoSection = styled.div`
  display: flex;
  justify-content: center;
`
let CounterWrapper = styled.div`
  position: relative;
`
let ProgressAvatar = styled(Avatar)`
  background-color: transparent;
  color: ${({theme}) => theme.muiTheme.palette.grey[600]};
`
let ProgressCounter = ({value, children}) => (
  <CounterWrapper>
    <CircularProgress percent={0.4} size={64} value={value} color="default">
      <ProgressAvatar>{children}</ProgressAvatar>
    </CircularProgress>
  </CounterWrapper>
)
let LeftIcon = styled.div`
  margin-right: ${({theme}) => theme.muiTheme.spacing(1)}px;
`
let calcUnitCost = ({quantity, amount}) => (amount * 100) / quantity / 100
let PurchaseOrderToolbar = ({
  loading,
  processing,
  po,
  onClose,
  onGoBack,
  onOpen,
  onProcess,
  onDelete,
}) =>
  loading ? (
    <DetailToolbar>
      <ToolbarLeft>
        <NavigateBack defaultBack="/pos" marker="PurchaseOrderBack">
          <CloseButton />
        </NavigateBack>
      </ToolbarLeft>
      <ToolbarTitle>Loading</ToolbarTitle>
    </DetailToolbar>
  ) : (
    <DetailToolbar>
      <ToolbarLeft>
        <NavigateBack
          defaultBack="/pos"
          marker="PurchaseOrderBack"
          onGoBack={onGoBack}
        >
          <CloseButton />
        </NavigateBack>
      </ToolbarLeft>
      <ToolbarTitle>
        {!loading ? 'Purchase Order ' + po.id : 'Loading'}
      </ToolbarTitle>
      <ToolbarRight>
        {po.closed ? (
          <Button onClick={onOpen}>Open</Button>
        ) : (
          <React.Fragment>
            {processing ? (
              <Button onClick={onProcess}>
                <LeftIcon>
                  <EditIcon />
                </LeftIcon>
                Edit
              </Button>
            ) : (
              <Button onClick={onProcess}>
                <LeftIcon>
                  <ProcessIcon />
                </LeftIcon>
                Process
              </Button>
            )}
            <Button onClick={onClose}>
              <LeftIcon>
                <CloseIcon />
              </LeftIcon>
              Close
            </Button>
          </React.Fragment>
        )}
        <ToolbarIconMenu>
          <DeleteMenuItem onClick={onDelete} />
        </ToolbarIconMenu>
      </ToolbarRight>
    </DetailToolbar>
  )

let AvatarContainer = styled.div`
  display: flex;
  align-items: center;
`
let ItemActions = styled(ListItemSecondaryAction)`
  display: flex;
  align-items: center;
`
let POItem = ({
  item,
  unitCost,
  closed,
  onDelete,
  onQuantityChange,
  onFilledChange,
  processing,
  onFillAll,
}) => (
  <ListItem>
    <Grid container spacing={2} alignItems="center">
      <Grid item>
        <ListItemAvatar>
          <AvatarContainer>
            <ProgressCounter
              value={(item.quantity_filled / item.quantity) * 100}
            >
              {item.quantity}
            </ProgressCounter>
          </AvatarContainer>
        </ListItemAvatar>
      </Grid>
      <Grid item>
        <ListItemText primary={item.quantity_filled} secondary="Filled" />
      </Grid>
      <Grid item>
        <ListItemText
          primary={
            <Typography variant="body2">
              {item.description + ' - ' + formatMoneyStandard(unitCost)}
            </Typography>
          }
          secondary={formatMoneyStandard(item.amount)}
        />
      </Grid>
      {closed ? null : processing ? (
        <ItemActions>
          <NumberInput
            key="processing"
            defaultValue={item.quantity_filled}
            onChange={onFilledChange}
            min={0}
          >
            <InputLabel>Filled</InputLabel>
          </NumberInput>
          <IconButton onClick={onFillAll}>
            <DoneAll />
          </IconButton>
        </ItemActions>
      ) : (
        <ItemActions>
          <NumberInput
            key="requesting"
            defaultValue={item.quantity}
            onChange={quantity =>
              onQuantityChange({
                id: item.id,
                unit_cost: unitCost,
                quantity,
              })
            }
            min={1}
          >
            <InputLabel>Quantity</InputLabel>
          </NumberInput>
          <IconButton onClick={onDelete}>
            <Delete />
          </IconButton>
        </ItemActions>
      )}
    </Grid>
  </ListItem>
)

let lowInventoryFragment = gql`
  fragment LowInventoryDetails on InventoryItems {
    id
    description
    min
    max
    count
    unit_cost
    merchant {
      id
      name
    }
  }
`
let lowInventoryQuery = gql`
  query lowInventory(
    $cursor: String = "-1"
    $limit: Int = 25
    $filters: InventoryItemFilters
  ) {
    inventory: allInventoryItems(
      cursor: $cursor
      limit: $limit
      filters: $filters
      orderBy: description_ASC
    ) {
      pageInfo {
        count
        total
      }
      edges {
        cursor
        edge {
          ...LowInventoryDetails
        }
      }
    }
  }
  ${lowInventoryFragment}
`
let Input = styled(InputBase)`
  align-items: center;
`
let Clear = styled(ClearIcon)`
  align-self: center;
  visibility: ${({hidden}) => (hidden ? 'hidden' : 'visible')};
  cursor: pointer;
`
class InventorySearch extends Component {
  static defaultProps = {
    onChange: () => {},
  }
  state = {search: ''}
  clearSearch = () => {
    this.setState({search: ''})
    this.props.onChange('')
  }
  handleChange = e => {
    this.setState({search: e.target.value})
    this.props.onChange(e.target.value)
  }
  render() {
    let {search} = this.state
    return (
      <FormControl variant="standard">
        <Input
          value={search}
          onChange={this.handleChange}
          startAdornment={<SearchIcon />}
          endAdornment={<Clear onClick={this.clearSearch} hidden={!search} />}
          placeholder="Search for inventory"
        />
      </FormControl>
    );
  }
}
let InventoryContainer = styled.div`
  height: 400px;
  display: flex;
  flex-direction: column;
`
let StockedSelect = styled(SelectMenu)`
  min-width: 125px;
`
class InventorySelect extends Component {
  state = {search: '', status: 'all_not_ordered'}
  handleSearch = search => this.setState({search})
  handleStatus = e => {
    this.setState({status: e.target.value})
    if (this.loader) {
      this.loader.resetLoadMoreRowsCache(true)
    }
  }
  getFiltersFromStatus = status => {
    switch (status) {
      case 'all_not_ordered':
        return {
          reorder: status,
        }
      case 'not_stocked':
        return {
          status,
        }
      case 'all':
      default:
        return {}
    }
  }
  render() {
    let {onSelect} = this.props
    let {search, status} = this.state

    return (
      <React.Fragment>
        <List subheader={<ListSubheader>Add Items</ListSubheader>}>
          <ListItem>
            <Spacing>
              <StockedSelect
                value={status}
                onChange={this.handleStatus}
                autoWidth
              >
                <MenuItem value="all_not_ordered">Low Stock</MenuItem>
                <MenuItem value="not_stocked">Out of Stock</MenuItem>
                <MenuItem value="all">All</MenuItem>
              </StockedSelect>
              <InventorySearch onChange={this.handleSearch} />
            </Spacing>
          </ListItem>
        </List>
        <PaginationContainer
          query={lowInventoryQuery}
          variables={{
            limit: 25,
            cursor: '-1',
            filters: {
              ...this.getFiltersFromStatus(status),
              description: search,
            },
          }}
        >
          {({inventory, loadMore, isRowLoaded}) => (
            <InventoryContainer>
              <TableList
                data={inventory ? inventory.edges : []}
                infinite
                loadMoreRows={loadMore}
                rowCount={inventory ? inventory.pageInfo.total : 9999}
                isRowLoaded={isRowLoaded}
                loaderRef={r => {
                  this.loader = r
                }}
              >
                <Column
                  dataKey="description"
                  label="Description"
                  width={0}
                  flexGrow={1}
                />
                <Column
                  dataKey="merchant"
                  label="Merchant"
                  width={160}
                  cellRenderer={({cellData}) => cellData && cellData.name}
                />
                <Column dataKey="min" label="Min" width={35} />
                <Column dataKey="max" label="Max" width={35} />
                <Column dataKey="count" label="Count" width={40} />
                <Column
                  dataKey="id"
                  width={60}
                  cellRenderer={({rowData}) => (
                    <IconButton
                      onClick={() => {
                        onSelect(rowData)
                      }}
                    >
                      <AddIcon />
                    </IconButton>
                  )}
                />
              </TableList>
            </InventoryContainer>
          )}
        </PaginationContainer>
      </React.Fragment>
    )
  }
}

let poFrag = gql`
  fragment PODetails on POs {
    id
    closed
    user {
      id
      first_name
      last_name
    }
    poitems {
      id
      inventoryitem_id
      quantity
      quantity_filled
      amount
      description
    }
  }
`
let poQuery = gql`
  query POQuery($id: String!) {
    pOs: pOs(id: $id) {
      ...PODetails
    }
  }
  ${poFrag}
`
class PurchaseOrder extends Component {
  state = {
    showDelete: false,
    goBack: false,
    processing: false,
    selectedUserId: null,
  }
  handleQuantityChange = ({id, unit_cost, quantity}) => {
    if (quantity > 0) {
      this.props.update({
        id,
        quantity,
        amount: (quantity * (unit_cost * 100)) / 100,
      })
    }
  }
  handleFill = ({id}) => v => {
    this.props.updateFilled({id, quantity_filled: v ? v : 0})
  }
  handleAddInventory = item => {
    let existing = this.props.data.pOs.poitems.find(
      ({inventoryitem_id}) => inventoryitem_id === item.id,
    )
    let quantity = item.max - item.count
    if (existing) {
      this.handleQuantityChange({id: existing.id, unit_cost: item.unit_cost})(
        quantity,
      )
    } else {
      this.props.addPOItems(this.props.data.pOs.id, [
        {
          quantity,
          quantity_filled: 0,
          inventoryitem_id: item.id,
          description: item.description,
          amount: (quantity * (item.unit_cost * 100)) / 100,
        },
      ])
    }
  }
  toggleDelete = () => {
    this.setState(({showDelete}) => ({
      showDelete: !showDelete,
    }))
  }
  handleDelete = async () => {
    await this.props.onDelete(this.props.data.pOs.id)
    this.setState({goBack: true})
  }
  handleOpen = () => {
    this.props.onOpenPO(this.props.data.pOs.id)
  }
  handleClose = () => {
    this.props.onClosePO(this.props.data.pOs.id)
  }
  handleProcess = () => {
    this.setState(({processing}) => ({processing: !processing}))
  }
  handleUserChange = user => {
    if (user) {
      this.props.changeUser({id: this.props.data.pOs.id, user})
      this.setState({selectedUserId: user.id})
    }
  }
  onGoBack = () => {
    // If we don't have any items then delete
    if (this.props.data.pOs.poitems.length < 1) {
      this.props.onDelete(this.props.data.pOs.id)
    }
  }
  render() {
    let {data, onDeleteItem} = this.props
    if (this.state.goBack) {
      return <RedirectBack defaultBack="/pos" marker="PurchaseOrderBack" />
    }
    return (
      <Card>
        <PurchaseOrderToolbar
          loading={data.loading}
          processing={this.state.processing}
          po={data.pOs}
          onClose={this.handleClose}
          onOpen={this.handleOpen}
          onProcess={this.handleProcess}
          onDelete={this.toggleDelete}
          onGoBack={this.onGoBack}
        />
        <DeleteDialog
          title="Delete Purchase Order?"
          message="Are you sure you want to delete this Purchase Order?"
          isOpen={this.state.showDelete}
          onCancel={this.toggleDelete}
          onConfirm={this.handleDelete}
        />
        <Content>
          {!data.loading ? (
            <React.Fragment>
              <InfoSection>
                <DataBlock
                  label="Status"
                  data={
                    <Typography variant="h3">
                      {data.pOs.closed ? 'Closed' : 'Open'}
                    </Typography>
                  }
                />
                <DataBlock
                  label="Total Cost"
                  data={
                    <Typography variant="h3">
                      {formatMoneyStandard(
                        data.pOs.poitems.reduce(
                          (acc, {amount}) => acc + amount,
                          0,
                        ),
                      )}
                    </Typography>
                  }
                />
                {!data.pOs.closed && !this.state.processing ? (
                  <DataBlock
                    label="Created By"
                    data={
                      <UsersSelect
                        selectedUserId={
                          this.state.selectedUserId || data.pOs.user.id
                        }
                        onChange={this.handleUserChange}
                      />
                    }
                  />
                ) : (
                  <DataBlock
                    label="For"
                    data={
                      <Typography variant="h3">
                        {data.pOs.user.first_name +
                          ' ' +
                          data.pOs.user.last_name}
                      </Typography>
                    }
                  />
                )}
              </InfoSection>
              <List>
                {data.pOs.poitems.map(item => (
                  <React.Fragment key={item.id}>
                    <POItem
                      item={item}
                      unitCost={calcUnitCost(item)}
                      onQuantityChange={this.handleQuantityChange}
                      onFilledChange={this.handleFill({id: item.id})}
                      onFillAll={() =>
                        this.handleFill({id: item.id})(item.quantity)
                      }
                      onDelete={() => onDeleteItem(item.id)}
                      processing={this.state.processing}
                      closed={data.pOs.closed}
                    />
                    <Divider />
                  </React.Fragment>
                ))}
              </List>
              {!data.pOs.closed && (
                <InventorySelect onSelect={this.handleAddInventory} />
              )}
            </React.Fragment>
          ) : (
            'Loading'
          )}
        </Content>
      </Card>
    )
  }
}
let deleteQuery = gql`
  mutation deletePOItem($id: String!) {
    deletePOItems(input: {id: $id}) {
      message
    }
  }
`
let deletePOQuery = gql`
  mutation deletePO($id: String!) {
    deletePOs(input: {id: $id}) {
      message
    }
  }
`
let updateItemQuery = gql`
  mutation updatePOItem($input: UpdatePOItemsInput) {
    updatePOItems(input: $input) {
      pOItems {
        id
        quantity
        amount
      }
    }
  }
`
let changeUserQuery = gql`
  mutation updatePOUser($input: UpdatePOsInput, $id: String!) {
    updatePOs(input: $input) {
      pOs {
        id
      }
      query {
        pOs(id: $id) {
          ...PODetails
        }
      }
    }
  }
  ${poFrag}
`
let updateItemFilledQuery = gql`
  mutation updatePOItemFilled($input: UpdatePOItemsInput) {
    updatePOItems(input: $input) {
      pOItems {
        id
        quantity_filled
      }
    }
  }
`
let updatePO = gql`
  mutation updatePO($input: UpdatePOsInput, $id: String!) {
    updatePOs(input: $input) {
      pOs {
        id
        closed
        total_items
      }
      query {
        pOs(id: $id) {
          ...PODetails
        }
      }
    }
  }
  ${poFrag}
`
let createPOsRes = attrs => ({
  __typename: 'Mutation',
  updatePOs: {
    __typename: 'UpdatePOsPayload',
    pOs: {
      __typename: 'POs',
      ...attrs,
    },
  },
})
let createItemRes = attrs => ({
  __typename: 'Mutation',
  updatePOItems: {
    __typename: 'UpdatePOItemsPayload',
    pOItems: {
      __typename: 'POItems',
      ...attrs,
    },
  },
})
export default compose(
  graphql(poQuery, {
    options: ({match}) => ({
      variables: {id: match.params.id},
    }),
  }),
  graphql(deleteQuery, {
    props: ({ownProps, mutate}) => ({
      onDeleteItem: id =>
        mutate({
          variables: {id},
          optimisticResponse: {
            __typename: 'Mutation',
            deletePOItems: {
              __typename: 'DeletePOItemsPayload',
              message: 'success',
            },
          },
          update: proxy => {
            let queryOptions = {
              query: poQuery,
              variables: {id: ownProps.match.params.id},
            }
            let data = proxy.readQuery(queryOptions)
            let poitems = data.pOs.poitems.filter(item => item.id !== id)
            proxy.writeQuery({
              ...queryOptions,
              data: {
                ...data,
                pOs: {...data.pOs, poitems},
              },
            })
          },
        }),
    }),
  }),
  graphql(updateItemQuery, {
    props: ({ownProps, mutate}) => ({
      update: input =>
        mutate({
          variables: {input},
          optimisticResponse: createItemRes(input),
        }),
    }),
  }),
  graphql(updateItemFilledQuery, {
    props: ({ownProps, mutate}) => ({
      updateFilled: input =>
        mutate({
          variables: {input},
          optimisticResponse: createItemRes(input),
        }),
    }),
  }),
  graphql(updatePO, {
    props: ({mutate, ownProps, ...restProps}) => ({
      updatePO: input =>
        mutate({
          variables: {input, id: input.id},
        }),
      addPOItems: (id, items) =>
        mutate({
          variables: {
            id,
            input: {
              id,
              poitems: {
                create: items.map(({description, ...rest}) => rest),
              },
            },
          },
          optimisticResponse: {
            updatePOs: {
              pOs: ownProps.data.pOs,
              query: {
                pOs: {
                  ...ownProps.data.pOs,
                  poitems: [
                    ...ownProps.data.pOs.poitems,
                    ...items.map((item, i) => ({
                      __typename: 'POItems',
                      id: 'temp' + id + ownProps.data.pOs.poitems.length + i,
                      ...item,
                    })),
                  ],
                },
              },
            },
          },
          update: (proxy, {data: {pOs}}) => {
            let q = {
              query: lowInventoryQuery,
              variables: {
                filters: {
                  reorder: 'all_not_ordered',
                  description: '',
                },
              },
            }
            let data = proxy.readQuery(q)
            let [edges] = [...data.inventory.edges]
              // Update edges for pagination container
              .sort(
                ({cursor: c1}, {cursor: c2}) =>
                  parseInt(c1, 10) - parseInt(c2, 10),
              )
              .reduce(
                ([acc, offset], {cursor, edge, ...rest}) => {
                  if (edge.id === items[0].inventoryitem_id) {
                    return [acc, offset + 1]
                  }
                  let newCursor = parseInt(cursor, 10) - offset
                  return [[...acc, {edge, cursor: newCursor, ...rest}], offset]
                },
                [[], 0],
              )
            let newData = {
              ...data,
              inventory: {
                ...data.inventory,
                pageInfo: {
                  ...data.inventory.pageInfo,
                  // update pageinfo for pagination container
                  total: data.inventory.pageInfo.count - items.length,
                  count: edges.length,
                },
                edges,
              },
            }
            proxy.writeQuery({...q, data: newData})
          },
        }),
      onClosePO: id =>
        mutate({
          variables: {input: {id, closed: true}, id},
          optimisticResponse: createPOsRes({
            id,
            closed: true,
          }),
        }),
      onOpenPO: id =>
        mutate({
          variables: {input: {id, closed: false}, id},
          optimisticResponse: createPOsRes({
            id,
            closed: false,
          }),
        }),
    }),
  }),
  graphql(changeUserQuery, {
    props: ({mutate}) => ({
      changeUser: ({id, user}) =>
        mutate({variables: {id, input: {id, user_id: user.id}}}),
    }),
  }),
  graphql(deletePOQuery, {
    props: ({mutate}) => ({
      onDelete: id => mutate({variables: {id}}),
    }),
  }),
)(PurchaseOrder)
