import React, { Component } from 'react'
import styled from 'styled-components'
import { graphql } from 'react-apollo'
import compose from 'lodash/flowRight'
import gql from 'graphql-tag'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import FormGroup from '@mui/material/FormGroup'
import ModifyQueryParams from '../../components/ModifyQueryParams'
import moment from 'moment'
import queryString from 'query-string'
import { formatMoneyStandard } from '../../utils/moneyFormatter'
import { DatePicker } from '@mui/lab'
import { LinearProgress, Typography } from '@mui/material'

let DashboardWrapper = styled.div`
  padding: 16px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 16px;
`

let BoldText = styled.div`
  font-weight: bold;
`

let TopCell = styled(TableCell).attrs(() => ({ classes: { root: 'root' } }))`
  &.root {
    background-color: #222;
    color: white;
  }
`

let VariedRow = styled(TableRow)`
  &:nth-of-type(odd) {
    background-color: #fafafa;
  }
`

class TheSelect extends Component {
  state = {
    start_date: moment()
      .subtract(1, 'year')
      .startOf('year')
      .format('YYYY-MM-DD'),
    end_date: moment()
      .endOf('year')
      .format('YYYY-MM-DD'),
  }

  render() {
    return (
      <Card>
        <CardContent>
          <FormGroup row>
            <DatePicker
              variant="filled"
              label="Start Date"
              value={moment.utc(this.state.start_date)}
              inputFormat="MMMM D, YYYY"
              onChange={d =>
                this.setState({ start_date: d.format('YYYY-MM-DD') })
              }
              renderInput={props => (
                <TextField {...props} margin="dense" size="small" />
              )}
            />
            <DatePicker
              variant="filled"
              label="End Date"
              value={moment.utc(this.state.end_date)}
              inputFormat="MMMM D, YYYY"
              onChange={d => this.setState({ end_date: d.format('YYYY-MM-DD') })}
              renderInput={props => (
                <TextField {...props} margin="dense" size="small" />
              )}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={() =>
                this.props.onClick(this.state.start_date, this.state.end_date)
              }
            >
              Go
            </Button>
          </FormGroup>
        </CardContent>
      </Card>
    )
  }
}

// let delinquents = ({type}) => type === 'Delinquent'
// let sales = ({type}) => type === 'Sales'
// let notSalesOrDeliqunets = ({type}) => !delinquents({type}) && !sales({type})
class StatisticsWeeklySales extends Component {
  state = {
    queryChanges: {},
    today: moment().format('YYYY-MM-DD'),
  }
  render() {
    let { data, location } = this.props
    // if (
    //   !data ||
    //   !data.allWeeklysalesstatistics ||
    //   data.allWeeklysalesstatistics.loading
    // ) {
    //   return null
    // }
    let statistics = data?.allWeeklysalesstatistics?.edges
    let params = queryString.parse(location.search)

    return (
      <DashboardWrapper>
        <Paper>
          <Typography variant="subtitle2" display="block" style={{ opacity: 0.5 }}>Calculation method: PAST: Invoice subtotal + uninvoiced multiday. TODAY + FUTURE: projected schedules + multiday workorders.</Typography>
          <Typography variant="subtitle2" display="block" style={{ opacity: 0.5 }}>Note that 1 time schedules that haven't been flipped to this year will not be included in projection</Typography>
          <TheSelect
            defaultSelected={{
              start_date: params.end_date,
              end_date: params.end_date,
            }}
            onClick={(start_date, end_date) =>
              this.setState({
                queryChanges: {
                  start_date: start_date,
                  end_date: end_date,
                },
              })
            }
          />
          <ModifyQueryParams query={this.state.queryChanges} />
          {data?.loading && <LinearProgress />}
          {statistics ? (
            <Table>
              <TableHead>
                <TableRow>
                  <TopCell>Year</TopCell>
                  <TopCell>Week</TopCell>
                  <TopCell>Sales</TopCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {statistics.map(({ edge: r }, i) => {
                  return (
                    <VariedRow key={r.id}>
                      <TableCell align="left">{r.year}</TableCell>
                      <TableCell align="left">
                        <BoldText>{r.week}</BoldText>
                      </TableCell>
                      <TableCell
                        align="left"
                        style={
                          r.projected === false
                            ? { color: 'black' }
                            : { color: 'red' }
                        }
                      >
                        {formatMoneyStandard(r.sales)}
                      </TableCell>
                    </VariedRow>
                  )
                })}
              </TableBody>
            </Table>
          ) : null}
        </Paper>
      </DashboardWrapper>
    )
  }
}
let query = gql`
  query statistics($filters: StatisticsWeeklySalesFilters) {
    allWeeklysalesstatistics(filters: $filters) {
      edges {
        edge {
          id
          week
          year
          sales
          projected
        }
      }
    }
  }
`
export default compose(
  graphql(query, {
    options: props => {
      let params = queryString.parse(props.location.search)

      return {
        variables: {
          filters: { start_date: params.start_date, end_date: params.end_date },
        },
      }
    },
  }),
)(StatisticsWeeklySales)
