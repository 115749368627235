import React, {useState} from 'react'
import {useQuery, useMutation} from '@apollo/react-hooks'
import styled from 'styled-components'
import gql from 'graphql-tag'
import moment from 'moment'

import {
  Paper,
  Typography,
  TextField,
  InputAdornment,
  Button,
  CircularProgress,
} from '@mui/material'
import {DatePicker} from '@mui/lab'
import {Save, Cancel} from '@mui/icons-material'

import {Space, Block} from '../../components/Layout'

let ThePaper = styled(Paper)`
  padding: 2em;
`

let Row = ({route, team, miles, onMiles, onCancel, disableCancel}) => (
  <>
    <div style={{width: '100px', alignSelf: 'center'}}>
      <Typography variant="subtitle1"> Route {route}</Typography>
    </div>
    <div align="left" style={{alignSelf: 'center', width: '300px'}}>
      <Typography variant="subtitle1">{team}</Typography>
    </div>
    <Space sizePx={16} inline />
    <TextField
      variant="filled"
      InputProps={{
        startAdornment: <InputAdornment position="start">Mi.</InputAdornment>,
      }}
      value={miles}
      onChange={e => {
        onMiles(e.target.value)
      }}
    />
    {/* <Space sizePx={16} inline /> */}
    <Button
      color="secondary"
      variant="contained"
      disabled={disableCancel}
      onClick={() => onCancel()}
    >
      <Cancel />
    </Button>
  </>
)

let query = gql`
  query routeruns($filters: RouteRunsFilters) {
    allRouteRuns(limit: 5000, orderBy: route_id_ASC, filters: $filters) {
      edges {
        edge {
          id
          route_id
          run_at
          total_miles
          users {
            first_name
            last_name
          }
          total_charge
        }
      }
    }
  }
`

let updateRouterunMutation = gql`
  mutation update($routeruns: [UpdateManyRouteRunsInput]) {
    updateManyRouteRuns(input: $routeruns) {
      routeRuns {
        id
        total_miles
      }
    }
  }
`

function RouteRunMiles() {
  let [date, setDate] = useState(
    moment()
      .subtract(1, 'days')
      .format('YYYY-MM-DD'),
  )
  let [miles, setMiles] = useState({})

  let {data, loading, refetch} = useQuery(query, {
    variables: {
      filters: {
        date: date,
      },
    },
  })

  function dataAintLoadedYet(data, loading) {
    return loading || !data || !data.allRouteRuns
  }

  function dataAintThere(data) {
    return !data.allRouteRuns.edges[0]
  }

  function makeSaveInput() {
    let input = []
    for (let [key, value] of Object.entries(miles)) {
      input.push({id: String(key), total_miles: Number(value)})
    }

    return input
  }

  let [updateRouteRun, {loading: sending}] = useMutation(
    updateRouterunMutation,
    {
      variables: {routeruns: makeSaveInput()},
    },
  )

  return (
    <ThePaper>
      <Typography variant="h5">Enter RouteRun Miles</Typography>
      <Space sizePx={30} />
      <Block>
        <DatePicker
          label="Date"
          value={date}
          onChange={d => {
            setDate(d.format('YYYY-MM-DD'))
            setMiles({})
          }}
          renderInput={props => (
            <TextField
              {...props}
              margin="dense"
              size="small"
              variant="filled"
            />
          )}
        />
      </Block>
      <Space sizePx={20} />

      {dataAintLoadedYet(data, loading) ? (
        <Typography variant="subtitle1"> ITS DA LOADING SCREEN</Typography>
      ) : dataAintThere(data) ? (
        <Typography variant="subtitle1"> nada.</Typography>
      ) : (
        <>
          {data.allRouteRuns.edges.map(edge => {
            let routerun = edge.edge

            let inputMiles =
              miles[routerun.id] === undefined
                ? routerun.total_miles || 0
                : miles[routerun.id]

            return (
              <React.Fragment key={routerun.id}>
                <Block>
                  <Row
                    route={routerun.route_id}
                    team={
                      routerun.users
                        ? routerun.users
                            .map(user => user.first_name + ' ' + user.last_name)
                            .join(' / ')
                        : null
                    }
                    miles={inputMiles}
                    onMiles={val => {
                      let newMiles = {[routerun.id]: val}

                      setMiles(miles => ({
                        ...miles,
                        ...newMiles,
                      }))
                    }}
                    disableCancel={inputMiles === (routerun.total_miles || 0)}
                    onCancel={() => {
                      let newMiles = {[routerun.id]: routerun.total_miles || 0}

                      setMiles(miles => ({
                        ...miles,
                        ...newMiles,
                      }))
                    }}
                  />
                </Block>
                <Space sizePx={10} />
              </React.Fragment>
            )
          })}

          <Space sizePx={20} />

          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              updateRouteRun().then(() => {
                refetch()
                setMiles({})
              })
            }}
          >
            {sending ? <CircularProgress color="secondary" /> : <Save />}
          </Button>
        </>
      )}
    </ThePaper>
  )
}

export default RouteRunMiles
