import React, {Component} from 'react'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Grow from '@mui/material/Grow'
import Paper from '@mui/material/Paper'
import MenuList from '@mui/material/MenuList'
import Popper from '@mui/material/Popper'

class IconMenu extends Component {
  state = {
    open: false,
  }

  handleMenu = event => {
    event.stopPropagation()
    this.setState({open: true, anchorEl: event.currentTarget})
  }

  handleClose = () => {
    this.setState({open: false})
  }

  render() {
    let {iconButtonElement, children, id} = this.props
    let {open} = this.state
    return (
      <div id={id}>
        <div onClick={this.handleMenu}>{iconButtonElement}</div>

        <Popper
          placement="bottom-start"
          open={open}
          anchorEl={this.state.anchorEl}
          style={{zIndex: 1500}}
        >
          <ClickAwayListener onClickAway={this.handleClose}>
            <Grow in={open} style={{transformOrigin: '0 0 0'}}>
              <Paper>
                <MenuList>{children}</MenuList>
              </Paper>
            </Grow>
          </ClickAwayListener>
        </Popper>
      </div>
    )
  }
}
export default IconMenu
