import React, {useState} from 'react'
import {useQuery} from 'react-query'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  TextField,
  Grid,
  LinearProgress,
  MenuItem,
} from '@mui/material'
import {fetchAPI} from '../../schema/utils'
import {formatMoneyStandard} from '../../utils/moneyFormatter'
import {ArrowForward, AttachMoney, MoneyOff} from '@mui/icons-material'
import {ToolbarGroup} from '../../components/Toolbar'
import {Toolbar} from '../../AppHandler'
import ToolbarMegaTitle from '../../components/ToolbarMegaTitle'
import {Space} from '../../components/Layout'
import {DatePicker} from '@mui/lab'
import moment from 'moment'
import theme from '../../styles/theme'
import ModifyQueryParams from '../../components/ModifyQueryParams'
import queryString from 'query-string'
import {Bar, ProgressBar} from '../Schedules/scheduleComponents'
import {CitySelect} from '../../components/CitySelect'
import {UserSelectDynamic} from '../../components/UsersSelect'
import ToolbarDropDownMenu from '../../components/ToolbarDropDownMenu'
import SaleItemSelect from '../../components/SaleItemSelect'

const DashItemTable = ({items = []}) => (
  <Table size="small" dense>
    <TableHead>
      <TableRow>
        {Object.keys(items[0] || {})
          .filter(key => key !== 'footer')
          .map(header => (
            <TableCell key={header}>{header}</TableCell>
          ))}
      </TableRow>
    </TableHead>
    <TableBody>
      {items.map((row, i) => (
        <TableRow key={i}>
          {Object.entries(row)
            .filter(([key, value]) => key !== 'footer')
            .map(([key, value]) => (
              <TableCell
                variant={row.footer ? 'footer' : ''}
                sx={{fontWeight: row.footer ? 'bold' : ''}}
              >
                {value}
              </TableCell>
            ))}
        </TableRow>
      ))}
    </TableBody>
  </Table>
)

const DashItem = ({
  label,
  items = [],
  summary = [],
  size = 4,
  loading = false,
  error = false,
}) => {
  return (
    <Grid item container direction="column" spacing={1} xs={size}>
      <Grid item>
        <Typography variant="h6">{label}</Typography>
        {error && (
          <Typography style={{color: 'red'}}>
            There was an error loading this
          </Typography>
        )}
        {loading && <Typography>Loading...</Typography>}
      </Grid>
      {summary.length > 0 && (
        <Grid item>
          <TableContainer component={Paper}>
            <DashItemTable items={summary} />
          </TableContainer>
        </Grid>
      )}
      <Grid item>
        <TableContainer component={Paper}>
          <DashItemTable items={items} />
        </TableContainer>
      </Grid>
    </Grid>
  )
}

const TrackedSales = ({location}) => {
  let [queryChanges, setQueryChanges] = useState({
    from: moment()
      .subtract(1, 'year')
      .format('YYYY-MM-DD'),
    to: moment().format('YYYY-MM-DD'),
  })
  let {
    from,
    to,
    city_id,
    sales_person_id,
    group_by,
    saleitem_id,
  } = queryString.parse(location.search)

  let searchSig = [from, to, city_id, sales_person_id, group_by, saleitem_id]
  let query = {
    start_date: from,
    end_date: to,
    city_id,
    sales_person_id,
    group_by,
    saleitem_id,
  }

  const {data: salesData, isLoading, isError} = useQuery(
    ['sales', ...searchSig],
    () =>
      fetchAPI({url: '/reports/tracked_sales_summary', query}).then(r =>
        r.json(),
      ),
  )
  const {
    data: salesSoldData,
    isLoading: soldLoading,
    isError: soldIsError,
  } = useQuery(['sales', 'sold', ...searchSig], () =>
    fetchAPI({
      url: '/reports/tracked_sales_summary',
      query: {...query, sold: 'true'},
    }).then(r => r.json()),
  )
  const {
    data: salesRejectedData,
    isLoading: rejectedLoading,
    isError: rejectedIsError,
  } = useQuery(['sales', 'rejected', ...searchSig], () =>
    fetchAPI({
      url: '/reports/tracked_sales_summary',
      query: {...query, sold: 'false'},
    }).then(r => r.json()),
  )
  const {
    data: salesByCityData,
    isLoading: byCityLoading,
    isError: byCityIsError,
  } = useQuery(['sales', 'city', ...searchSig], () =>
    fetchAPI({
      url: '/reports/tracked_sales_summary',
      query: {...query, group_by: 'city_id'},
    }).then(r => r.json()),
  )
  const {
    data: salesByDiscountData,
    isLoading: byDiscountLoading,
    isError: byDiscountIsError,
  } = useQuery(['sales', 'discount', ...searchSig], () =>
    fetchAPI({
      url: '/reports/tracked_sales_summary',
      query: {
        ...query,
        group_by: 'discount_id',
        sold: 'true',
        had_discount: 'true',
      },
    }).then(r => r.json()),
  )

  const getProgress = (amount, total) =>
    Number.parseInt(
      (Number.parseFloat(amount) / Number.parseFloat(total)) * 100,
    )

  let detailItems = []
  let summaryItems = []
  if (!!salesData && !isLoading) {
    // all data
    let detailTotals = salesData.detail.reduce(
      (carry, row) => {
        carry.customers += Number.parseInt(row.unique_customers)
        carry.amount += Number.parseFloat(row.totalAmount)
        return carry
      },
      {label: 'Grand Total', customers: 0, amount: 0, footer: true},
    )
    detailItems = salesData.detail.map(row => ({
      ['Source']: row.source_name,
      ['Customers']: row.unique_customers,
      ['Amount']: (
        <Bar
          variant="determinate"
          value={getProgress(row.totalAmount, detailTotals.amount)}
          display={formatMoneyStandard(row.totalAmount)}
        />
      ),
    }))
    detailTotals.amount = formatMoneyStandard(detailTotals.amount)
    detailItems.push(detailTotals)
    summaryItems = salesData.summary.map(row => ({
      ['Type']: row.existing,
      ['Count']: row.count,
    }))
  }

  // sold data
  let detailItemsSold = []
  let summaryItemsSold = []
  if (!!salesSoldData && !soldLoading) {
    let detailTotalsSold = salesSoldData.detail.reduce(
      (carry, row) => {
        carry.customers += Number.parseInt(row.unique_customers)
        carry.amount += Number.parseFloat(row.totalAmount)
        return carry
      },
      {label: 'Grand Total', customers: 0, amount: 0, footer: true},
    )
    detailItemsSold = salesSoldData.detail.map(row => ({
      ['Source']: row.source_name,
      ['Customers']: row.unique_customers,
      ['Amount']: (
        <Bar
          variant="determinate"
          value={getProgress(row.totalAmount, detailTotalsSold.amount)}
          display={formatMoneyStandard(row.totalAmount)}
        />
      ),
    }))
    detailTotalsSold.amount = formatMoneyStandard(detailTotalsSold.amount)
    detailItemsSold.push(detailTotalsSold)
    summaryItemsSold = salesSoldData.summary.map(row => ({
      ['Type']: row.existing,
      ['Count']: row.count,
    }))
  }

  // Rejected data
  let detailItemsRejected = []
  let summaryItemsRejected = []
  if (!!salesRejectedData && !rejectedLoading) {
    let detailTotalsRejected = salesRejectedData.detail.reduce(
      (carry, row) => {
        carry.customers += Number.parseInt(row.unique_customers)
        return carry
      },
      {label: 'Grand Total', customers: 0, footer: true},
    )
    detailItemsRejected = salesRejectedData.detail.map(row => ({
      ['Source']: row.source_name,
      ['Customers']: (
        <Bar
          variant="determinate"
          value={getProgress(
            row.unique_customers,
            detailTotalsRejected.customers,
          )}
          display={row.unique_customers}
        />
      ),
    }))
    detailItemsRejected.push(detailTotalsRejected)
    summaryItemsRejected = salesRejectedData.summary.map(row => ({
      ['Type']: row.existing,
      ['Count']: row.count,
    }))
  }

  // city data
  let detailItemsByCity = []
  let summaryItemsByCity = []
  if (!!salesByCityData && !byCityLoading) {
    let detailTotalsByCity = salesByCityData.detail.reduce(
      (carry, row) => {
        carry.customers += Number.parseInt(row.unique_customers)
        return carry
      },
      {label: 'Grand Total', customers: 0, footer: true},
    )
    detailItemsByCity = salesByCityData.detail.map(row => ({
      ['City']: row.city_name,
      ['Customers']: (
        <Bar
          variant="determinate"
          value={getProgress(
            row.unique_customers,
            detailTotalsByCity.customers,
          )}
          display={row.unique_customers}
        />
      ),
    }))
    detailItemsByCity.push(detailTotalsByCity)
  }

  // discount data
  let detailItemsByDiscount = []
  let summaryItemsByDiscount = []
  if (!!salesByDiscountData && !byDiscountLoading) {
    let detailTotalsByDiscount = salesByDiscountData.detail.reduce(
      (carry, row) => {
        carry.customers += Number.parseInt(row.unique_customers)
        carry.amount += Number.parseFloat(row.totalAmount)
        return carry
      },
      {label: 'Grand Total', customers: 0, amount: 0, footer: true},
    )
    detailItemsByDiscount = salesByDiscountData.detail.map(row => ({
      ['Discount']: row.discount_name,
      ['Customers']: (
        <Bar
          variant="determinate"
          value={getProgress(
            row.unique_customers,
            detailTotalsByDiscount.customers,
          )}
          display={row.unique_customers}
        />
      ),
      ['Amount']: (
        <Bar
          variant="determinate"
          value={getProgress(row.totalAmount, detailTotalsByDiscount.amount)}
          display={formatMoneyStandard(row.totalAmount)}
        />
      ),
    }))
    detailTotalsByDiscount.amount = formatMoneyStandard(
      detailTotalsByDiscount.amount,
    )
    detailItemsByDiscount.push(detailTotalsByDiscount)
    summaryItemsByDiscount = salesByDiscountData.summary.map(row => ({
      ['Type']: row.existing,
      ['Count']: row.count,
    }))
  }

  return (
    <div
      style={{
        padding: '20px',
        height: '100%',
        backgroundColor: theme.palette.backgroundColor,
      }}
    >
      <Toolbar>
        <ToolbarGroup first>
          <ToolbarMegaTitle>Tracked Sales</ToolbarMegaTitle>
          <Space inline size={2} />
          <DatePicker
            variant="filled"
            label="As of"
            value={moment.utc(from)}
            inputFormat="MMMM D, YYYY"
            onChange={d =>
              setQueryChanges({...queryChanges, from: d.format('YYYY-MM-DD')})
            }
            renderInput={props => (
              <TextField {...props} margin="dense" size="small" />
            )}
            autoOk
          />
          <Space inline size={2} />
          <ArrowForward />
          <Space inline size={2} />
          <DatePicker
            variant="filled"
            label="As of"
            value={moment.utc(to)}
            inputFormat="MMMM D, YYYY"
            onChange={d =>
              setQueryChanges({...queryChanges, to: d.format('YYYY-MM-DD')})
            }
            renderInput={props => (
              <TextField {...props} margin="dense" size="small" />
            )}
            autoOk
          />
          <Space inline size={2} />
          <CitySelect
            onChange={(e, v) =>
              setQueryChanges({
                ...queryChanges,
                city_id: v == null ? null : v?.id,
              })
            }
            selectedId={city_id}
            clearable
            inputProps={{
              size: 'small',
              style: {
                width: '200px',
              },
            }}
          />
          <Space inline size={2} />
          <UserSelectDynamic
            label="Sales Person"
            onChange={(e, v) =>
              setQueryChanges({
                ...queryChanges,
                sales_person_id: v == null ? null : v?.id,
              })
            }
            selectedId={sales_person_id}
            clearable
            inputProps={{
              size: 'small',
              style: {
                width: '200px',
              },
            }}
          />
          <Space inline size={2} />
          <ToolbarDropDownMenu
            value={group_by}
            onChange={e =>
              setQueryChanges({...queryChanges, group_by: e.target.value})
            }
            label="Group By"
            style={{width: '120px'}}
          >
            <MenuItem value="source_id">Source</MenuItem>
            <MenuItem value="saleitem_id">Saleitem</MenuItem>
          </ToolbarDropDownMenu>
          <Space inline size={2} />
          <SaleItemSelect
            onChange={e =>
              setQueryChanges({...queryChanges, saleitem_id: e?.id ?? ''})
            }
            selectedId={saleitem_id}
            inputVariant="outlined"
            inputSize="small"
          />
        </ToolbarGroup>
        <ToolbarGroup></ToolbarGroup>
      </Toolbar>
      <ModifyQueryParams query={{...queryChanges}} state={{}} />
      <Typography variant="subtitle2" display="block" style={{opacity: 0.5}}>
        Information derived from the sales records under a customer
      </Typography>
      <Grid container spacing={2}>
        <DashItem
          label="All Sales Calls"
          items={detailItems}
          summary={summaryItems}
          loading={isLoading}
          error={isError}
        />
        <DashItem
          label="Sold"
          items={detailItemsSold}
          summary={summaryItemsSold}
          loading={soldLoading}
          error={soldIsError}
        />
        <DashItem
          label="Rejected"
          items={detailItemsRejected}
          summary={summaryItemsRejected}
          loading={rejectedLoading}
          error={rejectedIsError}
        />
        <DashItem
          label="By City"
          items={detailItemsByCity}
          loading={byCityLoading}
          error={byCityIsError}
        />
        <DashItem
          label="By Discount"
          items={detailItemsByDiscount}
          summary={summaryItemsByDiscount}
          loading={byDiscountLoading}
          error={byDiscountIsError}
        />
      </Grid>
    </div>
  )
}

export default TrackedSales
