import React, {useState} from 'react'
import {useMutation, useQuery} from 'react-query'
import {gql} from 'graphql-tag' // Added gql import
import styled from 'styled-components' // Added styled-components import
import {prgql} from '../../utils/graphql'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  TableFooter,
  Grid,
  LinearProgress,
  CircularProgress,
} from '@mui/material'
import {Toolbar} from '../../AppHandler' // Added Toolbar import
import {Space} from '../../components/Layout'
import {SettingsApplications} from '@mui/icons-material'

let query = gql`
  query UnrecordedIncome {
    allInvoices(filters: {recordable: true}, limit: 1000000000) {
      edges {
        edge {
          id
          customer {
            id
            name
            taxable
            taxitem_id
            taxitem {
              id
              name
            }
          }
          charge
          recorded
        }
      }
    }
  }
`

let DashboardWrapper = styled.div`
  padding: 8px;
  grid-column-gap: 8px;
  display: flex;
  flex-direction: column;
  max-width: 1000px;
`

const SettingsToolbar = () => (
  <Toolbar>
    <Typography variant="h6" style={{alignItems: 'center', display: 'flex'}}>
      <SettingsApplications />
      <Space inline />
      Settings
    </Typography>
  </Toolbar>
)

const Integrations = () => {
  const {data, isLoading, isError} = useQuery('quickbooks', async () => {
    let json = ''
    json = (await fetch('/quickbooks/info')).json()
    return json
  })

  const [open, setOpen] = useState(false)

  let connectionDisplay = ''
  if (!data || isLoading) {
    connectionDisplay = 'Loading...'
  } else if (isError) {
    connectionDisplay = 'Error...'
  } else {
    if (data.error) {
      if (
        data.errors &&
        data.errors.filter(e => e.code === 'VQ-2').length > 0
      ) {
        connectionDisplay = (
          <a href="/quickbooks/authorize" target="_blank">
            Connect to Intuit
          </a>
        )
      } else {
        connectionDisplay = data.message
      }
    } else {
      connectionDisplay = <a onClick={() => setOpen(true)}> {data.data.name + ' (Click to disconnect)'}</a>
    }
  }

  return (
    <>
      <DashboardWrapper>
        <Typography variant="h6">Quickbooks: {connectionDisplay}</Typography>
      </DashboardWrapper>
      <Dialog open={open}>
        <DialogTitle>Disconnect from Quickbooks</DialogTitle>
        <DialogContent>
            You sure you want to disconnect from {data && data.data && data.data.name || 'SoMe CoMpAnY'}?
        </DialogContent>
        <DialogActions>
            <Button onClick={() => setOpen(false)}>Cancel</Button>
            <Button variant='contained' color='primary' onClick={() => {
                setOpen(false)
                window.open('/quickbooks/disconnect', '_blank')
            }}>Disconnect</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default Integrations
