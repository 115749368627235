import React, { useState } from 'react'
import gql from 'graphql-tag'
import { Query, Mutation } from 'react-apollo'
import styled, { css } from 'styled-components'
import moment from 'moment'
import { Switch, Route, Link, Redirect } from 'react-router-dom'

// import { DatePicker } from '@mui/lab'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import { TextField as MuiTextField } from '@mui/material'
import { Checkbox as MuiCheckbox } from '@mui/material'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import MenuItem from '@mui/material/MenuItem'

import Edit from '@mui/icons-material/Edit'
import Save from '@mui/icons-material/Save'

import DataBlock from '../../components/DataBlock'
import {
  DetailToolbar,
  ToolbarTitle,
  ToolbarLeft,
  ToolbarCenter,
  ToolbarRight,
} from '../../components/Toolbar'
import { Block, Space } from '../../components/Layout'
import urlJoin from '../../utils/url-join'
import Button from '../../components/Button'
import { NavigateBack } from '../../components/Navigator/index'
import { formatMoneyStandard } from '../../utils/moneyFormatter'
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  ImageListItem,
  ImageListItemBar,
  IconButton,
  LinearProgress,
  colors,
} from '@mui/material'
import LoadingButton from '../../components/LoadingButton'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { prgql } from '../../utils/graphql'
import { Snackbar } from '@mui/material'
import Alert from '../../components/Alert'
import Image from '../../components/Image'
import { AddAPhoto, Check, Person } from '@mui/icons-material'
import { AddPhotoDialog } from '../Customer/CustomerPhotos'
import { fetchAPI } from '../../schema/utils'
import { useAuth } from '../../security/auth'
import { Code, CodeBlock } from '../../components/Code'
import { useDebounce, useDebounceCallback } from '../../utils'
import RoleSelectStatic from '../../components/RoleSelect'
import toaster from '../../utils/toaster'
import { Formik } from 'formik'
import { Checkbox, DatePicker, PhoneNumber, TextField } from '../../components/forms'
import * as Yup from 'yup'

let DetailsContainer = styled.div`
  margin: 24px 24px;
`
let Section = styled(Paper)``

let Headline = styled(Typography).attrs(() => ({ variant: 'h4' }))`
  ${({ disabled }) => disabled && 'color: #AAA;'};
`
let leftIconStyles = css`
  margin-right: ${({ theme }) => theme.muiTheme.spacing(1)};
`
let EditIcon = styled(Edit)`
  ${leftIconStyles};
`
let SaveIcon = styled(Save)`
  ${leftIconStyles};
`
let LargeIcon = styled.div`
  color: ${({ theme }) => theme.muiTheme.palette.action.disabled};
  width: 66%;
  height: 66%;
`
let PersonIconLarge = styled(LargeIcon).attrs(() => ({ as: Person }))``

const CertLevels = {
  0: 'None',
  1: 'Level 1',
  2: 'Level 2',
  3: 'Expert',
}

let employeeDetails = gql`
  fragment EmployeeDetails on Users {
    id
    first_name
    last_name
    active
    hire_date
    termination_date
    email
    phone
    email_feedback
    max_leave_hours
    username
    ww_cert
    pw_cert
    bio
    email_signature
    account_manager_commission_percent
    account_manager_gpm_percent
    commission_percent
    gpm_percent
    commission_days
    roles {
      id
      name
    }
    photo {
      id
      url
    }
    attendance_statistics {
      id
      pto_hours
      unpaid_hours
    }
    expense_statistics {
      id
      losses_this_year
      all_losses
      requisitions_this_year
      all_requisitions
    }
  }
`

let employeeQuery = gql`
  query employeeQuery($id: String!, $startDate: String) {
    users(id: $id) {
      ...EmployeeDetails
    }

    late: allAttendance(
      limit: 1
      cursor: "-1"
      filters: {user_id: $id, type: LATE, from: $startDate}
    ) {
      pageInfo {
        total
      }
    }

    excused: allAttendance(
      limit: 1
      cursor: "-1"
      filters: {user_id: $id, type: EXCUSED, from: $startDate}
    ) {
      pageInfo {
        total
      }
    }
  }
  ${employeeDetails}
`

// ChatGippity
function formatDuration(duration) {
  const years = duration.years();
  const months = duration.months();

  const formattedYears = years !== 0 ? `${Math.abs(years)} year${Math.abs(years) !== 1 ? 's' : ''}` : null;
  const formattedMonths = months !== 0 ? `${Math.abs(months)} month${Math.abs(months) !== 1 ? 's' : ''}` : null;

  const resultArray = [formattedYears, formattedMonths].filter(Boolean);

  return resultArray.length > 0 ? resultArray.join(', ') + ' ago' : 'Less than a month';
}

let printHired = ({ hire_date, termination_date }) =>
  moment.utc(hire_date).format('LL') +
  (termination_date ? ` / ${moment.utc(termination_date).format('LL')}` : '') +
  ` (${formatDuration(moment
    .duration(moment(hire_date).diff(termination_date || moment())))})`

let PhotoWrapper = styled(ImageListItem)`
  width: 300px;
  height: 300px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
`
let PhotoFallback = styled.div`
  width: 300px;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.grey300};
`

export function UserPhoto({ user: u, onRefetch }) {
  let [showUpload, setShowUpload] = useState(false)
  // MMMMM, gotta love react-query
  let { isSuccess, mutate, isLoading } = useMutation(
    ({ photo }) => {
      let formData = new FormData()
      formData.append(
        'body',
        JSON.stringify({
          data: {
            attributes: {
              photoable_id: String(u.id),
              photoable_type: 'App\\User',
            },
          },
        }),
      )
      formData.append('photo', photo)

      return fetchAPI({
        url: `/users/${u.id}/photos`,
        options: {
          method: 'POST',
          body: formData,
        },
      })
    },
    {
      onSuccess: () => {
        setShowUpload(false)
        onRefetch()
      },
    },
  )

  return (
    // <a href={`/old/database/photo.php?JobID=${u.id}`}>
    // </a>
    <>
      <PhotoWrapper>
        {u.photo ? (
          <Image
            src={u.photo.url}
            alt={'User ' + u.name}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            }}
          />
        ) : (
          <PhotoFallback>
            <PersonIconLarge />
          </PhotoFallback>
        )}
        <ImageListItemBar
          title={`${u.photo ? 1 : 'No'} Photo`}
          actionIcon={
            <IconButton
              style={{ color: 'white' }}
              onClick={() => setShowUpload(true)}
              size="large"
            >
              <AddAPhoto color="inherit" />
            </IconButton>
          }
        />
      </PhotoWrapper>
      <AddPhotoDialog
        open={showUpload}
        loading={isLoading}
        onSave={mutate}
        onClose={() => setShowUpload(false)}
      />
    </>
  )
}

let ViewEmployeeDetails = ({ user, match, onRefetch }) => {
  let { status: canEditUser } = useAuth('manage users')

  return (
    <Section>
      <DetailToolbar>
        <ToolbarCenter>
          <ToolbarTitle>Details</ToolbarTitle>
        </ToolbarCenter>
        <ToolbarRight>
          {canEditUser === 'authorized' && (
            <Link to={urlJoin`${match.url}/edit`}>
              <Button>
                <EditIcon />
                edit
              </Button>
            </Link>
          )}
        </ToolbarRight>
      </DetailToolbar>
      <Grid container direction="row">
        <Grid item md={4}>
          <Block column paddingX={2}>
            <UserPhoto user={user} onRefetch={onRefetch} />
            <DataBlock label="Bio" data={user.bio} />
            <DataBlock
              label="Signature"
              data={<CodeBlock>{user.email_signature}</CodeBlock>}
              multiline
            />
          </Block>
        </Grid>
        <Grid item md={8}>
          <Block column paddingX={2}>
            <DataBlock label="Hired" data={printHired(user)} />
            <DataBlock label="Email" data={user.email} />
            <DataBlock
              label="Email Feedbacks"
              data={user.email_feedback ? 'Yes' : 'No'}
            />
            <DataBlock
              label="Cell Number"
              data={user.phone}
            />
            <DataBlock label="Username" data={user.username} />
            <DataBlock
              label="Role"
              data={user.roles?.map(({ name }) => name).join(', ')}
            />
            <DataBlock
              label="WW Certification"
              data={CertLevels[user.ww_cert]}
            />
            <DataBlock
              label="PW Certification"
              data={CertLevels[user.pw_cert]}
            />
          </Block>
        </Grid>
      </Grid>
    </Section>
  )
}

let updateMut = gql`
  mutation updateEmployeeDetails($input: UpdateUsersInput) {
    savedUser: updateUsers(input: $input) {
      users {
        ...EmployeeDetails
      }
      errors {
        title
        code
        detail
      }
    }
  }
  ${employeeDetails}
`
let createMut = gql`
  mutation updateEmployeeDetails($input: CreateUsersInput) {
    savedUser: createUsers(input: $input) {
      users {
        ...EmployeeDetails
      }
      errors {
        title
        code
        detail
      }
    }
  }
  ${employeeDetails}
`

let SaveDetails = ({ id, details, children }) => (
  <Mutation
    mutation={id ? updateMut : createMut}
    variables={{ input: id ? { id, ...details } : details }}
  >
    {children}
  </Mutation>
)

let DangerLoadingButton = styled(LoadingButton)`
  background-color: ${({ theme }) => theme.palette.danger.main};
  color: ${({ theme }) => theme.palette.danger.contrastText};
`

let PurgeDialog = ({ isOpen, onClose, onSubmit, loading, employeeName }) => {
  return (
    <Dialog open={isOpen} maxWidth="xs" fullWidth onClose={onClose}>
      <DialogTitle>Purge {employeeName} from Schedule</DialogTitle>
      <DialogContent>
        <Typography>
          Would you like to purge {employeeName} from the upcoming schedule?
        </Typography>
        <Space />
        <Typography>
          This will remove them from future routeruns and the routerun template.
        </Typography>
        <Space />
        <Typography>
          This action cannot be undone.
        </Typography>
        <Space />
        <Typography>
          (You can decide to do this later if you want)
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <DangerLoadingButton
          onClick={onSubmit}
          loading={loading}
          variant="contained"
          color="primary"
        >
          Purge
        </DangerLoadingButton>
      </DialogActions>
    </Dialog>
  )
}

let ResourcesText = styled(Typography)`
  white-space: pre-wrap;
  padding: 1em;
  background-color: #eaeaea;
  border-radius: 10px;
`

// ChatGippity
function replaceLinks(text) {
  // Regular expression to match URLs
  var urlRegex = /(\b(?:https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi

  // Replace URLs with <a> tags
  var replacedText = text.replace(urlRegex, function(url) {
    return '<a href="' + url + '" target="blank">' + url + '</a>'
  })

  return replacedText
}

let TrainingDialog = ({
  isOpen,
  onClose,
  onCheck,
  loading,
  tasks = [],
  completedTasks = [],
  certTitle,
}) => {
  let sorted = tasks.sort((a, b) => (a.fields.Order || 0) > (b.fields.Order || 0) ? 1 : -1)
  return (
    <Dialog open={isOpen} maxWidth="xl" fullWidth onClose={onClose}>
      <DialogTitle>{certTitle}</DialogTitle>
      <DialogContent>
        {!loading ? (
          sorted.map(t => (
            <div key={t.id}>
              <Typography>
                <MuiCheckbox
                  color="secondary"
                  onClick={e => onCheck(e, t.id)}
                  checked={completedTasks.includes(t.id)}
                />{' '}
                {t.fields.Task}
              </Typography>
              {t.fields['Session Resources'] && (
                <ResourcesText
                  dangerouslySetInnerHTML={{
                    __html: replaceLinks(t.fields['Session Resources']),
                  }}
                />
              )}
            </div>
          ))
        ) : (
          <CircularProgress />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}

let mergeChanges = (base = {}, changes) => ({
  hire_date: moment().format('YYYY-MM-DD'),
  active: true,
  email_feedback: false,
  roles: [{ id: "6" }],
  ...base,
  ...changes,
})

let userSchema = Yup.object().shape(
  {
    first_name: Yup.string().required('Required'),
    last_name: Yup.string().required('Required'),
    roles: Yup.array().required('Required'),
    hire_date: Yup.string().required('Required'),
  },
)

function EditEmployeeDetails(props) {
  let [state, setState] = useState({ changes: {} })
  let [redirect, setRedirect] = useState(null)
  let queryClient = useQueryClient()

  let { status: authStatus } = useAuth('set user credentials')
  let { status: rightsStatus } = useAuth('change role')
  let { status: setCommissionRateStatus } = useAuth('set commission rates')

  let { defaultDetails } = props
  let { changes } = state
  let { onPurge } = props
  let d = mergeChanges(defaultDetails, changes)

  let { mutateAsync: save, isLoading: saveLoading } = useMutation((values) => {
    let input = {
      ..._formatDataForGraphQL(mungeData(values))
    }
    return defaultDetails.id
      ? prgql({ query: updateMut, variables: { input: { id: values.id, ...input } } })
      : prgql({ query: createMut, variables: { input } })
  }, {
    onSuccess: (res) => {
      if (!!res.savedUser.errors) {
        toaster.error(res.savedUser.errors[0].detail, { duration: 3000 })
      } else {
        toaster.success('Employee saved')
        queryClient.refetchQueries('employees')
        setRedirect('/employees/' + res.savedUser.users.id)
      }
    },
    onError: (err) => {
      toaster.error('something broke')
    },
  })

  let _formatDataForGraphQL = data => ({ ...data, roles: { set: data.roles } })

  let mungeData = (obj) => {
    let fields = [
      'account_manager_commission_percent',
      'account_manager_gpm_percent',
      'active',
      'bio',
      'phone',
      'commission_days',
      'commission_percent',
      'email',
      'email_feedback',
      'email_signature',
      'first_name',
      'gpm_percent',
      'hire_date',
      'id',
      'last_name',
      'max_leave_hours',
      'pw_cert',
      'roles',
      'termination_date',
      'username',
      'ww_cert',
      'password'
    ]
    return Object.fromEntries(
      Object.entries(obj).filter(([key, value]) => fields.includes(key)).map(([key, value]) => {
        if (["account_manager_gpm_percent", "account_manager_commission_percent", "gpm_percent", "commission_percent", "commission_days",].includes(key)) {
          return [key, parseFloat(value)];
        } else if (['max_leave_hours'].includes(key)) {
          return [key, parseInt(value)];
        } else {
          return [key, value];
        }
      })
    );
  }

  return (
    <Formik
      initialValues={d}
      validationSchema={userSchema}
      onSubmit={async (values) => {
        await save(values).then(() => {
          if (values.active === false) {
            onPurge()
          }
        })
      }}
    >
      {({ values, submitForm, isSubmitting, setFieldValue, errors }) => (
        <Section>
          <DetailToolbar>
            <ToolbarLeft>
              <NavigateBack defaultBack="/employees" marker="EmployeeEdit">
                <Button>cancel</Button>
              </NavigateBack>
            </ToolbarLeft>
            <ToolbarCenter>
              <ToolbarTitle>Details</ToolbarTitle>
            </ToolbarCenter>
            <ToolbarRight>
              <Button
                onClick={({ ...props }) => {
                  console.log(props)
                  submitForm(props)
                }}
                icon={<SaveIcon />}
                loading={isSubmitting}
              >
                save
              </Button>
              {!!redirect && !isSubmitting && (
                <Redirect
                  to={redirect}
                />
              )}
            </ToolbarRight>
          </DetailToolbar>
          <Block paddingX={2} spacing={2} column>
            <Block spacing={1} column>
              <Typography variant="h6">Employee Info</Typography>
            </Block>
            <Block spacing={1}>
              <TextField
                name="first_name"
                label="First Name"
                variant="filled"
              />
              <TextField
                name="last_name"
                label="Last Name"
                variant="filled"
              />
            </Block>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    name="active"
                    color="secondary"
                    checked={values.active}
                  />
                }
                label="Active"
              />
            </FormGroup>
            <Block spacing={1}>
              <DatePicker
                label="Hire Date"
                name={'hire_date'}
                inputFormat="MMMM D, YYYY"
                renderInput={props => <MuiTextField {...props} variant="filled" />}
              />
              <DatePicker
                label="Termination Date"
                name={'termination_date'}
                inputFormat="MMMM D, YYYY"
                clearable
                renderInput={props => <MuiTextField {...props} variant="filled" />}
              />
            </Block>
            <Block spacing={1} column>
              <TextField
                name="email"
                label="Email"
                variant="filled"
              />
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="email_feedback"
                      color="secondary"
                      checked={values.email_feedback}
                    />
                  }
                  label="Email Feedback"
                />
              </FormGroup>
            </Block>
            <Block spacing={1} column>
              <PhoneNumber
                name={`phone`}
                label="Cell Number"
                variant="filled"
                size="small"
                allowEmpty
                fullWidth
              />
            </Block>
            <Block spacing={1} column>
              <TextField
                label="Max Paid Leave Hours"
                variant="filled"
                name="max_leave_hours"
              />
            </Block>
            <Block spacing={1} column>
              <TextField
                select
                label="WW Certification"
                variant="filled"
                name="ww_cert"
                children={
                  Object.values(CertLevels).map((value, index) => (
                    <MenuItem key={index} value={index}>
                      {value}
                    </MenuItem>
                  ))
                }
              />
            </Block>
            <Block spacing={1} column>
              <TextField
                select
                label="PW Certification"
                variant="filled"
                name="pw_cert"
              >
                {Object.values(CertLevels).map((value, index) => (
                  <MenuItem key={index} value={index}>
                    {value}
                  </MenuItem>
                ))}
              </TextField>
            </Block>
            <Block spacing={1} column>
              <TextField
                label="Bio"
                variant="filled"
                name="bio"
              />
            </Block>
            <Block spacing={1} column>
              <TextField
                label="Email Signature"
                variant="filled"
                name="email_signature"
                multiline
              />
            </Block>

            {authStatus === 'authorized' && (
              <>
                <Block spacing={1} column>
                  <Typography variant="h6">Credentials</Typography>
                </Block>
                <Block spacing={1} column>
                  <TextField
                    label="Username"
                    variant="filled"
                    name="username"
                  />
                </Block>
                <Block spacing={1} column>
                  <TextField
                    label="Password"
                    variant="filled"
                    type='password'
                    name="password"
                  />
                </Block>
                {rightsStatus === 'authorized' && (
                  <Block spacing={1} column>
                    <RoleSelectStatic
                      variant={'filled'}
                      value={!!values.roles ? values.roles?.map(r => r.id) : ['6']}
                      onChange={e => setFieldValue('roles', e.target.value.map(id => ({ id })))}
                      inputProps={{ variant: 'filled' }}
                    />
                  </Block>
                )}
                {setCommissionRateStatus === 'authorized' &&
                  <Block spacing={1} column>
                    <Typography variant="h6">Commission Info</Typography>
                  </Block>}
                {setCommissionRateStatus === 'authorized' &&
                  <Block spacing={1} column>
                    <TextField
                      label="Account Manager Commision %"
                      variant="filled"
                      name="account_manager_commission_percent"
                      type="number"
                      multiline
                    />
                  </Block>}
                {setCommissionRateStatus === 'authorized' &&
                  <Block spacing={1} column>
                    <TextField
                      label="Account Manager GPM %"
                      variant="filled"
                      name="account_manager_gpm_percent"
                      type="number"
                    />
                  </Block>}
                {setCommissionRateStatus === 'authorized' &&
                  <Block spacing={1} column>
                    <TextField
                      label="Regular Commission %"
                      variant="filled"
                      name="commission_percent"
                      type="number"
                    />
                  </Block>}
                {setCommissionRateStatus === 'authorized' &&
                  <Block spacing={1} column>
                    <TextField
                      label="Regular Commission GPM %"
                      variant="filled"
                      name="gpm_percent"
                      type="number"
                    />
                  </Block>}
                {setCommissionRateStatus === 'authorized' &&
                  <Block spacing={1} column>
                    <TextField
                      label="Regular Commission Lifetime (Days)"
                      variant="filled"
                      name="commission_days"
                      type="number"
                    />
                  </Block>}
              </>
            )}
          </Block>
        </Section>
      )}
    </Formik>
  )
}
EditEmployeeDetails.defaultProps = {
  defaultDetails: {},
}

let purgeEmployeeMutation = gql`
  mutation Purge($input: PurgeUserFromScheduleInput) {
    purgeUserFromSchedule(input: $input) {
      message
    }
  }
`

let ProgressBar = styled(LinearProgress).attrs(() => ({
  classes: {
    root: 'root',
    barColorPrimary: 'colorPrimary',
    barColorSecondary: 'colorSecondary',
  },
}))`
  &.root {
    flex-grow: 1;
    height: 30px;
    background-color: ${({ theme }) => theme.palette.info.light};
    border-radius: 8px;
    border: 2px solid ${({ theme }) => theme.palette.info.dark};
    padding: 0.25em;
    margin-bottom: 0.25em;
    width: 100%;
  }

  & .colorPrimary {
    background-color: ${({ theme }) => theme.palette.info.main};
  }

  & .colorSecondary {
    background-color: ${({ theme }) => colors.blue100};
  }
`

export let NewEmployee = props => <EditEmployeeDetails {...props} />

let EmployeeDetails = ({ match, currentUser }) => {
  let { params = {} } = match
  let [purgeWindow, setPurgeWindow] = useState(false)
  let [trainingWindow, setTrainingWindow] = useState(false)
  let [selectedCert, setSelectedCert] = useState('xxxxxxxxx')
  let [selectedCertTitle, setSelectedCertTitle] = useState('')
  let [snackBar, setSnackBar] = useState(false)
  let queryClient = useQueryClient()
  let { data, isLoading: loading, isError: error, refetch } = useQuery(
    ['employees', params.id],
    () => prgql({ query: employeeQuery, variables: { id: params.id, startDate } }),
  )
  let { mutateAsync: purge, isError, isLoading } = useMutation(
    async ({ variables }) => prgql({ query: purgeEmployeeMutation, variables }),
    {
      onSuccess: () => {
        setPurgeWindow(false)
        toaster.success('Employee purged from schedule', { duration: 3000 })
      },
      onError: err => {
        setPurgeWindow(false)
        toaster.error('Something went wrong while purging employee from schedule', { duration: 5000 })
      },
    },
  )
  let { data: certs, isLoading: certsLoading } = useQuery(
    ['certs', params.id],
    async () =>
      await fetchAPI({
        url: 'airtable/employee_certifications/',
        query: {
          user_id: params.id,
        },
      }).then(r => r.json()),
  )
  let { data: employee, isLoading: employeeLoading } = useQuery(
    ['employeeairtable', params.id],
    async () =>
      await fetchAPI({
        url: 'airtable/employees/' + params.id,
      }).then(r => r.json()),
  )

  let [_invalidateCert] = useDebounceCallback(async () => {
    await queryClient.invalidateQueries(['certs', params.id])
  }, 2000)

  let { data: tasks, isLoading: tasksLoading } = useQuery(
    ['tasks', selectedCert],
    async () => {
      return await fetchAPI({
        url:
          'airtable/training_tasks/' +
          certs?.records.filter(r => r.id === selectedCert)[0]?.fields
            .Certification[0],
      }).then(r => r.json())
    },
  )
  let { mutateAsync: update } = useMutation(async ({ tasks, employeeCertId }) => {
    queryClient.setQueryData(['certs', params.id], old => {
      let oldData = old.records.filter(r => r.id !== employeeCertId)
      let modified = old.records.filter(r => r.id === employeeCertId)[0]
      modified = {
        ...modified,
        fields: {
          ...modified.fields,
          Tasks: tasks,
        },
      }
      let newData = {
        records: [...oldData, modified],
      }
      return newData
    }),
      await fetchAPI({
        url: 'airtable/employee_certifications/' + employeeCertId,
        options: {
          method: 'PATCH',
          body: JSON.stringify({
            tasks,
          }),
        },
      }).then(() => _invalidateCert())
  })

  let startDate = moment()
    .dayOfYear(1)
    .format('YYYY-MM-DD')

  return loading && !data ? (
    <p>loading</p>
  ) : error ? (
    <p>Oops. It broke.</p>
  ) : (
    <>
      <DetailsContainer>
        <Block column spacing={2}>
          <Switch>
            <Route
              path={match.path + '/edit'}
              render={matchProps => (
                <EditEmployeeDetails
                  {...matchProps}
                  defaultDetails={data.users}
                  onPurge={() => setPurgeWindow(true)}
                />
              )}
            />
            <Route
              path={match.path}
              render={matchProps => (
                <ViewEmployeeDetails
                  {...matchProps}
                  user={data.users}
                  onRefetch={refetch}
                />
              )}
            />
          </Switch>
          <Section>
            <DetailToolbar>
              <ToolbarCenter>
                <ToolbarTitle>Statistics</ToolbarTitle>
              </ToolbarCenter>
            </DetailToolbar>
            <Block column paddingX={2}>
              <Headline>
                Certifications
                <Space inline />
                <a
                  href={"https://airtable.com/appJVF3XSAHDfFHoB/pag13qoa1YS1rkKw3?rOqTp=" + (employee?.records?.[0]?.id || certs?.records?.[0]?.fields.Employees[0] || '')}
                  //   https://airtable.com/appJVF3XSAHDfFHoB/pag13qoa1YS1rkKw3?rOqTp=recmLVkggtLGGyPEU
                  target="_blank"
                >
                  <Button> + Assign New </Button>
                </a>
              </Headline>
              <Grid container spacing={2} style={{ marginBottom: '.15em' }}>
                {certsLoading ? (
                  <Grid item md={4}>
                    <CircularProgress />
                  </Grid>
                ) : (
                  certs?.records
                    ?.sort((a, b) =>
                      a.fields['The Certification'].localeCompare(
                        b.fields['The Certification'],
                      ),
                    )
                    .map(c => (
                      <Grid
                        item
                        md={4}
                        onClick={() => {
                          setSelectedCert(c.id)
                          setSelectedCertTitle(
                            c.fields['The Certification'],
                          )
                          setTrainingWindow(true)
                        }}
                      >
                        <DataBlock
                          label={c.fields['The Certification']}
                          data={
                            <div
                              style={{
                                position: 'relative',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                              }}
                            >
                              <ProgressBar
                                variant="determinate"
                                value={Math.round(
                                  c.fields['Percent Completed'] > 1 ? 100 : c.fields['Percent Completed'] * 100,
                                )}
                              />
                              {c.fields['Percent Completed'] >= 1 && (
                                <Check
                                  style={{
                                    position: 'absolute',
                                    top: '0px',
                                  }}
                                />
                              )}
                            </div>
                          }
                        />
                      </Grid>
                    ))
                )}
              </Grid>

              {/* <Block spacing={1}> */}
              {/*   <DataBlock */}
              {/*     label="PTO" */}
              {/*     data={ */}
              {/*       data.users.attendance_statistics && */}
              {/*       data.users.attendance_statistics.pto_hours + ' hrs.' */}
              {/*     } */}
              {/*   /> */}
              {/*   <DataBlock */}
              {/*     label="Unpaid Time Off" */}
              {/*     data={ */}
              {/*       data.users.attendance_statistics && */}
              {/*       data.users.attendance_statistics.unpaid_hours + */}
              {/*         ' hrs.' */}
              {/*     } */}
              {/*   /> */}
              {/*   <DataBlock label="Late" data={data.late.pageInfo.total} /> */}
              {/*   <DataBlock */}
              {/*     label="Excused" */}
              {/*     data={data.excused.pageInfo.total} */}
              {/*   /> */}
              {/* </Block> */}
              {/*   <Headline>Losses & Expenses </Headline> */}
              {/*   <Block> */}
              {/*     <DataBlock */}
              {/*       label="Losses This Year" */}
              {/*       data={ */}
              {/*         data.users.expense_statistics && */}
              {/*         formatMoneyStandard( */}
              {/*           data.users.expense_statistics.losses_this_year, */}
              {/*         ) */}
              {/*       } */}
              {/*     /> */}
              {/*     <DataBlock */}
              {/*       label="All Losses" */}
              {/*       data={ */}
              {/*         data.users.expense_statistics && */}
              {/*         formatMoneyStandard( */}
              {/*           data.users.expense_statistics.all_losses, */}
              {/*         ) */}
              {/*       } */}
              {/*     /> */}
              {/*   </Block> */}
            </Block>
          </Section>
        </Block>
      </DetailsContainer>
      <PurgeDialog
        isOpen={purgeWindow}
        loading={isLoading}
        employeeName={data.users.first_name + ' ' + data.users.last_name}
        onClose={() => setPurgeWindow(false)}
        onSubmit={async () =>
          await purge({
            variables: {
              input: {
                from: moment().format('YYYY-MM-DD'),
                user_id: data.users.id,
              },
            },
          })
        }
      />
      <TrainingDialog
        isOpen={trainingWindow}
        onClose={() => setTrainingWindow(false)}
        onCheck={(e, id) => {
          let employeeCert = certs.records.filter(
            c => c.id === selectedCert,
          )[0]
          let tasks = employeeCert?.fields.Tasks || []

          if (!!e.target.checked) {
            tasks = [...tasks, id]
          } else {
            tasks = [...tasks].filter(t => t !== id)
          }

          update({ tasks, employeeCertId: employeeCert.id })
        }}
        certTitle={selectedCertTitle}
        tasks={tasks?.records || []}
        loading={tasksLoading}
        completedTasks={
          certs
            ? certs.records.filter(c => c.id === selectedCert)[0]?.fields
              .Tasks
            : []
        }
      />
      <Snackbar
        open={snackBar}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        onClose={() => setSnackBar(false)}
        autoHideDuration={3000}
      >
        <Alert>
          <Typography>Employee cleared from Schedule</Typography>
        </Alert>
      </Snackbar>
    </>
  )
}

export default EmployeeDetails
