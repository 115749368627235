import React, {useEffect, useState} from 'react'
import {Link, Route, Switch} from 'react-router-dom'
import * as Yup from 'yup'

import Button from '@mui/material/Button'
import {Toolbar} from '../../AppHandler'
import {Space, Spacing} from '../../components/Layout'
import {
  Paper,
  TableHead,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
  Tooltip,
  TableSortLabel,
  MenuItem,
  Menu,
  TableFooter,
  Badge,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  DialogActions,
  IconButton,
  TextField as MuiTextField,
  Chip,
} from '@mui/material'
import Typography from '@mui/material/Typography'
import {
  Assignment,
  Receipt,
  RadioButtonUnchecked,
  CheckCircle,
  Block,
  ErrorOutline,
  MonetizationOn,
  MoneyOff,
  ArrowDropDown,
  Print,
  Edit,
  FlashOn,
  Comment,
  Warning,
  ErrorOutlineSharp,
  Error,
  AccountBalance,
  AccountBalanceWallet,
  Delete,
  Add,
  ModeComment,
  CreditCard,
  Check,
  DoneOutline,
  VerifiedUser,
  Pause,
  AssignmentLate,
} from '@mui/icons-material'
import _ from 'lodash'
import {DatePicker} from '@mui/lab'
import styled from 'styled-components'
import {
  getWorkorderIcon,
  PillBoxDisabled,
  PillBoxEmpty,
  PillBoxInvoice,
  PillBoxWorkorder,
} from '../Schedules/scheduleComponents'
import {formatMoneyStandard} from '../../utils/moneyFormatter'
import moment from 'moment'
import RouteSelect, {RouteSelectStatic} from '../../components/RouteSelect'
import colors from '../../styles/colors'
import LoadingButton from '../../components/LoadingButton'
import {FieldArray, Formik} from 'formik'
import {AutoCompleteField, TextField} from '../../components/forms'
import theme from '../../styles/theme'
import UsersSelect from '../../components/UsersSelect'
import {isValid} from 'react-redux-form'
import {StaticDatePicker} from '@mui/x-date-pickers'
import Note from '@mui/icons-material/Note'

let DashboardWrapper = styled.div`
  padding: 8px;
  grid-column-gap: 8px;
  display: flex;
  flex-direction: row;
`

let DenseTableCell = styled(TableCell)`
  padding-left: 0.5em;
  padding-right: 0.5em;
`

let TinyCell = styled(DenseTableCell)`
  width: 50px;
`

let SlightlyTinyCell = styled(DenseTableCell)`
  width: 100px;
`

let SmallCell = styled(DenseTableCell)`
  width: 150px;
`

let WoCell = styled(DenseTableCell)`
  // width: 200px;
  margin: 1em;
`

let FlexCellContents = styled.div`
  display: flex;
  align-items: center;
`

// Yes, I know about MUI's grid. But it was causing the buttons to overflow the parent card for some reason.
let ButtonGroupContainer = styled.div`
  display: flex;
`

let ButtonGroupColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 0.25em;
`

let MakeIcon = ({menu}) => <FlashOn style={{marginRight: menu && '.5em'}} />
let OpenIcon = ({menu}) => (
  <RadioButtonUnchecked style={{marginRight: menu && '.5em'}} />
)
let CompleteIcon = ({menu}) => (
  <CheckCircle style={{marginRight: menu && '.5em'}} />
)
let VoidIcon = ({menu}) => <Block style={{marginRight: menu && '.5em'}} />
let InactiveIcon = ({menu}) => (
  <ErrorOutline style={{marginRight: menu && '.5em'}} />
) //<CheckCircleOutline />
let IncompleteIcon = ({menu}) => <Error style={{marginRight: menu && '.5em'}} /> //<CheckCircleOutline />
let PaidIcon = ({menu}) => (
  <MonetizationOn style={{marginRight: menu && '.5em'}} />
)
let CreditIcon = ({menu}) => (
  <AccountBalanceWallet style={{marginRight: menu && '.5em'}} />
)
let BaddebtIcon = ({menu}) => <MoneyOff style={{marginRight: menu && '.5em'}} />

export function getStatusIcon(status, not_completed) {
  switch (status) {
    case 'OPEN':
      if (not_completed) return <IncompleteIcon />
      return <OpenIcon />
    case 'COMPLETE':
      return <CompleteIcon />
    case 'VOID':
      return <VoidIcon />
    case 'INACTIVE':
      return <InactiveIcon />
    case 'PAID':
      return <PaidIcon />
    case 'BAD_DEBT':
      return <BaddebtIcon />
  }
}

let Invoice = ({inv}) => (
  <Tooltip title={inv.status}>
    <a
      href={'/old/database/invoice.php?InvoiceNumber=' + inv.id}
      style={{color: colors.grey900}}
    >
      <PillBoxInvoice id={`invoice${inv.id}`}>
        <FlexCellContents>
          <Receipt />
          INV {inv.id}
          {getStatusIcon(inv.status)}
        </FlexCellContents>
      </PillBoxInvoice>
    </a>
  </Tooltip>
)

let StyledBadge = styled(Badge)`
  text-align: center;
`

let Workorder = ({wo, onClick, po_needed}) => {
  let editable = wo.status === 'OPEN'
  return (
    <Tooltip title={wo.status + (wo.multiday ? ' (MULTIDAY)' : '')}>
      {editable ? (
        <StyledBadge
          badgeContent={
            wo.notes && (wo.notes_resolved ? <ModeComment /> : <Comment />)
          }
        >
          <PillBoxWorkorder
            onClick={editable && onClick}
            style={{cursor: !editable && 'auto'}}
            multiday={wo.multiday}
            id={`workorder${wo.id}`}
          >
            <FlexCellContents>
              {getWorkorderIcon(po_needed)}
              WO {wo.id}
              {getStatusIcon(wo.status, wo.not_completed)}
            </FlexCellContents>
          </PillBoxWorkorder>
        </StyledBadge>
      ) : (
        <StyledBadge
          badgeContent={
            wo.notes && (wo.notes_resolved ? <ModeComment /> : <Comment />)
          }
        >
          <Link to={`/workorders/${wo.id}`} style={{color: 'inherit'}}>
            <PillBoxWorkorder multiday={wo.multiday} id={`workorder${wo.id}`}>
              <FlexCellContents>
                {getWorkorderIcon(po_needed)}
                WO {wo.id}
                {getStatusIcon(wo.status, wo.not_completed)}
              </FlexCellContents>
            </PillBoxWorkorder>
          </Link>
        </StyledBadge>
      )}
    </Tooltip>
  )
}

let BlankRow = styled(TableRow)`
  border: 0px;
`
let BlankCell = styled(TableCell)`
  border: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
`

let BoldCell = styled(TableCell)`
  border: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  font-weight: bold;
`

let CustomLink = styled.a`
  &:hover {
    color: ${({theme}) => theme.palette.secondary.light};
    cursor: pointer;
  }
  display: flex;
  align-items: center;
`

let EditTipsIcon = styled(Edit)`
  height: 0.65em;
`

let SummaryFooter = ({paymentData = {}}) => {
  // Is there a better way to do this? Dunno. Do I care? Absolutely not.
  // Do I wish that JS was more like PHP? All types of YES.
  let total = 0
  for (let key in paymentData) {
    total += paymentData[key]
  }

  return (
    <>
      <BlankRow>
        <BlankCell colSpan={1} />
        <BoldCell colSpan={2}></BoldCell>
        <BoldCell>Open Invoices</BoldCell>
        <BoldCell>{formatMoneyStandard(paymentData.OPEN)}</BoldCell>
        <BlankCell colSpan={5} />
      </BlankRow>
      <BlankRow>
        <BlankCell colSpan={3} />
        <BoldCell colSpan={1}>Paid Invoices</BoldCell>
        <BoldCell>{formatMoneyStandard(paymentData.PAID)}</BoldCell>
        <BlankCell colSpan={5} />
      </BlankRow>
      <BlankRow>
        <BlankCell colSpan={3} />
        <BoldCell colSpan={1}>Open & Paid</BoldCell>
        <BoldCell>
          {formatMoneyStandard(
            (paymentData.OPEN || 0) + (paymentData.PAID || 0),
          )}
        </BoldCell>
        <BlankCell colSpan={5} />
      </BlankRow>
      <BlankRow>
        <BlankCell colSpan={3} />
        <BoldCell colSpan={1}></BoldCell>
        <BoldCell></BoldCell>
        <BlankCell colSpan={5} />
      </BlankRow>
    </>
  )
}

let tipsSchema = Yup.object().shape({
  tips: Yup.array().of(
    Yup.object().shape({
      user_id: Yup.string().required('Required'),
      amount: Yup.number()
        .required('Required')
        .positive('Must be a positive number')
        .moreThan(0),
    }),
  ),
})

let EditTipsDialog = ({isOpen, onClose, tips = [], onSubmit}) => {
  let initialTotal = tips.reduce((carry, e) => carry + e.edge.amount, 0)

  let intialTips = tips.map(e => e.edge)

  let [deletes, setDeletes] = useState([])
  let [newId, setNewId] = useState(0)

  let mungeFormikCreate = values => {
    return Object.values(
      values.tips
        .filter(({id}) => id.indexOf('new') !== -1)
        .reduce((carry, row) => {
          let {id, user, ...newRow} = row
          newRow = {
            ...newRow,
            amount: Number.parseFloat(newRow.amount),
          }
          carry = [...carry, newRow]
          return carry
        }, []),
    )
  }

  let mungeFormikUpdate = values => {
    return Object.values(
      values.tips
        .filter(({id}) => id.indexOf('new') === -1)
        .reduce((carry, row) => {
          let {user, __typename, ...newRow} = row
          newRow = {...newRow, amount: Number.parseFloat(newRow.amount)}
          carry = [...carry, newRow]

          return carry
        }, []),
    )
  }

  let totalMatchesFormikItems = (total, values) => {
    return (
      Number.parseFloat(total) === values.tips.reduce((c, t) => c + t.amount, 0)
    )
  }
  let _setTouched = values => {
    let genSchema = {}
    for (let key in values) {
      genSchema[key] = true
    }
    return genSchema
  }

  return (
    <Dialog open={isOpen} maxWidth="sm" fullWidth onClose={onClose}>
      <Formik
        initialValues={{
          tips: intialTips,
        }}
        onSubmit={values =>
          onSubmit({
            creates: mungeFormikCreate(values),
            updates: mungeFormikUpdate(values),
            deletes,
          })
        }
        validationSchema={tipsSchema}
      >
        {({values, validateForm, submitForm, isValid, setTouched, errors}) => (
          <>
            <DialogTitle>Edit Tips</DialogTitle>
            <DialogContent>
              <Grid container spacing={2} style={{marginBottom: '.5em'}}>
                <Grid item>
                  <Typography variant="subtitle1">
                    Total:{' '}
                    {formatMoneyStandard(
                      values.tips.reduce((c, v) => c + v.amount, 0),
                    )}
                  </Typography>
                </Grid>
                {/* <Grid item>
                  <MuiTextField
                    name={`total`}
                    variant={'filled'}
                    type="number"
                    label="amount"
                    fullWidth
                    value={total}
                    onChange={e => setTotal(e.target.value)}
                    error={!totalMatchesFormikItems(total, values)}
                  />
                </Grid> */}
              </Grid>
              <hr />
              <Grid container spacing={2}>
                <FieldArray name="tips">
                  {({insert, remove, push, replace}) => (
                    <>
                      {values.tips.map((t, index) => (
                        <Grid container item key={t.id} spacing={2}>
                          <Grid item sm={6}>
                            <AutoCompleteField
                              as={UsersSelect}
                              name={`tips.${index}.user_id`}
                              selectedUserId={values.tips[index].user_id}
                              fullWidth
                              label="Employee"
                              inputVariant="filled"
                            />
                          </Grid>
                          <Grid item sm={4}>
                            <TextField
                              name={`tips.${index}.amount`}
                              variant={'filled'}
                              type="number"
                              label="amount"
                              fullWidth
                            />
                          </Grid>
                          <Grid item sm={2}>
                            <IconButton
                              id={'delete-' + t.id}
                              onClick={() => {
                                remove(index)
                                if (t.id.indexOf('new') === -1) {
                                  setDeletes([...deletes, {id: t.id}])
                                }
                                setTimeout(() => validateForm(values), 1) // helps revalidate correctly if you delete an invalid item
                              }}
                              size="large"
                            >
                              <Delete
                                style={{color: theme.palette.danger.main}}
                              />
                            </IconButton>
                          </Grid>
                        </Grid>
                      ))}

                      <Grid container item key={'new'}>
                        <Button
                          variant="contained"
                          color="secondary"
                          id="addTip"
                          onClick={() => {
                            push({
                              id: 'new' + newId,
                              amount: 0,
                            })
                            setNewId(newId + 1)
                            setTimeout(() => {
                              validateForm()
                            }, 1)
                          }}
                        >
                          <Add />
                        </Button>
                      </Grid>
                    </>
                  )}
                </FieldArray>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose}>Cancel</Button>
              <LoadingButton
                onClick={submitForm}
                // loading={loading}
                color={'primary'}
                disabled={!isValid}
                variant={'contained'}
              >
                Save
              </LoadingButton>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  )
}

let ApprovedIcon = () => (
  <>
    <Tooltip title="Approved">
      <VerifiedUser style={{color: 'green'}} />
    </Tooltip>
  </>
)

let Manage = ({
  date,
  day,
  rows,
  onChangeDate,
  routeId,
  onChangeRoute,
  onClickWo,
  manageMode,
  onCheck,
  onCheckAll,
  checks,
  allRowsChecked,
  someRowsChecked,
  onMakeWorkordersAction,
  onCompleteWorkordersAction,
  onOpenWorkordersAction,
  onVoidWorkordersAction,
  onMakeInvoicesAction,
  onVoidInvoicesAction,
  onPayInvoicesWithCashAction,
  onPayInvoicesWithCreditAction,
  onPrintAction,
  onPrintInvAction,
  availableActions = {},
  actionsLoading,
  tips = [],
  onSubmitTips,
}) => {
  let [sortBy, setSortBy] = useState(undefined)
  let [sortDir, setSortDir] = useState('asc')
  let [showTips, setShowTips] = useState(false)
  let [showEditIcon, setShowEditIcon] = useState(false)

  let [editAnchorEl, setEditAnchorEl] = useState(null)

  let _onSort = col => {
    if (sortBy !== col) {
      setSortBy(col)
    } else {
      switch (sortDir) {
        case 'desc':
          setSortBy(undefined)
          setSortDir('asc')
          break
        case 'asc':
          setSortDir('desc')
          break
      }
    }
  }

  let _getCharge = r =>
    r.invoice
      ? r.invoice.charge
      : r.workorder
      ? !r.workorder.multiday
        ? r.workorder.workorderitems.reduce(
            (carry, woi) => carry + woi.charge,
            0,
          )
        : r.workorder.multiday_estimated_total
      : r.schedule.services.reduce((carry, s) => carry + s.charge, 0)

  let _getTerms = r =>
    r.invoice
      ? r.invoice.terms.terms
      : !!r.workorder && !!r.workorder.terms
      ? r.workorder.terms.terms
      : r.customer.terms.terms
  let _getTermsId = r =>
    r.invoice
      ? r.invoice.terms_id
      : !!r.workorder && !!r.workorder.terms
      ? r.workorder.terms_id
      : r.customer.terms_id
  let _purchaseCardAuthorized = r =>
    Number.parseInt(_getTermsId(r)) === 6 &&
    (Number.parseInt(r.customer.terms_id) === 6 ||
      (!!r.workorder &&
        Number.parseInt(r.workorder.terms_id) === 6 &&
        r.workorder.photos.filter(p => p.type === 'SIGNATURE').length))

  let _sortFunction = (a, b) => {
    switch (sortBy) {
      case 'name':
        return sortDir === 'asc'
          ? a.customer.name.localeCompare(b.customer.name)
          : b.customer.name.localeCompare(a.customer.name)
      case 'charge':
        let aCharge = _getCharge(a)
        let bCharge = _getCharge(b)
        return sortDir === 'asc' ? aCharge - bCharge : bCharge - aCharge
      case 'workorder':
        let aId = a.workorder ? a.workorder.id : 0
        let bId = b.workorder ? b.workorder.id : 0
        return sortDir === 'asc' ? aId - bId : bId - aId
      case 'invoice':
        let aInvId = a.invoice ? a.invoice.id : 0
        let bInvId = b.invoice ? b.invoice.id : 0
        return sortDir === 'asc' ? aInvId - bInvId : bInvId - aInvId
      case 'terms':
        return sortDir === 'asc'
          ? Number.parseInt(_getTermsId(a)) - Number.parseInt(_getTermsId(b))
          : Number.parseInt(_getTermsId(b)) - Number.parseInt(_getTermsId(a))
      case 'credit':
        return sortDir === 'asc'
          ? a.customer.credit_items.reduce((carry, c) => carry + c.amount, 0) -
              b.customer.credit_items.reduce((carry, c) => carry + c.amount, 0)
          : b.customer.credit_items.reduce((carry, c) => carry + c.amount, 0) -
              a.customer.credit_items.reduce((carry, c) => carry + c.amount, 0)
      case 'sequence':
      default:
        return a.sequence - b.sequence
    }
  }

  let _filterFunction = r =>
    !r.customer.services_paused ||
    (r.customer.services_paused && (!!r.workorder || !!r.invoice))

  rows = [...rows].sort(_sortFunction).filter(_filterFunction)
  let sortedRowKeys = rows.map(({rowKey}) => rowKey)

  let paymentData = _.chain(rows)
    .map(row => {
      if (row === undefined) return false
      return row.invoice
    })
    .uniqBy(i => {
      return i && i.id
    })
    .reduce((carry, inv) => {
      if (!inv) return carry

      let key = inv.status
      carry[key] = (carry[key] || 0) + inv.charge
      return carry
    }, {})
    .value()

  let collectionData = _.chain(rows)
    .map(row => (row === undefined ? false : row.workorder))
    .filter(wo => !!wo)
    .map(wo => wo.payments)
    .flatten()
    .uniqBy(p => p && p.id)
    .reduce((carry, p) => {
      let key = p.paymenttype_id
      carry[key] = (carry[key] || 0) + p.amount
      return carry
    }, {})
    .value()

  let totalTips = tips.reduce((carry, e) => carry + e.edge.amount, 0)

  return (
    <>
      <DashboardWrapper>
        <Paper>
          <StaticDatePicker value={date} onChange={onChangeDate} />
          <div style={{margin: '10px'}}>
            <RouteSelectStatic
              value={routeId}
              onChange={e => onChangeRoute({id: e.target.value})}
              showAll={false}
            />
          </div>
          <Space size={2} />
        </Paper>
        {rows && (
          <Paper
            style={{
              overflow: 'overlay',
              background: manageMode && colors.yellow100,
            }}
          >
            <div style={{display: 'flex', margin: '15px 0px 10px 10px'}}>
              <Typography variant="body">Week {day && day.week}</Typography>
              <Typography variant="body" style={{marginLeft: '2em'}}>
                <span style={{fontWeight: 'bold'}}>Team: </span>
                {day &&
                  day.users
                    .map(user => `${user.first_name} ${user.last_name}`)
                    .join(', ')}
              </Typography>
              <div
                style={{
                  color:
                    day.reconciled && !!day.reconciliation_notes
                      ? theme.palette.danger.main
                      : undefined,
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <Typography
                  variant="body"
                  style={{
                    marginLeft: '2em',
                    // display: 'flex',
                    // flexDirection: 'row',
                    // alignItems: 'center',
                  }}
                >
                  <span style={{fontWeight: 'bold'}}>
                    Collected Cash & Checks:{' '}
                  </span>
                  <span>
                    {formatMoneyStandard(
                      (collectionData[1] || 0) + (collectionData[2] || 0),
                    )}
                  </span>
                </Typography>
                {day.reconciled && !!day.reconciliation_notes && (
                  <Tooltip
                    title={<Typography>{day.reconciliation_notes}</Typography>}
                    style={{marginLeft: '6px', marginTop: '-3px'}}
                    arrow
                  >
                    <Comment />
                  </Tooltip>
                )}
              </div>
              <Typography variant="body" style={{marginLeft: '2em'}}>
                <CustomLink
                  onClick={() => setShowTips(true)}
                  onMouseEnter={() => setShowEditIcon(true)}
                  onMouseLeave={() => setShowEditIcon(false)}
                >
                  <span style={{fontWeight: 'bold'}} id="tips">
                    Tips:&nbsp;
                  </span>{' '}
                  ${totalTips} {showEditIcon ? <EditTipsIcon /> : <></>}
                </CustomLink>
              </Typography>
            </div>

            <div style={{display: 'flex'}}>
              <Space inline size={3} />
              <Button
                onClick={e => {
                  setEditAnchorEl(e.currentTarget)
                }}
                component="nav"
                style={{textTransform: 'none'}}
                id="edit"
              >
                <Edit />
                <Space inline />
                <Typography>Edit</Typography>
                <Space inline />
                <ArrowDropDown />
              </Button>
              <Menu
                id="manage-workorders-edit-menu"
                anchorEl={editAnchorEl}
                open={Boolean(editAnchorEl)}
                onClose={() => {
                  setEditAnchorEl(null)
                }}
              >
                <Typography variant="caption" style={{marginLeft: '1em'}}>
                  Workorders
                </Typography>
                <MenuItem
                  disabled={
                    !availableActions['make-workorders'] || actionsLoading
                  }
                  onClick={onMakeWorkordersAction}
                  id="makeWorkorders"
                >
                  <MakeIcon menu /> Make
                </MenuItem>
                <MenuItem
                  disabled={
                    !availableActions['complete-workorders'] || actionsLoading
                  }
                  onClick={onCompleteWorkordersAction}
                  id="completeWorkorders"
                >
                  <CompleteIcon menu /> Complete
                </MenuItem>
                <MenuItem
                  disabled={
                    !availableActions['open-workorders'] || actionsLoading
                  }
                  onClick={onOpenWorkordersAction}
                  id="openWorkorders"
                >
                  <OpenIcon menu /> Open
                </MenuItem>
                <MenuItem
                  disabled={
                    !availableActions['void-workorders'] || actionsLoading
                  }
                  onClick={onVoidWorkordersAction}
                  id="voidWorkorders"
                >
                  <VoidIcon menu /> Void
                </MenuItem>
                <hr></hr>
                <Typography variant="caption" style={{marginLeft: '1em'}}>
                  Invoices
                </Typography>
                <MenuItem
                  disabled={
                    !availableActions['make-invoices'] || actionsLoading
                  }
                  onClick={onMakeInvoicesAction}
                  id="makeInvoices"
                >
                  <MakeIcon menu /> Make
                </MenuItem>
                <MenuItem
                  disabled={
                    !availableActions['void-invoices'] || actionsLoading
                  }
                  onClick={onVoidInvoicesAction}
                  id="voidInvoices"
                >
                  <VoidIcon menu /> Void
                </MenuItem>
                <MenuItem
                  disabled={!availableActions['pay-invoices'] || actionsLoading}
                  onClick={onPayInvoicesWithCashAction}
                  id="payCash"
                >
                  <PaidIcon menu /> Pay Cash
                </MenuItem>
                <MenuItem
                  disabled={
                    !availableActions['credit-invoices'] || actionsLoading
                  }
                  onClick={onPayInvoicesWithCreditAction}
                  id="payCredit"
                >
                  <CreditIcon menu /> Apply Credit
                </MenuItem>
              </Menu>
              <Button
                component="nav"
                style={{textTransform: 'none'}}
                onClick={onPrintAction}
                disabled={
                  !availableActions['print-workorders'] || actionsLoading
                }
                id="print"
              >
                <Print />
                <Space inline />
                <Typography>Print Workorders</Typography>
              </Button>
              <Button
                component="nav"
                style={{textTransform: 'none'}}
                onClick={onPrintInvAction}
                disabled={!availableActions['print-invoices'] || actionsLoading}
                id="print"
              >
                <Print />
                <Space inline />
                <Typography>Print Invoices</Typography>
              </Button>
            </div>
            <Table
              style={{tableLayout: 'fixed', minWidth: '1200px'}}
              size="small"
            >
              <TableHead>
                <TableRow>
                  <TinyCell>
                    <Checkbox
                      checked={allRowsChecked}
                      onClick={onCheckAll}
                      indeterminate={someRowsChecked && !allRowsChecked}
                      name="allbox"
                    />
                  </TinyCell>
                  <WoCell colSpan={1}>
                    <TableSortLabel
                      active={sortBy === 'workorder'}
                      direction={sortDir}
                      onClick={() => _onSort('workorder')}
                    >
                      Workorders
                    </TableSortLabel>
                  </WoCell>
                  <WoCell colSpan={1}>
                    <TableSortLabel
                      active={sortBy === 'invoice'}
                      direction={sortDir}
                      onClick={() => _onSort('invoice')}
                    >
                      Invoices
                    </TableSortLabel>
                  </WoCell>
                  <TableCell>
                    <TableSortLabel
                      active={sortBy === 'name'}
                      direction={sortDir}
                      onClick={() => _onSort('name')}
                    >
                      Customer Name
                    </TableSortLabel>
                  </TableCell>
                  <SlightlyTinyCell>
                    <TableSortLabel
                      active={sortBy === 'charge'}
                      direction={sortDir}
                      onClick={() => _onSort('charge')}
                    >
                      Charge
                    </TableSortLabel>
                  </SlightlyTinyCell>
                  <SlightlyTinyCell>
                    <TableSortLabel
                      active={sortBy === 'terms'}
                      direction={sortDir}
                      onClick={() => _onSort('terms')}
                    >
                      Terms
                    </TableSortLabel>
                  </SlightlyTinyCell>
                  <SlightlyTinyCell>Balance</SlightlyTinyCell>
                  <SlightlyTinyCell>
                    <TableSortLabel
                      active={sortBy === 'credit'}
                      direction={sortDir}
                      onClick={() => _onSort('credit')}
                    >
                      Credit
                    </TableSortLabel>
                  </SlightlyTinyCell>
                  <SmallCell>Last Serviced</SmallCell>
                  <SmallCell>Next Service</SmallCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map(
                  ({
                    workorder,
                    invoice,
                    schedule,
                    customer,
                    sequence,
                    rowKey,
                  }) => {
                    return (
                      <TableRow key={rowKey} selected={!!checks[rowKey]}>
                        <SmallCell>
                          <Checkbox
                            checked={!!checks[rowKey]}
                            onClick={e => onCheck({e, rowKey, sortedRowKeys})}
                            name={rowKey}
                            color="secondary"
                          />
                        </SmallCell>
                        <DenseTableCell>
                          <FlexCellContents>
                            {workorder && (
                              <Workorder
                                wo={workorder}
                                onClick={() => onClickWo(workorder)}
                                po_needed={customer.po_needed}
                              />
                            )}
                          </FlexCellContents>
                        </DenseTableCell>
                        <DenseTableCell>
                          <FlexCellContents>
                            {workorder && !workorder.is_billable && (
                              <PillBoxDisabled>
                                &nbsp;Not billable&nbsp;
                              </PillBoxDisabled>
                            )}
                            {invoice && <Invoice inv={invoice} />}
                          </FlexCellContents>
                        </DenseTableCell>
                        <DenseTableCell>
                          <Link
                            to={`/customers/${customer.id}/workorders/manage`}
                          >
                            {customer.name}
                          </Link>
                        </DenseTableCell>
                        <DenseTableCell>
                          {!invoice && workorder && workorder.multiday && '~'}
                          {workorder && !workorder.is_billable ? (
                            <MoneyOff style={{opacity: '.4'}} />
                          ) : (
                            formatMoneyStandard(
                              _getCharge({schedule, invoice, workorder}),
                            )
                          )}
                        </DenseTableCell>
                        <DenseTableCell>
                          {workorder && !workorder.is_billable ? (
                            <MoneyOff style={{opacity: '.4'}} />
                          ) : (
                            _getTerms({schedule, invoice, workorder, customer})
                          )}
                          {_purchaseCardAuthorized({
                            schedule,
                            invoice,
                            workorder,
                            customer,
                          }) ? (
                            <ApprovedIcon />
                          ) : (
                            ''
                          )}
                        </DenseTableCell>
                        <DenseTableCell>
                          <Link to={`/customers/${customer.id}/transactions`}>
                            {formatMoneyStandard(customer.balances.balance)}
                          </Link>
                        </DenseTableCell>
                        <DenseTableCell>
                          {/* render an icon with a tooltip showing the concatenated notes on credit_payments */}
                          <Tooltip
                            title={customer.credit_payments
                              .filter(p => !!p.notes)
                              .map(p => p.notes)
                              .join(' | ')}
                          >
                            <StyledBadge
                              badgeContent={
                                customer.credit_payments.filter(p => !!p.notes)
                                  .length > 0 && <Comment />
                              }
                            >
                              {formatMoneyStandard(
                                customer.credit_items.reduce(
                                  (carry, ci) => carry + ci.amount,
                                  0,
                                ),
                              )}
                            </StyledBadge>
                          </Tooltip>
                        </DenseTableCell>
                        <DenseTableCell>
                          {customer && customer.last_routerun && (
                            <Link
                              to={`/schedules/daily?date=${moment(
                                customer.last_routerun.run_at,
                              ).format('YYYY-MM-DD')}&routes=${
                                customer.last_routerun.route_id
                              }`}
                            >
                              {moment(customer.last_routerun.run_at).format(
                                'ddd, MMM D',
                              )}
                            </Link>
                          )}
                        </DenseTableCell>
                        <DenseTableCell>
                          {customer && customer.next_routerun && (
                            <Link
                              to={`/schedules/daily?date=${moment(
                                customer.next_routerun.run_at,
                              ).format('YYYY-MM-DD')}&routes=${
                                customer.next_routerun.route_id
                              }`}
                            >
                              {moment(customer.next_routerun.run_at).format(
                                'ddd, MMM D',
                              )}
                            </Link>
                          )}
                        </DenseTableCell>
                      </TableRow>
                    )
                  },
                )}
                {rows.length ? (
                  <SummaryFooter
                    paymentData={paymentData}
                    onSubmitTips={onSubmitTips}
                  />
                ) : (
                  <></>
                )}
              </TableBody>
            </Table>
          </Paper>
        )}
      </DashboardWrapper>
      <EditTipsDialog
        key={'tips' + showTips}
        isOpen={showTips}
        onClose={() => setShowTips(false)}
        tips={tips}
        onSubmit={val => onSubmitTips(val).then(() => setShowTips(false))}
      />
    </>
  )
}

export default Manage
