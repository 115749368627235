// @flow
import {useCallback, useRef, useEffect, useState} from 'react'
import {setTimeout} from 'optimism'
import moment from 'moment'
import {round} from 'lodash'
import {fetchAPI} from '../schema/utils'
export function formatPhone(num) {
  if (!num) return ''

  const phone = num.slice(-10)
  const trunk = num.length > 10 && num.slice(-11, -10)
  const numRe = /(\d{3})(\d{3})(\d{4})/
  const parsed = numRe.exec(phone)
  if (!parsed || !parsed.length) return ''

  const [, area, prefix, line] = parsed
  return `${trunk ? trunk + ' ' : ''}(${area}) ${prefix}-${line}`
}

export function useDebounceCallback(callback, delay) {
  const functionTimeoutHandler = useRef(null)

  const cancelDebouncedCallback = useCallback(() => {
    clearTimeout(functionTimeoutHandler.current)
    functionTimeoutHandler.current = null
  }, [])

  useEffect(() => () => cancelDebouncedCallback(), [cancelDebouncedCallback])

  const debouncedCallback = useCallback(() => {
    cancelDebouncedCallback()

    functionTimeoutHandler.current = setTimeout(() => {
      callback()
    }, delay)
  }, [callback, delay, cancelDebouncedCallback])

  return [debouncedCallback, {cancel: cancelDebouncedCallback}]
}

export function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value)
  const updateValue = useCallback(() => setDebouncedValue(value), [value])
  const [debouncedSet, {cancel}] = useDebounceCallback(updateValue, delay)
  const previousValue = useRef(value)

  useEffect(() => {
    // make sure we don't call debounce on first render
    if (previousValue.current !== value) {
      debouncedSet(value)
      previousValue.current = value
    }
  }, [value, debouncedSet])

  return [debouncedValue, {cancel}]
}

export function getDatesBetweenDates(startDate, endDate) {
  let dates = []
  let theDate = moment.utc(startDate)
  while (theDate.isBefore(moment.utc(endDate))) {
    dates = [...dates, theDate.toDate()]
    theDate = theDate.add(1, 'days')
  }
  return dates
}

export function datesAreOnSameDay(first, second, thing) {
  return (
    first.getFullYear() === second.getFullYear() &&
    first.getMonth() === second.getMonth() &&
    first.getDate() === second.getDate()
  )
}

export function print(endpoint) {
  const response = fetchAPI({
    url: endpoint,
    options: {method: 'GET', headers: {accept: 'application/pdf'}},
  })
  response.then(async res => {
    const blob = await res.blob()
    const data_url = URL.createObjectURL(blob)
    window.open(data_url, '_blank')
  })
}

export function csv(endpoint) {
  const response = fetchAPI({
    url: endpoint,
    options: {method: 'GET', headers: {accept: 'text/csv'}},
  })
  response.then(async res => {
    const blob = await res.blob()
    const data_url = URL.createObjectURL(blob)
    window.open(data_url, '_blank')
  })
}

export function discountedCharge(woi) {
  let charge = woi.charge
  if (woi.discount) {
    if (woi.discount.type === 2) {
      charge = woi.charge - woi.discount.amount
    } else {
      charge = woi.charge - woi.charge * (woi.discount.amount / 100)
    }
  }
  return round(charge, 2)
}

export function getUsablePhotoUrl(photo, largest = false) {
  let sizes = ['thumb_url', 'medium_url', 'large_url', 'url']

  if (largest) {
    sizes = sizes.reverse()
  }

  for (const size of sizes) {
    if (photo[size]) {
      return photo[size]
    }
  }

  return null
}

// ChatGippity
export function getRandomString(num = 12) {
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  let result = ''
  for (let i = 0; i < num; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length))
  }
  return result
}

export function mungeFormikRelationshipUpdate<
  RelName extends string,
  RelData extends Array<{ id: string }>
>({
  reldata = [],
  relname,
  origData,
}: {
  reldata: RelData | [];
  relname: RelName;
  origData: {
    [key in RelName]: RelData | [];
  } & Record<string, any>;
}) {
  // good golly this is so much easier than what i was doing before
  const ids = reldata.map(p => p.id);
  const creates = reldata
    .filter((p) => p.id.includes("new"))
    .map(({ id, ...p }) => p);
  const updates = reldata.filter((p) => !p.id.includes("new"));
  const deletes = (origData[relname] || [])
    .filter((p) => !ids.includes(p.id))
    .map((p) => ({ id: p.id }));

  return {
    [relname]: {
      create: creates,
      update: updates,
      destroy: deletes,
    },
  };
}

export function mungeFormikRelationshipAssociate<
  RelName extends string,
  RelData extends Array<{ id: string }>
>({
  reldata = [],
  relname,
  origData,
}: {
  reldata: RelData | [];
  relname: RelName;
  origData: {
    [key in RelName]: RelData | [];
  } & Record<string, any>;
}) {
  // good golly this is so much easier than what i was doing before
  const set = reldata.filter(p => !!p.id).map(p => ({id: p.id}));

  return {
    [relname]: {
      set
    },
  };
}

type LatitudeRef = 'N' | 'S';
type LongitudeRef = 'E' | 'W';

interface GPSCoordinate {
  description: number;
}

interface GPSReference<T extends string> {
  value: [T];
}

interface GPSMetadata {
  GPSLatitude: GPSCoordinate;
  GPSLongitude: GPSCoordinate;
  GPSLatitudeRef: GPSReference<LatitudeRef>;
  GPSLongitudeRef: GPSReference<LongitudeRef>;
}

export function getGPSCoordsFromMetadata({
  GPSLatitude,
  GPSLongitude,
  GPSLatitudeRef,
  GPSLongitudeRef
}: GPSMetadata): { lat: number; lng: number } {
  function orient(ref: LatitudeRef | LongitudeRef): number {
    switch (ref) {
      case 'N':
      case 'E':
        return 1;
      case 'S':
      case 'W':
        return -1;
      default:
        return 1;
    }
  }

  const lat = GPSLatitude.description * orient(GPSLatitudeRef.value[0]);
  const lng = GPSLongitude.description * orient(GPSLongitudeRef.value[0]);

  return { lat, lng };
}
