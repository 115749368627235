import React, {useEffect, useState, useRef} from 'react'
import {Toolbar} from '../../AppHandler'
import ToolbarGroup from '../../components/ToolbarGroup'
import ToolbarMegaTitle from '../../components/ToolbarMegaTitle'
import styled from 'styled-components'
import {
  Typography,
  LinearProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  MenuItem,
  CircularProgress,
  InputLabel,
  TextField,
} from '@mui/material'
import {Space} from '../../components/Layout'
import {fetchAPI} from '../../schema/utils'
import moment from 'moment'
import {useInfiniteQuery} from 'react-query'
import queryString from 'query-string'
import ModifyQueryParams from '../../components/ModifyQueryParams'
import {UserSelectDynamic} from '../../components/UsersSelect'
import {formatMoneyStandard} from '../../utils/moneyFormatter'
import {Link} from '../../pr-router'
import {DatePicker} from '@mui/lab'
import {ArrowForward} from '@mui/icons-material'
import SaleItemSelect from '../../components/SaleItemSelect'

let SummaryWrapper = styled.div`
  page-break-after: always;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
`

let TableWrapper = styled.div`
  margin-top: 2em;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  // max-height: 400px; /* Set a fixed height for scrolling */
  overflow-y: auto; /* Enable vertical scrolling */
`

let getData = async ({pageParam = 1, saleitem_ids, user_id}) => {
  let data = await fetchAPI({
    url: '/reports/estimates_report',
    query: {
      page: pageParam, // Add page param for pagination
      user_id,
      saleitem_ids,
    },
  })
  return JSON.parse(await data.text())
}

let munge = ({user_id, saleitem_ids}) => {
  user_id = !user_id ? undefined : user_id
  saleitem_ids = !saleitem_ids ? undefined : saleitem_ids
  return {user_id, saleitem_ids}
}

const EstimateReport = () => {
  const [queryChanges, setQueryChanges] = useState(
    queryString.parse(location.search),
  )
  const observerRef = useRef(null)
  const lastRowRef = useRef(null)

  let params = queryString.parse(location.search)
  let {user_id, saleitem_ids} = munge(params)

  const {
    isLoading,
    error,
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery(
    ['estimatesReport', user_id, saleitem_ids],
    ({pageParam}) => getData({pageParam, user_id, saleitem_ids}),
    {
      getNextPageParam: (lastPage, allPages) => {
        // Assuming your API returns something like { data: [], totalPages }
        // Adjust this logic based on your API's response
        return lastPage.length > 0 ? allPages.length + 1 : undefined
      },
    },
  )

  // Set up Intersection Observer
  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        if (entries[0].isIntersecting && hasNextPage && !isFetchingNextPage) {
          fetchNextPage()
        }
      },
      {threshold: 0.1}, // Trigger when 10% of the last row is visible
    )

    if (lastRowRef.current) {
      observer.observe(lastRowRef.current)
    }

    observerRef.current = observer

    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect()
      }
    }
  }, [data, hasNextPage, isFetchingNextPage, fetchNextPage])

  return (
    <>
      <ModifyQueryParams query={queryChanges} />
      <Toolbar>
        <ToolbarMegaTitle>All Estimates</ToolbarMegaTitle>
        <Space inline size={2} />
        <ToolbarGroup last>
          <UserSelectDynamic
            selectedId={user_id}
            onChange={(e, u) =>
              setQueryChanges({
                ...queryChanges,
                user_id: u?.id || '',
              })
            }
            sx={{width: 200}}
            inputProps={{
              variant: 'outlined',
              size: 'small',
              margin: 'dense',
            }}
            label={'user'}
          />
          <Space inline size={2} />
          <SaleItemSelect
            onChange={e =>
              setQueryChanges({...queryChanges, saleitem_ids: e?.id ?? ''})
            }
            selectedId={saleitem_ids}
            inputVariant="outlined"
            inputSize="small"
          />
        </ToolbarGroup>
      </Toolbar>

      {isLoading && <LinearProgress />}

      {error && <p>Error: {error.message}</p>}

      {data && (
        <>
          {/* <SummaryWrapper> */}
          <TableWrapper>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Id</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Services</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>User</TableCell>
                  <TableCell>Price</TableCell>
                  <TableCell>Created</TableCell>
                  <TableCell>Schedules</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.pages.map((page, pageIndex) =>
                  page.map((row, rowIndex) => {
                    const isLastRow =
                      pageIndex === data.pages.length - 1 &&
                      rowIndex === page.length - 1
                    return (
                      <TableRow
                        key={row.id}
                        ref={isLastRow ? lastRowRef : null}
                      >
                        <TableCell>{row.id}</TableCell>
                        <TableCell>
                          <Link to={row.url}>{row.name}</Link>
                        </TableCell>
                        <TableCell>{row.services}</TableCell>
                        <TableCell>{row.description}</TableCell>
                        <TableCell>{row.user}</TableCell>
                        <TableCell>{formatMoneyStandard(row.price)}</TableCell>
                        <TableCell>
                          {moment(row.created_at).format('dddd, MMMM Do')}
                        </TableCell>
                        <TableCell>{row.schedules_count}</TableCell>
                      </TableRow>
                    )
                  }),
                )}
              </TableBody>
            </Table>
            {isFetchingNextPage && (
              <div style={{textAlign: 'center', padding: '16px'}}>
                <CircularProgress />
              </div>
            )}
          </TableWrapper>
          {/* </SummaryWrapper> */}
        </>
      )}
    </>
  )
}

export default EstimateReport
