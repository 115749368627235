import {
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  LOGOUT_SUCCESS,
  LOGOUT_ERROR,
  LOGIN_REDIRECT,
} from '../constants/ActionTypes'
import axios from 'axios'
import { API_DOMAIN } from '../middleware/api'
import I from 'immutable'

export const ADD_SECURITY_RETRY_QUEUE = 'ADD_SECURITY_RETRY_QUEUE'
export const RETRY_SECURITY_RETRY_QUEUE = 'CLEAR_SECURITY_RETRY_QUEUE'

function saveCredentials({ token }) {
  window.localStorage.setItem('token', token)
}

function clearCredentials() {
  window.localStorage.removeItem('token')
}

function clearPersistedCache() {
  // const client = useApolloClient();

  // // Get the persisted cache data from local storage
  // const persistedData = new PersistedData({ storage: window.localStorage });

  // // Clear the persisted cache data
  // persistedData.delete(client.cache);

  window.localStorage.removeItem('apollo-cache-persist');
}

export function login(username, password) {
  return dispatch => {
    axios
      .post(
        API_DOMAIN + '/login',
        { username, password },
        {
          withCredentials: true,
        },
      )
      .then(credentials => {
        saveCredentials({ token: credentials.data.access_token })

        dispatch({ type: LOGIN_SUCCESS })
        dispatch(retrySecurityQueue())
      })
      .catch(res => {
        if (res instanceof Error) {
          // check if the API wants to redirect us somewhere
          if (res.response.data.errors[0].code === 'S-6') {
            dispatch({ type: LOGIN_REDIRECT, payload: res.response.data.errors[0].detail })
          } else {
            dispatch({ type: LOGIN_ERROR, error: res.response.data.error })
          }
        } else {
          dispatch({ type: LOGIN_ERROR, error: res.data.error })
        }
      })
  }
}

export function logout() {
  return dispatch => {
    axios
      .get(API_DOMAIN + '/logout', {
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then(user => {
        clearCredentials()

        clearPersistedCache()

        dispatch({ type: LOGOUT_SUCCESS, payload: I.Map() })
      })
      .catch(res => {
        if (res instanceof Error) {
          dispatch({ type: LOGOUT_ERROR, error: res.response.data.message })
          console.error(res)
        } else {
          dispatch({ type: LOGOUT_ERROR, error: res.data.message })
        }
      })
  }
}

export function addSecurityRetryQueue(retryItem) {
  return { type: ADD_SECURITY_RETRY_QUEUE, payload: retryItem }
}

export function retrySecurityQueue() {
  return (dispatch, getState) => {
    const queue = getState().auth.get('retryQueue')
    dispatch({ type: RETRY_SECURITY_RETRY_QUEUE })
    return queue.map(retryItem => retryItem.retry())
  }
}
