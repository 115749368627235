import PropTypes from 'prop-types'
import React, {Component} from 'react'
import styled from 'styled-components'
import {Caption, Subhead, Display1} from './Typography'
import {Typography} from '@mui/material'

let DataBlockContainer = styled.div`
  padding: 0 16px;
  margin: 16px 0;
`

let DenseDataBlockContainer = styled.div`
  padding: 0 5px;
  margin: 5px 0;
`

let HorizontalDataBlockContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  margin-bottom: 8px;
`

export class HorizontalDataBlock extends Component {
  static propTypes = {
    label: PropTypes.node,
    data: PropTypes.node,
    align: PropTypes.oneOf(['left', 'center', 'right']),
    size: PropTypes.oneOf(['normal', 'large']),
    multiline: PropTypes.bool,
    valueId: PropTypes.string,
  }

  static defaultProps = {align: 'left', size: 'normal'}

  render() {
    let {align, label, data, size, style, multiline} = this.props
    const DataElement = size === 'large' ? Display1 : Subhead
    return (
      <HorizontalDataBlockContainer style={style}>
        <Caption display="block" style={{textAlign: align, fontSize: '14px'}}>
          {label}
        </Caption>
        <DataElement
          display="block"
          style={{
            textAlign: align,
            lineHeight: '1em',
            wordBreak: 'break-word',
            whiteSpace: multiline ? 'pre-wrap' : 'normal',
          }}
          id={this.props.valueId}
        >
          {data}
        </DataElement>
      </HorizontalDataBlockContainer>
    )
  }
}

class DataBlock extends Component {
  static propTypes = {
    label: PropTypes.node,
    data: PropTypes.node,
    align: PropTypes.oneOf(['left', 'center', 'right']),
    size: PropTypes.oneOf(['normal', 'large']),
    multiline: PropTypes.bool,
    valueId: PropTypes.string,
    spacing: PropTypes.oneOf(['normal', 'dense']),
  }

  static defaultProps = {align: 'left', size: 'normal'}

  render() {
    let {align, label, data, size, style, multiline, spacing} = this.props
    const DataElement = size === 'large' ? Display1 : Subhead
    const Container =
      spacing === 'dense' ? DenseDataBlockContainer : DataBlockContainer
    return (
      <Container style={{...style}}>
        <Caption
          display="block"
          style={{textAlign: align, fontSize: '14px', marginBottom: '8px'}}
        >
          {label}
        </Caption>
        <DataElement
          display="block"
          style={{
            textAlign: align,
            lineHeight: '1em',
            wordBreak: 'break-word',
            whiteSpace: multiline ? 'pre-wrap' : 'normal',
          }}
          id={this.props.valueId}
        >
          {data}
        </DataElement>
      </Container>
    )
  }
}

export default DataBlock
