import React, {useEffect, useLayoutEffect, useRef, useState} from 'react'

import Button from '@mui/material/Button'
import {
  Badge,
  ButtonGroup,
  Chip,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Tooltip,
} from '@mui/material'
import Typography from '@mui/material/Typography'
import {
  Loop as RepeatIcon,
  Event as RepeatOneIcon,
  Receipt,
  Person,
  ArrowForward,
  ArrowForwardRounded,
  CancelOutlined,
  Room,
  Assignment,
  Comment,
  Error,
  Email,
  CheckCircle,
  ModeComment,
  AssignmentLate,
  VpnKey,
  PermPhoneMsg,
  WaterDrop,
} from '@mui/icons-material'

import {TableCell, LinearProgress} from '@mui/material'
import styled from 'styled-components'
import gql from 'graphql-tag'
import {useQuery} from 'react-apollo'
import {
  useQuery as useReactQuery,
  useMutation as useReactQueryMutation,
} from 'react-query'
import _ from 'lodash'
import moment from 'moment'
import {formatMoneyStandard} from '../../utils/moneyFormatter'
import {Brightness2, Brightness5} from '@mui/icons-material'
import colors from '../../styles/colors'
import {Dialog} from '@mui/material'
import LoadingButton from '../../components/LoadingButton'
import {Formik} from 'formik'
import {
  AutoCompleteField,
  DatePicker,
  MultiSelect,
  TextField,
} from '../../components/forms'
import {useMutation} from 'react-apollo'
import {darken, lighten} from '@mui/material/styles'
import {Space} from '../../components/Layout'
import {Link} from 'react-router-dom'
import {useDrag, useDrop} from 'react-dnd'
import {prgql} from '../../utils/graphql'
import {blue, amber as color} from '@mui/material/colors'
import {position} from 'polished'
import theme from '../../styles/theme'
import {TimePicker} from '../../components/forms'
import {TimePicker as MuiTimePicker} from '@mui/lab'
import RouteSelect from '../../components/RouteSelect'
import {useAutoCompleteEndpoint} from '../../components/AutoComplete'
import DangerLoadingButton from '../../components/DangerLoadingButton'
import Workorder from '../Workorder'
import {discountedCharge} from '../../utils'
import Cone from '../../icons/Cone'

const DragTypes = {
  USER: 'users',
  ASSET: 'assets',
  SCHEDULE: 'schedules',
  WORKORDER: 'workorders',
}

const DayNumbers = {
  1: 'Sunday',
  2: 'Monday',
  3: 'Tuesday',
  4: 'Wednesday',
  5: 'Thursday',
  6: 'Friday',
  7: 'Saturday',
}

let DayCell = styled(TableCell).attrs(() => ({
  classes: {
    root: 'root',
  },
}))`
  vertical-align: top;
  cursor: pointer;
  padding-left: 0.5em;
  padding-right: 0.5em;
  :hover {
    background-color: ${colors.grey100} !important;
  }
`

let SchedulesCell = styled(TableCell)`
  vertical-align: top;
  background-color: ${colors.grey200};
  padding-left: 1.5em;
  padding-right: 0.5em;

  height: 1px;

  @-moz-document url-prefix() {
    height: 100%;
  }
`

export let ProgressBar = styled(LinearProgress).attrs(() => ({
  classes: {
    root: 'root',
    barColorPrimary: 'colorPrimary',
    barColorSecondary: 'colorSecondary',
  },
}))`
  &.root {
    flex-grow: 1;
    height: 30px;
    background-color: ${({theme}) => theme.palette.info.light};
    border-radius: 8px;
    padding: 0.25em;
    margin-bottom: 0.25em;
  }

  & .colorPrimary {
    background-color: ${({theme}) => theme.palette.info.main};
  }

  & .colorSecondary {
    background-color: ${({theme}) => colors.blue100};
  }
`

let CustomizableProgressBar = styled(LinearProgress).attrs(() => ({
  classes: {
    root: 'root',
    barColorPrimary: 'colorPrimary',
    barColorSecondary: 'colorSecondary',
  },
}))`
  &.root {
    flex-grow: 1;
    height: 30px;
    background-color: ${({theme, baseColor}) =>
      baseColor || theme.palette.info.light};
    border-radius: 8px;
    padding: 0.25em;
    margin-bottom: 0.25em;
  }

  & .colorPrimary {
    background-color: ${({theme, barColor}) =>
      barColor || theme.palette.info.main};
  }
`

export let Bar = ({value, color, display, big, custom = false}) => {
  return (
    <div
      style={{
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div style={{position: 'absolute', zIndex: '100'}}>{display}</div>
      {custom ? (
        <CustomizableProgressBar
          variant="determinate"
          value={value}
          barColor={color}
          style={{height: big && '45px'}}
        />
      ) : (
        <ProgressBar
          variant="determinate"
          value={value}
          color={color}
          style={{height: big && '45px'}}
        />
      )}
    </div>
  )
}

let PillBox = styled.div`
  border-radius: 8px;
  padding: 0.25em;
  margin-bottom: 0.25em;
  text-align: center;
  display: flex;
  align-items: center
  justify-content: center
  flex-direction: ${({direction}) => direction || 'inherit'} 
`

let StyledBadge = styled(Badge)`
  text-align: center;
  display: flex;
  flex: 1;
`

let StyledSuccessBadge = styled(Badge).attrs(() => ({
  classes: {
    root: 'root',
    badge: 'badge',
  },
}))`
  text-align: center;
  display: flex;
  flex: 1;
  & .badge {
    background-color: ${({theme}) => theme.palette.success.main};
  }
`

let CustomBadge = styled.div`
  background-color: ${({color}) => color || 'red'}
  border-radius: 10px;
  font-size: 0.75rem;
  color: white;
  min-width: 20px;
  height: 20px;
  display: flex;
  padding: 0 6px;
  flex-wrap: wrap;
  box-sizing: border-box;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  line-height: 1;
  font-weight: 500;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  margin: 0 2px;
`

export let PillBoxEmployee = styled(PillBox)`
  background-color: ${({theme, unavailable}) =>
    !unavailable ? theme.palette.primary.main : colors.grey500};
  color: ${({theme}) => theme.palette.primary.contrastText};
  cursor: ${({unavailable}) => (!unavailable ? 'grab' : 'inherit')};
  :hover {
    background-color: ${({theme, unavailable}) =>
      !unavailable ? darken(theme.palette.primary.main, 0.2) : colors.grey500};
  }
`

export let ChipEmployee = styled(Chip)`
  background-color: ${({theme}) => theme.palette.primary.main};
  color: ${({theme}) => theme.palette.primary.contrastText};
`

export let ChipAsset = styled(Chip).attrs(props => ({
  className: props.className,
}))`
  background-color: ${({theme, className}) =>
    className === 'powerwasher'
      ? theme.palette.primary.lighter
      : theme.palette.primary.light};
  color: ${({theme}) => theme.palette.primary.contrastText};

  & .powerwasher {
    background-color: red;
  }
`

let PillBoxAsset = styled(PillBox).attrs(props => ({
  className: props.className,
}))`
  background-color: ${({theme, className}) =>
    className === 'powerwasher'
      ? theme.palette.primary.lighter
      : theme.palette.primary.light};
  color: ${({theme}) => theme.palette.primary.contrastText};
  cursor: grab;
  :hover {
    background-color: ${({theme, className}) =>
      className === 'powerwasher'
        ? darken(theme.palette.primary.lighter, 0.2)
        : darken(theme.palette.primary.light, 0.2)};
  }
  margin-right: 0.2em;
  margin-bottom: 0.2em;

  & .powerwasher {
    background-color: red;
  }

  & .powerwasher :hover {
    background-color: ${({theme}) =>
      darken(theme.palette.primary.lighter, 0.2)};
  }
`

let PillBoxResidential = styled(PillBox)`
  background-color: ${({theme, exclusion, disabled}) =>
    disabled
      ? colors.grey300
      : exclusion
      ? colors.blue400
      : theme.palette.calendar.residential};
  cursor: pointer;
  :hover {
    background-color: ${({theme}) =>
      darken(theme.palette.calendar.residential, 0.2)};
  }
  color: ${({theme, disabled}) =>
    disabled ? colors.grey500 : theme.palette.primary.main}
  border-width: 1px;
  border-style: solid;
  border-color: ${({theme, disabled}) =>
    disabled ? colors.grey500 : theme.palette.primary.main}
`

let PillBoxCommercial = styled(PillBox).attrs(props => ({
  exclusion: props.exclusion,
}))`
  background-color: ${({theme, exclusion, disabled}) =>
    disabled
      ? colors.grey300
      : exclusion
      ? colors.blue400
      : theme.palette.calendar.commercial};
  cursor: pointer;
  :hover {
    background-color: ${({theme}) =>
      darken(theme.palette.calendar.commercial, 0.2)};
  }
  color: ${({theme, disabled}) =>
    disabled ? colors.grey500 : theme.palette.primary.main}
  border-width: 1px;
  border-style: solid;
  border-color: ${({theme, disabled}) =>
    disabled ? colors.grey500 : theme.palette.primary.main}
`

let PillBoxLargeCommercial = styled(PillBox)`
  background-color: ${({theme, exclusion, disabled}) =>
    disabled
      ? colors.grey300
      : exclusion
      ? colors.blue400
      : theme.palette.calendar.commercial};
  color: ${({theme}) => theme.palette.primary.contrastText};
  cursor: pointer;
  :hover {
    background-color: ${({theme}) =>
      darken(theme.palette.calendar.commercial, 0.2)};
  }
  color: ${({theme, disabled}) =>
    disabled ? colors.grey500 : theme.palette.primary.main}
  border-width: 1px;
  border-style: solid;
  border-color: ${({theme, disabled}) =>
    disabled ? colors.grey500 : theme.palette.primary.main}
`

export let PillBoxInvoice = styled(PillBox)`
  flex: 1;
  justify-content: space-around;
  background-color: ${({theme}) => theme.palette.calendar.invoice};
  cursor: pointer;
  :hover {
    background-color: ${({theme}) =>
      darken(theme.palette.calendar.invoice, 0.2)};
  }
`
export let PillBoxWorkorder = styled(PillBox)`
  background-color: ${({theme, multiday, disabled}) =>
    disabled
      ? colors.grey300
      : multiday
      ? theme.palette.calendar.multiday_workorder
      : theme.palette.calendar.workorder};
  cursor: pointer;
  border-width: 1px;
  border-style: solid;
  border-color: ${({theme, multiday, disabled}) =>
    disabled
      ? colors.grey500
      : multiday
      ? theme.palette.calendar.multiday_workorder_stroke
      : theme.palette.calendar.workorder_stroke};
  color: ${({theme, disabled}) =>
    disabled ? colors.grey500 : theme.palette.primary.main}
  :hover {
    background-color: ${({theme, multiday}) =>
      darken(
        multiday
          ? theme.palette.calendar.multiday_workorder
          : theme.palette.calendar.workorder,
        0.2,
      )};
  }
`
export let PillBoxEmpty = styled(PillBox)`
  border-width: 2px;
  border-color: ${({theme}) => colors.blue100};
  border-style: solid;
  background-color: ${({theme}) => theme.palette.info.light};
`

export let PillBoxDisabled = styled(PillBox)`
  border-width: 2px;
  border-color: ${() => colors.grey300};
  border-style: dashed;
  background-color: ${({theme}) => theme.palette.info.light};
  color: ${() => colors.grey600};
`

let DraggablePillbox = ({self, on, as, onUpdate, canDrag = () => true}) => {
  const [{isDragging}, drag] = useDrag(
    () => ({
      type: self.type,
      item: {...self, on},

      collect: monitor => ({
        isDragging: !!monitor.isDragging(),
      }),
      end: (item, monitor) => onUpdate({item, monitor}),
      canDrag,
    }),
    [self, on],
  )

  let Comp = as

  return (
    <Comp
      drag={drag}
      style={{
        opacity: isDragging ? 0.5 : 1,
        filter: !canDrag() ? 'grayscale(50%)' : undefined,
      }}
    />
  )
}

let MoveEmployeeMutation = gql`
  mutation update($input: [UpdateManyRouteRunsInput]) {
    updateManyRouteRuns(input: $input) {
      routeRuns {
        id
        users {
          id
        }
      }
    }
  }
`

export function DraggableEmployee({
  id,
  parent,
  on,
  onUpdate,
  disableDrag,
  unavailable = false,
  ...props
}) {
  let [update] = useMutation(MoveEmployeeMutation)
  let self = {id, type: DragTypes.USER}
  return (
    <DraggablePillbox
      self={self}
      on={on}
      onUpdate={({item, monitor}) => {
        let destination = monitor.getDropResult()
        if (destination) {
          let input = []

          if (parent.id) {
            input.push({
              id: parent.id,
              [self.type]: {
                remove: [{id: self.id}],
              },
            })
          }

          if (destination.id) {
            input.push({
              id: destination.id,
              [self.type]: {
                create: [{id: self.id}],
              },
            })
          }

          update({
            variables: {
              input,
            },
          }).then(() => {
            if (typeof onUpdate === 'function')
              onUpdate({self, parent, destination})
          })
        }
      }}
      as={({drag, ...p}) => (
        <PillBoxEmployee
          ref={!disableDrag ? drag : undefined}
          unavailable={unavailable}
          {...props}
          {...p}
        />
      )}
    />
  )
}

let MoveAssetMutation = gql`
  mutation update($input: [UpdateManyRouteRunsInput]) {
    updateManyRouteRuns(input: $input) {
      routeRuns {
        id
        assets {
          id
        }
      }
    }
  }
`

export function DraggableAsset({
  id,
  parent,
  on,
  onUpdate,
  type,
  disableDrag,
  ...props
}) {
  let [update] = useMutation(MoveAssetMutation)
  let self = {id, type: DragTypes.ASSET}
  return (
    <DraggablePillbox
      on={on}
      self={self}
      onUpdate={({item, monitor}) => {
        let destination = monitor.getDropResult()
        if (destination) {
          let input = []

          if (parent.id) {
            input.push({
              id: parent.id,
              [self.type]: {
                remove: [{id: self.id}],
              },
            })
          }

          if (destination.id) {
            input.push({
              id: destination.id,
              [self.type]: {
                create: [{id: self.id}],
              },
            })
          }

          update({
            variables: {
              input,
            },
          }).then(() => {
            if (typeof onUpdate === 'function')
              onUpdate({self, parent, destination})
          })
        }
      }}
      as={({drag, ...p}) => (
        <PillBoxAsset
          ref={!disableDrag ? drag : undefined}
          className={type === 2 ? 'powerwasher' : undefined}
          {...props}
          {...p}
        />
      )}
    />
  )
}

let SchedulesFragment = gql`
  fragment SchedulesDetail on Schedules {
    id
    route_id
    customer_id
    charge
    man_minutes
    sequence
    start_at
    end_at
    freq_type
    freq_interval
    freq_relative_interval
    freq_recurrence_factor
    # next_occurrence_at
    week
    day
    arrival_start_time
    arrival_end_time
    services {
      id
      saleitem_id
      saleitem {
        id
        name
        description
      }
      sales_person_id
      sales_person {
        id
        first_name
        last_name
      }
      jobrequirements {
        id
        jobrequirementtype_id
        jobrequirementtype {
          id
          type
        }
        qty
      }
      description
      notes
      man_minutes
      charge
      sales_person_id
      commission_start_at
      commission_end_at
      gpm_percent
      commission_percent
    }
  }
`

let UpdateScheduleQuery = gql`
  mutation updateSchedules($schedule: UpdateSchedulesInput) {
    updateSchedules(input: $schedule) {
      schedules {
        ...SchedulesDetail
      }
    }
  }
  ${SchedulesFragment}
`

let MoveScheduleMutation = gql`
  mutation update($input: UpdateSchedulesInput) {
    updateSchedules(input: $input) {
      schedules {
        id
        route_id
        day
        week
      }
    }
  }
`

let RescheduleMutation = gql`
  mutation reschedule($input: RescheduleInput, $scheduleId: ID!) {
    reschedule(input: $input, scheduleId: $scheduleId) {
      id
    }
  }
`

let ReorderScheduleMutation = gql`
  mutation update($input: ResequenceScheduleInput, $scheduleId: ID!) {
    resequenceSchedule(input: $input, scheduleId: $scheduleId) {
      id
      sequence
    }
  }
`

let CreateExclusionMutation = gql`
  mutation createExclusions($exclusion: CreateExclusionsInput) {
    createExclusions(input: $exclusion) {
      exclusions {
        id
      }
    }
  }
`

let UpdateExclusionMutation = gql`
  mutation updateExclusions($exclusion: UpdateExclusionsInput) {
    updateExclusions(input: $exclusion) {
      exclusions {
        id
      }
    }
  }
`
let DeleteExclusionMutation = gql`
  mutation deleteExclusions($exclusion: DeleteExclusionsInput) {
    deleteExclusions(input: $exclusion) {
      message
    }
  }
`

let MoveWorkorderMutation = gql`
  mutation update($input: UpdateWorkordersInput) {
    updateWorkorders(input: $input) {
      workorders {
        id
        route_id
        assigned_at
      }
    }
  }
`

let ReorderWorkordersMutation = gql`
  mutation update($input: ResequenceWorkorderInput, $workorderId: ID!) {
    resequenceWorkorder(input: $input, workorderId: $workorderId) {
      id
      sequence
    }
  }
`

function getArrivalText(schedule) {
  let arrivalStart = ''
  let arrivalEnd = ''
  if (schedule.arrival_start_time)
    arrivalStart = moment
      .utc(schedule.arrival_start_time, 'hh:mm:ss')
      .format('h:mm a')
  if (schedule.arrival_end_time)
    arrivalEnd = moment
      .utc(schedule.arrival_end_time, 'hh:mm:ss')
      .format('h:mm a')

  if (!arrivalStart && !arrivalEnd) return ''

  if (!arrivalStart || !arrivalEnd) {
    return '~ ' + (arrivalStart || arrivalEnd)
  }

  return `${arrivalStart} - ${arrivalEnd}`
}

let DraggableSchedule = ({
  schedule,
  parent,
  onUpdate,
  onOpenSchedule,
  ...props
}) => {
  let [update] = useMutation(MoveScheduleMutation)
  let [reorder] = useMutation(ReorderScheduleMutation)
  let [reschedule] = useMutation(RescheduleMutation)
  let [createExclusion] = useMutation(CreateExclusionMutation)
  let [updateExclusion] = useMutation(UpdateExclusionMutation)
  let [deleteExclusion] = useMutation(DeleteExclusionMutation)
  let [moveWarning, setMoveWarning] = useState(null)

  let Comp =
    schedule.customer.customertype_id === '1'
      ? ({children, drag, ...props}) => (
          <PillBoxCommercial ref={drag} {...props}>
            {children}
          </PillBoxCommercial>
        )
      : schedule.customer.customertype_id === '3'
      ? ({children, drag, ...props}) => (
          <PillBoxLargeCommercial ref={drag} {...props}>
            {children}
          </PillBoxLargeCommercial>
        )
      : ({children, drag, ...props}) => (
          <PillBoxResidential ref={drag} {...props}>
            {children}
          </PillBoxResidential>
        )
  let self = {
    type: 'schedules',
    id: schedule.id,
    route_id: schedule.route_id,
    schedule_type: schedule.freq_type !== 1 ? 1 : 2,
    routerun_id: parent.id,
    sequence: schedule.sequence,
    instanceDate: parent.run_at,
    exclusions: schedule.exclusions,
  }

  let hasExclusion =
    schedule.exclusions !== null &&
    !!schedule.exclusions.filter(e =>
      moment.utc(e.reschedule_at).isSame(self.instanceDate, 'day'),
    ).length

  let move = async ({self, parent, destination}) => {
    if (Number.parseInt(parent.id) !== Number.parseInt(destination.id)) {
      let input = {
        on_date: moment.utc(self.instanceDate).format('YYYY-MM-DD'),
        to_date: moment.utc(destination.run_at).format('YYYY-MM-DD'),
        to_route_id: String(destination.route_id),
      }

      await reschedule({variables: {input, scheduleId: self.id}})
    }

    if (destination && destination.sequence) {
      await reorder({
        variables: {
          input: {
            sequence: destination.sequence,
            date: moment.utc(destination.run_at).format('YYYY-MM-DD'),
            route_id: destination.route_id,
          },
          scheduleId: self.id,
        },
      })
    }

    onUpdate({self, parent, destination})
  }

  return (
    // Yeah this is terrible, but (1) I am not a designer, and (2) I don't have time to figure it out properly
    <span>
      <DraggablePillbox
        self={self}
        onUpdate={async ({item, monitor}) => {
          let destination = monitor.getDropResult()
          let p = {type: 'routeruns', id: parent.id}
          if (destination && destination.type === 'routeruns') {
            move({self: item, destination, parent: p})
          }
        }}
        canDrag={monitor => {
          if (!schedule.freq_type === 1) return true
          if (!schedule.exclusions.length) return true

          let ex = schedule.exclusions
            .filter(e =>
              moment.utc(e.reschedule_at).isSame(self.instanceDate, 'day'),
            )
            .pop()

          if (!ex) return true

          return ex.id
        }}
        as={({drag, ...p}) => (
          <StyledBadge
            badgeContent={
              schedule.customer.unresolved_tech &&
              schedule.customer.unresolved_tech.length
            }
            color="error"
          >
            <Comp
              style={{display: 'flex', alignItems: 'center'}}
              onClick={() => onOpenSchedule(schedule)}
              drag={drag}
              exclusion={hasExclusion}
              disabled={schedule.customer.services_paused}
              {...p}
              id={`schedule-${schedule.id}-${moment
                .utc(parent.run_at)
                .format('YYYY-MM-DD')}`}
            >
              {hasExclusion ? (
                <ArrowForwardRounded />
              ) : schedule.freq_type !== 1 ? (
                <RepeatIcon />
              ) : (
                <RepeatOneIcon />
              )}
              <div>
                <Typography>
                  {schedule.customer.name} -{' '}
                  <span style={{fontWeight: 'bold'}}>
                    {formatMoneyStandard(schedule.charge)}
                  </span>{' '}
                  -{' '}
                  <span style={{fontWeight: 'bold'}}>
                    {schedule.man_minutes} min
                  </span>
                </Typography>
                {getArrivalText(schedule) !== '' ? (
                  <Typography
                    variant="small"
                    style={{color: colors.orange700, fontWeight: 'bold'}}
                  >
                    {getArrivalText(schedule)}
                  </Typography>
                ) : (
                  <></>
                )}
              </div>
            </Comp>
          </StyledBadge>
        )}
      />
      {/* <DnDRecurringScheduleWarning
        data={moveWarning}
        onCancel={() => setMoveWarning(null)}
        onSubmit={props => {
          move(props)
          setMoveWarning(null)
        }}
      /> */}
    </span>
  )
}

let ViewModeSchedule = ({
  schedule,
  parent,
  onUpdate,
  onOpenSchedule,
  ...props
}) => {
  let Comp =
    schedule.customer.customertype_id === '1'
      ? ({children, drag, ...props}) => (
          <PillBoxCommercial ref={drag} {...props}>
            {children}
          </PillBoxCommercial>
        )
      : schedule.customer.customertype_id === '3'
      ? ({children, drag, ...props}) => (
          <PillBoxLargeCommercial ref={drag} {...props}>
            {children}
          </PillBoxLargeCommercial>
        )
      : ({children, drag, ...props}) => (
          <PillBoxResidential ref={drag} {...props}>
            {children}
          </PillBoxResidential>
        )
  let self = {
    type: 'schedules',
    id: schedule.id,
    schedule_type: schedule.freq_type !== 1 ? 1 : 2,
    routerun_id: parent.id,
    sequence: schedule.sequence,
  }

  let hasExclusion =
    schedule.exclusions !== null &&
    !!schedule.exclusions.filter(e =>
      moment.utc(e.reschedule_at).isSame(parent.run_at, 'day'),
    ).length

  return (
    <>
      <Link to={`/customers/${schedule.customer.id}`}>
        <StyledBadge
          badgeContent={
            schedule.customer.unresolved_tech &&
            schedule.customer.unresolved_tech.length
          }
          color="error"
        >
          <Comp
            style={{display: 'flex', alignItems: 'center'}}
            exclusion={hasExclusion}
            disabled={schedule.customer.services_paused}
          >
            {hasExclusion ? (
              <ArrowForwardRounded />
            ) : schedule.freq_type !== 1 ? (
              <RepeatIcon />
            ) : (
              <RepeatOneIcon />
            )}
            <div>
              <Typography>
                {schedule.customer.name} -{' '}
                <span style={{fontWeight: 'bold'}}>
                  {formatMoneyStandard(schedule.charge)}
                </span>{' '}
                -{' '}
                <span style={{fontWeight: 'bold'}}>
                  {schedule.man_minutes} min
                </span>
              </Typography>
              {getArrivalText(schedule) !== '' ? (
                <Typography
                  variant="small"
                  style={{color: colors.orange700, fontWeight: 'bold'}}
                >
                  {getArrivalText(schedule)}
                </Typography>
              ) : (
                <></>
              )}
            </div>
          </Comp>
        </StyledBadge>
      </Link>
    </>
  )
}

export function getWorkorderIcon(isExternal) {
  if (isExternal) return <AssignmentLate style={{color: colors.blue400}} />
  return <Assignment />
}

let DraggableWorkorder = ({
  workorder,
  onOpenWorkorder,
  parent,
  relatedSchedule,
  onUpdate,
  ...props
}) => {
  let [update] = useMutation(MoveWorkorderMutation)
  let [reorder] = useMutation(ReorderWorkordersMutation)

  let self = {
    type: DragTypes.WORKORDER,
    id: workorder.id,
    route_id: workorder.route_id,
    routerun_id: parent.id,
    sequence: workorder.sequence,
    instanceDate: parent.run_at,
  }

  let schedule =
    !!relatedSchedule && relatedSchedule.freq_type !== 6
      ? {
          type: DragTypes.SCHEDULE,
          id: relatedSchedule.id,
          route_id: relatedSchedule.route_id,
          schedule_type: relatedSchedule.freq_type !== 1 ? 1 : 2,
          routerun_id: parent.id,
          sequence: relatedSchedule.sequence,
          instanceDate: parent.run_at,
          exclusions: relatedSchedule.exclusions,
        }
      : {}

  let move = async ({self, parent, destination}) => {
    if (Number.parseInt(parent.id) !== Number.parseInt(destination.id)) {
      let input = {
        route_id: destination.route_id,
        id: self.id,
        assigned_at: moment.utc(destination.run_at).format('YYYY-MM-DD'),
      }

      await update({
        variables: {
          input,
        },
      })
    }

    if (destination && destination.sequence) {
      await reorder({
        variables: {
          input: {
            sequence: destination.sequence,
            date: moment.utc(destination.run_at).format('YYYY-MM-DD'),
            route_id: destination.route_id,
          },
          workorderId: self.id,
        },
      })
    }

    onUpdate({self: [schedule, self], parent, destination})
  }

  let notesIcon = !!workorder.notes
  let notesResolved = !!workorder.notes_resolved
  let reminderIcon = workorder.reminder_sent
  let notCompletedIcon = workorder.not_completed
  let completeIcon = workorder.status === 'COMPLETE'
  let unresolvedTechIcon =
    !!workorder.customer.unresolved_tech &&
    workorder.customer.unresolved_tech.length
  let poNeeded = !!workorder.customer.po_needed
  let keyAccess = !!workorder.customer.key_access
  let serviceConf = !!workorder.customer.needs_service_confirmation
  let rain = !!workorder.customer.rain_sensitive
  let moveObstruction = !!workorder.customer.move_obstruction_for_service

  return (
    // Yeah this is terrible, but (1) I am not a designer, and (2) I don't have time to figure it out properly
    <span>
      <DraggablePillbox
        self={self}
        onUpdate={async ({item, monitor}) => {
          let destination = monitor.getDropResult()
          let p = {type: 'routeruns', id: parent.id}
          if (destination && destination.type === 'routeruns') {
            move({self: item, destination, parent: p})
          }
        }}
        canDrag={() => true}
        as={({drag, ...p}) => (
          <PillBoxWorkorder
            onClick={() => onOpenWorkorder(workorder)}
            ref={drag}
            multiday={workorder.multiday}
            id={'workorder-' + workorder.id}
            direction="column"
            disabled={workorder.customer.services_paused}
            {...p}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {getWorkorderIcon(poNeeded)}
              <div>
                <Typography>
                  {workorder.customer.name}
                  {workorder.multiday ? (
                    <span style={{fontWeight: 'bold'}}>
                      {' '}
                      - ~
                      {formatMoneyStandard(workorder.multiday_estimated_total)}
                    </span>
                  ) : workorder.is_billable ? (
                    <span style={{fontWeight: 'bold'}}>
                      {' '}
                      -{' '}
                      {formatMoneyStandard(
                        workorder.workorderitems.reduce(
                          (c, i) => discountedCharge(i) + c,
                          0,
                        ),
                      )}
                    </span>
                  ) : (
                    <></>
                  )}
                </Typography>
                {getArrivalText(workorder) !== '' ? (
                  <Typography
                    variant="small"
                    style={{color: colors.orange700, fontWeight: 'bold'}}
                  >
                    {getArrivalText(workorder)}
                  </Typography>
                ) : (
                  <></>
                )}
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'flex-start',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              {unresolvedTechIcon ? (
                <Tooltip title="Tech Feedbacks">
                  <CustomBadge>
                    {workorder.customer.unresolved_tech.length}
                  </CustomBadge>
                </Tooltip>
              ) : (
                ''
              )}
              {reminderIcon && (
                <Tooltip title="Reminder sent">
                  <Email />
                </Tooltip>
              )}
              {notesIcon && (
                <Tooltip
                  title={`Has notes ${notesResolved ? '(RESOLVED)' : ''}`}
                  style={{color: colors.bethanyBlue300}}
                >
                  {notesResolved ? <ModeComment /> : <Comment />}
                </Tooltip>
              )}
              {notCompletedIcon && (
                <Tooltip title="Not completed" style={{color: colors.red400}}>
                  <Error />
                </Tooltip>
              )}
              {completeIcon && (
                <Tooltip title="COMPLETE" style={{color: colors.green500}}>
                  <CheckCircle />
                </Tooltip>
              )}
              {keyAccess && (
                <Tooltip
                  title="Key Access required"
                  style={{color: colors.bethanyBlue300}}
                >
                  <VpnKey />
                </Tooltip>
              )}
              {serviceConf && (
                <Tooltip
                  title="Needs confirmation before service"
                  style={{color: colors.bethanyBlue300}}
                >
                  <PermPhoneMsg />
                </Tooltip>
              )}
              {rain && (
                <Tooltip title="Rain sensitive" style={{color: colors.blue300}}>
                  <WaterDrop />
                </Tooltip>
              )}
              {moveObstruction && (
                <Tooltip
                  title="Customer must move obstruction"
                  style={{color: colors.orange600}}
                >
                  <Cone />
                </Tooltip>
              )}
            </div>
          </PillBoxWorkorder>
        )}
      />
    </span>
  )
}

let ViewModeWorkorder = ({workorder, goto = 'customer'}) => {
  let notesIcon = !!workorder.notes
  let notesResolved = !!workorder.notes_resolved
  let reminderIcon = workorder.reminder_sent
  let notCompletedIcon = workorder.not_completed
  let unresolvedTechIcon =
    !!workorder.customer.unresolved_tech &&
    workorder.customer.unresolved_tech.length
  let completeIcon = workorder.status === 'COMPLETE'
  let poNeeded = !!workorder.customer.po_needed
  let keyAccess = !!workorder.customer.key_access
  let serviceConf = !!workorder.customer.needs_service_confirmation
  let rain = !!workorder.customer.rain_sensitive
  let moveObstruction = !!workorder.customer.move_obstruction_for_service

  let url = ''
  switch (goto) {
    case 'workorder':
      url = `/workorders/${workorder.id}`
      break
    case 'customer':
    default:
      url = `/customers/${workorder.customer.id}`
      break
  }

  return (
    <Link to={url} style={{color: colors.grey900}}>
      <PillBoxWorkorder
        multiday={workorder.multiday}
        id={'workorder-' + workorder.id}
        direction="column"
        disabled={workorder.customer.services_paused}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {getWorkorderIcon(poNeeded)}
          <div>
            <Typography>
              {workorder.customer.name} -{' '}
              {workorder.multiday ? (
                <span style={{fontWeight: 'bold'}}>
                  ~{formatMoneyStandard(workorder.multiday_estimated_total)}
                </span>
              ) : (
                <span style={{fontWeight: 'bold'}}>
                  {formatMoneyStandard(
                    workorder.workorderitems.reduce(
                      (c, i) => discountedCharge(i) + c,
                      0,
                    ),
                  )}
                </span>
              )}
            </Typography>
            {getArrivalText(workorder) !== '' ? (
              <Typography
                variant="small"
                style={{color: colors.orange700, fontWeight: 'bold'}}
              >
                {getArrivalText(workorder)}
              </Typography>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'flex-start',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          {unresolvedTechIcon ? (
            <Tooltip title="Tech Feedbacks">
              <CustomBadge>
                {workorder.customer.unresolved_tech.length}
              </CustomBadge>
            </Tooltip>
          ) : (
            ''
          )}
          {reminderIcon && (
            <Tooltip title="Reminder sent">
              <Email />
            </Tooltip>
          )}
          {notesIcon && (
            <Tooltip title={`Has notes ${notesResolved ? '(RESOLVED)' : ''}`}>
              {notesResolved ? (
                <ModeComment style={{color: colors.bethanyBlue300}} />
              ) : (
                <Comment style={{color: colors.bethanyBlue300}} />
              )}
            </Tooltip>
          )}
          {notCompletedIcon && (
            <Tooltip title="Not completed" style={{color: colors.red400}}>
              <Error />
            </Tooltip>
          )}
          {completeIcon && (
            <Tooltip title="COMPLETE" style={{color: colors.green500}}>
              <CheckCircle />
            </Tooltip>
          )}
          {keyAccess && (
            <Tooltip
              title="Key Access required"
              style={{color: colors.bethanyBlue300}}
            >
              <VpnKey />
            </Tooltip>
          )}
          {serviceConf && (
            <Tooltip
              title="Needs confirmation before service"
              style={{color: colors.bethanyBlue300}}
            >
              <PermPhoneMsg />
            </Tooltip>
          )}
          {rain && (
            <Tooltip title="Rain sensitive" style={{color: colors.blue300}}>
              <WaterDrop />
            </Tooltip>
          )}
          {moveObstruction && (
            <Tooltip
              title="Customer must move obstruction"
              style={{color: colors.orange600}}
            >
              <Cone />
            </Tooltip>
          )}
        </div>
      </PillBoxWorkorder>
    </Link>
  )
}

let SunIcon = styled(Brightness5)`
  color: ${colors.grey700};
`

let MoonIcon = styled(Brightness2)`
  color: ${colors.grey700};
`

let usersQuery = gql`
  query Users {
    users: allUsers(cursor: "-1", limit: 1000000, filters: {status: active}) {
      edges {
        edge {
          id
          first_name
          last_name
        }
      }
    }
  }
`

let assetsQuery = gql`
  query Assets {
    assets: allAssets(cursor: "-1", limit: 1000000) {
      edges {
        edge {
          id
          name
          description
          active
        }
      }
    }
  }
`

let UpdateRouterun = gql`
  mutation UpdateRouterun($input: UpdateRouteRunsInput) {
    data: updateRouteRuns(input: $input) {
      routeRuns {
        id
      }
      errors {
        code
        title
        detail
      }
    }
  }
`

export function Day({
  data,
  backgroundColor,
  dayView,
  onUpdate,
  onError,
  onClick,
  viewMode,
  summaryMode,
  supervisorMode,
}) {
  let [{isOver, canDrop}, drop] = useDrop(
    () => ({
      accept: [
        DragTypes.USER,
        DragTypes.ASSET,
        DragTypes.SCHEDULE,
        DragTypes.WORKORDER,
      ],
      drop: (item, monitor) => ({
        type: 'routeruns',
        id: data.id,
        vehicle_id: data.vehicle_id,
        run_at: data.run_at,
        route_id: data.route_id,
        week: data.week,
        day: Number.parseInt(moment.utc(data.run_at).format('d')) + 1,
      }),
      canDrop: (item, monitor) => _canDrop(item),
      collect: monitor => ({
        isOver: !!monitor.isOver(),
        canDrop: !!monitor.canDrop(),
      }),
    }),
    [
      data && _(data.users.map(u => u.id)).join(','),
      data && _(data.assets.map(u => u.id)).join(','),
    ],
  )

  if (!data) return <TableCell style={{backgroundColor: backgroundColor}} />

  let generatedScheduleIds = data.workorders
    .filter(i => !['VOID'].includes(i.status))
    .map(wo => wo.schedule_id)
    .flat()
  let ungeneratedSchedules = data.schedules
    .filter(s => !generatedScheduleIds.includes(s.id))
    .filter(s => !s.customer.services_paused)
  // console.log(data.schedules)

  const jobReqs = data.schedules
    .map(s => s.services)
    .flat()
    .map(s => s.jobrequirements)
    .flat()
    .reduce((carry, req) => {
      carry[req.jobrequirementtype_id] = {
        name: req.jobrequirementtype.name,
        qty:
          req.jobrequirementtype.type === 1
            ? Math.max(
                carry[req.jobrequirementtype_id]
                  ? carry[req.jobrequirementtype_id].qty
                  : 0,
                req.qty,
              )
            : (carry[req.jobrequirementtype_id]?.qty || 0) + req.qty,
      }
      return carry
    }, {})

  let date = moment.utc(data.run_at)
  let today = moment.utc().startOf('day')

  let totalCharges =
    data.workorders
      .filter(i => !['VOID'].includes(i.status))
      .filter(i => i.customer.services_paused !== true)
      .reduce(
        (c, s) =>
          (s.multiday
            ? s.multiday_estimated_total
            : s.workorderitems.reduce((c, i) => discountedCharge(i) + c, 0)) +
          c,
        0,
      ) +
    (!date.isBefore(today)
      ? ungeneratedSchedules.reduce(
          (carry, s) => carry + discountedCharge(s),
          0,
        )
      : 0)
  let totalChargesPercent =
    totalCharges > data.income_capacity
      ? 100
      : (totalCharges / data.income_capacity) * 100
  totalChargesPercent = totalCharges === 0 ? 0 : totalChargesPercent

  let totalTime =
    data.workorders
      .filter(i => !['VOID'].includes(i.status))
      .filter(i => i.customer.services_paused !== true)
      .reduce(
        (carry, s) =>
          carry + s.workorderitems.reduce((c, i) => i.man_minutes + c, 0),
        0,
      ) +
    (!date.isBefore(today)
      ? ungeneratedSchedules.reduce((carry, s) => carry + s.man_minutes, 0)
      : 0)
  let totalTimeHrs = Math.round((totalTime / 60 + Number.EPSILON) * 100) / 100
  let totalTimePercent =
    totalTime > data.users.length * 6.15 * 60
      ? 100
      : (totalTime / (data.users.length * 6.15 * 60)) * 100
  totalTimePercent = totalTime === 0 ? 0 : totalTimePercent
  let totalStops = data.workorders.filter(i => !['VOID'].includes(i.status))
    .length
  let totalCompletedStops = data.workorders
    .filter(i => !['VOID'].includes(i.status))
    .filter(
      i => i.status === 'COMPLETE' || (i.status === 'OPEN' && i.not_completed),
    ).length
  let totalStopsPercent =
    totalCompletedStops >= totalStops
      ? 100
      : (totalCompletedStops / totalStops) * 100
  totalStopsPercent = totalStops === 0 ? 0 : totalStopsPercent
  totalStopsPercent = totalStopsPercent === Infinity ? 0 : totalStopsPercent

  let cities = _.uniq(
    []
      .concat(
        data.workorders.filter(i => !['VOID'].includes(i.status)),
        ungeneratedSchedules,
      )
      .sort((a, b) => a.sequence - b.sequence)
      .map(s => (s.customer.city ? s.customer.city.name : '')),
  ).join(', ')

  let noWorkerAlert = totalCharges && data.users.length === 0

  function _canDrop(item) {
    // unscheduled user or asset
    if (
      data &&
      item.on === undefined &&
      (item.type === DragTypes.USER || item.type === DragTypes.ASSET)
    ) {
      return true
    }

    if (data && !!item.on && moment.utc(data.run_at).isSame(item.on, 'day')) {
      if (item.type === 'users' && data && data.users) {
        return !data.users.map(u => u.id).filter(id => id === item.id).length
      }

      if (item.type === 'assets' && data && data.assets) {
        return !data.assets.map(u => u.id).filter(id => id === item.id).length
      }
    }

    if (data && item.type === 'schedules') {
      return (
        data.id !== item.routerun_id
        // if doing Cycle4 schedules, you'll have to check if it's already present as well.
      )
    }

    if (data && item.type === DragTypes.WORKORDER) {
      return data.id !== item.routerun_id
    }

    return false
  }

  function computeBackground() {
    if (canDrop && isOver) return colors.blue100
    if (canDrop) return colors.blue50
    return backgroundColor
  }

  return (
    <>
      <DayCell
        style={{backgroundColor: computeBackground()}}
        onClick={!viewMode ? onClick : undefined}
        key={`day-${data.id}`}
        id={`day-${data.id}`}
        ref={drop}
      >
        <div style={{display: 'flex', alignItems: 'center'}}>
          {data.route.shift.id === '1' ? <SunIcon /> : <MoonIcon />}
          <Typography variant="caption">
            {dayView ? (
              <span style={{fontWeight: 'bold'}}>Route {data.route.name}</span>
            ) : (
              date.format('ddd, MMM D')
            )}{' '}
            {!summaryMode && (
              <>at {moment.utc(data.start_time, 'HH:mm:ss').format('h:mm a')}</>
            )}
          </Typography>
        </div>
        {!supervisorMode && (
          <Bar
            value={totalChargesPercent}
            big
            display={
              <div style={{display: 'flex', alignItems: 'center'}}>
                {date.isBefore(today) && <Receipt />}
                <div>
                  <Typography
                    style={{
                      fontWeight: totalCharges > data.income_capacity && 'bold',
                      wordBreak: 'keep-all',
                    }}
                  >
                    {formatMoneyStandard(totalCharges)}
                  </Typography>
                  <Typography style={{fontSize: '.95em'}}>
                    {formatMoneyStandard(data.income_capacity)}
                  </Typography>
                </div>
              </div>
            }
            color={'secondary'}
            // color={
            //   totalCharges > data.income_capacity
            //     ? 'secondary'
            //     : 'primary'
            // }
          />
        )}
        {!summaryMode && (
          <Bar
            value={totalTimePercent}
            display={
              <Typography
                style={{
                  fontWeight: totalTime > data.users.length * 6 * 60 && 'bold',
                }}
              >
                {totalTimeHrs} / {data.users.length * 6} hrs
              </Typography>
            }
          />
        )}
        {!summaryMode && (
          <Bar
            value={totalStopsPercent}
            color={theme.palette.calendar.progress}
            custom
            display={
              <Typography>
                {totalCompletedStops} / {totalStops} completed
              </Typography>
            }
          />
        )}
        {data.users.length ? (
          data.users.map(u => (
            <DraggableEmployee
              key={`${u.id}`}
              id={u.id}
              on={data.run_at}
              parent={{type: 'routeruns', id: data.id}}
              onUpdate={onUpdate}
              disableDrag={viewMode}
            >
              <Typography variant="body1">
                {u.first_name + ' ' + u.last_name}
              </Typography>
            </DraggableEmployee>
          ))
        ) : (
          <PillBoxEmpty
            style={
              noWorkerAlert
                ? {
                    borderColor: colors.red400,
                    backgroundColor: colors.red200,
                    fontWeight: 'bold',
                  }
                : undefined
            }
          >
            Assign Employee
          </PillBoxEmpty>
        )}
        {!summaryMode && (
          <>
            {data.assets.length > 0 ? (
              <div style={{display: 'flex', flexWrap: 'wrap'}}>
                {data.assets.map(a => (
                  <DraggableAsset
                    key={`asset-${a.id}`}
                    id={a.id}
                    on={data.run_at}
                    parent={{
                      type: 'routeruns',
                      id: data.id,
                    }}
                    onUpdate={onUpdate}
                    type={a.assettype_id}
                    disableDrag={viewMode}
                  >
                    <Typography>{`${a.name}`}</Typography>
                  </DraggableAsset>
                ))}
              </div>
            ) : (
              <PillBoxEmpty>Assign Assets</PillBoxEmpty>
            )}
            <Typography style={{fontStyle: 'italic'}}>
              {data.scheduler_notes}
            </Typography>
            {!!data.scheduler_notes && !!data.notes && <hr />}
            <Typography>{data.notes}</Typography>
            {Object.values(jobReqs).length ? (
              <div>
                <Tooltip
                  title={Object.values(jobReqs)
                    .map(({name, qty}) => `(${qty}) ${name}`)
                    .join(', ')}
                >
                  <Typography
                    variant="caption"
                    style={{fontWeight: 'bold', textDecoration: 'underline'}}
                    id={'jobreqs-' + data.id}
                  >
                    Job Requirements
                  </Typography>
                </Tooltip>
              </div>
            ) : (
              ''
            )}
            <Typography variant="caption">{cities}</Typography>
          </>
        )}
      </DayCell>
    </>
  )
}

export let LeaderSelect = ({name, label, users}) => {
  return (
    <TextField name={name} variant="filled" label={label} fullWidth select>
      <MenuItem value={null}></MenuItem>
      {users.map(u => (
        <MenuItem value={u.edge.id}>
          {u.edge.first_name} {u.edge.last_name}
        </MenuItem>
      ))}
    </TextField>
  )
}

export let UsersSelect = ({name, label, users, ...props}) => {
  return (
    <MultiSelect
      name={name}
      variant="filled"
      label={label}
      fullWidth
      chip={value => {
        let u = users.find(u => u.edge.id === value)
        return (
          <ChipEmployee
            key={value}
            label={`${u.edge.first_name} ${u.edge.last_name}`}
            style={{margin: '.1em'}}
          />
        )
      }}
      {...props}
    >
      {users.map(u => (
        <MenuItem value={u.edge.id} key={u.edge.id}>
          {u.edge.first_name} {u.edge.last_name}
        </MenuItem>
      ))}
    </MultiSelect>
  )
}

export let AssetsSelect = ({name, label, assets}) => {
  return (
    <MultiSelect
      name={name}
      variant="filled"
      label={label}
      fullWidth
      chip={value => {
        let u = assets.find(u => u.edge.id === value)
        return (
          <ChipAsset
            key={value}
            label={u && `${u.edge.name}`}
            style={{margin: '.1em'}}
            className={u.edge.assettype_id === 2 ? 'powerwasher' : undefined}
          />
        )
      }}
    >
      {assets.map(u => (
        <MenuItem value={u.edge.id} key={u.edge.id}>
          {u.edge.name}
        </MenuItem>
      ))}
    </MultiSelect>
  )
}

function EditRouterunDetails({routerun, children, users, assets, ...props}) {
  let initial = {
    ...routerun,
    users: routerun ? routerun.users.map(u => u.id) : [],
    assets: routerun ? routerun.assets.map(u => u.id) : [],
  }
  let activeUsers = users ? users.users.edges.map(e => e.edge.id) : []
  let options = [
    ...(users ? users.users.edges : []),
    ...(routerun
      ? routerun.users
          .filter(u => activeUsers.indexOf(u.id) === -1)
          .map(u => ({edge: u}))
      : []),
  ]

  return (
    <Formik
      initialValues={initial}
      {...props}
      children={({
        isSubmitting,
        isValid,
        values,
        setFieldValue,
        submitForm,
      }) => {
        let form = (
          <>
            <Grid container direction={'row'}>
              <Grid
                item
                container
                sm={5}
                spacing={2}
                direction="column"
                style={{marginRight: '1em'}}
              >
                <Grid item size={12}>
                  <Typography variant="h6">Assignments</Typography>
                </Grid>
                {users && users.users && (
                  <>
                    <Grid item>
                      <UsersSelect
                        users={options}
                        name={'users'}
                        variant="filled"
                        label="Employees"
                        fullWidth
                        id="select-users"
                      />
                    </Grid>
                  </>
                )}
                <Grid item size={12}>
                  <AssetsSelect
                    assets={assets && assets.assets ? assets.assets.edges : []}
                    name={'assets'}
                    variant="filled"
                    label="Assets"
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid item container sm={6} spacing={2} direction="column">
                <Grid item>
                  <Typography variant="h6">Details</Typography>
                </Grid>
                <Grid item spacing={2} container>
                  <Grid item sm={3}>
                    <TextField
                      name="income_capacity"
                      variant="filled"
                      type="number"
                      label="Capacity"
                      fullWidth
                    />
                  </Grid>
                  <Grid item sm={4}>
                    <TimePicker
                      name="start_time"
                      label="Time"
                      TextFieldComponent={props => <TextField {...props} />}
                      inputVariant={'filled'}
                    />
                  </Grid>
                </Grid>
                <Grid item>
                  <TextField
                    name="notes"
                    variant="filled"
                    label="Notes"
                    fullWidth
                  />
                </Grid>
                <Grid item>
                  <TextField
                    name="scheduler_notes"
                    variant="filled"
                    label="Scheduler Notes"
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>
          </>
        )
        if (typeof children === 'function') {
          return children({submitForm, isSubmitting, form, isValid})
        } else {
          return form
        }
      }}
    />
  )
}

export let lookupQuery = gql`
  query Users {
    users: allUsers(cursor: "-1", limit: 6000000, filters: {status: active}) {
      edges {
        edge {
          id
          first_name
          last_name
          permissions {
            id
            name
          }
        }
      }
    }
    assets: allAssets(cursor: "-1", limit: 500000) {
      edges {
        edge {
          id
          name
          assettype_id
          description
          active
        }
      }
    }
  }
`

export function EditRouterunModal({
  editRouterun,
  setEditRouterun,
  onUpdate,
  onError,
}) {
  let [errors, setErrors] = useState([])
  let [errorInput, setErrorInput] = useState(null)
  let {
    mutate: updateRouterun,
    isError,
    isLoading,
  } = useReactQueryMutation(async ({variables}) =>
    prgql({query: UpdateRouterun, variables}),
  )
  // let {data: users} = useReactQuery('users', () => prgql({query: usersQuery}))
  // let {data: assets} = useReactQuery('assets', () =>
  //   prgql({query: assetsQuery}),
  // )
  let {data: lookup} = useReactQuery('lookup', () =>
    prgql({query: lookupQuery}),
  )

  async function handleSave(values) {
    let attrs = [
      'id',
      'shift_id',
      'notes',
      'scheduler_notes',
      'income_capacity',
      'vehicle_id',
      'user_id',
      'start_time',
    ]
    let rels = ['users', 'assets']
    let routerun = attrs.reduce((carry, k) => {
      let v = values[k]
      if (k === 'start_time') {
        if (Number(v)) {
          v = v.substring(0, 2) + ':' + v.substring(2, 4)
        }
      }
      carry[k] = v
      return carry
    }, {})
    let relationships = rels.reduce((carry, k) => {
      carry[k] = {
        set: values[k]
          .filter((u, index, arr) => arr.indexOf(u) === index)
          .reduce((carry, u) => {
            carry.push({id: u})
            return carry
          }, []),
      }
      return carry
    }, {})

    updateRouterun(
      {variables: {input: {...routerun, ...relationships}}},
      {
        onSuccess: res => {
          if (res.data.errors) {
            setErrors(res.data.errors)
            setErrorInput({
              ...routerun,
              ...relationships,
              override_booking_rules: true,
            })
          } else {
            setEditRouterun(null)
            onUpdate({})
          }
        },
      },
    )
  }

  return (
    <>
      <EditRouterunDetails
        key={editRouterun && editRouterun.id ? editRouterun.id : undefined}
        routerun={editRouterun}
        onSubmit={handleSave}
        users={lookup}
        assets={lookup}
      >
        {({form, submitForm, isSubmitting, isValid}) => (
          <Dialog open={!!editRouterun} maxWidth="md" fullWidth>
            <DialogTitle>
              Edit Route {editRouterun && editRouterun.route.name} on{' '}
              {moment
                .utc(editRouterun && editRouterun.run_at)
                .format('dddd, MMMM D, YYYY')}
            </DialogTitle>
            <DialogContent>{form}</DialogContent>
            <DialogActions>
              <Button
                onClick={() => setEditRouterun(null)}
                disabled={isSubmitting || isLoading}
              >
                Cancel
              </Button>
              <LoadingButton
                variant="contained"
                color="primary"
                onClick={submitForm}
                loading={isSubmitting || isLoading}
                // disabled={!isValid}
              >
                Save
              </LoadingButton>
            </DialogActions>
          </Dialog>
        )}
      </EditRouterunDetails>
      <ConflictDialog
        open={errors.length}
        errors={errors}
        onClose={() => setErrors([])}
        onOverride={() => {
          updateRouterun({variables: {input: errorInput}})
          setErrorInput(null)
          setErrors([])
          setEditRouterun(null)
          onUpdate({})
        }}
        isLoading={isLoading}
      />
    </>
  )
}

let MassExclusionDetails = ({
  schedule,
  jobRequirements,
  children,
  defaultService,
  instanceDate,
  ...props
}) => {
  let initial = {
    reschedule_at: instanceDate,
    reschedule_route_id: 1,
  }

  return (
    <Formik
      initialValues={initial || {}}
      {...props}
      children={({
        isSubmitting,
        isValid,
        values,
        setFieldValue,
        submitForm,
        validateForm,
      }) => {
        let form = (
          <>
            <Grid item xs={12}>
              <Typography variant="h6">Reschedule All Work</Typography>
            </Grid>
            <Grid item sm={12} spacing={2} container>
              <Grid item>
                <Chip
                  label="Reschedule"
                  icon={<ArrowForwardRounded />}
                  onClick={() => {
                    setFieldValue(
                      'reschedule_at',
                      moment.utc(instanceDate).add(1, 'day'),
                    )
                  }}
                  color={values.reschedule_at ? 'primary' : undefined}
                  id={'mass-reschedule'}
                />
              </Grid>
              <Grid item>
                <Chip
                  label="Cancel Job"
                  icon={<CancelOutlined />}
                  // onClick={() => {
                  //   setFieldValue('reschedule_at', null)
                  // }}
                  color={!values.reschedule_at ? 'primary' : undefined}
                  clickable={false}
                  id={'mass-cancel-job'}
                />
              </Grid>
            </Grid>
            <Grid container sm={12} spacing={2}>
              <Grid item>
                <AutoCompleteField
                  as={RouteSelect}
                  name="reschedule_route_id"
                  selectedId={String(values.reschedule_route_id)}
                  fullWidth
                  disabled={!values.reschedule_at}
                  clearable={false}
                />
              </Grid>
              <Grid item>
                <DatePicker
                  name="reschedule_at"
                  inputVariant="filled"
                  label="Reschedule to"
                  format="dddd, MMMM D, YYYY"
                  fullWidth
                  disabled={!values.reschedule_at}
                />
              </Grid>
            </Grid>
          </>
        )

        if (typeof children === 'function') {
          return children({
            submitForm,
            isSubmitting,
            form,
            isValid,
            values,
          })
        } else {
          return form
        }
      }}
    />
  )
}

export function MassRescheduleModal({
  onRefetch,
  rescheduleRouterun,
  setRescheduleRouterun,
  isLoading,
}) {
  let [updateSchedule] = useMutation(UpdateScheduleQuery)
  let [moveWorkorder] = useMutation(MoveWorkorderMutation)
  let [reschedule] = useMutation(RescheduleMutation)
  let [createExclusion] = useMutation(CreateExclusionMutation)
  let [updateExclusion] = useMutation(UpdateExclusionMutation)
  let [deleteExclusion] = useMutation(DeleteExclusionMutation)
  let [confirm, setConfirm] = useState(false)
  let [errors, setErrors] = useState([])

  let gqlFragment = gql`
    fragment Route on Routes {
      id
      name
      description
    }
  `
  // this is kinda terrible
  let {data: routes} = useAutoCompleteEndpoint({
    gqlFragment,
    selectedId: null,
    searchField: 'name',
  })

  async function handleSave(values) {
    let workorders = rescheduleRouterun.workorders
    let schedules = rescheduleRouterun.schedules
    // Would TypeScript make this unnecessary?
    workorders = workorders.map(wo => {
      wo.type = 'workorders'
      return wo
    })
    schedules = schedules.map(wo => {
      wo.type = 'schedules'
      return wo
    })
    let generatedScheduleIds = workorders
      .filter(i => !['VOID'].includes(i.status))
      .map(wo => wo.schedule_id)
      .flat()
    let ungeneratedSchedules = schedules.filter(
      s => !generatedScheduleIds.includes(s.id),
    )

    let data = workorders.concat(ungeneratedSchedules)

    let promises = data.map(s => {
      if (s.type === 'schedules') {
        let input = {
          on_date: moment.utc(rescheduleRouterun.run_at).format('YYYY-MM-DD'),
          to_date: moment.utc(values.reschedule_at).format('YYYY-MM-DD'),
          to_route_id: String(values.reschedule_route_id),
        }

        return reschedule({variables: {input, scheduleId: s.id}})
      } else {
        let input = {
          id: s.id,
          assigned_at: values.reschedule_at,
          route_id: String(values.reschedule_route_id),
        }

        return moveWorkorder({variables: {input}})
      }
    })

    return Promise.allSettled(promises)
      .then(() => setRescheduleRouterun(null))
      .then(() => setConfirm(false))
      .then(onRefetch)
  }

  return (
    <>
      <MassExclusionDetails
        key={
          rescheduleRouterun && rescheduleRouterun.id
            ? rescheduleRouterun.id
            : undefined
        }
        routerun={rescheduleRouterun}
        onSubmit={handleSave}
        instanceDate={rescheduleRouterun && rescheduleRouterun.run_at}
      >
        {({form, submitForm, isSubmitting, isValid, values}) => (
          <>
            <Dialog open={!!rescheduleRouterun} maxWidth="md" fullWidth>
              <DialogTitle>
                Reschedule Route{' '}
                {rescheduleRouterun && rescheduleRouterun.route.name} on{' '}
                {moment
                  .utc(rescheduleRouterun && rescheduleRouterun.run_at)
                  .format('dddd, MMMM D, YYYY')}
              </DialogTitle>
              <DialogContent>{form}</DialogContent>
              <DialogActions>
                <Button
                  onClick={() => setRescheduleRouterun(null)}
                  disabled={isSubmitting || isLoading}
                >
                  Cancel
                </Button>
                <LoadingButton
                  variant="contained"
                  color="primary"
                  onClick={() => setConfirm(true)}
                  loading={isSubmitting || isLoading}
                  // disabled={!isValid}
                >
                  Save
                </LoadingButton>
              </DialogActions>
            </Dialog>
            <Dialog open={!!confirm} maxWidth="md" fullWidth>
              <DialogTitle>
                CONFIRM MASS {!values.reschedule_at ? 'CANCEL' : 'RESCHEDULE'}
              </DialogTitle>
              <DialogContent>
                {rescheduleRouterun &&
                  (!values.reschedule_at ? (
                    <Typography>
                      Are you sure you want to cancel ALL work on{' '}
                      <span style={{fontWeight: 'bold'}}>
                        {moment
                          .utc(rescheduleRouterun && rescheduleRouterun.run_at)
                          .format('dddd, MMMM D, YYYY')}
                        , Route{' '}
                        {
                          routes
                            .map(e => e.edge)
                            .filter(
                              e => e.id === String(rescheduleRouterun.route_id),
                            )
                            .pop().name
                        }
                      </span>
                      ? This will VOID Invoices as well! <br />
                      <br /> One-Time jobs and their invoices will be ignored.
                    </Typography>
                  ) : (
                    <Typography>
                      Are you sure you want to move ALL work on{' '}
                      <span style={{fontWeight: 'bold'}}>
                        {moment
                          .utc(rescheduleRouterun && rescheduleRouterun.run_at)
                          .format('dddd, MMMM D, YYYY')}
                        , Route{' '}
                        {
                          routes
                            .map(e => e.edge)
                            .filter(
                              e => e.id === String(rescheduleRouterun.route_id),
                            )
                            .pop().name
                        }
                      </span>{' '}
                      to{' '}
                      <span style={{fontWeight: 'bold'}}>
                        {moment
                          .utc(values.reschedule_at)
                          .format('dddd, MMMM D, YYYY')}
                        , Route{' '}
                        {
                          routes
                            .map(e => e.edge)
                            .filter(
                              e => e.id === String(values.reschedule_route_id),
                            )
                            .pop().name
                        }
                      </span>
                      ?
                    </Typography>
                  ))}
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => setConfirm(false)}
                  disabled={isSubmitting || isLoading}
                >
                  {!values.reschedule_at ? 'Go Back' : 'Cancel'}
                </Button>
                <DangerLoadingButton
                  variant="contained"
                  color="primary"
                  onClick={submitForm}
                  loading={isSubmitting || isLoading}
                  // disabled={!isValid}
                >
                  {!values.reschedule_at ? 'Cancel Jobs' : 'Reschedule'}
                </DangerLoadingButton>
              </DialogActions>
            </Dialog>
          </>
        )}
      </MassExclusionDetails>
    </>
  )
}

export function ConflictDialog({open, errors, onClose, onOverride, isLoading}) {
  return (
    <Dialog open={!!open} onClose={onClose}>
      <DialogTitle>Scheduling Conflict</DialogTitle>
      <DialogContent>
        <Typography>
          <ul>
            {errors.map(e => (
              <li>{e.detail}</li>
            ))}
          </ul>
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={onClose}>
          Close
        </Button>
        <LoadingButton
          variant="contained"
          style={{
            backgroundColor: theme.palette.danger.main,
            color: theme.palette.danger.contrastText,
          }}
          onClick={onOverride}
          loading={isLoading}
        >
          Save with Conflicts
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

let ReorderHook = ({sequence, run_at, parentId, route_id, week}) => {
  let [{isOver, canDrop}, drop] = useDrop(() => ({
    accept: [DragTypes.SCHEDULE, DragTypes.WORKORDER],
    drop: (item, monitor) => {
      return {
        type: 'routeruns',
        sequence:
          sequence -
          (parentId == item.routerun_id && item.sequence < sequence ? 1 : 0),
        run_at,
        id: parentId,
        route_id,
        week,
        day: Number.parseInt(moment.utc(run_at).format('d')) + 1,
      }
    },
    collect: monitor => ({
      isOver: !!monitor.isOver(),
      canDrop: !!monitor.canDrop(),
    }),
  }))

  return (
    <div
      style={{
        display: 'flex',
        position: 'relative',
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          opacity: isOver ? 1 : 0,
          margin: '0.1em -1em',
          width: '120%',
        }}
      >
        <span
          style={{
            color: colors.blue300,
            height: '10px',
            width: '10px',
            borderRadius: '50%',
            display: 'inline-block',
            borderWidth: '2px',
            borderStyle: 'solid',
            position: 'absolute',
          }}
        />
        <span
          style={{
            borderWidth: '1em',
            borderColor: colors.blue300,
            height: '0px',
            borderWidth: '1px',
            borderStyle: 'solid',
            flexGrow: '1',
            marginLeft: '10px',
          }}
        />
      </div>
      <div
        ref={drop}
        style={{
          position: 'absolute',
          // background: 'blue',
          // opacity: .2,
          width: '120%',
          height: '36px',
          marginTop: '-18px',
          marginLeft: '-15px',
        }}
      ></div>
    </div>
  )
}

let ExtraDrop = ({sequence, run_at, parentId, route_id, week}) => {
  let [{isOver, canDrop}, drop] = useDrop(() => ({
    accept: [DragTypes.SCHEDULE, DragTypes.WORKORDER],
    drop: (item, monitor) => {
      return {
        type: 'routeruns',
        sequence: sequence + (item.sequence > sequence ? 1 : 0),
        run_at,
        id: parentId,
        route_id,
        week,
        day: Number.parseInt(moment.utc(run_at).format('d')) + 1,
      }
    },
    collect: monitor => ({
      isOver: !!monitor.isOver(),
      canDrop: !!monitor.canDrop(),
    }),
  }))

  return (
    <div
      ref={drop}
      style={{
        background: colors.blue100,
        borderRadius: '8px',
        flex: '1',
        opacity: isOver ? 1 : 0,
      }}
    ></div>
  )
}

export function SidebarDrop({children}) {
  let [{isOver, canDrop}, drop] = useDrop(() => ({
    accept: [DragTypes.USER, DragTypes.ASSET],
    drop: (item, monitor) => {
      return {
        type: 'unscheduled',
        id: 0,
      }
    },
    collect: monitor => ({
      isOver: !!monitor.isOver(),
      canDrop: !!monitor.canDrop(),
    }),
  }))

  return (
    <div
      ref={drop}
      style={{
        background: isOver ? colors.blue100 : 'inherit',
        display: 'flex',
        flexDirection: 'row',
        padding: '10px',
        flex: '1',
      }}
    >
      {children}
    </div>
  )
}

let ActionBar = ({past, data, parent, onMassReschedule, onNewWorkorder}) => (
  <>
    <ButtonGroup size="small" color="secondary" variant="contained">
      <Tooltip title="Move Day">
        <Button
          disabled={past || data.length === 0}
          onClick={() => onMassReschedule(parent)}
        >
          <ArrowForward />
        </Button>
      </Tooltip>
      <Tooltip title="Map Route">
        <Button
          component={Link}
          to={`/maproute?date=${moment
            .utc(parent.run_at)
            .format('YYYY-MM-DD')}&routes=${parent.route_id}`}
        >
          <Room />
        </Button>
      </Tooltip>
      <Tooltip title="New Workorder">
        <Button onClick={onNewWorkorder}>
          <Typography variant="subtitle2">+</Typography>
          <Assignment />
        </Button>
      </Tooltip>
    </ButtonGroup>
    <Space />
  </>
)

export function SchedulesList({
  schedules,
  workorders,
  parent,
  backgroundColor,
  past,
  onOpenSchedule,
  onOpenWorkorder,
  onMassReschedule,
  onUpdate,
  viewMode,
  filterRemaining = false,
}) {
  // Would TypeScript make this unnecessary?
  workorders = workorders.map(wo => {
    wo.type = 'workorders'
    return wo
  })
  schedules = schedules.map(wo => {
    wo.type = 'schedules'
    return wo
  })

  let generatedScheduleIds = workorders
    .filter(i => !['VOID'].includes(i.status))
    .map(wo => wo.schedule_id || '0')
    .flat()
  let ungeneratedSchedules = schedules.filter(
    s => !generatedScheduleIds.includes(s.id),
  )

  let data = []
  data = data
    .concat(workorders, ungeneratedSchedules)
    .sort((a, b) => a.sequence - b.sequence)
  let countData = data.length
  data = data
    .filter(i =>
      filterRemaining
        ? i.type === 'workorders' &&
          !(i.status === 'COMPLETE' || (i.status === 'OPEN' && i.not_completed))
        : true,
    )
    .filter(i => (past ? i.type === 'workorders' : true))

  return (
    <SchedulesCell style={{backgroundColor}}>
      {past ? (
        <>
          {data.map(s => (
            <ViewModeWorkorder
              workorder={s}
              key={`workorder-${s.id}`}
              goto="workorder"
            />
          ))}
          {countData > data.length ? (
            <Typography style={{color: colors.grey400, fontWeight: 'bold'}}>
              ~ {countData - data.length} items hidden ~
            </Typography>
          ) : (
            <></>
          )}
        </>
      ) : (
        <>
          <div
            style={{height: '100%', display: 'flex', flexDirection: 'column'}}
          >
            {!viewMode && (
              <ActionBar
                past={past}
                data={data}
                parent={parent}
                onMassReschedule={onMassReschedule}
                onNewWorkorder={() =>
                  onOpenWorkorder({
                    route_id: parent.route_id,
                    assigned_at: parent.run_at,
                  })
                }
              />
            )}
            {parent.run_at && (
              <ReorderHook
                sequence={1}
                key={`sequence-1-${parent.id}`}
                run_at={parent.run_at}
                parentId={parent.id}
                route_id={parent.route_id}
                week={parent.week}
              />
            )}
            {data.map((s, index) => (
              <>
                <div style={{display: 'flex'}} key={`job-${s.id}`}>
                  <Typography
                    variant="overline"
                    style={{
                      marginLeft: '-1.5em',
                      marginRight: '.5em',
                      top: '0px',
                      color: colors.grey400,
                      fontWeight: 'bold',
                    }}
                  >
                    {s.sequence}
                  </Typography>

                  {s.type === 'workorders' ? (
                    viewMode ? (
                      <ViewModeWorkorder
                        workorder={s}
                        key={`workorder-${s.id}`}
                      />
                    ) : (
                      <DraggableWorkorder
                        workorder={s}
                        relatedSchedule={
                          schedules.filter(
                            schedule => s.schedule_id == schedule.id,
                          )[0]
                        }
                        onOpenWorkorder={onOpenWorkorder}
                        parent={parent}
                        onUpdate={onUpdate}
                        key={`workorder-${s.id}`}
                      />
                    )
                  ) : viewMode ? (
                    <ViewModeSchedule
                      schedule={s}
                      onOpenSchedule={onOpenSchedule}
                      parent={parent}
                      onUpdate={onUpdate}
                      key={`schedule-${s.id}`}
                    />
                  ) : (
                    <DraggableSchedule
                      schedule={s}
                      onOpenSchedule={onOpenSchedule}
                      parent={parent}
                      onUpdate={onUpdate}
                      key={`schedule-${s.id}-${parent.run_at}`}
                    />
                  )}
                </div>
                <ReorderHook
                  sequence={index + 2}
                  run_at={parent.run_at}
                  parentId={parent.id}
                  key={`sequence-${index + 2}-${parent.id}}`}
                  route_id={parent.route_id}
                  week={parent.week}
                />
              </>
            ))}
            {countData > data.length ? (
              <Typography
                style={{color: colors.grey400, fontWeight: 'bold'}}
                key={'hidden'}
              >
                ~ {countData - data.length} items hidden ~
              </Typography>
            ) : (
              <></>
            )}
            {parent.run_at && (
              <ExtraDrop
                sequence={100}
                key={`sequence-100-${parent.id}`}
                run_at={parent.run_at}
                parentId={parent.id}
                route_id={parent.route_id}
                week={parent.week}
              />
            )}
          </div>
        </>
      )}
    </SchedulesCell>
  )
}
