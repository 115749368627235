import {
  Button,
  Grid,
  IconButton,
  Paper,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TableBody,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Modal,
  TextField,
  Badge,
  ImageListItem,
  Checkbox,
  FormControlLabel,
} from '@mui/material'
import NavigationArrowBack from '@mui/icons-material/ArrowBack'
import React, {useEffect, useState} from 'react'
import {Route} from 'react-router-dom'
import {NavigateBack} from '../../components/Navigator'
import {
  CompleteMenuItem,
  DeleteMenuItem,
  OpenMenuItem,
  ToolbarGroup,
  ToolbarIconMenu,
  ToolbarMenuItem,
  VoidMenuItem,
} from '../../components/Toolbar'
import {Toolbar} from '../../AppHandler'
import ToolbarMegaTitle from '../../components/ToolbarMegaTitle'
import {
  Add,
  AddBox,
  Assignment,
  AttachFile,
  Attachment,
  CheckBox,
  CheckBoxOutlineBlank,
  Edit,
  Photo,
  Print,
  Refresh,
} from '@mui/icons-material'
import {CustomerMap, CustomerPhoto} from '../Customer/CustomerDetail'
import styled from 'styled-components'
import {Space} from '../../components/Layout'
import gql from 'graphql-tag'
import {useQuery, useMutation} from '@apollo/react-hooks'
import {
  useQuery as useReactQuery,
  useMutation as useReactQueryMutation,
  useQueryClient,
} from 'react-query'
import moment from 'moment'
import {formatMoneyStandard} from '../../utils/moneyFormatter'
import {Link} from 'react-router-dom'
import EditWorkorder from './EditWorkorder'
import {fetchAPI, openFile} from '../../schema/utils'
import {getGPSCoordsFromMetadata, getUsablePhotoUrl, print} from '../../utils'
import colors from '../../styles/colors'
import theme from '../../styles/theme'
import {DatePicker, StaticDatePicker} from '@mui/lab'
import RouteSelect from '../../components/RouteSelect'
import {prgql} from '../../utils/graphql'
import PRImage from '../../components/Image'
import {fragments} from '../Workorders/WorkordersList'
import ExifReader from 'exifreader'

let Content = styled(Grid)`
  padding: 25px 50px;
`

let Bottom = styled(Content)`
  justify-content: right;
`

let DetailsWrapper = styled.div`
  padding: 16px;
  width: 100%;
`

let Numberline = styled.div`
  display: flex;
  justify-content: space-between;
`

let SummaryNumbers = ({total, subtotal, tax, isDesktop = true}) => (
  <Grid item xs={isDesktop ? 3 : 4} style={{minWidth: '300px'}}>
    <Numberline>
      <Typography variant="h5">Subtotal:</Typography>
      <Typography variant="h5">{formatMoneyStandard(subtotal)}</Typography>
    </Numberline>
    <Numberline>
      <Typography variant="h5">Tax:</Typography>
      <Typography variant="h5">{formatMoneyStandard(tax)}</Typography>
    </Numberline>
    <hr />
    <Numberline>
      <Typography variant="h4">Total:</Typography>
      <Typography variant="h4">{formatMoneyStandard(total)}</Typography>
    </Numberline>
  </Grid>
)

let WorkorderStatusTag = styled(Typography)`
  background-color: ${({theme}) => theme.grey600};
  padding: 4px;
  border-radius: 4px;
  margin-left: 8px;
  opacity: 0.9;
  color: ${({theme}) => theme.grey50};
`

let ColoredRow = styled(TableRow)`
  &:nth-of-type(even) {
    background-color: ${({theme}) => theme.grey100};
  }
`

let workordersQuery = gql`
  query Workorders($id: String!) {
    workorders(id: $id) {
      id
      assigned_at
      completed_at
      status
      route_id
      multiday
      route {
        id
        name
      }
      arrival_start_time
      arrival_end_time
      customer_id
      notes
      notes_resolved
      attachment {
        id
        scanned_url
      }
      reminder_sent
      is_billable
      customer {
        id
        name
        street1
        customertype {
          id
          type
        }
        city {
          id
          name
        }
        region
        postcode
        taxable
        taxitem {
          id
          rate
        }
        photo {
          id
          thumb_url
          url
        }
        photos {
          id
          thumb_url
          url
        }
      }
      invoice_id
      invoice {
        id
      }
      workorderitems {
        ...WorkorderitemDetail
        saleitem {
          id
          name
        }
        salesperson {
          id
          first_name
          last_name
        }
      }
      routerun {
        id
        users {
          id
          first_name
          last_name
        }
      }
    }
  }
  ${fragments.workorderitem}
`

let UpdateWorkorderQuery = gql`
  mutation updateWorkorders($workorder: UpdateWorkordersInput) {
    updateWorkorders(input: $workorder) {
      workorders {
        id
      }
    }
  }
`

let ExtractFromBundleMutation = gql`
  mutation extract($input: ExtractFromBundleInput) {
    extractFromBundle(input: $input) {
      error
      message
    }
  }
`

let PhotoWrapper = styled(ImageListItem)`
  width: ${({width}) => width || '50px'}
  height: ${({height}) => height || '50px'}
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  :hover {
    ${({hover}) => hover && 'opacity: 50%;'}
  }
  margin: ${({margin}) => margin || '0'}
`
let PhotoFallback = styled.div`
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({theme}) => theme.grey300};
`
let LargeIcon = styled.div`
  color: ${({theme}) => theme.muiTheme.palette.action.disabled};
  width: 66%;
  height: 66%;
`
let PhotoIcon = styled(LargeIcon).attrs(() => ({as: Photo}))``

function WorkorderPhoto({photo, onClick}) {
  return (
    <PhotoWrapper
      onClick={() => (photo ? onClick() : '')}
      hover
      margin="0 3px 0 0"
    >
      {photo ? (
        <PRImage
          src={photo.thumb_url}
          alt={'customer ' + photo.id}
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }}
        />
      ) : (
        <PhotoFallback>
          <PhotoIcon />
        </PhotoFallback>
      )}
    </PhotoWrapper>
  )
}

function WorkorderDetailPhoto({photo, onClick}) {
  // console.log(getUsablePhotoUrl(photo, true))
  return (
    <PhotoWrapper height="300px" width="300px">
      {photo ? (
        <PRImage
          src={photo.large_url}
          fallbackSrc={photo.thumb_url}
          alt={'customer ' + photo.id}
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }}
        />
      ) : (
        <PhotoFallback>
          <PhotoIcon />
        </PhotoFallback>
      )}
    </PhotoWrapper>
  )
}

let PictureDialog = ({open, photo, onCancel}) => {
  const [metadata, setMetadata] = useState(null)

  useEffect(() => {
    if (!photo?.url) {
      return
    }

    const srcUrl = new URL(photo.url)
    fetchAPI({
      url: srcUrl.pathname + srcUrl.search,
      options: {mode: 'cors', cache: 'force-cache'},
      prefixUrl: false,
    })
      .then(response => response.arrayBuffer())
      .then(buffer => {
        const tags = ExifReader.load(buffer)
        console.log('EXIF metadata:', tags)
        setMetadata(tags)
      })
      .catch(error => console.error('Fetch error:', error))
  }, [photo?.url])

  return (
    <Dialog open={!!open} maxWidth="md">
      <DialogTitle>Photo Details</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item md={photo.lat && photo.lng ? 6 : 12}>
            <WorkorderDetailPhoto photo={photo} />
            <Typography>
              Created:{' '}
              {moment(photo.created_at).format('ddd, MMM D YYYY [at] h:mm a')}
            </Typography>
          </Grid>
          {metadata?.GPSLatitude && metadata?.GPSLongitude && (
            <Grid item md={6}>
              <CustomerMap
                containerElement={
                  <div
                    style={{
                      width: '300px',
                      height: '300px',
                      overflow: 'hidden',
                      borderRadius: '16px',
                    }}
                  />
                }
                mapElement={<div style={{width: '300px', height: '300px'}} />}
                markers={[
                  {
                    position: getGPSCoordsFromMetadata(metadata),
                    key: moment
                      .utc(photo.created_at)
                      .format('ddd, MMM D YYYY [at] h:mm a'),
                  },
                ]}
                defaultCenter={getGPSCoordsFromMetadata(metadata)}
              />
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>close</Button>
      </DialogActions>
    </Dialog>
  )
}

const WORKORDERSTATUS = {
  OPEN: 'OPEN',
  COMPLETE: 'COMPLETE',
  VOID: 'VOID',
}

let ChangeStatusDialog = ({onConfirm, onCancel, status}) => (
  <Dialog open={!!status}>
    <DialogTitle>Change Status</DialogTitle>
    <DialogContent>
      <Typography>
        Do you want to change this workorder's status to{' '}
        <WorkorderStatusTag component={'span'}>{status}</WorkorderStatusTag>?
      </Typography>
    </DialogContent>
    <DialogActions>
      <Button onClick={onCancel}>Cancel</Button>
      <Button color="primary" variant="contained" onClick={onConfirm}>
        {status}
      </Button>
    </DialogActions>
  </Dialog>
)

let BundleMissingDialog = ({onClose, open, message}) => (
  <Dialog open={!!open}>
    <DialogTitle>Error</DialogTitle>
    <DialogContent>
      <Typography>{message}</Typography>
    </DialogContent>
    <DialogActions>
      <Button color="primary" variant="contained" onClick={onClose}>
        Close
      </Button>
    </DialogActions>
  </Dialog>
)

let StyledSuccessBadge = styled(Badge).attrs(() => ({
  classes: {
    root: 'root',
    badge: 'badge',
  },
}))`
  // text-align: center;
  // display: flex;
  // flex: 1;
  & .badge {
    background-color: ${({theme}) => theme.palette.success.main};
  }
`

let ExtractAttachmentDialog = ({
  onConfirm,
  onCancel,
  onChangeDate,
  onChangeRoute,
  onChangePages,
  open,
  routeId,
  date,
  pages,
}) => (
  <Dialog open={open}>
    <DialogTitle>Extract Attachment from Batch</DialogTitle>
    <DialogContent>
      <StaticDatePicker
        variant="static"
        value={moment.utc(date)}
        onChange={d => onChangeDate(d.toDate())}
        name="date"
        renderInput={props => (
          <TextField
            {...props}
            variant="outlined"
            margin="dense"
            size="small"
          />
        )}
      />
      <RouteSelect
        clearable={false}
        selectedId={routeId}
        onChange={onChangeRoute}
        variant="fillled"
        name="route"
      />
      <TextField
        variant="filled"
        name="pages"
        label="Pages (comma separated)"
        value={pages}
        onChange={onChangePages}
      ></TextField>
    </DialogContent>
    <DialogActions>
      <Button onClick={onCancel}>Cancel</Button>
      <Button color="primary" variant="contained" onClick={onConfirm}>
        Extract
      </Button>
    </DialogActions>
  </Dialog>
)

let StatusBar = ({type, children}) => {
  let statusColor = ''
  switch (type) {
    case 'error':
      statusColor = theme.palette.danger.main
      break
    case 'info':
      statusColor = theme.palette.primary.light
      break
    case 'alert':
      statusColor = theme.palette.alert.main
      break
    case 'trashed':
      statusColor = theme.palette.trashed.main
      break
    case 'success':
    default:
      statusColor = theme.palette.success.main
  }

  return (
    <div
      style={{
        background: statusColor,
        padding: '.25em',
        textJustify: 'center',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      {children}
    </div>
  )
}

let photoQuery = gql`
  query photos($id: String!) {
    workorders(id: $id) {
      id
      photos {
        id
        type
        thumb_url
        medium_url
        large_url
        url
        lat
        lng
        created_at
      }
    }
  }
`

let notesMut = gql`
  mutation tips($id: String!, $resolved: Boolean!) {
    updateWorkorders(input: {id: $id, notes_resolved: $resolved}) {
      workorders {
        id
        notes_resolved
      }
    }
  }
`

const discountedCharge = woi => {
  let charge = woi.charge
  if (woi.discount) {
    if (woi.discount.type === 2) {
      charge = woi.charge - woi.discount.amount
    } else {
      charge = woi.charge - (woi.charge * woi.discount.amount) / 100
    }
  }
  return charge
}

export function WorkorderDetails({
  wo,
  showCustomerPhoto = false,
  showUpload = false,
  showInlineControls = false,
  onUpload = () => {},
  onEdit = () => {},
  showNotesResolvedCheckbox = false,
  onUpdateNotesResolved = () => {},
}) {
  let [showPic, setShowPic] = useState(false)
  let [pic, setPic] = useState({})

  let worktotal = 0
  let subtotal = 0
  let tax = 0
  let total = 0
  let isNotBillable = false

  let {data: photos, isLoading: photosLoading} = useReactQuery(
    ['workorder-photos', wo ? wo.id : 0],
    () => {
      return prgql({
        query: photoQuery,
        variables: {
          id: wo ? wo.id : 0,
        },
      })
    },
  )

  if (wo) {
    worktotal = wo.workorderitems.reduce((carry, i) => i.charge + carry, 0)
    subtotal = wo.workorderitems.reduce(
      (carry, i) => discountedCharge(i) + carry,
      0,
    )
    tax = wo.customer.taxable ? wo.customer.taxitem.rate * subtotal : 0
    total = subtotal + tax
    isNotBillable = !wo.is_billable || Math.round(worktotal * 100) <= 0
  }

  let mediaMatches = useMediaQuery('(min-width:950px)')

  return (
    <>
      <DetailsWrapper>
        <Paper
          style={{
            maxWidth: '1000px',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          {showInlineControls ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignContent: 'center',
              }}
            >
              <div>&nbsp;</div>
              <Typography
                variant="h5"
                style={{alignSelf: 'center', marginTop: '1em'}}
              >
                Workorder {wo.id}
              </Typography>
              <div>
                <IconButton
                  onClick={() => print(`/workorders/${wo.id}`)}
                  size="large"
                >
                  <Print />
                </IconButton>
              </div>
            </div>
          ) : (
            <Typography
              variant="h5"
              style={{alignSelf: 'center', marginTop: '1em'}}
            >
              Workorder {wo.id}
            </Typography>
          )}
          <Content container direction={mediaMatches ? 'row' : 'column'}>
            {showCustomerPhoto && (
              <>
                <Grid item xs={4} container>
                  <DetailsWrapper>
                    <Grid container spacing={2}>
                      <Grid item>
                        <CustomerPhoto customer={wo.customer} />
                      </Grid>
                      <Grid item></Grid>
                    </Grid>
                  </DetailsWrapper>
                </Grid>
                <Space size={2} inline={mediaMatches} />
              </>
            )}
            <Grid item xs>
              <DetailsWrapper>
                <Typography variant="h5">
                  <Link to={`/customers/${wo.customer.id}`}>
                    {wo.customer.name}
                  </Link>
                </Typography>
                <a
                  href={`https://maps.google.com/maps?q=${
                    wo.customer.street1
                  }+${wo.customer.city && wo.customer.city.name}+${
                    wo.customer.region
                  }+${wo.customer.postcode}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Typography variant="subtitle2">
                    {wo.customer.street1}{' '}
                  </Typography>
                  <Typography variant="body2">
                    {wo.customer.city && wo.customer.city.name},{' '}
                    {wo.customer.region} {wo.customer.postcode}{' '}
                  </Typography>
                  <Typography variant="body2">
                    {wo.customer.city && wo.customer.city.county}
                  </Typography>
                </a>
                <Space size={3} />
                <Typography variant="h5" display="p">
                  <Link
                    to={`/schedules/daily?date=${moment
                      .utc(wo.assigned_at)
                      .format('YYYY-MM-DD')}&routes=${wo.route_id}`}
                  >
                    {moment.utc(wo.assigned_at).format('ddd, MMM D')} on Route{' '}
                    {wo.route_id}
                  </Link>
                </Typography>
                <Grid container>
                  <Grid item>
                    <Typography variant="h5" display="span">
                      Status:{' '}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <WorkorderStatusTag display="span">
                      {wo.status}
                    </WorkorderStatusTag>
                  </Grid>
                  {wo.status === 'COMPLETE' && (
                    <Grid item style={{marginLeft: '.5em'}}>
                      <Typography variant="h5" display="span">
                        {moment
                          .utc(wo.completed_at)
                          .format('ddd, MMM D [at] HH:mm:ss')}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
                <Space size={3} />
                <Grid container alignItems="center" spacing={2}>
                  <Grid item>
                    <Typography variant="h5" display="span">
                      Confirmation:
                    </Typography>
                  </Grid>
                  <Grid item>
                    {photosLoading && !photos ? (
                      <Typography>loading</Typography>
                    ) : !photos ||
                      !photos.workorders ||
                      !photos.workorders.photos.length ? (
                      <Typography>No Confirmation Photo</Typography>
                    ) : (
                      <div style={{display: 'flex', flexDirection: 'row'}}>
                        {photos.workorders.photos
                          .filter(p => p.type === 'CONFIRMATION')
                          .map(p => {
                            return (
                              <WorkorderPhoto
                                photo={p}
                                onClick={() => {
                                  setPic(p)
                                  setShowPic(true)
                                }}
                              />
                            )
                          })}
                      </div>
                    )}
                  </Grid>
                </Grid>
                <Space size={3} />
                <Grid container alignItems="center" spacing={2}>
                  <Grid item>
                    <Typography variant="h5" display="span">
                      Signature:
                    </Typography>
                  </Grid>
                  <Grid item>
                    {photosLoading && !photos ? (
                      <Typography>loading</Typography>
                    ) : !photos ||
                      !photos.workorders ||
                      !photos.workorders.photos.length ? (
                      <Typography>No Signature</Typography>
                    ) : (
                      <WorkorderPhoto
                        photo={
                          photos.workorders.photos.filter(
                            p => p.type === 'SIGNATURE',
                          )[0]
                        }
                        onClick={() => {
                          setPic(
                            photos.workorders.photos.filter(
                              p => p.type === 'SIGNATURE',
                            )[0],
                          )
                          setShowPic(true)
                        }}
                      />
                    )}
                  </Grid>
                </Grid>
                <Space size={3} />
                <Grid container alignItems="center">
                  <Grid item>
                    <Typography variant="h5" display="span">
                      Attachments:
                    </Typography>
                  </Grid>
                  <Grid item>
                    {showUpload ? (
                      <>
                        <IconButton
                          onClick={() => {
                            wo.attachment && wo.attachment.scanned_url
                              ? openFile({
                                  url: '/api/workorders/' + wo.id + '.pdf',
                                  fileType: 'application/pdf',
                                })
                              : onUpload()
                          }}
                          id={
                            wo.attachment && wo.attachment.scanned_url
                              ? 'openAttachment'
                              : 'addAttachment'
                          }
                          size="large"
                        >
                          {wo.attachment && wo.attachment.scanned_url ? (
                            <Attachment />
                          ) : (
                            <Add />
                          )}
                        </IconButton>
                        {wo.attachment && wo.attachment.scanned_url && (
                          <Grid item>
                            <IconButton
                              onClick={() => {
                                onUpload()
                              }}
                              id="editAttachment"
                              size="large"
                            >
                              <Edit />
                            </IconButton>
                          </Grid>
                        )}
                      </>
                    ) : (
                      wo.attachment &&
                      wo.attachment.scanned_url && (
                        <IconButton
                          onClick={() => {
                            openFile({
                              url: '/api/workorders/' + wo.id + '.pdf',
                              fileType: 'application/pdf',
                            })
                          }}
                          size="large"
                        >
                          <Attachment />
                        </IconButton>
                      )
                    )}
                  </Grid>
                </Grid>
                <Space size={2} />
                {wo.invoice && (
                  <Typography>
                    Invoice:{' '}
                    <a
                      href={`/old/database/invoice.php?InvoiceNumber=${wo.invoice.id}`}
                    >
                      {wo.invoice.id}
                    </a>
                  </Typography>
                )}
                {(wo.arrival_start_time || wo.arrival_end_time) && (
                  <Typography>
                    Arrival:{' '}
                    {moment
                      .utc(wo.arrival_start_time, 'hh:mm:ss')
                      .format('h:mm a')}{' '}
                    -{' '}
                    {moment
                      .utc(wo.arrival_end_time, 'hh:mm:ss')
                      .format('h:mm a')}
                  </Typography>
                )}
                {wo.routerun && (
                  <Typography>
                    Team:{' '}
                    {wo.routerun.users.length
                      ? wo.routerun.users
                          .map(u => `${u.first_name} ${u.last_name}`)
                          .join(', ')
                      : 'No employees assigned'}{' '}
                  </Typography>
                )}
                {wo.reminder_sent && (
                  <Typography>
                    <StyledSuccessBadge
                      badgeContent={'✓'}
                      style={{marginRight: '.5em'}}
                    />{' '}
                    Reminder sent
                  </Typography>
                )}
                {wo.notes && (
                  <div>
                    <Typography>Notes: {wo.notes}</Typography>
                    {showNotesResolvedCheckbox ? (
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="secondary"
                            checked={wo.notes_resolved}
                            onClick={e => {
                              onUpdateNotesResolved(e.target.checked)
                            }}
                          />
                        }
                        label={'Notes Resolved'}
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                )}
              </DetailsWrapper>
            </Grid>
          </Content>
          <Bottom container direction="row" spacing={2}>
            {wo.workorderitems.map(woi => woi.tasks).flat().length ? (
              <Grid item xs={12}>
                <DetailsWrapper>
                  <Typography variant="h6">Tasks:</Typography>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell>Task</TableCell>
                        <TableCell>Complete</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {wo.workorderitems
                        .map(woi => woi.tasks)
                        .flat()
                        .map((t, i) => (
                          <>
                            <ColoredRow key={t.id}>
                              <TableCell>{i + 1}</TableCell>
                              <TableCell>
                                <Typography>
                                  {t.tasktype
                                    ? t.tasktype.description
                                    : t.description}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                {t.complete ? (
                                  <CheckBox style={{color: colors.grey600}} />
                                ) : (
                                  <CheckBoxOutlineBlank
                                    style={{color: colors.grey600}}
                                  />
                                )}
                              </TableCell>
                            </ColoredRow>
                          </>
                        ))}
                    </TableBody>
                  </Table>
                </DetailsWrapper>
              </Grid>
            ) : (
              <></>
            )}
            <Grid item xs={12}>
              <DetailsWrapper>
                <Typography variant="h6">Items:</Typography>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Line Item</TableCell>
                      <TableCell>Sale Item</TableCell>
                      <TableCell>Description</TableCell>
                      {wo && !wo.multiday && !isNotBillable && (
                        <TableCell>Charge</TableCell>
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {wo.workorderitems.map((w, i) => (
                      <>
                        <ColoredRow key={w.id}>
                          <TableCell>{i + 1}</TableCell>
                          <TableCell>
                            <Typography>
                              {w.saleitem && w.saleitem.name}
                            </Typography>
                          </TableCell>
                          <TableCell
                            style={{display: 'flex', flexDirection: 'column'}}
                          >
                            <Typography>{w.description}</Typography>
                            {w.notes && (
                              <Typography variant="body2">
                                Notes: {w.notes}
                              </Typography>
                            )}
                            {w.salesperson && (
                              <Typography variant="caption">
                                Commission: {w.salesperson.first_name}{' '}
                                {w.salesperson.last_name}
                              </Typography>
                            )}
                          </TableCell>
                          {!wo.multiday && !isNotBillable && (
                            <TableCell>
                              <Typography>
                                {formatMoneyStandard(w.charge)}
                              </Typography>
                            </TableCell>
                          )}
                        </ColoredRow>
                        {!!w.discount && (
                          <ColoredRow>
                            <TableCell></TableCell>
                            <TableCell>
                              <Typography>Discount</Typography>
                            </TableCell>
                            <TableCell>
                              <Typography>{w.discount.name}</Typography>
                            </TableCell>
                            <TableCell>
                              <Typography style={{color: 'red'}}>
                                -{' '}
                                {w.discount.type == 2
                                  ? formatMoneyStandard(w.discount.amount)
                                  : `${w.discount.amount}%`}
                              </Typography>
                            </TableCell>
                          </ColoredRow>
                        )}
                      </>
                    ))}
                  </TableBody>
                </Table>
              </DetailsWrapper>
            </Grid>
            <Space size={2} />
            {!wo.multiday && !isNotBillable && (
              <SummaryNumbers
                total={total}
                subtotal={subtotal}
                tax={tax}
                isDesktop={mediaMatches}
              />
            )}
          </Bottom>
        </Paper>
      </DetailsWrapper>
      <PictureDialog
        photo={pic}
        open={showPic}
        onCancel={() => setShowPic(false)}
      />
    </>
  )
}

let Workorder = ({match}) => {
  let {data, loading, error, refetch} = useQuery(workordersQuery, {
    variables: {id: match.params.id},
  })
  let [updateWorkorder] = useMutation(UpdateWorkorderQuery)
  let [extractFromBundle, {loading: bundleLoading}] = useMutation(
    ExtractFromBundleMutation,
    {
      onCompleted: v => {
        if (v.extractFromBundle.error) {
          setErrorMessage(v.extractFromBundle.message)
          setErrorOpen(true)
        }
      },
    },
  )
  // let {mutate: updateNotesResolved} = useReactQueryMutation(variables =>
  //   prgql({
  //     query: notesMut,
  //     variables,
  //   }),
  // )
  let [updateNotesResolved] = useMutation(notesMut)

  let [edit, setEdit] = useState(false)
  let [changeStatusTo, setChangeStatusTo] = useState('')
  let [extract, setExtract] = useState(false)
  let [extractDate, setExtractDate] = useState(new Date())
  let [extractRouteId, setExtractRouteId] = useState(0)
  let [extractPages, setExtractPages] = useState('')
  let [errorOpen, setErrorOpen] = useState(false)
  let [errorMessage, setErrorMessage] = useState('')

  useEffect(() => {
    if (data && data.workorders) {
      setExtractDate(
        new Date(data.workorders.completed_at || data.workorders.assigned_at),
      )
      setExtractRouteId(data.workorders.route_id)
    }
  }, [data])

  let wo = {}
  wo = data && data.workorders
  let subtotal = 0
  let tax = 0
  let total = 0
  if (wo) {
    subtotal = wo.workorderitems.reduce((carry, i) => i.charge + carry, 0)
    tax = wo.customer.taxable ? wo.customer.taxitem.rate * subtotal : 0
    total = subtotal + tax
  }

  let mediaMatches = useMediaQuery('(min-width:950px)')

  return (
    <>
      <Toolbar>
        <ToolbarGroup first={true}>
          <Route path={`/workorders`} exact>
            <NavigateBack defaultBack={'/workorders'} marker="WorkorderBack">
              <Button>
                <NavigationArrowBack />
              </Button>
            </NavigateBack>
          </Route>
        </ToolbarGroup>
        <ToolbarGroup>
          <ToolbarMegaTitle>
            Workorder {match.params.id} {wo && wo.multiday && '[MULTIDAY]'}
          </ToolbarMegaTitle>{' '}
          {/* {wo && <WorkorderStatusTag>{wo.status}</WorkorderStatusTag>} */}
        </ToolbarGroup>
        {!!wo && wo.status ? (
          <ToolbarGroup last={true}>
            {!!wo && wo.status === 'OPEN' && (
              <IconButton onClick={() => setEdit(true)} id="edit" size="large">
                <Edit />
              </IconButton>
            )}
            <IconButton
              onClick={() => print(`/workorders/${match.params.id}`)}
              size="large"
            >
              <Print />
            </IconButton>
            {!!wo && (
              <ToolbarIconMenu id={'menu'}>
                {wo.status === 'OPEN' && (
                  <VoidMenuItem
                    disabled={!!wo.invoice_id}
                    onClick={() => setChangeStatusTo('VOID')}
                    id="void"
                  />
                )}
                {wo.status === 'OPEN' && (
                  <CompleteMenuItem
                    onClick={() => setChangeStatusTo('COMPLETE')}
                    id="complete"
                  />
                )}
                {wo.status === 'COMPLETE' && (
                  <OpenMenuItem
                    onClick={() => setChangeStatusTo('OPEN')}
                    id="open"
                  />
                )}
                {wo.status === 'VOID' && (
                  <OpenMenuItem
                    text={'Re-Open'}
                    onClick={() => setChangeStatusTo('OPEN')}
                    id="reopen"
                  />
                )}
              </ToolbarIconMenu>
            )}
          </ToolbarGroup>
        ) : (
          ''
        )}
      </Toolbar>
      {data && wo && (
        <StatusBar
          type={
            wo.status === 'COMPLETE'
              ? 'success'
              : wo.status === 'OPEN'
              ? 'alert'
              : 'trashed'
          }
        >
          <Typography variant="body2" style={{alignSelf: 'center'}}>
            {wo.status}
            &nbsp;
            {wo.status === 'COMPLETE' &&
              '(' +
                moment.utc(wo.completed_at).format('ddd, MMM D [at] HH:mm:ss') +
                ')'}
          </Typography>
        </StatusBar>
      )}
      {data && wo && (
        <>
          <WorkorderDetails
            wo={wo}
            showCustomerPhoto
            onUpload={() => setExtract(true)}
            showUpload
            showNotesResolvedCheckbox={true}
            onUpdateNotesResolved={val => {
              updateNotesResolved({
                variables: {
                  id: wo.id,
                  resolved: val,
                },
                optimisticResponse: {
                  updateNotes: {
                    id: wo.id,
                    notes_resolved: val,
                  },
                },
              })
            }}
          />
          <ExtractAttachmentDialog
            routeId={extractRouteId}
            date={extractDate}
            pages={extractPages}
            onChangeRoute={r => setExtractRouteId(r.id)}
            onChangeDate={d => setExtractDate(d)}
            onChangePages={e => setExtractPages(e.target.value)}
            open={extract}
            onCancel={() => setExtract(false)}
            onConfirm={() => {
              setExtract(false)
              extractFromBundle({
                variables: {
                  input: {
                    workorder_id: wo.id,
                    route_id: extractRouteId,
                    date: moment.utc(extractDate).format('YYYY-MM-DD'),
                    page_numbers: extractPages,
                  },
                },
              }).then(refetch)
            }}
          />
          <BundleMissingDialog
            open={errorOpen}
            onClose={() => setErrorOpen(false)}
            message={errorMessage}
          />
          <EditWorkorder
            wo={wo}
            open={edit}
            onSave={refetch}
            onClose={() => setEdit(false)}
            showMultidayVersion={!!wo.multiday}
          />
          <ChangeStatusDialog
            onCancel={() => {
              setChangeStatusTo(false)
            }}
            onConfirm={() => {
              setChangeStatusTo(false)
              updateWorkorder({
                variables: {
                  workorder: {
                    id: wo.id,
                    status: WORKORDERSTATUS[changeStatusTo],
                    completed_at:
                      WORKORDERSTATUS[changeStatusTo] === 'COMPLETE'
                        ? moment.utc(wo.assigned_at).format('YYYY-MM-DD')
                        : undefined,
                  },
                },
              }).then(refetch)
            }}
            status={changeStatusTo}
          />
        </>
      )}
    </>
  )
}

export default Workorder
