import React from 'react'
import _ from 'lodash'
import {Redirect} from 'react-router-dom'
import compose from 'lodash/flowRight'
import styled from 'styled-components'
import url from 'url'
import {LocalForm, Control} from 'react-redux-form'
import Paper from '@mui/material/Paper'
import TextField from '@mui/material/TextField'
import ListItemText from '@mui/material/ListItemText'
import InputAdornment from '@mui/material/InputAdornment'
import InputLabel from '@mui/material/InputLabel'

import {gql, graphql} from '../utils/graphql'
import {formatMoneyStandard} from '../utils/moneyFormatter'
import {NavigateBack, RedirectBack} from '../components/Navigator'
import DetailToolbar from '../components/DetailToolbar'
import {Subhead} from '../components/Typography'
import Loading from '../components/Loading'
import AutoComplete from '../components/AutoComplete'

let Card = styled(Paper)`
  min-width: 960px;
  margin: 16px auto;
`
let Content = styled.div`
  padding: 16px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
`
let DataWrapper = styled.div`
  flex: 0 1 33%;
  margin-bottom: 16px;
`
let DataTitle = styled(Subhead)`
  font-weight: 600;
  font-size: 14px;
  display: block;
`
let DataValue = styled(Subhead)`
  display: block;
`
let DataSection = ({title, data}) => (
  <DataWrapper>
    <DataTitle>{title}</DataTitle>
    <DataValue>{data}</DataValue>
  </DataWrapper>
)

let TextFieldStyled = styled(TextField)``

let TextFieldCtrl = ({...props}) => (
  <Control
    {...props}
    component={TextFieldStyled}
    mapProps={{
      onChange: p => e =>
        p.type === 'number'
          ? p.onChange(parseFloat(e.target.value))
          : p.onChange(e),
    }}
  />
)

class InventoryDetailSelector extends React.Component {
  state = {search: null, selectedItem: null}
  handleSearch = search => {
    this.setState({search})
  }
  getData = () =>
    this.props.data &&
    this.props.data.edges &&
    this.props.data.edges.filter(
      i =>
        !this.state.search ||
        (this.props.itemToString(i.edge).toLowerCase() || '').includes(
          this.state.search.toLowerCase(),
        ),
    )
  render() {
    let {
      value,
      itemToString,
      noneMsg,
      label,
      selected,
      onChange,
      updateQuery,
      data,
      ...props
    } = this.props
    return (
      <AutoComplete
        data={this.getData() || []}
        getItem={({edge}) => edge}
        onSearch={this.handleSearch}
        itemToString={itemToString}
        renderItem={({item}) => <ListItemText primary={itemToString(item)} />}
        selectedItem={this.state.selectedItem || selected}
        renderNoItems={() => <ListItemText primary={noneMsg} />}
        onChange={item => {
          this.setState({selectedItem: item})
          onChange(item && item.id)
        }}
        hideIcon
        label={<InputLabel>{label}</InputLabel>}
        {...props}
      />
    )
  }
}
let createAutoComplete = ({query, itemToString, nameProp, noneMsg, label}) =>
  graphql(query, {
    options: ({value}) => ({
      variables: {selectedId: value, withSelected: !!value},
    }),
    props: ({data, ...props}) => ({
      ...props,
      itemToString: itemToString || (i => (!i ? '' : i[nameProp])),
      noneMsg,
      label,
      data: data.data,
      updateQuery: data.updateQuery,
      selected: data.selected,
    }),
  })(InventoryDetailSelector)

let FundSelect = createAutoComplete({
  query: gql`
    query funds($selectedId: String = "", $withSelected: Boolean!) {
      data: allFunds(limit: 100, orderBy: name_ASC) {
        edges {
          edge {
            id
            name
          }
        }
      }
      selected: funds(id: $selectedId) @include(if: $withSelected) {
        id
        name
      }
    }
  `,
  nameProp: 'name',
  noneMsg: 'No Funds found',
  label: 'Fund',
})
let TypeSelect = createAutoComplete({
  query: gql`
    query inventoryTypes($selectedId: String = "", $withSelected: Boolean!) {
      data: allInventoryItemTypes(limit: 100, orderBy: type_ASC) {
        edges {
          edge {
            id
            type
          }
        }
      }
      selected: inventoryItemTypes(id: $selectedId)
        @include(if: $withSelected) {
        id
        type
      }
    }
  `,
  nameProp: 'type',
  noneMsg: 'No Types found',
  label: 'Type',
})
let BrandSelect = createAutoComplete({
  query: gql`
    query brands($selectedId: String = "", $withSelected: Boolean!) {
      data: allBrands(limit: 100, orderBy: name_ASC) {
        edges {
          edge {
            id
            name
          }
        }
      }
      selected: brands(id: $selectedId) @include(if: $withSelected) {
        id
        name
      }
    }
  `,
  nameProp: 'name',
  noneMsg: 'No Brands found',
  label: 'Brand',
})
let MerchantSelect = createAutoComplete({
  query: gql`
    query merchants($selectedId: String = "", $withSelected: Boolean!) {
      data: allMerchants(limit: 100, orderBy: name_ASC) {
        edges {
          edge {
            id
            name
          }
        }
      }
      selected: merchants(id: $selectedId) @include(if: $withSelected) {
        id
        name
      }
    }
  `,
  nameProp: 'name',
  noneMsg: 'No Merchants found',
  label: 'Merchant',
})

let Grid = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-auto-rows: auto;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: ${({theme}) =>
    `${theme.muiTheme.spacing(2)}px ${theme.muiTheme.spacing(2)}px`};
  align-items: center;
  width: 100%;
`
let Cell = styled.div`
  grid-column: ${({col}) => col};
`

let InventoryItemDetail = ({item}) => (
  <Content>
    <Grid>
      <DataSection title="Count" data={item.count} />
      <Cell col="2/6">
        <DataSection title="Description" data={item.description} />
      </Cell>
      <DataSection
        title="Unit Cost"
        data={formatMoneyStandard(item.unit_cost)}
      />
      <DataSection title="Part Number" data={item.part_number} />
      <DataSection title="Min" data={item.min} />
      <DataSection title="Max" data={item.max} />
      {item.inventoryitemtype && (
        <DataSection title="Type" data={item.inventoryitemtype.type} />
      )}
      {item.fund && <DataSection title="Fund" data={item.fund.name} />}
      {item.brand && <DataSection title="Brand" data={item.brand.name} />}
      {item.merchant && (
        <DataSection title="Merchant" data={item.merchant.name} />
      )}
    </Grid>
  </Content>
)

let InventoryItemEdit = ({item, onChange}) => (
  <LocalForm
    initialState={_.pick(item, [
      'description',
      'count',
      'unit_cost',
      'part_number',
      'min',
      'max',
      'inventoryitemtype_id',
      'fund_id',
      'brand_id',
      'merchant_id',
    ])}
    onChange={onChange}
  >
    <Content>
      <Grid>
        <TextFieldCtrl model=".count" label="Count" type="number" />
        <Cell col="2/6">
          <TextFieldCtrl model=".description" label="Description" fullWidth />
        </Cell>
        <TextFieldCtrl
          model=".unit_cost"
          label="Unit Cost"
          type="number"
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
        <TextFieldCtrl model=".part_number" label="Part Number" />
        <TextFieldCtrl model=".min" label="Min" type="number" />
        <TextFieldCtrl model=".max" label="Max" type="number" />
        <Control component={TypeSelect} model=".inventoryitemtype_id" />
        <Control component={FundSelect} model=".fund_id" />
        <Control component={BrandSelect} model=".brand_id" />
        <Control component={MerchantSelect} model=".merchant_id" />
      </Grid>
    </Content>
  </LocalForm>
)

class InventoryItem extends React.Component {
  state = {editing: false, item: null, goBack: false}

  toggleEdit = () => this.setState(({editing}) => ({editing: !editing}))
  handleUpdate = item => this.setState({item})
  canSave = () => !!this.state.item
  handleSave = () =>
    this.props
      .update(this.state.item)
      .then(this.props.refetch)
      .then(() => this.toggleEdit())
  handleDelete = async () => {
    await this.props.delete(this.props.item.id)
    this.setState({goBack: true})
  }
  isEditing = () => this.state.editing

  render() {
    let {item, loading, match} = this.props
    let {goBack} = this.state
    let editing = this.isEditing()

    return !goBack ? (
      <Card>
        <DetailToolbar
          title={loading || !item ? '' : item.description}
          editing={editing}
          closeWrapper={
            <NavigateBack
              defaultBack={url.resolve(match.url, '.')}
              marker="InventoryBack"
            />
          }
          onEdit={this.toggleEdit}
          onDelete={this.handleDelete}
          onCancel={this.toggleEdit}
          onSave={this.handleSave}
          canSave={this.canSave()}
          deleteMessage={
            !loading
              ? 'Are you sure you want to delete ' + item.description + '?'
              : undefined
          }
          deleteTitle={
            !loading ? 'Delete ' + item.description + '?' : undefined
          }
        />
        {loading || !item ? (
          <Content>
            <Loading />
          </Content>
        ) : editing ? (
          <InventoryItemEdit item={item} onChange={this.handleUpdate} />
        ) : (
          <InventoryItemDetail item={item} />
        )}
      </Card>
    ) : (
      <RedirectBack
        defaultBack={url.resolve(match.url, '.')}
        marker="InventoryBack"
      />
    )
  }
}
let inventoryFragment = gql`
  fragment InventoryItemDetail on InventoryItems {
    id
    description
    count
    unit_cost
    part_number
    min
    max
    inventoryitemtype_id
    inventoryitemtype {
      id
      type
    }
    merchant_id
    merchant {
      id
      name
    }
    brand_id
    brand {
      id
      name
    }
    fund_id
    fund {
      id
      name
    }
  }
`
let deleteQuery = gql`
  mutation deleteInventoryItem($id: String!) {
    deleteInventoryItems(input: {id: $id}) {
      message
    }
  }
`
export default compose(
  graphql(
    gql`
      query InventoryItem($id: String!) {
        item: inventoryItems(id: $id) {
          ...InventoryItemDetail
        }
      }
      ${inventoryFragment}
    `,
    {
      skip: ({isNew}) => isNew,
      options: ({match: {params}}) => ({variables: {id: params.id}}),
    },
  ),
  graphql(
    gql`
      mutation updateInventoryItems($input: UpdateInventoryItemsInput) {
        updateInventoryItems(input: $input) {
          inventoryItems {
            ...InventoryItemDetail
          }
        }
      }
      ${inventoryFragment}
    `,
    {
      props: ({mutate, ownProps: {match}}) => ({
        update: attrs =>
          mutate({variables: {input: {id: match.params.id, ...attrs}}}),
      }),
    },
  ),
  graphql(deleteQuery, {
    props: ({mutate}) => ({
      delete: id =>
        mutate({variables: {id}}).then(res => {
          return res
        }),
    }),
  }),
)(InventoryItem)

class NewInventoryItem extends React.Component {
  state = {
    item: {
      description: _.get(
        this.props.location,
        'state.newItem.description',
        'New Item',
      ),
    },
    redirect: null,
  }

  canSave = () => !!this.state.item
  handleUpdate = item => this.setState({item})
  handleSave = async () => {
    let {id} = await this.props.create(this.state.item)
    this.setState({redirect: `/inventory/${id}`})
  }

  render() {
    let {match} = this.props
    let {item, redirect} = this.state
    return !redirect ? (
      <Card>
        <DetailToolbar
          title={item.description}
          editing
          cancelWrapper={
            <NavigateBack
              defaultBack={url.resolve(match.url, '.')}
              marker="InventoryBack"
            />
          }
          onCancel={this.cancel}
          onSave={this.handleSave}
          canSave={this.canSave()}
        />
        <InventoryItemEdit item={item} onChange={this.handleUpdate} />
      </Card>
    ) : (
      <Redirect to={redirect} />
    )
  }
}
let createItemQuery = gql`
  mutation createItem($input: CreateInventoryItemsInput) {
    createInventoryItems(input: $input) {
      inventoryItems {
        id
        description
      }
    }
  }
`
let NewItemContainer = graphql(createItemQuery, {
  props: ({mutate}) => ({
    create: input =>
      mutate({variables: {input}}).then(
        ({data}) => data.createInventoryItems.inventoryItems,
      ),
  }),
})(NewInventoryItem)
export {NewItemContainer as NewInventoryItem}
