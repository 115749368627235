import React, { useState } from 'react'
import { Route, Switch } from 'react-router-dom'

import Button from '@mui/material/Button'
import NavigationArrowBack from '@mui/icons-material/ArrowBack'
import { Query, Mutation } from 'react-apollo'
import { useMutation, useQuery } from 'react-query'
import gql from 'graphql-tag'
import Chip from '@mui/material/Chip'

import Attendance from './Attendance'
import EmployeeDetails from './EmployeeDetails'
import AttendanceEntry, { NewAttendanceEntry } from './AttendanceEntry'
import { NavigateBack, RedirectBack } from '../../components/Navigator'
import { Toolbar } from '../../AppHandler'
import ToolbarGroup from '../../components/ToolbarGroup'
import ToolbarMegaTitle from '../../components/ToolbarMegaTitle'
import { Spacing } from '../../components/Layout'
import { ToolbarIconMenu, DeleteMenuItem } from '../../components/Toolbar'
import DeleteDialog from '../../components/DeleteDialog'
import { NavTabs, TabLink } from '../../components/NavTabs'
import Losses from './Losses'
import LossEntry, { NewLossEntry } from './LossEntry'
import AccessTimeControls from './AccessTimeControls'
import AccessTimeControlEntry, {
  NewAccesstimecontrolEntry,
} from './AccessTimeControlEntry'
import { prgql } from '../../utils/graphql'
import { useAuth, useCurrentUser } from '../../security/auth'
import Commissions from './Commissions'
import Mentions from './Mentions'
import Tips from './Tips'
import { ListItemIcon, ListItemText, MenuItem, Typography } from '@mui/material'
import { EventBusy } from '@mui/icons-material'
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { Block, Space } from '../../components/Layout'
import DangerLoadingButton from '../../components/DangerLoadingButton'
import moment from 'moment'

let deleteQuery = gql`
  mutation deleteEmployee($id: String!) {
    deleteUsers(input: {id: $id}) {
      message
    }
  }
`
let DeleteEmployee = ({ id, showDelete, toggleDelete, onDelete }) => (
  <Mutation mutation={deleteQuery} variables={{ id }}>
    {deleteMut => (
      <DeleteDialog
        title="Delete Employee?"
        message="Are you sure you want to delete this Employee?"
        isOpen={showDelete}
        onCancel={toggleDelete}
        onConfirm={() => {
          deleteMut().then(onDelete)
        }}
      />
    )}
  </Mutation>
)

class ScreenToolbar extends React.Component {
  state = {
    showDelete: false,
    goBack: false,
  }

  render() {
    let {
      match,
      title,
      status,
      userId,
      canEditUser,
      currentUser,
      canPurgeUserFromSchedule,
      onPurgeUserFromSchedule,
    } = this.props
    let { showDelete, goBack } = this.state
    let toggleDelete = () =>
      this.setState(({ showDelete }) => ({ showDelete: !showDelete }))
    let setGoBack = () => this.setState({ goBack: true })
    let defaultBack = '/employees'

    return (
      <React.Fragment>
        <Toolbar>
          <ToolbarGroup first={true}>
            <Route
              path={`${match.path}/(attendance|feedbacks)?`}
              exact
              render={() => (
                <NavigateBack defaultBack={defaultBack} marker="EmployeeBack">
                  <Button>
                    <NavigationArrowBack />
                  </Button>
                </NavigateBack>
              )}
            />
          </ToolbarGroup>

          <ToolbarGroup>
            <Spacing>
              <ToolbarMegaTitle>{title}</ToolbarMegaTitle>
              {status && <Chip label={status} />}
            </Spacing>
          </ToolbarGroup>

          <ToolbarGroup last>
            {(canEditUser || canPurgeUserFromSchedule) && (
              <ToolbarIconMenu>
                {canEditUser && <DeleteMenuItem onClick={toggleDelete} />}
                {canPurgeUserFromSchedule && (
                  <MenuItem onClick={onPurgeUserFromSchedule}>
                    <ListItemIcon>
                      <EventBusy />
                    </ListItemIcon>
                    <ListItemText primary={'Purge User from Schedule'} />
                  </MenuItem>
                )}
              </ToolbarIconMenu>
            )}
            <DeleteEmployee
              id={userId}
              showDelete={showDelete}
              toggleDelete={toggleDelete}
              onDelete={setGoBack}
            />
            {goBack && (
              <RedirectBack defaultBack={defaultBack} marker="EmployeeBack" />
            )}
          </ToolbarGroup>
        </Toolbar>
        <Toolbar>
          <Route
            path={`${match.path}/:tab`}
            children={({ match: tabMatch }) => {
              let { params = {} } = tabMatch || {}
              let tabsMap = {
                commissions: 1,
                tips: 2,
                attendance: 3,
                losses: 4,
                // accesstimecontrols: 5,
                mentions: 5,
              }
              let activeTab = tabsMap[params.tab] || 0

              return (
                <NavTabs
                  value={activeTab}
                  variant="scrollable"
                  scrollButtons
                  allowScrollButtonsMobile
                >
                  <TabLink to={match.url} label="Details" />
                  {(canEditUser ||
                    (currentUser && currentUser.id === userId)) && (
                      <TabLink
                        to={match.url + '/commissions'}
                        label="Commissions"
                      />
                    )}
                  {(canEditUser ||
                    (currentUser && currentUser.id === userId)) && (
                      <TabLink to={match.url + '/tips'} label="tips" />
                    )}
                  {canEditUser && (
                    <TabLink
                      to={match.url + '/attendance'}
                      label="Attendance"
                    />
                  )}
                  {canEditUser && (
                    <TabLink to={match.url + '/losses'} label="Losses" />
                  )}
                  {/* {canEditUser && ( */}
                  {/*   <TabLink */}
                  {/*     to={match.url + '/accesstimecontrols'} */}
                  {/*     label="Access" */}
                  {/*   /> */}
                  {/* )} */}
                  {canEditUser && (
                    <TabLink
                      to={match.url + '/mentions'}
                      label="Feedback Mentions"
                    />
                  )}

                  {/* {canEditUser && (
                    <>
                      <TabLink
                        to={match.url + '/evaluations'}
                        label="Evaluations"
                        disabled
                      />
                      <TabLink
                        to={match.url + '/feedbacks'}
                        label="Feedbacks"
                        disabled
                      />
                      <TabLink
                        to={match.url + '/sales'}
                        label="Sales"
                        disabled
                      />
                      <TabLink
                        to={match.url + '/loses'}
                        label="Stewardships"
                        disabled
                      />
                    </>
                  )} */}
                </NavTabs>
              )
            }}
          />
        </Toolbar>
      </React.Fragment>
    )
  }
}

let employeeToolbarQuery = gql`
  query employeeToolbarQuery($id: String!) {
    users(id: $id) {
      id
      first_name
      last_name
      active
      auth_until
    }
  }
`

let purgeEmployeeMutation = gql`
  mutation Purge($input: PurgeUserFromScheduleInput) {
    purgeUserFromSchedule(input: $input) {
      message
    }
  }
`

let PurgeDialog = ({ isOpen, onClose, onSubmit, loading, employeeName }) => {
  return (
    <Dialog open={isOpen} maxWidth="xs" fullWidth onClose={onClose}>
      <DialogTitle>Purge {employeeName} from Schedule</DialogTitle>
      <DialogContent>
        <Typography>
          Would you like to purge {employeeName} from the upcoming schedule?
        </Typography>
        <Space />
        <Typography>
          This will remove them from future routeruns and the routerun template.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <DangerLoadingButton
          onClick={onSubmit}
          loading={loading}
          variant="contained"
          color="primary"
        >
          Purge
        </DangerLoadingButton>
      </DialogActions>
    </Dialog>
  )
}

let Feedbacks = () => <div>This is the feedbacks screen</div>

let Employee = ({ match }) => {
  let { params = {} } = match

  let { isLoading: loading, isError: error, data, refetch } = useQuery(['employees', params.id, 'toolbar'], () => prgql({ query: employeeToolbarQuery, variables: { id: params.id } }))

  let { status: canEditUser } = useAuth('manage users')

  let { status: canPurgeUserFromSchedule } = useAuth('purge user from schedule')

  let { currentUser } = useCurrentUser()

  let [purgeUserModal, setPurgeUserModal] = useState(false)

  let { mutate: purge } = useMutation(
    async ({ variables }) => prgql({ query: purgeEmployeeMutation, variables }),
    {
      onSuccess: () => {
        setPurgeUserModal(false)
      },
    },
  )

  return (
    <React.Fragment>
      {loading || error ? (
        <ScreenToolbar
          match={match}
          title={error ? 'Oops. Something went wrong' : ''}
        />
      ) : (
        <>
          <ScreenToolbar
            match={match}
            title={`${data.users.first_name} ${data.users.last_name}`}
            status={data.users.active ? 'Active' : 'Inactive'}
            userId={params.id}
            canEditUser={canEditUser === 'authorized'}
            canPurgeUserFromSchedule={canPurgeUserFromSchedule === 'authorized'}
            onPurgeUserFromSchedule={() => setPurgeUserModal(true)}
            currentUser={currentUser}
          />
          <PurgeDialog
            isOpen={purgeUserModal}
            loading={loading}
            employeeName={data.users.first_name + ' ' + data.users.last_name}
            onClose={() => setPurgeUserModal(false)}
            onSubmit={() =>
              purge({
                variables: {
                  input: {
                    from: moment().format('YYYY-MM-DD'),
                    user_id: data.users.id,
                  },
                },
              })
            }
          />
        </>
      )}
      <Switch>
        <Route
          path={`${match.path}/commissions`}
          exact
          component={Commissions}
        />
        <Route path={`${match.path}/losses`} exact component={Losses} />
        <Route
          path={`${match.path}/losses/new`}
          exact
          component={NewLossEntry}
        />
        <Route
          path={`${match.path}/losses/:lossId`}
          exact
          component={LossEntry}
        />
        <Route
          path={`${match.path}/accesstimecontrols`}
          exact
          // component={AccessTimeControls}
          render={props => (
            <AccessTimeControls employee={data.users} {...props} />
          )}
        />
        <Route
          path={`${match.path}/accesstimecontrols/new`}
          exact
          component={NewAccesstimecontrolEntry}
        />
        <Route
          path={`${match.path}/accesstimecontrols/:controlId`}
          component={AccessTimeControlEntry}
        />
        <Route path={`${match.path}/attendance`} exact component={Attendance} />
        <Route
          path={`${match.path}/attendance/new`}
          exact
          component={NewAttendanceEntry}
        />
        <Route
          path={`${match.path}/attendance/:attendanceId`}
          component={AttendanceEntry}
        />
        <Route path={`${match.path}/feedbacks`} component={Feedbacks} />
        <Route path={`${match.path}/Tips`} component={Tips} />
        <Route path={`${match.path}/mentions`} component={Mentions} />
        <Route path={`${match.path}`} component={EmployeeDetails} />
      </Switch>
    </React.Fragment>
  )
}

export default Employee
