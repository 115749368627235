import React, { useState } from 'react'
import gql from 'graphql-tag'
import { Route, Switch } from 'react-router-dom'
import queryString from 'query-string'

import PaginationContainer from '../../components/PaginationContainer'
import ModifyQueryParams from '../../components/ModifyQueryParams'
import ArList from './ArList'
import ArToolbar from './ArToolbar'
import ArSummary from './ArSummary'
import ArPrint from './ArPrint'
import ArEmail from './ArEmail'
import { useDebounce } from '../../utils'
import { debounce } from 'lodash'

let principalsQuery = gql`
  query Principals(
    $cursor: String
    $limit: Int
    $searchRoute: Int
    $searchTerms: Int
    $searchOrder: String
    $searchType: Int
    $name: String
    $balance: Float
  ) {
    customers: allPrincipals(
      cursor: $cursor
      limit: $limit
      filters: {
        route_id: $searchRoute
        invoice_status: "OPEN"
        name: $name
        terms_id: $searchTerms
        customertype_id: $searchType
        order: $searchOrder
        balance: $balance
      }
    ) {
      edges {
        cursor
        edge {
          ...PrincipalDetail
        }
      }
      pageInfo {
        next
        current
        prev
        count
        total
      }
    }
  }
  ${ArList.fragments.principal}
`

let customersQuery = gql`
  query Customers(
    $cursor: String
    $limit: Int
    $searchRoute: String
    $searchTerms: String
    $searchOrder: String
    $searchType: ID
    $searchPaused: Boolean
    $searchStopped: Boolean
    $searchCredit: Boolean
    $searchInvoiceStatus: String
    $name: String
    $balance: Float
  ) {
    customers: allCustomers(
      cursor: $cursor
      limit: $limit
      filters: {
        route_id: $searchRoute
        name: $name
        terms_id: $searchTerms
        customertype_id: $searchType
        order: $searchOrder
        services_paused: $searchPaused
        services_stopped: $searchStopped
        credit: $searchCredit
        invoice_status: $searchInvoiceStatus
        balance: $balance
      }
    ) {
      edges {
        cursor
        edge {
          ...CustomerARDetail
        }
      }
      pageInfo {
        next
        current
        prev
        count
        total
      }
    }
  }
  ${ArList.fragments.customer}
`

function Ar({ location, match }) {
  const {
    searchTerms = 'all',
    searchRoute = 'all',
    searchOrder = 'balances',
    searchType = 'all',
    display = 'customers',
    searchPaused = 'all',
    searchCredit = 'all',
    searchInvoiceStatus = 'all',
    searchBy = 'name',
    hideCols = '',
    q = '',
  } = queryString.parse(location.search)

  let mungeEdges = e => {
    if (e.edge.__typename === 'Customers') {
      return {
        ...e,
        edge: {
          ...e.edge,
          id: 'customers-' + e.edge.id,
          balance: e.edge.balances.balance,
          thirtyDay: e.edge.balances.thirtyDay,
          sixtyDay: e.edge.balances.sixtyDay,
          ninetyDay: e.edge.balances.ninetyDay,
          overNinetyDay: e.edge.balances.overNinetyDay,
          type: 'Customer',
          resource_id: e.edge.id,
        },
      }
    }
    return e
  }

  let [queryChanges, setQueryChanges] = useState({ q })
  let [debouncedQ] = useDebounce(queryChanges.q, 300)

  // a little ugly b/c the customers filters use strings instead of ints (like principals) and I don't want to change it.
  let search = {
    name: q === '' || searchBy !== 'name' ? undefined : q,
    balance: q === '' || searchBy !== 'balance' ? undefined : parseFloat(q),
    searchTerms:
      searchTerms === 'all'
        ? undefined
        : display === 'customers'
          ? searchTerms
          : parseInt(searchTerms, 10),
    searchRoute:
      searchRoute === 'all'
        ? undefined
        : display === 'customers'
          ? searchRoute
          : parseInt(searchRoute, 10),
    searchOrder: searchOrder === 'name' ? undefined : searchOrder,
    searchType:
      searchType === 'all'
        ? undefined
        : display === 'customers'
          ? searchType
          : parseInt(searchType, 10),
    searchPaused:
      searchPaused === 'all'
        ? undefined
        : searchPaused === 'stopped'
          ? undefined
          : searchPaused === 'true'
            ? true
            : false,
    searchStopped:
      searchPaused === 'all'
        ? undefined
        : searchPaused === 'stopped'
          ? true
          : searchPaused === 'true'
            ? undefined
            : false,
    searchCredit:
      searchCredit === 'all'
        ? undefined
        : searchCredit === 'true' ? true : false,
    searchInvoiceStatus: searchInvoiceStatus === 'all' ? undefined : searchInvoiceStatus,
  }

  let queries = {
    principals: principalsQuery,
    customers: customersQuery,
  }

  return (
    <PaginationContainer
      query={queries[display] || principalsQuery}
      variables={{
        ...search,
        cursor: '-1',
      }}
      children={({ customers, loadMore, loading, refetch }) => {
        return (
          <React.Fragment>
            <ArToolbar
              searchQuery={queryChanges.q}
              searchBy={searchBy}
              searchTerms={searchTerms}
              searchRoute={searchRoute}
              searchOrder={searchOrder}
              searchType={searchType}
              searchDisplay={display}
              searchPaused={searchPaused}
              searchCredit={searchCredit}
              searchInvoiceStatus={searchInvoiceStatus}
              onQueryChange={changes =>
                setQueryChanges(c => ({ ...c, ...changes }))
              }
              match={match}
              location={location}
              onRefetch={refetch}
              hideCols={!!hideCols ? hideCols.split(',') : []}
            />
            <ModifyQueryParams
              query={{ ...queryChanges, q: debouncedQ }}
              state={{}}
            />
            <ArList
              {...search}
              customers={
                customers && customers.edges && customers.edges.map(mungeEdges)
              }
              pageInfo={customers && customers.pageInfo}
              onInfiniteLoad={loadMore}
              loading={loading}
              display={display}
              onRefetch={refetch}
              hideCols={!!hideCols ? hideCols.split(',') : []}
            />
          </React.Fragment>
        )
      }}
    />
  )
}

export default ({ match }) => (
  <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
    <Switch>
      <Route path={match.path} exact component={Ar} />
      <Route path={`${match.path}/summary`} component={ArSummary} />
      <Route path={`${match.path}/print`} component={ArPrint} />
      <Route path={`${match.path}/email`} exact component={ArEmail} />
    </Switch>
  </div>
)
