import React from 'react'
import {Redirect} from 'react-router-dom'
import {withRouter} from 'react-router-dom'
import _ from 'lodash'
import queryString from 'query-string'

function ModifyQueryParams({query, location, state}) {
  let currentQuery = queryString.parse(location.search) || {}
  let matchQuery = query || {}

  // copy over undefined properties to have a positive match
  // and prevent infinite redirects
  for (let [k, v] of Object.entries(matchQuery)) {
    if (v === undefined) {
      currentQuery[k] = v
    }
  }

  return !_.isMatch(currentQuery, matchQuery) ? (
    <Redirect
      to={{
        ...location,
        search: queryString.stringify({...currentQuery, ...matchQuery}),
        state,
      }}
    />
  ) : null
}

export default withRouter(ModifyQueryParams)
