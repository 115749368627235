import React from 'react'
import styled from 'styled-components'
import {Query, Mutation} from 'react-apollo'
import gql from 'graphql-tag'
import moment from 'moment'

import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'
import CircularProgress from '@mui/material/CircularProgress'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import {DatePicker} from '@mui/lab'

import SaveIcon from '@mui/icons-material/Save'

import {
  DetailToolbar,
  ToolbarLeft,
  CloseButton,
  ToolbarTitle,
  ToolbarRight,
  ToolbarIconMenu,
  DeleteMenuItem,
} from '../../components/Toolbar'
import {NavigateBack, RedirectBack} from '../../components/Navigator/index'
import Loading from '../../components/Loading'
import {Space} from '../../components/Layout'
import DeleteDialog from '../../components/DeleteDialog'

let LeftIcon = styled.div`
  margin-right: ${({theme}) => theme.muiTheme.spacing(1)};
`

let ScreenToolbar = ({
  defaultBack,
  canSave,
  attendance,
  onSave,
  isSaving,
  isNew,
  onDelete,
}) => (
  <DetailToolbar>
    <ToolbarLeft>
      <NavigateBack defaultBack={defaultBack} marker="EmployeeAttendanceBack">
        <CloseButton />
      </NavigateBack>
    </ToolbarLeft>
    <ToolbarTitle>
      {attendance &&
        moment.utc(attendance.taken_at).format('dddd, MMMM D, YYYY')}
    </ToolbarTitle>
    <ToolbarRight>
      <Space sizePx={16} inline />
      <Button onClick={onSave} disabled={!canSave} color="primary">
        <LeftIcon>
          {isSaving ? (
            <CircularProgress size={24} thickness={3} />
          ) : (
            <SaveIcon />
          )}
        </LeftIcon>
        Save
      </Button>
      {!isNew && (
        <ToolbarIconMenu>
          <DeleteMenuItem onClick={onDelete} />
        </ToolbarIconMenu>
      )}
    </ToolbarRight>
  </DetailToolbar>
)

let attendanceQuery = gql`
  query attendanceEntryQuery($id: String!) {
    attendance(id: $id) {
      id
      taken_at
      hours
      type
      notes
      paid
      automated
    }
  }
`

let updateAttendanceQuery = gql`
  mutation attendanceEntryQuery($input: UpdateAttendancesInput) {
    updateAttendances(input: $input) {
      attendances {
        id
        taken_at
        hours
        type
        notes
        paid
      }
    }
  }
`
let createAttendanceQuery = gql`
  mutation createAttendanceEntryQuery($input: CreateAttendancesInput) {
    createAttendances(input: $input) {
      attendances {
        id
        taken_at
        hours
        type
        notes
      }
    }
  }
`

let deleteQuery = gql`
  mutation deleteAttendanceEntry($id: String!) {
    deleteAttendances(input: {id: $id}) {
      message
    }
  }
`

let EditRow = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 16px;
`

let ATTENDANCE_TYPES = [
  ['PTO', 'Paid Time Off'],
  ['UNPAID', 'Unpaid Day Off'],
  ['EXCUSED', 'Excused'],
  ['LATE', 'Late'],
]
let BEHAVIOR_TYPES = ['LATE', 'EXCUSED']

let HoursInputs = ({isCustom, isNegative, hours, onChange, setCustom}) => (
  <>
    <TextField
      select
      label="Period"
      variant="filled"
      value={isCustom ? 0 : Math.abs(hours)}
      onChange={e => {
        if (e.target.value === 0) {
          setCustom(true)
        } else {
          setCustom(false)
          onChange({
            hours: Math.abs(e.target.value) * (isNegative ? -1 : 1),
          })
        }
      }}
    >
      <MenuItem value={8}>Whole Day</MenuItem>
      <MenuItem value={4}>Half Day</MenuItem>
      <MenuItem value={0}>Custom</MenuItem>
    </TextField>
    {isCustom && (
      <TextField
        label="Hours"
        type="number"
        variant="filled"
        onChange={e => {
          let v = e.target.value
          onChange({
            hours: v === '' ? v : Math.abs(v) * (isNegative ? -1 : 1),
          })
        }}
        onBlur={() => {
          if (hours === '') {
            onChange({hours: 0})
          }
        }}
        value={hours === '' ? hours : Math.abs(hours)}
      />
    )}
    <TextField
      select
      variant="filled"
      value={isNegative}
      onChange={e => {
        let negOrPos = e.target.value ? -1 : 1
        onChange({
          hours: Math.abs(hours) * negOrPos,
        })
      }}
    >
      <MenuItem value={true}>Used</MenuItem>
      <MenuItem value={false}>Earned</MenuItem>
    </TextField>
  </>
)

class DetailEdit extends React.Component {
  state = {isCustom: false}

  render() {
    let {loading, data, onChange, isNew} = this.props
    let createHandler = key => e => onChange({[key]: e.target.value})
    let isNegative = data.hours <= 0
    let presetHours = [4, 8]
    let isBehavior = BEHAVIOR_TYPES.includes(data.type)
    let isCustom =
      this.state.isCustom || !presetHours.includes(Math.abs(data.hours))

    return loading || !data ? (
      <Loading />
    ) : (
      <>
        <EditRow>
          <TextField
            label="Type"
            select
            variant="filled"
            onChange={e => {
              let v = e.target.value
              let newIsBehavior = BEHAVIOR_TYPES.includes(v)
              onChange({
                type: v,
                ...(isBehavior && !newIsBehavior ? {hours: -8} : {}),
              })
            }}
            value={data.type}
          >
            {ATTENDANCE_TYPES.map(([t, label]) => (
              <MenuItem key={t} value={t}>
                {label}
              </MenuItem>
            ))}
          </TextField>
          <DatePicker
            variant="filled"
            label="Date"
            value={moment.utc(data.taken_at)}
            inputFormat="MMMM D, YYYY"
            onChange={d => onChange({taken_at: d.format('YYYY-MM-DD')})}
            renderInput={props => (
              <TextField
                {...props}
                variant="outlined"
                margin="dense"
                size="small"
              />
            )}
          />
          {!isBehavior && (
            <HoursInputs
              isCustom={isCustom}
              isNegative={isNegative}
              hours={data.hours}
              onChange={onChange}
              setCustom={v => this.setState({isCustom: v})}
            />
          )}
        </EditRow>
        <Space sizePx={16} />
        <TextField
          label="Notes"
          variant="filled"
          multiline
          onChange={createHandler('notes')}
          fullWidth
          value={data.notes}
        />
        {!isNew && isNegative && !data.automated && (
          <>
            <Space sizePx={16} />
            <FormControlLabel
              control={
                <Checkbox
                  color="secondary"
                  checked={data.paid}
                  onChange={(e, checked) => onChange({paid: checked})}
                />
              }
              label="Paid in Payroll"
            />
          </>
        )}
      </>
    )
  }
}

let PaperContainer = styled(Paper)`
  max-width: 960px;
  margin: 16px auto 0;
`

let Content = styled.div`
  padding: 16px;
`

let DeleteAttendance = ({id, showDelete, toggleDelete, onDelete}) => (
  <Mutation mutation={deleteQuery} variables={{id}}>
    {deleteMut => (
      <DeleteDialog
        title="Delete Attendance?"
        message="Are you sure you want to delete this Attendance?"
        isOpen={showDelete}
        onCancel={toggleDelete}
        onConfirm={() => {
          deleteMut().then(onDelete)
        }}
      />
    )}
  </Mutation>
)

let mergeChanges = (original, changes) => {
  let defaultAttendance = {
    hours: -8,
    notes: '',
    type: 'PTO',
    taken_at: moment().format('YYYY-MM-DD'),
  }
  return {...defaultAttendance, ...original, ...changes}
}
let cleanChanges = changes => {
  let {type, hours, ...rest} = changes
  let isBehavior = BEHAVIOR_TYPES.includes(type)
  return {
    ...rest,
    type,
    // if type is a behavior, set hours to 0 when saving
    hours: isBehavior ? 0 : hours,
  }
}

class AttendanceEntry extends React.Component {
  state = {
    goBack: false,
    changes: {},
    showDelete: false,
  }

  render() {
    let {match} = this.props
    let {params = {}} = match
    let {changes, goBack, showDelete} = this.state
    let canSave = Object.keys(changes).length > 0
    let defaultBack = `/employees/${params.id}/attendance`
    let setGoBack = () => this.setState({goBack: true})
    let toggleDelete = () =>
      this.setState(({showDelete}) => ({showDelete: !showDelete}))

    return (
      <Query query={attendanceQuery} variables={{id: params.attendanceId}}>
        {({data, loading, error}) => (
          <PaperContainer>
            <Mutation
              mutation={updateAttendanceQuery}
              variables={{
                input: {id: params.attendanceId, ...cleanChanges(changes)},
              }}
            >
              {(update, result) => (
                <ScreenToolbar
                  loading={loading}
                  attendance={mergeChanges(
                    (data && data.attendance) ?? [],
                    changes,
                  )}
                  defaultBack={defaultBack}
                  onSave={() => update().then(setGoBack)}
                  onDelete={() => this.setState({showDelete: true})}
                  canSave={canSave}
                  isSaving={result && result.loading}
                />
              )}
            </Mutation>
            <Content>
              <DetailEdit
                data={mergeChanges((data && data.attendance) ?? [], changes)}
                loading={loading}
                onChange={newChanges =>
                  this.setState(({changes}) => ({
                    changes: {...changes, ...newChanges},
                  }))
                }
              />
            </Content>
            <DeleteAttendance
              id={params.attendanceId}
              showDelete={showDelete}
              toggleDelete={toggleDelete}
              onDelete={setGoBack}
            />
            {goBack && (
              <RedirectBack
                defaultBack={defaultBack}
                marker="EmployeeAttendanceBack"
              />
            )}
          </PaperContainer>
        )}
      </Query>
    )
  }
}

export default AttendanceEntry

export class NewAttendanceEntry extends React.Component {
  state = {
    goBack: false,
    changes: {},
  }

  render() {
    let {match} = this.props
    let {params = {}} = match
    let {changes, goBack} = this.state
    let canSave = Object.keys(changes).length > 0
    let defaultBack = `/employees/${params.id}/attendance`
    let computedAttendance = mergeChanges({}, changes)

    return (
      <PaperContainer>
        <Mutation
          mutation={createAttendanceQuery}
          variables={{
            input: {user_id: params.id, ...cleanChanges(computedAttendance)},
          }}
        >
          {(update, result) => (
            <ScreenToolbar
              attendance={computedAttendance}
              defaultBack={defaultBack}
              onSave={() =>
                update().then(() => {
                  this.setState({goBack: true})
                })
              }
              canSave={canSave}
              isSaving={result && result.loading}
            />
          )}
        </Mutation>
        <Content>
          <DetailEdit
            isNew
            data={computedAttendance}
            onChange={newChanges =>
              this.setState(({changes}) => ({
                changes: {...changes, ...newChanges},
              }))
            }
          />
        </Content>
        {goBack && (
          <RedirectBack
            defaultBack={defaultBack}
            marker="EmployeeAttendanceBack"
          />
        )}
      </PaperContainer>
    )
  }
}
