import React from 'react'
import styled from 'styled-components'

// Define the prop types for the DetailHeader component.
interface DetailHeaderProps {
  warning?: boolean;
  caution?: boolean;
  info?: boolean;
}

// Pass the DetailHeaderProps interface as a generic parameter to styled.div.
const DetailHeader = styled.div`
  background-color: ${({theme, warning, caution, info}) =>
    warning
      ? theme.muiTheme.palette.danger.light
      : caution
      ? theme.muiTheme.palette.warning.light
      : theme.grey200};
  color: ${({theme, warning}) =>
    warning ? theme.muiTheme.palette.danger.contrastText : 'inherit'};
  margin: -16px -16px 16px -16px;
  padding: 8px 16px;
  text-align: left;
  display: flex;
  align-items: center;
`

export default DetailHeader
