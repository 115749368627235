import React, { Component, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Downshift from 'downshift'
import invariant from 'invariant'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'

import Viewport from './Viewport'

import List from '@mui/material/List'
import ListItemText from '@mui/material/ListItemText'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import InputBase from '@mui/material/Input'
import ListItem from '@mui/material/ListItem'
import Paper from '@mui/material/Paper'
import Grow from '@mui/material/Grow'
import Popper from '@mui/material/Popper'
import {
  FilledInput,
  OutlinedInput,
  InputAdornment,
  FormHelperText,
  InputLabel,
  LinearProgress,
  Typography,
  AutocompleteProps,
  Autocomplete as MuiAutocomplete
} from '@mui/material'

import SearchIcon from '@mui/icons-material/Search'
import CloseIcon from '@mui/icons-material/Close'

// Hello future me. I'm sorry for you. 
type ParsableFilters =
  | { [key: string]: { enum?: string; string?: string; number?: number; boolean?: boolean } }
  | { [key: string]: string | number | boolean };

function parseExtraFilters(filters: ParsableFilters): string {
  const extraGraphQLFilters: Array<string> = []
  for (let [key, value] of Object.entries(filters)) {
    let propType: string = typeof value
    if (propType === 'object') {
      const [type, val] = Object.entries(value)[0]
      value = val
      propType = type
    }

    const search = {
      number: value,
      string: `"${value}"`,
      boolean: value,
      enum: value
    }[propType] ?? value
    extraGraphQLFilters.push(`${key}: ${search}`);
  }
  return ', ' + extraGraphQLFilters.join(', ')
}

export function useAutoCompleteEndpoint({
  gqlFragment,
  selectedId,
  searchField,
  limit = 200,
  extraFilters,
  order,
}: { gqlFragment: any, selectedId: string, searchField: string, limit: number, extraFilters: undefined | ParsableFilters, order: undefined | string }): {
  selectedItem: EdgeContents,
  data: Array<GraphqlEdge>,
  getItem: (contents: GraphqlEdge) => EdgeContents,
  onSearch: (search: string) => void,
  loading: boolean,
  pageInfo: object | false,
} {
  const fragment = gqlFragment.definitions.find(
    def => def.kind === 'FragmentDefinition',
  )
  invariant(fragment, 'Graphql document must contain a Fragment')
  invariant(searchField, 'Autocomplete query must have a search field')
  const entityType = fragment.typeCondition.name.value

  // probably evil? I don't care
  let extraFiltersString = ''
  if (extraFilters) {
    extraFiltersString = parseExtraFilters(extraFilters)
  }

  const itemsQuery = React.useMemo(
    () => gql`
      query ${entityType}($limit: Int = ${limit}, $cursor: String = "-1", $search: String) {
        options: all${entityType}(limit: $limit, cursor: $cursor, filters: {${searchField}: $search${extraFiltersString}} ${order ? `, orderBy: ${order}` : ''}) {
          edges {
            edge {
              ...${fragment.name.value}
            }
          }
          pageInfo {
            total
            count
          }
        }
      }
      ${gqlFragment}
    `,
    [entityType, fragment, searchField, gqlFragment, extraFiltersString],
  )
  const selectedQuery = React.useMemo(
    () => gql`
      query selected($selectedId: String = "") {
        selected: ${entityType.charAt(0).toLowerCase() +
      entityType.slice(1)}(id: $selectedId) {
                  ...${fragment.name.value}
        }
      }
      ${gqlFragment}
    `,
    [entityType, fragment, gqlFragment],
  )

  const { data, loading: initialLoading, refetch, networkStatus } = useQuery(
    itemsQuery,
    {
      notifyOnNetworkStatusChange: true,
    },
  )
  const { data: selectedData } = useQuery(selectedQuery, {
    variables: {
      selectedId,
    },
    skip: !selectedId,
  })

  function onSearch(search) {
    refetch({ search }) ?? (() => { })()
  }

  const isLoading = initialLoading || networkStatus === 4

  return {
    selectedItem: selectedData && selectedData.selected,
    data: data && data.options && data.options.edges,
    getItem: ({ edge }) => edge,
    onSearch,
    loading: isLoading,
    pageInfo: data && data.options && data.options.pageInfo,
  }
}

type EdgeContents = {
  id: string
  [key: string]: any
}

type GraphqlEdge = {
  edge: EdgeContents
}

type PrAutocompleteProps = AutocompleteProps<any, any, any, any> & {
  selectedItem: EdgeContents | undefined,
  data: Array<GraphqlEdge>,
  onSearch: (search: string) => void,
  loading: boolean,
  getOptionLabel: (option: EdgeContents) => string,
  initialInputValue: string
}

export function Autocomplete({ selectedItem, data, onSearch = () => { }, loading, initialInputValue = '', ...props }: PrAutocompleteProps) {
  const [inputValue, setInputValue] = useState(initialInputValue)

  const selectedValues = React.useMemo(
    () => data?.map(({ edge }) => edge) || [],
    [data],
  );

  return (
    <MuiAutocomplete
      {...props}
      value={selectedItem}
      inputValue={inputValue}
      options={selectedValues}
      autoHighlight
      onInputChange={(event, value) => {
        setInputValue(value)
        onSearch(value)
      }}
      loading={loading}
      isOptionEqualToValue={(option, value) => {
        return option?.id === value?.id
      }}
    />
  )
}

//////////////////////////
// Legacy 
//////////////////////////

const InputFrame = styled.div`
  ${({ fullWidth }) => 'width: 100%;'};
`
const Root = styled.div`
  ${({ fullWidth }) => (fullWidth ? 'width: 100%;' : 'width: fit-content;')};
  display: inline-flex;
`
const Input = styled(InputBase)`
  align-items: center;
`
const Close = styled(CloseIcon)`
  align-self: center;
  visibility: ${({ hidden }) => (hidden ? 'hidden' : 'visible')};
  cursor: pointer;
`
const LoadingProgress = styled(LinearProgress)`
  position: absolute;
  right: 0;
  left: 0;
`

class AutoComplete extends Component {
  state = { isOpen: false }
  stateReducer = (state, changes) => {
    switch (changes.type) {
      default:
        return changes
    }
  }
  render() {
    let {
      onChange,
      data,
      onSearch,
      itemToString,
      selectedItem,
      icon,
      hideIcon,
      placeholder,
      renderItem,
      getItem,
      getKey,
      renderNoItems,
      emptySearchFilter = e => true,
      allSearchFilter = e => true,
      fullWidth,
      label,
      inputProps,
      inputVariant,
      inputMargin,
      inputSize,
      inputStyle,
      helperText,
      error,
      loading,
      disabled,
      clearable,
      frameStyle,
      pageInfo,
      ...props
    } = this.props

    if (clearable === undefined) clearable = true

    const InputVariantComp = { filled: FilledInput, outlined: OutlinedInput }[
      inputVariant
    ]

    const helperTextComp = helperText ? (
      <FormHelperText children={helperText} error={error} />
    ) : (
      undefined
    )

    if (inputMargin) {
      switch (inputMargin) {
        case 'dense':
          inputSize = 'small'
          break
        default:
          inputSize = 'normal'
          break
      }
    }

    return (
      <Downshift
        onChange={onChange}
        itemCount={data && data.length}
        initialHighlightedIndex={0}
        selectedItem={selectedItem || null}
        stateReducer={this.stateReducer}
        onStateChange={(changes, helpers) => {
          if (changes.isOpen) {
            onSearch('')
          }
        }}
        onInputValueChange={onSearch}
        itemToString={item => (!!item && !!itemToString ? itemToString(item) : '')}
        {...props}
        children={({
          getInputProps,
          getItemProps,
          getMenuProps,
          getRootProps,
          highlightedIndex,
          selectedItem,
          selectHighlightedItem,
          selectItem,
          clearSelection,
          openMenu,
          isOpen,
          inputValue,
        }) => {
          let autoCompleteItems =
            data &&
            data
              .filter(inputValue === '' ? emptySearchFilter : () => true)
              .filter(allSearchFilter)
              .map((item, index) => (
                <MenuItem
                  {...getItemProps({
                    item: getItem(item),
                    index,
                    key: getKey(getItem(item)),
                    selected: highlightedIndex === index,
                  })}
                >
                  {renderItem({ item: getItem(item) })}
                </MenuItem>
              ))

          if (!!pageInfo && pageInfo.total > pageInfo.count) {
            autoCompleteItems = [
              ...autoCompleteItems,
              <MenuItem
                {...getItemProps({
                  item: { id: 'more_items' },
                  index: data.length,
                  key: 'more-options',
                  selected: false,
                })}
                disabled
              >
                <Typography variant="subtitle2">
                  ~ {pageInfo.total - pageInfo.count} more ~
                </Typography>
              </MenuItem>,
            ]
          }

          return (
            <Root fullWidth={fullWidth} {...getRootProps({ refKey: 'ref' })}>
              <InputFrame fullWidth={fullWidth} style={frameStyle}>
                <FormControl
                  fullWidth={fullWidth}
                  variant={inputVariant}
                  error={error}
                  disabled={disabled}
                  size={inputSize}
                  style={inputStyle}
                >
                  {typeof label === 'string' ? (
                    <InputLabel
                      error={error}
                      disabled={disabled}
                      margin={inputMargin}
                    >
                      {label}
                    </InputLabel>
                  ) : (
                    label
                  )}
                  <Input
                    as={InputVariantComp}
                    disabled={disabled}
                    error={error}
                    startAdornment={
                      !hideIcon && (
                        <InputAdornment position="start">{icon}</InputAdornment>
                      )
                    }
                    endAdornment={
                      clearable && (
                        <InputAdornment position="end">
                          <Close
                            hidden={!selectedItem}
                            onClick={clearSelection}
                          />
                        </InputAdornment>
                      )
                    }
                    inputRef={node => {
                      this.popperNode = node
                    }}
                    placeholder={placeholder}
                    fullWidth={fullWidth}
                    margin={inputMargin}
                    {...getInputProps({
                      onFocus: e => {
                        e.target.select()
                        openMenu()
                      },
                      ...inputProps,
                    })}
                  />
                  {helperTextComp}
                </FormControl>
              </InputFrame>
              <Popper
                open={isOpen}
                anchorEl={this.popperNode}
                style={{
                  zIndex: 1600,
                }}
              >
                <Viewport>
                  {({ width, height }) => (
                    <Grow in={isOpen} style={{ transformOrigin: '0 0 0' }}>
                      <div
                        {...(isOpen
                          ? getMenuProps({}, { suppressRefError: true })
                          : {})}
                      >
                        <Paper
                          style={{
                            position: 'relative',
                            overflowY: 'scroll',
                            overflowX: 'hidden',
                            maxHeight: `${height / 3}px`,
                            width: this.popperNode
                              ? this.popperNode.clientWidth
                              : null,
                          }}
                        >
                          {loading && <LoadingProgress />}
                          <List>
                            {autoCompleteItems && autoCompleteItems.length ? (
                              autoCompleteItems
                            ) : !loading ? (
                              <ListItem>{renderNoItems()}</ListItem>
                            ) : null}
                          </List>
                        </Paper>
                      </div>
                    </Grow>
                  )}
                </Viewport>
              </Popper>
            </Root>
          )
        }}
      />
    )
  }
}
AutoComplete.propTypes = {
  onChange: PropTypes.func,
  data: PropTypes.array,
  onSearch: PropTypes.func.isRequired,
  itemToString: PropTypes.func.isRequired,
  selectedItem: PropTypes.any,
  icon: PropTypes.node,
  placeholder: PropTypes.string,
  renderItem: PropTypes.func.isRequired,
  getItem: PropTypes.func,
  getKey: PropTypes.func,
  renderNoItems: PropTypes.func,
  hideIcon: PropTypes.bool,
  label: PropTypes.node,
  inputVariant: PropTypes.string,
  inputMargin: PropTypes.string,
  inputStyle: PropTypes.object,
}
AutoComplete.defaultProps = {
  getItem: item => item,
  getKey: item => item && item.id,
  renderNoItems: () => <ListItemText primary="Nothing found" />,
  data: [],
  onChange: () => { },
  icon: <SearchIcon />,
}
export default AutoComplete
