import React from 'react'
import {useState} from 'react'
import {useMutation, useQuery, useQueryClient} from 'react-query'
import {gql, prgql} from '../../utils/graphql'
import {
  Grid,
  Typography,
  Button,
  Paper,
  FormControlLabel,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Switch,
  MenuItem,
  Box,
  Select,
  Tooltip,
} from '@mui/material'
import TableList, {TrashableTableList} from '../../components/Table/TableList'
import {Column} from 'react-virtualized'
import {usePagination} from '../../components/PaginationContainer'
import {
  Add,
  ArrowRight,
  Cancel,
  Check,
  CheckCircle,
  ChevronRight,
  Circle,
  CloseRounded,
  Delete,
  HorizontalRule,
  Save,
  Stop,
} from '@mui/icons-material'
import Toolbar, {
  CloseButton,
  TrashMenuItem,
  DetailToolbar,
  ToolbarCenter,
  ToolbarIconMenu,
  ToolbarLeft,
  ToolbarRight,
} from '../../components/Toolbar'
import {NavigateBack} from '../../components/Navigator'
import styled from 'styled-components'
import {Space} from '../../components/Layout'
import {FieldArray, Formik} from 'formik'
import {
  AutoCompleteField,
  Checkbox,
  StaticSelect,
  TextField,
} from '../../components/forms'
import {RedirectBack} from '../../components/Navigator'
import {Link} from 'react-router-dom'
import toaster from '../../utils/toaster'
import colors from '../../styles/colors'
import IconButton from '@mui/material/IconButton'
import {RestoreFromTrash} from '@mui/icons-material'
import {fetchAPI} from '../../schema/utils'
import {estimateserviceFragment} from '../Customer/Estimate'
import SaleItemSelect from '../../components/SaleItemSelect'
import {getRandomString} from '../../utils'
import {formatMoneyStandard} from '../../utils/moneyFormatter'
import * as Yup from 'yup'
import Categories from '../../constants/EstimateserviceCategories'
import {WhiteBackgroundSwitch} from '../../components/WhiteBackgroundSwitch'

let query = gql`
  query Estimateservices(
    $limit: Int = 20
    $cursor: String
    $filters: EstimateserviceFilters
  ) {
    items: allEstimateservices(
      limit: $limit
      cursor: $cursor
      filters: $filters
    ) {
      edges {
        edge {
          ...EstimateserviceDetails
        }
        cursor
      }
      pageInfo {
        next
        current
        prev
        count
        total
      }
    }
  }
  ${estimateserviceFragment}
`

let getQuery = gql`
  query Estimateservice($id: String!) {
    item: estimateservices(id: $id) {
      ...EstimateserviceDetails
    }
  }
  ${estimateserviceFragment}
`

let createMutation = gql`
  mutation Create($input: CreateEstimateservicesInput) {
    createEstimateservices(input: $input) {
      estimateservices {
        id
      }
    }
  }
`

let updateMutation = gql`
  mutation Update($input: UpdateEstimateservicesInput) {
    updateEstimateservices(input: $input) {
      estimateservices {
        id
      }
    }
  }
`

let deleteMutation = gql`
  mutation Delete($id: String!) {
    deleteEstimateservices(input: {id: $id}) {
      message
    }
  }
`

const AttributeoptionContainer = styled.div`
  padding: 0.5em;
  border: 1px ${colors.grey400} solid;
  border-radius: 10px;
  display: flex;
  gap: 5px;
  position: relative;
`

const StyledIconButton = styled(IconButton)`
  position: absolute;
  top: -20px;
  right: -20px;
`

const Attributeoption = ({onDelete, children}) => {
  const [hover, setHover] = useState(false)

  return (
    <AttributeoptionContainer
      onMouseOver={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {children}
      {hover && (
        <StyledIconButton onClick={onDelete}>
          <Cancel />
        </StyledIconButton>
      )}
    </AttributeoptionContainer>
  )
}

const Attribute = ({attr, i, onDelete, children}) => {
  const [hover, setHover] = useState(false)

  return (
    <Grid
      item
      container
      xs={12}
      key={attr.id}
      alignItems={'center'}
      gap={2}
      wrap="wrap"
      onMouseOver={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <div
        style={{display: 'flex', alignItems: 'center', flexDirection: 'row'}}
      >
        {/* BIG BRAIN MOMENT */}
        {hover ? (
          <IconButton id={`delete-attr-${i}`} onClick={onDelete}>
            <Delete />
          </IconButton>
        ) : (
          <IconButton id={`delete-attr-${i}`} disabled>
            <Circle />
          </IconButton>
        )}
        &nbsp;
        <TextField
          name={`attributes.${i}.label`}
          variant={'outlined'}
          size={'small'}
        />
        &nbsp;
        <FormControlLabel
          label="Multiselect"
          control={<Checkbox name={`attributes.${i}.multiselect`} />}
        />
        &nbsp;
        <Typography variant="h6" style={{width: '20px'}}>
          :
        </Typography>
      </div>

      {children}
    </Grid>
  )
}

const schema = Yup.object().shape({
  label: Yup.string().required('Required'),
  saleitem_id: Yup.string().required('Select Sale item'),
  price: Yup.number().required('Required'),
})

const mungeValues = (values, oldValues) => {
  console.log(values, oldValues)

  let input = {}

  let attrs = [
    'id',
    'label',
    'notes',
    'category',
    'price',
    'min_price',
    'ignore_qty',
    'qty_label',
    't2_threshhold',
    't2_price',
    't3_threshhold',
    't3_price',
    't4_threshhold',
    't4_price',
    't5_threshhold',
    't5_price',
    'saleitem_id',
    'is_custom_price',
  ]
  let attrReducer = (acc, k) => {
    let v = values[k]
    // id prop is not allowed in creation
    if (k === 'id' && (v === undefined || v.includes('new'))) {
      v = undefined
    }
    acc[k] = v
    return acc
  }

  let rels = ['attributes']
  let relAttrs = ['id', 'label', 'attributeoptions', 'multiselect']

  // I'm perfectly aware that this could be done recursively but I too stoopid to do that.
  let relReducer = (acc, k) => {
    let v = values[k]
    if (k === 'attributes') {
      let update = []
      let create = []
      let destroy = []

      let origIds = oldValues?.attributes?.map(e => e.id) || []
      values[k].forEach(function(rel) {
        let relAttrReducer = (acc, k) => {
          let v = rel[k]

          if (k === 'attributeoptions') {
            let o = rel.attributeoptions
            let origIds =
              oldValues.attributes
                .filter(attr => attr.id === rel.id)[0]
                ?.attributeoptions?.map(o => o.id) || []

            let update = []
            let create = []
            let destroy = []

            o.forEach(o => {
              let relRelAttrReducer = (acc2, k) => {
                let v = o[k]
                // id prop is not allowed in creation
                if (k === 'id' && (v === undefined || v.includes('new'))) {
                  v = undefined
                }
                acc2[k] = v
                return acc2
              }

              let item = ['id', 'label', 'price'].reduce(relRelAttrReducer, {})

              if (origIds.includes(item.id)) {
                update.push(item)
              } else {
                let {id, ...item2} = item
                create.push(item2)
              }
            }, {})
            const updateIds = update.map(u => u.id)
            origIds.forEach(id => {
              if (!updateIds.includes(id)) {
                destroy.push({id})
              }
            })

            v = {update, create, destroy, api_type: 'attributeoptions'}
          }

          // do the regular stuff
          acc[k] = v
          return acc
        }
        let item = relAttrs.reduce(relAttrReducer, {})
        if (origIds.includes(item.id)) {
          update.push(item)
        } else {
          let {id, ...item2} = item
          create.push(item2)
        }
      })
      const updateIds = update.map(u => u.id)
      origIds.forEach(id => {
        if (!updateIds.includes(id)) {
          destroy.push({id})
        }
      })

      acc['attributes'] = {
        create,
        update,
        destroy,
        api_type: 'attributes',
      }
    }
    return acc
  }

  let est = attrs.reduce(attrReducer, {})
  let relationships = rels.reduce(relReducer, {})

  input = {
    ...input,
    ...est,
    ...relationships,
  }

  console.log('input', input)

  return input
}

function hasMissingAttributes(originalData, modifiedData) {
  for (const attribute of originalData.attributes) {
    const modifiedAttribute = modifiedData.attributes.find(
      attr => attr.id === attribute.id,
    )
    if (!modifiedAttribute) {
      return true
    }

    for (const option of attribute.attributeoptions) {
      const modifiedOption = modifiedAttribute.attributeoptions.find(
        opt => opt.id === option.id,
      )
      if (!modifiedOption) {
        return true
      }
    }
  }

  return false
}

const Estimateservices = () => {
  let [showTrash, setShowTrash] = useState(false)
  let {data, loading, loadMore, refetch} = usePagination({
    query: query,
    variables: {
      cursor: '-1',
      filters: {
        trashed: showTrash,
      },
    },
    fetchPolicy: 'cache-and-network',
  })

  function getRowCount() {
    if (!data || !data.items || !data.items.pageInfo) {
      return 9999
    }
    return data.items.pageInfo.total
  }

  let {mutateAsync: restore} = useMutation(
    'restore',
    async ({id}) =>
      await fetchAPI({
        url: `/estimateservices/${id}/restore`,
        options: {method: 'POST'},
      }),
  )

  let canManage = 'authorized'

  return (
    <>
      <Toolbar>
        <FormControlLabel
          style={{marginLeft: '2em'}}
          control={
            <WhiteBackgroundSwitch
              checked={showTrash}
              onChange={e => {
                setShowTrash(e.target.checked)
              }}
              color="secondary"
            />
          }
          label={'Show Trash'}
        />
      </Toolbar>
      <TrashableTableList
        data={(data && data.items && data.items.edges) || []}
        infinite
        loadMoreRows={loadMore}
        rowCount={getRowCount()}
        wrapRow={({children, rowData}) => {
          return canManage === 'authorized' && !rowData.deleted_at ? (
            <Link to={`/settings/estimateservices/${rowData.id}`}>
              {children}
            </Link>
          ) : (
            children
          )
        }}
        rowClassName={({index}) => {
          let classnames = ''
          let sortedData = data
            ? data.items?.edges?.sort((a, b) => (b.cursor > a.cursor ? -1 : 1))
            : []
          let rowData =
            sortedData && sortedData[index] && sortedData[index].edge

          if (rowData) {
            classnames += rowData.deleted_at ? ' trashed' : ''
          }

          if (rowData) {
            classnames += !rowData.active ? ' inactive' : ''
          }

          return classnames
        }}
      >
        <Column
          dataKey="label"
          headerRenderer={({label}) => (
            <div style={{textAlign: 'left'}}>{label}</div>
          )}
          cellRenderer={data => (
            <Typography style={{textAlign: 'left'}}>
              {data.rowData?.saleitem?.name} - {data.cellData}
            </Typography>
          )}
          label="Label"
          flexGrow={1}
          width={0}
        />
        <Column
          dataKey="category"
          headerRenderer={({label}) => (
            <div style={{textAlign: 'left'}}>{label}</div>
          )}
          cellRenderer={data => (
            <Typography style={{textAlign: 'left'}}>
              {Categories[data.cellData]?.label || ''}
            </Typography>
          )}
          label="Category"
          flexGrow={1}
          width={0}
        />
        <Column
          dataKey="price"
          headerRenderer={({label}) => (
            <div style={{textAlign: 'left'}}>{label}</div>
          )}
          cellRenderer={data => (
            <Typography style={{textAlign: 'left'}}>
              {data.rowData?.is_custom_price
                ? 'Custom Price'
                : formatMoneyStandard(data.cellData) +
                  ' / ' +
                  data.rowData?.qty_label}
            </Typography>
          )}
          label="Base Price"
          flexGrow={1}
          width={0}
        />
        <Column
          dataKey="attributes"
          headerRenderer={({label}) => (
            <div style={{textAlign: 'left'}}>{label}</div>
          )}
          cellRenderer={data => (
            <Typography style={{textAlign: 'left'}}>
              {data.cellData?.map(acc => acc.label).join(', ')}
            </Typography>
          )}
          label="Attributes"
          flexGrow={1}
          width={0}
        />
        {showTrash && (
          <Column
            dataKey="id"
            cellRenderer={data => (
              <LeftIcon>
                <IconButton
                  onClick={async () => {
                    await restore({id: data.cellData})
                    refetch()
                  }}
                >
                  <RestoreFromTrash />
                </IconButton>
              </LeftIcon>
            )}
          />
        )}
      </TrashableTableList>
    </>
  )
}
let PaperContainer = styled(Paper)`
  width: 98%;
  margin: 16px auto 0;
  padding: 2em;
`
let LeftIcon = styled.div`
  margin-right: ${({theme}) => theme.muiTheme.spacing(1)};
`

let Form = ({onSubmit, initial = {}, formikkey, onDelete = () => {}}) => {
  const [warn, setWarn] = useState(false)
  const [confirm, setConfirm] = useState(false)
  const [modifyWarn, setModifyWarn] = useState(false)

  return (
    <>
      <Formik
        initialValues={initial}
        onSubmit={onSubmit}
        key={formikkey}
        validationSchema={schema}
      >
        {({
          submitForm,
          values,
          setValues,
          setFieldValue,
          isValid,
          isSubmitting,
          errors,
        }) => (
          <>
            <DetailToolbar>
              <ToolbarLeft>
                <NavigateBack
                  defaultBack={location.pathname}
                  marker="EstimateservicesBack"
                >
                  <CloseButton />
                </NavigateBack>
              </ToolbarLeft>
              <ToolbarCenter>
                <Typography variant="h6">
                  {values.id ? 'Edit' : 'Add'} Estimateservice
                </Typography>
              </ToolbarCenter>
              <ToolbarRight>
                <Space sizePx={16} inline />
                <Tooltip>
                  <Button
                    color="primary"
                    onClick={() => {
                      if (hasMissingAttributes(initial, values)) {
                        setModifyWarn(true)
                        return
                      }
                      submitForm()
                    }}
                    disabled={!isValid || isSubmitting}
                  >
                    <LeftIcon>
                      <Save />
                    </LeftIcon>
                    Save
                  </Button>
                </Tooltip>
                <ToolbarIconMenu>
                  <TrashMenuItem onClick={() => setWarn(true)} />
                </ToolbarIconMenu>
              </ToolbarRight>
            </DetailToolbar>
            <Typography variant="h6">Base properties</Typography>
            <Space sizePx={10} />
            <Grid container spacing={2}>
              <Grid item xs={3} style={{display: 'flex', alignItems: 'center'}}>
                <AutoCompleteField
                  as={SaleItemSelect}
                  name={`saleitem_id`}
                  selectedId={values.saleitem_id}
                  inputProps={{variant: 'outlined'}}
                  fullWidth
                />
              </Grid>
              <Grid item xs={3} style={{display: 'flex', alignItems: 'center'}}>
                <TextField
                  fullWidth
                  label="Label"
                  name="label"
                  variant={'outlined'}
                />
              </Grid>
              <Grid item xs={2} style={{display: 'flex', alignItems: 'center'}}>
                <FormControlLabel
                  label="Has a custom price"
                  control={<Checkbox name={`is_custom_price`} />}
                />
              </Grid>
              <Grid item xs={4} style={{display: 'flex', alignItems: 'center'}}>
                <TextField
                  fullWidth
                  label="Instructions"
                  name="notes"
                  multiline
                  variant={'outlined'}
                />
              </Grid>
              {/* <Grid item xs={4} style={{ display: 'flex', alignItems: 'center', }}> */}
              {/*   <Select */}
              {/*     value={values.category} */}
              {/*     onChange={e => setFieldValue('category', e.target.value)} */}
              {/*     label="Category" */}
              {/*   > */}
              {/*     {Object.values(Categories).map(cat => <MenuItem value={cat.value}>{cat.label}</MenuItem>)} */}
              {/*   </Select> */}
              {/* </Grid> */}
            </Grid>

            <Space sizePx={50} />
            <Typography variant="h6">Quantity based pricing</Typography>
            <Space sizePx={10} />

            <Grid container spacing={2}>
              <Grid item xs={2} style={{display: 'flex', alignItems: 'center'}}>
                <TextField
                  fullWidth
                  label="Quantity Label"
                  name="qty_label"
                  variant={'outlined'}
                />
              </Grid>
              <Grid item xs={2} style={{display: 'flex', alignItems: 'center'}}>
                <TextField
                  fullWidth
                  label="Base Price"
                  name="price"
                  variant={'outlined'}
                  type="number"
                />
              </Grid>
              <Grid item xs={2} style={{display: 'flex', alignItems: 'center'}}>
                <TextField
                  fullWidth
                  label="Minimum Price"
                  name="min_price"
                  variant={'outlined'}
                  type="number"
                />
              </Grid>
              <Grid item xs={2} style={{display: 'flex', alignItems: 'center'}}>
                <TextField
                  fullWidth
                  label="Ignore Quantity"
                  name="ignore_qty"
                  variant={'outlined'}
                  type="number"
                />
              </Grid>
            </Grid>

            <Space sizePx={50} />
            <Typography variant="h6">Tiered pricing</Typography>
            <Space sizePx={10} />

            <Grid container spacing={2}>
              {/* Tier 2 */}
              <Grid item xs={2} style={{display: 'flex', alignItems: 'center'}}>
                <TextField
                  fullWidth
                  label="Tier 2 pricing threshhold"
                  name="t2_threshhold"
                  variant={'outlined'}
                  type="number"
                  inputProps={{
                    inputMode: 'numeric',
                    pattern: '[0-9]*',
                  }}
                />
              </Grid>
              <Grid item xs={2} style={{display: 'flex', alignItems: 'center'}}>
                <TextField
                  fullWidth
                  label="Tier 2 pricing"
                  name="t2_price"
                  variant={'outlined'}
                  type="number"
                />
              </Grid>
              {/* Tier 3 */}
              <Grid item xs={2} style={{display: 'flex', alignItems: 'center'}}>
                <TextField
                  fullWidth
                  label="Tier 3 pricing threshhold"
                  name="t3_threshhold"
                  variant={'outlined'}
                  type="number"
                  inputProps={{
                    inputMode: 'numeric',
                    pattern: '[0-9]*',
                  }}
                />
              </Grid>
              <Grid item xs={2} style={{display: 'flex', alignItems: 'center'}}>
                <TextField
                  fullWidth
                  label="Tier 3 pricing"
                  name="t3_price"
                  variant={'outlined'}
                  type="number"
                />
              </Grid>
              {/* Tier 4 */}
              <Grid item xs={2} style={{display: 'flex', alignItems: 'center'}}>
                <TextField
                  fullWidth
                  label="Tier 4 pricing threshhold"
                  name="t4_threshhold"
                  variant={'outlined'}
                  type="number"
                  inputProps={{
                    inputMode: 'numeric',
                    pattern: '[0-9]*',
                  }}
                />
              </Grid>
              <Grid item xs={2} style={{display: 'flex', alignItems: 'center'}}>
                <TextField
                  fullWidth
                  label="Tier 4 pricing"
                  name="t4_price"
                  variant={'outlined'}
                  type="number"
                />
              </Grid>
              {/* Tier 5 */}
              <Grid item xs={2} style={{display: 'flex', alignItems: 'center'}}>
                <TextField
                  fullWidth
                  label="Tier 5 pricing threshhold"
                  name="t5_threshhold"
                  variant={'outlined'}
                  type="number"
                  inputProps={{
                    inputMode: 'numeric',
                    pattern: '[0-9]*',
                  }}
                />
              </Grid>
              <Grid item xs={2} style={{display: 'flex', alignItems: 'center'}}>
                <TextField
                  fullWidth
                  label="Tier 5 pricing"
                  name="t5_price"
                  variant={'outlined'}
                  type="number"
                />
              </Grid>
            </Grid>

            <Space sizePx={50} />
            <Typography variant="h6">Attributes</Typography>
            <Typography variant="subtitle1">
              Attributes are additive to the original price & affect every tier.
            </Typography>
            <Space sizePx={10} />

            <Grid container spacing={2} gap={1}>
              <FieldArray name="attributes">
                {({remove, push}) => (
                  <>
                    {' '}
                    {values.attributes.map((attr, i) => (
                      <Attribute
                        key={attr.id}
                        i={i}
                        attr={attr}
                        onDelete={() => remove(i)}
                      >
                        <FieldArray name={`attributes.${i}.attributeoptions`}>
                          {({remove, push}) => (
                            <>
                              {values.attributes[i].attributeoptions?.map(
                                (o, ii) => (
                                  <Attributeoption
                                    key={o.id}
                                    onDelete={() => remove(ii)}
                                  >
                                    <TextField
                                      name={`attributes.${i}.attributeoptions.${ii}.label`}
                                      label="Label"
                                      style={{width: '100px'}}
                                    />
                                    <TextField
                                      name={`attributes.${i}.attributeoptions.${ii}.price`}
                                      label="price"
                                      type={'number'}
                                      style={{width: '60px'}}
                                    />
                                  </Attributeoption>
                                ),
                              )}
                              <IconButton
                                onClick={() =>
                                  push({
                                    id: 'new' + getRandomString(3),
                                    price: 0,
                                    label: '',
                                  })
                                }
                              >
                                <Add />
                              </IconButton>
                            </>
                          )}
                        </FieldArray>
                      </Attribute>
                    ))}
                    <Button
                      variant="contained"
                      color="secondary"
                      size="small"
                      onClick={() =>
                        push({
                          id: 'new' + getRandomString(3),
                          label: '',
                          price: 0,
                          multiselect: false,
                          attributeoptions: [
                            {
                              id: 'new' + getRandomString(3),
                              price: 0,
                              label: '',
                            },
                            {
                              id: 'new' + getRandomString(3),
                              price: 0,
                              label: '',
                            },
                          ],
                        })
                      }
                    >
                      <Add />
                    </Button>
                  </>
                )}
              </FieldArray>
            </Grid>

            <Dialog open={warn} onClose={() => setWarn(false)}>
              <DialogTitle>Trash Record</DialogTitle>
              <DialogContent>
                Are you sure you want to trash this record? (You can restore it
                from trash later)
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setWarn(false)}>Cancel</Button>
                <Button onClick={() => onDelete(values.id)} variant="contained">
                  Trash
                </Button>
              </DialogActions>
            </Dialog>

            <Dialog open={modifyWarn} onClose={() => setModifyWarn(false)}>
              <DialogTitle>Removed Attributes</DialogTitle>
              <DialogContent
                style={{display: 'flex', flexDirection: 'column', gap: '5px'}}
              >
                <Typography>
                  Heads up! It looks like you removed some attributes or
                  attribute options. If any estimates are using these, it will
                  remove that attribute and their price may change!
                </Typography>
                <Typography>
                  In some cases, you may have to re-select the option to get the
                  original price.
                </Typography>
                <Typography>Are you sure you want to do this?</Typography>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setModifyWarn(false)}>Cancel</Button>
                <Button
                  onClick={() => {
                    submitForm()
                    setModifyWarn(false)
                  }}
                  variant="contained"
                >
                  Save modifications
                </Button>
              </DialogActions>
            </Dialog>
          </>
        )}
      </Formik>
    </>
  )
}

export const Estimateservice = ({match, location}) => {
  let [goBack, setGoBack] = useState(false)

  let queryClient = useQueryClient()
  let {data, isError, isLoading} = useQuery(
    ['single', match.params.id],
    async () =>
      await prgql({query: getQuery, variables: {id: match.params.id}}),
  )

  let {mutateAsync} = useMutation('update', async input => {
    return await prgql({
      query: updateMutation,
      variables: {input},
    })
  })

  let {mutateAsync: destroy} = useMutation('delete', async ({id}) => {
    return await prgql({
      query: deleteMutation,
      variables: {id},
    })
  })

  return (
    <>
      <PaperContainer>
        {isLoading ? (
          <div>Loading...</div>
        ) : isError ? (
          <div>Error</div>
        ) : (
          <Form
            formikkey={match.params.id}
            initial={data && data.item}
            onSubmit={async values => {
              let saveValues = mungeValues(values, data.item)
              await mutateAsync(saveValues)
              await queryClient.invalidateQueries(['single', match.params.id])
              toaster.success('Estimateservice saved')
              setGoBack(true)
            }}
            onDelete={async id => {
              await destroy({id})
              toaster.success('Estimateservice trashed')
              setGoBack(true)
            }}
          />
        )}
      </PaperContainer>
      {/* This is extremely evil. we need a hook that'll do this */}
      {goBack && (
        <RedirectBack
          defaultBack={location.pathname}
          marker="EstimateservicesBack"
          location="/settings/estimateservices"
        />
      )}
    </>
  )
}

export const NewEstimateservice = ({match, location}) => {
  let [goBack, setGoBack] = useState(false)

  let {mutateAsync} = useMutation('create', async input => {
    return await prgql({
      query: createMutation,
      variables: {input},
    })
  })

  const initial = {
    label: '',
    is_custom_price: false,
    attributes: [],
  }

  return (
    <>
      <PaperContainer>
        {
          <Form
            formikkey={match.params.id}
            initial={initial}
            onSubmit={async values => {
              let saveValues = mungeValues(values, initial)
              await mutateAsync(saveValues)
              setGoBack(true)
              toaster.success('Estimateservice Created')
            }}
          />
        }
      </PaperContainer>
      {/* This is extremely evil. we need a hook that'll do this */}
      {goBack && (
        <RedirectBack
          defaultBack={location.pathname}
          marker="EstimateservicesBack"
        />
      )}
    </>
  )
}

export default Estimateservices
