type FreqType = {
  [key: string]: {
    value: number,
    label: string,
  },
}
const DESIREDFREQUENCY: FreqType = {
  NOT_SELECTED: {
    value: 0,
    label: 'Select',
  },
  ONE_TIME: {value: 1, label: 'One Time'},
  ANNUAL: {value: 2, label: 'Annual'},
  BI_ANNUAL: {value: 3, label: 'Bi-Annual'},
  QUARTERLY: {value: 4, label: 'Quarterly'},
  BI_MONTHLY: {value: 5, label: 'Bi-Monthly'},
  MONTHLY: {value: 6, label: 'Monthly'},
  BI_WEEKLY: {value: 7, label: 'Bi-Weekly'},
  WEEKLY: {value: 8, label: 'Weekly'},
  FOUR_WEEKS: {value: 9, label: 'Every 4 Weeks'},
  EIGHT_WEEKS: {value: 10, label: 'Every 8 Weeks'},
}
export default DESIREDFREQUENCY
