import { gql, prgql } from '../../utils/graphql'
import TableList from '../../components/Table/TableList'
import { usePagination } from '../../components/PaginationContainer'
import React from 'react';
import { Paper, Accordion, AccordionSummary, AccordionDetails, TableRow, TableCell, Table, Chip, Typography, Dialog, DialogTitle, DialogContent, Button, Grid, TableBody, IconButton, DialogActions, TableHead, colors } from '@mui/material';
import { useMutation, useQuery } from 'react-query';
import { formatMoneyStandard } from '../../utils/moneyFormatter'
import { AssetsSelect, ChipAsset, ChipEmployee, LeaderSelect, PillBoxEmployee, UsersSelect, lookupQuery } from '../Schedules/scheduleComponents';
import styled from 'styled-components';
import LoadingButton from '../../components/LoadingButton';
import { Formik } from 'formik';
import { MultiSelect, TextField } from '../../components/forms';
import { Add, Brightness2, Brightness5, Delete, ExpandMore } from '@mui/icons-material';
import { RouteSelectFormik, RouteSelectStatic } from '../../components/RouteSelect';
import * as Yup from 'yup'
import DangerLoadingButton from '../../components/DangerLoadingButton';
import { useAuth } from '../../security/auth';

let query = gql`
query Templates {
  templates: allRouteruntemplates(limit: 10000) {
    edges {
      edge {
        id
        route_id 
        notes 
        day
        income_capacity
        items: routeruntemplateitems {
          id
          user_id
          leader
          user {
            id 
            first_name
            last_name
          }
        }
        assets {
          id
          type: assettype_id
          name
        }
        route {
          id
          name
          shift_id
          description
        }
      }
    }
  }
}`

let getQuery = gql`
  query Template($id: String!) {
    template: emailtemplates(id: $id) {
      id
      is_default
      is_system
      name
      body
    }
  }
`

let updateMutation = gql`
  mutation UpdateRouterunTemplate($input: UpdateRouteruntemplatesInput) {
    updateRouteruntemplates(input: $input) {
      routeruntemplates {
        id
      }
    }
  }
`

let createMutation = gql`
  mutation CreateRouterunTemplate($input: CreateRouteruntemplatesInput) {
    createRouteruntemplates(input: $input) {
      routeruntemplates {
        id
      }
    }
  }
`

let deleteMutation = gql`
  mutation DeleteRouterunTemplate($id: String!) {
    deleteRouteruntemplates(input: {id: $id}) {
      message
    }
  }
`

// constant that maps days of the week to text, 0 indexed week
const DAYS = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

const DenseTableCell = styled(TableCell)`
    padding: 4px;
`

const ClickableTableRow = styled(TableRow)`
    cursor: pointer;
    &:hover {
        background-color: #f5f5f5;
    }
`

const EditTemplateModal = ({ open, onClose, template, excludeRoutes = [] }) => {
  let { data: lookup } = useQuery('lookup', () =>
    prgql({ query: lookupQuery }),
  )
  let { mutateAsync: update } = useMutation('updateTemplate', async input => {
    return await prgql({
      query: updateMutation,
      variables: { input },
    })
  })
  let { mutateAsync: create } = useMutation('createTemplate', async input => {
    return await prgql({
      query: createMutation,
      variables: { input },
    })
  })

  let activeUsers = lookup ? lookup.users.edges.map(e => e.edge.id) : []
  let options = [
    ...(lookup ? lookup.users.edges : []),
    ...(template
      ? template.items
        .filter(u => activeUsers.indexOf(u.user?.id) === -1)
        .map(u => ({ edge: u }))
      : []),
  ]

  let formikValues = {
    ...template,
    users: template?.items.filter(e => !!e.user_id && e.user_id !== '0').map(e => e.user_id) || [],
    assets: template?.assets.map(e => e.id) || [],
  }

  const _mungeFormikValues = (values) => {
    let attrs = [
      'id',
      'route_id',
      'day',
      'notes',
      'income_capacity',
    ]
    let rels = ['users', 'assets']
    let props = attrs.reduce((carry, k) => {
      let v = values[k]
      if (k === 'start_time') {
        if (Number(v)) {
          v = v.substring(0, 2) + ':' + v.substring(2, 4)
        }
      }
      carry[k] = v
      return carry
    }, {})

    let routeruntemplateitems = {
      create: values.users
        .filter((u, index, arr) => arr.indexOf(u) === index)
        .reduce((carry, u) => {
          carry.push({ user_id: u })
          return carry
        }, []),
      destroy: template?.items
        .reduce((carry, u) => {
          carry.push({ id: u.id })
          return carry
        }, []),
    }

    let assets = {
      set: values.assets
        .filter((u, index, arr) => arr.indexOf(u) === index)
        .reduce((carry, u) => {
          carry.push({ id: u })
          return carry
        }, [])
    }
    // console.log(JSON.stringify({ ...props, routeruntemplateitems, assets }))

    return { ...props, routeruntemplateitems, assets }
  }

  return <Dialog open={open} maxWidth={'md'} fullWidth>
    <Formik
      initialValues={formikValues}
      onSubmit={async values => values.id ? await update(_mungeFormikValues(values)).then(onClose) : await create(_mungeFormikValues(values)).then(onClose)}
      validationSchema={Yup.object().shape({ route_id: Yup.string().required('Route is required').notOneOf(excludeRoutes, 'You already have a template item for this day and route') })}
      children={({ isSubmitting, isValid, submitForm, values, setFieldValue }) =>
        <>
          <DialogTitle>
            Edit Template
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={6} container direction={'row'} spacing={2}>
                <Grid item sm={12}>
                  <Typography variant="h6">Assignments</Typography>
                </Grid>

                <Grid item sm={12}>
                  <RouteSelectFormik
                    showAll={false}
                    name={'route_id'}
                    variant="filled"
                    label="Route"
                    fullWidth
                    id="select-route"
                    value={values.route_id || "1"}
                    filter={e => !excludeRoutes.includes(e.edge.id)}
                  />
                </Grid>

                <Grid item sm={12}>
                  <UsersSelect
                    users={options}
                    name={'users'}
                    variant="filled"
                    label="Employees"
                    fullWidth
                    id="select-users"
                  />
                </Grid>
                <Grid item sm={12}>
                  <AssetsSelect
                    assets={lookup && lookup.assets ? lookup.assets.edges : []}
                    name={'assets'}
                    variant="filled"
                    label="Assets"
                    fullWidth
                  />
                </Grid>
              </Grid>

              <Grid item xs={6} container direction={'column'} spacing={2}>
                <Grid item>
                  <Typography variant="h6">Details</Typography>
                </Grid>
                <Grid item sm={3}>
                  <TextField
                    name="income_capacity"
                    variant="filled"
                    type="number"
                    label="Capacity"
                    fullWidth
                  />
                </Grid>
                <Grid item>
                  <TextField
                    name="notes"
                    variant="filled"
                    label="Notes"
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={onClose}
              disabled={isSubmitting}
            >
              Cancel
            </Button>
            <LoadingButton
              variant="contained"
              color="primary"
              onClick={submitForm}
              loading={isSubmitting}
              disabled={!isValid}
            >
              Save
            </LoadingButton>
          </DialogActions>
        </>}
    />
  </Dialog>
}

const DeleteModal = ({ open, onClose }) => {
  let { mutateAsync: destroy, isLoading } = useMutation('deleteTemplate', async input => {
    return await prgql({
      query: deleteMutation,
      variables: { ...input },
    })
  })

  return <Dialog open={open} onClose={onClose}>
    <DialogTitle>Delete Template</DialogTitle>
    <DialogContent>
      <Typography>Are you sure you want to delete this template?</Typography>
    </DialogContent>
    <DialogActions>
      <Button
        onClick={onClose}
      >
        Cancel
      </Button>
      <DangerLoadingButton
        color="error"
        onClick={() => destroy({ id: open }).then(onClose)}
        loading={isLoading}
      >
        Delete
      </DangerLoadingButton>
    </DialogActions>
  </Dialog>
}

let SunIcon = styled(Brightness5)`
  color: ${colors.grey700};
`

let MoonIcon = styled(Brightness2)`
  color: ${colors.grey700};
`

const RouterunTemplates = () => {
  let { data, refetch, loading, loadMore } = useQuery(['RouterunTemplates'], async () => await prgql({ query }))
  let [selectedTemplate, setSelectedTemplate] = React.useState(null)
  let [editOpen, setEditOpen] = React.useState(false)
  let [deleteOpen, setDeleteOpen] = React.useState(false)

  function getRowCount() {
    if (!data || !data.templates || !data.templates.pageInfo) {
      return 9999
    }
    return data.templates.pageInfo.total
  }

  const templates = data?.templates?.edges?.map(edge => edge?.edge)
  let groups = {}
  if (templates) {
    templates.forEach(template => {
      if (!groups[template.day]) {
        groups[template.day] = []
      }
      groups[template.day].push(template)
    })
  }

  let { status: canManageRouterunTemplates } = useAuth('manage routerun templates')

  return (
    <>
      <Paper>
        {Object.entries(groups).map(([key, group]) => {
          return (
            <Accordion key={key} id={DAYS[key]}>
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography variant='h6'>
                  {DAYS[key]}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Table size='small'>
                  <TableHead>
                    <DenseTableCell>Route</DenseTableCell>
                    <DenseTableCell>Assets</DenseTableCell>
                    <DenseTableCell>Employees</DenseTableCell>
                    <DenseTableCell>Notes</DenseTableCell>
                    <DenseTableCell>Capacity</DenseTableCell>
                    <DenseTableCell></DenseTableCell>
                  </TableHead>
                  <TableBody>
                    {group.map((template, key) => {
                      return (
                        <ClickableTableRow key={key} onClick={() => {
                          if (canManageRouterunTemplates === 'authorized') {
                            setSelectedTemplate(template); setEditOpen(true)
                          }
                        }}
                          id={'edit-' + template.id}
                        >
                          <DenseTableCell>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              {template.route.shift_id === '1' ? <SunIcon /> : <MoonIcon />} &nbsp;
                              <Chip size='small' label={template.route.name} color='secondary' />
                            </div>
                          </DenseTableCell>
                          <DenseTableCell>
                            {template.assets.map(asset =>
                              <ChipAsset
                                size='small'
                                key={asset.id}
                                style={{ marginRight: '.2em' }}
                                className={
                                  asset.type === 2 ? 'powerwasher' : undefined
                                }
                                label={asset.name}
                              />
                            )}
                          </DenseTableCell>
                          <DenseTableCell>
                            {template.items.filter(item => !!item.user).map(item => <ChipEmployee style={{ marginRight: '.2em' }} size="small" key={item.user?.id} label={item.user?.first_name + ' ' + item.user?.last_name} />)}
                          </DenseTableCell>
                          <DenseTableCell>
                            {template.notes}
                          </DenseTableCell>
                          <DenseTableCell>
                            {formatMoneyStandard(template.income_capacity)}
                          </DenseTableCell>
                          <DenseTableCell>
                            {canManageRouterunTemplates === 'authorized' &&
                              <IconButton id={'delete-' + template.id} size='small' color='secondary' onClick={(e) => { e.stopPropagation(); setDeleteOpen(template.id) }}><Delete /></IconButton>
                            }
                          </DenseTableCell>
                        </ClickableTableRow>
                      )
                    })}
                  </TableBody>
                </Table>

                {canManageRouterunTemplates === 'authorized' &&
                  <Button color='secondary' id={'new-' + DAYS[key]} size='small' variant='contained' style={{ marginTop: '1em' }} onClick={() => { setSelectedTemplate({ day: Number.parseInt(key), items: [], assets: [] }); setEditOpen(true) }}><Add /></Button>
                }
              </AccordionDetails>
            </Accordion>
          )
        })}
      </Paper>
      <EditTemplateModal
        open={editOpen}
        onClose={() => { setSelectedTemplate(null); setEditOpen(false); refetch() }}
        template={selectedTemplate}
        excludeRoutes={groups?.[selectedTemplate?.day]?.filter(t => t.route_id !== selectedTemplate?.route_id)?.map(t => t.route_id)}
      />
      <DeleteModal open={deleteOpen} onClose={() => { setDeleteOpen(false); refetch() }} onDelete={() => { setDeleteOpen(false); refetch() }} />
    </>
  );
}

export default RouterunTemplates
